import React from 'react'

import { Outlet } from 'react-router-dom'

import { useI18n } from 'shared/i18n'

import { ROUTING_KEY } from '../../../locale/routing.en'
import { routingVueMigratedQa } from '../../../qa/routing.qa'

export function LayoutNewProfileF2(): JSX.Element {
  const t = useI18n(ROUTING_KEY)

  return (
    <div className="h-[calc(100vh-156px)] mt-[86px] w-full relative overflow-auto scrollbar-sm">
      <div
        className="max-w-[1006px]  mx-auto  px-8 "
        data-qa={routingVueMigratedQa.newProfile.profileWrapper}
      >
        <div className="w-full text-2xl top-16 mx-auto font-bold fixed bg-mono-white flex justify-items-start z-20 item-center pt-7 pb-8">
          <div className="self-center text-tertiary-grey-800 leading-tight">
            {t('newProfile.titleV2')}
          </div>
        </div>
        <div className=" pb-[50px]">
          <Outlet />
        </div>
      </div>
    </div>
  )
}
