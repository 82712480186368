import { CustomerChildId } from 'entities/organisation'

import {
  startOfMonth,
  subDays,
  addDays,
  startOfToday,
  endOfToday,
} from 'shared/date-time'
import { ArrayValue, asConst } from 'shared/typescript'

import { dashboardEn } from '../locale/dashboard.en'

export type DateRange<TDate extends Date | string = Date> = {
  startDate: TDate
  endDate: TDate
}

const asDateEnKey = asConst<keyof (typeof dashboardEn)['dateFilter']['option']>

export const dateRangeAdditionalOptions = [
  { tKey: asDateEnKey('today'), value: asConst('TODAY') },
  { tKey: asDateEnKey('lastWeek'), value: asConst('LAST_WEEK') },
  { tKey: asDateEnKey('currentMonth'), value: asConst('CURRENT_MONTH') },
  { tKey: asDateEnKey('last30Days'), value: asConst('LAST_30_DAYS') },
  { tKey: asDateEnKey('lastYear'), value: asConst('LAST_YEAR') },
]

export type DateRangeAvailableOptions = ArrayValue<
  typeof dateRangeAdditionalOptions
>

export type DateFilterForm = DateRange<string> & {
  dateOption: DateRangeAvailableOptions | null
}

export const dateRangeRecord = (): Record<
  DateRangeAvailableOptions,
  DateRange
> => {
  const endDate = endOfToday()

  return {
    TODAY: {
      startDate: startOfToday(),
      endDate,
    },
    LAST_WEEK: {
      startDate: subDays(startOfToday(), 7),
      endDate,
    },
    CURRENT_MONTH: {
      startDate: addDays(startOfMonth(new Date()), 1),
      endDate,
    },
    LAST_30_DAYS: {
      startDate: subDays(startOfToday(), 30),
      endDate,
    },
    LAST_YEAR: {
      startDate: subDays(startOfToday(), 365),
      endDate,
    },
  }
}

export type SelectedAccounts = {
  isOwnSelected: boolean
  customerChildIds: CustomerChildId[]
}

export type GenerateReportForm = {
  password: string
}
