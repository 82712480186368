import React, { useEffect } from 'react'

import { FrankieButton, FrankieLoader } from 'frankify/src'

import { useApplicantAssociateBusinessMutation } from 'features/applicant-business-ownership/state/applicant-associate-business/applicant-associate-business.mutation'

import { ApplicantId } from 'entities/applicant'

import { useI18n } from 'shared/i18n'
import { Noop } from 'shared/typescript'

import { APPLICANT_BUSINESS_OWNERSHIP_KEY } from '../../../applicant-business-ownership.key'
import { applicantBusinessOwnershipEn } from '../../../locale/applicant-business-ownership.en'
import { businessOwnerShipDataGridActionMenuQa } from '../../../qa/applicant-business-ownership.qa'

type Props = {
  entityId: ApplicantId
  applicantId: ApplicantId
  onClose: Noop
}

export function RemoveAssociatedPartyModal({
  entityId,
  applicantId,
  onClose,
}: Props) {
  const t = useI18n([APPLICANT_BUSINESS_OWNERSHIP_KEY], {
    keys: applicantBusinessOwnershipEn,
  })

  const { mutate, isLoading, isSuccess } =
    useApplicantAssociateBusinessMutation({
      applicantId,
      associationType: 'remove',
    })

  useEffect(() => {
    if (isSuccess) {
      onClose()
    }
  }, [isSuccess, onClose])

  const handleRemove = () => {
    mutate({ entityId })
  }

  return (
    <div className="text-tertiary-grey-700 w-[510px]">
      <div className="py-4 px-6 border-b border-tertiary-grey-300 text-md font-bold text-tertiary-grey-800">
        {t('action.removeAssociatedParty')}
      </div>

      <FrankieLoader loading={isLoading}>
        <div
          className="py-4 px-6"
          data-qa={businessOwnerShipDataGridActionMenuQa.wrapper}
        >
          <div className="font-bold">
            {t('actionWillRemoveAssociatedParty')}
          </div>
          <div className="font-medium mt-[20px] mb-[50px]">
            {t('areYouSure')}
          </div>
          <div className="flex justify-between">
            <FrankieButton
              onClick={handleRemove}
              testId={{
                button: businessOwnerShipDataGridActionMenuQa.removeBtn,
              }}
            >
              {t('remove')}
            </FrankieButton>

            <FrankieButton intent="secondary" onClick={onClose}>
              {t('cancel')}
            </FrankieButton>
          </div>
        </div>
      </FrankieLoader>
    </div>
  )
}
