import { APPLICANT_PEP_SACTIONS_KEY as APS } from '../applicant-pep-sactions.key'

const AppPepSac = `${APS}-applicant-pep-sactions-qa`
export const applicantPepSactionsQa = {
  toggleSwitchContainer: `${AppPepSac}-toggleSwitch-divContainer`,
  applicantPepSactionsInfoHeader: `${AppPepSac}-applicant-PepSactions-InfoHeader-component`,
  ApplicantPepSactionsDataGrid: `${AppPepSac}-applicant-PepSactions-DataGrid`,
} satisfies Record<string, `${typeof AppPepSac}-${string}`>

const AppPepSacDataGrid = `${AppPepSac}-applicant-PepSactions-data-grid-qa`
export const applicantPepSactionsDataGridQa = {
  dataGridProContainer: `${AppPepSacDataGrid}-dataGrid-Pro-Container`,
  applicationMatchStatusContainer: `${AppPepSacDataGrid}-application-match-status-container`,
} satisfies Record<string, `${typeof AppPepSacDataGrid}-${string}`>

const AppPepSanctionInfoHeader = `${AppPepSac}-applicant-PepSactions-info-header-qa`
export const applicantPepSanctionsInfoHeaderQa = {
  matchStrength: `${AppPepSanctionInfoHeader}-match-strength`,
  applicationMatchStatusContainer: `${AppPepSanctionInfoHeader}-application-match-status-container`,
} satisfies Record<string, `${typeof AppPepSanctionInfoHeader}-${string}`>

const AppPepSanctionStatusChange = `${AppPepSac}-applicant-PepSactions-match-status-change-qa`
export const applicantPepSanctionsStatusChangeQa = {
  form: `${AppPepSanctionStatusChange}-form`,
  closeBtn: `${AppPepSanctionStatusChange}-close-btn`,
  textArea: `${AppPepSanctionStatusChange}-text-area`,
  triggerBtn: `${AppPepSanctionStatusChange}-trigger-btn`,
} satisfies Record<string, `${typeof AppPepSanctionStatusChange}-${string}`>

const AppPepSanctionAdverseMedia = `${AppPepSac}-applicant-PepSactions-adverse-media-qa`
export const applicantPepSanctionAdverseMediaQa = {
  mediaArticle: `${AppPepSanctionAdverseMedia}-media-article`,
} satisfies Record<string, `${typeof AppPepSanctionAdverseMedia}-${string}`>

const AppPepSanctionDataSources = `${AppPepSac}-applicant-PepSactions-data-sources-qa`
export const applicantPepSanctionDataSourcesQa = {
  document: `${AppPepSanctionDataSources}-document`,
} satisfies Record<string, `${typeof AppPepSanctionDataSources}-${string}`>

const AppPepSanctionKeyData = `${AppPepSac}-applicant-PepSactions-key-data-qa`
export const applicantPepSanctionKeyDataQa = {
  listing: `${AppPepSanctionKeyData}-listing`,
  entity: `${AppPepSanctionKeyData}-entity`,
} satisfies Record<string, `${typeof AppPepSanctionKeyData}-${string}`>

const AppPepSanctionResultTab = `${AppPepSac}-applicant-PepSactions-result-tab-qa`
export const applicantPepSanctionResultTabQa = {
  container: `${AppPepSanctionResultTab}-container`,
  tab: `${AppPepSanctionResultTab}-tab`,
} satisfies Record<string, `${typeof AppPepSanctionResultTab}-${string}`>

const AppPepSanctionResult = `${AppPepSac}-applicant-PepSactions-result-qa`
export const applicantPepSanctionResultQa = {
  nextBtn: `${AppPepSanctionResult}-next-btn`,
  prevBtn: `${AppPepSanctionResult}-prev-btn`,
  infoHeader: `${AppPepSanctionResult}-info-header`,
  infoHeaderSearchDetails: `${AppPepSanctionResult}-info-header-search-details`,
} satisfies Record<string, `${typeof AppPepSanctionResult}-${string}`>
