import React, { useState } from 'react'

import { ListItemIcon } from '@mui/material'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

import { FrankieButton, FrankieIcon } from 'frankify/src'

import { useDownloadAnalyticsReportQuery } from 'features/dashboard/state/analytics-query/analytics.query'

import { differenceInDays } from 'shared/date-time'
import { useI18n } from 'shared/i18n'
import { notification } from 'shared/notification'
import { useOverlay } from 'shared/overlay'
import { Noop } from 'shared/typescript'

import { DASHBOARD_KEY } from '../../dashboard.key'
import { dashboardEn } from '../../locale/dashboard.en'
import { DateRange, SelectedAccounts } from '../../model/dashboard-form.model'
import { generateRequestQa } from '../../qa/dashboard.qa'
import { RequestReportModal } from '../request-report-modal/request-report-modal'

export type Props = {
  className?: string
  selectedAccount: SelectedAccounts
  selectedDateRange: DateRange
  onReportGeneration: Noop
}

export function GenerateReport({
  className = '',
  selectedAccount,
  selectedDateRange,
  onReportGeneration,
}: Props) {
  const t = useI18n([DASHBOARD_KEY], { keys: dashboardEn })

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const [createOverlay, closeOverlay] = useOverlay()

  const { downloadReport, isFetching: downloadingReport } =
    useDownloadAnalyticsReportQuery({
      ...selectedAccount,
      ...selectedDateRange,
    })

  const open = Boolean(anchorEl)

  const handleGenerateReport = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const openModal = () => {
    const { isOwnSelected, customerChildIds } = selectedAccount
    const noCustomerChildAcc = customerChildIds.length

    if (
      (isOwnSelected && noCustomerChildAcc > 0) ||
      (!isOwnSelected && noCustomerChildAcc > 1)
    ) {
      notification.error(t('message.singleAccount'))
      return
    }

    createOverlay(
      <RequestReportModal
        closeModal={closeOverlay}
        onReportGeneration={onReportGeneration}
      />,
      { className: 'p-0' },
    )

    handleClose()
  }

  const handleTransactionRecord = () => {
    if (
      !selectedAccount.isOwnSelected &&
      selectedAccount.customerChildIds.length === 0
    ) {
      notification.error(t('message.atleastOneAccount'))
      return
    }

    if (downloadingReport) {
      notification.error(t('message.inProgress'))
      return
    }

    const daysDifference = differenceInDays(
      selectedDateRange.endDate,
      selectedDateRange.startDate,
    )

    if (daysDifference > 31) {
      notification.error(t('message.maxDateRange', { noOfDays: 31 }))
      return
    }

    downloadReport()
    handleClose()
  }

  return (
    <div
      className={`flex items-center gap-4 ${className}`}
      data-qa={generateRequestQa.mainWrapper}
    >
      <FrankieButton
        className="!bg-mono-100 !outline-mono-50"
        testId={{ button: generateRequestQa.generateBtn }}
        onClick={handleGenerateReport}
        size="sm"
      >
        {t('generateReport.btn')}
      </FrankieButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        data-qa={generateRequestQa.menu}
      >
        <MenuItem
          onClick={handleTransactionRecord}
          data-qa={generateRequestQa.downloadTCR}
        >
          <ListItemIcon>
            <FrankieIcon
              name="mdiAccountDetailsOutline"
              className="text-tertiary-grey-400"
              size="md"
            />
          </ListItemIcon>
          {t('generateReport.menu.transactionRecord')}
        </MenuItem>
        <MenuItem
          onClick={openModal}
          data-qa={generateRequestQa.downloadAllTCR}
        >
          <ListItemIcon>
            <FrankieIcon
              name="mdiSwapHorizontal"
              className="text-tertiary-grey-400"
              size="md"
            />
          </ListItemIcon>
          {t('generateReport.menu.allRecord')}
        </MenuItem>
      </Menu>
    </div>
  )
}
