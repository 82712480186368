import { useMemo } from 'react'

import { useQuery } from '@tanstack/react-query'

import { F2PermissionPrivileges } from 'entities/role'

import { roleManagementF2Api } from '../../api/role-management-f2.api'
import { IPermissionFacade } from '../../model/role-management-f2.model'
import { ROLE_MANAGEMENT_F2_KEY } from '../../role-management-f2.key'

type PermissionState = Record<
  | 'viewPermission'
  | 'writePermission'
  | 'downloadPermission'
  | 'settingsPermission',
  IPermissionFacade[]
>

export const F2PermissionsQueryKey = [
  ROLE_MANAGEMENT_F2_KEY,
  'permission-query-key',
]

export const useF2PermissionQuery = () => {
  const { data: permissions, ...restQuery } = useQuery({
    queryKey: F2PermissionsQueryKey,
    queryFn: async () => {
      const { data } = await roleManagementF2Api.getPermissionList()
      return data
    },
  })

  /**
   * Group permissions based on their privileges or resource
   */
  const permissionState = useMemo(() => {
    const permissionState = permissions?.reduce(
      (acc, value) => {
        const { permission } = value
        const isView = permission.includes('view')

        const isWrite = (
          ['write', 'create', 'edit'] as F2PermissionPrivileges[]
        ).some(permissionType => permission.includes(permissionType))

        const isDownload = permission.includes('download')

        if (isView) acc.viewPermission.push(value)
        else if (isWrite) acc.writePermission.push(value)
        else if (isDownload) acc.downloadPermission.push(value)
        else if (permission.includes('settings'))
          acc.settingsPermission.push(value)
        return acc
      },
      {
        viewPermission: [],
        writePermission: [],
        settingsPermission: [],
        downloadPermission: [],
      } as PermissionState,
    )

    return permissionState
  }, [permissions])

  return { permissions, ...permissionState, ...restQuery }
}
