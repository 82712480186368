import React from 'react'

import { ApplicantId } from 'entities/applicant'

import { useI18n } from 'shared/i18n'

import { APPLICANT_BIOMETRICS_OCR_KEY } from '../../applicant-biometrics-ocr.key'
import { applicantBiometricsOcrEn } from '../../locale/applicant-biometrics-ocr.en'
import {
  CheckResult,
  reportStatus,
} from '../../model/applicant-biometrics-ocr.model'
import { applicantDocumentReportPanelQa } from '../../qa/applicant-biometric-ocr.qa'
import { useApplicantBiometricsOcrDocumentState } from '../../state/applicant-biometrics-ocr-data-state/applicant-biometrics-ocr-data.state'
import { BiometricAccordionHeader } from '../applicant-biometrics-accordion/applicant-biometrics-accordion'
import {
  ConfidenceScore,
  TickTree,
} from '../applicant-biometrics-ocr-helper/applicant-biometrics-ocr-helper'

type Props = {
  applicantId: ApplicantId
  index: number
  onManualOverride?: (status: { [key: string]: string }) => void
}

export function ApplicantDocumentsReportPanel({
  applicantId,
  index,
  onManualOverride,
}: Props) {
  const t = useI18n([APPLICANT_BIOMETRICS_OCR_KEY], {
    keys: applicantBiometricsOcrEn,
  })

  const {
    data: { documentDetailsData },
  } = useApplicantBiometricsOcrDocumentState({ applicantId, index })

  if (
    !documentDetailsData?.checkResultItems?.length &&
    !documentDetailsData?.hasSimplifiedResult
  )
    return null

  return (
    <BiometricAccordionHeader
      checkResult={documentDetailsData.ocrResult.toLowerCase() as CheckResult}
      reportStatus={t(
        reportStatus(
          documentDetailsData.checkStatus,
          documentDetailsData.hasSimplifiedResult,
        ),
      )}
      checkDate={documentDetailsData.checkDate}
      detailType="document"
      applicantId={applicantId}
      index={index}
      className="min-w-[934px]"
      onManualOverride={onManualOverride}
    >
      <div
        className="flex flex-wrap gap-y-6 py-6"
        data-qa={applicantDocumentReportPanelQa.trickTreeWrapper}
      >
        {documentDetailsData.checkResultItems?.map(item => (
          <TickTree {...item} className="basis-1/4" />
        ))}
        <ConfidenceScore
          confidenceLevel={documentDetailsData.confidenceLevel}
        />
      </div>
    </BiometricAccordionHeader>
  )
}
