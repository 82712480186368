import {
  AddressCategoryTypes,
  ApplicantResponse,
  BlocklistAttributeTypes,
  BlocklistReasonTypes,
  getLongAddressName,
} from 'entities/applicant'

export type BlocklistData = {
  entityId: string
  attribute: BlocklistAttributeTypes
  description: string
  reason: BlocklistReasonTypes
  createdDate: string
  lastUpdated: string
  addedBy: string | null
  duplicateBlocklistResolved: boolean | null
}

export type BlocklistDataProperties = keyof BlocklistData

export type BlocklistResponse = {
  nextPageMarker: number | null
  records: BlocklistData[]
}

// eslint-disable-next-line complexity
export function getContentFromApplicantByBlocklistInfo(
  applicantData: ApplicantResponse,
) {
  const blocklistAttribute =
    applicantData.checkSummary.issues.BLOCKLIST_ATTRIBUTE || ''
  const { applicantDetails } = applicantData
  const addresses = applicantDetails.addresses || []
  const { displayName } = applicantData.applicantDetails.name
  const dob = applicantData.applicantDetails.dateOfBirth

  let content = ''
  switch (blocklistAttribute) {
    case BlocklistAttributeTypes.ENTIRE_ENTITY:
      content = `${displayName ?? ''} DOB: ${dob ?? ''}`
      break
    case BlocklistAttributeTypes.MAILING_ADDRESS:
      content = getLongAddressName(addresses, AddressCategoryTypes.mailing)
      break
    case BlocklistAttributeTypes.RESIDENTIAL_ADDRESS:
      content = getLongAddressName(addresses, AddressCategoryTypes.current)
      break
    case BlocklistAttributeTypes.EMAIL_ADDRESS:
      content = applicantDetails.email.idNumber ?? ''
      break
    case BlocklistAttributeTypes.PHONE_NUMBER:
      content = applicantDetails.phoneNumber.idNumber ?? ''
      break
    case BlocklistAttributeTypes.ID_DOCUMENT:
      content = `${applicantData.documents[0]?.idType} ${
        applicantData.documents[0]?.idNumber ?? ''
      }`
      break
    case BlocklistAttributeTypes.NAME_AND_DOB:
      content = [
        applicantDetails.name.givenName,
        applicantDetails.name.middleName,
        applicantDetails.name.familyName,
      ]
        .filter(Boolean)
        .join(' ')
      break
    default:
      break
  }
  return content
}
