import axios from 'axios'

import { isProd } from 'shared/environment'

import { Client, IClient } from './client.service'

const bffAxiosInstance = axios.create({
  maxRedirects: 0,
  baseURL: process.env.BFF_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
    'Referrer-Policy': 'origin',
    'X-Frankie-Channel': 'portal',
    Authorization: 'Bearer ',
    ...(isProd && {
      'Strict-Transport-Security': 'max-age=31536000; includeSubDomains',
    }),
  },
})

export const bffClient: IClient = new Client(bffAxiosInstance)
