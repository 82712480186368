import { cva } from 'cva'
import './radio.css'

export const RadioStyle = cva(
  [
    'frankie-radio',
    'absolute',
    'cursor-pointer',
    'rounded',
    'disabled:cursor-default',
    'w-1',
    'h-1',
    'before:content-[""]',
    'before:relative',
    'before:block',
    'before:rounded-full',
    'before:border',
    'before:border-solid',
    'before:border-tertiary-grey-300',
    'before:bg-mono-white',
    'checked:before:bg-primary-800',
    'checked:before:border-none',
    'disabled:before:bg-tertiary-grey-100',
    'disabled:before:border-tertiary-grey-200',
    'checked:disabled:before:bg-primary-200',
    'after:content-[""]',
    'after:relative',
    'after:block',
    'after:bg-no-repeat',
    'after:bg-center',
  ],
  {
    variants: {
      size: {
        sm: [
          'top-2',
          'left-2',
          'before:bottom-2',
          'before:right-2',
          'before:w-4',
          'before:h-4',
          'after:w-2',
          'after:rounded-full',
          'after:h-2',
          'after:bottom-5',
          'after:right-1',
          'checked:after:bg-mono-white',
        ],
        md: [
          'top-2.5',
          'left-2.5',
          'before:bottom-2.5',
          'before:right-2.5',
          'before:w-5',
          'before:h-5',
          'after:w-2.5',
          'after:rounded-full',
          'after:h-2.5',
          'after:bottom-[25px]',
          'after:right-[5px]',
          'checked:after:bg-mono-white',
        ],
        lg: [
          'top-3',
          'left-3',
          'before:bottom-3',
          'before:right-3',
          'before:w-6',
          'before:h-6',
          'after:w-3',
          'after:rounded-full',
          'after:h-3',
          'after:bottom-[30px]',
          'after:right-1.5',
          'checked:after:bg-mono-white',
        ],
      },
      partial: {
        true: [],
      },
    },
    compoundVariants: [
      {
        size: 'sm',
        partial: true,
      },
      {
        size: 'md',
        partial: true,
      },
      {
        size: 'lg',
        partial: true,
      },
    ],
  },
)
export const ContainerStyle = cva(['relative'], {
  variants: {
    size: {
      sm: ['w-4', 'h-4'],
      md: ['w-5', 'h-5'],
      lg: ['w-6', 'h-6'],
    },
  },
})
