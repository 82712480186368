import { MFA_KEY } from '../mfa.key'

export const mfaQa = {
  form: 'mfa-code-form',
  header: 'mfa-code-form-header',
  confirmCta: 'mfa-code-form-cta-confirm',
  codeInput: 'mfa-code-form-input',
  resendCta: 'mfa-code-form-cta-resend',
  useAppRecoveryCta: 'mfa-code-form-cta-use-app-recovery',
  dontUseAppRecoveryCta: 'mfa-code-form-cta-dont-use-app-recovery',
  alert: 'mfa-alert',

  // disable mfa confirm modal
  disableMfaConfirm_header: 'mfa-disable-confirm-header',
  disableMfaConfirm_ctaConfirm: 'mfa-disable-confirm-cta-confirm',
  disableMfaConfirm_ctaCancel: 'mfa-disable-confirm-cta-cancel',

  mfaTypeSelect_header: 'mfa-type-select-header',
  mfaTypeSelect_optionEmail: 'mfa-type-select-option-email',
  mfaTypeSelect_optionApp: 'mfa-type-select-option-app',

  mfaEnableEmail_sendEmail_header: 'mfa-enable-email-send-email-header',
  mfaEnableEmail_sendEmail_subheader: 'mfa-enable-email-send-email-subheader',
  mfaEnableEmail_sendEmail_ctaCancel: 'mfa-enable-email-send-email-cta-cancel',
  mfaEnableEmail_sendEmail_ctaSubmit: 'mfa-enable-email-send-email-cta-submit',

  mfaEnableEmail_verifyCode_header: 'mfa-enable-email-verify-code-header',
  mfaEnableEmail_verifyCode_ctaCancel:
    'mfa-enable-email-verify-code-cta-cancel',
  mfaEnableEmail_verifyCode_ctaSubmit:
    'mfa-enable-email-verify-code-cta-submit',

  mfaEnableApp_verifyCode_header: 'mfa-enable-app-verify-code-header',
  mfaEnableApp_verifyCode_qr_code: 'mfa-enable-app-verify-code-qr-code',
  mfaEnableApp_verifyCode_mfaKey: 'mfa-enable-app-verify-code-mfa-key',
  mfaEnableApp_verifyCode_saveRecoveryCodes:
    'mfa-enable-app-verify-code-save-recovery-codes',
  mfaEnableApp_verifyCode_recoveryCodes:
    'mfa-enable-app-verify-code-recovery-codes',
  mfaEnableApp_verifyCode_recoveryCode:
    'mfa-enable-app-verify-code-recovery-code',
  mfaEnableApp_verifyCode_ctaCancel: 'mfa-enable-app-verify-code-cta-cancel',
  mfaEnableApp_verifyCode_ctaSubmit: 'mfa-enable-app-verify-code-cta-submit',
  mfaEnableApp_verifyCode_ctaCopyMfaKey:
    'mfa-enable-app-verify-code-cta-copy-mfa-key',

  mfaEnabled_header: 'mfa-enabled-header',
  mfaEnabled_ctaConfirm: 'mfa-enabled-cta-confirm',
} satisfies Record<string, `${typeof MFA_KEY}-${string}`>
