import { useEffect, useMemo } from 'react'

import {
  ApplicantId,
  ApplicantRiskChecksResponse,
  useApplicantRiskCheckQuery,
} from 'entities/applicant'

import { Noop } from 'shared/typescript'

const RiskTxnCount = ({
  risks,
  issue,
}: {
  risks?: ApplicantRiskChecksResponse['transactionalRisk']
  issue: 'AML' | 'Fraud' | 'Customer'
}): number => {
  if (!risks) return 0
  const issues = {
    AML: {
      type: 'aml',
      name: 'Transactions',
    },
    Fraud: {
      type: 'fraud',
      name: 'Transactions',
    },
    Customer: {
      type: 'fraud',
      name: 'Device/Customer',
    },
  }
  const { type } = issues[issue]
  const { name } = issues[issue]

  return risks.factors[type]?.find(factor => factor.name === name)?.count || 0
}

export const RiskHasTxnIssue = ({
  risks,
  type,
}: {
  risks?: ApplicantRiskChecksResponse['transactionalRisk']
  type: 'TXN_AML' | 'TXN_FRAUD' | 'TXN_CUSTOMER'
}): boolean =>
  !!risks?.issues.filter(issue => issue.type === type).length || false

type Args = {
  applicantId: ApplicantId
  onError: Noop
}

export const useApplicantTransactionRecordState = ({
  applicantId,
  onError,
}: Args) => {
  const {
    data: riskCheckData,
    isLoading,
    isError,
  } = useApplicantRiskCheckQuery({
    entityId: applicantId,
  })

  const transactionCheckRecord = useMemo(() => {
    const transactionalRisk = riskCheckData?.transactionalRisk
    return {
      AML: {
        count: RiskTxnCount({ risks: transactionalRisk, issue: 'AML' }),
        issue: RiskHasTxnIssue({ risks: transactionalRisk, type: 'TXN_AML' }),
      },
      Fraud: {
        count: RiskTxnCount({ risks: transactionalRisk, issue: 'Fraud' }),
        issue: RiskHasTxnIssue({
          risks: transactionalRisk,
          type: 'TXN_FRAUD',
        }),
      },
      Customer: {
        count: RiskTxnCount({ risks: transactionalRisk, issue: 'Customer' }),
        issue: RiskHasTxnIssue({
          risks: transactionalRisk,
          type: 'TXN_CUSTOMER',
        }),
      },
    }
  }, [riskCheckData?.transactionalRisk])

  const transactionCheckState = useMemo(() => {
    const txnState = {
      AML: 'unchecked',
      FRAUD: 'unchecked',
      CUSTOMER: 'unchecked',
    }

    const { AML, Fraud, Customer } = transactionCheckRecord
    if (AML.count) txnState.AML = AML.issue ? 'fail' : 'pass'
    if (Fraud.count) txnState.FRAUD = Fraud.issue ? 'fail' : 'pass'
    if (Customer.count) txnState.CUSTOMER = Customer.issue ? 'fail' : 'pass'

    return txnState
  }, [transactionCheckRecord])

  useEffect(() => {
    if (isError) onError()
  }, [isError, onError])

  return { transactionCheckRecord, transactionCheckState, isLoading }
}
