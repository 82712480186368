import React from 'react'

import { FrankieIcon } from 'frankify/src'

import { useAnalyticsDashboardTranslation } from '../../locale/analytics-dashboard.en'

export function AnalyticsDashboardError() {
  const { t } = useAnalyticsDashboardTranslation()
  return (
    <div className="flex min-h-[calc(100vh-400px)] flex-col h-full justify-center gap-4 items-center">
      <FrankieIcon
        size="xl"
        name="mdiInformationOutline"
        className="text-primary-200"
      />
      <div className="text-lg font-bold text-tertiary-grey-800">
        {t('report.issue')}
      </div>
      <div className="text-tertiary-grey-800 whitespace-pre-line text-center">
        {t('report.issueDescription')}
      </div>
    </div>
  )
}
