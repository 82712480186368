import {
  RESET_PASSWORD_KEY,
  RESET_PASSWORD_SENT_KEY,
} from '../model/reset-password.model'

export const ResetPasswordQa = {
  header: 'reset-password-header',
  emailInput: 'reset-password-email-input',
  backToLoginButton: 'reset-password-back-to-login-button',
  resetPasswordRequestButton: 'reset-password-request-button',
  resetPasswordErrorMessage: 'reset-password-error-message',
} satisfies Record<string, `${typeof RESET_PASSWORD_KEY}-${string}`>

export const ResetPasswordSentQa = {
  header: 'reset-password-link-sent-header',
  backToLoginButton: 'reset-password-link-sent-back-to-login-button',
} satisfies Record<string, `${typeof RESET_PASSWORD_SENT_KEY}-${string}`>
