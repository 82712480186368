import React, { useEffect, useMemo, useState } from 'react'

import MenuItem from '@mui/material/MenuItem'
import { Controller, UseFormReturn } from 'react-hook-form'

import { FrankieButton, FrankieIcon, FrankiePopover } from 'frankify/src'

import { INDIVIDUAL_PROFILE_KEY } from 'features/individual-profile/individual-profile.keys'
import { individualProfileEn } from 'features/individual-profile/locale/new-profile.en'
import {
  IIndividualProfileInputs,
  IndividualProfileInputTypes,
} from 'features/individual-profile/model/form.model'
import { documentSubFormQa } from 'features/individual-profile/qa/individual-profile.qa'

import { DocumentIdTypes, KycDocumentCategoryTypes } from 'entities/document'
import { PageData, useSessionQuery } from 'entities/session'

import { useI18n } from 'shared/i18n'
import { Nullable } from 'shared/typescript'

export type Props = {
  form: UseFormReturn<IIndividualProfileInputs>
  idx: number
  className?: string
  disabled?: boolean
}

export function DocumentCategory({ form, idx, className }: Props) {
  const t = useI18n([INDIVIDUAL_PROFILE_KEY], { keys: individualProfileEn })

  const { data } = useSessionQuery()

  const { control, watch, setValue } = form

  const [open, setOpen] = useState<boolean>(false)

  const isManualKyc = watch(IndividualProfileInputTypes.KycMethod) === 'manual'

  const docCategoryOption = useMemo(
    () => [
      {
        label: t('manualKyc.category.primary'),
        value: KycDocumentCategoryTypes.PRIMARY_PHOTOGRAPHIC,
      },
      {
        label: t('manualKyc.category.nonPrimary'),
        value: KycDocumentCategoryTypes.PRIMARY_NON_PHOTOGRAPHIC,
      },
      {
        label: t('manualKyc.category.secondary'),
        value: KycDocumentCategoryTypes.SECONDARY,
      },
    ],

    [t],
  )

  const country = watch(`document.${idx}.country`)
  const idType = watch(`document.${idx}.IdType`)

  const getDocumentCategoryType = (
    mkycOptions: PageData['mkyc_rules'],
    country: string,
    idType: string,
  ): Nullable<string> => {
    if (idType === DocumentIdTypes.OTHER) return null

    const { countries } = mkycOptions || {}
    if (!countries) return null

    if (!(country in countries)) country = 'default'
    const optionsForCountry = countries[country]
    const categories = Object.entries(optionsForCountry)

    let category = null
    categories.forEach(([categoryKey, categoryData]) => {
      const { types } = categoryData
      if (types?.includes(idType as DocumentIdTypes)) category = categoryKey
    })
    return category
  }

  const categoryForThisField = useMemo(
    () => getDocumentCategoryType(data?.mkyc_rules, country, idType),
    [country, idType, data],
  )

  useEffect(() => {
    if (categoryForThisField)
      setValue(
        `${IndividualProfileInputTypes.Document}.${idx}.${IndividualProfileInputTypes.DocumentCategory}`,
        categoryForThisField as never,
      )
  }, [categoryForThisField, idx, setValue])

  const textColor: Record<KycDocumentCategoryTypes, string> = useMemo(
    () => ({
      [KycDocumentCategoryTypes.PRIMARY_NON_PHOTOGRAPHIC]:
        'text-tertiary-amber-500',
      [KycDocumentCategoryTypes.PRIMARY_PHOTOGRAPHIC]: 'text-tertiary-aqua-500',
      [KycDocumentCategoryTypes.SECONDARY]: 'text-tertiary-purple-500',
    }),
    [],
  )

  return (
    <div className={className}>
      {isManualKyc && (
        <Controller
          data-qa={documentSubFormQa.controller}
          render={({ field: { onChange, value } }) => (
            <div className="flex flex-col gap-1">
              <div className="pb-[2px] text-tertiary-grey-800 text-sm font-medium">
                {t('documentForm.documentCategory')}
              </div>

              <FrankiePopover
                open={open}
                popoverRest={{ placement: 'bottom-start' }}
                onOpenChange={setOpen}
                trigger={
                  <FrankieButton
                    noStyles
                    className={`flex h-[40px] items-center border border-solid border-tertiary-grey-300 bg-mono-white text-tertiary-grey-800 rounded-sm ${
                      categoryForThisField
                        ? 'bg-tertiary-grey-100 cursor-not-allowed'
                        : ''
                    }`}
                    onClick={() =>
                      !categoryForThisField && setOpen(prev => !prev)
                    }
                    startIcon={{
                      name: 'mdiCircleMedium',
                      className: `text-mono-white ${
                        textColor[value as KycDocumentCategoryTypes]
                      } p-1`,
                      size: 'sm',
                    }}
                    endIcon={{
                      name: open ? 'mdiChevronUp' : 'mdiChevronDown',
                      className: 'text-tertiary-grey-400 pe-2',
                      size: 'xs',
                    }}
                    data-qa={documentSubFormQa.btn}
                  >
                    <span className="flex-grow text-left whitespace-nowrap">
                      {
                        docCategoryOption.find(opt => opt.value === value)
                          ?.label
                      }
                    </span>
                  </FrankieButton>
                }
                data-qa={documentSubFormQa.popOver}
              >
                <div className="bg-mono-white shadow-md rounded-sm">
                  {docCategoryOption.map(item => (
                    <MenuItem
                      key={item.value}
                      onClick={() => {
                        onChange(item.value)
                        setOpen(false)
                      }}
                    >
                      <FrankieIcon
                        name="mdiCircleMedium"
                        className={textColor[item.value]}
                        size="sm"
                      />
                      <span className="ps-2">{item.label}</span>
                    </MenuItem>
                  ))}
                </div>
              </FrankiePopover>
            </div>
          )}
          shouldUnregister
          control={control}
          name={`${IndividualProfileInputTypes.Document}.${idx}.${IndividualProfileInputTypes.DocumentCategory}`}
        />
      )}
    </div>
  )
}
