import { IRole, PermissionId } from 'entities/role'

import { Nullable } from 'shared/typescript'

export const ROLE_NAME_MAX_LENGTH = 50
export const ROLE_DESCRIPTION_MAX_LENGTH = 220

export interface IRoleConfig {
  roleName: string
  description: Nullable<string>
  action_list_ids: PermissionId[]
}

/**
 * In DB permissions field is called actionLists.
 * Some endpoints return not serialized object.
 */
export type IRoleNotSerialized = Omit<IRole, 'permissions' | 'createdAt'> & {
  actionLists: IRole['permissions']
  createdAt: string
}

export function serializeRole(notSerialized: IRoleNotSerialized): IRole {
  return {
    ...notSerialized,
    permissions: notSerialized.actionLists,
    createdAt: { date: notSerialized.createdAt },
  }
}
