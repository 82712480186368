import { NOTIFICATION_WIDGET_KEY } from '../suprsend-notification.key'

export const notificationWidgetQa = {
  bell: `${NOTIFICATION_WIDGET_KEY}-bell`,
  notificationItemIcon: `${NOTIFICATION_WIDGET_KEY}-item-icon`,
  notificationItemText: `${NOTIFICATION_WIDGET_KEY}-item-text`,
  notificationItem: `${NOTIFICATION_WIDGET_KEY}-item`,
  markAsRead: `${NOTIFICATION_WIDGET_KEY}-mark-as-read`,
  markAsUnread: `${NOTIFICATION_WIDGET_KEY}-mark-as-unread`,
  unreadLogo: `${NOTIFICATION_WIDGET_KEY}-unread-logo`,
} satisfies Record<string, `${typeof NOTIFICATION_WIDGET_KEY}-${string}`>
