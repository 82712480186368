import { useMemo } from 'react'

import { useQuery } from '@tanstack/react-query'

import {
  ApplicantId,
  useApplicantDataQuery,
  useApplicantState,
} from 'entities/applicant'

import { ErrorResponse } from 'shared/http'

import { applicantFraudCheckApi } from '../../api/applicant-fraud-check'
import {
  FraudCheckResponse,
  FraudListData,
  fraudMatchType,
  fraudReportedBy,
  fraudSubject,
  matchList,
} from '../../model/applicant-fraud-check.model'

type Args = {
  applicantId: ApplicantId
}

export const ApplicantFraudCheckQueryKey = 'applicant-fraud-check'

export const useApplicantFraudCheckState = ({ applicantId }: Args) =>
  useQuery<FraudCheckResponse, ErrorResponse>({
    enabled: !!applicantId,
    queryKey: [ApplicantFraudCheckQueryKey, applicantId],
    queryFn: async () => {
      const { data } = await applicantFraudCheckApi.getFrauds(applicantId)
      return data
    },
  })

export const useApplicantFraudCheckTableData = ({ applicantId }: Args) => {
  const { data: fraudsChecks, isFetching } = useApplicantFraudCheckState({
    applicantId,
  })

  const rows: FraudListData[] = useMemo(
    () =>
      fraudsChecks?.matches?.map((match, index) => {
        const data: FraudListData = {
          // matchListId from the data is not unique but id in data grid needs to be unique
          // must split the string where id is used
          id: `${match.matchListId}__##__${index}`,
          checkId: match.checkId,
          reportedBy: fraudReportedBy(match),
          date: match.dateOfFraud,
          matchStatus: match.status,
          matchList: matchList(match),
          fraudSubject: fraudSubject(match),
          fraudType: match.fraudType,
          matchType: fraudMatchType(match),
          extraDetails: match.extraDetails,
          matchId: match.matchListId,
        }
        return data
      }) ?? [],
    [fraudsChecks],
  )

  return {
    rows,
    isFetching,
  }
}

export const useApplicantFraudCheckSummary = ({ applicantId }: Args) => {
  const { data: applicantData } = useApplicantDataQuery({ applicantId })
  const { address } = useApplicantState({ applicantId })

  const result = useMemo(() => {
    if (applicantData) {
      const { applicantDetails, documents, businessInfo } = applicantData
      const phoneNumber = applicantDetails.phoneNumber.idNumber
      const currentAddress = applicantDetails.addresses?.find(
        address => address.addressType === 'RESIDENTIAL1',
      )
      const applicantDetail = {
        search: '',
        phone: phoneNumber,
        address: currentAddress?.longForm || '',
      }
      let businessDetail = null
      if (businessInfo) {
        businessDetail = {
          name: businessInfo.businessName,
          phone: 'N/A',
          address: typeof address !== 'string' ? address.longForm : 'N/A',
          abn: businessInfo.ABNNumber,
        }
      }

      return { businessDetail, applicantDetail, documents }
    }

    return null
  }, [applicantData, address])

  return result
}
