import { useQuery } from '@tanstack/react-query'

import { PageData } from 'entities/session'

import { ssoApi } from '../../api/sso.api'

export const SSOTokenLoginQuery = 'sso-token-login-query'

type Args = {
  token?: string | null
}

type QueryResponse = PageData & { token: string }

export const useSSSTokenLoginQuery = ({ token }: Args) => {
  const query = useQuery<QueryResponse>({
    enabled: !!token,
    queryKey: [SSOTokenLoginQuery, token],
    queryFn: async () => {
      const { data, headers } = await ssoApi.checkTokenValidity(token!)

      const headersFromClient = headers

      // This function is used to find the token from the headers
      const headersOrNull = (headers: Array<string>): string | null => {
        let foundToken: string | null = null
        headers.forEach(datum => {
          if (foundToken) return
          if (headersFromClient[datum]) {
            foundToken = headersFromClient[datum] as string
          }
        })
        return foundToken
      }

      const accessToken = headersOrNull([
        'authorisation',
        'authorization',
        'auth',
        'token', // This is the one that is working
      ])

      return {
        ...data,
        organization: data.organisation,
        token: accessToken ?? '',
      }
    },
  })

  return query
}
