import { useMemo } from 'react'

import {
  AddressStatusEnum,
  AddressTypeEnum,
  IDocsToBeDisplayedType,
  useGetWorkflowEventsData,
  ILabelAndSources,
  WorkflowStepResultEnum,
  extractLongForm,
  useEntityDataQuery,
  useEntityLabelDataState,
  WorkflowStatusKeysTypes,
  getWorkflowStatusKey,
  ServiceProfileState,
  ProcessResultManualStatusEnumAML,
  ProcessResultManualStatusEnumKYC,
  ProcessResultSystemStatusEnum,
} from 'entities/entity'

import { useI18n } from 'shared/i18n'
import { TrackingEventsTypes } from 'shared/tracking'

import { workflowEventsEn } from '../locale/workflow-events.en'
import {
  formatDate,
  getSourceMatch,
} from '../model/applicant-workflow-events.model'
import {
  getIconConfigAml,
  IconConfig,
} from '../ui/entity-verification-helper/entity-verification-helper'
import { WORKFLOW_EVENTS_KEY } from '../workflow-events.key'

export type Args = {
  entityId: string
  docR1?: boolean
}

export const useGetWorkFlowDataWithSources = ({
  entityId,
  docR1 = false,
}: Args) => {
  const { data, isLoading } = useGetWorkflowEventsData({ entityId })
  const { data: entity, isFetching } = useEntityDataQuery(entityId, 'base64')
  const {
    documentWithLabelData: documentLabelDataF2R2,
    documentWithLabelDataR1,
  } = useEntityLabelDataState({ entityId })
  const t = useI18n(WORKFLOW_EVENTS_KEY, { keys: workflowEventsEn })

  // some places need docs to have R1 data despite being in F2R2
  const documentWithLabelData = docR1
    ? documentWithLabelDataR1
    : documentLabelDataF2R2

  if (!data || !entity) {
    return {
      data: undefined,
      isLoading: isLoading || isFetching,
    }
  }

  const workflowEventResult = data.workflowSummaries?.at(0)

  const isArchived =
    entity.serviceProfiles?.at(0)?.state === ServiceProfileState.ARCHIVED

  const kycStep =
    workflowEventResult?.workflowResultData?.workflowStepResults?.find(
      i => i.stepName === 'KYC',
    )

  const isManuallyVerified =
    kycStep?.result === WorkflowStepResultEnum.MATCH &&
    kycStep.processResults?.every(
      i => i.manualStatus === ProcessResultManualStatusEnumKYC.PASS,
    )

  const isNotUnchecked =
    workflowEventResult?.status &&
    getWorkflowStatusKey(workflowEventResult) !==
      WorkflowStatusKeysTypes.UNCHECKED

  function fullName() {
    const { displayName, givenName, middleName, familyName } =
      entity?.individual?.name ?? {}

    return (
      displayName ??
      `${givenName ?? ''} ${middleName ?? ''} ${familyName ?? ''}`.trim()
    )
  }

  const profileInfo: ILabelAndSources[] = [
    {
      label: t('personalInfo.name'),
      value: fullName(),
      sources: getSourceMatch({
        objectType: 'NAME',
        workflowSummaries: data.workflowSummaries,
      }),
    },
    {
      label: t('personalInfo.dob'),
      value:
        entity.individual?.dateOfBirth?.normalized &&
        formatDate({
          dateString: entity.individual.dateOfBirth.normalized,
        }),
      sources: getSourceMatch({
        objectType: 'DATE_OF_BIRTH',
        workflowSummaries: data.workflowSummaries,
      }),
    },
    {
      label: t('personalInfo.address'),
      value: extractLongForm(
        entity.individual?.addresses?.find(
          address =>
            address.type === AddressTypeEnum.RESIDENTIAL &&
            address.status === AddressStatusEnum.CURRENT,
        ),
      ),
      sources: getSourceMatch({
        objectType: 'ADDRESS',
        workflowSummaries: data.workflowSummaries,
      }),
    },
  ]

  const docsToBeDisplayed: IDocsToBeDisplayedType[] =
    documentWithLabelData?.map(doc => ({
      ...doc,
      label: [
        {
          ...doc.label[0],
          sources: getSourceMatch({
            objectType: 'DOCUMENT',
            idType: doc.type,
            workflowSummaries: data.workflowSummaries,
          }),
        },
        ...doc.label.slice(1),
      ],
    })) || []

  return {
    docsToBeDisplayed,
    isLoading,
    profileInfo,
    isNotUnchecked,
    isManuallyVerified,
    isArchived,
  }
}

type EventName = 'KYC' | 'AML' | 'IDV'
const EVENT_WHITELIST = ['KYC', 'AML', 'IDV']

export const useOrderWithFailedAndPassed = ({ entityId }: Args) => {
  const { data } = useGetWorkflowEventsData({ entityId })

  const t = useI18n(WORKFLOW_EVENTS_KEY, { keys: workflowEventsEn })

  const workflow = data?.workflowSummaries?.at(0)

  const order = workflow?.steps?.order

  const failed = workflow?.steps?.failed

  const passed = workflow?.steps?.passed

  const filterEvents = useMemo(
    () =>
      order?.filter(event => EVENT_WHITELIST.includes(event as EventName)) ??
      [],
    [order],
  ) as EventName[]

  const eventStepper = useMemo(
    () =>
      filterEvents.map(eventName => {
        const trackingEvent = {
          AML: TrackingEventsTypes.WorkflowEventSummaryAMLClickIndividual,
          KYC: TrackingEventsTypes.WorkflowEventSummaryEntityVerificationClickIndividual,
          IDV: TrackingEventsTypes.WorkflowEventSummaryIDVClickIndividual,
          OCR: TrackingEventsTypes.WorkflowEventSummaryOCRClickIndividual,
        }

        const event = trackingEvent[eventName]

        if (eventName === 'AML') {
          return {
            config: getIconConfigAml(
              workflow?.workflowResultData?.workflowStepResults
                ?.find(step => step.stepName === 'AML')
                ?.processResults?.filter(
                  i =>
                    i.result === 'HIT' &&
                    i.systemStatus === ProcessResultSystemStatusEnum.VALID,
                )
                ?.map(result => result.manualStatus) as
                | (ProcessResultManualStatusEnumAML | undefined)[]
                | undefined,
            ),
            text: t(`entityStatusText.${eventName}`),
            eventName,
            track: event,
          }
        }
        if (failed?.find(item => item === eventName)) {
          return {
            config: IconConfig({ type: WorkflowStepResultEnum.FAIL }),
            text: t(`entityStatusText.${eventName}`),
            eventName,
            track: event,
          }
        }
        if (passed?.find(item => item === eventName)) {
          return {
            config: IconConfig({ type: WorkflowStepResultEnum.PASS }),
            text: t(`entityStatusText.${eventName}`),
            eventName,
            track: event,
          }
        }
        return {
          config: IconConfig({ type: WorkflowStepResultEnum.UNCHECKED }),
          text: t(`entityStatusText.${eventName}`),
          eventName,
          track: event,
        }
      }),
    [t, failed, passed, filterEvents, workflow],
  )

  return {
    order,
    failed,
    passed,
    filterEvents,
    eventStepper,
  }
}
