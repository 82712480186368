import React, { useMemo } from 'react'

import { GridColDef } from '@mui/x-data-grid-pro'

import { ApplicantId } from 'entities/applicant'
import { useHasFeatureFlag } from 'entities/session'

import { useI18n } from 'shared/i18n'
import { DefaultDataGrid } from 'shared/tables-common'
import { Nullable } from 'shared/typescript'

import { IKYB_AML_SCREENING_KEY } from '../../ikyb-aml-screening.key'
import { iKybAmlScreeningEn } from '../../locale/ikyb-aml-screening.en'
import {
  AmlCheckResult,
  amlScreeningDateFormat,
  amlSearchResultDashValue,
  fuzzinessPercentage,
} from '../../model/ikyb-aml-screening.model'
import { iKybAmlInfoHeaderQa } from '../../qa/ikyb-aml-screening.qa'
import { useIKybAmlScreeningQuery } from '../../state/ikyb-aml-screening-query/ikyb-aml-screening.query'
import { MatchStrengthView } from '../ikyb-aml-screening-data-grid-helper/ikyb-aml-screening-data-grid-helper'

type Props = {
  applicantId: ApplicantId
  className?: string
  testId?: { container?: string }
  matchIdx?: number
}

type DataFields = AmlCheckResult & {
  type: string
  matchStrength?: Nullable<number>
}

// eslint-disable-next-line complexity
export function IKybAmlInfoHeader({
  applicantId,
  matchIdx,
  className = '',
  testId,
}: Props) {
  const t = useI18n(IKYB_AML_SCREENING_KEY, {
    keys: iKybAmlScreeningEn,
  })

  const { showAMLMatchStrength } = useHasFeatureFlag({
    showAMLMatchStrength: 'showAMLMatchStrength',
  })

  const { data } = useIKybAmlScreeningQuery({ applicantId })
  const isComparison = matchIdx !== undefined

  const columns = useMemo(() => {
    const dataColumns: (GridColDef<DataFields> & {
      field: keyof DataFields
    })[] = []

    if (isComparison) {
      dataColumns.push({
        field: 'type',
        headerName: t('field.details'),
        valueFormatter: amlSearchResultDashValue,
      })
    }

    dataColumns.push(
      {
        field: 'name',
        headerName: t('field.name'),
        valueFormatter: amlSearchResultDashValue,
        width: isComparison ? undefined : 175,
      },
      {
        field: 'countries',
        headerName: t('field.countries'),
        valueFormatter: amlSearchResultDashValue,
      },
    )

    if (!isComparison) {
      dataColumns.push(
        {
          field: 'searchDate',
          headerName: t('field.searchDate'),
          valueFormatter: ({ value }) =>
            amlScreeningDateFormat(value as string, '-'),
        },
        {
          field: 'searchFuzziness',
          headerName: t('field.searchFuzziness'),
          valueFormatter: ({ value }) => fuzzinessPercentage(value as number),
        },
        {
          field: 'vendorId',
          headerName: t('field.vendorCaseId'),
          valueFormatter: amlSearchResultDashValue,
        },
      )
    }

    if (isComparison && showAMLMatchStrength) {
      dataColumns.push({
        field: 'matchStrength',
        headerName: t('field.matchStrength'),
        renderCell: ({ row }) => (
          <MatchStrengthView matchPercentage={row.matchStrength as number} />
        ),
      })
    }

    return dataColumns
  }, [isComparison, showAMLMatchStrength, t])

  const rowData = useMemo(() => {
    const searchData = {
      ...data,
      type: t('field.search'),
      matchStrength: null, // Not to be shown in the grid
    } as DataFields

    const returnedData = {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      ...data?.matches?.[matchIdx ?? 0],
      type: t('field.returned'),
    } as DataFields

    if (isComparison) {
      return [searchData, returnedData]
    }

    return [searchData]
  }, [data, isComparison, matchIdx, t])

  return (
    <div className={className} data-qa={testId?.container}>
      <DefaultDataGrid<DataFields>
        testId={{ container: iKybAmlInfoHeaderQa.grid }}
        getRowId={row => row.name ?? 'searchRow'}
        columns={columns}
        rows={rowData}
      />
    </div>
  )
}
