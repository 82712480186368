import React, { useEffect, useRef, useState } from 'react'

import { ApplicantId } from 'entities/applicant'

import { useI18n } from 'shared/i18n'

import { APPLICANT_GENERAL_INFORMATION_KEY } from '../../applicant-general-information.key'
import { applicantGeneralInformationEn } from '../../locale/applicant-general-information.en'
import { applicantBusinessOverviewQa } from '../../qa/applicant-general-information.qa'
import { useApplicantBusinessReport } from '../../state/applicant-business-state/applicant-business-state'
import { useApplicantCheckSummaryState } from '../../state/applicant-check-summary-state/applicant-check-summary-state'
import { ApplicantActionMenu } from '../applicant-action-menu/applicant-action-menu'
import { ApplicantBusinessCheckRun } from '../applicant-business-check-run/applicant-business-check-run'
import { ApplicantBusinessCompletedChecks } from '../applicant-business-completed-checks/applicant-business-completed-checks'
import { ApplicantBusinessReportRun } from '../applicant-business-report-run/applicant-business-report-run'
import { ApplicantBusinessSummary } from '../applicant-business-summary/applicant-business-summary'
import { ApplicantTrustAnalyserBanner } from '../applicant-business-trust-analyser-banner/applicant-business-trust-analyser-banner'
import { ApplicantStatusBanner } from '../applicant-status-banner/applicant-status-banner'

type Props = {
  applicantId: ApplicantId
}

export function ApplicantBusinessOverview({ applicantId }: Props) {
  const t = useI18n([APPLICANT_GENERAL_INFORMATION_KEY], {
    keys: applicantGeneralInformationEn,
  })

  const ref = useRef<HTMLDivElement>(null)

  const [tempMessage, setTempMessage] = useState<string | false>(false)

  const { pendingReport, errorReport } = useApplicantBusinessReport({
    applicantId,
  })

  const { inactiveProfileAccount } = useApplicantCheckSummaryState({
    applicantId,
  })

  useEffect(() => {
    if (tempMessage) ref.current?.scrollIntoView({ behavior: 'smooth' })
  }, [tempMessage])

  /**
   * Setting message report states
   */
  useEffect(() => {
    const { kyb: kybPending, ubo: uboPending, cr: crPending } = pendingReport
    const { kyb: kybError, ubo: uboError, cr: crError } = errorReport

    // Sequentially check for pending and error states
    switch (true) {
      case kybPending:
        setTempMessage(
          t('success.reportWillBeGeneratedShortly', {
            reportName: t('checks.title.kybReport'),
          }),
        )
        break
      case uboPending:
        setTempMessage(
          t('success.reportWillBeGeneratedShortly', {
            reportName: t('checks.title.uboReport'),
          }),
        )
        break
      case crPending:
        setTempMessage(
          t('success.reportWillBeGeneratedShortly', {
            reportName: t('checks.title.crReport'),
          }),
        )
        break
      case kybError || uboError || crError:
        setTempMessage(t('error.generateBusinessReport'))
        break
      default:
        setTempMessage(false)
    }
  }, [errorReport, pendingReport, t])

  return (
    <div
      ref={ref}
      className="flex flex-col gap-8 p-8"
      data-qa={applicantBusinessOverviewQa.component}
    >
      {tempMessage && (
        <div className="text-tertiary-grey-800 font-bold bg-tertiary-yellow-50 p-3 rounded-sm">
          {tempMessage}
        </div>
      )}

      <ApplicantTrustAnalyserBanner applicantId={applicantId} />
      <div
        className="flex justify-between"
        data-qa={applicantBusinessOverviewQa.actionMenu}
      >
        <div className="text-lg">{t('businessInfo')}</div>
        {!inactiveProfileAccount && (
          <ApplicantActionMenu applicantId={applicantId} />
        )}
      </div>

      <ApplicantStatusBanner
        applicantId={applicantId}
        className="rounded-sm overflow-hidden"
      />

      <ApplicantBusinessSummary applicantId={applicantId} />

      <ApplicantBusinessCompletedChecks applicantId={applicantId} />

      <ApplicantBusinessCheckRun applicantId={applicantId} />

      <ApplicantBusinessReportRun applicantId={applicantId} />
    </div>
  )
}
