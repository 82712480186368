import React, { useEffect, useRef, useState } from 'react'

import classNames from 'classnames'
import { Trans } from 'react-i18next'

import { FrankieButton, FrankieDivider } from 'frankify/src'

import { externalLinks } from 'shared/external-links'
import { useI18n } from 'shared/i18n'
import { trackingManager, TrackingEventsTypes } from 'shared/tracking'

import { GLOBAL_SEARCH_KEY } from '../../locale/global-search.en'
import {
  IGlobalSearchMemo,
  searchByI18nMap,
  SearchByTypes,
} from '../../model/global-search.model'
import { globalSearchQa } from '../../qa/global-search.qa'

const SCROLLING_STEP = 100
const SCROLLING_THRESHOLD = 0

function SearchByButton({
  isActive,
  onClick,
  text,
  testId,
}: {
  isActive: boolean
  onClick: () => void
  text: string
  testId: { button: string }
}) {
  return (
    <FrankieButton
      className={classNames('shrink-0', {
        'bg-primary-50 hover:!bg-primary-50 border-transparent cursor-default':
          isActive,
      })}
      onClick={onClick}
      size="xs"
      intent="darkOutline"
      testId={testId}
    >
      {text}
    </FrankieButton>
  )
}

type Props = {
  searchBy: SearchByTypes
  history: IGlobalSearchMemo[]
  onChangeSearchBy: (searchBy: SearchByTypes) => void
  onSelectGlobalSearchMemo: (memo: IGlobalSearchMemo) => void
  isSearchProfiles?: boolean
}

export function GlobalSearchControlPanel({
  history,
  searchBy,
  onChangeSearchBy,
  onSelectGlobalSearchMemo,
  isSearchProfiles = false,
}: Props) {
  const t = useI18n([GLOBAL_SEARCH_KEY])
  const container = useRef<HTMLDivElement>(null)
  const [scrollLeft, setScrollLeft] = useState(0)
  const [scrollLeftMax, setScrollLeftMax] = useState(0)

  const showScrollLeftCta: boolean = scrollLeft > SCROLLING_THRESHOLD
  const showScrollRightCta: boolean =
    !!scrollLeftMax && scrollLeft < scrollLeftMax - SCROLLING_THRESHOLD

  useEffect(() => {
    trackingManager.track(TrackingEventsTypes.GlobalSearchShow)
  }, [])

  useEffect(() => {
    let cleanupCopy: HTMLDivElement | null = null
    function handleScroll(event: Event) {
      if (
        event.target &&
        'scrollLeft' in event.target &&
        typeof event.target.scrollLeft === 'number'
      ) {
        setScrollLeft(event.target.scrollLeft)
      }
    }

    if (container.current) {
      container.current.addEventListener('scroll', handleScroll, {
        passive: true,
      })
      cleanupCopy = container.current
      setScrollLeftMax(
        container.current.scrollWidth - container.current.clientWidth,
      )
    }
    return () => {
      if (cleanupCopy) {
        cleanupCopy.removeEventListener('scroll', handleScroll)
      }
    }
  }, [container])

  const handleScrollManually = (direction: 'right' | 'left') => () => {
    if (container.current) {
      if (direction === 'right') {
        container.current.scrollLeft += SCROLLING_STEP
        setScrollLeft(container.current.scrollLeft)
      } else {
        container.current.scrollLeft -= SCROLLING_STEP
        setScrollLeft(container.current.scrollLeft)
      }
    }
  }

  const handleChangeSearchBy = (newSearchBy: SearchByTypes) => () =>
    onChangeSearchBy(newSearchBy)

  const handleSelectGlobalSearchMemo = (memo: IGlobalSearchMemo) => () => {
    trackingManager.track(TrackingEventsTypes.GlobalSearchRecentSearch)
    onSelectGlobalSearchMemo(memo)
  }

  const handleGiveFeedback = () => {
    trackingManager.track(TrackingEventsTypes.GlobalSearchFeedback)
  }

  const handleBadResultDocClick = () => {
    trackingManager.track(TrackingEventsTypes.GlobalSearchDocs)
  }
  return (
    <div className="">
      <div className="text-tertiary-grey-500 text-xs font-medium mb-2 tablet:px-4">
        {t('searchByTitle')}
      </div>
      <div
        className={classNames('relative mb-6', {
          'pl-6 laptop:pl-4': showScrollLeftCta,
          'tablet:pl-4': !showScrollLeftCta,
          'pr-6 laptop:pr-4': showScrollRightCta,
          'tablet:pr-4': !showScrollRightCta,
        })}
      >
        {showScrollLeftCta && (
          <FrankieButton
            noStyles
            onClick={handleScrollManually('left')}
            intent="subtle"
            size="xs"
            startIcon={{ size: 'sm', name: 'mdiChevronLeft' }}
            className="absolute flex items-center -top-1 left-0 laptop:hidden"
          >
            <div className="bg-gradient-to-r border-l from-mono-white from-10% w-6 h-[42px] border-solid border-tertiary-grey-200" />
          </FrankieButton>
        )}
        {showScrollRightCta && (
          <FrankieButton
            noStyles
            onClick={handleScrollManually('right')}
            intent="subtle"
            size="xs"
            endIcon={{ size: 'sm', name: 'mdiChevronRight' }}
            className="absolute flex items-center -top-1 right-0 laptop:hidden"
          >
            <div className="bg-gradient-to-l border-r from-mono-white from-10% w-6 h-[42px] border-solid border-tertiary-grey-200" />
          </FrankieButton>
        )}
        <div
          ref={container}
          className="flex flex-row items-center overflow-x-auto scrollbar-none gap-3"
        >
          <SearchByButton
            isActive={searchBy === SearchByTypes.IndividualName}
            onClick={handleChangeSearchBy(SearchByTypes.IndividualName)}
            text={t('searchByIndividualName')}
            testId={{ button: globalSearchQa.cta.searchBy.IndividualName }}
          />
          {!isSearchProfiles && (
            <SearchByButton
              isActive={searchBy === SearchByTypes.OrganisationName}
              onClick={handleChangeSearchBy(SearchByTypes.OrganisationName)}
              text={t('searchByOrganisationName')}
              testId={{ button: globalSearchQa.cta.searchBy.OrganisationName }}
            />
          )}
          <SearchByButton
            isActive={searchBy === SearchByTypes.CustomerId}
            onClick={handleChangeSearchBy(SearchByTypes.CustomerId)}
            text={t(
              isSearchProfiles ? 'searchByCustomerIdF2' : 'searchByCustomerId',
            )}
            testId={{ button: globalSearchQa.cta.searchBy.CustomerId }}
          />
          <SearchByButton
            isActive={searchBy === SearchByTypes.FrankieId}
            onClick={handleChangeSearchBy(SearchByTypes.FrankieId)}
            text={t(
              isSearchProfiles ? 'searchByEntityId' : 'searchByFrankieId',
            )}
            testId={{ button: globalSearchQa.cta.searchBy.FrankieId }}
          />
          {!isSearchProfiles && (
            <SearchByButton
              isActive={searchBy === SearchByTypes.AbnOrAcn}
              onClick={handleChangeSearchBy(SearchByTypes.AbnOrAcn)}
              text={t('searchByAbnOrAcn')}
              testId={{ button: globalSearchQa.cta.searchBy.AbnOrAcn }}
            />
          )}
        </div>
      </div>
      {history.length > 0 && (
        <div>
          <div className="tablet:px-4 text-tertiary-grey-500 text-xs font-medium mb-2">
            {t('recentSearchesTitle')}
          </div>
          {history.map(memo => (
            <FrankieButton
              noStyles
              key={`${memo.searchBy}${memo.value}`}
              startIcon={{ size: 'xs', name: 'mdiMagnify' }}
              onClick={handleSelectGlobalSearchMemo(memo)}
              className="tablet:px-4 flex flex-initial flex-row gap-3 items-center text-sm font-normal py-1.5 tablet:pr-4 text-tertiary-grey-600 w-full hover:bg-tertiary-grey-100"
              testId={{ button: globalSearchQa.cta.recentSearch }}
            >
              <div className="truncate">
                {t(searchByI18nMap(isSearchProfiles)[memo.searchBy])}:{' '}
                {memo.value}
              </div>
            </FrankieButton>
          ))}
        </div>
      )}
      <FrankieDivider className="mb-3 tablet:px-4" />
      <div className="flex flex-row tablet:justify-end tablet:px-4 text-xs font-normal text-tertiary-grey-600">
        <div>
          <span>{t('badResultsTitle')}</span>
          &nbsp;
          <Trans
            i18nKey="global-search:badResultsOr"
            feedback={t('badResultsFeedback')}
            docs={t('badResultsDocs')}
          >
            <FrankieButton
              noStyles
              className="inline text-primary-800"
              testId={{ button: globalSearchQa.cta.feedback }}
              onClick={handleGiveFeedback}
            >
              {/* @ts-ignore */}
              {{ feedback: t('badResultsFeedback') }}
            </FrankieButton>
            &nbsp;or&nbsp;
            <a
              rel="noreferrer"
              target="_blank"
              onClick={handleBadResultDocClick}
              href={externalLinks.globalSearchDocs}
              className="text-primary-800"
              data-qa={globalSearchQa.cta.docs}
            >
              {/* @ts-ignore */}
              {{ docs: t('badResultsDocs') }}
            </a>
          </Trans>
        </div>
      </div>
    </div>
  )
}
