import React, { useState } from 'react'

import { ListItemIcon, Menu, MenuItem } from '@mui/material'

import { FrankieButton, FrankieIcon, FrankieIconName } from 'frankify/src'
import { FrankieIconSize } from 'frankify/src/components/icon'

import { individualProfileQa } from 'features/individual-profile/qa/individual-profile.qa'

type FrankieDropdownButtonOptions = {
  label: string
  value: string
  iconProps?: FrankieDropdownButtonIcon
  isActive?: boolean
}

type FrankieDropdownButtonIcon = {
  name: FrankieIconName
  className?: string
  size?: FrankieIconSize
}

export type Props = {
  options?: FrankieDropdownButtonOptions[]
  label: string
  iconClassName?: string
  className?: string
  selectedOption?: (data: string) => void
  handleOnBtnClick?: () => void
  disabled?: boolean
  testId?: { button: string }
}

export function AddMoreButton({
  label,
  iconClassName = '',
  className = '',
  selectedOption,
  handleOnBtnClick,
  options = [],
  disabled,
  testId,
}: Props) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const open = Boolean(anchorEl)

  const handleOnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (options.length !== 0) {
      setAnchorEl(event.currentTarget)
      return
    }

    if (handleOnBtnClick) {
      handleOnBtnClick()
    }
  }

  const handleClose = (data: string) => {
    setAnchorEl(null)

    if (selectedOption) {
      selectedOption(data)
    }
  }
  return (
    <div>
      <FrankieButton
        className={`bg-tertiary-grey-50 hover:bg-tertiary-grey-100 border border-solid border-tertiary-grey-300 text-tertiary-grey-800 px-2 h-8 focus:outline-none ${className} `}
        intent="secondary"
        startIcon={{
          name: 'mdiPlusCircle',
          className: `self-center text-xl text-primary  mr-1 bg-transparent ${iconClassName}`,
          size: 'md',
        }}
        onClick={handleOnClick}
        testId={{ button: individualProfileQa.button }}
      >
        {label}
      </FrankieButton>
      {!!options.length && (
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          sx={{
            width: '100%',
            left: '0',
            right: '0',
          }}
        >
          {options
            .filter(option => option.isActive)
            .map(({ label, value, iconProps }, idx) => (
              <MenuItem
                onClick={() => handleClose(value)}
                key={value}
                className="text-tertiary-grey-100"
                sx={{
                  fontSize: '14px',
                }}
              >
                {!!iconProps && (
                  <ListItemIcon>
                    <FrankieIcon {...iconProps} />
                  </ListItemIcon>
                )}
                {label}
              </MenuItem>
            ))}
        </Menu>
      )}
    </div>
  )
}
