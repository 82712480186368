import React, { ReactNode, useMemo, useState } from 'react'

import { MenuItem } from '@mui/material'
import { NavLink } from 'react-router-dom'

import {
  FrankieButton,
  FrankieIcon,
  FrankieIconName,
  FrankiePopover,
} from 'frankify/src'

import {
  ApplicantId,
  useApplicantPaths,
  AdditionalApplicantPathTypes,
} from 'entities/applicant'
import { PermissionTypes } from 'entities/role'
import { useHasFeatureFlag, useHasPermission } from 'entities/session'

import { I18nKeys, useI18n } from 'shared/i18n'
import { useOverlay } from 'shared/overlay'

import {
  ApplicantActionModal,
  ApplicantActionModalTypes,
} from './applicant-action-modal/applicant-action-modal'
import { APPLICANT_GENERAL_INFORMATION_KEY } from '../../applicant-general-information.key'
import { applicantGeneralInformationEn } from '../../locale/applicant-general-information.en'
import { applicantGeneralInformationVueMigratedQa } from '../../qa/applicant-general-information.qa'
import { useApplicantCheckSummaryState } from '../../state/applicant-check-summary-state/applicant-check-summary-state'

type ActionType =
  | { type: 'redirect'; path: string }
  | { type: 'popup'; modal: ApplicantActionModalTypes }

type ActionWrapperProps = {
  children: ReactNode
  action: ActionType
  hidden?: boolean
  disabled?: boolean
}

function ActionWrapper({
  action,
  children,
  hidden,
  disabled,
}: ActionWrapperProps) {
  if (hidden) return null

  if (action.type === 'redirect' && !disabled) {
    return <NavLink to={action.path}>{children}</NavLink>
  }

  return children
}

type Props = { className?: string; applicantId: ApplicantId }

export function ApplicantActionMenu({ className = '', applicantId }: Props) {
  const t = useI18n([APPLICANT_GENERAL_INFORMATION_KEY], {
    keys: applicantGeneralInformationEn,
  })

  const { getApplicantPath } = useApplicantPaths()

  const [open, setOpen] = useState(false)
  const [createOverlay, closeOverlay] = useOverlay()

  const permission = useHasPermission({
    canAddToBlocklist: PermissionTypes.BlocklistNew,
    canAddToWatchList: PermissionTypes.WatchListNew,
    canArchive: PermissionTypes.ApplicantArchiveInactive,
    canChangeStatus: PermissionTypes.ApplicantProfileStatusOverride,
    canRemoveWatchlist: PermissionTypes.ApplicantRemoveWatchlist,
    canRemoveBlocklist: PermissionTypes.ApplicantRemoveBlacklist,
  })

  const { isAllowGenerateKYCReport } = useHasFeatureFlag({
    isAllowGenerateKYCReport: 'KYCIndividualReports',
  })

  const summaryData = useApplicantCheckSummaryState({ applicantId })

  // eslint-disable-next-line complexity
  const actionMenu = useMemo(() => {
    const {
      canAddToBlocklist,
      canAddToWatchList,
      canArchive,
      canChangeStatus,
      canRemoveWatchlist,
      canRemoveBlocklist,
    } = permission

    const {
      isManualBlocklist,
      disableEditProfile,
      watchlistReasonCode,
      isBlocklistEntity,
      isLegacyProfile,
      isBusinessProfile,
      entityWithSpecialState,
      archivedProfileAccount,
    } = summaryData

    const editProfileRoute: AdditionalApplicantPathTypes = isBusinessProfile
      ? 'editBusiness'
      : 'manualIdv'

    const options: {
      label: I18nKeys<(typeof applicantGeneralInformationEn)['action']>
      action: ActionType
      disabled?: boolean
      iconName: FrankieIconName
      hidden?: boolean
    }[] = [
      {
        label:
          isManualBlocklist && !isBusinessProfile
            ? 'editBlocklistInfo'
            : 'editCustomerInfo',
        action: {
          type: 'redirect',
          path: getApplicantPath(
            isManualBlocklist ? 'blocklistedInfo' : editProfileRoute,
          ),
        },
        hidden: isBusinessProfile && entityWithSpecialState,
        disabled: disableEditProfile,
        iconName: 'mdiSquareEditOutline',
      },
      {
        // Not for business profile
        label: 'removeFromBlocklist',
        iconName: 'mdiCancel',
        hidden: isBusinessProfile || !isManualBlocklist || !canRemoveBlocklist,
        action: {
          type: 'popup',
          modal: 'RemoveApplicantFromBlocklist',
        },
      },
      {
        // Not for business profile
        label: 'addToBlocklist',
        iconName: 'mdiCancel',
        hidden: isBusinessProfile || isManualBlocklist || !canAddToBlocklist,
        action: {
          type: 'popup',
          modal: 'AddApplicantToBlockList',
        },
      },
      {
        // Not for business profile
        label: 'removeFromWatchList',
        iconName: 'mdiEyeOutline',
        hidden:
          isBusinessProfile || !watchlistReasonCode || !canRemoveWatchlist,
        action: {
          type: 'popup',
          modal: 'RemoveApplicantFromWatchList',
        },
      },
      {
        // Not for business profile
        label: 'addToWatchList',
        iconName: 'mdiEyeOutline',
        hidden:
          isBusinessProfile ||
          !canAddToWatchList ||
          !!watchlistReasonCode ||
          (isManualBlocklist && isBlocklistEntity),
        action: {
          type: 'popup',
          modal: 'AddApplicantToWatchList',
        },
      },
      {
        label: 'changeStatus',
        iconName: isBusinessProfile
          ? 'mdiOfficeBuildingOutline'
          : 'mdiAccountOutline',
        hidden: !canChangeStatus,
        action: {
          type: 'popup',
          modal: 'ChangeApplicantStatus',
        },
      },
      {
        label:
          isManualBlocklist && !isBusinessProfile
            ? 'archiveBlocklist'
            : 'archiveProfile',
        iconName: 'mdiArchiveArrowDownOutline',
        action: {
          type: 'popup',
          modal: 'ArchiveApplicant',
        },
        hidden: !canArchive || entityWithSpecialState,
      },
      {
        // Not for business profile
        label: 'generateIndividualVerificationReport',
        iconName: 'mdiFileDocumentOutline',
        hidden:
          isBusinessProfile || isLegacyProfile || !isAllowGenerateKYCReport,
        action: {
          type: 'popup',
          modal: 'GenerateIndividualVerificationReport',
        },
      },
      {
        // Only for business profile
        label: 'unarchiveProfile',
        iconName: 'mdiArchiveArrowUpOutline',
        hidden: !isBusinessProfile || !archivedProfileAccount,
        action: {
          type: 'popup',
          modal: 'UnarchiveApplicant',
        },
      },
    ]

    return options
  }, [permission, summaryData, getApplicantPath, isAllowGenerateKYCReport])

  const handleActionMenu = (action: ActionType) => {
    if (action.type === 'popup') {
      setOpen(false)
      createOverlay(
        <ApplicantActionModal
          handleClose={closeOverlay}
          applicantId={applicantId}
          type={action.modal}
        />,
        { className: 'p-0' },
      )
    }
  }

  return (
    <FrankiePopover
      onOpenChange={setOpen}
      open={open}
      popoverRest={{ placement: 'bottom-end' }}
      trigger={
        <FrankieButton
          noStyles
          className={`border border-tertiary-grey-300 border-solid p-1 rounded-sm ${className} ${
            open ? 'bg-neutral-30 border-transparent' : ''
          } hover:bg-neutral-30 hover:border-transparent`}
          singleIcon={{
            name: 'mdiDotsHorizontal',
            className: 'text-tertiary-grey-300',
          }}
          onClick={() => setOpen(prev => !prev)}
          testId={{
            button: applicantGeneralInformationVueMigratedQa.actionMenuCta,
          }}
        />
      }
    >
      <div className="bg-mono-white shadow-md rounded-sm py-1">
        {actionMenu.map(({ label, action, disabled, iconName, hidden }) => (
          <ActionWrapper
            key={label}
            action={action}
            hidden={hidden}
            disabled={disabled}
          >
            <MenuItem
              key={label}
              onClick={() => handleActionMenu(action)}
              disabled={disabled}
              className="py-2 font-bold text-neutral-90 text-sm hover:text-primary-600 hover:bg-primary-50"
            >
              <FrankieIcon
                name={iconName}
                size="sm"
                className="text-tertiary-grey-300 me-3"
              />
              {t(`action.${label}`)}
            </MenuItem>
          </ActionWrapper>
        ))}
      </div>
    </FrankiePopover>
  )
}
