import { bffClient, IClient } from 'shared/client'

import { MutateManualStatusType } from '../model/applicant-workflow-events.model'

export class WorkflowEventsAPI {
  client: IClient

  constructor(client: IClient) {
    this.client = client
  }

  async updateWorkflowStatus(
    entityId: string,
    manualStatusData: MutateManualStatusType,
  ) {
    return this.client.patch(
      `f2/v1/individual/${entityId}/status`,
      manualStatusData,
    )
  }
}

export const workflowEventsAPI = new WorkflowEventsAPI(bffClient)
