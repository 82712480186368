export const ORGANISATION_KEY = 'ORGANISATION'

export const organisationEn = {
  info: {
    title: 'Organization information',
    mainName: 'Main name',
    location: 'Location',
    abn: 'ABN',
    acn: 'ACN',
    type: 'Type',
    abnStatus: 'ABN status',
    businessNames: 'Current business names',
    historicalBusinessNames: 'Historical business names',
    values: {
      abnStatus: '{{status}} from {{from}}',
      businessName: '{{name}} (effective from {{from}})',
      noHistoricalBusinessNames: 'No historical business names',
    },
  },

  names: 'Names',
  effectiveFrom: 'Effective from',
  effectiveTo: 'Effective to',

  error: {
    rolesNotConfigured: 'Roles not configured',
  },
}
