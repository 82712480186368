import countryListJson from './country.json'

/**
 * @example AND, USA, AUS, ARM
 */
export type CountryAlpha3Code = string

/**
 * Ordinary name in English
 * @example Andorra, United States of America
 */
export type CountryName = string

/**
 * @example US-AL, US-AK
 */
export type CountrySubdivisionCode = string

/**
 * Ordinary name in English
 * @example Alabama, Alaska
 */
export type CountrySubdivisionName = string

export enum CountryAlpha3CodeTypes {
  Australia = 'AUS',
  NEW_ZEALAND = 'NZL',
  UNITED_KINGDOM = 'GBR',
  INDIA = 'IND',
  PHILIPPINES = 'PHL',
  CHINA = 'CHN',
  HONG_KONG = 'HKG',
  INDONESIA = 'IDN',
  MALAYSIA = 'MYS',
  SPAIN = 'ESP',
  SWEDEN = 'SWE',
  ITALY = 'ITA',
  BRAZIL = 'BRA',
  MEXICO = 'MEX',
  RUSSIA = 'RUS',
  TURKEY = 'TUR',
  SINGAPORE = 'SGP',
  SOUTH_AFRICA = 'ZAF',
  ARGENTINA = 'ARG',
  JORDAN = 'JOR',
  KUWAIT = 'KWT',
  OMAN = 'OMN',
  SAUDI_ARABIA = 'SAU',
  EGYPT = 'EGY',
  ROMANIA = 'ROU',
  VIETNAM = 'VNM',
  CAMBODIA = 'KHM',
  THAILAND = 'THA',
  UNITED_STATES_AMERICA = 'USA',
  DEFAULT = 'DEFAULT',
}

export interface ICountryOptionSubdivision {
  name: CountrySubdivisionName
  subdivisionCode: CountrySubdivisionCode
}

export interface ICountryOption {
  name: CountryName
  alpha3code: CountryAlpha3Code
  subdivision?: ICountryOptionSubdivision[]
  UBOAvailable?: boolean
}

export const countryList: ICountryOption[] =
  countryListJson.map<ICountryOption>(country => ({
    alpha3code: country.alpha3Code,
    name: country.name,
  }))

export const countryPhoneCodeList = countryListJson.map(country => ({
  label: `${country.name} (+${country.callingCodes[0]})`,
  code: country.alpha3Code,
  alpha2: country.alpha2Code,
  value: country.callingCodes[0],
}))

// backend accepts only subdivision in two-chars format - AL not US-AL
export function stripSubdivision(
  subdivisionCode: CountrySubdivisionCode,
): string {
  return subdivisionCode.split('-')[1]
}

export function getCountryNameByAlpha3Code(
  alpha3code?: CountryAlpha3Code,
): CountryName {
  return (
    countryListJson.find(country => country.alpha3Code === alpha3code)?.name ||
    ''
  )
}
