import { useCallback } from 'react'

import { useQuery, useQueryClient } from '@tanstack/react-query'

import { RoleId } from 'entities/role'

import { roleManagementF2Api } from '../../api/role-management-f2.api'
import { ROLE_MANAGEMENT_F2_KEY } from '../../role-management-f2.key'

export const F2RoleQueryKey = [ROLE_MANAGEMENT_F2_KEY, 'role-query-key']
export const F2SelectedRoleKey = [ROLE_MANAGEMENT_F2_KEY, 'selected-role-key']

export const useF2RoleQuery = () => {
  const queryClient = useQueryClient()

  const { data: roleId } = useQuery({
    queryKey: F2SelectedRoleKey,
    queryFn: () => {
      const roleId = queryClient.getQueryData<RoleId>(F2SelectedRoleKey)
      return roleId
    },
  })

  const { data, ...restQuery } = useQuery({
    queryKey: F2RoleQueryKey,
    queryFn: async () => {
      const { data } = await roleManagementF2Api.getRoleList()

      if (data.length && !roleId) {
        // Initially set the first role as selected
        queryClient.setQueryData(F2SelectedRoleKey, data[0].id)
      }

      return data
    },
  })

  const selectedRole = data?.find(role => role.id === roleId)

  const setSelectedRole = useCallback(
    (roleId: RoleId) => {
      queryClient.setQueryData(F2SelectedRoleKey, roleId)
    },
    [queryClient],
  )

  return {
    roles: data,
    selectedRole,
    setSelectedRole,
    ...restQuery,
  }
}
