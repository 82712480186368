import { useMemo } from 'react'

import { ApplicantId, useApplicantDataQuery } from 'entities/applicant'

type Args = {
  applicantId?: ApplicantId
}

export const useApplicantCheckSummaryState = ({ applicantId }: Args) => {
  const {
    data: applicantData,
    isLoading,
    isRefetching,
    isFetching,
    error,
  } = useApplicantDataQuery({ applicantId })

  // eslint-disable-next-line complexity
  const result = useMemo(() => {
    const checkSummary = applicantData?.checkSummary
    const isLegacyProfile = !checkSummary?.checkResults.length
    const isBusinessProfile = !!applicantData?.businessInfo

    const isManualBlocklist = !!checkSummary?.issues.MANUAL_BLOCKLIST
    const blocklistReason = checkSummary?.issues.MANUAL_BLOCKLIST
    const blocklistAttribute = checkSummary?.issues.BLOCKLIST_ATTRIBUTE

    const profileStatus = checkSummary?.status.key
    const profileStatusType = checkSummary?.status.type ?? ''
    const inactiveProfileAccount = profileStatus === 'INACTIVE'
    const archivedProfileAccount = profileStatus === 'ARCHIVED'
    const isEntityIsUnchecked = checkSummary?.status.type === 'unchecked'
    const lastCheckDate = checkSummary?.checkDate

    const entityWithSpecialState =
      inactiveProfileAccount || archivedProfileAccount
    const isManualKyc = checkSummary?.kycMethod === 'manual'

    const isBlocklistEntity = checkSummary?.alertList?.some(issue =>
      ['blacklist'].includes(issue.term),
    )
    const isDuplicateEntity = checkSummary?.alertList?.some(issue =>
      ['duplicate'].includes(issue.term),
    )

    const isBlocklistAttribute =
      blocklistAttribute && blocklistAttribute !== 'ENTIRE_ENTITY'

    const isResolveDuplicateEntity =
      checkSummary?.issues.DUPLICATE_RESOLVED && isDuplicateEntity
    const isResolvedBlacklistEntity =
      checkSummary?.issues.DUPLICATE_RESOLVED && isBlocklistEntity
    const watchlistReasonCode = checkSummary?.issues.MANUAL_WATCHLIST

    const disableEditProfile =
      inactiveProfileAccount ||
      isResolveDuplicateEntity ||
      isResolvedBlacklistEntity ||
      isManualBlocklist

    const entityHasUncheckedChecks = checkSummary?.checkResults.some(
      i => i.result.type === 'UNCHECKED' || i.result.type === 'FAIL',
    )

    return {
      checkSummary,
      isLegacyProfile,
      isBusinessProfile,
      isManualBlocklist,
      blocklistReason,
      blocklistAttribute,
      profileStatus,
      profileStatusType,
      inactiveProfileAccount,
      archivedProfileAccount,
      entityWithSpecialState,
      isDuplicateEntity,
      isBlocklistEntity,
      isBlocklistAttribute,
      isResolveDuplicateEntity,
      isResolvedBlacklistEntity,
      watchlistReasonCode,
      disableEditProfile,
      entityHasUncheckedChecks,
      isManualKyc,
      isEntityIsUnchecked,
      lastCheckDate,
    }
  }, [applicantData])

  return {
    ...result,
    isLoading: isLoading || isRefetching || isFetching,
    error,
  }
}
