import React from 'react'

import { FrankieBadge, FrankieBadgeTheme } from 'frankify/src'

export function BadgeCell({
  theme,
  text,
  className = '',
}: {
  theme: FrankieBadgeTheme
  text: string
  className?: string
}) {
  return (
    <div
      className={`w-full h-full whitespace-nowrap px-4 py-3 flex items-center ${className}`}
    >
      <FrankieBadge theme={theme} text={text} />
    </div>
  )
}
