import React, { useCallback, useEffect } from 'react'

import { Controller, SubmitHandler, useForm } from 'react-hook-form'

import { FrankieButton, FrankieLoader, FrankieTextField } from 'frankify/src'

import { useRoleListQuery } from 'features/role-management'
import {
  userManagementQa,
  useCreateUserMutation,
  useUpdateUserMutation,
  USER_MANAGEMENT_KEY,
} from 'features/user-management'

import { PermissionTypes, RoleId } from 'entities/role'
import { useHasFeatureFlag, useHasPermission } from 'entities/session'
import { IUser } from 'entities/user'

import { useI18n } from 'shared/i18n'
import { notification } from 'shared/notification'
import { TrackingEventsTypes, trackingManager } from 'shared/tracking'
import { Nullable } from 'shared/typescript'
import { EMAIL_PATTERN } from 'shared/validation'

import { CheckboxGroup } from './checkbox-group/checkbox-group'

enum CreateUserInputTypes {
  FullName = 'fullName',
  Email = 'email',
  RoleIds = 'roleIds',
}

type NewUserInputs = {
  [CreateUserInputTypes.FullName]: string
  [CreateUserInputTypes.Email]: string
  [CreateUserInputTypes.RoleIds]: RoleId[]
}

type Props = {
  onCancel: () => void
  userToEdit?: Nullable<IUser>
  userList: IUser[]
}

export function CreateUserModal({
  onCancel,
  userToEdit = null,
  userList,
}: Props) {
  const t = useI18n([USER_MANAGEMENT_KEY])

  const isEdit = !!userToEdit

  const { canFetchRoleList } = useHasPermission({
    canFetchRoleList: PermissionTypes.RoleListFunction,
  })

  const { isFrankie2 } = useHasFeatureFlag({ isFrankie2: 'frankie2customer' })

  const { data: roleList, isLoading: isRoleListLoading } = useRoleListQuery({
    canFetchRoleList,
    version: isFrankie2 ? 'f2' : 'f1',
  })

  const { mutateAsync: createUser } = useCreateUserMutation()
  const { mutateAsync: updateUser } = useUpdateUserMutation()

  const getDefaultValues = useCallback(() => {
    if (!userToEdit) {
      return {
        fullName: '',
        email: '',
        roleIds: [],
      }
    }

    const foundRoleIds = (userToEdit.roles || []).map(
      roleName => roleList?.find(role => role.role === roleName)?.id,
    )

    return {
      fullName: userToEdit.realname,
      email: userToEdit.email,
      roleIds: foundRoleIds,
    }
  }, [userToEdit, roleList])

  const {
    reset,
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
  } = useForm<NewUserInputs>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: getDefaultValues(),
  })

  // Reset form defaultValues, once list is loaded
  useEffect(
    () => reset(getDefaultValues()),
    [isRoleListLoading, reset, getDefaultValues],
  )

  const fullNameRegister = register(CreateUserInputTypes.FullName, {
    required: true,
  })

  const emailRegister = register(CreateUserInputTypes.Email, {
    required: true,
    pattern: {
      value: EMAIL_PATTERN,
      message: t('createUserModal.form.emailPattern'),
    },
    validate: value =>
      userList.some(user => user.email === value && userToEdit?.id !== user.id)
        ? t('createUserModal.form.emailUnique')
        : undefined,
  })

  const handleFormSubmit: SubmitHandler<NewUserInputs> = async data => {
    try {
      if (isEdit) {
        await updateUser({ userId: userToEdit.id, ...data })

        trackingManager.track(
          TrackingEventsTypes.UserManagementUserTableEditUser,
        )

        notification.success(
          t('updateUserModal.notification.updated', {
            name: data[CreateUserInputTypes.FullName],
          }),
        )
      } else {
        await createUser(data)
        trackingManager.track(TrackingEventsTypes.SettingsUserListCreateUser)
        notification.success(t('createUserModal.notification.created'))
      }
      onCancel()
    } catch (error) {
      if (!isEdit) {
        trackingManager.track(
          TrackingEventsTypes.SettingsUserListCreateUserError,
        )
      } else {
        trackingManager.track(
          TrackingEventsTypes.UserManagementUserTableEditUserError,
        )
      }
      notification.error(
        t(
          isEdit
            ? 'updateUserModal.notification.error'
            : 'createUserModal.notification.error',
          { name: data[CreateUserInputTypes.FullName] },
        ),
      )
    }
  }

  if (isRoleListLoading)
    return <FrankieLoader loading size="md" className="min-h-[535px]" />

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <FrankieLoader
        label={
          <span className="text-tertiary-grey-800 mt-3 text-md font-semibold">
            {t(
              isEdit ? 'updateUserModal.loader' : 'createUserModal.form.loader',
            )}
          </span>
        }
        size="md"
        loading={isSubmitting}
      >
        <div>
          <span className="text-2xl font-bold">
            {isEdit ? t('updateUserModal.header') : t('createUserModal.header')}
          </span>
          <div className="flex mt-4">
            <FrankieTextField
              {...fullNameRegister}
              placeholder={t('createUserModal.form.fullNamePlaceholder')}
              className="mr-4"
              size="sm"
              label={t('createUserModal.form.fullName')}
              errorText={errors[CreateUserInputTypes.FullName]?.message}
              error={!!errors[CreateUserInputTypes.FullName]?.message}
              testId={{ input: userManagementQa.createUserForm_inputName }}
            />
            <FrankieTextField
              {...emailRegister}
              size="sm"
              placeholder={t('createUserModal.form.emailPlaceholder')}
              label={t('createUserModal.form.email')}
              errorText={errors[CreateUserInputTypes.Email]?.message}
              error={!!errors[CreateUserInputTypes.Email]?.message}
              testId={{ input: userManagementQa.createUserForm_inputEmail }}
            />
          </div>

          <div className="mt-4">
            <span className="text-md font-semibold">
              {t('createUserModal.form.roles')}
            </span>
            {roleList && (
              <Controller
                control={control}
                name={CreateUserInputTypes.RoleIds}
                rules={{
                  required: true,
                }}
                render={({ field: { value, onChange } }) => (
                  // eslint-disable-next-line jsx-a11y/label-has-associated-control
                  <CheckboxGroup
                    type={isFrankie2 ? 'radio' : 'checkbox'}
                    values={value}
                    options={roleList}
                    onChange={onChange}
                  />
                )}
              />
            )}
          </div>
          <div className="mt-6 flex gap-4">
            <FrankieButton
              intent="primary"
              size="sm"
              type="submit"
              disabled={!isValid || isSubmitting}
              testId={{ button: userManagementQa.createUserForm_ctaSubmit }}
            >
              {isEdit
                ? t('updateUserModal.updateUserButton')
                : t('createUserModal.addUserButton')}
            </FrankieButton>
            <FrankieButton
              intent="subtle"
              size="sm"
              onClick={onCancel}
              testId={{ button: userManagementQa.createUserForm_ctaCancel }}
            >
              {t('createUserModal.cancel')}
            </FrankieButton>
          </div>
        </div>
      </FrankieLoader>
    </form>
  )
}
