import React from 'react'

import { FrankieButton } from 'frankify/src'

import { DateFormatTypes, formatDate } from 'shared/date-time'
import { useI18n } from 'shared/i18n'
import { Noop } from 'shared/typescript'

import { ORGANISATION_KEY, organisationEn } from '../../locale/organisation.en'
import { BusinessNameAndDates } from '../../model/business.model'

type Props = {
  heading: string
  data: BusinessNameAndDates[]
  onClose: Noop
}

export function OrganisationViewModal({ heading, data, onClose }: Props) {
  const t = useI18n([ORGANISATION_KEY], { keys: organisationEn })

  const cellClass = 'p-4 text-left'

  return (
    <div className="relative bg-mono-white rounded-2 w-[700px]">
      <div>
        <div className="flex justify-between py-4 px-6 border-b border-neutral-50">
          <div className="flex gap-2">
            <div className="text-md font-bold ">{heading}</div>
          </div>
          <FrankieButton
            noStyles
            onClick={onClose}
            className="flex-grow-0"
            singleIcon={{
              name: 'mdiClose',
            }}
          />
        </div>
        <div className="m-6 font-semibold text-xs border border-tertiary-grey-300 rounded-sm shadow-sm overflow-x-hidden max-h-[600px] overflow-y-auto">
          <table className="w-full border-collapse ">
            <thead className="bg-tertiary-grey-100 text-tertiary-grey-500 sticky top-0">
              <tr>
                <th className={`${cellClass} w-auto`}>{t('names')}</th>
                <th className={`${cellClass} w-[140px]`}>
                  {t('effectiveFrom')}
                </th>
                <th className={`${cellClass} w-[140px]`}>{t('effectiveTo')}</th>
              </tr>
            </thead>
            <tbody>
              {data.map(({ name, effectiveFrom, effectiveTo }, index) => (
                <tr className="border-t border-tertiary-grey-300 text-neutral-100">
                  <td className={cellClass}>{name}</td>
                  <td className={cellClass}>
                    {effectiveFrom
                      ? formatDate(effectiveFrom, DateFormatTypes.ShortDate)
                      : '-'}
                  </td>
                  <td className={cellClass}>
                    {effectiveTo
                      ? formatDate(effectiveTo, DateFormatTypes.ShortDate)
                      : '-'}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
