import React from 'react'

import { FrankieIcon } from 'frankify/src'

import { ApplicantId } from 'entities/applicant'

import { SelectFormField, TextAreaFormField } from 'shared/form'
import { useI18n } from 'shared/i18n'
import { Noop } from 'shared/typescript'

import { APPLICANT_GENERAL_INFORMATION_KEY } from '../../../../applicant-general-information.key'
import { applicantGeneralInformationEn } from '../../../../locale/applicant-general-information.en'
import { watchListOptions } from '../../../../model/applicant-menu.model'
import { addApplicantToWatchListQa } from '../../../../qa/applicant-general-information.qa'
import {
  AddToBlocklistData,
  useAddApplicantToBlocklistMutation,
} from '../../../../state/applicant-profile-state-change/applicant-profile-state-change'
import { ApplicantActionModalWrapper } from '../../applicant-action-modal-wrapper/applicant-action-modal-wrapper'

type Props = { applicantId: ApplicantId; handleClose: Noop }

export function AddApplicantToWatchList({ applicantId, handleClose }: Props) {
  const t = useI18n([APPLICANT_GENERAL_INFORMATION_KEY], {
    keys: applicantGeneralInformationEn,
  })

  const { mutate, isLoading, isSuccess } = useAddApplicantToBlocklistMutation({
    applicantId,
  })

  return (
    <ApplicantActionModalWrapper<AddToBlocklistData>
      heading={t('action.heading.watchlist')}
      iconName="mdiEyeOutline"
      isProgress={isLoading}
      isSuccess={isSuccess}
      onClose={handleClose}
      onSubmit={mutate}
      submitText={t('action.addToWatchList')}
      confirmation={{
        description: t('action.description.confirmAddToWatchlist'),
        confirmText: t('action.confirm.addToWatchlist'),
      }}
      defaultValues={{ flag: 'true_positive', flagType: 'watchlist' }}
      renderForm={({ control }) => (
        <>
          <span className="font-bold">
            {t('action.description.profileWillBeAddedToWatchlist')}
          </span>

          <span>{t('action.description.willNeedFurtherCheck')}</span>

          <div className="flex items-center p-4 gap-2 bg-tertiary-grey-100 rounded-sm">
            <FrankieIcon
              name="mdiInformation"
              className="text-tertiary-grey-400"
            />
            <span>{t('action.description.canRemoveFromWatchlist')}</span>
          </div>

          <SelectFormField
            className="max-w-[60%]"
            label={t('action.fields.reasonForWatchlist')}
            options={watchListOptions.map(({ tKey, value }) => ({
              label: t(tKey),
              value,
            }))}
            control={control}
            name="reasonCode"
            rules={{ required: true }}
            testId={{ input: addApplicantToWatchListQa.select }}
          />

          <TextAreaFormField
            label={t('action.fields.comment')}
            placeholder={t('action.fields.commentPlaceholder')}
            control={control}
            name="comment"
            testId={{ input: addApplicantToWatchListQa.textarea }}
          />
        </>
      )}
    />
  )
}
