import { I18nKeys } from 'shared/i18n'

import { applicantEn } from '../locale/applicant.en'

export enum EntityStatusRawTypes {
  UNCHECKED = 'UNCHECKED',
  REFER = 'REFER', // needs attention -> needs review
  FAIL = 'FAIL', // failed
  PASS = 'PASS', // passed
  PASS_MANUAL = 'PASS_MANUAL', // manually passed
  FAIL_MANUAl = 'FAIL_MANUAL', // manually failed
  ARCHIVED = 'ARCHIVED',
  INACTIVE = 'INACTIVE',
}

export enum ApplicantEntityStatusTypes {
  ManuallyPassed = 'ManuallyPassed',
  Passed = 'Passed',
  NeedsAttention = 'NeedsAttention',
  Failed = 'Failed',
  ManuallyFailed = 'ManuallyFailed',
  Unchecked = 'Unchecked',
  Archived = 'Archived',
  Inactive = 'Inactive',
}

export const rawToApplicantEntityStatus = {
  [EntityStatusRawTypes.ARCHIVED]: ApplicantEntityStatusTypes.Archived,
  [EntityStatusRawTypes.FAIL]: ApplicantEntityStatusTypes.Failed,
  [EntityStatusRawTypes.INACTIVE]: ApplicantEntityStatusTypes.Inactive,
  [EntityStatusRawTypes.PASS]: ApplicantEntityStatusTypes.Passed,
  [EntityStatusRawTypes.PASS_MANUAL]: ApplicantEntityStatusTypes.ManuallyPassed,
  [EntityStatusRawTypes.REFER]: ApplicantEntityStatusTypes.NeedsAttention,
  [EntityStatusRawTypes.UNCHECKED]: ApplicantEntityStatusTypes.Unchecked,
  [EntityStatusRawTypes.FAIL_MANUAl]: ApplicantEntityStatusTypes.ManuallyFailed,
}

export const applicantProfileStatusOptions: {
  tKey: I18nKeys<typeof applicantEn>
  value: ApplicantEntityStatusTypes
}[] = [
  {
    tKey: 'issue.label.failed',
    value: ApplicantEntityStatusTypes.Failed,
  },
  {
    tKey: 'issue.label.failManual',
    value: ApplicantEntityStatusTypes.ManuallyFailed,
  },
  {
    tKey: 'issue.label.refer',
    value: ApplicantEntityStatusTypes.NeedsAttention,
  },
  {
    tKey: 'issue.label.unchecked',
    value: ApplicantEntityStatusTypes.Unchecked,
  },
  {
    tKey: 'issue.label.archived',
    value: ApplicantEntityStatusTypes.Archived,
  },
  {
    tKey: 'issue.label.inactive',
    value: ApplicantEntityStatusTypes.Inactive,
  },
  {
    tKey: 'issue.label.passed',
    value: ApplicantEntityStatusTypes.Passed,
  },
  {
    tKey: 'issue.label.passedManual',
    value: ApplicantEntityStatusTypes.ManuallyPassed,
  },
]
