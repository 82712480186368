export const APPLICANTS_FILTER_KEY = 'applicantsFilter'

export const applicantsFilterEn = {
  form: {
    title: 'Filters',
    headers: {
      entityType: 'Entity type',
      entityStatus: 'Entity status',
      issue: 'Issue',
      riskLevel: 'Risk level',
      createdDate: 'Created date',
      lastUpdatedDate: 'Last updated',
      recipe: 'Recipe',
      assignee: 'Assignee',
    },
    cta: {
      showLess: 'Show less',
      viewAll: 'View all',
      applyAll: 'Apply filters',
      clearAll: 'Clear all',
      apply: 'Apply',
      clear: 'Clear',
    },
    date: {
      custom: 'Custom',
      all: 'All',
      today: 'Today',
      yesterday: 'Yesterday',
      past7Days: 'Past 7 days',
      past30Days: 'Past 30 days',
      pastYear: 'Past year',
      from: 'From',
      to: 'To',
      startValidation: 'Start date can not be empty',
      endValidation: 'End date can not be empty',
      startBeforeEndValidation: 'Start date can not be after end date',
    },
    assignee: {
      placeholder: 'Search user',
    },
  },
  applicantTypes: {
    individual: 'Individual',
    organisation: 'Organisation',
  },
  applicantStatuses: {
    archived: 'Archived',
    failed: 'Failed',
    inactive: 'Inactive',
    manuallyFailed: 'Manually failed',
    manuallyPassed: 'Manually passed',
    needsAttention: 'Needs attention',
    passed: 'Passed',
    unchecked: 'Unchecked',
  },
  applicantIssues: {
    adverseMedia: 'Adverse media',
    biometrics: 'Biometrics',
    blocklist: 'Blocklist',
    device: 'Device',
    digitalId: 'Digital ID',
    document: 'Document',
    duplicate: 'Duplicate',
    fraudTransaction: 'Fraud',
    facialDuplication: 'Fraud duplicate',
    internalWatchlist: 'Internal watchlist',
    kyc: 'KYC',
    pep: 'PEP',
    risk: 'Risk',
    sanctions: 'Sanctions',
    timeout: 'Time out',
    watchlist: 'Watchlist',
  },
  applicantRisks: {
    low: 'Low',
    medium: 'Medium',
    high: 'High',
    unacceptable: 'Unacceptable',
    unknown: 'Unknown',
  },
  plusMore: '+ more',
}
