import { ROLE_MANAGEMENT_F2_KEY } from '../role-management-f2.key'

export const roleManagementF2Qa = {
  role: `${ROLE_MANAGEMENT_F2_KEY}-role-`,
  roleId: `${ROLE_MANAGEMENT_F2_KEY}-role-id-`,
  container: `${ROLE_MANAGEMENT_F2_KEY}-container`,
  rolePermissionContainer: `${ROLE_MANAGEMENT_F2_KEY}-permission-container`,
  rolePermissionTitle: `${ROLE_MANAGEMENT_F2_KEY}-permission-title`,
  rolePermission: `${ROLE_MANAGEMENT_F2_KEY}-permission-`,
} satisfies Record<string, `${typeof ROLE_MANAGEMENT_F2_KEY}-${string}`>

const RoleManagementRolePermissions = `${ROLE_MANAGEMENT_F2_KEY}-role-permissions`

export const roleManagementRolePermissionsF2Qa = {
  container: `${RoleManagementRolePermissions}-container`,
  title: `${RoleManagementRolePermissions}-title`,
} satisfies Record<string, `${typeof RoleManagementRolePermissions}-${string}`>

export const roleSegment = (role: string) =>
  role.split(' ').join('-').toLowerCase()

export const rolePermissionDataQa = (permission: string) =>
  `${ROLE_MANAGEMENT_F2_KEY}-permission-${permission}`
