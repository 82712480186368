import React from 'react'

import { FrankieButton } from 'frankify/src'

import { useFrankie2R2Customer } from 'entities/entity'

import { useOverlay } from 'shared/overlay'

import { SendLinkEventTypes } from '../../model/individual-send-verification-link.model'
import { individualSendVerificationLinkQa } from '../../qa/individual-send-verification-link.qa'
import { useSendLinkTrackingEvent } from '../../state/individual-send-verification-link.state'
import { IndividualSendVerificationLinkForm } from '../individual-send-verification-link-form/individual-send-verification-link-form'

type Props = {
  entityId?: string
}

function IndividualSendVerificationLink({ entityId }: Props) {
  const [createOverlay] = useOverlay()

  const track = useSendLinkTrackingEvent(SendLinkEventTypes.click, entityId)
  const isFrankie2R2 = useFrankie2R2Customer()

  if (!entityId || !isFrankie2R2) {
    return null
  }

  const handleClick = () => {
    createOverlay(<IndividualSendVerificationLinkForm entityId={entityId} />, {
      className: '!p-6',
      closeButtonClassName: '!top-4 !right-4 z-30',
    })
    track()
  }

  return (
    <FrankieButton
      noStyles
      onClick={handleClick}
      singleIcon={{
        name: 'mdiSendOutline',
        size: 'sm',
        className: 'text-tertiary-grey-700',
      }}
      testId={{ button: individualSendVerificationLinkQa.sendBtn }}
    />
  )
}

export { IndividualSendVerificationLink }
