import React, { useMemo } from 'react'

import {
  DataGridPro,
  GridColDef,
  GridExpandMoreIcon,
  GridKeyboardArrowRight,
  GridRenderCellParams,
  GridRowParams,
} from '@mui/x-data-grid-pro'
import { Trans } from 'react-i18next'

import { FrankieIcon } from 'frankify/src'

import { ApplicantResponse } from 'entities/applicant'
import { RulesetMatchFields } from 'entities/applicant/model/applicant-response.model'

import { useI18n } from 'shared/i18n'

import { APPLICANT_PERSONAL_INFO_KEYS } from '../../applicant-persona-info.keys'
import { applicantPersonalInfoEn } from '../../locale/applicant-personal-info.en'
import { getStatusIcon } from '../../model/applicant-detail-view.model'
import {
  GetRowRequiredDataDetails,
  RulesetsRowData,
  getRowRequiredDataDetails,
  getRowValue,
  getStatus,
  getVerifyColorOutcome,
  verifiedBgColor,
} from '../../model/applicant-matched-rulesets.model'

type ApplicantRulesetsTableProps = {
  applicantData: ApplicantResponse
  isPriorityMatched?: boolean
}

type RulesetMatchedCellProps = GridRenderCellParams<RulesetsRowData>
type RulesetOutcomeCellProps = GridRenderCellParams<RulesetsRowData>
type RowDetailViewProps = GridRowParams<RulesetsRowData>

const TABLE_MIN_WIDTH = 600

export function RulesetMatchedCell({
  row,
  value,
  colDef,
}: RulesetMatchedCellProps) {
  const field = colDef.field as keyof RulesetMatchFields
  const matchTypeName = row.rulesetMatchField[field]
  return (
    <p
      className={`flex justify-center items-center grow-0 font-semibold m-auto text-center w-[16px] h-[16px] ${verifiedBgColor(
        matchTypeName,
        row.result,
        value as string,
      )}`}
    >
      {value}
    </p>
  )
}

export function RowDetailView({ row }: RowDetailViewProps) {
  const details = getRowRequiredDataDetails(row.rulesetMatchField)
  const dataFields = Object.keys(details)

  return (
    <div className="rounded-sm p-6 m-5 bg-tertiary-grey-100 rounded">
      <div className="flex items-center">
        <FrankieIcon
          name="mdiInformationOutline"
          size="sm"
          className="text-tertiary-grey-400 mr-2"
        />
        <span>
          <Trans
            i18nKey="applicant_personal_info:rulesets.rowDetails"
            components={[<strong />]}
            tOptions={{
              name: row.ruleset,
            }}
          />
        </span>
      </div>
      {!!dataFields.length && (
        <ul className="!list-disc ml-8">
          {dataFields.map(field => (
            <li key={field}>
              <span>
                <Trans
                  i18nKey="applicant_personal_info:rulesets.requiredData"
                  components={[<strong />]}
                  tOptions={{
                    countValue:
                      details[field as keyof GetRowRequiredDataDetails].count,
                    field:
                      details[field as keyof GetRowRequiredDataDetails].name,
                  }}
                />
              </span>
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

export function RulesetOutcomeCell({ row }: RulesetOutcomeCellProps) {
  const status = getStatus(row.result)
  const iconName = getStatusIcon(status.status, null)

  return (
    <div className="text-sm flex justify-start items-center font-bold">
      <FrankieIcon
        name={iconName}
        className={`${getVerifyColorOutcome(status.status)} mr-1`}
        size="sm"
      />
      <p className={`mr-3 ${getVerifyColorOutcome(status.status)}`}>
        {status.text}
      </p>
    </div>
  )
}

export function ApplicantRulesetsTable({
  applicantData,
  isPriorityMatched,
}: ApplicantRulesetsTableProps) {
  const t = useI18n(APPLICANT_PERSONAL_INFO_KEYS, {
    keys: applicantPersonalInfoEn,
  })

  const rowData = useMemo(() => {
    const rulesets = [...applicantData.checkSummary.rulesetsMatches].sort(
      (a, b) => {
        if (a.order === null) return -1
        if (b.order === null) return 1
        return a.order - b.order
      },
    )

    const rows = rulesets.map(ruleset => ({
      ruleset: ruleset.name || '',
      name: String(getRowValue(ruleset.rulesetMatchFields.name)), // No need for template literals
      dob: String(getRowValue(ruleset.rulesetMatchFields.dob)),
      address: String(getRowValue(ruleset.rulesetMatchFields.address)),
      govId: String(getRowValue(ruleset.rulesetMatchFields.govId)),
      result: ruleset.result,
      rulesetMatchField: ruleset.rulesetMatchFields,
    }))

    if (isPriorityMatched) {
      const [priorityRow] = rows.filter(row => row.result === 'PASS')
      return [priorityRow].filter(Boolean)
    }

    return rows
  }, [applicantData.checkSummary.rulesetsMatches, isPriorityMatched])

  const columns: GridColDef<RulesetsRowData>[] = [
    {
      field: 'ruleset',
      headerName: t('rulesets.table.rulesets'),
      maxWidth: 200,
      flex: 200 / TABLE_MIN_WIDTH,
    },
    {
      field: 'name',
      headerName: t('rulesets.table.name'),
      maxWidth: 120,
      flex: 120 / TABLE_MIN_WIDTH,
      headerAlign: 'center',
      renderCell: RulesetMatchedCell,
    },
    {
      field: 'dob',
      headerName: t('rulesets.table.dob'),
      maxWidth: 120,
      flex: 120 / TABLE_MIN_WIDTH,
      headerAlign: 'center',
      renderCell: RulesetMatchedCell,
    },
    {
      field: 'address',
      headerName: t('rulesets.table.address'),
      maxWidth: 120,
      flex: 120 / TABLE_MIN_WIDTH,
      headerAlign: 'center',
      renderCell: RulesetMatchedCell,
    },
    {
      field: 'govId',
      headerName: t('rulesets.table.govId'),
      maxWidth: 120,
      flex: 120 / TABLE_MIN_WIDTH,
      headerAlign: 'center',
      renderCell: RulesetMatchedCell,
    },
    {
      field: 'outcome',
      headerName: t('rulesets.table.outcome'),
      maxWidth: 120,
      flex: 120 / TABLE_MIN_WIDTH,
      renderCell: RulesetOutcomeCell,
    },
  ]

  return (
    <DataGridPro
      rows={rowData}
      hideFooter
      disableColumnResize
      columnHeaderHeight={40}
      getRowId={row => row.ruleset}
      localeText={{
        noRowsLabel: t('rulesets.table.noRows'),
      }}
      slots={{
        detailPanelExpandIcon: GridKeyboardArrowRight,
        detailPanelCollapseIcon: GridExpandMoreIcon,
      }}
      columns={columns.map(item => ({
        hideSortIcons: true,
        resizable: false,
        disableColumnMenu: true,
        disableReorder: true,
        headerClassName:
          'text-xs !justify-center text-xs bg-tertiary-grey-50 font-medium text-tertiary-grey-500 !outline-none !p-0',

        ...item,
      }))}
      getDetailPanelContent={RowDetailView}
      getDetailPanelHeight={() => 'auto'}
      sx={{
        '.MuiDataGrid-columnHeaders': {
          backgroundColor: '#f9fafb',
        },
        '& .MuiDataGrid-cell': {
          paddingLeft: 0,
          paddingRight: 0,
        },
        '& .MuiDataGrid-row .MuiDataGrid-cell': {
          fontWeight: '600',
        },
        ...(rowData.length === 0
          ? { '.MuiDataGrid-overlayWrapper': { height: '40px' } }
          : {}),
      }}
    />
  )
}
