import React from 'react'

import { UseFormReturn } from 'react-hook-form'

import { FrankieCheckbox } from 'frankify/src'

import { useHasFeatureFlag } from 'entities/session'

import { TextAreaFormField, getError } from 'shared/form'
import { useI18n } from 'shared/i18n'

import { INDIVIDUAL_PROFILE_KEY } from '../../individual-profile.keys'
import { individualProfileEn } from '../../locale/new-profile.en'
import {
  IIndividualProfileInputs,
  IndividualProfileInputTypes,
  separateConsents,
} from '../../model/form.model'
import { consentFormQa } from '../../qa/individual-profile.qa'

export type Props = {
  form: UseFormReturn<IIndividualProfileInputs>
}

export function ConsentForm({ form }: Props) {
  const {
    register,
    watch,
    trigger,
    control,
    formState: { errors },
  } = form
  const t = useI18n([INDIVIDUAL_PROFILE_KEY], { keys: individualProfileEn })
  const formWatch = watch()

  const { separateConsent } = useHasFeatureFlag({
    separateConsent: 'applicantSubmitSplitConsent',
  })

  const kycType = formWatch[IndividualProfileInputTypes.KycMethod]

  return (
    <div data-qa={consentFormQa.wrapper}>
      <div className="text-lg mb-[14px] font-bold">
        {t('consentForm.title')}
      </div>
      {separateConsent ? (
        separateConsents[kycType].map(i => (
          <div key={i.value} className="flex py-2">
            <FrankieCheckbox
              {...register(i.name, { required: i.required })}
              value={i.value}
              testId={{ input: consentFormQa.checkbox }}
            />
            <span
              className={`ml-8 text-tertiary-grey-700 text-sm font-normal mt-0 ${
                getError(i.name, errors) ? 'text-tertiary-red-400' : ''
              }`}
            >
              <strong>{t(i.title)}</strong> {t(i.desc)}
            </span>
          </div>
        ))
      ) : (
        <div className="flex py-2">
          <FrankieCheckbox
            {...register(IndividualProfileInputTypes.Consent, {
              required: {
                value: true,
                message: 'Error',
              },
              onChange: async () => {
                await trigger(IndividualProfileInputTypes.Consent)
              },
            })}
            testId={{ input: consentFormQa.checkbox }}
          />
          <span
            className={`ml-8 text-tertiary-grey-700 text-sm font-normal mt-0 ${
              getError(IndividualProfileInputTypes.Consent, errors)
                ? 'text-tertiary-red-400'
                : ''
            }`}
          >
            {kycType === IndividualProfileInputTypes.Electronic
              ? t('consentForm.eKyc')
              : t('consentForm.mKyc')}
          </span>
        </div>
      )}

      <div className="mt-4" data-qa={consentFormQa.textArea}>
        <TextAreaFormField
          label={t('consentForm.comment')}
          placeholder={t('consentForm.addYourComment')}
          control={control}
          name={IndividualProfileInputTypes.Comment}
        />
      </div>
    </div>
  )
}
