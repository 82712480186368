import { useQuery } from '@tanstack/react-query'

import { ApplicantId, useApplicantDataQuery } from 'entities/applicant'

import { useI18n } from 'shared/i18n'
import { notification } from 'shared/notification'

import { applicantSupportingDocumentApi } from '../../api/applicant-supporting-document.api'
import { APPLICANT_SUPPORTING_DOCUMENTS_KEY } from '../../applicant-supporting-documents.key'
import { applicantSupportingDocumentsEn } from '../../locale/applicant-supporting-documents.en'
import { BusinessDocumentCatalogRowType } from '../../model/applicant-supporting-documents.model'

export const BusinessDocumentsCatalogQueryKey = 'business-documents-catalog'

type Args = { applicantId: ApplicantId; disabled?: boolean }

export const useBusinessDocumentsCatalogQuery = ({
  applicantId,
  disabled,
}: Args) => {
  const t = useI18n(APPLICANT_SUPPORTING_DOCUMENTS_KEY, {
    keys: applicantSupportingDocumentsEn,
  })
  const { data: applicantData } = useApplicantDataQuery({ applicantId })

  return useQuery({
    enabled: !!applicantId && !disabled,
    queryKey: [BusinessDocumentsCatalogQueryKey, applicantId],
    queryFn: () =>
      applicantSupportingDocumentApi.businessDocumentCatalog(applicantId),
    onSuccess: () =>
      notification.success(
        t('documentCatalog.retrievingSuccess', {
          businessName: applicantData?.businessInfo?.businessName,
        }),
      ),
    onError: () =>
      notification.error(
        t('documentCatalog.retrievingError', {
          businessName: applicantData?.businessInfo?.businessName,
        }),
      ),
  })
}

export const useBusinessDocumentsCatalogData = ({
  applicantId,
  disabled,
}: Args) => {
  const t = useI18n(APPLICANT_SUPPORTING_DOCUMENTS_KEY, {
    keys: applicantSupportingDocumentsEn,
  })
  const { data, isFetching, isFetched, isRefetching, refetch } =
    useBusinessDocumentsCatalogQuery({
      applicantId,
      disabled,
    })

  const rows: BusinessDocumentCatalogRowType[] =
    data?.data.reports.map(doc => ({
      title: doc.reportTitle,
      id: `${doc.reportCode}.${doc.availableFormats}`,
      reportCode: doc.reportCode,
      availableFormats: doc.availableFormats,
      status: doc.recordStatus
        ?.reportStatus as BusinessDocumentCatalogRowType['status'],
      isSelectable: !doc.recordStatus,
      provider: doc.provider,
      pricingTier: doc.priceTierCode
        ? t('documentCatalog.tier', {
            tierCode: doc.priceTierCode,
          })
        : '-',
    })) || []

  return {
    rows,
    isFetched,
    isLoading: isFetching || isRefetching,
    refetch,
  }
}
