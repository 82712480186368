import React, { useEffect } from 'react'

import { Controller, useForm } from 'react-hook-form'

import { FrankieButton, FrankieLoader } from 'frankify/src'

import { ApplicantId } from 'entities/applicant'

import { TextAreaFormField } from 'shared/form'
import { useI18n } from 'shared/i18n'

import { APPLICANT_FRAUD_CHECK_KEY } from '../../applicant-fraud-check.key'
import { applicantFraudCheckEn } from '../../locale/applicant-fraud-check.en'
import {
  FraudStatusTypes,
  FraudStatusUpdateFormData,
} from '../../model/applicant-fraud-check.model'
import { applicantFraudCheckStatusChangeQa } from '../../qa/applicant-fraud-check.qa'
import { useApplicantFraudStatusChange } from '../../state/applicant-fraud-status-change/applicant-fraud-status-change'

type Props = {
  handleClose: () => void
  fraudListIds: string[]
  checkId: string
  applicantId: ApplicantId
}

export function FraudStatusChangeForm({
  handleClose,
  applicantId,
  checkId,
  fraudListIds,
}: Props) {
  const t = useI18n([APPLICANT_FRAUD_CHECK_KEY], {
    keys: applicantFraudCheckEn,
  })

  const {
    watch,
    reset,
    formState: { isValid },
    control,
    handleSubmit,
  } = useForm<FraudStatusUpdateFormData>({
    defaultValues: { fraudListIds, checkId },
  })

  const { mutate, isLoading, isSuccess } = useApplicantFraudStatusChange({
    applicantId,
  })

  const handleFormSubmit = (data: FraudStatusUpdateFormData) => mutate(data)

  useEffect(() => {
    if (isSuccess) {
      handleClose()
    }
  }, [isSuccess])

  const status = watch('status')

  return (
    <div>
      <div className="w-full h-full shadow-lg rounded-sm absolute flex items-center justify-center" />
      <FrankieLoader loading={isLoading} label={t('updating')}>
        <form
          data-qa={applicantFraudCheckStatusChangeQa.form}
          onSubmit={handleSubmit(handleFormSubmit)}
          className="min-w-[340px] bg-mono-white p-4 shadow-lg rounded-sm"
        >
          <FrankieButton
            testId={{ button: applicantFraudCheckStatusChangeQa.closeBtn }}
            singleIcon={{
              name: 'mdiClose',
              size: 'sm',
            }}
            noStyles
            onClick={handleClose}
            className="absolute top-2 right-2 text-tertiary-grey-300"
          />
          <div>
            <div className="font-bold mt-2">
              {t('statusChange.form.changeMatchStatus')}
            </div>
            <Controller
              render={({ field }) => (
                <div className="flex justify-between items-center mb-3">
                  <FrankieButton
                    className={`basis-[46%]  border border-solid font-extrabold rounded-sm p-1 flex-grow-0 mt-2 ${
                      status === FraudStatusTypes.TRUE_POSITIVE
                        ? 'text-primary-700 border-primary-700 bg-primary-50'
                        : 'text-tertiary-grey-600 border-neutral-100 hover:border-primary-700 hover:bg-mono-white hover:text-primary-700'
                    }`}
                    intent="secondary"
                    onClick={() =>
                      field.onChange(FraudStatusTypes.TRUE_POSITIVE)
                    }
                  >
                    {t('statusChange.form.truePositive')}
                  </FrankieButton>
                  <FrankieButton
                    className={`basis-[46%]  border border-solid font-extrabold rounded-sm p-1 flex-grow-0 mt-2 ${
                      status === FraudStatusTypes.FALSE_POSITIVE
                        ? 'text-primary-700 border-primary-700 bg-primary-50'
                        : 'text-tertiary-grey-600 border-neutral-100 hover:border-primary-700 hover:bg-mono-white hover:text-primary-700'
                    }`}
                    intent="secondary"
                    onClick={() =>
                      field.onChange(FraudStatusTypes.FALSE_POSITIVE)
                    }
                  >
                    {t('statusChange.form.falsePositive')}
                  </FrankieButton>
                </div>
              )}
              name="status"
              control={control}
              rules={{ required: true }}
            />
          </div>
          <TextAreaFormField
            label={t('statusChange.form.comment')}
            name="comment"
            placeholder={t('statusChange.form.typeYourComment')}
            control={control}
            rules={{ required: true }}
            testId={{ input: applicantFraudCheckStatusChangeQa.textArea }}
          />
          <div className="flex justify-between items-center">
            <FrankieButton
              type="submit"
              disabled={!isValid}
              className="max-w-[70] font-extrabold rounded-sm p-1 flex-grow-0 mt-2"
            >
              {t('statusChange.form.save')}
            </FrankieButton>
            <FrankieButton
              className="text-tertiary-grey-600 border-0 font-extrabold rounded-sm p-1 hover:text-primary-700 hover:bg-mono-white flex-grow-0 mt-2"
              intent="secondary"
              type="reset"
              onClick={() => {
                reset()
                handleClose()
              }}
            >
              {t('statusChange.form.cancel')}
            </FrankieButton>
          </div>
        </form>
      </FrankieLoader>
    </div>
  )
}
