import { useQuery } from '@tanstack/react-query'

import { isNotEmptyString } from 'shared/typescript'

import { addressF2Api } from '../api/address-f2.api'

export const GetAddressSuggestionF2QueryKey = ['get-address-suggestion-f2']

const sleep = (ms = 1000) =>
  new Promise(resolve => {
    void setTimeout(resolve, ms)
  })

export const useGetAddressSuggestion = (
  searchTerm: string,
  regions: string[],
) =>
  useQuery({
    queryKey: [GetAddressSuggestionF2QueryKey, searchTerm, regions],
    queryFn: async ({ signal }) => {
      await sleep(250)
      if (!signal?.aborted) {
        const { data } = await addressF2Api.getAddressSuggestion(
          searchTerm || '',
          regions,
          signal,
        )
        return data
      }
      return undefined
    },
    enabled: isNotEmptyString(searchTerm || '') && searchTerm.length >= 3,
  })
