import { getLocalStorageState, StorageKeyTypes } from '../../local-storage'
import { ITracker } from '../model/tracker.model'
import {
  TrackerNameTypes,
  TrackingEventsPerTracker,
  TrackingEventsTypeF2Common,
  TrackingEventsTypes,
  TrackingEventsTypesF1,
} from '../model/tracking-events.enum'

export class TrackingManagerService {
  private trackers: ITracker[] = []

  register(tracker: ITracker) {
    this.trackers.push(tracker)
  }

  getTracker(name: TrackerNameTypes) {
    return this.trackers.find(item => item.name === name)
  }

  isTrackerExists(name: TrackerNameTypes) {
    return this.trackers.some(item => item.name === name)
  }

  _template(str: string, data?: Record<string, string>) {
    if (!data) return str
    let template = str
    for (const key in data) {
      if (template.includes(`{${key}}`))
        template = template.replaceAll(`{${key}}`, data[key])
    }
    return template
  }

  trackWithProps(eventName: string, properties: Record<string, unknown>) {
    this.track(eventName, undefined, properties)
  }

  track(
    name: string,
    data?: Record<string, string>,
    properties?: Record<string, unknown>,
  ) {
    const [pageData] = getLocalStorageState(StorageKeyTypes.PageData)

    const isFrankie2Customer = !!(
      pageData as { features?: { frankie2customer?: boolean } } | undefined
    )?.features?.frankie2customer

    // not emit F1 events for Frankie2 customers and F2 common events for non-Frankie2 customers
    if (isFrankie2Customer) {
      if (TrackingEventsTypesF1.includes(name as TrackingEventsTypes)) {
        return
      }
    } else if (
      TrackingEventsTypeF2Common.includes(name as TrackingEventsTypes)
    ) {
      return
    }

    console.debug('Tracking event:', this._template(name, data), properties)

    this.trackers.forEach(tracker => {
      if (
        TrackingEventsPerTracker[tracker.name].includes(
          name as TrackingEventsTypes,
        )
      ) {
        const parsedName = this._template(name, data)
        tracker.track(parsedName, properties)
      }
    })
  }
}

export const trackingManager = new TrackingManagerService()
