import { bffClient, IClient } from 'shared/client'

import { SelectedAccounts, DateRange } from '../model/dashboard-form.model'
import { AnalyticsResponse } from '../model/dashboard.model'

export class DashboardAPI {
  client: IClient

  constructor(client: IClient) {
    this.client = client
  }

  async getAnalytics({ startDate, endDate }: DateRange) {
    return this.client.get<AnalyticsResponse>('data/v1/analytics', {
      params: {
        startDate,
        endDate,
      },
    })
  }

  async getAnalyticsCSV(data: SelectedAccounts & DateRange) {
    return this.client.get<BlobPart>('data/v1/analytics.csv', {
      params: {
        startDate: data.startDate,
        endDate: data.endDate,
        selectedCustomerIds: [
          ...(data.isOwnSelected ? ['own'] : []),
          ...data.customerChildIds,
        ].join(','),
      },
    })
  }

  async generateReport(data: {
    password: string
    reportName: 'full'
    requester: string
  }) {
    return this.client.post('data/v1/generate-report', data)
  }
}
export const dashboardApi = new DashboardAPI(bffClient)
