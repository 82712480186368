import React, { useMemo } from 'react'

import { NavLink } from 'react-router-dom'

import { FrankieIcon, FrankieTooltip } from 'frankify/src'

import { useI18n } from 'shared/i18n'
import { PartialRecord } from 'shared/typescript'

import { APPLICANT_KEY, applicantEn } from '../../locale/applicant.en'
import {
  ApplicantIssueBadgeColorType,
  alertListRecord,
} from '../../model/applicant-issue.model'

export type ApplicantIssueActionBadgeData = {
  type: string
  masterLabel?: string
  redirectTo?: string
  isDisabled?: boolean
}

type Props = ApplicantIssueActionBadgeData & {
  className?: string
  showTooltip?: boolean
  showSkeleton?: boolean
  hideBg?: boolean
  prioritiesLongLabel?: boolean
}

// eslint-disable-next-line complexity
export function ApplicantIssueActionBadge({
  type,
  isDisabled = false,
  masterLabel,
  className = '',
  showTooltip = false,
  redirectTo,
  showSkeleton = false,
  hideBg = false,
  prioritiesLongLabel = false,
}: Props) {
  const t = useI18n([APPLICANT_KEY], {
    keys: applicantEn,
  })

  const { iconName, label, shortLabel, tooltip } = useMemo(() => {
    const { icon, labelTKey, shortTKey, tooltipTKey } =
      alertListRecord[type] ?? {}

    const result = {
      iconName: icon,
      label: labelTKey ? t(`issue.label.${labelTKey}`) : type,
      tooltip: tooltipTKey ? t(`issue.tooltip.${tooltipTKey}`) : type,
      shortLabel: shortTKey ? t(`issue.shortLabel.${shortTKey}`) : false,
    }

    // Overriding shortLabel if name is provided as shortLevel has priority
    if (masterLabel) {
      result.shortLabel = masterLabel
    }

    return result
  }, [masterLabel, t, type])

  const bgClass = useMemo(() => {
    if (hideBg) {
      return ''
    }

    const bgColorClass: PartialRecord<ApplicantIssueBadgeColorType, string> = {
      blue: '!bg-primary-100 after:!bg-tertiary-primary-100',
      neutral20: '!bg-tertiary-grey-100 after:!bg-tertiary-grey-100',
      lightYellow: '!bg-tertiary-amber-200 after:!bg-tertiary-amber-200',
      extraLightYellow: '!bg-tertiary-yellow-100 after:!bg-tertiary-yellow-100',
      darkNavy: '!bg-tertiary-grey-500 after:!bg-tertiary-grey-500',
      extraLightGreen: '!bg-tertiary-green-100 after:!bg-tertiary-green-100',
      fYellow: '!bg-tertiary-yellow-500 after:!bg-tertiary-yellow-500',
      green: '!bg-tertiary-green-400 after:!bg-tertiary-green-400',
      lightRed: '!bg-tertiary-red-100 after:!bg-tertiary-red-100',
      midLightGrey: '!bg-tertiary-grey-300 after:!bg-tertiary-grey-300',
      navy: '!bg-tertiary-grey-400 after:!bg-tertiary-grey-400',
      red: '!bg-tertiary-red-500 after:!bg-tertiary-red-500',
      darkRed: '!bg-tertiary-red-700 after:!bg-tertiary-red-700',
    }

    const colorType = alertListRecord[type]?.colorType
    const colorClass = colorType ? bgColorClass[colorType] : undefined

    return colorClass ?? 'bg-tertiary-grey-800'
  }, [type, hideBg])

  const textClass = useMemo(() => {
    const textColorClass: PartialRecord<ApplicantIssueBadgeColorType, string> =
      {
        neutral20: '!text-tertiary-grey-500',
        lightRed: '!text-tertiary-red-500',
        blue: '!text-primary-500',
        extraLightYellow: '!text-tertiary-yellow-700',
        midLightGrey: '!text-tertiary-grey-800',
        extraLightGreen: '!text-tertiary-green-600',
      }

    const colorType = alertListRecord[type]?.colorType
    const colorClass = colorType ? textColorClass[colorType] : undefined

    return colorClass ?? 'text-mono-white'
  }, [type])

  const showLongLabel = prioritiesLongLabel && label

  if (showSkeleton) {
    return (
      <div
        className={`animate-pulse h-10 bg-tertiary-grey-300 rounded-sm ${className}`}
      />
    )
  }

  if (redirectTo) {
    return (
      <NavLink
        className={`flex gap-2 px-4 py-2 item-center ${bgClass} text-mono-white rounded-sm ${className}`}
        to={redirectTo}
        onClick={e => {
          if (isDisabled) e.preventDefault()
        }}
      >
        <span className="font-bold"> {masterLabel}</span>
        <FrankieIcon
          name="mdiArrowRight"
          size="sm"
          className="text-mono-white"
        />
      </NavLink>
    )
  }

  return (
    <FrankieTooltip
      title={tooltip}
      position="left"
      className={`${bgClass} min-w-[250px]`}
      textClassName={textClass}
      hidden={!showTooltip}
    >
      <div
        className={`flex items-center justify-center px-2 py-2 font-bold whitespace-nowrap w-min text-center ${className} ${bgClass} ${textClass} rounded-sm`}
      >
        {iconName && (
          <FrankieIcon name={iconName} className={shortLabel ? 'pe-2' : ''} />
        )}
        {showLongLabel && <div className="capitalize">{label}</div>}
        {!showLongLabel &&
          (shortLabel ? (
            <div>{shortLabel}</div>
          ) : (
            !iconName && <div className="capitalize">{label}</div>
          ))}
      </div>
    </FrankieTooltip>
  )
}
