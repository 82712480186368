export const kybSearchV2En = {
  errorCreatingProfileROW:
    'There was an issue generating an organisation profile. Please try again',
  retrieveList: 'Retrieve the list of registry documents on this organisation',
  retrieveListButton: 'Retrieve list',
  listAlreadyRetrieved: 'Purchase registry documents on this organisation',
  listAlreadyRetrievedButton: 'View document list',
  retrievingList:
    'Hold tight, the entity is being created to retrieve the registry documents.',
}
