import React, { useEffect, useMemo, useState } from 'react'

import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { FrankieButton, FrankieVerticalTabs } from 'frankify/src'

import {
  ApplicantSupportingDocuments,
  TrustAnalyzer,
  OrganisationDocumentsCatalog,
  OrganisationPurchasedDocuments,
  TrustAnalyzerDocument,
} from 'features/applicant-supporting-documents'
import { useApplicantQuery } from 'features/monitoring-overview'

import {
  ApplicantId,
  DocumentTabTypes,
  useApplicantPaths,
  useApplicantInternationalKYB,
} from 'entities/applicant'
import { useHasFeatureFlag } from 'entities/session'

import { useI18n } from 'shared/i18n'
import { SuggestionString } from 'shared/typescript'

import { APPLICANT_SUPPORTING_DOCUMENTS_PAGE_KEY } from '../applicant-supporting-documents-page.key'
import { applicantSupportingDocumentsQa } from '../qa/applicant-supporting-documents-page.qa'

const PARAM_KEY = 'documentId'

type Props = {
  applicantIdParamKey: ApplicantId
}

// eslint-disable-next-line complexity
export function ApplicantProfileSupportingDocuments({
  applicantIdParamKey,
}: Props) {
  const params = useParams()
  const { getApplicantPath } = useApplicantPaths()
  const { trustAnalyser } = useHasFeatureFlag({
    trustAnalyser: 'trustAnalyser',
  })

  const t = useI18n([APPLICANT_SUPPORTING_DOCUMENTS_PAGE_KEY])

  const [activeTab, setActiveTab] = useState(DocumentTabTypes.UPLOADED)

  const [open, setOpen] = useState(true)

  const paramsEntityId = params[applicantIdParamKey]

  const { data: applicantData } = useApplicantQuery({
    entityId: paramsEntityId,
  })

  const { state, search } = useLocation()

  const navigate = useNavigate()

  const { isInternationalKYB } = useApplicantInternationalKYB({
    applicantId: paramsEntityId,
  })

  const handleTabChange = (value: string) => {
    setActiveTab(value as DocumentTabTypes)
    navigate(getApplicantPath('supportingDocuments'), {
      state: { activeTabKey: value },
    })
  }

  const documentId = useMemo(() => {
    const params = new URLSearchParams(search)
    return params.get(PARAM_KEY) as SuggestionString<'new'> | undefined
  }, [search])

  useEffect(() => {
    const activeTabKey = state?.activeTabKey as DocumentTabTypes | undefined
    if (activeTabKey) {
      setActiveTab(activeTabKey)
    }
  }, [state])

  const tabItems = useMemo(() => {
    const trustAnalyserTab = {
      value: DocumentTabTypes.TRUSTS,
      label: t('trust'),
      component: TrustAnalyzer,
      testId: applicantSupportingDocumentsQa.trustAnalyzerTab,
    }

    const defaultTabs = [
      {
        value: DocumentTabTypes.UPLOADED,
        label: t('uploadedDocuments'),
        component: ApplicantSupportingDocuments,
      },
      {
        value: DocumentTabTypes.PURCHASED,
        label: t('purchased'),
        component: OrganisationPurchasedDocuments,
      },
      {
        value: DocumentTabTypes.CATALOG,
        label: t('documentCatalog'),
        component: OrganisationDocumentsCatalog,
      },
    ]

    // domestic entity tab and if trust analyser flag is true then show the uploaded documents & trust tab
    if (!isInternationalKYB && trustAnalyser) {
      const uploadedDocumentsTab = defaultTabs[0]
      return [uploadedDocumentsTab, trustAnalyserTab]
    }

    return defaultTabs
  }, [isInternationalKYB, t, trustAnalyser])

  const RenderActiveTab = useMemo(
    () => tabItems.find(tab => tab.value === activeTab)?.component,
    [tabItems, activeTab],
  )

  if (!applicantData) return null

  if (!isInternationalKYB && !trustAnalyser) {
    return (
      <div className="p-10 max-h-[calc(100vh-200px)] overflow-y-auto">
        {paramsEntityId && (
          <ApplicantSupportingDocuments applicantId={paramsEntityId} />
        )}
      </div>
    )
  }
  if (documentId) {
    return <TrustAnalyzerDocument />
  }
  return (
    <div className="relative p-10 gap-2 max-h-[calc(100vh-200px)] flex overflow-y-auto">
      <div
        className={`sticky top-0 transition-[width] ease-out duration-200 ${
          !open ? 'w-[32px] overflow-hidden' : 'w-[200px] pr-6 '
        }`}
      >
        <div className="flex gap-5 text-xl text-secondary-900 font-bold pb-2">
          {open && t('document')}
          <FrankieButton
            noStyles
            className={`flex p-1.5 justify-center items-center text-tertiary-grey-500
              w-8 h-8 rounded-sm
              active:outline focus-visible:outline outline-mono-white focus-visible:outline-3 active:outline-3
              hover:bg-tertiary-grey-100
              hover:text-tertiary-grey-800
              active:bg-primary-50
              focus-visible:bg-primary-50
              active:text-tertiary-grey-800
              focus-visible:text-tertiary-grey-800 ${open ? '' : 'rotate-180'}`}
            singleIcon={{ name: 'mdiArrowCollapseLeft', size: 'sm' }}
            onClick={() => setOpen(o => !o)}
          />
        </div>
        {open && (
          <FrankieVerticalTabs
            activeTab={activeTab}
            onTabChange={handleTabChange}
            tabItems={tabItems}
          />
        )}
      </div>

      {paramsEntityId && RenderActiveTab && (
        <RenderActiveTab applicantId={paramsEntityId} />
      )}
    </div>
  )
}
