import React, { ReactNode } from 'react'

import { NavLink } from 'react-router-dom'

type Props = {
  children: ReactNode
  to: string
  testId: string
}

export function SettingsNavLink({ children, to, testId }: Props) {
  return (
    <NavLink
      className={({ isActive }) =>
        `items-center pb-3 whitespace-nowrap border-b-4 text-sm font-semibold ${
          isActive
            ? 'text-tertiary-grey-700 border-primary-500'
            : 'text-tertiary-grey-500 border-transparent'
        }`
      }
      to={to}
      data-qa={testId}
    >
      {children}
    </NavLink>
  )
}
