import { bffClient, IClient } from 'shared/client'

import {
  IntegrationSetting,
  IntegrationSettingResponse,
  IOrganisationSetting,
  IOrganisationSettingPatch,
} from '../model/organisation-settings.model'

export class OrganisationSettingsApi {
  client: IClient

  constructor(client: IClient) {
    this.client = client
  }

  async getOrganisationSettings() {
    return this.client.get<IOrganisationSetting[]>('/admin/settings')
  }

  async updateOrganisationSettings(patch: IOrganisationSettingPatch) {
    return this.client.post<
      IOrganisationSetting,
      { setting: IOrganisationSettingPatch }
    >('/admin/settings', { setting: patch })
  }

  async updateIntegrationSettings(patch: IntegrationSetting) {
    return this.client.post<IOrganisationSetting, IntegrationSetting>(
      '/data/v1/slack-integration',
      patch,
    )
  }

  async deleteIntegrationSettings() {
    return this.client.delete('/data/v1/slack-integration')
  }

  async getIntegrationSetting() {
    return this.client.get<IntegrationSettingResponse>(
      '/data/v1/slack-integration',
    )
  }
}

export const organisationSettingsApi = new OrganisationSettingsApi(bffClient)
