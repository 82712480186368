import { IProfileResponse, IndividualsEntityResponse } from 'entities/entity'

import { IClient, bffClient } from 'shared/client'

import { IIndividualProfileF2FormType } from '../model/individual-profile-f2-form.model'

export class IndividualApiF2 {
  client: IClient

  constructor(client: IClient) {
    this.client = client
  }

  async createIndividual(
    body: IIndividualProfileF2FormType,
    saveOnly: boolean,
  ) {
    const res = await this.client.post<
      {
        individual: IIndividualProfileF2FormType
        serviceProfiles: IProfileResponse['matches'][0]['serviceProfile'][]
      },
      IIndividualProfileF2FormType
    >('/f2/v1/create-individual-entity', body, { params: { saveOnly } })
    return res
  }

  async updateIndividual(
    body: IIndividualProfileF2FormType,
    saveOnly: boolean,
  ) {
    const res = await this.client.patch<
      IndividualsEntityResponse,
      IIndividualProfileF2FormType
    >(`/f2/v1/update-individual-entity/${body.entityId || ''}`, body, {
      params: { saveOnly },
    })
    return res
  }

  async getIndividualFormValue(entityId: string) {
    const res = await this.client.get<IIndividualProfileF2FormType>(
      `f2/v1/individual/${entityId}/form`,
    )
    return res
  }
}

export const individualApiF2 = new IndividualApiF2(bffClient)
