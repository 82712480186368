import React, {
  ChangeEvent,
  FocusEvent,
  RefCallback,
  useEffect,
  useState,
} from 'react'

import {
  FrankieIcon,
  FrankieTextField,
  FrankieTooltip,
  useFrankieMenu,
} from 'frankify/src'

import { IRole, RoleName } from 'entities/role'
import { IUser } from 'entities/user'

import { useI18n } from 'shared/i18n'
import { Nullable } from 'shared/typescript'

import { rolesToUsersQa } from '../../../qa/roles-to-users.qa'
import { ROLES_TO_USERS_KEY } from '../../../roles-to-users.key'

/**
 * RoleName - selected role
 * null - do not reassign a role
 * '' (empty string) - default value, untouched
 */
export type RoleNameValue = Nullable<RoleName>

type Props = {
  user: IUser
  roles: IRole[]
  value: RoleNameValue
  onChange: (event: { target: { value: RoleNameValue } }) => void
  onBlur: (event: FocusEvent) => void
  name: string
  ref: RefCallback<HTMLInputElement>
  error: boolean
}

export function UserRoleSelector({
  roles,
  user,
  value,
  name,
  ref,
  onBlur,
  onChange,
  error,
}: Props) {
  const t = useI18n([ROLES_TO_USERS_KEY])
  const [rolesSearched, setRolesSearched] = useState<IRole[]>([])
  const { parentRef, isOpen, handleOpen, handleToggle } = useFrankieMenu()

  const hasOtherRoles: boolean = user.roles?.length
    ? user.roles.length > 1
    : false

  useEffect(() => {
    setRolesSearched(
      roles.filter(role =>
        role.role.toLowerCase().includes((value || '').toLowerCase()),
      ),
    )
  }, [roles, value])

  const handleSelectRole = (name: RoleName | null) => () => {
    onChange({ target: { value: name } })
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event)
    handleOpen()
  }

  return (
    <div className="flex flex-initial w-full min-w-0 items-center justify-between">
      <div className="flex flex-initial">
        <div
          data-qa={rolesToUsersQa.reassignRolesForm_userName}
          className="text-md text-tertiary-grey-700 font-semibold"
        >
          {user.realname}
        </div>
        <FrankieTooltip
          noFloating
          className="ml-2.5 -mt-2"
          position="right"
          body={
            <div>
              <div>
                {t('reassignRolesForm.tooltip', { name: user.realname })}
              </div>
              {user.roles?.map((userRole, idx, arr) => (
                <div
                  key={userRole}
                  data-qa={rolesToUsersQa.reassignRolesForm_userTooltipRole}
                >
                  {userRole}
                  {idx !== arr.length - 1 && ','}
                </div>
              ))}
            </div>
          }
          testId={{ tooltip: rolesToUsersQa.reassignRolesForm_userTooltip }}
        >
          <FrankieIcon
            name="mdiInformation"
            className="text-tertiary-grey-500 ml-1"
            size="2xs"
          />
        </FrankieTooltip>
      </div>
      <div className="w-[196px]" ref={parentRef}>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label onBlur={onBlur} className="cursor-text">
          <div className="relative">
            <FrankieTextField
              inputClassName="pr-[30px]"
              value={value ?? t('reassignRolesForm.roleSelect.leaveEmpty')}
              onChange={handleChange}
              name={name}
              ref={ref}
              type="search"
              placeholder={t('reassignRolesForm.roleSelect.placeholder')}
              size="sm"
              onClick={handleToggle}
              error={error}
              testId={{
                input: rolesToUsersQa.reassignRolesForm_roleSelect,
              }}
            />
            <FrankieIcon
              className="absolute bottom-2.5 right-4 text-tertiary-grey-700"
              name="mdiChevronDown"
              size="xs"
            />
          </div>

          {isOpen && (
            <div className="relative">
              <ul className="absolute z-10 top-1 bg-mono-white shadow-md rounded-sm w-[196px] max-h-[152px] py-1 break-words overflow-y-auto scrollbar-sm">
                {hasOtherRoles && (
                  // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                  <li
                    onClick={handleSelectRole(null)}
                    onKeyDown={handleSelectRole(null)}
                    className="text-sm text-tertiary-grey-800 py-2 px-4 cursor-pointer hover:bg-tertiary-grey-100"
                    data-qa={rolesToUsersQa.reassignRolesForm_roleSelectEmpty}
                  >
                    {t('reassignRolesForm.roleSelect.leaveEmpty')}
                  </li>
                )}
                {rolesSearched.map(role => (
                  // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                  <li
                    key={role.id}
                    onClick={handleSelectRole(role.role)}
                    onKeyDown={handleSelectRole(role.role)}
                    className="text-sm text-tertiary-grey-800 py-2 px-4 cursor-pointer hover:bg-tertiary-grey-100"
                    data-qa={rolesToUsersQa.reassignRolesForm_roleSelectOption}
                  >
                    {role.role}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </label>
      </div>
    </div>
  )
}
