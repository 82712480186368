import { useQuery } from '@tanstack/react-query'

import { countryApi } from '../../api/country.api'
import { ICountryOption } from '../../model/country.model'

export const CountriesCoveredQueryKey = ['country-options']

export const useCountriesCoveredQuery = () =>
  useQuery<ICountryOption[]>({
    queryKey: CountriesCoveredQueryKey,
    queryFn: async () => {
      const { data } = await countryApi.getCoveredCountries()
      return data
    },
  })
