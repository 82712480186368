import { RegisterOptions } from 'react-hook-form'

import { IFrankieAlertProps } from 'frankify/src'

import { translateNumber } from 'shared/i18n'
import type { I18nFunction } from 'shared/i18n'

export const MFA_CODE_LENGTH = 6

export enum MfaCodeInputTypes {
  Code = 'code',
}

export interface IMfaCodeInputs {
  [MfaCodeInputTypes.Code]: string
}

export type IAlert = Pick<
  IFrankieAlertProps,
  'children' | 'type' | 'testId'
> | null

export function getResendCodeAttemptsAlert(
  attemptsLeft: number,
  t: I18nFunction,
): IAlert {
  if (attemptsLeft === 1) {
    return {
      type: 'warning',
      children: t('enableMfaEmail.verifyEmail.alerts.resendAttemptsLast'),
    }
  }
  if (attemptsLeft === 0) {
    return {
      type: 'error',
      children: t('enableMfaEmail.verifyEmail.alerts.resendBlocked'),
    }
  }
  return {
    type: 'info',
    children: t('enableMfaEmail.verifyEmail.alerts.resendAttemptsLeft', {
      amount: translateNumber(t, attemptsLeft),
    }),
  }
}

export function getMfaCodeRegisterOptions(t: I18nFunction): RegisterOptions {
  return {
    required: {
      value: true,
      message: t('form.validation.required'),
    },
  }
}
