import React, { forwardRef } from 'react'

import { FrankieBadgeStyle } from './badge.theme'
import { IFrankieBadgeProps } from './badge.types'
import { FrankieButton } from '../button'

/**
 * [Figma Badge]{@link https://www.figma.com/file/anPnGr7dr0GvGzQiwzENV4/FrankieOne-Master-DS?node-id=3335%3A17087&mode=dev}
 */
export const FrankieBadge = forwardRef<HTMLSpanElement, IFrankieBadgeProps>(
  (
    {
      className = '',
      theme,
      text,
      size = 'sm',
      testId = {
        badge: '',
      },
      onClick,
      closeButton,
    }: IFrankieBadgeProps,
    ref,
  ): JSX.Element => (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <span
      onClick={onClick}
      ref={ref}
      className={FrankieBadgeStyle({
        theme,
        size,
        className,
      })}
      data-qa={testId.badge}
    >
      {text}
      {closeButton && (
        <span className="flex flex-row items-center">
          <FrankieButton
            noStyles
            testId={{ button: closeButton.testId?.button }}
            className="text-tertiary-grey-500"
            onClick={closeButton.onClick}
            singleIcon={{
              size: 'xs',
              name: 'mdiClose',
            }}
          />
        </span>
      )}
    </span>
  ),
)
