import React, { useMemo } from 'react'

import { Controller, SubmitHandler, useForm } from 'react-hook-form'

import { FrankieButton } from 'frankify/src'

import { ApplicantIssueTypes } from 'entities/applicant'

import { CheckboxGroup, IOption } from 'shared/filters-common'
import { useI18n } from 'shared/i18n'

import {
  APPLICANTS_FILTER_KEY,
  applicantsFilterEn,
} from '../../locale/applicants-filter.en'
import {
  applicantIssues2Columns,
  applicantIssuesI18n,
} from '../../model/applicants-filter-form.model'
import {
  applicantsGlobalFilterDefault,
  ApplicantsFilterInputTypes,
  IApplicantsGlobalFilter,
} from '../../model/applicants-filter.model'
import { applicantsFilterQa } from '../../qa/applicants-filter.qa'

type IApplicantIssuesFilter = Pick<
  IApplicantsGlobalFilter,
  ApplicantsFilterInputTypes.Issue
>

const defaultValues: IApplicantIssuesFilter = {
  [ApplicantsFilterInputTypes.Issue]:
    applicantsGlobalFilterDefault[ApplicantsFilterInputTypes.Issue],
}

type Props = {
  onClose: () => void
  initialValues?: IApplicantIssuesFilter
  onSubmit: (filters: IApplicantIssuesFilter) => void
}

export function ApplicantIssuesForm({
  onClose,
  initialValues,
  onSubmit,
}: Props) {
  const t = useI18n([APPLICANTS_FILTER_KEY], { keys: applicantsFilterEn })

  const issueOptions = useMemo<IOption<ApplicantIssueTypes>[][]>(
    () =>
      applicantIssues2Columns.map(column =>
        column.map(option => ({
          value: option,
          label: t(applicantIssuesI18n[option]),
        })),
      ),
    [t],
  )

  const {
    handleSubmit,
    control,
    formState: { isDirty },
  } = useForm<IApplicantIssuesFilter>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    values: initialValues || defaultValues,
  })

  const handleClear = () => {
    onSubmit(defaultValues)
    onClose()
  }

  const handleFormSubmit: SubmitHandler<IApplicantIssuesFilter> = data => {
    onSubmit(data)
    onClose()
  }

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} className="w-full p-4">
      <Controller
        control={control}
        name={ApplicantsFilterInputTypes.Issue}
        render={({ field: { onChange, value } }) => (
          <CheckboxGroup
            testId={{ checkbox: applicantsFilterQa.inputs.issueOption }}
            optionGroups={issueOptions}
            onChange={onChange}
            values={value}
          />
        )}
      />
      <div className="flex flex-row items-center gap-3 mt-4">
        <FrankieButton
          testId={{ button: applicantsFilterQa.cta.applySingleFilter }}
          size="xs"
          disabled={!isDirty}
          type="submit"
          className="w-[68px]"
        >
          {t('form.cta.apply')}
        </FrankieButton>
        <FrankieButton
          testId={{ button: applicantsFilterQa.cta.clearSingleFilter }}
          size="xs"
          intent="darkOutline"
          onClick={handleClear}
          className="w-[68px]"
        >
          {t('form.cta.clear')}
        </FrankieButton>
      </div>
    </form>
  )
}
