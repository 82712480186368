import { APPLICANT_GENERAL_INFORMATION_KEY as AGIK } from '../applicant-general-information.key'

/**
 * Migrated from Vue codebase
 */
export const applicantGeneralInformationVueMigratedQa = {
  checkEntityProfile: 'check-entity-profile',
  actionMenuCta: 'dropdown-button',
  checkCta: (title: string) => `${title} button`,
}

const AddAppBLQType = `${AGIK}-add-applicant-blocklist-qa`

export const addApplicantToBlockListQa = {
  selectInput: `${AddAppBLQType}-select`,
  textareaInput: `${AddAppBLQType}-text-area`,
} satisfies Record<string, `${typeof AddAppBLQType}-${string}`>
//
const AddAppWLQType = `${AGIK}-add-applicant-watchList-qa`
export const addApplicantToWatchListQa = {
  select: `${AddAppWLQType}-select`,
  textarea: `${AddAppWLQType}-text-area`,
} satisfies Record<string, `${typeof AddAppWLQType}-${string}`>

const AddAppArchiveQType = `${AGIK}-archive-applicant-qa`
export const archiveApplicantQa = {
  description: `${AddAppArchiveQType}-description`,
} satisfies Record<string, `${typeof AddAppArchiveQType}-${string}`>
// /change it in all place as i did above.

const ChangeAppSQa = `${AGIK}-change-application-status-qa`
export const changeApplicationStatusQa = {
  select: `${ChangeAppSQa}-select`,
  textarea: `${ChangeAppSQa}-text-area`,
} satisfies Record<string, `${typeof ChangeAppSQa}-${string}`>

const DeactivateAQa = `${AGIK}-deactivate-applicant-qa`
export const deactivateApplicantQa = {
  description: `${DeactivateAQa}-description`,
} satisfies Record<string, `${typeof DeactivateAQa}-${string}`>

const RemoveBlockListQa = `${AGIK}-remove-from-blocklist-qa`
export const removeFromBlocklistQa = {
  description: `${RemoveBlockListQa}-description`,
} satisfies Record<string, `${typeof RemoveBlockListQa}-${string}`>

const RemoveAppQa = `${AGIK}-remove-applicant-watchlist`
export const removeApplicantWatchlist = {
  description: `${RemoveAppQa}-description`,
} satisfies Record<string, `${typeof RemoveAppQa}-${string}`>

const UnarchiveQa = `${AGIK}-unarchive-applicant-qa`
export const unarchiveApplicantQa = {
  description: `${UnarchiveQa}-description`,
} satisfies Record<string, `${typeof UnarchiveQa}-${string}`>

const BusinessCompCheck = `${AGIK}-applicant-business-complete-checks-qa`
export const applicantBusinessCompletedChecksQa = {
  completedCheckText: `${BusinessCompCheck}-completed-check`,
} satisfies Record<string, `${typeof BusinessCompCheck}-${string}`>

const VerificationReport = `${AGIK}-generate-verification-report-qa`
export const generateVerificationReportQa = {
  generateReport: `${VerificationReport}-generate-report`,
  containsSummary: `${VerificationReport}-contains-summary`,
} satisfies Record<string, `${typeof VerificationReport}-${string}`>

const BusinessRModal = `${AGIK}-applicant-business-report-modal-qa`
export const applicantBusinessReportModalQa = {
  closeBtn: `${BusinessRModal}-close`,
  reportType: `${BusinessRModal}-report-type`,
  generateReportBtn: `${BusinessRModal}-gnrt-report`,
} satisfies Record<string, `${typeof BusinessRModal}-${string}`>

const ReportRun = `${AGIK}-applicant-business-report-run-qa`
export const applicantBusinessReportRunQa = {
  selectInput: `${ReportRun}-select`,
  generateReportBtn: `${ReportRun}-gnrt-report`,
} satisfies Record<string, `${typeof ReportRun}-${string}`>

const GeneralInfo = `${AGIK}-applicant-general-information-qa`
export const applicantGeneralInformationQa = {
  overViewComp: `${GeneralInfo}-overview`,
} satisfies Record<string, `${typeof GeneralInfo}-${string}`>

const BusinessSummary = `${AGIK}-applicant-business-summary-qa`
export const applicantBusinessSummaryQa = {
  summaryView: `${BusinessSummary}-summary-1`,
} satisfies Record<string, `${typeof BusinessSummary}-${string}`>

const ChkSummary = `${AGIK}-applicant-check-summary`
export const applicantCheckSummaryQa = {
  loader: `${ChkSummary}-loader`,
  frankieIcon: `${ChkSummary}-frankie-icon`,
} satisfies Record<string, `${typeof ChkSummary}-${string}`>

const ChkCard = `${AGIK}-applicant-check-card-qa`
export const applicantCheckCardQa = {
  comp: `${ChkCard}-component`,
  frankieIcon: `${ChkCard}-state-data-icon`,
} satisfies Record<string, `${typeof ChkCard}-${string}`>

const AppOverview = `${AGIK}-applicant-overview-qa`
export const applicantOverviewQa = {
  summary: `${AppOverview}-summary`,
} satisfies Record<string, `${typeof AppOverview}-${string}`>

const AppBusinessOverview = `${AGIK}-applicant-business-overview-qa`
export const applicantBusinessOverviewQa = {
  component: `${AppBusinessOverview}-component`,
  actionMenu: `${AppBusinessOverview}-actionMenu`,
  trustAnalyserBanner: `${AppBusinessOverview}-trustAnalyserBanner`,
  trustAnalyserBannerTitle: `${AppBusinessOverview}-trustAnalyserBannerTitle`,
  trustAnalyserBannerCta: `${AppBusinessOverview}-trustAnalyserBanner-cta`,
} satisfies Record<string, `${typeof AppBusinessOverview}-${string}`>

const ActMap = `${AGIK}-action-mapping-qa`
export const actionMappingQa = {
  archiveBtn: `${ActMap}-archive-btn`,
  deactivateBtn: `${ActMap}-deactivate-btn`,
} satisfies Record<string, `${typeof ActMap}-${string}`>

const BannerMapping = `${AGIK}-banner-mapping-qa`
export const bannerMappingQa = {
  blockListIcon: `${BannerMapping}-blockList-icon`,
  watchListIcon: `${BannerMapping}-watchList-icon`,
} satisfies Record<string, `${typeof BannerMapping}-${string}`>
