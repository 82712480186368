import React from 'react'

import { ApplicantId } from 'entities/applicant'

import { DateFormatTypes, formatDate } from 'shared/date-time'
import { useI18n } from 'shared/i18n'

import { APPLICANT_BUSINESS_OWNERSHIP_INTERNATIONAL_KEY } from '../../applicant-business-ownership-international.key'
import { applicantBusinessOwnershipInternationalEn } from '../../locale/applicant-business-ownership-international.en'
import {
  applicantBusinessOwnershipInternationalQa,
  businessOwnerShipAssociateDataGridQa,
} from '../../qa/applicant-business-ownership-international.qa'
import { useApplicantAssociateInternationalEntityState } from '../../state/applicant-associate-international-entity-query/applicant-associate-international-entity.query'
import { useApplicantInternationalBusinessOwnershipQuery } from '../../state/applicant-business-ownership-international-query/applicant-business-ownership-international.query'
import { ApplicantAddAssociateInternationalEntity } from '../applicant-add-associate-international-entity/applicant-add-associate-international-entity'
import { BusinessOwnershipDataGrid } from '../business-ownership-data-grid/business-ownership-data-grid'
import { ShareCapitalDataGrid } from '../share-capital-data-grid/share-capital-data-grid'

type Props = {
  applicantId: ApplicantId
  className?: string
}

// eslint-disable-next-line complexity
export function ApplicantBusinessOwnershipInternational({
  applicantId,
  className = '',
}: Props) {
  const t = useI18n([APPLICANT_BUSINESS_OWNERSHIP_INTERNATIONAL_KEY], {
    keys: applicantBusinessOwnershipInternationalEn,
  })

  const { data: ownershipData, isLoading } =
    useApplicantInternationalBusinessOwnershipQuery({
      applicantId,
    })

  const { associatedEntry, isLoading: loadingAssociatedEntry } =
    useApplicantAssociateInternationalEntityState({
      applicantId,
    })

  return (
    <div
      className={`text-tertiary-grey-700 ${className}`}
      data-qa={applicantBusinessOwnershipInternationalQa.container}
    >
      <div className="flex flex-col py-4">
        <div className="text-tertiary-grey-800 font-bold text-xl">
          {t('ubo.heading')}
        </div>
        <div className="pt-1 pb-2 text-tertiary-grey-700 text-sm">
          <div>{t('ubo.subHeading')}</div>
          <div>{t('ubo.description')}</div>
        </div>
        <BusinessOwnershipDataGrid
          applicantId={applicantId}
          className="mb-8"
          rows={ownershipData?.ubos || []}
          ownerShipHolderType="ubos"
          isLoading={isLoading}
          emptyText={t('ubo.emptyText')}
        />
        {/* blocking Entity */}
        <div className="text-tertiary-grey-800 font-bold text-xl">
          {t('blockingEntity.heading')}
        </div>
        <div className="pt-1 pb-2 text-tertiary-grey-700 text-sm">
          <div>{t('blockingEntity.subHeading')}</div>
        </div>
        <BusinessOwnershipDataGrid
          applicantId={applicantId}
          className="mb-8"
          rows={ownershipData?.blockingEntities || []}
          ownerShipHolderType="blockingEntities"
          isLoading={isLoading}
          emptyText={t('blockingEntity.emptyText')}
        />
        {/* blocking Entity Ends */}
        <div className="text-tertiary-grey-800 font-bold text-xl">
          {t('otherOwners.heading')}
        </div>
        <div className="pt-1 pb-2 text-tertiary-grey-700 text-sm">
          <div>{t('otherOwners.subHeading')}</div>
        </div>
        <BusinessOwnershipDataGrid
          applicantId={applicantId}
          className="mb-8"
          rows={ownershipData?.otherOwners || []}
          ownerShipHolderType="otherOwners"
          isLoading={isLoading}
          emptyText={t('otherOwners.emptyText')}
        />
        <div className="text-tertiary-grey-800 font-bold text-xl">
          {t('linkedCompanies.heading')}
        </div>
        <div className="pt-1 pb-2 text-tertiary-grey-700 text-sm">
          <div>{t('linkedCompanies.subHeading')}</div>
        </div>
        <BusinessOwnershipDataGrid
          applicantId={applicantId}
          className="mb-8"
          rows={ownershipData?.linkedCompanies || []}
          ownerShipHolderType="linkedCompanies"
          isLoading={isLoading}
          emptyText={t('linkedCompanies.emptyText')}
        />
        <div className="text-tertiary-grey-800 font-bold text-xl">
          {t('officeHolders.heading')}
        </div>
        <div className="pt-1 pb-2 text-tertiary-grey-700 text-sm">
          <div>{t('officeHolders.subHeading')}</div>
        </div>
        <BusinessOwnershipDataGrid
          applicantId={applicantId}
          className="mb-8"
          rows={ownershipData?.officials || []}
          ownerShipHolderType="officials"
          isLoading={isLoading}
          emptyText={t('officeHolders.emptyText')}
        />
        <div className="text-tertiary-grey-800 font-bold text-xl">
          {t('shareCapital.heading')}
        </div>
        <div className="pt-1 pb-2 text-tertiary-grey-700 text-sm">
          <div>{t('shareCapital.subHeading')}</div>
        </div>
        <div className="pb-2 pt-4 text-tertiary-grey-800 font-bold text-md">
          {t('shareCapital.issued')}
        </div>
        <ShareCapitalDataGrid
          className="mb-4"
          shareCapital={ownershipData?.shareCapital ?? { shares: [] }}
          isLoading={isLoading}
        />
        <div className="pb-2 pt-4 text-tertiary-grey-800 font-bold text-md">
          {t('shareCapital.shareHolders')}
        </div>
        <BusinessOwnershipDataGrid
          applicantId={applicantId}
          className="mb-8"
          rows={ownershipData?.shareholders || []}
          ownerShipHolderType="shareholders"
          isLoading={isLoading}
          emptyText={t('shareCapital.emptyText.shareHolder')}
        />
        <div className="text-tertiary-grey-800 font-bold text-xl">
          {t('associatedParties.heading')}
        </div>
        <div className="pt-1 pb-2 text-tertiary-grey-700 text-sm">
          <div>{t('associatedParties.subHeading')}</div>
        </div>
        <BusinessOwnershipDataGrid
          applicantId={applicantId}
          className="mb-1"
          rows={associatedEntry}
          ownerShipHolderType="associatedParties"
          isLoading={loadingAssociatedEntry}
          emptyText={t('associatedParties.emptyText')}
          testId={{
            wrapper: businessOwnerShipAssociateDataGridQa.container,
            dataGrid: businessOwnerShipAssociateDataGridQa.dataGrid,
          }}
        />
      </div>

      <ApplicantAddAssociateInternationalEntity applicantId={applicantId} />

      <div className="my-8">
        <span className="text-tertiary-grey-500 font-medium pr-2">
          {t('lastUpdated')}
        </span>
        <span
          className="font-semibold pr-4"
          data-qa={applicantBusinessOwnershipInternationalQa.createdDate}
        >
          {ownershipData?.reportCreatedAt
            ? formatDate(
                ownershipData.reportCreatedAt,
                DateFormatTypes.ShortDateAndTime,
              )
            : '-'}
        </span>
        <span className="text-tertiary-grey-500 font-medium pr-2">
          {t('frankieId')}
        </span>
        <span className="font-semibold">{applicantId}</span>
      </div>
    </div>
  )
}
