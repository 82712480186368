import React from 'react'

import { MenuItem } from '@mui/material'

import { businessOwnerShipDataGridActionMenuQa } from 'features/applicant-business-ownership-international/qa/applicant-business-ownership-international.qa'

import { useI18n } from 'shared/i18n'
import { useOverlay } from 'shared/overlay'
import { Noop } from 'shared/typescript'

import {
  EditAssociatedPartyModal,
  UpdateAssociatedPartyProps,
} from './edit-associated-party-modal/edit-associated-party-modal'
import { RemoveAssociatedPartyModal } from './remove-associated-party-modal/remove-associated-party-modal'
import { APPLICANT_BUSINESS_OWNERSHIP_INTERNATIONAL_KEY } from '../../../applicant-business-ownership-international.key'
import { applicantBusinessOwnershipInternationalEn } from '../../../locale/applicant-business-ownership-international.en'

type Props = {
  setOpen: (open: boolean) => void
} & UpdateAssociatedPartyProps

export function UpdateAssociatedPartyMenu({
  applicantId,
  entityId,
  setOpen,
}: Props) {
  const t = useI18n([APPLICANT_BUSINESS_OWNERSHIP_INTERNATIONAL_KEY], {
    keys: applicantBusinessOwnershipInternationalEn,
  })

  const [createOverlay, closeOverlay] = useOverlay()

  const editAssociatedParty = () => {
    setOpen(false)

    createOverlay(
      <EditAssociatedPartyModal
        applicantId={applicantId}
        onClose={closeOverlay}
        entityId={entityId}
      />,
      {
        className: 'p-0',
        closeButtonClassName: '!top-6 !right-5',
      },
    )
  }

  const removeEditAssociatedParty = () => {
    setOpen(false)

    createOverlay(
      <RemoveAssociatedPartyModal
        applicantId={applicantId}
        onClose={closeOverlay}
        entityId={entityId}
      />,
      {
        className: 'p-0',
        closeButtonClassName: 'z-10',
      },
    )
  }

  const handleClick =
    (cb: Noop) =>
    (e: React.MouseEvent<HTMLButtonElement | HTMLLIElement, MouseEvent>) => {
      e.stopPropagation()
      e.preventDefault()
      cb()
    }

  return (
    <>
      <MenuItem
        className="py-2 text-sm text-secondary-900 hover:text-primary-600 hover:bg-primary-50"
        onClick={handleClick(editAssociatedParty)}
        data-qa={businessOwnerShipDataGridActionMenuQa.menuAction}
      >
        {t('action.editAssociatedParty')}
      </MenuItem>
      <MenuItem
        className="py-2 text-sm text-secondary-900 hover:text-primary-600 hover:bg-primary-50"
        onClick={handleClick(removeEditAssociatedParty)}
        data-qa={businessOwnerShipDataGridActionMenuQa.menuAction}
      >
        {t('action.removeAssociatedParty')}
      </MenuItem>
    </>
  )
}
