import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { FrankieButton, FrankieIcon } from 'frankify/src'

import { ApplicantId } from 'entities/applicant'

import { getDataFileUrl } from 'shared/file'
import { useI18n } from 'shared/i18n'
import { assertContent } from 'shared/string'

import { APPLICANT_BIOMETRICS_OCR_KEY } from '../../applicant-biometrics-ocr.key'
import { applicantBiometricsOcrEn } from '../../locale/applicant-biometrics-ocr.en'
import {
  ScoreStatus,
  scoreStatus,
} from '../../model/applicant-biometrics-ocr.model'
import { useApplicantBiometryState } from '../../state/applicant-biometrics-ocr-data-state/applicant-biometrics-ocr-data.state'
import { useApplicantFacialDuplicatesQuery } from '../../state/applicant-facial-duplicates-query/applicant-facial-duplicates.query'
import { BiometricAccordionHeader } from '../applicant-biometrics-accordion/applicant-biometrics-accordion'
import { BiometricEntityView } from '../applicant-biometrics-ocr-helper/applicant-biometrics-ocr-helper'

type Props = {
  applicantId: ApplicantId
}

const index = 0

export function ApplicantFacialDuplicateReportPanel({ applicantId }: Props) {
  const t = useI18n([APPLICANT_BIOMETRICS_OCR_KEY], {
    keys: applicantBiometricsOcrEn,
  })

  const [selectedDuplicateId, setSelectedDuplicateId] = useState<string>()

  const { data: facialDuplicatesData, hasFacialDuplicatesFeature } =
    useApplicantFacialDuplicatesQuery({ applicantId })

  const {
    data: { biometryBase64, facialDuplicatesResult },
  } = useApplicantBiometryState({ applicantId, index })

  /**
   * Setting the first duplicate as the selected duplicate
   */
  useEffect(() => {
    const firstDuplicate = facialDuplicatesData?.facialDuplicatesDetails[0]
    if (firstDuplicate) {
      setSelectedDuplicateId(firstDuplicate.applicant.entityId)
    }
  }, [facialDuplicatesData])

  const duplicateImageUrl = useCallback(
    (portrait?: string) =>
      portrait && /base64/.test(portrait)
        ? getDataFileUrl('image/jpeg', portrait)
        : portrait,
    [],
  )

  const {
    createDate,
    applicantData,
    duplicateApplicantData,
    matchScore,
    duplicateApplicantImage,
  } = useMemo(() => {
    const applicantData = facialDuplicatesData?.applicantData
    const duplicateApplicant =
      facialDuplicatesData?.facialDuplicatesDetails.find(
        item => item.applicant.entityId === selectedDuplicateId,
      )

    const duplicatePortrait = duplicateApplicant?.portraitData

    return {
      applicantData,
      applicantImage: biometryBase64.image
        ? getDataFileUrl(
            biometryBase64.image.mimeType,
            biometryBase64.image.file,
          )
        : undefined,
      duplicateApplicantData: duplicateApplicant?.applicant,
      duplicateApplicantImage: duplicateImageUrl(duplicatePortrait),
      matchScore: duplicateApplicant?.duplicateScorePercentage,
      createDate: applicantData?.extraData?.biometric_check_last_initiated,
    }
  }, [
    biometryBase64,
    facialDuplicatesData,
    selectedDuplicateId,
    duplicateImageUrl,
  ])

  const { matchScorePercentage, matchScoreClass } = useMemo(() => {
    const matchScoreClassMap = {
      risk_high: 'border-tertiary-red-500 bg-tertiary-red-50',
      risk_medium: 'border-tertiary-amber-500 bg-tertiary-amber-50',
      risk_low: 'border-tertiary-grey-600 bg-neutral-30',
    } satisfies Record<ScoreStatus, string>

    return {
      matchScorePercentage: `${assertContent(matchScore)} ${
        matchScore ? '%' : ''
      }`,
      matchScoreClass: matchScoreClassMap[scoreStatus(matchScore)],
    }
  }, [matchScore])

  /**
   * If the feature is not enabled, we don't show the panel
   */
  if (!hasFacialDuplicatesFeature) return null

  return (
    <BiometricAccordionHeader
      checkResult={facialDuplicatesResult}
      createdDate={createDate}
      possibleDuplicates={2}
      detailType="facial"
      applicantId={applicantId}
      index={index}
      className="min-w-[934px]"
    >
      <div className="mt-4 flex items-center p-8 bg-neutral-30">
        <div className="basis-2/5">
          <div className="text-lg mb-4 text-tertiary-grey-900">
            {t('submitted')}
          </div>
          <BiometricEntityView applicantData={applicantData} />
        </div>

        <div className="basis-1/5 relative">
          <div className="absolute top-[50%] h-[1px] w-full bg-tertiary-grey-600" />
          <div
            className={`relative z-10 flex flex-col items-center justify-center  h-[100px] max-w-[120px] m-auto border text-tertiary-grey-900 rounded-md ${matchScoreClass}`}
          >
            <div>{t('matchingScore')}</div>
            <div className="font-bold text-[32px]">{matchScorePercentage}</div>
          </div>
        </div>

        <div className="basis-2/5">
          <div className="text-lg mb-4 text-tertiary-grey-900">
            {t('duplicateSelected')}
          </div>
          {duplicateApplicantData ? (
            <BiometricEntityView
              className="flex-row-reverse"
              applicantData={duplicateApplicantData}
              applicantImage={duplicateApplicantImage}
              viewProfile
            />
          ) : (
            <div className="h-[325px] flex flex-col gap-2 items-center justify-center bg-tertiary-green-100 text-tertiary-green-400 rounded-xs">
              <FrankieIcon name="mdiCheckCircle" />
              <div className="font-bold">{t('noPossibleDup')}</div>
            </div>
          )}
        </div>
      </div>
      <div className="my-2">
        <div className="py-4">
          <div className="text-lg ">{t('possibleDuplicates')}</div>
          <div className="border-tertiary-grey-500">{t('clickOnImage')}</div>
        </div>
        <div className="flex gap-4">
          {facialDuplicatesData?.facialDuplicatesDetails.map(
            ({ applicant, duplicateScorePercentage, portraitData }) => (
              <FrankieButton
                noStyles
                key={applicant.entityId}
                onClick={() => setSelectedDuplicateId(applicant.entityId)}
              >
                <BiometricEntityView
                  applicantData={applicant}
                  applicantImage={duplicateImageUrl(portraitData)}
                  score={duplicateScorePercentage}
                  variant="score-card"
                  className={
                    applicant.entityId === selectedDuplicateId
                      ? '!border-primary-600 shadow-md shadow-primary-400'
                      : ''
                  }
                />
              </FrankieButton>
            ),
          )}
        </div>
      </div>
    </BiometricAccordionHeader>
  )
}
