export const applicantsFilterQa = {
  inputs: {
    statusOption: 'applicants-filter_status-option-input',
    issueOption: 'applicants-filter_issue-option-input',
  },
  cta: {
    showFilters: 'applicants-filter_show-filters-cta',
    selectIssues: 'applicants-filter_select-issues-cta',
    selectStatuses: 'applicants-filter_select-statuses-cta',
    clearFilters: 'applicants-filter_clear-filters-cta',
  },
  enabledCategories: 'applicants-filter_enabled-categories-badge',
}
