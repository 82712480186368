import { useMutation, useQueryClient } from '@tanstack/react-query'

import { IRole, RoleId } from 'entities/role'

import { getSingleRoleQueryKey } from './single-role.query'
import { roleManagementApi } from '../../api/role-management.api'
import { RoleListQueryKey } from '../role-list/role-list.query'

export const useDeleteRoleMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ id }: { id: RoleId }) => roleManagementApi.deleteRole(id),
    onSuccess: (data, variables) => {
      const deletedId = variables.id

      const roles = queryClient.getQueryData<IRole[]>(RoleListQueryKey)
      if (roles) {
        const filteredRoles = roles.filter(role => role.id !== deletedId)
        queryClient.setQueryData(RoleListQueryKey, filteredRoles)
      }

      queryClient.removeQueries({
        exact: true,
        queryKey: getSingleRoleQueryKey(deletedId),
      })
    },
  })
}
