import { useMutation, useQueryClient } from '@tanstack/react-query'

import { ApplicantDataQueryKey, ApplicantId } from 'entities/applicant'

import { ErrorResponse } from 'shared/http'
import { notification } from 'shared/notification'

import { blacklistAPI } from '../../apis/blacklist.api'
import { CheckBlocklistPayload } from '../../models/blacklist.model'

export type Args = {
  entityId: ApplicantId
  data: CheckBlocklistPayload
}

export const useCheckBlocklist = () => {
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: async ({ entityId, data }: Args) =>
      blacklistAPI.checkBlocklist(entityId, data),
    onSuccess: (_, data) => {
      const key = [ApplicantDataQueryKey, data.entityId]
      void queryClient.refetchQueries(key)
    },
    onError: (err: ErrorResponse) => {
      notification.error(err.response?.data.message as string)
    },
  })

  return mutation
}
