import React, { useMemo } from 'react'

import { FrankieButton, FrankieIcon } from 'frankify/src'

import { useI18n } from 'shared/i18n'

import { APPLICANT_KEY, applicantEn } from '../../../locale/applicant.en'
import { IFraudCheckpointRule } from '../../../model/applicant-fraud-check.model'

type Props = {
  title: string
  ruleIds: string[] | null
  rules: IFraudCheckpointRule[] | null
  deviceSardineUrl: string | null
}
export function CheckRulesModal({
  title,
  rules,
  ruleIds,
  deviceSardineUrl,
}: Props) {
  const t = useI18n(APPLICANT_KEY, { keys: applicantEn })

  const liveRules = useMemo<IFraudCheckpointRule[]>(
    () =>
      rules?.filter(
        rule => rule.isLive && rule.ruleId && ruleIds?.includes(rule.ruleId),
      ) ?? [],
    [rules, ruleIds],
  )
  const shadowRules = useMemo<IFraudCheckpointRule[]>(
    () =>
      rules?.filter(
        rule => !rule.isLive && rule.ruleId && ruleIds?.includes(rule.ruleId),
      ) ?? [],
    [rules, ruleIds],
  )

  const headCss =
    'font-medium py-2 px-3 text-tertiary-grey-500 bg-tertiary-grey-50 border-b border-solid border-tertiary-grey-200'
  const rowCss =
    'py-[18px] px-3 border-b border-solid border-tertiary-grey-200 w-full text-left'

  if (!liveRules.length && !shadowRules.length)
    return (
      <div
        className="py-[30px] flex flex-col items-center justify-center text-center gap-3
           rounded-1.5 text-tertiary-grey-800"
      >
        <FrankieIcon
          name="mdiAlertCircleOutline"
          size="75px"
          className="text-primary-300"
        />
        <div className="text-lg font-bold">
          {t('fraud.checkRules.placeholder.title')}
        </div>
        <div className="text-sm font-normal max-w-[336px]">
          {t('fraud.checkRules.placeholder.description')}
        </div>
      </div>
    )

  return (
    <div>
      <div className="text-2xl font-bold text-tertiary-grey-900 mb-2.5">
        {title}
      </div>
      <div className="flex flex-col gap-[30px]">
        {liveRules.length > 0 && (
          <div>
            <div className={headCss}>{t('fraud.checkRules.executed')}</div>
            {liveRules.map(rule => (
              <FrankieButton
                onClick={() =>
                  deviceSardineUrl && window.open(deviceSardineUrl, '_blank')
                }
                noStyles
                key={rule.ruleId}
                className={rowCss}
              >
                {rule.ruleName}
              </FrankieButton>
            ))}
          </div>
        )}
        {shadowRules.length > 0 && (
          <div>
            <div className={headCss}>{t('fraud.checkRules.shadow')}</div>
            {shadowRules.map(rule => (
              <FrankieButton
                onClick={() =>
                  deviceSardineUrl && window.open(deviceSardineUrl, '_blank')
                }
                noStyles
                key={rule.ruleId}
                className={rowCss}
              >
                {rule.ruleName}
              </FrankieButton>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}
