export const PASSWORD_RESET_PAGE_LOCALE = 'passwordResetPage'

export const passwordResetPageEn = {
  validation: {
    email: {
      label: 'Email',
      required: 'Email is required',
      pattern: 'Please enter a valid email address',
    },
    password: {
      label: 'Password',
      required: 'Password is required',
    },
  },
  container: {
    heading: 'Reset password',
    headingExpired: 'This link has expired',
    subheadingExpiredFirst:
      'Password links are valid for 1 hour from time of request.',
    subheadingExpiredSecond: 'Please request a new link below.',
    subheading: {
      success: 'Please check your email',
    },
    description: {
      request:
        'Please enter your email address below and we will send you a reset password link.',
    },
  },
  form: {
    button: {
      submit: {
        default: 'Send link',
        new: 'Send new link',
        loading: 'Sending link..',
      },
      backToLogin: {
        default: 'Back to login',
      },
    },
    requestError:
      'There was an issue during the request. Please retry the password reset process.',
  },
}
