import { useMutation } from '@tanstack/react-query'

import {
  ApplicantQueryKeysTypes,
  useApplicantDataQuery,
  useUpdateApplicantQueries,
} from 'entities/applicant'
import { organisationApi, CheckParams } from 'entities/organisation'

import { ErrorResponse } from 'shared/http'
import { notification } from 'shared/notification'

type Args = {
  applicantId: string
}

type ChecksToRun = { checksToRun: (keyof CheckParams)[] }

export const useApplicantCheckTrigger = ({ applicantId }: Args) => {
  const { refetch: refetchApplicantData } = useApplicantDataQuery({
    applicantId,
  })

  const { removeQueries } = useUpdateApplicantQueries({ applicantId })

  const mutation = useMutation({
    mutationFn: ({ checksToRun = [] }: ChecksToRun) =>
      organisationApi.checkAmlPep(
        applicantId,
        checksToRun.reduce((value, item) => ({ ...value, [item]: true }), {
          forceCheck: false,
          noInvalidate: false,
          uploadDocs: false,
        } as CheckParams),
      ),
    onSuccess: () => {
      void removeQueries(ApplicantQueryKeysTypes.ApplicantPepSactionsQueryKey)
      void refetchApplicantData()
    },
    onError: (err: ErrorResponse) => {
      notification.error(err.response?.data.message)
    },
  })

  return mutation
}
