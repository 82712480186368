import { ApplicantId } from 'entities/applicant'

import { IClient, bffClient } from 'shared/client'
import { Nullable } from 'shared/typescript'

import {
  BlacklistResponseType,
  CheckBlocklistPayload,
  CheckDuplicateType,
} from '../models/blacklist.model'

export class BlacklistAPI {
  client: IClient

  constructor(client: IClient) {
    this.client = client
  }

  async getBlacklist(applicantId: ApplicantId) {
    return this.client.get<BlacklistResponseType[]>(
      `data/v1/applicants/${applicantId}/checks/blacklists`,
      { params: { applicantId } },
    )
  }

  async checkBlocklist(entityId: ApplicantId, payload: CheckBlocklistPayload) {
    return this.client.put(
      `data/v1/applicants/${entityId}/checks/blacklists`,
      payload,
    )
  }

  async checkDuplicate(
    ids: { applicantId: ApplicantId; matchedApplicantId: ApplicantId },
    data: CheckDuplicateType,
  ) {
    return this.client.put<CheckDuplicateType, Nullable<unknown>>(
      `data/v1/applicants/${ids.applicantId}/checks/duplicates/${ids.matchedApplicantId}`,
      data,
    )
  }

  async getDuplicate(applicantId: ApplicantId) {
    return this.client.get<BlacklistResponseType[]>(
      `data/v1/applicants/${applicantId}/checks/duplicates`,
    )
  }
}

export const blacklistAPI = new BlacklistAPI(bffClient)
