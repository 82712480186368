import React, { useEffect, useRef } from 'react'

import { GridRow, GridRowId, GridRowParams } from '@mui/x-data-grid-pro'

import { FrankieIcon, FrankieLoader } from 'frankify/src'

import { ErrorResponse } from 'shared/http'
import { I18nFunction, useI18n } from 'shared/i18n'

import { APPLICANT_AUDIT_REPORT_KEY } from '../../applicant-audit-report.key'
import { applicantAuditReportEn } from '../../locale/applicant-audit-report.en'
import { AuditRecordsData } from '../../model/applicant-audit-report.model'
import { applicantAuditReportTableSlotQa } from '../../qa/applicant-audit-report.qa'

/**
 * To trigger the expandable row
 */
export function AuditTableDetailPanel() {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>
}

export type AuditRowProps = GridRowParams<AuditRecordsData> & {
  t: I18nFunction<typeof applicantAuditReportEn>
  expandedRowIds: GridRowId[]
}

export function AuditTableRow({
  t,
  expandedRowIds,
  ...gridRowProps
}: AuditRowProps) {
  const { row } = gridRowProps

  const contentRef = useRef<HTMLDivElement>(null)

  const isExpanded = expandedRowIds.includes(row.id)

  useEffect(() => {
    if (!contentRef.current) return
    const contentHeight = contentRef.current.scrollHeight

    if (isExpanded) {
      contentRef.current.style.maxHeight = `${contentHeight}px`

      // Set a short delay to allow the expanded state to update before applying the transition
      const delay = setTimeout(() => {
        if (!contentRef.current) return

        contentRef.current.style.maxHeight = '1000px' // Set to a large enough value
        clearTimeout(delay)
      }, 50)
    } else {
      contentRef.current.style.maxHeight = `${contentHeight}px`
    }
  }, [isExpanded, row.id])

  return (
    <div className="transition-all overflow-hidden">
      <div
        ref={contentRef}
        className="transition-[max-height] ease-in-out duration-300"
      >
        <GridRow {...gridRowProps} />
        <div
          className={`px-4 bg-neutral-30 text-right text-xs text-neutral-100 font-medium ${
            isExpanded ? 'h-[25px] opacity-100 py-1' : 'h-0 opacity-0'
          } transition-opacity ease-in-out duration-300`}
        >{`${t('requestId')}: ${row.requestId}`}</div>
      </div>
    </div>
  )
}

/**
 * Audit Report table loading overlay
 */
export function AuditReportLoadingOverlay() {
  const t = useI18n([APPLICANT_AUDIT_REPORT_KEY], {
    keys: applicantAuditReportEn,
  })

  return (
    <div
      data-qa={applicantAuditReportTableSlotQa.loadingOverlay}
      className="flex align-center justify-center flex-col w-full h-full bg-mono-white opacity-80"
    >
      <div>
        <div id="loader">
          <FrankieLoader
            loading
            className="min-h-[76px]"
            size="sm"
            testId={{ loader: applicantAuditReportTableSlotQa.loader }}
          />
        </div>
        <div className="flex flex-col items-center mt-3">
          <span className="inline-block text-md font-semibold leading-6 text-tertiary-grey-800">
            {t('records.fetching')}
          </span>
        </div>
      </div>
    </div>
  )
}

export function AuditReportNoRowOverlay({
  error,
}: {
  error: ErrorResponse | null
}) {
  const t = useI18n([APPLICANT_AUDIT_REPORT_KEY], {
    keys: applicantAuditReportEn,
  })

  return (
    <div
      className="flex align-center justify-center flex-col w-full h-full bg-mono-white opacity-80"
      data-qa={applicantAuditReportTableSlotQa.main}
    >
      <div>
        <FrankieIcon
          size="lg"
          name="mdiFileSearchOutline"
          className="text-primary-300 flex justify-center"
        />
        <div
          className="flex flex-col items-center mt-3"
          data-qa={applicantAuditReportTableSlotQa.error}
        >
          <span className="inline-block text-md font-semibold leading-6 text-tertiary-grey-800">
            {error?.message || t('records.empty')}
          </span>
        </div>
      </div>
    </div>
  )
}
