import { useQuery } from '@tanstack/react-query'

import { IRole } from 'entities/role'

import { removeDuplicates } from 'shared/array'

import {
  ApiFrankieType,
  roleManagementApi,
} from '../../api/role-management.api'
import { ROLE_MANAGEMENT_KEY } from '../../role-management.key'

export const RoleListQueryKey = [`${ROLE_MANAGEMENT_KEY}_role-list`]

export const useRoleListQuery = ({
  canFetchRoleList,
  version = 'f1',
}: {
  canFetchRoleList: boolean
  version?: ApiFrankieType
}) =>
  useQuery<IRole[]>({
    queryKey: RoleListQueryKey,
    queryFn: async () => {
      if (!canFetchRoleList) {
        throw new Error('No access')
      }
      const { data } = await roleManagementApi.getRoleList(version)
      return removeDuplicates(data, role => role.id)
    },
  })
