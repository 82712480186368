import React, { useMemo } from 'react'

import { NavLink } from 'react-router-dom'

import { FrankieButton, FrankieIcon, FrankieTooltip } from 'frankify/src'

import {
  useApplicantPaths,
  AlertList,
  ApplicantFraudCheckResult,
  ApplicantResponse,
} from 'entities/applicant'

import { useI18n } from 'shared/i18n'

import { APPLICANT_PERSONAL_INFO_KEYS } from '../../applicant-persona-info.keys'
import { applicantPersonalInfoEn } from '../../locale/applicant-personal-info.en'
import {
  BannerStatesTypes,
  bannerBgColor,
  bannerTextColor,
  getApplicantName,
  getTooltipDesc,
  isEditProfileDisabled,
} from '../../model/applicant-detail-view.model'
import { applicantPersonalInfoQa } from '../../qa/applicant-personal-info.qa'
import { DocumentInfo } from '../applicant-document-info/applicant-document-info'
import { ApplicantIndividualVerificationReport } from '../applicant-individual-verification-report/applicant-individual-verification-report'
import { ApplicantMatchedRulesets } from '../applicant-matched-rulesets/applicant-matched-rulesets'
import { ApplicantNameComparison } from '../applicant-name-comparison/applicant-name-comparison'
import { PersonalInfo } from '../personal-info/personal-info'

type Props = {
  applicantData: ApplicantResponse
  canSeeFraudCheck: boolean
}

// eslint-disable-next-line complexity
export function ApplicantPersonalInfoKyc({
  applicantData,
  canSeeFraudCheck,
}: Props) {
  const t = useI18n(APPLICANT_PERSONAL_INFO_KEYS, {
    keys: applicantPersonalInfoEn,
  })

  const { getApplicantPath } = useApplicantPaths()

  const isLegacyProfile = !applicantData.checkSummary.checkResults.length
  const isBusinessType = !!applicantData.businessInfo

  const tooltip = useMemo(
    () => ({
      text: getTooltipDesc(applicantData, t),
      isInActive: isEditProfileDisabled(applicantData),
    }),
    [applicantData, t],
  )

  const hasOcrExpiryDisclaimer = useMemo(
    () =>
      applicantData.documents.some(
        document => document.extraData?.ocr_scanned_date_of_expiry,
      ),
    [applicantData.documents],
  )

  const bannerContent = useMemo(() => {
    const { alertList } = applicantData.checkSummary
    if (!alertList) return null
    const includeTerm = (alert: AlertList, terms: string[]) =>
      terms.includes(alert.term)
    const partialMatch = alertList.some(i => includeTerm(i, ['partial']))
    const noMatch = alertList.some(i => includeTerm(i, ['404', '500']))
    const unchecked = applicantData.checkSummary.status.key === 'UNCHECKED'
    const applicantName = getApplicantName(applicantData.applicantDetails.name)

    if (partialMatch)
      return {
        desc: t('partialMatch.desc', { applicantName }),
        resultType: BannerStatesTypes.PartialMatch,
        type: t('partialMatch.title'),
        value: false,
        tooltip: {
          title: t('partialMatch.title'),
          desc: t('partialMatch.tooltipDesc'),
        },
      }
    if (noMatch)
      return {
        desc: t('noMatch.desc', { applicantName }),
        resultType: BannerStatesTypes.NoMatch,
        type: t('noMatch.title'),
        value: false,
        tooltip: {
          title: t('noMatch.title'),
          desc: t('noMatch.tooltipDesc'),
        },
      }

    if (unchecked)
      return {
        desc: t('unchecked.desc'),
        resultType: BannerStatesTypes.Unchecked,
        type: t('unchecked.title'),
        value: null,
      }
    return null
  }, [applicantData, t])

  return (
    <div className="max-w-[1440px] min-w-[640px] mx-auto">
      <div className=" my-10 pt-8 pb-8 px-10 border-[1px] border-tertiary-grey-200 border-solid rounded-md">
        {bannerContent && !isLegacyProfile && (
          <div
            className={`flex justify-between items-center py-3 pl-12 pr-8 mb-8 rounded-sm ${bannerBgColor(
              bannerContent.value,
            )} ${bannerTextColor(bannerContent.value)} `}
          >
            <div className="font-bold">{bannerContent.desc}</div>
            <div className="flex justify-end items-center">
              <div className="mx-3 font-bold">{bannerContent.type}</div>
              <FrankieTooltip
                key="banner-icon"
                position="left"
                hidden={!bannerContent.tooltip}
                title={bannerContent.tooltip?.title}
                body={<div>{bannerContent.tooltip?.desc}</div>}
                className="max-w-[420px]"
              >
                <div>
                  <FrankieIcon
                    name={
                      bannerContent.resultType === BannerStatesTypes.Unchecked
                        ? 'mdiAlertCircle'
                        : 'mdiCloseCircle'
                    }
                    size="md"
                  />
                </div>
              </FrankieTooltip>
            </div>
          </div>
        )}
        <div className="flex justify-between items-center mb-6">
          <div className="text-xl font-[700]">
            {isBusinessType ? t('businessInfo.title') : t('personalInfo.title')}
          </div>
          <FrankieTooltip
            key="edit-btn"
            position="left"
            title={tooltip.text}
            className="max-w-[300px] text-right"
            hidden={!tooltip.text}
          >
            <NavLink to={getApplicantPath('manualIdv')}>
              <FrankieButton
                testId={{ button: applicantPersonalInfoQa.button }}
                size="sm"
                className={`!bg-mono-100 focus:border-0 focus:bg-mono-100 !text-mono-white hover:bg-mono-100 ${
                  tooltip.isInActive ? 'opacity-25 !cursor-not-allowed' : ''
                }`}
                intent="secondary"
                disabled={tooltip.isInActive}
              >
                {t('personalInfo.editCustomerInfo')}
              </FrankieButton>
            </NavLink>
          </FrankieTooltip>
        </div>

        <PersonalInfo
          applicantData={applicantData}
          isLegacyProfile={isLegacyProfile}
          testId={{ container: applicantPersonalInfoQa.personalInfo }}
        />

        {!isBusinessType && (
          <DocumentInfo
            applicantData={applicantData}
            isLegacyProfile={isLegacyProfile}
            testId={{ container: applicantPersonalInfoQa.documentInfo }}
          />
        )}

        <ApplicantMatchedRulesets
          applicantData={applicantData}
          testId={{ container: applicantPersonalInfoQa.matchedRuleSet }}
          isLegacyProfile={isLegacyProfile}
        />

        <ApplicantNameComparison applicantData={applicantData} />
        <div className="flex justify-between items-center mt-3">
          <div className="flex justify-start items-center">
            <div className="mr-3 text-[13px]">{t('frankieId')}</div>
            <div>{applicantData.applicantDetails.entityId}</div>
          </div>
          {hasOcrExpiryDisclaimer && (
            <div className="text-[13px]">*{t('ocrExpiryDisclaimer')}</div>
          )}
        </div>
      </div>
      {canSeeFraudCheck && applicantData.applicantDetails.fraudData && (
        <div>
          <ApplicantFraudCheckResult
            phoneNumber={applicantData.applicantDetails.phoneNumber.idNumber}
            email={applicantData.applicantDetails.email.idNumber}
            fraudData={applicantData.applicantDetails.fraudData}
            checkResults={applicantData.checkSummary.checkResults}
            deviceSardineUrl={
              applicantData.applicantDetails.extraData?.device_sardine_url
            }
          />
        </div>
      )}
      <ApplicantIndividualVerificationReport
        applicantData={applicantData}
        isLegacyProfile={isLegacyProfile}
      />
    </div>
  )
}
