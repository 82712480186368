import { useCallback, useMemo } from 'react'

import { useMutation } from '@tanstack/react-query'

import { ApplicantId } from 'entities/applicant'

import { useI18n } from 'shared/i18n'
import { notification } from 'shared/notification'

import { applicantBusinessOwnershipApi } from '../../api/applicant-business-ownership-international.api'
import { APPLICANT_BUSINESS_OWNERSHIP_INTERNATIONAL_KEY } from '../../applicant-business-ownership-international.key'
import { applicantBusinessOwnershipInternationalEn } from '../../locale/applicant-business-ownership-international.en'
import {
  AssociateInternationalPartiesPayload,
  AssociateNewInternationalPartiesPayload,
  HidePartyFromSearch,
} from '../../model/applicant-business-ownership-international.model'
import { useApplicantAssociateInternationalEntityQuery } from '../applicant-associate-international-entity-query/applicant-associate-international-entity.query'

export type AssociationType = 'add' | 'updating' | 'remove'

type Args<TType extends AssociationType> = {
  applicantId: ApplicantId
  associationType: TType
}

type MainData = {
  mainData: AssociateInternationalPartiesPayload
}

type PartyData = {
  partyData:
    | AssociateNewInternationalPartiesPayload<'BUSINESS'>
    | AssociateNewInternationalPartiesPayload<'INDIVIDUAL'>
}

type EntityIdArg = {
  entityId: ApplicantId
}

export const useApplicantAssociateInternationalEntityMutation = <
  TType extends AssociationType,
>({
  applicantId,
  associationType,
}: Args<TType>) => {
  const t = useI18n([APPLICANT_BUSINESS_OWNERSHIP_INTERNATIONAL_KEY], {
    keys: applicantBusinessOwnershipInternationalEn,
  })
  const { refetch: refetchInternationalAssociatedEntity } =
    useApplicantAssociateInternationalEntityQuery({ applicantId })

  const [successMsg, errorMsg] = useMemo(() => {
    const successMsgMap = {
      add: t('success.createAssociatedParty'),
      updating: t('success.editAssociatedParty'),
      remove: t('success.removeAssociatedParty'),
    }

    const errorMsgMap = {
      add: t('error.createAssociatedParty'),
      updating: t('error.editAssociatedParty'),
      remove: t('error.removeAssociatedParty'),
    }

    return [successMsgMap[associationType], errorMsgMap[associationType]]
  }, [associationType, t])

  const onSuccess = useCallback(() => {
    void refetchInternationalAssociatedEntity()
    notification.success(successMsg)
  }, [refetchInternationalAssociatedEntity, successMsg])

  const onError = useCallback(() => {
    notification.error(errorMsg)
  }, [errorMsg])

  const removeAssociatedPartyMutation = useMutation({
    mutationFn: ({ entityId }: EntityIdArg) =>
      applicantBusinessOwnershipApi.removeAssociatedParty(
        applicantId,
        entityId,
      ),
    onSuccess,
    onError,
  })

  const updateAssociatedPartyMutation = useMutation({
    mutationFn: ({ entityId, mainData }: MainData & EntityIdArg) =>
      applicantBusinessOwnershipApi.updateAssociatedParty(
        applicantId,
        mainData,
        entityId,
      ),
    onSuccess,
    onError,
  })

  const addAssociatePartyMutation = useMutation({
    mutationFn: ({
      mainData,
      partyData,
      hidePartyFromSearch,
    }: PartyData & MainData & HidePartyFromSearch) =>
      applicantBusinessOwnershipApi.associateNewParty(applicantId, {
        hidePartyFromSearch,
        ...mainData,
        ...partyData,
      }),
    onSuccess,
    onError,
  })

  const mutate = useMemo(() => {
    const mutationMap = {
      add: addAssociatePartyMutation,
      updating: updateAssociatedPartyMutation,
      remove: removeAssociatedPartyMutation,
    }

    return mutationMap[associationType]
  }, [
    addAssociatePartyMutation,
    removeAssociatedPartyMutation,
    updateAssociatedPartyMutation,
    associationType,
  ])

  return mutate
}
