import React from 'react'

import cx from 'classnames'
import { useNavigate } from 'react-router-dom'

import { FrankieButton } from 'frankify/src'

import { monitoringOverviewEn } from 'features/monitoring-overview/locale/monitoring-overview.en'
import { MONITORING_OVERVIEW_KEY } from 'features/monitoring-overview/monitoring-overview.key'

import { DocumentTabTypes, useApplicantPaths } from 'entities/applicant'

import { useI18n } from 'shared/i18n'

export function KybDocumentCatalog() {
  const t = useI18n([MONITORING_OVERVIEW_KEY], { keys: monitoringOverviewEn })
  const { getApplicantPath } = useApplicantPaths()

  const nav = useNavigate()
  return (
    <div
      className={cx(
        'p-8 pt-7 bg-tertiary-grey-50 rounded-md flex justify-between items-center gap-4',
      )}
    >
      <span className="text-lg font-bold text-tertiary-grey-800">
        {t('listAlreadyRetrieved')}
      </span>
      <FrankieButton
        onClick={() => {
          const path = getApplicantPath('supportingDocuments')
          nav(path, { state: { activeTabKey: DocumentTabTypes.CATALOG } })
        }}
      >
        {t('listAlreadyRetrievedButton')}
      </FrankieButton>
    </div>
  )
}
