import React, { useMemo } from 'react'

import axios from 'axios'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'

import { FrankieButton, FrankieLoader, FrankieTextField } from 'frankify/src'

import { IPermission, IRole, PermissionCheckboxGroup } from 'entities/role'

import { useI18n } from 'shared/i18n'
import { Nullable } from 'shared/typescript'

import {
  ROLE_DESCRIPTION_MAX_LENGTH,
  ROLE_NAME_MAX_LENGTH,
} from '../../model/role-management.model'
import { roleManagementQa } from '../../qa/role-management.qa'
import { ROLE_MANAGEMENT_KEY } from '../../role-management.key'

export enum RoleEditInputTypes {
  Name = 'role',
  Description = 'description',
  Permissions = 'permissions',
}

export interface IRoleEditInputs {
  [RoleEditInputTypes.Name]: string
  [RoleEditInputTypes.Description]: Nullable<string>
  [RoleEditInputTypes.Permissions]: IPermission[]
}

type Props = {
  permissionOptions: IPermission[]
  initialValues?: Pick<IRole, 'permissions' | 'role' | 'description'>
  onSubmit: (data: IRoleEditInputs) => Promise<void>
  onCancel: () => void
  canEditName: boolean
  canEditPermissions: boolean
}

export function RoleEditForm({
  permissionOptions,
  initialValues,
  onSubmit,
  onCancel,
  canEditName,
  canEditPermissions,
}: Props) {
  const isNewRole = !initialValues
  const t = useI18n([ROLE_MANAGEMENT_KEY])

  const {
    control,
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<IRoleEditInputs>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    values: initialValues || { role: '', permissions: [], description: '' },
  })

  const disabled = isSubmitting || (!canEditName && !canEditPermissions)

  const nameRegister = useMemo(
    () =>
      register(RoleEditInputTypes.Name, {
        required: {
          value: true,
          message: t('roleEditForm.name.required'),
        },
      }),
    [register, t],
  )

  const descriptionRegister = useMemo(
    () => register(RoleEditInputTypes.Description),
    [register],
  )

  const handleCancel = () => onCancel()

  const handleFormSubmit: SubmitHandler<IRoleEditInputs> = async data => {
    try {
      await onSubmit(data)
    } catch (error: unknown) {
      if (
        axios.isAxiosError(error) &&
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        error.response?.data?.error?.name === 'QueryFailedError'
      ) {
        setError(
          RoleEditInputTypes.Name,
          { message: t('roleEditForm.name.unique') },
          { shouldFocus: true },
        )
      }
    }
  }

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <FrankieLoader
        loading={isSubmitting}
        fullscreen
        size="md"
        label={
          <span className="text-tertiary-grey-800 mt-3 text-md font-semibold">
            {t(
              isNewRole
                ? 'roleEditForm.loader.create'
                : 'roleEditForm.loader.save',
            )}
          </span>
        }
      />
      <div className="mb-8 flex gap-[141px]">
        <div className="w-[326px]">
          <FrankieTextField
            {...nameRegister}
            disabled={!canEditName}
            maxLength={ROLE_NAME_MAX_LENGTH}
            size="sm"
            label={t('roleEditForm.name.label')}
            errorText={errors[RoleEditInputTypes.Name]?.message}
            error={!!errors[RoleEditInputTypes.Name]}
            testId={{ input: roleManagementQa.roleEditForm_inputName }}
          />
        </div>
        <div className="w-[443px]">
          <FrankieTextField
            {...descriptionRegister}
            disabled={!canEditName}
            maxLength={ROLE_DESCRIPTION_MAX_LENGTH}
            size="sm"
            label={t('roleEditForm.description.label')}
            testId={{ input: roleManagementQa.roleEditForm_inputDescription }}
          />
        </div>
      </div>
      <div className="mb-6 text-xl font-bold text-tertiary-grey-700">
        {t('roleEditForm.permissions.header')}
      </div>
      <Controller
        control={control}
        name={RoleEditInputTypes.Permissions}
        render={({ field: { onChange, value } }) => (
          <PermissionCheckboxGroup
            disabled={!canEditPermissions}
            options={permissionOptions}
            values={value}
            onChange={onChange}
          />
        )}
      />

      <div className="mt-10 flex gap-4">
        <FrankieButton
          intent="primary"
          size="sm"
          type="submit"
          disabled={disabled}
          testId={{ button: roleManagementQa.roleEditForm_editCta }}
        >
          {t(isNewRole ? 'roleEditForm.cta.create' : 'roleEditForm.cta.save')}
        </FrankieButton>
        <FrankieButton
          intent="subtle"
          size="sm"
          onClick={handleCancel}
          disabled={disabled}
          testId={{ button: roleManagementQa.roleEditForm_cancelCta }}
        >
          {t('roleEditForm.cta.cancel')}
        </FrankieButton>
      </div>
    </form>
  )
}
