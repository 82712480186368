import React, { useMemo, useState } from 'react'

import {
  GridColumnHeaderParams,
  GridSortModel,
  GridExpandMoreIcon,
} from '@mui/x-data-grid-pro'

import {
  FrankieButton,
  FrankiePopover,
  FrankieTableHeaderCell,
} from 'frankify/src'

import { ApplicantId } from 'entities/applicant'

import { useI18n } from 'shared/i18n'

import { APPLICANT_FRAUD_CHECK_KEY } from '../../applicant-fraud-check.key'
import { applicantFraudCheckEn } from '../../locale/applicant-fraud-check.en'
import {
  FraudListData,
  FraudListDataKeys,
} from '../../model/applicant-fraud-check.model'
import { applicantFraudCheckCellQa } from '../../qa/applicant-fraud-check.qa'
import { FraudStatusChangeForm } from '../applicant-fraud-check-status-change/applicant-fraud-check-status-change'

type FraudCheckCellProps = {
  row: FraudListData
}

export function MatchStatusTypeCell({
  row,
  applicantId,
}: FraudCheckCellProps & { applicantId: ApplicantId }) {
  const [open, setOpen] = useState(false)

  return (
    <div>
      <FrankiePopover
        onOpenChange={setOpen}
        open={open}
        popoverRest={{ placement: 'bottom-end' }}
        trigger={
          <FrankieButton
            noStyles
            className="flex py-2 gap-2 px-4 whitespace-nowrap rounded-sm border border-solid text-mono-90 h-9 border-mono-black font-bold"
            onClick={e => {
              e.stopPropagation()
              setOpen(prev => !prev)
            }}
          >
            <span>{row.matchStatus.label}</span>

            <GridExpandMoreIcon
              fontSize="small"
              sx={{ transform: open ? 'rotate(-180deg)' : 'none' }}
            />
          </FrankieButton>
        }
      >
        <div>
          <FraudStatusChangeForm
            applicantId={applicantId}
            fraudListIds={[row.matchId]}
            checkId={row.checkId}
            handleClose={() => setOpen(false)}
          />
        </div>
      </FrankiePopover>
    </div>
  )
}

export function FraudMatchTypeCell({ row }: FraudCheckCellProps) {
  return (
    <div
      className="bg-tertiary-yellow-500 p-1 text-mono-white rounded-sm "
      data-qa={applicantFraudCheckCellQa.fraudTypeCellContainer}
    >
      {row.matchType}
    </div>
  )
}

type FraudSelectCellProps = {
  setFraudListIds: (ids: string[]) => void
  fraudListIds: string[]
}

export function SelectedEntityCell({
  row,
  fraudListIds,
  setFraudListIds,
}: FraudCheckCellProps & FraudSelectCellProps) {
  const selected = fraudListIds.includes(row.matchId)
  const handleSelectAll = () => {
    setFraudListIds(
      selected
        ? fraudListIds.filter(fraudId => fraudId !== row.matchId)
        : [...fraudListIds, row.matchId],
    )
  }
  return (
    <FrankieButton
      testId={{ button: applicantFraudCheckCellQa.selectBtn }}
      noStyles
      onClick={handleSelectAll}
      className="w-4 h-4 p-[2px] mr-4 m-auto shadow-sm border border-tertiary-grey-500 border-solid rounded-full"
    >
      {selected && (
        <div className="rounded-full bg-tertiary-red-500 w-full h-full" />
      )}
    </FrankieButton>
  )
}

type FraudCheckHeaderProps = {
  rows: FraudListData[]
}

export function SelectedEntityCellHeader({
  rows,
  setFraudListIds,
  fraudListIds,
}: FraudCheckHeaderProps & FraudSelectCellProps) {
  const t = useI18n([APPLICANT_FRAUD_CHECK_KEY], {
    keys: applicantFraudCheckEn,
  })
  const selected = useMemo(
    () =>
      rows.every(row => fraudListIds.includes(row.matchId)) && rows.length > 0,
    [rows, fraudListIds],
  )
  const handleSelectAll = () => {
    if (selected) {
      setFraudListIds([])
    } else {
      const allFraudListIds: string[] = []
      rows.forEach(row => {
        if (!allFraudListIds.includes(row.matchId)) {
          allFraudListIds.push(row.matchId)
        }
      })
      setFraudListIds(allFraudListIds)
    }
  }
  return (
    <div className="mr-4 ml-auto">
      {t('header.select')}
      <FrankieButton
        testId={{ button: applicantFraudCheckCellQa.selectAllBtn }}
        noStyles
        onClick={handleSelectAll}
        className="w-4 h-4 p-[2px] ml-2 m-auto shadow-sm border border-tertiary-grey-500 border-solid rounded-full"
      >
        {selected && (
          <div className="rounded-full bg-tertiary-red-500 w-full h-full" />
        )}
      </FrankieButton>
    </div>
  )
}

type FraudCheckTableHeaderProps = GridColumnHeaderParams<FraudListData> & {
  sortModel: GridSortModel
}

export function FraudCheckTableHeader({
  colDef,
  sortModel,
}: FraudCheckTableHeaderProps) {
  const fieldName = colDef.field as FraudListDataKeys

  const currentSortValue = useMemo(
    () =>
      sortModel.find(item => item.field === fieldName)?.sort ||
      (colDef.sortable ? 'off' : undefined),
    [sortModel, colDef.sortable, fieldName],
  )
  return (
    <div
      className="relative flex items-center py-2"
      data-qa={applicantFraudCheckCellQa.tableHeaderCell}
    >
      <FrankieTableHeaderCell
        className="!p-0 flex-row-reverse"
        sortValue={currentSortValue}
      >
        <span>{colDef.headerName}</span>
      </FrankieTableHeaderCell>
    </div>
  )
}
