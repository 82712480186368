import React, { useCallback, useMemo } from 'react'

import { FrankieCheckbox, FrankieSelectField } from 'frankify/src'

import { useHasFeatureFlag } from 'entities/session'

import { useI18n } from 'shared/i18n'

import {
  NotificationChannelCell,
  NotificationsConfigTableRowLayout,
} from './notifications-config-table-row-layout/notifications-config-table-row-layout'
import {
  NOTIFICATIONS_MANAGEMENT_KEY,
  notificationsManagementEn,
} from '../../locale/notifications-management.en'
import {
  INotificationSetting,
  INotificationSettingsDictionary,
  notificationFrequenciesOptions,
  NotificationFrequenciesTypes,
  notificationWithFrequencyDisabled,
} from '../../model/notifications-management.model'
import { notificationsConfigTableRowQa } from '../../qa/notifications-management.qa'

type Props = {
  notificationTypeInfo: INotificationSettingsDictionary
  settings: INotificationSetting[]
  onChange: (setting: INotificationSetting, newChecked: boolean) => void
  onMultipleChange: (
    notification: INotificationSettingsDictionary['value'],
    frequency: NotificationFrequenciesTypes,
  ) => void
}

export function NotificationsConfigTableRow({
  settings,
  notificationTypeInfo,
  onChange,
  onMultipleChange,
}: Props) {
  const t = useI18n([NOTIFICATIONS_MANAGEMENT_KEY], {
    keys: notificationsManagementEn,
  })
  const { batchNotifications } = useHasFeatureFlag({
    batchNotifications: 'batchNotifications',
  })

  const handleChange = useCallback(
    (setting: INotificationSetting, newChecked: boolean) => () => {
      onChange(setting, newChecked)
    },
    [onChange],
  )

  const channels = useMemo<NotificationChannelCell[]>(
    () =>
      settings.map<NotificationChannelCell>(setting => ({
        cell: (
          <div key={setting.channel} className="py-3 px-4">
            <FrankieCheckbox
              checked={setting.value}
              onChange={handleChange(setting, !setting.value)}
              disabled={!setting.canDisable}
            />
          </div>
        ),
        key: setting.channel,
      })),
    [settings, handleChange],
  )

  const isFrequencyDisabled =
    !batchNotifications ||
    notificationWithFrequencyDisabled.includes(notificationTypeInfo.value)

  return (
    <div
      className="w-full  leading-5"
      data-qa={notificationsConfigTableRowQa.container}
    >
      <NotificationsConfigTableRowLayout
        notificationTypeCell={
          <>
            <div
              className=" basis-[120px] grow-0 shrink-0  text-tertiary-grey-700"
              data-qa={notificationsConfigTableRowQa.name}
            >
              {notificationTypeInfo.name}
            </div>
            <div
              className=" flex-1 whitespace-break-spaces text-tertiary-grey-500"
              data-qa={notificationsConfigTableRowQa.description}
            >
              {notificationTypeInfo.description}
            </div>
          </>
        }
        frequencyCell={
          <FrankieSelectField
            options={notificationFrequenciesOptions.map(option => ({
              ...option,
              label: t(option.label),
            }))}
            disabled={isFrequencyDisabled}
            size="sm"
            optionsClassName="[&>li]:!text-xs"
            inputClassName="text-xs !py-2 !pl-3"
            onChange={e =>
              onMultipleChange(
                notificationTypeInfo.value,
                e.target.value as NotificationFrequenciesTypes,
              )
            }
            value={
              settings.at(0)?.frequency || NotificationFrequenciesTypes.REALTIME
            }
            testId={{
              input: notificationsConfigTableRowQa.frequencySelectInput,
            }}
          />
        }
        channelCells={channels}
      />
    </div>
  )
}
