import {
  ApplicantEntityStatusTypes,
  ApplicantEntityTypes,
  ApplicantIssueTypes,
  ApplicantRiskLevelTypes,
} from 'entities/applicant'
import { IUser } from 'entities/user'

import { IDateRange } from 'shared/filters-common'

export enum ApplicantsFilterInputTypes {
  EntityType = 'entityType',
  EntityStatus = 'entityStatus',
  Issue = 'issue',
  RiskLevel = 'riskLevel',
  CreatedDate = 'createdDate',
  Recipe = 'recipe',
  Assignee = 'assignee',
  Name = 'name',
  EntityId = 'entityId',
}

export enum ApplicantsSearchParamsTypes {
  MatchStatusFilter = 'matchStatusFilter',
  NameFilter = 'nameFilter',
  EntityIdFilter = 'entityIdFilter',
  EntityTypeFilter = 'entityTypeFilter',
  IssueFilter = 'amlStatusFilter',
  RiskStatusFilter = 'riskStatusFilter',
  RecipeFilter = 'profileNameFilter',
  AssigneeFilter = 'assigneeFilter',
  StartDate = 'startDate',
  EndDate = 'endDate',
}

export interface IApplicantsGlobalFilter {
  [ApplicantsFilterInputTypes.EntityType]: ApplicantEntityTypes | ''
  [ApplicantsFilterInputTypes.EntityStatus]: ApplicantEntityStatusTypes[]
  [ApplicantsFilterInputTypes.Issue]: ApplicantIssueTypes[]
  [ApplicantsFilterInputTypes.Recipe]: string[]
  [ApplicantsFilterInputTypes.RiskLevel]: ApplicantRiskLevelTypes[]
  [ApplicantsFilterInputTypes.Assignee]: { value: string; label: string }[]
  [ApplicantsFilterInputTypes.CreatedDate]: IDateRange
  [ApplicantsFilterInputTypes.Name]: string
  [ApplicantsFilterInputTypes.EntityId]: string
}

export const applicantsGlobalFilterDefault: IApplicantsGlobalFilter = {
  [ApplicantsFilterInputTypes.EntityType]: '',
  [ApplicantsFilterInputTypes.EntityStatus]: [],
  [ApplicantsFilterInputTypes.Issue]: [],
  [ApplicantsFilterInputTypes.Recipe]: [],
  [ApplicantsFilterInputTypes.RiskLevel]: [],
  [ApplicantsFilterInputTypes.Assignee]: [],
  [ApplicantsFilterInputTypes.CreatedDate]: {
    startDate: null,
    endDate: null,
  },
  [ApplicantsFilterInputTypes.Name]: '',
  [ApplicantsFilterInputTypes.EntityId]: '',
}

function splitToArr(str: string | null): string[] {
  return str ? str.split(',') : []
}

function splitToArrValue(
  str: string | null,
  userList: IUser[] | undefined,
): { value: string; label: string }[] {
  const users = userList ?? ([] as IUser[])

  return str
    ? str.split(',').map(value => {
        const label =
          users.find(user => user.email === value)?.realname ?? value
        return {
          value,
          label,
        }
      })
    : []
}

export function getApplicantFilterFromSearchParams(
  searchParams: URLSearchParams,
  userList: IUser[] | undefined,
): IApplicantsGlobalFilter {
  const startDateStr = searchParams.get(ApplicantsSearchParamsTypes.StartDate)
  const endDateStr = searchParams.get(ApplicantsSearchParamsTypes.EndDate)

  return {
    [ApplicantsFilterInputTypes.EntityType]: (searchParams.get(
      ApplicantsSearchParamsTypes.EntityTypeFilter,
    ) || '') as ApplicantEntityTypes | '',
    [ApplicantsFilterInputTypes.EntityStatus]: splitToArr(
      searchParams.get(ApplicantsSearchParamsTypes.MatchStatusFilter),
    ) as ApplicantEntityStatusTypes[],
    [ApplicantsFilterInputTypes.Issue]: splitToArr(
      searchParams.get(ApplicantsSearchParamsTypes.IssueFilter),
    ) as ApplicantIssueTypes[],
    [ApplicantsFilterInputTypes.Recipe]: splitToArr(
      searchParams.get(ApplicantsSearchParamsTypes.RecipeFilter),
    ),
    [ApplicantsFilterInputTypes.RiskLevel]: splitToArr(
      searchParams.get(ApplicantsSearchParamsTypes.RiskStatusFilter),
    ) as ApplicantRiskLevelTypes[],
    [ApplicantsFilterInputTypes.Assignee]: splitToArrValue(
      searchParams.get(ApplicantsSearchParamsTypes.AssigneeFilter),
      userList,
    ),
    [ApplicantsFilterInputTypes.CreatedDate]: {
      startDate: startDateStr ? new Date(startDateStr) : null,
      endDate: endDateStr ? new Date(endDateStr) : null,
    },
    [ApplicantsFilterInputTypes.Name]:
      searchParams.get(ApplicantsSearchParamsTypes.NameFilter) ?? '',
    [ApplicantsFilterInputTypes.EntityId]:
      searchParams.get(ApplicantsSearchParamsTypes.EntityIdFilter) ?? '',
  }
}

/**
 * Updates search params in place with the given filter
 */
// eslint-disable-next-line complexity
export function updateSearchParamsWithApplicantFilter(
  searchParams: URLSearchParams,
  filter: IApplicantsGlobalFilter,
) {
  if (filter[ApplicantsFilterInputTypes.EntityStatus].length > 0)
    searchParams.set(
      ApplicantsSearchParamsTypes.MatchStatusFilter,
      filter[ApplicantsFilterInputTypes.EntityStatus].join(','),
    )
  else searchParams.delete(ApplicantsSearchParamsTypes.MatchStatusFilter)

  if (filter[ApplicantsFilterInputTypes.EntityType])
    searchParams.set(
      ApplicantsSearchParamsTypes.EntityTypeFilter,
      filter[ApplicantsFilterInputTypes.EntityType],
    )
  else searchParams.delete(ApplicantsSearchParamsTypes.EntityTypeFilter)

  if (filter[ApplicantsFilterInputTypes.Name])
    searchParams.set(
      ApplicantsSearchParamsTypes.NameFilter,
      filter[ApplicantsFilterInputTypes.Name],
    )
  else searchParams.delete(ApplicantsSearchParamsTypes.NameFilter)

  if (filter[ApplicantsFilterInputTypes.EntityId])
    searchParams.set(
      ApplicantsSearchParamsTypes.EntityIdFilter,
      filter[ApplicantsFilterInputTypes.EntityId],
    )
  else searchParams.delete(ApplicantsSearchParamsTypes.EntityIdFilter)

  if (filter[ApplicantsFilterInputTypes.Issue].length > 0)
    searchParams.set(
      ApplicantsSearchParamsTypes.IssueFilter,
      filter[ApplicantsFilterInputTypes.Issue].join(','),
    )
  else searchParams.delete(ApplicantsSearchParamsTypes.IssueFilter)

  if (filter[ApplicantsFilterInputTypes.Recipe].length > 0) {
    searchParams.set(
      ApplicantsSearchParamsTypes.RecipeFilter,
      filter[ApplicantsFilterInputTypes.Recipe].join(','),
    )
  } else searchParams.delete(ApplicantsSearchParamsTypes.RecipeFilter)

  if (filter[ApplicantsFilterInputTypes.RiskLevel].length > 0)
    searchParams.set(
      ApplicantsSearchParamsTypes.RiskStatusFilter,
      filter[ApplicantsFilterInputTypes.RiskLevel].join(','),
    )
  else searchParams.delete(ApplicantsSearchParamsTypes.RiskStatusFilter)

  if (filter[ApplicantsFilterInputTypes.Assignee].length > 0)
    searchParams.set(
      ApplicantsSearchParamsTypes.AssigneeFilter,
      filter[ApplicantsFilterInputTypes.Assignee]
        .map(value => value.value)
        .join(','),
    )
  else searchParams.delete(ApplicantsSearchParamsTypes.AssigneeFilter)

  if (
    filter[ApplicantsFilterInputTypes.CreatedDate].startDate &&
    filter[ApplicantsFilterInputTypes.CreatedDate].endDate
  ) {
    searchParams.set(
      ApplicantsSearchParamsTypes.StartDate,
      filter[ApplicantsFilterInputTypes.CreatedDate].startDate.toISOString(),
    )
    searchParams.set(
      ApplicantsSearchParamsTypes.EndDate,
      filter[ApplicantsFilterInputTypes.CreatedDate].endDate.toISOString(),
    )
  } else {
    searchParams.delete(ApplicantsSearchParamsTypes.StartDate)
    searchParams.delete(ApplicantsSearchParamsTypes.EndDate)
  }
}

export function countEnabledFilterCategories(searchParams: URLSearchParams) {
  let count = Object.values(ApplicantsSearchParamsTypes)
    .filter(
      value =>
        ![
          ApplicantsSearchParamsTypes.NameFilter,
          ApplicantsSearchParamsTypes.EntityIdFilter,
        ].includes(value),
    )
    .reduce((acc, curr) => acc + (searchParams.has(curr) ? 1 : 0), 0)

  if (
    searchParams.has(ApplicantsSearchParamsTypes.StartDate) &&
    searchParams.has(ApplicantsSearchParamsTypes.EndDate)
  ) {
    count -= 1
  }

  return count
}
