/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'

import { IMatchedOrganization } from 'entities/organisation'

import { useI18n } from 'shared/i18n'

import { ORGANISATION_SEARCH_KEY } from '../../../organisation-search/organisation-search.key'

type Props = {
  organizationName: string
  countryName: string
  foundOrganizations: IMatchedOrganization[]
  onSelectOrganisation?: (organisation: IMatchedOrganization) => void
}

function getOrganisationName(organisation: IMatchedOrganization): string {
  if (organisation.organizationName) {
    return organisation.organizationName
  }

  if (organisation.name?.name) {
    return organisation.name.name
  }

  if (organisation.alternameNames?.length) {
    return organisation.alternameNames[0].name
  }

  return ''
}

export function OrganisationSearchResultTable({
  organizationName,
  countryName,
  foundOrganizations,
  onSelectOrganisation,
}: Props) {
  const t = useI18n([ORGANISATION_SEARCH_KEY])

  return (
    <>
      <div>
        <span className="text-lg font-bold text-tertiary-grey-800">
          {t('searchResult', {
            organizationName,
            countryName,
          })}
        </span>
        <span className="text-sm font-normal text-tertiary-grey-500 ml-2">
          {t('resultsNumber', {
            resultsNumber: foundOrganizations.length,
          })}
        </span>
      </div>
      <div id="results-table" className="mt-4">
        <div
          id="results-table-header"
          className="flex bg-tertiary-grey-50 px-4 py-2 text-left border-b border-tertiary-grey-200"
        >
          <div className="w-5/6 ">{t('resultsTable.nameColumnTitle')}</div>
          <div className="w-1/6">
            {t('resultsTable.registrationCodeColumnTitle')}
          </div>
        </div>
        <div id="results-table-rows">
          {foundOrganizations.map(organisation => (
            // eslint-disable-next-line jsx-a11y/no-static-element-interactions
            <div
              key={organisation.registrationDetails[0].registrationNumber}
              className={`px-4 py-3 text-left flex flex-row border-b border-tertiary-grey-200 ${
                onSelectOrganisation
                  ? 'cursor-pointer hover:bg-tertiary-grey-100'
                  : ''
              }`}
              onClick={
                onSelectOrganisation
                  ? () => onSelectOrganisation(organisation)
                  : undefined
              }
            >
              <div className="inline-block w-5/6">
                {getOrganisationName(organisation)}
              </div>
              <div className="inline-block w-1/6">
                {organisation.registrationDetails[0].registrationNumber}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}
