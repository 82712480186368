import { convertDataURItoBlob } from './convert-data-uri-to-blob'
import { prefixBase64 } from './prefix-base-64'

export const makeDataURL = (mimeType: string, file: string) =>
  `${prefixBase64(mimeType)},${file}`

export const getDataFileUrl = (mimeType: string, file: string) => {
  const base64 = makeDataURL(mimeType, file)
  const blob = convertDataURItoBlob(base64)
  return window.URL.createObjectURL(blob)
}
