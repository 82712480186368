import { useMutation, useQueryClient } from '@tanstack/react-query'

import { OrganisationSettingsQueryKey } from './organisation-settings.query'
import { organisationSettingsApi } from '../../api/organisation-settings.api'
import {
  IOrganisationSetting,
  IOrganisationSettingPatch,
} from '../../model/organisation-settings.model'

export const useUpdateOrganisationSettingMutation = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ patch }: { patch: IOrganisationSettingPatch }) =>
      organisationSettingsApi.updateOrganisationSettings(patch),
    onSuccess: ({ data: newSetting }) => {
      const settings = queryClient.getQueryData<IOrganisationSetting[]>(
        OrganisationSettingsQueryKey,
      )
      if (settings) {
        const newSettings = settings.map(setting =>
          setting.id === newSetting.id ? newSetting : setting,
        )
        queryClient.setQueryData(OrganisationSettingsQueryKey, newSettings)
      }
    },
  })
}
