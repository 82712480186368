export enum BlocklistReasonTypes {
  FABRICATED_IDENTITY = 'FABRICATED_IDENTITY',
  IDENTITY_TAKEOVER = 'IDENTITY_TAKEOVER',
  FALSIFIED_ID_DOCUMENTS = 'FALSIFIED_ID_DOCUMENTS',
  STOLEN_ID_DOCUMENTS = 'STOLEN_ID_DOCUMENTS',
  MERCHANT_FRAUD = 'MERCHANT_FRAUD',
  NEVER_PAY_BUST_OUT = 'NEVER_PAY_BUST_OUT',
  CONFLICTING_DATA_PROVIDED = 'CONFLICTING_DATA_PROVIDED',
  MONEY_MULE = 'MONEY_MULE',
  FALSE_FRAUD_CLAIM = 'FALSE_FRAUD_CLAIM',
  FRAUDULENT_3RD_PARTY = 'FRAUDULENT_3RD_PARTY',
  COMPANY_TAKEOVER = 'COMPANY_TAKEOVER',
  FICTITIOUS_EMPLOYER = 'FICTITIOUS_EMPLOYER',
  COLLUSIVE_EMPLOYER = 'COLLUSIVE_EMPLOYER',
  OVER_VALUATION_OF_ASSETS = 'OVER_VALUATION_OF_ASSETS',
  FALSIFIED_EMPLOYMENT_DETAILS = 'FALSIFIED_EMPLOYMENT_DETAILS',
  MANIPULATED_IDENTITY = 'MANIPULATED_IDENTITY',
  SYNDICATED_FRAUD = 'SYNDICATED_FRAUD',
  INTERNAL_FRAUD = 'INTERNAL_FRAUD',
  BANK_FRAUD = 'BANK_FRAUD',
  UNDISCLOSED_DATA = 'UNDISCLOSED_DATA',
  FALSE_HARDSHIP = 'FALSE_HARDSHIP',
  SMR_REPORT_LODGED = 'SMR_REPORT_LODGED',
  _2X_SMR_REPORTS_LODGED = '2X_SMR_REPORTS_LODGED',
}

export enum BlocklistAttributeTypes {
  ENTIRE_ENTITY = 'ENTIRE_ENTITY',
  MAILING_ADDRESS = 'MAILING_ADDRESS',
  RESIDENTIAL_ADDRESS = 'RESIDENTIAL_ADDRESS',
  EMAIL_ADDRESS = 'EMAIL_ADDRESS',
  PHONE_NUMBER = 'PHONE_NUMBER',
  ID_DOCUMENT = 'ID_DOCUMENT',
  NAME_AND_DOB = 'NAME_AND_DOB',
}
