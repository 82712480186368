import React from 'react'

import { FrankieLoader } from 'frankify/src'

import { CreditRiskConfirmationModal } from 'features/abr-search-australia/ui/credit-risk-report-confirmation-modal/credit-risk-report-confirmation-modal'
import { SingleReportConfirmationModal } from 'features/abr-search-australia/ui/single-report-confirmation-modal/single-report-confirmation-modal'
import { UboReportConfirmationModal } from 'features/abr-search-australia/ui/ubo-report-confirmation-modal/ubo-report-confirmation-modal'
import { ORGANISATION_AUDIT_KEY } from 'features/organisation-audit/locale/organisation-audit.en'

import { useApplicantSingleSpaOverviewPath } from 'entities/applicant'
import {
  useAmlCheckCreateApplicantMutation,
  useGenerateReportMutation,
  useAmlCheck,
} from 'entities/organisation'
import { ProfileCheckRun } from 'entities/profile-check'

import { useI18n } from 'shared/i18n'
import { notification } from 'shared/notification'
import { useOverlay } from 'shared/overlay'

import { ABRCompany } from '../../model/abr-search-model'

type Props = {
  abrOrganisation: Partial<ABRCompany>
}

export function AbrOrganisationChecks({ abrOrganisation }: Props) {
  const t = useI18n([ORGANISATION_AUDIT_KEY])

  const { mutateAsync: createProfileFromAML, isLoading: isRunningAmlPepCheck } =
    useAmlCheckCreateApplicantMutation()

  const { mutateAsync: runAMLCheck, isLoading: isRunningAml } = useAmlCheck()

  const { isLoading: isGeneratingReport } = useGenerateReportMutation()

  const { navigateToOverview } = useApplicantSingleSpaOverviewPath()

  const handleClickAML = async () => {
    try {
      const {
        data: { entityId },
      } = await createProfileFromAML({
        body: {
          entityType: 'ORGANISATION',
          kycMethod: 'electronic',
          business: {
            businessProfile: 'organisation',
            ABNNumber: abrOrganisation.abn ?? undefined,
            ACNNumber: abrOrganisation.acn ?? undefined,
            businessName: abrOrganisation.name,
            businessType: abrOrganisation.type ?? undefined,
          },
        },
      })

      await runAMLCheck({ entityId })

      navigateToOverview(entityId)
    } catch (error) {
      notification.error(t('errorCreatingProfileAUS'))
    }
  }

  const isAwaitingReport =
    isRunningAmlPepCheck || isGeneratingReport || isRunningAml

  const [createOverlay, closeOverlay] = useOverlay()

  const handleClickUBO = () => {
    createOverlay(
      <UboReportConfirmationModal
        abrOrganisation={abrOrganisation}
        onClose={closeOverlay}
      />,
      {
        className: 'w-[480px] p-7',
        closeButtonClassName: '!top-7 !right-6 z-10',
      },
    )
  }

  const handleClickCreditCheck = () => {
    createOverlay(
      <CreditRiskConfirmationModal
        abrOrganisation={abrOrganisation}
        onClose={closeOverlay}
      />,
      {
        className: 'w-[480px] p-7',
        closeButtonClassName: '!top-7 !right-6 z-10',
      },
    )
  }

  const handleClickSingleReport = () => {
    createOverlay(
      <SingleReportConfirmationModal
        abrOrganisation={abrOrganisation}
        onClose={closeOverlay}
      />,
      {
        className: 'w-[480px] p-7',
        closeButtonClassName: '!top-7 !right-6 z-10',
      },
    )
  }

  return (
    <FrankieLoader loading={isAwaitingReport}>
      <div className="p-8 pt-7 bg-tertiary-grey-50 rounded-md flex flex-col gap-4">
        <span className="text-lg font-bold text-tertiary-grey-800">
          {t('selectReportTitleAus')}
        </span>
        <ProfileCheckRun
          name={t('amlScreeningTitle')}
          description={t('amlScreeningSubtitle')}
          onClick={handleClickAML}
          isLoading={isGeneratingReport}
        />
        <ProfileCheckRun
          name={t('singleLevelBusinessOwnershipTitle')}
          description={t('singleLevelBusinessOwnershipSubtitle')}
          isReport
          onClick={handleClickSingleReport}
          isLoading={isGeneratingReport}
        />
        <ProfileCheckRun
          name={t('uboReportTitle')}
          description={t('uboReportSubtitle')}
          isReport
          onClick={handleClickUBO}
          isLoading={isGeneratingReport}
        />
        <ProfileCheckRun
          name={t('creditReportTitle')}
          description={t('creditReportSubtitle')}
          isReport
          onClick={handleClickCreditCheck}
          isLoading={isGeneratingReport}
        />
      </div>
    </FrankieLoader>
  )
}
