import { useMutation } from '@tanstack/react-query'

import { ApplicantId, useApplicantDataQuery } from 'entities/applicant'

import { useI18n } from 'shared/i18n'
import { notification } from 'shared/notification'

import { ikybAmlScreeningApi } from '../../api/ikyb-aml-screening'
import { IKYB_AML_SCREENING_KEY } from '../../ikyb-aml-screening.key'
import { iKybAmlScreeningEn } from '../../locale/ikyb-aml-screening.en'
import { UpdateMatchPayload } from '../../model/ikyb-aml-screening.model'
import { useIKybAmlScreeningQuery } from '../ikyb-aml-screening-query/ikyb-aml-screening.query'

type Args = {
  applicantId: ApplicantId
}

export type UpdateStatusMutateArgs = {
  checkId: string
} & UpdateMatchPayload

export const useIKybAmlScreeningMatchStatusChangeMutation = ({
  applicantId,
}: Args) => {
  const { refetch } = useIKybAmlScreeningQuery({ applicantId })
  const { refetch: refetchApplicantData } = useApplicantDataQuery({
    applicantId,
  })

  const t = useI18n(IKYB_AML_SCREENING_KEY, {
    keys: iKybAmlScreeningEn,
  })

  return useMutation({
    mutationFn: ({ checkId, ...data }: UpdateStatusMutateArgs) =>
      ikybAmlScreeningApi.updateMatchStatus(applicantId, checkId, data),

    onSuccess: () => {
      notification.success(t('success.changeStatus'))
      void refetch()
      void refetchApplicantData()
    },

    onError: () => {
      notification.error(t('error.changeStatus'))
    },
  })
}
