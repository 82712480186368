import React from 'react'

import { useI18n } from 'shared/i18n'
import { TrackingEventsTypes, trackingManager } from 'shared/tracking'

import { APPLICANT_KEY, applicantEn } from '../../../locale/applicant.en'
import {
  IDeviceCheckpoint,
  IFraudCheckpointRule,
} from '../../../model/applicant-fraud-check.model'
import { CheckHeader } from '../check-header/check-header'
import { ContentItem } from '../content-item/content-item'

type Props = {
  data: IDeviceCheckpoint
  rules: IFraudCheckpointRule[] | null
  checkResult: 'fail' | 'unchecked' | 'pass'
  deviceSardineUrl: string | null
}

export function FraudCheckDevice({
  data,
  rules,
  checkResult,
  deviceSardineUrl,
}: Props) {
  const t = useI18n(APPLICANT_KEY, { keys: applicantEn })

  return (
    <div>
      <CheckHeader
        checkResult={checkResult}
        riskLevel={data.riskLevel}
        rules={rules}
        ruleIds={data.ruleIds}
        title={t('fraud.device.title')}
        buttonLabel={t('fraud.device.viewRulesCta')}
        modalTitle={t('fraud.checkRules.device')}
        onViewRules={() =>
          trackingManager.track(TrackingEventsTypes.FraudViewResultsDevice)
        }
        deviceSardineUrl={deviceSardineUrl}
      />
      <div className="flex flex-row gap-x-[18] gap-y-6 flex-wrap">
        <ContentItem
          className="max-w-[252px] w-full"
          name={t('fraud.device.deviceModel')}
          value={data.deviceModel}
        />
        <ContentItem
          className="max-w-[252px] w-full"
          name={t('fraud.device.trueOS')}
          value={data.trueOS}
        />
        <ContentItem
          className="max-w-[252px] w-full"
          name={t('fraud.device.ipAddress')}
          value={data.ipAddress}
        />
        <ContentItem
          className="max-w-[252px] w-full"
          name={t('fraud.device.trueIPAddress')}
          value={data.trueIPAddress}
        />
        <ContentItem
          className="max-w-[252px] w-full"
          name={t('fraud.device.browser')}
          value={data.browser}
        />
      </div>
    </div>
  )
}
