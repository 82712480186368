import React from 'react'

import { FrankieButton, FrankieIcon } from 'frankify/src'

import { INDIVIDUAL_PROFILE_F2_KEY } from 'features/individual-profile-f2/individual-profile-f2.key'
import { individualProfileF2En } from 'features/individual-profile-f2/locale/individual-profile-f2.en'

import { useI18n } from 'shared/i18n'
import { useOverlay } from 'shared/overlay'

type Props = {
  onConfirm?: () => void
  onCancel?: () => void
  isImage?: boolean
}
export function DeletionConfirmation({ isImage, onConfirm, onCancel }: Props) {
  const t = useI18n([INDIVIDUAL_PROFILE_F2_KEY], {
    keys: individualProfileF2En,
  })
  const [, closeOverlay] = useOverlay()

  const confirm = () => {
    if (onConfirm) onConfirm()
    closeOverlay()
  }

  const cancel = () => {
    if (onCancel) onCancel()
    closeOverlay()
  }

  return (
    <div className="flex flex-col gap-4 items-center w-[400px]">
      <FrankieIcon
        name="mdiAlertCircleOutline"
        className="text-tertiary-yellow-300"
        size="lg"
      />
      <div className="text-md text-tertiary-grey-700 text-center whitespace-pre-line">
        {t(
          isImage
            ? 'documentForm.deleteDocumentDescImage'
            : 'documentForm.deleteDocumentDesc',
        )}
      </div>

      <div className="flex gap-4 justify-center">
        <FrankieButton intent="darkOutline" onClick={cancel}>
          {t(isImage ? 'documentForm.keepImage' : 'documentForm.keepIt')}
        </FrankieButton>
        <FrankieButton onClick={confirm}>
          {t('documentForm.yesImSure')}
        </FrankieButton>
      </div>
    </div>
  )
}
