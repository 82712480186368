import React from 'react'

import { FrankieButton } from 'frankify/src'

import { useI18n } from 'shared/i18n'

import { PROFILE_CHECK_KEY } from '../../locale/profile-check.en'

type Props = {
  name: string
  description: string
  isReport?: boolean
  onClick?: () => void
  isLoading?: boolean
}

export function ProfileCheckRun({
  name,
  description,
  isReport = false,
  onClick,
  isLoading,
}: Props) {
  const t = useI18n([PROFILE_CHECK_KEY])

  return (
    <div
      className={`py-4 px-6 bg-mono-white border border-solid border-tertiary-grey-200 rounded-[6px] flex flex-row items-center justify-between gap-2 ${
        isLoading ? 'opacity-40' : ''
      }`}
    >
      <div className="">
        <div className="text-md font-semibold text-tertiary-grey-800">
          {name}
        </div>
        <div className="text-tertiary-grey-700">{description}</div>
      </div>

      <FrankieButton
        intent="secondary"
        className="!w-[110px] !min-w-[110px] !p-0 text-xs"
        onClick={onClick}
        disabled={isLoading}
      >
        {isReport
          ? t('checkRun.cta.generateReport')
          : t('checkRun.cta.runCheck')}
      </FrankieButton>
    </div>
  )
}
