import { blocklistReasonData, blocklistTableEn } from 'features/blocklist-table'

import { I18nFunction } from 'shared/i18n'

export const getBlocklistReasonOptions = (
  t: I18nFunction<typeof blocklistTableEn>,
) =>
  Object.entries(blocklistReasonData).map(item => ({
    label: t(item[1].tKey),
    value: item[0],
  }))
