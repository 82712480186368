/* eslint-disable complexity */
import React, { memo, useEffect, useState } from 'react'

import { useNavigate } from 'react-router-dom'

import { FrankieAvatar, FrankieButton, FrankieDivider } from 'frankify/src'

import { COMMENT_KEY } from 'entities/comments/comments.key'
import { commentsEn } from 'entities/comments/locale/comments.en'
import { expandedCommentQa } from 'entities/comments/qa/comments.qa'
import {
  useApplicantRoute,
  useGlobalAppState,
  useRouteMatcher,
} from 'entities/routing'

import { DateFormatTypes, formatDate } from 'shared/date-time'
import { useI18n } from 'shared/i18n'
import { trackingManager } from 'shared/tracking'
import { Nullable } from 'shared/typescript'

import {
  CommentsTypes,
  SupplementaryData,
  eventMapper,
} from '../../model/comments.model'
import {
  useCommentBoxVisibility,
  useCommentData,
  useUpdateCommentBoxVisibility,
} from '../../states/comments-states/comments.state'

const ScrollableCommentSection = [CommentsTypes.WORKFLOW, CommentsTypes.AML]

type ExpandedCommentBoxProps = {
  commentType: CommentsTypes
  entityId: string
  processId?: string | string[]
  footer?: string
  timeFormat?: DateFormatTypes
  workflowName?: string
}

export const ExpandedCommentBox = memo(
  ({
    commentType,
    entityId,
    processId,
    footer,
    timeFormat,
    workflowName,
  }: ExpandedCommentBoxProps) => {
    const { data } = useCommentData({
      entityId,
      commentType,
      processId,
      workflowName,
    })

    const [expanded, setExpanded] = useState<boolean[]>(data.map(() => false))
    const t = useI18n([COMMENT_KEY], { keys: commentsEn })
    const { data: isVisible } = useCommentBoxVisibility({ entityId })
    const { data: amlData } = useCommentData({
      entityId,
      commentType: CommentsTypes.AML,
      processId,
      workflowName,
    })
    const { data: workflowData } = useCommentData({
      entityId,
      commentType: CommentsTypes.WORKFLOW,
      processId,
      workflowName,
    })

    const { generateRoute } = useApplicantRoute()
    const isWorkflowTab = useRouteMatcher({
      pathKey: 'applicantProfileWorkflowEvent',
      entityId,
    })

    const maxCollapsedHeight = 150
    const maxExpandedChars = 500

    const toggleExpand = (index: number) => {
      setExpanded(prev => {
        const newExpanded = [...prev]
        newExpanded[index] = !newExpanded[index]
        return newExpanded
      })
    }

    const handleScroll = (value: CommentsTypes) => {
      const scrollCommentIndex = ScrollableCommentSection.findIndex(
        item => item === value,
      )
      const nextIndex =
        scrollCommentIndex + 1 === ScrollableCommentSection.length
          ? 0
          : scrollCommentIndex + 1
      const nextCom = document.getElementById(
        ScrollableCommentSection[nextIndex],
      )
      nextCom?.scrollIntoView({ behavior: 'smooth' })
    }

    const shouldIconVisible =
      isVisible?.[CommentsTypes.WORKFLOW] &&
      isVisible[CommentsTypes.AML] &&
      workflowData.length &&
      amlData.length

    const { updateAppState } = useGlobalAppState()

    useEffect(() => {
      if (isVisible) updateAppState({ isSidebarOpen: false })
    }, [isVisible, updateAppState])

    const updateComment = useUpdateCommentBoxVisibility({ entityId })

    const navigate = useNavigate()

    const updateCommentBoxVisibility = useUpdateCommentBoxVisibility({
      entityId,
    })

    if (!data.length) return null

    return (
      <div className="w-full min-w-[220px] max-w-[270px] relative min-h-[min(100%,700px)]">
        <div
          className="w-full max-h-full h-min  overflow-auto scrollbar  bg-tertiary-grey-100 pb-4 pl-3 pr-4 rounded-sm "
          id={commentType}
          data-qa={expandedCommentQa.container}
        >
          <div className="flex justify-between items-center mb-4 sticky z-10 bg-tertiary-grey-100 top-0 pt-3">
            <div className="flex justify-between">
              <h1
                className="text-tertiary-grey-800 font-bold text-md leading-6 max-w-[152px] min-w-[100px] truncate"
                data-qa={expandedCommentQa.title}
              >
                {t(`title.${commentType}`)}
              </h1>
              {(commentType === CommentsTypes.WORKFLOW ||
                commentType === CommentsTypes.AML) &&
              shouldIconVisible ? (
                <div className="w-[56px]">
                  <FrankieButton
                    noStyles
                    singleIcon={{
                      name: 'mdiChevronUp',
                      className: 'text-tertiary-grey-700',
                      size: 'xs',
                    }}
                    className="w-1/2 h-full"
                    onClick={() => {
                      handleScroll(commentType)
                      if (eventMapper[CommentsTypes.WORKFLOW].prev) {
                        trackingManager.track(
                          eventMapper[CommentsTypes.WORKFLOW].prev,
                        )
                      }
                    }}
                    testId={{ button: expandedCommentQa.upIcon }}
                  />
                  <FrankieButton
                    noStyles
                    singleIcon={{
                      name: 'mdiChevronDown',
                      className: 'text-tertiary-grey-700',
                      size: 'xs',
                    }}
                    className="w-1/2 h-full"
                    onClick={() => {
                      handleScroll(commentType)
                      if (eventMapper[CommentsTypes.WORKFLOW].next) {
                        trackingManager.track(
                          eventMapper[CommentsTypes.WORKFLOW].next,
                        )
                      }
                    }}
                    testId={{ button: expandedCommentQa.downIcon }}
                  />
                </div>
              ) : null}
            </div>
            <FrankieButton
              noStyles
              singleIcon={{
                name: 'mdiClose',
                size: 'xs',
                className: 'text-tertiary-grey-400',
              }}
              onClick={() => {
                const trackingEvent = eventMapper[commentType].expandedCollapse
                if (trackingEvent) trackingManager.track(trackingEvent)
                updateComment({
                  payload: commentType,
                })
              }}
              testId={{ button: expandedCommentQa.closeIcon }}
            />
          </div>

          {data.map((commentData, index) => {
            const isExpanded = expanded[index]
            // eslint-disable-next-line no-nested-ternary
            const messageToShow = isExpanded
              ? commentData.userComment?.text ??
                ''.substring(0, maxExpandedChars)
              : (commentData.userComment?.text ?? '').length >
                maxCollapsedHeight
              ? `${(commentData.userComment?.text ?? '').substring(
                  0,
                  maxCollapsedHeight,
                )}...`
              : commentData.userComment?.text

            return (
              <div
                key={commentData.batchId}
                className="flex flex-col gap-4 grow-0"
                data-qa={expandedCommentQa.commentContainer}
              >
                <div className="flex gap-2 items-center">
                  <FrankieAvatar
                    name={commentData.source ?? ''}
                    size="md"
                    testId={{ initial: expandedCommentQa.avatar }}
                    className="min-w-[32px] min-h-[32px]"
                  />
                  <div className="flex flex-col gap-1 max-w-[148px] desktop:max-w-[190px]">
                    <p
                      className="text-tertiary-grey-800 text-sm font-semibold leading-3 truncate"
                      data-qa={expandedCommentQa.name}
                    >
                      {commentData.source ?? ''}
                    </p>
                    <p
                      className="text-tertiary-grey-500 leading-4 text-xs"
                      data-qa={expandedCommentQa.date}
                    >
                      {formatDate(
                        commentData.timestamp,
                        timeFormat || DateFormatTypes.shortDateAndShortTime,
                      )}
                    </p>
                  </div>
                </div>
                <div className="flex flex-col gap-2">
                  <p
                    className="text-tertiary-grey-700 font-semibold text-sm leading-4"
                    data-qa={expandedCommentQa.action}
                  >
                    {commentData.actionTaken}
                    {commentData.statusComment && (
                      <span
                        data-qa={expandedCommentQa.status}
                        className={`${
                          commentData.statusComment.textColor ?? 'text-inherit'
                        }  text-sm font-semibold leading-4`}
                      >
                        {commentData.statusComment.text ?? ''}
                      </span>
                    )}
                  </p>
                  <p
                    className="text-tertiary-grey-600 text-sm whitespace-break-spaces break-words"
                    data-qa={expandedCommentQa.comment}
                  >
                    {messageToShow}
                  </p>
                  {(commentData.userComment?.text ?? '').length >
                    maxCollapsedHeight && (
                    <FrankieButton
                      noStyles
                      onClick={() => toggleExpand(index)}
                      className="text-primary-800 text-sm -mt-1 text-start"
                    >
                      {isExpanded ? t('seeLess') : t('seeMore')}
                    </FrankieButton>
                  )}
                  {(commentType === CommentsTypes.AML ||
                    commentType === CommentsTypes.AMLRESULT) &&
                    !isWorkflowTab &&
                    (
                      (
                        commentData.supplementaryData as Nullable<SupplementaryData>
                      )?.resultsUpdated ?? []
                    ).length > 1 && (
                      <FrankieButton
                        noStyles
                        onClick={() => {
                          if (commentType === CommentsTypes.AMLRESULT) {
                            updateCommentBoxVisibility({
                              payload: CommentsTypes.AMLRESULT,
                            })
                            navigate(
                              generateRoute({
                                routeKey: 'applicantProfileWorkflowEvent',
                                overrideParams: { entityId },
                              }),
                              {
                                state: '#AML',
                              },
                            )
                          }
                          if (eventMapper[CommentsTypes.AMLHISTORY].viewBatch) {
                            trackingManager.track(
                              eventMapper[CommentsTypes.AMLHISTORY].viewBatch,
                            )
                          }
                        }}
                        className="text-primary-800 text-sm text-start"
                      >
                        {t('viewBatch')}
                      </FrankieButton>
                    )}
                  {Boolean(footer) && (
                    <p className="text-tertiary-grey-500 text-sm">{footer}</p>
                  )}
                </div>
                {index !== data.length - 1 && (
                  <FrankieDivider className="!pb-4" />
                )}
              </div>
            )
          })}
        </div>
      </div>
    )
  },
)
ExpandedCommentBox.displayName = 'ExpandedCommentBox'
