import { useRef, useState } from 'react'

import { useOnClickOutside } from 'usehooks-ts'

import { IUseFrankieMenuReturn } from './menu.types'

export function useFrankieMenu(): IUseFrankieMenuReturn {
  const parentRef = useRef(null)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const handleOpen = () => setIsOpen(true)
  const handleClose = () => setIsOpen(false)
  const handleToggle = () => setIsOpen(prev => !prev)

  useOnClickOutside(parentRef, handleClose)

  return {
    parentRef,
    isOpen,
    handleOpen,
    handleClose,
    handleToggle,
  }
}
