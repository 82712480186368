type NormalizedRiskData = {
  type: string
  label: string
}

function normalizeRiskDataHelper(key: string): NormalizedRiskData {
  const norm = { key, type: '', label: '' }
  switch (key.toLowerCase()) {
    case 'significant':
      norm.type = 'risk_significant'
      norm.label = 'Significant'
      break
    case 'unacceptable':
      norm.type = 'risk_unacceptable'
      norm.label = 'Unacceptable'
      break
    case 'low':
      norm.type = 'risk_low'
      norm.label = 'Low'
      break
    case 'medium':
      norm.type = 'risk_medium'
      norm.label = 'Medium'
      break
    case 'high':
      norm.type = 'risk_high'
      norm.label = 'High'
      break
    case 'very_high':
      norm.type = 'very_high'
      norm.label = 'Very High'
      break
    case 'unknown':
    default:
      norm.type = 'unknown'
      norm.label = 'Unknown'
  }

  return norm
}

export function normalizeRiskData(key?: string | object) {
  switch (typeof key) {
    case 'string':
      return normalizeRiskDataHelper(key.toLowerCase())
    case 'object':
      return {
        type: (key as NormalizedRiskData).type,
        label: (key as NormalizedRiskData).label,
      }
    default:
      return normalizeRiskDataHelper('unknown')
  }
}
