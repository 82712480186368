import React from 'react'

import { WorkflowStepResultEnum } from 'entities/entity'

import { useI18n } from 'shared/i18n'

import { workflowEventsEn } from '../../locale/workflow-events.en'
import { personalInfoSectionQa } from '../../qa/applicant-workflow-events.qa'
import { useGetWorkFlowDataWithSources } from '../../state/get-workflow-events.query'
import { WORKFLOW_EVENTS_KEY } from '../../workflow-events.key'
import { LabelAndSources } from '../entity-verification-helper/entity-verification-helper'

type PersonalInfoProps = {
  entityId: string
  eventResult: WorkflowStepResultEnum
}
export function PersonalInfoSection({
  entityId,
  eventResult,
}: PersonalInfoProps) {
  const { profileInfo, isManuallyVerified } = useGetWorkFlowDataWithSources({
    entityId,
  })

  const t = useI18n([WORKFLOW_EVENTS_KEY], {
    keys: workflowEventsEn,
  })

  return (
    <div
      className="flex justify-between"
      data-qa={personalInfoSectionQa.container}
    >
      <div className="flex flex-col w-3/4 gap-3">
        <div className="flex justify-between">
          <div
            className="font-semibold text-md text-tertiary-grey-800"
            data-qa={personalInfoSectionQa.header}
          >
            {t('personalInfo.header')}
          </div>
          <div
            className="min-w-[244px] basis-[244px] desktop:basis-[450px] text-tertiary-grey-800 font-medium"
            data-qa={personalInfoSectionQa.sourceHeader}
          >
            {eventResult !== WorkflowStepResultEnum.UNCHECKED &&
              !isManuallyVerified &&
              t('personalInfo.sourceHeader')}
          </div>
        </div>
        {profileInfo && <LabelAndSources labelAndSources={profileInfo} />}
      </div>
    </div>
  )
}
