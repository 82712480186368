import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { useForm } from 'react-hook-form'

import {
  FrankieButton,
  FrankieIcon,
  FrankieLoader,
  FrankieTextField,
} from 'frankify/src'

import {
  ApplicantId,
  ApplicantResponse,
  applicantDetailConfig,
  useApplicantDataQuery,
  useApplicantHostedFlowMutation,
  useApplicantUpdateDetailsMutation,
} from 'entities/applicant'
import { countryPhoneCodeList } from 'entities/country'
import { useSessionQuery } from 'entities/session'

import {
  ConfirmationFormField,
  SelectFormField,
  TextAreaFormField,
} from 'shared/form'
import { Show } from 'shared/hoc'
import { useI18n } from 'shared/i18n'
import { notification } from 'shared/notification'
import { Noop } from 'shared/typescript'

import { APPLICANT_BUSINESS_OWNERSHIP_KEY } from '../../../applicant-business-ownership.key'
import { applicantBusinessOwnershipEn } from '../../../locale/applicant-business-ownership.en'
import { businessOwnerShipDataGridActionMenuQa } from '../../../qa/applicant-business-ownership.qa'

type ModalState =
  | 'send-kyc-link'
  | 'copy-link'
  | 'text-message'
  | 'update-recipe'

type ModalStateRecord = Record<'prev' | 'curr', ModalState>

const defaultState: ModalStateRecord = {
  prev: 'send-kyc-link',
  curr: 'send-kyc-link',
}

const supportedCountry = ['AUS']
const australiaCountryCode = '61'

type FormData = {
  recipe: string
  iConfirmLinkSent: boolean
  country: string
  mobileNumber: string
  comment: string
  isCopied: boolean
}

type Props = {
  entityId: ApplicantId
  onClose: Noop
}

// eslint-disable-next-line complexity
export function EntitySendKycLinkModal({ entityId, onClose }: Props) {
  const t = useI18n([APPLICANT_BUSINESS_OWNERSHIP_KEY], {
    keys: applicantBusinessOwnershipEn,
  })

  const [state, setState] = useState<ModalStateRecord>(defaultState)

  const { watch, control, register, getValues, setValue } = useForm<FormData>({
    defaultValues: {
      country: australiaCountryCode,
    },
  })

  const { data } = useSessionQuery()

  const { data: applicantData, isLoading: loadingApplicantData } =
    useApplicantDataQuery({
      applicantId: entityId,
    })

  const currentRecipe = useMemo(
    () => applicantData?.applicantDetails.profile.profileType,
    [applicantData],
  )

  useEffect(() => {
    if (currentRecipe) {
      setValue('recipe', currentRecipe)
    }
  }, [currentRecipe, setValue])

  const {
    mutate: getHostedFlowLink,
    isLoading: isLoadingHostedFlowLink,
    isSuccess: isHostedFlowLinkSuccess,
    data: hostedFlowData,
    variables: hostedFlowVariables,
  } = useApplicantHostedFlowMutation()

  const {
    mutate: mutateApplicantUpdateDetails,
    isLoading: isApplicantUpdateDetailsLoading,
    isSuccess: isApplicantUpdateDetailsSuccess,
  } = useApplicantUpdateDetailsMutation()

  const recipeOptions = useMemo(() => {
    const defaultOption = [{ label: t('automaticSelection'), value: 'auto' }]
    return (
      data?.profiles?.profile_options.map(item => ({
        label: item.label,
        value: item.value.toLowerCase(), // in vue code, the value is converted to lowercase
      })) ?? defaultOption
    )
  }, [data?.profiles?.profile_options, t])

  const countryOption = useMemo(
    () =>
      countryPhoneCodeList
        .filter(item => supportedCountry.includes(item.code))
        .map(item => ({
          label: item.label,
          value: item.value,
        })),
    [],
  )

  /**
   * Modal heading
   */
  const heading = useMemo(() => {
    const record: Record<ModalState, string> = {
      'copy-link': t('copyTheLink'),
      'text-message': t('sendLinkMsg'),
      'send-kyc-link': t('action.sendKycLink'),
      'update-recipe': t('pleaseConfirm'),
    }
    return record[state.curr]
  }, [state, t])

  // Watch form values
  const watchRecipe = watch('recipe')
  const watchConfirmation = watch('iConfirmLinkSent')
  const watchIsCopied = watch('isCopied')
  const watchPhoneNumber = watch('mobileNumber')

  // Check if recipe is updated
  const isRecipeUpdated = useMemo(
    () => watchRecipe !== currentRecipe,
    [watchRecipe, currentRecipe],
  )

  // Get hosted flow link every time recipe changes
  useEffect(() => {
    if (watchRecipe && state.curr === 'send-kyc-link') {
      getHostedFlowLink({
        entityId,
        payload: { recipe: watchRecipe },
      })
    }
  }, [entityId, getHostedFlowLink, state.curr, watchRecipe])

  /**
   * Copy link to clipboard
   */
  const clipboardLink = useCallback(
    () =>
      hostedFlowData &&
      navigator.clipboard
        .writeText(hostedFlowData.smsLink)
        .then(() => setValue('isCopied', true))
        .catch(() => notification.error(t('error.copy'))),
    [hostedFlowData, setValue, t],
  )

  /**
   * Send link via text message
   */
  const sendLinkTextMessage = useCallback(() => {
    const { comment, country, mobileNumber, recipe } = getValues()
    getHostedFlowLink({
      entityId,
      payload: {
        recipe,
        pushToSms: true,
        smsPhoneNumber: `+${country}${mobileNumber}`,
        comment,
      },
      successMessage: t('success.linkSent'),
    })
  }, [entityId, getHostedFlowLink, getValues, t])

  // Close modal on success and final notification
  useEffect(() => {
    if (isApplicantUpdateDetailsSuccess) {
      if (state.prev === 'copy-link') {
        void clipboardLink()
        notification.success(t('success.linkCopied'))
        onClose()
      }

      if (state.prev === 'text-message' && !isLoadingHostedFlowLink) {
        sendLinkTextMessage()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isApplicantUpdateDetailsSuccess])

  useEffect(() => {
    if (
      isHostedFlowLinkSuccess &&
      !!hostedFlowVariables?.payload.pushToSms &&
      [state.curr, state.prev].includes('text-message')
    ) {
      onClose()
    }
  }, [
    hostedFlowVariables?.payload.pushToSms,
    isHostedFlowLinkSuccess,
    onClose,
    state,
  ])

  const updateState = (newState: ModalState, prevState?: ModalState) => {
    setState({ prev: prevState ?? state.curr, curr: newState })
  }

  const handleSendTextMessage = () => {
    if (isRecipeUpdated) {
      updateState('update-recipe')
    } else {
      sendLinkTextMessage()
    }
  }

  const handleCopyLink = () => {
    if (!hostedFlowData?.smsLink) {
      notification.error(t('error.noLinkCopy'))
      return
    }

    if (isRecipeUpdated) {
      updateState('update-recipe')
    } else {
      void clipboardLink()
    }
  }

  const handleRecipeOverride = () => {
    const applicant = applicantData?.applicantDetails
    if (!applicant) return

    const updateApplicant: ApplicantResponse['applicantDetails'] = {
      ...applicantDetailConfig,
      entityId: applicant.entityId,
      profile: {
        ...applicant.profile,
        profileType: getValues('recipe'),
      },
    }

    mutateApplicantUpdateDetails({
      entityId: applicant.entityId,
      payload: { applicant: updateApplicant },
    })
  }

  const containerClass = 'p-6 flex flex-col items-start'

  const initialLoading = isLoadingHostedFlowLink || loadingApplicantData
  const updatingRecipe =
    isApplicantUpdateDetailsLoading || isLoadingHostedFlowLink

  return (
    <div className="text-tertiary-grey-700 w-[510px]">
      <div className="py-4 px-6 flex gap-2 border-b border-tertiary-grey-300 text-md font-bold text-tertiary-grey-800">
        <FrankieIcon
          className="text-tertiary-grey-300"
          name="mdiCardAccountDetailsOutline"
          testId={{ icon: businessOwnerShipDataGridActionMenuQa.icon }}
        />
        <span>{heading}</span>
      </div>

      <Show>
        <Show.When isTrue={state.curr === 'send-kyc-link'}>
          <div className={`${containerClass} gap-4`}>
            <div className="text-tertiary-grey-800 font-bold text-lg">
              {t('selectRecipe')}
            </div>
            <SelectFormField
              className="my-2"
              options={recipeOptions}
              control={control}
              name="recipe"
              disabled={initialLoading}
            />

            <FrankieLoader
              loading={initialLoading}
              className="flex flex-col items-start gap-4"
              label={
                <div className="text-tertiary-grey-800 font-semibold">
                  {loadingApplicantData
                    ? t('loading.recipe')
                    : t('loading.link')}
                </div>
              }
            >
              <div className="text-tertiary-grey-800 font-bold text-lg">
                {t('sendLink')}
              </div>

              <div className="font-bold">
                {t('enterIndividualsPhoneNumber')}
              </div>

              <div className="">{t('checksWillBeRun')}</div>
            </FrankieLoader>

            <FrankieButton
              intent="subtle"
              className="!ps-0 !pe-2"
              startIcon={{
                size: 'lg',
                name: 'mdiCellphoneText',
                className: `pr-2 ${initialLoading ? '' : 'text-primary-500'}`,
              }}
              onClick={() => updateState('text-message')}
              disabled={initialLoading}
            >
              {t('sendLinkMsg')}
            </FrankieButton>

            <FrankieButton
              intent="subtle"
              className="!ps-0 !pe-2"
              startIcon={{
                size: 'lg',
                name: 'mdiLinkVariant',
                className: `pr-2 ${initialLoading ? '' : 'text-primary-500'}`,
              }}
              onClick={() => updateState('copy-link')}
              disabled={initialLoading}
            >
              {t('copyLink')}
            </FrankieButton>

            <FrankieButton
              className="mt-2"
              onClick={onClose}
              testId={{
                button: businessOwnerShipDataGridActionMenuQa.cancelBtn,
              }}
            >
              {t('cancel')}
            </FrankieButton>
          </div>
        </Show.When>

        <Show.When isTrue={state.curr === 'copy-link'}>
          <div className={`${containerClass} gap-4`}>
            <div className="font-bold">{t('copyLinkAndSendToApplicant')}</div>

            <div className="flex items-end ">
              <FrankieTextField
                label={t('secureLink')}
                value={hostedFlowData?.smsLink}
                className="!w-[300px]"
              />

              <FrankieButton
                className={`${
                  watchIsCopied && watchConfirmation
                    ? '!bg-tertiary-green-400 !text-mono-white'
                    : ''
                }`}
                onClick={handleCopyLink}
                disabled={!watchConfirmation}
              >
                {watchIsCopied ? t('copied') : t('copy')}
              </FrankieButton>
            </div>

            <ConfirmationFormField
              label={t('iConfirmLinkSent')}
              control={control}
              name="iConfirmLinkSent"
            />

            <div className="flex justify-between w-full mt-4">
              <FrankieButton onClick={onClose}>{t('done')}</FrankieButton>

              <FrankieButton
                intent="secondary"
                onClick={() => setState(defaultState)}
              >
                {t('back')}
              </FrankieButton>
            </div>
          </div>
        </Show.When>

        <Show.When isTrue={state.curr === 'text-message'}>
          <FrankieLoader
            loading={isLoadingHostedFlowLink}
            label={
              <div className="text-tertiary-grey-800 font-semibold">
                {t('loading.sendLink')}
              </div>
            }
          >
            <div className={`${containerClass} gap-6`}>
              <div className="font-bold">{t('sendLinkToApplicant')}</div>

              <SelectFormField
                key={state.curr}
                className="!w-[75%]"
                label={t('country')}
                options={countryOption}
                control={control}
                name="country"
              />

              <FrankieTextField
                className="!w-[75%]"
                label={t('mobilePhoneNumber')}
                {...register('mobileNumber', { required: true })}
              />

              <div>
                <div className="text-tertiary-grey-800 text-sm font-medium">
                  {t('comment')}
                </div>
                <TextAreaFormField
                  label={t('auditPurposeOnly')}
                  control={control}
                  name="comment"
                  placeholder={t('typeComment')}
                />
              </div>

              <div className="flex justify-between w-full">
                <FrankieButton
                  disabled={!watchPhoneNumber}
                  onClick={handleSendTextMessage}
                >
                  {t('sendLink')}
                </FrankieButton>

                <FrankieButton
                  intent="secondary"
                  onClick={() => setState(defaultState)}
                >
                  {t('back')}
                </FrankieButton>
              </div>
            </div>
          </FrankieLoader>
        </Show.When>

        <Show.When isTrue={state.curr === 'update-recipe'}>
          <FrankieLoader
            loading={updatingRecipe}
            label={
              <div className="text-tertiary-grey-800 font-semibold">
                {isApplicantUpdateDetailsLoading
                  ? t('loading.updatingRecipe')
                  : t('loading.sendLink')}
              </div>
            }
          >
            <div className={`${containerClass} gap-8`}>
              <div className="flex flex-col gap-6 px-4">
                <div className="text-tertiary-grey-800 font-bold">
                  {t('mayOverride')}
                </div>
                <div className="font-medium">{t('newCheckBeingRun')}</div>
                <div className="font-medium">{t('confirmToContinue')}</div>
              </div>
              <div className="flex justify-between w-full ">
                <FrankieButton
                  className="!w-[110px]"
                  onClick={handleRecipeOverride}
                >
                  {t('yes')}
                </FrankieButton>

                <FrankieButton intent="secondary" onClick={onClose}>
                  {t('cancel')}
                </FrankieButton>
              </div>
            </div>
          </FrankieLoader>
        </Show.When>
      </Show>
    </div>
  )
}
