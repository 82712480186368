import React from 'react'

import { FrankieButton, FrankieLoader } from 'frankify/src'

import { useFrankie2R2Customer } from 'entities/entity'

import { useI18n } from 'shared/i18n'
import { useOverlay } from 'shared/overlay'

import { EntityVerificationReverifyModal } from './entity-verification-reverify-modal/entity-verification-reverify-modal'
import { workflowEventsEn } from '../../locale/workflow-events.en'
import { useExecuteWorkflowMutation } from '../../mutation/execute-workflow.mutation'
import { applicantWorkflowEventsQa } from '../../qa/applicant-workflow-events.qa'
import { WORKFLOW_EVENTS_KEY } from '../../workflow-events.key'

type Props = {
  entityId: string
}
export function EntityVerificationReverify({ entityId }: Props) {
  const isFrankie2R2 = useFrankie2R2Customer()
  const t = useI18n([WORKFLOW_EVENTS_KEY], { keys: workflowEventsEn })
  const [createOverlay] = useOverlay()
  const { mutate: executeWorkflow, isLoading: isExecutingWorkflow } =
    useExecuteWorkflowMutation({ entityId })
  const handleExecuteWorkflow = () => {
    executeWorkflow({})
  }

  const handleOverlay = () => {
    createOverlay(
      <EntityVerificationReverifyModal onConfirm={executeWorkflow} />,
      { className: '!p-5 ', closeButtonClassName: '!top-5 !right-5' },
    )
  }

  return (
    <>
      <FrankieLoader
        label={t('executeWorkflow.runningWorkflow')}
        size="md"
        className="z-20"
        fullscreen
        loaderClassName="text-base font-semibold leading-[3rem]"
        loading={isExecutingWorkflow}
      />
      {isFrankie2R2 ? (
        <FrankieButton
          className="!bg-mono-white text-tertiary-grey-700 outline-1 outline-tertiary-grey-200 truncate"
          size="sm"
          testId={{
            button: applicantWorkflowEventsQa.verifyEntityCta,
          }}
          onClick={handleOverlay}
        >
          {t('executeWorkflow.runWorkflow')}
        </FrankieButton>
      ) : (
        <FrankieButton
          className="!bg-mono-white text-tertiary-grey-700 outline-1 outline-tertiary-grey-200 truncate"
          size="sm"
          onClick={handleExecuteWorkflow}
          testId={{
            button: applicantWorkflowEventsQa.verifyEntityCta,
          }}
        >
          {t('executeWorkflow.verifyEntityCta')}
        </FrankieButton>
      )}
    </>
  )
}
