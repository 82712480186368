import { IClient, bffClient } from 'shared/client'

import {
  IndividualProfilePayloadTypes,
  NewProfileCheckPayload,
} from '../model/individual-profile-payload.model'

export type SaveIndividualProfileResponse = {
  entityId: string
  requestId: string
}

export type NewProfileCheckResponse = {
  entityId: string
  detailLevel: string
  creditHeaderIssueMessage: string
  serviceDisplayableError: boolean
}

export class IndividualProfileApi {
  client: IClient

  constructor(client: IClient) {
    this.client = client
  }

  async saveIndividualProfile(
    profile: IndividualProfilePayloadTypes,
    saveOnly: boolean,
  ) {
    return this.client.post<
      SaveIndividualProfileResponse,
      IndividualProfilePayloadTypes
    >('data/v1/applicants', profile, {
      params: { saveOnly },
    })
  }

  async updateIndividualProfile(
    profile: IndividualProfilePayloadTypes,
    applicantId: string,
    saveOnly: boolean,
  ) {
    return this.client.put<
      SaveIndividualProfileResponse,
      IndividualProfilePayloadTypes
    >(`data/v1/applicants/${applicantId}`, profile, {
      params: { saveOnly, noInvalidate: false, noNameDobValidation: false },
    })
  }

  async newProfileCheck(
    entityId: string,
    params = { forceCheck: false, uploadDocs: false, noInvalidate: false },
  ) {
    return this.client.post<NewProfileCheckResponse, NewProfileCheckPayload>(
      `data/v1/applicants/${entityId}/checks`,
      {},
      { params },
    )
  }

  async creditHeaderAcknowledge(entityId: string) {
    return this.client.post(
      `data/v1/applicants/${entityId}/check-header-acknowledgement`,
    )
  }
}

export const individualProfileApi = new IndividualProfileApi(bffClient)
