import { FrankieBadgeTheme } from 'frankify/src'

import {
  applicantIssuesI18n,
  applicantRisksI18n,
  applicantsFilterEn,
  applicantStatusesI18n,
} from 'features/applicants-filter'

import {
  rawToApplicantEntityStatus,
  FrankieRawApplicant,
  ApplicantEntityStatusTypes,
  ApplicantRiskLevelTypes,
  RiskMapping,
  issuesMapping,
} from 'entities/applicant'

import { I18nFunction } from 'shared/i18n'

const badgeThemeMapping: Record<ApplicantEntityStatusTypes, FrankieBadgeTheme> =
  {
    [ApplicantEntityStatusTypes.Archived]: 'grey',
    [ApplicantEntityStatusTypes.Unchecked]: 'grey',
    [ApplicantEntityStatusTypes.Inactive]: 'grey',
    [ApplicantEntityStatusTypes.ManuallyFailed]: 'red',
    [ApplicantEntityStatusTypes.Failed]: 'red',
    [ApplicantEntityStatusTypes.NeedsAttention]: 'yellow',
    [ApplicantEntityStatusTypes.ManuallyPassed]: 'green',
    [ApplicantEntityStatusTypes.Passed]: 'green',
  }

export function getStatusBadge(
  applicant: FrankieRawApplicant,
  filterT: I18nFunction<typeof applicantsFilterEn>,
): {
  theme: FrankieBadgeTheme
  text: string
} {
  const mappedStatus = rawToApplicantEntityStatus[applicant.applicantStatus.key]

  return {
    theme: badgeThemeMapping[mappedStatus],
    text: filterT(applicantStatusesI18n[mappedStatus]),
  }
}

export function getRiskIndicator(
  applicant: FrankieRawApplicant,
  filterT: I18nFunction<typeof applicantsFilterEn>,
): {
  textClassName: string
  iconClassName: string
  text: string
} {
  const risk = RiskMapping[applicant.riskLevel.term]

  const riskTextColorMapping: Record<string, string> = {
    [ApplicantRiskLevelTypes.High]: 'text-tertiary-red-700',
    [ApplicantRiskLevelTypes.Unacceptable]: 'text-tertiary-red-700',
    [ApplicantRiskLevelTypes.Unknown]: 'text-tertiary-grey-600',
    [ApplicantRiskLevelTypes.Medium]: 'text-tertiary-yellow-700',
    [ApplicantRiskLevelTypes.Low]: 'text-tertiary-green-600',
  }
  const riskColorMapping: Record<string, string> = {
    [ApplicantRiskLevelTypes.High]: 'text-tertiary-red-500',
    [ApplicantRiskLevelTypes.Unacceptable]: 'text-tertiary-red-500',
    [ApplicantRiskLevelTypes.Unknown]: 'text-tertiary-grey-400',
    [ApplicantRiskLevelTypes.Medium]: 'text-tertiary-yellow-300',
    [ApplicantRiskLevelTypes.Low]: 'text-tertiary-green-500',
  }

  return {
    textClassName: riskTextColorMapping[risk],
    iconClassName: riskColorMapping[risk],
    text: filterT(applicantRisksI18n[risk]),
  }
}

export function getEntityIssues(
  applicant: FrankieRawApplicant,
  filterT: I18nFunction<typeof applicantsFilterEn>,
): string[] {
  return applicant.alertList.map(alert =>
    filterT(applicantIssuesI18n[issuesMapping[alert.term]]),
  )
}
