import { useEffect, useRef } from 'react'

/**
 * Custom hook that returns the previous value of the given input.
 *
 * @template T - The type of the value.
 * @param {T} value - The current value to track.
 * @returns {T | undefined} - The previous value of the input, or undefined if there is no previous value.
 */
export function usePrevious<T>(value: T): T | undefined {
  const ref = useRef<T>()

  useEffect(() => {
    ref.current = value
  })

  return ref.current
}
