import { APPLICANT_BLOCKLISTED_INFO_KEY as ABI } from '../applicant-bllocklisted-info.key'

const ApplicantBlockListInfo = `${ABI}-qa`
export const applicantBlockListFormQa = {
  container: `${ApplicantBlockListInfo}-container`,
  form: `${ApplicantBlockListInfo}-form`,
  saveBtn: `${ApplicantBlockListInfo}-save-btn`,
} satisfies Record<string, `${typeof ApplicantBlockListInfo}-${string}`>

const ApplicantBlockListReason = `${ABI}-blocklist-reason-qa`
export const blockListReasonQa = {
  select: `${ApplicantBlockListReason}-select`,
  textArea: `${ApplicantBlockListReason}-textArea`,
  container: `${ApplicantBlockListReason}-container`,
} satisfies Record<string, `${typeof ApplicantBlockListReason}-${string}`>

const ApplicantBlockListTypeForm = `${ABI}-blocklist-type-form-qa`
export const blockListTypeFormQa = {
  select: `${ApplicantBlockListTypeForm}-select`,
  container: `${ApplicantBlockListTypeForm}-container`,
} satisfies Record<string, `${typeof ApplicantBlockListTypeForm}-${string}`>

const ApplicantBlockListEmailForm = `${ABI}-blocklist-email-form-qa`
export const blockListEmailFormQa = {
  text: `${ApplicantBlockListEmailForm}-text`,
  container: `${ApplicantBlockListEmailForm}-container`,
} satisfies Record<string, `${typeof ApplicantBlockListEmailForm}-${string}`>

const ApplicantBlockListPhoneNumberForm = `${ABI}-blocklist-phone-number-form-qa`
export const blockListPhoneNumberFormQa = {
  text: `${ApplicantBlockListPhoneNumberForm}-text`,
  container: `${ApplicantBlockListPhoneNumberForm}-container`,
} satisfies Record<
  string,
  `${typeof ApplicantBlockListPhoneNumberForm}-${string}`
>

const ApplicantBlockListPersonalForm = `${ABI}-blocklist-personal-form-qa`
export const blockListPersonalFormQa = {
  container: `${ApplicantBlockListPersonalForm}-container`,
} satisfies Record<string, `${typeof ApplicantBlockListPersonalForm}-${string}`>

const ApplicantBlockListDocumentForm = `${ABI}-blocklist-document-form-qa`
export const blockListDocumentFormQa = {
  container: `${ApplicantBlockListDocumentForm}-container`,
} satisfies Record<string, `${typeof ApplicantBlockListDocumentForm}-${string}`>

const ApplicantBlockListAddressForm = `${ABI}-blocklist-address-form-qa`
export const blockListAddressFormQa = {
  container: `${ApplicantBlockListAddressForm}-container`,
} satisfies Record<string, `${typeof ApplicantBlockListAddressForm}-${string}`>
