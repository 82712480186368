import { useCallback } from 'react'

import { useQuery, useQueryClient } from '@tanstack/react-query'

const AUDIT_REPORT_DOWNLOAD_STATE_QUERY_KEY = ['auditReportDownloadState']

export const useAuditReportDownloadingState = () => {
  const queryClient = useQueryClient()

  const query = useQuery({
    queryKey: AUDIT_REPORT_DOWNLOAD_STATE_QUERY_KEY,
    initialData: false,
    queryFn: () =>
      queryClient.getQueryData<boolean>(AUDIT_REPORT_DOWNLOAD_STATE_QUERY_KEY),
  })
  return query
}

export const useUpdateAuditReportDownloadingState = () => {
  const queryClient = useQueryClient()

  const update = useCallback(
    (value: boolean) => {
      queryClient.setQueryData(AUDIT_REPORT_DOWNLOAD_STATE_QUERY_KEY, value)
    },
    [queryClient],
  )

  return update
}
