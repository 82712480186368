import React from 'react'

import { ABRSearch } from 'features/abr-search-australia'
import { KYBSearch } from 'features/kyb-search-v2'

import { useHasFeatureFlag } from 'entities/session'

export function NewProfilePage() {
  const { hasInternationalKYB } = useHasFeatureFlag({
    hasInternationalKYB: 'internationalKYB',
  })

  return (
    <div className="flex justify-center overflow-auto h-[calc(100vh-126px)] p-3">
      {hasInternationalKYB ? <KYBSearch /> : <ABRSearch />}{' '}
    </div>
  )
}
