export enum SupportedFileTypes {
  JPEG = 'image/jpeg',
  PNG = 'image/png',
  GIF = 'image/gif',
  WEBP = 'image/webp',
  TIFF = 'image/tiff',
  BMP = 'image/bmp',
  PDF = 'application/pdf',
}

export const defaultSupportedFileTypes: SupportedFileTypes[] = [
  SupportedFileTypes.JPEG,
  SupportedFileTypes.PNG,
  SupportedFileTypes.GIF,
  SupportedFileTypes.WEBP,
  SupportedFileTypes.TIFF,
  SupportedFileTypes.BMP,
  SupportedFileTypes.PDF,
]

export type Scan = {
  scanId: string
  file: string
  mimeType: string
  side: string
  scanCreated: string
  scanName: string
}
