import React, { ReactElement } from 'react'

import { Link, useNavigate } from 'react-router-dom'

import { FrankieIcon, FrankieLoader } from 'frankify/src'

import {
  IRoleEditInputs,
  RoleEditForm,
  RoleEditInputTypes,
  useCreateRoleMutation,
  usePermissionOptionsQuery,
} from 'features/role-management'

import { PermissionTypes } from 'entities/role'
import { useHasPermission } from 'entities/session'

import { useI18n } from 'shared/i18n'
import { notification } from 'shared/notification'

import { settingsRoleNewQa } from '../../qa/settings-role-new.qa'
import { SETTINGS_ROLE_NEW_PAGE_KEY } from '../../settings-role-new.key'

type Props = {
  roleListPath: string
}

export function SettingsRoleNewPage({ roleListPath }: Props): ReactElement {
  const t = useI18n([SETTINGS_ROLE_NEW_PAGE_KEY])
  const navigate = useNavigate()
  const { canCreateRole, canFetchPermissions } = useHasPermission({
    canCreateRole: PermissionTypes.RoleCreateFunction,
    canFetchPermissions: PermissionTypes.ActionListFunction,
  })

  const { data: permissionList, isLoading } = usePermissionOptionsQuery({
    canFetchPermissions,
  })
  const { mutateAsync: createRole } = useCreateRoleMutation()

  const handleCreateRole = async (data: IRoleEditInputs) => {
    if (!canCreateRole) {
      return
    }
    await createRole({
      roleConfig: {
        description: data[RoleEditInputTypes.Description],
        roleName: data[RoleEditInputTypes.Name],
        action_list_ids: data[RoleEditInputTypes.Permissions].map(
          permission => permission.id,
        ),
      },
    })
    notification.success(
      t('notification.created', { name: data[RoleEditInputTypes.Name] }),
    )
    navigate(roleListPath)
  }

  const handleCancel = () => navigate(roleListPath)

  if (!isLoading) {
    return (
      <div className="h-[calc(100vh-92px)] p-8 pr-4 mr-2 overflow-y-auto scrollbar">
        <Link
          to={roleListPath}
          className="mb-3 text-tertiary-grey-500 text-xs flex leading-[18px]"
          data-qa={settingsRoleNewQa.goBackCta}
        >
          <FrankieIcon size="xs" name="mdiChevronLeft" />
          {t('cta.goBack')}
        </Link>
        <div
          data-qa={settingsRoleNewQa.header}
          className="mb-4 text-2xl font-bold text-tertiary-grey-700 leading-8"
        >
          {t('header.createRole')}
        </div>
        {permissionList && (
          <RoleEditForm
            canEditName={canCreateRole}
            canEditPermissions={canCreateRole}
            onSubmit={handleCreateRole}
            permissionOptions={permissionList}
            onCancel={handleCancel}
          />
        )}
      </div>
    )
  }

  return <FrankieLoader loading className="h-screen" />
}
