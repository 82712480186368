import { bffClient, IClient } from 'shared/client'

import { AmlStatusUpdateProps } from '../model/aml.model'
import { ProcessResultResponse } from '../model/entity.model'

export type AmlResultParams = {
  page?: number
  limit: number
  mock?: boolean
}

export class AmlResultAPI {
  client: IClient

  constructor(client: IClient) {
    this.client = client
  }

  async getAmlResult(entityId: string, { limit, page }: AmlResultParams) {
    return this.client.get<ProcessResultResponse>(
      `/f2/v1/entity/${entityId}/aml`,
      {
        params: {
          limit,
          page,
        },
      },
    )
  }

  async updateAmlStatus(entityId: string, amlStatusData: AmlStatusUpdateProps) {
    return this.client.patch<ProcessResultResponse, AmlStatusUpdateProps>(
      `/f2/v1/entity/${entityId}/aml`,
      amlStatusData,
    )
  }
}

export const amlResultApi = new AmlResultAPI(bffClient)
