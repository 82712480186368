import { fraudCheckEn } from './fraud-check.en'

export const APPLICANT_KEY = 'APPLICANT'

export const issueEn = {
  issue: {
    tooltip: {
      verified: 'This information has been verified',
      unverified: 'This information is unverified',
      delete: 'Delete',
      edit: 'Edit',
      view: 'View',
      resetPassword: 'Reset Password',
      noAMLMatches: 'No AML matches',
      didNotPassKyc:
        'Did not pass KYC ruleset. Please review and verify manually.',
      potentialAdverseMediaFound:
        'Potential Adverse Media matches found. Please review.',
      potentialPEPFound: 'Potential PEP match found. Please review.',
      potentialWatchlistFound:
        'Potential Watchlist match found. Please review.',
      potentialSanctionFound: 'Potential Sanction match found. Please review.',
      internalWatchList: 'Internal Watchlist',
      facialRecognitionFailed:
        'Facial recognition check has failed. Please review and verify manually.',
      ocrFailed: 'OCR check has failed. Please review and verify manually.',
      docCheckFailed:
        'ID document authenticity check has failed. Please review and verify manually.',
    },
    shortLabel: {
      pep: 'PEP',
      watchlist: 'WL',
      iwl: 'IW',
      duplicate: 'DUP',
      blacklist: 'BL',
      device: 'Device',
      fraud: 'Fraud',
      media: 'AM',
      txnFraud: 'Fraud Transactions',
      txnAml: 'AML Transactions',
      txnCustomer: 'Device/Customer',
      facialDuplicate: 'Facial Duplicate',
      digitalId: 'Digital ID',
      sanction: 'S',
      kyc: 'KYC',
      timeout: 'Timeout',
      document: 'Document',
      biometrics: 'Biometrics',
      risk: 'Risk',
    },
    label: {
      riskLow: 'Risk Level Low',
      riskMedium: 'Risk Level Medium',
      riskHigh: 'Risk Level High',
      riskUnacceptable: 'Risk Level Unacceptable',
      riskSignificant: 'Risk Level Significant',
      riskUnknown: 'Risk Level Unknown',
      waitOnCustomer: 'Waiting on Customer',
      unknown: 'Unknown',
      passed: 'Passed',
      unchecked: 'Unchecked',
      clear: 'Clear',
      failed: 'Failed',
      refer: 'Needs Attention',
      watchlist: 'Watchlist',
      duplicate: 'Duplicate',
      blacklist: 'Blocklist',
      media: 'Adverse Media',
      pep: 'PEP',
      pepClass1: 'PEP Class 1',
      pepClass2: 'PEP Class 2',
      pepClass3: 'PEP Class 3',
      pepClass4: 'PEP Class 4',
      pepClass5: 'PEP Class 5',
      sanction: 'Sanctions',
      amlError: 'AML Error',
      systemError: 'System Error',
      kyc: 'KYC',
      passedManual: 'Manually Passed',
      failManual: 'Manually Failed',
      archived: 'Archived',
      inactive: 'Inactive',
    },
  },
}

export const generateIndividualReportEn = {
  generateReport: 'Generate Report',
  generateIndividualVerificationReport:
    'Generate Individual Verification Report',

  close: 'Close',
  cancel: 'Cancel',
  retry: 'Retry',
  openReport: 'Open Report',
  description: {
    reportAvailable:
      'This report is now available under Individual Verification Reports on the Personal Info tab.',
    pleaseWaitForReport: 'Please wait, we are generating your report',
    closeAlternatively:
      'Alternatively, you may close this modal at any time and we will notify you when your report is ready.',
    containsSummary:
      'This report contains a summary of the checks completed and current status of the Individual.',
    reflectionUptoThisPoint:
      'The following report will be a reflection of this profile at this point in time. If the profile is updated a new report will need to be manually run.',
  },
  error: {
    generateIndividualReport:
      'We were unable to generate the report at this time. Please try again, or contact Support for further assistance.',
  },
  status: {
    reportGeneratedSuccess:
      ' Individual Verification Report for {{name}} successfully generated',
  },
}

export const applicantEn = {
  assignTo: 'Assign to',
  addComment: 'Add Comment',
  send: 'Send',
  sending: 'Sending...',
  sent: 'Sent',
  choose: 'Choose',
  error: {
    updatingAssignee: 'Error occurred during assigning.',
    addingComment: 'Error occurred adding comments.',
    fraudChecks: 'Error fetching Onboarding Fraud results',
    linkGeneration: 'Link Generation failed',
    linkNotSent: 'Failed to send Text Message',
    securityError:
      'There was a security problem. The requested information does not belong to this account. Please log in with the correct account.',
    defaultMessage: 'There was an issue. Please try again.',
  },
  generateIndividualReport: { ...generateIndividualReportEn },
  fraud: fraudCheckEn,
  ...issueEn,
}
