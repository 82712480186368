import React, { useCallback, useEffect } from 'react'

import { useQuery, useQueryClient } from '@tanstack/react-query'

import {
  useApplicantState,
  IndividualReportGenerationNotificationSessionKey,
  RequestedReportData,
  useGenerateIndividualReportQuery,
} from 'entities/applicant'

import { useI18n } from 'shared/i18n'
import { notification } from 'shared/notification'

import { APPLICANT_GENERAL_INFORMATION_KEY } from '../../applicant-general-information.key'
import { applicantGeneralInformationEn } from '../../locale/applicant-general-information.en'

export function ApplicantReportGeneratedNotification() {
  const t = useI18n([APPLICANT_GENERAL_INFORMATION_KEY], {
    keys: applicantGeneralInformationEn,
  })

  const queryClient = useQueryClient()

  const { data: notificationData, remove } = useQuery<RequestedReportData>({
    queryKey: IndividualReportGenerationNotificationSessionKey,
    queryFn: () => {
      const cachedSession = queryClient.getQueryData<RequestedReportData>(
        IndividualReportGenerationNotificationSessionKey,
      )
      return cachedSession ?? ({ requestedReport: {} } as RequestedReportData)
    },
  })

  const applicantId = notificationData?.applicantId

  const { reportUrl, needRetry } = useGenerateIndividualReportQuery({
    applicantId,
    isEnabled: true,
  })

  const { applicantName } = useApplicantState({ applicantId })

  const resetNotification = useCallback(() => {
    queryClient.setQueryData<RequestedReportData>(
      IndividualReportGenerationNotificationSessionKey,
      { applicantId: undefined },
    )

    remove()
  }, [queryClient, remove])

  useEffect(() => {
    if (needRetry) {
      notification.error(t('error.generateIndividualReport'))
      resetNotification()
    }
  }, [needRetry, resetNotification, t])

  /**
   * Notification Trigger when report is getting generated in background
   */
  useEffect(() => {
    if (reportUrl) {
      notification.success(
        <div className="flex flex-col gap-1 text-xs text-mono-white">
          <div>
            {t('status.reportGeneratedSuccess', { name: applicantName })}
          </div>
          <div>{t('action.description.reportAvailable')}</div>
          <a
            className="font-bold"
            href={reportUrl}
            target="_blank"
            rel="noreferrer"
          >
            {t('action.confirm.openReport')}
          </a>
        </div>,
        { autoClose: false },
      )
      resetNotification()
    }
  }, [applicantName, resetNotification, reportUrl, t])

  return null
}
