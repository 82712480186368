import React from 'react'

import { useSearchParams } from 'react-router-dom'

import { ApplicantsSearchParamsTypes } from 'features/applicants-filter'
import { ProfileSearchParamsTypes } from 'features/profiles-filter'

import { useI18n } from 'shared/i18n'

import {
  GLOBAL_SEARCH_KEY,
  globalSearchEn,
} from '../../locale/global-search.en'

export function GlobalSearchTitle() {
  const t = useI18n([GLOBAL_SEARCH_KEY], { keys: globalSearchEn })
  const [searchParams] = useSearchParams()

  // eslint-disable-next-line complexity
  const search =
    searchParams.get(ApplicantsSearchParamsTypes.NameFilter) ||
    searchParams.get(ApplicantsSearchParamsTypes.EntityIdFilter) ||
    searchParams.get(ProfileSearchParamsTypes.EntityName) ||
    searchParams.get(ProfileSearchParamsTypes.CustomerId) ||
    searchParams.get(ProfileSearchParamsTypes.EntityId) ||
    ''

  return (
    <div className="text-2xl font-bold text-tertiary-grey-800">
      {t('searchResultTitle', { search })}
    </div>
  )
}
