import React from 'react'

import { useI18n } from 'shared/i18n'

import {
  NOTIFICATIONS_MANAGEMENT_KEY,
  notificationsManagementEn,
} from '../../../locale/notifications-management.en'
import { INotificationChannel } from '../../../model/notifications-management.model'
import { notificationsConfigTableRowHeaderQa } from '../../../qa/notifications-management.qa'
import { NotificationsConfigTableRowLayout } from '../notifications-config-table-row-layout/notifications-config-table-row-layout'

type Props = {
  channels: INotificationChannel[]
}

export function NotificationsConfigTableRowHeader({ channels }: Props) {
  const t = useI18n([NOTIFICATIONS_MANAGEMENT_KEY], {
    keys: notificationsManagementEn,
  })

  return (
    <div
      data-qa={notificationsConfigTableRowHeaderQa.container}
      className="w-full text-sm font-semibold text-tertiary-grey-700 mb-2 "
    >
      <NotificationsConfigTableRowLayout
        notificationTypeCell={
          <>
            <div data-qa={notificationsConfigTableRowHeaderQa.header}>
              {t('type.header')}
            </div>
            <div className="grow" />
          </>
        }
        frequencyCell={
          <div data-qa={notificationsConfigTableRowHeaderQa.frequency}>
            {t('type.frequency')}
          </div>
        }
        channelCells={channels.map(channel => ({
          cell: (
            <div
              data-qa={notificationsConfigTableRowHeaderQa.rowHeaderChannelCell}
              className="!capitalize"
            >
              {channel.value.toLowerCase()}
            </div>
          ),
          key: channel.value,
        }))}
      />
    </div>
  )
}
