import React, { useCallback, useMemo } from 'react'

import {
  GridColumnHeaderParams,
  GridRowProps,
  GridSortModel,
} from '@mui/x-data-grid-pro'

import {
  FrankieButton,
  FrankieIcon,
  FrankieLoader,
  FrankieTableHeaderCell,
} from 'frankify/src'

import { ApplicantIssueActionBadge } from 'entities/applicant'

import { useI18n } from 'shared/i18n'
import { ExclusiveProperty, PartialRecord, aliasKeys } from 'shared/typescript'

import { APPLICANT_PEP_SACTIONS_KEY } from '../../applicant-pep-sactions.key'
import { applicantPepSactionsEn } from '../../locale/applicant-pep-sactions.en'
import {
  PepSactionsMatchRecord,
  PepSactionsMatchRecordProperties,
  PepSactionsStatus,
  PepSactionsStatusResult,
} from '../../model/applicant-pep-sactions.model'

export type RowProps = GridRowProps & { row: PepSactionsMatchRecord }
export type HeaderProps = GridColumnHeaderParams<PepSactionsMatchRecord>

type SelectCellProps = {
  selectedMatchIds: string[]
  setSelectedMatchIds: (selectedMatchIds: string[]) => void
}

export function SelectedEntityCellHeader({
  rows,
  selectedMatchIds,
  setSelectedMatchIds,
}: HeaderProps & SelectCellProps & { rows: PepSactionsMatchRecord[] }) {
  const t = useI18n(APPLICANT_PEP_SACTIONS_KEY, {
    keys: applicantPepSactionsEn,
  })

  const selected = useMemo(
    () =>
      rows.every(row => selectedMatchIds.includes(row.id)) && rows.length > 0,
    [rows, selectedMatchIds],
  )

  const handleSelectAll = () => {
    if (selected) {
      setSelectedMatchIds([])
    } else {
      const allMatchListIds: string[] = []
      rows.forEach(row => {
        if (!allMatchListIds.includes(row.id)) {
          allMatchListIds.push(row.id)
        }
      })
      setSelectedMatchIds(allMatchListIds)
    }
  }
  return (
    <div className="mr-4 ml-auto">
      <FrankieButton
        noStyles
        onClick={handleSelectAll}
        className="w-4 h-4 p-[2px] mx-2 m-auto shadow-sm border border-tertiary-grey-500 border-solid rounded-full"
      >
        {selected && (
          <div className="rounded-full bg-tertiary-red-500 w-full h-full" />
        )}
      </FrankieButton>
      <span> {t('field.selected')}</span>
    </div>
  )
}

export function SelectedEntityCell({
  row,
  selectedMatchIds,
  setSelectedMatchIds,
}: RowProps & SelectCellProps) {
  const selected = selectedMatchIds.includes(row.id)

  const handleSelectAll = () => {
    setSelectedMatchIds(
      selected
        ? selectedMatchIds.filter(matchId => matchId !== row.id)
        : [...selectedMatchIds, row.id],
    )
  }

  return (
    <FrankieButton
      noStyles
      onClick={e => {
        e.stopPropagation()
        handleSelectAll()
      }}
      className="w-4 h-4 p-[2px] ml-2 shadow-sm border border-tertiary-grey-500 border-solid rounded-full"
    >
      {selected && (
        <div className="rounded-full bg-tertiary-red-500 w-full h-full" />
      )}
    </FrankieButton>
  )
}

export function PepSactionsTableHeader({
  colDef,
  sortModel,
}: HeaderProps & { sortModel: GridSortModel }) {
  const fieldName = colDef.field as PepSactionsMatchRecordProperties

  const currentSortValue = useMemo(
    () => sortModel.find(item => item.field === fieldName)?.sort || 'off',
    [sortModel, fieldName],
  )

  const fieldClassName = 'text-tertiary-grey-500 font-medium'

  return (
    <div className="relative flex items-center py-2">
      {colDef.sortable ? (
        <FrankieTableHeaderCell className="!px-0" sortValue={currentSortValue}>
          <span className={fieldClassName}>{colDef.headerName}</span>
        </FrankieTableHeaderCell>
      ) : (
        <span className={fieldClassName}>{colDef.headerName}</span>
      )}
    </div>
  )
}

export function PepSactionsIssuesCell({ row }: RowProps) {
  return (
    <div className="flex flex-wrap gap-[5px]">
      {row.issues.map(issue => (
        <ApplicantIssueActionBadge
          key={issue}
          className="!font-semibold text-xs !p-1 max-w-min"
          type={issue}
        />
      ))}
    </div>
  )
}

export const usePepSactionsMatchStatusLabel = () => {
  const t = useI18n(APPLICANT_PEP_SACTIONS_KEY, {
    keys: applicantPepSactionsEn,
  })

  const getLabel = useCallback(
    (matchStatus?: PepSactionsStatusResult) => {
      const labelMap: PartialRecord<PepSactionsStatus, string> = {
        true_positive: t('status.true'),
        false_positive: t('status.false'),
        unknown: t('status.unknown'),
        possible_match: t('status.possible'),
        true_positive_accept: t('status.approved'),
        true_positive_reject: t('status.rejected'),
        true_positive_approve: t('status.approved'),
      }

      if (matchStatus) {
        return labelMap[matchStatus] ?? t('status.possible')
      }

      return t('status.possible')
    },
    [t],
  )

  const sortMatchStatusComparator = useCallback(
    (a?: PepSactionsStatusResult, b?: PepSactionsStatusResult) => {
      const aLabel = getLabel(a)
      const bLabel = getLabel(b)
      return aLabel.localeCompare(bLabel)
    },
    [getLabel],
  )

  return { getLabel, sortMatchStatusComparator }
}

type PepSactionsMatchStatusProps = {
  matchStatus?: PepSactionsMatchRecord['matchStatus'] | null
  className?: string
}

export function PepSactionsMatchStatusLabel({
  className,
  matchStatus,
}: PepSactionsMatchStatusProps) {
  const { getLabel } = usePepSactionsMatchStatusLabel()

  return <div className={className}>{getLabel(matchStatus)}</div>
}

export function PepSactionsMatchCell({ row }: RowProps) {
  const colorClass = useMemo(() => {
    const colorClassMap: PartialRecord<PepSactionsStatus, string> = {
      ...aliasKeys(
        ['true_positive', 'true_positive_reject'],
        'text-tertiary-red-500',
      ),
      ...aliasKeys(
        ['false_positive', 'true_positive_accept', 'true_positive_approve'],
        'text-tertiary-green-500',
      ),

      ...aliasKeys(['unknown', 'possible_match'], 'text-tertiary-amber-500'),
    }

    if (row.matchStatus) {
      return colorClassMap[row.matchStatus] ?? 'text-tertiary-amber-500'
    }

    return 'text-tertiary-amber-500'
  }, [row.matchStatus])

  return (
    <PepSactionsMatchStatusLabel
      className={`font-medium ${colorClass}`}
      matchStatus={row.matchStatus}
    />
  )
}

type MatchStrengthBarProps = { className?: string; strength: number }

export function PepSactionsMatchStrengthBar({
  className = '',
  strength = 0,
}: MatchStrengthBarProps) {
  return (
    <div
      className={`${className} w-[110px] bg-tertiary-grey-300 h-[6px] rounded-2xl`}
    >
      <div
        className="bg-primary-500 h-[6px] rounded-2xl"
        style={{
          width: `${strength}%`,
        }}
      />
    </div>
  )
}

export function PepSactionsMatchStrengthCell({ row }: RowProps) {
  return <PepSactionsMatchStrengthBar strength={row.matchScore} />
}

type PepSactionsSlotProps = ExclusiveProperty<{
  loadingOverlay: true
  noRowsOverlay: true
}>

export function PepSactionsSlot({
  loadingOverlay,
  noRowsOverlay,
}: PepSactionsSlotProps) {
  const t = useI18n(APPLICANT_PEP_SACTIONS_KEY, {
    keys: applicantPepSactionsEn,
  })

  return (
    <div className="flex items-center pt-12 justify-start flex-col w-full h-full bg-mono-white opacity-80">
      <div>
        {loadingOverlay && (
          <FrankieLoader loading className="min-h-[35px]" size="sm" />
        )}
        {noRowsOverlay && (
          <FrankieIcon
            size="lg"
            name="mdiFileSearchOutline"
            className="text-primary-300 flex justify-center"
          />
        )}
        <div className="flex flex-col items-center mt-3">
          <span className="inline-block text-md font-semibold leading-6 text-tertiary-grey-800">
            {loadingOverlay && t('loading.records')}
            {noRowsOverlay && t('empty.records')}
          </span>
        </div>
      </div>
    </div>
  )
}

export const pepSactionsDataGridSx = {
  '.MuiDataGrid-columnSeparator': {
    display: 'none',
  },
  '& .MuiDataGrid-row': {
    borderBottomWidth: '1px',
    borderStyle: 'solid',
  },
  '& .MuiDataGrid-virtualScroller': {
    minHeight: 'calc(100vh - 325px)',
    maxHeight: 'calc(100vh - 325px)',
  },
  '&.MuiDataGrid-root': {
    border: 'none',
    overflow: 'hidden',
    '& .MuiDataGrid-row': {
      alignItems: 'center',
    },
  },
  '& .MuiDataGrid-columnHeaderTitleContainerContent': {
    height: '100%',
  },
  '& .MuiDataGrid-columnHeaders': {
    minHeight: 'unset !important',
    maxHeight: '40px !important',
    lineHeight: 'unset !important',
    borderBottom: '1px solid blue',
    // p: '0 2%',
  },
  '.MuiDataGrid-columnHeaderTitleContainer': {
    display: 'inline-block !important',
    flex: 'none !important',
  },
  '.MuiDataGrid-cell': {
    minHeight: 'unset !important',
    maxHeight: 'unset !important',
    alignItems: 'center',
    lineHeight: 'unset !important',
  },
}
