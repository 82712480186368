import React from 'react'

import { FrankieButton, FrankieLoader } from 'frankify/src'

import { useApplicantSingleSpaOverviewPath } from 'entities/applicant'
import {
  IMatchedOrganization,
  useCreateProfileROW,
  useGenerateReportROW,
} from 'entities/organisation'

import { useI18n } from 'shared/i18n'
import { notification } from 'shared/notification'
import { Noop } from 'shared/typescript'

import { ORGANISATION_AUDIT_KEY } from '../../locale/organisation-audit.en'

type Props = {
  onClose: Noop
  organisationInfo: IMatchedOrganization
}

export function OrganisationProfileReportModal({
  organisationInfo,
  onClose,
}: Props) {
  const t = useI18n([ORGANISATION_AUDIT_KEY])

  const { mutateAsync: createProfileROW, isLoading: isCreatingProfile } =
    useCreateProfileROW()

  const { mutateAsync: generateReportROW, isLoading: isGeneratingReport } =
    useGenerateReportROW('profile')

  const { navigateToOverview } = useApplicantSingleSpaOverviewPath()

  const handleClickProfileReport = async () => {
    try {
      const {
        data: {
          organization: { entityId },
        },
      } = await createProfileROW({
        organisationToken: organisationInfo.organizationToken,
      })

      await generateReportROW({ entityId, reportType: 'profile' })

      onClose()

      navigateToOverview(entityId, 'onboarding')
    } catch (error) {
      notification.error(t('errorCreatingProfileROW'))
    } finally {
      onClose()
    }
  }
  // There was an issue generating an organisation profile. Please try again

  const isLoading = isCreatingProfile || isGeneratingReport

  return (
    <FrankieLoader loading={isLoading}>
      <span className="block text-tertiary-grey-800 text-xl font-bold">
        {t('profileReportModal.title')}
      </span>
      <span className="block text-tertiary-grey-700 text-sm font-normal mt-2">
        {t('profileReportModal.subtitle')}
      </span>
      <div className="flex flex-row justify-end mt-6">
        <FrankieButton intent="subtle" onClick={onClose}>
          {t('profileReportModal.cancelButton')}
        </FrankieButton>
        <FrankieButton
          intent="primary"
          className="ml-2"
          onClick={handleClickProfileReport}
        >
          {t('profileReportModal.generateReport')}
        </FrankieButton>
      </div>
    </FrankieLoader>
  )
}
