export const settingsPersonalPageEn = {
  header: 'Multi-factor authentication',
  description:
    'Increase security by enabling multi-factor authentication (MFA) when you log in to FrankieOne.',
  enabled: 'Enabled',
  disabled: 'Disabled',
  enableButtonText: 'Enable MFA',
  disableButtonText: 'Disable MFA',
  learnMoreLink: 'Learn more.',
}
