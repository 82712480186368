import { useMutation } from '@tanstack/react-query'

import { organisationApi } from '../../api/organisation.api'
import { CreateProfileInternationalBody } from '../../model/applicant.model'
import { getInternationalProfileFromMatchOrganisation } from '../../model/international-organisation.model'
import { IMatchedOrganization } from '../../model/organisation.model'

export const useCreateProfileInternational = () => {
  const mutate = useMutation({
    mutationFn: (payload: CreateProfileInternationalBody) =>
      organisationApi.createProfileInternational(payload),
  })

  const createProfileInternational = async (
    organisationInfo: IMatchedOrganization,
  ) => {
    const response = await mutate.mutateAsync(
      getInternationalProfileFromMatchOrganisation(organisationInfo),
    )
    return response.data
  }

  return {
    ...mutate,
    createProfileInternational,
  }
}
