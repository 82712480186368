import React from 'react'

import classNames from 'classnames'

import { FrankieButton } from 'frankify/src'

import { I18nKeys, useI18n } from 'shared/i18n'
import { useOverlay } from 'shared/overlay'

import { APPLICANT_KEY, applicantEn } from '../../../locale/applicant.en'
import { IFraudCheckpointRule } from '../../../model/applicant-fraud-check.model'
import { CheckRulesModal } from '../check-rules-modal/check-rules-modal'

const labelMap = {
  high: 'fraud.result.high',
  medium: 'fraud.result.medium',
  low: 'fraud.result.low',
  very_high: 'fraud.result.veryHigh',
  unknown: 'fraud.result.unknown',
} satisfies Record<string, I18nKeys<typeof applicantEn>>

const getLabel = (
  riskLevel: string | null,
  checkResult: 'fail' | 'unchecked' | 'pass',
): I18nKeys<typeof applicantEn> => {
  if (checkResult === 'unchecked') {
    return 'fraud.result.unchecked'
  }
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  return labelMap[riskLevel as keyof typeof labelMap] || labelMap.unknown
}

type Props = {
  title: string
  riskLevel: string | null
  buttonLabel: string
  modalTitle: string
  ruleIds: string[] | null
  rules: IFraudCheckpointRule[] | null
  checkResult: 'fail' | 'unchecked' | 'pass'
  onViewRules: () => void
  deviceSardineUrl: string | null
}
export function CheckHeader({
  title,
  riskLevel,
  buttonLabel,
  modalTitle,
  ruleIds,
  rules,
  checkResult,
  onViewRules,
  deviceSardineUrl,
}: Props) {
  const t = useI18n(APPLICANT_KEY, { keys: applicantEn })
  const [createOverlay] = useOverlay()
  const handleViewRules = () => {
    createOverlay(
      <CheckRulesModal
        title={modalTitle}
        rules={rules}
        ruleIds={ruleIds}
        deviceSardineUrl={deviceSardineUrl}
      />,
      {
        className: 'p-8 w-[500px]',
      },
    )
    onViewRules()
  }

  const hasLiveRules = !!rules?.filter(
    rule => rule.isLive && rule.ruleId && ruleIds?.includes(rule.ruleId),
  ).length

  return (
    <div className="flex flex-row items-center justify-between">
      <div className="flex flex-row gap-1.5 items-center mb-[18px] ">
        <div className="text-xl font-bold">{title}</div>
        <div
          className={classNames('flex flex-row gap-1.5 items-center', {
            'text-tertiary-red-500': checkResult === 'fail',
            'text-tertiary-green-500': checkResult === 'pass',
            'text-tertiary-grey-400': checkResult === 'unchecked',
          })}
        >
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none">
            <circle cx="6" cy="6" r="6" fill="currentColor" />
          </svg>
          <div
            className={classNames({
              'text-tertiary-red-700': checkResult === 'fail',
              'text-tertiary-green-600': checkResult === 'pass',
              'text-tertiary-grey-500': checkResult === 'unchecked',
            })}
          >
            {t(getLabel(riskLevel, checkResult))}
          </div>
        </div>
      </div>
      {hasLiveRules && (
        <FrankieButton
          onClick={handleViewRules}
          className="text-primary-800 cursor-pointer"
          noStyles
        >
          {buttonLabel}
        </FrankieButton>
      )}
    </div>
  )
}
