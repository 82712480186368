import { cva } from 'cva'

export const ContainerStyle = cva(
  ['relative', 'cursor-pointer', 'inline-block'],
  {
    variants: {
      size: {
        sm: ['w-[40px]', 'h-[20px]'],
        md: ['w-[44px]', 'h-[24px]'],
        lg: ['w-[56px]', 'h-[28px]'],
      },
    },
  },
)

export const CheckboxStyle = cva(['w-0', 'h-0', 'opacity-0'])

export const SliderStyle = cva(
  [
    'absolute',
    'top-0',
    'left-0',
    'right-0',
    'bottom-0',
    'rounded-[40px]',
    'before:content-[""]',
    'before:absolute',
    'before:block',
    'before:rounded-full',
    'before:bg-mono-white',
    'before:transition',
    'before:duration-300',
  ],
  {
    variants: {
      size: {
        sm: [
          'before:h-[16px]',
          'before:w-[16px]',
          'before:top-0.5',
          'before:left-[2.5px]',
        ],
        md: [
          'before:h-[19px]',
          'before:w-[19px]',
          'before:top-[2.5px]',
          'before:left-[3px]',
        ],
        lg: [
          'before:h-[22px]',
          'before:w-[22px]',
          'before:top-[3px]',
          'before:left-[3.5px]',
        ],
      },
      checked: {
        true: [],
      },
      disabled: { true: ['cursor-auto'] },
    },
    compoundVariants: [
      {
        size: 'sm',
        checked: true,
        className: 'before:translate-x-[18.5px]',
      },
      {
        size: 'md',
        checked: true,
        className: 'before:translate-x-[18px]',
      },
      {
        size: 'lg',
        checked: true,
        className: 'before:translate-x-[27.5px]',
      },
      // disabled
      {
        checked: true,
        disabled: false,
        className: 'bg-primary-800',
      },
      {
        checked: false,
        disabled: false,
        className: 'bg-tertiary-grey-300',
      },
      {
        checked: true,
        disabled: true,
        className: 'bg-primary-200',
      },
      {
        checked: false,
        disabled: true,
        className: 'bg-tertiary-grey-200',
      },
    ],
  },
)
