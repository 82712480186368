export const GLOBAL_SEARCH_KEY = 'global-search'

export const globalSearchEn = {
  searchResultTitle: 'Search result for "{{search}}"',

  defaultPlaceholder: 'Search FrankieOne',
  searchByTitle: 'I’M LOOKING FOR',
  searchByIndividualName: 'Individual name',
  searchByIndividualNamePlaceholder:
    'Search for an individual by their full name or surname',
  searchByOrganisationName: 'Organisation name',
  searchByOrganisationNamePlaceholder:
    'Search for an organisation by its full legal name',
  searchByCustomerId: 'Customer ID',
  searchByCustomerIdPlaceholder:
    'Search for an entity by its assigned Customer ID',
  searchByCustomerIdF2: 'Customer Ref',
  searchByCustomerIdPlaceholderF2:
    'Search for an entity by its assigned Customer Ref',
  searchByFrankieId: 'Frankie ID',
  searchByFrankieIdPlaceholder:
    'Search for an entity by its assigned Frankie ID',
  searchByEntityId: 'Entity ID',
  searchByEntityIdPlaceholder: 'Search for an entity by its assigned Entity ID',
  searchByAbnOrAcn: 'ABN / ACN',
  searchByAbnOrAcnPlaceholder: 'Search for an organisation by its ABN or ACN',

  badResultsTitle: 'Not the results that you expected?',
  badResultsFeedback: 'Give feedback',
  badResultsOr: '<0>{{feedback}}</0> or <2>{{docs}}</2>',
  badResultsDocs: 'learn how our search works',

  recentSearchesTitle: 'RECENT SEARCHES',

  noResultsTitle: 'No match found',
  noResultsSubtitle:
    'Your {{searchBy}} search “{{value}}” did not return any matches.',
  noResultsInfo:
    'Check for any typos or try again with additional or exact terms.',
  noResultsDocs: '<0>{{docs}}</0> about how our search works.',
  noResultsDocsLearnMore: 'Learn more',
}
