import { COMMENT_KEY } from '../comments.key'

const commentBox = `${COMMENT_KEY}-commentBox`
export const commentBoxQa = {
  container: `${commentBox}-container`,
  avatar: `${commentBox}-avatar`,
  name: `${commentBox}-name`,
  date: `${commentBox}-date`,
  action: `${commentBox}-action`,
  status: `${commentBox}-status`,
  comment: `${commentBox}-comment`,
} satisfies Record<string, `${typeof commentBox}-${string}`>

const commentToolTip = `${COMMENT_KEY}-commentToolTip`
export const commentToolTipQa = {
  container: `${commentToolTip}-container`,
  logo: `${commentToolTip}-logo`,
} satisfies Record<string, `${typeof commentToolTip}-${string}`>

const expandedComment = `${COMMENT_KEY}-expandedComment`
export const expandedCommentQa = {
  container: `${expandedComment}-container`,
  title: `${expandedComment}-title`,
  upIcon: `${expandedComment}-upIcon`,
  downIcon: `${expandedComment}-downIcon`,
  closeIcon: `${expandedComment}-closeIcon`,
  commentContainer: `${expandedComment}-commentContainer`,
  avatar: `${expandedComment}-avatar`,
  name: `${expandedComment}-name`,
  date: `${expandedComment}-date`,
  action: `${expandedComment}-action`,
  status: `${expandedComment}-status`,
  comment: `${expandedComment}-comment`,
} satisfies Record<string, `${typeof expandedComment}-${string}`>
