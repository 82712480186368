import React, { useState } from 'react'

import classNames from 'classnames'
import { Link, NavLink, useMatch } from 'react-router-dom'

import {
  FrankieAvatar,
  FrankieButton,
  FrankieIcon,
  FrankiePopover,
} from 'frankify/src'

import { GlobalSearch } from 'fwidgets/global-search'
import { SuprSendNotification } from 'fwidgets/suprsend-notifications'

import { logout, replaceUrl } from 'features/logout'
import { resetPasswordApi } from 'features/reset-password'

import { ApplicantId } from 'entities/applicant'
import { useSessionQuery, useHasFeatureFlag } from 'entities/session'

import { useI18n } from 'shared/i18n'
import { notification, notificationQa } from 'shared/notification'

import { ROUTING_KEY } from '../../locale/routing.en'
import { routingQa } from '../../qa/routing.qa'

type Props = {
  newProfilePath: string
  settingsPath: string
  loginPath: string
  canSeeNewApplicantCta: boolean
  globalSearchOpen: boolean
  setGlobalSearchOpen: (open: boolean) => void
  searchResultsPath: string
  getApplicantPath: (
    id: ApplicantId,
    search?: { matchStatusFilter?: string },
  ) => string
}

// eslint-disable-next-line complexity
export function PortalHeader({
  newProfilePath,
  settingsPath,
  loginPath,
  canSeeNewApplicantCta,
  globalSearchOpen,
  setGlobalSearchOpen,
  searchResultsPath,
  getApplicantPath,
}: Props) {
  const t = useI18n(ROUTING_KEY)
  const { isFrankie2 } = useHasFeatureFlag({ isFrankie2: 'frankie2customer' })
  const matchSearchResults = useMatch(searchResultsPath)
  const [isChangePwdDisabled, setIsChangePwdDisabled] = useState<boolean>(false)
  const { data: session } = useSessionQuery()
  const email = session?.user.email
  const realName = session?.user.realname || 'Anonymous'

  const { hasEventNotifications } = useHasFeatureFlag({
    hasEventNotifications: 'eventNotifications',
  })

  const distinctId = `${session?.user.id || ''}@${
    session?.user.organizationId || ''
  }:${session?.suprSend?.suprSendPortalBaseURL || ''}`
  const subscriberId = session?.suprSend?.suprSendSubscriberId
  const suprSendWorkspaceKey = session?.suprSend?.suprSendWorkspaceKey
  const shouldShowSuprSendInbox = !!(
    subscriberId &&
    suprSendWorkspaceKey &&
    hasEventNotifications
  )

  const menuItemClassName =
    'px-4 py-2 text-sm w-full flex text-tertiary-grey-700 hover:bg-tertiary-grey-100'

  const handleLogout = () => {
    logout()
    replaceUrl(loginPath)
  }

  const handleChangePassword = async () => {
    if (email) {
      try {
        setIsChangePwdDisabled(true)
        await resetPasswordApi.requestResetPasswordLink(email)
        setIsChangePwdDisabled(false)
        notification.success(
          t('header.notification.resetPassword', { email }),
          {
            icon: (
              <FrankieIcon
                testId={{ icon: notificationQa.successIcon }}
                size="sm"
                className="w-8 h-8 flex flex-initial justify-center items-center bg-tertiary-green-600 rounded-full"
                name="mdiLockReset"
              />
            ),
          },
        )
      } catch (error) {
        setIsChangePwdDisabled(false)
      }
    }
  }

  return (
    <div className="flex justify-between items-center px-6 tablet:px-8 py-2.5 gap-6 h-[68px]">
      <GlobalSearch
        open={globalSearchOpen}
        isSearchProfiles={isFrankie2}
        setOpen={setGlobalSearchOpen}
        redirectPath={searchResultsPath}
        shouldResetFilter={isFrankie2 ? false : matchSearchResults === null}
      />
      <div
        className={classNames('justify-end items-center shrink-0', {
          flex: !globalSearchOpen,
          'hidden laptop:flex': globalSearchOpen,
        })}
      >
        {canSeeNewApplicantCta && (
          <NavLink
            className="mr-6"
            to={newProfilePath}
            data-qa={routingQa.newProfileLink}
          >
            <FrankieButton
              notAButton
              size="sm"
              startIcon={{ name: 'mdiPlus', size: 'xs' }}
              intent="primary"
            >
              {t('nav.newProfile')}
            </FrankieButton>
          </NavLink>
        )}
        {shouldShowSuprSendInbox && (
          <SuprSendNotification
            distinctId={distinctId}
            subscriberId={subscriberId}
            workspaceKey={suprSendWorkspaceKey}
            getApplicantPath={getApplicantPath}
          />
        )}
        <FrankiePopover
          trigger={
            <FrankieButton
              testId={{ button: routingQa.accountMenuCta }}
              noStyles
            >
              <FrankieAvatar name={realName} />
            </FrankieButton>
          }
        >
          <div className="absolute bg-mono-white top-0 -right-4 mt-1 w-56 py-1 shadow-md rounded">
            <Link data-qa={routingQa.settingsLink} to={settingsPath}>
              <div className={menuItemClassName}>
                {t('header.settingsLink')}
              </div>
            </Link>
            <FrankieButton
              testId={{ button: routingQa.changePasswordCta }}
              className={menuItemClassName}
              onClick={handleChangePassword}
              disabled={isChangePwdDisabled}
              noStyles
            >
              {t('header.changePasswordCta')}
            </FrankieButton>
            <hr className="text-tertiary-grey-200 h-[1px]" />
            <FrankieButton
              testId={{ button: routingQa.logoutCta }}
              className={menuItemClassName}
              noStyles
              onClick={handleLogout}
            >
              {t('header.logoutCta')}
            </FrankieButton>
          </div>
        </FrankiePopover>
      </div>
    </div>
  )
}
