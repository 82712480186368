import { useMutation } from '@tanstack/react-query'

import { organisationApi } from 'entities/organisation/api/organisation.api'

type Args = {
  entityId: string
}

export const useAmlCheck = () =>
  useMutation({
    mutationFn: ({ entityId }: Args) => organisationApi.checkAmlPep(entityId),
    onSuccess: response => response.data,
  })
