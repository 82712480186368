export const individualAuditReportF2En = {
  header: {
    title: 'Audit report',
    subTitle: 'Record of all activity and status changes on the entity.',
    dropDownPlaceHolder: 'Source',
    cta: 'Generate report',
  },
  section: {
    label: 'Request ID',
    clickToCopy: 'Click to copy',
    copied: 'Copied to clipboard',
  },
  emptyScreen: {
    title: 'No match found with selected filters',
    subTitle1: 'Your selected filters did not return any matches.',
    subTitle2: 'Adjust your filters and try again.',
  },
  status: {
    Archived: 'Archived',
    Passed: 'Passed',
    Failed: 'Failed',
    review: 'Needs Review',
  },
  label: {
    connector: 'Data connector',
    entity: 'Entity',
    frankieOne: 'FrankieOne',
    portalUser: 'Portal user',
    generatingReport: 'Generating report',
  },
  message: {
    success: 'Audit report downloaded',
  },
}
