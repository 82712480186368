import React from 'react'

type Props = {
  field: React.ReactNode
  value: React.ReactNode
}

export function OrganisationInfoRow({ field, value }: Props) {
  return (
    <div className="flex w-full border-b-[1px] border-solid border-tertiary-grey-200">
      <div className="w-[232px] py-3 px-4 uppercase text-sm font-medium text-tertiary-grey-500">
        {field}
      </div>
      <div className="flex-grow py-3 px-4 text-sm font-normal text-tertiary-grey-700 whitespace-pre-wrap">
        {value}
      </div>
    </div>
  )
}
