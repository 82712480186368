import {
  ApplicantEntityStatusTypes,
  ApplicantIssueTypes,
  ApplicantRiskLevelTypes,
} from 'entities/applicant'

import { I18nKeys } from 'shared/i18n'

import { applicantsFilterEn } from '../locale/applicants-filter.en'

const applicantStatusesOrder: ApplicantEntityStatusTypes[] = [
  ApplicantEntityStatusTypes.NeedsAttention,
  ApplicantEntityStatusTypes.Passed,
  ApplicantEntityStatusTypes.ManuallyPassed,
  ApplicantEntityStatusTypes.Failed,
  ApplicantEntityStatusTypes.ManuallyFailed,
  ApplicantEntityStatusTypes.Unchecked,
  ApplicantEntityStatusTypes.Archived,
  ApplicantEntityStatusTypes.Inactive,
]

export const applicantStatuses3Columns: ApplicantEntityStatusTypes[][] = [
  applicantStatusesOrder.slice(0, 3),
  applicantStatusesOrder.slice(3, 6),
  applicantStatusesOrder.slice(6),
]

export const applicantStatusesI18n: Record<
  ApplicantEntityStatusTypes,
  I18nKeys<typeof applicantsFilterEn>
> = {
  [ApplicantEntityStatusTypes.Archived]: 'applicantStatuses.archived',
  [ApplicantEntityStatusTypes.Failed]: 'applicantStatuses.failed',
  [ApplicantEntityStatusTypes.Inactive]: 'applicantStatuses.inactive',
  [ApplicantEntityStatusTypes.ManuallyFailed]:
    'applicantStatuses.manuallyFailed',
  [ApplicantEntityStatusTypes.ManuallyPassed]:
    'applicantStatuses.manuallyPassed',
  [ApplicantEntityStatusTypes.NeedsAttention]:
    'applicantStatuses.needsAttention',
  [ApplicantEntityStatusTypes.Passed]: 'applicantStatuses.passed',
  [ApplicantEntityStatusTypes.Unchecked]: 'applicantStatuses.unchecked',
}

const applicantIssuesOrder: ApplicantIssueTypes[] = [
  ApplicantIssueTypes.AdverseMedia,
  ApplicantIssueTypes.Biometrics,
  ApplicantIssueTypes.Blocklist,
  ApplicantIssueTypes.Device,
  ApplicantIssueTypes.DigitalId,
  ApplicantIssueTypes.Document,
  ApplicantIssueTypes.Duplicate,
  ApplicantIssueTypes.FraudTransaction,
  ApplicantIssueTypes.FacialDuplication,
  ApplicantIssueTypes.InternalWatchlist,
  ApplicantIssueTypes.KYC,
  ApplicantIssueTypes.PEP,
  ApplicantIssueTypes.Risk,
  ApplicantIssueTypes.Sanctions,
  ApplicantIssueTypes.Timeout,
  ApplicantIssueTypes.Watchlist,
]

export const applicantIssues2Columns: ApplicantIssueTypes[][] = [
  applicantIssuesOrder.slice(0, Math.ceil(applicantIssuesOrder.length / 2)),
  applicantIssuesOrder.slice(Math.ceil(applicantIssuesOrder.length / 2)),
]

export const applicantIssues3Columns: ApplicantIssueTypes[][] = [
  applicantIssuesOrder.slice(0, 6),
  applicantIssuesOrder.slice(6, 12),
  applicantIssuesOrder.slice(12),
]

export const applicantIssuesI18n: Record<
  ApplicantIssueTypes,
  I18nKeys<typeof applicantsFilterEn>
> = {
  [ApplicantIssueTypes.AdverseMedia]: 'applicantIssues.adverseMedia',
  [ApplicantIssueTypes.Biometrics]: 'applicantIssues.biometrics',
  [ApplicantIssueTypes.Blocklist]: 'applicantIssues.blocklist',
  [ApplicantIssueTypes.Device]: 'applicantIssues.device',
  [ApplicantIssueTypes.DigitalId]: 'applicantIssues.digitalId',
  [ApplicantIssueTypes.Document]: 'applicantIssues.document',
  [ApplicantIssueTypes.Duplicate]: 'applicantIssues.duplicate',
  [ApplicantIssueTypes.FacialDuplication]: 'applicantIssues.facialDuplication',
  [ApplicantIssueTypes.FraudTransaction]: 'applicantIssues.fraudTransaction',
  [ApplicantIssueTypes.InternalWatchlist]: 'applicantIssues.internalWatchlist',
  [ApplicantIssueTypes.KYC]: 'applicantIssues.kyc',
  [ApplicantIssueTypes.PEP]: 'applicantIssues.pep',
  [ApplicantIssueTypes.Risk]: 'applicantIssues.risk',
  [ApplicantIssueTypes.Sanctions]: 'applicantIssues.sanctions',
  [ApplicantIssueTypes.Timeout]: 'applicantIssues.timeout',
  [ApplicantIssueTypes.Watchlist]: 'applicantIssues.watchlist',
  // not in filters
  [ApplicantIssueTypes.AMLTransaction]: 'applicantIssues.device',
  [ApplicantIssueTypes.DeviceCustomer]: 'applicantIssues.device',
}

export const applicantRisksColumns: ApplicantRiskLevelTypes[][] = [
  [ApplicantRiskLevelTypes.Low, ApplicantRiskLevelTypes.Medium],
  [ApplicantRiskLevelTypes.High, ApplicantRiskLevelTypes.Unacceptable],
  [ApplicantRiskLevelTypes.Unknown],
]

export const applicantRisksI18n: Record<
  ApplicantRiskLevelTypes,
  I18nKeys<typeof applicantsFilterEn>
> = {
  [ApplicantRiskLevelTypes.Low]: 'applicantRisks.low',
  [ApplicantRiskLevelTypes.Medium]: 'applicantRisks.medium',
  [ApplicantRiskLevelTypes.High]: 'applicantRisks.high',
  [ApplicantRiskLevelTypes.Unacceptable]: 'applicantRisks.unacceptable',
  [ApplicantRiskLevelTypes.Unknown]: 'applicantRisks.unknown',
}
