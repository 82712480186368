import { bffClient, IClient } from 'shared/client'

import { RiskLevelData } from '../model/applicant-risk-score.model'

export class ApplicantRiskScoreAPI {
  client: IClient

  constructor(client: IClient) {
    this.client = client
  }

  async changeRiskLevel(entityId: string, payload: RiskLevelData) {
    return this.client.put(
      `data/v1/applicants/${entityId}/checks/risk/level`,
      payload,
    )
  }
}
export const applicantRiskScoreApi = new ApplicantRiskScoreAPI(bffClient)
