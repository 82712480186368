import { useI18n } from 'shared/i18n'

import { ROLE_MANAGEMENT_F2_KEY } from '../role-management-f2.key'

export const roleManagementF2En = {
  roles: 'Roles',
  permissions: 'Permissions',
  viewAccess: 'View Access',
  writeAndDownloadAccess: 'Create, Edit, and Download access',
  settings: 'Settings',
  loading: 'Getting roles and permissions...',
}

export const useRoleManagementTranslation = () => {
  const t = useI18n([ROLE_MANAGEMENT_F2_KEY], { keys: roleManagementF2En })

  return { t }
}
