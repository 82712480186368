import React from 'react'

import { TPath, useHasFeatureFlag } from 'entities/session'

import { LayoutNewProfileF1 } from './layout-new-profile-f1/new-profile-f1'
import { LayoutNewProfileF2 } from './layout-new-profile-f2/new-profile-f2'

type NewProfilePropsType = {
  paths: {
    individualPath: TPath
    businessProfile: TPath
  }
}

export function LayoutNewProfile({ paths }: NewProfilePropsType): JSX.Element {
  const { isFrankie2 } = useHasFeatureFlag({ isFrankie2: 'frankie2customer' })

  return isFrankie2 ? (
    <LayoutNewProfileF2 />
  ) : (
    <LayoutNewProfileF1 paths={paths} />
  )
}
