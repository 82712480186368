import { useQuery } from '@tanstack/react-query'

import { entityApi } from '../../api/entity.api'

export type Args = {
  entityId: string
  disabled?: boolean
}

export const EntityWorkflowDataQueryKey = 'entity-workflow-data-query'

export const useGetWorkflowEventsData = ({ entityId, disabled }: Args) =>
  useQuery({
    queryKey: [EntityWorkflowDataQueryKey, { entityId }],
    enabled: !!entityId && !disabled,
    queryFn: async () => {
      const res = await entityApi.getWorkflowEvents(entityId)
      return res.data.serviceProfile
    },
  })
