import React, { ReactNode } from 'react'

type Props = {
  nameCell: ReactNode
  emailCell: ReactNode
  isLockedCell: ReactNode
  roleCell?: ReactNode
  mfaCell: ReactNode
  lastLoginDateCell: ReactNode
  createdDateCell: ReactNode
  actionsCell: ReactNode
}

export function UserTableRowLayout({
  nameCell,
  emailCell,
  isLockedCell,
  roleCell,
  mfaCell,
  lastLoginDateCell,
  createdDateCell,
  actionsCell,
}: Props) {
  return (
    <div className="w-full flex flex-initial flex-row border-b border-solid border-tertiary-grey-200">
      <div className="basis-[180px] shrink-0 min-w-0">{nameCell}</div>
      <div className="basis-full min-w-[100px] shrink">{emailCell}</div>
      <div className="basis-[124px] shrink-0">{isLockedCell}</div>
      {roleCell && (
        <div className="basis-[200px] shrink-0 min-w-0">{roleCell}</div>
      )}
      <div className="basis-[140px] shrink-0">{mfaCell}</div>
      <div className="basis-[140px] shrink-0">{createdDateCell}</div>
      <div className="basis-[188px] shrink-0">{lastLoginDateCell}</div>
      <div className="basis-[84px] shrink-0">{actionsCell}</div>
    </div>
  )
}
