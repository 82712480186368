import { useQueryClient } from '@tanstack/react-query'

import {
  getReportRequestIdQueryKey,
  InternationalReportType,
} from '../../model/organisation.model'

type Props = {
  entityId: string
}
type Data = {
  requestId: string
}

// TODO: Typescript is not able to infer the type of the props passed to the hook
export function useReportRequestIdQuery<T extends Props | undefined>(
  argsData?: T,
) {
  const queryClient = useQueryClient()

  const setRequestIdData = (
    reportType: InternationalReportType,
    data: T extends Props ? Data : Props & Data,
  ) => {
    const { entityId } = {
      ...(argsData as Props),
      entityId: (data as Props).entityId,
    }

    const queryKeyWithReportType = getReportRequestIdQueryKey(
      reportType,
      entityId,
    )

    queryClient.setQueryData(queryKeyWithReportType, data.requestId)
  }

  return { setRequestIdData }
}
