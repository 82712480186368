import React, { useMemo } from 'react'

import { FrankieButton } from 'frankify/src'

import { ApplicantResponse, Document } from 'entities/applicant'
import {
  DOCUMENT_KEY,
  DocumentTypes,
  DocumentView,
  documentTypesOption,
  documentTypeNameEn,
  useDocumentScansQuery,
  DocumentTypeOption,
} from 'entities/document'

import { useI18n } from 'shared/i18n'
import { useOverlay } from 'shared/overlay'
import { arraysAsValueObject } from 'shared/typescript'

import { APPLICANT_POTENTIAL_MATCHES_KEYS } from '../../applicant-potential-matches.keys'
import { applicantPotentialMatchesEn } from '../../locale/applicant-potential-matches.en'
import {
  makeSorterAlphabetically,
  makeSorterByExistence,
} from '../../models/applicant-matches.models'
import { BlacklistResponseType } from '../../models/blacklist.model'
import { appPotentialMatchesQa } from '../../qa/applicant-potential-matches.qa'

type DocumentInfoViewProps = {
  document: Document
  isError: boolean
}

function DocumentInfoView({ document, isError }: DocumentInfoViewProps) {
  const [createOverlay] = useOverlay()

  const t = useI18n(APPLICANT_POTENTIAL_MATCHES_KEYS, {
    keys: applicantPotentialMatchesEn,
  })

  const tDoc = useI18n(DOCUMENT_KEY, {
    keys: documentTypeNameEn,
  })

  const { data: scan } = useDocumentScansQuery({
    documentId: document.documentId,
  })

  const scanData = scan?.[0]

  const documentTypesOptionRecord = arraysAsValueObject(documentTypesOption)

  const docName = useMemo(() => {
    const currentOptions = documentTypesOptionRecord[
      document.idType as DocumentTypes
    ] as DocumentTypeOption | undefined

    const tKey = currentOptions?.tDefaultKey ?? currentOptions?.tKey

    return tKey ? tDoc(tKey) : document.idType
  }, [document.idType, documentTypesOptionRecord, tDoc])

  const handleView = () => {
    if (!scanData) {
      return
    }

    createOverlay(
      <DocumentView
        fileUrl={scanData.file}
        fileName={docName}
        mimeType={scanData.mimeType}
        uploadedOn={scanData.scanCreated}
      />,
    )
  }

  return (
    <div
      className="flex items-center justify-start flex-wrap mt-3 px-8"
      key={document.documentId}
    >
      <div className={` basis-1/3 ${isError ? 'text-tertiary-red-500' : ''}`}>
        {docName}
      </div>

      <div className={` basis-1/3 ${isError ? 'text-tertiary-red-500' : ''}`}>
        {document.idNumber ?? '-'}
      </div>

      <div className="basis-1/3">
        <FrankieButton
          startIcon={{
            name: 'mdiCardAccountDetails',
            className: 'mr-3 text-tertiary-grey-300',
            size: 'sm',
          }}
          size="xs"
          className={`!w-[120px] ${
            scanData?.file ? '!bg-mono-100 !outline-mono-50' : ''
          }`}
          disabled={!scanData?.file}
          onClick={handleView}
        >
          {t('documentInfo.view')}
        </FrankieButton>
      </div>
    </div>
  )
}

type Props = {
  applicantData?: ApplicantResponse
  comparisonData?: ApplicantResponse
  matches?: BlacklistResponseType
  isLoading?: boolean
}

export function ApplicantDocumentInfo({
  applicantData,
  matches,
  isLoading = false,
  comparisonData,
}: Props) {
  // Not sure if errorClasses is needed here
  // const [errorClasses, setErrorClasses] = useState<string[]>([])

  const t = useI18n(APPLICANT_POTENTIAL_MATCHES_KEYS, {
    keys: applicantPotentialMatchesEn,
  })

  // useEffect(() => {
  //   if (matches) {
  //     setErrorClasses(getMatchedFields(matches.matches.personal))
  //   }
  // }, [matches])

  const errorIds = useMemo(() => {
    const docIds = applicantData?.documents.map(doc => doc.idNumber)
    const comparisonDocIdx = comparisonData?.documents.map(doc => doc.idNumber)

    if (!docIds?.length || !comparisonDocIdx?.length) return []

    return docIds.filter(docId => comparisonDocIdx.includes(docId))
  }, [applicantData?.documents, comparisonData?.documents])

  const sortedDocuments = useMemo(() => {
    const docs = applicantData?.documents.filter(d => d.idType !== 'REPORT')
    const comparisonDoc = comparisonData?.documents.filter(
      d => d.idType !== 'REPORT',
    )

    if (!docs?.length) return []
    if (!comparisonDoc?.length)
      return [...docs].sort((a, b) =>
        `${b.idNumber ?? ''}`.localeCompare(`${a.idNumber ?? ''}`),
      )

    const sortByExistence = makeSorterByExistence(comparisonDoc)
    const sortAlphabetically = makeSorterAlphabetically()

    return [...docs].sort(sortAlphabetically).sort(sortByExistence)
  }, [applicantData?.documents, comparisonData?.documents])

  if (isLoading) return null

  return (
    <div
      className="text-tertiary-grey-700 p-5 pt-0 pb-8"
      data-qa={appPotentialMatchesQa.wrapper}
    >
      <div className="text-lg mb-3 font-bold">{t('documentInfo.title')}</div>

      {!sortedDocuments.length ? (
        <div>{t('noDocs')}</div>
      ) : (
        <div className="flex items-center justify-start flex-wrap mt-3 mb-[-4px] px-8">
          <div className="basis-1/3 text-tertiary-grey-400">
            {t('documentInfo.idProvided')}
          </div>

          <div className="basis-1/3 text-tertiary-grey-400">
            {t('documentInfo.documentNumber')}
          </div>
          <div className="basis-1/3" />
        </div>
      )}

      {sortedDocuments.map(document => (
        <DocumentInfoView
          document={document}
          isError={errorIds.includes(document.idNumber)}
        />
      ))}
    </div>
  )
}
