import React, { useEffect, useMemo, useState } from 'react'

import {
  Controller,
  ControllerRenderProps,
  FormProvider,
  useForm,
} from 'react-hook-form'

import {
  FrankieButton,
  FrankieCheckbox,
  FrankieLoader,
  FrankieTextField,
} from 'frankify/src'

import { ApplicantId, applicantDetailConfig } from 'entities/applicant'
import {
  OTHER_ROLE_KEY,
  useApplicantBusinessAssociationRolesQuery,
} from 'entities/organisation'
import { useSessionQuery } from 'entities/session'

import { ConfirmationFormField, getError } from 'shared/form'
import { useI18n } from 'shared/i18n'
import { Noop } from 'shared/typescript'
import { DECIMAL_2_PLACES_PATTERN } from 'shared/validation'

import { AssociateExistingParties } from './associate-existing-parties/associate-existing-parties'
import { AssociateNewParties } from './associate-new-parties/associate-new-parties'
import { APPLICANT_BUSINESS_OWNERSHIP_KEY } from '../../applicant-business-ownership.key'
import { applicantBusinessOwnershipEn } from '../../locale/applicant-business-ownership.en'
import { AssociatePartyFormData } from '../../model/associate-party.model'
import { applicantAssociatePartiesQa } from '../../qa/applicant-business-ownership.qa'
import {
  AssociationType,
  useApplicantAssociateBusinessMutation,
} from '../../state/applicant-associate-business/applicant-associate-business.mutation'

type RoleCheckboxProps = {
  field: ControllerRenderProps<AssociatePartyFormData, 'roles'>
  typeDescription: string
  type: string
  className?: string
}

function RoleCheckbox({
  field,
  typeDescription,
  type,
  className = '',
}: RoleCheckboxProps) {
  const isChecked = field.value.includes(type)

  return (
    <label
      className={`basis-[32%] flex-grow-0 flex gap-2 items-center text-tertiary-grey-800 font-medium cursor-pointer ${className}`}
    >
      <FrankieCheckbox
        value={field.value}
        checked={isChecked}
        onChange={() =>
          isChecked
            ? field.onChange(field.value.filter(item => item !== type))
            : field.onChange([...field.value, type])
        }
      />
      {typeDescription}
    </label>
  )
}

type Props = {
  applicantId: ApplicantId
  entityId?: ApplicantId
  associationType: AssociationType
  defaultValues?: Partial<AssociatePartyFormData>
  onClose: Noop
  testId?: { container: string }
}

export function ApplicantAssociateParties({
  applicantId,
  associationType,
  entityId,
  defaultValues = {},
  onClose,
  testId,
}: Props) {
  const t = useI18n([APPLICANT_BUSINESS_OWNERSHIP_KEY], {
    keys: applicantBusinessOwnershipEn,
  })

  const form = useForm<AssociatePartyFormData>({
    defaultValues: {
      entityId,
      roles: [],
      partyType: 'INDIVIDUAL',
      individualData: {
        givenName: '',
        middleName: '',
        familyName: '',
      },
      hidePartyFromSearch: false,
      ...defaultValues,
    },
    mode: 'onChange',
  })

  const {
    handleSubmit,
    control,
    register,
    watch,
    setValue,
    formState: { errors, isValid },
  } = form

  const {
    mutate,
    isSuccess,
    isLoading: isAssociatePartyLoading,
  } = useApplicantAssociateBusinessMutation({
    applicantId,
    associationType,
  })

  const [askConfirmation, setAskConfirmation] = useState(false)

  const { data: pageData } = useSessionQuery()

  const { data: roleTypes, isLoading } =
    useApplicantBusinessAssociationRolesQuery()

  useEffect(() => {
    if (isSuccess) {
      onClose()
    }
  }, [isSuccess, onClose])

  /**
   * Final submission of the form
   */
  const handleMutation = (data: AssociatePartyFormData) => {
    setAskConfirmation(false)

    const isIndividual = data.partyType === 'INDIVIDUAL'

    const roles =
      roleTypes?.filter(role => data.roles.includes(role.type)) ?? []

    if (data.roles.includes(OTHER_ROLE_KEY)) {
      roles.push({
        type: OTHER_ROLE_KEY,
        typeDescription: data.otherRoleTypeDescription,
      })
    }

    mutate({
      entityId: data.entityId,
      mainData: {
        addedBy: pageData?.user.username ?? ' ',
        percentage: {
          beneficially: +data.beneficiallyOwnership,
          nonBeneficially: +data.nonBeneficiallyOwnership,
          jointly: null,
        },
        roles,
      },
      partyData: isIndividual
        ? {
            partyType: 'INDIVIDUAL',
            party: {
              ...applicantDetailConfig,
              name: {
                givenName: data.individualData.givenName,
                middleName: data.individualData.middleName,
                familyName: data.individualData.familyName,
                displayName: null,
              },
            },
          }
        : {
            partyType: 'BUSINESS',
            party: data.organisationData,
          },
      hidePartyFromSearch: data.hidePartyFromSearch,
    })
  }

  const onSubmit = (data: AssociatePartyFormData) => {
    if (associationType === 'updating') {
      handleMutation(data)
      return
    }

    setAskConfirmation(true)
  }

  const isIndividual = form.watch('partyType') === 'INDIVIDUAL'

  const watchBeneficiallyOwnershipValue = watch('beneficiallyOwnership')
  const watchNonBeneficiallyOwnershipValue = watch('nonBeneficiallyOwnership')

  useEffect(() => {
    const value1 = +watchBeneficiallyOwnershipValue
    const value2 = +watchNonBeneficiallyOwnershipValue
    setValue('total', value1 + value2)
  }, [
    setValue,
    watchBeneficiallyOwnershipValue,
    watchNonBeneficiallyOwnershipValue,
  ])

  const heading = useMemo(() => {
    if (associationType === 'new') {
      return t('associateNewParties')
    }

    if (associationType === 'existing') {
      return t('associateExistingParties')
    }

    return t('editAssociateParties')
  }, [associationType, t])

  return (
    <FormProvider {...form}>
      {askConfirmation ? (
        <div className="w-[500px]">
          <div className="py-4 px-6 border-b border-tertiary-grey-300 text-lg font-bold text-tertiary-grey-800">
            {isIndividual
              ? t('associateIndividual')
              : t('associateOrganisation')}
          </div>
          <form
            className="p-6 flex flex-col gap-4 items-start"
            onSubmit={handleSubmit(handleMutation)}
          >
            <div className="font-bold text-secondary-900">
              {t('wishToAddIndividualAsAssociatedParty')}
            </div>
            <ConfirmationFormField
              label={t('hideMonitoring')}
              control={control}
              name="hidePartyFromSearch"
              nonMandatory
            />
            <div className="flex justify-between w-full mt-4">
              <FrankieButton type="submit">{t('add')}</FrankieButton>

              <FrankieButton
                intent="secondary"
                onClick={() => setAskConfirmation(false)}
              >
                {t('cancel')}
              </FrankieButton>
            </div>
          </form>
        </div>
      ) : (
        <div
          data-qa={testId?.container}
          className={`max-w-[1024px] ${
            associationType === 'updating' ? 'w-[70vw]' : 'w-[95vw]'
          }`}
        >
          <div className="py-4 px-6 border-b border-tertiary-grey-300 text-lg font-bold text-tertiary-grey-800">
            {heading}
          </div>

          <FrankieLoader loading={isAssociatePartyLoading} size="md">
            <form
              className="py-4 px-6  max-h-[calc(95vh-60px)] overflow-y-auto"
              onSubmit={handleSubmit(onSubmit)}
              noValidate
            >
              {associationType === 'new' && <AssociateNewParties />}
              {associationType === 'existing' && <AssociateExistingParties />}
              <div className="flex flex-wrap items-end gap-x-[2%] gap-y-8">
                <Controller
                  render={({ field }) => (
                    <div className="basis-full">
                      <div className="text-md font-bold mb-4">
                        {t('roleType')}
                      </div>
                      <FrankieLoader
                        loading={isLoading}
                        className={`flex flex-wrap gap-x-[2%] gap-y-4 text-tertiary-grey-800 font-semibold ${
                          isLoading ? 'min-h-[130px]' : ''
                        }`}
                        label={t('loading.roles')}
                      >
                        {roleTypes?.map(({ type, typeDescription }) => (
                          <RoleCheckbox
                            key={type}
                            field={field}
                            typeDescription={typeDescription}
                            type={type}
                          />
                        ))}

                        <div className="basis-full flex">
                          <RoleCheckbox
                            className="basis-auto mr-4"
                            field={field}
                            typeDescription={t('other')}
                            type={OTHER_ROLE_KEY}
                          />

                          {field.value.includes(OTHER_ROLE_KEY) && (
                            <FrankieTextField
                              className="max-w-[320px]"
                              placeholder={t('pleaseSpecify')}
                              {...register('otherRoleTypeDescription', {
                                required: true,
                              })}
                            />
                          )}
                        </div>
                      </FrankieLoader>
                    </div>
                  )}
                  control={control}
                  name="roles"
                  rules={{ required: true }}
                />

                <div className="basis-[32%]">
                  <div className="text-tertiary-grey-800 font-medium">
                    {`${t('beneficiallyOwnership')} ${t('optional')}`}
                  </div>
                  <div className="font-medium text-mono-70 pb-1.5">
                    {t('upto2Decimal')}
                  </div>

                  <FrankieTextField
                    testId={{
                      input: applicantAssociatePartiesQa.beneficiaryInput,
                    }}
                    type="number"
                    placeholder={t('enterNumber')}
                    error={!!getError('beneficiallyOwnership', errors)}
                    {...register('beneficiallyOwnership', {
                      pattern: DECIMAL_2_PLACES_PATTERN,
                    })}
                  />
                </div>

                <div className="basis-[32%]">
                  <div className="text-tertiary-grey-800 font-medium">
                    {`${t('nonBeneficiallyOwnership')} ${t('optional')}`}
                  </div>
                  <div className="font-medium text-mono-70 pb-1.5">
                    {t('upto2Decimal')}
                  </div>

                  <FrankieTextField
                    type="number"
                    placeholder={t('enterNumber')}
                    error={!!getError('nonBeneficiallyOwnership', errors)}
                    {...register('nonBeneficiallyOwnership', {
                      pattern: DECIMAL_2_PLACES_PATTERN,
                    })}
                  />
                </div>

                <FrankieTextField
                  type="number"
                  disabled
                  className="basis-[32%]"
                  label={t('total')}
                  {...register('total')}
                />

                <div className="basis-full">
                  <FrankieButton
                    type="submit"
                    disabled={!isValid}
                    className="!w-[170px] float-right"
                  >
                    {associationType === 'updating' ? t('done') : t('add')}
                  </FrankieButton>
                </div>
              </div>
            </form>
          </FrankieLoader>
        </div>
      )}
    </FormProvider>
  )
}
