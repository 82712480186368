export interface IFrankiePieChartProps {
  /**
   * Override or extend the styles applied to the component
   */
  className?: string

  /**
   * Data set
   */
  data: {
    label: string
    value: number
    color: FrankiePieChartStatsColorTypes
    testId?: { input?: string; pie?: string }
  }[]

  /**
   * Override or extend the styles applied to the component
   */
  innerRadius?: number

  /**
   * Define the dimension of the chart
   * @default 180
   */
  size?: number

  /**
   * Define the gap between arcs
   * @default 0.5
   */
  padding?: number

  /**
   * To show just graph not the fraction and percentage
   * @default false
   */
  hideAnalytics?: boolean

  /**
   * Testing identifiers for Jest and Cypress
   */
  testId?: { graphContainer?: string; dataContainer?: string }
}

/**
 * @enum Color Code to be used for pie chart
 * Add more if required
 */
export enum FrankiePieChartStatsColorTypes {
  Green500 = 'Green',
  Green300 = 'lightGreen',
  Yellow500 = 'Yellow',
  Yellow300 = 'lightYellow',
  Red500 = 'Red',
  Red300 = 'lightRed',
  Navy700 = 'dartBlue',
  Grey300 = 'lightGree',
}

export type FrankiePieChartColorConfig = Record<
  FrankiePieChartStatsColorTypes,
  { fillClass: string; bgClass: string }
>
