export const PROFILE_CHECK_KEY = 'PROFILE_CHECK'

export const profileCheckEn = {
  checkStatus: {
    statuses: {
      passed: 'Passed',
      failed: 'Failed',
      unchecked: 'Unchecked',
      notRequired: 'Not Required',
    },
    cta: {
      resolve: 'Resolve',
      review: 'Review',
      viewReport: 'View Report',
    },
  },
  checkRun: {
    cta: {
      runCheck: 'Run check',
      generateReport: 'Generate report',
    },
  },
}
