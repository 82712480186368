import React from 'react'

import { Trans } from 'react-i18next'

import { ANALYTICS_DASHBOARD_KEY } from '../../analytics-dashboard.key'
import { useAnalyticsDashboardTranslation } from '../../locale/analytics-dashboard.en'
import { AnalyticsGenerateReport } from '../analytics-generate-report/analytics-generate-report'
import { AnalyticsLiveboard } from '../analytics-liveboard/analytics-liveboard'

export function AnalyticsDashboard() {
  const { t } = useAnalyticsDashboardTranslation()

  return (
    <div className="p-6 gap-6 flex flex-col">
      <div className="">
        <h1 className="text-xxl font-bold text-tertiary-grey-800">
          {t('title')}
        </h1>
        <p className="text-tertiary-grey-500">
          <Trans
            i18nKey={`${ANALYTICS_DASHBOARD_KEY}:description`}
            components={[
              <strong
                key="pass-rate"
                className="font-semibold text-tertiary-grey-500"
              >
                Pass Rate Straight Through Processing (STP)
              </strong>,
            ]}
          />
        </p>
      </div>
      <AnalyticsGenerateReport />
      <AnalyticsLiveboard />
    </div>
  )
}
