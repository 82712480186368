import React from 'react'

import { QRCodeSVG } from 'qrcode.react'

type Props = {
  value: string
  className?: string
  size?: number
  testId?: {
    code: string
  }
}

export function QrCode({ value, className, size, testId }: Props) {
  return (
    <QRCodeSVG
      value={value}
      className={className}
      size={size}
      data-qa={testId?.code}
    />
  )
}
