import { CustomerChildId } from 'entities/organisation'
import { PageData } from 'entities/session'

import { bffClient, IClient } from 'shared/client'

export class AccountChangeApi {
  client: IClient

  constructor(client: IClient) {
    this.client = client
  }

  async viewAsChild(customerChildId: CustomerChildId) {
    return this.client.put<PageData, { child_uuid: CustomerChildId }>(
      '/data/v1/organization/child-view',
      { child_uuid: customerChildId },
    )
  }
}

export const accountChangeApi = new AccountChangeApi(bffClient)
