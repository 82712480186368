const text = {
  '2xs': '0.625rem',
  xs: '0.75rem',
  sm: '0.875rem',
  md: '1rem',
  lg: '1.125rem',
  xl: '1.25rem',
  '2xl': '1.5rem',
  '3xl': '1.875rem',
  '4xl': '2.25rem',
}

const icon = {
  'icon-2xs': '0.75rem',
  'icon-xs': '1rem',
  'icon-sm': '1.25rem',
  'icon-md': '1.5rem',
  'icon-lg': '2rem',
  'icon-xl': '4rem',
}

module.exports = {
  text,
  icon,
}
