import React, { useEffect } from 'react'

import { googleMapService } from '../../service/google-map.service'

type Props = {
  lat: number
  lng: number
  className?: string
}

export function GoogleMap({ lat, lng, className }: Props) {
  useEffect(() => {
    void (async () => {
      const map = await googleMapService.renderMap(
        document.getElementById('google-map') as HTMLElement,
        {
          center: { lat, lng },
          zoom: 16,
        },
      )
      googleMapService.setMarker(map, lat, lng)
    })()
  }, [lat, lng])

  return <div className={className} id="google-map" />
}
