import { DateFormatTypes, formatDate } from 'shared/date-time'
import { I18nFunction, useI18n } from 'shared/i18n'

import { useAmlData } from './individual-aml-result.state'
import { INDIVIDUAL_AML_RESULT_KEY } from '../individual-aml-result.key'
import { individualAmlResultEn } from '../locale/individual-aml-result.en'

type LabelAndSrc = {
  t: I18nFunction<typeof individualAmlResultEn>
  entityId: string
  processResultId?: string
  historyPage?: boolean
}

type SrcLabel = { label: string; value: string; isUrl?: boolean }

type SrcLabelGroup = { values: SrcLabel[]; title: string }[]

export type SrcLabelGroupType =
  | 'pepSrcLabel'
  | 'sanctionSrcLabel'
  | 'watchlistSrcLabel'

export const useLabelAndSrc = ({
  t,
  entityId,
  processResultId,
  historyPage,
}: LabelAndSrc): Record<SrcLabelGroupType, SrcLabelGroup> => {
  const { amlData } = useAmlData({ entityId, processResultId, historyPage })

  const { supplementaryData } = amlData
  const pepSrcLabel: SrcLabelGroup =
    supplementaryData?.pepData?.map(pep => ({
      title: pep.sourceName ?? '',
      values: [
        {
          label: t('tabMatchCard.countryCodes'),
          value: pep.countryCode ?? '',
        },
        {
          label: t('tabMatchCard.country'),
          value: pep.countryName ?? '',
        },
        {
          label: t('tabMatchCard.level'),
          value: `${pep.level ?? ''}`,
        },
        {
          label: t('tabMatchCard.srcUrl'),
          value: pep.sourceUrl ?? '',
          isUrl: true,
        },
        {
          label: t('tabMatchCard.politicalPosition'),
          value: pep.sourcePosition ?? '',
        },
        {
          label: t('tabMatchCard.startData'),
          value: pep.listingStart
            ? `${formatDate(pep.listingStart, DateFormatTypes.ShortDate)}`
            : '',
        },
        {
          label: t('tabMatchCard.endDate'),
          value: pep.listingEnd
            ? `${formatDate(pep.listingEnd, DateFormatTypes.ShortDate)}`
            : '',
        },
        {
          label: t('tabMatchCard.imageUrl'),
          value: pep.imageUrl ?? '',
          isUrl: true,
        },
        {
          label: t('tabMatchCard.referenceDocs'),
          value: pep.referenceDocs?.at(0)?.url ?? '',
          isUrl: true,
        },
      ],
    })) ?? []

  const sanctionSrcLabel: SrcLabelGroup =
    supplementaryData?.sanctionData?.map(sanctions => ({
      title: sanctions.sourceName ?? '',
      values: [
        {
          label: t('tabMatchCard.countryCodes'),
          value: sanctions.countryCode ?? '',
        },
        {
          label: t('tabMatchCard.country'),
          value: sanctions.countryName ?? '',
        },
        {
          label: t('tabMatchCard.srcUrl'),
          value: sanctions.sourceUrl?.split(',')[0] ?? '',
          isUrl: true,
        },
        {
          label: t('tabMatchCard.srcReason'),
          value: sanctions.sourceReason ?? '',
        },
        {
          label: t('tabMatchCard.startData'),
          value: sanctions.listingStart
            ? `${formatDate(sanctions.listingStart, DateFormatTypes.ShortDate)}`
            : '',
        },
        {
          label: t('tabMatchCard.endDate'),
          value: sanctions.listingEnd
            ? `${formatDate(sanctions.listingEnd, DateFormatTypes.ShortDate)}`
            : '',
        },

        {
          label: t('tabMatchCard.imageUrl'),
          value: sanctions.imageUrl ?? '',
          isUrl: true,
        },
        {
          label: t('tabMatchCard.referenceDocs'),
          value: sanctions.referenceDocs?.at(0)?.url ?? '',
          isUrl: true,
        },
      ],
    })) ?? []

  const watchlistSrcLabel: SrcLabelGroup =
    supplementaryData?.watchlistData?.map(watchList => ({
      title: watchList.sourceName ?? '',
      values: [
        {
          label: t('tabMatchCard.countryCodes'),
          value: watchList.countryCode ?? '',
        },
        {
          label: t('tabMatchCard.country'),
          value: watchList.countryName ?? '',
        },
        {
          label: t('tabMatchCard.srcUrl'),
          value: watchList.sourceUrl?.split(',')[0] ?? '',
          isUrl: true,
        },

        {
          label: t('tabMatchCard.startData'),
          value: watchList.listingStart
            ? `${formatDate(watchList.listingStart, DateFormatTypes.ShortDate)}`
            : '',
        },
        {
          label: t('tabMatchCard.endDate'),
          value: watchList.listingEnd
            ? `${formatDate(watchList.listingEnd, DateFormatTypes.ShortDate)}`
            : '',
        },

        {
          label: t('tabMatchCard.imageUrl'),
          value: watchList.imageUrl ?? '',
          isUrl: true,
        },
        {
          label: t('tabMatchCard.referenceDocs'),
          value: watchList.referenceDocs?.at(0)?.url ?? '',
          isUrl: true,
        },
      ],
    })) ?? []

  return { pepSrcLabel, sanctionSrcLabel, watchlistSrcLabel }
}

type SummaryLabelProps = {
  entityId: string
  processResultId?: string
  historyPage?: boolean
}

export const useLabelAndSrcForSummary = ({
  entityId,
  processResultId,
  historyPage,
}: SummaryLabelProps) => {
  const { amlData } = useAmlData({ entityId, processResultId, historyPage })

  const t = useI18n([INDIVIDUAL_AML_RESULT_KEY], {
    keys: individualAmlResultEn,
  })

  return [
    {
      label: t('individualAmlResolveSingleSummary.fullName'),
      value: amlData.query?.name ?? '-',
    },
    {
      label: t('individualAmlResolveSingleSummary.yearOfBirth'),
      value: amlData.query?.dateOfBirth
        ? `${new Date(amlData.query.dateOfBirth).getFullYear()}`
        : '-',
    },
    {
      label: t('individualAmlResolveSingleSummary.countryOfResidence'),
      value: amlData.query?.address ?? '-',
    },
  ]
}
