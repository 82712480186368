/* eslint-disable complexity */
import { useMemo } from 'react'

import { useOrganisationProfile } from 'entities/organisation'

import { DateFormatTypes, formatDate } from 'shared/date-time'
import { useI18n } from 'shared/i18n'

import { MONITORING_OVERVIEW_KEY } from '../../monitoring-overview.key'

type Args = {
  entityId?: string
}

export const useOrganisationProfileInfo = ({ entityId }: Args) => {
  const t = useI18n([MONITORING_OVERVIEW_KEY])

  const { data: organisationProfile } = useOrganisationProfile({
    entityId,
  })

  const organisationRowData = useMemo(() => {
    const rows = []

    if (!organisationProfile) return []

    // name
    if (organisationProfile.organization.details?.name.name) {
      rows.push({
        title: t('companyName'),
        value: organisationProfile.organization.details.name.name,
      })
    }

    // country
    if (organisationProfile.organization.details?.jurisdiction?.country) {
      rows.push({
        title: t('country'),
        value: organisationProfile.organization.details.jurisdiction.country,
      })
    }

    // type
    if (organisationProfile.organization.details?.type?.code) {
      rows.push({
        title: t('companyType'),
        value: organisationProfile.organization.details.type.code,
      })
    }

    // reg number
    if (organisationProfile.organization.details?.registrationDetails[0]) {
      rows.push({
        title: t('registrationNumber'),
        value:
          organisationProfile.organization.details.registrationDetails[0]
            .registrationNumber,
      })
    }

    // reg status
    if (
      organisationProfile.organization.details?.status?.unstructured
        ?.description
    ) {
      rows.push({
        title: t('companyStatus'),
        value:
          organisationProfile.organization.details.status.unstructured
            .description,
      })
    }

    // jurisdiction
    const jurisdictionCountry =
      organisationProfile.organization.details?.jurisdiction?.country
    const jurisdictionSubdivision =
      organisationProfile.organization.details?.jurisdiction?.subdivision
    if (jurisdictionSubdivision && jurisdictionCountry) {
      rows.push({
        title: t('jurisdiction'),
        value: `${jurisdictionSubdivision} - ${jurisdictionCountry}`,
      })
    }

    if (jurisdictionCountry && !jurisdictionSubdivision) {
      rows.push({
        title: t('jurisdiction'),
        value: jurisdictionCountry,
      })
    }

    if (jurisdictionSubdivision && !jurisdictionCountry) {
      rows.push({
        title: t('jurisdiction'),
        value: jurisdictionSubdivision,
      })
    }

    // registration auth
    const authority =
      organisationProfile.organization.details?.registrationDetails[0]
        ?.registryDescription
    if (authority) {
      rows.push({
        title: t('registrationAuthority'),
        value: authority,
      })
    }

    const incorporationDate =
      organisationProfile.organization.details?.registrationDetails[0]
        ?.registeredDate?.normalized

    if (incorporationDate) {
      rows.push({
        title: t('registrationDate'),
        value: formatDate(incorporationDate, DateFormatTypes.DateNumbers),
      })
    }

    return rows
  }, [organisationProfile, t])

  return { organisationRowData }
}
