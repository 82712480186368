import React, { useEffect, useMemo } from 'react'

import {
  BusinessSummaryField,
  BusinessSummaryView,
  OrganisationViewModal,
} from 'entities/organisation'

import { useI18n } from 'shared/i18n'
import { useOverlay } from 'shared/overlay'
import { isNonEmptyArray, isNotEmptyString } from 'shared/typescript'

import { APPLICANT_BUSINESS_OWNERSHIP_KEY } from '../../../applicant-business-ownership.key'
import { applicantBusinessOwnershipEn } from '../../../locale/applicant-business-ownership.en'
import { applicantAssociatePartiesQa } from '../../../qa/applicant-business-ownership.qa'
import {
  ABRBusinessSummaryData,
  UseABRBusinessSummaryProps,
  useABRBusinessSummary,
} from '../../../state/abr-business-summary/abr-business-summary'

type Props = { className?: string } & UseABRBusinessSummaryProps

type ABRBusinessSummaryFields = BusinessSummaryField<ABRBusinessSummaryData>[]
type ABRBusinessSummaryProperties = keyof ABRBusinessSummaryData

export function ABRBusinessSummary({ className = '', searchTerm }: Props) {
  const t = useI18n([APPLICANT_BUSINESS_OWNERSHIP_KEY], {
    keys: applicantBusinessOwnershipEn,
  })

  const {
    abrBusinessSummary,
    historicalBusinessNames,
    loadingBusinessSummary,
    handleSearchValue,
  } = useABRBusinessSummary({ searchTerm })

  useEffect(() => {
    handleSearchValue(searchTerm)
  }, [handleSearchValue, searchTerm])

  const isNotEmptyValue = (
    data: ABRBusinessSummaryData,
    key: ABRBusinessSummaryProperties,
  ) => {
    const value = data[key]
    return isNonEmptyArray(value) || isNotEmptyString(value)
  }

  const abrSummaryFields = useMemo(
    () =>
      [
        {
          name: t('abrSummary.name'),
          value: 'mainNames',
          isVisible: (data: ABRBusinessSummaryData) =>
            (
              [
                'mainNames',
                'legalNames',
                'currentBusiness',
                'tradingNames',
              ] as ABRBusinessSummaryProperties[]
            ).reduce(
              (value, key) => value && !isNotEmptyValue(data, key),
              false,
            ),
        },
        {
          name: t('abrSummary.mainNames'),
          value: 'mainNames',
          isVisible: isNotEmptyValue,
        },
        {
          name: t('abrSummary.legalNames'),
          value: 'legalNames',
          isVisible: isNotEmptyValue,
        },
        {
          name: t('abrSummary.tradingNames'),
          value: 'tradingNames',
          isVisible: isNotEmptyValue,
        },
        {
          name: t('abrSummary.location'),
          value: 'location',
          isVisible: isNotEmptyValue,
        },
        { name: t('abrSummary.type'), value: 'type' },
        { name: t('abrSummary.abn'), value: 'abn' },
        { name: t('abrSummary.status'), value: 'abnStatus' },
        {
          name: t('abrSummary.currentBusinessNames'),
          value: 'currentBusiness',
          // isVisible: isNotEmptyValue,
        },
        {
          name: t('abrSummary.historicalBusinessNames'),
          value: 'historicalBusiness',
          isAction: isNonEmptyArray(historicalBusinessNames),
        },
      ] satisfies ABRBusinessSummaryFields,
    [historicalBusinessNames, t],
  )

  const [createOverlay, closeOverlay] = useOverlay()

  const handleAction = (value: ABRBusinessSummaryProperties) => {
    if (value === 'historicalBusiness' && historicalBusinessNames) {
      createOverlay(
        <OrganisationViewModal
          heading={t('abrSummary.historicalBusinessNames')}
          data={historicalBusinessNames}
          onClose={closeOverlay}
        />,
        { className: 'p-0' },
      )
    }
  }

  return (
    <BusinessSummaryView
      className={className}
      heading={t('abrSummary.heading')}
      data={abrBusinessSummary}
      fields={abrSummaryFields}
      handleAction={handleAction}
      isLoading={loadingBusinessSummary}
      hidden={!searchTerm}
      testId={{ container: applicantAssociatePartiesQa.summaryViewWrapper }}
    />
  )
}
