import {
  AddressCategoryTypes,
  ApplicantResponse,
  AdditionalAddressCategoryTypes,
} from 'entities/applicant'
import {
  DocumentIdTypes,
  KycDocumentCategoryTypes,
  scanDateSorter,
  scanSorter,
} from 'entities/document'

import { DateFormatTypes, formatDate } from 'shared/date-time'

import { cardValue } from './document.model'
import {
  IIndividualProfileInputs,
  IndividualProfileInputTypes,
  individualProfileDefaultValues,
} from './form.model'

// eslint-disable-next-line complexity
export const mapDataToForm = (
  applicantResponseData?: ApplicantResponse,
): IIndividualProfileInputs => {
  let formData = individualProfileDefaultValues
  if (!applicantResponseData) return formData

  formData = {
    ...formData,
    [IndividualProfileInputTypes.KycMethod]:
      applicantResponseData.applicantDetails.extraData?.['kyc.method'] ||
      'electronic',
    [IndividualProfileInputTypes.Recipe]:
      applicantResponseData.applicantDetails.profile.profileType ?? '',
    [IndividualProfileInputTypes.PersonalInfo]: {
      ...formData[IndividualProfileInputTypes.PersonalInfo],
      [IndividualProfileInputTypes.CustomerId]:
        applicantResponseData.applicantDetails.customerReference ?? '',
      [IndividualProfileInputTypes.Email]:
        applicantResponseData.applicantDetails.email.idNumber ?? '',
      [IndividualProfileInputTypes.PhoneNumber]:
        applicantResponseData.applicantDetails.phoneNumber.idNumber ?? '',
      [IndividualProfileInputTypes.FirstName]:
        applicantResponseData.applicantDetails.name.givenName ?? '',
      [IndividualProfileInputTypes.MiddleName]:
        applicantResponseData.applicantDetails.name.middleName ?? '',
      [IndividualProfileInputTypes.LastName]:
        applicantResponseData.applicantDetails.name.familyName ?? '',
      dob: applicantResponseData.applicantDetails.dateOfBirth ?? '',
    },
    [IndividualProfileInputTypes.Document]: applicantResponseData.documents
      .length
      ? applicantResponseData.documents
          .filter(item => item.idType !== 'REPORT')
          // eslint-disable-next-line complexity
          .map(document => {
            const docScan = document.scans.sort(scanSorter).sort(scanDateSorter)
            // Why  ??
            const overrideIdTypeForPanEpic = () => {
              if (document.idSubType === DocumentIdTypes.PAN) {
                return {
                  [IndividualProfileInputTypes.IdType]: DocumentIdTypes.PAN,
                }
              }
              if (document.idSubType === 'EPIC') {
                return {
                  [IndividualProfileInputTypes.IdType]:
                    DocumentIdTypes.VOTER_ID,
                }
              }
              return {}
            }
            return {
              [IndividualProfileInputTypes.NationalId]:
                document.extraData?.national_id ?? null,
              [IndividualProfileInputTypes.PaternalFamilyName]:
                document.extraData?.paternal_familyname ?? null,
              [IndividualProfileInputTypes.MaternalFamilyName]:
                document.extraData?.maternal_familyname ?? null,
              [IndividualProfileInputTypes.Gender]:
                document.extraData?.gender ?? null,
              [IndividualProfileInputTypes.LaserCode]:
                document.extraData?.laser_code ?? null,
              [IndividualProfileInputTypes.LicenceVersion]:
                document.extraData?.licence_version,
              [IndividualProfileInputTypes.HomeCountryFullName]:
                document.extraData?.home_country_fullname,
              [IndividualProfileInputTypes.Country]: document.country ?? '',
              [IndividualProfileInputTypes.IdType]:
                document.idType as DocumentIdTypes,
              [IndividualProfileInputTypes.DocumentId]: document.documentId,
              [IndividualProfileInputTypes.IdNumber]: document.idNumber ?? '',
              [IndividualProfileInputTypes.DocumentNumber]:
                document.extraData?.document_number ?? '',
              [IndividualProfileInputTypes.State]: document.region ?? '',
              [IndividualProfileInputTypes.DocumentCategory]: document.extraData
                ?.kyc_document_category as KycDocumentCategoryTypes,

              [IndividualProfileInputTypes.Scan]: {
                [IndividualProfileInputTypes.UploadedUUID]:
                  null as unknown as string,
                [IndividualProfileInputTypes.ScanId]: docScan[0]?.scanId,
                [IndividualProfileInputTypes.FileType]:
                  docScan[0]?.mimeType ?? '',
              },
              [IndividualProfileInputTypes.Passport]: {
                [IndividualProfileInputTypes.IdExpiry]:
                  document.idExpiry &&
                  document.idType === DocumentIdTypes.PASSPORT
                    ? document.idExpiry
                    : '',
              },

              [IndividualProfileInputTypes.BirthCertificate]: {
                [IndividualProfileInputTypes.RegistrationDate]:
                  document.extraData?.registration_date ?? '',

                [IndividualProfileInputTypes.RegistrationName]:
                  document.extraData?.registered_name_first_given ?? '',
                [IndividualProfileInputTypes.OtherName]:
                  document.extraData?.registered_name_other_given ?? '',
                [IndividualProfileInputTypes.FamilyName]:
                  document.extraData?.registered_name_family ?? '',
                [IndividualProfileInputTypes.RegistrationDistrict]:
                  document.extraData?.registration_district ?? null,
                [IndividualProfileInputTypes.CertificateNumber]:
                  document.extraData?.certificate_number ?? null,
                [IndividualProfileInputTypes.DateOfPrint]:
                  document.extraData?.certificate_date_of_print ?? null,
              },
              [IndividualProfileInputTypes.MedicareCard]: {
                [IndividualProfileInputTypes.IdSubType]:
                  document.idSubType ?? '',
                [IndividualProfileInputTypes.Reference]:
                  document.extraData?.reference ?? '',
                [IndividualProfileInputTypes.IdExpiry]:
                  document.idExpiry && document.idSubType === cardValue.green
                    ? formatDate(
                        document.idExpiry,
                        DateFormatTypes.MonthYearNumber,
                      )
                    : document.idExpiry ?? '',
                [IndividualProfileInputTypes.MiddleNameDisplay]:
                  document.extraData?.display_middle_name ?? '',
                [IndividualProfileInputTypes.DisplayNameLine]:
                  document.extraData?.display_name_line1 ?? '',
                [IndividualProfileInputTypes.Name]: '',
              },
              [IndividualProfileInputTypes.CitizenShipCertificate]: {
                [IndividualProfileInputTypes.AcquisitionDate]:
                  document.extraData?.acquisition_date ?? '',
                [IndividualProfileInputTypes.PrimaryCountry]:
                  document.extraData?.primary_country ?? '',
                [IndividualProfileInputTypes.SecondaryCountry]:
                  document.extraData?.secondary_country ?? '',
              },
              [IndividualProfileInputTypes.ChangeNameCertificate]: {
                [IndividualProfileInputTypes.RegistrationName]:
                  document.extraData?.registered_name_first_given ?? '',
                [IndividualProfileInputTypes.OtherName]:
                  document.extraData?.registered_name_other_given ?? '',
                [IndividualProfileInputTypes.FamilyName]:
                  document.extraData?.registered_name_family ?? '',
                [IndividualProfileInputTypes.CertificateDateOfRegistration]:
                  document.extraData?.certificate_date_of_registration,
                [IndividualProfileInputTypes.CertificateNumber]:
                  document.extraData?.certificate_number,
                [IndividualProfileInputTypes.DateOfPrint]:
                  document.extraData?.certificate_date_of_print,
              },
              [IndividualProfileInputTypes.MarriageCertificate]: {
                [IndividualProfileInputTypes.MarriageDate]:
                  document.extraData?.marriage_date ?? '',
                [IndividualProfileInputTypes.CertificateDateOfRegistration]:
                  document.extraData?.certificate_date_of_registration,
                [IndividualProfileInputTypes.CertificateNumber]:
                  document.extraData?.certificate_number,
                [IndividualProfileInputTypes.DateOfPrint]:
                  document.extraData?.certificate_date_of_print,
                [IndividualProfileInputTypes.RegistrationYear]:
                  document.extraData?.registration_year,
                [IndividualProfileInputTypes.Bride]: {
                  [IndividualProfileInputTypes.FirstName]:
                    document.extraData?.bride_name_first_given ?? '',
                  [IndividualProfileInputTypes.OtherName]:
                    document.extraData?.bride_name_other_given ?? '',
                  [IndividualProfileInputTypes.FamilyName]:
                    document.extraData?.bride_name_family ?? '',
                },
                [IndividualProfileInputTypes.Groom]: {
                  [IndividualProfileInputTypes.FirstName]:
                    document.extraData?.groom_name_first_given ?? '',
                  [IndividualProfileInputTypes.OtherName]:
                    document.extraData?.groom_name_other_given ?? '',
                  [IndividualProfileInputTypes.FamilyName]:
                    document.extraData?.groom_name_family ?? '',
                },
              },
              [IndividualProfileInputTypes.TaxId]: {
                [IndividualProfileInputTypes.IdSubType]:
                  document.idSubType ?? '',
              },
              [IndividualProfileInputTypes.Other]: {
                [IndividualProfileInputTypes.Description]:
                  document.idNumber ?? '',
              },
              ...overrideIdTypeForPanEpic(),
            }
          })
      : individualProfileDefaultValues[IndividualProfileInputTypes.Document],
  }

  const addressType =
    applicantResponseData.applicantDetails.addresses?.map(
      address => address.addressType,
    ) ?? ''
  if (addressType.includes(AddressCategoryTypes.mailing)) {
    const address = applicantResponseData.applicantDetails.addresses?.find(
      address => address.addressType === AddressCategoryTypes.mailing,
    )

    if (address)
      formData = {
        ...formData,
        [IndividualProfileInputTypes.MailingAddress]: {
          ...formData.mailingAddress,
          [IndividualProfileInputTypes.Country]: address.country ?? '',
          [IndividualProfileInputTypes.AddressId]: address.addressId ?? '',
          [IndividualProfileInputTypes.Search]: address.longForm ?? '',
          [IndividualProfileInputTypes.isActive]: true,
          [IndividualProfileInputTypes.ManualAddress]: {
            // ...formData.mailingAddress.manualAddress,
            [IndividualProfileInputTypes.PropertyName]:
              address.buildingName ?? '',
            [IndividualProfileInputTypes.UnitNumber]: address.unitNumber ?? '',
            [IndividualProfileInputTypes.StreetNumber]:
              address.streetNumber ?? '',
            [IndividualProfileInputTypes.StreetName]: address.streetName ?? '',
            [IndividualProfileInputTypes.StreetType]: address.streetType ?? '',
            [IndividualProfileInputTypes.Suburb]: address.suburb ?? '',
            [IndividualProfileInputTypes.State]: address.state ?? '',
            [IndividualProfileInputTypes.Town]: address.town ?? '',
            [IndividualProfileInputTypes.PostalCode]: address.postalCode ?? '',
          },
        },
      }
  }
  if (addressType.includes(AddressCategoryTypes.previous)) {
    const address = applicantResponseData.applicantDetails.addresses?.find(
      address => address.addressType === AddressCategoryTypes.previous,
    )

    if (address)
      formData = {
        ...formData,
        [IndividualProfileInputTypes.PreviousResidentialAddress]: {
          ...formData[IndividualProfileInputTypes.PreviousResidentialAddress],
          [IndividualProfileInputTypes.Country]: address.country ?? '',
          [IndividualProfileInputTypes.AddressId]: address.addressId ?? '',
          [IndividualProfileInputTypes.Search]: address.longForm ?? '',
          [IndividualProfileInputTypes.isActive]: true,
          [IndividualProfileInputTypes.ManualAddress]: {
            [IndividualProfileInputTypes.PropertyName]:
              address.buildingName ?? '',
            [IndividualProfileInputTypes.UnitNumber]: address.unitNumber ?? '',
            [IndividualProfileInputTypes.StreetNumber]:
              address.streetNumber ?? '',
            [IndividualProfileInputTypes.StreetName]: address.streetName ?? '',
            [IndividualProfileInputTypes.StreetType]: address.streetType ?? '',
            [IndividualProfileInputTypes.Suburb]: address.suburb ?? '',
            [IndividualProfileInputTypes.State]: address.state ?? '',
            [IndividualProfileInputTypes.Town]: address.town ?? '',
            [IndividualProfileInputTypes.PostalCode]: address.postalCode ?? '',
          },
        },
      }
    // break
  }

  const address =
    applicantResponseData.applicantDetails.addresses?.find(
      address => address.addressType === AddressCategoryTypes.current,
    ) ??
    applicantResponseData.applicantDetails.addresses?.find(
      address =>
        address.addressType === AdditionalAddressCategoryTypes.RESIDENTIAL,
    )
  // if (!address) {
  //   formData = individualProfileDefaultValues
  //   // break
  // }
  if (address) {
    formData = {
      ...formData,
      [IndividualProfileInputTypes.CurrentResidentialAddress]: {
        ...formData.currentResidentialAddress,
        [IndividualProfileInputTypes.Country]: address.country ?? '',
        [IndividualProfileInputTypes.AddressId]: address.addressId ?? '',
        [IndividualProfileInputTypes.Search]: address.longForm ?? '',
        [IndividualProfileInputTypes.isActive]: true,
        [IndividualProfileInputTypes.ManualAddress]: {
          ...formData.currentResidentialAddress.manualAddress,
          [IndividualProfileInputTypes.PropertyName]:
            address.buildingName ?? '',
          [IndividualProfileInputTypes.UnitNumber]: address.unitNumber ?? '',
          [IndividualProfileInputTypes.StreetNumber]:
            address.streetNumber ?? '',
          [IndividualProfileInputTypes.StreetName]: address.streetName ?? '',
          [IndividualProfileInputTypes.StreetType]: address.streetType ?? '',
          [IndividualProfileInputTypes.Suburb]: address.suburb ?? '',
          [IndividualProfileInputTypes.State]: address.state ?? '',
          [IndividualProfileInputTypes.Town]: address.town ?? '',
          [IndividualProfileInputTypes.PostalCode]: address.postalCode ?? '',
        },
      },
    }
  } else {
    const nullTypeAddress =
      applicantResponseData.applicantDetails.addresses?.find(
        address => address.addressType === null,
      )
    if (nullTypeAddress) {
      formData = {
        ...formData,
        [IndividualProfileInputTypes.CurrentResidentialAddress]: {
          ...formData.currentResidentialAddress,
          [IndividualProfileInputTypes.Country]: nullTypeAddress.country ?? '',
          [IndividualProfileInputTypes.AddressId]:
            nullTypeAddress.addressId ?? '',
          [IndividualProfileInputTypes.Search]: nullTypeAddress.longForm ?? '',
          [IndividualProfileInputTypes.isActive]: true,
          [IndividualProfileInputTypes.ManualAddress]: {
            ...formData.currentResidentialAddress.manualAddress,
            [IndividualProfileInputTypes.PropertyName]:
              nullTypeAddress.buildingName ?? '',
            [IndividualProfileInputTypes.UnitNumber]:
              nullTypeAddress.unitNumber ?? '',
            [IndividualProfileInputTypes.StreetNumber]:
              nullTypeAddress.streetNumber ?? '',
            [IndividualProfileInputTypes.StreetName]:
              nullTypeAddress.streetName ?? '',
            [IndividualProfileInputTypes.StreetType]:
              nullTypeAddress.streetType ?? '',
            [IndividualProfileInputTypes.Suburb]: nullTypeAddress.suburb ?? '',
            [IndividualProfileInputTypes.State]: nullTypeAddress.state ?? '',
            [IndividualProfileInputTypes.Town]: nullTypeAddress.town ?? '',
            [IndividualProfileInputTypes.PostalCode]:
              nullTypeAddress.postalCode ?? '',
          },
        },
      }
    }
  }
  return formData
}
