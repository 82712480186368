import { APPLICANT_AUDIT_REPORT_KEY } from '../applicant-audit-report.key'

const aarKey = `${APPLICANT_AUDIT_REPORT_KEY}-aar`

export const applicantAuditReportQa = {
  main: `${aarKey}-main`,
  text: `${aarKey}-text`,
  btn: `${aarKey}-button`,
} satisfies Record<string, `${typeof aarKey}-${string}`>

const aarTRKey = `${APPLICANT_AUDIT_REPORT_KEY}-aar-table`

export const applicantAuditReportTableSlotQa = {
  main: `${aarTRKey}-main`,
  error: `${aarTRKey}-error`,
  loadingOverlay: `${aarTRKey}-loading-overlay`,
  loader: `${aarTRKey}-loader`,
} satisfies Record<string, `${typeof aarTRKey}-${string}`>
