import React from 'react'

import { FrankieCheckbox, IFrankieCheckboxProps } from 'frankify/src'

export type GridCheckboxProps = IFrankieCheckboxProps & {
  indeterminate: boolean
}

export const GridCheckbox = React.forwardRef<
  HTMLInputElement,
  GridCheckboxProps
>(({ indeterminate, ...rest }, ref) => (
  <FrankieCheckbox ref={ref} partial={indeterminate} {...rest} />
))
