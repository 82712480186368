import React from 'react'

import {
  ApplicantQueryKeysTypes,
  useApplicantDataQuery,
  useApplicantPaths,
  useUpdateApplicantQueries,
} from 'entities/applicant'
import { useRunAmlCheckIKyb } from 'entities/organisation'
import {
  ProfileCheckStatus,
  ProfileGenerate,
  RunCheckTypes,
} from 'entities/profile-check'
import { useHasFeatureFlag } from 'entities/session'

import { useI18n } from 'shared/i18n'
import { notification } from 'shared/notification'

import { MONITORING_OVERVIEW_KEY } from '../../monitoring-overview.key'
import { useIKybCheckStatus } from '../../state/ikyb-aml-status/ikyb-aml-status'

type Props = {
  entityId: string
}

export function AmlCheckStatus({ entityId }: Props) {
  const t = useI18n([MONITORING_OVERVIEW_KEY])

  const { getApplicantPath } = useApplicantPaths()
  const { alertHasAml, isLoadingAmlStatus, showAmlStatus } = useIKybCheckStatus(
    { entityId },
  )

  if (!showAmlStatus) return null

  return (
    <ProfileCheckStatus
      resolvePage={getApplicantPath('iKybAmlScreening')}
      name={t('amlResult.title')}
      description={t(alertHasAml ? 'amlResult.failed' : 'amlResult.passed')}
      status={alertHasAml ? 'failed' : 'passed'}
      loading={isLoadingAmlStatus}
    />
  )
}

export function AmlCheckRun({ entityId }: Props) {
  const t = useI18n([MONITORING_OVERVIEW_KEY])

  const { refetch } = useApplicantDataQuery({
    applicantId: entityId,
  })

  const { removeQueries } = useUpdateApplicantQueries({ applicantId: entityId })

  const { hasInternationalKybAML } = useHasFeatureFlag({
    hasInternationalKybAML: 'internationalKybAML',
  })

  // Will need to test with international - not working as of now.
  const { reRunAmlCheck, isLoading: isRunningAml } =
    useRunAmlCheckIKyb(entityId)

  const handleRunAml = async () => {
    try {
      await reRunAmlCheck()
      void refetch()
      void removeQueries(ApplicantQueryKeysTypes.IKybAmlScreeingQueryKey)
    } catch (error) {
      notification.error(t('errorAmlCheck'))
    }
  }

  if (!hasInternationalKybAML) {
    return null
  }

  return (
    <ProfileGenerate
      className="mb-6"
      heading={t('runCheckOrganisation')}
      label={t('runChecks')}
      placeholder={t('selectCheck')}
      options={[
        {
          title: t('amlScreening'),
          value: RunCheckTypes.AML,
          description: t('amlScreeningSubtitle'),
        },
      ]}
      onSubmit={handleRunAml}
      isLoading={isRunningAml}
    />
  )
}
