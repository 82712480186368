import { useCallback, useState } from 'react'

import { useInfiniteQuery } from '@tanstack/react-query'

import { ErrorResponse } from 'shared/http'

import { applicantApi } from '../../api/applicant.api'
import {
  SearchApplicantParams,
  SearchApplicantResponse,
} from '../../model/applicant.model'

export const SearchApplicantQueryKey = 'search-applicant-query'

type Args = SearchApplicantParams

/**
 * Applicant Search Query
 */
export const useSearchApplicantsQuery = (defaultFilters: Args) => {
  const [filter, setFilter] = useState<SearchApplicantParams>({
    appendList: false,
    entityIdFilter: '',
    limit: 10,
    matchStatusFilter: '',
    nameFilter: '',
    stage: 'onboarding',
    ...defaultFilters,
  })

  const query = useInfiniteQuery<SearchApplicantResponse, ErrorResponse>({
    enabled: !!filter.entityIdFilter || !!filter.nameFilter,
    queryKey: [SearchApplicantQueryKey, ...Object.values(filter)],
    getNextPageParam: lastPage => lastPage.nextPageMarker,
    queryFn: async ({ pageParam }: { pageParam?: number }) => {
      const { data } = await applicantApi.getApplicantsList({
        ...filter,
        nextPageMarker: pageParam,
      })
      return data
    },
  })

  const updateFilter = useCallback(
    (newFilter: Partial<SearchApplicantParams>) => {
      setFilter(prev => ({ ...prev, ...newFilter }))
    },
    [],
  )

  return { ...query, updateFilter }
}
