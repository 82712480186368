import { useQuery } from '@tanstack/react-query'

import { ApplicantId, applicantApi } from 'entities/applicant'

import { useI18n } from 'shared/i18n'
import { notification } from 'shared/notification'

import { APPLICANT_SUPPORTING_DOCUMENTS_KEY } from '../../applicant-supporting-documents.key'
import { applicantSupportingDocumentsEn } from '../../locale/applicant-supporting-documents.en'
import { ApplicantUploadedDocumentsRecord } from '../../model/applicant-supporting-documents.model'

type Args = {
  applicantId: ApplicantId
}

export const ApplicantSupportingDocumentQueryKey =
  'applicant-supporting-document'

export const useApplicantSupportingDocumentQuery = ({ applicantId }: Args) => {
  const t = useI18n([APPLICANT_SUPPORTING_DOCUMENTS_KEY], {
    keys: applicantSupportingDocumentsEn,
  })

  return useQuery({
    enabled: !!applicantId,
    queryKey: [ApplicantSupportingDocumentQueryKey, applicantId],
    queryFn: async () => {
      try {
        const { data } = await applicantApi.getApplicantSupportedDocument(
          applicantId,
        )

        return data.map(item => new ApplicantUploadedDocumentsRecord(item))
      } catch (err) {
        notification.error(t('errorFetchingDocs'))
        return [] as ApplicantUploadedDocumentsRecord[]
      }
    },
  })
}
