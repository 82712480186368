import { useMemo } from 'react'

import { useApplicantDataQuery } from 'entities/applicant'
import { ICountryOption, useCountriesCoveredQuery } from 'entities/country'
import { useOrganisationProfile } from 'entities/organisation'

export const ApplicantQueryKey = ['applicant']

type Args = {
  applicantId?: string
}

export const useHasInternationalUbo = ({ applicantId }: Args) => {
  const { data: applicantData } = useApplicantDataQuery({
    applicantId,
  })

  const { data: organisationProfile } = useOrganisationProfile({
    entityId: applicantId,
  })

  const { data: coveredCountries } = useCountriesCoveredQuery()

  const hasInternationalUBO = useMemo(() => {
    const orgDetails = organisationProfile?.organization.details
    const applicantDetails = applicantData?.applicantDetails

    const countryCode =
      applicantDetails?.extraData?.requested_jurisdiction_country ??
      orgDetails?.jurisdiction?.country ??
      orgDetails?.registrationDetails[0]?.registeredCountry ??
      applicantDetails?.profile.countryAlpha3?.toUpperCase()

    return coveredCountries?.find(
      (country: ICountryOption) => country.alpha3code === countryCode,
    )?.UBOAvailable
  }, [applicantData, organisationProfile, coveredCountries])

  return {
    hasInternationalUBO,
  }
}
