import React from 'react'

import {
  GridColDef,
  GridRenderCellParams,
  GridRow,
  GridRowProps,
} from '@mui/x-data-grid-pro'

import { FrankieLoader } from 'frankify/src'

import { useI18n } from 'shared/i18n'

import { APPLICANT_BUSINESS_OWNERSHIP_INTERNATIONAL_KEY } from '../../applicant-business-ownership-international.key'
import { applicantBusinessOwnershipInternationalEn } from '../../locale/applicant-business-ownership-international.en'
import { IShareCapital } from '../../model/applicant-business-ownership-international.model'
import { businessOwnershipDataGridHelperQa } from '../../qa/applicant-business-ownership-international.qa'

export type ShareCapitalDataGridColumn = (GridColDef<IShareCapital> & {
  field: keyof IShareCapital
})[]

export const BUSINESS_OWNERSHIP_DATA_GRID_MIN_WIDTH = 800

export const shareCapitalDataGridCellWidth = (
  width: number,
  noFlex = false,
) => ({
  minWidth: width,
  ...(noFlex
    ? { maxWidth: width }
    : { flex: width / BUSINESS_OWNERSHIP_DATA_GRID_MIN_WIDTH }),
})

export type RowProps = GridRowProps & { row: IShareCapital }

export function ShareCapitalDataGridRow(props: RowProps) {
  return <GridRow {...props} />
}

export function ShareCapitalDataGridCell({
  row,
  colDef,
}: GridRenderCellParams<IShareCapital>) {
  const field = colDef.field as keyof IShareCapital
  const value = row[field]

  if (value === undefined) {
    return <div className="font-semibold">-</div>
  }

  return <div className="font-semibold">{value.toString()}</div>
}

export function ShareCapitalDataGridLoadingOverlay() {
  const t = useI18n([APPLICANT_BUSINESS_OWNERSHIP_INTERNATIONAL_KEY], {
    keys: applicantBusinessOwnershipInternationalEn,
  })

  return (
    <div className="flex align-center justify-center flex-col w-full h-full bg-mono-white opacity-80">
      <FrankieLoader
        label={t('loading.default')}
        loading
        className="text-tertiary-grey-800 text-md font-semibold min-h-[250px]"
        size="sm"
        testId={{ loader: businessOwnershipDataGridHelperQa.loader }}
      />
    </div>
  )
}
