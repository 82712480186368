/* eslint-disable complexity */
import React, { useEffect, useState } from 'react'

import { useQuery, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { FrankieButton, FrankieIcon } from 'frankify/src'

import { AbrSearchResultAustralia } from 'features/abr-search-australia'
import { ABRCompany } from 'features/abr-search-australia/model/abr-search-model'
import { ManualAddAustralianOrganisation } from 'features/abr-search-australia/ui/manual-add-aus-organisation/manual-add-aus-organisation'
import { ORGANISATION_SEARCH_KEY } from 'features/organisation-search'

import { IMatchedOrganization } from 'entities/organisation'

import { useI18n } from 'shared/i18n'

import { TOO_MANY_SEARCH_RESULTS_FRANKIE_ERROR_CODE } from '../../model/kyb-search-v2.model'
import { KybSearchInput } from '../kyb-search-input/kyb-search-input'
import { KybSearchResult } from '../kyb-search-result/kyb-search-result'

export function KYBSearch() {
  const t = useI18n([ORGANISATION_SEARCH_KEY])

  const [dataToDisplay, setDataToDisplay] = useState({
    organizationName: '',
    countryName: '',
  })

  const queryClient = useQueryClient()

  const {
    data: foundABROrganisations,
    isFetching: isLoadingAbrOrganisations,
    isError: isErrorLoadingAbrOrganisations,
    isSuccess: isLoadedABROrganizationsSuccesfully,
  } = useQuery({
    queryKey: ['abr-search'],
    queryFn: () => queryClient.getQueryData<ABRCompany[]>(['abr-search']),
    enabled: false,
  })

  const {
    isFetching: isLoadingOrganizations,
    isSuccess: isLoadedOrganizationsSuccesfully,
    isError: isErrorLoadingOrganizations,
    data: foundOrganisations,
    error: searchErrorKYB,
  } = useQuery<
    IMatchedOrganization[] | undefined,
    AxiosError<{ responseData: { errorCode: string } }>
  >({
    queryKey: ['organizations-lookup'],
    queryFn: () =>
      queryClient.getQueryData<IMatchedOrganization[] | undefined>([
        'organizations-lookup',
      ]),
    enabled: false,
  })

  useEffect(() => {
    // clear search result on mount
    void queryClient.setQueryData(['abr-search'], null)
    void queryClient.removeQueries({ queryKey: ['abr-search'] })
    void queryClient.invalidateQueries({ queryKey: ['abr-search'] })

    void queryClient.setQueryData(['organizations-lookup'], null)
    void queryClient.removeQueries({ queryKey: ['organizations-lookup'] })
    void queryClient.invalidateQueries({ queryKey: ['organizations-lookup'] })

    setDataToDisplay({
      countryName: '',
      organizationName: '',
    })
  }, [queryClient])

  const noSearchResultsKYB =
    (foundOrganisations?.length === 0 || !foundOrganisations) &&
    (isLoadedOrganizationsSuccesfully || isErrorLoadingOrganizations)

  const isTooManySearchResultsError =
    searchErrorKYB &&
    searchErrorKYB.response?.status === 400 &&
    searchErrorKYB.response.data.responseData.errorCode ===
      TOO_MANY_SEARCH_RESULTS_FRANKIE_ERROR_CODE

  const showTooManySearchResultsError = Boolean(
    !isLoadingOrganizations && isTooManySearchResultsError,
  )

  const showNoMatchesError =
    !isLoadingOrganizations &&
    (noSearchResultsKYB || isErrorLoadingOrganizations)

  const showKYBSearchResults = Boolean(
    !isLoadingOrganizations &&
      isLoadedOrganizationsSuccesfully &&
      foundOrganisations &&
      foundOrganisations.length > 0,
  )

  const showAbrResults = Boolean(
    !isLoadingAbrOrganisations &&
      isLoadedABROrganizationsSuccesfully &&
      foundABROrganisations &&
      foundABROrganisations.length > 0,
  )

  const showNoABRResults =
    !isLoadingAbrOrganisations &&
    (isErrorLoadingAbrOrganisations || foundABROrganisations?.length === 0)

  const isAustraliaSearch = dataToDisplay.countryName === 'Australia'

  const [
    isManualAddAustralianOrganisation,
    toggleManualAddAustralianOrganisation,
  ] = useState(false)

  const handleGoToManualAdd = () => toggleManualAddAustralianOrganisation(true)

  const handleClickBackFromManualAdd = () => {
    setDataToDisplay({
      countryName: '',
      organizationName: '',
    })

    toggleManualAddAustralianOrganisation(false)
  }

  return (
    <div
      id="mid-cont"
      className="max-w-[1440px] w-full flex justify-center bg-mono-white"
    >
      <div className="w-[910px] mt-8">
        {!isManualAddAustralianOrganisation && (
          <>
            <KybSearchInput setDataToDisplay={setDataToDisplay} />
            <div className="mt-12 pb-2">
              {!isAustraliaSearch && (
                <KybSearchResult
                  foundOrganisations={foundOrganisations!}
                  showSearchResults={showKYBSearchResults}
                  showNoMatchesError={showNoMatchesError}
                  showTooManySearchResultsError={showTooManySearchResultsError}
                  isLoadingOrganizations={isLoadingOrganizations}
                  dataToDisplay={dataToDisplay}
                />
              )}
              {isAustraliaSearch && (
                <AbrSearchResultAustralia
                  dataToDisplay={dataToDisplay}
                  isLoadingAbrOrganisations={isLoadingAbrOrganisations}
                  showNoMatchesError={showNoABRResults}
                  foundABROrganisations={foundABROrganisations!}
                  showAbrResults={showAbrResults}
                  onGoToManualAdd={handleGoToManualAdd}
                />
              )}
            </div>
          </>
        )}
        {isManualAddAustralianOrganisation && (
          <div>
            <FrankieButton
              noStyles
              className="text-sm font-normal text-tertiary-grey-500"
              onClick={handleClickBackFromManualAdd}
            >
              <div className="flex items-center">
                <FrankieIcon
                  size="xs"
                  name="mdiChevronLeft"
                  className="inline-block"
                />
                <span>{t('backToResults')}</span>
              </div>
            </FrankieButton>
            <div className="mt-2 text-tertiary-grey-800 text-xl font-bold">
              {t('manuallyAdd.header')}
            </div>
            <div className="mt-6">
              <ManualAddAustralianOrganisation />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
