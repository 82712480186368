import React, { useCallback, useState } from 'react'

import { navigateToUrl } from 'single-spa'

import { FrankieButton, FrankieIcon, FrankieTextField } from 'frankify/src'

import { BlocklistTable } from 'features/blocklist-table'

import { ApplicantId } from 'entities/applicant'
import { PermissionTypes } from 'entities/role'
import {
  TPath,
  TPathParams,
  useGetPathValue,
  useHasPermission,
} from 'entities/session'

import { useI18n } from 'shared/i18n'

import { BLOCKLIST_PAGE_KEY } from '../blocklist.key'
import { blocklistEn } from '../locale/blocklist.en'
import { blocklistVueMigratedQa } from '../qa/blocklist-page.qa'

type Props = {
  addNewBlocklistPath: TPath
  applicantGeneralInfoPath: TPath
  getApplicantPathParams: (applicantId: ApplicantId) => TPathParams
}

export function BlocklistPage({
  addNewBlocklistPath,
  applicantGeneralInfoPath,
  getApplicantPathParams,
}: Props) {
  const t = useI18n([BLOCKLIST_PAGE_KEY], { keys: blocklistEn })
  const { canAddNewBlocklist } = useHasPermission({
    canAddNewBlocklist: PermissionTypes.BlocklistNew,
  })

  const [searchText, setSearchText] = useState('')

  const { generatePathValue, getPathValue } = useGetPathValue()

  const getApplicantGeneralInfoPath = useCallback(
    (applicantId: ApplicantId) =>
      generatePathValue(
        applicantGeneralInfoPath,
        getApplicantPathParams(applicantId),
      ),
    [applicantGeneralInfoPath, generatePathValue, getApplicantPathParams],
  )

  return (
    <div className="px-8">
      <div className="flex justify-between py-4">
        <div className="flex items-center">
          <FrankieIcon name="mdiCancel" className="text-primary-700" />
          <div className="px-4 text-lg font-bold">{t('title')}</div>
          {canAddNewBlocklist && (
            <FrankieButton
              intent="secondary"
              size="sm"
              startIcon={{
                name: 'mdiPlus',
                className: 'text-primary-700',
              }}
              className="border-primary-700 text-secondary-900"
              onClick={() => navigateToUrl(getPathValue(addNewBlocklistPath))}
              testId={{ button: blocklistVueMigratedQa.addToBlocklist }}
            >
              {t('add')}
            </FrankieButton>
          )}
        </div>

        <FrankieTextField
          className="!w-[300px]"
          size="sm"
          type="search"
          isSearchIcon
          placeholder={t('search')}
          onChange={e => setSearchText(e.target.value)}
          testId={{ input: blocklistVueMigratedQa.searchBlocklist }}
        />
      </div>
      <div className="h-[calc(100vh-138px)]">
        <BlocklistTable
          getApplicantGeneralInfoPath={getApplicantGeneralInfoPath}
          search={searchText}
        />
      </div>
    </div>
  )
}
