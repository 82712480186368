import React, { ReactNode } from 'react'

import { Link } from 'react-router-dom'

import { roleQa } from '../../../qa/role.qa'

type Props = {
  nameCell: ReactNode
  descriptionCell: ReactNode
  createdByCell: ReactNode
  createdDateCell: ReactNode
  actionsCell: ReactNode
  linkTo?: string
}

export function RoleTableRowLayout({
  nameCell,
  descriptionCell,
  createdByCell,
  createdDateCell,
  actionsCell,
  linkTo,
}: Props) {
  const columns = (
    <>
      <div className="basis-[180px] shrink-0 min-w-0">{nameCell}</div>
      <div className="basis-full shrink min-w-[160px]">{descriptionCell}</div>
      <div className="basis-[160px] shrink-0 min-w-0">{createdByCell}</div>
      <div className="basis-[208px] shrink-0">{createdDateCell}</div>
    </>
  )
  const actionColumn = (
    <div className="basis-[84px] shrink-0">{actionsCell}</div>
  )

  return (
    <div className="w-full flex flex-initial flex-row border-b border-solid border-tertiary-grey-200">
      {linkTo ? (
        <Link
          data-qa={roleQa.roleTableRow_ctaView}
          className="basis-full shrink min-w-0 flex flex-initial flex-row"
          to={linkTo}
        >
          {columns}
        </Link>
      ) : (
        columns
      )}
      {actionColumn}
    </div>
  )
}
