import React from 'react'

import { DocumentIdTypes } from 'entities/entity'

import { EmptyString } from 'shared/typescript'

import {
  DocumentFormComponentMapping,
  DocumentsSubFormProps,
} from './document-sub-forms.types'
import { DriverLicenseForm } from './driver-license/driver-license'
import { MedicareCardForm } from './medicare-card/medicare-card'
import { NationalIdForm } from './national-id/national-id'
import { PassportForm } from './passport/passport'

export const DocumentFormMapping: DocumentFormComponentMapping = {
  [DocumentIdTypes.PASSPORT]: PassportForm,
  [DocumentIdTypes.DRIVERS_LICENSE]: DriverLicenseForm,
  [DocumentIdTypes.NATIONAL_HEALTH_ID]: MedicareCardForm,
  [DocumentIdTypes.NATIONAL_ID]: NationalIdForm,
}

export type OwnProps = { selectedType: EmptyString<DocumentIdTypes> }

export type Props = OwnProps & DocumentsSubFormProps

export function DocumentSubForm({ selectedType, ...rest }: Props) {
  if (!selectedType) {
    return null
  }
  const DocType = DocumentFormMapping[selectedType]

  if (!DocType) {
    return null
  }

  return <DocType {...rest} />
}
