import { useQuery } from '@tanstack/react-query'

import { applicantApi } from '../../api/applicant.api'
import { ApplicantRiskChecksResponse } from '../../model/applicant-response.model'

export const ApplicantRiskCheckQueryKey = ['applicant-risk-check']

type Args = {
  entityId?: string
}

export const useApplicantRiskCheckQuery = ({ entityId }: Args) =>
  useQuery<ApplicantRiskChecksResponse>({
    enabled: !!entityId,
    queryKey: [ApplicantRiskCheckQueryKey, entityId],
    queryFn: async () => {
      const { data } = await applicantApi.getApplicantRiskChecks(entityId!)

      return data
    },
  })
