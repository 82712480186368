import React from 'react'

import { DataGridPro } from '@mui/x-data-grid-pro'

import { WithProps } from 'shared/hoc'
import { useI18n } from 'shared/i18n'

import { APPLICANT_BUSINESS_OWNERSHIP_INTERNATIONAL_KEY } from '../../applicant-business-ownership-international.key'
import { applicantBusinessOwnershipInternationalEn } from '../../locale/applicant-business-ownership-international.en'
import {
  ApplicantOwnershipHolderRecord,
  ApplicantOwnershipHolderTypes,
} from '../../model/applicant-business-ownership-international.model'
import { businessOwnerShipDataGridQa } from '../../qa/applicant-business-ownership-international.qa'
import {
  BusinessOwnershipDataGridCell,
  BusinessOwnershipDataGridColumn,
  BusinessOwnershipDataGridLoadingOverlay,
  BusinessOwnershipDataGridRow,
  businessOwnershipDataGridCellWidth,
  businessOwnershipDataGridSx,
} from '../business-ownership-data-grid-helper/business-ownership-data-grid-helper'

type Props = {
  className?: string
  ownerShipHolderType: ApplicantOwnershipHolderTypes
  emptyText?: string
  rows: ApplicantOwnershipHolderRecord[]
  isLoading: boolean
  applicantId: string
  testId?: {
    wrapper: string
    dataGrid: string
  }
}

export function BusinessOwnershipDataGrid({
  className = '',
  ownerShipHolderType,
  emptyText,
  rows,
  isLoading,
  applicantId,
  testId = {
    wrapper: businessOwnerShipDataGridQa.container,
    dataGrid: businessOwnerShipDataGridQa.dataGrid,
  },
}: Props) {
  const t = useI18n([APPLICANT_BUSINESS_OWNERSHIP_INTERNATIONAL_KEY], {
    keys: applicantBusinessOwnershipInternationalEn,
  })

  const columns: BusinessOwnershipDataGridColumn = [
    {
      field: 'entityType',
      headerName: t('dataGridFields.type'),
      ...businessOwnershipDataGridCellWidth(60, true),
      showInGrid: [
        'ubos',
        'otherOwners',
        'linkedCompanies',
        'officials',
        'shareCapital',
        'shareholders',
        'blockingEntities',
        'associatedParties',
      ],
    },
    {
      field: 'name',
      headerName: t('dataGridFields.name'),
      ...businessOwnershipDataGridCellWidth(275, true),
      showInGrid: [
        'ubos',
        'otherOwners',
        'officials',
        'shareholders',
        'shareCapital',
        'linkedCompanies',
        'blockingEntities',
        'associatedParties',
      ],
    },
    {
      field: 'reasons',
      headerName: t('dataGridFields.blockingReason'),
      ...businessOwnershipDataGridCellWidth(200),
      showInGrid: ['blockingEntities'],
    },
    {
      field: 'companyType',
      headerName: t('dataGridFields.companyType'),
      ...businessOwnershipDataGridCellWidth(600),
      showInGrid: ['linkedCompanies'],
    },
    {
      field: 'role',
      headerName: t('dataGridFields.role'),
      ...businessOwnershipDataGridCellWidth(130, true),
      showInGrid: ['officials'],
    },
    {
      field: 'jointHolding',
      headerName: t('dataGridFields.jointHolding'),
      ...businessOwnershipDataGridCellWidth(130, true),
      showInGrid: ['shareholders'],
    },
    {
      field: 'isBeneficiallyHeld',
      headerName: t('dataGridFields.beneficiallyHeld'),
      ...businessOwnershipDataGridCellWidth(130, true),
      showInGrid: ['shareholders'],
    },
    {
      field: 'officialState',
      headerName: t('dataGridFields.officialState'),
      ...businessOwnershipDataGridCellWidth(130, true),
      showInGrid: ['officials'],
    },
    {
      field: 'beneficiallyHeld',
      headerName: t('dataGridFields.beneficiallyHeldOwnership'),
      ...businessOwnershipDataGridCellWidth(120),
      showInGrid: [
        'ubos',
        'otherOwners',
        'blockingEntities',
        'associatedParties',
      ],
    },
    {
      field: 'nonBeneficiallyHeld',
      headerName: t('dataGridFields.nonBeneficiallyHeldOwnership'),
      ...businessOwnershipDataGridCellWidth(120),
      showInGrid: [
        'ubos',
        'otherOwners',
        'blockingEntities',
        'associatedParties',
      ],
    },
    {
      field: 'totalHeld',
      headerName: t('dataGridFields.total'),
      ...businessOwnershipDataGridCellWidth(100),
      showInGrid: [
        'ubos',
        'otherOwners',
        'blockingEntities',
        'associatedParties',
      ],
    },
    {
      field: 'profileStatus',
      headerName: t('dataGridFields.profileStatus'),
      ...businessOwnershipDataGridCellWidth(100),
      showInGrid: [
        'ubos',
        'otherOwners',
        'officials',
        'shareholders',
        'associatedParties',
      ],
    },
    {
      field: 'classCode',
      headerName: t('dataGridFields.classCode'),
      ...businessOwnershipDataGridCellWidth(100),
      showInGrid: ['shareholders'],
    },
    {
      field: 'sharesHeld',
      headerName: t('dataGridFields.sharesHeld'),
      ...businessOwnershipDataGridCellWidth(100),
      showInGrid: ['shareholders'],
    },
    {
      field: 'issues',
      headerName: t('dataGridFields.issues'),
      ...businessOwnershipDataGridCellWidth(100),
      showInGrid: ['ubos', 'otherOwners', 'officials'],
    },
    {
      field: 'roleType',
      headerName: t('dataGridFields.roleType'),
      ...businessOwnershipDataGridCellWidth(100),
      showInGrid: ['associatedParties'],
    },
    {
      field: 'recipe',
      headerName: t('dataGridFields.recipe'),
      ...businessOwnershipDataGridCellWidth(100),
      showInGrid: ['ubos', 'otherOwners', 'officials', 'associatedParties'],
    },
    {
      field: 'riskLevel',
      headerName: t('dataGridFields.riskLevel'),
      ...businessOwnershipDataGridCellWidth(100),
      showInGrid: ['ubos', 'otherOwners', 'officials', 'shareholders'],
    },
    {
      field: 'actions',
      headerName: '',
      ...businessOwnershipDataGridCellWidth(60, true),
      showInGrid: [
        'ubos',
        'otherOwners',
        'officials',
        'shareholders',
        'associatedParties',
      ],
    },
  ]

  return (
    <div className={`${className}`} data-qa={testId.wrapper}>
      <DataGridPro
        data-qa={isLoading || testId.dataGrid}
        getRowId={row => row.entityId}
        className={`[&_.MuiDataGrid-overlayWrapperInner]:text-tertiary-grey-700 ${
          !rows.length && !isLoading
            ? '[&_.MuiDataGrid-virtualScroller]:h-[60px]'
            : ''
        }`}
        rows={rows}
        slots={{
          row: BusinessOwnershipDataGridRow,
          loadingOverlay: BusinessOwnershipDataGridLoadingOverlay,
          noRowsOverlay: () => emptyText ?? t('noResults'),
        }}
        columns={columns
          .filter(item => item.showInGrid.includes(ownerShipHolderType))
          .map(item => ({
            // Adding default properties for every column
            renderCell: WithProps(BusinessOwnershipDataGridCell, {
              applicantId,
              ownerShipHolderType,
            }),
            headerClassName:
              '!p-0 text-xs !border-neutral-30 font-semibold bg-neutral-30 text-tertiary-grey-500 !outline-none',
            cellClassName:
              '!outline-none !border-neutral-30 text-sm break-words',
            sortable: false,
            hideSortIcons: true,
            resizable: false,
            disableColumnMenu: true,
            disableReorder: true,
            ...item,
          }))}
        loading={isLoading}
        isRowSelectable={() => false}
        isCellEditable={() => false}
        getRowHeight={() => 'auto'}
        columnHeaderHeight={45}
        disableRowSelectionOnClick
        disableColumnSelector
        disableColumnFilter
        disableColumnMenu
        disableDensitySelector
        showCellVerticalBorder={false}
        showColumnVerticalBorder={false}
        hideFooterPagination
        hideFooter
        hideFooterSelectedRowCount
        autoHeight
        autoPageSize
        sx={businessOwnershipDataGridSx}
      />
    </div>
  )
}
