import React, { useMemo } from 'react'

import { useFormContext } from 'react-hook-form'

import { FrankieButton, FrankieIconName, FrankieTextField } from 'frankify/src'

import { Show } from 'shared/hoc'
import { useI18n } from 'shared/i18n'

import { APPLICANT_BUSINESS_OWNERSHIP_KEY } from '../../../applicant-business-ownership.key'
import { applicantBusinessOwnershipEn } from '../../../locale/applicant-business-ownership.en'
import {
  AssociateNewPartyType,
  AssociatePartyFormData,
} from '../../../model/associate-party.model'
import { applicantAssociatePartiesQa } from '../../../qa/applicant-business-ownership.qa'
import { AssociateNewBusiness } from '../associate-new-business/associate-new-business'

type AssociatePartiesTypeOption = {
  label: string
  icon: FrankieIconName
  type: AssociateNewPartyType
}

export function AssociateNewParties() {
  const { register, watch, setValue } = useFormContext<AssociatePartyFormData>()

  const t = useI18n([APPLICANT_BUSINESS_OWNERSHIP_KEY], {
    keys: applicantBusinessOwnershipEn,
  })

  const partyType = watch('partyType')
  const isPartyTypeIndividual = partyType === 'INDIVIDUAL'

  const associatePartiesTypeOption: AssociatePartiesTypeOption[] = [
    {
      label: t('individual'),
      icon: 'mdiAccountOutline',
      type: 'INDIVIDUAL',
    },
    {
      label: t('business'),
      icon: 'mdiOfficeBuildingOutline',
      type: 'BUSINESS',
    },
  ]

  const heading = useMemo(() => {
    if (isPartyTypeIndividual) {
      return t('personalInfo')
    }
    return t('businessInfo')
  }, [t, partyType])

  return (
    <div className="mb-8">
      <div
        className="flex gap-4 py-2"
        data-qa={applicantAssociatePartiesQa.buttonWrapper}
      >
        {associatePartiesTypeOption.map(({ icon, label, type }) => (
          <FrankieButton
            key={type}
            intent="secondary"
            className={`${
              partyType === type
                ? 'bg-primary-50 !border-primary-800 text-primary-400'
                : 'border-tertiary-grey-300 bg-transparent hover:bg-transparent  hover:border-primary-500 text-tertiary-grey-700'
            }`}
            startIcon={{
              name: icon,
              className: `${
                partyType === type ? '' : 'text-tertiary-grey-400'
              } mr-2`,
            }}
            onClick={() => setValue('partyType', type)}
          >
            {label}
          </FrankieButton>
        ))}
      </div>
      <div className="my-4 text-xl text-tertiary-grey-800 font-bold">
        {heading}
      </div>
      <Show>
        <Show.When isTrue={isPartyTypeIndividual}>
          <div className="flex flex-wrap items-end gap-x-[2%] gap-y-8">
            <FrankieTextField
              className="basis-[32%]"
              label={t('givenName')}
              placeholder={t('givenName')}
              {...register('individualData.givenName', {
                required: isPartyTypeIndividual,
              })}
              testId={{ input: applicantAssociatePartiesQa.firstNameField }}
            />

            <FrankieTextField
              className="basis-[32%]"
              label={`${t('middleName')} ${t('optional')}`}
              placeholder={t('middleName')}
              {...register('individualData.middleName')}
            />

            <FrankieTextField
              className="basis-[32%]"
              label={t('familyName')}
              placeholder={t('familyName')}
              {...register('individualData.familyName', {
                required: isPartyTypeIndividual,
              })}
              testId={{ input: applicantAssociatePartiesQa.lastNameField }}
            />
          </div>
        </Show.When>

        <Show.When isTrue={!isPartyTypeIndividual}>
          <AssociateNewBusiness />
        </Show.When>
      </Show>
    </div>
  )
}
