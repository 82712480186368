import { useCallback } from 'react'

import { useQueryClient } from '@tanstack/react-query'

export enum ApplicantQueryKeysTypes {
  ApplicantPepSactionsQueryKey = 'applicant-pep-sactions-query-key',
  IKybAmlScreeingQueryKey = 'ikyb-aml-screening-query-key',
}

type QueyKeyType = ApplicantQueryKeysTypes | ApplicantQueryKeysTypes[]

type Args = {
  applicantId: string
}

export const useUpdateApplicantQueries = ({ applicantId }: Args) => {
  const queryClient = useQueryClient()

  const refetchQueries = useCallback(
    (queryKey: QueyKeyType) => {
      if (Array.isArray(queryKey)) {
        void queryKey.map(key => queryClient.refetchQueries([key, applicantId]))
      } else {
        void queryClient.refetchQueries([queryKey, applicantId])
      }
    },
    [applicantId, queryClient],
  )

  const removeQueries = useCallback(
    (queryKey: QueyKeyType) => {
      if (Array.isArray(queryKey)) {
        void queryKey.map(key => queryClient.removeQueries([key, applicantId]))
      } else {
        void queryClient.removeQueries([queryKey, applicantId])
      }
    },
    [applicantId, queryClient],
  )

  return {
    refetchQueries,
    removeQueries,
  }
}
