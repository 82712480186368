import React, { Ref, useMemo, useState } from 'react'

import {
  DataGridPro,
  GridColDef,
  GridRow,
  GridRowProps,
} from '@mui/x-data-grid-pro'
import cx from 'classnames'
import { Link } from 'react-router-dom'

import { FrankieIcon, FrankieIconName, FrankieTooltip } from 'frankify/src'

import {
  APPLICANTS_FILTER_KEY,
  applicantsFilterEn,
} from 'features/applicants-filter'
import { entitiesTableQa } from 'features/entities-table/qa/entities-table.qa'

import {
  ApplicantEntityTypes,
  ApplicantId,
  FrankieRawApplicant,
} from 'entities/applicant'
import { getFullRecipesList } from 'entities/recipe'
import { useSessionQuery } from 'entities/session'
import { getUserList } from 'entities/user'

import { useI18n } from 'shared/i18n'
import {
  DateCell,
  NameCell,
  TextCell,
  AvatarCell,
  BadgeCell,
  IndicatorCell,
  BadgeListCell,
} from 'shared/tables-common'

import {
  ENTITIES_TABLE_KEY,
  entitiesTableEn,
} from '../../locale/entities-table.en'
import {
  getEntityIssues,
  getRiskIndicator,
  getStatusBadge,
} from '../../model/entities-table.model'

type Props = {
  applicants: FrankieRawApplicant[] | []
  isLoading: boolean
  onScrollEnd: () => void
  className?: string
  getApplicantGeneralInfoPath: (applicantId: ApplicantId) => string
  tableRef?: Ref<HTMLDivElement>
  customDefaultSortModel?: {
    field: 'createdDate' | 'timestamp'
    sort: 'asc' | 'desc' | null
  }[]
}

function CustomRow(
  props: GridRowProps & Pick<Props, 'getApplicantGeneralInfoPath'>,
) {
  const { entityId } = props.row as FrankieRawApplicant
  const { getApplicantGeneralInfoPath, ...rest } = props

  if (!entityId) {
    return <GridRow {...rest} />
  }

  return (
    <Link
      to={getApplicantGeneralInfoPath(entityId)}
      className="!transition-none hover:!transition-none hover:!text-current"
    >
      <GridRow {...rest} />
    </Link>
  )
}

const sortIcons: Record<'asc' | 'desc', FrankieIconName> = {
  desc: 'mdiArrowDown',
  asc: 'mdiArrowUp',
}

const sortingTooltipMapping: Record<string, 'sortAsc' | 'sortDesc'> = {
  asc: 'sortDesc',
  desc: 'sortAsc',
}

const iconMapping: Record<ApplicantEntityTypes, FrankieIconName> = {
  [ApplicantEntityTypes.Individual]: 'mdiAccountOutline',
  [ApplicantEntityTypes.Organisation]: 'mdiOfficeBuildingOutline',
}

const TABLE_MIN_WIDTH = 1136

function EmptyOverlay() {
  return null
}

export function EntitiesTable({
  applicants,
  isLoading,
  className,
  onScrollEnd,
  getApplicantGeneralInfoPath,
  tableRef,
  customDefaultSortModel,
}: Props) {
  const t = useI18n([ENTITIES_TABLE_KEY], { keys: entitiesTableEn })
  const filterT = useI18n([APPLICANTS_FILTER_KEY], { keys: applicantsFilterEn })

  const headerClassName =
    '!p-0 text-xs font-medium uppercase bg-tertiary-grey-50 text-tertiary-grey-500 !outline-none'
  const cellClassName = '!outline-none'

  const { data: pageData } = useSessionQuery()
  const userList = getUserList(pageData)

  const [sortModel, setSortModel] = useState<
    { field: 'createdDate' | 'timestamp'; sort: 'asc' | 'desc' | null }[]
  >(
    customDefaultSortModel || [
      { field: 'createdDate', sort: 'desc' },
      { field: 'timestamp', sort: null },
    ],
  )

  const columns: GridColDef<FrankieRawApplicant>[] = useMemo(
    () => [
      {
        field: 'name',
        headerName: t('headers.name'),
        minWidth: 252,
        flex: 252 / TABLE_MIN_WIDTH,
        disableReorder: true,
        disableColumnMenu: true,
        hideSortIcons: true,
        resizable: false,
        sortable: false,
        headerClassName,
        cellClassName,
        renderHeader: params => (
          <span className="!px-4 !py-2">{params.colDef.headerName}</span>
        ),
        renderCell: params => {
          const { row } = params
          const entityName =
            row.entityName && row.entityName !== '<empty>'
              ? row.entityName
              : row.entityId
          return (
            <NameCell
              title={entityName ?? ''}
              subtitle={row.customerReference ?? ''}
              icon={iconMapping[row.entityType]}
            />
          )
        },
      },
      {
        field: 'status',
        headerName: t('headers.entityStatus'),
        minWidth: 142,
        flex: 142 / TABLE_MIN_WIDTH,
        disableReorder: true,
        disableColumnMenu: true,
        hideSortIcons: true,
        resizable: false,
        sortable: false,
        headerClassName,
        cellClassName,
        renderHeader: params => (
          <span className="!px-4 !py-2">{params.colDef.headerName}</span>
        ),
        renderCell: params => (
          <BadgeCell
            {...getStatusBadge(params.row, filterT)}
            className="min-w-[110px]"
          />
        ),
      },
      {
        field: 'issues',
        headerName: t('headers.issues'),
        minWidth: 163,
        flex: 163 / TABLE_MIN_WIDTH,
        disableReorder: true,
        disableColumnMenu: true,
        hideSortIcons: true,
        resizable: false,
        sortable: false,
        headerClassName,
        cellClassName,
        renderHeader: params => (
          <span className="!px-4 !py-2">{params.colDef.headerName}</span>
        ),
        renderCell: params => (
          <BadgeListCell badges={getEntityIssues(params.row, filterT)} />
        ),
      },
      {
        field: 'riskLevel',
        headerName: t('headers.riskLevel'),
        minWidth: 130,
        flex: 130 / TABLE_MIN_WIDTH,
        disableReorder: true,
        disableColumnMenu: true,
        hideSortIcons: true,
        resizable: false,
        sortable: false,
        headerClassName,
        cellClassName,
        renderHeader: params => (
          <span className="!px-4 !py-2">{params.colDef.headerName}</span>
        ),
        renderCell: params => (
          <IndicatorCell {...getRiskIndicator(params.row, filterT)} />
        ),
      },
      {
        field: 'createdDate',
        headerName: t('headers.createdDate'),
        minWidth: 110,
        flex: 110 / TABLE_MIN_WIDTH,
        disableReorder: true,
        disableColumnMenu: true,
        hideSortIcons: true,
        resizable: false,
        sortable: true,
        headerClassName,
        cellClassName,
        renderHeader: params => {
          const sortItem = sortModel.find(
            sortField => sortField.field === 'createdDate',
          )
          let sortIcon: FrankieIconName = 'mdiSwapVertical'
          let iconClassName = 'ml-1 pointer-events-none'
          let tooltip = t('noSortTooltipCreated')

          if (sortItem?.sort) {
            sortIcon = sortIcons[sortItem.sort]
            iconClassName = 'ml-1 pointer-events-none text-primary-800'
            tooltip = t(sortingTooltipMapping[sortItem.sort])
          }

          return (
            <span className="!px-4 !py-2 flex">
              {params.colDef.headerName}
              <FrankieTooltip position="top" body={<div>{tooltip}</div>}>
                <div>
                  <FrankieIcon
                    name={sortIcon}
                    size="xs"
                    className={iconClassName}
                  />
                </div>
              </FrankieTooltip>
            </span>
          )
        },
        renderCell: params => <DateCell date={params.row.createdDate} />,
      },
      {
        field: 'timestamp',
        headerName: t('headers.lastUpdatedDate'),
        minWidth: 110,
        flex: 110 / TABLE_MIN_WIDTH,
        disableReorder: true,
        disableColumnMenu: true,
        hideSortIcons: true,
        resizable: false,
        sortable: true,
        headerClassName,
        cellClassName,
        renderHeader: params => {
          const sortItem = sortModel.find(
            sortField => sortField.field === 'timestamp',
          )

          let sortIcon: FrankieIconName = 'mdiSwapVertical'
          let iconClassName = 'ml-1 pointer-events-none'
          let tooltip = t('noSortTooltipUpdated')

          if (sortItem?.sort) {
            sortIcon = sortIcons[sortItem.sort]
            iconClassName = 'ml-1 text-primary-800 pointer-events-none'
            tooltip = t(sortingTooltipMapping[sortItem.sort])
          }

          return (
            <span className="!px-4 !py-2 flex items-center">
              {params.colDef.headerName}
              <FrankieTooltip position="top" body={<div>{tooltip}</div>}>
                <div>
                  <FrankieIcon
                    name={sortIcon}
                    size="xs"
                    className={iconClassName}
                  />
                </div>
              </FrankieTooltip>
            </span>
          )
        },
        renderCell: params => <DateCell date={params.row.timestamp} />,
      },
      {
        field: 'recipe',
        headerName: t('headers.recipe'),
        minWidth: 140,
        flex: 140 / TABLE_MIN_WIDTH,
        disableReorder: true,
        disableColumnMenu: true,
        hideSortIcons: true,
        resizable: false,
        sortable: false,
        headerClassName,
        cellClassName,
        renderHeader: params => (
          <span className="!px-4 !py-2">{params.colDef.headerName}</span>
        ),
        renderCell: params => {
          const recipes = getFullRecipesList(pageData)
          const recipeToFind = recipes.find(
            recipe => recipe.value === params.row.profileName,
          )
          if (!recipeToFind) return null

          return <TextCell title={recipeToFind.label} />
        },
      },
      {
        field: 'assignee',
        headerName: t('headers.assignee'),
        minWidth: 89,
        flex: 89 / TABLE_MIN_WIDTH,
        disableReorder: true,
        disableColumnMenu: true,
        hideSortIcons: true,
        resizable: false,
        sortable: false,
        headerClassName: cx(
          headerClassName,
          '[&_.MuiDataGrid-columnHeaderDraggableContainer]:justify-center',
        ),
        cellClassName,
        renderHeader: params => (
          <span className="!px-4 !py-2">{params.colDef.headerName}</span>
        ),
        renderCell: params => {
          const userFromList = userList.find(
            user => user.email === params.row.assignee,
          )
          return <AvatarCell name={userFromList?.realname} notFull />
        },
      },
    ],
    [sortModel, t, userList, pageData, filterT],
  )

  const getRowId = (row: FrankieRawApplicant) => row.entityId || ''
  const isRowSelectable = () => false
  const isCellEditable = () => false
  const getRowClassName = () => 'hover:bg-tertiary-grey-200'
  const sortingOrder = ['asc', 'desc'] as const

  const handleSortChange = (
    sort: { field: 'createdDate' | 'timestamp'; sort: 'asc' | 'desc' }[],
  ) => {
    setSortModel(sort)
  }

  const handleScrollEnd = () => {
    if (!applicants.length) return

    onScrollEnd()
  }

  return (
    <DataGridPro
      data-qa={entitiesTableQa.table}
      ref={tableRef}
      rows={applicants}
      slots={{
        noRowsOverlay: EmptyOverlay,
        loadingOverlay: EmptyOverlay,
        row: CustomRow,
      }}
      slotProps={{ row: { getApplicantGeneralInfoPath } }}
      sortModel={sortModel}
      sortingOrder={sortingOrder}
      sortingMode="client"
      onRowsScrollEnd={handleScrollEnd}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onSortModelChange={handleSortChange}
      getRowClassName={getRowClassName}
      loading={isLoading}
      className={className}
      getRowId={getRowId}
      columns={columns}
      rowHeight={68}
      columnHeaderHeight={34}
      columnBuffer={0}
      isRowSelectable={isRowSelectable}
      isCellEditable={isCellEditable}
      disableRowSelectionOnClick
      disableColumnSelector
      disableColumnFilter
      disableColumnMenu
      disableDensitySelector
      showCellVerticalBorder={false}
      showColumnVerticalBorder={false}
      hideFooterPagination
      hideFooter
      hideFooterSelectedRowCount
      sx={{
        '.MuiDataGrid-columnSeparator': {
          display: 'none',
        },
        '&.MuiDataGrid-root': {
          border: 'none',
          marginRight: '32px',
        },
        '.MuiDataGrid-columnHeaders': {
          minHeight: 'unset !important',
          maxHeight: 'unset !important',
          lineHeight: 'unset !important',
          borderRadius: '0 !important',
        },
        '.MuiDataGrid-columnHeaderTitleContainer': {
          display: 'inline-block !important',
          flex: 'none !important',
        },
        '.MuiDataGrid-cell': {
          minHeight: 'unset !important',
          maxHeight: 'unset !important',
          lineHeight: 'unset !important',
          padding: '0 !important',
        },
      }}
    />
  )
}
