import { FrankieIconName } from 'frankify/src'

import {
  BlocklistReasonTypes,
  BlocklistAttributeTypes,
} from 'entities/applicant'

import { I18nKeys } from 'shared/i18n'

import { BlocklistDataProperties } from './blocklist.model'
import { BlocklistFilter } from '../api/blocklist.api'
import {
  blocklistAttributeEn,
  blocklistReasonEn,
  blocklistTableEn,
} from '../locale/blocklist-table.en'

export type BlockListConfig<TData extends object> = {
  tKey: I18nKeys<TData>
  tShortKey?: I18nKeys<TData>
  iconName?: FrankieIconName
}

export type BlocklistOptionData<
  TKeys extends BlocklistReasonTypes | BlocklistAttributeTypes,
  TData extends object,
> = Record<TKeys, BlockListConfig<TData>>

export const blocklistReasonData: BlocklistOptionData<
  BlocklistReasonTypes,
  typeof blocklistReasonEn
> = {
  [BlocklistReasonTypes.FABRICATED_IDENTITY]: {
    tKey: 'blocklistReason.fabricatedIdentity',
  },
  [BlocklistReasonTypes.IDENTITY_TAKEOVER]: {
    tKey: 'blocklistReason.identityTakeover',
  },
  [BlocklistReasonTypes.FALSIFIED_ID_DOCUMENTS]: {
    tKey: 'blocklistReason.falsifiedIdDocuments',
  },
  [BlocklistReasonTypes.STOLEN_ID_DOCUMENTS]: {
    tKey: 'blocklistReason.stolenIdDocuments',
  },
  [BlocklistReasonTypes.MERCHANT_FRAUD]: {
    tKey: 'blocklistReason.merchantFraud',
  },
  [BlocklistReasonTypes.NEVER_PAY_BUST_OUT]: {
    tKey: 'blocklistReason.neverPayBustOut',
  },
  [BlocklistReasonTypes.CONFLICTING_DATA_PROVIDED]: {
    tKey: 'blocklistReason.conflictingDataProvided',
  },
  [BlocklistReasonTypes.MONEY_MULE]: {
    tKey: 'blocklistReason.moneyMule',
  },
  [BlocklistReasonTypes.FALSE_FRAUD_CLAIM]: {
    tKey: 'blocklistReason.falseFraudClaim',
  },
  [BlocklistReasonTypes.FRAUDULENT_3RD_PARTY]: {
    tKey: 'blocklistReason.fraudulent3rdParty',
  },
  [BlocklistReasonTypes.COMPANY_TAKEOVER]: {
    tKey: 'blocklistReason.companyTakeover',
  },
  [BlocklistReasonTypes.FICTITIOUS_EMPLOYER]: {
    tKey: 'blocklistReason.fictitiousEmployer',
  },
  [BlocklistReasonTypes.COLLUSIVE_EMPLOYER]: {
    tKey: 'blocklistReason.collusiveEmployer',
  },
  [BlocklistReasonTypes.OVER_VALUATION_OF_ASSETS]: {
    tKey: 'blocklistReason.overValuationOfAssets',
  },
  [BlocklistReasonTypes.FALSIFIED_EMPLOYMENT_DETAILS]: {
    tKey: 'blocklistReason.falsifiedEmploymentDetails',
  },
  [BlocklistReasonTypes.MANIPULATED_IDENTITY]: {
    tKey: 'blocklistReason.manipulatedIdentity',
  },
  [BlocklistReasonTypes.SYNDICATED_FRAUD]: {
    tKey: 'blocklistReason.syndicatedFraud',
  },
  [BlocklistReasonTypes.INTERNAL_FRAUD]: {
    tKey: 'blocklistReason.internalFraud',
  },
  [BlocklistReasonTypes.BANK_FRAUD]: {
    tKey: 'blocklistReason.bankFraud',
  },
  [BlocklistReasonTypes.UNDISCLOSED_DATA]: {
    tKey: 'blocklistReason.undisclosedData',
  },
  [BlocklistReasonTypes.FALSE_HARDSHIP]: {
    tKey: 'blocklistReason.falseHardship',
  },
  [BlocklistReasonTypes.SMR_REPORT_LODGED]: {
    tKey: 'blocklistReason.smrReportLodged',
  },
  [BlocklistReasonTypes._2X_SMR_REPORTS_LODGED]: {
    tKey: 'blocklistReason._2xSmrReportsLodged',
  },
}

export const blocklistAttributeData: BlocklistOptionData<
  BlocklistAttributeTypes,
  typeof blocklistAttributeEn
> = {
  [BlocklistAttributeTypes.ENTIRE_ENTITY]: {
    tKey: 'blocklistAttribute.entireEntity',
    iconName: 'mdiAccountOutline',
  },
  [BlocklistAttributeTypes.MAILING_ADDRESS]: {
    tKey: 'blocklistAttribute.mailingAddress',
    iconName: 'mdiEmailOutline',
  },
  [BlocklistAttributeTypes.RESIDENTIAL_ADDRESS]: {
    tKey: 'blocklistAttribute.residentialAddress',
    iconName: 'mdiHomeOutline',
  },
  [BlocklistAttributeTypes.EMAIL_ADDRESS]: {
    tKey: 'blocklistAttribute.emailAddress',
    iconName: 'mdiAt',
  },
  [BlocklistAttributeTypes.PHONE_NUMBER]: {
    tKey: 'blocklistAttribute.phoneNumber',
    iconName: 'mdiCellphone',
  },
  [BlocklistAttributeTypes.ID_DOCUMENT]: {
    tKey: 'blocklistAttribute.idDocument',
    iconName: 'mdiCardAccountDetailsOutline',
  },
  [BlocklistAttributeTypes.NAME_AND_DOB]: {
    tKey: 'blocklistAttribute.nameAndDateOfBirth',
    tShortKey: 'blocklistAttribute.nameAndDob',
    iconName: 'mdiCalendarAccountOutline',
  },
}

export type FilterOptions = {
  tKey: I18nKeys<typeof blocklistTableEn>
  value: string
}[]

type Filters = Partial<
  Record<
    BlocklistDataProperties,
    {
      filterName: keyof BlocklistFilter
      filterOptions: FilterOptions
    }
  >
>

export const blocklistFilters: Filters = {
  reason: {
    filterName: 'reasonFilter',
    filterOptions: Object.entries(blocklistReasonData).map(([value, item]) => ({
      tKey: item.tKey,
      value,
    })),
  },
  attribute: {
    filterName: 'attributeFilter',
    filterOptions: Object.entries(blocklistAttributeData).map(
      ([value, item]) => ({
        tKey: item.tKey,
        value,
      }),
    ),
  },
}
