import { ApplicantResponse, Document } from 'entities/applicant'

const getChineseNationalDocument = (documents: Document[]) =>
  documents.find(doc => doc.idType === 'NATIONAL_ID' && doc.country === 'CHN')

const getNameComparisonData = (document: Document | undefined) => {
  if (!document) return null

  const { extraData } = document
  if (extraData && extraData.transliteration_name_match) {
    return {
      userEnteredNameFromId: extraData.transliteration_original_name,
      transliteratedName: extraData.transliteration_transliterated_name,
    }
  }
  return null
}

export const getChineseNameComparisonInfo = (
  applicantData: ApplicantResponse,
) => {
  const { personalChecks } = applicantData.checkSummary
  const chineseNationalDocument = getChineseNationalDocument(
    applicantData.documents,
  )
  const entityName = applicantData.applicantDetails.displayName ?? ''

  return {
    title: 'Chinese National ID Name Comparison',
    comparisonInfo: getNameComparisonData(chineseNationalDocument),
    customerName: entityName,
    isVerified: personalChecks.chineseName || null,
    documentId: chineseNationalDocument?.documentId || '',
  }
}

export type OverrideResultForm = {
  status: string
  comment: string
}

export enum OverrideResultTypes {
  ManualFail = 'false_positive',
  ManualPass = 'true_positive',
}
