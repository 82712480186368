import React, { useEffect } from 'react'

import { useForm } from 'react-hook-form'

import { FrankieButton, FrankieIcon, FrankieLoader } from 'frankify/src'

import {
  IIndividualProfileInputs,
  individualProfileDefaultValues,
} from 'features/individual-profile'

import {
  ApplicantId,
  BlocklistAttributeTypes,
  useApplicantDataQuery,
} from 'entities/applicant'
import { PermissionTypes } from 'entities/role'
import { useHasPermission } from 'entities/session'

import { useI18n } from 'shared/i18n'
import { useOverlay } from 'shared/overlay'
import { Noop } from 'shared/typescript'

import { APPLICANT_BLOCKLISTED_INFO_KEY } from '../../applicant-bllocklisted-info.key'
import { applicantBlocklistedInfoEn } from '../../locale/applicant-blocklisted-info.en'
import {
  blocklistDataConfig,
  getAddToBlocklistPayload,
} from '../../model/add-to-blocklist.model'
import {
  BlacklistedFormInputs,
  BlocklistedFormDefaultValue,
  BlocklistedInputTypes,
  FormTypes,
} from '../../model/applicant-blocklisted-form.model'
import { mapDataToForm } from '../../model/edit-blocklist.model'
import { useSaveBlocklist } from '../../mutation/add-to-blocklist.mutation'
import { applicantBlockListFormQa } from '../../qa/applicant-blocklisted-info-qa'
import { BlocklistAddressForm } from '../address-form/address-form'
import { BlocklistReason } from '../blocklist-reason/blocklist-reason'
import { BlocklistTypeForm } from '../blocklist-type-form/blocklist-type-form'
import { BlocklistDocumentForm } from '../document-form/document-form'
import { EmailForm } from '../email-form/email-form'
import { NameDob } from '../name-and-dob/name-and-dob'
import { PhoneNumberForm } from '../phone-number-form/phone-number-form'

type ConfirmationModalProps = {
  loading: boolean
  onClose: Noop
  onSubmit: Noop
}
export function ConfirmationModal({
  loading,
  onClose,
  onSubmit,
}: ConfirmationModalProps) {
  const t = useI18n([APPLICANT_BLOCKLISTED_INFO_KEY], {
    keys: applicantBlocklistedInfoEn,
  })

  return (
    <div className="text-tertiary-grey-700 w-[510px]">
      <div className="py-4 px-6 border-b border-tertiary-grey-300 text-md font-bold text-tertiary-grey-800">
        {t('modal.confirmation')}
      </div>

      <FrankieLoader loading={loading}>
        <div className="py-3 px-6">
          <div className="flex flex-col items-center gap-2 font-bold bg-tertiary-red-50 p-4 my-2 text-tertiary-red-500 rounded-md">
            <FrankieIcon name="mdiAlertOutline" />
            <span className="text-center mb-4">{t('modal.description')}</span>
            <span className="text-center">{t('modal.title')}</span>
          </div>

          <div className="flex justify-between mt-4">
            <FrankieButton intent="primary" onClick={onSubmit}>
              {t('modal.save')}
            </FrankieButton>

            <FrankieButton intent="subtle" onClick={onClose}>
              {t('modal.cancel')}
            </FrankieButton>
          </div>
        </div>
      </FrankieLoader>
    </div>
  )
}

type Props = {
  type?: FormTypes
  applicantId?: ApplicantId
  getApplicantGeneralInfoPath: (applicantId: ApplicantId) => string
}

// eslint-disable-next-line complexity
export function ApplicantBlocklistedForm({
  type = FormTypes.ADD,
  applicantId = '',
  getApplicantGeneralInfoPath,
}: Props) {
  const t = useI18n([APPLICANT_BLOCKLISTED_INFO_KEY], {
    keys: applicantBlocklistedInfoEn,
  })

  const { hasEditPermission } = useHasPermission({
    hasEditPermission: PermissionTypes.BlocklistEdit,
  })

  const { data: applicantData } = useApplicantDataQuery({
    applicantId,
    enabled: type === FormTypes.Edit,
  })

  const { addToBlocklist, isLoading, isAddedSuccessfully } = useSaveBlocklist({
    getApplicantGeneralInfoPath,
    isUpdate: type === FormTypes.Edit,
  })

  const blocklistTypeForm = useForm<BlacklistedFormInputs>({
    mode: 'onSubmit',
    defaultValues: BlocklistedFormDefaultValue,
  })

  const form = useForm<IIndividualProfileInputs>({
    mode: 'onSubmit',
    defaultValues: individualProfileDefaultValues,
  })

  const { reset: resetForm, trigger, getValues } = form

  const [createOverlay, closeOverlay] = useOverlay()

  const {
    watch: blocklistFormWatch,
    reset: resetBlocklistForm,
    handleSubmit: handleBlocklistFormSubmit,
  } = blocklistTypeForm

  useEffect(() => {
    if (applicantData && !!applicantId) {
      const data = mapDataToForm(applicantData)

      resetBlocklistForm(data.blocklistFormData)
      resetForm(data.formData)
    }
  }, [applicantData, applicantId, resetBlocklistForm, resetForm])

  useEffect(() => {
    if (isAddedSuccessfully) {
      closeOverlay()
    }
  }, [closeOverlay, isAddedSuccessfully])

  const blocklistType = blocklistFormWatch(BlocklistedInputTypes.BlocklistType)

  const saveData = (
    formData: IIndividualProfileInputs,
    blocklistFormData: BlacklistedFormInputs,
  ) => {
    let defaultBlocklistData = blocklistDataConfig

    if (applicantData && type === FormTypes.Edit) {
      const { applicantDetails, ...restApplicantData } = applicantData
      defaultBlocklistData = {
        applicant: applicantDetails,
        ...restApplicantData,
      }
    }

    const data = getAddToBlocklistPayload({
      blocklistAttribute: blocklistType as BlocklistAttributeTypes,
      data: formData,
      blocklistFormData,
      defaultBlocklistData,
    })

    addToBlocklist({
      data,
      checkBlocklistData: {
        entityId: '',
        payload: {
          flag: 'true_positive',
          comment: blocklistFormData[BlocklistedInputTypes.Comment],
          reasonCode: blocklistFormData[BlocklistedInputTypes.BlocklistReason],
          attribute: blocklistType,
        },
      },
    })
  }

  const onFormSubmit = (blocklistFormData: BlacklistedFormInputs) => {
    void trigger().then(isValid => {
      if (isValid) {
        if (type === FormTypes.ADD) {
          const formData = getValues()
          saveData(formData, blocklistFormData)
        } else {
          createOverlay(
            <ConfirmationModal
              loading={isLoading}
              onClose={closeOverlay}
              onSubmit={() => {
                const formData = getValues()
                saveData(formData, blocklistFormData)
              }}
            />,
            { className: 'p-0', closeButtonClassName: '!top-4 !right-5' },
          )
        }
      }
    })
  }

  return (
    <div
      data-qa={applicantBlockListFormQa.container}
      className="max-w-[1078px] mx-auto text-tertiary-grey-700 border border-solid border-tertiary-grey-200 rounded-sm"
    >
      <div className="flex justify-start items-center border-b border-b-solid border-tertiary-grey-200 px-5 py-4">
        <FrankieIcon
          name="mdiCancel"
          size="sm"
          className="text-primary-500 mr-2"
        />
        <div className="font-bold text-xl">
          {type === FormTypes.ADD ? t('titleAdd') : t('titleEdit')}
        </div>
      </div>
      <div className="px-5 py-4">
        {!hasEditPermission && (
          <div className="p-4 bg-tertiary-grey-500 mt-6 mx-3 text-mono-white rounded-md flex justify-start items-center font-bold">
            <FrankieIcon
              name="mdiInformation"
              size="md"
              className="text-mono-white mr-3"
            />
            {t('editPermissionError')}
          </div>
        )}
        <form
          data-qa={applicantBlockListFormQa.form}
          onSubmit={handleBlocklistFormSubmit(onFormSubmit, () => {
            void trigger()
          })}
        >
          <BlocklistTypeForm form={blocklistTypeForm} type={type} />

          {blocklistType === BlocklistAttributeTypes.PHONE_NUMBER && (
            <PhoneNumberForm form={blocklistTypeForm} />
          )}

          {blocklistType === BlocklistAttributeTypes.EMAIL_ADDRESS && (
            <EmailForm form={blocklistTypeForm} />
          )}

          {blocklistType === BlocklistAttributeTypes.NAME_AND_DOB && (
            <NameDob form={form} />
          )}

          {blocklistType === BlocklistAttributeTypes.ID_DOCUMENT && (
            <BlocklistDocumentForm form={form} />
          )}

          {blocklistType === BlocklistAttributeTypes.RESIDENTIAL_ADDRESS && (
            <BlocklistAddressForm form={form} type="residential" />
          )}

          {blocklistType === BlocklistAttributeTypes.MAILING_ADDRESS && (
            <BlocklistAddressForm form={form} type="mailing" />
          )}

          {blocklistType !== '' && <BlocklistReason form={blocklistTypeForm} />}

          <FrankieButton
            testId={{ button: applicantBlockListFormQa.saveBtn }}
            className="mt-6 font-bold"
            type="submit"
            disabled={isLoading}
          >
            {t('form.saveToBlocklist')}
          </FrankieButton>
        </form>
      </div>
    </div>
  )
}
