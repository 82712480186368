import { bffClient } from 'shared/client'
import { clearCookies } from 'shared/cookies'
import { localStorageClearSensitive } from 'shared/local-storage'

export const logout = () => {
  clearCookies()
  bffClient.clearToken()
  localStorageClearSensitive()
}

export const replaceUrl = (redirectUrl: string) => {
  window.location.replace(redirectUrl)
}
