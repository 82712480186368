import React from 'react'

import { Dialog, DialogContent } from './dialog-floating-ui'
import { enableTailwindClass } from '../../styles/tailwind/enable-tailwind-class'

type Props = {
  children: React.ReactNode
  open?: boolean
  onOpenChange?: (open: boolean) => void
}

export function FrankieModal({ open, onOpenChange, children }: Props) {
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="m-4 bg-mono-white p-4 rounded">
        <div className={enableTailwindClass}>{children}</div>
      </DialogContent>
    </Dialog>
  )
}
