import { INDIVIDUAL_AML_SCREENING_KEY } from 'features/individual-aml-screening/individual-aml-screening.key'

const noPlaceHolder = `${INDIVIDUAL_AML_SCREENING_KEY}-no-placeholder`
export const noPlaceHolderQa = {
  container: `${noPlaceHolder}-container`,
  icon: `${noPlaceHolder}-icon`,
  noMatch: `${noPlaceHolder}-noMatch`,
  noMatchSubtitle: `${noPlaceHolder}-noMatchSubtitle`,
} satisfies Record<string, `${typeof noPlaceHolder}-${string}`>

const individualAmlScreening = `${INDIVIDUAL_AML_SCREENING_KEY}-individual-aml-screening`
export const individualAmlScreeningQa = {
  container: `${individualAmlScreening}-container`,
} satisfies Record<string, `${typeof individualAmlScreening}-${string}`>

const individualAmlScreeningSummary = `${INDIVIDUAL_AML_SCREENING_KEY}-individual-aml-screening-summary`
export const individualAmlScreeningSummaryQa = {
  container: `${individualAmlScreeningSummary}-container`,
  amlTitle: `${individualAmlScreeningSummary}-amlTitle`,
  name: `${individualAmlScreeningSummary}-name`,
  dob: `${individualAmlScreeningSummary}-dob`,
  country: `${individualAmlScreeningSummary}-country`,
  fuzziness: `${individualAmlScreeningSummary}-fuzziness`,
  matchSummary: `${individualAmlScreeningSummary}-matchSummary`,
  potentialMatch: `${individualAmlScreeningSummary}-potentialMatch`,
  unknown: `${individualAmlScreeningSummary}-unknown`,
  truePositive: `${individualAmlScreeningSummary}-truePositive`,
  falsePositive: `${individualAmlScreeningSummary}-falsePositive`,
} satisfies Record<string, `${typeof individualAmlScreeningSummary}-${string}`>

const individualAmlScreeningDataGridHelper = `${INDIVIDUAL_AML_SCREENING_KEY}-individual-aml-screening-data-grid-helper`
export const individualAmlScreeningDataGridHelperQa = {
  checkbox: `${individualAmlScreeningDataGridHelper}-checkbox`,
  headerCellContainer: `${individualAmlScreeningDataGridHelper}-headerCell-container`,
  headerCellHeaderName: `${individualAmlScreeningDataGridHelper}-headerCell-headerName`,
  headerCellSortIcon: `${individualAmlScreeningDataGridHelper}-headerCell-sortIcon`,
  paginationContainer: `${individualAmlScreeningDataGridHelper}-paginationContainer`,
  rowPerPageText: `${individualAmlScreeningDataGridHelper}-rowPerPageText`,
  paginationSelectField: `${individualAmlScreeningDataGridHelper}-paginationSelectField`,
} satisfies Record<
  string,
  `${typeof individualAmlScreeningDataGridHelper}-${string}`
>

const individualAmlScreeningDataGrid = `${INDIVIDUAL_AML_SCREENING_KEY}-individual-aml-screening-data-grid`
export const individualAmlScreeningDataGridQa = {
  container: `${individualAmlScreeningDataGrid}-container`,
  returnResult: `${individualAmlScreeningDataGrid}-returnResult`,
  filter: `${individualAmlScreeningDataGrid}-filter`,
  filterContainer: `${individualAmlScreeningDataGrid}-filterContainer`,
  popoverBtn: `${individualAmlScreeningDataGrid}-popoverBtn`,
  clearFilter: `${individualAmlScreeningDataGrid}-clearFilter`,
  updateAlertBtn: `${individualAmlScreeningDataGrid}-updateAlertBtn`,
  bannerContainer: `${individualAmlScreeningDataGrid}-bannerContainer`,
  bannerBtn: `${individualAmlScreeningDataGrid}-bannerBtn`,
} satisfies Record<string, `${typeof individualAmlScreeningDataGrid}-${string}`>
