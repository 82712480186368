import React from 'react'

import { DocumentIdTypes } from 'entities/document'

import { EmptyString } from 'shared/typescript'

import { BankStatement } from './bank-statement/bank-statement'
import { BirthCertificateForm } from './birth-certificate/birth-certificate'
import { ChangeOfNameForm } from './change-of-name/change-of-name'
import { CitizenShipCertificateForm } from './citizen-ship-certificate/citizen-ship-certificate'
import {
  DocumentFormComponentMapping,
  DocumentsSubFormProps,
} from './document-sub-forms.types'
import { DriverLicenseForm } from './driver-license/driver-license'
import { ImmigrationForm } from './immigration/immigration'
import { MarriageCertificateForm } from './marriage-certificate/marriage-certificate'
import { MedicareCardForm } from './medicare-card/medicare-card'
import { NationalIdForm } from './national-id/national-id'
import { OtherForm } from './other/other'
import { PassportForm } from './passport/passport'
import { TaxIdForm } from './tax-id/tax-id'
import { VehicleForm } from './vehicle/vehicle'
import { VisaForm } from './visa/visa'

export const DocumentFormMapping: DocumentFormComponentMapping = {
  [DocumentIdTypes.PASSPORT]: PassportForm,
  [DocumentIdTypes.DRIVERS_LICENSE]: DriverLicenseForm,
  [DocumentIdTypes.VISA]: VisaForm,
  [DocumentIdTypes.IMMIGRATION]: ImmigrationForm,
  [DocumentIdTypes.BIRTH_CERT]: BirthCertificateForm,
  [DocumentIdTypes.MEDICARE_CARD]: MedicareCardForm,
  [DocumentIdTypes.CITIZENSHIP]: CitizenShipCertificateForm,
  [DocumentIdTypes.NAME_CHANGE]: ChangeOfNameForm,
  [DocumentIdTypes.MARRIAGE_CERT]: MarriageCertificateForm,
  [DocumentIdTypes.TAX_ID]: TaxIdForm,
  [DocumentIdTypes.OTHER]: OtherForm,
  [DocumentIdTypes.NATIONAL_ID]: NationalIdForm,
  [DocumentIdTypes.VOTER_ID]: NationalIdForm,
  [DocumentIdTypes.PAN]: VisaForm,
  [DocumentIdTypes.BANK_STATEMENT]: BankStatement,
  [DocumentIdTypes.VEHICLE_REGISTRATION]: VehicleForm,
}

export type OwnProps = { selectedType: EmptyString<DocumentIdTypes> }

export type Props = OwnProps & DocumentsSubFormProps

export function DocumentSubForm({ selectedType, ...rest }: Props) {
  if (!selectedType) {
    return null
  }
  const DocType = DocumentFormMapping[selectedType]

  if (!DocType) {
    return null
  }

  return <DocType {...rest} />
}
