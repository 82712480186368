import React from 'react'

import { useForm } from 'react-hook-form'

import { FrankieTextarea, FrankieButton, FrankieLoader } from 'frankify/src'

import { MONITORING_OVERVIEW_KEY } from 'features/monitoring-overview/monitoring-overview.key'

import { useApplicantDataQuery } from 'entities/applicant'

import { useI18n } from 'shared/i18n'
import { notification } from 'shared/notification'

import { ArchiveType } from '../../model/applicant.model'
import { useApplicantUpdateStatus } from '../../state/update-status-mutation/applicant-update-status.mutation'

enum FormFieldTypes {
  Comment = 'comment',
  Status = 'state',
}

type FormFields = {
  comment: string
  state: ArchiveType
}

type Props = {
  applicantId: string
  onClose: () => void
  action: 'archive' | 'unarchive'
}

export function ArchieveProfileModal({ applicantId, action, onClose }: Props) {
  const t = useI18n(MONITORING_OVERVIEW_KEY)

  const { mutateAsync: updateApplicantStatus, isLoading: isUpdatingStatus } =
    useApplicantUpdateStatus()

  const { refetch } = useApplicantDataQuery({ applicantId })

  const {
    register,
    watch,
    setValue,
    trigger,
    formState: { isValid },
  } = useForm<FormFields>({
    mode: 'onChange',
    defaultValues: {
      comment: '',
      state: action === 'archive' ? 'archived' : 'clear',
    },
  })

  const formWatch = watch()

  register(FormFieldTypes.Status, {
    required: {
      value: true,
      message: 'err',
    },
  })

  register(FormFieldTypes.Comment, {
    required: {
      value: true,
      message: 'err',
    },
  })

  const handleChangeComment = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(FormFieldTypes.Comment, e.target.value)
    void trigger(FormFieldTypes.Comment)
  }

  const handleChangeStatus = async () => {
    try {
      await updateApplicantStatus({
        applicantId,
        status: formWatch[FormFieldTypes.Status],
        comment: formWatch[FormFieldTypes.Comment],
      })
      void refetch()
      onClose()
    } catch (error) {
      notification.error(t('errorOnViewReport'))
    }
  }

  if (action === 'archive') {
    return (
      <FrankieLoader loading={isUpdatingStatus}>
        <span className="block text-tertiary-grey-800 text-xl font-bold">
          {t('archiveModal.archiveProfileTitle')}
        </span>
        <span className="block text-tertiary-grey-700 text-sm font-normal mt-3">
          {t('archiveModal.archiveProfileSubtitle')}
        </span>
        <span className="block text-tertiary-grey-700 text-sm font-normal mt-2">
          {t('archiveModal.archiveProfileDescription')}
        </span>
        <div>
          <span className="inline-block mt-4 text-sm font-medium">
            {t('archiveModal.commentFieldTitle')}
          </span>
          <FrankieTextarea
            placeholder={t('archiveModal.commentPlaceholder')}
            value={formWatch[FormFieldTypes.Comment]}
            onChange={handleChangeComment}
            inputClassName="mt-2 w-full min-h-[112px] !p-1 resize-none outline-none block rounded-sm border border-tertiary-grey-300 border-solid focus-visible:border-primary"
          />
        </div>
        <div className="flex flex-row justify-end mt-6">
          <FrankieButton intent="subtle" onClick={onClose}>
            {t('archiveModal.cancelButton')}
          </FrankieButton>
          <FrankieButton
            intent="primary"
            className="ml-2"
            disabled={!isValid}
            onClick={handleChangeStatus}
          >
            {t('archiveModal.archiveProfileButton')}
          </FrankieButton>
        </div>
      </FrankieLoader>
    )
  }

  return (
    <FrankieLoader loading={isUpdatingStatus}>
      <span className="block text-tertiary-grey-800 text-xl font-bold">
        {t('unarchiveModal.unarchiveProfileTitle')}
      </span>
      <span className="block text-tertiary-grey-700 text-sm font-normal mt-3">
        {t('unarchiveModal.unarchiveProfileSubtitle')}
      </span>
      <span className="block text-tertiary-grey-700 text-sm font-normal mt-2">
        {t('unarchiveModal.unarchiveProfileDescription')}
      </span>
      <div>
        <span className="inline-block mt-4 text-sm font-medium">
          {t('archiveModal.commentFieldTitle')}
        </span>
        <FrankieTextarea
          placeholder={t('archiveModal.commentPlaceholder')}
          value={formWatch[FormFieldTypes.Comment]}
          onChange={handleChangeComment}
          inputClassName="mt-2 w-full min-h-[112px] !p-1 resize-none outline-none block rounded-sm border border-tertiary-grey-300 border-solid focus-visible:border-primary"
        />
      </div>
      <div className="flex flex-row justify-end mt-6">
        <FrankieButton intent="subtle" onClick={onClose}>
          {t('archiveModal.cancelButton')}
        </FrankieButton>
        <FrankieButton
          intent="primary"
          className="ml-2"
          disabled={!isValid}
          onClick={handleChangeStatus}
        >
          {t('unarchiveModal.unArchiveProfileButton')}
        </FrankieButton>
      </div>
    </FrankieLoader>
  )
}
