import { APPLICANT_BIOMETRICS_OCR_KEY as ABO } from '../applicant-biometrics-ocr.key'

const AppBioAccord = `${ABO}-applicant-biometric-accordion-qa`
export const applicantBiometricAccordionQa = {
  toggleBtn: `${AppBioAccord}-toggle-btn`,
  loader: `${AppBioAccord}-loader`,
  tooltip: `${AppBioAccord}-tooltip`,
  overrideStatusWrapper: `${AppBioAccord}-override-status-wrapper`,
} satisfies Record<string, `${typeof AppBioAccord}-${string}`>

const AppBioOcr = `${ABO}-applicant-biometric-ocr-qa`
export const applicantBiometricOcrQa = {
  loader: `${AppBioOcr}-loader`,
  openBtn: `${AppBioOcr}-open-btn`,
  wrapper: `${AppBioOcr}-wrapper`,
} satisfies Record<string, `${typeof AppBioOcr}-${string}`>

const AppBioOcrHelper = `${ABO}-applicant-biometric-ocr-helper-qa`
export const applicantBiometricOcrHelperQa = {
  icon: `${AppBioOcrHelper}-icon`,
  confidenceScoreWrapper: `${AppBioOcrHelper}-confidence-score-wrapper`,
  entityViewWrapper: `${AppBioOcrHelper}-entity-view-wrapper`,
  loader: `${AppBioOcrHelper}-loader`,
} satisfies Record<string, `${typeof AppBioOcrHelper}-${string}`>

const AppDocReportPnl = `${ABO}-applicant-document-report-panel-qa`
export const applicantDocumentReportPanelQa = {
  trickTreeWrapper: `${AppDocReportPnl}-trick-tree-wrapper`,
} satisfies Record<string, `${typeof AppDocReportPnl}-${string}`>

const AppOverrideBioStatus = `${ABO}-applicant-override-biometric-status-qa`
export const applicantManualOverrideBiometricStatusQa = {
  manualOverrideBtn: `${AppOverrideBioStatus}-manual-override-btn`,
  form: `${AppOverrideBioStatus}-form`,
  status: `${AppOverrideBioStatus}-status`,
  comment: `${AppOverrideBioStatus}-comment`,
  cancel: `${AppOverrideBioStatus}-cancel`,
  changeStatus: `${AppOverrideBioStatus}-change-status`,
} satisfies Record<string, `${typeof AppOverrideBioStatus}-${string}`>

const AppSendBioLink = `${ABO}-applicant-send-biometric-link-qa`
export const applicantSendBiometricLinkQa = {
  button: `${AppSendBioLink}-button`,
  icon: `${AppSendBioLink}-icon`,
  selectField: `${AppSendBioLink}-select-field`,
} satisfies Record<string, `${typeof AppSendBioLink}-${string}`>
