import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { FrankieBadge } from 'frankify/src'

import { useI18n } from 'shared/i18n'

import {
  TABLES_COMMON_KEY,
  tablesCommonEn,
} from '../../locale/tables-common.en'

export function BadgeListCell({
  badges,
  className = '',
}: {
  badges: string[]
  className?: string
}) {
  const elementRef = useRef<HTMLDivElement>(null)
  const t = useI18n([TABLES_COMMON_KEY], { keys: tablesCommonEn })

  const [overflowBadges, setOverflowBadges] = useState<string[]>([])

  const updateBadgesOverflow = useCallback(() => {
    if (elementRef.current) {
      const parentDimensions = elementRef.current.getBoundingClientRect()
      const overflowElements: string[] = []

      for (const child of elementRef.current.children) {
        const elementDimension = child.getBoundingClientRect()

        const isElementOutOfParentBounds =
          elementDimension.bottom >= parentDimensions.bottom ||
          elementDimension.right >= parentDimensions.right

        if (isElementOutOfParentBounds) {
          overflowElements.push(child.innerHTML)
        }
      }
      setOverflowBadges(overflowElements)
    }
  }, [])

  const uniqueBadges = useMemo(() => [...new Set(badges)], [badges])

  useEffect(() => {
    updateBadgesOverflow()
  }, [updateBadgesOverflow])

  useEffect(() => {
    const handleResize = () => {
      updateBadgesOverflow()
    }

    handleResize()

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [updateBadgesOverflow])

  const plusMoreIndex = uniqueBadges.findIndex(
    badge => badge === overflowBadges[0],
  )

  return (
    <div
      ref={elementRef}
      className={`w-full h-full whitespace-normal px-4 py-3 flex flex-row flex-wrap gap-[3px] items-center ${className}`}
    >
      {uniqueBadges.map((badge, index) => {
        if (index === plusMoreIndex - 1) {
          return (
            <FrankieBadge theme="lightgrey" text={t('plusMore')} key="more" />
          )
        }

        return (
          <FrankieBadge
            theme="lightgrey"
            text={badge}
            key={badge}
            className={overflowBadges.includes(badge) ? 'hidden' : ''}
          />
        )
      })}
    </div>
  )
}
