import { useMemo } from 'react'

import { useNotificationSettingsQuery } from './notification-settings.query'
import { personalNotificationMapper } from '../../model/notifications-management.model'

export const useNotificationSettingsVolumeState = ({
  canFetch,
}: {
  canFetch: boolean
}) => {
  const { data } = useNotificationSettingsQuery({ canFetch })

  const dictionaryWithSettingsAndI18n = useMemo(
    () =>
      data?.notificationSettingsDictionary.map(dict => ({
        ...dict,
        type: dict.value,
        settings: data.notificationSettings.filter(
          setting => setting.type === dict.value,
        ),
      })),
    [data],
  )

  const value = useMemo(
    () =>
      Object.values(personalNotificationMapper).some(value =>
        dictionaryWithSettingsAndI18n
          ?.find(d => value.includes(d.type))
          ?.settings.some(setting => setting.value),
      ),
    [dictionaryWithSettingsAndI18n],
  )

  return { isPersonal: !value }
}
