export const SUPRSEND_KEY = 'suprsend'

export const suprsendEn = {
  message: {
    userAssigned: '<0>{{applicantName}}</0> has been assigned to you',
    userAssignedBatched: '<0>{{count}}</0> entities have been assigned to you',
    userAssignedBatched_one: '<0>{{count}}</0> entity has been assigned to you',
    userAssignedBatched_other:
      '<0>{{count}}</0> entities have been assigned to you',
    entityStatusUpdated:
      '<0>{{applicantName}}</0> has a new status: <0>{{status}}</0>',
    entityStatusUpdatedBatched:
      '<0>{{count}}</0> entities have a new status: <0>{{status}}</0>',
    entityStatusUpdatedBatched_one:
      '<0>{{count}}</0> entity has a new status: <0>{{status}}</0>',
    entityStatusUpdatedBatched_other:
      '<0>{{count}}</0> entities have a new status: <0>{{status}}</0>',
    assignedEntityStatusUpdated:
      '<0>{{applicantName}}</0> assigned to you has a new status: <0>{{status}}</0>',
    entityRiskUpdated:
      '<0>{{applicantName}}</0> has an updated risk score: <0>from {{riskFrom}} to {{riskTo}}</0>',
    assignedEntityRiskUpdated:
      '<0>{{applicantName}}</0> assigned to you has an updated risk score: <0>from {{riskFrom}} to {{riskTo}}</0>',
    trustAnalysisComplete:
      'Trust deed analysis for <0>{{documentName}}</0> is complete and ready for review',
  },
  format: {
    ago: '{{date}} ago',
  },
  title: {
    today: 'Today',
    yesterday: 'Yesterday',
    older: 'Older',
  },
}
