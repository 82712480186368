import { useQuery } from '@tanstack/react-query'

import { Scan, documentApi } from 'entities/document'

export const ScansQueryKey = ['scans']

type Args = {
  documentId?: string
}

export const useScansQuery = ({ documentId }: Args) =>
  useQuery<Scan[]>({
    enabled: false,
    queryKey: [ScansQueryKey, documentId],
    queryFn: async () => {
      const { data } = await documentApi.getDocumentScans(documentId!)

      return data
    },
  })
