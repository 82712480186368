import { useMutation, useQueryClient } from '@tanstack/react-query'

import { IRole, RoleId } from 'entities/role'

import { getSingleRoleQueryKey } from './single-role.query'
import { roleManagementApi } from '../../api/role-management.api'
import { IRoleConfig } from '../../model/role-management.model'
import { RoleListQueryKey } from '../role-list/role-list.query'

export const useEditRoleMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({
      id,
      roleConfig,
    }: {
      id: RoleId
      roleConfig: Partial<IRoleConfig>
    }) => roleManagementApi.editRole(id, roleConfig),
    onSuccess: ({ data: newRole }, variables) => {
      const roles = queryClient.getQueryData<IRole[]>(RoleListQueryKey)
      if (roles) {
        const newRoles = roles.map<IRole>(role =>
          role.id === newRole.id ? newRole : role,
        )
        queryClient.setQueryData(RoleListQueryKey, newRoles)
      }

      queryClient.setQueryData(getSingleRoleQueryKey(variables.id), newRole)
    },
  })
}
