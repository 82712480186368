import React from 'react'

import { ApplicantId } from 'entities/applicant'

import { TextAreaFormField } from 'shared/form'
import { useI18n } from 'shared/i18n'
import { Noop } from 'shared/typescript'

import { APPLICANT_GENERAL_INFORMATION_KEY } from '../../../../applicant-general-information.key'
import { applicantGeneralInformationEn } from '../../../../locale/applicant-general-information.en'
import { removeFromBlocklistQa } from '../../../../qa/applicant-general-information.qa'
import {
  AddToBlocklistData,
  useAddApplicantToBlocklistMutation,
} from '../../../../state/applicant-profile-state-change/applicant-profile-state-change'
import { ApplicantActionModalWrapper } from '../../applicant-action-modal-wrapper/applicant-action-modal-wrapper'

type Props = { applicantId: ApplicantId; handleClose: Noop }

export function RemoveApplicantFromBlocklist({
  applicantId,
  handleClose,
}: Props) {
  const t = useI18n([APPLICANT_GENERAL_INFORMATION_KEY], {
    keys: applicantGeneralInformationEn,
  })

  const { mutate, isLoading, isSuccess } = useAddApplicantToBlocklistMutation({
    applicantId,
  })

  return (
    <ApplicantActionModalWrapper<AddToBlocklistData>
      heading={t('action.removeFromBlocklist')}
      iconName="mdiCancel"
      isProgress={isLoading}
      isSuccess={isSuccess}
      onClose={handleClose}
      onSubmit={mutate}
      submitText={t('action.removeFromBlocklist')}
      confirmation={{
        description: t('action.description.areYouSureToRemoveBlocklist'),
        confirmText: t('action.confirm.removeFromBlocklist'),
      }}
      defaultValues={{ flag: 'false_positive', flagType: 'blacklist' }}
      renderForm={({ control }) => (
        <>
          <span
            className="font-bold"
            data-qa={removeFromBlocklistQa.description}
          >
            {t('action.description.removeFromBlocklist')}
          </span>

          <span>{t('action.description.noLongerBeBlocklistFlagged')}</span>

          <TextAreaFormField
            label={t('action.fields.comment')}
            placeholder={t('action.fields.commentPlaceholder')}
            control={control}
            name="comment"
            rules={{ required: true }}
          />
        </>
      )}
    />
  )
}
