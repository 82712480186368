import React, {
  ChangeEvent,
  ReactElement,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { Link } from 'react-router-dom'

import { FrankieButton, FrankieLoader, FrankieTextField } from 'frankify/src'

import { RoleRemoveFromUsers } from 'fwidgets/roles-to-users'

import { RoleTable, useRoleListQuery } from 'features/role-management'
import { RoleManagement } from 'features/role-management-f2'
import { useUserListQuery } from 'features/user-management'

import { OrganisationId } from 'entities/organisation'
import { IRole, PermissionTypes, RoleId } from 'entities/role'
import {
  useHasFeatureFlag,
  useHasPermission,
  useSessionQuery,
} from 'entities/session'

import { useI18n } from 'shared/i18n'
import { useOverlay } from 'shared/overlay'

import { settingsRoleListQa } from '../../qa/settings-role-list.qa'
import { SETTINGS_ROLE_LIST_PAGE_KEY } from '../../settings-role-list.key'

type Props = {
  newRolePath: string
  getRoleViewPath: (id: RoleId) => string
  getRoleEditPath: (id: RoleId) => string
}

export function SettingsRoleListPageDefault({
  newRolePath,
  getRoleViewPath,
  getRoleEditPath,
}: Props): ReactElement {
  const t = useI18n([SETTINGS_ROLE_LIST_PAGE_KEY])
  const [search, setSearch] = useState<string>('')
  const [rolesSearched, setRolesSearched] = useState<IRole[]>([])
  const [createOverlay, closeOverlay] = useOverlay()
  const {
    canDeleteRole,
    canCreateRole,
    canViewRole,
    canEditRole,
    canFetchRoleList,
    canFetchUserList,
  } = useHasPermission({
    canDeleteRole: PermissionTypes.RoleDeleteFunction,
    canCreateRole: PermissionTypes.RoleNewPage,
    canViewRole: PermissionTypes.RolePermissionMappingPage,
    canEditRole: PermissionTypes.RoleEditPage,
    canFetchRoleList: PermissionTypes.RoleListFunction,
    canFetchUserList: PermissionTypes.UserList,
  })

  const { data: session, isLoading: isSessionLoading } = useSessionQuery()
  const { data: roleList, isLoading: isRoleListLoading } = useRoleListQuery({
    canFetchRoleList,
  })
  // fetch user list on background in case user opens RoleRemoveFromUsers modal (deletes a role)
  useUserListQuery({ canFetchUserList })

  const isLoading = isRoleListLoading || isSessionLoading

  const organisationMap = useMemo<Record<OrganisationId, string>>(() => {
    const map: Record<OrganisationId, string> = {}
    if (session?.organisation) {
      map[session.organisation.id] = session.organisation.name
      session.organisation.children?.forEach(child => {
        map[child.id] = child.name
      })
    }
    return map
  }, [session])

  useEffect(() => {
    if (roleList) {
      setRolesSearched(
        roleList.filter(role =>
          role.role.toLowerCase().includes(search.toLowerCase()),
        ),
      )
    }
  }, [search, roleList])

  const handleChangeSearch = (event: ChangeEvent<HTMLInputElement>) =>
    setSearch(event.target.value)

  const handleDeleteRole = (id: RoleId) =>
    createOverlay(
      <div className="pr-2 mr-2 max-h-[calc(100vh-145px)] overflow-y-auto scrollbar-gutter scrollbar">
        <RoleRemoveFromUsers
          roleId={id}
          onSuccess={closeOverlay}
          onCancel={closeOverlay}
        />
      </div>,
      {
        className: 'p-6 max-w-[448px] pb-5 pr-0',
      },
    )

  return (
    <div className="p-8 pr-0">
      <div className="mb-4 flex w-full flex-initial flex-row justify-between items-center pr-8">
        <div className="w-[326px]">
          <FrankieTextField
            size="sm"
            type="search"
            value={search}
            onChange={handleChangeSearch}
            testId={{ input: settingsRoleListQa.searchRoleInput }}
            isSearchIcon
            placeholder={t('input.searchRole')}
          />
        </div>
        {canCreateRole && (
          <Link data-qa={settingsRoleListQa.createRoleCta} to={newRolePath}>
            <FrankieButton notAButton size="sm" intent="secondary">
              {t('cta.createRole')}
            </FrankieButton>
          </Link>
        )}
      </div>
      <FrankieLoader loading={isLoading}>
        <RoleTable
          organisationId={session?.organisation.id}
          getRoleViewPath={getRoleViewPath}
          getRoleEditPath={getRoleEditPath}
          roles={rolesSearched}
          organisationMap={organisationMap}
          onDeleteRole={handleDeleteRole}
          canDeleteRole={canDeleteRole}
          canViewRole={canViewRole}
          canEditRole={canEditRole}
        />
      </FrankieLoader>
    </div>
  )
}

export function SettingsRoleListPage(props: Props) {
  const { isFrankie2 } = useHasFeatureFlag({
    isFrankie2: 'frankie2customer',
  })

  // if feature flag is enabled, show the new role management page
  if (isFrankie2) {
    return (
      <RoleManagement className="p-8 max-h-[calc(100vh-185px)] overflow-y-auto" />
    )
  }

  return <SettingsRoleListPageDefault {...props} />
}
