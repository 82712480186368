import React, { Fragment, useMemo } from 'react'

import { FrankieIcon, FrankieTooltip } from 'frankify/src'

import { ApplicantId, useApplicantDataQuery } from 'entities/applicant'

import { useI18n, I18nKeys } from 'shared/i18n'
import { isNonEmptyObject } from 'shared/typescript'

import { APPLICANT_BIOMETRICS_OCR_KEY } from '../../applicant-biometrics-ocr.key'
import { applicantBiometricsOcrEn } from '../../locale/applicant-biometrics-ocr.en'
import {
  OcrItems,
  convertIDTypeToHumanReadable,
  formatOcrDate,
  reportStatus,
} from '../../model/applicant-biometrics-ocr.model'
import { useApplicantBiometricsOcrDocumentState } from '../../state/applicant-biometrics-ocr-data-state/applicant-biometrics-ocr-data.state'
import { BiometricAccordionHeader } from '../applicant-biometrics-accordion/applicant-biometrics-accordion'
import { BiometricDocumentView } from '../applicant-biometrics-ocr-helper/applicant-biometrics-ocr-helper'

type TitleEn = I18nKeys<(typeof applicantBiometricsOcrEn)['ocrField']>

type FieldViewProps = {
  titleEn: TitleEn
  value: string
  isMismatch?: boolean
  className?: string
  bgClass: string
}
function FieldView({
  titleEn,
  value,
  isMismatch = false,
  className = '',
  bgClass,
}: FieldViewProps) {
  const t = useI18n([APPLICANT_BIOMETRICS_OCR_KEY], {
    keys: applicantBiometricsOcrEn,
  })

  return (
    <div
      className={`${className} ${
        isMismatch ? 'bg-tertiary-amber-50' : bgClass
      } px-4 py-2`}
    >
      <div className="flex items-center gap-1">
        <span className="text-tertiary-grey-600 font-medium text-xs">
          {t(`ocrField.${titleEn}`)}
        </span>
        {isMismatch && (
          <FrankieTooltip
            position="right"
            title={t('ocrMismatch')}
            className="min-w-[280px]"
          >
            <span className="text-tertiary-amber-500">
              <FrankieIcon size="xs" name="mdiAlertCircle" className="" />
            </span>
          </FrankieTooltip>
        )}
      </div>
      <div className="text-tertiary-grey-900 font-semibold">{value}</div>
    </div>
  )
}

type FieldSet = {
  titleEn: TitleEn
  customerReviewedData: string
  ocrExtractedData: string
  field: keyof OcrItems | `customer_info_${string}`
}

type Props = {
  applicantId: ApplicantId
  index: number
  onManualOverride?: (status: { [key: string]: string }) => void
}

export function ApplicantOcrReportPanel({
  applicantId,
  index,
  onManualOverride,
}: Props) {
  const t = useI18n([APPLICANT_BIOMETRICS_OCR_KEY], {
    keys: applicantBiometricsOcrEn,
  })

  const {
    data: { documentBase64Scan, documentDetailsData },
    loadingScan,
  } = useApplicantBiometricsOcrDocumentState({ applicantId, index })

  const { data: applicantData } = useApplicantDataQuery({ applicantId })
  const applicantDetails = applicantData?.applicantDetails

  const ocrItems = documentDetailsData?.ocrItems

  const applicantInfo = useMemo(
    () => ({
      firstName: applicantDetails?.name.givenName || '-',
      middleName: applicantDetails?.name.middleName || '-',
      familyName: applicantDetails?.name.familyName || '-',
      nativeFirstName: applicantDetails?.extraData?.home_country_givenname,
      nativeLastName: applicantDetails?.extraData?.home_country_familyname,
      dob: formatOcrDate(applicantDetails?.dateOfBirth),
      buddhistDOB:
        applicantDetails?.extraData?.buddhist_solar_dob === 'true'
          ? applicantDetails.dateOfBirth
          : null,
      address: applicantDetails?.addresses?.[0]?.longForm || '-',
    }),
    [applicantDetails],
  )

  // eslint-disable-next-line complexity
  const fieldsSet = useMemo(() => {
    const fields: FieldSet[] = [
      {
        titleEn: 'firstName',
        customerReviewedData: applicantInfo.firstName,
        ocrExtractedData: ocrItems?.ocr_scanned_first_name || '-',
        field: 'ocr_scanned_first_name',
      },
      {
        titleEn: 'middleName',
        customerReviewedData: applicantInfo.middleName,
        ocrExtractedData: ocrItems?.ocr_scanned_middle_name || '-',
        field: 'ocr_scanned_middle_name',
      },
      {
        titleEn: 'lastName',
        customerReviewedData: applicantInfo.familyName,
        ocrExtractedData: ocrItems?.ocr_scanned_last_name || '-',
        field: 'ocr_scanned_last_name',
      },
      {
        titleEn: 'dob',
        customerReviewedData: applicantInfo.dob,
        ocrExtractedData: formatOcrDate(ocrItems?.ocr_scanned_date_of_birth),
        field: 'ocr_scanned_date_of_birth',
      },
    ]

    if (applicantInfo.nativeFirstName) {
      fields.push({
        titleEn: 'nativeFirstName',
        customerReviewedData: applicantInfo.nativeFirstName || '-',
        ocrExtractedData: '-',
        field: 'customer_info_native_first_name',
      })
      fields.push({
        titleEn: 'nativeLastName',
        customerReviewedData: applicantInfo.nativeLastName || '-',
        ocrExtractedData: '-',
        field: 'customer_info_native_last_name',
      })
    }

    if (applicantInfo.buddhistDOB) {
      fields.push({
        titleEn: 'buddhistDOB',
        customerReviewedData: applicantInfo.buddhistDOB || '-',
        ocrExtractedData: '-',
        field: 'customer_info_buddhist_dob',
      })
    }

    fields.push({
      titleEn: 'address',
      customerReviewedData: applicantInfo.address,
      ocrExtractedData: ocrItems?.ocr_scanned_address || '-',
      field: 'ocr_scanned_address',
    })

    const ocrDocumentId = documentDetailsData?.documentId || ''
    const documentDetails = applicantData?.documents.find(
      ({ documentId }) => documentId === ocrDocumentId,
    )

    const idType = convertIDTypeToHumanReadable(documentDetails?.idType)

    const isDL = idType === 'Drivers Licence'
    const isPassport = idType === 'Passport'

    let documentNumberTitleEn: TitleEn = 'documentNumber'
    if (isDL) documentNumberTitleEn = 'licenseNumber'
    if (isPassport) documentNumberTitleEn = 'passportNumber'

    fields.push(
      {
        titleEn: 'documentType',
        customerReviewedData: idType || '-',
        ocrExtractedData: ocrItems?.ocr_scanned_document_type || '-',
        field: 'ocr_scanned_document_type',
      },
      {
        titleEn: documentNumberTitleEn,
        customerReviewedData: documentDetails?.idNumber || '-',
        ocrExtractedData: ocrItems?.ocr_scanned_document_number || '-',
        field: 'ocr_scanned_document_number',
      },
    )

    if (
      isDL &&
      (documentDetails?.country === 'AUS' || documentDetails?.country === 'NZL')
    ) {
      fields.push({
        titleEn: 'cardNumber',
        customerReviewedData: documentDetails.extraData?.document_number || '-',
        ocrExtractedData: ocrItems?.ocr_scanned_id_number || '-',
        field: 'ocr_scanned_id_number',
      })
    }

    fields.push(
      {
        titleEn: 'issuingState',
        customerReviewedData: documentDetails?.region || '-',
        ocrExtractedData: ocrItems?.ocr_scanned_issuing_state || '-',
        field: 'ocr_scanned_issuing_state',
      },
      {
        titleEn: 'issuingCountry',
        customerReviewedData: documentDetails?.country || '-',
        ocrExtractedData: ocrItems?.ocr_scanned_issuing_country || '-',
        field: 'ocr_scanned_issuing_country',
      },
      {
        titleEn: 'dateOfIssue',
        customerReviewedData: formatOcrDate(
          ocrItems?.ocr_scanned_date_of_issue,
        ),
        ocrExtractedData: formatOcrDate(ocrItems?.ocr_scanned_date_of_issue),
        field: 'ocr_scanned_date_of_issue',
      },
      {
        titleEn: 'dateOfExpiry',
        customerReviewedData: formatOcrDate(documentDetails?.idExpiry),
        ocrExtractedData: formatOcrDate(ocrItems?.ocr_scanned_date_of_expiry),
        field: 'ocr_scanned_date_of_expiry',
      },
    )

    if (documentDetails?.extraData?.laser_code) {
      fields.push({
        titleEn: 'laserCode',
        customerReviewedData: documentDetails.extraData.laser_code,
        ocrExtractedData: '-',
        field: 'customer_info_laser_code',
      })
    }

    return fields
  }, [
    applicantData?.documents,
    applicantInfo,
    documentDetailsData?.documentId,
    ocrItems,
  ])

  const hasOcrItem = isNonEmptyObject(ocrItems)

  // No data check
  if (
    !documentDetailsData ||
    (!hasOcrItem && !documentDetailsData.hasSimplifiedResult)
  )
    return null

  return (
    <BiometricAccordionHeader
      checkResult={documentDetailsData.result}
      reportStatus={t(
        reportStatus(
          documentDetailsData.checkStatus,
          documentDetailsData.hasSimplifiedResult,
        ),
      )}
      className="min-w-[934px]"
      checkDate={documentDetailsData.checkDate}
      detailType="ocr"
      applicantId={applicantId}
      index={index}
      onManualOverride={onManualOverride}
    >
      <div className="flex gap-4 py-2">
        <div className="min-w-[300px]">
          {!hasOcrItem && <div>{t('checkNotCompleted')}</div>}

          <BiometricDocumentView
            fileName={`${t('ocrDocument')} - ${t('front')}`}
            imageScan={documentBase64Scan.front}
            className="max-w-[300px] mb-6"
            loading={loadingScan}
          />
          <BiometricDocumentView
            fileName={`${t('ocrDocument')} - ${t('back')}`}
            imageScan={documentBase64Scan.back}
            className="max-w-[300px]"
          />
        </div>

        {!documentDetailsData.hasSimplifiedResult && (
          <div className="flex flex-wrap">
            <div className="px-4 pb-1 basis-1/2 bg-mono-white font-bold text-tertiary-grey-900 border-b border-mono-20">
              {t('ocrExtractedData')}
            </div>
            <div className="px-4 pb-1 basis-1/2 bg-mono-10 font-bold text-tertiary-grey-900 border-b border-mono-20">
              {t('customerReview')}
            </div>
            {fieldsSet.map(
              ({ titleEn, customerReviewedData, ocrExtractedData, field }) => {
                const isMismatch =
                  documentDetailsData.mismatchFields.includes(field)

                return (
                  <Fragment key={field}>
                    <FieldView
                      titleEn={titleEn}
                      value={ocrExtractedData}
                      isMismatch={isMismatch}
                      bgClass="bg-mono-white"
                      className="basis-1/2"
                    />
                    <FieldView
                      titleEn={titleEn}
                      value={customerReviewedData}
                      isMismatch={isMismatch}
                      bgClass="bg-mono-10"
                      className="basis-1/2"
                    />
                  </Fragment>
                )
              },
            )}
          </div>
        )}
      </div>
    </BiometricAccordionHeader>
  )
}
