import React from 'react'

import { FrankieIcon } from 'frankify/src'

export function IndicatorCell({
  text,
  iconClassName,
  textClassName,
}: {
  text: string
  iconClassName: string
  textClassName: string
}) {
  return (
    <div className="w-full h-full whitespace-normal px-4 py-3 flex items-center">
      <FrankieIcon name="mdiCircleMedium" className={iconClassName} />
      <span className={`text-xs font-semibold ${textClassName}`}>{text}</span>
    </div>
  )
}
