import React from 'react'

import classNames from 'classnames'

import { FrankieButton } from 'frankify/src'

type Props = {
  gallery: string[]
  selected: number
  onChange: (index: number) => void
  className?: string
  imageClassName?: string
}

function ImageGalleryComp({
  gallery,
  onChange,
  selected,
  className,
  imageClassName,
}: Props) {
  return (
    <div
      className={classNames(
        'flex gap-1 pb-2 w-auto scrollbar overflow-x-scroll',
        className,
      )}
    >
      {gallery.map((image, index) => {
        const isSel = index === selected
        const isVideo = ['MP4'].includes(
          image.split('.').at(-1)?.toUpperCase() || '',
        )
        return (
          <FrankieButton
            noStyles
            className={classNames(
              'min-w-[70px]  max-w-[70px] rounded-sm overflow-hidden relative ',
            )}
            onClick={() => onChange(index)}
            key={image}
          >
            {isSel && (
              <div className="border-2 w-full h-full border-primary-600 rounded-sm border-solid absolute" />
            )}
            {!isVideo ? (
              <img
                src={image}
                alt="gallery"
                className={classNames(
                  'w-full aspect-square object-cover',
                  imageClassName,
                )}
              />
            ) : (
              // eslint-disable-next-line jsx-a11y/media-has-caption
              <video
                src={image}
                className={classNames(
                  'w-full aspect-square object-cover',
                  imageClassName,
                )}
              />
            )}
          </FrankieButton>
        )
      })}
    </div>
  )
}

const ImageGallery = React.memo(ImageGalleryComp)

export { ImageGallery }
