import React, { FormEvent, forwardRef, useState } from 'react'

import { FrankieTextAreaStyle } from './text-area.theme'
import { IFrankieTextareaProps } from './text-area.types'
import { FrankieIcon } from '../icon'

export const FrankieTextarea = forwardRef(
  // eslint-disable-next-line complexity, prefer-arrow-callback
  function FrankieTextField(
    {
      className = '',
      inputClassName = '',
      value,
      onChange,
      onClick,
      onFocus,
      onBlur,
      placeholder,
      disabled,
      error = false,
      errorText = '',
      supportingText = '',
      counter,
      maxLength,
      testId = {
        container: '',
        input: '',
        label: '',
        supportingText: '',
        counter: '',
      },
    }: IFrankieTextareaProps,
    ref: React.ForwardedRef<HTMLInputElement>,
  ): JSX.Element {
    const [inputLength, setInputLength] = useState(0)

    const handleInput = (e: FormEvent<HTMLTextAreaElement>) => {
      setInputLength(e.currentTarget.value.length)
    }
    return (
      <div className={`w-full ${className}`} data-qa={testId.container}>
        <textarea
          onInput={handleInput}
          placeholder={placeholder}
          className={FrankieTextAreaStyle({
            className: inputClassName,
            inputError: error,
          })}
          onClick={onClick}
          onFocus={onFocus}
          onBlur={onBlur}
          onChange={onChange}
          value={value}
          data-qa={testId.input}
          disabled={disabled}
          maxLength={maxLength}
        />
        <div className="flex">
          {supportingText || errorText ? (
            <div className=" text-mono-70 text-sm">
              <div
                className={`flex flex-initial items-start ${
                  error ? 'text-tertiary-red-600' : ''
                }`}
              >
                <div className="p-0.5 mr-2">
                  <FrankieIcon
                    name={
                      error ? 'mdiAlertCircleOutline' : 'mdiInformationOutline'
                    }
                    size="sm"
                  />
                </div>
                <div data-qa={testId.supportingText}>
                  {error ? errorText : supportingText}
                </div>
              </div>
            </div>
          ) : (
            <div
              className="flex flex-initial tertiary-grey-500"
              data-qa={testId.counter}
            >
              {typeof counter === 'number' && counter > 0 ? (
                <div
                  className={
                    inputLength > counter ? 'text-tertiary-red-700' : ''
                  }
                >
                  {counter - inputLength} characters left
                </div>
              ) : (
                counter
              )}
            </div>
          )}
        </div>
      </div>
    )
  },
)
