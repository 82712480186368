import { PROFILES_TABLE_KEY as PTK } from '../locale/profiles-table.en'

const loadingOverlay = 'loading-overlay'
export const loadingOverlayQa = {
  container: `${PTK}-${loadingOverlay}-container`,
  loader: `${PTK}-${loadingOverlay}-loader`,
  filterWrapper: `${PTK}-${loadingOverlay}-filter-wrapper`,
} satisfies Record<string, `${typeof PTK}-${typeof loadingOverlay}-${string}`>

const noRowsPlaceholder = 'no-rows-placeholder'
export const noRowsPlaceholderQa = {
  container: `${PTK}-${noRowsPlaceholder}-container`,
  icon: `${PTK}-${noRowsPlaceholder}-icon`,
  noMatchTitle: `${PTK}-${noRowsPlaceholder}-noMatchTitle`,
  noMatchSubtitle: `${PTK}-${noRowsPlaceholder}-noMatchSubtitle`,
  giveFeedbackBtn: `${PTK}-${noRowsPlaceholder}-giveFeedbackBtn`,
} satisfies Record<
  string,
  `${typeof PTK}-${typeof noRowsPlaceholder}-${string}`
>

const dateGridPro = 'Data-Grid-Pro'
export const profilesTableQa = {
  container: `${PTK}-${dateGridPro}-container`,
} satisfies Record<string, `${typeof PTK}-${typeof dateGridPro}-${string}`>
