import { useMutation, useQueryClient } from '@tanstack/react-query'

import { NotificationSettingsConfigKey } from 'entities/user'

import { useI18n } from 'shared/i18n'
import { notification } from 'shared/notification'

import { useIntegrationQuery } from './slack-integration.query'
import { organisationSettingsApi } from '../../api/organisation-settings.api'
import { IntegrationSetting } from '../../model/organisation-settings.model'
import { ORGANISATION_SETTINGS_KEY } from '../../organisation-settings.key'

export const useSlackIntegrationMutation = () => {
  const { refetch } = useIntegrationQuery()
  const t = useI18n([ORGANISATION_SETTINGS_KEY])

  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (body: IntegrationSetting) => {
      const { data } = await organisationSettingsApi.updateIntegrationSettings(
        body,
      )
      return data
    },

    onSuccess: () => {
      void queryClient.invalidateQueries(NotificationSettingsConfigKey)
      void refetch()
      notification.success(t('integration.connect.success'))
    },
    onError: () => {
      notification.error(t('integration.connect.fail'))
    },
  })
}
