import { useMutation } from '@tanstack/react-query'

import { applicantApi } from '../../api/applicant.api'

type Args = {
  applicantId: string
  status: string
  comment: string
}

export const useApplicantUpdateStatus = () =>
  useMutation({
    mutationFn: ({ applicantId, status, comment }: Args) =>
      applicantApi.updateApplicantStatus(applicantId, status, comment),
  })
