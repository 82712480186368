/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react'

import { FrankieTableHeaderCellSortValues } from 'frankify/src'

import { OrganisationId } from 'entities/organisation'
import {
  RoleTableRow,
  RoleTableRowHeader,
  RoleSortByTypes,
  IRole,
  RoleId,
} from 'entities/role'

import { sortArrOfObjs } from 'shared/array'

import { roleManagementQa } from '../../qa/role-management.qa'

type Sorts = Record<RoleSortByTypes, FrankieTableHeaderCellSortValues>

const defaultSorts: Sorts = {
  [RoleSortByTypes.Name]: 'off',
  [RoleSortByTypes.OrganisationName]: 'off',
  [RoleSortByTypes.CreatedDate]: 'off',
}

type Props = {
  organisationId?: OrganisationId
  roles: IRole[]
  getRoleViewPath: (id: RoleId) => string
  getRoleEditPath: (id: RoleId) => string
  onDeleteRole: (id: RoleId) => void
  organisationMap: Record<OrganisationId, string>
  canDeleteRole: boolean
  canViewRole: boolean
  canEditRole: boolean
}

export function RoleTable({
  organisationId,
  roles,
  getRoleEditPath,
  getRoleViewPath,
  organisationMap,
  onDeleteRole,
  canDeleteRole,
  canViewRole,
  canEditRole,
}: Props) {
  const [sorts, setSorts] = useState<Sorts>(defaultSorts)
  const [rolesSorted, setRolesSorted] = useState<IRole[]>(roles)

  useEffect(() => {
    if (sorts[RoleSortByTypes.Name] !== 'off') {
      setRolesSorted(
        sortArrOfObjs(roles, sorts[RoleSortByTypes.Name], role => role.role),
      )
      return
    }
    if (sorts[RoleSortByTypes.OrganisationName] !== 'off') {
      setRolesSorted(
        sortArrOfObjs(
          roles,
          sorts[RoleSortByTypes.OrganisationName],
          role => organisationMap[role.organisationId || -1] || '',
        ),
      )
      return
    }
    if (sorts[RoleSortByTypes.CreatedDate] !== 'off') {
      setRolesSorted(
        sortArrOfObjs(
          roles,
          sorts[RoleSortByTypes.CreatedDate],
          role => role.createdAt.date,
        ),
      )
      return
    }
    setRolesSorted(
      // eslint-disable-next-line complexity
      [...roles].sort((a: IRole, b: IRole) => {
        // sort global roles by name
        if (a.isGlobalRole && b.isGlobalRole) {
          return a.role > b.role ? 1 : a.role < b.role ? -1 : 0
        }
        // sort other roles by date
        if (!a.isGlobalRole && !b.isGlobalRole) {
          return a.createdAt.date > b.createdAt.date
            ? 1
            : a.createdAt.date < b.createdAt.date
            ? -1
            : 0
        }
        // sort global roles first
        return a.isGlobalRole ? -1 : b.isGlobalRole ? 1 : 0
      }),
    )
  }, [sorts, roles, organisationMap])

  const handleChangeSort = (sortBy: RoleSortByTypes) => () =>
    // 1. turn off all sorts
    // 2. if sorting was disabled -- set asc, otherwise toggle asc/desc
    setSorts({
      ...defaultSorts,
      [sortBy]: sorts[sortBy] === 'asc' ? 'desc' : 'asc',
    })

  const handleRemove = (id: RoleId) => () => onDeleteRole(id)

  return (
    <div>
      <div className="pr-8">
        <RoleTableRowHeader sorts={sorts} onChangeSort={handleChangeSort} />
      </div>
      <div
        data-qa={roleManagementQa.roleTable_roleList}
        className="h-[calc(100vh-364px)] pb-20 pr-4 mr-2 overflow-y-auto scrollbar scrollbar-gutter"
      >
        {rolesSorted.map(role => (
          <RoleTableRow
            key={role.id}
            name={role.role}
            isChildOrganisation={role.organisationId !== organisationId}
            isGlobal={role.isGlobalRole}
            description={role.description || ''}
            createdBy={organisationMap[role.organisationId || -1] || '-'}
            createdDate={role.createdAt.date}
            linkTo={getRoleViewPath(role.id)}
            linkToEdit={getRoleEditPath(role.id)}
            onRemove={handleRemove(role.id)}
            canRemove={canDeleteRole}
            canEdit={canEditRole}
            canView={canViewRole}
          />
        ))}
      </div>
    </div>
  )
}
