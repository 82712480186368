import React, { useMemo, useState } from 'react'

import { FrankieButton } from 'frankify/src'

import { DocumentInformation } from 'entities/entity'

import { FrankieUtilityImg } from 'shared/FrankieImg'
import { DateFormatTypes, formatDate } from 'shared/date-time'
import { DocumentImg } from 'shared/documentImg'
import { convertDataURItoBlob, makeDataURL } from 'shared/file'
import { Show } from 'shared/hoc'
import { useI18n } from 'shared/i18n'
import { useOverlay } from 'shared/overlay'
import { TrackingEventsTypes, trackingManager } from 'shared/tracking'

import { DOCUMENT_F2_KEY, documentF2En } from '../../locale/document-f2.en'
import {
  documentIdTypesOption,
  documentSideOptionMap,
  DocumentSideOptionTypes,
  photoPageIdTypes,
} from '../../model/document-id-types.model'
import { PdfPreview } from '../pdf-preview/pdf-preview'

type Props = {
  document: DocumentInformation
  className?: string
  extraData: { label: string; value?: string }[]
  trackingEvents?: {
    gallery: TrackingEventsTypes[]
    rotate: TrackingEventsTypes
    zoom: TrackingEventsTypes
    show: TrackingEventsTypes
  }
}
export function DocumentViewF2R2({
  document,
  extraData,
  className = '',
  trackingEvents,
}: Props) {
  const [createOverlay] = useOverlay()
  const t = useI18n(DOCUMENT_F2_KEY, { keys: documentF2En })

  const attachments = useMemo(
    () =>
      document.attachments?.reduce(
        (acc, attachment, index) => {
          const multipleAttachments = (document.attachments?.length ?? 0) > 1
          const { mimeType } = attachment
          const fileUrl =
            'base64' in attachment.data ? attachment.data.base64 : ''

          if (!fileUrl || !mimeType) {
            // Safety check
            return acc
          }
          const temp_url = makeDataURL(mimeType, fileUrl)
          const blob = convertDataURItoBlob(temp_url)
          const url = window.URL.createObjectURL(blob)

          const type: DocumentSideOptionTypes =
            (!multipleAttachments &&
              photoPageIdTypes.includes(document.type)) ||
            !attachment.side
              ? DocumentSideOptionTypes.photoPage
              : documentSideOptionMap[attachment.side]

          const isImage = mimeType.split('/').includes('image')
          acc.urls.push(url)
          acc.isImages.push(isImage)
          acc.types.push(type)

          return acc
        },
        { urls: [], isImages: [], types: [] } as {
          types: DocumentSideOptionTypes[]
          urls: string[]
          isImages: boolean[]
        },
      ),
    [document.type, document.attachments],
  )

  const { urls, isImages = [], types = [] } = attachments || {}
  if (!urls?.length) return null

  const handleClick = (idx?: number) => {
    if (trackingEvents?.show) trackingManager.track(trackingEvents.show)

    const documentLabelTKey = documentIdTypesOption.find(
      ({ value }) => value === document.type,
    )?.tKey

    const documentUploadedBy = Math.random() > 0.5 ? 'Ryan Gosling' : ''

    // eslint-disable-next-line react/no-unstable-nested-components
    function OverlayDocComp() {
      const [currentIndex, setCurrentIndex] = useState(idx ?? 0)

      const handleImageChange = (isNext: boolean) => () => {
        if (isNext) {
          setCurrentIndex(prev => {
            const idx = prev + 1
            return idx
          })
        } else {
          setCurrentIndex(prev => {
            const idx = prev - 1
            return idx
          })
        }
      }

      return (
        <div className="flex flex-wrap max-w-[850px] gap-x-6 gap-y-4 min-h-[450px]">
          <div className="text-xl basis-full font-semibold">
            {documentLabelTKey ? t(documentLabelTKey) : document.type}
          </div>
          <div className="flex flex-col gap-3">
            <div className="text-tertiary-grey-500 text-sm leading-5">
              {t('uploadedAtVia', {
                date: formatDate(
                  document.createdAt ?? new Date().toUTCString(),
                  DateFormatTypes.DateNumbersSlash,
                ),
                time: formatDate(
                  document.createdAt ?? new Date().toUTCString(),
                  DateFormatTypes.Time24HoursWithSeconds,
                ),
                via: documentUploadedBy
                  ? t('by', { name: documentUploadedBy })
                  : t('directCapture'),
              })}
            </div>
            <div className="relative">
              <Show>
                <Show.When isTrue={isImages[currentIndex]}>
                  <FrankieUtilityImg
                    gallery={urls?.[currentIndex] ? [urls[currentIndex]] : []}
                    className="!w-[610px] !h-[360px]"
                    trackingEvents={trackingEvents}
                  />
                </Show.When>
                <Show.Else>
                  <div
                    className="!w-[610px] !h-[460px] rounded-md max-h-96 overflow-y-auto border border-tertiary-grey-200"
                    data-hj-suppress
                    title="uploaded-file"
                  >
                    <PdfPreview
                      url={urls?.[currentIndex] ?? ''}
                      pageProps={{
                        width: 592,
                      }}
                    />
                  </div>
                </Show.Else>
              </Show>
              <div className=" border border-tertiary-grey-200 flex bg-mono-white rounded-sm absolute right-6 bottom-2">
                <FrankieButton
                  intent="subtle"
                  disabled={currentIndex === 0}
                  size="xs"
                  className="border-r border-solid border-tertiary-grey-200 rounded-none"
                  onClick={handleImageChange(false)}
                  singleIcon={{ name: 'mdiChevronLeft' }}
                />
                <FrankieButton
                  intent="subtle"
                  size="xs"
                  disabled={
                    currentIndex === (document.attachments?.length ?? 0) - 1
                  }
                  onClick={handleImageChange(true)}
                  singleIcon={{ name: 'mdiChevronRight' }}
                />
              </div>
            </div>
          </div>
          <div className="min-w-[200px] max-w-[200px] max-h-[495px] overflow-y-auto">
            {extraData.map(({ label, value }) => (
              <div key={label} className="mt-3">
                <div className="font-medium text-tertiary-grey-800">
                  {label}
                </div>
                <div
                  data-hj-suppress
                  className="font-normal  text-tertiary-grey-700"
                >
                  {value ?? '-'}
                </div>
              </div>
            ))}
          </div>
        </div>
      )
    }
    createOverlay(<OverlayDocComp />, {
      closeButtonClassName: '!top-6 !right-6',
      className: '!p-6',
    })
  }

  return (
    <DocumentImg
      className={className}
      gallery={urls}
      isImage={isImages}
      thumbnailTypes={types}
      trackingEvents={trackingEvents?.gallery}
      thumbnailTexts={{
        front: t('front'),
        photoPage: t('photoPage'),
        back: t('back'),
      }}
      handleExpand={handleClick}
    />
  )
}
