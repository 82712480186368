import React from 'react'

import { useForm } from 'react-hook-form'

import { FrankieButton, FrankieLoader } from 'frankify/src'

import { useGenerateReportMutation } from 'features/analytics-dashboard/mutation/dashboard.mutation'

import { TextFormField } from 'shared/form'
import { notification } from 'shared/notification'
import { useOverlay } from 'shared/overlay'

import { useAnalyticsDashboardTranslation } from '../../../locale/analytics-dashboard.en'

type CustomerReportForm = {
  date?: [Date, Date]
  password: string
}

export function CustomerReport() {
  const { t } = useAnalyticsDashboardTranslation()
  const {
    control,
    formState: { isValid },
    handleSubmit,
  } = useForm<CustomerReportForm>()

  const [, closeOverlay] = useOverlay()

  const { mutate, isLoading } = useGenerateReportMutation({
    onSuccess: () => {
      notification.success(t('report.success'))
      closeOverlay()
    },
  })

  const onSubmit = (data: CustomerReportForm) => {
    mutate(data)
  }
  return (
    <FrankieLoader loading={isLoading}>
      <form onSubmit={handleSubmit(onSubmit)} className="gap-4 flex flex-col">
        <div className="text-xl text-tertiary-gray-800 font-bold">
          {t('report.customerRecords')}
        </div>
        <div className="text-md font-semibold text-tertiary-700">
          {t('report.customerAlert')}
        </div>
        <div className="text-md  text-tertiary-700">
          {t('report.sensitiveInfo')}
        </div>
        <div className="text-md  text-tertiary-700">
          {t('report.enterPassword')}
        </div>
        <TextFormField
          label={t('report.passwordLabel')}
          control={control}
          name="password"
          type="password"
          rules={{ required: true }}
        />
        {/* Uncomment if date is required in report generation */}
        {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
        <div className="flex flex-col gap-2 [&_.MuiInputBase-input]:text-sm [&_.MuiInputBase-input]:py-3  text-sm font-medium">
          {t('report.dateLabel')}
          <Controller
            control={control}
            name="date"
            render={({ field }) => (
              <>
                <DateRangePicker
                  {...field}
                  slots={{ field: SingleInputDateRangeField }}
                />
                {field.value?.length === 2 &&
                  !Number.isNaN(differenceInCalendarDays(...field.value)) &&
                  t('report.daysSelected', {
                    count:
                      Math.abs(differenceInCalendarDays(...field.value)) + 1,
                  })}
              </>
            )}
            rules={{
              required: true,
              validate: value => value?.filter(Boolean).length === 2,
            }}
          />
        </div>
      </LocalizationProvider> */}
        <div className="flex gap-4 justify-end">
          <FrankieButton onClick={closeOverlay} intent="darkOutline">
            {t('report.cancel')}
          </FrankieButton>
          <FrankieButton type="submit" disabled={!isValid}>
            {t('report.requestReport')}
          </FrankieButton>
        </div>
      </form>
    </FrankieLoader>
  )
}
