import { useCallback, useEffect, useState } from 'react'

import { useQuery } from '@tanstack/react-query'

import { documentApi } from '../api/document.api'
import { scanDateSorter } from '../model/document.model'

type Args = {
  documentId?: string
  fetchNewScan?: boolean
}

export const GetDocumentScanQueryKey = 'get-document-scan'

export const useGetDocumentScan = ({ documentId, fetchNewScan }: Args) => {
  const [docScanId, setDocScanId] = useState<string>()

  const mutation = useQuery({
    enabled: !!docScanId,
    queryKey: [GetDocumentScanQueryKey, docScanId],
    queryFn: async () => {
      const { data } = await documentApi.getDocumentScans(docScanId!)
      return data
    },
    cacheTime: fetchNewScan ? 0 : Infinity,
  })

  useEffect(() => {
    if (documentId) setDocScanId(documentId)
  }, [documentId])

  const getDocumentScan = useCallback(
    (docScanId: string) => setDocScanId(docScanId),
    [],
  )

  return { ...mutation, getDocumentScan }
}

export const DocumentScansQueryKey = 'document-scans-query'

type DocScanArgs = {
  documentId?: string
}

export const useDocumentScansQuery = ({ documentId }: DocScanArgs) =>
  useQuery({
    enabled: !!documentId,
    queryKey: [DocumentScansQueryKey, documentId],
    queryFn: async () => {
      const { data } = await documentApi.getDocumentScans(documentId!)
      return data.sort(scanDateSorter)
    },
  })
