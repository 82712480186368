import {
  APPLICANTS_FILTER_WIDGET_KEY,
  applicantsFilterWidgetEn,
} from 'fwidgets/applicants-filter'
import { GLOBAL_SEARCH_KEY, globalSearchEn } from 'fwidgets/global-search'
import {
  PROFILE_FILTER_WIDGET_KEY,
  profileFilterWidgetEn,
} from 'fwidgets/profiles-filter'
import { ROLES_TO_USERS_KEY, rolesToUsersEn } from 'fwidgets/roles-to-users'
import { SUPRSEND_KEY, suprsendEn } from 'fwidgets/suprsend-notifications'

export const fwidgets = {
  [ROLES_TO_USERS_KEY]: rolesToUsersEn,
  [GLOBAL_SEARCH_KEY]: globalSearchEn,
  [APPLICANTS_FILTER_WIDGET_KEY]: applicantsFilterWidgetEn,
  [PROFILE_FILTER_WIDGET_KEY]: profileFilterWidgetEn,
  [SUPRSEND_KEY]: suprsendEn,
}
