import { GenericAbortSignal } from 'axios'

import { IClient, bffClient } from 'shared/client'

import { AddressSuggestionResponse } from '../model/address.model'
import { Address } from '../model/individual-profile-f2-form.model'

export class AddressF2API {
  client: IClient

  constructor(client: IClient) {
    this.client = client
  }

  async getAddressSuggestion(
    searchTerm: string,
    regions: string[],
    signal?: GenericAbortSignal,
  ) {
    return this.client.get<AddressSuggestionResponse[]>(
      'f2/v1/address/search',
      { params: { searchTerm, regions }, signal },
    )
  }

  async getDetailsAddress(googleAddressId: string) {
    return this.client.get<Address>(`f2/v1/address/${googleAddressId}`)
  }
}

export const addressF2Api = new AddressF2API(bffClient)
