import React from 'react'

import { ApplicantId } from 'entities/applicant'

import { TextAreaFormField } from 'shared/form'
import { useI18n } from 'shared/i18n'
import { Noop } from 'shared/typescript'

import { APPLICANT_GENERAL_INFORMATION_KEY } from '../../../../applicant-general-information.key'
import { applicantGeneralInformationEn } from '../../../../locale/applicant-general-information.en'
import { archiveApplicantQa } from '../../../../qa/applicant-general-information.qa'
import {
  ProfileStatusChangeData,
  useApplicantProfileStatusChangeMutation,
} from '../../../../state/applicant-profile-state-change/applicant-profile-state-change'
import { ApplicantActionModalWrapper } from '../../applicant-action-modal-wrapper/applicant-action-modal-wrapper'

type Props = { applicantId: ApplicantId; handleClose: Noop }

export function ArchiveApplicant({ applicantId, handleClose }: Props) {
  const t = useI18n([APPLICANT_GENERAL_INFORMATION_KEY], {
    keys: applicantGeneralInformationEn,
  })

  const { mutate, isLoading, isSuccess } =
    useApplicantProfileStatusChangeMutation({
      applicantId,
    })

  return (
    <ApplicantActionModalWrapper<ProfileStatusChangeData>
      heading={t('action.heading.archive')}
      iconName="mdiArchiveArrowDownOutline"
      isProgress={isLoading}
      isSuccess={isSuccess}
      onClose={handleClose}
      onSubmit={mutate}
      submitText={t('action.archiveProfile')}
      confirmation={{
        description: t('action.description.areYouSureToArchive'),
        confirmText: t('action.confirm.archive'),
      }}
      defaultValues={{ state: 'archived' }}
      renderForm={({ control }) => (
        <>
          <span className="font-bold" data-qa={archiveApplicantQa.description}>
            {t('action.description.archiveTheProfile')}
          </span>

          <span>{t('action.description.canStillBeFoundInSearch')}</span>

          <TextAreaFormField
            label={t('action.fields.comment')}
            placeholder={t('action.fields.commentPlaceholder')}
            control={control}
            name="comment"
            rules={{ required: true }}
          />
        </>
      )}
    />
  )
}
