import { useEffect, useState } from 'react'

import { useQueryClient } from '@tanstack/react-query'
import { useLocation } from 'react-router-dom'

import { SessionQueryKey } from 'entities/session'

import { getLocalStorageState, StorageKeyTypes } from 'shared/local-storage'

import { useSSSTokenLoginQuery } from '../sso-token-login-data-query/sso-token-login-data.query'

type SSOAssessed =
  | 'SSO-Passed'
  | 'SSO-Failed'
  | 'SSO-Not-Required'
  | 'SSO-Not-Assessed'

export const useSSOlogin = () => {
  const queryClient = useQueryClient()

  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const queryToken = query.get('token')
  const queryEndSession = query.get('end_session')

  const [ssoLoginAssessed, setSSOLoginAssessed] = useState<SSOAssessed>(
    queryToken ? 'SSO-Not-Assessed' : 'SSO-Not-Required',
  )

  const {
    data: pageData,
    isSuccess,
    isError,
  } = useSSSTokenLoginQuery({
    token: queryToken,
  })

  useEffect(() => {
    if (isSuccess) {
      const [, setPageData] = getLocalStorageState(StorageKeyTypes.PageData)
      const [, setToken] = getLocalStorageState(StorageKeyTypes.Token)

      setPageData(pageData)
      setToken(pageData.token)

      queryClient.setQueryData(SessionQueryKey, pageData)

      setSSOLoginAssessed('SSO-Passed')

      // Remove token from query params and refresh page
      const url = new URL(window.location.href)
      url.searchParams.delete('token')
      window.location.href = url.href
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess])

  useEffect(() => {
    if (isError) {
      console.error(
        'Token passed in query parameter seems to be invalid. Clearing any existing session...',
      )
      setSSOLoginAssessed('SSO-Failed')
    }
  }, [isError])

  return {
    ssoLoginAssessed,
    queryEndSession: !!queryEndSession,
  }
}
