import React, { useRef } from 'react'

import cx from 'classnames'
import { useSearchParams } from 'react-router-dom'

import { FrankieButton, FrankieVerticalTabs } from 'frankify/src'

import { workflowEventsEn } from 'features/applicant-workflow-events/locale/workflow-events.en'
import { useActiveNav } from 'features/applicant-workflow-events/state/get-navigation-active-item.state'
import { useNavItems } from 'features/applicant-workflow-events/state/get-navigation-items.state'
import { WORKFLOW_EVENTS_KEY } from 'features/applicant-workflow-events/workflow-events.key'

import { CommentsTypes, useUpdateCommentBoxVisibility } from 'entities/comments'
import { type ServiceProfile } from 'entities/entity'
import { WORKFLOW_EXECUTION_ID_QUERY_PARAM } from 'entities/workflow'

import { useI18n } from 'shared/i18n'

type ApplicantWorkflowEventsNavbarProps = {
  data: ServiceProfile
  entityId: string
  openNavbar: boolean
  onToggleNavbar: (isOpen: boolean) => void
}

export function ApplicantWorkflowEventsNavbar(
  props: ApplicantWorkflowEventsNavbarProps,
) {
  const { data, entityId, openNavbar, onToggleNavbar } = props

  const currentExecutionId = useRef<string | null>(null)
  const [searchParams, setSearchParams] = useSearchParams()
  const t = useI18n([WORKFLOW_EVENTS_KEY], { keys: workflowEventsEn })
  const updateCommentBox = useUpdateCommentBoxVisibility({ entityId })

  const navItems = useNavItems(t, data.workflowSummaries)
  const activeNav = useActiveNav(searchParams, data.workflowSummaries)
  const previousExecutionId = useRef(currentExecutionId.current)

  const handleClickNavItem = (workflowName: string) => {
    const { workflowExecutionId } =
      data.workflowSummaries?.find(
        workflowSummary => workflowSummary.workflowName === workflowName,
      ) ?? {}

    if (workflowExecutionId) {
      const urlSearchParams = new URLSearchParams()
      urlSearchParams.set(
        WORKFLOW_EXECUTION_ID_QUERY_PARAM,
        workflowExecutionId,
      )

      setSearchParams(urlSearchParams)
      currentExecutionId.current = workflowExecutionId
    }

    if (previousExecutionId.current !== currentExecutionId.current) {
      // Force comment box to close every time we switch workflows
      updateCommentBox({
        overrideState: false,
        payload: [
          CommentsTypes.AML,
          CommentsTypes.WORKFLOW,
          CommentsTypes.IDV_DOCUMENT,
          CommentsTypes.IDV_BIOMETRICS,
        ],
      })

      previousExecutionId.current = currentExecutionId.current
    }
  }

  const handleClickToggleButton = () => {
    onToggleNavbar(!openNavbar)
  }

  return (
    <div
      className={cx('sticky top-0 transition-all duration-300 ease-in-out', {
        'w-[200px]': openNavbar,
        'w-[48px]': !openNavbar,
      })}
    >
      <div className="relative mb-4">
        <div
          className={cx(
            'absolute left-0 right-8 transition-all duration-300 transform',
            {
              'opacity-100 translate-x-0': openNavbar,
              'opacity-0 -translate-x-80 w-0 overflow-hidden': !openNavbar,
            },
          )}
        >
          <h1 className="text-xl text-secondary-900 font-bold">
            {t('header.navbar')}
          </h1>
        </div>
        <div className="flex justify-end">
          <FrankieButton
            noStyles
            className={cx(
              'flex grow-0 items-center justify-end p-1.5 text-tertiary-grey-500',
              'w-8 h-8 rounded-sm',
              'active:outline focus-visible:outline',
              'outline-mono-white',
              'focus-visible:outline-3 active:outline-3',
              'hover:bg-tertiary-grey-100 hover:text-tertiary-grey-800',
              'active:bg-primary-50 focus-visible:bg-primary-50',
              'active:text-tertiary-grey-800 focus-visible:text-tertiary-grey-800',
              'transition-all duration-300 transform ease-in-out',
            )}
            singleIcon={{
              name: openNavbar ? 'mdiArrowCollapseLeft' : 'mdiArrowExpandRight',
              size: 'sm',
            }}
            onClick={handleClickToggleButton}
          />
        </div>
      </div>
      <div
        className={cx(
          'transition-all duration-300 transform ease-in-out overflow-hidden',
          {
            'opacity-100 translate-x-0 max-h-[800px]': openNavbar,
            'opacity-0 -translate-x-80 max-h-0': !openNavbar,
          },
        )}
      >
        <FrankieVerticalTabs
          tabItems={navItems}
          activeTab={activeNav}
          onTabChange={handleClickNavItem}
        />
      </div>
    </div>
  )
}
