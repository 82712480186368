import React from 'react'

import { useFormContext } from 'react-hook-form'

import { FrankieIconName, FrankieTabs, FrankieTextField } from 'frankify/src'

import { Show } from 'shared/hoc'
import { useI18n } from 'shared/i18n'

import { APPLICANT_BUSINESS_OWNERSHIP_INTERNATIONAL_KEY } from '../../../applicant-business-ownership-international.key'
import { applicantBusinessOwnershipInternationalEn } from '../../../locale/applicant-business-ownership-international.en'
import {
  AssociateInternationalPartyFormData,
  AssociateNewInternationalPartyType,
} from '../../../model/applicant-business-ownership-international.model'
import { applicantAssociatePartiesQa } from '../../../qa/applicant-business-ownership-international.qa'
import { AssociateNewBusiness } from '../associate-new-business/associate-new-business'

type AssociatePartiesTypeOption = {
  label: string
  icon: FrankieIconName
  value: AssociateNewInternationalPartyType
}

export function AssociateNewParties() {
  const { register, watch, setValue } =
    useFormContext<AssociateInternationalPartyFormData>()

  const t = useI18n([APPLICANT_BUSINESS_OWNERSHIP_INTERNATIONAL_KEY], {
    keys: applicantBusinessOwnershipInternationalEn,
  })

  const partyType = watch('partyType')
  const isPartyTypeIndividual = partyType === 'INDIVIDUAL'

  const associatePartiesTypeOption: AssociatePartiesTypeOption[] = [
    {
      label: t('individual'),
      icon: 'mdiAccountOutline',
      value: 'INDIVIDUAL',
    },
    {
      label: t('business'),
      icon: 'mdiOfficeBuildingOutline',
      value: 'BUSINESS',
    },
  ]

  return (
    <div className="mb-6 overflow-x-hidden">
      <div
        className="w-full mb-6"
        data-qa={applicantAssociatePartiesQa.buttonWrapper}
      >
        <FrankieTabs
          onTabSelect={tab => setValue('partyType', tab)}
          options={associatePartiesTypeOption}
          selectedTab={partyType}
        />
      </div>
      {!isPartyTypeIndividual && (
        <div className="mb-2 text-md text-tertiary-grey-800 font-semibold">
          {t('businessInfo')}
        </div>
      )}
      <Show>
        <Show.When isTrue={isPartyTypeIndividual}>
          <div className="flex flex-wrap items-end gap-x-[2%] gap-y-8">
            <FrankieTextField
              className="basis-[32%]"
              label={t('givenName')}
              {...register('individualData.givenName', {
                required: isPartyTypeIndividual,
              })}
              testId={{ input: applicantAssociatePartiesQa.firstNameField }}
            />

            <FrankieTextField
              className="basis-[32%]"
              label={`${t('middleName')} ${t('optional')}`}
              {...register('individualData.middleName')}
            />

            <FrankieTextField
              className="basis-[32%]"
              label={t('familyName')}
              {...register('individualData.familyName', {
                required: isPartyTypeIndividual,
              })}
              testId={{ input: applicantAssociatePartiesQa.lastNameField }}
            />
          </div>
        </Show.When>

        <Show.When isTrue={!isPartyTypeIndividual}>
          <AssociateNewBusiness />
        </Show.When>
      </Show>
    </div>
  )
}
