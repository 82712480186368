import React, { useRef, useState, KeyboardEvent } from 'react'

import { FrankieTextField } from 'frankify/src'

import { ORGANISATION_SEARCH_KEY } from 'features/organisation-search'

import { useI18n } from 'shared/i18n'

import { useABRSearchQuery } from '../../state/abr-search-australia-query/abr-search-australia.query'

type Props = {
  setDataToDisplay: ({
    organizationName,
    countryName,
  }: {
    organizationName: string
    countryName: string
  }) => void
}

export function AbrSearchInput({ setDataToDisplay }: Props) {
  const t = useI18n([ORGANISATION_SEARCH_KEY])

  const searchFieldRef = useRef<HTMLInputElement>(null)

  const [search, setSearch] = useState('')
  const showClearSearchButton = search.length > 0

  const handleClickClearSearch = () => {
    if (searchFieldRef.current) {
      searchFieldRef.current.focus()
    }
    setSearch('')
  }

  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
  }

  const { refetch: getABROrganisations } = useABRSearchQuery({ search })

  const handleKeyDown = (e: KeyboardEvent<Element>) => {
    if (e.key === 'Enter' && search.length) {
      e.preventDefault()

      setDataToDisplay({
        organizationName: search,
        countryName: 'Australia',
      })

      void getABROrganisations()
    }
  }

  return (
    <>
      <div className="text-tertiary-grey-800 text-xl font-bold">
        {t('title')}
      </div>
      <div className="flex mt-3">
        <div className="relative basis-[584px] shrink min-w-[327px]">
          <FrankieTextField
            ref={searchFieldRef}
            onKeyDown={handleKeyDown}
            size="sm"
            type="search"
            inputClassName="pr-[30px] text-tertiary-grey-700 focus-visible:text-tertiary-grey-700"
            value={search}
            onChange={handleChangeSearch}
            isSearchIcon
            placeholder={t('organisationSelector.placeholderAustralia')}
            closeButton={
              showClearSearchButton
                ? { onClick: handleClickClearSearch }
                : undefined
            }
          />
        </div>
      </div>
    </>
  )
}
