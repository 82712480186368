import { useMutation } from '@tanstack/react-query'

import { ApplicantId } from 'entities/applicant'

import { useI18n } from 'shared/i18n'
import { notification } from 'shared/notification'

import { applicantFraudCheckApi } from '../../api/applicant-fraud-check'
import { APPLICANT_FRAUD_CHECK_KEY } from '../../applicant-fraud-check.key'
import { applicantFraudCheckEn } from '../../locale/applicant-fraud-check.en'
import { FraudStatusChangePayload } from '../../model/applicant-fraud-check.model'
import { useApplicantFraudCheckState } from '../applicant-fraud-check-state/applicant-fraud-check-state'

type Args = {
  applicantId: ApplicantId
}

export const useApplicantFraudStatusChange = ({ applicantId }: Args) => {
  const { refetch } = useApplicantFraudCheckState({ applicantId })
  const t = useI18n([APPLICANT_FRAUD_CHECK_KEY], {
    keys: applicantFraudCheckEn,
  })

  return useMutation({
    mutationFn: (data: FraudStatusChangePayload) =>
      applicantFraudCheckApi.fraudStatusChange(applicantId || '', data),

    onSuccess: () => {
      notification.success(t('success.changeStatus'))
      void refetch()
    },

    onError: () => {
      notification.error(t('error'))
    },
  })
}
