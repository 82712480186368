import { organisationApi } from '../../api/organisation.api'
import { OrganisationStatus } from '../../model/organisation.model'

export const getRequestStatus = async (
  entityId: string,
  requestId: string,
): Promise<OrganisationStatus> => {
  const { data } = await organisationApi.getProfileStatus(entityId, requestId)

  return data
}
