import React, { useEffect, useState } from 'react'

import { useFormContext } from 'react-hook-form'

import {
  FrankieButton,
  FrankieLoader,
  FrankiePopover,
  FrankieTextField,
} from 'frankify/src'

import { CountriesCoveredSelector } from 'entities/country'
import { OrganisationAddress } from 'entities/organisation'

import { Show } from 'shared/hoc'
import { useI18n } from 'shared/i18n'

import { APPLICANT_BUSINESS_OWNERSHIP_INTERNATIONAL_KEY } from '../../../applicant-business-ownership-international.key'
import { applicantBusinessOwnershipInternationalEn } from '../../../locale/applicant-business-ownership-international.en'
import { AssociateInternationalPartyFormData } from '../../../model/applicant-business-ownership-international.model'
import { applicantAssociatePartiesQa } from '../../../qa/applicant-business-ownership-international.qa'
import { useInternationalBusinessSearch } from '../../../state/international-business-search/international-business-search.query'
import { InternationalBusinessSummary } from '../international-business-summary/international-business-summary'

export function AssociateNewBusiness() {
  const t = useI18n([APPLICANT_BUSINESS_OWNERSHIP_INTERNATIONAL_KEY], {
    keys: applicantBusinessOwnershipInternationalEn,
  })

  const [open, setOpen] = useState(false)

  const { setValue, register, watch, unregister } =
    useFormContext<AssociateInternationalPartyFormData>()

  const {
    data: businessData,
    isFetching,
    handleSearchValue,
    selectedCountry,
    handleSelectCountry,
    countriesOptions,
    hasSearchValue,
    searchRef,
    searchValue,
  } = useInternationalBusinessSearch({})

  const searchedOrganisationToken = watch('searchedOrganisationToken')
  const isPartyTypeBusiness = watch('partyType') === 'BUSINESS'

  useEffect(() => {
    register('searchedOrganisationToken', {
      required: isPartyTypeBusiness,
    })

    return () => {
      unregister('searchedOrganisationToken')
    }
  }, [isPartyTypeBusiness, register, unregister])

  const selectedOrganisationInfo = businessData?.matchedOrganizations?.find(
    business => business.organizationToken === searchedOrganisationToken,
  )

  useEffect(() => {
    if (searchedOrganisationToken && selectedOrganisationInfo) {
      const outerCountry =
        selectedOrganisationInfo.organizationCountry ??
        selectedOrganisationInfo.country

      const registrationDetails =
        selectedOrganisationInfo.registrationDetails[0]

      setValue('organisationData', {
        addresses:
          selectedOrganisationInfo.addresses?.map(add => ({
            ...add,
            country: add.country || outerCountry,
          })) ?? ([{ country: outerCountry }] as OrganisationAddress[]),

        businessName:
          registrationDetails.registeredName ??
          selectedOrganisationInfo.name?.name ??
          '',
        businessDescription: registrationDetails.registryDescription,
        businessNumber: registrationDetails.registrationNumber,

        organisationToken: searchedOrganisationToken,
      })
    }
  }, [selectedOrganisationInfo, searchedOrganisationToken, setValue])

  const clearOrganisationData = () => {
    // Clear organisation data
    setValue('organisationData', {
      addresses: [],
      businessName: '',
      businessDescription: '',
      businessNumber: '',
      organisationToken: '',
    })

    // Clearing the searched organisation token
    setValue('searchedOrganisationToken', '', {
      shouldValidate: true,
    })
  }

  // Data clearing on search value change
  useEffect(() => {
    if (searchedOrganisationToken) {
      clearOrganisationData()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue])

  // Data clearing on selected country change
  useEffect(() => {
    if (searchedOrganisationToken) {
      clearOrganisationData()

      // Clearing the search value if the selected country changes
      if (searchRef.current?.value) {
        searchRef.current.value = ''
        handleSearchValue('')
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCountry])

  const handleClear = () => {
    if (searchRef.current?.value) {
      searchRef.current.value = ''
      searchRef.current.focus()
    }
    handleSearchValue('')
  }

  const inputSearchMaxWidth = 'max-w-[590px]'

  return (
    <div
      className="min-h-[37px]"
      data-qa={applicantAssociatePartiesQa.container}
    >
      <div className="absolute flex items-center w-[calc(95vw-43px)]">
        {selectedCountry && (
          <CountriesCoveredSelector
            options={countriesOptions ?? []}
            value={selectedCountry}
            onChange={handleSelectCountry}
            className="min-w-[274px] max-w-[274px]"
          />
        )}
        <FrankiePopover
          open={open}
          onOpenChange={setOpen}
          initialFocus={-1}
          trigger={
            <div className={`w-full ${inputSearchMaxWidth}`}>
              <FrankieTextField
                type="search"
                inputClassName="h-[37px] !rounded-s-[0px]"
                isSearchIcon
                placeholder={t('searchBusiness')}
                onChange={e => {
                  setOpen(true)
                  handleSearchValue(e.target.value)
                }}
                ref={searchRef}
                onClick={() => setOpen(true)}
                testId={{
                  input: applicantAssociatePartiesQa.textField,
                  closeCta: applicantAssociatePartiesQa.clearButton,
                }}
                closeButton={
                  hasSearchValue ? { onClick: handleClear } : undefined
                }
              />
            </div>
          }
        >
          <div
            className={`bg-mono-white shadow-md rounded-sm w-[calc(95vw-322px)] ${inputSearchMaxWidth}`}
          >
            <Show>
              <Show.When isTrue={isFetching}>
                <FrankieLoader
                  label={t('loading.business')}
                  loading
                  className="text-tertiary-grey-800 text-xs font-semibold min-h-[100px]"
                  size="sm"
                />
              </Show.When>

              <Show.When isTrue={!!businessData?.matchedOrganizations?.length}>
                <div className="max-h-[290px] overflow-y-auto w-full">
                  <div className="sticky top-0 flex text-tertiary-grey-500 text-xs font-medium px-2 py-1 border-b border-tertiary-grey-200 bg-mono-white">
                    <div className="basis-[46%] uppercase">{t('name')}</div>
                    <div className="basis-[27%] uppercase">
                      {t('registrationNumber')}
                    </div>
                    <div className="basis-[27%] uppercase">
                      {t('registrationAuthority')}
                    </div>
                  </div>

                  {businessData?.matchedOrganizations?.map(
                    ({ name, registrationDetails, organizationToken }) => (
                      <FrankieButton
                        key={organizationToken}
                        noStyles
                        className={`flex p-2 cursor-pointer text-left w-full text-tertiary-grey-700 ${
                          organizationToken === searchedOrganisationToken
                            ? 'bg-primary-50'
                            : 'hover:bg-tertiary-grey-100'
                        }`}
                        testId={{
                          button: applicantAssociatePartiesQa.organisationToken,
                        }}
                        onClick={() => {
                          setValue(
                            'searchedOrganisationToken',
                            organizationToken,
                            { shouldValidate: true },
                          )
                          if (searchRef.current) {
                            searchRef.current.value =
                              name?.name ??
                              registrationDetails[0].registeredName ??
                              searchRef.current.value
                          }
                          setOpen(false)
                        }}
                      >
                        <div className="basis-[46%]">
                          {name?.name ?? registrationDetails[0].registeredName}
                        </div>
                        <div className="basis-[27%] text-ellipsis overflow-hidden">
                          {registrationDetails[0].registrationNumber}
                        </div>
                        <div className="basis-[27%] text-ellipsis overflow-hidden">
                          {registrationDetails[0].registryDescription}
                        </div>
                      </FrankieButton>
                    ),
                  )}
                </div>
              </Show.When>

              <Show.When isTrue={hasSearchValue}>
                <div className="p-4 font-bold">{t('noResults')}</div>
              </Show.When>
            </Show>
          </div>
        </FrankiePopover>
      </div>

      {selectedOrganisationInfo && (
        <InternationalBusinessSummary
          className="pt-14"
          organisationSummary={selectedOrganisationInfo}
        />
      )}
    </div>
  )
}
