import React, { useMemo } from 'react'

import { ApplicantId } from 'entities/applicant'
import { useHasFeatureFlag } from 'entities/session'

import { DateFormatTypes } from 'shared/date-time'
import { useI18n } from 'shared/i18n'
import { generateRandomKey } from 'shared/string'
import { ExclusiveType } from 'shared/typescript'

import { APPLICANT_PEP_SACTIONS_KEY } from '../../applicant-pep-sactions.key'
import { applicantPepSactionsEn } from '../../locale/applicant-pep-sactions.en'
import {
  fuzzinessPercentage,
  pepSactionsDateFormat,
} from '../../model/applicant-pep-sactions.model'
import { applicantPepSanctionsInfoHeaderQa } from '../../qa/applicant-pep-sactions.qa'
import { useApplicantPepSactionsQuery } from '../../state/applicant-pep-sactions-query/applicant-pep-sactions.query'
import { PepSactionsMatchStrengthBar } from '../applicant-pep-sactions-data-grid-helper/applicant-pep-sactions-data-grid-helper'

type Props = {
  applicantId: ApplicantId
  className?: string
  testId?: { container?: string }
} & ExclusiveType<
  [
    { search: true },
    { searchDetails: true },
    { returnedDetails: true; matchIdx: number },
  ]
>

type FieldsType = {
  label: string
  value: string | null
}

// eslint-disable-next-line complexity
export function ApplicantPepSactionsInfoHeader({
  applicantId,
  search,
  searchDetails,
  returnedDetails,
  matchIdx,
  className = '',
  testId,
}: Props) {
  const t = useI18n(APPLICANT_PEP_SACTIONS_KEY, {
    keys: applicantPepSactionsEn,
  })

  const briefView = searchDetails || returnedDetails

  const { showAMLMatchStrength } = useHasFeatureFlag({
    showAMLMatchStrength: 'showAMLMatchStrength',
  })

  const { data } = useApplicantPepSactionsQuery({ applicantId })

  // eslint-disable-next-line complexity
  const fields = useMemo(() => {
    const matchData = returnedDetails ? data?.matches[matchIdx] : data

    const resultFields: FieldsType[] = [
      {
        label: t('field.name'),
        value: matchData?.name ?? '-',
      },
      {
        label: t('field.yearOfBirth'),
        value:
          !matchData?.dateOfBirth && !returnedDetails
            ? null
            : pepSactionsDateFormat(
                matchData?.dateOfBirth,
                '-',
                DateFormatTypes.YearOnly,
              ),
      },
      {
        label: t('field.countries'),
        value: matchData?.countries?.length
          ? matchData.countries.join(', ')
          : '-',
      },
    ]

    if (search) {
      resultFields.push(
        {
          label: t('field.searchDate'),
          value: pepSactionsDateFormat(data?.searchDate, '-'),
        },
        {
          label: t('field.searchFuzziness'),
          value: fuzzinessPercentage(data?.searchFuzziness),
        },
        {
          label: t('field.vendorCaseId'),
          value: data?.vendorId ?? '-',
        },
      )
    }
    return resultFields.filter(({ value }) => value !== null)
  }, [data, matchIdx, returnedDetails, search, t])

  return (
    <div
      data-qa={testId?.container}
      className={`flex gap-2 ${
        returnedDetails ? '' : 'bg-neutral-20'
      } ${className}`}
    >
      <div className={`mr-3 ${briefView ? 'w-[120px]' : ''}`}>
        {search && (
          <span className="text-xs font-medium text-tertiary-grey-700">
            {t('action.search')}
          </span>
        )}
        {searchDetails && (
          <span className="text-xs font-medium text-tertiary-grey-700">
            {t('field.search')}
          </span>
        )}
        {returnedDetails && (
          <span className="text-tertiary-grey-500">{t('field.returned')}</span>
        )}
      </div>
      {fields.map(({ label, value }, idx) => (
        <div
          key={generateRandomKey(value, idx)}
          className={`flex ${
            briefView ? 'items-center gap-2 max-w-[230px]' : 'flex-col'
          }`}
        >
          <div className="text-tertiary-grey-500">{label}</div>
          <div className="text-tertiary-grey-900">{value}</div>
        </div>
      ))}
      {showAMLMatchStrength && returnedDetails && (
        <div data-qa={applicantPepSanctionsInfoHeaderQa.matchStrength}>
          <div className="text-tertiary-grey-500">
            {t('field.matchStrength')}
          </div>
          <PepSactionsMatchStrengthBar
            strength={data?.matches[matchIdx].matchStrength ?? 0}
          />
        </div>
      )}
    </div>
  )
}
