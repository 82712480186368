export const NUMBERS_LOCALE = 'numbers'

export const numbersEn = {
  number_1: 'one',
  number_2: 'two',
  number_3: 'three',
  number_4: 'four',
  number_5: 'five',
  number_6: 'six',
  number_7: 'seven',
  number_8: 'eight',
  number_9: 'nine',
}
