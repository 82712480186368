import React, { useMemo } from 'react'

import { UseFormReturn } from 'react-hook-form'

import { BLOCKLIST_TABLE_KEY, blocklistTableEn } from 'features/blocklist-table'

import { SelectFormField, TextAreaFormField } from 'shared/form'
import { useI18n } from 'shared/i18n'

import { APPLICANT_BLOCKLISTED_INFO_KEY } from '../../applicant-bllocklisted-info.key'
import { applicantBlocklistedInfoEn } from '../../locale/applicant-blocklisted-info.en'
import {
  BlacklistedFormInputs,
  BlocklistedInputTypes,
} from '../../model/applicant-blocklisted-form.model'
import { getBlocklistReasonOptions } from '../../model/blocklist-reason.model'
import { blockListReasonQa } from '../../qa/applicant-blocklisted-info-qa'

type Props = {
  form: UseFormReturn<BlacklistedFormInputs>
}

export function BlocklistReason({ form }: Props) {
  const { control } = form

  const t = useI18n([APPLICANT_BLOCKLISTED_INFO_KEY], {
    keys: applicantBlocklistedInfoEn,
  })

  const tBlocklistTable = useI18n([BLOCKLIST_TABLE_KEY], {
    keys: blocklistTableEn,
  })

  const blocklistReasonOptions = useMemo(
    () => getBlocklistReasonOptions(tBlocklistTable),
    [tBlocklistTable],
  )

  return (
    <div data-qa={blockListReasonQa.container}>
      <div className="mb-6 max-w-[486px]">
        <SelectFormField
          testId={{ input: blockListReasonQa.select }}
          className="mt-4  mb-2 basis-1/4"
          label={t('form.blocklistReason')}
          control={control}
          name={BlocklistedInputTypes.BlocklistReason}
          rules={{ required: true }}
          options={blocklistReasonOptions}
          placeholder={t('form.blocklistReasonPlaceholder')}
        />
      </div>
      <div className="mb-6 max-w-[486px]">
        <TextAreaFormField
          testId={{ input: blockListReasonQa.textArea }}
          label={t('form.comment')}
          placeholder={t('form.commentPlaceholder')}
          control={control}
          name={BlocklistedInputTypes.Comment}
          rules={{ required: true }}
          showError
        />
      </div>
    </div>
  )
}
