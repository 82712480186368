import { useCallback, useMemo } from 'react'

import {
  BusinessSearchResponse,
  useBusinessSearch,
} from 'entities/organisation'

import { DateFormatTypes, formatDate } from 'shared/date-time'
import { useI18n } from 'shared/i18n'

import { APPLICANT_BUSINESS_OWNERSHIP_KEY } from '../../applicant-business-ownership.key'
import { applicantBusinessOwnershipEn } from '../../locale/applicant-business-ownership.en'

export type ABRBusinessSummaryData = {
  name: string
  mainNames: string[]
  legalNames: string[]
  tradingNames: string[]
  location: string
  type: string
  acn: string
  abn: string
  abnStatus: string
  currentBusiness: string[]
  historicalBusiness: string
}

function getFormattedDate(date?: string): string {
  return date ? formatDate(date, DateFormatTypes.ShortDate) : ''
}

export type UseABRBusinessSummaryProps = {
  searchTerm?: string
}

/**
 * ABR Business Summary
 */
export const useABRBusinessSummary = ({
  searchTerm,
}: UseABRBusinessSummaryProps) => {
  const t = useI18n([APPLICANT_BUSINESS_OWNERSHIP_KEY], {
    keys: applicantBusinessOwnershipEn,
  })

  const {
    data: businessSearchData,
    isFetching: isFetchingBusinessData,
    handleSearchValue,
  } = useBusinessSearch({
    searchTerm,
  })

  const getBusinessNames = useCallback(
    (businessNames?: BusinessSearchResponse['businessNames']) => {
      if (!businessNames?.length)
        return [t('abrSummary.noRegisteredBusinessName')]

      return businessNames.map(business =>
        t('abrSummary.businessName', {
          name: business.name,
          from: getFormattedDate(business.effectiveFrom),
        }),
      )
    },
    [t],
  )

  const getAbnStatus = useCallback(
    (abnStatus?: BusinessSearchResponse['abnStatus']) => {
      if (!abnStatus) return ''
      return t('abrSummary.abnStatus', {
        status: abnStatus.status,
        from: getFormattedDate(abnStatus.effectiveFrom),
      })
    },
    [t],
  )

  // eslint-disable-next-line complexity
  const abrBusinessSummary: ABRBusinessSummaryData = useMemo(() => {
    const searchResult = businessSearchData?.[0]

    const postalCode = searchResult?.postalCode ?? ''

    const state = searchResult?.state ?? ''

    return {
      name: searchResult?.name ?? '',
      mainNames: searchResult?.mainNames ?? [],
      tradingNames: searchResult?.tradingNames ?? [],
      legalNames: searchResult?.legalNames ?? [],
      location: `${postalCode} ${state}`,

      type: searchResult?.type ?? '',

      acn: searchResult?.acn ?? '',
      abn: searchResult?.abn ?? '',

      currentBusiness: getBusinessNames(searchResult?.businessNames),

      abnStatus: getAbnStatus(searchResult?.abnStatus),

      historicalBusiness: searchResult?.historicalBusinessNames.length
        ? t('abrSummary.viewHistoricalBusinessNames')
        : t('abrSummary.noHistoricalBusinessNames'),
    }
  }, [businessSearchData, getAbnStatus, getBusinessNames, t])

  return {
    handleSearchValue,
    abrBusinessSummary,
    historicalBusinessNames: businessSearchData?.[0]?.historicalBusinessNames,
    loadingBusinessSummary: isFetchingBusinessData,
  }
}
