import { ApplicantId } from 'entities/applicant'

import { IClient, bffClient } from 'shared/client'

import {
  AmlCheckResult,
  UpdateMatchPayload,
} from '../model/applicant-pep-sactions.model'

export class ApplicantPepSactionsApi {
  client: IClient

  constructor(client: IClient) {
    this.client = client
  }

  async getMatches(applicantId: ApplicantId) {
    return this.client.get<AmlCheckResult>(
      `data/v1/applicants/${applicantId}/checks/aml/matches`,
    )
  }

  async updateMatchStatus(
    applicantId: ApplicantId,
    checkId: string,
    data: UpdateMatchPayload,
  ) {
    return this.client.put(
      `data/v1/applicants/${applicantId}/checks/aml/${checkId}`,
      data,
    )
  }

  async toggleOngoingAml(applicantId: string, data: { flag: boolean }) {
    return this.client.put(
      `data/v1/applicants/${applicantId}/toggleOngoingAml`,
      data,
      { params: data }, // From vue code - not sure why we are sending data in both param or payload
    )
  }
}

export const applicantPepSactionsApi = new ApplicantPepSactionsApi(bffClient)
