import React from 'react'

import { FrankieIconName } from 'frankify/src'

import { ApplicantResponse, Document } from 'entities/applicant'
import {
  CountryAlpha3Code,
  CountryAlpha3CodeTypes,
  australianState,
  countryList,
} from 'entities/country'
import { DocumentIdTypes } from 'entities/document'

import { isValidDate } from 'shared/date-time'
import { I18nFunction, I18nKeys } from 'shared/i18n'
import { Nullable } from 'shared/typescript'

import { applicantPersonalInfoEn } from '../locale/applicant-personal-info.en'

export type Content = { label: string; value: Nullable<string> | undefined }

export enum AdditionalDocumentsTypes {
  REPORT = 'REPORT',
}

export type DocumentInfoTypes = {
  label: string
  icon: FrankieIconName
  idType: DocumentIdTypes | AdditionalDocumentsTypes
  content: Content[] | []
  isVerified: Nullable<boolean> | undefined
  checkSource: Nullable<unknown[]>
  checkCount: number
  documentId: string
  ocrExpiryDate: Nullable<string>
  docForm?: React.ReactNode
  vevoData: ReturnType<typeof getVevoData>
}

// eslint-disable-next-line complexity
export const getDocIcon = (docType: DocumentIdTypes) => {
  let icon: FrankieIconName = 'mdiCardBulletedOutline'
  switch (docType) {
    case DocumentIdTypes.BIRTH_CERT:
      icon = 'mdiSeal'
      break
    case DocumentIdTypes.CITIZENSHIP:
      icon = 'mdiMapLegend'
      break
    case DocumentIdTypes.IMMIGRATION:
      icon = 'mdiCardTextOutline'
      break
    case DocumentIdTypes.MARRIAGE_CERT:
      icon = 'mdiSetAll'
      break
    case DocumentIdTypes.MEDICARE_CARD:
      icon = 'mdiHospitalBoxOutline'
      break
    case DocumentIdTypes.OTHER:
      icon = 'mdiFileOutline'
      break
    case DocumentIdTypes.PASSPORT:
    case DocumentIdTypes.VISA:
      icon = 'mdiPassport'
      break

    default:
      break
  }
  return icon
}

type ColorName = Record<string, I18nKeys<typeof applicantPersonalInfoEn>>

export const colorName: ColorName = {
  G: 'color.green',
  B: 'color.blue',
  Y: 'color.yellow',
}

const getCountryName = (alpha3Code: CountryAlpha3Code) =>
  countryList.find(country => country.alpha3code === alpha3Code)?.name ??
  alpha3Code

export const getAustralianStateDetails = (code3: string) => {
  const state = australianState.find(s => s.alpha3Code === code3)
  return state?.name ?? code3
}

export const filterData = (document: Document) => {
  if (document.country === 'NZL' && document.idExpiry !== '0001-01-01') {
    return document.idExpiry
  }

  return null
}

// eslint-disable-next-line complexity
export const getDocumentDetails = (
  document: Document,
  t: I18nFunction<typeof applicantPersonalInfoEn>,
  name?: ApplicantResponse['applicantDetails']['name'],
) => {
  const docType = document.idType
  switch (docType) {
    case DocumentIdTypes.DRIVERS_LICENSE: {
      return [
        {
          label: t('documentInfo.labels.state'),
          value: getAustralianStateDetails(document.region as string),
        },
        {
          label: t('documentInfo.labels.licenceNumber'),
          value: document.idNumber,
        },
        {
          label: t('documentInfo.labels.docNumber'),
          value: document.extraData?.document_number
            ? document.extraData.document_number
            : '',
        },
        {
          label: t('documentInfo.labels.licenseVersion'),
          value: document.extraData?.licence_version,
        },
        {
          label: t('documentInfo.labels.homeCountryFullName'),
          value: document.extraData?.home_country_fullname,
        },
      ]
    }
    case DocumentIdTypes.PASSPORT: {
      return [
        {
          label: t('documentInfo.labels.countryIssue'),
          value: getCountryName(document.country as CountryAlpha3Code),
        },
        {
          label: t('documentInfo.labels.passportNumber'),
          value: document.idNumber,
        },
        {
          label: t('documentInfo.labels.dateOfExp'),
          value: filterData(document),
        },
        {
          label:
            document.country === 'IND'
              ? t('documentInfo.labels.fileNumber')
              : t('documentInfo.labels.docNumber'),
          value: document.extraData?.document_number,
        },
      ]
    }
    case DocumentIdTypes.VISA: {
      return [
        {
          label: t('documentInfo.labels.countryIssue'),
          value: getCountryName(document.country as CountryAlpha3Code),
        },
        { label: t('documentInfo.labels.number'), value: document.idNumber },
      ]
    }
    case DocumentIdTypes.MEDICARE_CARD: {
      return [
        {
          label: t('documentInfo.labels.cardColor'),
          value: t(colorName[document.idSubType as keyof ColorName]),
        },
        {
          label: t('documentInfo.labels.cardNumber'),
          value: document.idNumber,
        },
        {
          label: t('documentInfo.labels.positionOnCard'),
          value: document.extraData?.reference,
        },
        {
          label: t('documentInfo.labels.expiryDate'),
          value: document.idExpiry,
        },
        {
          label: t('documentInfo.labels.fullNameOnCard'),
          value: `${name?.givenName ?? ''} ${
            document.extraData?.display_middle_name ?? ''
          } ${name?.familyName ?? ''}`,
        },
      ]
    }
    case DocumentIdTypes.IMMIGRATION: {
      return [
        {
          label: t('documentInfo.labels.cardNumber'),
          value: document.idNumber,
        },
      ]
    }
    case DocumentIdTypes.BIRTH_CERT: {
      return [
        {
          label: t('documentInfo.labels.registrationState'),
          value: getAustralianStateDetails(document.region as string),
        },
        {
          label: t('documentInfo.labels.registrationNumber'),
          value: document.idNumber,
        },
        {
          label: t('documentInfo.labels.registrationDate'),
          value:
            !['ACT', 'NT', 'SA'].includes(document.region!) &&
            document.country === CountryAlpha3CodeTypes.Australia
              ? document.extraData?.registration_date || 'NA'
              : '',
        },
        {
          label: t('documentInfo.labels.dateOfPrint'),
          value: document.extraData?.certificate_date_of_print,
        },
        {
          label: t('documentInfo.labels.registrationName'),
          value: `${document.extraData?.registered_name_first_given ?? ''} ${
            document.extraData?.registered_name_other_given ?? ''
          } ${document.extraData?.registered_name_family ?? ''}`.trim(),
        },
        {
          label: t('documentInfo.labels.certificateNumber'),
          value: document.extraData?.certificate_number,
        },
        {
          label:
            document.country === CountryAlpha3CodeTypes.Australia
              ? t('documentInfo.labels.registrationDistrict')
              : t('documentInfo.labels.placeOfBirth'),
          value: document.extraData?.registration_district,
        },
      ]
    }
    case DocumentIdTypes.CITIZENSHIP: {
      return [
        {
          label: t('documentInfo.labels.stockNumber'),
          value: document.idNumber,
        },
        {
          label: t('documentInfo.labels.dateOfAcquisition'),
          value: document.extraData?.acquisition_date,
        },
        {
          label:
            document.country === CountryAlpha3CodeTypes.Australia
              ? t('documentInfo.labels.primaryCountry')
              : t('documentInfo.labels.countryOfBirth'),
          value: getCountryName(
            document.extraData?.primary_country as CountryAlpha3Code,
          ),
        },
        {
          label: t('documentInfo.labels.secondaryCountry'),
          value: getCountryName(
            document.extraData?.secondary_country as CountryAlpha3Code,
          ),
        },
      ]
    }
    case DocumentIdTypes.NAME_CHANGE: {
      return [
        {
          label: t('documentInfo.labels.registrationState'),
          value: getAustralianStateDetails(document.region as string),
        },
        {
          label: t('documentInfo.labels.registrationNumber'),
          value: document.idNumber,
        },
        {
          label: t('documentInfo.labels.registrationName'),
          value: `${document.extraData?.registered_name_first_given ?? ''} ${
            document.extraData?.bride_name_other_given ?? ''
          } ${document.extraData?.registered_name_family ?? ''}`,
        },
        {
          label: t('documentInfo.labels.dateOfRegistration'),
          value: document.extraData?.certificate_date_of_registration,
        },
        {
          label: t('documentInfo.labels.certificateNumber'),
          value: document.extraData?.certificate_number,
        },
        {
          label: t('documentInfo.labels.dateOfPrint'),
          value: document.extraData?.certificate_date_of_print,
        },
      ]
    }
    case DocumentIdTypes.MARRIAGE_CERT: {
      return [
        {
          label: t('documentInfo.labels.registrationState'),
          value: getAustralianStateDetails(document.region as string),
        },
        {
          label: t('documentInfo.labels.registrationNumber'),
          value: document.idNumber,
        },
        {
          label: t('documentInfo.labels.registrationYear'),
          value: document.extraData?.registration_year,
        },
        {
          label: t('documentInfo.labels.marriageDate'),
          value: document.extraData?.marriage_date,
        },
        {
          label: t('documentInfo.labels.partnerBride'),
          value: `${document.extraData?.bride_name_first_given ?? ''} ${
            document.extraData?.bride_name_other_given ?? ''
          } ${document.extraData?.bride_name_family ?? ''}`,
        },
        {
          label: t('documentInfo.labels.partnerGroom'),
          value: `${document.extraData?.groom_name_first_given ?? ''} ${
            document.extraData?.groom_name_other_given ?? ''
          } ${document.extraData?.groom_name_family ?? ''}`,
        },
        {
          label: t('documentInfo.labels.certificateNumber'),
          value: document.extraData?.certificate_number,
        },
        {
          label: t('documentInfo.labels.dateOfPrint'),
          value: document.extraData?.certificate_date_of_print,
        },
        /** @todo registration year */
      ]
    }
    case DocumentIdTypes.OTHER: {
      return [
        {
          label: t('documentInfo.labels.description'),
          value: document.idNumber,
        },
      ]
    }

    case DocumentIdTypes.TAX_ID: {
      return [
        { label: t('documentInfo.labels.idNumber'), value: document.idNumber },
        {
          label: t('documentInfo.labels.country'),
          value: getCountryName(document.country as CountryAlpha3Code),
        },
        {
          label: t('documentInfo.labels.idSubtype'),
          value: document.idSubType,
        },
      ]
    }
    case DocumentIdTypes.BANK_STATEMENT: {
      return [
        {
          label: t('documentInfo.labels.bankCardNumber'),
          value: document.idNumber,
        },
        {
          label: t('documentInfo.labels.homeCountryFullName'),
          value: document.extraData?.home_country_fullname,
        },
        {
          label: t('documentInfo.labels.nationalIdNumber'),
          value: document.extraData?.national_id,
        },
      ]
    }
    case DocumentIdTypes.NATIONAL_ID: {
      return [
        {
          label: t('documentInfo.labels.idNumber'),
          value: document.idNumber,
        },
        {
          label: t('documentInfo.labels.gender'),
          value: document.extraData?.gender,
        },
        {
          label: t('documentInfo.labels.nationalIdNumber'),
          value: document.extraData?.national_id,
        },
        {
          label: t('documentInfo.labels.homeCountryFullName'),
          value: document.extraData?.home_country_fullname,
        },
        {
          label: t('documentInfo.labels.maternalName'),
          value: document.extraData?.maternal_familyname,
        },
        {
          label: t('documentInfo.labels.paternalName'),
          value: document.extraData?.paternal_familyname,
        },
        {
          label: t('documentInfo.labels.laserCode'),
          value: document.extraData?.laser_code,
        },
      ]
    }
    case DocumentIdTypes.MOTOR_VEHICLE_REGISTRATION: {
      return [
        {
          label: t('documentInfo.labels.licencePlate'),
          value: document.idNumber,
        },
      ]
    }
    case DocumentIdTypes.VEHICLE_REGISTRATION: {
      return [
        {
          label: t('documentInfo.labels.licencePlate'),
          value: document.idNumber,
        },
      ]
    }
    default: {
      return []
    }
  }
}

export const upperCaseFirstLetter = (str: string) => {
  if (!str) return ''
  const strLowerCase = str.toLowerCase()
  return strLowerCase.charAt(0).toUpperCase() + strLowerCase.slice(1)
}

type IssueTypes = {
  isVisaExpired: boolean
  isVisaClassRejected: boolean
}

export const getVisaIssueMessages = (
  issues: IssueTypes,
  t: I18nFunction<typeof applicantPersonalInfoEn>,
) => {
  const { isVisaExpired, isVisaClassRejected } = issues
  const errors = []
  if (isVisaExpired)
    errors.push({
      message: t('documentInfo.error.visaExpired'),
    })
  if (isVisaClassRejected)
    errors.push({
      message: t('documentInfo.error.visaClassRejected'),
    })
  return errors
}

export const getVevoData = (
  document: Document,
  t: I18nFunction<typeof applicantPersonalInfoEn>,
) => {
  const { extraData } = document

  if (!extraData) return null
  const expiryDate = extraData['visa.expiryDate'] || ''

  const isExpiryDateDateFormat = isValidDate(expiryDate) // moment(expiryDate).isValid()
  const resolveExpiryDate = isExpiryDateDateFormat
    ? expiryDate
    : upperCaseFirstLetter(expiryDate)
  if (extraData['visa.class']) {
    return [
      {
        title: t('documentInfo.labels.applicantType'),
        value: extraData['visa.applicant'],
      },
      {
        title: t('documentInfo.labels.grantData'),
        value: extraData['visa.grantDate'],
      },
      {
        title: t('documentInfo.labels.classSubClass'),
        value: `${extraData['visa.class']}/${
          extraData['visa.subclass'] ? extraData['visa.subclass'] : ''
        }`,
      },
      {
        title: t('documentInfo.labels.expiryDate'),
        value: resolveExpiryDate,
        highLight: expiryDate === 'EXPIRED',
      },
      {
        title: t('documentInfo.labels.workingRights'),
        value: extraData['entitlement.summary'],
      },
    ]
  }
  return null
}

export const getDocumentStatus = (
  document: Document,
  isDocumentHasIssues: boolean,
  kycMethod: ApplicantResponse['checkSummary']['kycMethod'],
) => {
  if (kycMethod === 'manual') return null
  if (isDocumentHasIssues) return false
  return document.verified?.[kycMethod as keyof Document['verified']]
}

export const getOverrideDataWhenVisaIsNoMatch = () => ({
  isVerified: false,
  overrideVerifiedText: null,
  visaIssues: [],
  checkSource: null,
  vevoData: null,
})

export const sortDocByVerifiedStatus = (doc: DocumentInfoTypes) => {
  if (doc.isVerified) return -1
  if (doc.isVerified === false) return 0
  return 1
}
