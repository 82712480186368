import { bffClient, IClient } from 'shared/client'

import {
  OnboardingURLPayloadType,
  SendLinkContentType,
  SendLinkPayload,
} from '../model/individual-send-verification-link.model'

class IndividualSendVerificationLinkApi {
  private client: IClient

  constructor(client: IClient) {
    this.client = client
  }

  async getSmsContent() {
    return this.client.get<SendLinkContentType>(
      '/f2/v1/external-idv/sms-content',
    )
  }

  async getOnboardingURL(params: OnboardingURLPayloadType) {
    return this.client.get<{ url: string }>(
      '/f2/v1/external-idv/onboarding-url',
      { params },
    )
  }

  async sendLink(params: SendLinkPayload) {
    return this.client.post('/f2/v1/external-idv/send-link', params)
  }
}

export const individualSendVerificationLinkApi =
  new IndividualSendVerificationLinkApi(bffClient)
