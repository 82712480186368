import React from 'react'

import { ApplicantId } from 'entities/applicant'

import { Noop } from 'shared/typescript'

import { AssociatePartyFormData } from '../../../model/associate-party.model'
import { businessOwnerShipDataGridActionMenuQa } from '../../../qa/applicant-business-ownership.qa'
import { ApplicantAssociateParties } from '../../applicant-associate-parties/applicant-associate-parties'

type Props = {
  /**
   * Entity Id of the associated party
   */
  entityId: ApplicantId
  /**
   * Entity Id of the applicant
   */
  applicantId: ApplicantId
  data: Partial<AssociatePartyFormData>
  onClose: Noop
}

export function EditAssociatedPartyModal({
  entityId,
  applicantId,
  data,
  onClose,
}: Props) {
  return (
    <ApplicantAssociateParties
      testId={{ container: businessOwnerShipDataGridActionMenuQa.container }}
      applicantId={applicantId}
      associationType="updating"
      entityId={entityId}
      defaultValues={data}
      onClose={onClose}
    />
  )
}
