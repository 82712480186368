import React from 'react'

import { Controller, SubmitHandler, useForm } from 'react-hook-form'

import { FrankieButton, FrankieIcon } from 'frankify/src'

import { IRole } from 'entities/role'
import { IUser, UserId } from 'entities/user'

import { useI18n } from 'shared/i18n'
import { Nullable } from 'shared/typescript'

import { rolesToUsersQa } from '../../../qa/roles-to-users.qa'
import { ROLES_TO_USERS_KEY } from '../../../roles-to-users.key'
import {
  UserRoleSelector,
  RoleNameValue,
} from '../user-role-selector/user-role-selector'

enum ReassignRoleInputTypes {
  Users = 'users',
}

export interface IReassignRoleInputs {
  [ReassignRoleInputTypes.Users]: { userId: UserId; roleName: RoleNameValue }[]
}

type Props = {
  users: IUser[]
  roles: IRole[]
  onSubmit: (data: IReassignRoleInputs) => void
  onCancel: () => void
  defaultValues: Nullable<IReassignRoleInputs>
}

export function ReassignRolesForm({
  roles,
  users,
  onSubmit,
  onCancel,
  defaultValues,
}: Props) {
  const t = useI18n([ROLES_TO_USERS_KEY])

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isValid },
  } = useForm<IReassignRoleInputs>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: defaultValues ?? {
      users: users.map(user => ({ userId: user.id, roleName: '' })),
    },
  })

  const handleFormSubmit: SubmitHandler<IReassignRoleInputs> = data => {
    try {
      onSubmit(data)
    } catch (error: unknown) {
      //
    }
  }

  return (
    <form
      onSubmit={handleSubmit(handleFormSubmit)}
      className="flex h-full flex-col min-w-0 flex-initial items-center justify-between"
    >
      <FrankieIcon
        className="text-tertiary-red-700 mb-4"
        size="44px"
        name="mdiAlertCircleOutline"
      />
      <div
        data-qa={rolesToUsersQa.reassignRolesForm_heading}
        className="mb-2 max-w-full break-words text-tertiary-grey-800 text-xl leading-snug font-bold"
      >
        {t('reassignRolesForm.title')}
      </div>
      <div className="text-tertiary-grey-700 text-md">
        {t('reassignRolesForm.body')}
      </div>
      {users.map((user, idx) => (
        <div className="mt-2 w-full" key={user.id}>
          <Controller
            control={control}
            render={({
              field: { value, name, ref, onBlur, onChange },
              formState: { errors },
            }) => (
              <UserRoleSelector
                value={value}
                name={name}
                ref={ref}
                onChange={onChange}
                onBlur={onBlur}
                user={user}
                roles={roles}
                error={!!errors[ReassignRoleInputTypes.Users]?.[idx]?.roleName}
              />
            )}
            name={`${ReassignRoleInputTypes.Users}.${idx}.roleName`}
            rules={{
              validate: (value: RoleNameValue) =>
                value === null || roles.some(role => role.role === value),
            }}
          />
        </div>
      ))}
      <div className="flex flex-initial items-center gap-4 mt-6">
        <FrankieButton
          onClick={onCancel}
          size="sm"
          intent="darkOutline"
          disabled={isSubmitting}
          testId={{ button: rolesToUsersQa.reassignRolesForm_ctaCancel }}
        >
          {t('reassignRolesForm.cta.cancel')}
        </FrankieButton>
        <FrankieButton
          type="submit"
          size="sm"
          intent="danger"
          disabled={!isValid || isSubmitting}
          testId={{ button: rolesToUsersQa.reassignRolesForm_ctaSubmit }}
        >
          {t('reassignRolesForm.cta.submit')}
        </FrankieButton>
      </div>
    </form>
  )
}
