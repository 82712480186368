import { useQuery } from '@tanstack/react-query'

import { individualSendVerificationLinkApi } from '../../api/individual-send-verification-link.api'
import { OnboardingURLPayloadType } from '../../model/individual-send-verification-link.model'

export const IndividualSendVerificationLinkQueryKey =
  'individual-send-verification-link'

export const useIndividualSendVerificationLinkQuery = (
  payload: OnboardingURLPayloadType,
  enabled = true,
) =>
  useQuery({
    enabled: enabled && Boolean(payload.entityId && payload.flowId),
    queryKey: [IndividualSendVerificationLinkQueryKey, payload],
    queryFn: async () =>
      (await individualSendVerificationLinkApi.getOnboardingURL(payload)).data,
  })
