import { APPLICANT_BUSINESS_OWNERSHIP_INTERNATIONAL_KEY as ABOK } from '../applicant-business-ownership-international.key'

const AppAddBsnEntity = `${ABOK}-applicant-add-business-entity-qa`
export const applicantAddBusinessEntityQa = {
  btn: `${AppAddBsnEntity}-btn`,
  wrapper: `${AppAddBsnEntity}-wrapper`,
  menu: `${AppAddBsnEntity}-menu`,
} satisfies Record<string, `${typeof AppAddBsnEntity}-${string}`>

const AppAssParties = `${ABOK}-applicant-associate-parties-qa`
export const applicantAssociatePartiesQa = {
  summaryViewWrapper: `${AppAssParties}-summary-view-wrapper`,
  textField: `${AppAssParties}-text-field`,
  clearButton: `${AppAssParties}-clear-button`,
  buttonWrapper: `${AppAssParties}-button-wrapper`,
  cancelButton: `${AppAssParties}-cancel-button`,
  otherCheckBox: `${AppAssParties}-other-checkbox`,
  otherInput: `${AppAssParties}-other-input`,
  organisationToken: `${AppAssParties}-organisation-token`,
  firstNameField: `${AppAssParties}-first-name-field`,
  lastNameField: `${AppAssParties}-last-name-field`,
  beneficiaryInput: `${AppAssParties}-beneficially-input`,
  loader: `${AppAssParties}-loader`,
  icon: `${AppAssParties}-icon`,
  nameFilter: `${AppAssParties}-name-filter`,
  applySearchBtn: `${AppAssParties}-apply-search-btn`,
  container: `${AppAssParties}-container`,
  addCTA: `${AppAssParties}-add-cta`,
  confirmationCheckbox: `${AppAssParties}-confirmation-checkbox`,
} satisfies Record<string, `${typeof AppAssParties}-${string}`>

const ApplicantBsnOwnership = `${ABOK}-applicant-business-ownership-qa`
export const applicantBusinessOwnershipInternationalQa = {
  createdDate: `${ApplicantBsnOwnership}-created-date`,
  container: `${ApplicantBsnOwnership}-container`,
} satisfies Record<string, `${typeof ApplicantBsnOwnership}-${string}`>

const BsnOwnershipDataGrid = `${ABOK}-business-ownership-data-grid-qa`
export const businessOwnerShipDataGridQa = {
  dataGrid: `${BsnOwnershipDataGrid}-data-grid`,
  container: `${BsnOwnershipDataGrid}-container`,
} satisfies Record<string, `${typeof BsnOwnershipDataGrid}-${string}`>

export const businessOwnerShipAssociateDataGridQa = {
  dataGrid: `${BsnOwnershipDataGrid}-associate-data-grid`,
  container: `${BsnOwnershipDataGrid}-associate-container`,
} satisfies Record<string, `${typeof BsnOwnershipDataGrid}-${string}`>

const BsnOwnershipDataGridActionMenu = `${ABOK}-business-ownership-data-grid-action-menu-qa`
export const businessOwnerShipDataGridActionMenuQa = {
  container: `${BsnOwnershipDataGridActionMenu}-container`,
  sendLink: `${BsnOwnershipDataGridActionMenu}-send-link`,
  sendSms: `${BsnOwnershipDataGridActionMenu}-send-sms`,
  copyLink: `${BsnOwnershipDataGridActionMenu}-copy-link`,
  menuAction: `${BsnOwnershipDataGridActionMenu}-menu-action`,
  icon: `${BsnOwnershipDataGridActionMenu}-icon`,
  cancelBtn: `${BsnOwnershipDataGridActionMenu}-cancel-btn`,
  wrapper: `${BsnOwnershipDataGridActionMenu}-wrapper`,
  removeBtn: `${BsnOwnershipDataGridActionMenu}-remove-btn`,
  componentWrapper: `${BsnOwnershipDataGridActionMenu}-component-wrapper`,
} satisfies Record<string, `${typeof BsnOwnershipDataGridActionMenu}-${string}`>

const BsnOwnershipHelper = `${ABOK}-business-ownership-data-grid-helper-qa`
export const businessOwnershipDataGridHelperQa = {
  loader: `${BsnOwnershipHelper}-business-ownership-data-grid-loader`,
  valueWrapper: `${BsnOwnershipHelper}-wrapper`,
  iconWrapper: `${BsnOwnershipHelper}-icon-wrapper`,
} satisfies Record<string, `${typeof BsnOwnershipHelper}-${string}`>
