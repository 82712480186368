import { useState } from 'react'

type Params = {
  selectedIdx?: number
  noOfItems: number
}

/**
 * Custom hook to create a circular iterator
 */
export function useCircularIterator({ selectedIdx, noOfItems }: Params) {
  const [currIdx, setCurrIdx] = useState(selectedIdx ?? 0)

  const getNextIdx = () => {
    setCurrIdx(prevIndex => (prevIndex === noOfItems - 1 ? 0 : prevIndex + 1))
  }

  const getPrevIdx = () => {
    setCurrIdx(prevIndex => (prevIndex === 0 ? noOfItems - 1 : prevIndex - 1))
  }

  return { currIdx, getNextIdx, getPrevIdx, setCurrIdx }
}
