import { USER_MANAGEMENT_KEY } from '../user-management.key'

export const userManagementQa = {
  userTable_userList: 'user-management-user-table-user-list',

  createUserForm_inputName: 'user-management-form-edit-input-name',
  createUserForm_inputEmail: 'user-management-form-edit-input-email',
  createUserForm_inputRoleGroup: 'user-management-form-edit-input-role-group',
  createUserForm_inputRoleOption: 'user-management-form-edit-input-role-option',
  createUserForm_ctaSubmit: 'user-management-form-edit-cta-submit',
  createUserForm_ctaCancel: 'user-management-form-edit-cta-cancel',

  deleteUserForm_ctaSubmit: 'user-management-form-delete-cta-submit',
  deleteUserForm_ctaCancel: 'user-management-form-delete-cta-cancel',
} satisfies Record<string, `${typeof USER_MANAGEMENT_KEY}-${string}`>
