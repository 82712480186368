import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { useForm } from 'react-hook-form'

import { FrankieButton, FrankieLoader, FrankiePopover } from 'frankify/src'

import { ApplicantId } from 'entities/applicant'

import { SelectFormField, TextAreaFormField } from 'shared/form'
import { useI18n } from 'shared/i18n'
import { ExclusiveProperty } from 'shared/typescript'

import { APPLICANT_PEP_SACTIONS_KEY } from '../../applicant-pep-sactions.key'
import { applicantPepSactionsEn } from '../../locale/applicant-pep-sactions.en'
import { UpdateMatchPayload } from '../../model/applicant-pep-sactions.model'
import { applicantPepSanctionsStatusChangeQa } from '../../qa/applicant-pep-sactions.qa'
import { useApplicantPepSactionsQuery } from '../../state/applicant-pep-sactions-query/applicant-pep-sactions.query'
import { useApplicantPepSactionsMatchStatusChangeMutation } from '../../state/applicant-pep-sactions-status-change/applicant-pep-sactions-match-status.mutation'

type Props = {
  applicantId: ApplicantId
  disabled?: boolean
  groupIds: string[]
} & Partial<
  ExclusiveProperty<{
    approve: boolean
    reject: boolean
  }>
>

type FormData = Omit<UpdateMatchPayload, 'groupIds'>

export function ApplicantPepSactionsMatchStatusChange({
  approve,
  reject,
  applicantId,
  disabled = false,
  groupIds,
}: Props) {
  const t = useI18n(APPLICANT_PEP_SACTIONS_KEY, {
    keys: applicantPepSactionsEn,
  })

  const [open, setOpen] = useState(false)

  const {
    control,
    reset,
    handleSubmit,
    formState: { isValid },
  } = useForm<FormData>({
    defaultValues: {
      ...(approve ? { status: 'true_positive_accept' } : {}),
      ...(reject ? { status: 'true_positive_reject' } : {}),
    },
  })

  const { mutate, isLoading, isSuccess } =
    useApplicantPepSactionsMatchStatusChangeMutation({ applicantId })

  const { data: matchesData } = useApplicantPepSactionsQuery({ applicantId })

  useEffect(() => {
    if (isSuccess) {
      reset()
      setOpen(false)
    }
  }, [isSuccess, reset])

  const options = useMemo(
    () =>
      [
        { label: t('status.true'), value: 'true_positive' },
        { label: t('status.false'), value: 'false_positive' },
        { label: t('status.unknown'), value: 'unknown' },
      ] satisfies { value: UpdateMatchPayload['status']; label: string }[],
    [t],
  )

  const actionText = useCallback(
    (triggerBtn = false) => {
      if (approve) {
        return t('action.approve')
      }
      if (reject) {
        return t('action.reject')
      }

      return triggerBtn
        ? t('action.changeMatchStatus')
        : t('action.changeStatus')
    },
    [approve, reject, t],
  )

  const triggerBtnClass = useMemo(() => {
    if (disabled) return ''
    if (approve) return '!bg-tertiary-green-400 !outline-tertiary-green-300'
    if (reject) return '!bg-tertiary-red-400 !outline-tertiary-red-300'
    return '!bg-mono-100 !outline-mono-50'
  }, [approve, disabled, reject])

  return (
    <FrankiePopover
      onOpenChange={setOpen}
      open={open}
      popoverRest={{ placement: 'bottom-start' }}
      showOverlay
      trigger={
        <FrankieButton
          testId={{ button: applicantPepSanctionsStatusChangeQa.triggerBtn }}
          className={triggerBtnClass}
          size="sm"
          disabled={disabled}
          onClick={() => setOpen(prev => !prev)}
        >
          {actionText(true)}
        </FrankieButton>
      }
    >
      <FrankieLoader
        label={
          <div className="text-tertiary-grey-800 font-medium">
            {t('loading.update')}
          </div>
        }
        loading={isLoading}
      >
        <form
          onSubmit={handleSubmit(data =>
            mutate({ ...data, groupIds, checkId: matchesData?.checkId ?? '' }),
          )}
          data-qa={applicantPepSanctionsStatusChangeQa.form}
          className="flex flex-col w-[378px] gap-4 p-4 bg-mono-white shadow-lg rounded-sm"
        >
          {!(approve || reject) && (
            <div className="flex items-center gap-2">
              <span className="whitespace-nowrap text-tertiary-grey-800 font-medium">
                {t('field.changeMatch')}
              </span>
              <SelectFormField
                options={options}
                className="flex"
                control={control}
                name="status"
                rules={{ required: true }}
              />
            </div>
          )}

          <TextAreaFormField
            testId={{ input: applicantPepSanctionsStatusChangeQa.textArea }}
            label={t('field.comment')}
            name="comment"
            placeholder={t('field.commentPlaceholder')}
            control={control}
            rules={{ required: true }}
          />

          <div className="flex gap-3 justify-end">
            <FrankieButton
              testId={{ button: applicantPepSanctionsStatusChangeQa.closeBtn }}
              noStyles
              className="text-tertiary-grey-900 font-medium"
              size="sm"
              onClick={() => setOpen(false)}
            >
              {t('action.cancel')}
            </FrankieButton>
            <FrankieButton
              disabled={!isValid}
              className={isValid ? '!bg-mono-100 !outline-mono-50' : ''}
              size="sm"
              type="submit"
            >
              {actionText()}
            </FrankieButton>
          </div>
        </form>
      </FrankieLoader>
    </FrankiePopover>
  )
}
