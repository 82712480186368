import React from 'react'

import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'

import { FrankieIcon, FrankieLoader } from 'frankify/src'

import {
  IRoleEditInputs,
  RoleEditForm,
  RoleEditInputTypes,
  useEditRoleMutation,
  usePermissionOptionsQuery,
  useSingleRoleQuery,
} from 'features/role-management'

import { PermissionTypes, RoleId } from 'entities/role'
import { useHasPermission } from 'entities/session'

import { useI18n } from 'shared/i18n'
import { notification } from 'shared/notification'
import { ObjectUnknown } from 'shared/typescript'

import { settingsRoleEditQa } from '../../qa/settings-role-edit.qa'
import { SETTINGS_ROLE_EDIT_PAGE_KEY } from '../../settings-role-edit.key'

type Props = {
  roleIdParamKey: string
  roleListPath: string
  getCancelPathFromState: (
    state: ObjectUnknown | undefined,
    roleId: RoleId,
  ) => string
}

export function SettingsRoleEditPage({
  roleIdParamKey,
  roleListPath,
  getCancelPathFromState,
}: Props) {
  const t = useI18n([SETTINGS_ROLE_EDIT_PAGE_KEY])
  const navigate = useNavigate()
  const params = useParams()
  const { state } = useLocation()
  const roleId = params[roleIdParamKey]
  const cancelPath = getCancelPathFromState(state, Number(roleId))
  const {
    canUpdateName,
    canUpdatePermissions,
    canFetchRole,
    canFetchPermissions,
  } = useHasPermission({
    canUpdateName: PermissionTypes.RoleUpdateFunction,
    canUpdatePermissions: PermissionTypes.RolePermissionMappingFunction,
    canFetchRole: PermissionTypes.RoleDetailFunction,
    canFetchPermissions: PermissionTypes.ActionListFunction,
  })
  const { data: permissionList, isLoading: isPermissionListLoading } =
    usePermissionOptionsQuery({ canFetchPermissions })
  const { data: role, isLoading: isRoleLoading } = useSingleRoleQuery(
    Number(roleId),
    { canFetchRole },
  )
  const { mutateAsync: editRole } = useEditRoleMutation()

  const isLoading = isRoleLoading || isPermissionListLoading

  if (!roleId) {
    return null
  }

  const handleEditRole = async (data: IRoleEditInputs) => {
    if (!canUpdateName && !canUpdatePermissions) {
      return
    }
    await editRole({
      id: Number(roleId),
      roleConfig: {
        description: canUpdateName
          ? data[RoleEditInputTypes.Description]
          : role?.description,
        roleName: canUpdateName ? data[RoleEditInputTypes.Name] : role?.role,
        action_list_ids: (canUpdatePermissions
          ? data[RoleEditInputTypes.Permissions]
          : role?.permissions
        )?.map(permission => permission.id),
      },
    })
    notification.success(
      t('notification.updated', { name: data[RoleEditInputTypes.Name] }),
    )
    navigate(roleListPath)
  }
  const handleCancel = () => navigate(cancelPath)

  if (!isLoading) {
    return (
      <div className="h-[calc(100vh-92px)] p-8 pr-4 mr-2 overflow-y-auto scrollbar">
        <Link
          to={roleListPath}
          className="mb-3 text-tertiary-grey-500 text-xs flex leading-[18px]"
          data-qa={settingsRoleEditQa.goBackCta}
        >
          <FrankieIcon size="xs" name="mdiChevronLeft" />
          {t('cta.goBack')}
        </Link>
        <div
          data-qa={settingsRoleEditQa.header}
          className="mb-4 text-2xl font-bold text-tertiary-grey-700 leading-8"
        >
          {t('header.editRole')}
        </div>
        {permissionList && role && (
          <RoleEditForm
            canEditName={canUpdateName}
            canEditPermissions={canUpdatePermissions}
            onSubmit={handleEditRole}
            onCancel={handleCancel}
            permissionOptions={permissionList}
            initialValues={role}
          />
        )}
      </div>
    )
  }

  return <FrankieLoader loading className="h-screen" />
}
