import { useMutation } from '@tanstack/react-query'

import { useI18n } from 'shared/i18n'
import { notification } from 'shared/notification'
import { TrackingEventsTypes, trackingManager } from 'shared/tracking'

import { customViewApi } from '../../../api/custom-view.api'
import { CUSTOM_VIEWS_KEYS } from '../../../custom-view.key'
import { customViewEn } from '../../../locale/custom-view.en'
import { CustomViewPayload } from '../../../model/custom-views.model'
import { useCustomView } from '../../custom-view-state/custom-view.state'

export const useCreateCustomView = () => {
  const { refetch, isFetching: isCustomViewLoading } = useCustomView()
  const t = useI18n([CUSTOM_VIEWS_KEYS], { keys: customViewEn })

  const { mutate, isLoading, isSuccess, data } = useMutation({
    mutationKey: ['createCustomView'],
    mutationFn: async (data: CustomViewPayload) => {
      const res = await customViewApi.createCustomView(data)
      return res.data
    },

    onSuccess: (_, variable) => {
      void refetch()

      trackingManager.track(TrackingEventsTypes.CustomViewSaveFromCustomSection)
      notification.success(
        t('notification.viewCreated', { name: variable.name }),
      )
    },
    onError: () => {
      notification.error(t('notification.error'))
    },
  })

  return {
    mutate,
    isLoading: isLoading || isCustomViewLoading,
    isSuccess,
    data,
  }
}
