import React from 'react'

type ValueType = string | number

export type IFrankieVerticalTabsProps<TValue extends ValueType> = {
  activeTab?: TValue
  onTabChange: (value: TValue, e: React.MouseEvent) => void
  tabItems: {
    label: string
    value: TValue
    link?: string
    testId?: string
  }[]
}

export function FrankieVerticalTabs<TValue extends ValueType>({
  tabItems,
  activeTab,
  onTabChange,
}: IFrankieVerticalTabsProps<TValue>) {
  return (
    <div className="w-[200px] mr-2 border-s-[2px] border-tertiary-grey-200">
      {tabItems.map(({ value, label, link, testId }) => (
        <a
          type="button"
          key={value}
          href={link}
          data-qa={testId}
          className="flex items-stretch justify-start my-2"
          onClick={e => onTabChange(value, e)}
        >
          <div
            className={`ml-[-2px] mr-2 w-[2px] ${
              value === activeTab ? 'rounded-md bg-primary-800' : ''
            }`}
          />
          <div
            className={`h-full block mx-2 text-start leading-relaxed ${
              value === activeTab
                ? 'text-primary-800'
                : 'text-tertiary-grey-600 hover:text-tertiary-grey-700'
            }`}
          >
            {label}
          </div>
        </a>
      ))}
    </div>
  )
}
