import { useInfiniteQuery } from '@tanstack/react-query'

import { FrankieRawApplicant } from 'entities/applicant'

import { applicantsApi } from '../api/applicants.api'
import { mapApplicantFiltersToApi } from '../model/applicant-api-filter.model'
import type { IApplicantsGlobalFilter } from '../model/applicants-filter.model'

export const APPLICANTS_QUERY = 'applicants-filter'

export const useApplicantsQueryInfinite = ({
  filters,
  limit,
  enabled = true,
}: {
  filters?: IApplicantsGlobalFilter
  limit?: string
  enabled?: boolean
}) =>
  useInfiniteQuery<{
    applicants: FrankieRawApplicant[]
    nextPageMarker?: string
  }>({
    enabled,
    staleTime: 0,
    queryKey: [APPLICANTS_QUERY, filters],
    getNextPageParam: lastPage => lastPage.nextPageMarker,
    queryFn: async ({ pageParam }: { pageParam?: string }) => {
      const { data } = await applicantsApi.getGlobalApplicants(
        limit,
        pageParam,
        filters ? mapApplicantFiltersToApi(filters) : undefined,
      )

      return {
        applicants: data.applicants,
        nextPageMarker: data.nextPageMarker ?? undefined,
      }
    },
  })
