import React, { useEffect, useState } from 'react'

import cx from 'classnames'
import { useInView } from 'react-intersection-observer'

import { getDocumentDisplayName } from 'entities/applicant'
import { CountryAlpha3CodeTypes } from 'entities/country'
import { DocumentViewF2 } from 'entities/document-f2'
import { DocumentType, IDocsToBeDisplayedType } from 'entities/entity'

import { useI18n } from 'shared/i18n'
import { TrackingEventsTypes, trackingManager } from 'shared/tracking'

import { workflowEventsEn } from '../../locale/workflow-events.en'
import { verificationDocumentCardQa } from '../../qa/applicant-workflow-events.qa'
import { WORKFLOW_EVENTS_KEY } from '../../workflow-events.key'
import { LabelAndSources } from '../entity-verification-helper/entity-verification-helper'

type DocumentProps = {
  data: IDocsToBeDisplayedType
}

export function VerificationDocumentCard({ data }: DocumentProps) {
  const t = useI18n([WORKFLOW_EVENTS_KEY], {
    keys: workflowEventsEn,
  })

  const { ref, inView } = useInView()
  const [eventEmitted, setEventEmitted] = useState(false)

  useEffect(() => {
    if (inView && !eventEmitted) {
      trackingManager.track(TrackingEventsTypes.WorkflowVerificationIndividual)
      setEventEmitted(true)
    }
  }, [eventEmitted, inView])

  return (
    <div
      className="flex flex-col gap-3"
      data-qa={verificationDocumentCardQa.container}
      ref={ref}
    >
      <div
        data-qa={verificationDocumentCardQa.documentLabel}
        className={cx('font-semibold text-md first-letter:capitalize', {
          lowercase: data.type !== DocumentType.NATIONAL_ID,
        })}
      >
        {getDocumentDisplayName(
          `documentLabels.${data.type}`,
          CountryAlpha3CodeTypes.UNITED_STATES_AMERICA,
        ) ||
          t(
            `documentLabels.${
              data.type as keyof typeof workflowEventsEn.documentLabels
            }`,
          )}
      </div>

      <div className="flex">
        <div className="flex gap-6 w-3/4 ">
          {data.attachments?.[0] && (
            <div
              className="desktop:w-64 w-36"
              data-qa={verificationDocumentCardQa.documentView}
            >
              <DocumentViewF2
                className="aspect-video"
                document={data}
                extraData={[...data.extraData, ...data.label]}
              />
            </div>
          )}
          <LabelAndSources labelAndSources={data.label} />
        </div>
      </div>
    </div>
  )
}
