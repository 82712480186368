import React, { useEffect } from 'react'

import { FrankieAvatar } from 'frankify/src'

import { DateFormatTypes, formatDate } from 'shared/date-time'
import { trackingManager } from 'shared/tracking'

import { CommentsTypes, eventMapper } from '../../model/comments.model'
import { commentBoxQa } from '../../qa/comments.qa'
import { useCommentData } from '../../states/comments-states/comments.state'

export function CommentBox({
  entityId,
  processId,
  commentType,
}: {
  entityId: string
  processId?: string
  commentType: CommentsTypes
}) {
  const { data } = useCommentData({ commentType, entityId, processId })
  useEffect(() => {
    trackingManager.track(eventMapper[commentType].hover ?? '')
  }, [])

  if (!data.length) return null
  return (
    <div
      className="bg-tertiary-grey-200 rounded-sm flex flex-col gap-2"
      data-qa={commentBoxQa.container}
    >
      <div className="flex gap-2 items-center">
        <FrankieAvatar
          name={data[0].source ?? ''}
          size="md"
          className="min-w-[32px] min-h-[32px]"
          testId={{ initial: commentBoxQa.avatar }}
        />
        <div className="flex flex-col gap-1 max-w-[148px] desktop:max-w-[190px]">
          <div
            className="text-tertiary-grey-800 text-sm font-semibold leading-3 truncate"
            data-qa={commentBoxQa.name}
          >
            {data[0].source ?? ''}
          </div>
          <div
            className="text-tertiary-grey-500 leading-4 text-xs"
            data-qa={commentBoxQa.date}
          >
            {formatDate(
              data[0].timestamp || '',
              DateFormatTypes.shortDateAndShortTime,
            )}
          </div>
        </div>
      </div>
      <div
        className="text-tertiary-grey-700 font-bold text-sm leading-4 max-h-9 line-clamp-2 overflow-hidden"
        data-qa={commentBoxQa.action}
      >
        {data[0].actionTaken}{' '}
        {data[0].statusComment && (
          <span
            data-qa={commentBoxQa.status}
            className={`${
              data[0]?.statusComment?.textColor ?? 'text-tertiary-grey-700'
            } font-bold text-sm leading-4`}
          >
            {data[0]?.statusComment?.text}
          </span>
        )}
      </div>
      <div
        className="text-tertiary-grey-600 text-xs whitespace-break-spaces max-h-[90px] line-clamp-6 overflow-hidden"
        data-qa={commentBoxQa.comment}
      >
        {data[0].userComment?.text}
      </div>
    </div>
  )
}
