import { FlowIdTypes, EntityType } from 'entities/entity'

import { I18nKeys, useI18n } from 'shared/i18n'
import { TrackingEventsTypes } from 'shared/tracking'

import { INDIVIDUAL_SEND_VERIFICATION_LINK_KEY } from '../individual-send-verification-link.key'
import { individualSendVerificationLinkEn } from '../locale/individual-send-verification-link.en'

type FlowIdOption = {
  label: I18nKeys<typeof individualSendVerificationLinkEn>
  value: FlowIdTypes
}

export const flowIdOptions: FlowIdOption[] = [
  {
    label: 'flows.kyc',
    value: FlowIdTypes.MANUAL_KYC,
  },
  {
    label: 'flows.idv',
    value: FlowIdTypes.IDV,
  },
  {
    label: 'flows.ocr',
    value: FlowIdTypes.OCR_ONLY,
  },
]

export type SendLinkFormType = {
  flowId: FlowIdTypes
  comment: string
  number: string
  country: string
  livenessDetection?: boolean
  smsContent: string
}

export type SendLinkPayload = SendLinkFormType & { entityId: string }

export type SendLinkContentType = {
  smsContent: string
  flowIds: string[]
  expiryIn: number
}

export type OnboardingURLPayloadType = {
  flowId: FlowIdTypes
  entityId: string
}

export const useSendLinkTranslation = () => {
  const t = useI18n([INDIVIDUAL_SEND_VERIFICATION_LINK_KEY], {
    keys: individualSendVerificationLinkEn,
  })
  return t
}

export enum SendLinkEventTypes {
  copy = 'copy',
  send = 'send',
  showSms = 'showSms',
  showLinkType = 'showLinkType',
  click = 'click',
  banner = 'banner',
}

export const sendLinkEventsMapping = {
  [EntityType.INDIVIDUAL]: {
    [SendLinkEventTypes.copy]: TrackingEventsTypes.IndividualSendLinkCopy,
    [SendLinkEventTypes.send]: TrackingEventsTypes.IndividualSendLinkConfirm,
    [SendLinkEventTypes.showSms]:
      TrackingEventsTypes.IndividualSendLinkSmsMessageShow,
    [SendLinkEventTypes.showLinkType]:
      TrackingEventsTypes.IndividualSendLinkLinkTypeShow,
    [SendLinkEventTypes.click]: TrackingEventsTypes.IndividualViewSendLinkClick,
    [SendLinkEventTypes.banner]:
      TrackingEventsTypes.OrganisationSendLinkWorkflowEventClick,
  },
  [EntityType.ORGANIZATION]: {
    [SendLinkEventTypes.copy]: TrackingEventsTypes.OrganisationSendLinkCopy,
    [SendLinkEventTypes.send]: TrackingEventsTypes.OrganisationSendLinkConfirm,
    [SendLinkEventTypes.showSms]:
      TrackingEventsTypes.OrganisationSendLinkSmsMessageShow,
    [SendLinkEventTypes.showLinkType]:
      TrackingEventsTypes.OrganisationSendLinkLinkTypeShow,
    [SendLinkEventTypes.click]:
      TrackingEventsTypes.OrganisationViewSendLinkClick,
    [SendLinkEventTypes.banner]:
      TrackingEventsTypes.OrganisationSendLinkWorkflowEventClick,
  },
  [EntityType.UNKNOWN]: {
    [SendLinkEventTypes.copy]: TrackingEventsTypes.IndividualSendLinkCopy,
    [SendLinkEventTypes.send]: TrackingEventsTypes.IndividualSendLinkConfirm,
    [SendLinkEventTypes.showSms]:
      TrackingEventsTypes.IndividualSendLinkSmsMessageShow,
    [SendLinkEventTypes.showLinkType]:
      TrackingEventsTypes.IndividualSendLinkLinkTypeShow,
    [SendLinkEventTypes.click]: TrackingEventsTypes.IndividualViewSendLinkClick,
    [SendLinkEventTypes.banner]:
      TrackingEventsTypes.OrganisationSendLinkWorkflowEventClick,
  },
}
