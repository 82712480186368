import React from 'react'

import {
  Controller,
  ControllerRenderProps,
  ControllerProps,
  FieldValues,
} from 'react-hook-form'

import { FrankieSelectField, IFrankieSelectFieldProps } from 'frankify/src'

type Props<
  TFormValues extends FieldValues,
  TOption extends string | number,
> = Omit<IFrankieSelectFieldProps<TOption>, keyof ControllerRenderProps> &
  Omit<ControllerProps<TFormValues>, 'render'> & { showErrorText?: boolean }

export function SelectFormField<
  TFormValues extends FieldValues,
  TOption extends string | number,
>({
  name,
  control,
  defaultValue,
  rules,
  shouldUnregister,
  showErrorText = false,
  ...selectProps
}: Props<TFormValues, TOption>) {
  return (
    <Controller
      render={({ field, fieldState: { error } }) => (
        <FrankieSelectField
          {...selectProps}
          {...field}
          error={!!error}
          errorText={showErrorText ? error?.message : undefined}
        />
      )}
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={rules}
      shouldUnregister={shouldUnregister}
    />
  )
}
