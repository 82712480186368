import React, { useEffect } from 'react'

import { useForm } from 'react-hook-form'

import { FrankieButton } from 'frankify/src'

import { workflowEventsEn } from 'features/applicant-workflow-events/locale/workflow-events.en'
import { WORKFLOW_EVENTS_KEY } from 'features/applicant-workflow-events/workflow-events.key'

import { ExecuteWorkflowPayload } from 'entities/entity'
import { useWorkflowListQuery } from 'entities/workflow'

import { SelectFormField, TextAreaFormField } from 'shared/form'
import { useI18n } from 'shared/i18n'
import { useOverlay } from 'shared/overlay'
import { useValidationRules } from 'shared/validation'

type Props = {
  onConfirm: (comment: ExecuteWorkflowPayload) => void
  showWorkflow?: boolean
  defaultWorkflow?: string
}
export function EntityVerificationReverifyModal({
  onConfirm,
  showWorkflow,
  defaultWorkflow,
}: Props) {
  const t = useI18n([WORKFLOW_EVENTS_KEY], { keys: workflowEventsEn })

  const [, closeOverlay] = useOverlay()

  const { data: workflows } = useWorkflowListQuery()

  const profileOptions = workflows?.map(workflow => ({
    label: workflow.workflowName,
    value: workflow.workflowName,
  }))

  const {
    control,
    handleSubmit,
    setValue,
    formState: { isValid },
  } = useForm<ExecuteWorkflowPayload>({
    mode: 'onTouched',
  })

  const { xssRule } = useValidationRules()

  const onSubmit = (data: ExecuteWorkflowPayload) => {
    onConfirm(data)
    closeOverlay()
  }
  useEffect(() => {
    if (defaultWorkflow) {
      setValue('workflowName', defaultWorkflow)
    }
  }, [defaultWorkflow, setValue])

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="w-[400px] flex flex-col gap-4"
    >
      <div className="text-xl font-bold text-tertiary-grey-800">
        {t(
          showWorkflow
            ? 'executeWorkflow.buttonVerify'
            : 'executeWorkflow.runWorkflow',
        )}
      </div>

      <p className="text-mono-black">
        {t(
          showWorkflow
            ? 'executeWorkflow.verifyDescription'
            : 'executeWorkflow.runWorkflowDesc',
        )}
      </p>

      {showWorkflow && (
        <SelectFormField
          className="mt-4 mb-2  basis-1/4"
          inputClassName="h-[37px]"
          control={control}
          name="workflowName"
          placeholder={t('executeWorkflow.selectWorkflow')}
          label={t('executeWorkflow.selectWorkflow')}
          options={profileOptions || []}
          rules={{ required: true }}
          showErrorText
        />
      )}

      <TextAreaFormField
        label={t('manualStatus.commentLabel')}
        control={control}
        rules={{
          required: t('manualStatus.commentError'),
          ...xssRule,
        }}
        showError
        name="comment"
        trim
        maxLength={500}
        inputClassName="!min-h-[86px]"
        placeholder={t('manualStatus.commentPlaceholder')}
      />
      <div className="flex justify-end mt-2">
        <FrankieButton onClick={closeOverlay} intent="subtle">
          {t('manualStatus.cancel')}
        </FrankieButton>
        <FrankieButton disabled={!isValid} type="submit">
          {t(
            showWorkflow
              ? 'executeWorkflow.runWorkflow'
              : 'executeWorkflow.run',
          )}
        </FrankieButton>
      </div>
    </form>
  )
}
