import { bffClient, IClient } from 'shared/client'

import { ApplicantResponse } from '../model/applicant.model'

export class ApplicantAPI {
  client: IClient

  constructor(client: IClient) {
    this.client = client
  }

  async getApplicant(entityId: string) {
    return this.client.get<ApplicantResponse>(`data/v1/applicants/${entityId}`)
  }

  async updateApplicantStatus(
    applicantId: string,
    status: string,
    comment: string,
  ) {
    return this.client.put(`data/v1/applicants/${applicantId}/state`, {
      comment,
      state: status,
    })
  }
}
export const applicantApi = new ApplicantAPI(bffClient)
