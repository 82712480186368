import React, { useEffect, useRef, useState } from 'react'

import classNames from 'classnames'
import { Document, Page } from 'react-pdf'

import { FrankieButton, FrankieLoader } from 'frankify/src'

import { DateFormatTypes, formatDate } from 'shared/date-time'
import { getDataFileUrl } from 'shared/file'
import { useI18n } from 'shared/i18n'
import { notification } from 'shared/notification'
import { useOverlay } from 'shared/overlay'

import { DOCUMENT_KEY, documentEn } from '../../locale/document.en'
import {
  defaultSupportedFileTypes,
  scanDateSorter,
  scanSorter,
  SupportedFileTypes,
} from '../../model/document.model'
import { useGetDocumentScan } from '../../mutation/document-scans'
import { useUploadDocument } from '../../mutation/upload-document.mutation'
import { PdfPreview } from '../pdf-preview/pdf-preview'

const FILENAME_MAX_LENGTH = 80

export type UploadedDocumentData = {
  fileUploadUuid: string
  mimeType: string
  scanCreated: string
  scanName: string
}

type Props = {
  className?: string
  name?: string
  uploadedOn?: string
  supportedFileTypes?: SupportedFileTypes[]
  fileSize?: number
  onChange: (data: UploadedDocumentData) => void
  error?: boolean
  documentId?: string
  fetchNewScan?: boolean
}

/**
 * Document Upload
 * @description Supports only pdf and images upload
 */
export function DocumentUpload({
  className = '',
  uploadedOn,
  supportedFileTypes = defaultSupportedFileTypes,
  fileSize = 25,
  onChange,
  name,
  error = false,
  documentId,
  fetchNewScan = false,
}: Props) {
  const t = useI18n([DOCUMENT_KEY], { keys: documentEn })
  const ref = useRef<HTMLInputElement | null>(null)

  const [fileUrl, setFileUrl] = useState<string | null>()
  const [isFileImage, setIsFileImage] = useState(false)
  const [fileName, setFileName] = useState<string>()
  const [createOverlay] = useOverlay()
  const { data: scans } = useGetDocumentScan({ documentId, fetchNewScan })

  const { mutate, isLoading } = useUploadDocument((data, variable) => {
    const { file } = variable
    const { type, name } = file
    const newDate = new Date()

    if (type.split('/').includes('pdf')) {
      setIsFileImage(false)
    } else {
      setIsFileImage(true)
    }
    setFileUrl(URL.createObjectURL(file))
    setFileName(name)

    onChange({
      fileUploadUuid: data.id,
      mimeType: type,
      scanName: name,
      scanCreated: newDate.toISOString(),
    })
  })

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target

    if (!files?.length) return

    const file = files[0]
    const fileType = file.type
    const fileName = file.name

    // File Size Check
    if (file.size > +(fileSize * 1000000).toFixed(0)) {
      notification.error(
        t('message.invalidFileSize', {
          fileSize,
        }),
      )

      return
    }

    // File Type Check
    if (!supportedFileTypes.some(type => RegExp(type).test(fileType))) {
      notification.error(
        t('message.invalidFileType', {
          fileTypes: supportedFileTypes
            .map(item => item.split('/')[1])
            .join(', '),
        }),
      )

      return
    }

    // Unsafe File name check
    if (fileName.length > FILENAME_MAX_LENGTH || /[<>\\/'"`]/.test(fileName)) {
      notification.error(
        t('message.invalidFileName', { maxLength: FILENAME_MAX_LENGTH }),
      )

      return
    }

    mutate({ file })
  }

  const handleView = () => {
    if (!fileUrl) return
    createOverlay(
      <div className="flex flex-col gap-2 h-[600px] w-[800px]">
        <div className="text-tertiary-grey-800 text-lg font-bold">
          {name ?? fileName ?? t('document')}
        </div>
        <div className="flex flex-grow-1 items-center justify-center border border-tertiary-grey-300 bg-tertiary-grey-100 rounded-sm">
          {isFileImage ? (
            <img
              data-hj-suppress
              className="object-contain max-h-[600px]"
              src={fileUrl}
              alt="uploaded-file"
            />
          ) : (
            <div
              data-hj-suppress
              className="overflow-y-auto overflow-x-hidden h-[536px] flex-1 rounded-sm"
            >
              <PdfPreview
                url={fileUrl}
                pageProps={{
                  width: 800,
                }}
              />
            </div>
          )}
        </div>
        <div className="text-sm">
          {t('uploadedOn', {
            date: formatDate(
              uploadedOn ?? new Date().toUTCString(),
              DateFormatTypes.fullDateWithTime,
            ),
          })}
        </div>
      </div>,
    )
  }

  useEffect(() => {
    if (scans?.length) {
      const docScan = scans.sort(scanSorter).sort(scanDateSorter)
      if (!docScan[0].file) return

      setIsFileImage(!docScan[0].mimeType.split('/').includes('pdf'))
      setFileName(docScan[0].scanName)
      setFileUrl(getDataFileUrl(docScan[0].mimeType, docScan[0].file))
    }
  }, [scans])

  return (
    <div
      className={classNames(
        className,
        'relative w-full h-full min-w-[200px] min-h-[150px] border-2 rounded-sm',
        fileUrl
          ? 'border-solid border-neutral-40'
          : 'border-dashed border-tertiary-grey-200',
        error
          ? 'border-tertiary-red-700 border-solid'
          : 'hover:border-primary-500 hover:border-solid',
      )}
    >
      <FrankieLoader
        loading={isLoading}
        label={fileUrl ? t('replacing') : t('uploading')}
      >
        <input
          className="absolute inset-0 opacity-0 z-[2] cursor-pointer"
          type="file"
          multiple={false}
          onChange={handleFileUpload}
          accept={supportedFileTypes.join(',')}
          onClick={e => {
            e.currentTarget.value = '' // to reset the file input so it can same file again
          }}
          ref={ref}
        />

        {fileUrl ? (
          <div className="group/uploaded flex items-center justify-center absolute inset-0 z-[3] bg-neutral-40 p-2">
            {isFileImage ? (
              <img
                data-hj-suppress
                className="max-h-[100%] object-contain"
                src={fileUrl}
                alt="uploaded-file"
              />
            ) : (
              <div
                data-hj-suppress
                title="uploaded-file"
                className="overflow-hidden border border-tertiary-grey-300 rounded-sm"
              >
                <Document file={fileUrl}>
                  <Page
                    pageNumber={1}
                    height={171}
                    renderAnnotationLayer={false}
                    renderTextLayer={false}
                  />
                </Document>
              </div>
            )}

            <div
              className="absolute inset-0 p-2 flex flex-col justify-center gap-4 items-center bg-neutral-90 bg-opacity-90
            opacity-0 group-hover/uploaded:opacity-100
            transition-opacity duration-300 ease-in-out transform"
            >
              <FrankieButton
                intent="primary"
                className="!w-3/5"
                size="sm"
                endIcon={{
                  name: 'mdiArrowExpand',
                  size: 'xs',
                  className: 'ps-2',
                }}
                onClick={handleView}
              >
                {t('view')}
              </FrankieButton>
              <FrankieButton
                onClick={() => ref.current?.click()}
                intent="secondary"
                size="sm"
                className="!bg-primary-50 !w-3/5"
                endIcon={{ name: 'mdiClose', size: 'xs', className: 'ps-2' }}
              >
                {t('replace')}
              </FrankieButton>
            </div>
          </div>
        ) : (
          <div className="absolute inset-0 z-[1] p-4 flex items-center justify-center">
            <p className="text-center text-tertiary-grey-800 text-sm font-medium">
              {t('placeholder')}
            </p>
          </div>
        )}
      </FrankieLoader>
    </div>
  )
}
