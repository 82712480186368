import { useMutation } from '@tanstack/react-query'

import { ApplicantId, useApplicantDataQuery } from 'entities/applicant'

import { useI18n } from 'shared/i18n'
import { notification } from 'shared/notification'

import { applicantPepSactionsApi } from '../../api/applicant-pep-sactions'
import { APPLICANT_PEP_SACTIONS_KEY } from '../../applicant-pep-sactions.key'
import { applicantPepSactionsEn } from '../../locale/applicant-pep-sactions.en'
import { UpdateMatchPayload } from '../../model/applicant-pep-sactions.model'
import { useApplicantPepSactionsQuery } from '../applicant-pep-sactions-query/applicant-pep-sactions.query'

type Args = {
  applicantId: ApplicantId
}

export type UpdateStatusMutateArgs = {
  checkId: string
} & UpdateMatchPayload

export const useApplicantPepSactionsMatchStatusChangeMutation = ({
  applicantId,
}: Args) => {
  const { refetch } = useApplicantPepSactionsQuery({ applicantId })
  const { refetch: refetchApplicantData } = useApplicantDataQuery({
    applicantId,
  })
  const t = useI18n(APPLICANT_PEP_SACTIONS_KEY, {
    keys: applicantPepSactionsEn,
  })

  return useMutation({
    mutationFn: ({ checkId, ...data }: UpdateStatusMutateArgs) =>
      applicantPepSactionsApi.updateMatchStatus(applicantId, checkId, data),

    onSuccess: () => {
      notification.success(t('success.changeStatus'))
      void refetch()
      void refetchApplicantData()
    },

    onError: () => {
      notification.error(t('error.changeStatus'))
    },
  })
}
