import React, { useMemo } from 'react'

import { FrankieAlert, IFrankieAlertProps } from 'frankify/src'

import { getLocalStorageState, StorageKeyTypes } from 'shared/local-storage'

export type ApplicantIssueActionBadgeData = {
  type: string
  masterLabel?: string
  redirectTo?: string
  isDisabled?: boolean
}

type Props = {
  /**
   * The key to persist the alert in local storage
   */
  persistKey: string
} & IFrankieAlertProps

export function FrankieAlertWithLocalStorage({ persistKey, ...props }: Props) {
  const [dismissedAlertsLs, setDismissAlertLs] = useMemo(
    () => getLocalStorageState(StorageKeyTypes.DismissedAlerts),
    [],
  )

  // we need to typecast the object to a boolean array since the type was unknown
  const dismissedAlertLs = dismissedAlertsLs as
    | { [key: string]: boolean }
    | undefined

  const value = !dismissedAlertLs?.[persistKey]

  const handleDismiss = () => {
    setDismissAlertLs({
      ...dismissedAlertLs,
      [persistKey]: true,
    })
  }

  return <FrankieAlert {...props} value={value} onDismiss={handleDismiss} />
}
