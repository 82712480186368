import React, { useEffect, useMemo, useState } from 'react'

import { FrankieButton, FrankieTabs } from 'frankify/src'

import { ApplicantId } from 'entities/applicant'

import { useCircularIterator } from 'shared/hooks'
import { useI18n } from 'shared/i18n'

import {
  ApplicantPepSactionsResultTabs,
  ApplicantPepSactionsResultTabsMappingTypes,
} from './applicant-pep-sactions-result-tabs/applicant-pep-sactions-result-tabs'
import { APPLICANT_PEP_SACTIONS_KEY } from '../../applicant-pep-sactions.key'
import { applicantPepSactionsEn } from '../../locale/applicant-pep-sactions.en'
import { applicantPepSanctionResultQa } from '../../qa/applicant-pep-sactions.qa'
import { useApplicantPepSactionsQuery } from '../../state/applicant-pep-sactions-query/applicant-pep-sactions.query'
import { PepSactionsMatchStatusLabel } from '../applicant-pep-sactions-data-grid-helper/applicant-pep-sactions-data-grid-helper'
import { ApplicantPepSactionsInfoHeader } from '../applicant-pep-sactions-info-header/applicant-pep-sactions-info-header'
import { ApplicantPepSactionsMatchStatusChange } from '../applicant-pep-sactions-match-status-change/applicant-pep-sactions-match-status-change'

type Props = {
  applicantId: ApplicantId
  selectedGroupId?: string
}

export function ApplicantPepSactionsResult({
  applicantId,
  selectedGroupId,
}: Props) {
  const t = useI18n(APPLICANT_PEP_SACTIONS_KEY, {
    keys: applicantPepSactionsEn,
  })

  const { data } = useApplicantPepSactionsQuery({ applicantId })
  const noOfMatches = data?.matches.length ?? 0

  const {
    currIdx: matchIdx,
    getNextIdx,
    getPrevIdx,
    setCurrIdx,
  } = useCircularIterator({
    noOfItems: noOfMatches,
  })

  const { matchStatus, groupId } = useMemo(() => {
    const currentMatchData = data?.matches[matchIdx]
    return {
      matchStatus: currentMatchData?.matchStatus.status,
      groupId: currentMatchData?.groupId,
    }
  }, [data?.matches, matchIdx])

  const [selectedTab, setSelectedTab] =
    useState<ApplicantPepSactionsResultTabsMappingTypes>('keyData')

  const tabs = useMemo(
    () =>
      [
        { label: t('tabs.keyData'), value: 'keyData' },
        { label: t('tabs.adverseMedia'), value: 'adverseMedia' },
        { label: t('tabs.dataSources'), value: 'dataSource' },
      ] as {
        label: string
        value: ApplicantPepSactionsResultTabsMappingTypes
      }[],
    [t],
  )

  useEffect(() => {
    const currIndex = data?.matches.findIndex(
      match => match.groupId === selectedGroupId,
    )
    setCurrIdx(currIndex && currIndex !== -1 ? currIndex : 0)
  }, [data?.matches, selectedGroupId, setCurrIdx])

  return (
    <div className="relative flex flex-col bg-mono-white rounded-2 w-[90vw] h-[90vh]">
      <FrankieButton
        testId={{ button: applicantPepSanctionResultQa.prevBtn }}
        noStyles
        className="absolute top-[50%] left-[-40px] translate-y-[-50%]"
        singleIcon={{
          name: 'mdiChevronLeft',
          className: 'text-mono-white',
          size: 'lg',
        }}
        onClick={getPrevIdx}
      />
      <FrankieButton
        testId={{ button: applicantPepSanctionResultQa.nextBtn }}
        noStyles
        className="absolute top-[50%] right-[-40px] translate-y-[-50%]"
        singleIcon={{
          name: 'mdiChevronRight',
          className: 'text-mono-white',
          size: 'lg',
        }}
        onClick={getNextIdx}
      />
      <div className="flex-grow-0 ps-8 pe-10 py-2 flex items-center ">
        <h1 className="font-bold flex-grow"> {t('amlResult')} </h1>
        <div className="flex items-center flex-grow-0 mr-2">
          <span className="text-secondary-900 font-medium mr-2">
            {t('result')}
          </span>
          <span className="font-bold">
            {t('outOfTotal', {
              current: matchIdx + 1,
              total: noOfMatches,
            })}
          </span>
        </div>
        <div className="flex items-center flex-grow-0">
          <FrankieButton
            startIcon={{
              name: 'mdiChevronLeft',
              className: 'mr-2',
            }}
            intent="subtle"
            onClick={getPrevIdx}
          >
            {t('action.prev')}
          </FrankieButton>
          <FrankieButton
            endIcon={{
              name: 'mdiChevronRight',
              className: 'ml-2',
            }}
            intent="subtle"
            onClick={getNextIdx}
          >
            {t('action.next')}
          </FrankieButton>
        </div>
      </div>

      <ApplicantPepSactionsInfoHeader
        testId={{
          container: applicantPepSanctionResultQa.infoHeaderSearchDetails,
        }}
        className="flex-grow-0 px-8 py-2 flex items-center "
        searchDetails
        applicantId={applicantId}
      />

      <ApplicantPepSactionsInfoHeader
        testId={{ container: applicantPepSanctionResultQa.infoHeader }}
        className="flex-grow-0 px-8 py-2 flex items-center"
        returnedDetails
        matchIdx={matchIdx}
        applicantId={applicantId}
      />

      <div className="flex flex-grow-0 gap-2 mx-5 px-3 py-2 flex bg-primary-50">
        <span className="text-tertiary-grey-500 w-[120px]">
          {t('field.matchStatus')}
        </span>
        <PepSactionsMatchStatusLabel
          className="text-tertiary-grey-500 w-[230px] text-md px-4"
          matchStatus={matchStatus}
        />

        <ApplicantPepSactionsMatchStatusChange
          applicantId={applicantId}
          groupIds={groupId ? [groupId] : []}
        />
      </div>

      <div className="flex-grow overflow-auto mx-8 my-2">
        <FrankieTabs
          className="sticky top-0 bg-mono-white"
          itemsClassName="!font-normal !text-secondary-900"
          selectedItemClassName="!border-b-2 !border-tertiary-red-500"
          options={tabs}
          onTabSelect={setSelectedTab}
          selectedTab={selectedTab}
        />

        <ApplicantPepSactionsResultTabs
          type={selectedTab}
          applicantId={applicantId}
          matchIdx={matchIdx}
        />
      </div>
    </div>
  )
}
