import React, { useEffect, useMemo } from 'react'

import { FrankieTextField } from 'frankify/src'

import { INDIVIDUAL_PROFILE_KEY } from 'features/individual-profile/individual-profile.keys'
import { individualProfileEn } from 'features/individual-profile/locale/new-profile.en'
import { IndividualProfileInputTypes } from 'features/individual-profile/model/form.model'

import { stateList } from 'entities/country'
import { KycDocumentCategoryTypes } from 'entities/document'

import { SelectFormField, getError } from 'shared/form'
import { useI18n } from 'shared/i18n'

import {
  documentSubFormQa,
  individualProfileVueMigratedQa,
} from '../../../../qa/individual-profile.qa'
import { DocumentCategory } from '../document-category/document-category'
import { DocumentsSubFormProps } from '../document-sub-forms.types'

const group1 = ['ACT'] // idNumber, fullName, certificate_number(optional), certificate_date_of_print(date)
const group2 = ['NSW', 'VIC', 'WA'] // idNumber, certificate_date_of_registration(year)
const group3 = ['QLD'] // idNumber, certificate_date_of_registration(date)
const group4 = ['NT'] // idNumber, certificate_date_of_print(date), certificate_number, fullName
const group5 = ['SA'] // idNumber, certificate_date_of_print(Optional -> date), certificate_number(optional), fullName
const group6 = ['TAS'] // idNumber(optional),  fullName, certificate_date_of_registration(date)

const stateNotToShowNameField = [...group2, ...group3]
const stateWithRegistrationDateRequired = ['NSW', 'VIC', 'WA', 'TAS', 'QLD']
const stateToShowReg = [...group1, ...group4, ...group5]

const timeOptions = [...Array(100).keys()].map(i => ({
  value: `${new Date().getFullYear() - i}`,
  label: `${new Date().getFullYear() - i}`,
  key: `${new Date().getFullYear() - i}`,
}))

export function ChangeOfNameForm({
  form,
  idx,
  wrapperClasses,
}: DocumentsSubFormProps) {
  const {
    register,
    watch,
    control,
    formState: { errors },
    setValue,
    unregister,
  } = form
  const t = useI18n([INDIVIDUAL_PROFILE_KEY], { keys: individualProfileEn })

  const registrationNameRegister = useMemo(
    () =>
      register(
        `${IndividualProfileInputTypes.Document}.${idx}.${IndividualProfileInputTypes.ChangeNameCertificate}.${IndividualProfileInputTypes.RegistrationName}`,
        {
          required: true,
          shouldUnregister: true,
        },
      ),
    [idx, register],
  )

  const otherNameRegister = useMemo(
    () =>
      register(
        `${IndividualProfileInputTypes.Document}.${idx}.${IndividualProfileInputTypes.ChangeNameCertificate}.${IndividualProfileInputTypes.OtherName}`,
      ),
    [idx, register],
  )

  const selectedState = watch(
    `${IndividualProfileInputTypes.Document}.${idx}.${IndividualProfileInputTypes.State}`,
  )

  const dateOfPrintRegister = useMemo(
    () =>
      stateToShowReg.includes(selectedState) &&
      register(
        `${IndividualProfileInputTypes.Document}.${idx}.${IndividualProfileInputTypes.ChangeNameCertificate}.${IndividualProfileInputTypes.DateOfPrint}`,
        {
          required: [...group1, ...group4].includes(selectedState),
          shouldUnregister: true,
        },
      ),
    [idx, selectedState, register],
  )

  const certificateNumberRegister = useMemo(
    () =>
      stateToShowReg.includes(selectedState) &&
      register(
        `${IndividualProfileInputTypes.Document}.${idx}.${IndividualProfileInputTypes.ChangeNameCertificate}.${IndividualProfileInputTypes.CertificateNumber}`,
        { required: group4.includes(selectedState), shouldUnregister: true },
      ),
    [idx, selectedState, register],
  )

  const certificateDateOfRegistrationRegister = useMemo(
    () =>
      [...group2, ...group3, ...group6].includes(selectedState) &&
      register(
        `${IndividualProfileInputTypes.Document}.${idx}.${IndividualProfileInputTypes.ChangeNameCertificate}.${IndividualProfileInputTypes.CertificateDateOfRegistration}`,
        {
          required: stateWithRegistrationDateRequired.includes(selectedState),
          shouldUnregister: true,
        },
      ),
    [idx, selectedState, register],
  )

  const familyNameRegister = useMemo(
    () =>
      register(
        `${IndividualProfileInputTypes.Document}.${idx}.${IndividualProfileInputTypes.ChangeNameCertificate}.${IndividualProfileInputTypes.FamilyName}`,
        {
          required: true,
          shouldUnregister: true,
        },
      ),
    [idx, register],
  )

  const selectedCountry: string = watch(
    `${IndividualProfileInputTypes.Document}.${idx}.${IndividualProfileInputTypes.Country}`,
  )

  const statesOptions = useMemo(
    () =>
      Object.hasOwn(stateList, selectedCountry)
        ? stateList[selectedCountry]
        : [],
    [selectedCountry],
  )

  useEffect(() => {
    setValue(
      `${IndividualProfileInputTypes.Document}.${idx}.${IndividualProfileInputTypes.DocumentCategory}`,
      KycDocumentCategoryTypes.SECONDARY as never,
    )
  }, [idx, setValue])

  const idNumberFieldName = useMemo(
    () =>
      `${IndividualProfileInputTypes.Document}.${idx}.${IndividualProfileInputTypes.IdNumber}` as IndividualProfileInputTypes.Document,
    [idx],
  )

  // unregister id number because used in multiple components
  useEffect(
    () => () => {
      unregister(idNumberFieldName)
    },
    [idx, unregister, idNumberFieldName],
  )

  return (
    <div className={wrapperClasses}>
      <SelectFormField
        options={statesOptions}
        className="basis-[32%]"
        label={t('documentForm.stateOfRegistration')}
        placeholder={t('documentForm.stateOfRegistrationPlaceholder')}
        control={control}
        name={`${IndividualProfileInputTypes.Document}.${idx}.${IndividualProfileInputTypes.State}`}
        rules={{ required: true }}
        error={
          !!getError(
            `${IndividualProfileInputTypes.Document}.${idx}.${IndividualProfileInputTypes.State}`,
            errors,
          )
        }
        testId={{
          input: individualProfileVueMigratedQa.document.region,
        }}
      />

      <FrankieTextField
        className="basis-[32%]"
        label={`${t('documentForm.registrationNumber')} ${
          ['TAS'].includes(selectedState) ? t('optional') : ''
        }`}
        placeholder={t('documentForm.registrationNumber')}
        error={!!getError(idNumberFieldName, errors)}
        {...register(idNumberFieldName, {
          required: !['TAS'].includes(selectedState),
          shouldUnregister: true,
        })}
        testId={{
          input: individualProfileVueMigratedQa.document.idNumberField,
        }}
      />

      <div className="basis-[32%]" />

      {stateNotToShowNameField.includes(selectedState) ? null : (
        <div className="basis-[100%]">
          <div className="pb-1 font-bold">
            {t('documentForm.registeredName')}
          </div>
          <div className={`${wrapperClasses} w-full`}>
            <FrankieTextField
              className="basis-[32%]"
              label={t('documentForm.firstGiveName')}
              placeholder={t('documentForm.firstGiveName')}
              {...registrationNameRegister}
              error={!!getError(registrationNameRegister.name, errors)}
              testId={{
                input: individualProfileVueMigratedQa.document.fullGivenName,
              }}
            />
            <FrankieTextField
              className="basis-[32%]"
              label={`${t('documentForm.otherGivenName')} ${t('optional')}`}
              placeholder={`${t('documentForm.otherGivenName')} ${t(
                'optional',
              )}`}
              {...otherNameRegister}
              testId={{
                input: individualProfileVueMigratedQa.document.fullNameOther,
              }}
            />
            <FrankieTextField
              className="basis-[32%]"
              label={t('documentForm.familyName')}
              placeholder={t('documentForm.familyName')}
              {...familyNameRegister}
              error={!!getError(familyNameRegister.name, errors)}
              testId={{
                input: individualProfileVueMigratedQa.document.fullNameFamily,
              }}
            />
          </div>
        </div>
      )}

      {certificateNumberRegister && (
        <FrankieTextField
          className="basis-[32%]"
          label={`${t('documentForm.certificateNumber')} ${
            group4.includes(selectedState) ? '' : t('optional')
          }`}
          {...certificateNumberRegister}
          error={!!getError(certificateNumberRegister.name, errors)}
          placeholder={t('documentForm.certificateNumber')}
        />
      )}

      {dateOfPrintRegister && (
        <FrankieTextField
          type="date"
          className="basis-[32%]"
          label={`${t('documentForm.dateOfPrint')} ${
            [...group4, ...group1].includes(selectedState) ? '' : t('optional')
          }`}
          {...dateOfPrintRegister}
          error={!!getError(dateOfPrintRegister.name, errors)}
          placeholder={t('documentForm.datePlaceholder')}
        />
      )}

      {certificateDateOfRegistrationRegister &&
        (group2.includes(selectedState) ? (
          <SelectFormField
            className="basis-[32%]"
            options={timeOptions}
            control={control}
            name={certificateDateOfRegistrationRegister.name}
            label={`${t('documentForm.registrationDate')} ${
              stateWithRegistrationDateRequired.includes(selectedState)
                ? ''
                : t('optional')
            }`}
            shouldUnregister
            placeholder={t('documentForm.registrationDatePlaceholder')}
          />
        ) : (
          <FrankieTextField
            className="basis-[32%]"
            type="date"
            label={`${t('documentForm.registrationDate')}`}
            {...certificateDateOfRegistrationRegister}
            error={
              !!getError(certificateDateOfRegistrationRegister.name, errors)
            }
            placeholder={t('documentForm.registrationDate')}
          />
        ))}

      <DocumentCategory
        disabled
        className="basis-[32%]"
        form={form}
        idx={idx}
        data-qa={documentSubFormQa.docCategory}
      />
    </div>
  )
}
