import React from 'react'

import { CommentHOC, CommentsTypes } from 'entities/comments'

import { individualAmlScreeningQa } from '../../qa/individul-aml-screening.qa'
import { IndividualAmlScreeningDataGrid } from '../individual-aml-screening-data-grid/individual-aml-screening-data-grid'
import { IndividualAmlScreeningSummary } from '../individual-aml-screening-summary/individual-aml-screening-summary'

type Props = {
  entityId: string
  workflowExecutionId?: string
  defaultOffset?: number
  historyPage?: boolean
}

export function IndividualAmlScreening({
  entityId,
  workflowExecutionId,
  defaultOffset,
  historyPage = false,
}: Props) {
  return (
    <div
      className="w-full flex flex-col gap-4 h-full"
      data-qa={individualAmlScreeningQa.container}
    >
      <IndividualAmlScreeningSummary
        workflowExecutionId={workflowExecutionId}
        entityId={entityId}
        historyPage={historyPage}
      />
      <CommentHOC
        commentType={
          historyPage ? CommentsTypes.AMLHISTORY : CommentsTypes.AMLSCREENING
        }
        entityId={entityId}
      >
        <IndividualAmlScreeningDataGrid
          workflowExecutionId={workflowExecutionId}
          entityId={entityId}
          defaultOffset={defaultOffset}
          historyPage={historyPage}
        />
      </CommentHOC>
    </div>
  )
}
