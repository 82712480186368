import React from 'react'

import { useQuery } from '@tanstack/react-query'

import { FrankieLoader } from 'frankify/src'

import { abrSearchAPI } from 'features/abr-search-australia/api/abr-search-australia.api'
import { OrganisationInfoRow } from 'features/kyb-search-v2/ui/organisation-info-row/organisation-info-row'
import { ORGANISATION_SEARCH_KEY } from 'features/organisation-search'

import { DateFormatTypes, formatDate } from 'shared/date-time'
import { useI18n } from 'shared/i18n'
import { Nullable } from 'shared/typescript'

import { ABRCompany, ABRBusinessName } from '../../model/abr-search-model'
import { AbrOrganisationChecks } from '../abr-organisation-checks/abr-ogranisation-checks'

type Props = {
  selectedOrganisationNumber: { abn: string; acn: Nullable<string> }
}

export function AbrOrganisationSummary({ selectedOrganisationNumber }: Props) {
  const t = useI18n([ORGANISATION_SEARCH_KEY])

  const { data: organisation, isFetching: isLoadingOrganisationByNumber } =
    useQuery<ABRCompany[] | undefined>({
      queryKey: ['abr-search', selectedOrganisationNumber],
      queryFn: async () => {
        const { data } = await abrSearchAPI.searchOrganisation(
          selectedOrganisationNumber.abn,
        )

        return data
      },
    })

  const getAbnStatus = (companyData: ABRCompany) => {
    let status = ''
    if (companyData.abnStatus) {
      const effectiveString = companyData.abnStatus.effectiveFrom
        ? t('info.values.abnStatus', {
            status: companyData.abnStatus.status,
            from: companyData.abnStatus.effectiveFrom,
          })
        : ''
      status = effectiveString
    }
    return status
  }

  const getBusinessNames = (businessNames: ABRBusinessName[]): string => {
    const businessStrArr = []
    for (const business of businessNames) {
      const effectiveToStr = business.effectiveFrom
        ? t('info.values.businessName', {
            name: business.name,
            from: formatDate(
              business.effectiveFrom,
              DateFormatTypes.DateNumbers,
            ),
          })
        : ''
      businessStrArr.push(effectiveToStr)
    }

    return businessStrArr.length
      ? businessStrArr.join('\n')
      : t('info.values.noCurrentBusinessNames')
  }

  const getHistoricalBusinessNames = (businessNames: ABRBusinessName[]) => {
    const businessStrArr: string[] = []
    for (const business of businessNames) {
      businessStrArr.push(
        t('info.values.historicalBusinessName', {
          name: business.name,
          from: business.effectiveFrom,
          to: business.effectiveTo,
        }),
      )
    }
    return businessStrArr.length > 0
      ? businessStrArr.join('\n')
      : t('info.values.noHistoricalBusinessNames')
  }

  const getFields = (abrOrganisation: ABRCompany) => {
    const fields: { title: string; value: string }[] = []

    fields.push({
      title: 'info.mainName',
      value: abrOrganisation.name,
    })

    if (abrOrganisation.postalCode && abrOrganisation.postalCode) {
      fields.push({
        title: 'info.location',
        value: `${abrOrganisation.postalCode} ${abrOrganisation.state}`,
      })
    }

    if (abrOrganisation.type) {
      fields.push({
        title: 'info.type',
        value: abrOrganisation.type.replace(/\s*\/\s*/g, ' / '),
      })
    }

    if (abrOrganisation.abn) {
      fields.push({
        title: 'info.abn',
        value: abrOrganisation.abn,
      })
    }

    if (abrOrganisation.acn) {
      fields.push({
        title: 'info.acn',
        value: abrOrganisation.acn,
      })
    }

    if (abrOrganisation.abnStatus) {
      fields.push({
        title: 'info.abnStatus',
        value: getAbnStatus(abrOrganisation),
      })
    }

    fields.push({
      title: 'info.businessNames',
      value: getBusinessNames(abrOrganisation.businessNames),
    })

    fields.push({
      title: 'info.historicalBusinessNames',
      value: getHistoricalBusinessNames(
        abrOrganisation.historicalBusinessNames,
      ),
    })

    return fields
  }

  return (
    <FrankieLoader loading={isLoadingOrganisationByNumber}>
      <div className="min-h-[277px]">
        <div className="text-lg font-bold text-tertiary-grey-800 mb-4">
          {t('info.title')}
        </div>
        {organisation?.length && (
          <div className="w-full border-t-[1px] border-solid border-tertiary-grey-200">
            {getFields(organisation[0]).map(row => (
              <OrganisationInfoRow
                field={t(row.title)}
                value={row.value}
                key={row.title}
              />
            ))}
          </div>
        )}
        {organisation?.length && (
          <div className="mt-8">
            <AbrOrganisationChecks abrOrganisation={organisation[0]!} />
          </div>
        )}
      </div>
    </FrankieLoader>
  )
}
