import { useMutation } from '@tanstack/react-query'

import { ErrorResponse } from 'shared/http'

import {
  NewProfileCheckResponse,
  individualProfileApi,
} from '../api/individual-profile.api'

type Args = {
  entityId: string
  options?: {
    forceCheck: boolean
    uploadDocs: boolean
    noInvalidate: boolean
  }
}

export const useNewProfileCheck = (
  handleSuccess: (data: NewProfileCheckResponse) => void,
  handleCreditHeaderIssue: (
    message: string,
    handleSuccess: () => void,
    entityId: string,
  ) => void,
  handleError?: (error: ErrorResponse) => void,
) => {
  const mutation = useMutation({
    mutationFn: ({ entityId, options }: Args) =>
      individualProfileApi.newProfileCheck(entityId, options),
    onSuccess: response => {
      const { creditHeaderIssueMessage } = response.data
      const { serviceDisplayableError } = response.data

      if (serviceDisplayableError && creditHeaderIssueMessage) {
        handleCreditHeaderIssue(
          creditHeaderIssueMessage,
          () => handleSuccess(response.data),
          response.data.entityId,
        )
      } else {
        handleSuccess(response.data)
      }
    },
    onError: handleError,
  })
  return mutation
}
