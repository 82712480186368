import { useCallback } from 'react'

import { useMutation, useQueries } from '@tanstack/react-query'

import { DocumentScansQueryKey, documentApi } from 'entities/document'

import { convertDataURItoBlob, makeDataURL } from 'shared/file'
import { ErrorResponse } from 'shared/http'
import { useI18n } from 'shared/i18n'
import { notification } from 'shared/notification'

import { APPLICANT_SUPPORTING_DOCUMENTS_KEY } from '../../applicant-supporting-documents.key'
import { applicantSupportingDocumentsEn } from '../../locale/applicant-supporting-documents.en'

export const useMultiDocumentScansQuery = (documentIds: string[]) =>
  useQueries({
    queries: documentIds.map(documentId => ({
      enabled: !!documentIds.length,
      queryKey: [DocumentScansQueryKey, documentId],
      queryFn: async () => {
        const { data } = await documentApi.getDocumentScans(documentId)
        return data
      },
    })),
  })

/**
 * Use to download the applicant Scanned document
 */
export const useApplicantDocumentDownload = ({
  disableDownload = false,
  showNoScanError = true,
}) => {
  const t = useI18n([APPLICANT_SUPPORTING_DOCUMENTS_KEY], {
    keys: applicantSupportingDocumentsEn,
  })

  const { mutate, ...restMutation } = useMutation({
    mutationFn: ({ documentId }: { documentId: string }) =>
      documentApi.getDocumentScans(documentId),
    onSuccess: res => {
      const scans = res.data.sort(
        (a, b) =>
          new Date(b.scanCreated).valueOf() - new Date(a.scanCreated).valueOf(),
      )

      if (showNoScanError && scans.length === 0) {
        notification.error(t('errorNoScan'))
        return
      }

      if (disableDownload) return

      const scan = scans[0]
      const base64 = makeDataURL(scan.mimeType || 'application/pdf', scan.file)
      const blob = convertDataURItoBlob(base64)
      const temp_url = window.URL.createObjectURL(blob)
      window.open(temp_url, '_blank')
    },
    onError: (error: ErrorResponse) => {
      notification.error(error.response?.data.message)
    },
  })

  const downloadReport = useCallback(
    (documentId: string) => {
      mutate({ documentId })
    },
    [mutate],
  )

  return { downloadReport, ...restMutation }
}
