import React from 'react'

import { useMutation, useQueryClient } from '@tanstack/react-query'

import { ApplicantId } from 'entities/applicant'

import { I18nFunction } from 'shared/i18n'
import { notification } from 'shared/notification'

import { applicantSupportingDocumentApi } from '../../api/applicant-supporting-document.api'
import { applicantSupportingDocumentsEn } from '../../locale/applicant-supporting-documents.en'
import {
  IBusinessDocumentsCatalogRes,
  IPurchaseDocumentResponse,
  PurchaseDocumentPayload,
} from '../../model/applicant-supporting-documents.model'
import {
  BusinessDocumentsCatalogQueryKey,
  useBusinessDocumentsCatalogQuery,
} from '../business-documents-catalog-query/business-documents-catalog-query'

type Args = {
  applicantId: ApplicantId
  t: I18nFunction<typeof applicantSupportingDocumentsEn>
}

export const useBusinessDocumentPurchaseMutation = ({
  applicantId,
  t,
}: Args) => {
  const { data: documentsCatalog } = useBusinessDocumentsCatalogQuery({
    applicantId,
  })
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (args: PurchaseDocumentPayload) =>
      applicantSupportingDocumentApi.businessDocumentPurchase(args),
    onSuccess: ({ data }, variables) => {
      const succeeded: { [x: string]: IPurchaseDocumentResponse['value'] } = {}
      let failed = 0
      data.forEach(purchaseResponse => {
        if (purchaseResponse.status === 'fulfilled') {
          succeeded[purchaseResponse.value.reportStatus.reportCode] =
            purchaseResponse.value
        } else {
          failed += 1
        }
      })

      const successfulCount = Object.values(succeeded).length

      if (successfulCount) {
        const newData: IBusinessDocumentsCatalogRes['reports'] = []
        for (const report of documentsCatalog?.data.reports || []) {
          const recordStatus = succeeded[report.reportCode]?.reportStatus
          if (recordStatus) {
            newData.push({
              ...report,
              recordStatus,
            })
          } else {
            newData.push(report)
          }
        }

        const newDocumentsCatalog = {
          ...documentsCatalog,
          data: { ...documentsCatalog?.data, reports: newData },
        }
        queryClient.setQueryData(
          [BusinessDocumentsCatalogQueryKey, variables.applicantId],
          newDocumentsCatalog,
        )
      }

      const total = data.length
      let nType: 'error' | 'info' | 'success' = 'success'
      if (failed === total) {
        nType = 'error'
      } else if (failed > 0) {
        nType = 'info'
      }

      notification[nType](
        <div>
          <div className="font-semibold">
            {t(`bulkActionMessage.${nType}.text`)}
          </div>
          <div>
            {t(`bulkActionMessage.${nType}.subtext`, {
              success: successfulCount,
              total,
              fail: failed,
            })}
          </div>
        </div>,
        { autoClose: 5000 },
      )
    },
  })
}
