import { bffClient, IClient } from 'shared/client'

export class DashboardAPI {
  client: IClient

  constructor(client: IClient) {
    this.client = client
  }

  async getAnalyticsCSV(data?: [Date, Date]) {
    return this.client.get<BlobPart>('data/v1/analytics.csv', {
      params: {
        startDate: data?.[0],
        endDate: data?.[1],
        selectedCustomerIds: 'own',
      },
    })
  }

  async generateReport(data: {
    password: string
    reportName: 'full'
    requester: string
  }) {
    return this.client.post('data/v1/generate-report', data)
  }
}
export const dashboardApi = new DashboardAPI(bffClient)
