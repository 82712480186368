import { useMutation } from '@tanstack/react-query'

import { applicantBiometricsApi } from '../../api/applicant-biometrics-ocr.api'
import { StatusUpdatePayload } from '../../model/applicant-biometrics-ocr.model'
import { useApplicantBiometricsOcrChecksQuery } from '../applicant-biometrics-ocr-check-state/applicant-biometrics-ocr-check.state'
import { useApplicantBiometricsOcrQuery } from '../applicant-biometrics-ocr-query/applicant-biometrics-ocr.query'

type Args = {
  applicantId: string
}

type MutateArgs = StatusUpdatePayload

export function useApplicantBiometricsStatusChange({ applicantId }: Args) {
  const { refetch } = useApplicantBiometricsOcrQuery({ applicantId })
  const { updateChecksData } = useApplicantBiometricsOcrChecksQuery({
    applicantId,
  })

  return useMutation({
    mutationFn: (data: MutateArgs) =>
      applicantBiometricsApi.changeBiometricsStatus(applicantId, data),
    onSuccess: (_, res) => {
      void refetch()
      updateChecksData({ updatedBiometricStatus: res.status })
    },
  })
}
