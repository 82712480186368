import {
  APPLICANT_SUPPORTING_DOCUMENTS_PAGE_KEY,
  applicantSupportingDocumentPageEn,
} from 'pages/applicant-supporting-documents'
import { blocklistEn, BLOCKLIST_PAGE_KEY } from 'pages/blocklist'
import { EDIT_ENTITY_KEY, editEntityEn } from 'pages/edit-entity'
import {
  INDIVIDUAL_AML_HISTORY_KEY,
  individualAmlHistoryEn,
} from 'pages/individual-aml-history'
import {
  INDIVIDUAL_AML_RESULT_PAGE_KEY,
  IndividualAmlResultPageEn,
} from 'pages/individual-aml-result'
import {
  INDIVIDUAL_AML_SCREENING_PAGE_KEY,
  individualAmlScreeningPageEn,
} from 'pages/individual-aml-screening'
import { NEW_PROFILE_KEY, newProfileEn } from 'pages/new-profile'
import {
  PASSWORD_RESET_PAGE_LOCALE,
  passwordResetPageEn,
} from 'pages/password-reset'
import {
  PASSWORD_RESET_SENT_PAGE_LOCALE,
  passwordResetSentPageEn,
} from 'pages/reset-password-sent'
import { RESOLVE_ENTITY_KEY, resolveEntityEn } from 'pages/resolve-entity'
import {
  SETTINGS_NOTIFICATIONS_PAGE_KEY,
  settingsNotificationsPageEn,
} from 'pages/settings-notifications'
import {
  SETTINGS_ORGANISATION_PAGE_KEY,
  settingsOrganisationPageEn,
} from 'pages/settings-organisation'
import {
  SETTINGS_PERSONAL_PAGE_KEY,
  settingsPersonalPageEn,
} from 'pages/settings-personal'
import {
  SETTINGS_ROLE_EDIT_PAGE_KEY,
  settingsRoleEditEn,
} from 'pages/settings-role-edit'
import {
  SETTINGS_ROLE_LIST_PAGE_KEY,
  settingsRoleListEn,
} from 'pages/settings-role-list'
import {
  SETTINGS_ROLE_NEW_PAGE_KEY,
  settingsRoleNewEn,
} from 'pages/settings-role-new'
import {
  SETTINGS_ROLE_VIEW_PAGE_KEY,
  settingsRoleViewEn,
} from 'pages/settings-role-view'
import {
  SETTINGS_USER_LIST_PAGE_KEY,
  settingsUserListEn,
} from 'pages/settings-user-list'

export const pages = {
  [PASSWORD_RESET_PAGE_LOCALE]: passwordResetPageEn,
  [PASSWORD_RESET_SENT_PAGE_LOCALE]: passwordResetSentPageEn,
  [SETTINGS_NOTIFICATIONS_PAGE_KEY]: settingsNotificationsPageEn,
  [SETTINGS_ORGANISATION_PAGE_KEY]: settingsOrganisationPageEn,
  [SETTINGS_PERSONAL_PAGE_KEY]: settingsPersonalPageEn,
  [SETTINGS_ROLE_LIST_PAGE_KEY]: settingsRoleListEn,
  [SETTINGS_USER_LIST_PAGE_KEY]: settingsUserListEn,
  [SETTINGS_ROLE_VIEW_PAGE_KEY]: settingsRoleViewEn,
  [SETTINGS_ROLE_EDIT_PAGE_KEY]: settingsRoleEditEn,
  [SETTINGS_ROLE_NEW_PAGE_KEY]: settingsRoleNewEn,
  [NEW_PROFILE_KEY]: newProfileEn,
  [BLOCKLIST_PAGE_KEY]: blocklistEn,
  [APPLICANT_SUPPORTING_DOCUMENTS_PAGE_KEY]: applicantSupportingDocumentPageEn,
  [EDIT_ENTITY_KEY]: editEntityEn,
  [RESOLVE_ENTITY_KEY]: resolveEntityEn,
  [INDIVIDUAL_AML_HISTORY_KEY]: individualAmlHistoryEn,
  [INDIVIDUAL_AML_RESULT_PAGE_KEY]: IndividualAmlResultPageEn,
  [INDIVIDUAL_AML_SCREENING_PAGE_KEY]: individualAmlScreeningPageEn,
}
