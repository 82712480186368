import { useMutation, useQueryClient } from '@tanstack/react-query'

import { IRole } from 'entities/role'

import { RoleListQueryKey } from './role-list.query'
import { roleManagementApi } from '../../api/role-management.api'
import { IRoleConfig, serializeRole } from '../../model/role-management.model'

export const useCreateRoleMutation = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ roleConfig }: { roleConfig: IRoleConfig }) =>
      roleManagementApi.createRole(roleConfig),
    onSuccess: ({ data: newRole }) => {
      const roles = queryClient.getQueryData<IRole[]>(RoleListQueryKey)
      if (roles) {
        const newRoles: IRole[] = [...roles, serializeRole(newRole)]
        queryClient.setQueryData(RoleListQueryKey, newRoles)
      }
    },
  })
}
