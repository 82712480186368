import React from 'react'

import { Navigate, useLocation } from 'react-router-dom'

import { useSessionQuery } from 'entities/session'

import { StorageKeyTypes, getLocalStorageState } from 'shared/local-storage'

export function PrivateRoute({
  children,
  loginPath,
}: {
  children: JSX.Element
  loginPath: string
}): JSX.Element {
  const path = useLocation()

  const { data: session } = useSessionQuery()
  const [token] = getLocalStorageState(StorageKeyTypes.Token)

  if (!session?.user && !token) {
    return <Navigate to={loginPath} state={{ redirectUrl: path }} replace />
  }

  return children
}
