import React, { useMemo } from 'react'

import { useI18n } from 'shared/i18n'
import { capitalizeStringBySeparator } from 'shared/string'

import { APPLICANT_FRAUD_CHECK_KEY } from '../../applicant-fraud-check.key'
import { applicantFraudCheckEn } from '../../locale/applicant-fraud-check.en'
import { applicantFraudCheckBusinessDetailsQa } from '../../qa/applicant-fraud-check.qa'
import { useApplicantFraudCheckSummary } from '../../state/applicant-fraud-check-state/applicant-fraud-check-state'

type Props = {
  applicantId: string
  testId?: { container?: string }
}

export function FraudCheckBusinessDetails({
  applicantId,
  testId = { container: '' },
}: Props) {
  const data = useApplicantFraudCheckSummary({ applicantId })

  const t = useI18n([APPLICANT_FRAUD_CHECK_KEY], {
    keys: applicantFraudCheckEn,
  })

  const businessDetails = useMemo(
    () =>
      data?.businessDetail
        ? [
            {
              label: t('entityDetails.business'),
              value: data.businessDetail.name,
              className: 'basis-[33.3%]',
            },
            {
              label: t('entityDetails.phone'),
              value: data.businessDetail.phone,
              className: 'basis-[12.5%]',
            },
            {
              label: t('entityDetails.address'),
              value: data.businessDetail.address,
              className: 'basis-[33.3%]',
            },
            {
              label: t('entityDetails.abn'),
              value: data.businessDetail.abn,
              className: 'basis-[12.5%]',
            },
          ]
        : [
            {
              label: t('entityDetails.search'),
              value: data?.applicantDetail.search,
              className: 'basis-[12.5%]',
            },
            {
              label: t('entityDetails.phone'),
              value: data?.applicantDetail.phone,
              className: 'basis-[16%]',
            },
            {
              label: t('entityDetails.address'),
              value: data?.applicantDetail.address,
              className: 'basis-[33.3%]',
            },
            ...(data?.documents.map(doc => {
              const idType = capitalizeStringBySeparator(doc.idType)
              return { label: idType, value: doc.idNumber, className: '' }
            }) || []),
          ],
    [data, t],
  )
  return (
    <div data-qa={testId.container}>
      <h4
        className="px-10 font-bold"
        data-qa={applicantFraudCheckBusinessDetailsQa.heading}
      >
        {t('fraudCheckResult')}
      </h4>
      <div className="bg-neutral-20 px-10 py-3 mt-4 flex w-full">
        {businessDetails.map(detail => (
          <div key={detail.label} className={`${detail.className} pr-6 `}>
            <div className="text-neutral-90">{detail.label}</div>
            <div className="font-medium">{detail.value}</div>
          </div>
        ))}
      </div>
    </div>
  )
}
