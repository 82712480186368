export enum DocumentIdTypes {
  DRIVERS_LICENSE = 'DRIVERS_LICENCE', // License is spelled wrong in vue code, so keeping its value as is.
  PASSPORT = 'PASSPORT',
  VISA = 'VISA',
  MEDICARE_CARD = 'NATIONAL_HEALTH_ID',
  IMMIGRATION = 'IMMIGRATION',
  BIRTH_CERT = 'BIRTH_CERT',
  CITIZENSHIP = 'CITIZENSHIP',
  NAME_CHANGE = 'NAME_CHANGE',
  MARRIAGE_CERT = 'MARRIAGE_CERT',
  ATTESTATION = 'ATTESTATION',
  VEHICLE_REGISTRATION = 'VEHICLE_REGISTRATION',
  SELF_IMAGE = 'SELF_IMAGE',
  OTHER = 'OTHER',
  NATIONAL_ID = 'NATIONAL_ID',
  BANK_STATEMENT = 'BANK_STATEMENT',
  TAX_ID = 'TAX_ID',
  PAN = 'PAN',
  VOTER_ID = 'VOTER_ID',
  MOTOR_VEHICLE_REGISTRATION = 'motorVehicleRegistration',
}

export enum KycDocumentCategoryTypes {
  PRIMARY_PHOTOGRAPHIC = 'primary_photographic',
  PRIMARY_NON_PHOTOGRAPHIC = 'primary_nonphotographic',
  SECONDARY = 'secondary',
}
