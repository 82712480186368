import React, { useEffect } from 'react'

import { UseFormReturn } from 'react-hook-form'

import { ConfirmationFormField, getError } from 'shared/form'
import { useI18n } from 'shared/i18n'

import { INDIVIDUAL_PROFILE_F2_KEY } from '../../individual-profile-f2.key'
import { individualProfileF2En } from '../../locale/individual-profile-f2.en'
import { IIndividualProfileF2FormType } from '../../model/individual-profile-f2-form.model'
import { consentFormF2Qa } from '../../qa/individual-profile-f2.qa'

export type Props = {
  form: UseFormReturn<IIndividualProfileF2FormType>
}

export function ConsentForm({ form }: Props) {
  const {
    watch,
    control,
    formState: { errors },
  } = form

  const t = useI18n([INDIVIDUAL_PROFILE_F2_KEY], {
    keys: individualProfileF2En,
  })

  const isEKyc = watch('eKyc')
  const consent = watch('confirmation')

  useEffect(() => {
    if (typeof consent === 'boolean') {
      void form.trigger('confirmation')
    }
  }, [consent])

  return (
    <div data-qa={consentFormF2Qa.wrapper}>
      <div
        data-qa={consentFormF2Qa.title}
        className="text-lg mb-[14px] font-bold"
      >
        {t('consentForm.title')}
      </div>

      <div className="flex py-2">
        <ConfirmationFormField
          control={control}
          name="confirmation"
          testId={{ input: consentFormF2Qa.checkbox }}
          label={
            <span
              className={`ml-1 text-tertiary-grey-700 text-sm font-normal -mt-2 ${
                getError('confirmation', errors) ? 'text-tertiary-red-700' : ''
              }`}
            >
              {isEKyc ? t('consentForm.eKyc') : t('consentForm.mKyc')}
            </span>
          }
        />
      </div>
    </div>
  )
}
