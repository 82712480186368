export const userManagementEn = {
  usersTable: {
    notification: {
      resetPassword: 'Password reset email has been sent to {{name}}',
      locked: '{{name}}‘s account has been successfully locked',
      unlocked: '{{name}}‘s account has been successfully unlocked',
    },
  },
  createUserModal: {
    header: 'Add new user',
    form: {
      fullName: 'Full Name',
      fullNamePlaceholder: 'Full name',
      email: 'Email Address',
      emailPlaceholder: 'Email',
      emailPattern: 'Please enter a valid email address',
      emailUnique: 'Email already in use.',
      roles: 'Roles',
      loader: 'Creating user...',
    },
    addUserButton: 'Add user',
    cancel: 'Cancel',
    notification: {
      created: 'New user created. A welcome email has been sent to their inbox',
      error:
        'There was an error while creating a profile for {{name}}. Please try again',
    },
  },
  updateUserModal: {
    header: 'Edit user details',
    updateUserButton: 'Update user',
    loader: 'Updating user...',
    notification: {
      updated: '{{name}}’s profile has been updated',
      error:
        'There was an error while updating the profile for {{name}}. Please try again',
    },
  },
  deleteUserModal: {
    heading: 'Are you sure you want to remove',
    subHeading: 'You will not be able to undo this action.',
    cancelButton: 'No, cancel',
    confirmButton: 'Yes I’m sure',
    notification: {
      deleted: '{{name}} has been removed',
    },
  },
}
