import { I18nFunction } from 'shared/i18n'

import { IndividualProfileInputTypes } from './form.model'
import { individualProfileEn } from '../locale/new-profile.en'

export type AddressSuggestionResponse = {
  label: string
  value: string
}

export const fieldMap: Partial<
  Record<keyof DetailAddressResponse, IndividualProfileInputTypes>
> = {
  buildingName: IndividualProfileInputTypes.PropertyName,
  unitNumber: IndividualProfileInputTypes.UnitNumber,
  streetNumber: IndividualProfileInputTypes.StreetNumber,
  streetName: IndividualProfileInputTypes.StreetName,
  streetType: IndividualProfileInputTypes.StreetType,
  state: IndividualProfileInputTypes.State,
  postalCode: IndividualProfileInputTypes.PostalCode,
  town: IndividualProfileInputTypes.Town,
  suburb: IndividualProfileInputTypes.Suburb,
  district: IndividualProfileInputTypes.District,
  city: IndividualProfileInputTypes.Town,
}

export type DetailAddressResponse = {
  buildingName: string | null
  postalCode: string | null
  state: string | null
  streetNumber: string | null
  streetType: string | null
  suburb: string | null
  town: string | null
  unitNumber: string | null
  addressType: string | null
  country: string | null
  addressId: string | null
  district: string | null
  city: string | null
  data: {
    longForm: string | null
  }
  isAddedByCustomer: string | null
  streetName: string | null
  longForm: string | null
}

export type LabelMapTypes = {
  [key: string]: {
    label: {
      countries: string[]
      value: string
    }[]
    defaultLabel: string
  }
}

export type Places = {
  label: string
  value: string
}

export enum CountryTypes {
  AUS = 'AUS',
  NZL = 'NZL',
  GBR = 'GBR',
  CAN = 'CAN',
  USA = 'USA',
  INTERNATIONAL = 'INTERNATIONAL',
}

export const optionalAddressFieldMap = {
  [IndividualProfileInputTypes.State]: [
    CountryTypes.AUS,
    CountryTypes.CAN,
    CountryTypes.USA,
    CountryTypes.INTERNATIONAL,
  ],
  [IndividualProfileInputTypes.Suburb]: [
    CountryTypes.NZL,
    CountryTypes.GBR,
    CountryTypes.CAN,
    CountryTypes.INTERNATIONAL,
  ],
}

export const NON_INTERNATIONAL_COUNTRIES = [
  CountryTypes.AUS,
  CountryTypes.NZL,
  CountryTypes.GBR,
  CountryTypes.CAN,
  CountryTypes.USA,
]

export const labelMapData = (t: I18nFunction<typeof individualProfileEn>) => {
  const labelMap: LabelMapTypes = {
    propertyName: {
      label: [
        {
          countries: [
            ...NON_INTERNATIONAL_COUNTRIES,
            CountryTypes.INTERNATIONAL,
          ],
          value: t('addressFrom.propertyName'),
        },
      ],
      defaultLabel: t('addressFrom.propertyName'),
    },
    unitNumber: {
      label: [
        {
          countries: [
            ...NON_INTERNATIONAL_COUNTRIES,
            CountryTypes.INTERNATIONAL,
          ],
          value: t('addressFrom.unitNumber'),
        },
      ],
      defaultLabel: t('addressFrom.unitNumber'),
    },
    streetNumber: {
      label: [
        {
          countries: [
            ...NON_INTERNATIONAL_COUNTRIES,
            CountryTypes.INTERNATIONAL,
          ],
          value: t('addressFrom.streetNumber'),
        },
      ],
      defaultLabel: t('addressFrom.streetNumber'),
    },
    streetName: {
      label: [
        {
          countries: [
            ...NON_INTERNATIONAL_COUNTRIES,
            CountryTypes.INTERNATIONAL,
          ],
          value: t('addressFrom.streetName'),
        },
      ],
      defaultLabel: t('addressFrom.streetName'),
    },
    streetType: {
      label: [
        {
          countries: [
            ...NON_INTERNATIONAL_COUNTRIES,
            CountryTypes.INTERNATIONAL,
          ],
          value: t('addressFrom.streetType'),
        },
      ],
      defaultLabel: t('addressFrom.streetType'),
    },
    town: {
      label: [
        {
          countries: [CountryTypes.NZL],
          value: t('addressFrom.townCity'),
        },
        {
          countries: [CountryTypes.GBR],
          value: t('addressFrom.town'),
        },
        {
          countries: [CountryTypes.AUS],
          value: t('addressFrom.suburb'),
        },
        {
          countries: [
            CountryTypes.CAN,
            CountryTypes.USA,
            CountryTypes.INTERNATIONAL,
          ],
          value: t('addressFrom.city'),
        },
      ],
      defaultLabel: t('addressFrom.suburb'),
    },
    suburb: {
      label: [
        {
          countries: [CountryTypes.NZL],
          value: t('addressFrom.suburb'),
        },
        {
          countries: [CountryTypes.GBR],
          value: t('addressFrom.suburbLocality'),
        },
        {
          countries: [CountryTypes.CAN, CountryTypes.INTERNATIONAL],
          value: t('addressFrom.district'),
        },
      ],
      defaultLabel: t('addressFrom.suburb'),
    },
    state: {
      label: [
        {
          countries: [CountryTypes.AUS],
          value: t('addressFrom.stateTerritory'),
        },
        {
          countries: [CountryTypes.CAN],
          value: t('addressFrom.provinceTerritory'),
        },
        {
          countries: [CountryTypes.USA, CountryTypes.INTERNATIONAL],
          value: t('addressFrom.state'),
        },
      ],
      defaultLabel: t('addressFrom.stateTerritory'),
    },
    postalCode: {
      label: [
        {
          countries: [
            CountryTypes.AUS,
            CountryTypes.NZL,
            CountryTypes.GBR,
            CountryTypes.CAN,
            CountryTypes.INTERNATIONAL,
          ],
          value: t('addressFrom.postalCode'),
        },
        {
          countries: [CountryTypes.USA],
          value: t('addressFrom.zipCode'),
        },
      ],
      defaultLabel: t('addressFrom.postalCode'),
    },
  }
  return labelMap
}

export function getAddressValidation(country: string) {
  const countryMapping: Record<string, Record<string, boolean>> = {
    STATE: {
      AUS: true,
    },
    POSTALCODE: {
      AUS: true,
      NZL: true,
    },
    STREETNUMBER: {
      AUS: true,
      NZL: true,
    },
    TOWN: {
      AUS: true,
      NZL: true,
      GBR: true,
    },
    STREETNAME: {
      AUS: true,
    },
  }

  return {
    STATE: countryMapping.STATE[country] || false,
    POSTALCODE: countryMapping.POSTALCODE[country] || false,
    STREETNUMBER: countryMapping.STREETNUMBER[country] || false,
    TOWN: countryMapping.TOWN[country] || false,
    STREETNAME: countryMapping.STREETNAME[country] || false,
  }
}
