import { useCallback } from 'react'

import { useQuery, useQueryClient } from '@tanstack/react-query'

import { COMMENT_KEY } from 'entities/comments/comments.key'
import { commentsEn } from 'entities/comments/locale/comments.en'
import {
  ProcessResultManualStatusEnumAML,
  WorkflowExecutionResultEnum,
} from 'entities/entity'
import {
  workflowManualStatusMapping,
  workflowStatusMapping,
} from 'entities/entity/model/profile.model'
import {
  Audit,
  useAllAuditReportData,
} from 'entities/individual-audit-report-f2'

import { I18nFunction, useI18n } from 'shared/i18n'
import { Nullable } from 'shared/typescript'

import {
  CommentsTypes,
  FunctionNameTypes,
  SupplementaryData,
  successVariantsForAML,
  successVariantsForWorkflow,
  alertVariantsForWorkflow,
  failedVariantsForAML,
  failedVariantsForWorkflow,
  warningVariantsForWorkflow,
  decodeHtmlEntities,
} from '../../model/comments.model'

export const COMMENT_BOX_VISIBILITY_QUERY_KEY = 'commentBoxVisibility'

export type CommentPayloadType = Record<CommentsTypes, boolean>

export const useCommentBoxVisibility = ({ entityId }: { entityId: string }) => {
  const queryClient = useQueryClient()

  return useQuery<CommentPayloadType>({
    queryKey: [COMMENT_BOX_VISIBILITY_QUERY_KEY, entityId],
    queryFn: () => {
      const visibilityData = queryClient.getQueryData<CommentPayloadType>([
        COMMENT_BOX_VISIBILITY_QUERY_KEY,
        entityId,
      ])

      if (visibilityData) {
        return visibilityData
      }
      return {
        COMMENT_WORKFLOW: false,
        COMMENT_AML: false,
        COMMENT_KYC: false,
        COMMENT_PROFILE: false,
        COMMENT_AMLRESULT: false,
        COMMENT_AMLHISTORY: false,
        COMMENT_AMLSCREENING: false,
        COMMENT_PROFILE_PERSONAL_INFO: false,
        AUDIT: false,
      } as CommentPayloadType
    },
  })
}

export const useUpdateCommentBoxVisibility = ({
  entityId,
}: {
  entityId: string
  isProfileTab?: boolean
}) => {
  const queryClient = useQueryClient()

  const update = useCallback(
    ({
      payload,
      overrideState,
    }: {
      payload: CommentsTypes | CommentsTypes[]
      overrideState?: boolean
      expanded?: boolean
    }) => {
      const visibilityData = queryClient.getQueryData<CommentPayloadType>([
        COMMENT_BOX_VISIBILITY_QUERY_KEY,
        entityId,
      ])
      if (visibilityData && !Array.isArray(payload)) {
        queryClient.setQueryData<CommentPayloadType>(
          [COMMENT_BOX_VISIBILITY_QUERY_KEY, entityId],
          {
            ...visibilityData,
            [payload]: !visibilityData[payload],
          },
        )
      }
      if (visibilityData && Array.isArray(payload)) {
        const updatedData = payload.reduce(
          (acc, item) => ({
            ...acc,
            [item]: overrideState ?? !visibilityData[item],
          }),
          {} as CommentPayloadType,
        )
        queryClient.setQueryData<CommentPayloadType>(
          [COMMENT_BOX_VISIBILITY_QUERY_KEY, entityId],
          {
            ...visibilityData,
            ...updatedData,
          },
        )
      }
    },
    [queryClient, entityId],
  )

  return update
}

export const getStatusConfigForAml = (
  t: I18nFunction<typeof commentsEn>,
  status?: ProcessResultManualStatusEnumAML,
) => {
  if (!status)
    return {
      textColor: undefined,
      text: undefined,
    }
  if (successVariantsForAML.includes(status)) {
    return {
      textColor: 'text-tertiary-green-600',
      text: t(`resolvedStatus.${status}`),
    }
  }
  if (failedVariantsForAML.includes(status)) {
    return {
      textColor: 'text-tertiary-red-600',
      text: t(`resolvedStatus.${status}`),
    }
  }

  return {
    textColor: undefined,
    text: undefined,
  }
}

export function getStatusConfigForWorkflow(
  t: I18nFunction<typeof commentsEn>,
  status?: WorkflowExecutionResultEnum,
) {
  if (!status)
    return {
      textColor: undefined,
      text: undefined,
    }
  const mappedStatus =
    workflowManualStatusMapping[status] || workflowStatusMapping[status]

  if (successVariantsForWorkflow.includes(mappedStatus)) {
    return {
      textColor: 'text-tertiary-green-600',
      text: t(`resolvedStatus.${mappedStatus}`),
    }
  }
  if (failedVariantsForWorkflow.includes(mappedStatus)) {
    return {
      textColor: 'text-tertiary-red-600',
      text: t(`resolvedStatus.${mappedStatus}`),
    }
  }

  if (alertVariantsForWorkflow.includes(mappedStatus)) {
    return {
      textColor: 'text-tertiary-grey-400',
      text: t(`resolvedStatus.${mappedStatus}`),
    }
  }
  if (warningVariantsForWorkflow.includes(mappedStatus)) {
    return {
      textColor: 'text-tertiary-yellow-600',
      text: t(`resolvedStatus.${mappedStatus}`),
    }
  }

  return {
    textColor: undefined,
    text: undefined,
  }
}

// eslint-disable-next-line complexity
export type CommentArrayReturnType = {
  data: (Audit & {
    actionTaken: string
    statusComment:
      | {
          textColor: string | undefined
          text: string | undefined
        }
      | undefined
  })[]
}

// eslint-disable-next-line complexity
const getCommentArray = ({
  commentType,
  t,
  data,
  processId,
}: {
  commentType: CommentsTypes
  t: I18nFunction<typeof commentsEn>
  data?: Audit[]
  processId?: string
}): CommentArrayReturnType => {
  let commentData = []
  let createEntityData = []
  let updateEntityData = []
  let archiveEntityData = []

  switch (commentType) {
    case CommentsTypes.AMLRESULT:
    case CommentsTypes.AMLHISTORY:
    case CommentsTypes.AMLSCREENING:
      commentData =
        data
          ?.filter(Boolean)
          ?.filter(
            item =>
              item.type === 'ENTITY_RESULT_UPDATE' &&
              item.functionName === FunctionNameTypes.UPDATEAML &&
              (
                item.supplementaryData as Nullable<SupplementaryData>
              )?.resultsUpdated?.some(
                res => res.objectType === 'RESULT_PROCESS',
              ) &&
              item.userComment &&
              (processId
                ? (
                    item.supplementaryData as Nullable<SupplementaryData>
                  )?.resultsUpdated?.find(res => res.objectId === processId)
                : true),
          )
          .map(item => ({
            ...item,
            actionTaken:
              (
                (item.supplementaryData as Nullable<SupplementaryData>)
                  ?.resultsUpdated ?? []
              ).length > 1
                ? t('actionTakenText.batchResolved')
                : t('actionTakenText.resolved'),
            statusComment: getStatusConfigForAml(
              t,
              (item.supplementaryData as Nullable<SupplementaryData>)
                ?.resultsUpdated?.[0]?.newStatus,
            ),
          }))
          .sort(
            (item1, item2) =>
              new Date(item2.timestamp).valueOf() -
              new Date(item1.timestamp).valueOf(),
          ) ?? []

      return { data: commentData }
    case CommentsTypes.AML:
      commentData =
        data
          ?.filter(Boolean)
          ?.filter(
            item =>
              item.type === 'ENTITY_RESULT_UPDATE' &&
              item.functionName === FunctionNameTypes.UPDATEAML &&
              (
                item.supplementaryData as Nullable<SupplementaryData>
              )?.resultsUpdated?.some(
                res => res.objectType === 'RESULT_PROCESS',
              ) &&
              item.userComment &&
              (processId
                ? (
                    item.supplementaryData as Nullable<SupplementaryData>
                  )?.resultsUpdated?.find(res => res.objectId === processId)
                : true),
          )
          .map(item => ({
            ...item,
            actionTaken:
              (
                (item.supplementaryData as Nullable<SupplementaryData>)
                  ?.resultsUpdated ?? []
              ).length > 1
                ? t('actionTakenText.batchResolved')
                : t('actionTakenText.resolved'),
            statusComment: getStatusConfigForAml(
              t,
              (item.supplementaryData as Nullable<SupplementaryData>)
                ?.resultsUpdated?.[0]?.newStatus,
            ),
          }))
          .sort(
            (item1, item2) =>
              new Date(item2.timestamp).valueOf() -
              new Date(item1.timestamp).valueOf(),
          ) ?? []

      return { data: commentData }
    case CommentsTypes.WORKFLOW:
      commentData =
        data
          ?.filter(Boolean)
          ?.filter(
            item =>
              item.type === 'WORKFLOW_STATUS_CHANGE' &&
              item.functionName ===
                FunctionNameTypes.OVERRIDEWORKFLOWEXECUTIONRESULT &&
              item.userComment,
          )
          .map(item => ({
            ...item,
            actionTaken: `${t('actionTakenText.statusOverride')}`,
            statusComment: getStatusConfigForWorkflow(
              t,
              item.status as WorkflowExecutionResultEnum,
            ),
          }))
          .sort(
            (item1, item2) =>
              new Date(item2.timestamp).valueOf() -
              new Date(item1.timestamp).valueOf(),
          ) ?? []

      return { data: commentData }

    case CommentsTypes.PROFILE:
    case CommentsTypes.PROFILE_PERSONAL_INFO:
      createEntityData =
        data
          ?.filter(Boolean)
          ?.filter(
            item =>
              item.type === 'ENTITY_CREATE' &&
              item.functionName === FunctionNameTypes.CREATEINDIVIDUAL &&
              item.userComment,
          )
          .map(item => ({
            ...item,
            actionTaken: t('actionTakenText.entityCreated'),
            statusComment: undefined,
          })) ?? []
      updateEntityData =
        data
          ?.filter(Boolean)
          ?.filter(
            item =>
              item.type === 'ENTITY_UPDATE' &&
              item.functionName === FunctionNameTypes.UPDATEINDIVIDUAL &&
              item.userComment,
          )
          .map(item => ({
            ...item,
            actionTaken: t('actionTakenText.entityUpdated'),
            statusComment: undefined,
          })) ?? []
      archiveEntityData =
        data
          ?.filter(Boolean)
          ?.filter(
            item =>
              item.type === 'SERVICE_PROFILE_UPDATE' &&
              item.functionName === FunctionNameTypes.UPDATEPROFILE &&
              item.userComment &&
              (
                item.supplementaryData as Nullable<SupplementaryData>
              )?.fieldsUpdated?.some(
                field =>
                  field.changeDescription.includes('State is changed') &&
                  field.objectType === 'SERVICE_PROFILE',
              ),
          )
          .map(item => ({
            ...item,
            actionTaken: t('actionTakenText.entityArchived'),
            statusComment: undefined,
          })) ?? []
      commentData = [
        ...createEntityData,
        ...updateEntityData,
        ...archiveEntityData,
      ].sort(
        (item1, item2) =>
          new Date(item2.timestamp).valueOf() -
          new Date(item1.timestamp).valueOf(),
      )
      return { data: commentData }

    default:
      return { data: [] }
  }
}

// eslint-disable-next-line complexity
export const useCommentData = ({
  entityId,
  commentType,
  processId,
}: {
  entityId: string
  commentType: CommentsTypes[] | CommentsTypes
  processId?: string
}) => {
  const { data } = useAllAuditReportData({ entityId })

  const t = useI18n([COMMENT_KEY], { keys: commentsEn })
  const commentTypesArr = Array.isArray(commentType)
    ? commentType
    : [commentType]
  const commentArray = commentTypesArr
    .map(commentType => getCommentArray({ commentType, t, data, processId }))
    .flatMap(i => i.data)
    .map(comment => ({
      ...comment,
      ...(comment.userComment
        ? {
            userComment: {
              ...comment.userComment,
              ...(comment.userComment.text
                ? { text: decodeHtmlEntities(comment.userComment.text) }
                : {}),
            },
          }
        : {}),
    }))

  return { data: commentArray }
}
