import { INDIVIDUAL_AML_RESULT_KEY } from '../individual-aml-result.key'

const amlEntityCard = `${INDIVIDUAL_AML_RESULT_KEY}-aml-entity-card`
export const amlEntityCardQa = {
  container: `${amlEntityCard}-container`,
  title: `${amlEntityCard}-title`,
  sourceLabel: `${amlEntityCard}-source-label`,
  sourceValue: `${amlEntityCard}-source-value`,
  sourceUrl: `${amlEntityCard}-source-url`,
} satisfies Record<string, `${typeof amlEntityCard}-${string}`>

const amlDetailCard = `${INDIVIDUAL_AML_RESULT_KEY}-detail-card`
export const amlDetailCardQa = {
  container: `${amlDetailCard}-container`,
  title: `${amlDetailCard}-title`,
  icon: `${amlDetailCard}-icon`,
  descInfo: `${amlDetailCard}-desc-info`,
  description: `${amlDetailCard}-description`,
} satisfies Record<string, `${typeof amlDetailCard}-${string}`>

const amlEntitySummary = `${INDIVIDUAL_AML_RESULT_KEY}-aml-entity-summary`
export const amlEntitySummaryQa = {
  container: `${amlEntitySummary}-container`,
  title: `${amlEntitySummary}-title`,
  sourceLabel: `${amlEntitySummary}-source-label`,
  sourceValue: `${amlEntitySummary}-source-value`,
} satisfies Record<string, `${typeof amlEntitySummary}-${string}`>

const amlMatchData = `${INDIVIDUAL_AML_RESULT_KEY}-aml-match-data`
export const amlMatchDatQa = {
  container: `${amlMatchData}-container`,
} satisfies Record<string, `${typeof amlMatchData}-${string}`>

const amlMatchTabs = `${INDIVIDUAL_AML_RESULT_KEY}-aml-match-tabs`
export const amlMatchTabsQa = {
  container: `${amlMatchTabs}-container`,
  amlTabContainer: `${amlMatchTabs}-aml-tab-container`,
  amlTabs: `${amlMatchTabs}-aml-tabs`,
} satisfies Record<string, `${typeof amlMatchTabs}-${string}`>

const amlPersonalInfo = `${INDIVIDUAL_AML_RESULT_KEY}-aml-personal-info`
export const amlPersonalInfoQa = {
  container: `${amlPersonalInfo}-container`,
  title: `${amlPersonalInfo}-title`,
  matchStrength: `${amlPersonalInfo}-match-strength`,
  sourceLabel: `${amlPersonalInfo}-source-label`,
  sourceValue: `${amlPersonalInfo}-source-value`,
} satisfies Record<string, `${typeof amlPersonalInfo}-${string}`>

const amlResult = `${INDIVIDUAL_AML_RESULT_KEY}-aml-result`
export const amlResultQa = {
  container: `${amlResult}-container`,
} satisfies Record<string, `${typeof amlResult}-${string}`>
