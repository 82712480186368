import { APPLICANT_RISK_SCORE_KEY as ARSK } from '../applicant-risk-score.key'

const AppMnlOverride = `${ARSK}-applicant-manual-override-qa`
export const applicantManualOverrideQa = {
  component: `${AppMnlOverride}-component`,
  btn: `${AppMnlOverride}-franky-btn`,
} satisfies Record<string, `${typeof AppMnlOverride}-${string}`>

const AppRskScore = `${ARSK}-applicant-risk-score-qa`
export const applicantRiskScoreQa = {
  dataGrid: `${AppRskScore}-data-grid`,
  component: `${AppRskScore}-component`,
} satisfies Record<string, `${typeof AppRskScore}-${string}`>

const AppRskScoreGrd = `${ARSK}-applicant-risk-score-data-grid-qa`
export const applicantRiskScoreDataGridQa = {
  noData: `${AppRskScoreGrd}-no-data-text`,
} satisfies Record<string, `${typeof AppRskScoreGrd}-${string}`>

const AppRskScoreGrdHlper = `${ARSK}-applicant-risk-score-data-grid-helper-qa`
export const applicantRiskScoreDataGridHelperQa = {
  loader: `${AppRskScoreGrdHlper}-loader`,
  gridCell: `${AppRskScoreGrdHlper}-grid-cell`,
} satisfies Record<string, `${typeof AppRskScoreGrdHlper}-${string}`>
