import React, { useMemo, useRef } from 'react'

import { Trans } from 'react-i18next'
import { NavLink, useLocation } from 'react-router-dom'

import { FrankieButton } from 'frankify/src'

import { ApplicantId } from 'entities/applicant'

import { externalLinks } from 'shared/external-links'
import { useI18n } from 'shared/i18n'
import { SuggestionString } from 'shared/typescript'

import { APPLICANT_SUPPORTING_DOCUMENTS_KEY } from '../../applicant-supporting-documents.key'
import { applicantSupportingDocumentsEn } from '../../locale/applicant-supporting-documents.en'
import { appSupportDocQa } from '../../qa/applicant-support-documents.qa'
import { ApplicantAddDocuments } from '../applicant-add-documents/applicant-add-documents'
import { ApplicantUploadedDocuments } from '../applicant-uploaded-documents/applicant-uploaded-documents'

const PARAM_KEY = 'uuid'

type Props = {
  applicantId: ApplicantId
}

export function ApplicantSupportingDocuments({ applicantId }: Props) {
  const t = useI18n([APPLICANT_SUPPORTING_DOCUMENTS_KEY], {
    keys: applicantSupportingDocumentsEn,
  })

  const location = useLocation()

  const wrapperRef = useRef<HTMLDivElement>(null)

  const uuid = useMemo(() => {
    const params = new URLSearchParams(location.search)
    return params.get(PARAM_KEY) as SuggestionString<'new'> | undefined
  }, [location.search])

  const notDocument = !uuid || uuid === 'new'

  return (
    <div
      ref={wrapperRef}
      className="basis-full text-tertiary-grey-700 overflow-x-hidden px-3"
      data-qa={appSupportDocQa.wrapper}
    >
      {notDocument && (
        <div className="mb-4" data-qa={appSupportDocQa.notDoc}>
          <div className="text-xl text-secondary-900 font-bold pb-2">
            {t('addDocuments')}
          </div>
          <div>
            <Trans
              i18nKey="applicant_supporting_documents:seeSupportedDocument"
              components={[
                <a
                  key="supporting-doc-link"
                  href={externalLinks.supportingDocs}
                  target="_blank"
                  rel="noreferrer"
                  className="text-primary-800"
                >
                  here
                </a>,
              ]}
            />
          </div>
        </div>
      )}

      {uuid ? (
        <ApplicantAddDocuments documentId={uuid} applicantId={applicantId} />
      ) : (
        <NavLink
          className="block max-w-max"
          to={`${location.pathname}?${PARAM_KEY}=new`}
        >
          <FrankieButton
            intent="secondary"
            size="xs"
            startIcon={{
              name: 'mdiPlus',
              className: 'text-primary mr-1',
              size: 'xs',
            }}
            onClick={() =>
              wrapperRef.current?.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
              })
            }
            testId={{ button: appSupportDocQa.notHaveUUIDBtn }}
          >
            {t('addDocument')}
          </FrankieButton>
        </NavLink>
      )}

      {notDocument && (
        <ApplicantUploadedDocuments
          paramKey={PARAM_KEY}
          applicantId={applicantId}
          className="mt-10 mb-6"
          data-qa={appSupportDocQa.notDoc}
        />
      )}
    </div>
  )
}
