import {
  IIndividualProfileInputs,
  getDocumentTypesData,
  IndividualProfileInputTypes,
  mapAddress,
} from 'features/individual-profile'

import {
  BlocklistAttributeTypes,
  ApplicantId,
  ApplicantResponse,
} from 'entities/applicant'

import {
  BlacklistedFormInputs,
  BlocklistedInputTypes,
} from './applicant-blocklisted-form.model'

export type AddToBlocklistResponseType = {
  entity: {
    entityId: ApplicantId
    entityType: string
    extraData: [
      {
        kvpKey: string
        kvpValue: string
      },
      {
        kvpKey: string
        kvpValue: string
      },
      {
        kvpKey: string
        kvpValue: string
      },
      {
        kvpKey: string
        kvpType: string
      },
    ]
    flags: [
      {
        flag: string
        value: number
      },
    ]
  }
  requestId: string
}

export type AddToBlocklistType = Omit<
  ApplicantResponse,
  | 'applicantDetails'
  | 'businessInfo'
  | 'registryDocuments'
  | 'supportingDocuments'
  | 'trustDeedsDocuments'
> & {
  applicant: Omit<ApplicantResponse['applicantDetails'], 'fraudData'>
}

export type CheckBlocklistPayloadTypes = {
  flag: 'true_positive' | 'false_positive'
  comment: string
  reasonCode: string
  attribute: string
}

export const blocklistDataConfig: AddToBlocklistType = {
  documents: [],
  applicant: {
    entityId: null as unknown as string,
    customerReference: null,
    name: {
      givenName: null,
      middleName: null,
      familyName: null,
      displayName: null,
    },
    dateOfBirth: null,
    gender: null,
    extraData: {} as unknown as undefined,
    phoneNumber: {
      documentId: null,
      idNumber: null,
    },
    addresses: [],
    email: {
      documentId: null,
      idNumber: null,
    },
    profile: {
      profileType: 'auto',
      kycMethod: 'electronic',
      countryAlpha3: 'AUS',
      dob: null,
      checkTypes: [],
    },
    assignee: null,
    consents: [],
    blocklistAttributes: null,
    originalUboDetails: null,
  },
  checkSummary: {} as AddToBlocklistType['checkSummary'],
}

type AddToBlocklistParams = {
  blocklistAttribute: BlocklistAttributeTypes
  data: IIndividualProfileInputs
  blocklistFormData: BlacklistedFormInputs
  defaultBlocklistData: AddToBlocklistType
}
// eslint-disable-next-line complexity
export const getAddToBlocklistPayload = ({
  blocklistAttribute,
  blocklistFormData,
  data,
  defaultBlocklistData,
}: AddToBlocklistParams): AddToBlocklistType => {
  switch (blocklistAttribute) {
    case BlocklistAttributeTypes.EMAIL_ADDRESS: {
      return {
        ...defaultBlocklistData,
        applicant: {
          ...defaultBlocklistData.applicant,
          email: {
            ...defaultBlocklistData.applicant.email,
            idNumber: blocklistFormData[BlocklistedInputTypes.Email],
          },
        },
      }
    }
    case BlocklistAttributeTypes.ID_DOCUMENT: {
      const { applicant, ...rest } = defaultBlocklistData
      const documents = getDocumentTypesData(data, {
        ...rest,
        applicantDetails: {
          ...(applicant as ApplicantResponse['applicantDetails']),
        },
        businessInfo: null,
        supportingDocuments: undefined,
        registryDocuments: [],
      })

      return {
        ...defaultBlocklistData,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any
        documents: documents.map(
          ({ documentData, ...rest }) =>
            ({
              ...documentData,
              ...rest,
            } as unknown as ApplicantResponse['documents'][0]),
        ),
      }
    }
    case BlocklistAttributeTypes.NAME_AND_DOB: {
      return {
        ...defaultBlocklistData,
        applicant: {
          ...defaultBlocklistData.applicant,
          name: {
            givenName:
              data[IndividualProfileInputTypes.PersonalInfo][
                IndividualProfileInputTypes.FirstName
              ],
            middleName:
              data[IndividualProfileInputTypes.PersonalInfo][
                IndividualProfileInputTypes.MiddleName
              ],
            familyName:
              data[IndividualProfileInputTypes.PersonalInfo][
                IndividualProfileInputTypes.LastName
              ],
            displayName: null,
          },
          dateOfBirth:
            data[IndividualProfileInputTypes.PersonalInfo][
              IndividualProfileInputTypes.Dob
            ],
        },
      }
    }
    // case BlocklistAttributeTypes.ENTIRE_ENTITY: {
    //   return undefined
    // }
    case BlocklistAttributeTypes.RESIDENTIAL_ADDRESS:
    case BlocklistAttributeTypes.MAILING_ADDRESS: {
      const selectedAddressType =
        blocklistAttribute === BlocklistAttributeTypes.RESIDENTIAL_ADDRESS
          ? IndividualProfileInputTypes.CurrentResidentialAddress
          : IndividualProfileInputTypes.MailingAddress

      const address = [mapAddress(data, selectedAddressType)]

      return {
        ...defaultBlocklistData,
        applicant: {
          ...defaultBlocklistData.applicant,
          addresses: [
            ...(defaultBlocklistData.applicant.addresses ?? []),
            ...address,
          ],
        },
      }
    }
    case BlocklistAttributeTypes.PHONE_NUMBER: {
      return {
        ...defaultBlocklistData,
        applicant: {
          ...defaultBlocklistData.applicant,
          phoneNumber: {
            ...defaultBlocklistData.applicant.phoneNumber,
            idNumber: blocklistFormData[BlocklistedInputTypes.PhoneNumber],
          },
        },
      }
    }
    default:
      break
  }
  return defaultBlocklistData
}
