import React from 'react'

import {
  GridColumnHeaderParams,
  GridRowSelectionModel,
} from '@mui/x-data-grid-pro'
import { Trans } from 'react-i18next'

import {
  FrankieCheckbox,
  FrankieIcon,
  FrankieIconName,
  FrankieSelectField,
  IFrankieCheckboxProps,
} from 'frankify/src'

import { Pagination } from 'shared/data-grid'
import { useI18n } from 'shared/i18n'

import { INDIVIDUAL_AML_SCREENING_KEY } from '../../individual-aml-screening.key'
import { individualAmlScreeningEn } from '../../locale/individual-aml-screening.en'
import { individualAmlScreeningDataGridHelperQa } from '../../qa/individul-aml-screening.qa'
import { AmlPRORow } from '../../state/individual-aml-screening.state/individual-aml-screening.state'

export type SortModel = {
  field: keyof AmlPRORow
  sort: 'asc' | 'desc' | null
}[]

export function MatchStrengthCell({ row }: { row: AmlPRORow }) {
  return <span>{row.matchStrength ? `${row.matchStrength}%` : '-'}</span>
}

export const sortIcons: Record<'asc' | 'desc', FrankieIconName> = {
  desc: 'mdiArrowDown',
  asc: 'mdiArrowUp',
}

export const sortingTooltipMapping: Record<string, 'sortAsc' | 'sortDesc'> = {
  asc: 'sortDesc',
  desc: 'sortAsc',
}

export function EmptyOverlay() {
  return null
}

type TableCheckboxProps = IFrankieCheckboxProps & {
  inputProps: {
    'aria-label':
      | 'Select all rows'
      | 'Unselect all rows'
      | 'Select row'
      | 'Unselect row'
  }
  indeterminate: boolean
  totalCount: number
  selectedCount: number
  notSelectableCount: number
  setSelectionModel: (selectionModel: GridRowSelectionModel) => void
}

export function TableCheckbox({
  indeterminate,
  inputProps,
  totalCount,
  notSelectableCount,
  selectedCount,
  setSelectionModel,
  ...rest
}: TableCheckboxProps) {
  const isHeader =
    inputProps['aria-label'] === 'Select all rows' ||
    inputProps['aria-label'] === 'Unselect all rows'

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (selectedCount && isHeader) {
      setSelectionModel([])
      return
    }
    rest.onChange?.(event)
  }

  return (
    <FrankieCheckbox
      partial={indeterminate}
      {...rest}
      onChange={handleChange}
      testId={{ input: individualAmlScreeningDataGridHelperQa.checkbox }}
    />
  )
}

export function RenderHeaderCellWithPagination(
  params: GridColumnHeaderParams<AmlPRORow> & { sortModel: SortModel },
) {
  const sortItem = params.sortModel.find(
    sortField => sortField.field === params.field,
  )

  let sortIcon: FrankieIconName = 'mdiSwapVertical'
  let iconClassName = 'ml-1 pointer-events-none'

  if (sortItem?.sort) {
    sortIcon = sortIcons[sortItem.sort]
    iconClassName = 'ml-1 text-primary-800 pointer-events-none'
  }

  return (
    <span
      className="flex items-center"
      data-qa={individualAmlScreeningDataGridHelperQa.headerCellContainer}
    >
      <span
        className="!whitespace-break-spaces"
        data-qa={individualAmlScreeningDataGridHelperQa.headerCellHeaderName}
      >
        {params.colDef.headerName}
      </span>
      <div>
        <FrankieIcon
          name={sortIcon}
          size="xs"
          className={iconClassName}
          testId={{
            icon: individualAmlScreeningDataGridHelperQa.headerCellSortIcon,
          }}
        />
      </div>
    </span>
  )
}

type Props = Parameters<typeof Pagination>[0] & {
  onChangePageSize: (pageSize: number) => void
}

export function PaginationWithPageSize({
  pageSize,
  onChangePageSize,
  ...rest
}: Props) {
  const t = useI18n([INDIVIDUAL_AML_SCREENING_KEY], {
    keys: individualAmlScreeningEn,
  })

  return (
    <div
      className="flex justify-between"
      data-qa={individualAmlScreeningDataGridHelperQa.paginationContainer}
    >
      <div
        className="flex items-center gap-2.5"
        data-qa={individualAmlScreeningDataGridHelperQa.rowPerPageText}
      >
        <div className="text-sm font-normal">{t('dataGrid.rowPerPage')}</div>
        <div>
          <FrankieSelectField
            className="!w-16"
            value={pageSize}
            onChange={({ target: { value } }) =>
              onChangePageSize(+(value ?? 5))
            }
            options={[
              { label: '5', value: 5 },
              { label: '10', value: 10 },
              { label: '15', value: 15 },
            ]}
            testId={{
              input:
                individualAmlScreeningDataGridHelperQa.paginationSelectField,
            }}
          />
        </div>
        <div className="text-xs font-normal text-tertiary-grey-500">
          <Trans
            components={{
              b: <span className="font-medium text-tertiary-grey-900" />,
            }}
          >
            {t('dataGrid.resultCount', {
              start: ((rest.page ?? 0) - 1) * (pageSize ?? 0) + 1,
              total: rest.totalCount,
              end: Math.min(
                (rest.page ?? 0) * (pageSize ?? 0),
                rest.totalCount,
              ),
            })}
          </Trans>
        </div>
      </div>

      <Pagination {...rest} showCount={false} pageSize={pageSize} />
    </div>
  )
}
