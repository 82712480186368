import React from 'react'

import { UseFormGetValues } from 'react-hook-form'

import { FrankieButton } from 'frankify/src'

import { IPartialProfileFilter } from 'entities/entity'
import { useHasFeatureFlag } from 'entities/session'

import { useI18n } from 'shared/i18n'
import { useOverlay } from 'shared/overlay'
import { TrackingEventsTypes, trackingManager } from 'shared/tracking'

import { CUSTOM_VIEWS_KEYS } from '../../custom-view.key'
import { customViewEn } from '../../locale/custom-view.en'
import { applyFilterSaveQa } from '../../qa/custom-view.qa'
import { CustomViewSaveModal } from '../custom-view-save-modal/custom-view-save-modal'

type Props = {
  isDirty: boolean
  getValues: UseFormGetValues<IPartialProfileFilter>
  onSubmit: (filters: IPartialProfileFilter, emitIfDisabled?: boolean) => void
  onClose: () => void
  openModal?: boolean
  handleNavigate?: () => void
}

export function CustomViewApplyFilterSave({
  isDirty,
  getValues,
  onSubmit,
  onClose,
  openModal,
  handleNavigate,
}: Props) {
  const t = useI18n([CUSTOM_VIEWS_KEYS], { keys: customViewEn })
  const [createViewOverlay, closeViewOverlay] = useOverlay()
  const { hasCustomViews } = useHasFeatureFlag({
    hasCustomViews: 'customViews',
  })

  const handleSaveView = () => {
    const data = getValues()
    onSubmit(data, true)
    trackingManager.track(
      TrackingEventsTypes.CustomViewCreatedFromCustomSection,
    )
    createViewOverlay(
      <CustomViewSaveModal
        onCloseOverlay={closeViewOverlay}
        onClose={onClose}
        handleNavigate={handleNavigate}
      />,
      {
        closeButtonClassName: '!top-6 !right-6',
        className: '!p-6 ',
        onClose: () => {
          if (handleNavigate) handleNavigate()
          closeViewOverlay()
          onClose()
        },
      },
    )
  }

  if (!openModal || !hasCustomViews) return null
  return (
    <FrankieButton
      disabled={!isDirty}
      type="button"
      intent="darkOutline"
      onClick={() => {
        handleSaveView()
      }}
      className={`${isDirty ? '!text-primary-800' : ''}`}
      testId={{ button: applyFilterSaveQa.btn }}
    >
      {t('applyAllAndSaveView')}
    </FrankieButton>
  )
}
