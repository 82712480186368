import { bffClient, IClient } from 'shared/client'

export class NewPasswordApi {
  client: IClient

  constructor(client: IClient) {
    this.client = client
  }

  async resetPassword(
    token: string,
    email: string,
    password: string,
  ): Promise<void> {
    try {
      await this.client.put(
        `/auth/v1/sessions/password-reset/${email}`,
        { username: email, password },
        {
          headers: {
            'Content-Type': 'application/json',
            authorization: token,
          },
        },
      )
      return await Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async checkResetPasswordTokenExpiration(token: string): Promise<void> {
    try {
      await this.client.get('/auth/v1/sessions/reset-token-validity', {
        headers: {
          'Content-Type': 'application/json',
          authorization: token,
        },
      })
      return await Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

export const newPasswordApi = new NewPasswordApi(bffClient)
