import React, { useMemo } from 'react'

import { NavLink } from 'react-router-dom'

import { FrankieButton } from 'frankify/src'

import { applicantBusinessOverviewQa } from 'features/applicant-general-information/qa/applicant-general-information.qa'

import { ApplicantId, useApplicantPaths } from 'entities/applicant'
import { TrustDeedTypes } from 'entities/applicant/model/applicant-trust-analyser.model'
import { FrankieAlertWithLocalStorage } from 'entities/applicant/ui/local-storage-alert/local-storage-alert'
import { useHasFeatureFlag } from 'entities/session'

import { useI18n } from 'shared/i18n'
import { getLocalStorageState, StorageKeyTypes } from 'shared/local-storage'

import { APPLICANT_GENERAL_INFORMATION_KEY } from '../../applicant-general-information.key'
import { applicantGeneralInformationEn } from '../../locale/applicant-general-information.en'
import { useTrustAnalyser } from '../../state/applicant-trust-analyser/applicant-trust-analyser-state'

export const TRUST_ANALYSER_BANNER_OVERVIEW_KEY =
  'trust-analyser-overview-banner'

type Props = {
  applicantId: ApplicantId
  className?: string
}

export function ApplicantTrustAnalyserBanner({
  applicantId,
  className = '',
}: Props) {
  const t = useI18n([APPLICANT_GENERAL_INFORMATION_KEY], {
    keys: applicantGeneralInformationEn,
  })
  const {
    latestTrustDeedAnalysisStatus,
    latestTrustDeedAnalysis,
    isUnknownType,
    isLoading,
  } = useTrustAnalyser({ applicantId })

  const [dismissedAlertLs] = useMemo(
    () =>
      getLocalStorageState(StorageKeyTypes.DismissedAlerts) as [
        Record<string, boolean> | undefined,
        (value: Record<string, boolean>) => void,
      ],
    [],
  )

  const { getApplicantPath } = useApplicantPaths()
  const trustDetailPath = `${getApplicantPath(
    'supportingDocuments',
    applicantId,
  )}?documentId=${latestTrustDeedAnalysis?.documentId || ''}`

  const { trustAnalyser } = useHasFeatureFlag({
    trustAnalyser: 'trustAnalyser',
  })

  // If trust analyser feature flag is not enabled, return null
  if (!trustAnalyser || isLoading) return null

  // If trust analyser feature flag is enabled, but trust analysis is not present, return null
  if (!latestTrustDeedAnalysisStatus || !latestTrustDeedAnalysis) return null

  const alertKey = `${TRUST_ANALYSER_BANNER_OVERVIEW_KEY}-${applicantId}-${latestTrustDeedAnalysis.documentId}`

  // Check if the trust analysis id is present on dismissed alerts local storage
  const shouldShowBanner = !dismissedAlertLs?.[alertKey]

  if (isUnknownType && shouldShowBanner)
    return (
      <FrankieAlertWithLocalStorage
        title={
          <div className="text-base font-semibold text-tertiary-grey-800">
            {t('trustAnalysisStatus.unknown.title')}
          </div>
        }
        type="error"
        dismissible
        closeIconSize="md"
        closeIconClassName="text-tertiary-grey-400"
        testId={{
          alert: applicantBusinessOverviewQa.trustAnalyserBanner,
        }}
        persistKey={alertKey}
      >
        <div
          className="grid grid-cols-[1fr_auto] gap-8"
          data-qa={applicantBusinessOverviewQa.trustAnalyserBannerTitle}
        >
          <p className="text-sm font-normal text-tertiary-grey-700">
            {t('trustAnalysisStatus.unknown.description')}
          </p>
          <NavLink to={trustDetailPath}>
            <FrankieButton
              intent="danger"
              className="flex-1"
              testId={{
                button: applicantBusinessOverviewQa.trustAnalyserBannerCta,
              }}
            >
              {t('trustAnalysisStatus.unknown.cta')}
            </FrankieButton>
          </NavLink>
        </div>
      </FrankieAlertWithLocalStorage>
    )

  if (
    latestTrustDeedAnalysisStatus === TrustDeedTypes.COMPLETE &&
    shouldShowBanner
  )
    return (
      <FrankieAlertWithLocalStorage
        className="[&>.MuiAlert-message]:w-full [&>.MuiAlert-action]:p-0 [&>.MuiAlert-action]:pl-3 flex items-center"
        closeIconSize="md"
        closeIconClassName="text-tertiary-grey-400"
        persistKey={alertKey}
        title={
          <div
            className="grid grid-cols-[1fr_auto] gap-8 w-full items-center text-tertiary-grey-800"
            data-qa={applicantBusinessOverviewQa.trustAnalyserBannerTitle}
          >
            {t('trustAnalysisStatus.completed.title')}
            <NavLink to={trustDetailPath}>
              <FrankieButton
                intent="primary"
                className="flex-1"
                testId={{
                  button: applicantBusinessOverviewQa.trustAnalyserBannerCta,
                }}
              >
                {t('trustAnalysisStatus.completed.cta')}
              </FrankieButton>
            </NavLink>
          </div>
        }
        type="info"
        dismissible
        testId={{
          alert: applicantBusinessOverviewQa.trustAnalyserBanner,
        }}
      />
    )

  if (
    latestTrustDeedAnalysisStatus === TrustDeedTypes.FAILED &&
    shouldShowBanner
  )
    return (
      <FrankieAlertWithLocalStorage
        title={
          <div className="text-base font-semibold text-tertiary-grey-800">
            {t('trustAnalysisStatus.failed.title')}
          </div>
        }
        type="error"
        dismissible
        closeIconSize="md"
        closeIconClassName="text-tertiary-grey-400"
        testId={{
          alert: applicantBusinessOverviewQa.trustAnalyserBanner,
        }}
        persistKey={alertKey}
      >
        <div
          className="grid grid-cols-[1fr_auto] gap-8"
          data-qa={applicantBusinessOverviewQa.trustAnalyserBannerTitle}
        >
          <p className="text-sm font-normal text-tertiary-grey-700">
            {t('trustAnalysisStatus.failed.description')}
          </p>
          <NavLink to={trustDetailPath}>
            <FrankieButton
              intent="danger"
              className="flex-1"
              testId={{
                button: applicantBusinessOverviewQa.trustAnalyserBannerCta,
              }}
            >
              {t('trustAnalysisStatus.failed.cta')}
            </FrankieButton>
          </NavLink>
        </div>
      </FrankieAlertWithLocalStorage>
    )
}
