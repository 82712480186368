import React, { useState } from 'react'

import { IconButton, MenuItem } from '@mui/material'

import { FrankiePopover } from 'frankify/src'

import { useI18n } from 'shared/i18n'
import { useOverlay } from 'shared/overlay'
import { Noop } from 'shared/typescript'

import { EntitySendKycLinkModal } from './entity-send-kyc-link-modal/entity-send-kyc-link-modal'
import { UpdateAssociatedPartyMenu } from './update-associated-party-menu/update-associated-party-menu'
import { APPLICANT_BUSINESS_OWNERSHIP_INTERNATIONAL_KEY } from '../../applicant-business-ownership-international.key'
import { applicantBusinessOwnershipInternationalEn } from '../../locale/applicant-business-ownership-international.en'
import { businessOwnerShipDataGridActionMenuQa } from '../../qa/applicant-business-ownership-international.qa'

type Props = {
  className?: string
  showUpdateAssociatedPartyMenu: boolean
  showSendKycLinkMenu: boolean
  applicantId: string
  entityId: string
}

export function BusinessOwnershipDataGridActionMenu({
  className = '',
  applicantId,
  entityId,
  showUpdateAssociatedPartyMenu,
  showSendKycLinkMenu,
}: Props) {
  const t = useI18n([APPLICANT_BUSINESS_OWNERSHIP_INTERNATIONAL_KEY], {
    keys: applicantBusinessOwnershipInternationalEn,
  })

  const [open, setOpen] = useState(false)

  const [createOverlay, closeOverlay] = useOverlay()

  const handleAction = () => {
    setOpen(false)

    createOverlay(
      <EntitySendKycLinkModal onClose={closeOverlay} entityId={entityId} />,
      {
        className: 'p-0',
        closeButtonClassName: '!top-4 !right-5',
      },
    )
  }

  const handleClick =
    (cb: Noop) =>
    (e: React.MouseEvent<HTMLButtonElement | HTMLLIElement, MouseEvent>) => {
      e.stopPropagation()
      e.preventDefault()
      cb()
    }

  if (!showSendKycLinkMenu && !showUpdateAssociatedPartyMenu) return null

  return (
    <div
      className={className}
      data-qa={businessOwnerShipDataGridActionMenuQa.componentWrapper}
    >
      <FrankiePopover
        open={open}
        onOpenChange={setOpen}
        popoverRest={{ placement: 'bottom-end' }}
        trigger={
          <IconButton
            className={`h-[30px] w-[30px] pb-4 rounded-sm px-2 text-tertiary-grey-400 ${
              open ? 'bg-neutral-30' : ''
            }`}
            onClick={handleClick(() => setOpen(prev => !prev))}
            data-qa={businessOwnerShipDataGridActionMenuQa.icon}
          >
            ...
          </IconButton>
        }
      >
        <div className="bg-mono-white shadow-md rounded-sm py-1">
          {showSendKycLinkMenu && (
            <MenuItem
              className="py-2 text-sm text-secondary-900 hover:text-primary-600 hover:bg-primary-50"
              onClick={handleClick(() => handleAction())}
            >
              {t('action.sendKycLink')}
            </MenuItem>
          )}
          {showUpdateAssociatedPartyMenu && (
            <UpdateAssociatedPartyMenu
              applicantId={applicantId}
              entityId={entityId}
              setOpen={setOpen}
              onClose={closeOverlay}
            />
          )}
        </div>
      </FrankiePopover>
    </div>
  )
}
