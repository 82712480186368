import { StatusTypes } from 'entities/individual-audit-report-f2'

export type ReportCardType = {
  title: string
  description?: {
    label: string
    value: string
    colorFlag?: 'primary' | 'default'
  }[]
  requestId?: string
  entityStatus?: string
}

export type TimelineType = Record<string, string[]>

export const getTimeDifference = ({
  time1,
  time2,
}: {
  time1: string
  time2: string
}) => {
  const date1 = new Date(time1)
  const date2 = new Date(time2)

  const diffInMs = Math.abs(date2.getTime() - date1.getTime())
  const diffInMinutes = Math.floor(diffInMs / (1000 * 60))

  if (diffInMinutes >= 60) {
    const hour = Math.floor(diffInMinutes / 60)
    return `${hour} ${hour > 1 ? 'hours' : 'hour'}`
  }
  if (diffInMinutes === 1) {
    return '1 minute'
  }
  if (diffInMinutes === 0) {
    return '< 1 minute'
  }

  return `${diffInMinutes} minutes`
}

export const extractDateTime = (
  timestamp: string,
): {
  formattedDate: string
  formattedTime: string
} => {
  const date = new Date(timestamp)

  const optionsDate: Intl.DateTimeFormatOptions = {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  }
  const optionsTime: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  }

  const formattedDate = date.toLocaleDateString('en-GB', optionsDate)
  const formattedTime = date.toLocaleTimeString('en-GB', optionsTime)

  return { formattedDate, formattedTime }
}

export const AuditReportQueryTypes = 'individual-audit-report'

export type EventsType = {
  time: string
  requestId?: string
  entityStatus: StatusTypes
  eventType: 'check' | 'workflow'
  tag?: StatusTypes
  title: string
  description?: {
    label: string
    value: string
    link?: string
    colorFlag?: 'primary' | 'default'
  }[]
}

export type AuditReportRecord = {
  date: string
  events: EventsType[]
}
