import React, { useEffect, useState } from 'react'

import { useInView } from 'react-intersection-observer'

import { FrankieIcon } from 'frankify/src'

import { CommentToolTip, CommentsTypes } from 'entities/comments'
import { useEntityLabelDataState } from 'entities/entity'

import { useI18n } from 'shared/i18n'
import { TrackingEventsTypes, trackingManager } from 'shared/tracking'

import { INDIVIDUAL_PROFILE_INSIGHT_KEY } from '../../individual-profile-insight.key'
import { individualProfileInsightEn } from '../../locale/individual-profile-insight.en'
import { IndividualProfilePersonalInfoQa } from '../../qa/individual-profile-insight.qa'

type Props = {
  entityId: string
  isInvalidated?: boolean
}

export function IndividualProfilePersonalInfo({
  entityId,
  isInvalidated,
}: Props) {
  const t = useI18n(INDIVIDUAL_PROFILE_INSIGHT_KEY, {
    keys: individualProfileInsightEn,
  })

  const { ref, inView } = useInView()
  const [isEventEmitted, setIsEventEmitted] = useState(false)

  const { personalInfoWithLabelData } = useEntityLabelDataState({
    entityId,
  })
  useEffect(() => {
    if (!isEventEmitted && inView) {
      trackingManager.trackWithProps(
        TrackingEventsTypes.ProfileInsightsPersonalInfoViewIndividual,
        {
          entityId,
        },
      )
      setIsEventEmitted(true)
    }
  }, [entityId])
  // calc(100% - 440px)

  return (
    <div
      data-qa={IndividualProfilePersonalInfoQa.container}
      className="flex flex-col gap-y-3"
      ref={ref}
    >
      <div className="flex justify-end items-center">
        <div className="font-bold text-tertiary-grey-800 text-xl flex items-center gap-1">
          {isInvalidated && (
            <FrankieIcon
              size="md"
              name="mdiAlertCircle"
              className="text-tertiary-yellow-300"
              testId={{ icon: IndividualProfilePersonalInfoQa.icon }}
            />
          )}
          {t('tab.personalInfo')}
        </div>
        <CommentToolTip
          className="!z-50"
          logoClassName="!text-tertiary-grey-400"
          commentType={CommentsTypes.PROFILE_PERSONAL_INFO}
          entityId={entityId}
        />
      </div>
      <div className="w-full flex gap-y-3 items-start flex-wrap">
        <div className="basis-[33%] min-w-[210px]">
          <div className="font-medium text-tertiary-grey-800">
            {t('label.profileType')}
          </div>
          <div className="flex  items-center gap-1 font-normal text-tertiary-grey-700">
            <FrankieIcon
              className="text-tertiary-grey-500"
              size="xs"
              name="mdiAccountOutline"
              testId={{ icon: IndividualProfilePersonalInfoQa.individualIcon }}
            />
            {t('label.individual')}
          </div>
        </div>
        {personalInfoWithLabelData.map(
          ({ label, value }) =>
            value && (
              <div
                className="basis-[33%] min-w-[210px]"
                data-qa={IndividualProfilePersonalInfoQa.tabItemContainer}
              >
                <div className="font-medium text-tertiary-grey-800">
                  {label}
                </div>
                <div
                  data-hj-suppress
                  className="font-normal text-tertiary-grey-700"
                >
                  {value}
                </div>
              </div>
            ),
        )}
      </div>
    </div>
  )
}
