import React from 'react'

import { FrankieLoader } from 'frankify/src'

type Props = {
  text: string
  loader?: boolean
  testId?: {
    container?: string
    loader?: string
  }
}
export function CustomViewLoader({ text, loader, testId }: Props) {
  if (!loader) return null
  return (
    <div
      data-qa={testId?.container}
      className="flex align-center justify-center flex-col w-full h-full bg-mono-white"
    >
      <div>
        <div id="loader">
          <FrankieLoader
            loading
            fullscreen
            className="!z-[100]"
            size="md"
            label={text}
            testId={{ loader: testId?.loader }}
            loaderClassName="!gap-2 !text-md !font-semibold"
          />
        </div>
      </div>
    </div>
  )
}
