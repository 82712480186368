import { useEffect, useMemo } from 'react'

import { useInfiniteQuery } from '@tanstack/react-query'

import { ApplicantId } from 'entities/applicant'

import { ErrorResponse } from 'shared/http'
import { addDefaultEmptyValue } from 'shared/typescript'

import { applicantTransactionsApi } from '../../api/applicant-transactions.api'
import { parseTransactionAmount } from '../../model/applicant-transactions-data-grid-helper.model'
import {
  ApplicantTransactionsRecord,
  ApplicantTransactionsResponse,
  TransactionTypes,
} from '../../model/applicant-transactions.model'

export type ApplicantTransactionsQueryArgs = {
  applicantId: ApplicantId
  transactionType: TransactionTypes[]
  needsAttention?: boolean
}

export const AuditTransactionsQueryKey = 'applicant-transactions-query'

export const useApplicantTransactionsQuery = ({
  applicantId,
  transactionType,
  needsAttention,
}: ApplicantTransactionsQueryArgs) => {
  const query = useInfiniteQuery<ApplicantTransactionsResponse, ErrorResponse>({
    enabled: !!transactionType.length,
    queryKey: [
      AuditTransactionsQueryKey,
      ...transactionType,
      needsAttention,
      applicantId,
    ],
    getNextPageParam: lastPage => lastPage.nextPageToken,
    queryFn: async ({ pageParam }: { pageParam?: string }) => {
      const { data } = await applicantTransactionsApi.getTransactions(
        applicantId,
        { type: transactionType, needsAttention, nextPageToken: pageParam },
      )

      // Empty string will get result in next call, so setting it to null
      if (data.nextPageToken === '') {
        data.nextPageToken = null
      }

      return data
    },
  })

  /**
   * Fetching all pages at once - not ideal @todo Need optimization form BE
   * Same is done in vue code - could be because of local search and total record status
   */
  useEffect(() => {
    if (query.hasNextPage && !query.isFetchingNextPage) {
      void query.fetchNextPage()
    }
  }, [query, query.hasNextPage, query.isFetchingNextPage])

  /**
   * Combine all the transaction records from all the pages
   */
  const transactionRecords = useMemo(() => {
    const { data } = query

    const dataRecords = data?.pages ?? [{ transactions: [], nextPageToken: '' }]

    return dataRecords.reduce(
      (value, curr) => [
        ...value,
        ...curr.transactions.map(
          ({ amount, riskToken, resultId, sourceLink, ...restTxnData }) => ({
            ...addDefaultEmptyValue(restTxnData),
            id: resultId,
            amount: parseTransactionAmount(
              amount?.currencyCode || '',
              amount?.value || '',
            ),
            sourceLink: sourceLink ?? '',
            sessionKey: riskToken?.sessionKey ?? '-',
          }),
        ),
      ],
      [] as ApplicantTransactionsRecord[],
    )
  }, [query])

  return {
    ...query,
    transactionRecords,
  }
}
