import { useMutation } from '@tanstack/react-query'

import { organisationApi } from '../../api/organisation.api'
import { CheckAmlCreateApplicantBody } from '../../model/applicant.model'

type Args = {
  body: CheckAmlCreateApplicantBody
}

export const useAmlCheckCreateApplicantMutation = () =>
  useMutation({
    mutationFn: ({ body }: Args) =>
      organisationApi.checkAmlCreateApplicant(body),
    onSuccess: response => response.data,
  })
