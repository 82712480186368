import { cva } from 'cva'

export const FrankieButtonStyle = cva(
  [
    'flex',
    'flex-initial',
    'font-semibold',
    'items-center',
    'justify-center',
    'outline',
    'outline-0',
    'focus-visible:outline-3',
    'active:outline-3',
    'disabled:text-tertiary-grey-400',
    'disabled:outline-0',
  ],
  {
    variants: {
      size: {
        xs: ['h-[34px]', 'text-xs', 'space-x-2', 'px-3', 'py-2', 'rounded-sm'],
        sm: [
          'h-9',
          'min-w-[60px]',
          'text-sm',
          'space-x-1',
          'px-3',
          'py-2',
          'rounded-sm',
        ],
        md: [
          'h-10',
          'w-full',
          'min-w-[40px]',
          'tablet:w-auto',
          'text-sm',
          'space-x-2',
          'px-5',
          'py-2.5',
          'rounded-sm',
        ],
        lg: [
          'h-12',
          'w-full',
          'tablet:w-auto',
          'text-md',
          'space-x-2',
          'px-5',
          'py-3',
          'rounded-sm',
        ],
      },
      intent: {
        primary: [
          'text-mono-white',
          'bg-primary-800',
          'hover:bg-primary-900',
          'focus-visible:bg-primary-900',
          'active:bg-primary-900',
          'disabled:bg-tertiary-grey-200',
          'outline-primary-200',
        ],
        secondary: [
          'text-primary-800',
          'border',
          'border-solid',
          'box-border',
          'border-tertiary-grey-200',
          'hover:bg-primary-50',
          'active:bg-primary-50',
          'focus-visible:bg-primary-50',
          'outline-primary-200',
          'disabled:bg-inherit',
        ],
        danger: [
          'text-mono-white',
          'bg-tertiary-red-700',
          'hover:bg-tertiary-red-800',
          'focus-visible:bg-tertiary-red-800',
          'active:bg-tertiary-red-800',
          'disabled:bg-tertiary-grey-200',
          'outline-tertiary-red-200',
        ],
        darkOutline: [
          'text-tertiary-grey-700',
          'border',
          'border-solid',
          'box-border',
          'border-tertiary-grey-200',
          'hover:bg-tertiary-grey-100',
          'active:bg-primary-50',
          'focus-visible:bg-primary-50',
          'outline-tertiary-grey-100',
          'disabled:bg-inherit',
        ],
        subtle: [
          'text-tertiary-grey-700',
          'bg-transparent',
          'hover:bg-tertiary-grey-100',
          'active:bg-primary-50',
          'focus-visible:bg-primary-50',
          'outline-tertiary-grey-100',
          'disabled:bg-inherit',
        ],
        warningPrimary: [
          'text-tertiary-grey-700',
          'bg-tertiary-yellow-300',
          'hover:bg-tertiary-yellow-500',
          'focus-visible:bg-tertiary-yellow-500',
          'active:bg-tertiary-yellow-500',
          'disabled:bg-tertiary-grey-200',
          'outline-tertiary-yellow-200',
        ],
        warningSecondary: [
          'text-tertiary-grey-700',
          'border',
          'border-solid',
          'box-border',
          'border-tertiary-grey-300',
          'hover:bg-tertiary-yellow-100',
          'active:bg-tertiary-yellow-100',
          'focus-visible:bg-tertiary-yellow-100',
          'outline-tertiary-yellow-300',
          'disabled:bg-inherit',
        ],
        success: [
          'text-mono-white',
          'bg-tertiary-green-700',
          'hover:bg-tertiary-green-800',
          'focus-visible:bg-tertiary-green-800',
          'active:bg-tertiary-green-800',
          'disabled:bg-tertiary-grey-200',
          'outline-tertiary-green-200',
        ],
      },
      icon: {
        xs: ['active:outline-none', '!px-1', '!py-1', '!h-8'],
        sm: ['active:outline-none', '!px-1', '!py-1'],
        md: ['active:outline-none', '!px-2', '!py-2'],
        lg: ['active:outline-none', '!px-3', '!py-3'],
      },
      circle: { true: ['!rounded-full'] },
    },
  },
)
