import React, { useState } from 'react'

import {
  FrankieBarChart,
  FrankieButton,
  FrankieLoader,
  FrankiePieChart,
} from 'frankify/src'

import { useAnalyticsData } from 'features/dashboard/state/analytics-data/analytics.data'
import { useAnalyticsQuery } from 'features/dashboard/state/analytics-query/analytics.query'

import { useI18n } from 'shared/i18n'

import {
  failureCauses,
  passRateConfig,
  riskLevelConfig,
} from '../../config/dashboard.config'
import { DASHBOARD_KEY } from '../../dashboard.key'
import { dashboardEn } from '../../locale/dashboard.en'
import {
  DateRange,
  SelectedAccounts,
  dateRangeRecord,
} from '../../model/dashboard-form.model'
import { DateFilter } from '../date-filter/date-filter'
import { GenerateReport } from '../generate-report/generate-report'
import { SelectAccounts } from '../select-accounts/select-accounts'

export function Dashboard() {
  const t = useI18n([DASHBOARD_KEY], { keys: dashboardEn })

  const [selectedDateRange, setSelectedDateRange] = useState<DateRange>(
    dateRangeRecord().LAST_30_DAYS,
  )

  const [selectedAccount, setSelectedAccount] = useState<SelectedAccounts>({
    customerChildIds: [],
    isOwnSelected: true,
  })

  const [isReportGenerated, setIsReportGenerated] = useState(false)

  const { data: analyticsData, isLoading } = useAnalyticsQuery({
    ...selectedDateRange,
  })

  const { passRate, riskLevel, issues, passRateChecks, passRatePercentage } =
    useAnalyticsData(selectedAccount, analyticsData)

  return (
    <div>
      <div className="flex gap-4 px-8 py-4 bg-neutral-20">
        <DateFilter
          selectedDateRange={selectedDateRange}
          onDateRangeChange={setSelectedDateRange}
        />
        <SelectAccounts
          onAccountSelect={setSelectedAccount}
          selectedAccount={selectedAccount}
        />
        <GenerateReport
          className="flex-grow-0"
          selectedDateRange={selectedDateRange}
          selectedAccount={selectedAccount}
          onReportGeneration={() => setIsReportGenerated(true)}
        />
      </div>

      <FrankieLoader
        loading={isLoading}
        size="md"
        className={`${
          !isLoading ? '!h-[calc(100vh-190px)]' : ''
        }  p-8 pr-4 mr-2 overflow-y-auto scrollbar`}
      >
        <>
          {isReportGenerated && (
            <div className="px-8 pt-8">
              <div className="h-[50px] px-4 py-2 flex font-bold justify-between item-center rounded-sm bg-tertiary-green-100 text-success">
                <div className="leading-8">{t('message.reportGenerated')}</div>

                <FrankieButton
                  noStyles
                  onClick={() => setIsReportGenerated(false)}
                  className="text-mono-100 underline"
                >
                  {t('dismiss')}
                </FrankieButton>
              </div>
            </div>
          )}
          <div className="flex flex-wrap p-2">
            <div className="basis-1/2 p-6">
              <div className="text-xl border-b flex justify-between border-neutral-50 mb-6 relative">
                <span>{t('analytics.passRate')}</span>
                <span>{passRatePercentage}</span>
                <span className="absolute text-sm text-neutral-60 right-0 bottom-[-24px]">
                  {t('analytics.checks')} : {passRateChecks}
                </span>
              </div>
              <FrankiePieChart
                data={passRateConfig.map(item => ({
                  color: item.color,
                  label: t(`passRate.${item.tKey}`),
                  value: passRate[item.key] || 0,
                }))}
              />
            </div>
            <div className="basis-1/2 p-6">
              <div className="text-xl border-b flex justify-between border-neutral-50 mb-6">
                <span>{t('analytics.riskLevel')}</span>
              </div>

              <FrankiePieChart
                data={riskLevelConfig.map(item => ({
                  color: item.color,
                  label: t(`riskLevel.${item.tKey}`),
                  value: riskLevel[item.key] || 0,
                }))}
              />
            </div>

            <div className="basis-full p-6">
              <div className="text-xl border-b flex justify-between border-neutral-50 mb-6">
                <span>{t('analytics.primaryCauses')}</span>
              </div>
              <div className="flex flex-wrap w-[100%] gap-x-[4%] gap-y-6">
                {failureCauses.map(failureCause => (
                  <div className="basis-[48%]" key={failureCause.tKey}>
                    <div className="font-bold text-lg">
                      {t(`failureCauses.${failureCause.tKey}`)}
                    </div>
                    <FrankieBarChart
                      data={failureCause.data.map(item => ({
                        label: t(`issuesType.${item.tKey}`),
                        value: issues.reduce(
                          (value, issue) =>
                            value +
                            (item.key.includes(issue.type) ? issue.count : 0),
                          0,
                        ),
                      }))}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      </FrankieLoader>
    </div>
  )
}
