import { bffClient, IClient } from 'shared/client'

import {
  CheckAmlCreateApplicantBody,
  ApplicantBusinessBody,
  TemplateType,
  CreateProfileROWBody,
  GenerateProfileROWBody,
  CreateProfileROWResponse,
  CreateOwnershipProfileResponse,
  CreateProfileInternationalBody,
  CreateProfileInternationalResponse,
  CreateOrganisationProfileByEntityIdBody,
  CreateAndRunAmlInternationalResponse,
} from '../model/applicant.model'
import { BusinessSearchResponse, MCCResponse } from '../model/business.model'
import {
  IMatchedOrganization,
  OrganisationProfile,
  OrganisationStatus,
} from '../model/organisation.model'
import { EntityBusinessRequests } from '../model/request-status.model'

type CheckAmlResponse = {
  entityId: string
  detailLevel: string
  creditHeaderIssueMessage: string
  serviceDisplayableError: boolean
}

export type CheckParams = {
  forceCheck?: boolean
  uploadDocs?: boolean
  noInvalidate?: boolean
}

type GenerateReportBody = {
  business: ApplicantBusinessBody
}

type GenerateReportParams = {
  level?: TemplateType
  template?: TemplateType
}

type GenerateReportResponse = {
  entityId: string
}

export class OrganisationApi {
  client: IClient

  constructor(client: IClient) {
    this.client = client
  }

  async checkAmlPep(
    entityId: string,
    checkParams: CheckParams = {
      forceCheck: false,
      uploadDocs: false,
      noInvalidate: false,
    },
  ) {
    return this.client.post<CheckAmlResponse, object>(
      `/data/v1/applicants/${entityId}/checks`,
      {},
      {
        params: checkParams,
      },
    )
  }

  async checkAmlCreateApplicant(body: CheckAmlCreateApplicantBody) {
    return this.client.post<CheckAmlResponse, CheckAmlCreateApplicantBody>(
      '/data/v1/applicants?saveOnly=false',
      body,
    )
  }

  async generateReport(body: GenerateReportBody, params: GenerateReportParams) {
    return this.client.post<GenerateReportResponse, GenerateReportBody>(
      '/data/v2/business/report',
      body,
      { params },
    )
  }

  async getOrganizationLookup(
    search: string,
    country: string,
    subdivision?: string,
  ) {
    return this.client.get<{
      matchedOrganizations: IMatchedOrganization[] | undefined
      requestId: string[]
    }>('/data/v2/organizations', {
      params: {
        search,
        country,
        subdivision: subdivision ?? undefined,
      },
    })
  }

  async createProfileROW(organizationToken: string) {
    return this.client.post<CreateProfileROWResponse, CreateProfileROWBody>(
      '/data/v3/business/profile',
      {
        organizationToken,
      },
    )
  }

  // for creating profile when clicking retrieve registry docs
  async createProfileInternational(body: CreateProfileInternationalBody) {
    return this.client.post<
      CreateProfileInternationalResponse,
      CreateProfileInternationalBody
    >('/data/v2/business/international/entity', body)
  }

  async runInternationalAmlCheck(body: CreateProfileInternationalBody) {
    return this.client.post<
      CreateAndRunAmlInternationalResponse,
      CreateProfileInternationalBody
    >('/data/v1/business/new/aml', body)
  }

  async reRunInternationalAmlCheck(entityId: string) {
    return this.client.post<CreateAndRunAmlInternationalResponse>(
      `/data/v1/business/${entityId}/aml`,
    )
  }

  async generateReportROW(
    entityId: string,
    reportType: 'profile' | 'ownership',
  ) {
    return this.client.post<{ requestId: string }, GenerateProfileROWBody>(
      '/data/v3/business/report',
      {
        entityId,
        reportType,
      },
    )
  }

  async getProfile(entityId: string) {
    return this.client.get<OrganisationProfile>(`/data/v3/business/${entityId}`)
  }

  async getProfileStatus(entityId: string, requestId: string) {
    return this.client.get<OrganisationStatus>(
      `/data/v3/business/${entityId}/report-status/${requestId}`,
    )
  }

  async getBusinessRequestStatus(entityId: string) {
    return this.client.get<EntityBusinessRequests>(
      `/data/v3/business/${entityId}/requests`,
    )
  }

  async searchBusiness(searchTerm: string | number) {
    return this.client.get<BusinessSearchResponse[]>(
      `/data/v2/businesses?search=${searchTerm}`,
    )
  }

  async getMCC() {
    return this.client.get<MCCResponse[]>('data/v2/business/mcc')
  }

  async createOwnershipProfile(organizationToken: string) {
    return this.client.post<
      CreateOwnershipProfileResponse,
      CreateProfileROWBody
    >('/data/v3/business/ownership-profile', {
      organizationToken,
    })
  }

  async continueOwnershipReportGeneration({ entityId }: { entityId: string }) {
    return this.client.post<
      CreateOwnershipProfileResponse,
      CreateOrganisationProfileByEntityIdBody
    >(`/data/v3/business/continue-ownership-report/${entityId}`, { entityId })
  }

  async getAssociationRoles() {
    return this.client.get<{ type: string; typeDescription: string }[]>(
      'data/v2/business/association-roles',
    )
  }
}

export const organisationApi = new OrganisationApi(bffClient)
