import { IApplicantSupportingDocuments } from 'entities/applicant'
import { TrustDeedTypes } from 'entities/applicant/model/applicant-trust-analyser.model'

import { bffClient, IClient } from 'shared/client'

import {
  AnalyseTrustDeedPayload,
  AnalysisTrustDocumentResponse,
  IBusinessDocumentsCatalogRes,
  IComment,
  IPurchaseDocumentResponse,
  ITrustDeedAnalysisResult,
  ITrustDeedsResponse,
  IUploadTrustDeedFileResponse,
  PurchaseDocumentPayload,
  SupportingDocumentPayload,
  UploadTrustDeedFilePayload,
  ConfirmAnalysisResultResponse,
  ConfirmAnalysisResultIdPayload,
  ConfirmAnalysisResultPayload,
  GetTrustAnalysisAssociatedPartiesPayload,
} from '../model/applicant-supporting-documents.model'
import { TrustDeedAssociatedParty } from '../model/applicant-supporting-trust-deed.model'

export class ApplicantSupportingDocumentAPI {
  client: IClient

  constructor(client: IClient) {
    this.client = client
  }

  async addApplicantSupportedDocument(
    entityId: string,
    payload: SupportingDocumentPayload,
  ) {
    return this.client.post<
      IApplicantSupportingDocuments,
      SupportingDocumentPayload
    >(`data/v1/applicant/${entityId}/supporting-documents`, payload)
  }

  async updateApplicantSupportedDocument(
    entityId: string,
    documentId: string,
    payload: SupportingDocumentPayload,
  ) {
    return this.client.put<
      IApplicantSupportingDocuments,
      SupportingDocumentPayload
    >(
      `data/v1/applicant/${entityId}/supporting-documents/${documentId}`,
      payload,
    )
  }

  async getSupportedDocComments(entityId: string, docId: string) {
    return this.client.get<IComment[]>(
      `data/v1/applicant/${entityId}/supporting-documents/${docId}/comments`,
    )
  }

  async deleteApplicantSupportDocument(
    applicantId: string,
    documentId: string,
  ) {
    return this.client.delete(
      `data/v1/applicants/${applicantId}/documents/${documentId}`,
    )
  }

  async businessDocumentCatalog(applicantId: string) {
    return this.client.get<IBusinessDocumentsCatalogRes>(
      `data/v1/business/${applicantId}/documents`,
    )
  }

  async businessDocumentPurchase({
    applicantId,
    selectedReports,
  }: PurchaseDocumentPayload) {
    return this.client.post<
      IPurchaseDocumentResponse[],
      Omit<PurchaseDocumentPayload, 'applicantId'>
    >(`data/v1/business/${applicantId}/documents/purchase`, { selectedReports })
  }

  async getTrustDeeds(applicantId: string) {
    return this.client.get<ITrustDeedsResponse[]>(
      `data/v2/business/trust-deeds/${applicantId}`,
    )
  }

  async uploadTrustDeedFile(
    applicantId: string,
    payload: UploadTrustDeedFilePayload,
  ) {
    return this.client.post<
      IUploadTrustDeedFileResponse,
      UploadTrustDeedFilePayload
    >(`data/v2/business/trust-deeds/${applicantId}`, payload)
  }

  async analyseTrustDeed(
    applicantId: string,
    payload: AnalyseTrustDeedPayload,
  ) {
    return this.client.post<
      AnalysisTrustDocumentResponse,
      AnalyseTrustDeedPayload
    >(`data/v2/business/trust-deeds/${applicantId}/analyse`, payload)
  }

  async getTrustDeedAnalysisStatus(documentId: string) {
    return this.client.get<TrustDeedTypes>(
      `data/v2/business/trust-deeds/${documentId}/analyse`,
    )
  }

  async getAnalysisResult(applicantId: string, documentId: string) {
    return this.client.get<ITrustDeedAnalysisResult>(
      `data/v2/business/trust-deeds/${applicantId}/analysis-result/${documentId}`,
    )
  }

  async getTrustAnalysisAssociatedParties({
    applicantId,
  }: GetTrustAnalysisAssociatedPartiesPayload) {
    return this.client.get<TrustDeedAssociatedParty[]>(
      `data/v2/business/trust-deeds/${applicantId}/associated-party`,
    )
  }

  async confirmAnalysisResult(
    applicantId: string,
    documentId: string,
    payload: ConfirmAnalysisResultIdPayload,
  ) {
    const { analysisId, ...restPayload } = payload
    return this.client.post<
      ConfirmAnalysisResultResponse,
      ConfirmAnalysisResultPayload
    >(
      `data/v2/business/trust-deeds/${applicantId}/analysis-result/${documentId}/confirmation/${analysisId}`,
      restPayload,
    )
  }
}
export const applicantSupportingDocumentApi =
  new ApplicantSupportingDocumentAPI(bffClient)
