import { useMemo } from 'react'

import { passRateConfig } from '../../config/dashboard.config'
import { SelectedAccounts } from '../../model/dashboard-form.model'
import {
  AnalyticsResponse,
  Issues,
  PassRate,
  RiskLevel,
} from '../../model/dashboard.model'

type UseAnalyticsReturnType = {
  passRate: PassRate
  riskLevel: RiskLevel
  issues: Issues
  passRateChecks: number
  passRatePercentage: string
}

export const useAnalyticsData = (
  selectedAccount: SelectedAccounts,
  analyticsData?: AnalyticsResponse,
): UseAnalyticsReturnType => {
  const resultData = useMemo<UseAnalyticsReturnType>(() => {
    /**
     * Sample data to be used to result analytic data
     */
    const newAnalyticsData: UseAnalyticsReturnType = {
      passRate: {
        passed: 0,
        manually_passed: 0,
        failed: 0,
        manually_failed: 0,
        needs_attention: 0,
        unchecked: 0,
        pending: 0,
        duplicate: 0,
        archived: 0,
        inactive: 0,
      },
      riskLevel: {
        high: 0,
        low: 0,
        medium: 0,
        unacceptable: 0,
        unknown: 0,
      },
      issues: [],
      passRateChecks: 0,
      passRatePercentage: '-',
    }

    if (!analyticsData) {
      return newAnalyticsData
    }

    const selectedAccountArr = [...selectedAccount.customerChildIds]

    // Pushing own if selected
    if (selectedAccount.isOwnSelected) {
      selectedAccountArr.push('own')
    }

    const combinedAnalyticsData = selectedAccountArr.reduce(
      // eslint-disable-next-line complexity
      (data, item) => {
        const selectedAnalyticsData = item ? { ...analyticsData[item] } : null
        if (!selectedAnalyticsData) return data

        // Pass Rate
        const { passRate } = data

        for (const passRateItem in passRate) {
          if (Object.hasOwn(passRate, passRateItem)) {
            const passRateItemKey = passRateItem as keyof PassRate
            passRate[passRateItemKey] =
              (passRate[passRateItemKey] || 0) +
              (selectedAnalyticsData.pass_rate[passRateItemKey] || 0)
          }
        }

        // Risk Level
        const { riskLevel } = data
        for (const riskLevelItem in riskLevel) {
          if (Object.hasOwn(riskLevel, riskLevelItem)) {
            const riskLevelItemKey = riskLevelItem as keyof RiskLevel
            riskLevel[riskLevelItemKey] =
              (riskLevel[riskLevelItemKey] || 0) +
              (selectedAnalyticsData.risk_level[riskLevelItemKey] || 0)
          }
        }

        // Issues
        selectedAnalyticsData.issues.forEach(issue => {
          const issueIdx = data.issues.findIndex(
            prevIssue => issue.type === prevIssue.type,
          )

          if (issueIdx !== -1) {
            data.issues[issueIdx] = {
              type: issue.type,
              count: data.issues[issueIdx].count + issue.count,
            }
          } else {
            data.issues.push(issue)
          }
        })

        return data
      },
      { ...newAnalyticsData, issues: [] as Issues },
    )

    const { passRate } = combinedAnalyticsData

    const validPassRateKeys = passRateConfig.map(item => item.key)
    /**
     * Pass Rate Count
     * @see validPassRateKeys Adding only config values
     */
    const passRateChecksCount = Object.entries(passRate).reduce(
      (sum, [key, value]) =>
        validPassRateKeys.includes(key as keyof PassRate) ? sum + value : sum,
      0,
    )

    const passRateChecks =
      passRate.passed + passRate.manually_passed + passRate.needs_attention

    const passRatePercentage =
      passRateChecksCount > 0
        ? `${((passRateChecks * 100) / passRateChecksCount).toFixed(1)}%`
        : combinedAnalyticsData.passRatePercentage

    // updating passRatePercentage and passRateChecks
    combinedAnalyticsData.passRatePercentage = passRatePercentage
    combinedAnalyticsData.passRateChecks = passRateChecksCount

    return combinedAnalyticsData
  }, [analyticsData, selectedAccount])

  return resultData
}
