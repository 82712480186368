import React, { useMemo } from 'react'

import { Controller, SubmitHandler, useForm } from 'react-hook-form'

import { FrankieButton, FrankieCheckbox, FrankieDivider } from 'frankify/src'

import { CustomViewApplyFilterSave } from 'features/custom-views/ui/custom-view-apply-filter-save/custom-view-apply-filter-save'

import {
  IPartialProfileFilter,
  ProfileIssueCategoryTypes,
  ProfileRiskLevelTypes,
  ProfileStateTypes,
  WorkflowStatusKeysTypes,
} from 'entities/entity'
import { useSessionQuery } from 'entities/session'
import { IUser } from 'entities/user'
import { useWorkflowListQuery } from 'entities/workflow'

import { removeDuplicates, sortArrOfObjs } from 'shared/array'
import {
  CheckboxGroup,
  CheckboxesSearch,
  DateSelector,
  FilterSection,
  IOption,
  validateDateRangeRequired,
  UNASSIGNED_OPTION,
} from 'shared/filters-common'
import { useI18n } from 'shared/i18n'

import {
  PROFILES_FILTER_KEY,
  profilesFilterEn,
} from '../../locale/profiles-filter.en'
import {
  profileIssueCategory3Columns,
  profileIssuesCategoriesI18n,
  profileRisksColumns,
  profileRisksI18n,
  profileStatesI18n,
  workflowStatesI18n,
} from '../../model/profiles-filter-form.model'
import { profileGlobalFilterDefault } from '../../model/profiles-filter.model'
import { applicantsFilterQa } from '../../qa/applicants-filter.qa'

export const applicantFilterGeneralDefault: IPartialProfileFilter =
  profileGlobalFilterDefault

type Props = {
  onClose: () => void
  initialValues?: IPartialProfileFilter
  onSubmit: (filters: IPartialProfileFilter) => void
  users: IUser[]
  statesOptionCols: ProfileStateTypes[][]
  workflowStatesOptionCols: WorkflowStatusKeysTypes[]
  openModal?: boolean
  handleNavigate?: () => void
}

type Option = { value: string; label: string; node?: JSX.Element }

export function ProfilesFilterForm({
  statesOptionCols,
  workflowStatesOptionCols,
  users,
  onClose,
  initialValues,
  onSubmit,
  openModal,
  handleNavigate,
}: Props) {
  const t = useI18n([PROFILES_FILTER_KEY], { keys: profilesFilterEn })

  const { data: session } = useSessionQuery()
  const { data: workflowList } = useWorkflowListQuery()
  const usersOptions = useMemo<Option[]>(() => {
    const filtered = removeDuplicates(users, user => user.realname).sort(
      (a, b) => (a.realname || '').localeCompare(b.realname || ''),
    )
    return [...filtered].map<Option>(user => ({
      label: user.realname || '',
      value: user.email || '',
      // node: 'node' in user ? user.node : undefined,
    }))
  }, [users])

  const workFlowStatusOption = useMemo<Option[][]>(() => {
    const options = workflowStatesOptionCols.map(key => ({
      label: t(workflowStatesI18n[key]) || '',
      value: key,
    }))

    return [
      options.slice(0, Math.ceil(options.length / 3)),
      options.slice(
        Math.ceil(options.length / 3),
        Math.ceil(options.length / 3) * 2,
      ),
      options.slice(Math.ceil(options.length / 3) * 2),
    ]
  }, [workflowStatesOptionCols, t])

  const workflowOptions = useMemo<Option[][]>(() => {
    const options = (workflowList || []).map<Option>(workflow => ({
      label: workflow.workflowName,
      value: workflow.workflowName,
    }))
    const sorted = sortArrOfObjs(options, 'asc', option => option.label)
    return [
      sorted.slice(0, Math.ceil(sorted.length / 3)),
      sorted.slice(
        Math.ceil(sorted.length / 3),
        Math.ceil(sorted.length / 3) * 2,
      ),
      sorted.slice(Math.ceil(sorted.length / 3) * 2),
    ]
  }, [workflowList])

  const issueOptions = useMemo<IOption<ProfileIssueCategoryTypes>[][]>(
    () =>
      profileIssueCategory3Columns.map(column =>
        column.map(option => ({
          value: option,
          label: t(profileIssuesCategoriesI18n[option]),
        })),
      ),
    [t],
  )
  const statesOption = useMemo<IOption<ProfileStateTypes>[][]>(
    () =>
      statesOptionCols.map(column =>
        column.map(option => ({
          value: option,
          label: t(profileStatesI18n[option]),
        })),
      ),
    [t, statesOptionCols],
  )
  const riskOptions = useMemo<IOption<ProfileRiskLevelTypes>[][]>(
    () =>
      profileRisksColumns.map(column =>
        column.map(option => ({
          value: option,
          label: t(profileRisksI18n[option]),
        })),
      ),
    [t],
  )

  const {
    handleSubmit,
    control,
    getValues,
    formState: { isDirty },
  } = useForm<IPartialProfileFilter>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    values: initialValues || applicantFilterGeneralDefault,
  })

  const handleClear = () => {
    onSubmit(profileGlobalFilterDefault)
    onClose()
  }

  const handleFormSubmit: SubmitHandler<IPartialProfileFilter> = data => {
    onSubmit(data)
    onClose()
  }

  const assignToMeCheckBox = {
    label: session?.user.realname || '',
    value: session?.user.email || '',
    node: <p className="text-primary-800">{t('form.assignee.assignToMe')}</p>,
  }

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} className="w-full">
      <div className="leading-tight p-6 pb-4 text-xl font-bold text-tertiary-grey-800">
        {openModal ? t('form.customTitle') : t('form.title')}
      </div>
      <FrankieDivider />
      <div className="py-3">
        <div className="flex flex-col gap-6 py-3 pl-6 pr-4 mr-2 max-h-[calc(100vh-296px)] overflow-y-auto scrollbar-gutter scrollbar">
          <FilterSection header={t('form.headers.state')}>
            <Controller
              control={control}
              name="states"
              render={({ field: { onChange, value } }) => (
                <CheckboxGroup
                  onChange={onChange}
                  optionGroups={statesOption}
                  values={value}
                  testId={{ checkbox: applicantsFilterQa.inputs.statusOption }}
                />
              )}
            />
          </FilterSection>

          <FilterSection header={t('form.headers.issue')}>
            <Controller
              control={control}
              name="issueCategories"
              render={({ field: { onChange, value } }) => (
                <CheckboxGroup
                  optionGroups={issueOptions}
                  onChange={onChange}
                  values={value}
                  testId={{ checkbox: applicantsFilterQa.inputs.issueOption }}
                />
              )}
            />
          </FilterSection>

          <FilterSection header={t('form.headers.riskLevel')}>
            <Controller
              control={control}
              name="riskLevels"
              render={({ field: { onChange, value } }) => (
                <CheckboxGroup
                  optionGroups={riskOptions}
                  onChange={onChange}
                  values={value}
                  testId={{
                    checkbox: applicantsFilterQa.inputs.riskLevelOption,
                  }}
                />
              )}
            />
          </FilterSection>
          <FilterSection header={t('form.headers.createdDate')}>
            <Controller
              control={control}
              name="createdDate"
              rules={{
                validate: validateDateRangeRequired,
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <DateSelector
                  onChange={onChange}
                  value={value}
                  error={error?.message?.split(',')}
                  testId={{
                    inputFrom: applicantsFilterQa.inputs.createdDate.from,
                    inputTo: applicantsFilterQa.inputs.createdDate.to,
                    optionToday: applicantsFilterQa.inputs.createdDate.today,
                    optionYesterday:
                      applicantsFilterQa.inputs.createdDate.yesterday,
                    optionPast7Days:
                      applicantsFilterQa.inputs.createdDate.past7Days,
                    optionPast30Days:
                      applicantsFilterQa.inputs.createdDate.past30Days,
                    optionAll: applicantsFilterQa.inputs.createdDate.all,
                    optionCustom: applicantsFilterQa.inputs.createdDate.custom,
                    optionPastYear:
                      applicantsFilterQa.inputs.createdDate.pastYear,
                  }}
                />
              )}
            />
          </FilterSection>
          <FilterSection header={t('form.headers.updatedDate')}>
            <Controller
              control={control}
              name="updatedDate"
              rules={{
                validate: validateDateRangeRequired,
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <DateSelector
                  onChange={onChange}
                  value={value}
                  // error={error?.message}
                  error={error?.message?.split(',')}
                  testId={{
                    inputFrom: applicantsFilterQa.inputs.updatedDate.from,
                    inputTo: applicantsFilterQa.inputs.updatedDate.to,
                    optionToday: applicantsFilterQa.inputs.updatedDate.today,
                    optionYesterday:
                      applicantsFilterQa.inputs.updatedDate.yesterday,
                    optionPast7Days:
                      applicantsFilterQa.inputs.updatedDate.past7Days,
                    optionPast30Days:
                      applicantsFilterQa.inputs.updatedDate.past30Days,
                    optionAll: applicantsFilterQa.inputs.updatedDate.all,
                    optionCustom: applicantsFilterQa.inputs.updatedDate.custom,
                    optionPastYear:
                      applicantsFilterQa.inputs.updatedDate.pastYear,
                  }}
                />
              )}
            />
          </FilterSection>
          {(workflowList ?? []).length > 0 && (
            <FilterSection header={t('form.headers.workflowNames')}>
              <Controller
                control={control}
                name="workflowNames"
                render={({ field: { onChange, value } }) => (
                  <CheckboxGroup
                    optionGroups={workflowOptions}
                    onChange={onChange}
                    values={value}
                    testId={{
                      checkbox: applicantsFilterQa.inputs.recipeOption,
                    }}
                  />
                )}
              />
            </FilterSection>
          )}
          <FilterSection header={t('form.headers.workflowStatuses')}>
            <Controller
              control={control}
              name="workflowStatuses"
              render={({ field: { onChange, value } }) => (
                <CheckboxGroup
                  optionGroups={workFlowStatusOption}
                  onChange={onChange}
                  values={value}
                  testId={{
                    checkbox: applicantsFilterQa.inputs.recipeStatusOption,
                  }}
                />
              )}
            />
          </FilterSection>

          {users.length > 0 && (
            <FilterSection header={t('form.headers.assignee')}>
              <div className="flex gap-6">
                <Controller
                  control={control}
                  name="assignees"
                  render={({ field: { onChange, value } }) => {
                    const hasUnassigned =
                      value.findIndex(
                        item => item.value === UNASSIGNED_OPTION,
                      ) >= 0
                    const handleUnassignedFilterChange = (
                      event: React.ChangeEvent<HTMLInputElement>,
                    ) => {
                      const payload = value.filter(
                        item => item.value !== UNASSIGNED_OPTION,
                      )
                      if (event.target.checked) {
                        payload.push({
                          value: UNASSIGNED_OPTION,
                          label: t('form.assignee.unassigned'),
                        })
                      }
                      onChange(payload)
                    }

                    return (
                      <>
                        <CheckboxesSearch
                          onChange={onChange}
                          values={value}
                          options={
                            value.find(i => i.value === session?.user.email)
                              ? usersOptions
                              : [assignToMeCheckBox, ...usersOptions]
                          }
                          placeholder={t('form.assignee.placeholder')}
                          testId={{
                            checkbox: applicantsFilterQa.inputs.assignee.option,
                            searchInput:
                              applicantsFilterQa.inputs.assignee.search,
                            closeBadge:
                              applicantsFilterQa.cta.assignee.closeBadge,
                            badge: applicantsFilterQa.cta.assignee.badge,
                            closeButton:
                              applicantsFilterQa.cta.assignee.clearSearch,
                          }}
                        />
                        <label className="flex items-center max-h-9 cursor-pointer grow-0">
                          <FrankieCheckbox
                            onChange={handleUnassignedFilterChange}
                            checked={hasUnassigned}
                            className="basis-1/2"
                            value={hasUnassigned ? 'on' : 'off'}
                            testId={{
                              input:
                                applicantsFilterQa.inputs.assignee.unassigned,
                            }}
                          />
                          <div className="ml-2 leading-tight text-sm">
                            {t('form.assignee.unassigned')}
                          </div>
                        </label>
                      </>
                    )
                  }}
                />
              </div>
            </FilterSection>
          )}
        </div>
      </div>
      <FrankieDivider />
      <div className="flex flex-row items-center justify-between p-6">
        <FrankieButton
          intent="darkOutline"
          onClick={handleClear}
          testId={{ button: applicantsFilterQa.cta.clearFilters }}
        >
          {t('form.cta.clearAll')}
        </FrankieButton>

        <div className="flex gap-3 grow-0">
          <CustomViewApplyFilterSave
            getValues={getValues}
            isDirty={isDirty}
            onSubmit={onSubmit}
            onClose={onClose}
            openModal={openModal}
            handleNavigate={handleNavigate}
          />
          <FrankieButton
            disabled={!isDirty}
            type="submit"
            testId={{ button: applicantsFilterQa.cta.applyFilters }}
          >
            {t('form.cta.applyAll')}
          </FrankieButton>
        </div>
      </div>
    </form>
  )
}
