export const REACT_SIDEBAR_CUSTOM_EVENT = 'react-portal-sidebar'
export const REACT_GLOBAL_SEARCH_FULLSCREEN_EVENT =
  'react-portal-global-search-fullscreen'
export const REACT_GLOBAL_SEARCH_FULLSCREEN_EVENT_DETAIL = 'isFullscreen'
export const REACT_SIDEBAR_CUSTOM_EVENT_DETAIL = 'isOpen'

// IDS ARE TAKEN FROM REACT PORTAL ROOT REPO

const SIDEBAR_ID = 'react-navigation-placeholder'
const HEADER_ID = 'react-header-placeholder'
const APPLICANTS_HEADER_ID = 'react-applicant-header-placeholder'
const LEGACY_HEADER_ID = 'vue-header-placeholder'
const VUE_APP_ID = 'vue-default-app'
const REACT_APP_WRAPPER_ID = 'react-app-wrapper'

const HEADER_HIGH = '69px'
const APPLICANT_HEADER_HEIGHT = '125px'
const APPLICANT_HEADER_WITHOUT_NAV_HEIGHT = '80px'
const HEADER_FULLSCREEN_HEIGHT = '100vh'

// eslint-disable-next-line complexity
function sidebarEventListener(event: Event) {
  // show react sidebar && toggle sidebar isOpen
  const sidebar = document.getElementById(SIDEBAR_ID)
  if (sidebar) {
    sidebar.style.width = `${
      // @ts-ignore
      // eslint-disable-next-line
      !!event.detail?.[REACT_SIDEBAR_CUSTOM_EVENT_DETAIL] ? 240 : 60
    }px`
  }
  // show react header
  const header = document.getElementById(HEADER_ID)
  if (header && header.style.height === '0px') {
    header.style.height = HEADER_HIGH
  }
  // show react applicants header
  const applicantHeader = document.getElementById(APPLICANTS_HEADER_ID)

  if (applicantHeader) {
    if (
      window.location.pathname.endsWith('manual-idv') ||
      window.location.pathname.endsWith('edit-business')
    ) {
      applicantHeader.style.height = APPLICANT_HEADER_WITHOUT_NAV_HEIGHT
    } else {
      applicantHeader.style.height = APPLICANT_HEADER_HEIGHT
    }
  }
  // if vue is default app
  const legacyHeader = document.getElementById(LEGACY_HEADER_ID)
  if (legacyHeader && legacyHeader.style.height !== '0px') {
    // hide legacy header placeholder
    legacyHeader.style.display = 'none'
    // hide vue app
    const vueApp = document.getElementById(VUE_APP_ID)
    if (vueApp) {
      vueApp.style.display = 'none'
    }
    // extend react app
    const reactAppWrapper = document.getElementById(REACT_APP_WRAPPER_ID)
    if (reactAppWrapper) {
      reactAppWrapper.style.margin = '0px'
      reactAppWrapper.style.maxWidth = '100%'
    }
  }
}

function globalSearchFullscreenEventListener(event: Event) {
  const header = document.getElementById(HEADER_ID)
  // if header exists set fullscreen high or regular high
  if (header && header.style.height !== '0px') {
    // @ts-ignore
    // eslint-disable-next-line
    header.style.height = !!event.detail?.[
      REACT_GLOBAL_SEARCH_FULLSCREEN_EVENT_DETAIL
    ]
      ? HEADER_FULLSCREEN_HEIGHT
      : HEADER_HIGH
  }
}

export function listenReact() {
  // This event is emitted in frontend-portal-module-react repo
  // when react sidebar is first rendered or opened/closed or url changes
  window.addEventListener(REACT_SIDEBAR_CUSTOM_EVENT, sidebarEventListener)

  window.addEventListener(
    REACT_GLOBAL_SEARCH_FULLSCREEN_EVENT,
    globalSearchFullscreenEventListener,
  )
}
