import { useMutation } from '@tanstack/react-query'

import {
  WorkflowExecutionResultEnum,
  WorkflowStepResultEnum,
  useGetWorkflowEventsData,
} from 'entities/entity'

import { TrackingEventsTypes, trackingManager } from 'shared/tracking'

import { workflowEventsAPI } from '../api/workflow-events.api'
import { MutateManualStatusType } from '../model/applicant-workflow-events.model'

type Args = {
  entityId: string
}

export const useUpdateWorkflowStatus = ({ entityId }: Args) => {
  const { refetch } = useGetWorkflowEventsData({ entityId })

  return useMutation({
    mutationFn: async (data: MutateManualStatusType) => {
      const res = await workflowEventsAPI.updateWorkflowStatus(entityId, data)
      return res.data
    },
    onSuccess: (_, variable) => {
      void refetch()
      switch (variable.workflowStatus) {
        case WorkflowStepResultEnum.PASS:
          trackingManager.trackWithProps(
            TrackingEventsTypes.WorkflowEventWorkflowStatusManualPassIndividual,
            {
              entityId,
              workflowStatus: variable.workflowStatus,
            },
          )
          break

        case WorkflowStepResultEnum.FAIL:
          trackingManager.trackWithProps(
            TrackingEventsTypes.WorkflowEventWorkflowStatusManualFailIndividual,
            {
              entityId,
              workflowStatus: variable.workflowStatus,
            },
          )
          break

        case WorkflowExecutionResultEnum.REVIEW:
          trackingManager.trackWithProps(
            TrackingEventsTypes.WorkflowEventWorkflowStatusManualNeedsAttentionIndividual,
            {
              entityId,
              workflowStatus: variable.workflowStatus,
            },
          )
          break

        default:
          break
      }
    },
  })
}
