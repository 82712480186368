import { useMutation } from '@tanstack/react-query'

import { ErrorResponse } from 'shared/http'
import { useI18n } from 'shared/i18n'
import { notification } from 'shared/notification'

import { applicantApi } from '../../api/applicant.api'
import { APPLICANT_KEY, applicantEn } from '../../locale/applicant.en'
import {
  ApplicantHostedFlowPayload,
  ApplicantId,
} from '../../model/applicant.model'

type MutateProps = {
  entityId: ApplicantId
  payload: ApplicantHostedFlowPayload
  successMessage?: string
}

export const useApplicantHostedFlowMutation = () => {
  const t = useI18n([APPLICANT_KEY], { keys: applicantEn })

  const mutation = useMutation({
    mutationFn: async ({ entityId, payload }: MutateProps) => {
      const { data } = await applicantApi.getHostedFlow(entityId, payload)
      return data
    },
    onError: (err: ErrorResponse, { payload: { pushToSms } }) => {
      notification.error(
        err.response?.data.message ??
          t(pushToSms ? 'error.linkNotSent' : 'error.linkGeneration'),
      )
    },
    onSuccess: (_, { successMessage }) => {
      if (successMessage) notification.success(successMessage)
    },
  })

  return mutation
}
