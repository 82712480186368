import { ROLES_TO_USERS_KEY } from '../roles-to-users.key'

export const rolesToUsersQa = {
  reassignRolesForm_heading: 'roles-to-users-reassign-roles-form-heading',
  reassignRolesForm_roleSelect:
    'roles-to-users-reassign-roles-form-select-role',
  reassignRolesForm_roleSelectOption:
    'roles-to-users-reassign-roles-form-select-role-option',
  reassignRolesForm_roleSelectEmpty:
    'roles-to-users-reassign-roles-form-select-role-option-empty',
  reassignRolesForm_userName: 'roles-to-users-reassign-roles-form-user-name',
  reassignRolesForm_userTooltip:
    'roles-to-users-reassign-roles-form-user-tooltip',
  reassignRolesForm_userTooltipRole:
    'roles-to-users-reassign-roles-form-user-tooltip-role',
  reassignRolesForm_ctaCancel: 'roles-to-users-reassign-roles-form-cta-cancel',
  reassignRolesForm_ctaSubmit: 'roles-to-users-reassign-roles-form-cta-submit',
} satisfies Record<string, `${typeof ROLES_TO_USERS_KEY}-${string}`>
