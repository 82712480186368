import { useCallback, useMemo } from 'react'

import { generatePath, useOutletContext, useParams } from 'react-router-dom'

import { ApplicantId, ApplicantRouteConfig } from '../../model/applicant.model'

/**
 * Feature to access the applicant paths within routes in applicant layout.
 *
 * @description All the applicant path in the applicant layout are provided in the outlet context of the applicant layout and can be accessed using useOutletContext.
 * useApplicantPaths is wrapper over it.
 */
export function useApplicantPaths() {
  const { paths, applicantIdParamKey } =
    useOutletContext<ApplicantRouteConfig>()

  const params = useParams()

  const applicantId = useMemo(
    () => params[applicantIdParamKey] as ApplicantId,
    [applicantIdParamKey, params],
  )

  const getApplicantPath = useCallback(
    (
      path: keyof ApplicantRouteConfig['paths'],
      overrideApplicantId?: ApplicantId, // If need to override the layout applicant id
    ) =>
      generatePath(paths[path], {
        [applicantIdParamKey]: overrideApplicantId ?? applicantId,
      }),
    [applicantId, applicantIdParamKey, paths],
  )

  return { paths, applicantIdParamKey, applicantId, getApplicantPath }
}
