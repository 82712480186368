import type { PageData } from 'entities/session'

export interface IRecipeOption {
  value: string
  label: string
}

export const getFullRecipesList = (pageData?: PageData): IRecipeOption[] => [
  ...(pageData?.profiles?.profile_options ?? []),
  ...(pageData?.profiles?.profile_options_for_business ?? []),
]
