import { useCallback } from 'react'

import { useQuery, useQueryClient } from '@tanstack/react-query'

const TABLE_SELECTION_STATE_QUERY_KEY = ['tableSelectionState']

type TableState = (string | number)[]

export const useTableSelectionState = () => {
  const queryClient = useQueryClient()

  return useQuery({
    queryKey: TABLE_SELECTION_STATE_QUERY_KEY,
    initialData: [],
    queryFn: () =>
      queryClient.getQueryData<TableState>(TABLE_SELECTION_STATE_QUERY_KEY),
  })
}

export const useUpdateTableSelectionState = () => {
  const queryClient = useQueryClient()

  const update = useCallback(
    (value: TableState) => {
      void queryClient.setQueryData(TABLE_SELECTION_STATE_QUERY_KEY, value)
    },
    [queryClient],
  )

  return update
}
