import React, { useEffect } from 'react'

import { useForm } from 'react-hook-form'

import { FrankieButton, FrankieLoader } from 'frankify/src'

import {
  ExecuteWorkflowPayload,
  useGetWorkflowEventsData,
} from 'entities/entity'
import { useWorkflowListQuery } from 'entities/workflow'

import { SelectFormField, TextAreaFormField } from 'shared/form'
import { useI18n } from 'shared/i18n'
import { useOverlay } from 'shared/overlay'
import { useValidationRules } from 'shared/validation'

import { INDIVIDUAL_PROFILE_INSIGHT_KEY } from '../../individual-profile-insight.key'
import { individualProfileInsightEn } from '../../locale/individual-profile-insight.en'
import { useExecuteWorkflowMutation } from '../../mutation/execute-workflow.mutation'
import { IndividualProfileVerifyQa } from '../../qa/individual-profile-insight.qa'

type Props = {
  entityId: string
}

export function IndividualProfileVerify({ entityId }: Props) {
  const [, closeOverlay] = useOverlay()
  const { xssRule } = useValidationRules()
  const { data: workflows } = useWorkflowListQuery()
  const { data } = useGetWorkflowEventsData({ entityId })

  const { mutate, isLoading } = useExecuteWorkflowMutation({
    entityId,
  })

  const t = useI18n([INDIVIDUAL_PROFILE_INSIGHT_KEY], {
    keys: individualProfileInsightEn,
  })

  const {
    control,
    setValue,
    handleSubmit,
    formState: { isValid },
  } = useForm<ExecuteWorkflowPayload>({
    mode: 'onTouched',
  })

  const profileOptions = workflows?.map(workflow => ({
    label: workflow.workflowName,
    value: workflow.workflowName,
  }))

  useEffect(() => {
    if (data?.currentWorkflowName) {
      setValue('workflowName', data.currentWorkflowName)
    }
  }, [data, setValue])

  const onSubmit = (data: ExecuteWorkflowPayload) => {
    mutate(data, {
      onSuccess() {
        if (!isLoading) {
          closeOverlay()
        }
      },
    })
  }

  return (
    <>
      <FrankieLoader
        size="md"
        fullscreen
        className="z-20"
        loading={isLoading}
        label={t('banner.modal.loading')}
      />
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="w-[440px] flex flex-col gap-4"
      >
        <h1 className="text-xl font-bold text-tertiary-grey-800">
          {t('banner.modal.buttonVerify')}
        </h1>

        <p className="text-mono-black">{t('banner.modal.verifyDescription')}</p>

        <SelectFormField
          className="mt-4 mb-2  basis-1/4"
          inputClassName="h-[37px]"
          control={control}
          name="workflowName"
          testId={{ input: IndividualProfileVerifyQa.workflowNameInput }}
          placeholder={t('banner.modal.selectWorkflow')}
          label={t('banner.modal.selectWorkflow')}
          options={profileOptions || []}
          rules={{ required: true }}
          showErrorText
        />

        <TextAreaFormField
          label={t('banner.modal.commentLabel')}
          control={control}
          rules={{
            required: t('banner.modal.commentError'),
            ...xssRule,
          }}
          showError
          name="comment"
          trim
          maxLength={500}
          inputClassName="!min-h-[86px]"
          placeholder={t('banner.modal.commentPlaceholder')}
          testId={{ input: IndividualProfileVerifyQa.commentInput }}
        />

        <div className="flex justify-end mt-2 gap-5">
          <FrankieButton
            onClick={closeOverlay}
            intent="subtle"
            testId={{ button: IndividualProfileVerifyQa.cancelButton }}
          >
            {t('banner.modal.cancel')}
          </FrankieButton>
          <FrankieButton
            disabled={!isValid}
            type="submit"
            testId={{ button: IndividualProfileVerifyQa.submitButton }}
          >
            {t('banner.modal.runWorkflow')}
          </FrankieButton>
        </div>
      </form>
    </>
  )
}
