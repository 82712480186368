import { PageData } from 'entities/session'

import { bffClient, IClient } from 'shared/client'

export class SSOApi {
  client: IClient

  constructor(client: IClient) {
    this.client = client
  }

  async checkTokenValidity(token: string) {
    return this.client.get<Omit<PageData, 'organization'>>(
      'data/v2/token-validity',
      {
        headers: {
          authorization: token,
          auth: null, // To override the default auth header
        },
      },
    )
  }
}

export const ssoApi = new SSOApi(bffClient)
