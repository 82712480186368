import React from 'react'

import { IFrankieTableHeaderCellProps } from './table-header-cell.types'
import { FrankieButton } from '../button'
import { FrankieIcon, FrankieIconName } from '../icon'

export function FrankieTableHeaderCell({
  children,
  sortValue,
  onClickSort,
  className = '',
  testId = {
    sort: '',
  },
}: IFrankieTableHeaderCellProps): JSX.Element {
  let iconName: FrankieIconName = 'mdiSwapVertical'
  if (sortValue === 'asc') {
    iconName = 'mdiArrowDown'
  }
  if (sortValue === 'desc') {
    iconName = 'mdiArrowUp'
  }
  return (
    <div className={`flex flex-initial py-2 px-4 ${className}`}>
      {sortValue && (
        <FrankieButton
          testId={{ button: testId.sort }}
          onClick={onClickSort}
          className="mr-1"
          noStyles
        >
          <FrankieIcon
            className={
              sortValue === 'off'
                ? 'text-tertiary-grey-500'
                : 'text-primary-800'
            }
            size="xs"
            name={iconName}
          />
        </FrankieButton>
      )}
      {children}
    </div>
  )
}
