import { bffClient, IClient } from 'shared/client'

export class ResetPasswordAPI {
  client: IClient

  constructor(client: IClient) {
    this.client = client
  }

  async requestResetPasswordLink(email: string): Promise<void> {
    await this.client.post(`/auth/v1/sessions/password-reset/${email}`, {})
  }
}

export const resetPasswordApi = new ResetPasswordAPI(bffClient)
