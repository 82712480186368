import React from 'react'

import { FrankieButton } from 'frankify/src'

import { FlowIdTypes } from 'entities/entity'

import { copyToClipboard } from 'shared/clipboard'
import { notification } from 'shared/notification'

import {
  SendLinkEventTypes,
  useSendLinkTranslation,
} from '../../../model/individual-send-verification-link.model'
import { individualSendVerificationLinkActionsQa } from '../../../qa/individual-send-verification-link.qa'
import { useIndividualSendVerificationLinkQuery } from '../../../query/individual-send-verification-link/individual-send-verification-link.query'
import { useSendLinkTrackingEvent } from '../../../state/individual-send-verification-link.state'

type Props = {
  entityId: string
  flowId: FlowIdTypes
}

export function IndividualSendVerificationLinkActions({
  entityId,
  flowId,
}: Props) {
  const { data } = useIndividualSendVerificationLinkQuery({ entityId, flowId })

  const t = useSendLinkTranslation()

  const trackCopy = useSendLinkTrackingEvent(SendLinkEventTypes.copy, entityId)

  const trackSubmit = useSendLinkTrackingEvent(
    SendLinkEventTypes.send,
    entityId,
  )

  const handleCopy = () => {
    void copyToClipboard(data?.url || '')
    notification.success(t('copySuccess'))
    trackCopy()
  }

  return (
    <>
      <FrankieButton
        intent="secondary"
        type="button"
        onClick={handleCopy}
        startIcon={{
          name: 'mdiContentCopy',
          size: 'sm',
          className: 'mr-2',
        }}
        testId={{ button: individualSendVerificationLinkActionsQa.copyBtn }}
      >
        {t('step.copy')}
      </FrankieButton>
      <FrankieButton
        testId={{ button: individualSendVerificationLinkActionsQa.sendBtn }}
        onClick={trackSubmit}
        type="submit"
      >
        {t('step.send')}
      </FrankieButton>
    </>
  )
}
