import { bffClient, IClient } from 'shared/client'

import { BlocklistResponse } from '../model/blocklist.model'

export type BlocklistFilter = {
  attributeFilter?: string
  reasonFilter?: string
}

export class BlocklistAPI {
  client: IClient

  constructor(client: IClient) {
    this.client = client
  }

  async getBlocklist(filters: BlocklistFilter, nextPageMarker?: number) {
    return this.client.get<BlocklistResponse>('data/v1/blocklists', {
      params: {
        ...filters,
        nextPageMarker,
      },
    })
  }
}

export const blocklistApi = new BlocklistAPI(bffClient)
