import { isNumber } from 'shared/typescript'

export interface IWrongCredentialsErrorData {
  attempts_count: number
  attempts_max: number
}

export function isIWrongCredentialsErrorData(
  val: unknown,
): val is IWrongCredentialsErrorData {
  return !!(
    val &&
    typeof val === 'object' &&
    'attempts_max' in val &&
    'attempts_count' in val &&
    isNumber(val.attempts_max) &&
    isNumber(val.attempts_count)
  )
}
