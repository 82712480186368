import React from 'react'

import { FrankieButton } from 'frankify/src'

import { useTableSelectionState } from 'features/profiles-table'

import { useHasFeatureFlag } from 'entities/session'

import { useI18n } from 'shared/i18n'
import { useOverlay } from 'shared/overlay'

import {
  BULK_ASSIGN_WIDGET_KEY,
  bulkAssignWidgetEn,
} from '../../locale/bulk-assign.en'
import { bulkAssignQa } from '../../qa/bulk-assign.qa'
import { BulkAssignOverlay } from '../bulk-assign-overlay/bulk-assign-overlay'

export function BulkAssign() {
  const { data } = useTableSelectionState()
  const [createOverlay] = useOverlay()
  const t = useI18n(BULK_ASSIGN_WIDGET_KEY, { keys: bulkAssignWidgetEn })
  const { bulkAssignEnabled } = useHasFeatureFlag({
    bulkAssignEnabled: 'bulkAssignEnabled',
  })

  const handleClick = () => {
    createOverlay(<BulkAssignOverlay />, {
      className: '!p-5 w-[440px]',
      closeButtonClassName: '!top-5 !right-5',
    })
  }

  if (!bulkAssignEnabled) {
    return null
  }

  return (
    <FrankieButton
      disabled={(data?.length ?? 0) === 0}
      className="px-5 py-2 bg-mono-white shadow-md border-t border-solid border-tertiary-grey-100 disabled:text-tertiary-grey-400 disabled:!bg-mono-white hover:bg-tertiary-grey-200  select-none cursor-pointer w-full text-start"
      onClick={handleClick}
      testId={{ button: bulkAssignQa.button }}
      noStyles
    >
      {t('assignSelected')}
    </FrankieButton>
  )
}
