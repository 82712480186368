export const BULK_ASSIGN_WIDGET_KEY = 'bulk-assign-widget'

export const bulkAssignWidgetEn = {
  assignSelected: 'Assign selected',
  assignSelectedEntities: 'Assign selected entities',
  theUserYouAssignTheseEntitiesToWillReceiveANotification:
    'The user you assign these entities to will receive a notification.',
  changeMatchStatusTo: 'Change match status to',
  thisWillOverrideTheStatusOfTheDocument:
    'This will override the status of the document.',
  assignTo: 'Assign to',
  searchUser: 'Search user',
  comment: 'Comment (optional)',
  theEntityHasBeenVerifiedAfterManualReview:
    'The entity has been verified after manual review',
  noteThatAssignmentsMayTakeACoupleOfMomentsToUpdateIfMoreThan20WereSelected:
    'Note that assignments may take a couple of moments to update if more than 20 were selected.',
  cancel: 'Cancel',
  assignEntities: 'Assign entities',
  assigningEntities:
    '{{count}} entities have been assigned to {{name}}. This may take a few moments.',
  noResultsFound: 'No results found',
  assignToMe: 'Assign to me',
}
