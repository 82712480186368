import React from 'react'

import { FrankieButton, FrankieLoader, FrankieIcon } from 'frankify/src'

import { useI18n } from 'shared/i18n'
import { notification, notificationQa } from 'shared/notification'
import { TrackingEventsTypes, trackingManager } from 'shared/tracking'

import { userManagementQa } from '../../qa/user-management.qa'
import { useDeleteUserMutation } from '../../state/delete-user/delete-user.mutation'
import { USER_MANAGEMENT_KEY } from '../../user-management.key'

type Props = {
  onCancel: () => void
  userId: string
  userName: string
}

export function DeleteUserModal({ onCancel, userId, userName }: Props) {
  const { mutateAsync: deleteUser, isLoading } = useDeleteUserMutation()
  const t = useI18n([USER_MANAGEMENT_KEY])

  const handleDelete = async () => {
    try {
      await deleteUser(userId)
      trackingManager.track(
        TrackingEventsTypes.UserManagementDeleteUserModalDeleteUser,
      )
      notification.success(
        t('deleteUserModal.notification.deleted', { name: userName }),
        {
          icon: (
            <FrankieIcon
              testId={{ icon: notificationQa.successIcon }}
              size="sm"
              className="w-8 h-8 flex flex-initial justify-center items-center bg-tertiary-green-600 rounded-full"
              name="mdiAccountMinusOutline"
            />
          ),
        },
      )
      onCancel()
    } catch (e) {
      // eslint-disable-next-line no-console
      trackingManager.track(
        TrackingEventsTypes.UserManagementDeleteUserModalDeleteUserError,
      )
      console.log('delete error ', e)
    }
  }

  return (
    <FrankieLoader loading={isLoading}>
      <FrankieIcon
        size="54px"
        name="mdiAlertCircleOutline"
        className="flex justify-center text-tertiary-red-700"
      />
      <div className="flex flex-col items-center mt-4">
        <span className="inline-block text-tertiary-grey-800 text-center text-xl font-bold">
          {t('deleteUserModal.heading')} {userName}?
        </span>
        <span className="inline-block text-md text-center font-normal mt-2">
          {t('deleteUserModal.subHeading')}
        </span>
      </div>
      <div className="flex justify-center mt-6">
        <div className="inline-flex">
          <FrankieButton
            intent="darkOutline"
            size="sm"
            type="submit"
            onClick={onCancel}
            className="mr-4"
            testId={{ button: userManagementQa.deleteUserForm_ctaCancel }}
          >
            {t('deleteUserModal.cancelButton')}
          </FrankieButton>
          <FrankieButton
            intent="danger"
            size="sm"
            type="submit"
            onClick={handleDelete}
            testId={{ button: userManagementQa.deleteUserForm_ctaSubmit }}
          >
            {t('deleteUserModal.confirmButton')}
          </FrankieButton>
        </div>
      </div>
    </FrankieLoader>
  )
}
