import React, { MouseEvent } from 'react'

import { useI18n } from 'shared/i18n'

import { ORGANISATION_SEARCH_KEY } from '../../../organisation-search/organisation-search.key'

type Props = {
  onClickManualAdd: (event: MouseEvent<HTMLAnchorElement>) => void
}

export function AustraliaSearchResultGutter({ onClickManualAdd }: Props) {
  const t = useI18n([ORGANISATION_SEARCH_KEY])

  return (
    <div className="mt-8 flex flex-col pb-16">
      <div className="text-center">
        <span className="text-tertiary-grey-700 text-sm font-normal">
          {t('manuallyUppercase')}
        </span>{' '}
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
        <a
          className="text-primary-800 text-sm font-normal cursor-pointer"
          onClick={onClickManualAdd}
        >
          {t('manuallyAdd.addAnOrganisation')}
        </a>{' '}
        <span className="text-tertiary-grey-700 text-sm font-normal">
          {t('manuallyAdd.or')}
        </span>{' '}
        <a
          href="https://apidocs.frankiefinancial.com/docs/create-a-new-business-profile-via-the-portal-1"
          target="_blank"
          className="text-primary-800 text-sm"
          rel="noreferrer"
        >
          {t('leanMoreLowercase')}
        </a>{' '}
        <span className="text-tertiary-grey-700 text-sm font-normal">
          {t('manuallyAdd.about')}
        </span>
      </div>
      <div className="text-center mt-3">
        <a
          href="https://apidocs.frankiefinancial.com/docs/contacting-developer-support"
          target="_blank"
          className="text-primary-800 text-sm block"
          rel="noreferrer"
        >
          {t('giveFeedback')}
        </a>
      </div>
    </div>
  )
}
