import React, { useEffect, useMemo, useState } from 'react'

import { useForm } from 'react-hook-form'
import { navigateToUrl } from 'single-spa'

import {
  FrankieButton,
  FrankieDivider,
  FrankieIcon,
  FrankieLoader,
} from 'frankify/src'

import { useApplicantCheckSummaryState } from 'features/applicant-general-information'
import { useAddApplicantToBlocklistMutation } from 'features/applicant-general-information/state/applicant-profile-state-change/applicant-profile-state-change'

import {
  ApplicantId,
  WatchlistReasonTypes,
  useApplicantDataQuery,
  useApplicantPaths,
} from 'entities/applicant'
import { useAmlCheck } from 'entities/organisation'
import { PermissionTypes } from 'entities/role'
import { useHasPermission } from 'entities/session'

import { SelectFormField, TextAreaFormField } from 'shared/form'
import { useI18n } from 'shared/i18n'
import { notification } from 'shared/notification'
import { useOverlay } from 'shared/overlay'
import { Noop, Nullable } from 'shared/typescript'

import { APPLICANT_POTENTIAL_MATCHES_KEYS } from '../../applicant-potential-matches.keys'
import { applicantPotentialMatchesEn } from '../../locale/applicant-potential-matches.en'
import {
  ApplicantMatchesTypes,
  IPossibleMatchInputs,
  MatchesTypes,
  MatchesValueTypes,
  PossibleMatchTypes,
  matchOptions,
  possibleMatchDefaultValues,
} from '../../models/applicant-matches.models'
import {
  ChangedStatus,
  CheckDuplicateType,
  getStatusBgColor,
  getStatusColor,
} from '../../models/blacklist.model'
import { useCheckBlocklist } from '../../mutation/check-blocklist-mutation/check-blocklist.mutation'
import { useCheckDuplicate } from '../../mutation/check-duplicate.mutation/check-duplicate.mutation'
import { appPotentialMatchesQa } from '../../qa/applicant-potential-matches.qa'

type Args = {
  type: MatchesTypes
  additionalFn?: Noop
  onFinal: Noop
}

const useForceCheckAfterAllStatusChangeToFalse = ({
  onFinal,
  type,
  additionalFn,
}: Args) => {
  const t = useI18n(APPLICANT_POTENTIAL_MATCHES_KEYS, {
    keys: applicantPotentialMatchesEn,
  })

  const {
    mutate: mutateForceCheck,
    isSuccess: forceCheckSuccess,
    isError: forceCheckError,
    isLoading: forceCheckLoading,
  } = useAmlCheck()

  const handleFinal = () => {
    if (additionalFn) {
      additionalFn()
    }
    onFinal()
  }

  useEffect(() => {
    if (forceCheckError) {
      notification.error(t('resolvedSuccessfullyError', { type }))
      handleFinal()
    }
  }, [forceCheckError])

  useEffect(() => {
    if (forceCheckSuccess) {
      notification.success(t('resolvedSuccessfully', { type }))
      handleFinal()
    }
  }, [forceCheckSuccess])

  return { mutateForceCheck, forceCheckLoading }
}

type ModalProps = {
  type: MatchesTypes
  applicantId: ApplicantId
  closeModal: Noop
  matchesLength: number
  noConfirmedStatus: number
  onFinal: Noop
  showNextProfile: Noop
}

export function MoveToWatchlistModal({
  type,
  applicantId,
  closeModal,
  matchesLength,
  onFinal,
  noConfirmedStatus,
  showNextProfile,
}: ModalProps) {
  const t = useI18n(APPLICANT_POTENTIAL_MATCHES_KEYS, {
    keys: applicantPotentialMatchesEn,
  })

  const { mutate, isLoading, isSuccess } = useAddApplicantToBlocklistMutation({
    applicantId,
  })

  const { mutateForceCheck, forceCheckLoading } =
    useForceCheckAfterAllStatusChangeToFalse({
      onFinal,
      type,
      additionalFn: closeModal,
    })

  const onConfirm = () => {
    mutate({
      comment: '',
      flag: MatchesValueTypes.TruePositive,
      flagType: 'watchlist',
      reasonCode: WatchlistReasonTypes.WAS_BLACKLISTED,
    })
  }

  useEffect(() => {
    if (isSuccess && matchesLength === noConfirmedStatus) {
      mutateForceCheck({ entityId: applicantId })
      return
    }

    if (isSuccess) {
      showNextProfile()
      closeModal()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicantId, isSuccess, matchesLength, noConfirmedStatus])

  const onClose = () => {
    if (matchesLength === noConfirmedStatus) {
      mutateForceCheck({ entityId: applicantId })
    } else {
      closeModal()
      showNextProfile()
    }
  }

  return (
    <div className="text-tertiary-grey-700 max-w-[500px] relative">
      <div className="py-4 px-6 border-b border-tertiary-grey-300 text-md font-bold text-tertiary-grey-800 flex justify-start items-center">
        <FrankieIcon name="mdiEyeOutline" size="md" />
        <div className="mx-2">{t('modal.title')}</div>
      </div>
      <FrankieLoader loading={isLoading || forceCheckLoading}>
        <div className="py-4 px-6">
          <div className="font-bold">{t('modal.heading')}</div>
          <div className="font-medium mt-[20px] mb-[50px]">
            {t('modal.description')}
          </div>
          <div className="flex justify-between">
            <FrankieButton onClick={onConfirm}>{t('modal.add')}</FrankieButton>

            <FrankieButton
              intent="secondary"
              noStyles
              className="font-bold"
              onClick={onClose}
            >
              {t('modal.cancel')}
            </FrankieButton>
          </div>
        </div>
      </FrankieLoader>
    </div>
  )
}

type Props = {
  type: MatchesTypes
  matchedView: boolean
  applicantId: ApplicantId
  matchedApplicantId: ApplicantId
  showNextProfile: Noop
  isConfirmedEntity?: Nullable<boolean>
  matchesLength: number
}

// eslint-disable-next-line complexity
export function ApplicantMatchSubheader({
  type,
  matchedView,
  applicantId,
  matchedApplicantId,
  showNextProfile,
  isConfirmedEntity,
  matchesLength,
}: Props) {
  const t = useI18n(APPLICANT_POTENTIAL_MATCHES_KEYS, {
    keys: applicantPotentialMatchesEn,
  })

  const { refetch: refetchApplicantData } = useApplicantDataQuery({
    applicantId,
  })

  const { watchlistReasonCode } = useApplicantCheckSummaryState({ applicantId })

  const [createOverlay, closeOverlay] = useOverlay()

  const { getApplicantPath } = useApplicantPaths()

  const {
    mutate: mutateCheckBlocklist,
    isSuccess: isCheckBlocklistSuccessful,
    isLoading: isCheckBlocklistLoading,
    reset: resetCheckBlocklists,
  } = useCheckBlocklist()

  const {
    mutate,
    isSuccess,
    isLoading: isCheckDuplicateLoading,
    reset: resetDuplicateMutation,
  } = useCheckDuplicate()

  const handleNavigationAndRefetch = () => {
    navigateToUrl(getApplicantPath('generalInformation'))
    void refetchApplicantData()
  }

  const { mutateForceCheck } = useForceCheckAfterAllStatusChangeToFalse({
    onFinal: handleNavigationAndRefetch,
    type,
  })

  const [isStatusChanged, setIsStatusChanged] = useState<ChangedStatus[]>([])
  const [actionLabel, setActionLabel] = useState<string>('')

  const { canRemoveWatchlist } = useHasPermission({
    canRemoveWatchlist: PermissionTypes.ApplicantRemoveWatchlist,
  })

  const {
    control,
    watch,
    reset,
    formState: { isValid },
    handleSubmit,
    setValue,
  } = useForm<IPossibleMatchInputs>({
    mode: 'onTouched',
    defaultValues: {
      ...possibleMatchDefaultValues,
    },
  })

  const watchFlagType = watch(PossibleMatchTypes.flag)

  const onFormSubmit = (formData: IPossibleMatchInputs) => {
    if (
      type === ApplicantMatchesTypes.POTENTIAL &&
      formData[PossibleMatchTypes.flag] === MatchesValueTypes.TruePositive
    ) {
      mutateCheckBlocklist({
        entityId: matchedApplicantId,
        data: {
          comment: formData[PossibleMatchTypes.Comment],
          flag: 'true_positive',
          originalId: applicantId,
        },
      })
      return
    }

    let payload: CheckDuplicateType = { ...formData }

    if (type === ApplicantMatchesTypes.BLOCKLIST) {
      payload = { ...formData, flagType: type }
    }

    if (matchedApplicantId && applicantId)
      mutate({
        ids: { applicantId, matchedApplicantId },
        data: { ...payload },
      })
  }

  useEffect(() => {
    const matchIdx =
      isStatusChanged.findIndex(item => item.id === matchedApplicantId) + 1

    if (!matchIdx) {
      reset({
        ...possibleMatchDefaultValues,
      })
    } else {
      setValue(PossibleMatchTypes.flag, isStatusChanged[matchIdx - 1].type)
    }
  }, [isStatusChanged, matchedApplicantId, reset, setValue])

  // eslint-disable-next-line complexity
  useEffect(() => {
    const matchIdx = isStatusChanged.findIndex(
      item => item.id === matchedApplicantId,
    )

    const currentStatusLength = isStatusChanged.length

    if ((isSuccess || isCheckBlocklistSuccessful) && !(matchIdx + 1)) {
      setIsStatusChanged([
        ...isStatusChanged,
        { id: matchedApplicantId, type: watchFlagType },
      ])

      if (watchFlagType === MatchesValueTypes.TruePositive) {
        if (type === ApplicantMatchesTypes.POTENTIAL) {
          showNextProfile()
        } else {
          resetDuplicateMutation()

          handleNavigationAndRefetch()
        }
      }

      if (
        watchFlagType === MatchesValueTypes.FalsePositive // will be always for blocklist/duplicate
      ) {
        if (watchlistReasonCode || !canRemoveWatchlist) {
          if (matchesLength === currentStatusLength + 1) {
            mutateForceCheck({ entityId: applicantId })
          } else {
            showNextProfile()
          }
        } else {
          createOverlay(
            <MoveToWatchlistModal
              type={type}
              applicantId={applicantId}
              closeModal={closeOverlay}
              matchesLength={matchesLength}
              onFinal={handleNavigationAndRefetch}
              showNextProfile={showNextProfile}
              noConfirmedStatus={currentStatusLength + 1}
            />,
            {
              className: 'p-0',
              closeButtonClassName: 'z-10 !top-4 !right-5',
            },
          )
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCheckBlocklistSuccessful, isSuccess])

  useEffect(() => {
    if (watchFlagType === MatchesValueTypes.Skip) {
      showNextProfile()
      resetCheckBlocklists()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchFlagType])

  const disableSelectStatus = useMemo(() => {
    const matchIdx =
      isStatusChanged.findIndex(item => item.id === matchedApplicantId) + 1

    return !!isConfirmedEntity || !!matchIdx
  }, [isConfirmedEntity, isStatusChanged, matchedApplicantId])

  useEffect(() => {
    const matchIdx =
      isStatusChanged.findIndex(item => item.id === matchedApplicantId) + 1

    if (matchIdx) {
      const status = isStatusChanged[matchIdx - 1].type
      setValue(PossibleMatchTypes.flag, status)

      if (status === MatchesValueTypes.TruePositive) {
        setActionLabel(t('confirmBlocklistMatch'))
      } else {
        setActionLabel(t('falsePositiveMatch'))
      }
      return
    }

    if (
      (isSuccess && watchFlagType === MatchesValueTypes.TruePositive) ||
      isConfirmedEntity
    ) {
      setValue(PossibleMatchTypes.flag, MatchesValueTypes.TruePositive)
      setActionLabel(t(`${type}.confirm`))
      return
    }

    setActionLabel(t(`${type}.match`))
  }, [
    isConfirmedEntity,
    isSuccess,
    watchFlagType,
    isStatusChanged,
    matchedApplicantId,
    t,
    type,
    setValue,
  ])

  const showCommentForm = useMemo(() => {
    const matchIdx =
      isStatusChanged.findIndex(item => item.id === matchedApplicantId) + 1
    return (
      (watchFlagType === MatchesValueTypes.FalsePositive ||
        watchFlagType === MatchesValueTypes.TruePositive) &&
      !isConfirmedEntity &&
      !matchIdx
    )
  }, [isConfirmedEntity, isStatusChanged, matchedApplicantId, watchFlagType])

  return (
    <div data-qa={appPotentialMatchesQa.wrapper}>
      {matchedView ? (
        <FrankieLoader
          loading={isCheckBlocklistLoading || isCheckDuplicateLoading}
        >
          <form onSubmit={handleSubmit(onFormSubmit)}>
            <div
              className={`p-[18px] ${getStatusBgColor(
                isConfirmedEntity,
                isStatusChanged,
                matchedApplicantId,
              )}`}
            >
              <div className=" flex justify-between items-center">
                <div
                  className={`font-bold basis-4/6 ${getStatusColor(
                    isConfirmedEntity,
                    isStatusChanged,
                    matchedApplicantId,
                  )}`}
                >
                  {actionLabel}
                </div>
                <SelectFormField
                  key={`${matchedApplicantId}-${watchFlagType}`}
                  name={PossibleMatchTypes.flag}
                  control={control}
                  options={matchOptions
                    .filter(({ matchType }) => matchType.includes(type))
                    .map(({ tKey, value }) => ({
                      label: t(tKey),
                      value,
                    }))}
                  placeholder={t('isMatch')}
                  className="basis-2/6 mr-2"
                  disabled={disableSelectStatus}
                />
              </div>
              {showCommentForm && (
                <div>
                  <div className="mt-4 text-tertiary-grey-700">
                    <TextAreaFormField
                      label={`${t('comment')}*:`}
                      placeholder={t('commentPlaceholder')}
                      control={control}
                      name="comment"
                      rules={{
                        required: true,
                      }}
                    />
                  </div>

                  <div className="flex items-center justify-end my-3 font-bold">
                    <FrankieButton
                      onClick={() => reset()}
                      noStyles
                      className="mr-2"
                    >
                      {t('cancel')}
                    </FrankieButton>
                    <FrankieButton
                      type="submit"
                      className="mx-2 font-[700]"
                      disabled={!isValid}
                    >
                      {t('saveMatchStatus')}
                    </FrankieButton>
                  </div>
                </div>
              )}
            </div>
          </form>
        </FrankieLoader>
      ) : (
        <div className="text-tertiary-grey-700 p-7 font-bold flex justify-start items-center">
          {t(`${type}.incoming`)}
        </div>
      )}
      <FrankieDivider />
    </div>
  )
}
