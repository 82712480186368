import React from 'react'

import { useI18n } from 'shared/i18n'
import { TrackingEventsTypes, trackingManager } from 'shared/tracking'

import { APPLICANT_KEY, applicantEn } from '../../../locale/applicant.en'
import {
  IFraudCheckpoint,
  IFraudCheckpointRule,
} from '../../../model/applicant-fraud-check.model'
import { CheckHeader } from '../check-header/check-header'
import { ContentItem } from '../content-item/content-item'

type Props = {
  data: IFraudCheckpoint
  email: string | null
  rules: IFraudCheckpointRule[] | null
  checkResult: 'fail' | 'unchecked' | 'pass'
  deviceSardineUrl: string | null
}

export function FraudCheckEmail({
  data,
  email,
  rules,
  checkResult,
  deviceSardineUrl,
}: Props) {
  const t = useI18n(APPLICANT_KEY, { keys: applicantEn })

  return (
    <div>
      <CheckHeader
        checkResult={checkResult}
        riskLevel={data.riskLevel}
        rules={rules}
        ruleIds={data.ruleIds}
        title={t('fraud.email.title')}
        buttonLabel={t('fraud.email.viewRulesCta')}
        modalTitle={t('fraud.checkRules.email')}
        onViewRules={() =>
          trackingManager.track(TrackingEventsTypes.FraudViewResultsEmail)
        }
        deviceSardineUrl={deviceSardineUrl}
      />
      <div>
        <ContentItem name={t('fraud.email.emailAddress')} value={email} />
      </div>
    </div>
  )
}
