import { useMutation } from '@tanstack/react-query'

import { MfaTypes } from 'entities/mfa'

import { bffClient } from 'shared/client'

type MfaEnableResponse = {
  secret: string // MFA_SECRET
  url: string // MFA_URL_FOR_QRCODE_GENERATION
}

type MfaGenerateTokenRequestBody = {
  mfaType: MfaTypes
}

export const useGenerateTokenMutation = () =>
  useMutation({
    mutationFn: (mfaType: MfaTypes) =>
      bffClient.post<MfaEnableResponse, MfaGenerateTokenRequestBody>(
        '/mfa/:version/token/generate',
        {
          mfaType,
        },
      ),
  })
