import { INDIVIDUAL_PROFILE_KEY } from '../individual-profile.keys'

export type AddressFormQaType = 'current' | 'previous' | 'mailing'
export const addressFormQa = (type: AddressFormQaType) => ({
  remove: `remove-address-${type}`,
  country: `address-country-${type}`,
  buildingName: `buildingName-${type}`,
  unitNumber: `unitNumber-${type}`,
  streetNumber: `streetNumber-${type}`,
  streetName: `_streetName-${type}`,
  streetType: `streetType-${type}`,
  town: `town-${type}`,
  suburb: `suburb-${type}`,
  state: `state-${type}`,
  postalCode: `postalCode-${type}`,
})

export const documentTypeQa = (index: number) => ({
  documentWrapper: `document[${index}]`,
  idType: `document[${index}]-type`,
})

/**
 * Migrated from Vue codebase
 */
export const individualProfileVueMigratedQa = {
  recipeDropdown: 'recipe-list-dropdown',
  customerId: 'customerid',
  firstName: 'firstName',
  middleName: 'middleName',
  lastName: 'lastName',
  mobileNumber: 'mobileNumber',
  emailAddress: 'emailAddress',
  autocompleteAddress: 'auto-complete-address-input',
  dob: 'date-of-birth',
  document: {
    documentNumber: 'document_number',
    countryInput: 'country-input',
    idNumberField: 'idNumberField',
    region: 'region',
    registrationYear: 'regYear',
    fullGivenName: 'full-name_given',
    fullNameOther: 'full-name_other',
    fullNameFamily: 'full-name_family',
    reference: 'reference',
    idExpiry: 'idExpiry',
    marriageDate: 'marriageDate',
    brideNameGiven: 'brideName_given',
    brideNameOther: 'brideName_other',
    brideNameFamily: 'brideName_family',
    groomNameGiven: 'groomName_given',
    groomNameOther: 'groomName_other',
    groomNameFamily: 'groomName_family',
    registrationDate: 'regDate',
    primaryCountry: 'primaryCountry',
    secondaryCountry: 'secondaryCountry',
    addNewCta: 'new document button',
    saveAndVerify: 'verify-bt',
    saveChanges: 'save button',
    licenceVersion: 'licenceVersion',
    homeCountryFullName: 'homeCountryFullName',
    nationalId: 'nationalId',
    laserCode: 'laserCode',
    maternalFamilyName: 'maternalFamilyName',
    paternalFamilyName: 'paternalFamilyName',
    gender: 'gender',
  },
}

export const individualProfileQa = {
  button: `${INDIVIDUAL_PROFILE_KEY}-button`,
} satisfies Record<string, `${typeof INDIVIDUAL_PROFILE_KEY}-${string}`>

export const consentFormQa = {
  wrapper: `${INDIVIDUAL_PROFILE_KEY}-wrapper`,
  checkbox: `${INDIVIDUAL_PROFILE_KEY}-checkbox`,
  textArea: `${INDIVIDUAL_PROFILE_KEY}-testArea`,
} satisfies Record<string, `${typeof INDIVIDUAL_PROFILE_KEY}-${string}`>

export const documentFormQa = {
  wrapper: `${INDIVIDUAL_PROFILE_KEY}-doc-form-wrapper`,
  formField: `${INDIVIDUAL_PROFILE_KEY}-doc-form-field`,
  docType: `${INDIVIDUAL_PROFILE_KEY}-doc-form-type`,
  removeBtn: `${INDIVIDUAL_PROFILE_KEY}-doc-form-remove-btn`,
} satisfies Record<
  string,
  `${typeof INDIVIDUAL_PROFILE_KEY}-doc-form-${string}`
>

export const documentSubFormQa = {
  docCategory: `${INDIVIDUAL_PROFILE_KEY}-sub-doc-form-docCategory`,
  controller: `${INDIVIDUAL_PROFILE_KEY}-sub-doc-form-controller`,
  popOver: `${INDIVIDUAL_PROFILE_KEY}-sub-doc-form-popOver`,
  btn: `${INDIVIDUAL_PROFILE_KEY}-sub-doc-form-btn`,
} satisfies Record<
  string,
  `${typeof INDIVIDUAL_PROFILE_KEY}-sub-doc-form-${string}`
>
