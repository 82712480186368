import React, { useState } from 'react'

import { Placement } from '@floating-ui/react'

import { Popover, PopoverContent, PopoverTrigger } from './popover-floating-ui'
import { enableTailwindClass } from '../../styles/tailwind/enable-tailwind-class'

type Props = {
  trigger: React.ReactNode | ((open: boolean) => React.ReactNode)
  children: React.ReactNode
  popoverRest?: {
    placement: Placement
  }
  open?: boolean
  onOpenChange?: (open: boolean, event?: Event) => void
  flip?: boolean
  /**
   * If need to disable initial focus use -1
   */
  initialFocus?: number
  /**
   * Show light overlay when popover is open
   */
  showOverlay?: boolean
}

export function FrankiePopover({
  trigger,
  children,
  popoverRest,
  open,
  onOpenChange,
  flip = true,
  showOverlay = false,
  initialFocus,
}: Props) {
  const [openState, setOpenState] = useState(false)
  const openValue = open ?? openState
  const onOpenChangeValue = onOpenChange ?? setOpenState

  return (
    <Popover
      flip={flip}
      open={openValue}
      onOpenChange={onOpenChangeValue}
      initialFocus={initialFocus}
      showOverlay={showOverlay}
      {...popoverRest}
    >
      <PopoverTrigger asChild onClick={() => setOpenState(v => !v)}>
        {typeof trigger === 'function' ? trigger(openValue) : trigger}
      </PopoverTrigger>
      <PopoverContent>
        <div className={enableTailwindClass}>{children}</div>
      </PopoverContent>
    </Popover>
  )
}
