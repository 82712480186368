import { useState } from 'react'

import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { notification } from 'shared/notification'

import { documentApi, DocumentUploadResponse } from '../api/document.api'

type Args = {
  file: File
  appendTo?: string
}

export const useUploadDocument = (
  handleSuccess: (data: DocumentUploadResponse, variable: Args) => void,
) =>
  useMutation({
    mutationFn: ({ file, appendTo }: Args) =>
      documentApi.uploadDocument(file, appendTo),
    onSuccess: (response, variable) => handleSuccess(response.data, variable),
  })

export const useUploadDocumentV2 = (
  handleSuccess: (data: DocumentUploadResponse, variable: Args) => void,
) => {
  const [uploadProgress, setProgress] = useState(0)
  const [isUploading, setUploading] = useState(false)

  const mutation = useMutation({
    mutationFn: ({ file, appendTo }: Args) =>
      documentApi.uploadDocumentWithProgress(file, appendTo, event => {
        if (event.total) {
          setProgress(Math.round((event.loaded * 100) / event.total))
        }
      }),
    onSuccess: (response, variable) => {
      handleSuccess(response.data, variable)
    },
    onMutate: () => setUploading(true),
    onSettled: () => setUploading(false),
    onError: (error: AxiosError<{ message: string }>) => {
      setUploading(false)
      setProgress(0)
      notification.error(error.response?.data.message)
    },
  })

  return { uploadProgress, isUploading, ...mutation }
}
