import React, { useEffect, useMemo, useState } from 'react'

import { useForm } from 'react-hook-form'

import { FrankieButton, FrankieLoader, FrankiePopover } from 'frankify/src'

import { ApplicantId, useApplicantState } from 'entities/applicant'

import { SelectFormField, TextAreaFormField } from 'shared/form'
import { useI18n } from 'shared/i18n'
import { notification } from 'shared/notification'

import { APPLICANT_BIOMETRICS_OCR_KEY } from '../../applicant-biometrics-ocr.key'
import { applicantBiometricsOcrEn } from '../../locale/applicant-biometrics-ocr.en'
import {
  BiometricDetailType,
  IdvStatusChangePayload,
  ResultStatus,
  StatusUpdatePayload,
} from '../../model/applicant-biometrics-ocr.model'
import { applicantManualOverrideBiometricStatusQa } from '../../qa/applicant-biometric-ocr.qa'
import { useApplicantBiometricsChangeIdvStatus } from '../../state/applicant-biometrics-idc-status-change/applicant-biometrics-idc-status-change.mutation'
import {
  useApplicantBiometricsOcrDocumentState,
  useApplicantBiometryState,
} from '../../state/applicant-biometrics-ocr-data-state/applicant-biometrics-ocr-data.state'
import { useApplicantBiometricsStatusChange } from '../../state/applicant-biometrics-status-change/applicant-biometrics-status-change.mutation'

type Props = {
  applicantId: ApplicantId
  className?: string
  classNameOverride?: string
  detailType: BiometricDetailType | 'DL'
  index: number
  onManualOverride?: (status: { [key: string]: string }) => void
  isVisible?: boolean
}

type FormData = Omit<StatusUpdatePayload, 'detailType'> & {
  detailType: BiometricDetailType | 'DL'
} & IdvStatusChangePayload

/**
 * Handle override status for 'Biometry -facial recognition report, Document - OCR report, OCR - OCR report, DL - Driver License'
 */
export function ApplicantManualOverrideBiometricsStatus({
  applicantId,
  className = '',
  classNameOverride,
  detailType,
  index,
  onManualOverride,
  isVisible = true,
}: Props) {
  const t = useI18n([APPLICANT_BIOMETRICS_OCR_KEY], {
    keys: applicantBiometricsOcrEn,
  })

  const {
    control,
    formState: { isValid },
    handleSubmit,
    reset,
    getValues,
  } = useForm<FormData>()

  const [open, setOpen] = useState(false)

  const { inactiveProfile } = useApplicantState({ applicantId })

  const {
    data: { documentType, biometryReportData },
  } = useApplicantBiometryState({ applicantId, index })

  const {
    data: { documentDetailsData, driverLicenseDocument },
  } = useApplicantBiometricsOcrDocumentState({ applicantId, index })

  const {
    mutate: biometricStatusChangeMutate,
    isLoading: isProcessingBiometricStatusChange,
    isSuccess,
  } = useApplicantBiometricsStatusChange({
    applicantId,
  })

  const {
    mutate: idvStatusChangeMutate,
    isLoading: isProcessingIdvStatusChange,
  } = useApplicantBiometricsChangeIdvStatus({ applicantId })

  useEffect(() => {
    if (isSuccess) {
      notification.success(t('success.statusChanged'))
      setOpen(false)
    }
  }, [getValues, isSuccess, t])

  const options = useMemo(
    () =>
      [
        {
          label: t('manualPass'),
          value: 'MANUAL_PASS',
        },
        { label: t('manualFail'), value: 'MANUAL_FAIL' },
      ] satisfies { label: string; value: ResultStatus }[],
    [t],
  )

  useEffect(() => {
    const defaultValue: Partial<FormData> = {}

    switch (detailType) {
      case 'biometry':
        defaultValue.documentId = biometryReportData?.documentId
        break
      case 'document':
        defaultValue.documentId = documentDetailsData?.ocrReportDocumentId
        defaultValue.old_status = documentDetailsData?.ocrResult
        break
      case 'ocr':
        defaultValue.documentId = documentDetailsData?.documentId
        defaultValue.old_status = documentDetailsData?.result
        break
      case 'facial':
        break
      case 'DL':
        defaultValue.documentId = driverLicenseDocument?.documentId
        defaultValue.documentCountry = driverLicenseDocument?.country
        break
      default:
        break
    }

    reset({
      comment: getValues('comment'),
      status: getValues('status'),
      documentType,
      detailType,
      ...defaultValue,
    })
  }, [
    biometryReportData,
    detailType,
    documentDetailsData,
    documentType,
    driverLicenseDocument,
    getValues,
    reset,
  ])

  const onSubmit = (data: FormData) => {
    const {
      documentCountry,
      detailType,
      comment,
      documentId,
      documentType,
      status,
      old_status,
    } = data

    if (detailType === 'DL') {
      idvStatusChangeMutate(
        { comment, documentId, documentCountry, status },
        { onSuccess: () => onManualOverride?.({ [detailType]: status }) },
      )
    } else {
      biometricStatusChangeMutate(
        {
          detailType,
          documentId,
          documentType,
          status,
          old_status,
          comment,
        },
        { onSuccess: () => onManualOverride?.({ [detailType]: status }) },
      )
    }
  }

  // No need to show manual override if profile is inactive
  if (inactiveProfile || !isVisible) {
    return null
  }

  return (
    <FrankiePopover
      onOpenChange={setOpen}
      open={open}
      popoverRest={{ placement: 'bottom-start' }}
      trigger={
        <FrankieButton
          testId={{
            button: applicantManualOverrideBiometricStatusQa.manualOverrideBtn,
          }}
          noStyles
          className={
            classNameOverride ??
            `${className} text-tertiary-grey-900 border border-solid border-tertiary-grey-900 font-semibold rounded-sm py-2 px-3 hover:text-primary-700 hover:border-primary-700 whitespace-nowrap`
          }
          onClick={() => setOpen(prev => !prev)}
        >
          {t('action.manualOverride')}
        </FrankieButton>
      }
    >
      <FrankieLoader
        label={
          <div className="text-tertiary-grey-800 font-medium">
            {t('loading.update')}
          </div>
        }
        loading={
          isProcessingBiometricStatusChange || isProcessingIdvStatusChange
        }
      >
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col gap-4 p-4 bg-mono-white shadow-lg rounded"
          data-qa={applicantManualOverrideBiometricStatusQa.form}
        >
          <div className="flex items-center gap-2">
            <span className="whitespace-nowrap text-tertiary-grey-800 font-medium">
              {t('overrideOutcome')}
            </span>
            <SelectFormField
              options={options}
              className="flex"
              control={control}
              name="status"
              rules={{ required: true }}
              testId={{
                input: applicantManualOverrideBiometricStatusQa.status,
              }}
            />
          </div>

          <TextAreaFormField
            label={t('comment')}
            name="comment"
            placeholder={t('typeComment')}
            control={control}
            rules={{ required: true }}
            testId={{ input: applicantManualOverrideBiometricStatusQa.comment }}
          />
          <div className="flex gap-3 justify-end">
            <FrankieButton
              noStyles
              className="text-tertiary-grey-900 font-medium"
              size="sm"
              onClick={() => setOpen(false)}
              testId={{
                button: applicantManualOverrideBiometricStatusQa.cancel,
              }}
            >
              {t('action.cancel')}
            </FrankieButton>
            <FrankieButton
              disabled={!isValid}
              className={isValid ? '!bg-mono-100 !outline-mono-50' : ''}
              size="sm"
              type="submit"
              testId={{
                button: applicantManualOverrideBiometricStatusQa.changeStatus,
              }}
            >
              {t('action.changeStatus')}
            </FrankieButton>
          </div>
        </form>
      </FrankieLoader>
    </FrankiePopover>
  )
}
