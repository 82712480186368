import { cva } from 'cva'
import './checkbox.css'

export const CheckboxStyle = cva(
  [
    'frankie-checkbox',
    'absolute',
    'cursor-pointer',
    'disabled:cursor-default',
    'w-1',
    'h-1',
    'before:content-[""]',
    'before:relative',
    'before:block',
    'before:rounded-sm',
    'before:border',
    'before:border-solid',
    'before:border-tertiary-grey-300',
    'before:bg-mono-white',
    'checked:before:bg-primary-800',
    'checked:before:border-none',
    'disabled:before:bg-tertiary-grey-100',
    'disabled:before:border-tertiary-grey-200',
    'checked:disabled:before:bg-primary-200',
    'after:content-[""]',
    'after:relative',
    'after:block',
    'after:bg-no-repeat',
    'after:bg-center',
  ],
  {
    variants: {
      size: {
        sm: [
          'top-2',
          'left-2',
          'before:bottom-2',
          'before:right-2',
          'before:w-4',
          'before:h-4',
          'after:w-4',
          'after:h-4',
          'after:bottom-6',
          'after:right-2',
          "checked:after:bg-[url('data:image/svg+xml,%3Csvg%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20d%3D%22M10.5%203.5L4.5%209.5L1.75%206.75L2.455%206.045L4.5%208.085L9.795%202.795L10.5%203.5Z%22%20fill%3D%22white%22%2F%3E%0A%3C%2Fsvg%3E%0A')]",
        ],
        md: [
          'top-2.5',
          'left-2.5',
          'before:bottom-2.5',
          'before:right-2.5',
          'before:w-5',
          'before:h-5',
          'after:w-5',
          'after:h-5',
          'after:bottom-[30px]',
          'after:right-2.5',
          "checked:after:bg-[url('data:image/svg+xml,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20d%3D%22M14%204.66667L6.00001%2012.6667L2.33334%209L3.27334%208.06L6.00001%2010.78L13.06%203.72667L14%204.66667Z%22%20fill%3D%22white%22%2F%3E%0A%3C%2Fsvg%3E%0A')]",
        ],
        lg: [
          'before:bottom-3',
          'before:right-3',
          'before:w-6',
          'before:h-6',
          'after:w-6',
          'after:h-6',
          'after:bottom-9',
          'after:bottom-9',
          'after:right-3',
          "checked:after:bg-[url('data:image/svg+xml,%3Csvg%20width%3D%2220%22%20height%3D%2220%22%20viewBox%3D%220%200%2020%2020%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20d%3D%22M17.5%205.83334L7.49999%2015.8333L2.91666%2011.25L4.09166%2010.075L7.49999%2013.475L16.325%204.65834L17.5%205.83334Z%22%20fill%3D%22white%22%2F%3E%0A%3C%2Fsvg%3E%0A')]",
        ],
      },
      partial: {
        true: [],
      },
    },
    compoundVariants: [
      {
        size: 'sm',
        partial: true,
        className:
          "checked:after:!bg-[url('data:image/svg+xml,%3Csvg%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20d%3D%22M9.5%206.5H2.5V5.5H9.5V6.5Z%22%20fill%3D%22white%22%2F%3E%0A%3C%2Fsvg%3E%0A')]",
      },
      {
        size: 'md',
        partial: true,
        className:
          "checked:after:!bg-[url('data:image/svg+xml,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20d%3D%22M12.6667%208.66667H3.33333V7.33334H12.6667V8.66667Z%22%20fill%3D%22white%22%2F%3E%0A%3C%2Fsvg%3E%0A')]",
      },
      {
        size: 'lg',
        partial: true,
        className:
          "checked:after:!bg-[url('data:image/svg+xml,%3Csvg%20width%3D%2220%22%20height%3D%2220%22%20viewBox%3D%220%200%2020%2020%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20d%3D%22M15.8333%2010.8333H4.16667V9.16667H15.8333V10.8333Z%22%20fill%3D%22white%22%2F%3E%0A%3C%2Fsvg%3E%0A')]",
      },
    ],
  },
)
export const ContainerStyle = cva(['relative'], {
  variants: {
    size: {
      sm: ['w-4', 'h-4'],
      md: ['w-5', 'h-5'],
      lg: ['w-6', 'h-6'],
    },
  },
})
