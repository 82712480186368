import React, { useMemo, useState } from 'react'

import {
  DataGridPro,
  GridPaginationModel,
  GridSortModel,
  gridClasses,
} from '@mui/x-data-grid-pro'
import { Controller, useForm } from 'react-hook-form'
import { Trans } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import {
  FrankieButton,
  FrankieCheckbox,
  FrankieLoader,
  IFrankieCheckboxProps,
} from 'frankify/src'
import { Banner } from 'frankify/src/components/banner'

import {
  ApplicantId,
  DocumentTabTypes,
  useApplicantPaths,
} from 'entities/applicant'

import { Pagination } from 'shared/data-grid'
import { DateFormatTypes, formatDate } from 'shared/date-time'
import { WithProps } from 'shared/hoc'
import { useI18n } from 'shared/i18n'
import { useOverlay } from 'shared/overlay'

import { PurchaseDocuments } from './purchase-documents/purchase-document'
import { APPLICANT_SUPPORTING_DOCUMENTS_KEY } from '../../applicant-supporting-documents.key'
import { applicantSupportingDocumentsEn } from '../../locale/applicant-supporting-documents.en'
import { BusinessDocumentCatalogRowType } from '../../model/applicant-supporting-documents.model'
import { getColumns } from '../../model/organisation-documents-catalog.model'
import { useBusinessDocumentsCatalogData } from '../../state/business-documents-catalog-query/business-documents-catalog-query'
import { DocumentTableHeader } from '../applicant-support-document-table-helper/applicant-support-document-table-helper'
import {
  OrganisationDocumentNoRowsOverlay,
  OrganisationDocumentTableActionMenu,
} from '../organisation-documents-catalog-table-cell/organisation-documents-catalog-table-cell'

type Props = {
  applicantId: ApplicantId
}

type TableCheckboxProps = IFrankieCheckboxProps & {
  inputProps: {
    'aria-label':
      | 'Select all rows'
      | 'Unselect all rows'
      | 'Select row'
      | 'Unselect row'
  }
  indeterminate: boolean
  totalCount: number
  selectedCount: number
  notSelectableCount: number
}

function TableCheckbox({
  indeterminate,
  checked,
  inputProps,
  totalCount,
  notSelectableCount,
  selectedCount,
  ...rest
}: TableCheckboxProps) {
  const isHeader =
    inputProps['aria-label'] === 'Select all rows' ||
    inputProps['aria-label'] === 'Unselect all rows'

  const isAllSelected =
    totalCount === selectedCount + notSelectableCount &&
    isHeader &&
    totalCount !== notSelectableCount

  return (
    <FrankieCheckbox
      checked={(checked && !indeterminate) || isAllSelected}
      {...rest}
    />
  )
}

export function OrganisationDocumentsCatalog({ applicantId }: Props) {
  const [createOverlay, closeOverlay] = useOverlay()

  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: 'purchased', sort: 'asc' },
  ])

  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    pageSize: 25,
    page: 0,
  })

  const t = useI18n([APPLICANT_SUPPORTING_DOCUMENTS_KEY], {
    keys: applicantSupportingDocumentsEn,
  })

  const navigate = useNavigate()
  const { getApplicantPath } = useApplicantPaths()

  const { rows, isLoading, refetch } = useBusinessDocumentsCatalogData({
    applicantId,
    disabled: false,
  })

  const form = useForm<{ docs: string[]; failedRetryId: string }>({
    defaultValues: { docs: [], failedRetryId: '' },
  })

  const { control, setValue } = form

  const isAnyDocInprogress = useMemo(
    () => rows.some(row => row.status === 'Waiting on Provider'),
    [rows],
  )

  const columns = getColumns(t)

  const handleClose = () => {
    const isFailedIdSet = form.getValues('failedRetryId')
    if (isFailedIdSet) {
      setValue('failedRetryId', '')
      setValue('docs', [])
    }

    closeOverlay()
  }

  const handleClick = () => {
    createOverlay(
      <PurchaseDocuments
        applicantId={applicantId}
        close={handleClose}
        rows={rows}
        form={form}
      />,
      { className: 'w-[700px] p-0', closeButtonClassName: 'hidden' },
    )
  }

  const handleActionClick = (row: BusinessDocumentCatalogRowType) => {
    form.setValue('docs', [row.id])
    setValue('failedRetryId', row.id)
    handleClick()
  }

  const totalCountPerpage = useMemo(() => {
    const noOfPage = Math.ceil(rows.length / paginationModel.pageSize)
    if (noOfPage === paginationModel.page + 1) {
      return rows.length % paginationModel.pageSize
    }
    return paginationModel.pageSize
  }, [paginationModel.page, paginationModel.pageSize, rows.length])

  return (
    <div
      className={`relative flex-1 h-full overflow-x-hidden px-3 ${
        isLoading ? 'overflow-hidden' : ''
      }`}
    >
      {isAnyDocInprogress && (
        <Banner
          heading={t('documentCatalog.banner.heading')}
          description={t('documentCatalog.banner.description')}
          theme="info"
        />
      )}

      <div className="text-xl text-secondary-900 font-bold pb-2">
        {t('documentCatalog.title')}
      </div>
      {isLoading && (
        <div className="absolute z-10 inset-x-[0px] top-[40px] bottom-[65px] w-full h-full flex flex-col justify-center items-center bg-mono-white bg-opacity-90 overflow-hidden">
          <FrankieLoader
            label={t('loadingRegistryDocuments')}
            className="max-w-[320px]"
            loading
          />
        </div>
      )}
      {rows.length > 0 && (
        <div className="text-tertiary-grey-700">
          <Trans
            i18nKey="applicant_supporting_documents:documentCatalog.description"
            components={[
              // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events, jsx-a11y/anchor-is-valid
              <a
                key="button"
                rel="noreferrer"
                onClick={() => {
                  navigate(getApplicantPath('supportingDocuments'), {
                    state: { activeTabKey: DocumentTabTypes.PURCHASED },
                  })
                }}
                className="underline decoration-1 cursor-pointer"
              >
                here
              </a>,
              <span>
                {formatDate(
                  new Date().toDateString(),
                  DateFormatTypes.FullMonthDate,
                )}
              </span>,
            ]}
            values={{
              lastRetrieveDate: formatDate(
                new Date().toDateString(),
                DateFormatTypes.FullMonthDate,
              ),
            }}
          />
        </div>
      )}

      <Controller
        name="docs"
        control={control}
        render={({ field: { onChange, value } }) => (
          <>
            <div className="flex w-full gap-4 justify-between items-center py-4">
              {!rows.length ? (
                <div className="max-w-[calc(100%-200px)]">
                  <p className="text-tertiary-grey-700">
                    {t('documentCatalog.descriptionNoRows1')}
                  </p>
                  <p className="text-tertiary-grey-700">
                    {t('documentCatalog.descriptionNoRows2')}
                  </p>
                </div>
              ) : (
                <div className=" flex-1 text-tertiary-grey-700">
                  {t('documentCatalog.documentsSelected', {
                    number: value.length,
                  })}
                </div>
              )}
              {!rows.length && (
                <FrankieButton
                  onClick={() => refetch()}
                  disabled={isLoading}
                  intent="secondary"
                >
                  {t('documentCatalog.retrieveList')}
                </FrankieButton>
              )}
              {rows.length > 0 && (
                <FrankieButton onClick={handleClick} disabled={!value.length}>
                  {t('documentCatalog.purchaseDocument')}
                </FrankieButton>
              )}
            </div>
            <DataGridPro
              rows={rows}
              slots={{
                baseCheckbox: WithProps(TableCheckbox, {
                  totalCount: totalCountPerpage,
                  selectedCount: value.length,
                  notSelectableCount: rows.filter((row, idx) => {
                    const startIdx =
                      paginationModel.pageSize * paginationModel.page
                    let endIdx =
                      paginationModel.pageSize * (paginationModel.page + 1)

                    if (rows.length < endIdx) {
                      endIdx = rows.length
                    }

                    const range = startIdx <= idx && endIdx > idx

                    return range && !row.isSelectable
                  }).length,
                }),
                noRowsOverlay: OrganisationDocumentNoRowsOverlay,
                pagination: WithProps(Pagination, {
                  totalCount: rows.length,
                  count: Math.ceil(rows.length / paginationModel.pageSize),
                  page: paginationModel.page + 1,
                  pageSize: paginationModel.pageSize,
                  onChange: (_val, page) => {
                    setPaginationModel(prev => ({ ...prev, page: page - 1 }))
                    setValue('docs', [])
                  },
                }),
              }}
              columns={[
                ...columns,
                {
                  field: 'action',
                  headerName: '',
                  sortable: false,
                  renderCell: WithProps(OrganisationDocumentTableActionMenu, {
                    handleActionClick,
                  }),
                },
              ].map(col => ({
                renderHeader: WithProps(
                  DocumentTableHeader<BusinessDocumentCatalogRowType>,
                  { sortModel },
                ),
                resizable: false,
                sortable: false,
                hideSortIcons: true,
                disableColumnMenu: true,
                disableReorder: true,
                filterable: true,
                ...col,
              }))}
              sx={{
                [`&.${gridClasses.root}`]: {
                  border: 'none',
                },
                '.MuiDataGrid-columnSeparator': {
                  display: 'none',
                },
                '& .MuiDataGrid-virtualScroller': {},
                '& .MuiDataGrid-columnHeaderTitleContainerContent': {
                  height: '100%',
                },

                '& .MuiDataGrid-detailPanelToggleCell': {
                  opacity: 0.7,
                },
                "& .MuiDataGrid-detailPanelToggleCell[aria-label='Expand']": {
                  transform: 'rotate(-90deg)',
                },
                '& .MuiDataGrid-row.Mui-hovered, .MuiDataGrid-row:hover': {
                  backgroundColor: 'transparent',
                },
                '& .MuiDataGrid-row.Mui-selected': {
                  bgcolor: 'transparent',
                },
                '& .MuiDataGrid-selectedRowCount': {
                  display: 'none',
                },
                '& .MuiDataGrid-footerContainer': {
                  marginTop: '10px',
                  justifyContent: 'center !important',
                  flexDirection: 'column',
                  alignItems: 'center',
                  border: 'none',
                },
              }}
              className="[&_.MuiDataGrid-virtualScrollerContent]:border-neutral-30 [&_.MuiDataGrid-columnHeadersInner]:bg-tertiary-grey-50 [&_.MuiDataGrid-columnHeaderTitle]:text-xs  [&_.MuiDataGrid-columnHeaderTitle]:font-medium [&_.MuiDataGrid-columnHeadersInner]:text-tertiary-grey-500  [&_.MuiDataGrid-columnHeader]:!outline-none"
              getCellClassName={() =>
                '!outline-none !border-neutral-30 !items-center !py-4 break-words'
              }
              getRowClassName={() => ' hover:!bg-transparent !min-h-[60px]'}
              rowSelectionModel={value}
              onRowSelectionModelChange={newSelection => onChange(newSelection)}
              loading={isLoading}
              sortModel={sortModel}
              onSortModelChange={setSortModel}
              checkboxSelectionVisibleOnly
              isCellEditable={() => false}
              getRowHeight={() => 'auto'}
              columnHeaderHeight={40}
              isRowSelectable={({ row }) => row.isSelectable}
              checkboxSelection
              disableRowSelectionOnClick
              disableColumnSelector
              disableColumnFilter
              disableColumnMenu
              disableDensitySelector
              showCellVerticalBorder={false}
              showColumnVerticalBorder={false}
              disableColumnResize
              disableColumnReorder
              disableVirtualization
              disableColumnPinning
              hideFooter={rows.length <= paginationModel.pageSize}
              autoHeight
              paginationModel={paginationModel}
              rowCount={rows.length}
              pagination
            />
          </>
        )}
      />
    </div>
  )
}
