import React, { useCallback, useMemo } from 'react'

import { GridColumnHeaderParams, GridRowProps } from '@mui/x-data-grid-pro'

import { FrankieButton, FrankieLoader } from 'frankify/src'

import {
  iKybAmlSlotQa,
  iKybIssuesCellQa,
  iKybMatchStatusLabelQa,
  iKybMatchStrengthBarQa,
  iKybMatchStrengthQa,
  iKybSelectedEntityCellHeaderQa,
  iKybSelectedEntityCellQa,
} from 'features/ikyb-aml-screening/qa/ikyb-aml-screening.qa'

import { ApplicantIssueActionBadge } from 'entities/applicant'

import { useI18n } from 'shared/i18n'
import { ExclusiveProperty, PartialRecord, aliasKeys } from 'shared/typescript'

import { IKYB_AML_SCREENING_KEY } from '../../ikyb-aml-screening.key'
import { iKybAmlScreeningEn } from '../../locale/ikyb-aml-screening.en'
import {
  AmlScreeningMatchRecord,
  IKybAmlScreeningStatus,
  IKybAmlScreeningStatusResult,
} from '../../model/ikyb-aml-screening.model'

export type RowProps<TRow = AmlScreeningMatchRecord> = GridRowProps & {
  row: TRow
}
export type HeaderProps = GridColumnHeaderParams<AmlScreeningMatchRecord>

type SelectCellProps = {
  selectedMatchIds: string[]
  setSelectedMatchIds: (selectedMatchIds: string[]) => void
}

export function SelectedEntityCellHeader({
  rows,
  selectedMatchIds,
  setSelectedMatchIds,
}: HeaderProps & SelectCellProps & { rows: AmlScreeningMatchRecord[] }) {
  const t = useI18n(IKYB_AML_SCREENING_KEY, {
    keys: iKybAmlScreeningEn,
  })

  const selected = useMemo(
    () =>
      rows.every(row => selectedMatchIds.includes(row.id)) && rows.length > 0,
    [rows, selectedMatchIds],
  )

  const handleSelectAll = () => {
    if (selected) {
      setSelectedMatchIds([])
    } else {
      const allMatchListIds: string[] = []
      rows.forEach(row => {
        if (!allMatchListIds.includes(row.id)) {
          allMatchListIds.push(row.id)
        }
      })
      setSelectedMatchIds(allMatchListIds)
    }
  }
  return (
    <div
      data-qa={iKybSelectedEntityCellHeaderQa.container}
      className="mr-4 ml-auto"
    >
      <FrankieButton
        testId={{ button: iKybSelectedEntityCellHeaderQa.btn }}
        noStyles
        onClick={handleSelectAll}
        className="w-4 h-4 p-[2px] mx-2 m-auto shadow-sm border border-tertiary-grey-500 border-solid rounded-full"
      >
        {selected && (
          <div className="rounded-full bg-tertiary-red-500 w-full h-full" />
        )}
      </FrankieButton>
      <span data-qa={iKybSelectedEntityCellHeaderQa.selected}>
        {' '}
        {t('field.selected')}
      </span>
    </div>
  )
}

export function SelectedEntityCell({
  row,
  selectedMatchIds,
  setSelectedMatchIds,
}: RowProps & SelectCellProps) {
  const selected = selectedMatchIds.includes(row.id)

  const handleSelectAll = () => {
    setSelectedMatchIds(
      selected
        ? selectedMatchIds.filter(matchId => matchId !== row.id)
        : [...selectedMatchIds, row.id],
    )
  }

  return (
    <FrankieButton
      noStyles
      onClick={e => {
        e.stopPropagation()
        handleSelectAll()
      }}
      testId={{ button: iKybSelectedEntityCellQa.button }}
      className="w-4 h-4 p-[2px] ml-2 shadow-sm border border-tertiary-grey-500 border-solid rounded-full"
    >
      {selected && (
        <div
          data-qa={iKybSelectedEntityCellQa.selected}
          className="rounded-full bg-tertiary-red-500 w-full h-full"
        />
      )}
    </FrankieButton>
  )
}

export function IssuesCell({ row }: RowProps) {
  return (
    <div
      data-qa={iKybIssuesCellQa.container}
      className="flex flex-wrap gap-[5px]"
    >
      {row.issues.map(issue => (
        <ApplicantIssueActionBadge
          key={issue}
          className="!font-semibold text-xs !p-1 max-w-min min-w-[30px] !rounded-md"
          type={issue}
        />
      ))}
    </div>
  )
}

export const useMatchStatusLabel = () => {
  const t = useI18n(IKYB_AML_SCREENING_KEY, {
    keys: iKybAmlScreeningEn,
  })

  const getLabel = useCallback(
    (matchStatus?: IKybAmlScreeningStatusResult) => {
      const labelMap: PartialRecord<IKybAmlScreeningStatus, string> = {
        true_positive: t('status.true'),
        false_positive: t('status.false'),
        unknown: t('status.unknown'),
        possible_match: t('status.possible'),
        true_positive_accept: t('status.approved'),
        true_positive_reject: t('status.rejected'),
        true_positive_approve: t('status.approved'),
      }

      if (matchStatus) {
        return labelMap[matchStatus] ?? t('status.possible')
      }

      return t('status.possible')
    },
    [t],
  )

  const sortMatchStatusComparator = useCallback(
    (a?: IKybAmlScreeningStatusResult, b?: IKybAmlScreeningStatusResult) => {
      const aLabel = getLabel(a)
      const bLabel = getLabel(b)
      return aLabel.localeCompare(bLabel)
    },
    [getLabel],
  )

  return { getLabel, sortMatchStatusComparator }
}

type MatchStatusProps = {
  matchStatus?: AmlScreeningMatchRecord['matchStatus'] | null
  className?: string
}

export function MatchStatusLabel({
  className = '',
  matchStatus,
}: MatchStatusProps) {
  const { getLabel } = useMatchStatusLabel()

  const colorClass = useMemo(() => {
    const colorClassMap: PartialRecord<IKybAmlScreeningStatus, string> = {
      ...aliasKeys(
        ['true_positive', 'true_positive_reject'],
        'text-tertiary-red-500',
      ),
      ...aliasKeys(
        ['false_positive', 'true_positive_accept', 'true_positive_approve'],
        'text-tertiary-green-500',
      ),

      ...aliasKeys(['unknown', 'possible_match'], 'text-tertiary-amber-500'),
    }

    if (matchStatus) {
      return colorClassMap[matchStatus] ?? 'text-tertiary-amber-500'
    }

    return 'text-tertiary-amber-500'
  }, [matchStatus])

  return (
    <div
      data-qa={iKybMatchStatusLabelQa.container}
      className={`${colorClass} ${className}`}
    >
      {getLabel(matchStatus)}
    </div>
  )
}

export function MatchCell({ row }: RowProps) {
  return (
    <MatchStatusLabel className="font-medium" matchStatus={row.matchStatus} />
  )
}

type MatchStrengthBarProps = { className?: string; strength: number }

export function MatchStrengthBar({
  className = '',
  strength = 0,
}: MatchStrengthBarProps) {
  return (
    <div
      data-qa={iKybMatchStrengthBarQa.container}
      className={`${className} w-[159px] bg-tertiary-grey-300 h-[6px] rounded-2xl`}
    >
      <div
        data-qa={iKybMatchStrengthBarQa.barContainer}
        className="bg-primary-500 h-[6px] rounded-2xl"
        style={{
          width: `${strength < 100 ? strength : 100}%`,
        }}
      />
    </div>
  )
}

export function MatchStrengthView({
  matchPercentage,
}: {
  matchPercentage: number | null
}) {
  // Not to show the match strength if it is null
  if (matchPercentage === null) {
    return ''
  }

  return (
    <div
      className="flex items-center gap-2"
      data-qa={iKybMatchStrengthQa.container}
    >
      <MatchStrengthBar strength={matchPercentage} />
      <span data-qa={iKybMatchStrengthQa.score}>{`${matchPercentage}%`}</span>
    </div>
  )
}

export function MatchStrengthCell({ row }: RowProps<{ matchScore: number }>) {
  const { matchScore } = row

  return <MatchStrengthView matchPercentage={matchScore} />
}

type IKybAmlSlotProps = ExclusiveProperty<{
  loadingOverlay: true
  noRowsOverlay: true
}>

export function IKybAmlSlot({
  loadingOverlay,
  noRowsOverlay,
}: IKybAmlSlotProps) {
  const t = useI18n(IKYB_AML_SCREENING_KEY, {
    keys: iKybAmlScreeningEn,
  })

  return (
    <div data-qa={iKybAmlSlotQa.container}>
      {loadingOverlay && (
        <div className="flex  items-center p-1 flex-col w-full h-full bg-mono-white opacity-80">
          <FrankieLoader
            loading
            className="min-h-[35px]"
            size="xs"
            testId={{ loader: iKybAmlSlotQa.loader }}
          />
          <span className="inline-block font-medium text-tertiary-grey-700">
            {t('loading.records')}
          </span>
        </div>
      )}

      {noRowsOverlay && (
        <div
          className="flex flex-col items-start p-4 border-b border-tertiary-grey-200"
          data-qa={iKybAmlSlotQa.recordContainer}
        >
          <span className="inline-block text-tertiary-grey-800">
            {t('empty.amlRecords')}
          </span>
        </div>
      )}
    </div>
  )
}
