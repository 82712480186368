import { generatePath, useParams } from 'react-router-dom'

import { f2Paths, newPaths, paths } from 'entities/routing/model/router'

type RouteKey =
  | keyof typeof f2Paths
  | keyof typeof paths
  | keyof typeof newPaths

export function useApplicantRoute() {
  const params = useParams()

  const generateRoute = ({
    routeKey,
    overrideParams = {},
  }: {
    routeKey: RouteKey
    overrideParams?: { [key: string]: string }
  }) => {
    const route =
      f2Paths[routeKey as keyof typeof f2Paths] ||
      paths[routeKey as keyof typeof paths] ||
      newPaths[routeKey as keyof typeof newPaths]

    const finalParams = { ...params, ...overrideParams }

    return generatePath(route, finalParams)
  }

  return { generateRoute }
}
