import React from 'react'

import { FrankieAvatar, FrankieButton, FrankieTooltip } from 'frankify/src'

export function AvatarCell({
  name,
  className = '',
  notFull = false,
}: {
  name?: string
  className?: string
  notFull?: boolean
}) {
  if (!name) return null

  const abbreviation = name
    .split(' ')
    .map(name => name[0])
    .slice(0, 2)
    .join('')
    .toUpperCase()

  return (
    <div
      className={`w-full h-full whitespace-normal px-4 py-3 flex flex-col justify-center items-center ${className}`}
    >
      <FrankieTooltip position="top" body={name}>
        {notFull ? (
          <div className="bg-tertiary-blue-dark-600 rounded-full !w-8 !h-8 !max-w-8 !max-h-8 text-mono-white flex justify-center items-center">
            {abbreviation}
          </div>
        ) : (
          <FrankieButton noStyles>
            <FrankieAvatar name={name} />
          </FrankieButton>
        )}
      </FrankieTooltip>
    </div>
  )
}
