import { useQuery } from '@tanstack/react-query'

import { individualSendVerificationLinkApi } from '../../api/individual-send-verification-link.api'

export const IndividualSendLinkContentQueryKey = 'individual-send-link-content'

export const useIndividualSendLinkContentQuery = () =>
  useQuery({
    queryKey: [IndividualSendLinkContentQueryKey],
    queryFn: async () =>
      (await individualSendVerificationLinkApi.getSmsContent()).data,
  })
