export const APPLICANTS_FILTER_WIDGET_KEY = 'applicants-filter-widget'

export const applicantsFilterWidgetEn = {
  cta: {
    entityStatus: 'Entity status',
    entityStatus_one: '1 entity status',
    entityStatus_other: '{{count}} entity statuses',
    issues: 'Issues',
    issues_one: '1 issue',
    issues_other: '{{count}} issues',
    filters: 'Filters',
    clearFilters: 'Clear filters',
  },
}
