export const FILTERS_COMMON_KEY = 'filters_common'

export const filtersCommonEn = {
  form: {
    title: 'Filters',
    headers: {
      entityType: 'Entity type',
      entityStatus: 'Entity status',
      issue: 'Issue',
      riskLevel: 'Risk level',
      createdDate: 'Created date',
      lastUpdatedDate: 'Last updated',
      recipe: 'Recipe',
      assignee: 'Assignee',
    },
    cta: {
      showLess: 'Show less',
      viewAll: 'View all',
      applyAll: 'Apply filters',
      clearAll: 'Clear all',
      apply: 'Apply',
      clear: 'Clear',
    },
    date: {
      custom: 'Custom',
      all: 'All',
      today: 'Today',
      yesterday: 'Yesterday',
      past7Days: 'Past 7 days',
      past30Days: 'Past 30 days',
      pastYear: 'Past year',
      from: 'From',
      to: 'To',
      startValidation: 'Start date can not be empty',
      endValidation: 'End date can not be empty',
      startBeforeEndValidation: 'Start date can not be after end date',
    },
    assignee: {
      placeholder: 'Search user',
    },
  },
  plusMore: '+ more',
}
