import { useCallback } from 'react'

import { useMutation } from '@tanstack/react-query'

import { AuditReportSourceOptionsTypes } from 'entities/individual-audit-report-f2'

import { downloadBlobAsFile } from 'shared/file'
import { ErrorResponse } from 'shared/http'
import { useI18n } from 'shared/i18n'
import { notification } from 'shared/notification'
import { TrackingEventsTypes, trackingManager } from 'shared/tracking'

import { useUpdateAuditReportDownloadingState } from './audit-report-downloading-state.query'
import { auditReportAPI } from '../../../entities/individual-audit-report-f2/api/audit-report.api'
import { INDIVIDUAL_AUDIT_REPORT_F2_KEY } from '../individual-audit-report-f2.key'
import { individualAuditReportF2En } from '../locale/individual-audit-report-f2.en'

type AuditReportFilterTypes = {
  type: AuditReportSourceOptionsTypes
}

type MutateArg = {
  /**
   * entityId can be used to override the applicantId
   */
  entityId?: string
  filters?: AuditReportFilterTypes
  mock?: boolean
}

export type RequestedReportData = {
  applicantId?: string
}

export const DownloadAuditReportQueryKey = 'download-audit-report-f2'

export const useDownloadAuditReportQuery = ({ entityId, mock }: MutateArg) => {
  const t = useI18n([INDIVIDUAL_AUDIT_REPORT_F2_KEY], {
    keys: individualAuditReportF2En,
  })

  const updateLoadingState = useUpdateAuditReportDownloadingState()
  const mutation = useMutation({
    mutationFn: async () => {
      updateLoadingState(true)

      const { data } = await auditReportAPI.downloadAuditReport(entityId!, mock)
      return data
    },
    onError: (error: ErrorResponse) => {
      updateLoadingState(false)

      trackingManager.track(
        TrackingEventsTypes.IndividualAuditReportGenerateError,
      )
      notification.error(error.response?.data.message)
    },
    onSuccess: reportData => {
      updateLoadingState(false)

      downloadBlobAsFile(reportData, {
        fileName: 'AuditReport.pdf',
        type: 'application/pdf',
      })
      trackingManager.track(TrackingEventsTypes.IndividualAuditReportGenerate)
      notification.success(t('message.success'))
    },
  })

  const downloadReport = useCallback(() => {
    mutation.mutate()
  }, [mutation])

  return {
    ...mutation,
    downloadReport,
  }
}
