import { I18nKeys } from 'shared/i18n'

export const checkEn = {
  checks: {
    description: {
      notRequired: 'Check is not required',
      notCompleted: 'Check has not been completed',
      amlMatchedFound: 'Potential PEP, sanctions or adverse media match',
      amlMatchesManuallyResolved: 'AML matches have been manually resolved',
      noAmlMatched: 'PEP, sanctions or adverse media match has been verified',
      transliteratedNameMatch:
        'Transliterated name has been matched with name input by customer',
      transliteratedNameNotMatch:
        'Transliterated name has not matched with name input by customer',
      identityVerified: 'Identity has been verified',
      noBlocklistMatches: 'No blocklist matches found',
      noDuplicateMatches: 'No duplicate matches found',
      truePositiveBlocklistMatch:
        'A true positive blocklist match has been found',
      truePositiveDuplicateMatch:
        'A true positive duplicate match has been found',
      potentialBlocklistMatch: 'A potential Blocklist match has been found',
      potentialDuplicateMatch: 'A potential duplicate match has been found',
      manuallyBlocklisted: 'This profile has been manually blocklisted',
      falsePositiveBlocklistCleared:
        'A false positive blocklist match has been cleared',
      manuallyConfirmedBlocklist: 'Manually confirmed blocklist match',
      visaVerified: 'Visa information has been verified',
      visaNotVerified:
        'Visa information could not be verified, check passport info',
      noFraudFound: 'No fraud found',
      potentialFraud: 'Potential fraud has been identified',
      issuesResolved: 'Issues have been resolved',
      noIssues: 'No issues found',
      fraudulentDocs: 'Potential issues or fraudulent document has been found',
      potentialIssues: 'Potential issues have been found',
      identityManuallyVerified: 'Identity has been manually verified',
      minDocReqNotMet: 'Minimum document requirements have not been met',
      identityNotVerifiedCheckInfo:
        'Identity could not be verified, check personal info',
      govIdVerified: 'Government ID has been verified',
      govIdNotVerified:
        'Government ID could not be verified, check ID document',
      checkSourcePass: 'Check source validation has passed',
      reqCheckSourceNotFound: 'Required check sources not found',
      checkSourceVerified: 'Check source validation has been verified',
      checksRemaining: 'Final check outcome has not been completed',
      docApprovalReq: 'Manual review and approval of documents required',
      deviceVerified: 'Device has been verified',
      highRiskDeviceFound: 'A high risk device has been found',
      lowRiskDeviceDetected:
        'A low risk device, email or phone has been detected',
      highRiskEmailAndPhoneDeviceDetected:
        'A high risk device, email or phone has been detected',
      noMLTransactionFound: 'No ML transactions found',
      potentialMLTransactionFound: 'Potential ML transactions found',
      noHighRiskCharacteristicsFound:
        'No high risk device/customer characteristics found',
      highRiskCharacteristicsFound:
        'High risk device/customer characteristics found',
      noFraudTransactionFound: 'No fraudulent transactions found',
      potentialFraudTransactionFound: 'Potential fraudulent transactions found',
      viewReportHere: 'View report here.',
      reportGenerating:
        'This report will be available to view here shortly. You can leave this tab while the report is being generated. Check back again soon and refresh the page to view.',
      ipLocationPassed: 'No geographic risk found',
      ipLocationFailed: 'A geographic risk has been detected',
      emailPassed: 'No email risk found',
      emailFailed: 'A high risk email has been detected',
      deviceFailed: 'A high risk device has been detected',
      devicePassed: 'No device risk found',
      phonePassed: 'No phone risk found',
      phoneFailed: 'A high risk phone has been detected',
      riskDescriptionMapping: {
        email: {
          low: 'A low risk email has been detected',
          medium: 'A medium risk email has been detected',
          high: 'A high risk email has been detected',
          very_high: 'A very high risk email has been detected',
          unknown: 'Check has not been completed',
        },
        device: {
          low: 'A low risk device has been detected',
          medium: 'A medium risk device has been detected',
          high: 'A high risk device has been detected',
          very_high: 'A very high risk device has been detected',
          unknown: 'Check has not been completed',
        },
        phone: {
          low: 'A low risk phone has been detected',
          medium: 'A medium risk phone has been detected',
          high: 'A high risk phone has been detected',
          very_high: 'A very high risk phone has been detected',
          unknown: 'Check has not been completed',
        },
        iplocation: {
          low: 'A low geographic risk has been detected',
          medium: 'A medium geographic risk has been detected',
          high: 'A high geographic risk has been detected',
          very_high: 'A very high geographic risk has been detected',
          unknown: 'Check has not been completed',
        },
      },
    },
    title: {
      aml: 'AML Screening',
      chineseNationalId: 'Chinese National ID Name Comparison',
      blocklist: 'Internal Blocklist',
      duplicate: 'Duplicate',
      vevo: 'VEVO',
      fraudCheck: 'Fraud Check',
      biometric: 'ID Check & Biometrics',
      manualKyc: 'Manual KYC',
      blocklistSearch: 'Blocklist Search',
      kyc: 'KYC',
      kyc_1: 'KYC 1 + 1',
      kyc_2: 'KYC 2 + 2',
      govId: 'GOV ID',
      checkSource: 'Check Source',
      docUpload: 'Document Upload',
      device: 'Device',
      phone: 'Phone',
      iplocation: 'IP Location',
      email: 'Email',
      onboardingFraud: 'Onboarding Fraud - Device',
      amlTransactionMonitoring: 'AML Transaction Monitoring',
      deviceCustomerCharacteristics: 'Device/Customer Characteristics',
      fraudTransactionMonitoring: 'Fraud Transaction Monitoring',
      kybReport: 'Single Level Business Ownership',
      uboReport: 'Ultimate Beneficial Owner',
      crReport: 'Credit Report',
    },
    action: {
      resolve: 'Resolve',
      view: 'View',
      review: 'Review',
      removeFromBlocklist: 'Remove from blocklist',
      viewReport: 'View Report',
    },
    note: {
      confirmBlocklist:
        'Note: Matches must be marked as true positive in order to confirm the blocklisting',
    },
    tooltip: {
      pleaseReviewOnboardingFraud:
        'Please review the Onboarding Fraud check in Sardine before changing the Profile Status. Changing the Profile Status will not update this alert status.',
    },
    state: {
      generatingReport: 'Generating Report',
    },
  },
}

export const watchListReasonEn = {
  watchListReason: {
    previouslyBlocklisted: 'Previously Blocklisted',
    suspectedFraud: 'Suspected Fraud',
    suspectedIdTheft: 'Suspected ID Theft',
    idTheftVictim: 'ID Theft Victim',
    suspiciousData: 'Suspicious Data',
    smrRaised: 'SMR Raised',
    _2xSMRReportsLodged: '2x SMR Reports Lodged',
  },
}

export const applicantGeneralInformationEn = {
  overview: 'Overview',
  checkSummary: 'Check Summary',
  businessInfo: 'Business Info',
  onboardingSummary: 'Onboarding Summary',
  monitoringSummary: 'Monitoring Summary',
  summaryNotFound: 'Summary Unavailable',
  recipe: 'Recipe',
  riskLevel: 'Risk Level',
  lastCheckDate: 'Last Check Date',
  issues: 'Issues',
  kycMethod: 'KYC Method',
  manual: 'Manual',
  electronic: 'Electronic',
  blocklisted: 'Blocklisted',
  legacyCustomerView: 'This is a legacy customer profile view',
  legacyCustomerViewInfo:
    'To update to the new customer profile view, select ‘Edit Customer Info’, an appropriate Recipe, and confirm with ‘Save & Verify’ to re-run checks as described <link1>here</link1>. To find out more about Recipes, click <link2>here</link2>.',
  selectReportForBusiness: 'Select a report to run on this Business',

  singleLevelBusinessReport:
    'This report will identify the directors and shareholders of a business to a single level as reported via ASIC/ABR. PEP, sanctions or adverse media check will be applied to individuals as configured in your FrankieOne account.',
  uboBusinessReport:
    'This report will identify the ultimate owner(s) of this company and help you understand what role (direct or indirect) individuals play within simple or complex corporate structures.',
  crBusinessReport:
    'Includes credit report + ASIC current extract. Add RiskScore and Payment predictor as an optional add on in the next step.',

  noRegisteredBusinessName:
    'No current registered business names, please check historical business names',
  businessEffectiveFrom: '{{businessName}} (effective from {{date}})',
  ausPblCompany: 'Australian Public Company',
  ausPvtCompany: 'Australian Private Company',
  statusEffectiveFrom: '{{status}} from {{date}}',
  viewHistoricalBusinessNames: 'View Historical Names',
  noHistoricalBusinessNames: 'No historical business names',

  businessSummary: {
    abr: 'ABR Summary',
    other: 'Other Information',
    fields: {
      name: 'Name',
      mainNames: 'Main names',
      legalNames: 'Legal names',
      tradingNames: 'Trading names',
      location: 'Location',
      type: 'Type',
      acn: 'ACN',
      abn: 'ABN',
      abnStatus: 'ABN Status',
      currentBusiness: 'Current Business Names',
      historicalBusiness: 'Historical Business Names',
      merchantCategory: 'Merchant Category',
    },
  },

  businessReport: {
    owner: 'Single Level Business Ownership',
    uboReport: 'UBO Report',
    creditReport: 'Credit Report',
  },

  businessCheck: {
    completedCheck: 'Completed Check',
    select: 'Select a check to run on this business',
    pep: {
      title: 'AML screening',
      description:
        'Run a PEP, sanctions or adverse media check on this organisation',
    },
  },

  trustAnalysisStatus: {
    completed: {
      title: 'Trust deed analysis is complete and ready for review.',
      cta: 'View trust analysis',
    },
    failed: {
      title: 'Trust analysis failed',
      description:
        "This might happen because the document isn't on the list of supported types, or there was an issue encountered by the Trust Analyser. Please ensure that your PDF document is a trust deed and it is a supported type.",
      cta: 'View trust deed',
    },
    unknown: {
      title: 'Trust type could not be detected',
      description:
        "This might happen because the trust type isn't on the list of supported types, or there was an issue encountered by the Trust Analyser. If the trust deed belongs to one of the supported types, please select that trust type and try again.",
      cta: 'View trust deed',
    },
  },
  error: {
    fetchingTransaction: 'Error fetching transaction monitoring results.',
    profileStatusChange: 'Error Occurred while changing status.',
    addToBLocklist: 'Error Occurred while adding to blocklist.',
    generateIndividualReport:
      'We were unable to generate the report at this time. Please try again, or contact Support for further assistance.',
    generateBusinessReport:
      'There was an error generating the report. Please try again',
    selectReport: 'Please select a report first.',
  },

  success: {
    profileStatusChange: 'Status has been updated to {{status}}.',
    blacklistStatusChange: 'Profile has been {{flag}} the {{flagType}}.',
    generateReport:
      'This report will be accessible at any time under Individual Verification Reports on the Personal Info page.',
    reportWillBeGeneratedShortly:
      'The {{reportName}} Report will be available here shortly. Check back again soon or refresh the page to view.',
  },

  status: {
    profileDeactivated: 'This profile has been deactivated',
    profileArchived: 'This profile has been archived',
    profileBlockListed: 'This profile has been blacklisted',
    truePositiveBlocklistMatch:
      'This customer has been confirmed as a true positive blocklist match',
    truePositiveDuplicateMatch:
      'This customer has been confirmed as a true positive duplicate match',
    internalWatchlist: 'This profile is on the internal watchlist',
    noChecksRun: 'No checks have been run',
    reportGeneratedSuccess:
      ' Individual Verification Report for {{name}} successfully generated',
  },

  action: {
    editCustomerInfo: 'Edit Customer Info',
    editBlocklistInfo: 'Edit Blocklist Info',
    removeFromBlocklist: 'Remove from Blocklist',
    removeFromWatchList: 'Remove from Watchlist',
    addToBlocklist: 'Add to Blocklist',
    addToWatchList: 'Add to Watchlist',
    changeStatus: 'Change Status',
    archiveProfile: 'Archive Profile',
    archiveBlocklist: 'Archive Blocklist',
    unarchiveProfile: 'Unarchive Profile',
    generateReport: 'Generate Report',
    generateIndividualVerificationReport:
      'Generate Individual Verification Report',
    generateNewIndividualVerificationReport:
      'Generate a new Individual Verification Report',
    deactivateProfile: 'Deactivate this profile',
    viewAndEdit: 'View And Edit',
    deactivate: 'Deactivate Profile',

    heading: {
      confirm: 'Confirmation',
      changeStatus: 'Change Profile Status',
      blocklist: 'Blocklist this profile',
      watchlist: 'Watchlist this profile',
      archive: 'Archive this profile',
      unarchive: 'Unarchive this Profile',
      changeBlocklistReason: 'Change Blocklist Reason',
      generateUboReport: 'Generate UBO Report',
      singleBusinessReport: 'Single level business ownership report',
    },

    description: {
      changeStatus:
        'This will change the status for this profile, all checks should be completed before proceeding.',
      confirmChangeStatus: 'Are you sure you want to change profile status?',
      profileWillBeAddedToBlocklist:
        'This profile will be added to your internal blocklist.',
      willNotBeAbleToOnboard:
        'They will not be able to onboard and any future attempts to onboard with similar information will be flagged as a potential blocklist match.',
      canRemoveFromBlocklistNote:
        'User’s with appropriate permissions will be able to remove this profile from the blocklist',
      confirmAddToBlocklist: 'Are you sure you want to blocklist this profile?',
      removeFromBlocklist:
        'This will remove this profile from your internal blocklist.',
      noLongerBeBlocklistFlagged:
        'Any future attempts to onboard with similar personal information will no longer be flagged as a potential blocklist match.',
      areYouSureToRemoveBlocklist:
        'Are you sure you want to remove this person from your internal blocklist?',

      profileWillBeAddedToWatchlist:
        'This profile will be added to your internal watchlist.',
      willNeedFurtherCheck:
        'A new risk factor has been added to the entity and further checks may need to be completed in order for this applicant to progress.',
      canRemoveFromWatchlist:
        'Only users with appropriate permissions will be able to remove this profile from the watchlist.',
      confirmAddToWatchlist: 'Are you sure you want to watchlist this profile?',
      removeFromWatchlist:
        'This will remove this profile from your internal watchlist.',
      removeAdditionalRiskFactor:
        'This will remove the additional risk factor that has been applied.',
      areYouSureToRemoveWatchlist:
        'Are you sure you want to remove this person from your internal watchlist?',

      archiveTheProfile: 'This will change the profile status to ‘archived’',
      canStillBeFoundInSearch:
        'The profile can still be found in searches, and may be reactivated at any time by updating details in Manual IDV and re-running checks.',
      areYouSureToArchive:
        'You’re about to archive this person. Are you sure you would like to proceed with this action?',

      unarchiveTheProfile:
        'This will re-run checks associated with this profile (excluding any business ownership reports)',
      statusWillBeUpdated:
        'The profile status will be updated according to the new check results..',
      areYouSureToUnarchive:
        'You’re about to unarchive this person. Are you sure you would like to proceed with this action?',

      containsSummary:
        'This report contains a summary of the checks completed and current status of the Individual.',
      reflectionUptoThisPoint:
        'The following report will be a reflection of this profile at this point in time. If the profile is updated a new report will need to be manually run.',
      pleaseWaitForReport: 'Please wait, we are generating your report',
      closeAlternatively:
        'Alternatively, you may close this modal at any time and we will notify you when your report is ready.',

      cannotBeReactivated:
        'This profile will become inactive and can not be reactivated at any time.\n It will still be visible for auditing and reporting purposes.',
      cannotBeUndone: 'This action cannot be undone.',
      aboutToDeactivate:
        'You’re about to deactivate this person. Are you sure you would like to proceed with this action?',

      reportAvailable:
        'This report is now available under Individual Verification Reports on the Personal Info tab.',

      includeCreditReport: 'Includes credit report + ASIC current extract.',
      uboReportInformation:
        'This report contains all of the information contained in a Single Level Business Ownership report, and in addition will identify the ultimate owner(s) of this company and help you understand what role (direct or indirect) individuals play within simple or complex corporate structures.',
      pepInformation:
        'PEP, sanctions or adverse media check will be applied to key individuals as configured in your FrankieOne account.',
      identifyShareholder:
        'This report will identify the shareholders and directors of a business to a single level via the current ASIC extract and/or ABR extract.',
    },

    fields: {
      comment: 'Comment',
      commentPlaceholder: 'Type your comment here',
      selectProfileStatus: 'Select Profile Status',
      manuallyPassed: 'Manually Passed',
      manuallyFailed: 'Manually Failed',
      reasonForBlocklist: 'Reason for blocklist',
      reasonForWatchlist: 'Reason for watchlist',
      addRiskScore: 'Add RiskScore and Payment predictor',
      selectReport: 'Select a Report to Generate',
    },

    confirm: {
      changeStatus: 'Yes, change status',
      addToBlocklist: 'Yes, blocklist this profile',
      removeFromBlocklist: 'Yes, remove from blocklist',
      addToWatchlist: 'Yes, add to watchlist',
      removeFromWatchlist: 'Yes, remove from watchlist',
      archive: 'Archive',
      inactive: 'Inactive',
      unarchive: 'Unarchive',
      openReport: 'Open Report',
      deactivate: 'Deactivate',
    },

    status: {
      internalWatchlist: 'Internal Watchlist',
      internalBlocklist: 'Internal Blocklist',
      added: 'added to',
      removed: 'removed from',
    },

    cancel: 'Cancel',
    retry: 'Retry',
    close: 'Close',
    save: 'Save',
    runCheck: 'Run Check',
  },

  ...checkEn,
  ...watchListReasonEn,
}

export type ApplicantGeneralInformationKeys = I18nKeys<
  typeof applicantGeneralInformationEn
>
