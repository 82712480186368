import React from 'react'

import { IFrankieBarChartProps } from './bar-chart.types'

/**
 * Bar chart component.
 *
 * @component
 */
export function FrankieBarChart({
  className = '',
  data,
  testId,
}: IFrankieBarChartProps) {
  const total = data.reduce((acc, item) => acc + item.value, 0)

  return (
    <div className={`min-w-[480px] ${className}`}>
      <div className="relative text-lg" data-qa={testId?.barContainer}>
        {data.map(item => (
          <div key={item.label} className="flex items-center w-[100%] gap-2">
            <div
              className="flex flex-grow-0 min-w-[240px]"
              data-qa={item.testId?.input}
            >
              <div className="flex-grow">{item.label}</div>
              <div>{item.value}</div>
            </div>
            <div className="w-full">
              <div
                data-qa={item.testId?.bar}
                className="h-[12px] bg-primary-500"
                style={{
                  width: `${total ? (item.value / total) * 100 : 0}%`,
                }}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
