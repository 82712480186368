import { useQuery } from '@tanstack/react-query'

import { useHasFeatureFlag } from 'entities/session'

import { organisationSettingsApi } from '../../api/organisation-settings.api'

export const IntegrationSettingQueryKey = ['integration-setting']

export const useIntegrationQuery = () => {
  const { isSlackEnabled } = useHasFeatureFlag({
    isSlackEnabled: 'slackNotifications',
  })

  return useQuery({
    enabled: isSlackEnabled,
    queryKey: IntegrationSettingQueryKey,
    queryFn: async () => {
      const response = await organisationSettingsApi.getIntegrationSetting()
      return response.data
    },
  })
}
