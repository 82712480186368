import React, { Fragment, useMemo } from 'react'

import { ApplicantId, ApplicantIssueActionBadge } from 'entities/applicant'

import { useI18n } from 'shared/i18n'
import { capitalize } from 'shared/string'
import { DefaultDataGrid } from 'shared/tables-common'

import { IKYB_AML_SCREENING_KEY } from '../../../../ikyb-aml-screening.key'
import { iKybAmlScreeningEn } from '../../../../locale/ikyb-aml-screening.en'
import { pepSactionsListing } from '../../../../model/ikyb-aml-screening.model'
import { iKybAmlKeyDataQa } from '../../../../qa/ikyb-aml-screening.qa'
import { useIKybAmlScreeningQuery } from '../../../../state/ikyb-aml-screening-query/ikyb-aml-screening.query'

type DataSummaryViewProps = {
  dataItems: {
    key: string
    item: string
  }[]
}

export function DataSummaryView({ dataItems }: DataSummaryViewProps) {
  const t = useI18n(IKYB_AML_SCREENING_KEY, {
    keys: iKybAmlScreeningEn,
  })

  // From vue code
  const urlIf = (value?: string) => {
    value?.trim()
    return value?.slice(0, 4) === 'http' || value?.slice(0, 5) === 'https'
  }

  if (!dataItems.length) return <div>{t('empty.data')}</div>

  return (
    <>
      {dataItems.map(({ item, key }) => (
        <div key={key} className="flex min-h-[29px] py-1">
          <div className="min-w-[200px]">{key}</div>
          {urlIf(item) ? (
            <a
              href={item}
              className="text-primary-800 underline"
              target="_blank"
              rel="noreferrer"
            >
              {item}
            </a>
          ) : (
            <div>{item}</div>
          )}
        </div>
      ))}
    </>
  )
}

type KeyData = {
  factor: string
  info: string | string[]
}

type Props = {
  applicantId: ApplicantId
  matchIdx: number
  testId?: { container?: string }
}

export function IKybAmlKeyData({ applicantId, matchIdx, testId }: Props) {
  const t = useI18n(IKYB_AML_SCREENING_KEY, {
    keys: iKybAmlScreeningEn,
  })

  const { data } = useIKybAmlScreeningQuery({ applicantId })

  // eslint-disable-next-line complexity
  const { entityFields, entityImage, matchEntityDataSummary } = useMemo(() => {
    const selectedMatch = data?.matches[matchIdx]
    const matchEntityData = data?.matches[matchIdx].details.entityData

    const addresses = matchEntityData?.addresses
    const countries = matchEntityData?.countries
    const associates = matchEntityData?.associates

    const fields: KeyData[] = [
      { factor: t('field.name'), info: matchEntityData?.name ?? '-' },
      {
        factor: t('field.countries'),
        info: countries?.length ? countries.join(', ') : '-',
      },
      {
        factor: t('field.addresses'),
        info: addresses?.length ? addresses : '-',
      },

      {
        factor: t('field.associates'),
        info: associates?.length
          ? associates.map(
              associate =>
                `${capitalize(associate.relation)}: ${associate.name}`,
            )
          : '-',
      },
    ]

    return {
      matchEntityDataSummary: selectedMatch?.details,
      entityFields: fields,
      entityImage: matchEntityData?.imageURL,
    }
  }, [data?.matches, matchIdx, t])

  const pepSactionsListingResult = matchEntityDataSummary
    ? pepSactionsListing.filter(
        ({ field }) => !!matchEntityDataSummary[field].length,
      )
    : []

  return (
    <div data-qa={testId?.container}>
      <div className="flex gap-2 py-8 px-2">
        <div className="basis-2/3 flex-grow">
          <DefaultDataGrid<KeyData>
            tableMinWidth={400}
            getRowId={row => row.factor}
            columns={[
              {
                field: 'factor',
                headerName: t('keyData.factor'),
                maxWidth: 200,
              },
              {
                field: 'info',
                headerName: t('keyData.info'),
                renderCell: ({ value }) => (
                  <div>
                    {Array.isArray(value)
                      ? value.map(item => <div>{item}</div>)
                      : value}
                  </div>
                ),
              },
            ]}
            rows={entityFields}
          />
        </div>

        {entityImage && (
          <div className="basis-1/3 p-2 h-[240px]">
            <img className="" src={entityImage} alt="entity" />
          </div>
        )}
      </div>
      {matchEntityDataSummary && (
        <div className="px-2 text-tertiary-grey-700">
          {!!pepSactionsListingResult.length && (
            <div className="text-xl font-bold">{t('listing')}</div>
          )}
          {pepSactionsListingResult.map(({ type, field, tKey }) => (
            <div
              key={type}
              data-qa={iKybAmlKeyDataQa.listing}
              className="p-4 text-secondary-900"
            >
              <ApplicantIssueActionBadge
                type={type}
                prioritiesLongLabel
                className="!px-2 !py-1 !rounded-[6px]"
              />

              <div className="text-tertiary-grey-500 pt-2">
                {t('currentlyOn')}
              </div>

              {matchEntityDataSummary[field].length ? (
                matchEntityDataSummary[field].map(({ name, items }) => (
                  <div key={name} className="mb-1">
                    <div className="font-semibold py-2">{name}</div>
                    <DataSummaryView
                      dataItems={items.filter(
                        ({ key }) => !key.includes('status.'),
                      )}
                    />
                  </div>
                ))
              ) : (
                <div>{t('empty.data')}</div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
