import React from 'react'

import { Controller, ControllerProps, FieldValues } from 'react-hook-form'

import { FrankieTextarea, IFrankieTextareaProps } from 'frankify/src'

import { Nullable } from 'shared/typescript'

type Props<TFormValues extends FieldValues> = {
  className?: string
  label?: string
  showError?: boolean
  overrideValue?: Nullable<string>
  disabled?: boolean
  trim?: boolean
} & Omit<IFrankieTextareaProps, 'value' | 'onChange'> &
  Omit<ControllerProps<TFormValues>, 'render'>

export function TextAreaFormField<TFormValues extends FieldValues>({
  name,
  control,
  defaultValue,
  rules,
  shouldUnregister,
  label,
  className = '',
  inputClassName = '',
  showError = false,
  disabled = false,
  trim = false,
  overrideValue,
  ...textAreaProps
}: Props<TFormValues>) {
  return (
    <Controller
      render={({ field, fieldState: { error } }) => (
        <div className={className}>
          {label && (
            <div className="pb-1 text-tertiary-grey-800 text-sm font-medium">
              {label}
            </div>
          )}
          <FrankieTextarea
            inputClassName={`w-full  min-h-[100px] max-h-[250px] px-4 py-2 rounded-sm border border-tertiary-grey-300 border-solid   ${inputClassName} ${
              showError && error && !disabled
                ? 'border-tertiary-red-700'
                : 'focus-visible:border-primary'
            } ${disabled ? '!bg-tertiary-grey-50' : ''}`}
            errorText={showError ? error?.message : undefined}
            {...textAreaProps}
            {...field}
            onBlur={e => {
              if (trim) {
                field.onChange(e.target.value.trim())
              }
              field.onBlur()
            }}
            error={showError ? !!error : undefined}
            value={overrideValue ?? field.value}
            disabled={disabled}
          />
        </div>
      )}
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={rules}
      shouldUnregister={shouldUnregister}
    />
  )
}
