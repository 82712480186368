import { useMutation, useQueryClient } from '@tanstack/react-query'

import { SessionQueryKey } from 'entities/session'
import type { PageData } from 'entities/session'

import { bffClient } from 'shared/client'
import { getLocalStorageState, StorageKeyTypes } from 'shared/local-storage'

type MfaDisableResponse = MfaDisableSuccessResponse | MfaDisableErrorResponse

type MfaDisableSuccessResponse = {
  message: string
}

type MfaDisableErrorResponse = {
  error: 'Unknown user'
}

export const useDisableMfaMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: () =>
      bffClient.post<MfaDisableResponse>('/mfa/:version/service/disable'),
    onSuccess: () => {
      const [pageData, setPageData] = getLocalStorageState(
        StorageKeyTypes.PageData,
      )

      const pageDataTyped = pageData as PageData

      pageDataTyped.user.mfaType = null

      queryClient.setQueryData(SessionQueryKey, pageDataTyped)

      setPageData(pageData)
    },
  })
}
