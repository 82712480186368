import React, { useEffect, useMemo } from 'react'

import { FrankieTextField } from 'frankify/src'

import { INDIVIDUAL_PROFILE_KEY } from 'features/individual-profile/individual-profile.keys'
import { individualProfileEn } from 'features/individual-profile/locale/new-profile.en'
import { IndividualProfileInputTypes } from 'features/individual-profile/model/form.model'

import { getError } from 'shared/form'
import { useI18n } from 'shared/i18n'

import { individualProfileVueMigratedQa } from '../../../../qa/individual-profile.qa'
import { DocumentCategory } from '../document-category/document-category'
import { DocumentsSubFormProps } from '../document-sub-forms.types'

export function TaxIdForm({
  form,
  idx,
  wrapperClasses,
}: DocumentsSubFormProps) {
  const {
    register,
    formState: { errors },
    unregister,
  } = form
  const t = useI18n([INDIVIDUAL_PROFILE_KEY], { keys: individualProfileEn })

  const idSubTypeRegister = useMemo(
    () =>
      register(
        `${IndividualProfileInputTypes.Document}.${idx}.${IndividualProfileInputTypes.TaxId}.${IndividualProfileInputTypes.IdSubType}`,
        {
          required: true,
        },
      ),
    [idx, register],
  )
  const idNumberFieldName = useMemo(
    () =>
      `${IndividualProfileInputTypes.Document}.${idx}.${IndividualProfileInputTypes.IdNumber}` as IndividualProfileInputTypes.Document,
    [idx],
  )

  // unregister id number because used in multiple components
  useEffect(
    () => () => {
      unregister(idNumberFieldName)
    },
    [idx, unregister, idNumberFieldName],
  )

  return (
    <div className={wrapperClasses}>
      <FrankieTextField
        className="basis-[32%]"
        label={t('documentForm.idNumber')}
        placeholder={t('documentForm.idNumber')}
        error={!!getError(idNumberFieldName, errors)}
        {...register(idNumberFieldName, {
          required: true,
        })}
        testId={{
          input: individualProfileVueMigratedQa.document.idNumberField,
        }}
      />
      <FrankieTextField
        className="basis-[32%]"
        label={t('documentForm.idSubType')}
        placeholder={t('documentForm.idSubType')}
        error={!!getError(idSubTypeRegister.name, errors)}
        testId={{
          input: individualProfileVueMigratedQa.document.idNumberField,
        }}
        {...idSubTypeRegister}
      />
      <div className="basis-[64%]">
        <DocumentCategory className="w-[50%]" form={form} idx={idx} />
      </div>
    </div>
  )
}
