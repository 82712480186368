import React, { useMemo } from 'react'

import { FrankieIcon } from 'frankify/src'

import { ApplicantId } from 'entities/applicant'

import { useI18n } from 'shared/i18n'

import { APPLICANT_PEP_SACTIONS_KEY } from '../../../../applicant-pep-sactions.key'
import { applicantPepSactionsEn } from '../../../../locale/applicant-pep-sactions.en'
import { pepSactionsDateFormat } from '../../../../model/applicant-pep-sactions.model'
import { applicantPepSanctionAdverseMediaQa } from '../../../../qa/applicant-pep-sactions.qa'
import { useApplicantPepSactionsQuery } from '../../../../state/applicant-pep-sactions-query/applicant-pep-sactions.query'

type Props = {
  applicantId: ApplicantId
  testId?: { container?: string }
  matchIdx: number
}

export function ApplicantPepSactionsAdverseMedia({
  applicantId,
  matchIdx,
  testId,
}: Props) {
  const t = useI18n(APPLICANT_PEP_SACTIONS_KEY, {
    keys: applicantPepSactionsEn,
  })

  const { data } = useApplicantPepSactionsQuery({ applicantId })

  const { mediaArticles, mediaMatches } = useMemo(
    () => ({
      mediaMatches: data?.matches[matchIdx].details.media.mediaMatches ?? [],
      mediaArticles: data?.matches[matchIdx].details.media.mediaArticles ?? [],
    }),
    [data?.matches, matchIdx],
  )

  if (!mediaMatches.length) {
    return (
      <div data-qa={testId?.container} className="p-3 text-secondary-900">
        {t('empty.adverseMedia')}
      </div>
    )
  }

  if (!mediaArticles.length) {
    return (
      <div data-qa={testId?.container} className="p-3 text-secondary-900">
        {t('empty.articles')}
      </div>
    )
  }

  return (
    <div data-qa={testId?.container} className="flex flex-col">
      {mediaArticles.map(articles =>
        articles.map(({ date, title, url, snippet }) => (
          <div
            data-qa={applicantPepSanctionAdverseMediaQa.mediaArticle}
            className="flex gap-4"
            key={title}
          >
            <FrankieIcon
              name="mdiCardAccountDetails"
              size="lg"
              className="text-tertiary-grey-400 pt-4"
            />
            <div className="flex flex-col gap-1 py-4 border-b border-tertiary-grey-200">
              <div className="text-tertiary-grey-500 font-medium">
                {`${t('published')} ${pepSactionsDateFormat(date)}`}
              </div>
              <a
                className="text-primary-500"
                href={url}
                target="_blank"
                rel="noreferrer"
              >
                {title}
              </a>
              <div className="text-tertiary-grey-900">{snippet}</div>
            </div>
          </div>
        )),
      )}
    </div>
  )
}
