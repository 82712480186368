import React, { useMemo } from 'react'

import { FrankieIcon } from 'frankify/src'

import {
  BLOCKLIST_TABLE_KEY,
  blocklistReasonData,
  blocklistTableEn,
} from 'features/blocklist-table'

import { ApplicantResponse } from 'entities/applicant'

import { useI18n } from 'shared/i18n'

import { APPLICANT_POTENTIAL_MATCHES_KEYS } from '../../applicant-potential-matches.keys'
import { applicantPotentialMatchesEn } from '../../locale/applicant-potential-matches.en'
import {
  ApplicantMatchesTypes,
  MatchesTypes,
  potentialMatchFieldLabel,
} from '../../models/applicant-matches.models'
import { convertBlocklistAttrToReadableString } from '../../models/blacklist.model'
import { appPotentialMatchesQa } from '../../qa/applicant-potential-matches.qa'

type Props = {
  matchedView?: boolean
  type: MatchesTypes
  applicantData: ApplicantResponse | undefined
}

type HeaderProps = {
  applicantData: ApplicantResponse | undefined
  heading: string
}

function Header({ applicantData, heading }: HeaderProps) {
  const tBlocklist = useI18n(BLOCKLIST_TABLE_KEY, {
    keys: blocklistTableEn,
  })
  return (
    <div
      className="h-[48px] flex items-center  justify-start p-3 bg-neutral-100 rounded-t-lg text-mono-white pr-[90px]"
      data-qa={appPotentialMatchesQa.wrapper}
    >
      <FrankieIcon name="mdiCancel" className="font-light mx-2" />
      <div className="font-bold uppercase">{heading}</div>
      {applicantData?.checkSummary.issues.MANUAL_BLOCKLIST && (
        <div className="mx-2 px-2 py-0 rounded-sm bg-tertiary-grey-500 text-mono-white font-bold whitespace-nowrap">
          {tBlocklist(
            blocklistReasonData[
              applicantData.checkSummary.issues.MANUAL_BLOCKLIST
            ].tKey,
          )}
        </div>
      )}
    </div>
  )
}

// eslint-disable-next-line complexity
export function ApplicantHeader({ matchedView, type, applicantData }: Props) {
  const t = useI18n(APPLICANT_POTENTIAL_MATCHES_KEYS, {
    keys: applicantPotentialMatchesEn,
  })

  const { heading } = useMemo(() => {
    const data = {
      heading: `${t('blocklisted')} ${convertBlocklistAttrToReadableString(
        applicantData?.checkSummary.issues.BLOCKLIST_ATTRIBUTE ||
          'ENTIRE_ENTITY', // default value could be empty string - need to validate
      )}`,
    }
    if (type === ApplicantMatchesTypes.DUPLICATES) {
      data.heading = t('duplicateProfile')
    }

    if (type === ApplicantMatchesTypes.POTENTIAL) {
      data.heading = t('potentialMatches')
    }

    return data
  }, [applicantData?.checkSummary.issues.BLOCKLIST_ATTRIBUTE, t, type])

  const potentialMatchField = useMemo(() => {
    if (!applicantData?.checkSummary.issues.BLOCKLIST_ATTRIBUTE) return ''
    const blocklistedAttribute =
      applicantData.checkSummary.issues.BLOCKLIST_ATTRIBUTE

    return potentialMatchFieldLabel(blocklistedAttribute, t)
  }, [applicantData, t])

  return (
    <div data-qa={appPotentialMatchesQa.headerWrapper}>
      {!matchedView && type !== ApplicantMatchesTypes.POTENTIAL && (
        <div className="text-tertiary-grey-900 flex items-center justify-start p-3 bg-neutral-20 rounded-t-lg">
          <FrankieIcon name="mdiAccountOutline" className="font-light mx-2" />
          <div className="font-bold">{t('incomingApplicant')}</div>
        </div>
      )}

      {matchedView && type === ApplicantMatchesTypes.POTENTIAL && (
        <div className="text-tertiary-grey-700 flex items-center justify-start p-3 rounded-t-lg">
          <FrankieIcon name="mdiAccountOutline" className="font-light mx-2" />
          <div className="font-bold">{t('existingProfile')}</div>
        </div>
      )}

      {!matchedView && type === ApplicantMatchesTypes.POTENTIAL && (
        <Header
          heading={`${t('blocklisted')}  ${potentialMatchField.toUpperCase()}`}
          applicantData={applicantData}
        />
      )}

      {matchedView && type !== ApplicantMatchesTypes.POTENTIAL && (
        <Header heading={heading} applicantData={applicantData} />
      )}
    </div>
  )
}
