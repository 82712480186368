import { cva } from 'cva'

export const classesTypeDictionary = {
  standardSuccess: '!bg-tertiary-green-50',
  standardError: '!bg-tertiary-red-50',
  standardWarning: '!bg-tertiary-yellow-50',
  standardInfo: '!bg-primary-50',
}

export const AlertTitleStyle = cva(
  ['text-md', 'font-semibold', 'leading-6', 'mb-0'],
  {
    variants: {
      type: {
        success: ['text-tertiary-green-500'],
        error: ['text-tertiary-red-700'],
        warning: ['text-tertiary-yellow-700'],
        info: ['text-primary-700'],
      },
    },
  },
)
