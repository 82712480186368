export const organisationSearchEn = {
  title: 'Organisation search',
  organisationSelector: {
    placeholder: 'Search organisation name or registration number',
    placeholderAustralia: 'Search organisation name, ABN or ACN',
  },
  loadingHeading: 'Searching for "{{organizationName}}" in {{countryName}}.',
  loadingHeadingABR: 'Searching for "{{organizationName}}"',
  loadingSubheading:
    'Hold tight, in some jurisdictions it can take up to 2 mins to show results.',
  noMatchesHeading: 'No matches found',
  searchResultHeading: 'Your search "{{search}}" did not return any matches.',
  checkTypos:
    'Check for any typos or try again with additional or exact terms.',
  learnMore: 'Learn more',
  learnMoreSubtitle: 'about our organisation search.',
  giveFeedback: 'Give feedback',
  searchResult: 'Search results for "{{organizationName}}" in {{countryName}}',
  resultsNumber: '{{resultsNumber}} results',
  resultsTable: {
    nameColumnTitle: 'NAME',
    registrationCodeColumnTitle: 'REGISTRATION CODE',
    abnAbcColumnTitle: 'ABN / ACN',
  },
  backToResults: 'Back to organisation search results',
  info: {
    title: 'Organisation information',
    mainName: 'Name',
    location: 'Location',
    registrationNumber: 'Registration Number',
    abn: 'ABN',
    acn: 'ACN',
    type: 'Type',
    abnStatus: 'ABN status',
    businessNames: 'Current business names',
    historicalBusinessNames: 'Historical business names',
    registrationAddress: 'Registration Address',
    country: 'Country',
    status: 'Status',
    registrationDate: 'Registration date',
    registrationAuthority: 'Registration Authority',
    values: {
      abnStatus: '{{status}} from {{from}}',
      businessName: '{{name}} (effective from {{from}})',
      historicalBusinessName: '{{name}} (effective from {{from}} to {{to}})',
      noHistoricalBusinessNames: 'No historical business names',
      noCurrentBusinessNames:
        'No current registered business names, please check historical business names',
    },
  },
  tooManySearches: {
    title: 'Search stopped',
    subtitle:
      'Your search "{{search}}" was too broad and was stopped. Please try again with additional terms to narrow the results.',
  },
  modalButtons: {
    generateReport: 'Generate Report',
    cancel: 'Cancel',
  },
  singleReportModal: {
    title: 'Single level business ownership report',
    description:
      'This report will identify the shareholders and directors of a business to a single level via the current ASIC extract and/or ABR extract.',
  },
  uboReportModal: {
    title: 'Generate UBO Report',
    subtitle:
      'This report contains all of the information contained in a single level business ownership report, and in addition will identify the ultimate owner(s) of this company and help you understand what role (direct or indirect) individuals play within simple or complex corporate structures.',
    description:
      'AML checks will be applied to key individual(s) as configured in your FrankieOne account.',
  },
  creditReport: {
    title: 'Credit Report',
    description: 'Includes credit report and ASIC current extract.',
    riskScoreCheckbox: 'Add risk score and payment predictor',
  },
  rowResultGutter: {
    title: 'Organisation not listed in the search result?',
  },
  manually: 'manually',
  manuallyUppercase: 'Manually',
  leanMoreLowercase: 'learn more',
  manuallyAdd: {
    title: 'Organisation not listed in the search result? Manually ',
    addAnOrganisation: 'add an organisation',
    or: 'or',
    learnMore: 'Learn more',
    about: 'about our organisation search',
    header: 'Manually add organisation',
    organisationNameLabel: 'Full organisation name',
    abnOrAcnLabel: 'ABN or ACN (optional)',
  },
  errorCreatingProfileROW: 'There was an issue. Please, try again',
  errorCreatingProfileAUS: 'There was an issue. Please, try again',
  abrOnlySearchResult: 'Search results for "{{organizationName}}',
}
