import { useCallback } from 'react'

import { useQueryClient } from '@tanstack/react-query'

import { ApplicantDataQueryKey, ApplicantResponse } from 'entities/applicant'

import { ApplicantBiometricResponse } from '../../model/applicant-biometrics-ocr.model'
import { ApplicantBiometricsOcrQueryKey } from '../applicant-biometrics-ocr-query/applicant-biometrics-ocr.query'

type UseApplicantBiometricStatusesArgs = {
  applicantId: string
}

export const useApplicantBiometricStatuses = (
  prop: UseApplicantBiometricStatusesArgs,
) => {
  const { applicantId } = prop
  const queryClient = useQueryClient()

  const biometry = queryClient.getQueryData<ApplicantBiometricResponse>([
    ApplicantBiometricsOcrQueryKey,
    applicantId,
  ])

  const applicant = queryClient.getQueryData<ApplicantResponse>(
    [ApplicantDataQueryKey, applicantId],
    {},
  )

  return useCallback(
    (index: number) => {
      const { documents = [] } = applicant ?? {}
      const biometryReportData = biometry?.biometry[index]

      biometry?.documentDetails.sort((a, b) =>
        b.checkDate.localeCompare(a.checkDate),
      )

      const doc = biometry?.documentDetails.find(documentDetail => {
        const docs = documents.find(
          document =>
            documentDetail.documentId === document.documentId &&
            document.idType === biometryReportData?.referenceDocType,
        )

        return docs
      })

      if (biometryReportData) {
        biometryReportData.items.sort((a, b) => a.title.localeCompare(b.title))
      }

      return {
        ocr: doc?.result ?? '',
        biometry: biometryReportData?.result ?? '',
        document: doc?.ocrResult.toLowerCase() ?? '',
      }
    },
    [applicant, biometry?.biometry, biometry?.documentDetails],
  )
}
