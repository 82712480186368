import type { RoleName } from 'entities/role'
import type { PageData } from 'entities/session'

import { Nullable } from 'shared/typescript'

import type { CustomerChildId, OrganisationId } from '../../organisation'

export type UserId = string

export interface IUser {
  id: UserId
  organizationId: OrganisationId
  activeChildCustomerId: Nullable<CustomerChildId>
  email?: string
  realname?: string
  username?: string
  mfaType: Nullable<'EMAIL' | 'APP'>
  locked?: boolean
  roles?: RoleName[]
  createdAt?: {
    date: string
  }
  loggedAt?: {
    date: string
  }
  actions?: string[] // List of permission
}

export const getUserList = (pageDta?: PageData): IUser[] =>
  pageDta?.organisation.users ?? []

export const NotificationSettingsConfigKey = [
  'notifications-management-settings',
]
