import React, { ReactNode } from 'react'

type Props = {
  showSkeleton: boolean
  children: ReactNode
  className?: string
  dummyText?: string
}

export function TextView({
  showSkeleton,
  children,
  className = '',
  dummyText = 'Dummy Text',
}: Props) {
  if (showSkeleton) {
    return (
      <div
        className={`animate-pulse rounded-xs !bg-tertiary-grey-300 !max-w-max !text-tertiary-grey-300 ${className}`}
      >
        {dummyText}
      </div>
    )
  }

  return <div className={className}>{children}</div>
}
