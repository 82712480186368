import type { ITracker } from 'shared/tracking'
import { TrackerNameTypes, trackingManager } from 'shared/tracking'

interface IHotjar {
  (type: string, body: string): void
}

class HotjarTrackerService implements ITracker {
  hj: IHotjar

  name = TrackerNameTypes.HOTJAR

  constructor(hj: IHotjar) {
    this.hj = hj
  }

  track(event: string) {
    this.hj('event', event)
  }
}

type HotjarInitData = {
  customerId: string
  organizationId: number
  user_id: string
  role: string
}

export function hotjarInit({
  customerId,
  organizationId,
  role,
  user_id,
}: HotjarInitData) {
  if ('hj' in window && typeof window.hj === 'function') {
    window.hj('identify', user_id, {
      organizationId,
      customerId,
      role,
      user_id,
    })
    if (!trackingManager.isTrackerExists(TrackerNameTypes.HOTJAR)) {
      trackingManager.register(new HotjarTrackerService(window.hj as IHotjar))
    }
  }
}

export function hotjarRegisterTracker() {
  if (
    'hj' in window &&
    typeof window.hj === 'function' &&
    !trackingManager.isTrackerExists(TrackerNameTypes.HOTJAR)
  ) {
    trackingManager.register(new HotjarTrackerService(window.hj as IHotjar))
  }
}
