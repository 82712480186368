import { APPLICANT_FRAUD_CHECK_KEY as AFC } from '../applicant-fraud-check.key'

const AppFrdChk = `${AFC}-applicant-fraud-check-qa`
export const applicantFraudCheckQa = {
  businessDetails: `${AppFrdChk}-business-details-component`,
  fraudCheckTable: `${AppFrdChk}-fraud-check-table`,
} satisfies Record<string, `${typeof AppFrdChk}-${string}`>

const FrdChkBusinessDetails = `${AFC}-applicant-fraud-check-business-details-qa`
export const applicantFraudCheckBusinessDetailsQa = {
  wrapper: `${FrdChkBusinessDetails}-wrapper`,
  heading: `${FrdChkBusinessDetails}-heading`,
} satisfies Record<string, `${typeof FrdChkBusinessDetails}-${string}`>

const AppFrdChkCell = `${AFC}-applicant-fraud-check-cell-qa`
export const applicantFraudCheckCellQa = {
  fraudTypeCellContainer: `${AppFrdChkCell}-fraud-type-wrapper`,
  selectBtn: `${AppFrdChkCell}-select-btn`,
  selectAllBtn: `${AppFrdChkCell}-select-all-btn`,
  tableHeaderCell: `${AppFrdChkCell}-table-header-cell`,
} satisfies Record<string, `${typeof AppFrdChkCell}-${string}`>

const FraudCheckStatusChange = `${AFC}-applicant-fraud-check-status-change-qa`
export const applicantFraudCheckStatusChangeQa = {
  form: `${FraudCheckStatusChange}-form`,
  closeBtn: `${FraudCheckStatusChange}-close-btn`,
  textArea: `${FraudCheckStatusChange}-text-area`,
} satisfies Record<string, `${typeof FraudCheckStatusChange}-${string}`>

const FraudCheckTable = `${AFC}-applicant-fraud-check-table-qa`
export const applicantFraudCheckTableQa = {
  button: `${FraudCheckTable}-button`,
} satisfies Record<string, `${typeof FraudCheckTable}-${string}`>

const FraudCheckTableSlot = `${AFC}-applicant-fraud-check-table-slot-qa`
export const applicantFraudCheckTableSlotQa = {
  wrapper: `${FraudCheckTableSlot}-wrapper`,
  loader: `${FraudCheckTableSlot}-loader`,
  icon: `${FraudCheckTableSlot}-icon`,
} satisfies Record<string, `${typeof FraudCheckTableSlot}-${string}`>
