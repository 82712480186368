import { useMutation } from '@tanstack/react-query'

import {
  ExecuteWorkflowPayload,
  useGetWorkflowEventsData,
  entityApi,
} from 'entities/entity'

import { useI18n } from 'shared/i18n'
import { notification } from 'shared/notification'

import { INDIVIDUAL_PROFILE_INSIGHT_KEY } from '../individual-profile-insight.key'
import { individualProfileInsightEn } from '../locale/individual-profile-insight.en'

type Args = {
  entityId: string
}

export const useExecuteWorkflowMutation = ({ entityId }: Args) => {
  const { refetch } = useGetWorkflowEventsData({ entityId })
  const t = useI18n([INDIVIDUAL_PROFILE_INSIGHT_KEY], {
    keys: individualProfileInsightEn,
  })
  return useMutation({
    mutationFn: async (payload: ExecuteWorkflowPayload) => {
      await entityApi.executeWorkflow(entityId, payload)
    },
    onSuccess: () => {
      void refetch()
      notification.success(t('banner.modal.success'))
    },
    onError: () => {
      notification.error(t('banner.modal.error'))
    },
  })
}
