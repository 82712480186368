import { useCallback, useState } from 'react'

import { useMutation } from '@tanstack/react-query'
import { Id } from 'react-toastify'

import { ApplicantId } from 'entities/applicant'

import { downloadBlobAsFile } from 'shared/file'
import { ErrorResponse } from 'shared/http'
import { useI18n } from 'shared/i18n'
import { notification } from 'shared/notification'

import { applicantAuditReportApi } from '../../api/applicant-audit-report.api'
import { APPLICANT_AUDIT_REPORT_KEY } from '../../applicant-audit-report.key'
import { applicantAuditReportEn } from '../../locale/applicant-audit-report.en'

type Arg = {
  applicantId?: ApplicantId
}

type MutateArg = {
  /**
   * entityId can be used to override the applicantId
   */
  entityId?: string
}

export type RequestedReportData = {
  applicantId?: string
}

export const DownloadAuditReportQueryKey = 'applicant-download-audit-report'

export const useDownloadAuditReportQuery = ({ applicantId }: Arg) => {
  const t = useI18n([APPLICANT_AUDIT_REPORT_KEY], {
    keys: applicantAuditReportEn,
  })

  const [toastId, setToastId] = useState<Id>()

  const mutation = useMutation({
    mutationFn: async ({ entityId }: MutateArg) => {
      const { data } = await applicantAuditReportApi.downloadAuditReport(
        entityId ?? applicantId!,
      )
      return data
    },
    onError: (error: ErrorResponse) => {
      notification.error(error.response?.data.message)
    },
    onSuccess: reportData => {
      notification.dismiss(toastId)
      downloadBlobAsFile(reportData, {
        fileName: 'AuditReport.pdf',
        type: 'application/pdf',
      })
      setToastId(notification.success(t('auditDownloadSuccess')))
    },
  })

  const downloadReport = useCallback(
    (entityId?: string) => {
      mutation.mutate({ entityId })
    },
    [mutation],
  )

  return {
    ...mutation,
    downloadReport,
  }
}
