import { CreateProfileInternationalBody } from './applicant.model'
import { IMatchedOrganization, OrganisationAddress } from './organisation.model'

export function getInternationalProfileFromMatchOrganisation(
  organisationInfo: IMatchedOrganization,
): CreateProfileInternationalBody {
  const outerCountry =
    organisationInfo.organizationCountry ?? organisationInfo.country

  const registrationDetails = organisationInfo.registrationDetails[0]

  return {
    organisationToken: organisationInfo.organizationToken,
    registeredName:
      registrationDetails.registeredName ?? organisationInfo.name?.name ?? '',
    registrationDescription: registrationDetails.registryDescription,
    registrationNumber: registrationDetails.registrationNumber,

    addresses:
      organisationInfo.addresses?.map(address => ({
        ...address,
        country: address.country || outerCountry,
      })) || ([{ country: outerCountry }] as OrganisationAddress[]),
  }
}
