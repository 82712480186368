import React from 'react'

import classNames from 'classnames'

import { FrankieButton, FrankieIcon, FrankieTooltip } from 'frankify/src'

import { useTriggerState } from 'shared/hooks'
import { useI18n } from 'shared/i18n'
import { TrackingEventsTypes, trackingManager } from 'shared/tracking'

import { INDIVIDUAL_AUDIT_REPORT_F2_KEY } from '../../individual-audit-report-f2.key'
import { individualAuditReportF2En } from '../../locale/individual-audit-report-f2.en'
import { EventsType } from '../../model/individual-audit-report-f2.model'
import { individualReportCardF2Qa } from '../../qa/individual-audit-report-f2.qa'
import { TagMapper } from '../individual-audit-report-helper-f2/individual-audit-report-helper-f2'

type IndividualReportCardProps = {
  eventData: EventsType
  idx: number
}
export function IndividualReportCard({
  eventData,
  idx,
}: IndividualReportCardProps) {
  const [copiedRequestId, setCopiedRequestId] = useTriggerState(10000)

  const t = useI18n([INDIVIDUAL_AUDIT_REPORT_F2_KEY], {
    keys: individualAuditReportF2En,
  })

  return (
    <div
      className={`border border-tertiary-grey-200 rounded-sm px-6 pt-4 pb-[18px] w-[93%] h-fit ${
        idx !== 0 ? 'mt-4' : ''
      }`}
      data-qa={individualReportCardF2Qa.header}
    >
      <div className="flex z-0 justify-between">
        <h1
          className="font-semibold text-[16px] text-tertiary-grey-800"
          data-qa={individualReportCardF2Qa.title}
        >
          {eventData.title}
        </h1>

        <TagMapper type={eventData.tag} />
      </div>
      {eventData.description?.map(desc => (
        <div key={desc.label} className="mt-[6px]">
          <span
            className="text-tertiary-grey-800 font-medium"
            data-qa={individualReportCardF2Qa.label}
          >
            {desc.label}:
          </span>
          <span
            className={classNames(
              desc.colorFlag === 'primary'
                ? 'text-primary-800'
                : 'text-tertiary-grey-700',
              'break-all',
            )}
            data-qa={individualReportCardF2Qa.value}
          >
            &nbsp;
            {desc.link ? <a href={desc.link}>{desc.value}</a> : desc.value}
          </span>
        </div>
      ))}

      {eventData.requestId && (
        <div className="mt-5 text-tertiary-grey-500 text-xs">
          <div className="flex gap-[6px]">
            <p className="font-medium  ">
              {t('section.label')}: &nbsp;
              <span
                className="font-normal"
                data-qa={individualReportCardF2Qa.requestId}
              >
                {eventData.requestId}
              </span>
            </p>
            <FrankieTooltip
              position="top"
              body={
                <div className="flex z-20 gap-2">
                  {copiedRequestId ? (
                    <>
                      <FrankieIcon size="xs" name="mdiCheckCircle" />{' '}
                      {t('section.copied')}
                    </>
                  ) : (
                    t('section.clickToCopy')
                  )}
                </div>
              }
            >
              <FrankieButton
                noStyles
                singleIcon={{
                  name: 'mdiContentCopy',
                  size: 'xs',
                  className: `cursor-pointer ${
                    copiedRequestId
                      ? 'text-primary-800'
                      : 'text-tertiary-grey-400'
                  }`,
                }}
                onClick={() => {
                  if (eventData.requestId) {
                    setCopiedRequestId()
                    void navigator.clipboard.writeText(eventData.requestId)
                    trackingManager.track(
                      TrackingEventsTypes.IndividualAuditReportCopyRequestId,
                    )
                  }
                }}
                testId={{ button: individualReportCardF2Qa.copyButton }}
              />
            </FrankieTooltip>
          </div>
        </div>
      )}
    </div>
  )
}
