import { LOGIN_KEY } from '../login.key'

export const loginQa = {
  form: 'login-form',
  header: 'login-header',
  emailInput: 'login-input-email',
  emailInputSupportText: 'login-input-email-support-text',
  passwordInput: 'login-input-password',
  passwordInputSupportText: 'login-input-password-support-text',
  resetPasswordLink: 'login-reset-password-link',
  alert: 'login-alert',
  submitButton: 'login-button',
  showPasswordCta: 'login-show-password-cta',
} satisfies Record<string, `${typeof LOGIN_KEY}-${string}`>
