import { useQuery } from '@tanstack/react-query'

import { ApplicantId } from 'entities/applicant'

import { ErrorResponse } from 'shared/http'

import { applicantBusinessOwnershipApi } from '../../api/applicant-business-ownership-international.api'
import { InternationalOwnershipResponse } from '../../model/applicant-business-ownership-international.model'

type Args = {
  applicantId?: ApplicantId
}

export const ApplicantBusinessOwnershipInternationalQueryKey =
  'applicant-business-ownership-international-query'

export const useApplicantInternationalBusinessOwnershipQuery = ({
  applicantId,
}: Args) =>
  useQuery<InternationalOwnershipResponse, ErrorResponse>({
    enabled: !!applicantId,
    queryKey: [ApplicantBusinessOwnershipInternationalQueryKey, applicantId],
    queryFn: async () => {
      const { data } =
        await applicantBusinessOwnershipApi.getBusinessOwnershipDetail(
          applicantId!,
        )
      return data.ownership
    },
  })
