import React from 'react'

import { FrankieLoader } from 'frankify/src'

import { useTableLoadingState } from 'features/applicants-filter'

import { useI18n } from 'shared/i18n'

import {
  ENTITIES_TABLE_KEY,
  entitiesTableEn,
} from '../../locale/entities-table.en'

export function EntitiesTableLoadingOverlay() {
  const t = useI18n([ENTITIES_TABLE_KEY], { keys: entitiesTableEn })

  const { data } = useTableLoadingState()

  const isClearingFilter = data === 'clearingFilters'
  const isApplyingFilters = data === 'applyingFilters'
  const isLoadingMoreData = data === 'loadingMoreData'

  return (
    <div className="flex align-center justify-center flex-col w-full h-full bg-mono-white">
      <div>
        <div id="loader">
          <FrankieLoader loading className="min-h-[76px]" size="sm" />
        </div>
        <div className="flex flex-col items-center mt-3">
          <span className="inline-block text-md font-semibold leading-6 text-tertiary-grey-800">
            {isClearingFilter && t('clearingFilters')}
            {isApplyingFilters && t('applyingFilters')}
            {isLoadingMoreData && t('loadingMoreData')}
          </span>
        </div>
      </div>
    </div>
  )
}
