import { IClient, bffClient } from 'shared/client'

import {
  AssignEntityResponse,
  ExecuteWorkflowPayload,
} from '../model/entity-data.model'
import {
  IndividualsEntityResponse,
  ServiceProfile,
} from '../model/entity.model'

export class EntityAPI {
  client: IClient

  constructor(client: IClient) {
    this.client = client
  }

  async getEntity(entityId: string, level?: string) {
    return this.client.get<IndividualsEntityResponse>(
      `/f2/v1/individual-entity/${entityId}`,
      {
        params: {
          level,
        },
      },
    )
  }

  async assignEntity(
    entityId: string,
    props: { assignee: string; entityIds?: string[] },
  ) {
    return this.client.patch<AssignEntityResponse, unknown>(
      `/f2/v1/individual/${entityId}/assign`,
      props,
    )
  }

  async archiveEntity(entityId: string, data: object, serviceName?: string) {
    return this.client.patch<{ serviceProfile: ServiceProfile }, unknown>(
      `/f2/v1/individual/${entityId}/state`,
      { ...data, serviceName },
    )
  }

  async getWorkflowEvents(entityId: string) {
    return this.client.get<{ serviceProfile: ServiceProfile }>(
      `f2/v1/individual/${entityId}/workflows`,
    )
  }

  async executeWorkflow(entityId: string, payload: ExecuteWorkflowPayload) {
    return this.client.patch(`f2/v1/individual/${entityId}/execute`, payload)
  }
}

export const entityApi = new EntityAPI(bffClient)
