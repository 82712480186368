import React, { useCallback, useMemo } from 'react'

import {
  IReferences,
  ITrustAnalyse,
} from '../../../model/applicant-supporting-documents.model'
import { TrustReferenceTypes } from '../../../model/applicant-supporting-trust-deed.model'

export type TrustAnalyserReferenceProps = {
  text: string
  data: ITrustAnalyse
  type: TrustReferenceTypes
  getPageNumber: (page: number) => void
  index?: number
  className?: string
  testId?: {
    reference?: string
  }
}

export function TrustAnalyserReference({
  text,
  data,
  type,
  getPageNumber,
  index,
  className = '',
  testId = {
    reference: '',
  },
}: TrustAnalyserReferenceProps) {
  const getPage = (
    references: IReferences,
    referenceIds: string[] | undefined,
  ) => {
    if (referenceIds && referenceIds.length) {
      // TODO: it will always get the first reference id, need to check if this is the correct one for the future
      const GET_FIRST_REFERENCE_ID = referenceIds[0]
      return references[GET_FIRST_REFERENCE_ID].location.page
    }
    return 0
  }

  // eslint-disable-next-line complexity
  const getPageByReferenceId = useCallback(() => {
    const { references = {}, documentInformation } = data

    if (Object.keys(references).length === 0 || !data.documentInformation)
      return 0

    const { trust } = documentInformation || {}
    const { name, establishment } = trust || {}
    const { date, country } = establishment || {}

    switch (type) {
      case TrustReferenceTypes.NAME_OF_TRUST:
        return getPage(references, name?.referenceIds)
      case TrustReferenceTypes.DATE_OF_ESTABLISHMENT:
        return getPage(references, date?.referenceIds)
      case TrustReferenceTypes.COUNTRY_OF_ESTABLISHMENT:
        return getPage(references, country?.referenceIds)
      default:
        break
    }

    const { settlors = [], trustees = [], typeInformation } = trust || {}
    const { discretionary, unit, selfManagedSuperFund } = typeInformation || {}
    const { unitHolders = [] } = unit || {}
    const { members = [] } = selfManagedSuperFund || {}
    const {
      generalBeneficiaries = [],
      specifiedBeneficiaries = [],
      appointors = [],
      protectors = [],
    } = discretionary || {}

    if (index === undefined) return 0

    switch (type) {
      case TrustReferenceTypes.SETTLORS:
        return getPage(references, settlors[index]?.referenceIds)
      case TrustReferenceTypes.TRUSTEES:
        return getPage(references, trustees[index]?.referenceIds)
      case TrustReferenceTypes.APPOINTORS:
        return getPage(references, appointors[index]?.referenceIds)
      case TrustReferenceTypes.PROTECTORS:
        return getPage(references, protectors[index]?.referenceIds)
      case TrustReferenceTypes.GENERAL_BENEFICIARIES:
        return getPage(references, generalBeneficiaries[index]?.referenceIds)
      case TrustReferenceTypes.SPECIFIED_BENEFICIARIES:
        return getPage(references, specifiedBeneficiaries[index]?.referenceIds)
      case TrustReferenceTypes.MEMBERS:
        return getPage(references, members[index]?.referenceIds)
      case TrustReferenceTypes.UNIT_HOLDERS:
        return getPage(references, unitHolders[index]?.referenceIds)
      default:
        return 0
    }
  }, [data, index, type])

  const pageNumber = useMemo(
    () => getPageByReferenceId(),
    [getPageByReferenceId],
  )

  if (pageNumber) {
    return (
      <sup
        onClick={() => getPageNumber(pageNumber)}
        role="presentation"
        className={`text-xs font-normal text-primary-800 cursor-pointer relative left-2 ${className}`}
        data-qa={testId.reference}
      >
        {text}
      </sup>
    )
  }

  return null
}
