import { AddressCategoryTypes, ApplicantResponse } from 'entities/applicant'
import { AddressCategoryReverseTypes } from 'entities/applicant/model/applicant-address.model'
import { DocumentIdTypes, scanDateSorter, scanSorter } from 'entities/document'

import {
  ConsentTypes,
  IIndividualProfileInputs,
  IndividualProfileInputTypes,
  consentsArray,
} from './form.model'
import { getAddressData, getExtraData } from './formData.model'
import {
  Address,
  ChangeType,
  IndividualProfilePayloadTypes,
} from './individual-profile-payload.model'

const getPersonalData = (
  formData: IIndividualProfileInputs,
  applicantData: ApplicantResponse,
): IndividualProfilePayloadTypes['personal'] & {
  originalUboDetails: ApplicantResponse['applicantDetails']['originalUboDetails']
  gender: ApplicantResponse['applicantDetails']['gender']
  fraudData: ApplicantResponse['applicantDetails']['fraudData']
  blocklistAttributes: ApplicantResponse['applicantDetails']['blocklistAttributes']
  additionalReferences: object
} => ({
  customerReference:
    formData[IndividualProfileInputTypes.PersonalInfo][
      IndividualProfileInputTypes.CustomerId
    ],
  dateOfBirth:
    formData[IndividualProfileInputTypes.PersonalInfo][
      IndividualProfileInputTypes.Dob
    ],
  msisdn: {
    documentId: applicantData.applicantDetails.phoneNumber.documentId || '',

    value:
      formData[IndividualProfileInputTypes.PersonalInfo][
        IndividualProfileInputTypes.PhoneNumber
      ],
  },
  email: {
    documentId: applicantData.applicantDetails.email.documentId || '',
    value:
      formData[IndividualProfileInputTypes.PersonalInfo][
        IndividualProfileInputTypes.Email
      ],
  },
  name: {
    givenName:
      formData[IndividualProfileInputTypes.PersonalInfo][
        IndividualProfileInputTypes.FirstName
      ],
    middleName:
      formData[IndividualProfileInputTypes.PersonalInfo][
        IndividualProfileInputTypes.MiddleName
      ],
    familyName:
      formData[IndividualProfileInputTypes.PersonalInfo][
        IndividualProfileInputTypes.LastName
      ],
  },
  profile: formData[IndividualProfileInputTypes.Recipe],
  selfie: {
    image: '',
    video: '',
    documentId: '',
    country: '',
  },
  extraData: {
    'dob.Buddhist': '',
    ...applicantData.applicantDetails.extraData,
  },
  originalUboDetails: applicantData.applicantDetails.originalUboDetails,
  gender: applicantData.applicantDetails.gender,
  fraudData: applicantData.applicantDetails.fraudData,
  blocklistAttributes: applicantData.applicantDetails.blocklistAttributes,
  additionalReferences: {},
  addresses: getAddressData(formData),
  consents: formData[IndividualProfileInputTypes.Consent]
    ? consentsArray
    : (formData[IndividualProfileInputTypes.SeparateConsent].filter(
        i => i,
      ) as ConsentTypes[]),
})

// eslint-disable-next-line complexity

export const getDocumentTypesData = (
  formData: IIndividualProfileInputs,
  applicantData: ApplicantResponse,
): IndividualProfilePayloadTypes['document'] =>
  formData[IndividualProfileInputTypes.Document]
    .filter(doc => doc[IndividualProfileInputTypes.IdType])
    .map((doc, idx) => {
      const document = applicantData.documents.find(
        i => i.documentId === doc[IndividualProfileInputTypes.DocumentId],
      )

      const fileUploadUuid =
        doc[IndividualProfileInputTypes.Scan][
          IndividualProfileInputTypes.UploadedUUID
        ]

      const scan = document?.scans.sort(scanSorter).sort(scanDateSorter)?.[0]

      const newScansArray = []

      if (scan || fileUploadUuid) {
        newScansArray.push({
          scanCreated: scan?.scanCreated,
          scanId: scan?.scanId,
          scanName: scan?.scanName,
          side: scan?.side,
          mimeType:
            doc[IndividualProfileInputTypes.Scan][
              IndividualProfileInputTypes.FileType
            ],
          fileUploadUuid,
        })
      }

      return {
        documentData: {
          country: doc[IndividualProfileInputTypes.Country],
          region: doc[IndividualProfileInputTypes.State],
          idNumber: doc[IndividualProfileInputTypes.IdNumber],
          idType: [DocumentIdTypes.PAN, DocumentIdTypes.VOTER_ID, ''].includes(
            doc[IndividualProfileInputTypes.IdType],
          )
            ? DocumentIdTypes.NATIONAL_ID
            : doc[IndividualProfileInputTypes.IdType],
          documentId: doc[IndividualProfileInputTypes.DocumentId],
          ...applicantData.documents.find(
            i => i.documentId === doc[IndividualProfileInputTypes.DocumentId],
          )?.extraData,
          ...getExtraData(
            formData,
            formData[IndividualProfileInputTypes.Document][idx],
          ),
        },
        idType: [DocumentIdTypes.PAN, DocumentIdTypes.VOTER_ID, ''].includes(
          doc[IndividualProfileInputTypes.IdType],
        )
          ? DocumentIdTypes.NATIONAL_ID
          : doc[IndividualProfileInputTypes.IdType],
        mimeType:
          doc[IndividualProfileInputTypes.Scan][
            IndividualProfileInputTypes.FileType
          ],
        validation: {
          manual: {
            isValid: true,
          },
          electronic: {
            validationReport: '',
            isValid: null,
          },
        },
        scans: newScansArray,
      }
    })

// eslint-disable-next-line complexity
const getAddressChanges = (
  address: Address,
  previousAddress?: Address,
): ChangeType => {
  if (address.addressType !== previousAddress?.addressType) {
    return {}
  }
  const key = `address.${
    AddressCategoryReverseTypes[address.addressType as AddressCategoryTypes]
  }.`
  const changes: ChangeType = {}
  for (const i in address) {
    if (!['isAddedByCustomer', 'data'].includes(i)) {
      const newValue =
        address[i as keyof Omit<Address, 'isAddedByCustomer' | 'data'>]
      const oldValue =
        previousAddress[i as keyof Omit<Address, 'isAddedByCustomer' | 'data'>]
      if (newValue !== oldValue) changes[`${key}${i}`] = [oldValue, newValue]
    }
  }
  return changes
}

// eslint-disable-next-line max-params
function getPersonalChanges(
  formData: object,
  oldFormData: object,
  keyStack: string,
  changeArray: ChangeType,
) {
  const newFormData: object = { ...formData }

  ;['addresses'].forEach(key => {
    delete newFormData[key as keyof typeof newFormData]
  })

  for (const i in newFormData) {
    if (typeof newFormData[i as keyof typeof newFormData] === 'object') {
      getPersonalChanges(
        newFormData[i as keyof typeof newFormData],
        oldFormData[i as keyof typeof newFormData],
        `${keyStack + i}.`,
        changeArray,
      )
    } else if (
      newFormData[i as keyof typeof newFormData] !==
      oldFormData[i as keyof typeof newFormData]
    ) {
      // eslint-disable-next-line no-param-reassign
      changeArray[`${keyStack}${i}`] = [
        oldFormData[i as keyof typeof newFormData],
        newFormData[i as keyof typeof newFormData],
      ]
    }
  }
}

export const transformDataForUpdateOnly = (
  formData: IIndividualProfileInputs,
  applicantData: ApplicantResponse,
): IndividualProfilePayloadTypes => ({
  checkSummary: null,
  business: null,
  personal: getPersonalData(formData, applicantData),
  entityType: 'INDIVIDUAL',
  document: getDocumentTypesData(formData, applicantData),
  consent: consentsArray,
  kycMethod: formData[IndividualProfileInputTypes.KycMethod],
  comment: formData[IndividualProfileInputTypes.Comment],
  property_status: {
    personal_changes: {},
    document_changes: {
      created: [],
      changed: [],
      removed: [],
    },
  },
})

export const transformDataForUpdate = (
  formData: IIndividualProfileInputs,
  previousFormData: IIndividualProfileInputs,
  applicantData: ApplicantResponse,
): IndividualProfilePayloadTypes => {
  let changeArray: ChangeType = {}
  const payloadData = transformDataForUpdateOnly(formData, applicantData)
  const oldPayloadData = transformDataForUpdateOnly(
    previousFormData,
    applicantData,
  )
  getPersonalChanges(
    payloadData.personal,
    oldPayloadData.personal,
    '',
    changeArray,
  )

  changeArray = {
    ...changeArray,
    ...payloadData.personal.addresses
      .map(address =>
        getAddressChanges(
          address,
          oldPayloadData.personal.addresses.find(
            oldAdd => oldAdd.addressType === address.addressType,
          ),
        ),
      )
      .reduce((acc, cur) => ({ ...acc, ...cur }), {}),
  }

  return {
    ...payloadData,
    property_status: {
      personal_changes: changeArray,
      document_changes: {
        created: formData[IndividualProfileInputTypes.Document].flatMap(doc =>
          previousFormData.document.find(
            oldDoc =>
              oldDoc[IndividualProfileInputTypes.DocumentId] ===
              doc[IndividualProfileInputTypes.DocumentId],
          )
            ? []
            : [doc[IndividualProfileInputTypes.IdType]],
        ),
        changed: [],
        removed: previousFormData[IndividualProfileInputTypes.Document].flatMap(
          doc =>
            formData[IndividualProfileInputTypes.Document].find(
              oldDoc =>
                oldDoc[IndividualProfileInputTypes.DocumentId] ===
                doc[IndividualProfileInputTypes.DocumentId],
            )
              ? []
              : [doc[IndividualProfileInputTypes.IdType]],
        ),
      },
    },
  }
}
