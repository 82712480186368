import { useCallback, useState } from 'react'

import { useQuery } from '@tanstack/react-query'

import { debounce } from 'shared/function'
import { Nullable } from 'shared/typescript'

import { organisationApi } from '../../api/organisation.api'

type Args = {
  searchTerm?: Nullable<string | number>
}

export const BusinessSearchQueryKey = 'business-search-query'

export const useBusinessSearch = ({ searchTerm }: Args) => {
  const [searchValue, setSearchValue] = useState<Args['searchTerm']>(searchTerm)

  const query = useQuery({
    enabled: !!searchValue,
    queryKey: [BusinessSearchQueryKey, searchValue],
    queryFn: async () => {
      const { data } = await organisationApi.searchBusiness(searchValue!)
      return data
    },
  })

  const handleSearchValue = useCallback(
    debounce((value: Args['searchTerm']) => {
      setSearchValue(value)
    }, 500),
    [],
  )

  return { ...query, handleSearchValue }
}
