import React, { useMemo } from 'react'

import { ApplicantResponse } from 'entities/applicant'

import { useI18n } from 'shared/i18n'

import { APPLICANT_PERSONAL_INFO_KEYS } from '../../applicant-persona-info.keys'
import { applicantPersonalInfoEn } from '../../locale/applicant-personal-info.en'
import { DetailViewTypes } from '../../model/applicant-detail-view.model'
import { RulesetMatchResultTypes } from '../../model/applicant-matched-rulesets.model'
import { applicantMatchedRuleSetQa } from '../../qa/applicant-personal-info.qa'
import { ApplicantDetailView } from '../applicant-detail-view/applicant-detail-view'
import { ApplicantRulesetsTable } from '../applicant-matched-rulesets-table-view/applicant-matched-rulesets-table'

type Props = {
  applicantData: ApplicantResponse
  isLegacyProfile: boolean
  testId?: { container?: string }
}

export function ApplicantMatchedRulesets({
  applicantData,
  isLegacyProfile,
  testId,
}: Props) {
  const t = useI18n(APPLICANT_PERSONAL_INFO_KEYS, {
    keys: applicantPersonalInfoEn,
  })

  const rulesets = useMemo(() => {
    const rulesetsData = applicantData.checkSummary.rulesetsMatches

    // Check if all rulesets are unchecked
    const allUnchecked = rulesetsData.every(
      row => row.result === RulesetMatchResultTypes.UNCHECKED,
    )

    if (allUnchecked) {
      return null
    }

    const passedRulesets = rulesetsData.filter(row => row.result === 'PASS')

    return {
      checkCount: passedRulesets.length,
      countSource: passedRulesets,
      isVerified: passedRulesets.length > 0,
      isManualKYC: applicantData.checkSummary.kycMethod === 'manual',
    }
  }, [
    applicantData.checkSummary.kycMethod,
    applicantData.checkSummary.rulesetsMatches,
  ])

  if (!rulesets) {
    return null
  }

  return (
    <div data-qa={testId?.container} className="text-tertiary-grey-700">
      <h2 className="text-xl font-[700] mt-8 mb-6">{t('rulesets.title')}</h2>
      <ApplicantDetailView
        isLast
        label={t('rulesets.title')}
        value={
          isLegacyProfile
            ? t('rulesets.sectionNotSupported')
            : t('rulesets.matches')
        }
        isEntityPassed={null}
        iconName="mdiAccount"
        checkCount={rulesets.checkCount}
        isVerified={rulesets.isVerified}
        isManualKYC={rulesets.isManualKYC}
        isLegacyProfile={isLegacyProfile}
        type={DetailViewTypes.RULESET_MATCHES}
        renderAdditionalInfo={[
          <ApplicantRulesetsTable
            isPriorityMatched
            applicantData={applicantData}
          />,
          <ApplicantRulesetsTable applicantData={applicantData} />,
        ]}
        testId={{ container: applicantMatchedRuleSetQa.applicantDetailView }}
      />
    </div>
  )
}
