import React, { useEffect, useRef, useState } from 'react'

import { TrackingEventsTypes, trackingManager } from 'shared/tracking'

type ZoomImageProps = {
  src: string
  rotateDeg: string
  allowZoom?: boolean
  zoomLevel?: number
  trackingEvent?: TrackingEventsTypes
}

export function ZoomImage({
  src,
  rotateDeg,
  allowZoom = true,
  zoomLevel = 2,
  trackingEvent,
}: ZoomImageProps) {
  const [isZoomed, setIsZoomed] = useState(false)
  const [transformOrigin, setTransformOrigin] = useState('50% 50%')
  const imageContainerRef = useRef<HTMLImageElement>(null)

  const handleMouseMove = (
    e: React.MouseEvent<HTMLImageElement, MouseEvent>,
  ) => {
    if (imageContainerRef.current) {
      const deg = Number(
        rotateDeg
          .replaceAll('rotate-[', '')
          .replaceAll(']', '')
          .replace('deg', ''),
      )

      const rect = imageContainerRef.current.getBoundingClientRect()
      const offsetX = e.clientX - rect.left
      const offsetY = e.clientY - rect.top
      const xPercent = (offsetX / rect.width) * 100
      const yPercent = (offsetY / rect.height) * 100

      if (deg === 180) {
        setTransformOrigin(`${100 - xPercent}% ${100 - yPercent}%`)
      }
      if (deg === 90) {
        setTransformOrigin(`${yPercent}% ${100 - xPercent}%`)
      }
      if (deg === 270) {
        setTransformOrigin(`${100 - yPercent}% ${xPercent}%`)
      }

      if (deg === 0) setTransformOrigin(`${xPercent}% ${yPercent}%`)
    }
  }

  useEffect(() => {
    if (trackingEvent && isZoomed) {
      trackingManager.track(trackingEvent)
    }
  }, [trackingEvent, isZoomed])

  const handleMouseEnter = () => setIsZoomed(true)
  const handleMouseLeave = () => setIsZoomed(false)

  return (
    <div className="border  border-tertiary-grey-500 relative rounded-[13.51px] w-full h-full overflow-hidden flex justify-center items-center ">
      <div className={`${rotateDeg} h-full w-full`}>
        <img
          ref={imageContainerRef}
          onMouseMove={allowZoom ? handleMouseMove : undefined}
          onMouseEnter={allowZoom ? handleMouseEnter : undefined}
          onMouseLeave={allowZoom ? handleMouseLeave : undefined}
          src={src}
          alt="thumbnail"
          className="object-contain h-full relative m-auto"
          style={{
            transformOrigin,
            transform: isZoomed ? ` scale(${zoomLevel})` : ' scale(1)',
            transition: 'transform 0.3s ease',
          }}
        />
      </div>
    </div>
  )
}
