import React from 'react'

import { Controller, ControllerProps, FieldValues } from 'react-hook-form'

import { FrankieButton } from 'frankify/src'

type ToggleButtonProps<T extends string | number> = {
  className?: string
  inputClassName?: string
  variant?: 'F1' | 'F2'
  label?: string
  options: {
    label: string
    value: T
    disabled?: boolean
    key?: string
  }[]
  value: T
  disabled?: boolean
  onChange: (value: T) => void
}

export function ToggleButton<T extends string | number>({
  className = '',
  inputClassName = '',
  label = '',
  options,
  variant = 'F1',
  value,
  onChange,
  disabled,
}: ToggleButtonProps<T>) {
  return (
    <div className={className}>
      {label && (
        <div className="pb-1 text-tertiary-grey-800 text-sm font-medium">
          {label}
        </div>
      )}
      <div
        className={`${inputClassName} flex ${
          variant === 'F2'
            ? 'border rounded-sm border-solid border-tertiary-grey-200'
            : ''
        }`}
      >
        {options.map((item, index) => {
          const disabledEitherWay =
            (disabled || item.disabled) && value !== item.value
          return (
            <FrankieButton
              key={item.key || item.value}
              disabled={disabled || item.disabled}
              noStyles
              onClick={() => onChange(item.value)}
              className={
                variant === 'F2'
                  ? `w-full px-2 py-1 text-tertiary-grey-700 relative overflow-hidden font-semibold leading-tight text-xs last:border-none  border-r border-solid border-tertiary-grey-200 ${
                      value === item.value
                        ? 'bg-primary-50'
                        : 'hover:bg-tertiary-grey-100'
                    } ${
                      disabledEitherWay
                        ? '!text-tertiary-grey-400 hover:bg-none !cursor-not-allowed'
                        : 'cursor-pointer '
                    }`
                  : `w-full px-2 py-1 relative overflow-hidden font-bold leading-tight ${
                      value === item.value
                        ? 'text-mono-white bg-primary-500'
                        : 'text-mono-black hover:bg-primary-50'
                    } border border-solid ${
                      disabledEitherWay
                        ? 'border-tertiary-grey-200 !text-tertiary-grey-300 hover:bg-primary-50 !cursor-not-allowed'
                        : 'border-tertiary-grey-300 cursor-pointer'
                    } hover:border-primary-500 ${
                      index === 0 ? 'rounded-l' : ''
                    } ${
                      index === options.length - 1 ? 'rounded-r' : ''
                    } transition duration-300 ease-in-out transform`
              }
            >
              {item.label}
              <div className="absolute inset-0 bg-white opacity-25" />
            </FrankieButton>
          )
        })}
      </div>
    </div>
  )
}

type Props<
  TFormValues extends FieldValues,
  TOption extends string | number,
> = Omit<ToggleButtonProps<TOption>, 'onChange' | 'value'> &
  Omit<ControllerProps<TFormValues>, 'render'>

export function ToggleButtonFormField<
  TFormValues extends FieldValues,
  TOption extends string | number,
>({
  name,
  control,
  defaultValue,
  rules,
  shouldUnregister,
  ...toggleButtonProps
}: Props<TFormValues, TOption>) {
  return (
    <Controller
      render={({ field }) => <ToggleButton {...field} {...toggleButtonProps} />}
      name={name}
      rules={rules}
      control={control}
      defaultValue={defaultValue}
      shouldUnregister={shouldUnregister}
    />
  )
}
