import { FieldErrors, FieldPath, FieldValues } from 'react-hook-form'

import { differenceInDays } from '../../date-time'

export const getError = <TFieldValues extends FieldValues>(
  name: FieldPath<TFieldValues>,
  errors: FieldErrors<TFieldValues>,
) => {
  if (!Object.keys(errors).length) return undefined

  const error = name
    .split('.')
    .map(item => item.replaceAll('[', '').replaceAll(']', ''))
    .reduce(
      (prev: object | string | undefined, curr) =>
        prev ? prev[curr as keyof typeof prev] : prev,
      errors,
    ) as string

  return error
}

export const validateDate = (value: string, isDob = false) => {
  // current date format YYYY-MM-DD
  const date = new Date(value)
  const currentDate = new Date()
  const daysDifference = differenceInDays(currentDate, date)
  const year = Number(date.getFullYear())

  if (isDob && daysDifference < 0) {
    return false
  }

  if (year < currentDate.getFullYear() - 100 || `${year}`.length > 4) {
    return false
  }

  return true
}

/**
 * for MM-YYYY date format
 */
export const isDateValid = (value: string) => {
  const splitData = value.split('-')
  if (splitData.length === 2) {
    const month = parseInt(splitData[0], 10)
    const year = parseInt(splitData[1], 10)
    const isValidDate =
      !Number.isNaN(month) &&
      !Number.isNaN(year) &&
      month >= 1 &&
      month <= 12 &&
      `${year}`.length === 4
    return isValidDate
  }
  return false
}
