import React, { useMemo, useState, useEffect, useRef, useCallback } from 'react'

import { debounce } from '@mui/material'
import { NavLink, useLocation } from 'react-router-dom'

import { FrankieButton, FrankieIcon, FrankieIconName } from 'frankify/src'

import { PermissionTypes } from 'entities/role'
import { useHasPermission } from 'entities/session'

export type NavTabOption = {
  label: string
  path: string
  permission?: {
    type: PermissionTypes | PermissionTypes[]
    name: `canSee${string}`
  }
  icon?: FrankieIconName
  testId?: string
}

type Props = {
  options: NavTabOption[]
  className?: string
  arrowButtonClassName?: string
  navItemClassName?: string
  replace?: boolean
}

/** Value by which tabs will be scrolled  */
const SCROLL_BY_VALUE = 120

/** Difference Value by which arrows are shown/hidden  */
const SCROLL_DIFF = 12

export function NavTabs({
  options,
  arrowButtonClassName = '',
  className = '',
  navItemClassName = '',
  replace,
}: Props) {
  const tabsContainerRef = useRef<HTMLDivElement>(null)

  const [showLeftArrow, setShowLeftArrow] = useState(false)
  const [showRightArrow, setShowRightArrow] = useState(false)

  // using pathname to match exact routes, as the nested path will show both the tab active
  const { pathname } = useLocation()

  /**
   * Extracting permission object from options to check whether user has permission to see the tab or not
   */
  const permissionObject = useMemo(
    () =>
      options.reduce((value, item) => {
        if (item.permission) {
          const { type, name } = item.permission
          return { ...value, [name]: type }
        }
        return value
      }, {} as Record<string, PermissionTypes | PermissionTypes[]>),
    [options],
  )

  const permissions = useHasPermission(permissionObject)

  /**
   * Filtering the options based on the permission
   */
  const filteredTabOptions = useMemo(
    () =>
      options.filter(
        item =>
          (item.permission && permissions[item.permission.name]) ||
          !item.permission,
      ),
    [options, permissions],
  )

  /**
   * Updating the visibility of arrows based on the scroll position
   * Debouncing to avoid multiple calls
   */
  const updateArrowsVisibility = useCallback(
    debounce(() => {
      const container = tabsContainerRef.current
      if (container) {
        setShowLeftArrow(container.scrollLeft > SCROLL_DIFF)
        setShowRightArrow(
          container.scrollWidth - container.clientWidth >
            container.scrollLeft + SCROLL_DIFF,
        )
      }
    }, 200),
    [],
  )

  /**
   * Getting whether items are overflown on if container width changes or filter option changes
   */
  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      updateArrowsVisibility()
    })

    const container = tabsContainerRef.current

    if (container) {
      resizeObserver.observe(container)
    }

    return () => {
      resizeObserver.disconnect()
    }
  }, [tabsContainerRef, filteredTabOptions, updateArrowsVisibility])

  /**
   * To scroll to selected tab if container is overflow during the option changes or page load
   */
  useEffect(() => {
    const container = tabsContainerRef.current

    if (container) {
      const selectedTabElement = container.querySelector(
        `a[href="${pathname}"]`,
      ) as HTMLElement | undefined

      if (selectedTabElement?.offsetLeft) {
        container.scrollTo({
          left: selectedTabElement.offsetLeft - container.clientWidth / 2,
          behavior: 'instant',
        })

        updateArrowsVisibility()
      }
    }
  }, [filteredTabOptions.length, pathname, updateArrowsVisibility])

  /**
   * Handle scroll of the item when clicked on left or right
   */
  const handleScroll = (direction: 'left' | 'right') => {
    const container = tabsContainerRef.current
    if (container) {
      const scrollAmount =
        direction === 'left' ? -SCROLL_BY_VALUE : SCROLL_BY_VALUE
      container.scrollBy({ left: scrollAmount, behavior: 'smooth' })
    }
    updateArrowsVisibility()
  }

  const arrowButtonClass = `text-tertiary-grey-500 px-1 my-[2px] border-solid border-tertiary-grey-300 shadow-sm hover:shadow-md ${arrowButtonClassName}`

  return (
    <div className={`flex relative h-[48px] ${className}`}>
      <FrankieButton
        noStyles
        className={`${arrowButtonClass} border-l rounded-r-xs rounded-l-md ${
          showLeftArrow ? 'visible' : 'invisible'
        }`}
        onClick={() => handleScroll('left')}
        singleIcon={{
          name: 'mdiChevronLeft',
        }}
      />

      <div
        ref={tabsContainerRef}
        className="flex items-center overflow-x-hidden"
      >
        {filteredTabOptions.map(item => (
          <NavLink
            key={item.label}
            className={`h-full  whitespace-nowrap font-semibold mx-4 py-4 border-b-4 cursor-pointer w-auto leading-tight hover:!text-tertiary-grey-700 ${
              pathname === item.path
                ? 'border-primary-800 text-tertiary-grey-700'
                : 'border-transparent text-tertiary-grey-500'
            } ${navItemClassName}`}
            to={item.path}
            replace={replace}
            data-qa={item.testId}
          >
            {item.icon && (
              <FrankieIcon
                size="sm"
                className="inline-block align-bottom mr-2"
                name={item.icon}
              />
            )}
            {item.label}
          </NavLink>
        ))}
      </div>

      <FrankieButton
        noStyles
        className={`${arrowButtonClass} border-r rounded-l-xs rounded-r-md ${
          showRightArrow ? 'visible' : 'invisible'
        }`}
        onClick={() => handleScroll('right')}
        singleIcon={{
          name: 'mdiChevronRight',
        }}
      />
    </div>
  )
}
