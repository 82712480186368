import React, { useState } from 'react'

import { FrankieButton } from 'frankify/src'

import { ImageGallery } from 'shared/image-gallery'
import { useOverlay } from 'shared/overlay'
import { TrackingEventsTypes, trackingManager } from 'shared/tracking'

import { IndividualOcrOverlay } from '../individual-ocr-overlay/individual-ocr-overlay'

type Props = {
  gallery: string[]
  entityId: string
}

export function IndividualOcrGallery({ gallery, entityId }: Props) {
  const [activeIndex, setActiveIndex] = useState(0)
  const [createOverlay] = useOverlay()

  const handleExpand = () => {
    trackingManager.track(
      TrackingEventsTypes.WorkflowEventsBiometricsCompareShow,
    )
    createOverlay(
      <IndividualOcrOverlay
        entityId={entityId}
        defaultIndex={activeIndex}
        gallery={gallery}
      />,
      { className: '!p-6', closeButtonClassName: '!top-6 !right-6' },
    )
  }

  const handleDownload = async () => {
    trackingManager.track(TrackingEventsTypes.WorkflowEventsBiometricsDownload)
    const blob = await fetch(gallery[activeIndex]).then(r => r.blob())
    const imageUrl = URL.createObjectURL(blob)
    const link = document.createElement('a')
    const isUrlNameCorrect =
      (gallery[activeIndex].split('.').at(-1)?.length ?? 0) <= 3
    link.href = imageUrl
    link.download = isUrlNameCorrect ? gallery[activeIndex] : 'download.jpg'
    link.target = '_blank'
    link.click()
  }

  const isVideo = ['MP4'].includes(
    gallery[activeIndex].split('.').at(-1)?.toUpperCase() || '',
  )

  return (
    <div className="row-start-1 row-end-6 ">
      <div className="shrink flex flex-col gap-3">
        <div className="flex gap-2 flex-col bg-tertiary-grey-200 rounded-sm p-2">
          <div className="">
            {!isVideo ? (
              <img
                src={gallery[activeIndex]}
                alt="selfie"
                className="w-[250px] aspect-square mx-auto"
              />
            ) : (
              <video
                loop
                controls
                onPlay={() =>
                  trackingManager.track(
                    TrackingEventsTypes.WorkflowEventsBiometricsPlay,
                  )
                }
                key={gallery[activeIndex]}
                className="w-[250px] aspect-square mx-auto"
              >
                <track kind="captions" />
                <source src={gallery[activeIndex]} type="video/mp4" />
              </video>
            )}
          </div>
          <div className="flex justify-between">
            <FrankieButton
              className="p-1.5"
              noStyles
              onClick={handleExpand}
              singleIcon={{ name: 'mdiArrowExpand' }}
            />
            <FrankieButton
              className="p-1.5"
              noStyles
              onClick={handleDownload}
              singleIcon={{ name: 'mdiDownload' }}
            />
          </div>
        </div>
        <ImageGallery
          gallery={gallery}
          selected={activeIndex}
          onChange={idx => {
            setActiveIndex(idx)
            trackingManager.track(
              TrackingEventsTypes.WorkflowEventsBiometricsThumbnailClick,
            )
          }}
        />
      </div>
    </div>
  )
}
