export type ValidationStates = {
  minLength: boolean
  hasUpperCase: boolean
  hasLowerCase: boolean
  hasNumeric: boolean
  hasSpecialChar: boolean
}
export enum ValidationTypes {
  MinLength = 'minLength',
  HasUpperCase = 'hasUpperCase',
  HasLowerCase = 'hasLowerCase',
  HasNumeric = 'hasNumeric',
  HasSpecialChar = 'hasSpecialChar',
}
export enum FormFieldTypes {
  NewPassword = 'newPassword',
  ConfirmPassword = 'confirmPassword',
}
export type FormFields = {
  newPassword: string
  confirmPassword: string
}
export enum UrlParamsTypes {
  isNewUser = 'n',
  Token = 'h',
  Email = 'u',
}

export const NEW_PASSWORD_KEY = 'new-password'

export enum KnownErrorMessageTypes {
  MatchesPreviosPassword = 'matchesPreviousPassword',
  TooManyPasswordAttempts = 'tooManyPasswordAttempts',
  PasswordResetExpired = 'passwordResetExpired',
}
