import type { ITracker } from 'shared/tracking'

import { TrackerNameTypes, trackingManager } from '../../tracking'
import { IAppCues } from '../model/appcues.model'

class HotjarTrackerService implements ITracker {
  appCues: IAppCues

  name = TrackerNameTypes.APPCUES

  constructor(appCues: IAppCues) {
    this.appCues = appCues
  }

  track(event: string) {
    this.appCues.track(event)
  }
}

export function appcuesRegisterTracker() {
  if (
    'Appcues' in window &&
    window.Appcues &&
    !trackingManager.isTrackerExists(TrackerNameTypes.APPCUES)
  ) {
    trackingManager.register(
      new HotjarTrackerService(window.Appcues as IAppCues),
    )
  }
}
