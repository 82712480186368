import { USER_KEY } from '../user.key'

export const userQa = {
  userTableRow_ctaActions: 'user-table-row_cta-actions',
  userTableRow_ctaEdit: 'user-table-row_cta-edit',
  userTableRow_ctaDelete: 'user-table-row_cta-delete',
  userTableRow_ctaChangeLocked: 'user-table-row_cta-change-locked',
  userTableRow_ctaResetPassword: 'user-table-row_cta-reset-password',
  userTableRow_cellName: 'user-table-row_cell-name',
  userTableRow_cellEmail: 'user-table-row_cell-email',
  userTableRow_cellLocked: 'user-table-row_cell-locked',
  userTableRow_role: 'user-table-row_role',
  userTableRow_cellMfa: 'user-table-row_cell-mfa',
  userTableRow_cellCreatedAtDate: 'user-table-row_cell-created-at-date',
  userTableRow_cellCreatedAtTime: 'user-table-row_cell-created-at-time',
  userTableRow_cellLoggedAtDate: 'user-table-row_cell-logged-at-date',
  userTableRow_cellLoggedAtTime: 'user-table-row_cell-logged-at-time',

  userTableHeader_sortByName: 'user-table-row_sort-by-name',
  userTableHeader_sortByLoggedDate: 'user-table-row_sort-by-logged-date',
  userTableHeader_sortByCreatedDate: 'user-table-row_sort-by-created-date',
} satisfies Record<string, `${typeof USER_KEY}-${string}`>
