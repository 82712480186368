import React, { useState } from 'react'

import { IconButton, MenuItem } from '@mui/material'

import { FrankieIcon, FrankiePopover } from 'frankify/src'

import { RegistryDocumentStatusTypes } from 'entities/applicant'

import { useI18n } from 'shared/i18n'
import { Noop } from 'shared/typescript'

import { APPLICANT_SUPPORTING_DOCUMENTS_KEY } from '../../applicant-supporting-documents.key'
import { applicantSupportingDocumentsEn } from '../../locale/applicant-supporting-documents.en'
import {
  BusinessDocumentCatalogRowType,
  registryDocumentErrorStatuses,
  statusProps,
} from '../../model/applicant-supporting-documents.model'

type CommonRowData = {
  title: string
  id: string
  status?: RegistryDocumentStatusTypes
}

type Args<T> = { row: T }

export function OrganisationNameTableCell<T extends CommonRowData>({
  row,
}: Args<T>) {
  return (
    <div className="cursor-pointer">
      <div className="font-semibold">{row.title}</div>
    </div>
  )
}

export function OrganisationDocumentTableStatusCell<T extends CommonRowData>({
  row,
}: Args<T>) {
  const t = useI18n(APPLICANT_SUPPORTING_DOCUMENTS_KEY, {
    keys: applicantSupportingDocumentsEn,
  })

  if (!row.status) return null

  const { className, translation, iconName } = statusProps[row.status]
  return (
    <div
      className={`${className} py-1 px-3 flex gap-2 items-center grow-0 rounded-md font-semibold`}
    >
      {iconName && (
        <FrankieIcon name={iconName} className={className} size="xs" />
      )}
      {t(translation)}
    </div>
  )
}

export function OrganisationDocumentNoRowsOverlay() {
  const t = useI18n(APPLICANT_SUPPORTING_DOCUMENTS_KEY, {
    keys: applicantSupportingDocumentsEn,
  })
  return (
    <div className="p-6 text-center border-b border-tertiary-grey-200">
      {t('documentCatalog.noRows')}
    </div>
  )
}

export function OrganisationPurchasedDocumentNoRowsOverlay() {
  const t = useI18n(APPLICANT_SUPPORTING_DOCUMENTS_KEY, {
    keys: applicantSupportingDocumentsEn,
  })
  return (
    <div className="!h-[56px] w-full px-4 py-3 flex justify-start items-center border-b border-tertiary-grey-200">
      {t('purchasedDocuments.noDocuments')}
    </div>
  )
}

type OrganisationDocumentTableActionMenuProps<T> = Args<T> & {
  handleActionClick: (row: T) => void
}
export function OrganisationDocumentTableActionMenu<
  T extends BusinessDocumentCatalogRowType,
>({ row, handleActionClick }: OrganisationDocumentTableActionMenuProps<T>) {
  const t = useI18n([APPLICANT_SUPPORTING_DOCUMENTS_KEY], {
    keys: applicantSupportingDocumentsEn,
  })

  const [open, setOpen] = useState(false)

  const handleAction = () => {
    setOpen(false)
    handleActionClick(row)
  }

  const handleClick =
    (cb: Noop) =>
    (e: React.MouseEvent<HTMLButtonElement | HTMLLIElement, MouseEvent>) => {
      e.stopPropagation()
      e.preventDefault()
      cb()
    }

  if (!(row.status && registryDocumentErrorStatuses.includes(row.status))) {
    return null
  }

  return (
    <div className="">
      <FrankiePopover
        open={open}
        onOpenChange={setOpen}
        popoverRest={{ placement: 'bottom-end' }}
        trigger={
          <IconButton
            className={`h-[30px] w-[30px] pb-4 rounded-sm px-2 text-tertiary-grey-400 ${
              open ? 'bg-neutral-30' : ''
            }`}
            onClick={handleClick(() => setOpen(prev => !prev))}
          >
            ...
          </IconButton>
        }
      >
        <div className="bg-mono-white shadow-md rounded-sm py-1">
          <MenuItem
            className="py-2 text-sm text-secondary-900 hover:text-primary-600 hover:bg-primary-50"
            onClick={handleClick(() => handleAction())}
          >
            {t('documentCatalog.action.retry')}
          </MenuItem>
        </div>
      </FrankiePopover>
    </div>
  )
}
