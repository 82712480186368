import React, { useEffect } from 'react'

import { FrankieButton } from 'frankify/src'

import { useI18n } from 'shared/i18n'
import { notification } from 'shared/notification'

import { MFA_KEY } from '../../mfa.key'
import { mfaQa } from '../../qa/mfa.qa'

type Props = {
  onClose: () => void
  children?: React.ReactNode
  className: string
}

export function MfaIsEnabled({ onClose, children, className }: Props) {
  const t = useI18n(MFA_KEY)

  // Send notification on unmount
  useEffect(
    () => () => {
      notification.success(t('mfaIsEnabled.notification'))
    },
    [t],
  )

  const handleClose = () => {
    onClose()
  }

  return (
    <div className={className}>
      <div>
        <div
          data-qa={mfaQa.mfaEnabled_header}
          className="text-2xl font-bold mb-4 text-tertiary-grey-800"
        >
          {t('mfaIsEnabled.heading')}
        </div>
        <div className="text-sm text-tertiary-grey-700">
          {t('mfaIsEnabled.subheading')}
        </div>
        {children}
      </div>
      <div className="mt-6 flex flex-initial justify-end items-center gap-4">
        <FrankieButton
          size="sm"
          onClick={handleClose}
          testId={{ button: mfaQa.mfaEnabled_ctaConfirm }}
        >
          {t('mfaIsEnabled.cta.done')}
        </FrankieButton>
      </div>
    </div>
  )
}
