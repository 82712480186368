import { useMutation } from '@tanstack/react-query'

import { organisationApi } from '../../api/organisation.api'

type Args = {
  organisationToken: string
}

export const useCreateProfileROW = () =>
  useMutation({
    mutationFn: ({ organisationToken }: Args) =>
      organisationApi.createProfileROW(organisationToken),
  })
