import React, { useEffect } from 'react'

import { useForm } from 'react-hook-form'

import { FrankieButton, FrankieDivider, FrankieLoader } from 'frankify/src'

import {
  ConfirmationFormField,
  SelectFormField,
  TextAreaFormField,
} from 'shared/form'
import { useI18n } from 'shared/i18n'
import { notification } from 'shared/notification'
import { Noop } from 'shared/typescript'

import { APPLICANT_TRANSACTIONS_KEY } from '../../applicant-transactions.key'
import { applicantTransactionsEn } from '../../locale/applicant-transactions.en'
import {
  transactionBulkActionOptions,
  transactionRecordMap,
} from '../../model/applicant-transactions.model'
import { applicantTransactionsDataGridBulkActionQa } from '../../qa/applicant-transactions.qa'
import { useApplicantTransactionsChangeStatusBulk } from '../../state/applicant-transactions-change-status/applicant-transactions-change-status.mutation'
import { ApplicantTransactionsQueryArgs } from '../../state/applicant-transactions-query/applicant-transactions.query'

type Props = {
  className?: string
  onClose: Noop
  selectedTransactionResultIds: string[]
} & ApplicantTransactionsQueryArgs

type BulkActionData = {
  status: string
  comment: string
  confirmations: boolean
}

export function ApplicantTransactionsDataGridBulkAction({
  className = '',
  onClose,
  applicantId,
  transactionType,
  needsAttention = false,
  selectedTransactionResultIds,
}: Props) {
  const t = useI18n([APPLICANT_TRANSACTIONS_KEY], {
    keys: applicantTransactionsEn,
  })

  const {
    control,
    formState: { isValid },
    handleSubmit,
  } = useForm<BulkActionData>()

  const { mutate, isSuccess, isLoading, data } =
    useApplicantTransactionsChangeStatusBulk({
      applicantId,
      needsAttention,
      transactionType,
    })

  /**
   * Close the modal when the bulk action is successful
   */
  useEffect(() => {
    if (isSuccess) {
      const { failed, total, successful } = data

      let nType: 'error' | 'info' | 'success' = 'success'
      if (failed.count === total) {
        nType = 'error'
      } else if (failed.count > 0) {
        nType = 'info'
      }

      notification[nType](
        <div>
          <div className="font-semibold">
            {t(`bulkActionMessage.${nType}.text`)}
          </div>
          <div>
            {t(`bulkActionMessage.${nType}.subtext`, {
              success: successful.count,
              total,
              fail: failed.count,
            })}
          </div>
        </div>,
        { autoClose: 5000 },
      )

      onClose()
    }
  }, [data, isSuccess, onClose, t])

  const onsubmit = (data: BulkActionData) => {
    mutate({
      transactionResultIds: selectedTransactionResultIds,
      newStatus: data.status,
      comment: data.comment,
    })
  }

  return (
    <div className={`p-4 w-[420px]  ${className}`}>
      <div className="text-lg text-mono-black font-bold">{t('bulkAction')}</div>

      <FrankieLoader
        className="text-tertiary-grey-800 text-md font-semibold"
        loading={isLoading}
        label={t('updatingTxn', {
          txnType: t(transactionRecordMap[transactionType[0]].tKey),
        })}
      >
        <form
          onSubmit={handleSubmit(onsubmit)}
          className="flex flex-col gap-5 mt-5 text-secondary-900 font-normal text-sm"
        >
          <div className="text-md font-bold tertiary-grey-800">
            {t('status')}
          </div>

          <SelectFormField
            options={transactionBulkActionOptions.map(({ tKey, value }) => ({
              label: t(tKey),
              value,
            }))}
            control={control}
            name="status"
            rules={{ required: true }}
            placeholder={t('selectStatus')}
            testId={{
              input: applicantTransactionsDataGridBulkActionQa.selectField,
            }}
          />

          <FrankieDivider />

          <div className="text-md font-bold tertiary-grey-800">
            {t('comment')}
          </div>

          <TextAreaFormField
            name="comment"
            placeholder={t('enterYourComment')}
            control={control}
            rules={{ required: true }}
          />

          <FrankieDivider />

          <div className="text-md font-bold tertiary-grey-800">
            {t('confirmation')}
          </div>

          <div className="flex flex-col gap-4">
            <span>{t('bulkActionConfirmationNote')}</span>

            <ConfirmationFormField
              control={control}
              name="confirmations"
              label={t('acknowledge')}
              testId={{
                input:
                  applicantTransactionsDataGridBulkActionQa.confirmationField,
              }}
            />
          </div>

          <FrankieDivider />

          <div className="flex gap-3 justify-end">
            <FrankieButton intent="darkOutline" onClick={onClose}>
              {t('cancel')}
            </FrankieButton>
            <FrankieButton disabled={!isValid} type="submit">
              {t('confirm')}
            </FrankieButton>
          </div>
        </form>
      </FrankieLoader>
    </div>
  )
}
