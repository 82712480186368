export interface IAppCues {
  page: () => void
  identify: (
    id: string,
    params: {
      customerId: string
      organizationId: number
      userRoles?: string
      userPermissions?: string
      userFeatures?: string
    },
  ) => void
  track: (event: string) => void
}

export function appcuesInit(
  id: string,
  data: {
    organizationId: number
    roles?: string[]
    permissions?: string[]
    features?: string[]
    customerId: string
  },
) {
  const { organizationId, permissions, features, roles, customerId } = data

  if (window.Appcues !== undefined && window.Appcues !== null) {
    window.Appcues.identify(id, {
      organizationId,
      customerId,
      user_id: id,
      userRoles: roles?.join(':'),
      userPermissions: permissions?.join(':'),
      userFeatures: features?.join(':'),
    })
  }
}
