import { useCallback, useMemo, useState } from 'react'

import { useInfiniteQuery } from '@tanstack/react-query'

import { ErrorResponse } from 'shared/http'

import { BlocklistFilter, blocklistApi } from '../../api/blocklist.api'
import { BlocklistData, BlocklistResponse } from '../../model/blocklist.model'

export const BlocklistEntityQueryKey = 'blocklist-entity-query'

export type BlocklistQueryFilter = Record<keyof BlocklistFilter, string[]>
export type BlockLoadingType = 'applyingFilter' | 'loading' | 'loadingMore'

type Args = { filters?: Partial<BlocklistQueryFilter> }

export const useBlocklistQuery = ({ filters }: Args) => {
  const [filter, setFilter] = useState<BlocklistQueryFilter>({
    ...filters,
    reasonFilter: [],
    attributeFilter: [],
  })

  const isFilterApplied = useMemo(
    () => filter.reasonFilter.length > 0 || filter.attributeFilter.length > 0,
    [filter],
  )

  const query = useInfiniteQuery<BlocklistResponse, ErrorResponse>({
    enabled: true,
    staleTime: 0,
    queryKey: [BlocklistEntityQueryKey, filter],
    getNextPageParam: lastPage => lastPage.nextPageMarker,
    queryFn: async ({ pageParam }: { pageParam?: number }) => {
      const { data } = await blocklistApi.getBlocklist(
        {
          reasonFilter: filter.reasonFilter.join(','),
          attributeFilter: filter.attributeFilter.join(','),
        },
        pageParam,
      )

      return data
    },
    cacheTime: 0,
  })

  const [loadingType, setLoadingType] = useState<BlockLoadingType>(
    query.data?.pages ? 'loadingMore' : 'loading',
  )

  const setQueryFilter = useCallback(
    (filterArgs: Partial<BlocklistQueryFilter>) => {
      setFilter(prev => ({
        ...prev,
        ...filterArgs,
      }))
      setLoadingType('applyingFilter')
    },
    [],
  )

  const records = useMemo(() => {
    const { data } = query
    if (!data?.pages) return []
    return data.pages.reduce(
      (value, curr) => [...value, ...curr.records],
      [] as BlocklistData[],
    )
  }, [query])

  const fetchNextData = useCallback(() => {
    if (query.hasNextPage && !query.isFetchingNextPage) {
      setLoadingType('loadingMore')
      void query.fetchNextPage()
    }
  }, [query])

  return {
    ...query,
    isFilterApplied,
    records,
    setQueryFilter,
    loadingType,
    fetchNextData,
  }
}
