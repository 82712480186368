import { useMutation } from '@tanstack/react-query'

import { applicantBiometricsApi } from '../../api/applicant-biometrics-ocr.api'
import { IdvStatusChangePayload } from '../../model/applicant-biometrics-ocr.model'
import { useApplicantBiometricReRunCheck } from '../applicant-biometric-re-run-check/applicant-biometric-re-run-check'

type Args = {
  applicantId: string
}

type MutateArgs = IdvStatusChangePayload

export function useApplicantBiometricsChangeIdvStatus({ applicantId }: Args) {
  const { mutate, isLoading } = useApplicantBiometricReRunCheck({ applicantId })

  const mutation = useMutation({
    mutationFn: (data: MutateArgs) =>
      applicantBiometricsApi.changeIdvStatus(applicantId, data),
    onSuccess: () => {
      mutate()
    },
  })

  return { ...mutation, isLoading: mutation.isLoading || isLoading }
}
