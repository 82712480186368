import React, { forwardRef, useEffect, useMemo, useState } from 'react'

import { Box, IconButton } from '@mui/material'
import {
  GridColumnHeaderParams,
  GridSortModel,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridColDef,
  GridRenderCellParams,
} from '@mui/x-data-grid-pro'
import { useForm } from 'react-hook-form'

import {
  FrankieTableHeaderCell,
  FrankieButton,
  FrankieIcon,
  FrankieIconName,
  FrankieLoader,
  FrankiePopover,
} from 'frankify/src'

import { ApplicantIssueActionBadge } from 'entities/applicant'

import { DateFormatTypes, formatDate } from 'shared/date-time'
import { SelectFormField, TextAreaFormField } from 'shared/form'
import { WithProps } from 'shared/hoc'
import { useI18n } from 'shared/i18n'
import { ExclusiveProperty, Noop } from 'shared/typescript'

import { APPLICANT_TRANSACTIONS_KEY } from '../../applicant-transactions.key'
import { applicantTransactionsEn } from '../../locale/applicant-transactions.en'
import {
  ApplicantTransactionIssueTypeMapping,
  transactionDataGridCellWidth,
  transactionReadableContent,
} from '../../model/applicant-transactions-data-grid-helper.model'
import {
  ApplicantTransactionsProperties,
  ApplicantTransactionsRecord,
  TransactionTypes,
  transactionRecordMap,
  transactionStatusOptions,
} from '../../model/applicant-transactions.model'
import { applicantTransactionsDataGridHelperQa } from '../../qa/applicant-transactions.qa'
import {
  useApplicantTransactionsChangeStatus,
  ChangeStatusData,
} from '../../state/applicant-transactions-change-status/applicant-transactions-change-status.mutation'
import { ApplicantTransactionsQueryArgs } from '../../state/applicant-transactions-query/applicant-transactions.query'

export type TransactionsDataGridCellProps =
  GridRenderCellParams<ApplicantTransactionsRecord>

/**
 * Status cell for the transaction data grid
 */
export function TransactionsDataGridStatusCell({
  row,
  field,
}: TransactionsDataGridCellProps) {
  const fieldName = field as ApplicantTransactionsProperties
  return (
    <ApplicantIssueActionBadge
      masterLabel={transactionReadableContent(row[fieldName])}
      type={ApplicantTransactionIssueTypeMapping[row[fieldName]] ?? ''}
      hideBg={fieldName === 'riskLevel'}
      className={`!font-semibold !py-1 !flex-[0] ${
        fieldName === 'riskLevel' ? '!justify-start !px-0' : 'text-xs !px-3'
      }`}
    />
  )
}

/**
 * Default cell for the transaction data grid
 * Converts the transaction data to readable content
 */
export function TransactionsDataGridReadableCell({
  row,
  field,
}: TransactionsDataGridCellProps) {
  const fieldName = field as ApplicantTransactionsProperties
  return (
    <span data-qa={applicantTransactionsDataGridHelperQa.readableCell}>
      {transactionReadableContent(row[fieldName])}
    </span>
  )
}

/**
 * Timestamp cell for the transaction data grid
 */
export function TransactionsDataGridTimestampCell({
  row,
}: TransactionsDataGridCellProps) {
  const timeStamp = row.transactionTimestamp
  return (
    <div data-qa={applicantTransactionsDataGridHelperQa.timeStampCell}>
      <div>{formatDate(timeStamp, DateFormatTypes.ShortDate)}</div>
      <div className="text-tertiary-grey-500 text-xs">
        {formatDate(timeStamp, DateFormatTypes.Time)}
      </div>
    </div>
  )
}

type TransactionDataGridActionBtnProps = {
  iconName: FrankieIconName
  isActive?: boolean
} & ExclusiveProperty<{ onClick: Noop; link: string }>

/**
 * Action Button for the transaction data grid
 */
const TransactionDataGridActionBtn = forwardRef(
  (
    {
      onClick,
      link,
      iconName,
      isActive = false,
    }: TransactionDataGridActionBtnProps,
    ref: React.ForwardedRef<HTMLDivElement>,
  ) => (
    <Box
      component={link ? 'a' : 'div'}
      href={link}
      target={link ? '_blank' : undefined}
      ref={ref}
      className={`relative h-[35px] w-[35px] border border-solid bolder-tertiary-grey-400 rounded-sm p-2 ${
        isActive ? 'bg-neutral-30' : ''
      }`}
    >
      <IconButton
        className="absolute inset-0 z-[2] rounded-sm"
        onClick={onClick}
      />
      <FrankieIcon
        className="absolute flex items-center justify-center inset-0 z-[1]"
        name={iconName}
        size="xs"
      />
    </Box>
  ),
)

/**
 * Action cell for the transaction data grid
 * Open source link and Change status/comment
 */
export function TransactionsDataGridActionCell({
  row,
  ...queryArgs
}: TransactionsDataGridCellProps & ApplicantTransactionsQueryArgs) {
  const [open, setOpen] = useState(false)

  const {
    control,
    formState: { isValid },
    handleSubmit,
    reset,
  } = useForm<ChangeStatusData>({
    defaultValues: {
      transactionId: row.transactionId,
      transactionResultId: row.id,
    },
  })

  const t = useI18n([APPLICANT_TRANSACTIONS_KEY], {
    keys: applicantTransactionsEn,
  })

  const { mutate, isLoading, isSuccess } =
    useApplicantTransactionsChangeStatus(queryArgs)

  useEffect(() => {
    if (isSuccess) {
      setOpen(false)
    }
  }, [isSuccess])

  useEffect(() => {
    if (!open && !isLoading) {
      reset({
        transactionId: row.transactionId,
        transactionResultId: row.id,
      })
    }
  }, [open, isLoading, reset, row])

  const dashboardURL = useMemo(
    () => decodeURIComponent(row.sourceLink || ''),
    [row.sourceLink],
  )

  return (
    <div
      className="flex items-center gap-2 text-tertiary-grey-400"
      data-qa={applicantTransactionsDataGridHelperQa.actionCell}
    >
      {dashboardURL && (
        <TransactionDataGridActionBtn
          iconName="mdiInformationOutline"
          link={dashboardURL}
        />
      )}

      <FrankiePopover
        onOpenChange={setOpen}
        open={open}
        popoverRest={{ placement: 'top-start' }}
        showOverlay
        trigger={
          <TransactionDataGridActionBtn
            iconName="mdiCommentOutline"
            onClick={() => setOpen(prev => !prev)}
            isActive={open}
          />
        }
      >
        <FrankieLoader label={t('updating')} loading={isLoading}>
          <form
            onSubmit={handleSubmit(data => mutate(data))}
            className="flex flex-col gap-4 p-4 bg-mono-white shadow-lg rounded-sm"
          >
            <div className="flex items-center gap-2">
              <span className="whitespace-nowrap text-tertiary-grey-800 font-medium">
                {t('changeStatus')}
              </span>
              <SelectFormField
                options={transactionStatusOptions.map(({ tKey, value }) => ({
                  label: t(tKey),
                  value,
                }))}
                control={control}
                name="newStatus"
                rules={{ required: true }}
              />
            </div>

            <TextAreaFormField
              label={`${t('comment')}*`}
              name="comment"
              placeholder={t('typeYourComment')}
              control={control}
              rules={{ required: true }}
            />
            <div className="flex gap-3 justify-end">
              <FrankieButton
                noStyles
                className="text-tertiary-grey-900 font-medium"
                size="sm"
                onClick={() => setOpen(false)}
              >
                {t('cancel')}
              </FrankieButton>
              <FrankieButton
                disabled={!isValid}
                className={isValid ? '!bg-mono-100 !outline-mono-50' : ''}
                size="sm"
                type="submit"
              >
                {t('save')}
              </FrankieButton>
            </div>
          </form>
        </FrankieLoader>
      </FrankiePopover>
    </div>
  )
}

type DataGridHeaderProps =
  GridColumnHeaderParams<ApplicantTransactionsRecord> & {
    sortModel: GridSortModel
  }

export function TransactionsDataGridHeader({
  colDef,
  sortModel,
}: DataGridHeaderProps) {
  const fieldName = colDef.field

  const currentSortValue = useMemo(
    () => sortModel.find(item => item.field === fieldName)?.sort || 'off',
    [sortModel, fieldName],
  )

  if (!colDef.sortable) {
    return colDef.headerName
  }

  return (
    <FrankieTableHeaderCell
      className="!px-0 flex-row-reverse"
      sortValue={currentSortValue}
    >
      {colDef.headerName}
    </FrankieTableHeaderCell>
  )
}

type TransactionsDataGridSlotsProps = {
  transactionType: TransactionTypes[]
}

/**
 * Loading overlay for the transaction data grid
 */
export function TransactionsDataGridLoadingOverlay({
  transactionType,
}: TransactionsDataGridSlotsProps) {
  const t = useI18n([APPLICANT_TRANSACTIONS_KEY], {
    keys: applicantTransactionsEn,
  })

  return (
    <div className="flex align-center justify-center flex-col w-full h-full bg-mono-white opacity-80">
      <FrankieLoader
        label={t('loadingTxn', {
          txnType: t(transactionRecordMap[transactionType[0]].tKey),
        })}
        loading
        className="text-tertiary-grey-800 text-sm font-semibold min-h-[250px]"
        size="sm"
      />
    </div>
  )
}

/**
 * No result message for the transaction data grid
 */
export function TransactionsDataGridNoResult({
  transactionType,
  isSearch,
}: TransactionsDataGridSlotsProps & { isSearch: boolean }) {
  const t = useI18n([APPLICANT_TRANSACTIONS_KEY], {
    keys: applicantTransactionsEn,
  })

  const message = useMemo(() => {
    if (isSearch) {
      // to show different message for search - considered as bug (as per the requirement)
      // return t('emptyMessage.noSearchResult')
    }

    if (transactionType.length === 1) {
      if (transactionType.includes(TransactionTypes.Aml)) {
        return t('emptyMessage.aml')
      }

      if (transactionType.includes(TransactionTypes.Fraud)) {
        return t('emptyMessage.fraud')
      }

      if (transactionType.includes(TransactionTypes.Customer)) {
        return t('emptyMessage.customer')
      }
    }

    return t('emptyMessage.all')
  }, [t, transactionType, isSearch])

  return (
    <span className="mt-[40px] inline-block w-full text-center font-semibold leading-6 text-tertiary-grey-500">
      {message}
    </span>
  )
}

export type TransactionsDataGridColumn =
  (GridColDef<ApplicantTransactionsRecord> & {
    field: ApplicantTransactionsProperties | 'actions'
    filedType: TransactionTypes[]
    headerClassName?: string
    cellClassName?: string
    fullViewFieldType?: TransactionTypes[]
  })[]

type UseTransactionDataGridColumnsArgs = ApplicantTransactionsQueryArgs & {
  transactionType: TransactionTypes[]
  hideBulkAction?: boolean
  fullView: boolean
}

/**
 * To get the columns configuration for the transaction data grid
 */
export const useTransactionDataGridColumns = ({
  transactionType,
  applicantId,
  needsAttention,
  hideBulkAction = false,
  fullView,
}: UseTransactionDataGridColumnsArgs) => {
  const t = useI18n([APPLICANT_TRANSACTIONS_KEY], {
    keys: applicantTransactionsEn,
  })

  const result = useMemo(() => {
    const isTransactionTypeCustomer = transactionType.includes(
      TransactionTypes.Customer,
    )

    const columns: TransactionsDataGridColumn = [
      {
        field: 'transactionId',
        headerName: t('field.transactionId'),
        renderCell: TransactionsDataGridReadableCell,
        filedType: [TransactionTypes.Aml, TransactionTypes.Fraud],
        cellClassName: 'lowercase',
        ...transactionDataGridCellWidth(165),
      },
      {
        field: 'sessionKey',
        headerName: t('field.sessionKey'),
        renderCell: TransactionsDataGridReadableCell,
        filedType: [TransactionTypes.Customer],
        cellClassName: 'lowercase',
        ...transactionDataGridCellWidth(165),
      },
      {
        field: 'status',
        headerName: t('field.status'),
        renderCell: TransactionsDataGridStatusCell,
        filedType: [
          TransactionTypes.Aml,
          TransactionTypes.Fraud,
          TransactionTypes.Customer,
        ],
        ...transactionDataGridCellWidth(140),
      },
      {
        field: 'issueType',
        headerName: t('field.issueType'),
        renderCell: TransactionsDataGridReadableCell,
        filedType: [], // No need to show for AML and Fraud in default view
        fullViewFieldType: [TransactionTypes.Aml, TransactionTypes.Fraud],
      },
      {
        field: 'subtype', // issueType is subType for customer grid
        headerName: t('field.issueType'),
        renderCell: TransactionsDataGridReadableCell,
        filedType: [TransactionTypes.Customer],
      },
      {
        field: 'riskLevel',
        headerName: t('field.riskLevel'),
        renderCell: TransactionsDataGridStatusCell,
        filedType: [
          TransactionTypes.Aml,
          TransactionTypes.Fraud,
          TransactionTypes.Customer,
        ],
      },
      {
        field: 'deviceId',
        headerName: t('field.deviceId'),
        renderCell: TransactionsDataGridReadableCell,
        filedType: [TransactionTypes.Customer],
      },
      {
        field: 'activityType',
        headerName: isTransactionTypeCustomer
          ? t('field.activityType')
          : t('field.type'),
        renderCell: TransactionsDataGridReadableCell,
        filedType: [
          TransactionTypes.Aml,
          TransactionTypes.Fraud,
          TransactionTypes.Customer,
        ],
      },
      {
        field: 'paymentMethod',
        headerName: t('field.paymentMethod'),
        renderCell: TransactionsDataGridReadableCell,
        filedType: [TransactionTypes.Aml, TransactionTypes.Fraud],
      },
      {
        field: 'amount',
        headerName: t('field.amount'),
        filedType: [TransactionTypes.Aml, TransactionTypes.Fraud],
        sortable: false,
      },
      {
        field: 'transactionTimestamp',
        headerName: t('field.transactionTimestamp'),
        sortComparator: (a: string, b: string) => b.localeCompare(a),
        renderCell: TransactionsDataGridTimestampCell,
        filedType: [
          TransactionTypes.Aml,
          TransactionTypes.Fraud,
          TransactionTypes.Customer,
        ],
      },
      {
        field: 'actions',
        headerName: t('field.action'),
        renderCell: WithProps(TransactionsDataGridActionCell, {
          applicantId,
          transactionType,
          needsAttention,
        }),
        sortable: false,
        ...transactionDataGridCellWidth(110),
        filedType: [
          TransactionTypes.Aml,
          TransactionTypes.Fraud,
          TransactionTypes.Customer,
        ],
      },
    ]

    // Adding bulk selection column at last
    if (!hideBulkAction) {
      columns.push({
        ...GRID_CHECKBOX_SELECTION_COL_DEF,
        ...transactionDataGridCellWidth(100),
        cellClassName:
          '[&_.Mui-checked.MuiCheckbox-root]:!text-primary-800 [&_.MuiCheckbox-root]:!text-tertiary-grey-300',
        headerClassName:
          '[&_.Mui-checked.MuiCheckbox-root]:!text-primary-800 [&_.MuiCheckbox-root]:!text-tertiary-grey-300',
        filedType: [
          TransactionTypes.Aml,
          TransactionTypes.Fraud,
          TransactionTypes.Customer,
        ],
      } as TransactionsDataGridColumn[number])
    }

    /**
     * Using first transaction type to filter the columns - as all the columns are same for common the transaction types
     * [Aml and Fraud goes together]
     * [Customer is separate]
     * Filter logic can be updated if there are any changes in the future
     */
    return columns.filter(
      ({ filedType, fullViewFieldType }) =>
        filedType.includes(transactionType[0]) ||
        (fullView && fullViewFieldType?.includes(transactionType[0])),
    )
  }, [applicantId, needsAttention, t, transactionType, hideBulkAction])

  return result
}
