import { useMemo } from 'react'

import { TrustTypes } from 'features/applicant-supporting-documents/model/applicant-supporting-documents.model'
import { useTrustDeedAnalysisResultQuery } from 'features/applicant-supporting-documents/state/trust-deeds-query/trust-deeds-query'

import {
  ApplicantId,
  Document,
  useApplicantDataQuery,
} from 'entities/applicant'
import { TrustDeedTypes } from 'entities/applicant/model/applicant-trust-analyser.model'

type Args = {
  applicantId?: ApplicantId
}

export const useTrustAnalyser = ({ applicantId }: Args) => {
  const {
    data: applicantData,
    isLoading,
    isRefetching,
    isFetching,
    error,
  } = useApplicantDataQuery({ applicantId })

  const result = useMemo(() => {
    const getLatestAnalysisResult = (): Document | null => {
      if (!applicantData?.trustDeedsDocuments) {
        return null
      }
      const analysisResult = applicantData.trustDeedsDocuments.filter(
        trustDeed =>
          trustDeed.extraData?.['trust.document.scan_status'] ===
            TrustDeedTypes.COMPLETE ||
          trustDeed.extraData?.['trust.document.scan_status'] ===
            TrustDeedTypes.FAILED,
      )
      if (analysisResult.length < 1) {
        return null
      }
      const sortByLatestDate = analysisResult.sort((a, b) => {
        const latestDocScanA = a.scans.sort(
          (docA, docB) =>
            new Date(docB.scanCreated).getTime() -
            new Date(docA.scanCreated).getTime(),
        )[0]
        const latestDocScanB = b.scans.sort(
          (docA, docB) =>
            new Date(docB.scanCreated).getTime() -
            new Date(docA.scanCreated).getTime(),
        )[0]
        return (
          new Date(latestDocScanB.scanCreated).getTime() -
          new Date(latestDocScanA.scanCreated).getTime()
        )
      })
      return sortByLatestDate[0]
    }

    const latestTrustDeedAnalysis = getLatestAnalysisResult()

    return {
      latestTrustDeedAnalysis,
      latestTrustDeedAnalysisStatus:
        latestTrustDeedAnalysis?.extraData?.['trust.document.scan_status'],
    }
  }, [applicantData])

  const { data, isFetching: analysisResultIsFetching } =
    useTrustDeedAnalysisResultQuery({
      applicantId,
      documentId: result.latestTrustDeedAnalysis?.documentId,
      status: result.latestTrustDeedAnalysisStatus as TrustDeedTypes,
    })

  const { detected } = data?.documentInformation?.trust.type || {}

  return {
    ...result,
    isUnknownType: detected === TrustTypes.UNKNOWN,
    isLoading:
      isLoading || isRefetching || isFetching || analysisResultIsFetching,
    error,
  }
}
