import React, { useMemo } from 'react'

import { FrankieIcon } from 'frankify/src'

import {
  BLOCKLIST_TABLE_KEY,
  blocklistReasonData,
  blocklistTableEn,
} from 'features/blocklist-table'

import { ApplicantId } from 'entities/applicant'

import { SelectFormField, TextAreaFormField } from 'shared/form'
import { useI18n } from 'shared/i18n'
import { Noop } from 'shared/typescript'

import { APPLICANT_GENERAL_INFORMATION_KEY } from '../../../../applicant-general-information.key'
import { applicantGeneralInformationEn } from '../../../../locale/applicant-general-information.en'
import { addApplicantToBlockListQa } from '../../../../qa/applicant-general-information.qa'
import {
  AddToBlocklistData,
  useAddApplicantToBlocklistMutation,
} from '../../../../state/applicant-profile-state-change/applicant-profile-state-change'
import { ApplicantActionModalWrapper } from '../../applicant-action-modal-wrapper/applicant-action-modal-wrapper'

type Props = {
  applicantId: ApplicantId
  handleClose: Noop
  changeReason?: boolean
  reasonCode?: string
}

export function AddApplicantToBlockList({
  applicantId,
  handleClose,
  changeReason = false,
  reasonCode,
}: Props) {
  const t = useI18n([APPLICANT_GENERAL_INFORMATION_KEY], {
    keys: applicantGeneralInformationEn,
  })

  const tBlocklist = useI18n([BLOCKLIST_TABLE_KEY], {
    keys: blocklistTableEn,
  })

  const { mutate, isLoading, isSuccess } = useAddApplicantToBlocklistMutation({
    applicantId,
  })

  const blocklistOptions = useMemo(() => {
    const options = Object.entries(blocklistReasonData).map(
      ([value, item]) => ({
        label: tBlocklist(item.tKey),
        value,
      }),
    )

    return options
  }, [tBlocklist])

  return (
    <ApplicantActionModalWrapper<AddToBlocklistData>
      heading={
        changeReason
          ? t('action.heading.changeBlocklistReason')
          : t('action.heading.blocklist')
      }
      iconName="mdiCancel"
      isProgress={isLoading}
      isSuccess={isSuccess}
      onClose={handleClose}
      onSubmit={mutate}
      submitText={changeReason ? t('action.save') : t('action.addToBlocklist')}
      confirmation={
        changeReason
          ? undefined
          : {
              description: t('action.description.confirmAddToBlocklist'),
              confirmText: t('action.confirm.addToBlocklist'),
            }
      }
      defaultValues={{
        flag: 'true_positive',
        reasonCode,
        flagType: 'blacklist',
      }}
      renderForm={({ control }) => (
        <>
          {!changeReason && (
            <>
              <span className="font-bold">
                {t('action.description.profileWillBeAddedToBlocklist')}
              </span>

              <span>{t('action.description.willNotBeAbleToOnboard')}</span>

              <div className="flex items-center p-4 gap-2 bg-tertiary-grey-100 rounded-sm">
                <FrankieIcon
                  name="mdiInformation"
                  className="text-tertiary-grey-400"
                />
                <span>
                  {t('action.description.canRemoveFromBlocklistNote')}
                </span>
              </div>
            </>
          )}

          <SelectFormField
            className="max-w-[60%]"
            label={t('action.fields.reasonForBlocklist')}
            options={blocklistOptions}
            control={control}
            name="reasonCode"
            rules={{ required: true }}
            testId={{ input: addApplicantToBlockListQa.selectInput }}
          />

          <TextAreaFormField
            label={t('action.fields.comment')}
            placeholder={t('action.fields.commentPlaceholder')}
            control={control}
            name="comment"
            rules={{ required: true }}
            testId={{ input: addApplicantToBlockListQa.textareaInput }}
          />
        </>
      )}
    />
  )
}
