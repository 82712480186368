import { useMutation, useQueryClient } from '@tanstack/react-query'

import { NotificationSettingsConfigKey } from 'entities/user'

import { useI18n } from 'shared/i18n'
import { notification } from 'shared/notification'

import { useIntegrationQuery } from './slack-integration.query'
import { organisationSettingsApi } from '../../api/organisation-settings.api'
import { ORGANISATION_SETTINGS_KEY } from '../../organisation-settings.key'

export const useSlackDisintegrationMutation = () => {
  const { refetch } = useIntegrationQuery()
  const t = useI18n([ORGANISATION_SETTINGS_KEY])

  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async () => {
      const { data } = await organisationSettingsApi.deleteIntegrationSettings()
      return data
    },

    onSuccess: () => {
      void queryClient.invalidateQueries(NotificationSettingsConfigKey)
      void refetch()
      notification.success(t('integration.disconnect.success'))
    },
    onError: () => {
      notification.error(t('integration.disconnect.fail'))
    },
  })
}
