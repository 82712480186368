import { useCallback } from 'react'

import { navigateToUrl } from 'single-spa'

import {
  Vue2ReactMigrationFlagTypes,
  useHasFeatureFlag,
} from 'entities/session'

import { maintainOldNavBarPaths } from 'shared/release'

/**
 * @deprecated remove once migration is done (Vue to React)
 */
export function useApplicantSingleSpaOverviewPath() {
  const { showVerticalNavBar, hasReactOverviewEnabled } = useHasFeatureFlag({
    showVerticalNavBar: 'verticalNavBar',
    hasReactOverviewEnabled: [
      'vue2ReactMigration',
      Vue2ReactMigrationFlagTypes.ReactApplicantProfileGeneralInformation,
    ],
  })

  const navigateToOverview = useCallback(
    (
      entityId: string,
      oldNavKey: Extract<
        keyof typeof maintainOldNavBarPaths,
        'monitoring' | 'onboarding'
      > = 'monitoring',
    ) => {
      if (!showVerticalNavBar) {
        navigateToUrl(
          `${maintainOldNavBarPaths[oldNavKey]}/profile/${entityId}/general-information`,
        )
      }
      // if new nav -> redirect to applicant profile
      if (showVerticalNavBar) {
        navigateToUrl(
          `${hasReactOverviewEnabled ? '/new' : ''}${
            maintainOldNavBarPaths.applicants
          }/profile/${entityId}/general-information`,
        )
      }
    },
    [hasReactOverviewEnabled, showVerticalNavBar],
  )

  return { navigateToOverview }
}
