import React from 'react'

import {
  Controller,
  ControllerProps,
  ControllerRenderProps,
  FieldValues,
  Path,
} from 'react-hook-form'

import { FrankieTextField, IFrankieTextFieldProps } from 'frankify/src'

import { getError } from '../../model/error.model'

type Props<TFormValues extends FieldValues> = Omit<
  IFrankieTextFieldProps,
  keyof ControllerRenderProps
> &
  Omit<ControllerProps<TFormValues>, 'render'> & {
    maskedFormat?: 'MM-YYYY'
    placeholder?: string
    showErrorText?: boolean
    valueConverter?: (value: string) => string
    valueReverseConverter?: (value: string) => string
  }

export function MaskedTextField<TFormValues extends FieldValues>({
  name,
  control,
  defaultValue,
  rules,
  shouldUnregister,
  maskedFormat = 'MM-YYYY',
  placeholder,
  showErrorText = false,
  valueConverter,
  valueReverseConverter,
  ...textFieldProps
}: Props<TFormValues>) {
  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    field: ControllerRenderProps<TFormValues, Path<TFormValues>>,
  ) => {
    // replace non-digit character
    let inputValue: string = event.target.value.replace(/\D/g, '')

    switch (maskedFormat) {
      case 'MM-YYYY': {
        if (inputValue.length > 2) {
          inputValue = `${inputValue.substring(0, 2)}-${inputValue.substring(
            2,
          )}`
        }
        break
      }
      default:
        break
    }

    field.onChange(
      valueReverseConverter ? valueReverseConverter(inputValue) : inputValue,
    )
  }
  return (
    <Controller
      render={({ field, formState: { errors }, fieldState: { error } }) => (
        <FrankieTextField
          {...textFieldProps}
          {...field}
          value={valueConverter ? valueConverter(field.value) : field.value}
          errorText={showErrorText ? error?.message : undefined}
          error={!!getError(name, errors)}
          placeholder={placeholder ?? maskedFormat}
          onChange={e => handleInputChange(e, field)}
          maxLength={maskedFormat.length}
        />
      )}
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      shouldUnregister={shouldUnregister}
    />
  )
}
