import React from 'react'

import { FrankieButton, FrankieIcon } from 'frankify/src'

import { useI18n } from 'shared/i18n'
import { notification } from 'shared/notification'

import { MFA_KEY } from '../../mfa.key'
import { useDisableMfaMutation } from '../../mutation/disable-mfa.mutation'
import { mfaQa } from '../../qa/mfa.qa'

type Props = {
  onCancel: () => void
}

export function DisableMfaConfirm({ onCancel }: Props) {
  const t = useI18n([MFA_KEY])

  const { mutateAsync: disableMfa, isLoading } = useDisableMfaMutation()

  const handleDisableMfaConfirm = async () => {
    await disableMfa()
    notification.success(t('disableMfaConfirm.notification'))
    onCancel()
  }

  return (
    <div className="flex flex-initial flex-col items-center">
      <FrankieIcon
        size="44px"
        className="mb-4 text-tertiary-red-700"
        name="mdiAlertCircleOutline"
      />
      <div
        className="mb-2 text-2xl font-bold leading-8 text-tertiary-grey-800"
        data-qa={mfaQa.disableMfaConfirm_header}
      >
        {t('disableMfaConfirm.heading')}
      </div>
      <div className="mb-6 text-center text-tertiary-grey-700">
        {t('disableMfaConfirm.subheading')}
      </div>
      <div className="flex flex-initial justify-center items-center gap-4">
        <FrankieButton
          size="sm"
          intent="darkOutline"
          testId={{ button: mfaQa.disableMfaConfirm_ctaCancel }}
          onClick={onCancel}
        >
          {t('disableMfaConfirm.cta.cancel')}
        </FrankieButton>
        <FrankieButton
          size="sm"
          intent="danger"
          testId={{ button: mfaQa.disableMfaConfirm_ctaConfirm }}
          onClick={handleDisableMfaConfirm}
          disabled={isLoading}
        >
          {t('disableMfaConfirm.cta.confirm')}
        </FrankieButton>
      </div>
    </div>
  )
}
