import { useMutation } from '@tanstack/react-query'

import { individualProfileApi } from '../api/individual-profile.api'
import { IndividualProfilePayloadTypes } from '../model/individual-profile-payload.model'

type MutateArgs = {
  saveOnly?: boolean
  profile: IndividualProfilePayloadTypes
}

type Args = {
  applicantId: string
}

export const useUpdateIndividualProfile = ({ applicantId }: Args) =>
  useMutation({
    mutationFn: ({ profile, saveOnly = false }: MutateArgs) =>
      individualProfileApi.updateIndividualProfile(
        profile,
        applicantId,
        saveOnly,
      ),
  })
