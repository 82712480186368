/* eslint-disable complexity */
import React, { useEffect, useMemo } from 'react'

import { Controller, UseFormReturn, useFieldArray } from 'react-hook-form'
import { Trans } from 'react-i18next'

import { FrankieButton, FrankieDivider, FrankieIcon } from 'frankify/src'

import { useSupportingDocumentDelete } from 'features/applicant-supporting-documents'

import { countryList } from 'entities/country'
import { DocumentIdTypes, DocumentUpload } from 'entities/document'

import { SelectFormField, ToggleSwitchFormField, getError } from 'shared/form'
import { I18nKeys, useI18n } from 'shared/i18n'
import { useOverlay } from 'shared/overlay'

import { DocumentSubForm } from './document-sub-forms/document-sub-forms'
import { INDIVIDUAL_PROFILE_KEY } from '../../individual-profile.keys'
import { individualProfileEn } from '../../locale/new-profile.en'
import { documentIdTypesOption } from '../../model/document.model'
import {
  IDocumentsInputs,
  IIndividualProfileInputs,
  IndividualProfileInputTypes,
  documentDefaultValue,
} from '../../model/form.model'
import {
  documentTypeQa,
  individualProfileVueMigratedQa,
} from '../../qa/individual-profile.qa'
import { AddMoreButton } from '../add-more-button/add-more-button'

type Props = {
  form: UseFormReturn<IIndividualProfileInputs>
  isOptionalFieldRequired?: boolean
  applicantId?: string
  removeDisabled?: boolean
  addDisabled?: boolean
  editDisabled?: boolean
  switchKycDisabled?: boolean
  defaultCountry?: string
  singleDocument?: boolean
  testId?: { container?: string }
}

export function DocumentForm({
  form,
  isOptionalFieldRequired = true,
  applicantId,
  removeDisabled,
  addDisabled,
  editDisabled,
  switchKycDisabled,
  defaultCountry = 'AUS',
  singleDocument = false,
  testId,
}: Props) {
  const t = useI18n([INDIVIDUAL_PROFILE_KEY], { keys: individualProfileEn })

  const { control, watch, clearErrors, trigger, setValue } = form

  const isManualKyc = watch(IndividualProfileInputTypes.KycMethod) === 'manual'
  const documentWatch = watch(IndividualProfileInputTypes.Document)
  const isIdTypeRequired = isManualKyc || singleDocument

  const { mutate: removeDocumentMutate } = useSupportingDocumentDelete({
    applicantId: applicantId || '',
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: IndividualProfileInputTypes.Document,
  })

  const [createOverlay, closeOverlay] = useOverlay()

  const countryOptions = useMemo(
    () =>
      countryList.map(country => ({
        label: country.name,
        value: country.alpha3code,
      })),
    [],
  )

  const appendDefaultValue = () => {
    append({
      ...documentDefaultValue,
      country: defaultCountry,
    })
  }

  useEffect(() => {
    const revalidate = () => {
      const docUploadNames = fields.map((_, idx) => [
        `${IndividualProfileInputTypes.Document}.${idx}.${IndividualProfileInputTypes.Scan}` as const,
        `${IndividualProfileInputTypes.Document}.${idx}.${IndividualProfileInputTypes.IdType}` as const,
      ])
      void trigger(docUploadNames.flatMap(i => i))
    }
    if (!isIdTypeRequired) {
      revalidate()
    }
  }, [fields, isManualKyc, trigger])

  /**
   * To empty the document fields when country or id type is changes
   */
  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name?.includes(IndividualProfileInputTypes.Document) && type) {
        const idx = +name.split('.')[1]
        const document = value.document?.[idx]
        if (name.includes(IndividualProfileInputTypes.IdType)) {
          setValue(`${IndividualProfileInputTypes.Document}.${idx}`, {
            ...documentDefaultValue,
            [IndividualProfileInputTypes.Country]: document?.[
              IndividualProfileInputTypes.Country
            ] as string,
            [IndividualProfileInputTypes.IdType]: document?.[
              IndividualProfileInputTypes.IdType
            ] as DocumentIdTypes,
            [IndividualProfileInputTypes.DocumentId]:
              document?.[IndividualProfileInputTypes.DocumentId],
          } as never)
        }
        if (name.includes(IndividualProfileInputTypes.Country)) {
          setValue(`${IndividualProfileInputTypes.Document}.${idx}`, {
            ...documentDefaultValue,
            [IndividualProfileInputTypes.DocumentId]:
              document?.[IndividualProfileInputTypes.DocumentId],
            [IndividualProfileInputTypes.Country]: document?.[
              IndividualProfileInputTypes.Country
            ] as string,
          } as never)
        }
      }
    })

    return () => subscription.unsubscribe()
  }, [setValue, watch])

  const handleRemove = (idx: number, idType: string, documentId?: string) => {
    createOverlay(
      <div className="">
        <div className="flex items-center gap-2 p-3 text-tertiary-grey-800 font-bold">
          <FrankieIcon
            name="mdiTrashCanOutline"
            className="text-tertiary-grey-300"
          />

          <span> {t('documentForm.deleteDocument')}</span>
        </div>
        <FrankieDivider />
        <div className="p-6">
          <div className="font-bold bg-tertiary-red-50 p-8 text-tertiary-red-500 mb-6 rounded-sm">
            {t('documentForm.confirmRemove', { idType })}
          </div>
          <div className="flex justify-between">
            <FrankieButton
              intent="danger"
              onClick={() => {
                if (documentId) removeDocumentMutate(documentId)
                remove(idx)
                if (!isOptionalFieldRequired) {
                  appendDefaultValue()
                }
                closeOverlay()
              }}
            >
              {t('documentForm.delete')}
            </FrankieButton>
            <FrankieButton
              intent="secondary"
              onClick={closeOverlay}
              className="border-none text-tertiary-grey-500"
            >
              {t('documentForm.cancel')}
            </FrankieButton>
          </div>
        </div>
      </div>,
      { className: 'p-0' },
    )
  }

  return (
    <div data-qa={testId?.container}>
      <div className="flex justify-between items-center">
        {isOptionalFieldRequired && (
          <div className="text-lg mt-3 font-bold">
            {t('documentForm.title')}
          </div>
        )}
        {!isOptionalFieldRequired && (
          <div className="flex justify-start items-center">
            <FrankieIcon name="mdiCardAccountDetailsOutline" className="mr-3" />
            <div className="font-bold">{t('documentForm.heading')}</div>
          </div>
        )}
        {isOptionalFieldRequired && (
          <div className="flex justify-end font-bold items-center">
            <ToggleSwitchFormField
              control={control}
              disabled={switchKycDisabled}
              name={`${IndividualProfileInputTypes.KycMethod}`}
              activeOption={{
                label: t('documentForm.eKyc'),
                value: 'electronic',
              }}
              inactiveOption={{
                label: t('documentForm.mKyc'),
                value: 'manual',
              }}
            />
          </div>
        )}
      </div>

      <div
        className={`my-2 overflow-hidden ${
          isManualKyc ? 'h-[150px]' : 'h-0'
        } transition-height duration-300 ease-in-out`}
      >
        <span className="">
          <Trans
            i18nKey="individual-new-profile:manualKyc.mKycRequirement"
            components={[<strong />]}
          />
        </span>
        <div className="flex gap-[2%] mt-4">
          {(
            ['docTypeReq1', 'docTypeReq2', 'docTypeReq3'] as I18nKeys<
              (typeof individualProfileEn)['manualKyc']
            >[]
          ).map(tKey => {
            let bgColor = 'bg-tertiary-aqua-500'
            let borderColor = 'border-tertiary-aqua-500'

            if (tKey === 'docTypeReq2') {
              bgColor = 'bg-tertiary-amber-500'
              borderColor = 'border-tertiary-amber-500'
            }

            if (tKey === 'docTypeReq3') {
              bgColor = 'bg-tertiary-purple-500'
              borderColor = 'border-tertiary-purple-500'
            }

            return (
              <div
                key={tKey}
                className={`relative basis-[33%] text-center border p-6 rounded-sm ${borderColor} `}
              >
                <div
                  className={`p-1 bg-mono-white rounded-full absolute left-[50%] top-0 
                  transform translate-x-[-50%] translate-y-[-50%]`}
                >
                  <div className={`${bgColor} h-4 w-4 rounded-full`} />
                </div>
                <Trans
                  item={tKey}
                  i18nKey={`individual-new-profile:manualKyc.${tKey}`}
                  components={[<strong />]}
                />
              </div>
            )
          })}
        </div>
      </div>

      <div className="flex flex-col gap-8 my-6">
        {fields.map((fields, idx) => {
          const watchIdType =
            documentWatch[idx][IndividualProfileInputTypes.IdType]

          const selectedCountry =
            documentWatch[idx][IndividualProfileInputTypes.Country]

          const isIdTypeSelected = !!watchIdType

          const idTypesOptions = documentIdTypesOption
            .filter(
              item =>
                item.isDefault ||
                item.country.findIndex(cou => cou.code === selectedCountry) !==
                  -1,
            )
            .map(item => {
              const additionalTextTKey = !item.isDefault
                ? item.country.find(cou => cou.code === selectedCountry)
                    ?.additionalTextTKey ?? null
                : null

              return {
                label: `${t(`idType.${item.tKey}`)}${
                  additionalTextTKey
                    ? ` ${t(`additionalText.${additionalTextTKey}`)}`
                    : ''
                }`,
                value: item.value,
              }
            })

          const idTypeName =
            idTypesOptions.find(item => item.value === watchIdType)?.label ?? ''
          const isOldDocAndEditDisabled =
            !!fields[IndividualProfileInputTypes.DocumentId] && editDisabled
          const isOldDocAndRemoveDisabled =
            (!!fields[IndividualProfileInputTypes.DocumentId] &&
              removeDisabled) ||
            singleDocument
          return (
            <div
              key={fields.id}
              className={`relative flex gap-4 bg-neutral-20 py-4 rounded-sm ${
                isIdTypeSelected ? 'ps-4 pe-[90px] min-h-[210px]' : 'px-4'
              } group/document`}
              data-qa={documentTypeQa(idx).documentWrapper}
            >
              {isOldDocAndRemoveDisabled || (
                <FrankieButton
                  noStyles
                  className="absolute right-0 top-[-20px] text-primary-700 font-bold hidden group-hover/document:block"
                  onClick={() =>
                    handleRemove(
                      idx,
                      idTypeName,
                      fields[IndividualProfileInputTypes.DocumentId],
                    )
                  }
                >
                  {t('documentForm.remove')}
                </FrankieButton>
              )}
              <fieldset disabled={isOldDocAndEditDisabled} className="flex">
                <div className="flex flex-col gap-6">
                  <div className="flex justify-start gap-[2%] items-start max-h-[65px]">
                    <SelectFormField
                      disabled={isOldDocAndEditDisabled}
                      className={
                        isIdTypeSelected ? 'basis-[32%]' : 'basis-[49%]'
                      }
                      label={t('documentForm.country')}
                      control={control}
                      name={`${IndividualProfileInputTypes.Document}.${idx}.${IndividualProfileInputTypes.Country}`}
                      options={countryOptions}
                      autocomplete
                      testId={{
                        input:
                          individualProfileVueMigratedQa.document.countryInput,
                      }}
                    />

                    <SelectFormField
                      className={
                        isIdTypeSelected ? 'basis-[32%]' : 'basis-[49%]'
                      }
                      label={t('documentForm.idType')}
                      control={control}
                      rules={{ required: isIdTypeRequired }}
                      placeholder={t('documentForm.idTypePlaceholder')}
                      name={`${IndividualProfileInputTypes.Document}.${idx}.${IndividualProfileInputTypes.IdType}`}
                      options={idTypesOptions}
                      data-qa={documentTypeQa(idx).idType}
                    />
                  </div>
                  <DocumentSubForm
                    form={form}
                    idx={idx}
                    selectedType={watchIdType}
                    wrapperClasses="flex flex-wrap justify-start gap-x-[2%] gap-y-6"
                  />
                </div>

                <div
                  className={`absolute right-4 w-[37%] h-[175px] ${
                    isIdTypeSelected ? 'block' : 'hidden'
                  }`}
                >
                  <Controller
                    render={({
                      field: { value, onChange },
                      formState: { errors },
                    }) => (
                      <DocumentUpload
                        fetchNewScan
                        documentId={
                          fields[IndividualProfileInputTypes.DocumentId]
                        }
                        name={
                          idTypesOptions.find(
                            item => item.value === watchIdType,
                          )?.label
                        }
                        onChange={({ fileUploadUuid, mimeType }) => {
                          const newValue: IDocumentsInputs[IndividualProfileInputTypes.Scan] =
                            { fileUploadUuid, mimeType }
                          clearErrors(
                            `${IndividualProfileInputTypes.Document}.${idx}.${IndividualProfileInputTypes.Scan}`,
                          )
                          onChange(newValue)
                        }}
                        error={
                          !!getError(
                            `${IndividualProfileInputTypes.Document}.${idx}.${IndividualProfileInputTypes.Scan}`,
                            errors,
                          )
                        }
                      />
                    )}
                    control={control}
                    name={`${IndividualProfileInputTypes.Document}.${idx}.${IndividualProfileInputTypes.Scan}`}
                    rules={{
                      validate: {
                        hasMedia: (
                          value: IDocumentsInputs[IndividualProfileInputTypes.Scan],
                        ) =>
                          isManualKyc
                            ? !!value.fileUploadUuid ||
                              !!value[IndividualProfileInputTypes.ScanId]
                            : true,
                      },
                    }}
                  />
                </div>
              </fieldset>
            </div>
          )
        })}
      </div>
      {isOptionalFieldRequired && (
        <fieldset disabled={addDisabled}>
          <AddMoreButton
            label={t('documentForm.addAnother')}
            handleOnBtnClick={appendDefaultValue}
            testId={{
              button: individualProfileVueMigratedQa.document.addNewCta,
            }}
          />
        </fieldset>
      )}
    </div>
  )
}
