import { bffClient, IClient } from 'shared/client'

import {
  InternationalOwnershipResponse,
  AssociateInternationalPartiesPayload,
  AssociateNewInternationalPartiesPayload,
  HidePartyFromSearch,
  InternationalAssociatedPartyResponse,
} from '../model/applicant-business-ownership-international.model'

export class ApplicantBusinessOwnershipInternationalApi {
  client: IClient

  constructor(client: IClient) {
    this.client = client
  }

  async getBusinessOwnershipDetail(entityId: string) {
    return this.client.get<{ ownership: InternationalOwnershipResponse }>(
      `data/v3/business/${entityId}/ownership`,
    )
  }

  async associateNewParty(
    applicantId: string,
    payload: AssociateInternationalPartiesPayload &
      (
        | AssociateNewInternationalPartiesPayload<'INDIVIDUAL'>
        | AssociateNewInternationalPartiesPayload<'BUSINESS'>
      ) &
      HidePartyFromSearch,
  ) {
    return this.client.post(
      `data/v2/business/${applicantId}/associate/new`,
      payload,
      { params: { isInternational: true } },
    )
  }

  async removeAssociatedParty(applicantId: string, entityId: string) {
    return this.client.delete(
      `data/v2/business/${applicantId}/associate/${entityId}`,
    )
  }

  async updateAssociatedParty(
    applicantId: string,
    payload: AssociateInternationalPartiesPayload,
    entityId: string,
  ) {
    return this.client.put(
      `data/v2/business/${applicantId}/associate/${entityId}`,
      payload,
    )
  }

  async getAssociatedParty(applicantId: string) {
    return this.client.get<InternationalAssociatedPartyResponse>(
      `data/v2/business/${applicantId}/manual-associate`,
    )
  }
}

export const applicantBusinessOwnershipApi =
  new ApplicantBusinessOwnershipInternationalApi(bffClient)
