/* eslint-disable complexity */
import React from 'react'

import {
  OrganisationAuditRest,
  OrganisationRunAml,
} from 'features/organisation-audit'

import { IMatchedOrganization } from 'entities/organisation/model/organisation.model'

import { useI18n } from 'shared/i18n'

import { ORGANISATION_SEARCH_KEY } from '../../../organisation-search/organisation-search.key'
import { KybDocumentCatalog } from '../kyb-document-catalog/kyb-document-catalog'
import { OrganisationInfoRow } from '../organisation-info-row/organisation-info-row'

type Props = {
  organisationInfo: IMatchedOrganization
}

type Row = {
  title: string
  value: string
}

function getRows(organisationInfo: IMatchedOrganization): Row[] {
  const rows: Row[] = []

  // name
  const name = organisationInfo.name?.name
  if (name) {
    rows.push({
      title: 'info.mainName',
      value: name,
    })
  }

  // country
  const { country } = organisationInfo
  if (country) {
    rows.push({
      title: 'info.country',
      value: country,
    })
  }

  // reg number
  const regNumber = organisationInfo.registrationDetails[0]?.registrationNumber
  if (regNumber) {
    rows.push({
      title: 'info.registrationNumber',
      value: regNumber,
    })
  }

  // reg authority
  const regAuthority =
    organisationInfo.registrationDetails[0]?.registryDescription
  if (regAuthority) {
    rows.push({
      title: 'info.registrationAuthority',
      value: regAuthority,
    })
  }

  return rows
}

export function OrganisationInfoRest({ organisationInfo }: Props) {
  const t = useI18n([ORGANISATION_SEARCH_KEY])

  const infoRows = getRows(organisationInfo)

  return (
    <div>
      <div className="text-lg font-bold text-tertiary-grey-800 mb-4">
        {t('info.title')}
      </div>
      <div className="w-full border-t-[1px] border-solid border-tertiary-grey-200">
        {infoRows.map(row => (
          <OrganisationInfoRow
            field={t(row.title)}
            value={row.value}
            key={row.value}
          />
        ))}
      </div>
      <OrganisationRunAml
        className="mt-8"
        organisationInfo={organisationInfo}
      />
      <div className="mt-8">
        <OrganisationAuditRest organisationInfo={organisationInfo} />
      </div>
      <div className="my-8">
        <KybDocumentCatalog organisationInfo={organisationInfo} />
      </div>
    </div>
  )
}
