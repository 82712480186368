import React from 'react'

import { SxProps } from '@mui/material'
import {
  GridColDef,
  GridRenderCellParams,
  GridRow,
  GridRowProps,
} from '@mui/x-data-grid-pro'
import { Link } from 'react-router-dom'

import { FrankieIcon, FrankieLoader } from 'frankify/src'

import {
  ApplicantIssueActionBadge,
  useApplicantPaths,
} from 'entities/applicant'

import { Show } from 'shared/hoc'
import { useI18n } from 'shared/i18n'
import { capitalizeStringBySeparator, generateRandomKey } from 'shared/string'
import { Nullable } from 'shared/typescript'

import { APPLICANT_BUSINESS_OWNERSHIP_INTERNATIONAL_KEY } from '../../applicant-business-ownership-international.key'
import { applicantBusinessOwnershipInternationalEn } from '../../locale/applicant-business-ownership-international.en'
import {
  ApplicantOwnershipHolderRecord,
  ApplicantOwnershipHolderRecordProperties,
  ApplicantOwnershipHolderTypes,
  BlockingReasonTypes,
  IBlockingReasons,
} from '../../model/applicant-business-ownership-international.model'
import { businessOwnershipDataGridHelperQa } from '../../qa/applicant-business-ownership-international.qa'
import { BusinessOwnershipDataGridActionMenu } from '../business-ownership-data-grid-action-menu/business-ownership-data-grid-action-menu'

export type BusinessOwnershipDataGridColumn =
  (GridColDef<ApplicantOwnershipHolderRecord> & {
    field: ApplicantOwnershipHolderRecordProperties
    showInGrid: ApplicantOwnershipHolderTypes[]
  })[]

export const BUSINESS_OWNERSHIP_DATA_GRID_MIN_WIDTH = 800

export const businessOwnershipDataGridCellWidth = (
  width: number,
  noFlex = false,
) => ({
  minWidth: width,
  ...(noFlex
    ? { maxWidth: width }
    : { flex: width / BUSINESS_OWNERSHIP_DATA_GRID_MIN_WIDTH }),
})

export type RowProps = GridRowProps & { row: ApplicantOwnershipHolderRecord }

export function BusinessOwnershipDataGridRow(props: RowProps) {
  const { entityId } = props.row

  const { getApplicantPath } = useApplicantPaths()

  if (!entityId) {
    return <GridRow {...props} />
  }

  return (
    <Link
      to={getApplicantPath('generalInformation', entityId)}
      className="!transition-none hover:!transition-none hover:!text-current hover:!text-mono-black"
    >
      <GridRow {...props} />
    </Link>
  )
}

// eslint-disable-next-line complexity
export function BusinessOwnershipDataGridCell({
  row,
  colDef,
  ownerShipHolderType,
  applicantId,
}: GridRenderCellParams<ApplicantOwnershipHolderRecord> & {
  ownerShipHolderType: ApplicantOwnershipHolderTypes
  applicantId: string
}) {
  const t = useI18n([APPLICANT_BUSINESS_OWNERSHIP_INTERNATIONAL_KEY], {
    keys: applicantBusinessOwnershipInternationalEn,
  })

  const field = colDef.field as ApplicantOwnershipHolderRecordProperties

  const getPercentage = (value?: Nullable<number>) =>
    value || value === 0
      ? `${Math.round(value * 100 + Number.EPSILON) / 100}%`
      : '0%'

  if (field === 'actions') {
    return (
      <BusinessOwnershipDataGridActionMenu
        className="ml-auto"
        entityId={row.entityId}
        applicantId={applicantId}
        showUpdateAssociatedPartyMenu={
          ownerShipHolderType === 'associatedParties'
        }
        showSendKycLinkMenu={
          row.countryList?.findIndex(c => c === 'AU' || c === 'AUS') !== -1
        }
      />
    )
  }

  const value = row[field]

  if (value === undefined || (Array.isArray(value) && value.length === 0)) {
    return <div className="font-semibold">-</div>
  }

  return (
    <Show>
      <Show.When isTrue={field === 'entityType'}>
        <FrankieIcon
          className="text-tertiary-grey-400"
          name={
            row.entityType === 'INDIVIDUAL'
              ? 'mdiAccountOutline'
              : 'mdiOfficeBuildingOutline'
          }
        />
      </Show.When>

      <Show.When isTrue={field === 'name'}>
        <div className="font-semibold">
          <div>{row.name}</div>
        </div>
      </Show.When>

      <Show.When isTrue={field === 'profileStatus'}>
        <ApplicantIssueActionBadge type={row.profileStatus?.type ?? ''} />
      </Show.When>

      <Show.When isTrue={field === 'riskLevel'}>
        <div className="font-semibold">
          {row.riskLevel ? capitalizeStringBySeparator(row.riskLevel) : '-'}
        </div>
      </Show.When>

      <Show.When isTrue={field === 'issues'}>
        <div className="flex flex-wrap gap-[5px]">
          {row.issues?.map(issue => (
            <ApplicantIssueActionBadge
              className="!font-semibold !p-1 max-w-min"
              type={issue.type}
            />
          ))}
        </div>
      </Show.When>

      <Show.When
        isTrue={['isBeneficiallyHeld', 'jointHolding'].includes(field)}
      >
        <div className="font-semibold">
          {typeof value === 'boolean' ? t(value ? 'yes' : 'no') : '-'}
        </div>
      </Show.When>

      <Show.When
        isTrue={[
          'beneficiallyHeld',
          'nonBeneficiallyHeld',
          'totalHeld',
        ].includes(field)}
      >
        <div className="font-semibold">
          {typeof value === 'number' ? getPercentage(value) : '-'}
        </div>
      </Show.When>

      <Show.When isTrue={field === 'reasons'}>
        {Array.isArray(value) &&
          (value as IBlockingReasons[]).map((val, idx) => (
            <span key={generateRandomKey(val.description, idx)}>
              <div
                className={`font-semibold ${
                  val.type === BlockingReasonTypes.INSUFFICIENT_MAX_CREDIT_COST
                    ? 'text-tertiary-red-500'
                    : ''
                }`}
              >
                {val.description ?? val.type ?? '-'}
              </div>
            </span>
          ))}
      </Show.When>

      <Show.Else>
        <div className="font-semibold">{value.toString()}</div>
      </Show.Else>
    </Show>
  )
}

export function BusinessOwnershipDataGridLoadingOverlay() {
  const t = useI18n([APPLICANT_BUSINESS_OWNERSHIP_INTERNATIONAL_KEY], {
    keys: applicantBusinessOwnershipInternationalEn,
  })

  return (
    <div className="flex align-center justify-center flex-col w-full h-full bg-mono-white opacity-80">
      <FrankieLoader
        label={t('loading.default')}
        loading
        className="text-tertiary-grey-800 text-md font-semibold min-h-[250px]"
        size="sm"
        testId={{ loader: businessOwnershipDataGridHelperQa.loader }}
      />
    </div>
  )
}

export const businessOwnershipDataGridSx: SxProps = {
  '& .MuiDataGrid-columnSeparator': {
    display: 'none',
  },
  '& .MuiDataGrid-columnHeaderTitleContainerContent': {
    height: '100%',
  },
  '& .MuiDataGrid-columnHeaders': {
    lineHeight: 'unset !important',
    borderRadius: '0 !important',
  },

  '& .MuiDataGrid-row--lastVisible': {
    '& .MuiDataGrid-cell': { border: '0px !important' },
  },

  '& .MuiDataGrid-columnHeaderTitleContainer': {
    padding: '0 14px',
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    fontWeight: 'inherit',
    whiteSpace: 'pre-line',
  },
  '& .MuiDataGrid-row': {
    lineHeight: 'unset !important',
  },
  '& .MuiDataGrid-cell': {
    maxHeight: 'unset !important',
    p: '20px 14px',
  },
  '& .MuiDataGrid-cellContent': {
    whiteSpace: 'normal',
    color: 'inherit',
  },
  '& .MuiDataGrid-row.Mui-hovered, .MuiDataGrid-row:hover': {
    backgroundColor: 'transparent',
  },
  '& .MuiDataGrid-overlayWrapperInner': {
    p: '20px',
  },
}
