import React, { useState } from 'react'

import {
  DataGridPro,
  GridColDef,
  GridExpandMoreIcon,
  GridSortModel,
} from '@mui/x-data-grid-pro'

import { WithProps } from 'shared/hoc'
import { useI18n } from 'shared/i18n'

import { APPLICANT_AUDIT_REPORT_KEY } from '../../applicant-audit-report.key'
import { applicantAuditReportEn } from '../../locale/applicant-audit-report.en'
import {
  AuditRecordsData,
  AuditRecordDataProperties,
} from '../../model/applicant-audit-report.model'
import { useApplicantAuditTableData } from '../../state/applicant-audit-record-state/applicant-audit-record-state'
import {
  AuditDescriptionCell,
  AuditReportTableHeader,
  AuditTimeStampCell,
  AuditUserCell,
} from '../audit-report-table-cell/audit-report-table-cell'
import {
  AuditTableDetailPanel,
  AuditReportLoadingOverlay,
  AuditReportNoRowOverlay,
  AuditTableRow,
} from '../audit-report-table-slot/audit-report-table-slot'

const TABLE_MIN_WIDTH = 650

const headerField = <T extends AuditRecordDataProperties>(name: T) => name

export const auditCellWidth = (width: number) => ({
  minWidth: width,
  flex: width / TABLE_MIN_WIDTH,
})

type Props = {
  applicantId: string
}

export function ApplicantAuditTable({ applicantId }: Props) {
  const t = useI18n([APPLICANT_AUDIT_REPORT_KEY], {
    keys: applicantAuditReportEn,
  })

  const [sortModel, setSortModel] = useState<GridSortModel>([])

  const {
    rows,
    sortFields,
    loading,
    handleExpandedRowIds,
    expandedRowIds,
    error,
  } = useApplicantAuditTableData({
    applicantId,
  })

  const columns: GridColDef<AuditRecordsData>[] = [
    {
      field: headerField('user'),
      headerName: t('header.user'),
      renderCell: AuditUserCell,
      ...auditCellWidth(40),
    },
    {
      field: headerField('action'),
      headerName: t('header.action'),
      cellClassName: 'font-bold pr-2',
      ...auditCellWidth(200),
    },
    {
      field: headerField('resultAndComments'),
      headerName: t('header.resultAndComments'),
      renderCell: WithProps(AuditDescriptionCell, { expandedRowIds }),
      ...auditCellWidth(250),
    },
    {
      field: headerField('timeStamp'),
      headerName: t('header.date'),
      renderCell: AuditTimeStampCell,
      ...auditCellWidth(180),
      sortComparator: (a: string, b: string) => b.localeCompare(a),
    },
  ]

  return (
    <DataGridPro
      rows={rows}
      slots={{
        row: WithProps(AuditTableRow, { t, expandedRowIds }),
        loadingOverlay: AuditReportLoadingOverlay,
        noRowsOverlay: WithProps(AuditReportNoRowOverlay, { error }),
        detailPanelExpandIcon: GridExpandMoreIcon,
        detailPanelCollapseIcon: GridExpandMoreIcon,
      }}
      columns={columns.map(item => ({
        sortable: sortFields.includes(item.field as AuditRecordDataProperties),
        hideSortIcons: true,
        resizable: false,
        disableColumnMenu: true,
        disableReorder: true,
        headerClassName:
          '!p-0 text-xs font-medium text-tertiary-grey-500 !outline-none ',
        renderHeader: WithProps(AuditReportTableHeader, {
          sortModel,
          sortFields,
        }),
        ...item,
      }))}
      getDetailPanelContent={AuditTableDetailPanel}
      getDetailPanelHeight={() => 'auto'}
      getRowHeight={() => 'auto'}
      className="[&_.MuiDataGrid-virtualScrollerContent]:border-neutral-30"
      getCellClassName={() =>
        '!outline-none !border-neutral-30 !items-start !py-4 !px-0 break-words'
      }
      getRowClassName={() => 'hover:!bg-transparent !min-h-[60px]'}
      onDetailPanelExpandedRowIdsChange={ids => handleExpandedRowIds(ids)}
      loading={loading}
      sortModel={sortModel}
      onSortModelChange={setSortModel}
      isRowSelectable={() => false}
      isCellEditable={() => false}
      disableRowSelectionOnClick
      disableColumnSelector
      disableColumnFilter
      disableColumnMenu
      disableDensitySelector
      showCellVerticalBorder={false}
      showColumnVerticalBorder={false}
      hideFooterPagination
      hideFooter
      hideFooterSelectedRowCount
      sx={{
        '.MuiDataGrid-columnSeparator': {
          display: 'none',
        },
        '& .MuiDataGrid-virtualScroller': {
          minHeight: 'calc(100vh - 340px)',
          maxHeight: 'calc(100vh - 340px)',
        },
        '& .MuiDataGrid-virtualScrollerContent': {
          border: '1px solid',
          borderBottom: 'none',
        },
        '&.MuiDataGrid-root': {
          border: 'none',
        },
        '& .MuiDataGrid-columnHeaderTitleContainerContent': {
          height: '100%',
        },
        '& .MuiDataGrid-columnHeaders': {
          minHeight: 'unset !important',
          maxHeight: '40px !important',
          lineHeight: 'unset !important',
          border: 'none !important',
        },
        '.MuiDataGrid-columnHeaderTitleContainer': {
          display: 'inline-block !important',
          flex: 'none !important',
        },
        '.MuiDataGrid-cell': {
          minHeight: 'unset !important',
          maxHeight: 'unset !important',
          lineHeight: 'unset !important',
        },
        '& .MuiDataGrid-detailPanelToggleCell': {
          opacity: 0.7,
        },
        "& .MuiDataGrid-detailPanelToggleCell[aria-label='Expand']": {
          transform: 'rotate(-90deg)',
        },
        '& .MuiDataGrid-row.Mui-hovered, .MuiDataGrid-row:hover': {
          backgroundColor: 'transparent',
        },
      }}
    />
  )
}
