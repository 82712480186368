import { APPLICANT_KEY, applicantEn } from 'entities/applicant'
import { COMMENT_KEY, commentsEn } from 'entities/comments'
import { DOCUMENT_KEY, documentEn } from 'entities/document'
import { DOCUMENT_F2_KEY, documentF2En } from 'entities/document-f2'
import { ENTITY_KEY, entityEn } from 'entities/entity'
import { ORGANISATION_KEY, organisationEn } from 'entities/organisation'
import { PROFILE_CHECK_KEY, profileCheckEn } from 'entities/profile-check'
import { ROLE_KEY, roleEn } from 'entities/role'
import { USER_KEY, userEn } from 'entities/user'

export const entities = {
  [ROLE_KEY]: roleEn,
  [USER_KEY]: userEn,
  [ORGANISATION_KEY]: organisationEn,
  [PROFILE_CHECK_KEY]: profileCheckEn,
  [DOCUMENT_KEY]: documentEn,
  [DOCUMENT_F2_KEY]: documentF2En,
  [APPLICANT_KEY]: applicantEn,
  [ENTITY_KEY]: entityEn,
  [COMMENT_KEY]: commentsEn,
}
