import React, { useMemo } from 'react'

import { Controller, SubmitHandler, useForm } from 'react-hook-form'

import { FrankieButton, FrankieDivider } from 'frankify/src'

import {
  ApplicantEntityStatusTypes,
  ApplicantIssueTypes,
  ApplicantRiskLevelTypes,
} from 'entities/applicant'
import { getFullRecipesList } from 'entities/recipe'
import type { IRecipeOption } from 'entities/recipe'
import { useSessionQuery } from 'entities/session'
import { IUser } from 'entities/user'

import { removeDuplicates, sortArrOfObjs } from 'shared/array'
import {
  CheckboxesSearch,
  CheckboxGroup,
  DateSelector,
  FilterSection,
  validateDateRange,
  IOption,
} from 'shared/filters-common'
import { useI18n } from 'shared/i18n'

import {
  APPLICANTS_FILTER_KEY,
  applicantsFilterEn,
} from '../../locale/applicants-filter.en'
import {
  applicantIssues3Columns,
  applicantIssuesI18n,
  applicantRisksColumns,
  applicantRisksI18n,
  applicantStatusesI18n,
} from '../../model/applicants-filter-form.model'
import {
  applicantsGlobalFilterDefault,
  ApplicantsFilterInputTypes,
  IApplicantsGlobalFilter,
} from '../../model/applicants-filter.model'
import { applicantsFilterQa } from '../../qa/applicants-filter.qa'
import { ApplicantTypeSelector } from '../applicant-type-selector/applicant-type-selector'

export type IPartialApplicantsFilter = Omit<
  IApplicantsGlobalFilter,
  ApplicantsFilterInputTypes.Name | ApplicantsFilterInputTypes.EntityId
>

export const applicantFilterGeneralDefault: IPartialApplicantsFilter = {
  [ApplicantsFilterInputTypes.Recipe]:
    applicantsGlobalFilterDefault[ApplicantsFilterInputTypes.Recipe],
  [ApplicantsFilterInputTypes.Issue]:
    applicantsGlobalFilterDefault[ApplicantsFilterInputTypes.Issue],
  [ApplicantsFilterInputTypes.EntityStatus]:
    applicantsGlobalFilterDefault[ApplicantsFilterInputTypes.EntityStatus],
  [ApplicantsFilterInputTypes.RiskLevel]:
    applicantsGlobalFilterDefault[ApplicantsFilterInputTypes.RiskLevel],
  [ApplicantsFilterInputTypes.CreatedDate]:
    applicantsGlobalFilterDefault[ApplicantsFilterInputTypes.CreatedDate],
  [ApplicantsFilterInputTypes.Assignee]:
    applicantsGlobalFilterDefault[ApplicantsFilterInputTypes.Assignee],
  [ApplicantsFilterInputTypes.EntityType]:
    applicantsGlobalFilterDefault[ApplicantsFilterInputTypes.EntityType],
}

type Props = {
  onClose: () => void
  initialValues?: IPartialApplicantsFilter
  onSubmit: (filters: IPartialApplicantsFilter) => void
  users: IUser[]
  statusesOptionCols: ApplicantEntityStatusTypes[][]
}

type Option = { value: string; label: string }

export function ApplicantsFilterForm({
  statusesOptionCols,
  users,
  onClose,
  initialValues,
  onSubmit,
}: Props) {
  const t = useI18n([APPLICANTS_FILTER_KEY], { keys: applicantsFilterEn })
  const { data: session } = useSessionQuery()

  const usersOptions = useMemo<Option[]>(() => {
    const filtered = removeDuplicates(users, user => user.realname)

    return filtered.map<Option>(user => ({
      label: user.realname || '',
      value: user.email || '',
    }))
  }, [users])

  const recipeOptions = useMemo<IRecipeOption[][]>(() => {
    const list = getFullRecipesList(session)
    const filtered = removeDuplicates(list, option => option.value)
    const sorted = sortArrOfObjs(filtered, 'asc', option => option.label)
    return [
      sorted.slice(0, Math.ceil(sorted.length / 3)),
      sorted.slice(
        Math.ceil(sorted.length / 3),
        Math.ceil(sorted.length / 3) * 2,
      ),
      sorted.slice(Math.ceil(sorted.length / 3) * 2),
    ]
  }, [session])
  const issueOptions = useMemo<IOption<ApplicantIssueTypes>[][]>(
    () =>
      applicantIssues3Columns.map(column =>
        column.map(option => ({
          value: option,
          label: t(applicantIssuesI18n[option]),
        })),
      ),
    [t],
  )
  const statusOptions = useMemo<IOption<ApplicantEntityStatusTypes>[][]>(
    () =>
      statusesOptionCols.map(column =>
        column.map(option => ({
          value: option,
          label: t(applicantStatusesI18n[option]),
        })),
      ),
    [t, statusesOptionCols],
  )
  const riskOptions = useMemo<IOption<ApplicantRiskLevelTypes>[][]>(
    () =>
      applicantRisksColumns.map(column =>
        column.map(option => ({
          value: option,
          label: t(applicantRisksI18n[option]),
        })),
      ),
    [t],
  )

  const {
    handleSubmit,
    control,
    formState: { isDirty },
  } = useForm<IPartialApplicantsFilter>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    values: initialValues || applicantFilterGeneralDefault,
  })

  const handleClear = () => {
    onSubmit(applicantsGlobalFilterDefault)
    onClose()
  }

  const handleFormSubmit: SubmitHandler<IPartialApplicantsFilter> = data => {
    onSubmit(data)
    onClose()
  }

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} className="w-full">
      <div className="leading-tight p-6 pb-4 text-xl font-bold text-tertiary-grey-800">
        {t('form.title')}
      </div>
      <FrankieDivider />
      <div className="py-3">
        <div className="flex flex-col gap-6 py-3 pl-6 pr-4 mr-2 max-h-[calc(100vh-296px)] overflow-y-auto scrollbar-gutter scrollbar">
          <FilterSection header={t('form.headers.entityType')}>
            <Controller
              control={control}
              name={ApplicantsFilterInputTypes.EntityType}
              render={({ field: { onChange, value } }) => (
                <ApplicantTypeSelector onChange={onChange} value={value} />
              )}
            />
          </FilterSection>

          <FilterSection header={t('form.headers.entityStatus')}>
            <Controller
              control={control}
              name={ApplicantsFilterInputTypes.EntityStatus}
              render={({ field: { onChange, value } }) => (
                <CheckboxGroup
                  onChange={onChange}
                  optionGroups={statusOptions}
                  values={value}
                  testId={{ checkbox: applicantsFilterQa.inputs.statusOption }}
                />
              )}
            />
          </FilterSection>

          <FilterSection header={t('form.headers.issue')}>
            <Controller
              control={control}
              name={ApplicantsFilterInputTypes.Issue}
              render={({ field: { onChange, value } }) => (
                <CheckboxGroup
                  optionGroups={issueOptions}
                  onChange={onChange}
                  values={value}
                  testId={{ checkbox: applicantsFilterQa.inputs.issueOption }}
                />
              )}
            />
          </FilterSection>

          <FilterSection header={t('form.headers.riskLevel')}>
            <Controller
              control={control}
              name={ApplicantsFilterInputTypes.RiskLevel}
              render={({ field: { onChange, value } }) => (
                <CheckboxGroup
                  optionGroups={riskOptions}
                  onChange={onChange}
                  values={value}
                  testId={{
                    checkbox: applicantsFilterQa.inputs.riskLevelOption,
                  }}
                />
              )}
            />
          </FilterSection>
          <FilterSection header={t('form.headers.createdDate')}>
            <Controller
              control={control}
              name={ApplicantsFilterInputTypes.CreatedDate}
              rules={{
                validate: validateDateRange,
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <DateSelector
                  onChange={onChange}
                  value={value}
                  error={error?.message ? [error.message] : undefined}
                  testId={{
                    inputFrom: applicantsFilterQa.inputs.createdDate.from,
                    inputTo: applicantsFilterQa.inputs.createdDate.to,
                    optionToday: applicantsFilterQa.inputs.createdDate.today,
                    optionYesterday:
                      applicantsFilterQa.inputs.createdDate.yesterday,
                    optionPast7Days:
                      applicantsFilterQa.inputs.createdDate.past7Days,
                    optionPast30Days:
                      applicantsFilterQa.inputs.createdDate.past30Days,
                    optionAll: applicantsFilterQa.inputs.createdDate.all,
                    optionCustom: applicantsFilterQa.inputs.createdDate.custom,
                    optionPastYear:
                      applicantsFilterQa.inputs.createdDate.pastYear,
                  }}
                />
              )}
            />
          </FilterSection>
          <FilterSection header={t('form.headers.recipe')}>
            <Controller
              control={control}
              name={ApplicantsFilterInputTypes.Recipe}
              render={({ field: { onChange, value } }) => (
                <CheckboxGroup
                  lessRowsToShow={2}
                  optionGroups={recipeOptions}
                  onChange={onChange}
                  values={value}
                  testId={{ checkbox: applicantsFilterQa.inputs.recipeOption }}
                />
              )}
            />
          </FilterSection>
          {users.length > 0 && (
            <FilterSection header={t('form.headers.assignee')}>
              <Controller
                control={control}
                name={ApplicantsFilterInputTypes.Assignee}
                render={({ field: { onChange, value } }) => (
                  <CheckboxesSearch
                    onChange={onChange}
                    values={value}
                    options={usersOptions}
                    placeholder={t('form.assignee.placeholder')}
                    testId={{
                      checkbox: applicantsFilterQa.inputs.assignee.option,
                      searchInput: applicantsFilterQa.inputs.assignee.search,
                      closeBadge: applicantsFilterQa.cta.assignee.closeBadge,
                      badge: applicantsFilterQa.cta.assignee.badge,
                      closeButton: applicantsFilterQa.cta.assignee.clearSearch,
                    }}
                  />
                )}
              />
            </FilterSection>
          )}
        </div>
      </div>
      <FrankieDivider />
      <div className="flex flex-row items-center justify-between p-6">
        <FrankieButton
          intent="darkOutline"
          onClick={handleClear}
          testId={{ button: applicantsFilterQa.cta.clearFilters }}
        >
          {t('form.cta.clearAll')}
        </FrankieButton>
        <FrankieButton
          disabled={!isDirty}
          type="submit"
          testId={{ button: applicantsFilterQa.cta.applyFilters }}
        >
          {t('form.cta.applyAll')}
        </FrankieButton>
      </div>
    </form>
  )
}
