import { bffClient, IClient } from 'shared/client'

import { AuditReportResponse } from '../model/individual-audit-report-model'

export type GetAuditEventParam = {
  page?: number
  limit: number
  mock?: boolean
}
export class AuditReportAPI {
  client: IClient

  constructor(client: IClient) {
    this.client = client
  }

  async downloadAuditReport(entityId: string, mock?: boolean) {
    return this.client.get<BlobPart>(
      `f2/v1/entity/${entityId}/audit-logs.pdf`,
      {
        responseType: 'arraybuffer',
        params: {
          mock,
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      },
    )
  }

  async getAuditEvents(
    entityId: string,
    { page = 1, limit, mock }: GetAuditEventParam,
  ) {
    return this.client.get<AuditReportResponse>(
      `/f2/v1/entity/${entityId}/audit-logs/all`,
      {
        params: {
          page,
          limit,
          mock,
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      },
    )
  }
}

export const auditReportAPI = new AuditReportAPI(bffClient)
