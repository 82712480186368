import React from 'react'

import { FrankieIcon } from 'frankify/src'

type Props = {
  text: string
  error: boolean
}

export function ApplicantRelatedOrganisationsOverlay(props: Props) {
  return props.error ? (
    <div className="h-full flex items-center text-tertiary-red-600">
      <FrankieIcon
        name="mdiAlertCircleOutline"
        size="sm"
        className="inline-block"
      />
      <span className="ml-2 inline-block">{props.text}</span>
    </div>
  ) : (
    <p className="h-full flex items-center">{props.text}</p>
  )
}
