import React from 'react'

import {
  LiveboardEmbed,
  useEmbedRef,
} from '@thoughtspot/visual-embed-sdk/lib/src/react'

import { FrankieLoader } from 'frankify/src'

import { useAnalyticsDashboardTranslation } from 'features/analytics-dashboard/locale/analytics-dashboard.en'

import { useSessionQuery } from 'entities/session'

import { useThoughSpot } from '../../state/thought-spot/thought-spot'
import { AnalyticsDashboardError } from '../analytics-dashboard-error/analytics-dashboard-error'

/**
 * ThoughtSpot Live board Integration
 * Clear cookies on logout see {@link src/features/logout/model/logout.model.ts}
 */
export function AnalyticsLiveboard() {
  const { t } = useAnalyticsDashboardTranslation()
  const embedRef = useEmbedRef()
  const { isSuccess, isLoading, isError } = useThoughSpot()

  const { data } = useSessionQuery()
  const dashboardId =
    data?.features.embeddableDashboard?.thoughtSpot.dashboardId
  const isFrankie2 = data?.features.frankie2customer

  return (
    <FrankieLoader
      loading={isLoading}
      label={
        <div className="font-semibold pt-2 text-tertiary-700">
          {t('initializing')}
        </div>
      }
      className="min-h-[calc(100vh-300px)]"
    >
      {/* ThoughtSpot liveboard Embed */}
      <div className=" border-t border-t-tertiary-grey-200">
        {isSuccess && (
          <LiveboardEmbed
            frameParams={{
              height: isFrankie2
                ? 'calc(100vh - 213px)'
                : 'calc(100vh - 260px)',
            }}
            className={
              isFrankie2 ? 'h-[calc(100vh-213px)]' : 'h-[calc(100vh-260px)]'
            }
            ref={embedRef as unknown as undefined}
            liveboardId={dashboardId}
          />
        )}
        {isError && <AnalyticsDashboardError />}
      </div>
    </FrankieLoader>
  )
}
