/**
 * For some stupid reason we are using different enums for api query
 * This is the only place where they are used
 */
import {
  ApplicantEntityStatusTypes,
  ApplicantEntityTypes,
  ApplicantIssueTypes,
  ApplicantRiskLevelTypes,
} from 'entities/applicant'

import {
  ApplicantsFilterInputTypes,
  IApplicantsGlobalFilter,
} from './applicants-filter.model'

export type ApiFilterIssueTypes =
  | '500'
  | 'PEP'
  | 'S'
  | 'M'
  | 'DUP'
  | 'BL'
  | 'RISK'
  | 'IWL'
  | 'KYC'
  | 'PTL'
  | '404'
  | 'ATT-OCR'
  | 'ATT-IDV'
  | 'ATT-PHT'
  | 'ATT-NCMP'
  | 'ATT-FACE'
  | 'SUPPORT-DOCS'
  | 'digital-id'
  | 'FRAUD'
  | 'DEVICE'
  | 'WL'

export const apiFilterIssuesMapping: Record<
  ApplicantIssueTypes,
  ApiFilterIssueTypes | ApiFilterIssueTypes[]
> = {
  [ApplicantIssueTypes.AdverseMedia]: 'M',
  [ApplicantIssueTypes.Biometrics]: [
    'ATT-NCMP',
    'ATT-PHT',
    'ATT-OCR',
    'ATT-IDV',
  ],
  [ApplicantIssueTypes.Blocklist]: 'BL',
  [ApplicantIssueTypes.Device]: 'DEVICE',
  [ApplicantIssueTypes.DigitalId]: 'digital-id',
  [ApplicantIssueTypes.Document]: 'SUPPORT-DOCS',
  [ApplicantIssueTypes.Duplicate]: 'DUP',
  [ApplicantIssueTypes.FacialDuplication]: 'ATT-FACE',
  [ApplicantIssueTypes.FraudTransaction]: 'FRAUD',
  [ApplicantIssueTypes.InternalWatchlist]: 'IWL',
  [ApplicantIssueTypes.KYC]: ['404', 'KYC', 'PTL'],
  [ApplicantIssueTypes.PEP]: 'PEP',
  [ApplicantIssueTypes.Risk]: 'RISK',
  [ApplicantIssueTypes.Sanctions]: 'S',
  [ApplicantIssueTypes.Timeout]: '500',
  [ApplicantIssueTypes.Watchlist]: 'WL',

  // Not in filters!
  [ApplicantIssueTypes.DeviceCustomer]: 'WL',
  [ApplicantIssueTypes.AMLTransaction]: 'WL',
}

export type ApiFilterEntityTypes = 'ORGANISATION' | 'INDIVIDUAL'

export const apiFilterEntityMapping: Record<
  ApplicantEntityTypes,
  ApiFilterEntityTypes
> = {
  [ApplicantEntityTypes.Individual]: 'INDIVIDUAL',
  [ApplicantEntityTypes.Organisation]: 'ORGANISATION',
}

export type ApiFilterStatusTypes =
  | 'fail'
  | 'pass'
  | 'fail_manual'
  | 'pass_manual'
  | 'refer'
  | 'unchecked'
  | 'archived'
  | 'inactive'

export const apiFilterStatusMapping: Record<
  ApplicantEntityStatusTypes,
  ApiFilterStatusTypes
> = {
  [ApplicantEntityStatusTypes.Failed]: 'fail',
  [ApplicantEntityStatusTypes.ManuallyFailed]: 'fail_manual',
  [ApplicantEntityStatusTypes.Passed]: 'pass',
  [ApplicantEntityStatusTypes.ManuallyPassed]: 'pass_manual',
  [ApplicantEntityStatusTypes.NeedsAttention]: 'refer',
  [ApplicantEntityStatusTypes.Unchecked]: 'unchecked',
  [ApplicantEntityStatusTypes.Archived]: 'archived',
  [ApplicantEntityStatusTypes.Inactive]: 'inactive',
}

export type ApiFilterRiskLevels =
  | 'LOW'
  | 'MEDIUM'
  | 'HIGH'
  | 'UNACCEPTABLE'
  | 'UNKNOWN'

export const apiFilterRiskLevelsMapping: Record<
  ApplicantRiskLevelTypes,
  ApiFilterRiskLevels
> = {
  [ApplicantRiskLevelTypes.Low]: 'LOW',
  [ApplicantRiskLevelTypes.Medium]: 'MEDIUM',
  [ApplicantRiskLevelTypes.High]: 'HIGH',
  [ApplicantRiskLevelTypes.Unacceptable]: 'UNACCEPTABLE',
  [ApplicantRiskLevelTypes.Unknown]: 'UNKNOWN',
}

export interface IApiFilters {
  matchStatusFilter: ApiFilterStatusTypes[]
  nameFilter: string
  entityIdFilter: string
  entityTypeFilter: ApiFilterEntityTypes | ''
  amlStatusFilter: ApiFilterIssueTypes[]
  riskStatusFilter: ApiFilterRiskLevels[]
  assigneeFilter: string[]
  startDate: string
  endDate: string
  profileNameFilter: string[]
}

export function mapApplicantFiltersToApi(
  filters: IApplicantsGlobalFilter,
): IApiFilters {
  return {
    entityTypeFilter: filters.entityType
      ? apiFilterEntityMapping[filters.entityType]
      : '',
    amlStatusFilter: filters.issue
      .map(value => apiFilterIssuesMapping[value])
      .flat(),
    matchStatusFilter: filters.entityStatus.map(
      value => apiFilterStatusMapping[value],
    ),
    riskStatusFilter: filters.riskLevel.map(
      value => apiFilterRiskLevelsMapping[value],
    ),
    nameFilter: filters.name,
    entityIdFilter: filters.entityId,
    profileNameFilter: filters.recipe,
    assigneeFilter: filters.assignee.map(assingee => assingee.value),
    startDate:
      filters[
        ApplicantsFilterInputTypes.CreatedDate
      ].startDate?.toISOString() ?? '',
    endDate:
      filters[ApplicantsFilterInputTypes.CreatedDate].endDate?.toISOString() ??
      '',
  }
}
