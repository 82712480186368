import React from 'react'

import { Link, LinkProps } from 'react-router-dom'

type Props = LinkProps & {
  notALink?: boolean
  children?: React.ReactNode
}

export function FrankieLink({ notALink = false, children, ...other }: Props) {
  if (notALink) {
    // @ts-ignore
    return <div {...other}>{children}</div>
  }

  return <Link {...other}>{children}</Link>
}
