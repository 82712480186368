import {
  useContinueOwnershipReportGeneration,
  useGenerateReportROW,
} from 'entities/organisation'

import { useI18n } from 'shared/i18n'
import { notification } from 'shared/notification'

import { MONITORING_OVERVIEW_KEY } from '../../monitoring-overview.key'

export type ReportGenerationArgs = {
  entityId: string
  toggleReportGeneration: (type: 'ownership' | 'profile') => void
}

export const useReportGeneration = ({
  entityId,
  toggleReportGeneration,
}: ReportGenerationArgs) => {
  const t = useI18n([MONITORING_OVERVIEW_KEY])

  const {
    mutateAsync: continueOwnershipReportGeneration,
    isLoading: isLoadingContinueOwnershipReport,
  } = useContinueOwnershipReportGeneration()

  const { mutateAsync: generateReportROW, isLoading: isLoadingProfileReport } =
    useGenerateReportROW('profile')

  const generateOwnershipReport = async () => {
    if (!entityId) return
    try {
      await continueOwnershipReportGeneration({
        entityId,
      })
    } catch (error) {
      notification.error(t('errorOnViewOwnershipReport'))
    } finally {
      void toggleReportGeneration('ownership')
    }
  }

  const generateProfileReport = async () => {
    if (!entityId) return
    try {
      await generateReportROW({
        entityId,
        reportType: 'profile',
      })
    } catch (error) {
      notification.error(t('errorOnViewReport'))
    } finally {
      void toggleReportGeneration('profile')
    }
  }

  return {
    generateOwnershipReport,
    generateProfileReport,
    isLoadingContinueOwnershipReport,
    isLoadingProfileReport,
  }
}
