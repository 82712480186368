import { AxiosError } from 'axios'

import { ErrorCodeTypes } from 'shared/error'
import { Nullable, Unknownable } from 'shared/typescript'

interface IAdminUserLockedErrorData {
  contactSupport: true
}

function isIAdminUserLockedErrorData(
  val: unknown,
): val is IAdminUserLockedErrorData {
  return !!(
    val &&
    typeof val === 'object' &&
    'contactSupport' in val &&
    val.contactSupport === true
  )
}

export enum AccountLockedErrorTypes {
  CanUnlock = 'canUnlock',
  Default = 'default',
}

export function findAccountLockedError(
  error: AxiosError<Unknownable<IAdminUserLockedErrorData>>,
): Nullable<AccountLockedErrorTypes> {
  const status = error.response?.status as ErrorCodeTypes | undefined
  const data = error.response?.data
  if (!status || status !== ErrorCodeTypes.E401) {
    return null
  }
  return isIAdminUserLockedErrorData(data)
    ? AccountLockedErrorTypes.CanUnlock
    : AccountLockedErrorTypes.Default
}
