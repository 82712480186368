const REACT_PDF_PAGE_SELECTOR = '.react-pdf__Page'

/**
 * The function `useScrollToPdfPage` allows for scrolling to a specific page in a React PDF lib document
 * @returns An object with a single property `scrollToPage`, which is a function that scrolls the page
 * to a specified PDF page number.
 */
export function useScrollToPdfPage(ref?: React.RefObject<HTMLDivElement>) {
  const scrollToPage = (page: number) => {
    const elSelector = ref?.current ?? document
    const pageEl = elSelector.querySelector(
      `${REACT_PDF_PAGE_SELECTOR}[data-page-number="${page}"]`,
    )
    pageEl?.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  return { scrollToPage }
}
