export const nameValidation = {
  required: 'Please enter a name',
  minLength: {
    value: 1,
    message: 'Name must be at least 1 characters long',
  },
}

export const holdingCountValidation = {
  required: 'At least one unit holder has to own one unit',
  min: {
    value: 1,
    message: 'At least one unit holder has to own one unit',
  },
  valueAsNumber: true,
}

export const totalUnitsValidation = {
  required: 'Total number of units cannot be 0 or blank',
  min: {
    value: 1,
    message: 'Total number of units cannot be 0 or blank',
  },
  valueAsNumber: true,
}
