// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js!./portal-module-vue.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../../../node_modules/css-loader/dist/cjs.js!./vuetify.min.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "[role='button']:disabled,\n[type='button']:disabled,\n[type='reset']:disabled,\n[type='submit']:disabled,\nbutton:disabled {\n  cursor: default;\n}\n", "",{"version":3,"sources":["webpack://./src/app/portal-vue-compatibility/breaking-styles.css"],"names":[],"mappings":"AAGA;;;;;EAKE,eAAe;AACjB","sourcesContent":["@import 'portal-module-vue.css';\n@import './vuetify.min.css';\n\n[role='button']:disabled,\n[type='button']:disabled,\n[type='reset']:disabled,\n[type='submit']:disabled,\nbutton:disabled {\n  cursor: default;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
