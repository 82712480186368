import React from 'react'

import { DataGridPro } from '@mui/x-data-grid-pro'

import { useI18n } from 'shared/i18n'

import { APPLICANT_BUSINESS_OWNERSHIP_INTERNATIONAL_KEY } from '../../applicant-business-ownership-international.key'
import { applicantBusinessOwnershipInternationalEn } from '../../locale/applicant-business-ownership-international.en'
import { TShareCapitalGroup } from '../../model/applicant-business-ownership-international.model'
import { businessOwnerShipDataGridQa } from '../../qa/applicant-business-ownership-international.qa'
import { businessOwnershipDataGridSx } from '../business-ownership-data-grid-helper/business-ownership-data-grid-helper'
import {
  ShareCapitalDataGridCell,
  shareCapitalDataGridCellWidth,
  ShareCapitalDataGridColumn,
  ShareCapitalDataGridLoadingOverlay,
  ShareCapitalDataGridRow,
} from '../share-capital-data-grid-helper/share-capital-data-grid-helper'

type Props = {
  className?: string
  emptyText?: string
  isLoading: boolean
  shareCapital: TShareCapitalGroup
}

export function ShareCapitalDataGrid({
  className = '',
  emptyText,
  shareCapital,
  isLoading,
}: Props) {
  const t = useI18n([APPLICANT_BUSINESS_OWNERSHIP_INTERNATIONAL_KEY], {
    keys: applicantBusinessOwnershipInternationalEn,
  })

  const rows = shareCapital.shares ?? []
  const { totalShares } = shareCapital

  const columns: ShareCapitalDataGridColumn = [
    {
      field: 'classTitle',
      headerName: t('dataGridFields.classTitle'),
      ...shareCapitalDataGridCellWidth(335, true),
    },
    {
      field: 'classCode',
      headerName: t('dataGridFields.classCode'),
      ...shareCapitalDataGridCellWidth(130, true),
    },
    {
      field: 'sharesIssued',
      headerName: t('dataGridFields.sharesIssued'),
      ...shareCapitalDataGridCellWidth(130, true),
    },
    {
      field: 'totalAmountDueAndPayable',
      headerName: t('dataGridFields.totalAmountDueAndPayable'),
      ...shareCapitalDataGridCellWidth(450),
    },
  ]

  return (
    <div
      className={`${className}`}
      data-qa={businessOwnerShipDataGridQa.container}
    >
      <div className="text-right text-md mt-[-24px]">
        <span className={totalShares ? 'font-bold' : 'font-medium'}>
          {t('dataGridFields.totalShares')}
        </span>
        {': '}
        <span>{totalShares ?? '-'}</span>
      </div>
      <DataGridPro
        data-qa={businessOwnerShipDataGridQa.dataGrid}
        getRowId={row => row.classTitle ?? ''}
        className={`[&_.MuiDataGrid-overlayWrapperInner]:text-tertiary-grey-700 ${
          !rows.length && !isLoading
            ? '[&_.MuiDataGrid-virtualScroller]:h-[60px]'
            : ''
        }`}
        rows={rows}
        slots={{
          row: ShareCapitalDataGridRow,
          loadingOverlay: ShareCapitalDataGridLoadingOverlay,
          noRowsOverlay: () =>
            emptyText ?? t('shareCapital.emptyText.shareCapital'),
        }}
        columns={columns.map(item => ({
          renderCell: ShareCapitalDataGridCell,
          headerClassName:
            '!p-0 text-xs !border-neutral-30 font-semibold bg-neutral-30 text-tertiary-grey-500 !outline-none',
          cellClassName: '!outline-none !border-neutral-30 text-sm break-words',
          sortable: false,
          hideSortIcons: true,
          resizable: false,
          disableColumnMenu: true,
          disableReorder: true,
          ...item,
        }))}
        loading={isLoading}
        isRowSelectable={() => false}
        isCellEditable={() => false}
        getRowHeight={() => 'auto'}
        columnHeaderHeight={45}
        disableRowSelectionOnClick
        disableColumnSelector
        disableColumnFilter
        disableColumnMenu
        disableDensitySelector
        showCellVerticalBorder={false}
        showColumnVerticalBorder={false}
        hideFooterPagination
        hideFooter
        hideFooterSelectedRowCount
        autoHeight
        autoPageSize
        sx={businessOwnershipDataGridSx}
      />
    </div>
  )
}
