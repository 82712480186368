import { useMutation, useQueryClient } from '@tanstack/react-query'

import type { IUser } from 'entities/user'

import { userManagementApi } from '../../api/user-management.api'
import type { UpdateUserArguments, CreateUserRequestBody } from '../../model'
import { UserListQueryKey } from '../user-list/user-list.query'

export const useUpdateUserMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (createUserArguments: UpdateUserArguments) => {
      const apiArgumements: CreateUserRequestBody = {
        userData: {
          realname: createUserArguments.fullName,
          username: createUserArguments.email,
          email: createUserArguments.email,
          roleIds: createUserArguments.roleIds,
        },
      }

      return userManagementApi.updateUser(
        createUserArguments.userId,
        apiArgumements,
      )
    },
    onSuccess: ({ data: updatedUser }) => {
      queryClient.setQueryData<IUser[]>(UserListQueryKey, oldData => {
        if (!oldData) return [updatedUser]

        return oldData.map(user =>
          user.id === updatedUser.id ? updatedUser : user,
        )
      })
    },
  })
}
