import { useMutation, useQueryClient } from '@tanstack/react-query'

import {
  NOTIFICATIONS_MANAGEMENT_KEY,
  notificationsManagementEn,
} from 'features/notifications-management/locale/notifications-management.en'

import { NotificationSettingsConfigKey } from 'entities/user'

import { replaceAll } from 'shared/array'
import { useI18n } from 'shared/i18n'
import { notification } from 'shared/notification'

import { notificationsManagementApi } from '../../api/notifications-management.api'
import {
  INotificationSetting,
  INotificationSettingsConfig,
} from '../../model/notifications-management.model'

export const useChangeNotificationSettingsMutation = () => {
  const t = useI18n([NOTIFICATIONS_MANAGEMENT_KEY], {
    keys: notificationsManagementEn,
  })
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async ({
      newSettings,
    }: {
      newSettings: INotificationSetting[]
    }) => {
      const oldConfig = queryClient.getQueryData<INotificationSettingsConfig>(
        NotificationSettingsConfigKey,
      )
      if (oldConfig) {
        queryClient.setQueryData<INotificationSettingsConfig>(
          NotificationSettingsConfigKey,
          {
            ...oldConfig,
            notificationSettings: replaceAll(
              oldConfig.notificationSettings,
              newSettings,
              setting => `${setting.type}${setting.channel}`,
            ),
          },
        )
      }
      await notificationsManagementApi.changeNotificationSettings(newSettings)
    },
    /**
     * Rollback query changes
     * Unhandled issue: when there are more than one async requests, they can
     * fail in any order and second error can override first error changes
     */
    onError: (_, { newSettings }) => {
      const oldConfig = queryClient.getQueryData<INotificationSettingsConfig>(
        NotificationSettingsConfigKey,
      )
      notification.error(t('error'))

      if (oldConfig) {
        const newConfig = {
          ...oldConfig,
          notificationSettings: replaceAll(
            oldConfig.notificationSettings,
            newSettings.map<INotificationSetting>(setting => ({
              ...setting,
              value: !setting.value,
            })),
            setting => `${setting.type}${setting.channel}`,
          ),
        }

        queryClient.setQueryData<INotificationSettingsConfig>(
          NotificationSettingsConfigKey,
          newConfig,
        )
      }
    },
    onSuccess: () => notification.success(t('success')),
  })
}
