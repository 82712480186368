import { useInfiniteQuery } from '@tanstack/react-query'

import { TrackingEventsTypes, trackingManager } from 'shared/tracking'

import { auditReportAPI } from '../api/audit-report.api'
import { AuditReportSourceOptionsTypes } from '../model/individual-audit-report-model'

type AuditReportFilterTypes = {
  type: AuditReportSourceOptionsTypes
}

const AUDIT_REPORT_F2_QUERY_KEY = 'audit-report-f2'

export type Args = {
  filters?: AuditReportFilterTypes
  entityId: string
}

const LIMIT = 30

export const useAuditReportData = ({ entityId }: Args) => {
  const { data, fetchNextPage, isFetchingNextPage, status, hasNextPage } =
    useInfiniteQuery({
      queryKey: [AUDIT_REPORT_F2_QUERY_KEY, { entityId }],
      queryFn: async ({ pageParam }: { pageParam?: number }) => {
        const res = await auditReportAPI.getAuditEvents(entityId, {
          page: pageParam,
          limit: LIMIT,
        })

        const allData = res.data.modifiedEvents

        const { count, page, limit } = res.data.meta
        const nextPage = count <= limit ? undefined : page + 1

        return {
          data: allData,
          events: res.data.events,
          currentPage: pageParam || 0,
          nextPage,
        }
      },
      getNextPageParam: lastPage => lastPage.nextPage,
      onError: () => {
        trackingManager.track(TrackingEventsTypes.IndividualAuditReportError)
      },
    })

  return { ...data, fetchNextPage, isFetchingNextPage, status, hasNextPage }
}

export const useAllAuditReportData = ({ entityId }: Args) => {
  const { status, pages } = useAuditReportData({
    entityId,
  })

  return { data: pages?.flatMap(i => i.events), status }
}
