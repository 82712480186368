import React, { useEffect } from 'react'

import { FrankieButton, FrankieIcon } from 'frankify/src'

import { useHasFeatureFlag } from 'entities/session'

import { useI18n } from 'shared/i18n'
import { TrackingEventsTypes, trackingManager } from 'shared/tracking'

import {
  ENTITIES_TABLE_KEY,
  entitiesTableEn,
} from '../../locale/entities-table.en'

type Props = {
  trackingEvent?: {
    view: TrackingEventsTypes
    giveFeedback: TrackingEventsTypes
  }
}
export function EntitiesTableNoRowsPlaceholder({ trackingEvent }: Props) {
  const t = useI18n([ENTITIES_TABLE_KEY], { keys: entitiesTableEn })

  const { featureFlag } = useHasFeatureFlag({
    featureFlag: 'frankie2customer',
  })

  const handleGiveFeedback = () => {
    if (trackingEvent) {
      trackingManager.track(trackingEvent.giveFeedback)
    }
  }

  useEffect(() => {
    if (trackingEvent) {
      trackingManager.track(trackingEvent.view)
    }
  }, [trackingEvent])

  return (
    <div className="flex align-center flex-col w-full h-full bg-mono-white">
      <div className="mt-12">
        <FrankieIcon
          size="xl"
          name="mdiMagnify"
          className="text-primary-300 flex justify-center"
        />
        <div className="flex flex-col items-center mt-3">
          <span className="text-lg font-bold inline-block leading-6">
            {t('noMatchTitle')}
          </span>
          <span
            className={`text-sm font-normal max-w-[430px] text-center mt-3 leading-6 ${
              featureFlag ? 'whitespace-pre-wrap' : ''
            }`}
          >
            {t('noMatchSubtitle')}
          </span>
          <FrankieButton
            noStyles
            className="text-primary-800 text-sm block mt-3"
            onClick={handleGiveFeedback}
          >
            {t('giveFeedback')}
          </FrankieButton>
        </div>
      </div>
    </div>
  )
}
