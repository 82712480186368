import { useEffect } from 'react'

import { useQuery, useQueryClient } from '@tanstack/react-query'

import { useGetWorkflowEventsData } from 'entities/entity'
import { PermissionTypes } from 'entities/role'
import { useHasFeatureFlag, useHasPermission } from 'entities/session'

import { Nullable } from 'shared/typescript'

import {
  failVariants,
  reviewVariants,
} from '../model/applicant-workflow-events.model'

const SHOW_SEND_LINK_BANNER_QUERY_KEY = 'showSendLinkBanner'

export const useSendLinkBannerState = (entityId: string) => {
  const { data } = useGetWorkflowEventsData({ entityId })

  const { hasPermissionToSendLink } = useHasPermission({
    hasPermissionToSendLink: PermissionTypes.ApplicantTriggerExternalIDV,
  })

  const { hasTwilioEnabled } = useHasFeatureFlag({
    hasTwilioEnabled: ['twilioSubAccountSid'],
  })

  const queryClient = useQueryClient()

  const { data: showSendLinkBanner } = useQuery({
    initialData: null,
    queryKey: [SHOW_SEND_LINK_BANNER_QUERY_KEY, { entityId }],
    queryFn: () =>
      queryClient.getQueryData<Nullable<boolean>>([
        SHOW_SEND_LINK_BANNER_QUERY_KEY,
        { entityId },
      ]),
  })

  useEffect(() => {
    const workflowEventResult = data?.workflowSummaries?.at(0)

    const showSendLinkBannerNewState =
      workflowEventResult?.workflowResultData?.workflowStepResults
        ?.filter(
          step =>
            step.stepName &&
            ['IDV', 'OCR'].includes(step.stepName) &&
            !step.statusOverrideAt &&
            !workflowEventResult.statusOverrideAt,
        )
        .some(step =>
          [...failVariants, ...reviewVariants].includes(step.result),
        )

    if (showSendLinkBanner === null) {
      queryClient.setQueryData(
        [SHOW_SEND_LINK_BANNER_QUERY_KEY, { entityId }],
        showSendLinkBannerNewState,
      )
    }
  }, [data, entityId, queryClient, showSendLinkBanner])

  const closeSendLinkBanner = () => {
    queryClient.setQueryData(
      [SHOW_SEND_LINK_BANNER_QUERY_KEY, { entityId }],
      false,
    )
  }

  return {
    canSendLink: hasPermissionToSendLink,
    hasTwilioEnabled,
    showSendLinkBanner,
    closeSendLinkBanner,
  }
}
