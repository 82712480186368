import React from 'react'

import { Trans } from 'react-i18next'
import { NavLink } from 'react-router-dom'

import { FrankieIcon } from 'frankify/src'

import { useApplicantRoute } from 'entities/routing'

import { useI18n } from 'shared/i18n'

import { CUSTOM_VIEWS_KEYS } from '../../custom-view.key'
import { customViewEn } from '../../locale/custom-view.en'

export function CustomViewAccessDenied() {
  const t = useI18n([CUSTOM_VIEWS_KEYS], { keys: customViewEn })
  const { generateRoute } = useApplicantRoute()

  return (
    <div className="flex justify-center items-center h-full">
      <div className="flex flex-col items-center gap-3">
        <div>
          <FrankieIcon
            name="mdiCloseCircle"
            size="xl"
            className="text-tertiary-red-500 "
          />
        </div>
        <div className="text-tertiary-grey-800 font-bold text-lg">
          {t('accessDenied.accessDenied')}
        </div>
        <div className="text-tertiary-grey-800 text-sm text-center">
          {t('accessDenied.permissionText')}
        </div>
        <div className="text-tertiary-grey-800 text-sm">
          <Trans
            components={{
              a: (
                <NavLink
                  to={generateRoute({ routeKey: 'applicants' })}
                  className="text-primary-800"
                />
              ),
            }}
          >
            {t('accessDenied.goToEntityPage')}
          </Trans>
        </div>
      </div>
    </div>
  )
}
