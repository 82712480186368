import React from 'react'

import { FrankieButton } from 'frankify/src'

import { ApplicantId } from 'entities/applicant'
import { PermissionTypes } from 'entities/role'
import { useHasPermission } from 'entities/session'

import { useI18n } from 'shared/i18n'

import { APPLICANT_AUDIT_REPORT_KEY } from '../../applicant-audit-report.key'
import { applicantAuditReportEn } from '../../locale/applicant-audit-report.en'
import { applicantAuditReportQa } from '../../qa/applicant-audit-report.qa'
import { useDownloadAuditReportQuery } from '../../state/applicant-download-audit-report/applicant-download-audit-report'
import { ApplicantAuditTable } from '../applicant-audit-table/applicant-audit-table'

type Props = {
  applicantId: ApplicantId
  className?: string
}

export function ApplicantAuditReport({ applicantId, className = '' }: Props) {
  const t = useI18n([APPLICANT_AUDIT_REPORT_KEY], {
    keys: applicantAuditReportEn,
  })

  const { downloadReport } = useDownloadAuditReportQuery({ applicantId })

  // Permission check for the audit download button
  const { canDownloadAuditReport } = useHasPermission({
    canDownloadAuditReport: PermissionTypes.AuditReportDownload,
  })

  return (
    <div className={className} data-qa={applicantAuditReportQa.main}>
      <div className="flex justify-between mb-4">
        <div className="">
          <div className="mb-1 font-bold text-xl">{t('auditReport')}</div>
          <div
            className="text-sm text-secondary-900"
            data-qa={applicantAuditReportQa.text}
          >
            {t('allActivityRecord')}
          </div>
        </div>

        {canDownloadAuditReport && (
          <FrankieButton
            className="!bg-mono-100 !outline-mono-50"
            onClick={() => downloadReport()}
            size="xs"
            testId={{ button: applicantAuditReportQa.btn }}
          >
            {t('downloadReport')}
          </FrankieButton>
        )}
      </div>

      <ApplicantAuditTable applicantId={applicantId} />
    </div>
  )
}
