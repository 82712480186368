import React from 'react'

import { customViewControlsQa } from '../../qa/custom-view.qa'
import { CustomViewCreate } from '../custom-view-create/custom-view-create'
import { CustomViewEditCta } from '../custom-view-edit-cta/custom-view-edit-cta'

type Props = {
  noRows: boolean
}
export function CustomViewControls({ noRows }: Props) {
  return (
    <span data-qa={customViewControlsQa.container}>
      <CustomViewCreate noRows={noRows} />
      <CustomViewEditCta />
    </span>
  )
}
