import { useI18n } from 'shared/i18n'

import { EDIT_BUSINESS_KEY } from '../edit-busines.key'

export const editBusinessEn = {
  heading: 'General Information',
  customerId: 'Customer ID',
  merchantCategory: 'Merchant category details',
  optional: 'optional',
  select: 'Select',
  save: 'Save Changes',
  cancel: 'Cancel',

  error: {
    updateMcc: 'Failed to update MCC code',
  },
  success: {
    updateBusiness: '{{businessName}} data has been updated',
    riskScoreUpdated: 'Profile risk score has been updated',
  },
}

export const useEditBusinessTranslation = () => {
  const t = useI18n([EDIT_BUSINESS_KEY], { keys: editBusinessEn })

  return { t }
}
