import React from 'react'

import { useInView } from 'react-intersection-observer'

import { FrankieBadge, FrankieButton, FrankieDivider } from 'frankify/src'

import { WorkflowStatusKeysTypes } from 'entities/entity'

import { useI18n } from 'shared/i18n'
import { useOverlay } from 'shared/overlay'
import { TrackingEventsTypes, useTrackingShowEvents } from 'shared/tracking'

import { ManualStatusChangeForm } from './individual-idv-form/individual-idv-form'
import { INDIVIDUAL_IDV_CHECK_KEY } from '../../individual-idv-check.key'
import { individualIdvCheckEn } from '../../locale/individual-idv-check.en'
import {
  borderStyleConfig,
  IconConfig,
} from '../../model/individual-idv-check.model'
import { useIndividualIdvCheckState } from '../../state/individual-idv-check/individual-idv-check.state'
import { IDVDocumentRow } from '../individual-idv-check-document-row/individual-idv-check-document-row'

type Props = {
  entityId: string
  idx: number
}

export function IndividualIdvCheck({ entityId, idx }: Props) {
  const {
    hadIdvChecks,
    status,
    documents,
    isManualFail,
    isManualPass,
    requireResolve,
  } = useIndividualIdvCheckState({
    entityId,
    idx,
  })

  const [createOverlay, closeOverlay] = useOverlay()

  const t = useI18n([INDIVIDUAL_IDV_CHECK_KEY], { keys: individualIdvCheckEn })

  function handleClick() {
    createOverlay(
      <ManualStatusChangeForm
        options={[
          {
            label: t('manualPassStatus'),
            value: WorkflowStatusKeysTypes.MANUALLY_PASSED,
          },
          {
            label: t('manualFailStatus'),
            value: WorkflowStatusKeysTypes.MANUALLY_FAILED,
          },
        ]}
        closeOverlay={closeOverlay}
        updateWorkflowStatus={() => {}}
      />,
      {
        closeButtonClassName: '!top-6 !right-6',
        className: '!p-6',
      },
    )
  }
  const { ref, inView } = useInView()

  useTrackingShowEvents({
    data: hadIdvChecks && inView,
    eventsType: TrackingEventsTypes.WorkflowEventsDocumentShow,
  })

  if (!hadIdvChecks) return null

  return (
    <div
      id="IDV"
      ref={ref}
      className={`border rounded-1.5 flex flex-col gap-4 pt-5 px-6 pb-6 ${borderStyleConfig(
        status,
      )}`}
    >
      <div className="flex items-center justify-between">
        <div className="text-xl text-tertiary-grey-800 gap-2 font-bold flex items-center">
          <IconConfig type={status} />
          {t('title')}
          {isManualFail && <FrankieBadge theme="red" text={t('manualFail')} />}
          {isManualPass && (
            <FrankieBadge theme="green" text={t('manualPass')} />
          )}
          {requireResolve && (
            <FrankieButton
              noStyles
              className="ml-auto !mt-1 text-sm text-primary-800"
              onClick={() => handleClick()}
            >
              {t('resolve')}
            </FrankieButton>
          )}
        </div>
      </div>

      {documents.map((document, idx) => (
        <>
          {idx !== 0 && <FrankieDivider className="my-4" />}
          <IDVDocumentRow documentInfo={document} />
        </>
      ))}
    </div>
  )
}
