import React from 'react'

import { UseFormRegister } from 'react-hook-form'

import { FrankieTextField } from 'frankify/src'

import { useI18n } from 'shared/i18n'

import { APPLICANT_SUPPORTING_DOCUMENTS_KEY } from '../../../applicant-supporting-documents.key'
import { applicantSupportingDocumentsEn } from '../../../locale/applicant-supporting-documents.en'
import {
  ITrustDeedForm,
  TrustFormTypes,
} from '../../../model/applicant-supporting-trust-deed.model'
import { trustAnalyserEditFormQa } from '../../../qa/applicant-support-documents.qa'

type TrustAnalyserNameFormDetailProps = {
  register: UseFormRegister<ITrustDeedForm>
  formKey: TrustFormTypes
  index: number
}

export function TrustAnalyserNameFormDetail({
  register,
  formKey,
  index,
}: TrustAnalyserNameFormDetailProps) {
  const t = useI18n([APPLICANT_SUPPORTING_DOCUMENTS_KEY], {
    keys: applicantSupportingDocumentsEn,
  })

  if (formKey === TrustFormTypes.GeneralBeneficiary) return null

  return (
    <div className="grid grid-cols-3 gap-4 min-h-[7rem]">
      <FrankieTextField
        {...register(`${formKey}.${index}.detail.name.givenName`)}
        label={t('trustFormLabel.name.givenName')}
        testId={{
          input: trustAnalyserEditFormQa.givenName(formKey, index + 1),
        }}
        data-hj-suppress
      />
      <FrankieTextField
        {...register(`${formKey}.${index}.detail.name.middleName`)}
        label={t('trustFormLabel.name.middleName')}
        testId={{
          input: trustAnalyserEditFormQa.middleName(formKey, index + 1),
        }}
        data-hj-suppress
      />
      <FrankieTextField
        {...register(`${formKey}.${index}.detail.name.familyName`)}
        label={t('trustFormLabel.name.familyName')}
        testId={{
          input: trustAnalyserEditFormQa.familyName(formKey, index + 1),
        }}
        data-hj-suppress
      />
    </div>
  )
}
