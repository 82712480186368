export enum AustralianStateCodeTypes {
  NSW = 'NSW',
  VIC = 'VIC',
  QLD = 'QLD',
  TAS = 'TAS',
  SA = 'SA',
  WA = 'WA',
  NT = 'NT',
  ACT = 'ACT',
}

type AustralianState = {
  alpha3Code: AustralianStateCodeTypes
  name: string
}

export const australianState: AustralianState[] = [
  { alpha3Code: AustralianStateCodeTypes.NSW, name: 'New South Wales' },
  { alpha3Code: AustralianStateCodeTypes.VIC, name: 'Victoria' },
  { alpha3Code: AustralianStateCodeTypes.QLD, name: 'Queensland' },
  { alpha3Code: AustralianStateCodeTypes.TAS, name: 'Tasmania' },
  { alpha3Code: AustralianStateCodeTypes.SA, name: 'South Australia' },
  { alpha3Code: AustralianStateCodeTypes.WA, name: 'Western Australia' },
  { alpha3Code: AustralianStateCodeTypes.NT, name: 'Northern Territory' },
  {
    alpha3Code: AustralianStateCodeTypes.ACT,
    name: 'Australian Capital Territory',
  },
]
