import { DATA_GRID_KEY, dataGridEn } from 'shared/data-grid'
import { FILTERS_COMMON_KEY, filtersCommonEn } from 'shared/filters-common'
import { NUMBERS_LOCALE, numbersEn } from 'shared/i18n'
import { TABLES_COMMON_KEY, tablesCommonEn } from 'shared/tables-common'
import { VALIDATION_LOCALE, validationEn } from 'shared/validation'

export const shared = {
  [VALIDATION_LOCALE]: validationEn,
  [NUMBERS_LOCALE]: numbersEn,
}

export const sharedSlices = {
  [TABLES_COMMON_KEY]: tablesCommonEn,
  [FILTERS_COMMON_KEY]: filtersCommonEn,
  [DATA_GRID_KEY]: dataGridEn,
}
