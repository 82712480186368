import React, { useEffect, useMemo } from 'react'

import { FrankieDivider } from 'frankify/src'

import { ApplicantId } from 'entities/applicant'
import {
  useEntityDataQuery,
  useGetWorkflowEventsData,
  WorkflowExecutionResultEnum,
  WorkflowStepResultEnum,
} from 'entities/entity'
import { useFilterWorkflow } from 'entities/workflow'

import { TrackingEventsTypes, trackingManager } from 'shared/tracking'

import { failVariants } from '../../model/applicant-workflow-events.model'
import { entityVerificationEventsQa } from '../../qa/applicant-workflow-events.qa'
import {
  useGetWorkFlowDataWithSources,
  useOrderWithFailedAndPassed,
} from '../../state/get-workflow-events.query'
import { VerificationDocumentCard } from '../entity-verification-document-card/entity-verification-document-card'
import { VerificationHeader } from '../entity-verification-header/entity-verification-header'
import { PersonalInfoSection } from '../entity-verification-personal-info-section/entity-verification-personal-info-section'

type PropsType = {
  entityId: ApplicantId
}

function borderStyleConfig({
  eventResult,
}: {
  eventResult: WorkflowStepResultEnum
}) {
  if (failVariants.includes(eventResult)) return 'border-tertiary-red-500'

  switch (eventResult) {
    case WorkflowStepResultEnum.UNCHECKED:
      return 'border-tertiary-grey-500'
    default:
      return 'border-tertiary-grey-200'
  }
}

export function EntityVerificationEvents({ entityId }: PropsType) {
  const { isLoading } = useEntityDataQuery(entityId, 'base64')
  const { data: workflow } = useGetWorkflowEventsData({
    entityId,
  })

  const workFlowData = useFilterWorkflow(workflow)

  const { docsToBeDisplayed, isManuallyVerified, isArchived } =
    useGetWorkFlowDataWithSources({
      entityId,
      docR1: true,
    })

  const { eventStepper } = useOrderWithFailedAndPassed({
    entityId,
  })

  const eventResult = useMemo(
    () =>
      workFlowData.workflowSummaries
        .at(0)
        ?.workflowResultData?.workflowStepResults?.find(
          event => event.stepName === 'KYC',
        )?.result || WorkflowStepResultEnum.UNCHECKED,
    [workFlowData],
  )

  useEffect(() => {
    if (workflow) {
      const summary = workFlowData.workflowSummaries.at(0)
      trackingManager.trackWithProps(
        TrackingEventsTypes.WorkflowEventEntityVerificationShowIndividual,
        {
          entityId,
          status: summary?.status,
          issues: summary?.issues?.map(issue => issue.issue),
          workflowExecutionId: summary?.workflowExecutionId,
          workflowName: summary?.workflowName,
        },
      )
    }
  }, [entityId, workFlowData])

  const kycVisible = useMemo(
    () =>
      (workFlowData.workflowSummaries.at(0)?.status ??
        WorkflowExecutionResultEnum.UNCHECKED) ===
        WorkflowExecutionResultEnum.UNCHECKED ||
      eventStepper.some(step => step.eventName === 'KYC'),
    [workFlowData.workflowSummaries, eventStepper],
  )

  if (isLoading) return null
  return (
    <div className="grow-0" data-qa={entityVerificationEventsQa.container}>
      {workflow && kycVisible && (
        <div
          data-qa={entityVerificationEventsQa.verificationHeaderSectionWrapper}
          className={`border rounded-1.5 flex flex-col pt-5 px-6 pb-6 ${borderStyleConfig(
            { eventResult },
          )}`}
        >
          <VerificationHeader
            isArchived={isArchived}
            isManuallyVerified={isManuallyVerified}
            eventResult={eventResult}
          />
          <PersonalInfoSection entityId={entityId} eventResult={eventResult} />
          {docsToBeDisplayed?.map(doc => (
            <React.Fragment key={doc.documentId ?? doc.sourceId}>
              <FrankieDivider className="my-8" />
              <VerificationDocumentCard data={doc} />
            </React.Fragment>
          ))}
        </div>
      )}
    </div>
  )
}
