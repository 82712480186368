import React, { useMemo } from 'react'

import { ApplicantId } from 'entities/applicant'
import { PermissionTypes } from 'entities/role'
import { useHasFeatureFlag, useHasPermission } from 'entities/session'

import { SelectFormField, TextAreaFormField } from 'shared/form'
import { useI18n } from 'shared/i18n'
import { Noop } from 'shared/typescript'

import { APPLICANT_GENERAL_INFORMATION_KEY } from '../../../../applicant-general-information.key'
import { applicantGeneralInformationEn } from '../../../../locale/applicant-general-information.en'
import { changeApplicationStatusQa } from '../../../../qa/applicant-general-information.qa'
import { useApplicantCheckSummaryState } from '../../../../state/applicant-check-summary-state/applicant-check-summary-state'
import {
  useApplicantProfileStatusChangeMutation,
  ProfileStatusChangeData,
} from '../../../../state/applicant-profile-state-change/applicant-profile-state-change'
import { ApplicantActionModalWrapper } from '../../applicant-action-modal-wrapper/applicant-action-modal-wrapper'

type Props = { applicantId: ApplicantId; handleClose: Noop }

export function ChangeApplicantStatus({ applicantId, handleClose }: Props) {
  const t = useI18n([APPLICANT_GENERAL_INFORMATION_KEY], {
    keys: applicantGeneralInformationEn,
  })

  const { canOverrideProfileStatus } = useHasPermission({
    canOverrideProfileStatus:
      PermissionTypes.ApplicantProfileAllowStatusOverride,
  })

  const { alwaysAllowManualPass } = useHasFeatureFlag({
    alwaysAllowManualPass: 'alwaysAllowManualPass',
  })

  const { entityHasUncheckedChecks, isBusinessProfile } =
    useApplicantCheckSummaryState({
      applicantId,
    })

  const { mutate, isLoading, isSuccess } =
    useApplicantProfileStatusChangeMutation({
      applicantId,
    })

  const stateOptions = useMemo(() => {
    const options: Array<{
      label: string
      value: ProfileStatusChangeData['state']
    }> = [{ label: t('action.fields.manuallyFailed'), value: 'fail' }]

    if (
      !entityHasUncheckedChecks ||
      (canOverrideProfileStatus && alwaysAllowManualPass)
    ) {
      options.unshift({
        label: t('action.fields.manuallyPassed'),
        value: 'pass',
      })
    }

    return options
  }, [
    alwaysAllowManualPass,
    canOverrideProfileStatus,
    entityHasUncheckedChecks,
    t,
  ])

  return (
    <ApplicantActionModalWrapper<ProfileStatusChangeData>
      heading={t('action.heading.changeStatus')}
      iconName={
        isBusinessProfile ? 'mdiOfficeBuildingOutline' : 'mdiAccountOutline'
      }
      isProgress={isLoading}
      isSuccess={isSuccess}
      onClose={handleClose}
      onSubmit={mutate}
      submitText={t('action.changeStatus')}
      confirmation={{
        description: t('action.description.confirmChangeStatus'),
        confirmText: t('action.confirm.changeStatus'),
      }}
      renderForm={({ control }) => (
        <>
          <div>{t('action.description.changeStatus')}</div>

          <SelectFormField
            className="max-w-[60%]"
            label={t('action.fields.selectProfileStatus')}
            options={stateOptions}
            control={control}
            name="state"
            rules={{ required: true }}
            testId={{ input: changeApplicationStatusQa.select }}
          />

          <TextAreaFormField
            label={t('action.fields.comment')}
            placeholder={t('action.fields.commentPlaceholder')}
            control={control}
            name="comment"
            rules={{ required: true }}
            testId={{ input: changeApplicationStatusQa.textarea }}
          />
        </>
      )}
    />
  )
}
