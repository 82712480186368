import React, { useEffect } from 'react'

import { Navigate } from 'react-router-dom'

import { FrankieLoader } from 'frankify/src'

import { logout } from 'features/logout'

import { TPath, useGetPathValue } from 'entities/session'
import { useSSOlogin } from 'entities/sso'

export function Redirect({ to }: { to: TPath }): JSX.Element {
  const { queryEndSession, ssoLoginAssessed } = useSSOlogin()

  const { getPathValue, isLoading } = useGetPathValue()

  // If the SSO login has been assessed and it failed, we should logout
  useEffect(() => {
    if (queryEndSession || ssoLoginAssessed === 'SSO-Failed') {
      logout()
    }
  }, [queryEndSession, ssoLoginAssessed])

  // If the SSO login has not been assessed yet, we should not redirect
  if (!isLoading && ssoLoginAssessed !== 'SSO-Not-Assessed') {
    return <Navigate to={getPathValue(to)} replace />
  }

  return <FrankieLoader loading fullscreen />
}
