import { useQuery } from '@tanstack/react-query'

import { isNotEmptyString } from 'shared/typescript'

import { individualApiF2 } from '../api/individual-f2.api'

export const GetIndividualAddressQueryKey = 'get-individual-address-f2'

export const useGetIndividualFormData = (entityId?: string) =>
  useQuery({
    queryKey: [GetIndividualAddressQueryKey, entityId],
    queryFn: async () => {
      if (!entityId) return undefined
      const { data } = await individualApiF2.getIndividualFormValue(entityId)
      return data
    },
    enabled: isNotEmptyString(entityId),
  })
