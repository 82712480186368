import React from 'react'

/**
 * The `useDebounce` function is used to debounce a value with a specified delay.
 * @param {T} value - The `value` parameter represents the value that you want to debounce. This can be
 * of any type `T` that you specify when calling the `useDebounce` function.
 * @param {number} delay - The `delay` parameter specifies the amount of time in milliseconds to wait
 * before updating the debounced value with the latest `value`.
 * @returns The `useDebounce` function returns the debounced value.
 */
export function useDebounce<T>(value: T, delay: number): T {
  const [debouncedValue, setDebouncedValue] = React.useState(value)

  React.useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    // Cleanup function to clear the timeout if the value changes or component unmounts
    return () => {
      clearTimeout(handler)
    }
  }, [value, delay])

  return debouncedValue
}
