import { createContext } from 'react'

import { IOverlayProps } from '../model/overlay.model'

export interface IOverlayContext {
  overlayStack: IOverlayProps[]
  setOverlayStack: (
    overlayStack:
      | IOverlayProps[]
      | ((prev: IOverlayProps[]) => IOverlayProps[]),
  ) => void
}

export const OverlayContext = createContext<IOverlayContext>({
  overlayStack: [],
  setOverlayStack: () => {
    console.error('OverlayContext is not provided')
  },
})
