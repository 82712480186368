import DOMPurify from 'dompurify'

/**
 * @description Function uses a regular expression to remove any <script> tags from the HTML content.
 * Use library like DOMPurify for sanitizing HTML content. {@link https://www.npmjs.com/package/dompurify}
 */
export const sanitizeHTML = (htmlString: string) => {
  const sanitizedString = DOMPurify.sanitize(htmlString)

  return sanitizedString
}
