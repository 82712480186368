import { ServiceProfile } from 'entities/entity'
import {
  EntityType,
  RiskLevel,
  ServiceProfileState,
  WorkflowExecutionResultEnum,
  WorkflowExecutionStateEnum,
} from 'entities/entity/model/entity.model'

export const mockWorkflowEventsData: ServiceProfile = {
  serviceProfileId: '55fc5d54-46f7-49c8-bf78-e07ec79c42fd',
  entityId: 'example-entity-id',
  entityName: 'Example Entity',
  entityType: 'Example Type',
  state: ServiceProfileState.ACTIVE,
  organizationType: 'SOLE TRADER',
  country: 'AUS',
  schemaVersion: 2,
  createdAt: '2024-01-15T02:18:11.365Z',
  createdRequestId: '01HMR2ERSKCYAVXKS1FKSRT2T4',
  createdBy: 'Ryan Reynolds',
  updatedAt: '2024-01-15T02:18:11.365Z',
  updatedRequestId: '01HMR2ERSKCYAVXKS1FKSRT2T4',
  updatedBy: 'Ryan Reynolds',
  assignee: 'Ryan Gosling',
  reviewer: 'Ryan Trahan',
  currentWorkflowId: 'example-workflow-id',
  currentWorkflowName: 'Example Workflow',
  workflowSummaries: [
    {
      schemaVersion: 1,
      workflowName: 'Example Workflow Name',
      workflowExecutionId: 'example-workflow-execution-id',
      workflowExecutionState: WorkflowExecutionStateEnum.IN_PROGRESS,
      status: WorkflowExecutionResultEnum.PASS,
      statusOverrideAt: '2024-01-15T02:18:11.365Z',
      statusOverrideRequestId: '01HMR2ERSKCYAVXKS1FKSRT2T4',
      statusOverrideBy: 'Ryan Reynolds',
      riskAssessment: {
        serviceProfileId: 'test id',
        riskAssessmentId: 'test risk id',
        riskLevel: RiskLevel.UNACCEPTABLE,
        workflowId: 'test work id',
        workflowExecutionId: 'test flow id',
        entityId: 'test entity id',
      },
      isManual: false,
      steps: {
        order: ['START', 'AML', 'RISK', 'FINISH'],
        passed: ['AML'],
        failed: [],
        incomplete: [],
      },
      workflowAttempts: 3,
      workflowResultData: {
        result: WorkflowExecutionResultEnum.APPROVED,
        workflowExecutionId: 'test id',
        workflowId: 'test id',
        requestId: 'test id',
        entityId: 'test id',
        entityType: EntityType.INDIVIDUAL,
        schemaVersion: 1,
        startedAt: '12',
        serviceProfileId: 'test id',
        workflowName: 'INDIVIDUAL',
        workflowExecutionState: WorkflowExecutionStateEnum.COMPLETED,
        createdAt: '12',
      },
    },
  ],
  serviceName: 'Example Service',
}

export const mockWorkflowSummariesEventsData = {
  workflowSummaries: [
    {
      workflowExecutionId: '12345',
      workflowResult: 'PASS',
      riskAssessment: {
        riskLevel: 'LOW',
      },
      steps: {
        order: ['KYC', 'RISK', 'AML'],
        failed: [],
        passed: ['KYC', 'AML'],
      },
      workflowAttempts: 1,
    },
  ],
  currentWorkflowName: 'ENTITY_VERIFICATION',
  createdAt: '2023-06-01T12:34:56Z',
}

export const mockWorkflowDataWithSources = [
  {
    label: [
      {
        text: 'Document Label 1',
        sources: ['Source 1'],
      },
    ],
    extraData: [
      {
        text: 'Document Label 1',
        sources: ['Source 1'],
      },
    ],
    type: 'TYPE1',
  },
]

export const individualEntityResponse = {
  individual: {
    test: {
      sourceId: 'test source id',
      type: 'test type',
      source: 'test source',
    },
  },
  serviceProfiles: mockWorkflowEventsData,
  requestId: 'test request id',
}
