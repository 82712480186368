import React, { useState } from 'react'

import { Alert, AlertTitle } from '@mui/material'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'

import { AlertTitleStyle, classesTypeDictionary } from './alert.theme'
import { IFrankieAlertProps } from './alert.types'
import { FrankieIcon } from '../icon'

export function FrankieAlert({
  className = '',
  children,
  title,
  dismissible = false,
  testId = {
    alert: '',
    dismissible: '',
    close: '',
    success: '',
    error: '',
    warning: '',
    info: '',
    title: '',
  },
  type,
  warningIconClassName = 'text-tertiary-yellow-700',
  closeIconClassName = '',
  closeIconSize = 'sm',
  value = true,
  overrideIconName,
  onDismiss,
}: IFrankieAlertProps): JSX.Element {
  const [visibility, setVisibility] = useState(true)

  const iconTypeDictionary = {
    success: (
      <FrankieIcon
        name={overrideIconName || 'mdiCheckCircle'}
        className="text-tertiary-green-500"
        testId={{ icon: testId.success }}
      />
    ),
    error: (
      <FrankieIcon
        name={overrideIconName || 'mdiAlertCircle'}
        className="text-tertiary-red-700"
        testId={{ icon: testId.error }}
      />
    ),

    warning: (
      <FrankieIcon
        name={overrideIconName || 'mdiAlertCircle'}
        className={warningIconClassName}
        testId={{ icon: testId.warning }}
      />
    ),
    info: (
      <FrankieIcon
        name={overrideIconName || 'mdiInformation'}
        className="text-primary-700"
        testId={{ icon: testId.info }}
      />
    ),
  }

  const dismissibleAction = dismissible ? (
    <IconButton
      aria-label="close"
      color="inherit"
      size="small"
      disableFocusRipple
      disableRipple
      onClick={() => {
        setVisibility(false)
        if (onDismiss) {
          onDismiss()
        }
      }}
      data-qa={testId.dismissible}
    >
      <FrankieIcon
        className={closeIconClassName}
        name="mdiClose"
        size={closeIconSize}
        testId={{ icon: testId.close }}
      />
    </IconButton>
  ) : null

  return (
    <Collapse in={value && visibility}>
      <Alert
        severity={type}
        icon={iconTypeDictionary[type]}
        classes={{ root: `py-2 ${className}`, ...classesTypeDictionary }}
        action={dismissibleAction}
      >
        <div data-qa={testId.alert}>
          {title && (
            <AlertTitle
              data-qa={testId.title}
              className={AlertTitleStyle({ type })}
            >
              {title}
            </AlertTitle>
          )}
          {children}
        </div>
      </Alert>
    </Collapse>
  )
}
