import { useEffect, useRef } from 'react'

/**
 * Custom hook to cleanup cache when component unmounts but not when data changes.
 */
export function useCacheCleanup<TData>(
  data: TData,
  cleanup: (data: TData) => void,
) {
  const dataRef = useRef(data)

  useEffect(() => {
    dataRef.current = data
  }, [data])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => cleanup(dataRef.current), [])
}
