import { useCallback } from 'react'

import { useMutation } from '@tanstack/react-query'

import { ApplicantId, ChannelTypes, IChangeStamp } from 'entities/applicant'
import {
  DocumentStatusTypes,
  documentStatusOptions,
  DOCUMENT_KEY,
  documentEn,
} from 'entities/document'
import { organisationApi } from 'entities/organisation'
import { useSessionQuery } from 'entities/session'

import { useI18n } from 'shared/i18n'
import { notification } from 'shared/notification'

import { applicantSupportingDocumentApi } from '../../api/applicant-supporting-document.api'
import { APPLICANT_SUPPORTING_DOCUMENTS_KEY } from '../../applicant-supporting-documents.key'
import { applicantSupportingDocumentsEn } from '../../locale/applicant-supporting-documents.en'
import { SupportingDocumentPayload } from '../../model/applicant-supporting-documents.model'
import { useApplicantSupportingDocumentCommentsQuery } from '../applicant-supporting-document-comments-query/applicant-supporting-document-comments.query'
import { useApplicantSupportingDocumentQuery } from '../applicant-supporting-document-query/applicant-supporting-document.query'

type ManageDocumentType = 'new' | 'update' | 'status-change'

type Args<TType extends ManageDocumentType> = {
  applicantId: ApplicantId
  disableProfileDocCheckUpdate?: boolean
  manageType: TType
} & (TType extends 'new' ? { documentId?: string } : { documentId: string })

export const useManageApplicantSupportingDocument = <
  TType extends ManageDocumentType,
>({
  applicantId,
  disableProfileDocCheckUpdate = false,
  manageType,
  documentId,
}: Args<TType>) => {
  const t = useI18n([APPLICANT_SUPPORTING_DOCUMENTS_KEY], {
    keys: applicantSupportingDocumentsEn,
  })

  const tDoc = useI18n([DOCUMENT_KEY], {
    keys: documentEn,
  })

  const { refetch: refetchDocument } = useApplicantSupportingDocumentQuery({
    applicantId,
  })
  const { remove } = useApplicantSupportingDocumentCommentsQuery({
    applicantId,
    documentId,
    disabled: true,
  })

  const handleSuccess = (
    status?: DocumentStatusTypes,
    isStatusUpdated = false,
  ) => {
    void refetchDocument()
    void remove()

    if (manageType === 'status-change' || isStatusUpdated) {
      notification.info(
        t('documentStatusChange', {
          status: status ? tDoc(documentStatusOptions[status].tKey) : '',
        }),
      )
    } else {
      notification.success(
        manageType === 'new' ? t('documentUploaded') : t('documentUpdated'),
      )
    }

    /**
     * Updating profile check if document is uploaded successfully
     */
    if (!disableProfileDocCheckUpdate) {
      void organisationApi.checkAmlPep(applicantId, {
        forceCheck: false,
        uploadDocs: true,
        noInvalidate: false,
      })
    }
  }

  const handleError = () => {
    notification.error(
      manageType === 'new' ? t('errorUploadingDoc') : t('errorUpdatingDoc'),
    )
  }

  const mutation = useMutation({
    mutationFn: ({
      isStatusUpdated = false,
      ...data
    }: SupportingDocumentPayload & { isStatusUpdated?: boolean }) =>
      manageType === 'new'
        ? applicantSupportingDocumentApi.addApplicantSupportedDocument(
            applicantId,
            data,
          )
        : applicantSupportingDocumentApi.updateApplicantSupportedDocument(
            applicantId,
            documentId!,
            data,
          ),

    onSuccess: (_, data) =>
      handleSuccess(data.supportingDocument.status!, data.isStatusUpdated),
    onError: handleError,
  })

  return mutation
}

export const useDocumentStamp = () => {
  const { data: sessionData } = useSessionQuery()

  const getDocumentStamp = useCallback(() => {
    const timeStamp = new Date()

    const userName = sessionData?.user.username
    const userId = sessionData?.user.id

    return {
      by: userName,
      channel: ChannelTypes.PORTAL,
      timestamp: timeStamp.toISOString(),
      userId,
    } as IChangeStamp
  }, [sessionData])

  return getDocumentStamp
}
