import { DocumentTypes, Scan } from 'entities/document'

import {
  Nullable,
  NullableProperties,
  PartialRecord,
  SuggestionString,
} from 'shared/typescript'

import {
  BlocklistAttributeTypes,
  BlocklistReasonTypes,
} from './applicant-blocklist.model'
import { IApplicantFraudData } from './applicant-fraud-check.model'
import { TrustDeedTypes } from './applicant-trust-analyser.model'
import { sardineChecksRegex } from '../../check'

export type ApplicantFraudCheckResponse = {
  device?: Nullable<
    Array<{
      checkPerformedBy?: string
      resultNotes?: { device_sardine_url?: string }
    }>
  >
  fraud?: Nullable<unknown>
}

export type ApplicantRiskScore = 'MEDIUM' | 'LOW' | 'HIGH'

export type ApplicantTransactionalRiskType = 'aml' | 'fraud'

export type ApplicantRiskChecksResponse = {
  onboardingRisk: {
    result: {
      rating: number
      policy?: string
      score: ApplicantRiskScore // can be adjusted
    }
    isManualOverride: boolean
    resultOverride: {
      rating: number
      policy?: string
      score: ApplicantRiskScore
    }
    factors: PartialRecord<
      SuggestionString<
        | 'manual'
        | 'num_pep'
        | 'num_sanctions'
        | 'internal_watchlist'
        | 'num_adverse'
        | 'wealth'
        | 'fund'
        | 'cross_border'
        | 'work_situation'
        | 'business'
        | 'country_excludes_birthplace'
        | 'country_birthplace'
      >,
      {
        rating: number
        type: string
        factor: string
        score?: ApplicantRiskScore
        policy?: string
      }
    >
  }
  transactionalRisk?: {
    overall: PartialRecord<
      SuggestionString<ApplicantTransactionalRiskType>,
      {
        rating: number
        level: 'NONE' | 'LOW' | 'MEDIUM' | 'HIGH' // can be adjusted
        policy?: string
      }
    >
    factors: PartialRecord<
      SuggestionString<ApplicantTransactionalRiskType>,
      {
        count: number
        description: string
        level: string
        name: string
        rating: number
      }[]
    >
    issues: Record<SuggestionString<'type'>, unknown>[] // (type not known)
  }
}

export type AlertList = {
  term: string
  type: string
  extra?: {
    label: string
    action: string
  }
}
export type MatchsetCountTypes = {
  required: Nullable<number>
  actual: Nullable<number>
}

export type RulesetMatchFields = {
  name: MatchsetCountTypes
  dob: MatchsetCountTypes
  address: MatchsetCountTypes
  govId: MatchsetCountTypes
}

export type RulesetsMatches = {
  name: Nullable<string>
  order: Nullable<number>
  result: Nullable<string>
  rulesetMatchFields: RulesetMatchFields
}

export type CheckResult = {
  type: string
  result: {
    key: string
    type: string
    label: string
  }
  issues: {
    code: string
    description?: string
  }[]
}

export function hasSardineCheckResult(checkResults: CheckResult[]): boolean {
  return Object.values(sardineChecksRegex).some(regex => {
    const findRegMatch: CheckResult | undefined = checkResults.find(
      (checkResult: CheckResult) => {
        const { type } = checkResult
        return regex.test(type)
      },
    )
    return !!findRegMatch
  })
}

export type ApplicantCheckSummaryResponse = {
  checkTypes?: SuggestionString<
    'blacklist' | 'two_plus' | 'id' | 'pep_media' | 'pep'
  >[] // add as per requirement
  checkDate?: string
  personalChecks: NullableProperties<{
    name: boolean
    dateOfBirth: boolean
    phoneNumber: boolean
    email: boolean
    chineseName: boolean
    addresses: NullableProperties<Record<string, boolean>>
  }>
  status: {
    type: string
    label: string
    key: string
  }
  blocklistPotentialMatches?: {
    matches: {
      personal: string[]
      documents: {
        documentId: Nullable<string>
        field: string[]
      }
    }
    entityId: string
    matchStrength: number
  }[]
  duplicatePotentialMatches: unknown[]
  duplicateBlocklistMatches: {
    matches: {
      personal: string[]
      documents: {
        documentId: Nullable<string>
        field: string[]
      }
    }
    entityId: string
    matchStrength: number
  }[]
  risk: {
    level: string | null
    class: string | object
  }
  alertList?: AlertList[]
  checkCounts: {
    name: Nullable<unknown[]>
    address: Record<string, unknown[]>
    document?: Record<string, unknown[]>
    dob: Nullable<unknown[]>
  }
  kycMethod: string
  issues: {
    creditHeader: boolean
    MANUAL_BLOCKLIST: Nullable<BlocklistReasonTypes>
    DUPLICATE_RESOLVED: Nullable<boolean>
    BLOCKLIST_ATTRIBUTE: Nullable<BlocklistAttributeTypes>
    BLOCKLIST_BY: Nullable<string>
    BLOCKLIST_AT: Nullable<string>
    MANUAL_WATCHLIST: Nullable<string>
  }
  checkResults: CheckResult[]
  rulesetsMatches: RulesetsMatches[] | []
}

export type KybReportType =
  | 'CR'
  | 'CR-RISK-PAYMENT'
  | 'SINGLE-LEVEL'
  | 'SINGLE-LEVEL-AML'
  | 'UBO'

export type ApplicantBusinessInfoResponse = {
  entityId: Nullable<string>
  customerID: Nullable<string>
  businessProfile: Nullable<string>
  businessName: Nullable<string>
  businessType: Nullable<string>
  ABNNumber: Nullable<string>
  ACNNumber: Nullable<string>
  ARBNNumber: Nullable<string>
  addresses: {
    postalCode: Nullable<string>
    state: Nullable<string>
    country: Nullable<string>
    buildingName: Nullable<string>
    unitNumber: Nullable<string>
    streetNumber: Nullable<string>
    streetName: Nullable<string>
    streetType: Nullable<string>
    suburb: Nullable<string>
    addressType: Nullable<string>
  }[]
  businessReportRequestedAt: Nullable<PartialRecord<KybReportType, string>>
  mccCode: Nullable<string>
}

export type ApplicantAddressResponse = NullableProperties<
  PartialRecord<
    | 'buildingName'
    | 'postalCode'
    | 'state'
    | 'streetName'
    | 'streetNumber'
    | 'streetType'
    | 'suburb'
    | 'town'
    | 'unitNumber'
    | 'addressType'
    | 'country'
    | 'addressId'
    | 'longForm',
    string
  >
> & {
  data?: { [k: string]: unknown }
  isAddedByCustomer?: boolean | null
}

type ContactInfo = {
  documentId: string
  idNumber: string
}

export type ReportIdSubType =
  | 'Custom Credit Report'
  | 'UBO'
  | 'SINGLE-LEVEL-AML'

export type Name = NullableProperties<{
  givenName: string
  middleName: string
  familyName: string
  displayName: string
}>

export type RegistryDocumentStatusTypes =
  | 'Waiting on Provider'
  | 'Completed'
  | 'Failed to Retrieve Report'
  | 'Request has timed out'
  | 'Cancelled Order'
  | 'Unknown Error'
  | 'Failed'

export type Document = {
  documentId: string
  scans: Scan[]
  idSubType?: Nullable<
    SuggestionString<'profile' | 'Company Profile' | ReportIdSubType>
  >
  idType: SuggestionString<'REPORT' | DocumentTypes>
  idNumber?: string
  country?: string
  idExpiry?: Nullable<string>
  region?: Nullable<string>
  verified?: {
    electronic: Nullable<boolean>
    manual: Nullable<boolean>
  }
  extraData?: Partial<{
    national_id: Nullable<string>
    maternal_familyname: Nullable<string>
    paternal_familyname: Nullable<string>
    gender: Nullable<string>
    reference: Nullable<string>
    document_number: Nullable<string>
    certificate_date_of_print: Nullable<string>
    certificate_number: Nullable<string>
    kyc_document_category: Nullable<string>
    licence_version?: Nullable<string>
    home_country_fullname?: Nullable<string>
    // marriage-certificate
    bride_name_family: Nullable<string>
    bride_name_first_given: Nullable<string>
    bride_name_other_given: Nullable<string>
    certificate_date_of_registration: Nullable<string>
    groom_name_family: Nullable<string>
    groom_name_first_given: Nullable<string>
    groom_name_other_given: Nullable<string>
    marriage_date: Nullable<string>
    registration_year: Nullable<string>

    // birth certificate
    registered_name_family: Nullable<string>
    registered_name_first_given: Nullable<string>
    registered_name_other_given: Nullable<string>
    registration_date: Nullable<string>
    registration_district: Nullable<string>

    // citizenship
    acquisition_date: Nullable<string>
    primary_country: Nullable<string>
    secondary_country: Nullable<string>

    // national health id
    display_middle_name: Nullable<string>
    display_name_line1: Nullable<string>

    // vevo data
    'visa.expiryDate': Nullable<string>
    'visa.class': Nullable<string>
    'visa.applicant': Nullable<string>
    'visa.subclass': Nullable<string>
    'visa.grantDate': Nullable<string>
    'entitlement.summary': Nullable<string>
    'visa.no_match': Nullable<string>
    'visa.blocked': Nullable<boolean>

    // name comparison
    transliteration_name_match: Nullable<string>
    transliteration_original_name: Nullable<string>
    transliteration_transliterated_name: Nullable<string>

    // ocr
    ocr_scanned_date_of_expiry: Nullable<string>
    laser_code: Nullable<string>
    digital_licence: Nullable<string>
    idv_webcapture_check_id: Nullable<string>

    // Trust Deed document scan result
    'trust.document.raw_pdf.scan_id': Nullable<string>
    'trust.document.scan_status': Nullable<TrustDeedTypes>
    'trust.document.analysis_id': Nullable<string>
    'trust.document.results.scan_id': Nullable<string>
  }>
}

export type RegistryDocument = {
  documentId: string
  verified: {
    electronic: Nullable<boolean>
    manual: Nullable<boolean>
  }
  dateOfBirth: Nullable<string>
  idType: string
  idSubType: Nullable<string>
  country: string
  region: Nullable<string>
  idNumber: Nullable<string>
  idExpiry: Nullable<string>
  extraData: Document['extraData']
  ocrResult: unknown
  gender: Nullable<string>
  scans: Scan[]
  validation: {
    manual: {
      isValid: Nullable<boolean>
    }
    electronic: {
      validationReport: Nullable<boolean>
      isValid: Nullable<boolean>
    }
  }
  docScan: (Scan & {
    fileUploadUuid: Nullable<string>
  })[]
  registryDocumentStatus?: {
    requestId: string
    requestedDate: string
    reportCode: string
    reportStatus?: RegistryDocumentStatusTypes
    reportTitle: string
    reportUrl: string
  }
}

export type ApplicantResponse = {
  applicantDetails: {
    entityId: string
    addresses?: ApplicantAddressResponse[]
    name: Name
    nativeName?: Nullable<unknown>
    displayName?: Nullable<string>
    gender: Nullable<string>
    dateOfBirth: Nullable<string>
    email: NullableProperties<ContactInfo>
    fraudData: Nullable<IApplicantFraudData>
    phoneNumber: NullableProperties<ContactInfo>
    customerReference: Nullable<string>
    profile: NullableProperties<{
      kycMethod: string
      countryAlpha3: string // 'aus'
      profileType: string // 'organisation'
      dob: string
      checkTypes: string[]
    }>
    consents: string[]
    blocklistAttributes: Nullable<string>
    assignee?: Nullable<string>
    extraData?: {
      marital_status?: Nullable<string>
      home_country_givenname?: Nullable<string>
      home_country_familyname?: Nullable<string>
      buddhist_solar_dob?: Nullable<string>
      biometric_check_last_initiated?: Nullable<string>
      requested_jurisdiction_country?: Nullable<string>
      'kyc.method'?: 'manual' | 'electronic'
      device_sardine_url?: Nullable<string>
      trust_analysis_id?: Nullable<string>
    }
    originalUboDetails?: Nullable<{
      name: Name
      dateOfBirth: Nullable<string>
      addresses: ApplicantAddressResponse[]
      isNameAddedByCustomer?: boolean
      isDobAddedByCustomer?: boolean
    }>
    flags?: { flag: SuggestionString<'ongoing_aml_disabled'>; value: boolean }[]
  }
  businessInfo: Nullable<ApplicantBusinessInfoResponse>
  checkSummary: ApplicantCheckSummaryResponse
  documents: Document[]
  supportingDocuments: unknown
  registryDocuments: RegistryDocument[]
  trustDeedsDocuments?: Document[]
}

export type StatusType = 'pass' | 'fail'

export type ArchiveType = 'archived' | 'clear'

export const applicantDetailConfig: ApplicantResponse['applicantDetails'] = {
  entityId: '',
  customerReference: null,
  name: {
    givenName: null,
    middleName: null,
    familyName: null,
    displayName: null,
  },
  dateOfBirth: null,
  gender: null,
  phoneNumber: {
    documentId: null,
    idNumber: null,
  },
  addresses: [],
  email: {
    documentId: null,
    idNumber: null,
  },
  fraudData: null,
  profile: {
    kycMethod: null,
    countryAlpha3: null,
    profileType: null,
    dob: null,
    checkTypes: [],
  },
  assignee: null,
  blocklistAttributes: null,
  consents: [],
  originalUboDetails: null,
}

export const businessInfoConfig: ApplicantBusinessInfoResponse = {
  ABNNumber: null,
  ACNNumber: null,
  addresses: [],
  ARBNNumber: null,
  businessName: null,
  businessProfile: null,
  businessReportRequestedAt: {},
  businessType: null,
  customerID: null,
  entityId: null,
  mccCode: null,
}
