export enum ApplicantRiskLevelTypes {
  Unknown = 'unknown',
  Low = 'low',
  Medium = 'medium',
  High = 'high',
  Unacceptable = 'unacceptable',
}

export enum RiskLevelTermTypes {
  Unknown = 'risk_unknown',
  Unacceptable = 'risk_unacceptable',
  High = 'risk_high',
  Medium = 'risk_medium',
  Low = 'risk_low',
}

export const RiskMapping: Record<RiskLevelTermTypes, ApplicantRiskLevelTypes> =
  {
    risk_unknown: ApplicantRiskLevelTypes.Unknown,
    risk_unacceptable: ApplicantRiskLevelTypes.Unacceptable,
    risk_high: ApplicantRiskLevelTypes.High,
    risk_medium: ApplicantRiskLevelTypes.Medium,
    risk_low: ApplicantRiskLevelTypes.Low,
  }
