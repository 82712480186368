import React, { useMemo } from 'react'

import { INDIVIDUAL_PROFILE_KEY } from 'features/individual-profile/individual-profile.keys'
import { individualProfileEn } from 'features/individual-profile/locale/new-profile.en'
import { genderValue } from 'features/individual-profile/model/document.model'
import { IndividualProfileInputTypes } from 'features/individual-profile/model/form.model'
import { individualProfileVueMigratedQa } from 'features/individual-profile/qa/individual-profile.qa'

import { CountryAlpha3CodeTypes } from 'entities/country'

import { SelectFormField, TextFormField } from 'shared/form'
import { useI18n } from 'shared/i18n'

import { DocumentCategory } from '../document-category/document-category'
import { DocumentsSubFormProps } from '../document-sub-forms.types'

function NationalIdForm({ form, idx, wrapperClasses }: DocumentsSubFormProps) {
  const { control, watch } = form

  const selectedCountry: string = watch(
    `${IndividualProfileInputTypes.Document}.${idx}.${IndividualProfileInputTypes.Country}`,
  )
  const t = useI18n([INDIVIDUAL_PROFILE_KEY], { keys: individualProfileEn })
  const genderOptions = useMemo(
    () => [
      { value: genderValue.male, label: t('documentForm.male') },
      { value: genderValue.female, label: t('documentForm.female') },
    ],
    [t],
  )
  return (
    <div className={wrapperClasses}>
      <TextFormField
        className="basis-[32%]"
        label={t('documentForm.idNumber')}
        placeholder={t('documentForm.idNumber')}
        control={control}
        name={`document.${idx}.idNumber`}
        rules={{
          required: true,
          validate: {
            length: (value: string) => {
              const { length } = value || ''
              switch (selectedCountry) {
                case CountryAlpha3CodeTypes.UNITED_STATES_AMERICA:
                  return length === 4 || length === 9
                case CountryAlpha3CodeTypes.THAILAND:
                  return length === 17
                case CountryAlpha3CodeTypes.INDONESIA:
                  return length === 16
                default:
                  return true
              }
            },
          },
        }}
        testId={{
          input: individualProfileVueMigratedQa.document.idNumberField,
        }}
        shouldUnregister
      />

      {selectedCountry === CountryAlpha3CodeTypes.CHINA && (
        <>
          <TextFormField
            className="basis-[32%]"
            label={t('documentForm.homeCountryFullName')}
            placeholder={t('documentForm.homeCountryFullName')}
            control={control}
            name={`document.${idx}.homeCountryFullName`}
            rules={{ required: true }}
            testId={{
              input:
                individualProfileVueMigratedQa.document.homeCountryFullName,
            }}
            shouldUnregister
          />

          <div className="basis-[32%]" />
        </>
      )}
      {selectedCountry === CountryAlpha3CodeTypes.THAILAND && (
        <>
          <TextFormField
            className="basis-[32%]"
            label={t('documentForm.laserCode')}
            placeholder={t('documentForm.laserCode')}
            control={control}
            name={`document.${idx}.laserCode`}
            rules={{ required: true }}
            testId={{
              input: individualProfileVueMigratedQa.document.laserCode,
            }}
            shouldUnregister
          />

          <div className="basis-[32%]" />
        </>
      )}

      {selectedCountry === CountryAlpha3CodeTypes.INDIA && (
        <>
          <SelectFormField
            options={genderOptions}
            className="basis-[32%]"
            label={t('documentForm.gender')}
            placeholder={t('documentForm.gender')}
            control={control}
            name={`document.${idx}.gender`}
            rules={{ required: true }}
            testId={{
              input: individualProfileVueMigratedQa.document.laserCode,
            }}
            shouldUnregister
          />

          <div className="basis-[32%]" />
        </>
      )}

      {selectedCountry === CountryAlpha3CodeTypes.MEXICO && (
        <>
          <TextFormField
            className="basis-[32%]"
            label={t('documentForm.paternalSurname')}
            placeholder={t('documentForm.paternalSurname')}
            control={control}
            name={`document.${idx}.paternalFamilyName`}
            rules={{ required: true }}
            testId={{
              input: individualProfileVueMigratedQa.document.paternalFamilyName,
            }}
            shouldUnregister
          />
          <div className="basis-[32%]" />
          <TextFormField
            className="basis-[32%]"
            label={t('documentForm.maternalSurname')}
            placeholder={t('documentForm.maternalSurname')}
            control={control}
            name={`document.${idx}.maternalFamilyName`}
            rules={{ required: true }}
            testId={{
              input: individualProfileVueMigratedQa.document.maternalFamilyName,
            }}
            shouldUnregister
          />
        </>
      )}

      <DocumentCategory
        disabled
        className="basis-[32%]"
        form={form}
        idx={idx}
      />
    </div>
  )
}

export { NationalIdForm }
