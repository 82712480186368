import React, { useMemo } from 'react'

import { FrankieIcon } from 'frankify/src'

import { ApplicantId } from 'entities/applicant'

import { useI18n } from 'shared/i18n'

import { IKYB_AML_SCREENING_KEY } from '../../../../ikyb-aml-screening.key'
import { iKybAmlScreeningEn } from '../../../../locale/ikyb-aml-screening.en'
import { iKybAmlDataSourceQa } from '../../../../qa/ikyb-aml-screening.qa'
import { useIKybAmlScreeningQuery } from '../../../../state/ikyb-aml-screening-query/ikyb-aml-screening.query'

type Props = {
  applicantId: ApplicantId
  matchIdx: number
  testId?: { container?: string }
}

export function IKybAmlDataSources({ applicantId, matchIdx, testId }: Props) {
  const t = useI18n(IKYB_AML_SCREENING_KEY, {
    keys: iKybAmlScreeningEn,
  })

  const { data } = useIKybAmlScreeningQuery({ applicantId })

  const supportingDocs = useMemo(() => {
    const matchDetails = data?.matches[matchIdx]?.details
    const supportingDocuments = matchDetails?.supportingDocuments ?? []
    const supportingDocUrls = matchDetails?.entityData.supportingDocUrls ?? []

    return [...supportingDocuments, ...supportingDocUrls]
  }, [data?.matches, matchIdx])

  if (!supportingDocs.length) {
    return (
      <div data-qa={testId?.container} className="p-3 text-secondary-900">
        {t('empty.dataSources')}
      </div>
    )
  }

  return (
    <div data-qa={testId?.container} className="p-2">
      {supportingDocs.map(supportingDoc => (
        <div
          data-qa={iKybAmlDataSourceQa.document}
          className="flex gap-2 py-4 border-b border-tertiary-grey-200"
          key={supportingDoc}
        >
          <a
            className="text-primary-800"
            href={supportingDoc}
            target="_blank"
            rel="noreferrer"
          >
            {supportingDoc} {data?.checkId}
          </a>
          <FrankieIcon
            size="xs"
            name="mdiOpenInNew"
            className="text-tertiary-grey-400 pt-[2px]"
          />
        </div>
      ))}
    </div>
  )
}
