import { bffClient, IClient } from 'shared/client'

import { ApplicantOwnershipResponse } from '../model/applicant-business-ownership.model'
import {
  AssociatePartiesPayload,
  AssociateNewPartiesPayload,
  HidePartyFromSearch,
} from '../model/associate-party.model'

export class ApplicantBusinessOwnershipAPI {
  client: IClient

  constructor(client: IClient) {
    this.client = client
  }

  async associateNewParty(
    applicantId: string,
    payload: AssociatePartiesPayload &
      (
        | AssociateNewPartiesPayload<'INDIVIDUAL'>
        | AssociateNewPartiesPayload<'BUSINESS'>
      ) &
      HidePartyFromSearch,
  ) {
    return this.client.post(
      `data/v2/business/${applicantId}/associate/new`,
      payload,
    )
  }

  async associateExistingParty(
    applicantId: string,
    payload: AssociatePartiesPayload & HidePartyFromSearch,
    entityId: string,
  ) {
    return this.client.post(
      `data/v2/business/${applicantId}/associate/${entityId}`,
      payload,
    )
  }

  async updateAssociatedParty(
    applicantId: string,
    payload: AssociatePartiesPayload,
    entityId: string,
  ) {
    return this.client.put(
      `data/v2/business/${applicantId}/associate/${entityId}`,
      payload,
    )
  }

  async removeAssociatedParty(applicantId: string, entityId: string) {
    return this.client.delete(
      `data/v2/business/${applicantId}/associate/${entityId}`,
    )
  }

  async getBusinessOwnershipDetail(entityId: string) {
    return this.client.get<ApplicantOwnershipResponse>(
      `data/v2/business/${entityId}/ownership`,
    )
  }
}

export const applicantBusinessOwnershipApi = new ApplicantBusinessOwnershipAPI(
  bffClient,
)
