import React, { useEffect, useState } from 'react'

import { FrankieButton, FrankieIcon, FrankieLoader } from 'frankify/src'

import { ORGANISATION_SEARCH_KEY } from 'features/organisation-search'

import { useI18n } from 'shared/i18n'
import { Nullable } from 'shared/typescript'

import { ABRCompany } from '../../model/abr-search-model'
import { AbrOrganisationSummary } from '../abr-organisation-summary/abr-organisation-summary'
import { AbrSearchResultTableAustralia } from '../abr-search-result-table-australia/abr-search-result-table-australia'
import { AustraliaSearchResultError } from '../aus-search-error/aus-search-error'
import { AustraliaSearchResultGutter } from '../aus-search-result-gutter/aus-search-result-gutter'

type Props = {
  dataToDisplay: {
    organizationName: string
    countryName: string
  }
  isLoadingAbrOrganisations: boolean
  showNoMatchesError: boolean
  foundABROrganisations: ABRCompany[]
  showAbrResults: boolean
  onGoToManualAdd: () => void
}

export function AbrSearchResultAustralia({
  showAbrResults,
  foundABROrganisations,
  dataToDisplay,
  isLoadingAbrOrganisations,
  showNoMatchesError,
  onGoToManualAdd,
}: Props) {
  const t = useI18n([ORGANISATION_SEARCH_KEY])

  const [selectedOrganisationNumber, setSelectedOrganisationNumber] = useState<{
    abn: Nullable<string>
    acn: Nullable<string>
  }>({ abn: null, acn: null })

  const handleSelectOrganisation = (organisationNumbers: {
    abn: Nullable<string>
    acn: Nullable<string>
  }) => {
    setSelectedOrganisationNumber(organisationNumbers)
  }

  const handleClickBackFromSummary = () =>
    setSelectedOrganisationNumber({ abn: null, acn: null })

  useEffect(() => {
    if (isLoadingAbrOrganisations) {
      setSelectedOrganisationNumber({ abn: null, acn: null })
    }
  }, [isLoadingAbrOrganisations])

  if (selectedOrganisationNumber.abn) {
    return (
      <>
        <FrankieButton
          noStyles
          className="text-sm font-normal text-tertiary-grey-500"
          onClick={handleClickBackFromSummary}
        >
          <div className="flex items-center">
            <FrankieIcon
              size="xs"
              name="mdiChevronLeft"
              className="inline-block"
            />
            <span>{t('backToResults')}</span>
          </div>
        </FrankieButton>
        <AbrOrganisationSummary
          selectedOrganisationNumber={
            selectedOrganisationNumber as { abn: string; acn: Nullable<string> }
          }
        />
      </>
    )
  }

  return (
    <div>
      {isLoadingAbrOrganisations && (
        <div id="loading-content" className="flex align-center flex-col">
          <div id="loader">
            <FrankieLoader
              loading={isLoadingAbrOrganisations}
              className="min-h-[76px]"
              size="md"
            />
          </div>
          <div className="flex flex-col items-center mt-3">
            <span className="inline-block font-normal text-md text-tertiary-grey-800">
              {t('loadingHeadingABR', {
                organizationName: dataToDisplay.organizationName,
              })}
            </span>
          </div>
        </div>
      )}
      {showNoMatchesError && (
        <div id="error-container">
          <FrankieIcon
            size="xl"
            name="mdiMagnify"
            className="text-primary-300 flex justify-center"
          />
          <div className="flex flex-col items-center">
            <span className="text-lg font-bold inline-block">
              {t('noMatchesHeading')}
            </span>
            <span className="text-sm font-normal">
              {t('searchResultHeading', {
                search: dataToDisplay.organizationName,
              })}
            </span>
            <span className="text-sm font-normal">{t('checkTypos')}</span>
            <div className="mt-3">
              <AustraliaSearchResultError onClickManualAdd={onGoToManualAdd} />
            </div>
          </div>
        </div>
      )}
      {showAbrResults && (
        <AbrSearchResultTableAustralia
          onSelectAbrOrganisation={handleSelectOrganisation}
          organizationName={dataToDisplay.organizationName}
          foundABROrganisations={foundABROrganisations}
        />
      )}
      {showAbrResults && (
        <AustraliaSearchResultGutter onClickManualAdd={onGoToManualAdd} />
      )}
    </div>
  )
}
