import React from 'react'

import cx from 'classnames'
import { useNavigate } from 'react-router-dom'

import { FrankieButton, FrankieLoader } from 'frankify/src'

import { KYB_SEARCH_V2_KEY } from 'features/kyb-search-v2/kyb-search-v2.key'
import { kybSearchV2En } from 'features/kyb-search-v2/locale/kyb-search-v2.en'

import { DocumentTabTypes } from 'entities/applicant'
import {
  IMatchedOrganization,
  useCreateOwnershipProfileROW,
} from 'entities/organisation'
import { OrganisationAddress } from 'entities/organisation/model/organisation.model'
import { useCreateProfileInternational } from 'entities/organisation/state/create-profile-international/create-profile-international.mutation'

import { useI18n } from 'shared/i18n'
import { notification } from 'shared/notification'
import { maintainOldNavBarPaths } from 'shared/release'

type Props = {
  organisationInfo: IMatchedOrganization
}
export function KybDocumentCatalog({ organisationInfo }: Props) {
  const t = useI18n([KYB_SEARCH_V2_KEY], { keys: kybSearchV2En })

  const navigate = useNavigate()

  const {
    mutateAsync: createProfileInternational,
    isLoading: isCreatingProfile,
  } = useCreateProfileInternational()

  const { isLoading: isCreatingOwnershipProfile } =
    useCreateOwnershipProfileROW()

  const viewDocumentList = async () => {
    const outerCountry =
      organisationInfo.organizationCountry ?? organisationInfo.country

    const registrationDetails = organisationInfo.registrationDetails[0]

    try {
      const {
        data: {
          entity: { entityId },
        },
        // TODO: use the inner method of the query: Does the same thing
      } = await createProfileInternational({
        organisationToken: organisationInfo.organizationToken,
        registeredName:
          registrationDetails.registeredName ??
          organisationInfo.name?.name ??
          '',
        registrationDescription: registrationDetails.registryDescription,
        registrationNumber: registrationDetails.registrationNumber,

        addresses:
          organisationInfo.addresses?.map(address => ({
            ...address,
            country: address.country || outerCountry,
          })) || ([{ country: outerCountry }] as OrganisationAddress[]),
      })

      navigate(
        `/new${maintainOldNavBarPaths.applicants}/profile/${entityId}/supporting-documents`,
        { state: { activeTabKey: DocumentTabTypes.CATALOG } },
      )
    } catch (error) {
      notification.error(t('errorCreatingProfileROW'))
    }
  }

  return (
    <div
      className={cx(
        'p-8 pt-7 bg-tertiary-grey-50 rounded-md flex justify-between items-center gap-4',
      )}
    >
      {isCreatingProfile || isCreatingOwnershipProfile ? (
        <div className="flex items-center gap-2">
          <FrankieLoader
            className="relative w-min min-w-[70px]"
            size="sm"
            loading
          />
          <div className="whitespace-pre-line">{t('retrievingList')}</div>
        </div>
      ) : (
        <span className="text-lg font-bold text-tertiary-grey-800">
          {t('retrieveList')}
        </span>
      )}
      <FrankieButton
        disabled={isCreatingOwnershipProfile || isCreatingProfile}
        onClick={viewDocumentList}
      >
        {t('retrieveListButton')}
      </FrankieButton>
    </div>
  )
}
