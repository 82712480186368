import React from 'react'

import { UseFormReturn } from 'react-hook-form'

import { FrankieCheckbox } from 'frankify/src'

import { useHasFeatureFlag } from 'entities/session'

import { useI18n } from 'shared/i18n'

import { INDIVIDUAL_PROFILE_KEY } from '../../individual-profile.keys'
import { individualProfileEn } from '../../locale/new-profile.en'
import {
  IIndividualProfileInputs,
  IndividualProfileInputTypes,
} from '../../model/form.model'

export type Props = {
  form: UseFormReturn<IIndividualProfileInputs>
}

export function ForceCheckForm({ form }: Props) {
  const t = useI18n([INDIVIDUAL_PROFILE_KEY], { keys: individualProfileEn })

  const { setValue, watch } = form

  const { applicantSubmissionForceCheck } = useHasFeatureFlag({
    applicantSubmissionForceCheck: 'applicantSubmissionForceCheck',
  })

  const checked = watch(IndividualProfileInputTypes.ForceCheck)

  const handleChangeForceCheck = () => {
    setValue(IndividualProfileInputTypes.ForceCheck, !checked)
  }

  if (!applicantSubmissionForceCheck) {
    return false
  }

  return (
    <div className="flex pt-5 pb-2">
      <FrankieCheckbox onChange={handleChangeForceCheck} checked={checked} />
      <div className="font-bold ml-3">{t('forceCheck')}</div>
    </div>
  )
}
