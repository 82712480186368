import { ApplicantResponse } from 'entities/applicant'
import { DocumentIdTypes, Scan } from 'entities/document'

import { DateFormatTypes, formatDate } from 'shared/date-time'
import { I18nKeys } from 'shared/i18n'
import { capitalizeStringBySeparator } from 'shared/string'
import { Nullable, SuggestionString } from 'shared/typescript'

import { applicantBiometricsOcrEn } from '../locale/applicant-biometrics-ocr.en'

export type BiometricDetailType = 'biometry' | 'document' | 'ocr' | 'facial'

export type CheckResult =
  | 'clear'
  | 'suspected'
  | 'caution'
  | 'rejected'
  | 'manual_pass'
  | 'manual_fail'
  | 'fail'

export type ResultStatus = 'MANUAL_FAIL' | 'MANUAL_PASS'

export type BiometryCheck = {
  checkDate: string
  documentId: string
  result: CheckResult
  confidence_level: string
  compare_type: string
  checkStatus: string
  referenceDocType: string
  items: BiometryItem[]
}

export type BiometryItem = {
  key: string
  value?: Nullable<boolean>
  title: string
  items?: BiometryItem[]
}

export type OcrItems = {
  ocr_scanned_first_name: string
  ocr_scanned_last_name: string
  ocr_scanned_issuing_country: string
  ocr_scanned_issuing_state: string
  ocr_scanned_nationality: string
  ocr_scanned_date_of_birth: string
  ocr_scanned_document_number: string
  ocr_scanned_document_type: string
  ocr_scanned_date_of_expiry: string
  ocr_scanned_mismatch: string
  ocr_scanned_document_type_internal: string
  ocr_scanned_middle_name: string
  ocr_scanned_address: string
  ocr_scanned_id_number: string
  ocr_scanned_date_of_issue: string
}

export type DocumentDetail = {
  documentId: string
  ocrReportDocumentId: string
  checkDate: string
  result: CheckResult
  checkStatus: string
  mismatchFields: SuggestionString<keyof OcrItems>[]
  checkResultItems?: CheckResultItem[]
  ocrResult: string // CheckResult in uppercase
  ocrItems: Partial<OcrItems>
  hasSimplifiedResult: boolean
  confidenceLevel: string
}

export type CheckResultItem = {
  key: string
  value: boolean
  title: string
  items?: CheckResultItem[]
}

export type ApplicantBiometricResponse = {
  biometry: BiometryCheck[]
  documentDetails: DocumentDetail[]
  idvOverrideResult: Nullable<SuggestionString<ResultStatus>>
  facialDuplicateResult: string
}

export type ApplicantDetails = ApplicantResponse['applicantDetails']

export type FacialDuplicatesDetails = {
  applicant: ApplicantDetails
  documentId: Nullable<string>
  portraitData?: string
  duplicateScorePercentage: Nullable<number>
  customerId: Nullable<string>
  customerChildId: Nullable<string>
}

export type ApplicantFacialDuplicatesResponse = {
  facialDuplicatesDetails: FacialDuplicatesDetails[]
  overallResult: Nullable<string>
  applicantData: ApplicantDetails
}

export type ApplicantIdvLinkPayload = {
  pushToSms: boolean
  useLiveness?: boolean
  smsPhoneNumber?: string
  comment?: string
}

export type BiometryBase64Scan = {
  video?: Scan
  image?: Scan
}

export type DocumentBase64Scan = {
  front?: Scan
  back?: Scan
}

export type StatusUpdatePayload = {
  comment: string
  status: ResultStatus
  old_status?: string
  documentId: string
  documentType: string
  detailType: BiometricDetailType
}

export type IdvStatusChangePayload = {
  status: ResultStatus
  documentId: string
  documentCountry: string
  comment: string
}

export const IDV_DOCS = [
  DocumentIdTypes.DRIVERS_LICENSE,
  DocumentIdTypes.PASSPORT,
  DocumentIdTypes.NATIONAL_ID,
]

export function convertIDTypeToHumanReadable(idType?: string) {
  if (!idType) return ''
  if (idType === 'id') return 'ID'
  return capitalizeStringBySeparator(idType)
}

export const reportStatus = (
  checkStatus: string,
  hasSimplifiedResult?: boolean,
): I18nKeys<typeof applicantBiometricsOcrEn> => {
  if (hasSimplifiedResult) return 'unchecked'
  return checkStatus === 'DOC_SCANNED' ? 'complete' : 'pending'
}

export type ScoreStatus = 'risk_high' | 'risk_medium' | 'risk_low'

export const scoreStatus = (score?: Nullable<number>): ScoreStatus => {
  if (score) {
    if (score >= 90) return 'risk_high'
    return 'risk_medium'
  }

  return 'risk_low'
}

export function countDescendants(item: CheckResultItem): number {
  if (!item.items) return 0
  const currentChildrenCount = item.items.length
  return item.items.reduce(
    (count, item) => count + countDescendants(item),
    currentChildrenCount,
  )
}

export const formatOcrDate = (date?: Nullable<string>) =>
  date ? formatDate(date, DateFormatTypes.DateNumbers) : '-'

/**
 * @todo Remove dummy data
 */
export const dummyFacialDuplicatesData: ApplicantFacialDuplicatesResponse = {
  facialDuplicatesDetails: [
    {
      applicant: {
        entityId: '4c9c56cb-7fc6-6ec8-cfbb-d1e183351307',
        customerReference: null,

        name: {
          givenName: 'sevenplusdups',
          middleName: null,
          familyName: 'facialdups',
          displayName: null,
        },
        dateOfBirth: '1990-01-01',
        gender: null,

        phoneNumber: {
          documentId: null,
          idNumber: null,
        },
        addresses: [
          {
            buildingName: null,
            postalCode: '4713',
            state: 'QLD',
            streetNumber: '20',
            streetType: null,
            suburb: null,
            town: 'Woorabinda',
            unitNumber: null,
            addressType: 'RESIDENTIAL1',
            country: 'AUS',
            addressId: '278df56a-4242-fa97-cdba-91605aa0507f',
            data: {},
            isAddedByCustomer: false,
            streetName: 'Carbine Street',
            longForm:
              '20 Carbine Street, Woorabinda Queensland 4713, Australia',
          },
        ],
        email: {
          documentId: null,
          idNumber: null,
        },
        profile: {
          profileType: 'default',
          kycMethod: 'electronic',
          countryAlpha3: 'aus',
          dob: '1990-01-01',
          checkTypes: ['profile'],
        },
        assignee: null,
        consents: ['general', 'docs', 'creditheader'],
        blocklistAttributes: null,
        originalUboDetails: {
          name: {
            givenName: null,
            middleName: null,
            familyName: null,
            displayName: null,
          },
          dateOfBirth: null,
          isDobAddedByCustomer: false,
          isNameAddedByCustomer: false,
          addresses: [],
        },

        fraudData: null,
      },
      documentId: 'DOC123',
      portraitData:
        'https://images.pexels.com/photos/614810/pexels-photo-614810.jpeg?cs=srgb&dl=pexels-simon-robben-614810.jpg&fm=jpg',
      duplicateScorePercentage: 37,
      customerId: 'CUST456',
      customerChildId: 'CHILD789',
    },
    {
      applicant: {
        entityId: '0c9c56cb-7fc6-6ec8-cfbb-d1e183351307',
        customerReference: null,

        name: {
          givenName: 'sevenplusdups',
          middleName: null,
          familyName: 'facialdups',
          displayName: null,
        },
        dateOfBirth: '1990-01-01',
        gender: null,

        phoneNumber: {
          documentId: null,
          idNumber: null,
        },
        addresses: [
          {
            buildingName: null,
            postalCode: '4713',
            state: 'QLD',
            streetNumber: '20',
            streetType: null,
            suburb: null,
            town: 'Woorabinda',
            unitNumber: null,
            addressType: 'RESIDENTIAL1',
            country: 'AUS',
            addressId: '278df56a-4242-fa97-cdba-91605aa0507f',
            data: {},
            isAddedByCustomer: false,
            streetName: 'Carbine Street',
            longForm:
              '20 Carbine Street, Woorabinda Queensland 4713, Australia',
          },
        ],
        email: {
          documentId: null,
          idNumber: null,
        },
        profile: {
          profileType: 'default',
          kycMethod: 'electronic',
          countryAlpha3: 'aus',
          dob: '1990-01-01',
          checkTypes: ['profile'],
        },
        assignee: null,
        consents: ['general', 'docs', 'creditheader'],
        blocklistAttributes: null,
        originalUboDetails: {
          name: {
            givenName: null,
            middleName: null,
            familyName: null,
            displayName: null,
          },
          dateOfBirth: null,
          isDobAddedByCustomer: false,
          isNameAddedByCustomer: false,
          addresses: [],
        },

        fraudData: null,
      },
      documentId: 'DOC123',
      portraitData:
        'https://t3.ftcdn.net/jpg/02/43/12/34/360_F_243123463_zTooub557xEWABDLk0jJklDyLSGl2jrr.jpg',
      duplicateScorePercentage: 0,
      customerId: 'CUST456',
      customerChildId: 'CHILD789',
    },
    {
      applicant: {
        entityId: '4c0c56cb-7fc6-6ec8-cfbb-d1e183351307',
        customerReference: null,
        name: {
          givenName: 'sevenplusdups',
          middleName: null,
          familyName: 'facialdups',
          displayName: null,
        },
        dateOfBirth: '1990-01-01',
        gender: null,
        phoneNumber: {
          documentId: null,
          idNumber: null,
        },
        addresses: [
          {
            buildingName: null,
            postalCode: '4713',
            state: 'QLD',
            streetNumber: '20',
            streetType: null,
            suburb: null,
            town: 'Woorabinda',
            unitNumber: null,
            addressType: 'RESIDENTIAL1',
            country: 'AUS',
            addressId: '278df56a-4242-fa97-cdba-91605aa0507f',
            data: {},
            isAddedByCustomer: false,
            streetName: 'Carbine Street',
            longForm:
              '20 Carbine Street, Woorabinda Queensland 4713, Australia',
          },
        ],
        email: {
          documentId: null,
          idNumber: null,
        },
        profile: {
          profileType: 'default',
          kycMethod: 'electronic',
          countryAlpha3: 'aus',
          dob: '1990-01-01',
          checkTypes: ['profile'],
        },
        assignee: null,
        consents: ['general', 'docs', 'creditheader'],
        blocklistAttributes: null,
        originalUboDetails: {
          name: {
            givenName: null,
            middleName: null,
            familyName: null,
            displayName: null,
          },
          dateOfBirth: null,
          isDobAddedByCustomer: false,
          isNameAddedByCustomer: false,
          addresses: [],
        },

        fraudData: null,
      },
      documentId: 'DOC124',
      portraitData:
        'https://img.freepik.com/free-photo/portrait-handsome-man-with-dark-hairstyle-bristle-toothy-smile-dressed-white-sweatshirt-feels-very-glad-poses-indoor-pleased-european-guy-being-good-mood-smiles-positively-emotions-concept_273609-61405.jpg',
      duplicateScorePercentage: 95,
      customerId: 'CUST456',
      customerChildId: 'CHILD789',
    },
  ],
  overallResult: 'Pass',
  applicantData: {
    entityId: '4c9c56cb-7fc6-6ec8-cfbb-d1e183351307',
    customerReference: null,

    name: {
      givenName: 'sevenplusdups',
      middleName: null,
      familyName: 'facialdups',
      displayName: null,
    },
    dateOfBirth: '1990-01-01',
    gender: null,

    phoneNumber: {
      documentId: null,
      idNumber: null,
    },
    addresses: [
      {
        buildingName: null,
        postalCode: '4713',
        state: 'QLD',
        streetNumber: '20',
        streetType: null,
        suburb: null,
        town: 'Woorabinda',
        unitNumber: null,
        addressType: 'RESIDENTIAL1',
        country: 'AUS',
        addressId: '278df56a-4242-fa97-cdba-91605aa0507f',
        data: {},
        isAddedByCustomer: false,
        streetName: 'Carbine Street',
        longForm: '20 Carbine Street, Woorabinda Queensland 4713, Australia',
      },
    ],
    email: {
      documentId: null,
      idNumber: null,
    },
    profile: {
      profileType: 'default',
      kycMethod: 'electronic',
      countryAlpha3: 'aus',
      dob: '1990-01-01',
      checkTypes: ['profile'],
    },
    assignee: null,
    consents: ['general', 'docs', 'creditheader'],
    blocklistAttributes: null,
    originalUboDetails: {
      name: {
        givenName: null,
        middleName: null,
        familyName: null,
        displayName: null,
      },
      dateOfBirth: null,
      isDobAddedByCustomer: false,
      isNameAddedByCustomer: false,
      addresses: [],
    },

    fraudData: null,
  },
}

/**
 * @todo Remove dummy data
 */
export const dummyBiometryData = {
  // https://latest.frankiefinancial.io/onboarding/profile/079eb352-a618-dacd-bb7e-6438484319d9/biometry-ocr
  biometry: [
    {
      checkDate: '2024-03-11T08:34:26Z',
      documentId: 'faf98be2-6ac5-f1ac-73c4-460a68bd2cb3',
      result: 'clear',
      confidence_level: '0.6512',
      compare_type: '',
      checkStatus: 'DOC_SCANNED',
      referenceDocType: 'PASSPORT',
      items: [
        {
          key: 'report.visual_authenticity',
          value: true,
          title: 'Visual Authenticity',
          items: [
            {
              key: 'report.visual_authenticity.spoofing_detection',
              value: true,
              title: 'Spoofing Detection',
              items: [
                {
                  key: 'report.visual_authenticity.spoofing_detection.score',
                  value: false,
                  title: '0.9512',
                  items: [],
                },
              ],
            },
          ],
        },
        {
          key: 'report.image_integrity',
          value: true,
          title: 'Image Integrity',
          items: [
            {
              key: 'report.image_integrity.face_detected',
              value: true,
              title: 'Face Detected',
              items: [],
            },
            {
              key: 'report.image_integrity.source_integrity',
              value: true,
              title: 'Source Integrity',
              items: [],
            },
          ],
        },
        {
          key: 'report.face_comparison',
          value: true,
          title: 'Face Comparison',
          items: [
            {
              key: 'report.face_comparison.face_match',
              value: true,
              title: 'Face Match',
              items: [
                {
                  key: 'report.face_comparison.face_match.document_id',
                  value: false,
                  title: 'Document Id',
                  items: [],
                },
                {
                  key: 'report.face_comparison.face_match.score',
                  value: false,
                  title: '0.6512',
                  items: [],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
  documentDetails: [
    {
      documentId: '6fd79308-6435-749c-ab93-ae1d57b3cebb',
      ocrReportDocumentId: '6d9c0d61-acff-9a60-5182-1bcefdc5b2e7',
      checkDate: '2024-03-11T08:34:26Z',
      result: 'suspected',
      checkStatus: 'DOC_SCANNED',
      mismatchFields: ['ocr_scanned_document_number'],
      checkResultItems: [
        {
          key: 'report.data_validation',
          value: true,
          title: 'Data Validation',
          items: [
            {
              key: 'report.data_validation.document_numbers',
              value: true,
              title: 'Document Numbers',
              items: [],
            },
            {
              key: 'report.data_validation.date_of_birth',
              value: true,
              title: 'Date Of Birth',
              items: [],
            },
            {
              key: 'report.data_validation.gender',
              value: true,
              title: 'Gender',
              items: [],
            },
            {
              key: 'report.data_validation.mrz',
              value: true,
              title: 'Mrz',
              items: [],
            },
            {
              key: 'report.data_validation.expiry_date',
              value: true,
              title: 'Expiry Date',
              items: [],
            },
            {
              key: 'report.data_validation.document_expiration',
              value: true,
              title: 'Document Expiration',
              items: [],
            },
          ],
        },
        {
          key: 'report.age_validation',
          value: true,
          title: 'Age Validation',
          items: [
            {
              key: 'report.age_validation.minimum_accepted_age',
              value: true,
              title: 'Minimum Accepted Age',
              items: [],
            },
          ],
        },
        {
          key: 'report.police_record',
          value: true,
          title: 'Police Record',
          items: [],
        },
        {
          key: 'report.image_integrity',
          value: true,
          title: 'Image Integrity',
          items: [
            {
              key: 'report.image_integrity.colour_picture',
              value: true,
              title: 'Colour Picture',
              items: [],
            },
            {
              key: 'report.image_integrity.supported_document',
              value: true,
              title: 'Supported Document',
              items: [],
            },
            {
              key: 'report.image_integrity.conclusive_document_quality',
              value: true,
              title: 'Conclusive Document Quality',
              items: [],
            },
            {
              key: 'report.image_integrity.image_quality',
              value: true,
              title: 'Image Quality',
              items: [],
            },
          ],
        },
        {
          key: 'report.data_comparison',
          value: true,
          title: 'Data Comparison',
          items: [
            {
              key: 'report.data_comparison.document_type',
              value: true,
              title: 'Document Type',
              items: [],
            },
            {
              key: 'report.data_comparison.last_name',
              value: true,
              title: 'Last Name',
              items: [],
            },
            {
              key: 'report.data_comparison.date_of_expiry',
              value: true,
              title: 'Date Of Expiry',
              items: [],
            },
            {
              key: 'report.data_comparison.gender',
              value: true,
              title: 'Gender',
              items: [],
            },
            {
              key: 'report.data_comparison.issuing_country',
              value: true,
              title: 'Issuing Country',
              items: [],
            },
            {
              key: 'report.data_comparison.date_of_birth',
              value: true,
              title: 'Date Of Birth',
              items: [],
            },
            {
              key: 'report.data_comparison.first_name',
              value: true,
              title: 'First Name',
              items: [],
            },
            {
              key: 'report.data_comparison.document_numbers',
              value: true,
              title: 'Document Numbers',
              items: [],
            },
          ],
        },
        {
          key: 'report.data_consistency',
          value: true,
          title: 'Data Consistency',
          items: [
            {
              key: 'report.data_consistency.last_name',
              value: true,
              title: 'Last Name',
              items: [],
            },
            {
              key: 'report.data_consistency.first_name',
              value: true,
              title: 'First Name',
              items: [],
            },
            {
              key: 'report.data_consistency.multiple_data_sources_present',
              value: true,
              title: 'Multiple Data Sources Present',
              items: [],
            },
            {
              key: 'report.data_consistency.date_of_birth',
              value: true,
              title: 'Date Of Birth',
              items: [],
            },
            {
              key: 'report.data_consistency.document_numbers',
              value: true,
              title: 'Document Numbers',
              items: [],
            },
            {
              key: 'report.data_consistency.nationality',
              value: true,
              title: 'Nationality',
              items: [],
            },
            {
              key: 'report.data_consistency.document_type',
              value: true,
              title: 'Document Type',
              items: [],
            },
            {
              key: 'report.data_consistency.issuing_country',
              value: true,
              title: 'Issuing Country',
              items: [],
            },
            {
              key: 'report.data_consistency.date_of_expiry',
              value: true,
              title: 'Date Of Expiry',
              items: [],
            },
            {
              key: 'report.data_consistency.gender',
              value: true,
              title: 'Gender',
              items: [],
            },
          ],
        },
        {
          key: 'report.compromised_document',
          value: true,
          title: 'Compromised Document',
          items: [],
        },
        {
          key: 'report.visual_authenticity',
          value: true,
          title: 'Visual Authenticity',
          items: [
            {
              key: 'report.visual_authenticity.other',
              value: true,
              title: 'Other',
              items: [],
            },
            {
              key: 'report.visual_authenticity.digital_tampering',
              value: true,
              title: 'Digital Tampering',
              items: [],
            },
            {
              key: 'report.visual_authenticity.original_document_present',
              value: true,
              title: 'Original Document Present',
              items: [],
            },
            {
              key: 'report.visual_authenticity.security_features',
              value: true,
              title: 'Security Features',
              items: [],
            },
            {
              key: 'report.visual_authenticity.template',
              value: true,
              title: 'Template',
              items: [],
            },
            {
              key: 'report.visual_authenticity.picture_face_integrity',
              value: true,
              title: 'Picture Face Integrity',
              items: [],
            },
            {
              key: 'report.visual_authenticity.fonts',
              value: true,
              title: 'Fonts',
              items: [],
            },
            {
              key: 'report.visual_authenticity.face_detection',
              value: true,
              title: 'Face Detection',
              items: [],
            },
          ],
        },
      ],
      ocrResult: 'CLEAR',
      ocrItems: {
        ocr_scanned_first_name: 'Test',
        ocr_scanned_last_name: 'Last',
        ocr_scanned_issuing_country: 'GBR',
        ocr_scanned_issuing_state: '',
        ocr_scanned_nationality: '',
        ocr_scanned_date_of_birth: '1990-01-01',
        ocr_scanned_document_number: '999999999',
        ocr_scanned_document_type: 'passport',
        ocr_scanned_date_of_expiry: '2031-05-28',
        ocr_scanned_mismatch: 'ocr_scanned_document_number',
        ocr_scanned_document_type_internal: 'PASSPORT',
      },
      hasSimplifiedResult: false,
      confidenceLevel: '',
    },
  ],
  idvOverrideResult: null,
  facialDuplicateResult: '',
}
