import { BLOCKLIST_TABLE_KEY } from '../blocklist-table.key'

export const blocklistTableQa = {
  header: 'blocklist-table-header',
  filter: 'blocklist-table-filter',
  applyFilterButton: 'blocklist-table-apply-filter-button',
  clearFilterButton: 'blocklist-table-clear-filter-button',
  main: 'blocklist-table-main-table',
} satisfies Record<string, `${typeof BLOCKLIST_TABLE_KEY}-${string}`>

export const blocklistAtrCellQa = {
  main: 'blocklist-table-atr-cell-main',
  icon: 'blocklist-table-atr-cell-icon',
  content: 'blocklist-table-atr-cell-content',
  reasonCellMain: 'blocklist-table-atr-cell-reason-main',
  addCellMain: 'blocklist-table-atr-cell-add-main',
  updateCellMain: 'blocklist-table-atr-cell-update-main',
  addByCellMain: 'blocklist-table-atr-cell-addBy-main',
  addByTooltip: 'blocklist-table-atr-cell-addBy-tp-main',
} satisfies Record<string, `${typeof BLOCKLIST_TABLE_KEY}-atr-cell-${string}`>

export const blocklistDescQa = {
  main: 'blocklist-table-desc-cell-main',
} satisfies Record<string, `${typeof BLOCKLIST_TABLE_KEY}-desc-cell-${string}`>

export const blocklistTableSlotQa = {
  link: 'blocklist-table-slot-link',
  grid: 'blocklist-table-slot-grid',
} satisfies Record<string, `${typeof BLOCKLIST_TABLE_KEY}-slot-${string}`>

export const blocklistTableLoadingQa = {
  main: 'blocklist-table-loading-main',
  loader: 'blocklist-table-loading-loader',
  filter: 'blocklist-table-loading-filter',
} satisfies Record<string, `${typeof BLOCKLIST_TABLE_KEY}-loading-${string}`>

export const blocklistTableOverlayQa = {
  main: 'blocklist-table-overlay-main',
  icon: 'blocklist-table-overlay-icon',
  content: 'blocklist-table-overlay-content',
} satisfies Record<string, `${typeof BLOCKLIST_TABLE_KEY}-overlay-${string}`>
