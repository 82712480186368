import { camelCaseToDashCase } from 'shared/string'

import { IKYB_AML_SCREENING_KEY } from '../ikyb-aml-screening.key'

const iKybDataQa = <T extends readonly string[]>(data: T) => {
  const qa = data.reduce(
    (acc, key) => ({
      ...acc,
      [key]: `${IKYB_AML_SCREENING_KEY}-${camelCaseToDashCase(key)}-qa`,
    }),
    {},
  )

  return qa as Record<T[number], `${typeof IKYB_AML_SCREENING_KEY}-${string}`>
}

export const iKybAmlQa = iKybDataQa([
  'toggleSwitchContainer',
  'infoHeader',
  'dataGrid',
] as const)

export const iKybAmlDataGridQa = iKybDataQa([
  'dataGridProContainer',
  'applicationMatchStatusContainer',
] as const)

export const iKybAmlInfoHeaderQa = iKybDataQa(['grid'] as const)

export const iKybAmlStatusChangeQa = iKybDataQa([
  'form',
  'closeBtn',
  'textArea',
  'triggerBtn',
] as const)

export const iKybAmlAdverseMediaQa = iKybDataQa(['mediaArticle'] as const)

export const iKybAmlDataSourceQa = iKybDataQa([
  'container',
  'document',
] as const)

export const iKybAmlKeyDataQa = iKybDataQa(['listing', 'entity'] as const)

export const iKybAmlResultTabQa = iKybDataQa(['tab', 'container'] as const)

export const iKybAmlResultQa = iKybDataQa([
  'nextBtn',
  'prevBtn',
  'infoHeader',
  'infoHeaderSearchDetails',
] as const)

export const iKybAmlSlotQa = iKybDataQa([
  'container',
  'loader',
  'icon',
  'recordContainer',
] as const)

export const iKybMatchStrengthQa = iKybDataQa(['container', 'score'] as const)
export const iKybMatchStrengthBarQa = iKybDataQa([
  'container',
  'barContainer',
] as const)
export const iKybMatchStatusLabelQa = iKybDataQa(['container'] as const)

export const iKybIssuesCellQa = iKybDataQa(['container', 'badge'] as const)
export const iKybSelectedEntityCellQa = iKybDataQa([
  'button',
  'selected',
] as const)

export const iKybSelectedEntityCellHeaderQa = iKybDataQa([
  'container',
  'btn',
  'selected',
] as const)
