import React, { useState, useMemo, useEffect, useCallback } from 'react'

import { addDays } from 'date-fns'
import { useForm } from 'react-hook-form'

import { FrankieButton, FrankiePopover, FrankieTextField } from 'frankify/src'

import {
  DateFormatTypes,
  formatDate,
  endOfDay,
  startOfDay,
} from 'shared/date-time'
import { useI18n } from 'shared/i18n'

import { DASHBOARD_KEY } from '../../dashboard.key'
import { dashboardEn } from '../../locale/dashboard.en'
import {
  DateFilterForm,
  DateRange,
  DateRangeAvailableOptions,
  dateRangeAdditionalOptions,
  dateRangeRecord,
} from '../../model/dashboard-form.model'
import { dashboardQa, dashboardVueMigratedQa } from '../../qa/dashboard.qa'

const dateOnly = (value: Date) => value.toISOString().split('T')[0]

type Props = {
  selectedDateRange: DateRange
  onDateRangeChange: (arg: DateRange) => void
}

export function DateFilter({ selectedDateRange, onDateRangeChange }: Props) {
  const [isOpen, setIsOpen] = useState(false)
  const t = useI18n([DASHBOARD_KEY], { keys: dashboardEn })

  const defaultValues = useMemo(
    () => ({
      startDate: dateOnly(selectedDateRange.startDate),
      endDate: dateOnly(selectedDateRange.endDate),
    }),
    [selectedDateRange],
  )

  const {
    register,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<DateFilterForm>({
    defaultValues,
    mode: 'onBlur',
  })

  const validate = useCallback((value: string) => {
    const dateValue = new Date(value)
    return !Number.isNaN(dateValue.getTime())
  }, [])

  const { startDateRegister, endDateRegister } = useMemo(
    () => ({
      startDateRegister: register('startDate', { validate }),
      endDateRegister: register('endDate', { validate }),
    }),
    [register, validate],
  )

  const handleOpen = (open: boolean) => {
    // Syncing Date Value when open as user could close the menu without applying
    if (open) {
      setValue('startDate', defaultValues.startDate)
      setValue('endDate', defaultValues.endDate)
    }
    setIsOpen(open)
  }

  const applyDateFilter = (data: DateFilterForm) => {
    onDateRangeChange({
      startDate: addDays(startOfDay(new Date(data.startDate)), 1),
      endDate: endOfDay(new Date(data.endDate)),
    })
    setIsOpen(false)
  }

  const handleDateOption = (value: DateFilterForm['dateOption']) => {
    if (value) onDateRangeChange(dateRangeRecord()[value])
    setIsOpen(false)
  }

  useEffect(() => {
    const selectedStartDate = selectedDateRange.startDate
    const selectedEndDate = selectedDateRange.endDate

    const selectedDateOption = Object.entries(dateRangeRecord()).find(
      ([_key, value]) =>
        dateOnly(value.startDate) === dateOnly(selectedStartDate) &&
        dateOnly(value.endDate) === dateOnly(selectedEndDate),
    )

    if (selectedDateOption) {
      setValue('dateOption', selectedDateOption[0] as DateRangeAvailableOptions)
    } else {
      setValue('dateOption', null)
    }
  }, [selectedDateRange, setValue])

  const dateOptionValue = watch('dateOption')

  return (
    <div className="bg-mono-white">
      <FrankiePopover
        open={isOpen}
        onOpenChange={handleOpen}
        popoverRest={{
          placement: 'bottom-start',
        }}
        trigger={
          <FrankieButton
            size="xs"
            endIcon={{
              size: 'xs',
              name: isOpen ? 'mdiChevronUp' : 'mdiChevronDown',
            }}
            onClick={() => handleOpen(!isOpen)}
            noStyles
            className={`h-full min-w-[200px] flex items-center justify-between border border-solid rounded-sm px-2 ${
              isOpen ? 'border-primary-800' : 'border-neutral-50'
            }`}
            testId={{ button: dashboardVueMigratedQa.dateFilterCta }}
          >
            {t('dateFilter.dateRange', {
              startDate: formatDate(
                dateOnly(selectedDateRange.startDate),
                DateFormatTypes.DateNumbers,
              ),
              endDate: formatDate(
                dateOnly(selectedDateRange.endDate),
                DateFormatTypes.DateNumbers,
              ),
            })}
          </FrankieButton>
        }
      >
        <div
          className="bg-mono-white shadow-md min-w-[200px] p-2 rounded-sm"
          data-qa={dashboardQa.popupWrapper}
        >
          <form
            onSubmit={handleSubmit(applyDateFilter)}
            className="rounded-sm p-2 mb-2"
          >
            <FrankieTextField
              {...startDateRegister}
              testId={{ input: dashboardQa.startInput }}
              className="mb-2"
              type="date"
              label="Start Date"
              error={!!errors.startDate}
              errorText={errors.startDate ? t('dateFilter.invalidDate') : ''}
            />

            <FrankieTextField
              {...endDateRegister}
              testId={{ input: dashboardQa.endInput }}
              className="mb-2"
              type="date"
              label="End Date"
              error={!!errors.endDate}
              errorText={errors.endDate ? t('dateFilter.invalidDate') : ''}
            />

            <FrankieButton size="xs" className="ms-auto" type="submit">
              {t('dateFilter.apply')}
            </FrankieButton>
          </form>
          <div
            className="flex flex-col border border-tertiary-grey-200 rounded-sm"
            data-qa={dashboardVueMigratedQa.dateFilterOptionList}
          >
            {dateRangeAdditionalOptions.map(item => (
              <FrankieButton
                key={item.value}
                noStyles
                className={`border-b border-tertiary-grey-200 p-2 cursor-pointer text-start ${
                  item.value === dateOptionValue
                    ? 'bg-primary-container'
                    : 'hover:bg-tertiary-grey-100'
                }`}
                onClick={() => handleDateOption(item.value)}
              >
                {t(`dateFilter.option.${item.tKey}`)}
              </FrankieButton>
            ))}
          </div>
        </div>
      </FrankiePopover>
    </div>
  )
}
