import { useMutation } from '@tanstack/react-query'

import { useApplicantDataQuery } from 'entities/applicant'

import { ErrorResponse } from 'shared/http'
import { useI18n } from 'shared/i18n'
import { notification } from 'shared/notification'

import { applicantBiometricsApi } from '../../api/applicant-biometrics-ocr.api'
import { APPLICANT_BIOMETRICS_OCR_KEY } from '../../applicant-biometrics-ocr.key'
import { applicantBiometricsOcrEn } from '../../locale/applicant-biometrics-ocr.en'
import { IDV_DOCS } from '../../model/applicant-biometrics-ocr.model'
import { useApplicantBiometricsOcrQuery } from '../applicant-biometrics-ocr-query/applicant-biometrics-ocr.query'

type Args = {
  applicantId: string
}

export const useApplicantRefreshIDVResult = ({ applicantId }: Args) => {
  const t = useI18n([APPLICANT_BIOMETRICS_OCR_KEY], {
    keys: applicantBiometricsOcrEn,
  })

  const { refetch } = useApplicantBiometricsOcrQuery({
    applicantId,
  })

  const { data: applicantData } = useApplicantDataQuery({ applicantId })

  const mutation = useMutation({
    mutationFn: () => {
      const checkId =
        applicantData?.documents.find(doc =>
          (IDV_DOCS as string[]).includes(doc.idType),
        )?.extraData?.idv_webcapture_check_id || ''

      return applicantBiometricsApi.refreshIdvResult(applicantId, { checkId })
    },
    onSuccess: () => {
      void refetch()
      notification.success(t('success.refresh'))
    },
    onError: (err: ErrorResponse) => {
      notification.error(err.response?.data.message ?? t('error.refresh'))
    },
  })

  return mutation
}
