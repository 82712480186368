import type { IClient } from 'shared/client'

import type {
  Entity,
  ParentAssociation,
} from '../model/applicant-related-organisations.model'

export class ApplicantRelatedOrganisationsAPI {
  client: IClient

  constructor(client: IClient) {
    this.client = client
  }

  getParentAssociations(entityId: string) {
    return this.client.get<ParentAssociation>(
      `data/v2/business/${entityId}/associations`,
    )
  }

  getEntityDetails(entityId: string) {
    return this.client.get<Entity>(`data/v2/entities/${entityId}`)
  }
}
