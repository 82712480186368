export const ROUTING_KEY = 'routing'

export const routingEn = {
  nav: {
    viewMenu: 'View menu',
    applicants: {
      title: 'Entities',
      all: 'All entities',
      review: 'Needs review',
      individuals: 'Individuals',
      organizations: 'Organizations',
    },
    analytics: 'Analytics',
    documentation: 'Developer hub',
    blocklist: 'Blocklist',
    newProfile: 'New entity',
    customView: 'CUSTOM VIEWS',
    customViewTooltip: 'Create new view',
  },
  settingsNav: {
    title: 'Settings',
    notifications: 'Notifications',
    personal: 'Personal',
    account: 'Account',
    roles: 'Role management',
    users: 'User management',
  },
  analyticsNav: {
    dashboard: 'Dashboard',
    systemMonitoring: 'System Monitoring',
  },
  header: {
    changePasswordCta: 'Change Password',
    logoutCta: 'Logout',
    settingsLink: 'Settings',
    notification: {
      resetPassword: 'Password reset email has been sent to {{email}}',
    },
  },
  newProfile: {
    title: 'Create New Profile',
    titleV2: 'Create new entity profile',
    plusIconTestId: 'create-icon-test-id',
    individual: 'Individual',
    business: 'Organisation',
  },
  applicant: {
    nav: {
      overview: 'Overview',
      auditReport: 'Audit Report',
      document: 'Document',
      documentUpload: 'Documents',
      ownersOfficeholders: 'Owners & Officeholders',
      riskScore: 'Risk Score',
      kycFraudCheck: 'KYC Fraud Check',
      kybFraudCheck: 'KYB Fraud Check',
      personalInfo: 'Personal Info',
      blocklistedInfo: 'Blocklisted Info',
      potentialMatches: 'Potential Matches',
      blocklist: 'Blocklist',
      duplicates: 'Duplicates',
      amlScreening: 'AML Screening',
      biometricsOcr: 'Biometrics / OCR',
      transactions: 'Transactions',
      characteristics: 'Device / Customer Characteristics',
      documents: 'Documents',
      relatedOrganisations: 'Related Organisations',
    },
    profileType: {
      organisation: 'Organisation',
      individual: 'Individual',
    },
    riskLevel: 'Risk Level',
    status: 'Status',
    blocklisted: 'Blocklisted',
    customerId: 'Customer ID',
    customerRef: 'Customer Ref',
    copied: 'Copied...',
    copy: 'Copy',
  },
  applicantF2: {
    nav: {
      workflowEvents: 'Workflow events',
      personalInfoKyc: 'Profile',
      auditReport: 'Audit Report',
    },
    dropdown: {
      edit: 'Edit profile',
      archive: 'Archive entity',
      unarchive: 'Unarchive entity',
    },
  },
}
