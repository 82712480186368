import React from 'react'

import { useI18n } from 'shared/i18n'
import { parsePhoneNumber } from 'shared/phone-number'
import { TrackingEventsTypes, trackingManager } from 'shared/tracking'

import { APPLICANT_KEY, applicantEn } from '../../../locale/applicant.en'
import {
  IFraudCheckpointRule,
  IPhoneCheckpoint,
} from '../../../model/applicant-fraud-check.model'
import { CheckHeader } from '../check-header/check-header'
import { ContentItem } from '../content-item/content-item'

type Props = {
  data: IPhoneCheckpoint
  number: string | null
  rules: IFraudCheckpointRule[] | null
  checkResult: 'fail' | 'unchecked' | 'pass'
  deviceSardineUrl: string | null
}

export function FraudCheckPhone({
  data,
  number,
  rules,
  checkResult,
  deviceSardineUrl,
}: Props) {
  const t = useI18n(APPLICANT_KEY, { keys: applicantEn })

  let { phoneCountry } = data
  if (!phoneCountry && number) {
    try {
      const parsedPhoneNumber = parsePhoneNumber(number)
      if (parsedPhoneNumber.country) {
        phoneCountry = parsedPhoneNumber.country
      }
    } catch (e) {
      // do nothing (parse error)
    }
  }

  return (
    <div>
      <CheckHeader
        checkResult={checkResult}
        riskLevel={data.riskLevel}
        rules={rules}
        ruleIds={data.ruleIds}
        title={t('fraud.phone.title')}
        buttonLabel={t('fraud.phone.viewRulesCta')}
        modalTitle={t('fraud.checkRules.phone')}
        onViewRules={() =>
          trackingManager.track(TrackingEventsTypes.FraudViewResultsPhone)
        }
        deviceSardineUrl={deviceSardineUrl}
      />
      <div className="flex flex-row gap-x-[18] gap-y-6 flex-wrap">
        <ContentItem
          className="max-w-[252px] w-full"
          name={t('fraud.phone.phoneNumber')}
          value={number}
        />
        <ContentItem
          className="max-w-[252px] w-full"
          name={t('fraud.phone.lineType')}
          value={data.lineType}
        />
        <ContentItem
          className="max-w-[252px] w-full"
          name={t('fraud.phone.carrier')}
          value={data.carrier}
        />
        <ContentItem
          className="max-w-[252px] w-full"
          name={t('fraud.phone.phoneCountry')}
          value={phoneCountry}
        />
      </div>
    </div>
  )
}
