import { FrankieRawApplicant } from 'entities/applicant'

import { bffClient, IClient } from 'shared/client'
import { Nullable } from 'shared/typescript'

import { IApiFilters } from '../model/applicant-api-filter.model'

export class ApplicantsApi {
  client: IClient

  constructor(client: IClient) {
    this.client = client
  }

  // eslint-disable-next-line complexity, max-params
  async getGlobalApplicants(
    limit?: string,
    nextPageMarker?: Nullable<string>,
    filters?: IApiFilters,
    signal?: AbortSignal,
  ) {
    return this.client.get<{
      applicants: FrankieRawApplicant[]
      nextPageMarker?: string
    }>('/data/v1/applicants/global/list', {
      params: filters
        ? {
            limit,
            nextPageMarker,
            matchStatusFilter: filters.matchStatusFilter.join(',') || undefined,
            nameFilter: filters.nameFilter || undefined,
            entityIdFilter: filters.entityIdFilter || undefined,
            entityTypeFilter: filters.entityTypeFilter || undefined,
            amlStatusFilter: filters.amlStatusFilter.join(',') || undefined,
            riskStatusFilter: filters.riskStatusFilter.join(',') || undefined,
            profileNameFilter: filters.profileNameFilter.join(',') || undefined,
            assigneeFilter: filters.assigneeFilter.join(',') || undefined,
            startDate: filters.startDate || undefined,
            endDate: filters.endDate || undefined,
          }
        : { limit, nextPageMarker },
      signal,
    })
  }
}

export const applicantsApi = new ApplicantsApi(bffClient)
