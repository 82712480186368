import React from 'react'

import { FrankieCheckbox, IFrankieCheckboxProps } from 'frankify/src'

export type GridCheckboxProps = IFrankieCheckboxProps & {
  indeterminate: boolean
}

export function GridCheckbox({ indeterminate, ...rest }: GridCheckboxProps) {
  return <FrankieCheckbox partial={indeterminate} {...rest} />
}
