import { useCallback, useEffect, useState } from 'react'

import { useQuery } from '@tanstack/react-query'

import {
  DateRange,
  SelectedAccounts,
} from 'features/dashboard/model/dashboard-form.model'

import { downloadBlobAsFile } from 'shared/file'

import { dashboardApi } from '../../api/dashboard.api'
import { AnalyticsResponse } from '../../model/dashboard.model'

export const AnalyticsQueryKey = ['analytics']
export const AnalyticsDownloadQueryKey = ['analytics-download']

export const useAnalyticsQuery = ({ startDate, endDate }: DateRange) =>
  useQuery<AnalyticsResponse>({
    enabled: true,
    queryKey: [AnalyticsQueryKey, startDate, endDate],
    queryFn: async () => {
      const { data } = await dashboardApi.getAnalytics({
        startDate,
        endDate,
      })
      return data
    },
  })

export const useDownloadAnalyticsReportQuery = ({
  startDate,
  endDate,
  customerChildIds,
  isOwnSelected,
}: DateRange & SelectedAccounts) => {
  const [isEnabled, setIsEnabled] = useState(false)

  const query = useQuery({
    enabled: isEnabled,
    queryKey: [
      AnalyticsDownloadQueryKey,
      startDate,
      endDate,
      customerChildIds,
      isOwnSelected,
    ],
    queryFn: async () => {
      const { data } = await dashboardApi.getAnalyticsCSV({
        startDate,
        endDate,
        customerChildIds,
        isOwnSelected,
      })

      return data
    },
  })

  useEffect(() => {
    const { isFetched, data: csvReport } = query
    if (isEnabled && isFetched && csvReport) {
      downloadBlobAsFile(csvReport, {
        fileName: 'Dashboard_Report.csv',
        type: 'text/csv',
      })

      setIsEnabled(false)
    }
  }, [isEnabled, query])

  const downloadReport = useCallback(() => {
    setIsEnabled(true)
  }, [])

  return { ...query, downloadReport }
}
