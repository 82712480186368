import posthog, { PostHog } from 'posthog-js'

import { ITracker, TrackerNameTypes, trackingManager } from 'shared/tracking'

class PostHogTrackerService implements ITracker {
  posthog: PostHog

  name = TrackerNameTypes.POSTHOG

  constructor(posthog: PostHog) {
    this.posthog = posthog
  }

  track(event: string, properties?: Record<string, unknown>) {
    this.posthog.capture(event, properties)
  }
}

export function posthogInit(id: string, properties: Record<string, unknown>) {
  posthog.identify(id, {
    version: process.env.APP_VERSION,
    environment: process.env.ENV_NAME,
    ...properties,
  })
}

export function posthogRegisterTracker() {
  const posthogEnabled = process.env.POSTHOG_ENABLED === 'true'
  const posthogHost = process.env.POSTHOG_HOST || ''
  const posthogKey = process.env.POSTHOG_KEY || ''

  if (
    posthogEnabled &&
    !trackingManager.isTrackerExists(TrackerNameTypes.POSTHOG)
  ) {
    posthog.init(posthogKey, {
      api_host: posthogHost,
      person_profiles: 'identified_only',
      session_recording: {
        maskTextSelector: '.data-hj-suppress, [data-hj-suppress]',
      },
    })

    trackingManager.register(new PostHogTrackerService(posthog))
  }
}
