import { useMemo } from 'react'

import { useEntityDataQuery, useEntityLabelDataState } from 'entities/entity'
import { useSessionQuery } from 'entities/session'

import { trackingManager } from 'shared/tracking'

import {
  flowIdOptions,
  sendLinkEventsMapping,
  SendLinkEventTypes,
} from '../model/individual-send-verification-link.model'
import { useIndividualSendLinkContentQuery } from '../query/individual-send-link-content/individual-send-link-content.query'

type Args = {
  entityId: string
}

export type SendLinkEntityData = {
  name: string
  number: string
  country: string
  flowIdOptions: { label: string; value: string }[]
  smsContent: string
  kycSmsContent: string
  isSendSMSEnabled: boolean
  isLivenessCheckEnabled: boolean
}

export const useSendLinkState = ({ entityId }: Args) => {
  const { data, isLoading: loadingEntity } = useEntityDataQuery(entityId)
  const { personalInfoWithLabelData } = useEntityLabelDataState({ entityId })
  const { data: smsData, isLoading } = useIndividualSendLinkContentQuery()
  const name = personalInfoWithLabelData.at(0)?.value

  const { data: sessionData } = useSessionQuery()
  const phoneNumber = data?.individual?.phoneNumbers?.[0]
  const number = phoneNumber?.number
  const country = phoneNumber?.country || 'AUS'

  const entityData = useMemo(
    () => ({
      name,
      number,
      country,
      flowIdOptions: flowIdOptions.filter(i =>
        smsData?.flowIds.includes(i.value),
      ),
      smsContent: smsData?.smsContent,
      kycSmsContent: sessionData?.features.sendKycLink?.kycSMSContent,
      isSendSMSEnabled: smsData?.isSendSMSEnabled,
      isLivenessCheckEnabled: smsData?.isLivenessCheckEnabled,
    }),
    [country, name, number, smsData?.flowIds, sessionData, smsData?.smsContent, smsData?.isSendSMSEnabled, smsData?.isLivenessCheckEnabled],
  )

  if (!data || !smsData)
    return { entityData: undefined, loading: isLoading || loadingEntity }

  return { entityData, loading: loadingEntity }
}

export const useSendLinkTrackingEvent = (
  type: SendLinkEventTypes,
  entityId?: string,
) => {
  const { data } = useEntityDataQuery(entityId)

  const track = useMemo(() => {
    const entityType = data?.individual?.entityType

    if (entityType) {
      return () =>
        trackingManager.track(sendLinkEventsMapping[entityType][type])
    }

    return () => {}
  }, [data, type])

  return track
}
