import React, { useState } from 'react'

import { FrankieButton, FrankieCheckbox, FrankieImage } from 'frankify/src'

import { ApplicantId, KybReportType } from 'entities/applicant'

import { useI18n } from 'shared/i18n'
import { Noop } from 'shared/typescript'

import { APPLICANT_GENERAL_INFORMATION_KEY } from '../../applicant-general-information.key'
import { businessReportIcon } from '../../assets'
import { applicantGeneralInformationEn } from '../../locale/applicant-general-information.en'
import {
  BusinessReportTypes,
  businessReportData,
} from '../../model/applicant-business.model'
import { applicantBusinessReportModalQa } from '../../qa/applicant-general-information.qa'
import { useApplicantGenerateBusinessReportMutation } from '../../state/applicant-generate-business-report/applicant-generate-business-report'

type Props = {
  applicantId: ApplicantId
  handleClose: Noop
  businessReportType: BusinessReportTypes
}

export function ApplicantBusinessReportModal({
  applicantId,
  businessReportType,
  handleClose,
}: Props) {
  const t = useI18n([APPLICANT_GENERAL_INFORMATION_KEY], {
    keys: applicantGeneralInformationEn,
  })

  const [includePaymentAndRiskScore, setIncludePaymentAndRiskScore] =
    useState(false)

  const { generateBusinessReport } = useApplicantGenerateBusinessReportMutation(
    { applicantId },
  )

  const { icon, titleTKey, kybReportType } =
    businessReportData[businessReportType]

  const onClose = () => {
    handleClose()
  }

  const handleGenerateReport = () => {
    const type: KybReportType =
      businessReportType === BusinessReportTypes.credit &&
      includePaymentAndRiskScore
        ? 'CR-RISK-PAYMENT' // If include payment and risk score then type is CR-RISK-PAYMENT
        : kybReportType
    generateBusinessReport(type)
    onClose()
  }

  return (
    <div className="relative bg-mono-white rounded-2 w-[500px]">
      <div className="flex justify-between py-4 px-6 border-b border-neutral-50">
        <div className="flex gap-2">
          <FrankieImage className="w-[25px]" src={businessReportIcon[icon]} />

          <div className="text-md font-bold">{t(titleTKey)}</div>
        </div>
        <FrankieButton
          noStyles
          onClick={onClose}
          className="flex-grow-0"
          singleIcon={{
            name: 'mdiClose',
          }}
          testId={{ button: applicantBusinessReportModalQa.closeBtn }}
        />
      </div>

      <div className="flex flex-col gap-4 overflow-y-auto p-6">
        {businessReportType === BusinessReportTypes.owner && (
          <>
            <div
              className="text-tertiary-grey-700 font-bold"
              data-qa={applicantBusinessReportModalQa.reportType}
            >
              {t('action.heading.singleBusinessReport')}
            </div>

            <div className="text-tertiary-grey-700">
              {t('action.description.identifyShareholder')}
            </div>
          </>
        )}

        {businessReportType === BusinessReportTypes.ubo && (
          <>
            <div className="text-tertiary-grey-700 font-bold">
              {t('action.description.uboReportInformation')}
            </div>
            <div className="text-tertiary-grey-700 font-bold">
              {t('action.description.pepInformation')}
            </div>
          </>
        )}

        {businessReportType === BusinessReportTypes.credit && (
          <>
            <div className="text-tertiary-grey-700 font-bold">
              {t('businessReport.creditReport')}
            </div>
            <div className="text-tertiary-grey-700 ">
              {t('action.description.includeCreditReport')}
            </div>
            <div className="flex gap-2 text-tertiary-grey-700 font-bold">
              <FrankieCheckbox
                onChange={() => setIncludePaymentAndRiskScore(prev => !prev)}
                checked={includePaymentAndRiskScore}
              />
              <span> {t('action.fields.addRiskScore')}</span>
            </div>
          </>
        )}

        <div className="flex justify-between mt-4">
          <FrankieButton
            onClick={handleGenerateReport}
            testId={{
              button: applicantBusinessReportModalQa.generateReportBtn,
            }}
          >
            {t('action.generateReport')}
          </FrankieButton>

          <FrankieButton intent="secondary" onClick={onClose}>
            {t('action.close')}
          </FrankieButton>
        </div>
      </div>
    </div>
  )
}
