import React from 'react'

import { FrankieButton, FrankieImage } from 'frankify/src'

import { ApplicantId } from 'entities/applicant'

import { useI18n } from 'shared/i18n'
import { objectAsValueOptions } from 'shared/typescript'

import { APPLICANT_GENERAL_INFORMATION_KEY } from '../../applicant-general-information.key'
import { businessCheckIcon } from '../../assets'
import { applicantGeneralInformationEn } from '../../locale/applicant-general-information.en'
import {
  BusinessCheckTypes,
  businessCheckData,
} from '../../model/applicant-business.model'
import { useApplicantBusinessState } from '../../state/applicant-business-state/applicant-business-state'
import { useApplicantCheckTrigger } from '../../state/applicant-check-trigger/applicant-check-trigger.mutation'

type Props = {
  applicantId: ApplicantId
}

export function ApplicantBusinessCheckRun({ applicantId }: Props) {
  const t = useI18n([APPLICANT_GENERAL_INFORMATION_KEY], {
    keys: applicantGeneralInformationEn,
  })

  const { businessCheck } = useApplicantBusinessState({ applicantId })
  const { mutate } = useApplicantCheckTrigger({ applicantId })

  const checksToRun = objectAsValueOptions(businessCheckData).filter(
    item => businessCheck[item.value].isPending,
  )

  /**
   * @description
   * Run check, currently only run force check for PEP. In the future, we can add more checks here.
   */
  const handleCheckRun = (value: BusinessCheckTypes) => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (value === BusinessCheckTypes.pep) {
      mutate({ checksToRun: ['forceCheck'] })
    }
  }

  if (checksToRun.length === 0) {
    return null
  }

  return (
    <div className="bg-neutral-20 p-6 rounded-md">
      <div className="text-lg">{t('businessCheck.select')}</div>

      <div className="">
        {checksToRun.map(({ titleTKey, descriptionTKey, icon, value }) => (
          <div
            className="flex gap-4 items-start p-4 mt-6 min-h-[95px] border border-tertiary-grey-200 rounded-sm bg-mono-white "
            key={value}
          >
            <FrankieImage
              className="max-w-[34px] py-2"
              src={businessCheckIcon[icon]}
            />

            <div className="font-semibold leading-tight flex-grow">
              <div className="text-md">{t(titleTKey)}</div>
              <div className="text-tertiary-grey-400 ">
                {t(descriptionTKey)}
              </div>
            </div>

            <FrankieButton onClick={() => handleCheckRun(value)}>
              {t('action.runCheck')}
            </FrankieButton>
          </div>
        ))}
      </div>
    </div>
  )
}
