import React, { useEffect, useMemo, useState } from 'react'

import { useParams } from 'react-router-dom'

import {
  FrankieBadge,
  FrankieDivider,
  FrankieIcon,
  FrankieLoader,
} from 'frankify/src'

import { applicantDetailConfig, businessInfoConfig } from 'entities/applicant'
import { parseStreetName } from 'entities/applicant/model/applicant-address.model'
import { TrustDeedTypes } from 'entities/applicant/model/applicant-trust-analyser.model'
import { EnumAddressType } from 'entities/entity/model/entity.model'
import { useApplicantBusinessAssociationRolesQuery } from 'entities/organisation'
import { useSessionQuery } from 'entities/session'

import { DateFormatTypes, formatDate } from 'shared/date-time'
import { useI18n } from 'shared/i18n'
import { TrackingEventsTypes, trackingManager } from 'shared/tracking'

import { APPLICANT_SUPPORTING_DOCUMENTS_KEY } from '../../../applicant-supporting-documents.key'
import { applicantSupportingDocumentsEn } from '../../../locale/applicant-supporting-documents.en'
import {
  ApplicantTrustTypes,
  IDocumentInformationEntity,
  IValueReferenceIds,
  ITrustAnalyse,
  IDocumentInformationAddresses,
  TrustInformationTypes,
} from '../../../model/applicant-supporting-documents.model'
import {
  checkAssociation,
  getLinkedEntityData,
  getReferenceAlphabet,
  getReferenceNumber,
  getReferenceType,
  mapLinkedData,
  TrustCommonFormType,
  TrustDeedAssociatedParty,
  TrustFormTypes,
  TrustReferenceTypes,
} from '../../../model/applicant-supporting-trust-deed.model'
import {
  trustAnalyserReferenceDetailQa,
  trustAnalyserReferenceQa,
  trustAnalyserViewFormQa,
  trustDeedsDocQa,
} from '../../../qa/applicant-support-documents.qa'
import {
  useAssociateNewPartyMutation,
  useUpdateAssociatedPartyMutation,
} from '../../../state/trust-deeds-mutation/trust-deeds-mutation'
import { useTrustDeedAssociatedPartiesQuery } from '../../../state/trust-deeds-query/trust-deeds-query'
import { TrustAnalyserReference } from '../trust-analyser-reference/trust-analyser-reference'

export type TrustAnalyserViewFormProps = {
  data: ITrustAnalyse
  scrollToPage: (page: number) => void
  associatedParties: TrustDeedAssociatedParty[]
}

type CommonViewFormProps = {
  data: ITrustAnalyse
  trustFormKey: TrustFormTypes
  trustFormData: IDocumentInformationEntity[] | IValueReferenceIds[]
  addAssociateParty: (type: TrustFormTypes, data: TrustCommonFormType) => void
  associatedParties: TrustDeedAssociatedParty[]
  scrollToPage: (page: number) => void
  formIndex: number
  showTitle?: boolean
}

function AddAssociatePartyBtn({
  trustFormKey,
  item,
  index,
  addAssociateParty,
  isFetching,
}: {
  trustFormKey: TrustFormTypes
  item: TrustCommonFormType
  index: number
  addAssociateParty: (type: TrustFormTypes, data: TrustCommonFormType) => void
  isFetching: boolean
}) {
  const t = useI18n([APPLICANT_SUPPORTING_DOCUMENTS_KEY], {
    keys: applicantSupportingDocumentsEn,
  })

  const [isDisabled, setIsDisabled] = useState(false)

  useEffect(() => {
    if (!isFetching) {
      setIsDisabled(false)
    }
  }, [isFetching])

  return (
    <div
      onClick={() => {
        setIsDisabled(true)
        addAssociateParty(trustFormKey, item)
      }}
      onKeyDown={() => {
        setIsDisabled(true)
        addAssociateParty(trustFormKey, item)
      }}
      tabIndex={0}
      role="button"
      className={`flex ${
        isDisabled ? 'cursor-not-allowed pointer-events-none' : 'cursor-pointer'
      } ml-4 items-center gap-1`}
      data-qa={trustAnalyserViewFormQa.addAssociate(trustFormKey, index + 1)}
    >
      <FrankieIcon
        className={`
          cursor-pointer ${
            isDisabled ? 'text-tertiary-grey-400' : 'text-primary-800'
          } `}
        name="mdiAccountPlusOutline"
        size="xs"
      />
      <div
        className={`${
          isDisabled ? 'text-tertiary-grey-400' : 'text-primary-800'
        } text-sm font-semibold`}
        role="button"
        tabIndex={0}
      >
        {t('addAssociateParty')}
      </div>
    </div>
  )
}

type GeneralBeneficiariesViewFormProps = {
  trustFormData: IValueReferenceIds[]
  text: {
    title: string
    subtitle: string
    label: string
  }
  scrollToPage: (page: number) => void
  data: ITrustAnalyse
  formIndex: number
}

function GeneralBeneficiariesViewForm({
  trustFormData,
  text,
  scrollToPage,
  data,
  formIndex,
}: GeneralBeneficiariesViewFormProps) {
  return (
    <div className="mb-6">
      <div className="text-md font-semibold my-4">{text.title}</div>
      <div className="text-sm font-bold mb-2">{text.subtitle}</div>
      {trustFormData.map((beneficiary, index) => (
        <div className="mb-2">
          <div className="font-semibold">
            {`${text.label} ${index + 1}`}
            <TrustAnalyserReference
              data={data}
              getPageNumber={page => scrollToPage(page)}
              text={`[${getReferenceNumber(formIndex)}${getReferenceAlphabet(
                index,
              )}]`}
              type={TrustReferenceTypes.GENERAL_BENEFICIARIES}
              index={index}
              testId={{
                reference: trustAnalyserReferenceQa(
                  TrustFormTypes.GeneralBeneficiary,
                  index,
                ),
              }}
            />
          </div>
          <div
            data-qa={trustAnalyserViewFormQa.generalBeneficiaries(
              TrustFormTypes.GeneralBeneficiary,
              index + 1,
            )}
          >
            {beneficiary.value || '-'}
          </div>
        </div>
      ))}
    </div>
  )
}

function RenderFormBlock({
  className,
  label,
  dataQa,
  value,
}: {
  className: string
  label: string
  dataQa: string
  value: string | number | ApplicantTrustTypes | undefined
}) {
  return (
    <div className={className}>
      <p className="font-semibold">{label}</p>
      <p data-qa={dataQa} data-hj-suppress>
        {value || '-'}
      </p>
    </div>
  )
}

function CommonViewForm({
  data,
  trustFormKey,
  trustFormData,
  addAssociateParty,
  associatedParties,
  showTitle = true,
  scrollToPage,
  formIndex,
}: CommonViewFormProps) {
  const { applicantId } = useParams()

  const { isLoading, isFetching } = useTrustDeedAssociatedPartiesQuery({
    applicantId,
    trustAnalysisId: data.analysisId,
    analysisStatus: TrustDeedTypes.CONFIRMED,
  })

  const t = useI18n([APPLICANT_SUPPORTING_DOCUMENTS_KEY], {
    keys: applicantSupportingDocumentsEn,
  })
  // check if the role is OTHER
  const isAssociated = (party: TrustCommonFormType) => {
    const isIndividual = party.type === ApplicantTrustTypes.Individual
    return checkAssociation({
      associatedParties,
      isIndividual,
      party,
    })
  }

  const { trust } = data.documentInformation || {}
  if (!trust) return null

  if (trustFormKey === TrustFormTypes.GeneralBeneficiary) {
    return (
      <GeneralBeneficiariesViewForm
        trustFormData={trustFormData as IValueReferenceIds[]}
        text={{
          title: t('beneficiaries'),
          subtitle: t('generalBeneficiaries'),
          label: t('group'),
        }}
        data={data}
        scrollToPage={scrollToPage}
        formIndex={formIndex}
      />
    )
  }

  const { totalUnits } = trust.typeInformation[TrustInformationTypes.unit] || {}

  return (
    <>
      {showTitle && (
        <div className="text-md font-semibold my-4">{t(trustFormKey)}</div>
      )}
      {mapLinkedData(trustFormData as IDocumentInformationEntity[], trust).map(
        // eslint-disable-next-line complexity
        (item: TrustCommonFormType, index: number) => {
          const {
            type,
            entityId,
            name = '',
            abnOrAcn = '',
            address = '',
            dateOfBirth = '',
            holdingCount = 0,
            role = '',
          } = item

          const dob = dateOfBirth
            ? formatDate(dateOfBirth, DateFormatTypes.DateNumbersSlash)
            : '-'

          const isMemberType = trustFormKey === TrustFormTypes.Member
          const getRoleAddressStr = isMemberType ? 'role' : 'address'
          const getForm = {
            label: t(getRoleAddressStr),
            value: isMemberType ? role : address,
          }

          return (
            <div key={entityId + name + address} className="mb-4">
              {index === 0 && trustFormKey === TrustFormTypes.UnitHolder && (
                <div className="grid grid-cols-3 gap-4">
                  <h4 className="text-sm font-bold mb-4 flex">
                    {t('totalUnits')}
                  </h4>
                  <p>{totalUnits?.value || '-'}</p>
                </div>
              )}
              <div className="text-sm font-bold mb-4 flex">
                <div>
                  {t(trustFormKey)}
                  <span className="px-1">{index + 1}</span>
                  <TrustAnalyserReference
                    text={`[${getReferenceNumber(
                      formIndex,
                    )}${getReferenceAlphabet(index)}]`}
                    data={data}
                    type={getReferenceType[trustFormKey]}
                    getPageNumber={page => scrollToPage(page)}
                    index={index}
                    testId={{
                      reference: trustAnalyserReferenceQa(trustFormKey, index),
                    }}
                  />
                </div>
                <div className="ml-2">
                  <FrankieLoader
                    loading={isLoading}
                    loaderClassName="!items-start pl-6 ml-4 !bg-opacity-100"
                    size="xs"
                  >
                    {isAssociated(item) ? (
                      <FrankieBadge
                        theme="green"
                        size="sm"
                        text={t('associatedParty')}
                        className="ml-5"
                      />
                    ) : (
                      <AddAssociatePartyBtn
                        trustFormKey={trustFormKey}
                        item={item}
                        index={index}
                        addAssociateParty={addAssociateParty}
                        isFetching={isFetching}
                      />
                    )}
                  </FrankieLoader>
                </div>
              </div>
              <div className="grid grid-cols-3 gap-4">
                <RenderFormBlock
                  className="col-span-1"
                  dataQa={trustAnalyserViewFormQa.type(trustFormKey, index + 1)}
                  label={t('trustType')}
                  value={type}
                />
                <RenderFormBlock
                  className="col-span-2"
                  dataQa={trustAnalyserViewFormQa.name(trustFormKey, index + 1)}
                  label={t('name')}
                  value={name}
                />
                {type === ApplicantTrustTypes.Individual && (
                  <>
                    <RenderFormBlock
                      className="col-span-1"
                      dataQa={trustAnalyserViewFormQa.dateOfBirth(
                        trustFormKey,
                        index + 1,
                      )}
                      label={t('dateOfBirth')}
                      value={dob}
                    />
                    <RenderFormBlock
                      className="col-span-2"
                      dataQa={trustAnalyserViewFormQa[getRoleAddressStr](
                        trustFormKey,
                        index + 1,
                      )}
                      label={getForm.label}
                      value={getForm.value}
                    />

                    {isMemberType && (
                      <RenderFormBlock
                        className="col-span-3"
                        dataQa={trustAnalyserViewFormQa.address(
                          trustFormKey,
                          index + 1,
                        )}
                        label={t('address')}
                        value={address}
                      />
                    )}
                  </>
                )}
                {type === ApplicantTrustTypes.Organization && (
                  <>
                    <RenderFormBlock
                      className="col-span-1"
                      dataQa={trustAnalyserViewFormQa.abnOrAcn(
                        trustFormKey,
                        index + 1,
                      )}
                      label={t('abnOrAcn')}
                      value={abnOrAcn}
                    />
                    <RenderFormBlock
                      className="col-span-2"
                      dataQa={trustAnalyserViewFormQa.address(
                        trustFormKey,
                        index + 1,
                      )}
                      label={t('principalPlace')}
                      value={address}
                    />
                  </>
                )}
                {trustFormKey === TrustFormTypes.UnitHolder && (
                  <RenderFormBlock
                    className="col-span-3"
                    dataQa={trustAnalyserViewFormQa.holdingCount(
                      trustFormKey,
                      index + 1,
                    )}
                    label={t('holdingCount')}
                    value={holdingCount}
                  />
                )}
              </div>
            </div>
          )
        },
      )}
      <FrankieDivider className="my-4" />
    </>
  )
}

export function TrustAnalyserViewForm({
  data,
  associatedParties,
  scrollToPage,
}: TrustAnalyserViewFormProps) {
  const t = useI18n([APPLICANT_SUPPORTING_DOCUMENTS_KEY], {
    keys: applicantSupportingDocumentsEn,
  })
  const { applicantId } = useParams()
  const { data: pageData } = useSessionQuery()

  const { mutate: associateNewPartyMutate } = useAssociateNewPartyMutation(
    applicantId,
    data.analysisId,
  )

  const { mutate: updateAssociatePartyMutate } =
    useUpdateAssociatedPartyMutation(applicantId, data.analysisId)

  const { data: roleTypes } = useApplicantBusinessAssociationRolesQuery()
  useEffect(() => {
    trackingManager.track(TrackingEventsTypes.TrustAnalyserAnalysisResultShow)
  }, [])

  const otherAssociateRole = (
    inputRole: TrustFormTypes,
  ): {
    type: string
    typeDescription: string
  } => {
    if (inputRole === TrustFormTypes.UnitHolder)
      return { type: 'OTH', typeDescription: t(inputRole) }
    if (inputRole === TrustFormTypes.GeneralBeneficiary)
      return { type: 'OTH', typeDescription: t(inputRole) }
    if (inputRole === TrustFormTypes.SpecifiedBeneficiary)
      return { type: 'OTH', typeDescription: t(inputRole) }
    if (inputRole === TrustFormTypes.Member)
      return { type: 'OTH', typeDescription: t(inputRole) }

    return {
      type: 'OTH',
      typeDescription: 'Other',
    }
  }

  const updateAssociate = (
    party: TrustCommonFormType,
    roles: { type: string; typeDescription: string }[],
  ) => {
    const associatedParty = associatedParties.find(p => p.name === party.name)
    if (associatedParty && associatedParty.entityId) {
      updateAssociatePartyMutate({
        // enabled hidePartyFromSearch will not create a new entity (this will be mapped to entity_by_ff in CORE)
        hidePartyFromSearch: true,
        entityId: associatedParty.entityId,
        mainData: {
          addedBy: pageData?.user.username ?? ' ',
          percentage: {
            beneficially: 0,
            nonBeneficially: 0,
            jointly: null,
          },
          roles: [...associatedParty.roles, ...roles],
        },
      })
    }
  }

  // eslint-disable-next-line complexity
  const addAssociateParty = (
    inputRole: TrustFormTypes,
    party: TrustCommonFormType,
  ) => {
    const isIndividual = party.type === ApplicantTrustTypes.Individual
    if (!roleTypes) return

    const roles = roleTypes.filter(
      role =>
        role.typeDescription.toLocaleLowerCase() ===
        inputRole.toLocaleLowerCase(),
    )

    // if there is no role match against the role type, set role as other
    if (!roles.length) {
      roles.push(otherAssociateRole(inputRole))
    }
    // check if the party is already associated
    const isAssociated = checkAssociation({
      party,
      isIndividual,
      associatedParties,
    })

    const { name, address, registrationType } = getLinkedEntityData(
      party.entityId,
      data.documentInformation?.trust,
    )
    const {
      displayName = '',
      familyName = '',
      givenName = '',
      middleName = '',
    } = name || {}
    const {
      country = '',
      buildingName = '',
      unitNumber = '',
      streetNumber = '',
      streetName: sn = '',
      neighborhood = '',
      locality = '',
      subdivision = '',
      postalCode = '',
    } = address as IDocumentInformationAddresses

    const { streetName, streetType = '' } = parseStreetName(sn)
    const suburb = neighborhood || locality
    const town = suburb // this needs to be checked
    const state = subdivision

    const commonAddress = {
      country,
      buildingName,
      unitNumber,
      streetNumber,
      streetName,
      streetType,
      suburb,
      town,
      state,
      postalCode,
    }

    if (!isAssociated) {
      associateNewPartyMutate({
        // enabled hidePartyFromSearch will not create a new entity (this will be mapped to entity_by_ff in CORE)
        hidePartyFromSearch: true,
        mainData: {
          addedBy: pageData?.user.username ?? ' ',
          percentage: {
            beneficially: 0,
            nonBeneficially: 0,
            jointly: null,
          },
          roles,
        },
        partyData: isIndividual
          ? {
              partyType: 'INDIVIDUAL',
              party: {
                ...applicantDetailConfig,
                name: {
                  displayName,
                  familyName,
                  givenName,
                  middleName,
                },
                addresses: [
                  {
                    ...commonAddress,
                    addressType: EnumAddressType.RESIDENTIAL1,
                  },
                ],
                dateOfBirth: party.dateOfBirth || '',
                extraData: {
                  trust_analysis_id: data.analysisId,
                },
              },
            }
          : {
              partyType: 'BUSINESS',
              party: {
                ...businessInfoConfig,
                addresses: [
                  {
                    ...commonAddress,
                    addressType: EnumAddressType.REGISTERED_OFFICE,
                  },
                ],
                ...(registrationType === 'ABN'
                  ? { ABNNumber: party.abnOrAcn ?? '' }
                  : { ACNNumber: party.abnOrAcn ?? '' }),
                businessName: party.name ?? '',
                businessProfile: 'organisation',
                extraData: {
                  trust_analysis_id: data.analysisId,
                },
              },
            },
      })
    } else {
      updateAssociate(party, roles)
    }
  }

  const {
    name,
    establishment = {},
    settlors = [],
    trustees = [],
    typeInformation,
    type,
  } = data.documentInformation?.trust || {}

  const { discretionary, selfManagedSuperFund, unit } = typeInformation || {}

  const {
    appointors = [],
    generalBeneficiaries = [],
    specifiedBeneficiaries = [],
    protectors = [],
  } = discretionary || {}
  const { members = [] } = selfManagedSuperFund || {}
  const { unitHolders = [] } = unit || {}

  const getTrustFormData = useMemo(
    () => ({
      [TrustFormTypes.Settlor]: settlors,
      [TrustFormTypes.Trustee]: trustees,
      [TrustFormTypes.Appointor]: appointors,
      [TrustFormTypes.Protector]: protectors,
      [TrustFormTypes.GeneralBeneficiary]: generalBeneficiaries,
      [TrustFormTypes.SpecifiedBeneficiary]: specifiedBeneficiaries,
      [TrustFormTypes.UnitHolder]: unitHolders,
      [TrustFormTypes.Member]: members,
    }),
    [
      appointors,
      generalBeneficiaries,
      members,
      protectors,
      settlors,
      specifiedBeneficiaries,
      trustees,
      unitHolders,
    ],
  )

  const establishmentDate =
    establishment.date?.normalized || establishment.date?.unstructured

  return (
    <div className="px-8 overflow-x-auto">
      <div className="text-md font-semibold my-4">{t('trustDetails')}</div>
      <div className="grid grid-cols-3 gap-4">
        <div>
          <div className="font-semibold">{t('trustType')}</div>
          <div data-qa={trustDeedsDocQa.trustTypeSelector}>
            {type?.provided || type?.detected || '-'}
          </div>
        </div>
        <div className="col-span-2">
          <div className="font-semibold">
            {t('nameOfTrust')}
            <TrustAnalyserReference
              text={t('trustDetailReference.name')}
              data={data}
              type={TrustReferenceTypes.NAME_OF_TRUST}
              getPageNumber={page => scrollToPage(page)}
              testId={{ reference: trustAnalyserReferenceDetailQa.name }}
            />
          </div>

          <div data-qa={trustDeedsDocQa.nameOfTrustField} data-hj-suppress>
            {name?.value || '-'}
          </div>
        </div>
        <div>
          <div className="font-semibold">
            {t('dateOfEstablishment')}
            <TrustAnalyserReference
              text={t('trustDetailReference.date')}
              data={data}
              type={TrustReferenceTypes.DATE_OF_ESTABLISHMENT}
              getPageNumber={page => scrollToPage(page)}
              testId={{ reference: trustAnalyserReferenceDetailQa.date }}
            />
          </div>
          <div
            data-qa={trustDeedsDocQa.dateOfEstablishmentField}
            data-hj-suppress
          >
            {establishmentDate
              ? formatDate(establishmentDate, DateFormatTypes.DateNumbersSlash)
              : '-'}
          </div>
        </div>
        <div className="col-span-2">
          <div className="font-semibold">
            {t('countryOfEstablishment')}
            <TrustAnalyserReference
              text={t('trustDetailReference.country')}
              data={data}
              type={TrustReferenceTypes.COUNTRY_OF_ESTABLISHMENT}
              getPageNumber={page => scrollToPage(page)}
              testId={{ reference: trustAnalyserReferenceDetailQa.country }}
            />
          </div>
          <div
            data-qa={trustDeedsDocQa.countryStateOfEstablishmentField}
            data-hj-suppress
          >
            {establishment.country?.value || '-'}
          </div>
        </div>
      </div>

      <FrankieDivider className="my-4" />

      {Object.values(TrustFormTypes).map((key, formIndex) => {
        const showTitle =
          key === TrustFormTypes.SpecifiedBeneficiary
            ? !generalBeneficiaries.length
            : true
        if (getTrustFormData[key].length > 0) {
          return (
            <CommonViewForm
              key={key}
              trustFormKey={key}
              trustFormData={getTrustFormData[key]}
              addAssociateParty={addAssociateParty}
              associatedParties={associatedParties}
              scrollToPage={scrollToPage}
              data={data}
              showTitle={showTitle}
              formIndex={formIndex}
            />
          )
        }
        return null
      })}
    </div>
  )
}
