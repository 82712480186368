import React, { useMemo } from 'react'

import {
  IFrankiePieChartProps,
  FrankiePieChartStatsColorTypes,
  FrankiePieChartColorConfig,
} from './pie-chart.types'

/**
 * Pie chart component.
 *
 * @component
 */
export function FrankiePieChart({
  className,
  data,
  innerRadius = 70,
  size = 180,
  padding = 0.6,
  hideAnalytics = false,
  testId,
}: IFrankiePieChartProps): JSX.Element {
  /**
   * Color Config
   */
  const colorConfig: FrankiePieChartColorConfig = useMemo(
    () => ({
      [FrankiePieChartStatsColorTypes.Green500]: {
        fillClass: 'fill-tertiary-green-500',
        bgClass: 'bg-tertiary-green-500',
      },
      [FrankiePieChartStatsColorTypes.Green300]: {
        fillClass: 'fill-tertiary-green-300',
        bgClass: 'bg-tertiary-green-300',
      },
      [FrankiePieChartStatsColorTypes.Yellow500]: {
        fillClass: 'fill-tertiary-yellow-500',
        bgClass: 'bg-tertiary-yellow-500',
      },
      [FrankiePieChartStatsColorTypes.Yellow300]: {
        fillClass: 'fill-tertiary-yellow-300',
        bgClass: 'bg-tertiary-yellow-300',
      },
      [FrankiePieChartStatsColorTypes.Red500]: {
        fillClass: 'fill-tertiary-red-500',
        bgClass: 'bg-tertiary-red-500',
      },
      [FrankiePieChartStatsColorTypes.Red300]: {
        fillClass: 'fill-tertiary-red-300',
        bgClass: 'bg-tertiary-red-300',
      },
      [FrankiePieChartStatsColorTypes.Navy700]: {
        fillClass: 'fill-tertiary-blue-dark-700',
        bgClass: 'bg-tertiary-blue-dark-700',
      },
      [FrankiePieChartStatsColorTypes.Grey300]: {
        fillClass: 'fill-tertiary-grey-300',
        bgClass: 'bg-tertiary-grey-300',
      },
    }),
    [],
  )

  /**
   * Calculate the total value of all slices in the pie chart data.
   */
  const total = useMemo(
    () => data.reduce((acc, slice) => acc + slice.value, 0),
    [data],
  )

  /**
   * Initialize the starting angle for drawing each slice.
   */
  let startAngle = 0

  return (
    <div className="flex gap-8">
      {!hideAnalytics && (
        <div data-qa={testId?.dataContainer}>
          {data.map(item => (
            <div
              key={`${item.label}-${item.value}-${item.color}`}
              data-qa={item.testId?.input}
              className="flex items-center gap-1 text-lg min-w-[240px]"
            >
              <div
                className={`flex flex-grow-0 gap-4 w-[10px] h-[10px] me-1 ${
                  item.value ? colorConfig[item.color].bgClass : 'bg-neutral-40'
                }`}
              />
              <div className="flex-grow">{item.label}</div>
              <div>{item.value}</div>
            </div>
          ))}
        </div>
      )}

      <div className="mx-auto">
        <svg width={size} height={size} className={className}>
          <g
            transform={`translate(${size / 2}, ${size / 2})`}
            data-qa={testId?.graphContainer}
          >
            {total > 0 ? (
              data
                .filter(item => !!item.value) // Filtering out the zero values
                .map((slice, index) => {
                  /**
                   * Calculate the angle for the current slice based on its value and the total value.
                   */
                  const sliceAngle = (slice.value / total) * 360

                  /**
                   * Calculate the end angle of the current slice.
                   */
                  const endAngle = startAngle + sliceAngle

                  /**
                   * Calculate the outer and inner radius of the pie chart.
                   */
                  const outerRadius = Math.min(size) / 2
                  const innerRadiusValue = innerRadius || 0

                  /**
                   * Add padding after every slice.
                   */
                  const paddedStartAngle = startAngle + padding
                  const paddedEndAngle = endAngle - padding

                  /**
                   * Calculate the coordinates for the outer arc.
                   */
                  const outerStartX =
                    outerRadius * Math.cos((paddedStartAngle * Math.PI) / 180)
                  const outerStartY =
                    outerRadius * Math.sin((paddedStartAngle * Math.PI) / 180)
                  const outerEndX =
                    outerRadius * Math.cos((paddedEndAngle * Math.PI) / 180)
                  const outerEndY =
                    outerRadius * Math.sin((paddedEndAngle * Math.PI) / 180)

                  /**
                   * Calculate the coordinates for the inner arc (if innerRadius is provided).
                   */
                  const innerStartX =
                    innerRadiusValue *
                    Math.cos((paddedEndAngle * Math.PI) / 180)
                  const innerStartY =
                    innerRadiusValue *
                    Math.sin((paddedEndAngle * Math.PI) / 180)
                  const innerEndX =
                    innerRadiusValue *
                    Math.cos((paddedStartAngle * Math.PI) / 180)
                  const innerEndY =
                    innerRadiusValue *
                    Math.sin((paddedStartAngle * Math.PI) / 180)

                  /**
                   * Determine the flag for using the larger arc (1) or the smaller arc (0).
                   */
                  const largeArcFlag = sliceAngle > 180 ? 1 : 0

                  /**
                   * Update the starting angle for the next slice.
                   */
                  startAngle += sliceAngle

                  /**
                   * Render the path element for the current slice
                   */
                  return (
                    <path
                      data-qa={slice.testId?.pie}
                      key={slice.label}
                      d={`M ${outerStartX} ${outerStartY} A ${outerRadius} ${outerRadius} 0 ${largeArcFlag} 1 ${outerEndX} ${outerEndY} L ${innerStartX} ${innerStartY} A ${innerRadiusValue} ${innerRadiusValue} 0 ${largeArcFlag} 0 ${innerEndX} ${innerEndY} Z`}
                      className={colorConfig[slice.color].fillClass}
                    />
                  )
                })
            ) : (
              <>
                <circle
                  key="outerCircle"
                  r={size / 2}
                  className="fill-neutral-40"
                />
                <circle
                  key="innerCircle"
                  r={innerRadius}
                  className="fill-mono-white"
                />
              </>
            )}
          </g>
        </svg>
      </div>

      {!hideAnalytics && (
        <div>
          {data.map(item => {
            if (!total) {
              return (
                <div className="font-bold text-lg" key={item.label}>
                  {0.0}
                </div>
              )
            }
            return (
              <div key={item.label} className="font-bold text-lg">
                {`${(+(item.value / total).toFixed(4) * 100).toFixed(1)}%`}
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}
