import {
  MatchsetCountTypes,
  RulesetMatchFields,
} from 'entities/applicant/model/applicant-response.model'

import { Nullable } from 'shared/typescript'

export enum RulesetMatchResultTypes {
  APPROVE = 'APPROVE',
  DECLINE = 'DECLINED',
  PENDING = 'PENDING',
  UNCHECKED = 'UNCHECKED',
  PASS = 'PASS',
}

export type RulesetsRowData = {
  ruleset: string
  name: string
  dob: string
  address: string
  govId: string
  result: Nullable<string>
  rulesetMatchField: RulesetMatchFields
}

export const getRowValue = (value: MatchsetCountTypes) => {
  if (value.actual === null && value.required === null) return 'N/A'
  if (value.actual === null || value.actual === 0) return 0
  return value.actual
}

export const checkIsVerified = (
  matchTypeName: MatchsetCountTypes,
  result: Nullable<string>,
) => {
  if (!result) return false

  if (matchTypeName.actual === null && result === 'UNCHECKED') return null

  if (
    matchTypeName.actual !== null &&
    matchTypeName.required !== null &&
    matchTypeName.actual >= matchTypeName.required
  ) {
    return true
  }
  return false
}

export const verifiedBgColor = (
  matchTypeName: MatchsetCountTypes,
  result: Nullable<string>,
  value: string,
) => {
  const isVerified = checkIsVerified(matchTypeName, result)
  if (matchTypeName.required === null) return 'bg-transparent text-mono-black'

  if (isVerified === null || Number.isNaN(Number(value)))
    return 'bg-transparent text-mono-black'
  if (isVerified) return 'bg-tertiary-green-400 text-mono-white'

  return 'bg-tertiary-red-500 text-mono-white'
}

export const isNotRequired = (matchTypeName: MatchsetCountTypes) => {
  if (matchTypeName.required === null) return 'bg-transparent text-mono-black'
  return ''
}

export const getVerifyColorOutcome = (isVerified: Nullable<boolean>) => {
  if (isVerified === null) return 'bg-transparent text-mono-black'
  if (isVerified) return 'text-tertiary-green-400'

  return 'text-tertiary-red-500'
}

export const getStatus = (result: Nullable<string>) => {
  switch (result) {
    case RulesetMatchResultTypes.UNCHECKED: {
      return {
        status: null,
        text: 'Unchecked',
      }
    }
    case RulesetMatchResultTypes.PASS: {
      return {
        status: true,
        text: 'Pass',
      }
    }
    default: {
      return {
        status: false,
        text: 'Fail',
      }
    }
  }
}

type RowRequiredData = { count: number; name: string }

export type GetRowRequiredDataDetails = {
  name: RowRequiredData
  dob: RowRequiredData
  address: RowRequiredData
  govId: RowRequiredData
}

export const getRowRequiredDataDetails = (
  rulesetMatchFields: RulesetMatchFields,
): GetRowRequiredDataDetails | Record<string, never> => {
  const fields = Object.keys(rulesetMatchFields) as (keyof RulesetMatchFields)[]
  return fields.reduce((acc, field) => {
    if (rulesetMatchFields[field].required)
      return {
        ...acc,
        [field]: { count: rulesetMatchFields[field].required, name: field },
      }
    return { ...acc }
  }, {})
}
