import { Loader } from '@googlemaps/js-api-loader'

import { googleMapApiKey } from 'shared/environment'

class GoogleMapService {
  private loader: Loader

  private Map: typeof google.maps.Map | null = null

  constructor() {
    this.loader = new Loader({
      apiKey: googleMapApiKey,
      version: 'weekly',
    })
  }

  async renderMap(mapDiv: HTMLElement, opts?: google.maps.MapOptions) {
    if (!this.Map) {
      const { Map } = await this.loader.importLibrary('maps')
      this.Map = Map
    }

    return new this.Map(mapDiv, opts)
  }

  setMarker(map: google.maps.Map, lat: number, lng: number) {
    // eslint-disable-next-line no-new
    new google.maps.Marker({
      position: { lat, lng },
      map,
    })
  }
}

export const googleMapService = new GoogleMapService()
