import React, { ComponentProps } from 'react'

import { GenerateIndividualVerificationReport } from 'entities/applicant'

import { AddApplicantToBlockList } from './add-applicant-to-blocklist/add-applicant-to-blocklist'
import { AddApplicantToWatchList } from './add-applicant-to-watchlist/add-applicant-to-watchlist'
import { ArchiveApplicant } from './archive-applicant/archive-applicant'
import { ChangeApplicantStatus } from './change-applicant-status/change-applicant-status'
import { DeactivateApplicant } from './deactivate-applicant/deactivate-applicant'
import { RemoveApplicantFromBlocklist } from './remove-applicant-from-blocklist/remove-applicant-from-blocklist'
import { RemoveApplicantFromWatchList } from './remove-applicant-from-watctlist/remove-applicant-from-watchlist'
import { UnarchiveApplicant } from './unarchive-applicant/unarchive-applicant'

const ApplicantActionDefaultModal = ChangeApplicantStatus
type ApplicantActionModalType = typeof ApplicantActionDefaultModal

export const ApplicantActionModalMapping = {
  AddApplicantToBlockList,
  AddApplicantToWatchList,
  ChangeApplicantStatus,
  ArchiveApplicant,
  GenerateIndividualVerificationReport,
  RemoveApplicantFromBlocklist,
  RemoveApplicantFromWatchList,
  DeactivateApplicant,
  UnarchiveApplicant,
} satisfies Record<string, ApplicantActionModalType>

type ApplicantActionModalMappingType = typeof ApplicantActionModalMapping

export type ApplicantActionModalTypes = keyof ApplicantActionModalMappingType

export type OwnProps<TType extends ApplicantActionModalTypes> = { type?: TType }

export function ApplicantActionModal<TType extends ApplicantActionModalTypes>({
  type,
  ...rest
}: OwnProps<TType> & ComponentProps<ApplicantActionModalMappingType[TType]>) {
  const DocType = type
    ? ApplicantActionModalMapping[type]
    : ApplicantActionDefaultModal

  return <DocType {...rest} />
}
