import React, { useEffect, useState } from 'react'

import { useForm } from 'react-hook-form'

import { FrankieButton, FrankieLoader, FrankiePopover } from 'frankify/src'

import { ApplicantId } from 'entities/applicant'

import { SelectFormField, TextAreaFormField } from 'shared/form'
import { useI18n } from 'shared/i18n'
import { notification } from 'shared/notification'

import { APPLICANT_RISK_SCORE_KEY } from '../../applicant-risk-score.key'
import { applicantRiskScoreEn } from '../../locale/applicant-risk-score.en'
import {
  RiskLevelData,
  onboardingStatusOption,
} from '../../model/applicant-risk-score.model'
import { applicantManualOverrideQa } from '../../qa/applicant-risk-score.qa'
import { useApplicantRiskScoreLevel } from '../../state/applicant-risk-score-level/applicant-risk-score-level.mutation'

type Props = {
  applicantId: ApplicantId
  className?: string
}

export function ApplicantManualOverride({ applicantId, className }: Props) {
  const t = useI18n([APPLICANT_RISK_SCORE_KEY], {
    keys: applicantRiskScoreEn,
  })

  const [open, setOpen] = useState(false)

  const {
    control,
    formState: { isValid },
    handleSubmit,
    getValues,
    reset,
  } = useForm<RiskLevelData>()

  const { mutate, isLoading, isSuccess } = useApplicantRiskScoreLevel({
    applicantId,
  })

  useEffect(() => {
    if (isSuccess) {
      notification.success(
        t('changeStatusSuccess', {
          level: getValues('level').toLowerCase(),
        }),
      )
      setOpen(false)
    }
  }, [getValues, isSuccess, t])

  // resetting on close
  useEffect(() => {
    if (!open && !isLoading) {
      reset()
    }
  }, [open, isLoading, reset])

  return (
    <div className={className} data-qa={applicantManualOverrideQa.component}>
      <FrankiePopover
        onOpenChange={setOpen}
        open={open}
        popoverRest={{ placement: 'top-start' }}
        showOverlay
        trigger={
          <FrankieButton
            className="!bg-mono-100 !outline-mono-50"
            size="sm"
            onClick={() => setOpen(prev => !prev)}
            testId={{ button: applicantManualOverrideQa.btn }}
          >
            {t('manualOverride')}
          </FrankieButton>
        }
      >
        <FrankieLoader label={t('updating')} loading={isLoading}>
          <form
            onSubmit={handleSubmit(data => mutate({ data }))}
            className="flex flex-col gap-4 p-4 bg-mono-white shadow-lg rounded-sm"
          >
            <div className="flex items-center gap-2">
              <span className="whitespace-nowrap text-tertiary-grey-800 font-medium">
                {t('changeMatch')}
              </span>
              <SelectFormField
                options={onboardingStatusOption.map(({ tKey, value }) => ({
                  label: t(tKey),
                  value,
                }))}
                className="flex"
                control={control}
                name="level"
                rules={{ required: true }}
              />
            </div>

            <TextAreaFormField
              label={`${t('comment')}*`}
              name="comment"
              placeholder={t('typeYourComment')}
              control={control}
              rules={{ required: true }}
            />
            <div className="flex gap-3 justify-end">
              <FrankieButton
                noStyles
                className="text-tertiary-grey-900 font-medium"
                size="sm"
                onClick={() => setOpen(false)}
              >
                {t('cancel')}
              </FrankieButton>
              <FrankieButton
                disabled={!isValid}
                className={isValid ? '!bg-mono-100 !outline-mono-50' : ''}
                size="sm"
                type="submit"
              >
                {t('changeStatus')}
              </FrankieButton>
            </div>
          </form>
        </FrankieLoader>
      </FrankiePopover>
    </div>
  )
}
