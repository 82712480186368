import React, { useEffect, useState } from 'react'

import { FrankieButton, FrankieIcon, FrankieLoader } from 'frankify/src'

import { ORGANISATION_SEARCH_KEY } from 'features/organisation-search'

import { IMatchedOrganization } from 'entities/organisation'

import { useI18n } from 'shared/i18n'
import { Nullable } from 'shared/typescript'

import { OrganisationInfoRest } from '../organisation-info-rest/organisation-info-rest'
import { OrganisationSearchResultTable } from '../organisation-result/organisation-result'
import { RowSearchResultError } from '../row-result-error/row-result-error'
import { RowSearchResultGutter } from '../row-search-result-gutter/row-search-result-gutter'

type Props = {
  foundOrganisations: IMatchedOrganization[]
  showSearchResults: boolean
  isLoadingOrganizations: boolean
  showTooManySearchResultsError: boolean
  showNoMatchesError: boolean
  dataToDisplay: {
    organizationName: string
    countryName: string
  }
}

export function KybSearchResult({
  foundOrganisations,
  showSearchResults,
  showTooManySearchResultsError,
  showNoMatchesError,
  isLoadingOrganizations,
  dataToDisplay,
}: Props) {
  const t = useI18n([ORGANISATION_SEARCH_KEY])

  const [selectedOrganisation, setSelectedOrganisation] =
    useState<Nullable<IMatchedOrganization>>(null)

  const handleSelectOrganisation = (org: IMatchedOrganization) => {
    setSelectedOrganisation(org)
  }

  const handleClickBackFromSummary = () => setSelectedOrganisation(null)

  useEffect(() => {
    if (isLoadingOrganizations) {
      setSelectedOrganisation(null)
    }
  }, [isLoadingOrganizations])

  if (selectedOrganisation) {
    return (
      <>
        <FrankieButton
          noStyles
          className="text-sm font-normal text-tertiary-grey-500"
          onClick={handleClickBackFromSummary}
        >
          <div className="flex items-center">
            <FrankieIcon
              size="xs"
              name="mdiChevronLeft"
              className="inline-block"
            />
            <span>{t('backToResults')}</span>
          </div>
        </FrankieButton>
        <OrganisationInfoRest organisationInfo={selectedOrganisation} />
      </>
    )
  }

  if (isLoadingOrganizations) {
    return (
      <div id="loading-content" className="flex align-center flex-col">
        <div id="loader">
          <FrankieLoader
            loading={isLoadingOrganizations}
            className="min-h-[76px]"
            size="md"
          />
        </div>
        <div className="flex flex-col items-center mt-3">
          <span className="inline-block font-normal text-md text-tertiary-grey-800">
            {t('loadingHeading', {
              organizationName: dataToDisplay.organizationName,
              countryName: dataToDisplay.countryName,
            })}
          </span>
          <span className="inline-block font-normal text-md text-tertiary-grey-800 mt-1">
            {t('loadingSubheading')}
          </span>
        </div>
      </div>
    )
  }

  if (showTooManySearchResultsError) {
    return (
      <div>
        <FrankieIcon
          size="xl"
          name="mdiMagnify"
          className="text-primary-300 flex justify-center"
        />
        <div className="flex flex-col items-center">
          <span className="text-lg font-bold inline-block">
            {t('tooManySearches.title')}
          </span>
          <span className="text-sm font-normal max-w-[430px] text-center">
            {t('tooManySearches.subtitle', {
              search: dataToDisplay.organizationName,
            })}
          </span>
          <div className="mt-3">
            <RowSearchResultError />
          </div>
        </div>
      </div>
    )
  }

  if (showNoMatchesError) {
    return (
      <div id="error-container">
        <FrankieIcon
          size="xl"
          name="mdiMagnify"
          className="text-primary-300 flex justify-center"
        />
        <div className="flex flex-col items-center">
          <span className="text-lg font-bold inline-block">
            {t('noMatchesHeading')}
          </span>
          <span className="text-sm font-normal">
            {t('searchResultHeading', {
              search: dataToDisplay.organizationName,
            })}
          </span>
          <span className="text-sm font-normal">{t('checkTypos')}</span>
          <div className="mt-3">
            <RowSearchResultError />
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      {showSearchResults && (
        <OrganisationSearchResultTable
          organizationName={dataToDisplay.organizationName}
          countryName={dataToDisplay.countryName}
          foundOrganizations={foundOrganisations}
          onSelectOrganisation={handleSelectOrganisation}
        />
      )}
      {showSearchResults && <RowSearchResultGutter />}
    </>
  )
}
