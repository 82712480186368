import React, { ChangeEvent } from 'react'

import classNames from 'classnames'

import { FrankieButton, FrankieCheckbox, IFrankieIconProps } from 'frankify/src'

type Props = {
  children: string
  className?: string
  checked: boolean
  size?: 'xs' | 'md'
  testId?: {
    input?: string
  }
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  startIcon?: IFrankieIconProps
}

export function CheckboxAsButton({
  children,
  checked,
  onChange,
  className = '',
  size = 'xs',
  startIcon,
  testId = { input: '' },
}: Props) {
  return (
    <label className={className}>
      <FrankieButton
        size={size}
        startIcon={startIcon}
        className={classNames('cursor-pointer min-w-[56px]', {
          'bg-primary-50 hover:!bg-primary-50 border-transparent': checked,
        })}
        intent="darkOutline"
        notAButton
      >
        {children}
      </FrankieButton>
      <FrankieCheckbox
        testId={{ input: testId.input }}
        onChange={onChange}
        checked={checked}
        className="hidden"
      />
    </label>
  )
}
