import { useQuery } from '@tanstack/react-query'

import { workflowApi } from '../api/workflow.api'
import { IWorkflow } from '../model/workflow.model'

const WORKFLOW_QUERY_KEY = ['workflow']

export const useWorkflowListQuery = (serviceName?: string) =>
  useQuery<IWorkflow[]>({
    queryKey: WORKFLOW_QUERY_KEY,
    queryFn: async () => {
      const { data } = await workflowApi.getWorkflows(serviceName)
      return data.workflows
    },
  })
