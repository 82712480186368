import { useCallback } from 'react'

import { useQuery } from '@tanstack/react-query'

import { ApplicantId, mappedBackendState } from 'entities/applicant'
import { OTHER_ROLE_KEY } from 'entities/organisation'

import { ErrorResponse } from 'shared/http'
import { useI18n } from 'shared/i18n'
import { generateRandomKey } from 'shared/string'
import { Nullable } from 'shared/typescript'

import { applicantBusinessOwnershipApi } from '../../api/applicant-business-ownership.api'
import { APPLICANT_BUSINESS_OWNERSHIP_KEY } from '../../applicant-business-ownership.key'
import { applicantBusinessOwnershipEn } from '../../locale/applicant-business-ownership.en'
import {
  ApplicantOwnershipHolderRecord,
  ApplicantOwnershipHolderTypes,
  ApplicantOwnershipResponse,
  OfficeHolder,
  applicantOwnershipHolderRecordConfig,
} from '../../model/applicant-business-ownership.model'

type Args = {
  applicantId?: ApplicantId
}

export const ApplicantBusinessOwnershipQueryKey =
  'applicant-business-ownership-query'

export const useApplicantBusinessOwnershipQuery = ({ applicantId }: Args) =>
  useQuery<ApplicantOwnershipResponse, ErrorResponse>({
    enabled: !!applicantId,
    queryKey: [ApplicantBusinessOwnershipQueryKey, applicantId],
    queryFn: async () => {
      const { data } =
        await applicantBusinessOwnershipApi.getBusinessOwnershipDetail(
          applicantId!,
        )
      return data
    },
  })

export const useApplicantBusinessOwnershipState = ({
  applicantId,
}: Required<Args>) => {
  const t = useI18n([APPLICANT_BUSINESS_OWNERSHIP_KEY], {
    keys: applicantBusinessOwnershipEn,
  })

  const { data, isFetching } = useApplicantBusinessOwnershipQuery({
    applicantId,
  })

  const getPercentage = useCallback(
    (value?: Nullable<number>, getZeroAsPercentage = false) =>
      value || value === 0
        ? `${Math.round(value * 100 + Number.EPSILON) / 100}%`
        : `${getZeroAsPercentage ? '0%' : ''}`,
    [],
  )

  const getTotalSubtitle = useCallback(
    (type: ApplicantOwnershipHolderTypes) => {
      if (type === 'shareCapitalGroup') {
        return {
          label: t('dataGridFields.totalShares'),
          value: data?.shareCapitalGroup?.totalNumberOfShares,
        }
      }

      return null
    },
    [data, t],
  )

  const getOfficeholdersData = useCallback(
    // eslint-disable-next-line complexity
    (
      officeholder: OfficeHolder,
      type: ApplicantOwnershipHolderTypes,
      idx: number,
    ) => {
      const ownership = officeholder.ownership[applicantId]
      const { percentageHolding } = officeholder
      const individualKyc = officeholder.kyc
      const isOrganisation = officeholder.entityType === 'ORGANISATION'

      return {
        ...applicantOwnershipHolderRecordConfig,
        id: generateRandomKey(officeholder.entityId, idx),
        entityId: officeholder.entityId,
        type: isOrganisation ? 'organisation' : 'individual', // if type is undefined, it is considered as individual
        name: officeholder.name,
        originalName: officeholder.originalDetails?.name ?? '',
        customerReference: officeholder.customerReference ?? '',

        role: officeholder.type ?? '',
        currentPrevious: officeholder.historicalStatus ?? '-',

        beneficiallyHeldOwnership: getPercentage(
          ownership?.beneficiallyHeld ?? percentageHolding?.beneficially,
          type === 'associatedParties',
        ),
        nonBeneficiallyHeldOwnership: getPercentage(
          ownership?.nonBeneficiallyHeld ?? percentageHolding?.nonBeneficially,
          type === 'associatedParties',
        ),
        total: getPercentage(
          ownership?.total ?? percentageHolding?.total,
          type === 'associatedParties',
        ),

        profileStatus:
          mappedBackendState(individualKyc.status.code ?? 'unchecked').type ??
          '',

        issues: individualKyc.issuesList.map(issue =>
          typeof issue === 'string' ? issue : issue.term,
        ),

        recipe: officeholder.profileType ?? '',
        riskLevel: individualKyc.riskLevel.label,

        actions: [
          ...(!isOrganisation ? (['sendKycLink'] as const) : []),
          ...(type === 'associatedParties'
            ? (['editParty', 'removeParty'] as const)
            : []),
        ],

        entityType:
          officeholder.asicExtract?.companyType ||
          officeholder.abrExtract?.entityType ||
          '',

        roleType:
          officeholder.roles?.map(role => role.typeDescription).join(', ') ??
          '',

        roles: officeholder.roles?.map(role => role.type) ?? [],
        otherRoleTypeDescription: officeholder.roles?.find(
          role => role.type === OTHER_ROLE_KEY,
        )?.typeDescription,
      } satisfies ApplicantOwnershipHolderRecord
    },
    [applicantId, getPercentage],
  )

  const getOwnershipData = useCallback(
    (
      type: ApplicantOwnershipHolderTypes,
      removeTableData?: ApplicantOwnershipHolderTypes[],
    ): ApplicantOwnershipHolderRecord[] => {
      // remove table data if it is in the list
      if (removeTableData?.includes(type)) return []

      // Share Capital Group
      if (type === 'shareCapitalGroup') {
        const shareCapitalGroup = data?.shareCapitalGroup

        return (
          shareCapitalGroup?.shareCapital?.map((item, idx) => ({
            ...applicantOwnershipHolderRecordConfig,
            id: generateRandomKey(item.classTitle, idx),
            classTitle: item.classTitle ?? '',
            classCode: item.classCode ?? ' ',
            sharesIssued: `${item.numberOfShares ?? ''}`,
            totalAmountPaid: `${item.totalAmountPaid ?? ''}`,
            totalAmountDueAndPayable: `${item.totalAmountDueAndPayable ?? ''}`,
          })) ?? []
        )
      }

      if (type === 'blockingEntities') {
        const blockingEntities = data?.blockingEntities

        return (
          blockingEntities?.map((item, idx) => {
            const { beneficially, nonBeneficially, total } =
              item.percentageOwned || {}
            const beneficiallyHeldOwnership = getPercentage(beneficially)
            const nonBeneficiallyHeldOwnership = getPercentage(nonBeneficially)
            const totalOwnership = getPercentage(total)
            return {
              ...applicantOwnershipHolderRecordConfig,
              id: generateRandomKey(item.name, idx),
              name: item.name,
              reasons: item.reasons?.join(', ') ?? '',
              beneficiallyHeldOwnership,
              nonBeneficiallyHeldOwnership,
              total: totalOwnership,
            }
          }) ?? []
        )
      }

      const officeholders = data?.[type]

      // Shareholders
      if (type === 'shareHolders') {
        return (officeholders ?? []).reduce((acc, officeholder, idx) => {
          const holdings =
            officeholder.ownership[applicantId]?.shareholdings ?? []

          const defaultOfficeHolderDetail = getOfficeholdersData(
            officeholder,
            type,
            idx,
          )

          holdings.forEach(holding => {
            acc.push({
              ...defaultOfficeHolderDetail,
              classCode: holding.shareClass ?? '-',
              sharesHeld: `${holding.sharesHeld ?? 0}`,
              beneficiallyHeld: holding.isBeneficiallyHeld ? 'Yes' : 'No',
              jointHolding: holding.jointHolding ? 'Yes' : 'No',
            })
          })

          return acc
        }, [] as ApplicantOwnershipHolderRecord[])
      }

      if (type === 'beneficialOwners') {
        const otherOwners = data?.otherOwners

        return (
          otherOwners?.map((item, idx) => {
            const { beneficially, nonBeneficially, total } =
              item.percentageHeld || {}
            const beneficiallyHeldOwnership = getPercentage(beneficially)
            const nonBeneficiallyHeldOwnership = getPercentage(nonBeneficially)
            const totalOwnership = getPercentage(total)
            return {
              ...applicantOwnershipHolderRecordConfig,
              id: generateRandomKey(item.name, idx),
              entityId: item.entityId,
              entityType: item.entityType ?? '',
              name: item.name,
              beneficiallyHeldOwnership,
              nonBeneficiallyHeldOwnership,
              total: totalOwnership,
            }
          }) ?? []
        )
      }

      // All the ownership types except shareHolders and shareCapitalGroup
      const result: ApplicantOwnershipHolderRecord[] =
        officeholders?.map((officeholder, idx) =>
          getOfficeholdersData(officeholder, type, idx),
        ) ?? []

      return result
    },
    [applicantId, data, getOfficeholdersData, getPercentage],
  )

  return { getOwnershipData, getTotalSubtitle, isLoading: isFetching }
}
