import React from 'react'

import { FrankieLoader } from 'frankify/src'

import { INDIVIDUAL_AUDIT_REPORT_F2_KEY } from 'features/individual-audit-report-f2/individual-audit-report-f2.key'
import { individualAuditReportF2En } from 'features/individual-audit-report-f2/locale/individual-audit-report-f2.en'
import { useAuditReportDownloadingState } from 'features/individual-audit-report-f2/state/audit-report-downloading-state.query'

import { ApplicantId } from 'entities/applicant'

import { useI18n } from 'shared/i18n'

import { individualAuditReportF2Qa } from '../../qa/individual-audit-report-f2.qa'
import { IndividualReportHeader } from '../individual-report-header/individual-report-header'
import { IndividualReportSection } from '../individual-report-section/individual-report-section'

type Props = {
  applicantId: ApplicantId
  className?: string
}

export function IndividualAuditReportF2({
  applicantId,
  className = '',
}: Readonly<Props>) {
  const { data: isLoading } = useAuditReportDownloadingState()
  const t = useI18n([INDIVIDUAL_AUDIT_REPORT_F2_KEY], {
    keys: individualAuditReportF2En,
  })
  return (
    <>
      {isLoading && (
        <FrankieLoader
          size="md"
          loading={isLoading}
          label={t('label.generatingReport')}
          className="left-0 top-0 z-20 !absolute text-lg font-semibold  !h-[calc(100vh-186px)] "
        />
      )}

      <div
        className={` mb-20 relative ${
          isLoading ? '!h-[calc(100vh-186px)] overflow-hidden' : ''
        } ${className}`}
        data-qa={individualAuditReportF2Qa.header}
      >
        <IndividualReportHeader entityId={applicantId} />
        <IndividualReportSection applicantId={applicantId} />
      </div>
    </>
  )
}
