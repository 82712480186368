import i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { I18nextProvider, initReactI18next } from 'react-i18next'

import { isDev } from 'shared/environment'

import { defaultNs, en } from './locale/en'

export const resources = {
  en,
}

export const i18n = i18next
  .use(LanguageDetector) // detect user language in browser
  .use(initReactI18next) // pass the i18n instance to react-i18next
  .createInstance(
    {
      detection: {
        order: ['navigator'],
      },
      resources,
      fallbackLng: 'en',
      ns: Object.keys(en),
      defaultNS: defaultNs,
      fallbackNS: defaultNs,
      debug: isDev,
      saveMissing: true,
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
    },
    // The callback will be called after all translations were loaded or with an error when failed
    error => {
      // eslint-disable-next-line no-console
      if (error) console.error(error)
    },
  )

export const I18nProvider = I18nextProvider
