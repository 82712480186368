import { ProcessResultManualStatusEnumAML } from 'entities/entity'

import { I18nFunction } from 'shared/i18n'

import { IndividualAmlResultPageEn } from '../locale/individual-aml-result-page.en'

type AmlStatusChangeFormProps = {
  amlStatus: ProcessResultManualStatusEnumAML
  t: I18nFunction<typeof IndividualAmlResultPageEn>
}

export const getApprovalStatusOptions = ({
  amlStatus,
  t,
}: AmlStatusChangeFormProps) => {
  switch (amlStatus) {
    case ProcessResultManualStatusEnumAML.TRUE_POSITIVE:
      return [
        {
          label: t('amlManualStatus.pass'),
          value: ProcessResultManualStatusEnumAML.TRUE_POSITIVE_ACCEPT,
        },
        {
          label: t('amlManualStatus.fail'),
          value: ProcessResultManualStatusEnumAML.TRUE_POSITIVE_REJECT,
        },
      ]

    case ProcessResultManualStatusEnumAML.UNKNOWN:
      return [
        {
          label: t('amlManualStatus.pass'),
          value: ProcessResultManualStatusEnumAML.UNKNOWN_ACCEPT,
        },
        {
          label: t('amlManualStatus.fail'),
          value: ProcessResultManualStatusEnumAML.UNKNOWN_REJECT,
        },
      ]
    default:
      return []
  }
}

export const getAmlStatusConfig = <
  IType extends I18nFunction<typeof IndividualAmlResultPageEn>,
>(
  t: IType,
  type?: ProcessResultManualStatusEnumAML,
) => {
  switch (type) {
    case ProcessResultManualStatusEnumAML.UNKNOWN:
      return {
        options: [
          {
            label: t('amlStatus.truePositive'),
            value: ProcessResultManualStatusEnumAML.TRUE_POSITIVE,
          },
          {
            label: t('amlStatus.falsePositive'),
            value: ProcessResultManualStatusEnumAML.FALSE_POSITIVE,
          },
        ],
        text: t('amlStatus.unknown'),
        styles:
          '!bg-tertiary-yellow-100 !text-tertiary-yellow-700 border-2 border-solid border-transparent',
      }
    case ProcessResultManualStatusEnumAML.UNKNOWN_ACCEPT:
      return {
        options: [
          {
            label: t('amlStatus.truePositive'),
            value: ProcessResultManualStatusEnumAML.TRUE_POSITIVE,
          },
          {
            label: t('amlStatus.falsePositive'),
            value: ProcessResultManualStatusEnumAML.FALSE_POSITIVE,
          },
        ],
        text: t('amlStatus.unknown'),
        styles:
          '!bg-tertiary-green-100 !text-tertiary-green-600 border-2 border-solid border-transparent',
        onFocus: '!border-2 !border-solid !border-tertiary-green-200',
      }
    case ProcessResultManualStatusEnumAML.UNKNOWN_REJECT:
      return {
        options: [
          {
            label: t('amlStatus.truePositive'),
            value: ProcessResultManualStatusEnumAML.TRUE_POSITIVE,
          },
          {
            label: t('amlStatus.falsePositive'),
            value: ProcessResultManualStatusEnumAML.FALSE_POSITIVE,
          },
        ],
        text: t('amlStatus.unknown'),
        styles:
          '!bg-tertiary-red-100 !text-tertiary-red-800 border-2 border-solid border-transparent',
        onFocus: '!border-2 !border-solid !border-tertiary-red-200',
      }
    case ProcessResultManualStatusEnumAML.TRUE_POSITIVE:
      return {
        options: [
          {
            label: t('amlStatus.falsePositive'),
            value: ProcessResultManualStatusEnumAML.FALSE_POSITIVE,
          },
          {
            label: t('amlStatus.unknown'),
            value: ProcessResultManualStatusEnumAML.UNKNOWN,
          },
        ],
        text: t('amlStatus.truePositive'),
        styles:
          '!bg-tertiary-yellow-100 !text-tertiary-yellow-700 border-2 border-solid border-transparent',
      }
    case ProcessResultManualStatusEnumAML.TRUE_POSITIVE_REJECT:
      return {
        options: [
          {
            label: t('amlStatus.falsePositive'),
            value: ProcessResultManualStatusEnumAML.FALSE_POSITIVE,
          },
          {
            label: t('amlStatus.unknown'),
            value: ProcessResultManualStatusEnumAML.UNKNOWN,
          },
        ],
        text: t('amlStatus.truePositive'),
        styles:
          '!bg-tertiary-red-100 !text-tertiary-red-800 border-2 border-solid border-transparent',
        onFocus: '!border-2 !border-solid !border-tertiary-red-200',
      }
    case ProcessResultManualStatusEnumAML.TRUE_POSITIVE_ACCEPT:
      return {
        options: [
          {
            label: t('amlStatus.falsePositive'),
            value: ProcessResultManualStatusEnumAML.FALSE_POSITIVE,
          },
          {
            label: t('amlStatus.unknown'),
            value: ProcessResultManualStatusEnumAML.UNKNOWN,
          },
        ],
        text: t('amlStatus.truePositive'),
        styles:
          '!bg-tertiary-green-100 !text-tertiary-green-600 border-2 border-solid border-transparent',
        onFocus: '!border-2 !border-solid !border-tertiary-green-200',
      }
    case ProcessResultManualStatusEnumAML.FALSE_POSITIVE:
      return {
        options: [
          {
            label: t('amlStatus.truePositive'),
            value: ProcessResultManualStatusEnumAML.TRUE_POSITIVE,
          },
          {
            label: t('amlStatus.unknown'),
            value: ProcessResultManualStatusEnumAML.UNKNOWN,
          },
        ],
        text: t('amlStatus.falsePositive'),
        styles:
          '!bg-tertiary-green-100 !text-tertiary-green-600 border-2 border-solid border-transparent',
        onFocus: '!border-2 !border-solid !border-tertiary-green-200',
      }

    default:
      return {
        options: [
          {
            label: t('amlStatus.falsePositive'),
            value: ProcessResultManualStatusEnumAML.FALSE_POSITIVE,
          },
          {
            label: t('amlStatus.truePositive'),
            value: ProcessResultManualStatusEnumAML.TRUE_POSITIVE,
          },
          {
            label: t('amlStatus.unknown'),
            value: ProcessResultManualStatusEnumAML.UNKNOWN,
          },
        ],
        styles:
          '!bg-tertiary-yellow-100 !text-tertiary-yellow-700 border-2 border-solid border-transparent',
        text: t('amlStatus.potentialMatch'),
      }
  }
}

export type SortModel = {
  field: 'matchStatus' | 'resolvedAt' | 'matchStrength'
  sort: 'desc' | 'asc' | null
}[]

const sortPriority = [
  ProcessResultManualStatusEnumAML.TRUE_POSITIVE_REJECT,
  ProcessResultManualStatusEnumAML.UNKNOWN_REJECT,
  undefined,
  null,
  ProcessResultManualStatusEnumAML.TRUE_POSITIVE_ACCEPT,
  ProcessResultManualStatusEnumAML.UNKNOWN_ACCEPT,
  ProcessResultManualStatusEnumAML.FALSE_POSITIVE,
]

export const sortMatchStatus = (
  v1: ProcessResultManualStatusEnumAML | undefined,
  v2: ProcessResultManualStatusEnumAML | undefined,
  matchStrength1: number,
  matchStrength2: number,
  // eslint-disable-next-line max-params
) => {
  const v1Index = sortPriority.indexOf(v1)
  const v2Index = sortPriority.indexOf(v2)

  if (v1Index < v2Index) return 1
  if (v1Index === v2Index) {
    if (Number(matchStrength1) && Number(matchStrength2)) {
      if (matchStrength1 < matchStrength2) return -1
      if (matchStrength1 > matchStrength2) return 1
      return 0
    }
    return 0
  }

  return -1
}
