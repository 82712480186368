import { bffClient, IClient } from 'shared/client'

export class EditBusinessAPI {
  client: IClient

  constructor(client: IClient) {
    this.client = client
  }

  async updateMCC(
    entityId: string,
    payload: { mcc: string; mccDescription: string },
  ) {
    return this.client.put(`/data/v2/business/${entityId}/mcc`, payload)
  }
}

export const editBusinessApi = new EditBusinessAPI(bffClient)
