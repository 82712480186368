import React from 'react'

import { FrankieButton } from 'frankify/src'

import { ApplicantId } from 'entities/applicant'
import { useHasTransactionMonitoring } from 'entities/session'

import { Show } from 'shared/hoc'
import { useTriggerState } from 'shared/hooks'
import { useI18n } from 'shared/i18n'
import { useOverlay } from 'shared/overlay'

import { APPLICANT_TRANSACTIONS_KEY } from '../../applicant-transactions.key'
import { applicantTransactionsEn } from '../../locale/applicant-transactions.en'
import { TransactionTypes } from '../../model/applicant-transactions.model'
import { applicantTransactionsQa } from '../../qa/applicant-transactions.qa'
import { ApplicantTransactionsDataGrid } from '../applicant-transactions-data-grid/applicant-transactions-data-grid'

type Props = {
  applicantId: ApplicantId
  className?: string
}

export function ApplicantTransactions({ applicantId, className = '' }: Props) {
  const t = useI18n([APPLICANT_TRANSACTIONS_KEY], {
    keys: applicantTransactionsEn,
  })

  const [createOverlay, closeOverlay] = useOverlay()

  const [refetchState, triggerRefetch] = useTriggerState()

  const { hasAmlTransaction, hasFraudTransaction } =
    useHasTransactionMonitoring()

  const handleViewAllTransactions = () => {
    const transactionType: TransactionTypes[] = []

    if (hasAmlTransaction) {
      transactionType.push(TransactionTypes.Aml)
    }
    if (hasFraudTransaction) {
      transactionType.push(TransactionTypes.Fraud)
    }

    createOverlay(
      <ApplicantTransactionsDataGrid
        applicantId={applicantId}
        heading={t('heading.allTxn')}
        searchPlaceholder={t('transactionId')}
        transactionType={transactionType}
        hideBulkAction
        fullView
        invalidateData
      />,
      {
        className: 'p-0 h-[100vh] w-[100vw]',
        closeButtonClassName: '!top-4',
        wrapperClassName: '!z-[800]',
        onClose: () => {
          closeOverlay()
          triggerRefetch()
        },
      },
    )
  }

  return (
    <div className={`${className}`}>
      <Show showAll>
        <Show.When isTrue={hasAmlTransaction}>
          <ApplicantTransactionsDataGrid
            className="mb-10"
            applicantId={applicantId}
            heading={t('heading.aml')}
            searchPlaceholder={t('transactionId')}
            transactionType={[TransactionTypes.Aml]}
            needsAttention
            refetchState={refetchState}
            testId={{ container: applicantTransactionsQa.dataGrid }}
          />
        </Show.When>

        <Show.When isTrue={hasFraudTransaction}>
          <ApplicantTransactionsDataGrid
            className="mb-10"
            applicantId={applicantId}
            heading={t('heading.fraud')}
            searchPlaceholder={t('transactionId')}
            transactionType={[TransactionTypes.Fraud]}
            needsAttention
            refetchState={refetchState}
          />
        </Show.When>

        <Show.When isTrue={hasFraudTransaction || hasAmlTransaction}>
          <div className="mb-10">
            <div className="pb-4 text-lg font-bold text-tertiary-grey-800">
              {t('heading.allTxn')}
            </div>
            <FrankieButton
              size="sm"
              className="!bg-mono-100 !outline-mono-50"
              onClick={handleViewAllTransactions}
              testId={{ button: applicantTransactionsQa.btn }}
            >
              {t('viewAllTransactions')}
            </FrankieButton>
          </div>
        </Show.When>
      </Show>
    </div>
  )
}
