import { useQuery } from '@tanstack/react-query'

import { IUser } from 'entities/user'

import { removeDuplicates } from 'shared/array'

import { userManagementApi } from '../../api/user-management.api'
import { USER_MANAGEMENT_KEY } from '../../user-management.key'

export const UserListQueryKey = [`${USER_MANAGEMENT_KEY}_user-list`]

export const useUserListQuery = ({
  canFetchUserList,
}: {
  canFetchUserList: boolean
}) =>
  useQuery<IUser[]>({
    queryKey: UserListQueryKey,
    queryFn: async () => {
      if (!canFetchUserList) {
        throw new Error('No access')
      }
      const { data } = await userManagementApi.getUserList()
      return removeDuplicates(data, user => user.id)
    },
  })
