import { useCallback } from 'react'

import { generatePath } from 'react-router-dom'

import {
  IPathUnderFeatureFlag,
  getIsFeatureFlagEnabled,
} from '../../model/session.model'
import { useSessionQuery } from '../../state/session/session.query'

export type TPathParams = Parameters<typeof generatePath>[1]

export type TPath = IPathUnderFeatureFlag | string

export function useGetPathValue() {
  const { data: session, isLoading } = useSessionQuery()

  const getPathValue = useCallback(
    (path: TPath): string => {
      if (typeof path === 'string') {
        return path
      }

      let flags = path.featureFlags || []

      if (path.reactMigrationFlag) {
        flags = [
          'verticalNavBar',
          ['vue2ReactMigration', path.reactMigrationFlag],
        ]
      }

      const isFeatureFlagEnabled = getIsFeatureFlagEnabled(session, flags)
      const isFrankie2Customer = getIsFeatureFlagEnabled(session, [
        'frankie2customer',
      ])
      if (isFeatureFlagEnabled || isFrankie2Customer) {
        return path.protectedValue
      }
      return path.fallbackValue
    },
    [session],
  )

  const generatePathValue = useCallback(
    (path: TPath, params: TPathParams) => {
      const resultPath = getPathValue(path)
      try {
        return generatePath(resultPath, params)
      } catch (e) {
        console.error('Error in generatePathValue', e)
        return ''
      }
    },
    [getPathValue],
  )

  return { getPathValue, generatePathValue, isLoading }
}
