import { useQuery } from '@tanstack/react-query'

import { IPermission } from 'entities/role'

import { roleManagementApi } from '../../api/role-management.api'
import { ROLE_MANAGEMENT_KEY } from '../../role-management.key'

export const PermissionOptionsQueryKey = [
  `${ROLE_MANAGEMENT_KEY}_permission-options`,
]

export const usePermissionOptionsQuery = ({
  canFetchPermissions,
}: {
  canFetchPermissions: boolean
}) =>
  useQuery<IPermission[]>({
    queryKey: PermissionOptionsQueryKey,
    queryFn: async () => {
      if (!canFetchPermissions) {
        throw new Error('No access')
      }
      const { data } = await roleManagementApi.getPermissionList()
      return data
    },
  })
