import { SupplementaryDataAML, useEntityAmlData } from 'entities/entity'

type Args = {
  entityId: string
  processResultId?: string
  historyPage?: boolean
}
export const useAmlData = ({
  entityId,
  processResultId,
  historyPage,
}: Args) => {
  const { data } = useEntityAmlData({ entityId, isNonValid: historyPage })
  const amlData = data.processResults.find(
    processResult => processResult.processResultId === processResultId,
  )

  const supplementaryData = amlData?.supplementaryData as
    | SupplementaryDataAML
    | undefined
  return { amlData: { ...amlData, supplementaryData } }
}
