import { INDIVIDUAL_PROFILE_INSIGHT_KEY as IPIK } from '../individual-profile-insight.key'

const individualProfileDocument = `${IPIK}-individual-profile-document`
export const IndividualProfileDocumentQa = {
  container: `${individualProfileDocument}-container`,
  tabDocumentIcon: `${individualProfileDocument}-tabDocumentIcon`,
  documentViewContainer: `${individualProfileDocument}-documentViewContainer`,
  srcLabelContainer: `${individualProfileDocument}-srcLabelContainer`,
} satisfies Record<string, `${typeof individualProfileDocument}-${string}`>

const individualProfileInsight = `${IPIK}-individual-profile-insight`
export const IndividualProfileInsightQa = {
  container: `${individualProfileInsight}-container`,
  title: `${individualProfileInsight}-title`,
  button: `${individualProfileInsight}-button`,
  tabItemContainer: `${individualProfileInsight}-tabItemContainer`,
  tabItem: `${individualProfileInsight}-tabItem`,
} satisfies Record<string, `${typeof individualProfileInsight}-${string}`>

const individualProfilePersonalInfo = `${IPIK}-individual-profile-personal-info`
export const IndividualProfilePersonalInfoQa = {
  container: `${individualProfilePersonalInfo}-container`,
  icon: `${individualProfilePersonalInfo}-icon`,
  individualIcon: `${individualProfilePersonalInfo}-individualIcon`,
  tabItemContainer: `${individualProfilePersonalInfo}-tabItemContainer`,
} satisfies Record<string, `${typeof individualProfilePersonalInfo}-${string}`>

const individualProfileAml = `${IPIK}-individual-profile-aml`
export const IndividualProfileAmlQa = {
  container: `${individualProfileAml}-container`,
  title: `${individualProfileAml}-title`,
  routeBtn: `${individualProfileAml}-route-btn`,
  routeFromTiles: `${individualProfileAml}-route-from-tiles`,
} satisfies Record<string, `${typeof individualProfileAml}-${string}`>

const individualProfileVerify = `${IPIK}-individual-profile-verify`
export const IndividualProfileVerifyQa = {
  workflowNameInput: `${individualProfileVerify}-workflow-name-Input`,
  commentInput: `${individualProfileVerify}-comment-input`,
  cancelButton: `${individualProfileVerify}-cancel-btn`,
  submitButton: `${individualProfileVerify}-submit-btn`,
}

export const getRouteTileQa = (issue: string) =>
  `${IndividualProfileAmlQa.routeFromTiles}-${issue}`
