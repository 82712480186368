import { ApplicantId } from 'entities/applicant'

import { IClient, bffClient } from 'shared/client'

import {
  FraudCheckResponse,
  FraudStatusChangePayload,
} from '../model/applicant-fraud-check.model'

export class ApplicantFraudCheckApi {
  client: IClient

  constructor(client: IClient) {
    this.client = client
  }

  async getFrauds(entityId: string) {
    return this.client.get<FraudCheckResponse>(
      `/data/v1/applicants/${entityId}/checks/fraud`,
    )
  }

  async fraudStatusChange(
    entityId: ApplicantId,
    data: FraudStatusChangePayload,
  ) {
    return this.client.put(
      `data/v1/applicants/${entityId}/checks/fraud/matches`,
      data,
    )
  }
}

export const applicantFraudCheckApi = new ApplicantFraudCheckApi(bffClient)
