import React from 'react'

import { UseFormReturn } from 'react-hook-form'

import { FrankieIcon } from 'frankify/src'

import {
  IIndividualProfileInputs,
  INDIVIDUAL_PROFILE_KEY,
  IndividualProfileInputTypes,
  individualProfileEn,
  AddressForm,
} from 'features/individual-profile'

import { useI18n } from 'shared/i18n'

import { blockListAddressFormQa } from '../../qa/applicant-blocklisted-info-qa'

type Props = {
  form: UseFormReturn<IIndividualProfileInputs>
  type: 'mailing' | 'residential'
}

export function BlocklistAddressForm({ form, type }: Props) {
  const t = useI18n([INDIVIDUAL_PROFILE_KEY], { keys: individualProfileEn })

  return (
    <div>
      <div
        data-qa={blockListAddressFormQa.container}
        className="flex justify-start items-center"
      >
        <FrankieIcon
          name={type === 'residential' ? 'mdiHomeOutline' : 'mdiEmailOutline'}
          className="mr-3"
        />
        <div className="font-bold">
          {type === 'residential'
            ? t('residentialAddress')
            : t('mailingAddress')}
        </div>
      </div>
      <AddressForm
        form={form}
        name={
          type === 'residential'
            ? IndividualProfileInputTypes.CurrentResidentialAddress
            : IndividualProfileInputTypes.MailingAddress
        }
        heading={
          type === 'residential' ? t('currentAddress') : t('mailingAddress')
        }
      />
    </div>
  )
}
