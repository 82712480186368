import React, { useCallback } from 'react'

import { IFrankieTabsProps } from './tabs.types'
import { FrankieIcon } from '../icon'

export function FrankieTabs<T extends string>({
  className = '',
  selectedItemClassName = '',
  itemsClassName = '',
  selectedTab,
  options,
  onTabSelect,
  testId,
}: IFrankieTabsProps<T>): JSX.Element {
  const getHorizontalMargin = useCallback(
    (idx: number) => {
      const isLast = idx === options.length - 1
      const isFirst = idx === 0

      if (isFirst) {
        return 'me-4'
      }

      if (isLast) {
        return 'ms-4'
      }

      return 'mx-4'
    },
    [options],
  )

  return (
    <div
      className={`text-sm text-center border-b border-tertiary-grey-300 text-gray-500 ${className}`}
      data-qa={testId?.tabsContainer}
    >
      <ul className="flex flex-wrap">
        {options.map((item, idx) => (
          <li
            key={item.value}
            className={`${itemsClassName} font-semibold inline-block py-2 border-b-4 cursor-pointer w-auto ${
              item.value === selectedTab
                ? `${selectedItemClassName} border-primary-800 text-tertiary-grey-700`
                : 'border-transparent text-tertiary-grey-500'
            } ${getHorizontalMargin(idx)}`}
            role="presentation"
            onClick={() => onTabSelect(item.value)}
            data-qa={item.testId?.input}
          >
            {item.icon && (
              <FrankieIcon
                size="sm"
                className="inline-block align-bottom mr-2"
                name={item.icon}
              />
            )}
            {item.label}
          </li>
        ))}
      </ul>
    </div>
  )
}
