import { APPLICANT_PERSONAL_INFO_KEYS } from '../applicant-persona-info.keys'

const ApplicantPersonalInfo = `${APPLICANT_PERSONAL_INFO_KEYS}-qa`

export const applicantPersonalInfoQa = {
  matchedRuleSet: `${ApplicantPersonalInfo}-matched-rule-set`,
  personalInfo: `${ApplicantPersonalInfo}-personal-info`,
  documentInfo: `${ApplicantPersonalInfo}-document-info`,
  button: `${ApplicantPersonalInfo}-button`,
} satisfies Record<string, `${typeof ApplicantPersonalInfo}-${string}`>

const ApplicantMatchedRuleSet = `${ApplicantPersonalInfo}-matched-rule-set-qa`
export const applicantMatchedRuleSetQa = {
  applicantDetailView: `${ApplicantMatchedRuleSet}-applicant-detail-view`,
} satisfies Record<string, `${typeof ApplicantMatchedRuleSet}-${string}`>

const PersonalInfo = `${ApplicantPersonalInfo}-personal-info-qa`
export const personalInfoQa = {
  applicantDetailView: `${PersonalInfo}-applicant-detail-view`,
} satisfies Record<string, `${typeof PersonalInfo}-${string}`>

const DocumentInfo = `${ApplicantPersonalInfo}-document-info-qa`
export const documentInfoQa = {
  applicantDetailView: `${DocumentInfo}-applicant-detail-view`,
  button: `${DocumentInfo}-button`,
  document: `${DocumentInfo}-document`,
} satisfies Record<string, `${typeof DocumentInfo}-${string}`>

const NameComparison = `${ApplicantPersonalInfo}-name-comparison-qa`
export const nameComparisonQa = {
  form: `${NameComparison}-form`,
  button: `${NameComparison}-button`,
  closeBtn: `${NameComparison}-close-button`,
  textArea: `${NameComparison}-text-area`,
} satisfies Record<string, `${typeof NameComparison}-${string}`>

const IndividualVerification = `${ApplicantPersonalInfo}-individual-verification-qa`
export const individualVerificationQa = {
  button: `${IndividualVerification}-button`,
  table: `${IndividualVerification}-table`,
} satisfies Record<string, `${typeof IndividualVerification}-${string}`>

const ApplicantDetailView = `${ApplicantPersonalInfo}-applicant-detail-view-qa`

export const applicantDetailViewQa = {
  expandBtn: `${ApplicantDetailView}-expand-button`,
  table: `${ApplicantDetailView}-table`,
  openAdditional: `${ApplicantDetailView}-open-additional-info`,
  openUboDetail: `${ApplicantDetailView}-open-ubo-detail`,
} satisfies Record<string, `${typeof ApplicantDetailView}-${string}`>
