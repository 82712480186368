import React, { useMemo } from 'react'

import { ApplicantId } from 'entities/applicant'

import { ActionMapping, ActionMappingType } from './action-mapping'
import { BannerMapping, BannerMappingType } from './banner-mapping'
import { useApplicantCheckSummaryState } from '../../state/applicant-check-summary-state/applicant-check-summary-state'

type Props = {
  applicantId: ApplicantId
  className?: string
}

export function ApplicantStatusBanner({ applicantId, className = '' }: Props) {
  const {
    entityWithSpecialState,
    isManualBlocklist,
    isResolvedBlacklistEntity,
    isResolveDuplicateEntity,
    watchlistReasonCode,
    archivedProfileAccount,
    isBlocklistAttribute,
    inactiveProfileAccount,
    isBusinessProfile,
  } = useApplicantCheckSummaryState({ applicantId })

  /**
   * Determine the current banner based on conditions
   */
  const currentBanner = useMemo((): BannerMappingType | null => {
    if (entityWithSpecialState) {
      return 'SpecialEntityStateBanner'
    }

    // If it's a business profile, no need to check other conditions
    if (isBusinessProfile) {
      return null
    }

    if (isManualBlocklist) {
      return 'BlocklistBanner'
    }

    if (isResolvedBlacklistEntity || isResolveDuplicateEntity) {
      return 'BlocklistOrDuplicateMatchBanner'
    }

    if (watchlistReasonCode) {
      return 'WatchlistBanner'
    }

    return null
  }, [
    entityWithSpecialState,
    isBusinessProfile,
    isManualBlocklist,
    isResolveDuplicateEntity,
    isResolvedBlacklistEntity,
    watchlistReasonCode,
  ])

  /**
   * Determine the current action based on conditions
   */
  const currentAction = useMemo((): ActionMappingType | null => {
    if (archivedProfileAccount) {
      return 'DeactivateProfile'
    }

    // If it's a business profile, no need to check other conditions
    if (isBusinessProfile) {
      return null
    }

    if (isManualBlocklist && isBlocklistAttribute) {
      return 'ViewAndEditProfile'
    }

    if (
      !inactiveProfileAccount &&
      (isResolveDuplicateEntity || isResolvedBlacklistEntity)
    ) {
      return 'ArchiveProfile'
    }

    return null
  }, [
    archivedProfileAccount,
    inactiveProfileAccount,
    isBlocklistAttribute,
    isBusinessProfile,
    isManualBlocklist,
    isResolveDuplicateEntity,
    isResolvedBlacklistEntity,
  ])

  const CurrentBanner = currentBanner ? BannerMapping[currentBanner] : null
  const CurrentAction = currentAction ? ActionMapping[currentAction] : null

  if (!CurrentBanner) return null

  return (
    <div className={className}>
      <CurrentBanner applicantId={applicantId}>
        {CurrentAction && <CurrentAction applicantId={applicantId} />}
      </CurrentBanner>
    </div>
  )
}
