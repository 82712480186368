import React, { useMemo } from 'react'

import { ORGANISATION_AUDIT_KEY } from 'features/organisation-audit/locale/organisation-audit.en'

import { ICountryOption, useCountriesCoveredQuery } from 'entities/country'
import {
  useCreateProfileROW,
  IMatchedOrganization,
  useGenerateReportROW,
  useCreateOwnershipProfileROW,
} from 'entities/organisation'
import { GenerateReportTypes, ProfileGenerate } from 'entities/profile-check'

import { useI18n } from 'shared/i18n'
import { useOverlay } from 'shared/overlay'

import { OrganisationOwnershipReportModal } from '../organisation-ownership-report-modal/organisation-ownership-report-modal'
import { OrganisationProfileReportModal } from '../organisation-profile-report-modal/organisation-profile-report-modal'

type Props = {
  organisationInfo: IMatchedOrganization
}

export function OrganisationAuditRest({ organisationInfo }: Props) {
  const t = useI18n([ORGANISATION_AUDIT_KEY])

  const { data: coveredCountries } = useCountriesCoveredQuery()

  const countryCode = organisationInfo.country
  const hasInternationalUBO = coveredCountries?.find(
    (country: ICountryOption) => country.alpha3code === countryCode,
  )?.UBOAvailable

  const { isLoading: isCreatingProfile } = useCreateProfileROW()

  const { isLoading: isCreatingOwnershipProfile } =
    useCreateOwnershipProfileROW()

  const { isLoading: isGeneratingReport } = useGenerateReportROW('profile')

  const [createOverlay, closeOverlay] = useOverlay()

  const options = useMemo(
    () => [
      {
        title: t('organisationProfileTitle'),
        value: GenerateReportTypes.PROFILE,
        description: t('organisationProfileSubtitle'),
      },
      {
        title: t('organisationOwnershipTitle'),
        value: GenerateReportTypes.OWNERSHIP,
        description: t('organisationOwnershipSubtitle'),
        isHidden: !hasInternationalUBO,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasInternationalUBO],
  )

  const handleClickGenerateProfileReport = () => {
    createOverlay(
      <OrganisationProfileReportModal
        organisationInfo={organisationInfo}
        onClose={closeOverlay}
      />,
      {
        className: 'w-[480px] p-7',
        closeButtonClassName: '!top-7 !right-6 z-10',
      },
    )
  }

  const handleClickGenerateOwnershipReport = () => {
    createOverlay(
      <OrganisationOwnershipReportModal
        organisationInfo={organisationInfo}
        onClose={closeOverlay}
      />,
      {
        className: 'w-[480px] p-7',
        closeButtonClassName: '!top-7 !right-6 z-10',
      },
    )
  }

  const handleSubmit = (value: GenerateReportTypes) => {
    if (value === GenerateReportTypes.PROFILE) {
      handleClickGenerateProfileReport()
    }

    if (value === GenerateReportTypes.OWNERSHIP) {
      handleClickGenerateOwnershipReport()
    }
  }

  const isLoadingProfileReport = isCreatingProfile || isGeneratingReport
  const isLoadingOwnershipReport =
    isCreatingOwnershipProfile || isGeneratingReport

  return (
    <ProfileGenerate
      className="mb-6"
      heading={t('selectReportTitleRow')}
      label={t('generateReport')}
      placeholder={t('selectReport')}
      options={options}
      onSubmit={handleSubmit}
      isLoading={isLoadingOwnershipReport || isLoadingProfileReport}
    />
  )
}
