import { ApplicantTabTypes } from 'entities/applicant'

export const routingQa = {
  frankieLogo: 'routing-header-logo',
  applicantsLink: 'routing-applicants-link',
  analyticsLink: 'routing-dashboard-link',
  blocklistLink: 'routing-blocklist-link',
  newProfileLink: 'routing-new-profile-link',
  applicantsReviewLink: 'routing-applicants-review-link',
  documentationLink: 'routing-documentation-link',

  viewMenuCta: 'navbar-view-menu-cta',
  collapseMenuCta: 'navbar-collapse-menu-cta',

  accountMenuCta: 'account-widget-account-menu-cta',
  changePasswordCta: 'account-widget-change-password-cta',
  logoutCta: 'account-widget-logout-cta',
  settingsLink: 'account-widget-settings-link',

  applicantMenuCta: 'applicant-menu-cta',
  applicantMenuEditCta: 'applicant-menu-edit-cta',
  applicantMenuArchiveCta: 'applicant-menu-archive-cta',
  applicantMenuUnarchiveCta: 'applicant-menu-unarchive-cta',
  settings: {
    notifications: 'notifications',
    personal: 'personal',
    account: 'settings',
    roles: 'roles',
    users: 'users',
  },
}

export const applicantNavQa: Record<ApplicantTabTypes, string> = {
  generalInformation: 'general-information',
  personalInfoKyc: 'personal-info-kyc',
  blocklistedInfo: 'blocklisted-info',
  blocklistMatches: 'blocklist-matches',
  pepSanctions: 'pep-sanctions',
  businessOwnership: 'business-ownership',
  riskIndicators: 'risk-indicators',
  biometryOcr: 'biometry-ocr',
  fraudCheck: 'fraud-check',
  potentialMatches: 'potential-matches',
  duplicateMatches: 'duplicate-matches',
  transactions: 'transactions',
  characteristics: 'characteristics',
  auditReport: 'audit-report',
  supportingDocuments: 'supporting-documents',
  workflowEvents: 'workflow-events',
  editEntity: 'edit-entity',
  resolveEntity: 'resolve-entity',
  profileInsight: 'profile-insight',
  amlHistory: 'aml-history',
  resolveAmlIssue: 'resolve-aml-issue',
  iKybAmlScreening: 'ikyb-aml-screening',
  amlScreening: 'aml-screening',
  relatedOrganisations: 'related-organisations',
}

/**
 * Migrated from Vue codebase
 */
export const routingVueMigratedQa = {
  analytics: {
    dashboard: 'dashboard',
    systemMonitoring: 'system_monitoring',
  },
  newProfile: {
    profileWrapper: 'profile-form',
  },
  applicants: {
    assignToInput: 'assignToInput',
    toggleComment: 'toggle-comment',
    nav: { ...applicantNavQa },
  },
}
