import React, { useEffect, useMemo } from 'react'

import { Controller } from 'react-hook-form'

import { FrankieButton, FrankieImage, FrankieTextField } from 'frankify/src'

import { INDIVIDUAL_PROFILE_KEY } from 'features/individual-profile/individual-profile.keys'
import { individualProfileEn } from 'features/individual-profile/locale/new-profile.en'
import {
  cardValue,
  mCareCardOption,
} from 'features/individual-profile/model/document.model'
import { IndividualProfileInputTypes } from 'features/individual-profile/model/form.model'

import { KycDocumentCategoryTypes } from 'entities/document'

import { ToggleButton, getError } from 'shared/form'
import { isDateValid, validateDate } from 'shared/form/model/error.model'
import { MaskedTextField } from 'shared/form/ui/masked-form-field/masked-form-field'
import { useI18n } from 'shared/i18n'
import { Nullable } from 'shared/typescript'

import { individualProfileVueMigratedQa } from '../../../../qa/individual-profile.qa'
import { DocumentCategory } from '../document-category/document-category'
import { DocumentsSubFormProps } from '../document-sub-forms.types'

enum SelectedMiddleNameTypes {
  FullName = 'fullName',
  NoName = 'noName',
  Initial = 'initial',
}

export function MedicareCardForm({
  form,
  idx,
  wrapperClasses,
}: DocumentsSubFormProps) {
  const t = useI18n([INDIVIDUAL_PROFILE_KEY], { keys: individualProfileEn })
  const {
    register,
    reset,
    watch,
    control,
    formState: { errors },
    setValue,
    getValues,
    unregister,
  } = form

  const MedicareCardInputName = useMemo(
    () =>
      `${IndividualProfileInputTypes.Document}.${idx}.${IndividualProfileInputTypes.MedicareCard}` as const,
    [idx],
  )

  const middleNameDisplayName =
    `${MedicareCardInputName}.${IndividualProfileInputTypes.MiddleNameDisplay}` as const

  const displayNameLineName =
    `${MedicareCardInputName}.${IndividualProfileInputTypes.DisplayNameLine}` as const

  const referenceRegister = useMemo(
    () =>
      register(
        `${MedicareCardInputName}.${IndividualProfileInputTypes.Reference}`,
        {
          required: true,
        },
      ),
    [MedicareCardInputName, register],
  )

  const idExpiryRegister = register(
    `${MedicareCardInputName}.${IndividualProfileInputTypes.IdExpiry}`,
    {
      required: true,
      validate: {
        isValidDate: value => validateDate(value),
      },
    },
  )

  const card = watch(
    `${MedicareCardInputName}.${IndividualProfileInputTypes.IdSubType}`,
  )

  useEffect(() => {
    setValue(
      `${IndividualProfileInputTypes.Document}.${idx}.${IndividualProfileInputTypes.DocumentCategory}`,
      KycDocumentCategoryTypes.SECONDARY as never,
    )
  }, [idx, setValue])

  // Function to get the selected middle name type
  const getSelectedMiddleNameType = (currentValue: Nullable<string>) => {
    let selectedDisplayMiddleName: SelectedMiddleNameTypes =
      SelectedMiddleNameTypes.NoName
    if (currentValue?.length)
      selectedDisplayMiddleName =
        currentValue.length > 1
          ? SelectedMiddleNameTypes.FullName
          : SelectedMiddleNameTypes.Initial

    return selectedDisplayMiddleName
  }

  const personalInfoWatch = watch(IndividualProfileInputTypes.PersonalInfo)
  const middleName = personalInfoWatch[IndividualProfileInputTypes.MiddleName]
  const firstName = personalInfoWatch[IndividualProfileInputTypes.FirstName]
  const lastName = personalInfoWatch[IndividualProfileInputTypes.LastName]

  useEffect(() => {
    if (!middleName) {
      setValue(middleNameDisplayName, '' as never)
    } else {
      const middleNameDisplayNameWatch = getValues(middleNameDisplayName)

      const selectedMiddleNameType = getSelectedMiddleNameType(
        middleNameDisplayNameWatch,
      )

      if (selectedMiddleNameType === SelectedMiddleNameTypes.FullName) {
        setValue(middleNameDisplayName, middleName as never)
      } else if (selectedMiddleNameType === SelectedMiddleNameTypes.Initial) {
        setValue(middleNameDisplayName, middleName.charAt(0) as never)
      }
    }
    // If the first name, middle name or last name changes, update the display name
  }, [firstName, middleName, lastName])

  const idNumberFieldName = useMemo(
    () =>
      `${IndividualProfileInputTypes.Document}.${idx}.${IndividualProfileInputTypes.IdNumber}` as IndividualProfileInputTypes.Document,
    [idx],
  )

  // unregister id number because used in multiple components
  useEffect(
    () => () => {
      unregister(idNumberFieldName)
    },
    [idx, unregister, idNumberFieldName],
  )

  const watchDisplayNameLine1 = watch(displayNameLineName)
  const watchMiddleNameDisplay = watch(
    middleNameDisplayName,
  ) as Nullable<string>
  // Update display name line 1 when middle name changes
  useEffect(() => {
    const displayNameLine1 = `${firstName}${
      watchMiddleNameDisplay ? ` ${watchMiddleNameDisplay} ` : ' '
    }${lastName}` // Template string to add middle name if it exists - add spaces also

    setValue(displayNameLineName, displayNameLine1 as never)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchMiddleNameDisplay, firstName, lastName, middleName])

  return (
    <div className={wrapperClasses}>
      <Controller
        render={({ field: { onChange, value } }) => (
          <div className="basis-[100%]">
            <div className="pb-1 text-tertiary-grey-800 text-sm font-medium">
              {t('documentForm.cardColor')}
            </div>

            {mCareCardOption.map(item => (
              <FrankieButton
                key={item.value}
                noStyles
                className={`border-2 border-solid m-1 ${
                  value === item.value
                    ? 'border-primary-700'
                    : 'border-transparent'
                } rounded-sm`}
                onClick={() => {
                  setValue(
                    `${MedicareCardInputName}.${IndividualProfileInputTypes.IdExpiry}`,
                    '' as never,
                  )
                  reset({}, { keepValues: true, keepIsValid: true })
                  onChange(item.value)
                }}
              >
                <FrankieImage className="cursor-pointer" src={item.icon} />
              </FrankieButton>
            ))}
          </div>
        )}
        control={control}
        name={`${MedicareCardInputName}.${IndividualProfileInputTypes.IdSubType}`}
      />
      <FrankieTextField
        className="basis-[32%]"
        label={t('documentForm.number')}
        placeholder={t('documentForm.numberPlaceholder')}
        error={!!getError(idNumberFieldName, errors)}
        {...register(idNumberFieldName, {
          required: true,
          minLength: 10,
        })}
        maxLength={10}
        minLength={10}
      />
      <FrankieTextField
        className="basis-[32%]"
        label={t('documentForm.positionOnCard')}
        placeholder={t('documentForm.positionOnCardPlaceholder')}
        {...referenceRegister}
        maxLength={1}
        error={!!getError(referenceRegister.name, errors)}
        testId={{
          input: individualProfileVueMigratedQa.document.reference,
        }}
      />
      {card === cardValue.green ? (
        <MaskedTextField
          className="basis-[32%]"
          label={t('documentForm.dateOfExpiry')}
          control={control}
          rules={{
            required: true,
            validate: {
              isDateValid: (value: string) => isDateValid(value),
            },
          }}
          name={`${MedicareCardInputName}.${IndividualProfileInputTypes.IdExpiry}`}
          testId={{
            input: individualProfileVueMigratedQa.document.idExpiry,
          }}
        />
      ) : (
        <FrankieTextField
          type="date"
          className="basis-[32%]"
          label={t('documentForm.dateOfExpiry')}
          {...idExpiryRegister}
          placeholder={t('documentForm.dateOfExpiry')}
          error={!!getError(idExpiryRegister.name, errors)}
          testId={{
            input: individualProfileVueMigratedQa.document.idExpiry,
          }}
        />
      )}

      <Controller
        render={({ field: { value, onChange } }) => {
          const currentValue = value as Nullable<string>

          const middleName =
            personalInfoWatch[IndividualProfileInputTypes.MiddleName]
          const selectedDisplayMiddleName =
            getSelectedMiddleNameType(currentValue)

          return (
            <>
              <ToggleButton<SelectedMiddleNameTypes>
                label={`${t('documentForm.displayMiddleName')} ${t(
                  'optional',
                )}`}
                className="basis-[66%]"
                inputClassName="h-[40px]"
                value={selectedDisplayMiddleName}
                disabled={!middleName}
                options={[
                  {
                    label: t('documentForm.fullMiddleName'),
                    value: SelectedMiddleNameTypes.FullName,
                  },
                  {
                    label: t('documentForm.noMiddleName'),
                    value: SelectedMiddleNameTypes.NoName,
                  },
                  {
                    label: t('documentForm.middleNameInitial'),
                    value: SelectedMiddleNameTypes.Initial,
                  },
                ]}
                onChange={event => {
                  switch (event) {
                    case SelectedMiddleNameTypes.FullName:
                      onChange(middleName)
                      break
                    case SelectedMiddleNameTypes.NoName:
                      onChange('')
                      break
                    case SelectedMiddleNameTypes.Initial:
                      onChange(middleName.charAt(0))
                      break
                    default:
                      break
                  }
                }}
              />

              <FrankieTextField
                className="basis-[32%]"
                label={t('documentForm.nameAsDisplay')}
                value={watchDisplayNameLine1}
                disabled
              />
            </>
          )
        }}
        control={control}
        name={middleNameDisplayName}
      />
      <DocumentCategory
        disabled
        className="basis-[32%]"
        form={form}
        idx={idx}
      />
    </div>
  )
}
