import { useMutation } from '@tanstack/react-query'

import { organisationApi } from '../../api/organisation.api'
import { useReportRequestIdQuery } from '../report-request-query/report-request-query'

type Args = {
  entityId: string
  reportType: 'profile' | 'ownership'
}

export const useGenerateReportROW = (reportType: 'profile' | 'ownership') => {
  const { setRequestIdData } = useReportRequestIdQuery()

  return useMutation({
    mutationFn: ({ entityId, reportType }: Args) =>
      organisationApi.generateReportROW(entityId, reportType),
    onSuccess: (response, { entityId }) => {
      setRequestIdData(reportType, {
        entityId,
        requestId: response.data.requestId,
      })
    },
  })
}
