import React, { ReactNode } from 'react'

import { notificationsConfigTableRowLayoutQa } from '../../../qa/notifications-management.qa'

export type NotificationChannelCell = { cell: ReactNode; key: string }

type Props = {
  notificationTypeCell: ReactNode
  frequencyCell: ReactNode
  channelCells: NotificationChannelCell[]
}

export function NotificationsConfigTableRowLayout({
  notificationTypeCell,
  channelCells,
  frequencyCell,
}: Props) {
  return (
    <div
      className="w-full flex gap-2.5 py-1 flex-initial flex-row mb-2 "
      data-qa={notificationsConfigTableRowLayoutQa.notificationTypeCell}
    >
      {notificationTypeCell}
      <div
        className="basis-[140px] shrink-0 grow-0 "
        data-qa={notificationsConfigTableRowLayoutQa.frequencyCell}
      >
        {frequencyCell}
      </div>

      {channelCells.map(({ cell, key }) => (
        <div
          key={key}
          className="basis-[97px] shrink-0 grow-0 flex flex-row justify-center"
          data-qa={notificationsConfigTableRowLayoutQa.channelCell}
        >
          {cell}
        </div>
      ))}
    </div>
  )
}
