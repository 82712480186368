import { I18nFunction } from 'shared/i18n'

import { ProcessResultManualStatusEnumAML } from './entity.model'
import { entityEn } from '../locale/entity.en'

export enum AmlIssuesTypes {
  PEP = 'PEP',
  SANCTION = 'SANCTION',
  WATCH_LIST = 'WATCH_LIST',
  ADVERSE_MEDIA = 'ADVERSE_MEDIA',
}

type AmlStatusChangeFormProps = {
  amlStatus: ProcessResultManualStatusEnumAML
  t: I18nFunction<typeof entityEn>
}
export const getApprovalStatusOptions = ({
  amlStatus,
  t,
}: AmlStatusChangeFormProps) => {
  switch (amlStatus) {
    case ProcessResultManualStatusEnumAML.TRUE_POSITIVE:
      return [
        {
          label: t('amlManualStatus.pass'),
          value: ProcessResultManualStatusEnumAML.TRUE_POSITIVE_ACCEPT,
        },
        {
          label: t('amlManualStatus.fail'),
          value: ProcessResultManualStatusEnumAML.TRUE_POSITIVE_REJECT,
        },
      ]

    case ProcessResultManualStatusEnumAML.UNKNOWN:
      return [
        {
          label: t('amlManualStatus.pass'),
          value: ProcessResultManualStatusEnumAML.UNKNOWN_ACCEPT,
        },
        {
          label: t('amlManualStatus.fail'),
          value: ProcessResultManualStatusEnumAML.UNKNOWN_REJECT,
        },
      ]
    default:
      return []
  }
}

export type AmlStatusUpdateProps = {
  processResults: string[]
  manualStatus: ProcessResultManualStatusEnumAML
  comment: {
    text: string
  }
}

export const getAmlStatusConfig = <IType extends I18nFunction<typeof entityEn>>(
  t: IType,
  type?: ProcessResultManualStatusEnumAML,
) => {
  switch (type) {
    case ProcessResultManualStatusEnumAML.UNKNOWN:
      return {
        options: [
          {
            label: t('amlStatus.truePositive'),
            value: ProcessResultManualStatusEnumAML.TRUE_POSITIVE,
          },
          {
            label: t('amlStatus.falsePositive'),
            value: ProcessResultManualStatusEnumAML.FALSE_POSITIVE,
          },
        ],
      }

    case ProcessResultManualStatusEnumAML.TRUE_POSITIVE:
      return {
        options: [
          {
            label: t('amlStatus.falsePositive'),
            value: ProcessResultManualStatusEnumAML.FALSE_POSITIVE,
          },
          {
            label: t('amlStatus.unknown'),
            value: ProcessResultManualStatusEnumAML.UNKNOWN,
          },
        ],
      }

    case ProcessResultManualStatusEnumAML.FALSE_POSITIVE:
      return {
        options: [
          {
            label: t('amlStatus.truePositive'),
            value: ProcessResultManualStatusEnumAML.TRUE_POSITIVE,
          },
          {
            label: t('amlStatus.unknown'),
            value: ProcessResultManualStatusEnumAML.UNKNOWN,
          },
        ],
      }

    default:
      return {
        options: [
          {
            label: t('amlStatus.falsePositive'),
            value: ProcessResultManualStatusEnumAML.FALSE_POSITIVE,
          },
          {
            label: t('amlStatus.truePositive'),
            value: ProcessResultManualStatusEnumAML.TRUE_POSITIVE,
          },
          {
            label: t('amlStatus.unknown'),
            value: ProcessResultManualStatusEnumAML.UNKNOWN,
          },
        ],
      }
  }
}
