import { useMutation, useQueryClient } from '@tanstack/react-query'

import type { IUser } from 'entities/user'

import { userManagementApi } from '../../api/user-management.api'
import type { CreateUserArguments, CreateUserRequestBody } from '../../model'
import { UserListQueryKey } from '../user-list/user-list.query'

export const useCreateUserMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (createUserArguments: CreateUserArguments) => {
      const apiArgumements: CreateUserRequestBody = {
        userData: {
          realname: createUserArguments.fullName,
          username: createUserArguments.email,
          email: createUserArguments.email,
          roleIds: createUserArguments.roleIds,
        },
      }

      return userManagementApi.createUser(apiArgumements)
    },
    onSuccess: ({ data: newUser }) => {
      queryClient.setQueryData<IUser[]>(UserListQueryKey, oldData => {
        if (!oldData) return [newUser]

        return [newUser, ...oldData]
      })
    },
  })
}
