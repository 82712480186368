import { useMutation, useQueryClient } from '@tanstack/react-query'

import { StorageKeyTypes, getLocalStorageState } from 'shared/local-storage'

import { SessionQueryKey } from './session.query'
import { PageData } from '../../model/session.model'

export const useSessionMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ sessionData }: { sessionData: Partial<PageData> }) =>
      Promise.resolve(sessionData),
    onSuccess: successResponse => {
      const [pageData, setPageData] = getLocalStorageState(
        StorageKeyTypes.PageData,
      )

      //

      const newPageData = Object.assign(pageData as PageData, successResponse)

      queryClient.setQueryData(SessionQueryKey, newPageData)

      setPageData(newPageData)
    },
  })
}
