/* eslint-disable import/no-named-default */
import { default as CreditHeader } from './credit-header-icon/credit-header.svg'
import { default as MCareBlueCard } from './mcare-cards/mcare-blue.svg'
import { default as MCareGreenCard } from './mcare-cards/mcare-green.svg'
import { default as MCareYellowCard } from './mcare-cards/mcare-yellow.svg'

export const MCareCard = {
  blue: MCareBlueCard,
  green: MCareGreenCard,
  yellow: MCareYellowCard,
}

export const CreditHeaderIcon = CreditHeader
