import { ORGANISATION_SETTINGS_KEY } from '../organisation-settings.key'

export const organisationSettingsQa = {
  defaultCountryFormCtaSubmit:
    'organisation-settings-default-country-form-cta-submit',
} satisfies Record<string, `${typeof ORGANISATION_SETTINGS_KEY}-${string}`>

const integrationSections = `${ORGANISATION_SETTINGS_KEY}-integration-sections`
export const integrationSectionsQa = {
  container: `${integrationSections}-container`,
  title: `${integrationSections}-title`,
  logo: `${integrationSections}-logo`,
  slack: `${integrationSections}-slack`,
  desc: `${integrationSections}-desc`,
  btnContainer: `${integrationSections}-btn-container`,
  connectBtn: `${integrationSections}-connect-btn`,
  disconnectBtn: `${integrationSections}-disconnect-btn`,
} satisfies Record<string, `${typeof integrationSections}-${string}`>
