import React, { useEffect } from 'react'

import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { FrankieButton, FrankieLoader, FrankieTextField } from 'frankify/src'

import {
  useApplicantDataQuery,
  useApplicantUpdateDetailsMutation,
} from 'entities/applicant'
import { useBusinessMCC } from 'entities/organisation'

import { SelectFormField } from 'shared/form'
import { notification } from 'shared/notification'

import { useEditBusinessTranslation } from '../../locale/edit-business.en'
import { useUpdateMCCMutation } from '../../state/udpate-mcc-mutation/udpate-mcc.mutation'

export type Props = {
  applicantId: string
  getApplicantGeneralInfoPath: (applicantId: string) => string
}

export type FormData = {
  customerId: string
  merchantCategory: string
}

export function EditBusiness({
  applicantId,
  getApplicantGeneralInfoPath,
}: Props) {
  const { t } = useEditBusinessTranslation()
  const navigate = useNavigate()

  const {
    control,
    register,
    reset,
    handleSubmit,
    watch,
    formState: { dirtyFields },
  } = useForm<FormData>()

  const { data: mccOptions, isLoading } = useBusinessMCC()

  const { data: applicantData, refetch } = useApplicantDataQuery({
    applicantId,
  })

  const customerId = watch('customerId')
  const merchantCategory = watch('merchantCategory')

  const {
    mutateAsync: updateApplicant,
    isLoading: applicantUpdateDetailLoading,
  } = useApplicantUpdateDetailsMutation()
  const { mutateAsync: updateMcc, isLoading: applicantUpdateMccLoading } =
    useUpdateMCCMutation({
      applicantId,
    })

  useEffect(() => {
    const customerId = applicantData?.businessInfo?.customerID ?? ''
    const merchantCategory = applicantData?.businessInfo?.mccCode ?? ''
    reset({
      customerId,
      merchantCategory,
    })
  }, [applicantData, reset])

  const onSubmit = async (data: FormData) => {
    const { customerId, merchantCategory } = data

    if (applicantData?.businessInfo) {
      try {
        await updateApplicant({
          entityId: applicantId,
          payload: {
            business: {
              ...applicantData.businessInfo,
              customerID: customerId,
            },
            entityType: 'ORGANISATION',
          },
          params: {
            noInvalidate: false,
            noNameDobValidation: false,
            saveOnly: false,
          },
        })

        if (merchantCategory && dirtyFields.merchantCategory) {
          await updateMcc({
            mcc: merchantCategory,
            mccDescription:
              mccOptions?.find(mcc => mcc.value === merchantCategory)?.name ??
              '',
          })
        }

        notification.success(
          t('success.updateBusiness', {
            businessName: applicantData.businessInfo.businessName,
          }),
        )

        void refetch()
        const path = getApplicantGeneralInfoPath(applicantId)

        navigate(path)
      } catch (error) {
        notification.error(t('error.updateMcc'))
      }
    }
  }

  const saveDisabled =
    isLoading ||
    applicantUpdateDetailLoading ||
    applicantUpdateMccLoading ||
    (!customerId && !merchantCategory)

  return (
    <FrankieLoader loading={isLoading}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="min-h-[450px] px-8 flex flex-col gap-7"
      >
        <div className="text-lg font-bold">{t('heading')}</div>

        <FrankieTextField
          className="max-w-[300px]"
          label={`${t('customerId')} (${t('optional')})`}
          placeholder={t('customerId')}
          {...register('customerId')}
        />

        <SelectFormField
          className="max-w-[550px]"
          control={control}
          name="merchantCategory"
          placeholder={t('select')}
          label={`${t('merchantCategory')} (${t('optional')})`}
          autocomplete="contains"
          options={
            mccOptions?.map(mcc => ({
              label: `${mcc.value} - ${mcc.name}`,
              value: mcc.value,
            })) ?? []
          }
        />
        <div className="flex items-start gap-5">
          <FrankieButton type="submit" disabled={saveDisabled}>
            {t('save')}
          </FrankieButton>
          <FrankieButton intent="subtle" onClick={() => navigate(-1)}>
            {t('cancel')}
          </FrankieButton>
        </div>
      </form>
    </FrankieLoader>
  )
}
