import React from 'react'

import cv from 'classnames'

import { FrankieIcon } from 'frankify/src'

import { useI18n } from 'shared/i18n'

import { INDIVIDUAL_OCR_CHECK_KEY } from '../../individual-ocr-check.key'
import { individualOcrCheckEn } from '../../locale/individual-ocr-check.en'
import { OcrCheckReport } from '../../model/mock-ocr-data.model'

type Props = {
  ocrResult: OcrCheckReport[number]
}

export function OcrDocumentRow({ ocrResult }: Props) {
  const { title, children, score, scoreTKey, success } = ocrResult
  const t = useI18n([INDIVIDUAL_OCR_CHECK_KEY], { keys: individualOcrCheckEn })

  const greenRedPercentClasses = (value: number) => {
    if (value >= 70) {
      return 'text-tertiary-green-600'
    }
    return 'text-tertiary-red-700'
  }

  return (
    <>
      <div className="col-start-2 col-span-1 ">
        <div className="text-tertiary-grey-800 font-medium flex gap-1.5 mb-2">
          {success && (
            <FrankieIcon
              name="mdiCheckCircle"
              size="xs"
              className="text-tertiary-green-600 mt-[2.5px]"
            />
          )}
          {success === false && (
            <FrankieIcon
              name="mdiCloseCircle"
              size="xs"
              className="text-tertiary-red-700 mt-[2.5px]"
            />
          )}
          {t(title)}
        </div>
        {children.map(report => (
          <div
            key={report.title}
            className="text-tertiary-grey-500 font-medium text-xs flex gap-1.5 ml-4 mb-1.5"
          >
            {report.success && (
              <FrankieIcon
                name="mdiCheckCircle"
                size="2xs"
                className="text-tertiary-green-600 mt-[3px]"
              />
            )}
            {report.success === false && (
              <FrankieIcon
                name="mdiCloseCircle"
                size="2xs"
                className="text-tertiary-red-700 mt-[3px]"
              />
            )}
            {t(report.title)}
          </div>
        ))}
      </div>

      <div className=" col-start-3 col-span-1 ">
        <div className="w-40">
          <div className="font-semibold text-tertiary-grey-800">
            {t(scoreTKey)}
          </div>
          <div
            className={cv('font-bold text-2xl', greenRedPercentClasses(score))}
          >
            {t('score', { score })}
          </div>
        </div>
      </div>
    </>
  )
}
