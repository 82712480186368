import React from 'react'

import { CountryAlpha3CodeTypes } from 'entities/country'

import { TextFormField } from 'shared/form'
import { useI18n } from 'shared/i18n'
import { useValidationRules } from 'shared/validation'

import { INDIVIDUAL_PROFILE_F2_KEY } from '../../../../individual-profile-f2.key'
import { individualProfileF2En } from '../../../../locale/individual-profile-f2.en'
import { DocumentsSubFormProps } from '../document-sub-forms.types'

export function NationalIdForm({
  form,
  idx,
  wrapperClasses,
}: DocumentsSubFormProps) {
  const t = useI18n([INDIVIDUAL_PROFILE_F2_KEY], {
    keys: individualProfileF2En,
  })
  const { control, watch } = form
  const selectedCountry = watch(`documents.${idx}.country`)
  const { numericRule } = useValidationRules()

  const getRequiredRule = (label: Parameters<typeof t>['0']) => ({
    required: t('documentForm.ssn.error.empty', {
      label: t(label).toLowerCase(),
    }),
  })

  return (
    <div className={wrapperClasses}>
      <TextFormField
        className="w-full basis-[47.5%]"
        label={t('documentForm.idNumber')}
        placeholder={
          selectedCountry === CountryAlpha3CodeTypes.UNITED_STATES_AMERICA
            ? t('documentForm.ssn.placeholder')
            : t('documentForm.idNumber')
        }
        control={control}
        name={`documents.${idx}.primaryIdentifier`}
        rules={{
          ...getRequiredRule('documentForm.idNumber'),
          ...numericRule,

          validate: {
            format: value => {
              const { length } = String(value)
              switch (selectedCountry) {
                case CountryAlpha3CodeTypes.UNITED_STATES_AMERICA:
                  if (!value) return t('documentForm.ssn.error.empty')
                  if (!/^\d+$/.test(String(value)))
                    return t('documentForm.ssn.error.numeric')
                  if (length !== 4 && length !== 9)
                    return t('errors.minMaxLengthMust', {
                      label: t('documentForm.ssn.label'),
                      minimum: 4,
                      maximum: 9,
                    })
                  return true
                default:
                  return true
              }
            },
          },
        }}
        shouldUnregister
        showErrorText
      />
    </div>
  )
}
