import React, { useMemo } from 'react'

import classNames from 'classnames'
import { Controller, UseFormReturn } from 'react-hook-form'

import { FrankieButton } from 'frankify/src'

import { INDIVIDUAL_PROFILE_F2_KEY } from 'features/individual-profile-f2/individual-profile-f2.key'
import { individualProfileF2En } from 'features/individual-profile-f2/locale/individual-profile-f2.en'
import {
  documentIdTypesOption,
  IAttachment,
  IIndividualProfileF2FormType,
} from 'features/individual-profile-f2/model/individual-profile-f2-form.model'

import { DocumentUploadF2 } from 'entities/document-f2'
import {
  DocumentAttachment,
  DocumentIdTypes,
  useFrankie2R2Customer,
} from 'entities/entity'

import { useI18n } from 'shared/i18n'
import { useOverlay } from 'shared/overlay'

import { DeletionConfirmation } from '../deletion-confirmation/deletion-confirmation'

type Props = {
  form: UseFormReturn<IIndividualProfileF2FormType>
  isEdit?: boolean
  idx: number
  eKyc: boolean
  idType: DocumentIdTypes
}

const thumbnailTexts = {
  front: 'ID Front',
  photoPage: 'Photo Page',
  back: 'ID Back',
}

export function DocumentAttachmentForm({
  form,
  idx,
  eKyc,
  isEdit,
  idType,
}: Props) {
  const { control, trigger } = form
  const t = useI18n([INDIVIDUAL_PROFILE_F2_KEY], {
    keys: individualProfileF2En,
  })

  const idTypesOptions = documentIdTypesOption.map(item => ({
    label: t(`idType.${item.tKey}`),
    value: item.value,
  }))

  const idTypeName =
    idTypesOptions.find(item => item.value === idType)?.label ?? ''

  const isFrankie2R2 = useFrankie2R2Customer()

  const galleryLength =
    isFrankie2R2 && idType === DocumentIdTypes.DRIVERS_LICENSE ? 2 : 1

  const [createOverlay] = useOverlay()
  const [currentAttachment, setCurrentAttachment] = React.useState<number>(0)

  const sizeInMb = 20

  const handleImageChange = (isNext: boolean) => () => {
    if (isNext) {
      setCurrentAttachment(prev => prev + 1)
    } else {
      setCurrentAttachment(prev => prev - 1)
    }
  }

  const getThumbnailText = useMemo(() => {
    if (galleryLength === 1) {
      return thumbnailTexts.photoPage
    }
    return currentAttachment === 0 ? thumbnailTexts.front : thumbnailTexts.back
  }, [galleryLength, currentAttachment])

  function footer(isError: boolean) {
    return (
      galleryLength > 1 && (
        <div className="flex mt-2 justify-between items-center w-full grow-0">
          <div className="border border-tertiary-grey-200 rounded-l-sm flex justify-center items-center grow-0">
            <FrankieButton
              intent="subtle"
              disabled={currentAttachment === 0}
              className="h-7 !min-w-[28px]"
              singleIcon={{
                name: 'mdiChevronLeft',
                size: '2xs',
              }}
              onClick={handleImageChange(false)}
            />
          </div>
          <div
            className={classNames(
              isError && 'text-tertiary-red-600',
              'font-medium text-xs leading-4 text-tertiary-grey-500',
            )}
          >
            {getThumbnailText}
          </div>
          <div className="border border-tertiary-grey-200 rounded-r-sm flex justify-center items-center grow-0">
            <FrankieButton
              intent="subtle"
              className="h-7 !min-w-[28px]"
              disabled={currentAttachment === galleryLength - 1}
              singleIcon={{
                name: 'mdiChevronRight',
                size: '2xs',
              }}
              onClick={handleImageChange(true)}
            />
          </div>
        </div>
      )
    )
  }
  return (
    <div>
      <Controller
        render={({
          field: { name, value, onChange, ref },
          fieldState: { error },
        }) => (
          <div
            className={classNames(
              currentAttachment !== 0 && '!h-0 overflow-hidden',
            )}
          >
            <DocumentUploadF2
              name={idTypeName}
              error={!!error}
              id={name}
              handleFocus={() => setCurrentAttachment(0)}
              errorText={error?.message}
              showFile={!error}
              required={!eKyc}
              uploadedOn={(value as DocumentAttachment | undefined)?.createdAt}
              value={value as DocumentAttachment | undefined}
              ref={ref}
              onChange={({ scanName, mimeType, dataUri, size }) => {
                const onConfirm = () => {
                  const newValue: IAttachment = {
                    ...value,
                    mimeType,
                    size,
                    side: 'FRONT',
                    filename: scanName,
                    type: 'PHOTO',
                    data: { base64: dataUri },
                    retrievalStatus: 'NORMAL',
                    createdAt: new Date().toISOString(),
                  }
                  onChange(newValue)
                  void trigger(name)
                }

                if (
                  isEdit &&
                  Boolean((value as IAttachment | undefined)?.attachmentId)
                ) {
                  createOverlay(
                    <DeletionConfirmation onConfirm={onConfirm} isImage />,
                  )
                } else {
                  onConfirm()
                }
              }}
            />
            {footer(!!error)}
          </div>
        )}
        control={control}
        shouldUnregister
        rules={{
          required: {
            value: !eKyc,
            message:
              galleryLength > 1
                ? t('documentForm.noUploadFront')
                : t('documentForm.noUpload'),
          },
          validate: (value: IAttachment | undefined) =>
            (value?.size || 0) < sizeInMb * 1000000 ||
            t('documentForm.sizeError', { sizeInMb }),
        }}
        name={`documents.${idx}.attachments.0`}
      />
      <Controller
        render={({
          field: { value, name, onChange, ref },
          fieldState: { error },
        }) => (
          <div
            className={classNames(
              currentAttachment !== 1 && '!h-0 overflow-hidden',
            )}
          >
            <DocumentUploadF2
              name={idTypeName}
              error={!!error}
              handleFocus={() => setCurrentAttachment(1)}
              id={name}
              errorText={error?.message}
              showFile={!error}
              required={!eKyc}
              uploadedOn={(value as DocumentAttachment | undefined)?.createdAt}
              value={value as DocumentAttachment | undefined}
              ref={ref}
              onChange={({ scanName, mimeType, dataUri, size }) => {
                const onConfirm = () => {
                  const newValue: IAttachment = {
                    ...value,
                    mimeType,
                    size,
                    side: 'BACK',
                    filename: scanName,
                    type: 'PHOTO',
                    data: { base64: dataUri },
                    retrievalStatus: 'NORMAL',
                    createdAt: new Date().toISOString(),
                  }
                  onChange(newValue)
                  void trigger(name)
                }

                if (
                  isEdit &&
                  Boolean((value as IAttachment | undefined)?.attachmentId)
                ) {
                  createOverlay(
                    <DeletionConfirmation onConfirm={onConfirm} isImage />,
                  )
                } else {
                  onConfirm()
                }
              }}
            />
            {footer(!!error)}
          </div>
        )}
        control={control}
        shouldUnregister
        rules={{
          validate: (value: IAttachment | undefined) =>
            (value?.size || 0) < sizeInMb * 1000000 ||
            t('documentForm.sizeError', { sizeInMb }),
        }}
        name={`documents.${idx}.attachments.1`}
      />
    </div>
  )
}
