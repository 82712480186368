import React from 'react'

import { FrankieSelectField } from 'frankify/src'

import { useI18n } from 'shared/i18n'

import {
  NOTIFICATIONS_MANAGEMENT_KEY,
  notificationsManagementEn,
} from '../../locale/notifications-management.en'
import {
  INotificationSetting,
  INotificationSettingsDictionary,
  notificationVolumeOptions,
  NotificationVolumeTypes,
} from '../../model/notifications-management.model'
import { generalNotificationsConfigTableRowQa } from '../../qa/notifications-management.qa'
import { NotificationsConfigTableRowLayout } from '../notifications-config-table-row/notifications-config-table-row-layout/notifications-config-table-row-layout'

type Props = {
  notificationTypeInfo: INotificationSettingsDictionary
  settings?: INotificationSetting[]
  onMultipleChange: (setting: NotificationVolumeTypes) => void
}

export function GeneralNotificationsConfigTableRow({
  settings,
  notificationTypeInfo,
  onMultipleChange,
}: Props) {
  const t = useI18n([NOTIFICATIONS_MANAGEMENT_KEY], {
    keys: notificationsManagementEn,
  })

  return (
    <div
      className="w-full  leading-5"
      data-qa={generalNotificationsConfigTableRowQa.container}
    >
      <NotificationsConfigTableRowLayout
        notificationTypeCell={
          <>
            <div className=" basis-[120px] grow-0 shrink-0  text-tertiary-grey-700">
              {notificationTypeInfo.name}
            </div>
            <div className=" flex-1 whitespace-break-spaces text-tertiary-grey-500">
              {notificationTypeInfo.description}
            </div>
          </>
        }
        frequencyCell={
          <FrankieSelectField
            options={notificationVolumeOptions.map(option => ({
              ...option,
              label: t(option.label),
            }))}
            size="sm"
            inputClassName="text-xs !py-2 !pl-3"
            optionsClassName="[&>li]:!text-xs"
            onChange={e =>
              onMultipleChange(e.target.value as NotificationVolumeTypes)
            }
            value={
              notificationTypeInfo.isPersonalNotification
                ? NotificationVolumeTypes.ASSIGNED_ENTITIES
                : NotificationVolumeTypes.ALL_ENTITIES
            }
          />
        }
        channelCells={
          settings?.map(setting => ({
            key: setting.channel,
            cell: <div />,
          })) || []
        }
      />
    </div>
  )
}
