import { ApplicantRiskLevelTypes } from 'entities/applicant'

import { PartialRecord, aliasKeys } from 'shared/typescript'

import { riskStatusOptionEn } from '../locale/applicant-risk-score.en'

type StatusOption<TValue = string> = {
  value: TValue
  tKey: keyof typeof riskStatusOptionEn
}

export type ApplicantRiskScoreType =
  | 'onboarding'
  | 'fraudMonitoring'
  | 'amlMonitoring'

export type RiskColorFlag = 'low' | 'medium' | 'high' | 'unknown'

export type RiskColorType = 'bg' | 'text'

export type ApplicantRiskScoreRecord = {
  id: string
  factor: string
  value: string
  policy: string
  score: number | null
  color?: {
    appliedOn: RiskColorType
    type?: RiskColorFlag
  }
} & (
  | { level: { type: string; label: string }; type: 'badge' }
  | { type: 'value'; level: string }
)

export type ApplicantRiskScoreRecordProperties = keyof Omit<
  ApplicantRiskScoreRecord,
  'color' | 'type'
>

export const riskScoreColorFlag: PartialRecord<string, RiskColorFlag> = {
  ...aliasKeys(['risk_low', 'Low'], 'low'),
  ...aliasKeys(['risk_medium', 'Medium'], 'medium'),
  ...aliasKeys(['risk_high', 'risk_unacceptable', 'very_high', 'High'], 'high'),
  ...aliasKeys(['Unknown', 'unknown'], 'unknown'),
}

export const onboardingStatusOption: StatusOption<ApplicantRiskLevelTypes>[] = [
  { value: ApplicantRiskLevelTypes.Low, tKey: 'low' },
  { value: ApplicantRiskLevelTypes.Medium, tKey: 'medium' },
  { value: ApplicantRiskLevelTypes.High, tKey: 'high' },
  { value: ApplicantRiskLevelTypes.Unacceptable, tKey: 'unacceptable' },
  // { value: ApplicantRiskLevelTypes.Unknown, tKey: 'unknown' },
]

export type RiskLevelData = {
  comment: string
  level: string
}
