import { ROLE_KEY } from '../role.key'

export const roleQa = {
  roleTableRow_ctaView: 'role-table-row_cta-view',
  roleTableRow_ctaActions: 'role-table-row_cta-actions',
  roleTableRow_ctaEdit: 'role-table-row_cta-edit',
  roleTableRow_ctaDelete: 'role-table-row_cta-delete',
  roleTableRow_cellName: 'role-table-row_cell-name',
  roleTableRow_cellDescription: 'role-table-row_cell-description',
  roleTableRow_cellCreatedBy: 'role-table-row_cell-created-by',
  roleTableRow_cellCreatedAtDate: 'role-table-row_cell-created-at-date',
  roleTableRow_cellCreatedAtTime: 'role-table-row_cell-created-at-time',

  roleTableHeader_sortByName: 'role-table-row_sort-by-name',
  roleTableHeader_sortByOrgName: 'role-table-row_sort-by-org-name',
  roleTableHeader_sortByCreatedDate: 'role-table-row_sort-by-created-date',

  permissionCheckbox_column: 'role-permission-checkbox_column',
  permissionCheckbox_checkGroup: 'role-permission-checkbox_check-group',
  permissionCheckbox_check: 'role-permission-checkbox_check',
} satisfies Record<string, `${typeof ROLE_KEY}-${string}`>
