import React from 'react'

import { FrankieIcon, FrankieSelectField } from 'frankify/src'

import { useUserListState } from 'entities/session/state/user-list/user-list.state'

import { useI18n } from 'shared/i18n'

import { APPLICANT_KEY, applicantEn } from '../../locale/applicant.en'
import { useApplicantAssignee } from '../../state/applicant-assignee-state/applicant-assign-state'

type Props = {
  className?: string
  applicantId: string
  placeholder?: string
  testId?: { input?: string }
}

export function SelectApplicantAssignee({
  applicantId,
  className = '',
  placeholder,
  testId,
}: Props) {
  const t = useI18n([APPLICANT_KEY], { keys: applicantEn })

  const userList = useUserListState()

  const {
    assignee,
    updateAssignee,
    updatingAssignee,
    assigneeUpdateSuccessfully,
  } = useApplicantAssignee({ applicantId })

  return (
    <div className={`relative flex items-center gap-2 ${className}`}>
      <span className="whitespace-nowrap text-neutral-70 text-xs">
        {t('assignTo')}:
      </span>

      <FrankieSelectField
        autocomplete
        options={userList}
        value={assignee}
        onChange={e => e.target.value && updateAssignee(`${e.target.value}`)}
        testId={{ input: testId?.input }}
        placeholder={placeholder ?? t('choose')}
        optionsClassName="!z-20"
      />
      <div className="absolute inset-y-2 right-2">
        {updatingAssignee && (
          <FrankieIcon
            name="mdiSyncCircle"
            className="bg-mono-white text-primary-800 animate-spin rounded-full"
          />
        )}
        {!updatingAssignee && assigneeUpdateSuccessfully && (
          <FrankieIcon
            name="mdiCheckCircle"
            className="bg-mono-white text-tertiary-green-400"
          />
        )}
      </div>
    </div>
  )
}
