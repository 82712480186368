import React, { useCallback, useState } from 'react'

import {
  DataGridPro,
  GridColDef,
  GridComparatorFn,
  GridSortModel,
} from '@mui/x-data-grid-pro'

import { FrankieIcon, FrankieLoader } from 'frankify/src'

import { ApplicantId } from 'entities/applicant'
import {
  DOCUMENT_KEY,
  DocumentStatusTypes,
  DocumentTypes,
  documentEn,
  documentStatusOptions,
  documentTypesOption,
} from 'entities/document'

import { WithProps } from 'shared/hoc'
import { useI18n } from 'shared/i18n'
import { arraysAsValueObject } from 'shared/typescript'

import { APPLICANT_SUPPORTING_DOCUMENTS_KEY } from '../../applicant-supporting-documents.key'
import { applicantSupportingDocumentsEn } from '../../locale/applicant-supporting-documents.en'
import { ApplicantUploadedDocumentsRecord } from '../../model/applicant-supporting-documents.model'
import { appUploadDocQa } from '../../qa/applicant-support-documents.qa'
import { useApplicantSupportingDocumentQuery } from '../../state/applicant-supporting-document-query/applicant-supporting-document.query'
import {
  headerField,
  documentCellWidth,
  documentDateFormatter,
  DocumentActionMenu,
  DocumentTableRow,
  documentTableMuiStyles,
  DocumentLoadingOverlay,
  DocumentTableHeader,
} from '../applicant-support-document-table-helper/applicant-support-document-table-helper'

type Props = {
  applicantId: ApplicantId
  paramKey: string
  className: string
}

export function ApplicantUploadedDocuments({
  applicantId,
  paramKey,
  className = '',
}: Props) {
  const t = useI18n([APPLICANT_SUPPORTING_DOCUMENTS_KEY], {
    keys: applicantSupportingDocumentsEn,
  })

  const tDoc = useI18n([DOCUMENT_KEY], {
    keys: documentEn,
  })

  const {
    data: applicantDocuments,
    isFetching,
    isLoading,
  } = useApplicantSupportingDocumentQuery({
    applicantId,
  })

  const [sortModel, setSortModel] = useState<GridSortModel>([])

  const sortDocumentNameComparator: GridComparatorFn<DocumentTypes> =
    useCallback(
      (v1, v2) => {
        const docTypesRecord = arraysAsValueObject(documentTypesOption)

        return tDoc(docTypesRecord[v1].tKey).localeCompare(
          tDoc(docTypesRecord[v2].tKey),
        )
      },
      [tDoc],
    )

  const columns: GridColDef<ApplicantUploadedDocumentsRecord>[] = [
    {
      field: headerField('documentType'),
      headerName: t('documentTypeAndName'),
      sortComparator: sortDocumentNameComparator,
      ...documentCellWidth(200),
      renderCell: ({ row }) => {
        const currentType = documentTypesOption.find(
          item => item.value === row.documentType,
        )
        return (
          <div className="py-2 flex-col">
            {currentType && <div>{tDoc(currentType.tKey)}</div>}
            <div className="text-xs font-medium text-tertiary-grey-500 break-all">
              {row.documentName}
            </div>
          </div>
        )
      },
    },
    {
      field: headerField('status'),
      headerName: t('status'),
      ...documentCellWidth(150),
      renderCell: ({ row }) => {
        const currentStatus = row.status

        const stylesClass = {
          [DocumentStatusTypes.APPROVED]: 'text-tertiary-green-500',
          [DocumentStatusTypes.NEEDS_REVIEW]: 'text-tertiary-amber-500',
          [DocumentStatusTypes.DECLINED]: 'text-tertiary-red-500',
        }

        return (
          <div>
            {currentStatus && (
              <div className="flex items-center gap-1 leading-tight">
                <FrankieIcon
                  className={stylesClass[currentStatus]}
                  size="sm"
                  name={documentStatusOptions[currentStatus].iconName}
                />
                <div className={stylesClass[currentStatus]}>
                  {tDoc(documentStatusOptions[currentStatus].tKey)}
                </div>
              </div>
            )}
          </div>
        )
      },
    },
    {
      field: headerField('uploadedDate'),
      headerName: t('uploadedDate'),
      valueFormatter: documentDateFormatter,
      ...documentCellWidth(145),
    },
    {
      field: headerField('uploadedBy'),
      headerName: t('uploadedBy'),
    },
    {
      field: headerField('reviewBy'),
      headerName: t('reviewedBy'),
    },
    {
      field: headerField('lastUpdatedDate'),
      headerName: t('lastUpdated'),
      valueFormatter: documentDateFormatter,
      sortComparator: (a: string, b: string) => b.localeCompare(a),
      ...documentCellWidth(145),
    },
    {
      field: 'action',
      headerName: '',
      sortable: false,
      type: 'actions',
      renderCell: WithProps(DocumentActionMenu, { applicantId }),
      renderHeader: () => null,
      ...documentCellWidth(30),
    },
  ]

  return (
    <div
      className={`text-tertiary-grey-700 ${className}`}
      data-qa={appUploadDocQa.wrapper}
    >
      <div className="text-xl text-secondary-900 font-bold pb-2">
        {t('uploadedDocuments')}
      </div>

      {applicantDocuments && !!applicantDocuments.length ? (
        <DataGridPro
          getRowId={row => row.id}
          rows={applicantDocuments}
          slots={{
            row: WithProps(DocumentTableRow, { paramKey }),
            loadingOverlay: DocumentLoadingOverlay,
          }}
          columns={columns.map(item => ({
            // Adding default properties for every column
            headerClassName:
              '!p-0 text-xs font-medium bg-tertiary-grey-50 text-tertiary-grey-500 !outline-none',
            cellClassName:
              '!outline-none text-sm font-semibold text-secondary-900 break-words',
            sortable: true,
            hideSortIcons: true,
            resizable: false,
            disableColumnMenu: true,
            disableReorder: true,
            renderHeader: WithProps(
              DocumentTableHeader<ApplicantUploadedDocumentsRecord>,
              { sortModel },
            ),
            ...documentCellWidth(180),
            ...item,
          }))}
          sx={documentTableMuiStyles}
          loading={isFetching || isLoading}
          sortModel={sortModel}
          onSortModelChange={setSortModel}
          isRowSelectable={() => false}
          isCellEditable={() => false}
          getRowHeight={() => 'auto'}
          disableRowSelectionOnClick
          disableColumnSelector
          disableColumnFilter
          disableColumnMenu
          disableDensitySelector
          showCellVerticalBorder={false}
          showColumnVerticalBorder={false}
          hideFooterPagination
          hideFooter
          hideFooterSelectedRowCount
          autoHeight
          autoPageSize
        />
      ) : (
        <FrankieLoader
          className="min-h-[50px]"
          loading={isLoading || isFetching}
          label={t('fetchingDoc')}
          testId={{ loader: appUploadDocQa.loader }}
        >
          <div className="">{t('noDocumentsUploaded')}</div>
        </FrankieLoader>
      )}
    </div>
  )
}
