import React from 'react'

import { FrankieLoader, FrankieVerticalTabs } from 'frankify/src'

import { useRoleManagementTranslation } from '../../locale/role-management-f2.en'
import { roleManagementF2Qa, roleSegment } from '../../qa/role-management-f2.qa'
import { useF2PermissionQuery } from '../../state/f2-permissions-query/f2-permissions.query'
import { useF2RoleQuery } from '../../state/f2-roles-query/f2-roles.query'
import { RolePermissions } from '../role-permissions/role-permissions'

type Props = {
  className?: string
}

export function RoleManagement({ className = '' }: Props) {
  const { t } = useRoleManagementTranslation()

  const { isLoading: isPermissionLoading } = useF2PermissionQuery()

  const {
    roles,
    selectedRole,
    setSelectedRole,
    isLoading: isRoleLoading,
  } = useF2RoleQuery()

  return (
    <FrankieLoader
      className={`flex gap-2 ${className}`}
      loading={isRoleLoading || isPermissionLoading}
      label={
        <div className="mt-1 text-md font-medium text-tertiary-grey-800">
          {t('loading')}
        </div>
      }
    >
      <div data-qa={roleManagementF2Qa.container} className="sticky top-0">
        <div className="text-tertiary-grey-800 text-xl font-bold">
          {t('roles')}
        </div>
        <FrankieVerticalTabs
          activeTab={selectedRole?.id}
          onTabChange={value => setSelectedRole(value)}
          tabItems={[...(roles ?? [])].map(({ role, id }) => ({
            label: role,
            value: id,
            testId: `${roleManagementF2Qa.role}${roleSegment(role)}`,
          }))}
        />
      </div>

      <RolePermissions
        testId={{
          container: roleManagementF2Qa.rolePermissionContainer,
          title: roleManagementF2Qa.rolePermissionTitle,
        }}
        className="flex-grow max-w-[750px] h-full"
      />
    </FrankieLoader>
  )
}
