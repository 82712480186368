import React, { useMemo } from 'react'

import { ApplicantId } from 'entities/applicant'
import { checksRegex, ChecksTypes } from 'entities/check'

import { useI18n } from 'shared/i18n'

import { APPLICANT_GENERAL_INFORMATION_KEY } from '../../applicant-general-information.key'
import { applicantGeneralInformationEn } from '../../locale/applicant-general-information.en'
import { CheckCardInfo, checksDataGenerator } from '../../model/checks.model'
import { applicantBusinessCompletedChecksQa } from '../../qa/applicant-general-information.qa'
import {
  useApplicantBusinessState,
  useApplicantBusinessReport,
} from '../../state/applicant-business-state/applicant-business-state'
import { ApplicantCheckCard } from '../applicant-check-card/applicant-check-card'

type Props = {
  applicantId: ApplicantId
}

export function ApplicantBusinessCompletedChecks({ applicantId }: Props) {
  const t = useI18n([APPLICANT_GENERAL_INFORMATION_KEY], {
    keys: applicantGeneralInformationEn,
  })

  const { checkResults, pepRan, entityHasAml } = useApplicantBusinessState({
    applicantId,
  })
  const { getReportDocument, pendingReport } = useApplicantBusinessReport({
    applicantId,
  })

  // eslint-disable-next-line complexity
  const completeChecks: CheckCardInfo[] = useMemo(() => {
    const result: CheckCardInfo[] = []

    if (checkResults.length) {
      const filteredCheckResults = checkResults.filter(
        cr => cr.result.type !== 'UNCHECKED',
      )

      filteredCheckResults.forEach(checkResult => {
        const { type } = checkResult
        const resultType = checkResult.result.type.toLowerCase()

        const findRegMatch = Object.entries(checksRegex).find(([_key, regex]) =>
          regex.test(type),
        )
        if (findRegMatch) {
          const matchedKey = findRegMatch[0] as ChecksTypes
          const checksDataGeneratorFn = checksDataGenerator[matchedKey]
          result.push(checksDataGeneratorFn(resultType))
        }
      })
    }

    // (From Vue) entity doesn't have checkResults available, need to manual pick up the PEP check status
    if (!checkResults.length && pepRan) {
      result.push(checksDataGenerator.pepMedia(entityHasAml ? 'fail' : 'pass'))
    }

    // if has kyb report document
    const entityHasKybReportDocument = Boolean(
      getReportDocument('SINGLE-LEVEL-AML', {
        checkForScans: true,
        lastScanErrorFree: true,
      }),
    )

    if (pendingReport.kyb || entityHasKybReportDocument) {
      result.push(
        checksDataGenerator.kybReport(
          entityHasKybReportDocument ? 'pass' : 'unchecked',
        ),
      )
    }

    // if has ubo report document
    const entityHasUboReportDocument = Boolean(
      getReportDocument('UBO', {
        checkForScans: true,
        lastScanErrorFree: true,
      }),
    )

    if (pendingReport.ubo || entityHasUboReportDocument) {
      result.push(
        checksDataGenerator.uboReport(
          entityHasUboReportDocument ? 'pass' : 'unchecked',
        ),
      )
    }

    // if has credit report document
    const entityHasCreditReportDocument = Boolean(
      getReportDocument('CR', {
        checkForScans: true,
        lastScanErrorFree: true,
      }),
    )

    if (pendingReport.cr || entityHasCreditReportDocument) {
      result.push(
        checksDataGenerator.crReport(
          entityHasCreditReportDocument ? 'pass' : 'unchecked',
        ),
      )
    }

    return result
  }, [checkResults, getReportDocument, pendingReport, pepRan, entityHasAml])

  const hasCompletedChecks = completeChecks.length > 0

  return (
    <div className="flex flex-col gap-4">
      {hasCompletedChecks && (
        <div
          className="text-lg"
          data-qa={applicantBusinessCompletedChecksQa.completedCheckText}
        >
          {t('businessCheck.completedCheck')}
        </div>
      )}
      {completeChecks.map(item => (
        <ApplicantCheckCard
          key={item.title}
          applicantId={applicantId}
          {...item}
        />
      ))}
    </div>
  )
}
