import React, { useState } from 'react'

import { IconButton, MenuItem } from '@mui/material'

import { FrankiePopover } from 'frankify/src'

import { ApplicantId } from 'entities/applicant'

import { I18nKeys, useI18n } from 'shared/i18n'
import { useOverlay } from 'shared/overlay'
import { Noop } from 'shared/typescript'

import { EditAssociatedPartyModal } from './edit-associated-party-modal/edit-associated-party-modal'
import { EntitySendKycLinkModal } from './entity-send-kyc-link-modal/entity-send-kyc-link-modal'
import { RemoveAssociatedPartyModal } from './remove-associated-party-modal/remove-associated-party-modal'
import { APPLICANT_BUSINESS_OWNERSHIP_KEY } from '../../applicant-business-ownership.key'
import { applicantBusinessOwnershipEn } from '../../locale/applicant-business-ownership.en'
import { ApplicantOwnershipHolderRecord } from '../../model/applicant-business-ownership.model'
import { businessOwnerShipDataGridActionMenuQa } from '../../qa/applicant-business-ownership.qa'

type Actions = Required<ApplicantOwnershipHolderRecord>['actions']

type Props = {
  applicantId: ApplicantId
  className?: string
  rowData: ApplicantOwnershipHolderRecord
}

const ActionComponentMapping = {
  EntitySendKycLinkModal,
  RemoveAssociatedPartyModal,
  EditAssociatedPartyModal,
}

type ActionComponent = keyof typeof ActionComponentMapping

type ActionData = Record<
  Actions[number],
  {
    tKey: I18nKeys<typeof applicantBusinessOwnershipEn>
    componentType: ActionComponent
  }
>

const actionData: ActionData = {
  removeParty: {
    tKey: 'action.removeAssociatedParty',
    componentType: 'RemoveAssociatedPartyModal',
  },
  editParty: {
    tKey: 'action.editAssociatedParty',
    componentType: 'EditAssociatedPartyModal',
  },
  sendKycLink: {
    tKey: 'action.sendKycLink',
    componentType: 'EntitySendKycLinkModal',
  },
}

export function BusinessOwnershipDataGridActionMenu({
  className = '',
  applicantId,
  rowData,
}: Props) {
  const t = useI18n([APPLICANT_BUSINESS_OWNERSHIP_KEY], {
    keys: applicantBusinessOwnershipEn,
  })

  const {
    actions,
    entityId,
    beneficiallyHeldOwnership,
    nonBeneficiallyHeldOwnership,
    otherRoleTypeDescription,
    roles,
    total,
  } = rowData

  const [open, setOpen] = useState(false)

  const [createOverlay, closeOverlay] = useOverlay()

  const handleAction = (action: Actions[number]) => {
    setOpen(false)

    const actionComp = actionData[action].componentType
    const SelectedActionComp = ActionComponentMapping[actionComp]

    createOverlay(
      <SelectedActionComp
        onClose={closeOverlay}
        entityId={entityId}
        applicantId={applicantId}
        data={{
          beneficiallyOwnership: beneficiallyHeldOwnership.replace('%', ''),
          nonBeneficiallyOwnership: nonBeneficiallyHeldOwnership.replace(
            '%',
            '',
          ),
          total: +total.replace('%', ''),
          otherRoleTypeDescription,
          roles,
        }}
      />,
      {
        className: 'p-0',
        closeButtonClassName: '!top-4 !right-5',
      },
    )
  }

  const handleClick =
    (cb: Noop) =>
    (e: React.MouseEvent<HTMLButtonElement | HTMLLIElement, MouseEvent>) => {
      e.stopPropagation()
      e.preventDefault()
      cb()
    }

  if (!actions?.length) return null

  return (
    <div
      className={className}
      data-qa={businessOwnerShipDataGridActionMenuQa.componentWrapper}
    >
      <FrankiePopover
        open={open}
        onOpenChange={setOpen}
        popoverRest={{ placement: 'bottom-end' }}
        trigger={
          <IconButton
            className={`h-[30px] w-[30px] pb-4 rounded-sm px-2 text-tertiary-grey-700 ${
              open ? 'bg-neutral-30' : ''
            }`}
            onClick={handleClick(() => setOpen(prev => !prev))}
          >
            ...
          </IconButton>
        }
      >
        <div className="bg-mono-white shadow-md rounded-sm py-1">
          {actions.map(action => (
            <MenuItem
              key={action}
              className="py-2 text-sm text-secondary-900 hover:bg-tertiary-grey-100"
              onClick={handleClick(() => handleAction(action))}
            >
              {t(actionData[action].tKey)}
            </MenuItem>
          ))}
        </div>
      </FrankiePopover>
    </div>
  )
}
