import { Nullable } from 'shared/typescript'

/**
 * Capitalize the first letter of a string
 */
export const capitalize = (str: string) => {
  const value = str.toLowerCase()
  return value ? value.charAt(0).toUpperCase() + value.slice(1) : ''
}

/**
 * Capitalize the first letter of each word in a string by a separator
 */
export function capitalizeStringBySeparator(
  inputString: string,
  separator: '-' | '_' = '_',
  useSentenceCase = false,
): string {
  const modifiedString = inputString
    .toLowerCase()
    .replace(/\b\w/g, match => match.toUpperCase())
    .replace(new RegExp(separator, 'g'), ' ')
    .replace(/\b\w/g, match => (useSentenceCase ? match : match.toUpperCase()))
  return modifiedString
}

type Content = string | number | null | undefined

/**
 * Assert the content of a string or number or array and return combined string or empty assertion
 */
export function assertContent(
  content?: Nullable<Content | Content[]>,
  assertOnEmpty = '-',
): string {
  const contentText =
    content && Array.isArray(content)
      ? content.filter(val => !!val || val === 0).join(' ')
      : content
  return contentText && `${contentText}`.trim()
    ? `${contentText}`
    : assertOnEmpty
}

/**
 * Generate a random key
 */
export function generateRandomKey(str?: string | null, idx?: number): string {
  if (str && idx) {
    return `${str}_${idx}`
  }

  const randomKey = Math.random().toString(36).substring(2, 15)

  return idx ? `${randomKey}_${idx}` : randomKey
}

export function getLastBySplitter(str: string, splitter: string): string {
  return str.split(splitter).pop() || ''
}

export function splitAndCompareLast(
  str: string,
  compareStr: string,
  splitter: string,
): boolean {
  return (
    getLastBySplitter(str, splitter) === getLastBySplitter(compareStr, splitter)
  )
}

/**
 * @param string any string to convert
 * @returns string converted (convert every first letter of string to lowercase except for first)
 * @example "Residential Address source match" -> "Residential address source match"
 */

export function convertToLowerCaseExceptFirst(inputString: string): string {
  const words = inputString.split(' ')
  if (words.length > 1) {
    const result = `${words[0]} ${words
      .slice(1)
      .map(word => word.toLowerCase())
      .join(' ')}`
    return result
  }
  return inputString
}

export function camelCaseToDashCase(inputString: string): string {
  return inputString.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase()
}
