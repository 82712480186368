import { ApplicantId } from 'entities/applicant'

import { Nullable } from 'shared/typescript'

import {
  BlocklistAttributeTypes,
  MatchesTypes,
  MatchesValueTypes,
} from './applicant-matches.models'

export type ChangedStatus = { id: string; type: string }

export type BlacklistResponseType = {
  matches: {
    personal: string[]
    documents: {
      documentId: Nullable<string>
      field: string[]
    }
  }
  entityId: ApplicantId
  matchStrength: number
}

export type CheckDuplicateType = {
  flag: string
  comment: string
  flagType?: MatchesTypes
}

export enum ErrorClassesTypes {
  DOCUMENT_ID_NUMBER = 'document-id-number',
  EXTERNAL_ID = 'external-id',
  NAME = 'name',
  CURRENT_ADDRESS = 'current-address',
  PREVIOUS_ADDRESS = 'previous-address',
  DOB = 'date-of-birth',
  PHONE_NUMBER = 'phone-number',
  EMAIL_ADDRESS = 'email-address',
  DEFAULT = '',
}

// eslint-disable-next-line complexity
export const getErrorFields = (matchingField: string) => {
  let field = ['unrecognized-field']
  const code = matchingField.toLowerCase()

  switch (code) {
    case 'ht_idnum':
    case 'id number':
    case 'idno':
      field = [ErrorClassesTypes.DOCUMENT_ID_NUMBER]
      break

    case 'ht_externalid':
    case 'external id':
    case 'exid':
      field = [ErrorClassesTypes.EXTERNAL_ID]
      break

    case 'ht_name':
    case 'ht_family_name':
    case 'ht_given_family_name':
    case 'ht_family_given_name':
    case 'ht_family_initial_name':
    case 'ht_family_given_middle_name':
    case 'full name':
    case 'family name':
    case 'given & family names':
    case 'family & given names':
    case 'family name & first initial':
    case 'family given & middle names':
    case 'name':
    case 'fnam':
    case 'gfnm':
    case 'fgnm':
    case 'finm':
    case 'fgmn':
      field = [ErrorClassesTypes.NAME]
      break

    case 'ht_address':
    case 'ht_short_address':
    case 'full address':
    case 'short address':
    case 'addr':
    case 'sadr':
      field = [
        ErrorClassesTypes.CURRENT_ADDRESS,
        ErrorClassesTypes.PREVIOUS_ADDRESS,
      ]
      break
    case 'ht_dob':
    case 'ht_yob':
    case 'date of birth':
    case 'year of birth':
    case 'date':
    case 'year':
      field = [ErrorClassesTypes.DOB]
      break
    case 'ht_msisdn':
    case 'mobile number':
    case 'phone number':
    case 'phon':
      field = [ErrorClassesTypes.PHONE_NUMBER]
      break
    case 'emal':
    case 'ht_email':
    case 'email address':
      field = [ErrorClassesTypes.EMAIL_ADDRESS]
      break
    default:
      field = []
      break
  }
  return field
}

export const getMatchedFields = (matchingFields: string[]) =>
  matchingFields.reduce(
    (fields: string[], matchingField) => [
      ...fields,
      ...getErrorFields(matchingField),
    ],
    [],
  )

export const getStatusBgColor = (
  confirmedStatus: Nullable<boolean> | undefined,
  changedStatus: ChangedStatus[],
  matchedApplicantId: ApplicantId,
  // eslint-disable-next-line max-params
) => {
  const idx = changedStatus.findIndex(item => item.id === matchedApplicantId)
  if (idx + 1)
    return changedStatus[idx].type === MatchesValueTypes.FalsePositive
      ? '!bg-tertiary-green-100'
      : '!bg-tertiary-grey-400'

  if (confirmedStatus === true) return '!bg-tertiary-grey-400'

  return 'bg-tertiary-red-50'
}

export const getStatusColor = (
  confirmedStatus: Nullable<boolean> | undefined,
  changedStatus: ChangedStatus[],
  matchedApplicantId: ApplicantId,
  // eslint-disable-next-line max-params
) => {
  // FalsePositive
  const idx = changedStatus.findIndex(item => item.id === matchedApplicantId)
  if (idx + 1)
    return changedStatus[idx].type === MatchesValueTypes.FalsePositive
      ? 'text-tertiary-green-400'
      : 'text-mono-white'
  // TruePositive
  if (confirmedStatus === true) return 'text-mono-white'

  return 'text-tertiary-red-500'
}

export type CheckBlocklistPayload = {
  comment: string
  flag: 'true_positive'
  originalId: ApplicantId
}

function capitalizeFirstChar(str: string) {
  const newStr = str.toLowerCase()
  return newStr.charAt(0).toUpperCase() + str.slice(1)
}
// From vue codebase
export function convertBlocklistAttrToReadableString(
  string: string,
  shortForm = false,
): string {
  if (!string) return ''
  switch (string) {
    case BlocklistAttributeTypes.ENTIRE_ENTITY:
      return 'Entire Profile'
    case BlocklistAttributeTypes.ID_DOCUMENT:
      return 'ID Document'
    case BlocklistAttributeTypes.NAME_AND_DOB:
      if (shortForm) return 'Name and DOB'
      return 'Name and Date Of Birth'

    default: {
      const splitStr = string.split('_')
      return splitStr.map(str => capitalizeFirstChar(str)).join(' ')
    }
  }
}
