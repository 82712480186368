import React, { lazy } from 'react'

import {
  createBrowserRouter,
  createRoutesFromElements,
  generatePath,
  Navigate,
  Route,
} from 'react-router-dom'

import { EXPIRED_URL_PARAM } from 'features/reset-password'

import { ApplicantId } from 'entities/applicant'
import { PermissionTypes, RoleId } from 'entities/role'
import { Vue2ReactMigrationFlagTypes } from 'entities/session'

import { LayoutAnalytics } from '../ui/layout-analytics/layout-analytics'
import { LayoutApplicantProfile } from '../ui/layout-applicant-profile/layout-applicant-profile'
import { LayoutAuth } from '../ui/layout-auth/layout-auth'
import { LayoutNewProfile } from '../ui/layout-new-profile/layout-new-profile'
import { LayoutPortal } from '../ui/layout-potal/layout-portal'
import { LayoutSettings } from '../ui/layout-settings/layout-settings'
import { PermissionGuard } from '../ui/permission-guard/permission-guard'
import { PrivateRoute } from '../ui/private-route/private-route'
import { Redirect } from '../ui/redirect/redirect'

const LoginPage = lazy(async () => {
  const { LoginPage } = await import('pages/login')
  return { default: LoginPage }
})

const NewPasswordPage = lazy(async () => {
  const { NewPasswordPage } = await import('pages/new-password')
  return { default: NewPasswordPage }
})

const PasswordResetPage = lazy(async () => {
  const { PasswordResetPage } = await import('pages/password-reset')
  return { default: PasswordResetPage }
})

const PasswordResetSentPage = lazy(async () => {
  const { PasswordResetSentPage } = await import('pages/reset-password-sent')
  return { default: PasswordResetSentPage }
})

const SettingsOrganisationPage = lazy(async () => {
  const { SettingsOrganisationPage } = await import(
    'pages/settings-organisation'
  )
  return { default: SettingsOrganisationPage }
})

const SettingsNotificationsPage = lazy(async () => {
  const { SettingsNotificationsPage } = await import(
    'pages/settings-notifications'
  )
  return { default: SettingsNotificationsPage }
})

const SettingsPersonalPage = lazy(async () => {
  const { SettingsPersonalPage } = await import('pages/settings-personal')
  return { default: SettingsPersonalPage }
})

const SettingsRoleEditPage = lazy(async () => {
  const { SettingsRoleEditPage } = await import('pages/settings-role-edit')
  return { default: SettingsRoleEditPage }
})

const SettingsRoleListPage = lazy(async () => {
  const { SettingsRoleListPage } = await import('pages/settings-role-list')
  return { default: SettingsRoleListPage }
})

const SettingsRoleNewPage = lazy(async () => {
  const { SettingsRoleNewPage } = await import('pages/settings-role-new')
  return { default: SettingsRoleNewPage }
})

const SettingsRoleViewPage = lazy(async () => {
  const { SettingsRoleViewPage } = await import('pages/settings-role-view')
  return { default: SettingsRoleViewPage }
})

const SettingsUserListPage = lazy(async () => {
  const { SettingsUserListPage } = await import('pages/settings-user-list')
  return { default: SettingsUserListPage }
})

const NewProfilePage = lazy(async () => {
  const { NewProfilePage } = await import('pages/new-profile')
  return { default: NewProfilePage }
})

const ManualIdvPage = lazy(async () => {
  const { ManualIdvPage } = await import('pages/manual-idv')
  return { default: ManualIdvPage }
})

const EditBusinessPage = lazy(async () => {
  const { EditBusinessPage } = await import('pages/edit-business')
  return { default: EditBusinessPage }
})

const InternationalProfilePage = lazy(async () => {
  const { InternationalProfilePage } = await import(
    'pages/international-profile'
  )
  return { default: InternationalProfilePage }
})

const ApplicantsListPage = lazy(async () => {
  const { ApplicantsListPage } = await import('pages/applicants-list')
  return { default: ApplicantsListPage }
})

const CustomViewPage = lazy(async () => {
  const { CustomViewPage } = await import('pages/custom-view')
  return { default: CustomViewPage }
})

const NeedsReviewPage = lazy(async () => {
  const { NeedsReviewPage } = await import('pages/needs-review')
  return { default: NeedsReviewPage }
})

const SearchResultsPage = lazy(async () => {
  const { SearchResultsPage } = await import('pages/search-results')
  return { default: SearchResultsPage }
})

const SearchResultsNotificationPage = lazy(async () => {
  const { SearchResultsNotificationPage } = await import(
    'pages/search-results-notification'
  )
  return { default: SearchResultsNotificationPage }
})

const DashboardPage = lazy(async () => {
  const { DashboardPage } = await import('pages/dashboard')
  return { default: DashboardPage }
})

const BlocklistPage = lazy(async () => {
  const { BlocklistPage } = await import('pages/blocklist')
  return { default: BlocklistPage }
})

const IndividualProfile = lazy(async () => {
  const { IndividualProfilePage } = await import('pages/individual-profile')
  return { default: IndividualProfilePage }
})

const ApplicantGeneralInformationPage = lazy(async () => {
  const { ApplicantGeneralInformationPage } = await import(
    'pages/applicant-general-information'
  )
  return { default: ApplicantGeneralInformationPage }
})

const ApplicantProfileSupportingDocuments = lazy(async () => {
  const { ApplicantProfileSupportingDocuments } = await import(
    'pages/applicant-supporting-documents'
  )
  return { default: ApplicantProfileSupportingDocuments }
})

const ApplicantAuditReportPage = lazy(async () => {
  const { ApplicantAuditReportPage } = await import(
    'pages/applicant-audit-report'
  )
  return { default: ApplicantAuditReportPage }
})

const ApplicantWorkflowEventsPage = lazy(async () => {
  const { ApplicantWorkflowEventsPage } = await import(
    'pages/applicant-workflow-events'
  )
  return { default: ApplicantWorkflowEventsPage }
})

const ApplicantBlocklistPage = lazy(async () => {
  const { ApplicantBlocklist } = await import('pages/applicant-blocklist')
  return { default: ApplicantBlocklist }
})

const ApplicantDuplicatePage = lazy(async () => {
  const { ApplicantDuplicate } = await import('pages/applicant-duplicate')
  return { default: ApplicantDuplicate }
})

const ApplicantPersonalInfoKycPage = lazy(async () => {
  const { ApplicantPersonalInfo } = await import(
    'pages/applicant-personal-info'
  )
  return { default: ApplicantPersonalInfo }
})

const ApplicantBlocklistedInfoPage = lazy(async () => {
  const { ApplicantBlocklistedInfo } = await import(
    'pages/applicant-blocklist-info'
  )
  return { default: ApplicantBlocklistedInfo }
})

const AddToBlocklistPage = lazy(async () => {
  const { AddToBlocklist } = await import('pages/add-to-blocklist')
  return { default: AddToBlocklist }
})

const ApplicantTransactionsPage = lazy(async () => {
  const { ApplicantTransactionsPage } = await import(
    'pages/applicant-transactions'
  )
  return { default: ApplicantTransactionsPage }
})

const ApplicantCustomerCharacteristicsPage = lazy(async () => {
  const { ApplicantCustomerCharacteristicsPage } = await import(
    'pages/applicant-customer-characteristics'
  )
  return { default: ApplicantCustomerCharacteristicsPage }
})

const ApplicantRiskScorePage = lazy(async () => {
  const { ApplicantRiskScorePage } = await import('pages/applicant-risk-score')
  return { default: ApplicantRiskScorePage }
})

const ApplicantPotentialMatchesPage = lazy(async () => {
  const { ApplicantPotentialMatches } = await import(
    'pages/applicant-potential-matches'
  )
  return { default: ApplicantPotentialMatches }
})

const ApplicantBusinessOwnershipPage = lazy(async () => {
  const { ApplicantBusinessOwnershipPage } = await import(
    'pages/applicant-business-ownership'
  )
  return { default: ApplicantBusinessOwnershipPage }
})

const ApplicantBiometricsOcrPage = lazy(async () => {
  const { ApplicantBiometricsOcrPage } = await import(
    'pages/applicant-biometrics-ocr'
  )
  return { default: ApplicantBiometricsOcrPage }
})

const ApplicantFraudCheckPage = lazy(async () => {
  const { ApplicantFraudCheckPage } = await import(
    'pages/applicant-fraud-check'
  )
  return { default: ApplicantFraudCheckPage }
})

const ApplicantPepSactionsPage = lazy(async () => {
  const { ApplicantPepSactionsPage } = await import(
    'pages/applicant-pep-sactions'
  )
  return { default: ApplicantPepSactionsPage }
})

const ApplicantRelatedOrganisationsPage = lazy(async () => {
  const { ApplicantRelatedOrganisationsPage } = await import(
    'pages/applicant-related-organisations/'
  )

  return { default: ApplicantRelatedOrganisationsPage }
})

const IKybAmlScreeningPage = lazy(async () => {
  const { IKybAmlScreeningPage } = await import('pages/ikyb-aml-screening')
  return { default: IKybAmlScreeningPage }
})

const EditEntityPage = lazy(async () => {
  const { EditEntityPage } = await import('pages/edit-entity')
  return { default: EditEntityPage }
})

const ResolveEntityPage = lazy(async () => {
  const { ResolveEntityPage } = await import('pages/resolve-entity')
  return { default: ResolveEntityPage }
})

const IndividualAmlHistoryPage = lazy(async () => {
  const { IndividualAmlHistoryPage } = await import(
    'pages/individual-aml-history'
  )
  return { default: IndividualAmlHistoryPage }
})

const IndividualAmlResultPage = lazy(async () => {
  const { IndividualAmlResultPage } = await import(
    'pages/individual-aml-result'
  )
  return { default: IndividualAmlResultPage }
})

const IndividualAmlScreeningPage = lazy(async () => {
  const { IndividualAmlScreeningPage } = await import(
    'pages/individual-aml-screening'
  )
  return { default: IndividualAmlScreeningPage }
})

const IndividualProfileInsightPage = lazy(async () => {
  const { IndividualProfileInsightPage } = await import(
    'pages/individual-profile-insight'
  )
  return { default: IndividualProfileInsightPage }
})

const params = {
  roleId: 'roleId',
  applicantId: 'applicantId',
  viewId: 'viewId',
}

export const paths = {
  root: '/',
  login: '/login',
  reset_password: '/reset-password', // page after login
  reset_password_sent: '/reset-password/:email', // password link sent
  new_password: '/new-password',
  settings_notifications: '/admin/notifications',
  settings_personal: '/admin/personal',
  settings_organisation: '/admin/settings',
  settings_role_list: '/admin/roles',
  settings_role_new: '/admin/roles/new',
  settings_role_edit: `/admin/roles/:${params.roleId}/edit`,
  settings_role_view: `/admin/roles/:${params.roleId}/view`,
  dashboard: '/dashboard',
  blocklist: '/blocklist',
  new_blocklist: '/blocklist/new',
  settings_user_list: '/admin/users',
  new_profile: '/new-profile',
  new_profile_individual: '/new-profile/individual',
  new_profile_business: '/new-profile/business',
  applicants: '/entities',
  applicantsTest: '/entities-test',
  needs_review: '/needs-review',
  needs_review_general_information: `/needs-review/profile/:${params.applicantId}/general-information`,
  search_results: '/search-results',

  // TODO: @ggrigorev NAV - remove this when we have a dashboard v2
  onboarding: '/onboarding',
  // end TODO
  monitoring_id: '/monitoring/profile/:entityId',
  onboarding_id: '/onboarding/profile/:entityId',
  monitoring_id_general_information: `/monitoring/profile/:${params.applicantId}/general-information`,
  onboarding_id_general_information: `/onboarding/profile/:${params.applicantId}/general-information`,
  applicant_profile: `/entities/profile/:${params.applicantId}`,
  applicant_profile_general_information: `/entities/profile/:${params.applicantId}/general-information`,

  applicantProfileSupportingDocuments: `/entities/profile/:${params.applicantId}/supporting-documents`,
  applicantProfileDuplicateMatches: `/entities/profile/:${params.applicantId}/duplicate-matches`,
  applicantProfileAuditReport: `/entities/profile/:${params.applicantId}/audit-report`,
  applicantProfileBlocklistMatches: `/entities/profile/:${params.applicantId}/blocklist-matches`,
  applicantProfileRiskIndicators: `/entities/profile/:${params.applicantId}/risk-indicators`,
  applicantProfilePotentialMatches: `/entities/profile/:${params.applicantId}/potential-matches`,
  // Rest of the applicant profile that will be converted to react
  applicantProfilePersonalInfo: `/entities/profile/:${params.applicantId}/personal-info-kyc`,
  applicantProfileBlocklistedInfo: `/entities/profile/:${params.applicantId}/blocklisted-info`,
  applicantProfileRelatedOrganisations: `/entities/profile/:${params.applicantId}/related-orgs`,
  applicantProfilePepSanctions: `/entities/profile/:${params.applicantId}/pep-sanctions`,
  applicantProfileOwnersOfficeholders: `/entities/profile/:${params.applicantId}/business-ownership`,
  applicantProfileBiometricsOcr: `/entities/profile/:${params.applicantId}/biometry-ocr`,
  applicantProfileFraudCheck: `/entities/profile/:${params.applicantId}/fraud-check`,
  applicantProfileTransactions: `/entities/profile/:${params.applicantId}/transactions`,
  applicantProfileCharacteristics: `/entities/profile/:${params.applicantId}/characteristics`,
  applicantManualIdv: `/entities/profile/:${params.applicantId}/manual-idv`,
  applicantEditBusiness: `/entities/profile/:${params.applicantId}/edit-business`,
  customView: `/custom-views/:${params.viewId}`,
}

// New migrated pages (names would changed on later stage)
const newPaths = {
  dashboardNew: '/new/dashboard',
  systemMonitoring: '/new/dashboard/system-monitoring',
  blocklist: '/new/blocklist',
  individualProfile: '/new/new-profile/individual',
  businessProfile: '/new/new-profile/business', // not in vue code so not to be used in navigation (original path is to be used)
  applicant: '/new/entities', // not to be used in navigation, used to highlight entity tab
  applicantProfile: `/new/entities/profile/:${params.applicantId}`,
  applicantProfileGeneralInformation: `/new/entities/profile/:${params.applicantId}/general-information`,
  applicantProfileSupportingDocuments: `/new/entities/profile/:${params.applicantId}/supporting-documents`,
  applicantProfileDuplicateMatches: `/new/entities/profile/:${params.applicantId}/duplicate-matches`,
  applicantProfileAuditReport: `/new/entities/profile/:${params.applicantId}/audit-report`,
  applicantProfileBlocklistMatches: `/new/entities/profile/:${params.applicantId}/blocklist-matches`,
  applicantProfilePersonalInfo: `/new/entities/profile/:${params.applicantId}/personal-info-kyc`,
  applicantProfileRiskIndicators: `/new/entities/profile/:${params.applicantId}/risk-indicators`,
  applicantProfilePotentialMatches: `/new/entities/profile/:${params.applicantId}/potential-matches`,
  applicantProfileTransactions: `/new/entities/profile/:${params.applicantId}/transactions`,
  applicantProfileCharacteristics: `/new/entities/profile/:${params.applicantId}/characteristics`,
  applicantProfileOwnersOfficeholders: `/new/entities/profile/:${params.applicantId}/business-ownership`,
  applicantNewBlocklist: '/new/blocklist/new',
  applicantProfileBlocklistedInfo: `/new/entities/profile/:${params.applicantId}/blocklisted-info`,
  applicantProfileBiometricsOcr: `/new/entities/profile/:${params.applicantId}/biometry-ocr`,
  applicantProfileFraudCheck: `/new/entities/profile/:${params.applicantId}/fraud-check`,
  applicantProfilePepSanctions: `/new/entities/profile/:${params.applicantId}/pep-sanctions`,
  applicantProfileRelatedOrganisations: `/new/entities/profile/:${params.applicantId}/related-orgs`,
  iKybAmlScreening: `/new/entities/profile/:${params.applicantId}/aml-screening`, // not in vue
  applicantManualIdv: `/new/entities/profile/:${params.applicantId}/manual-idv`,
  applicantEditBusiness: `/new/entities/profile/:${params.applicantId}/edit-business`,
  applicantProfileWorkflowEvent: `/new/entities/profile/:${params.applicantId}/workflow-event`,
  individualProfileInsight: `/new/entities/profile/:${params.applicantId}/profile-insight`,
  searchResultsNotification: '/notification-results',
}

const f2Paths = {
  individualAmlHistory: `/f2/individual/:${params.applicantId}/aml-history`,
  editEntity: `/f2/individual/:${params.applicantId}/edit`,
  resolveEntity: `/f2/individual/:${params.applicantId}/resolve`,
  resolveAmlIssue: `/f2/individual/:${params.applicantId}/resolve-aml-issue`,
  amlScreening: `/f2/individual/:${params.applicantId}/aml-screening`,
}

const applicantGeneralInfoPath = {
  fallbackValue: paths.applicant_profile_general_information,
  protectedValue: newPaths.applicantProfileGeneralInformation,
  reactMigrationFlag:
    Vue2ReactMigrationFlagTypes.ReactApplicantProfileGeneralInformation,
}

const getApplicantPathParams = (applicantId: ApplicantId) => ({
  [params.applicantId]: applicantId,
})

const UnAuthorizedLayout = Route
const SettingsNavLayout = Route
const DefaultLayout = Route
const AnalyticsNavLayout = Route
const ProfileLayout = Route
const ApplicantProfileLayout = Route

export const CreatedRouter = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route
        path={paths.root}
        element={
          <Redirect
            to={{
              protectedValue: paths.applicants,
              fallbackValue: paths.onboarding,
              featureFlags: ['verticalNavBar'],
            }}
          />
        }
      />
      <UnAuthorizedLayout element={<LayoutAuth />}>
        <Route
          path={paths.login}
          element={
            <LoginPage
              applicantsPath={paths.applicants}
              onboardingPath={paths.onboarding}
              passwordResetPath={paths.reset_password}
            />
          }
        />
        <Route
          path={paths.reset_password_sent}
          element={
            <PasswordResetSentPage
              loginPath={paths.login}
              errorPath={paths.reset_password}
            />
          }
        />
        <Route
          path={paths.reset_password}
          element={
            <PasswordResetPage
              loginPath={paths.login}
              afterResetPath={paths.reset_password}
            />
          }
        />
        <Route
          path={paths.new_password}
          element={
            <NewPasswordPage
              loginPath={paths.login}
              expiredTokenPath={`${paths.reset_password}?${EXPIRED_URL_PARAM}=true`}
            />
          }
        />
        {/* TODO: @ggrigorev NAV fallback for 404 */}
        {/* <Route path="*" element={<Navigate to={paths.login} replace />} /> */}
      </UnAuthorizedLayout>
      <DefaultLayout
        element={
          <LayoutPortal
            searchResultsPath={paths.search_results}
            loginPath={paths.login}
            applicantsPath={paths.applicants}
            auxiliaryApplicantPath={newPaths.applicant}
            needsReviewPath={paths.needs_review}
            blocklistPath={{
              protectedValue: newPaths.blocklist,
              fallbackValue: paths.blocklist,
              reactMigrationFlag:
                Vue2ReactMigrationFlagTypes.ReactBlocklistPage,
            }}
            searchResultsNotificationPath={newPaths.searchResultsNotification}
            analyticsPath={{
              protectedValue: newPaths.dashboardNew,
              fallbackValue: paths.dashboard,
              reactMigrationFlag:
                Vue2ReactMigrationFlagTypes.ReactDashboardPage,
            }}
            newProfilePath={{
              protectedValue: newPaths.individualProfile,
              fallbackValue: paths.new_profile,
              reactMigrationFlag:
                Vue2ReactMigrationFlagTypes.ReactIndividualProfilePage,
            }}
            settingsPath={paths.settings_personal}
            applicantPath={{
              fallbackValue: paths.applicant_profile,
              protectedValue: newPaths.applicantProfile,
              reactMigrationFlag:
                Vue2ReactMigrationFlagTypes.ReactApplicantProfileGeneralInformation,
            }}
            getApplicantPathParams={getApplicantPathParams}
          />
        }
      >
        <SettingsNavLayout
          element={
            <LayoutSettings
              settingsNotificationsPath={paths.settings_notifications}
              settingsAccountPath={paths.settings_organisation}
              settingsPersonalPath={paths.settings_personal}
              settingsRolesPath={paths.settings_role_list}
              settingsUsersPath={paths.settings_user_list}
            />
          }
        >
          <Route
            path={paths.settings_personal}
            element={
              <PrivateRoute loginPath={paths.login}>
                <SettingsPersonalPage />
              </PrivateRoute>
            }
          />
          <Route
            path={paths.settings_notifications}
            element={
              <PrivateRoute loginPath={paths.login}>
                <SettingsNotificationsPage />
              </PrivateRoute>
            }
          />
          <Route
            path={paths.settings_organisation}
            element={
              <PrivateRoute loginPath={paths.login}>
                <PermissionGuard
                  permissions={PermissionTypes.OrganisationSettingsFetch}
                >
                  <SettingsOrganisationPage />
                </PermissionGuard>
              </PrivateRoute>
            }
          />
          <Route
            path={paths.settings_role_list}
            element={
              <PrivateRoute loginPath={paths.login}>
                <PermissionGuard permissions={PermissionTypes.RoleIndexPage}>
                  <SettingsRoleListPage
                    newRolePath={paths.settings_role_new}
                    getRoleViewPath={(roleId: RoleId) =>
                      generatePath(paths.settings_role_view, { roleId })
                    }
                    getRoleEditPath={(roleId: RoleId) =>
                      generatePath(paths.settings_role_edit, { roleId })
                    }
                  />
                </PermissionGuard>
              </PrivateRoute>
            }
          />
          <Route
            path={paths.settings_user_list}
            element={
              <PrivateRoute loginPath={paths.login}>
                <PermissionGuard permissions={PermissionTypes.UserIndex}>
                  <SettingsUserListPage />
                </PermissionGuard>
              </PrivateRoute>
            }
          />
        </SettingsNavLayout>
        <ProfileLayout
          element={
            <LayoutNewProfile
              paths={{
                individualPath: {
                  protectedValue: newPaths.individualProfile,
                  fallbackValue: paths.new_profile_individual,
                  reactMigrationFlag:
                    Vue2ReactMigrationFlagTypes.ReactIndividualProfilePage,
                },
                businessProfile: paths.new_profile_business,
              }}
            />
          }
        >
          <Route
            path={newPaths.individualProfile}
            element={
              <PrivateRoute loginPath={paths.login}>
                <IndividualProfile
                  applicantPersonalInfoPathF2={
                    newPaths.individualProfileInsight
                  }
                  applicantGeneralInfoPath={applicantGeneralInfoPath}
                  applicantGeneralInfoPathF2={
                    newPaths.applicantProfileWorkflowEvent
                  }
                  getApplicantPathParams={getApplicantPathParams}
                />
              </PrivateRoute>
            }
          />

          <Route
            path={paths.new_profile_business}
            element={
              <PrivateRoute loginPath={paths.login}>
                <PermissionGuard permissions={PermissionTypes.ApplicantNew}>
                  <NewProfilePage />
                </PermissionGuard>
              </PrivateRoute>
            }
          />
        </ProfileLayout>

        <Route
          path={paths.settings_role_view}
          element={
            <PrivateRoute loginPath={paths.login}>
              <PermissionGuard
                permissions={PermissionTypes.RolePermissionMappingPage}
              >
                <SettingsRoleViewPage
                  roleListPath={paths.settings_role_list}
                  roleIdParamKey={params.roleId}
                  getRoleEditPath={(roleId: RoleId) => ({
                    path: generatePath(paths.settings_role_edit, { roleId }),
                    state: { fromRoleView: true },
                  })}
                />
              </PermissionGuard>
            </PrivateRoute>
          }
        />
        <Route
          path={paths.settings_role_edit}
          element={
            <PrivateRoute loginPath={paths.login}>
              <PermissionGuard permissions={PermissionTypes.RoleEditPage}>
                <SettingsRoleEditPage
                  roleListPath={paths.settings_role_list}
                  roleIdParamKey={params.roleId}
                  getCancelPathFromState={(state, roleId: RoleId) => {
                    if (
                      state &&
                      state.fromRoleView &&
                      typeof state.fromRoleView === 'boolean' &&
                      state.fromRoleView &&
                      roleId
                    ) {
                      return generatePath(paths.settings_role_view, { roleId })
                    }
                    return paths.settings_role_list
                  }}
                />
              </PermissionGuard>
            </PrivateRoute>
          }
        />
        <Route
          path={paths.settings_role_new}
          element={
            <PrivateRoute loginPath={paths.login}>
              <PermissionGuard permissions={PermissionTypes.RoleNewPage}>
                <SettingsRoleNewPage roleListPath={paths.settings_role_list} />
              </PermissionGuard>
            </PrivateRoute>
          }
        />

        <Route
          path={paths.monitoring_id}
          element={
            <Navigate to="./general-information" replace relative="route" />
          }
        />

        <Route
          path={paths.onboarding_id}
          element={
            <Navigate to="./general-information" replace relative="route" />
          }
        />

        <Route
          path={paths.monitoring_id_general_information}
          element={
            <PrivateRoute loginPath={paths.login}>
              <PermissionGuard
                permissions={[
                  PermissionTypes.MonitoringCaseManagementData,
                  PermissionTypes.MonitoringIndex,
                ]}
              >
                <InternationalProfilePage
                  applicantIdParamKey={params.applicantId}
                />
              </PermissionGuard>
            </PrivateRoute>
          }
        />

        <Route
          path={paths.onboarding_id_general_information}
          element={
            <PrivateRoute loginPath={paths.login}>
              <PermissionGuard
                permissions={[
                  PermissionTypes.MonitoringCaseManagementData,
                  PermissionTypes.MonitoringIndex,
                ]}
              >
                <InternationalProfilePage
                  applicantIdParamKey={params.applicantId}
                />
              </PermissionGuard>
            </PrivateRoute>
          }
        />

        <Route
          path={paths.applicants}
          element={
            <PrivateRoute loginPath={paths.login}>
              {/* <PermissionGuard */}
              {/*   // TODO: @ggrigorev filters! add permissions */}
              {/*   permissions={[]} */}
              {/* > */}
              <ApplicantsListPage
                applicantProfileInsightPath={newPaths.individualProfileInsight}
                applicantGeneralInfoPath={applicantGeneralInfoPath}
                applicantGeneralInfoPathf2={
                  newPaths.applicantProfileWorkflowEvent
                }
                getApplicantPathParams={getApplicantPathParams}
              />
              {/* </PermissionGuard> */}
            </PrivateRoute>
          }
        />

        <Route
          path={paths.customView}
          element={
            <PrivateRoute loginPath={paths.login}>
              <CustomViewPage
                applicantProfileInsightPath={newPaths.individualProfileInsight}
                applicantGeneralInfoPath={applicantGeneralInfoPath}
                applicantGeneralInfoPathf2={
                  newPaths.applicantProfileWorkflowEvent
                }
                getApplicantPathParams={getApplicantPathParams}
              />
            </PrivateRoute>
          }
        />

        <AnalyticsNavLayout element={<LayoutAnalytics />}>
          <Route
            path={newPaths.dashboardNew}
            element={
              <PrivateRoute loginPath={paths.login}>
                <PermissionGuard
                  permissions={PermissionTypes.ReportingDashboard}
                >
                  <DashboardPage />
                </PermissionGuard>
              </PrivateRoute>
            }
          />
        </AnalyticsNavLayout>

        <ApplicantProfileLayout
          element={
            <LayoutApplicantProfile
              applicantIdParamKey={params.applicantId}
              paths={{
                generalInformation: {
                  protectedValue: newPaths.applicantProfileGeneralInformation,
                  fallbackValue: paths.applicant_profile_general_information,
                  reactMigrationFlag:
                    Vue2ReactMigrationFlagTypes.ReactApplicantProfileGeneralInformation,
                },

                supportingDocuments: {
                  protectedValue: newPaths.applicantProfileSupportingDocuments,
                  fallbackValue: paths.applicantProfileSupportingDocuments,
                  reactMigrationFlag:
                    Vue2ReactMigrationFlagTypes.ReactApplicantProfileSupportingDocuments,
                },

                auditReport: {
                  protectedValue: newPaths.applicantProfileAuditReport,
                  fallbackValue: paths.applicantProfileAuditReport,
                  reactMigrationFlag:
                    Vue2ReactMigrationFlagTypes.ReactApplicantProfileAuditReport,
                },

                blocklistMatches: {
                  protectedValue: newPaths.applicantProfileBlocklistMatches,
                  fallbackValue: paths.applicantProfileBlocklistMatches,
                  reactMigrationFlag:
                    Vue2ReactMigrationFlagTypes.ReactApplicantProfileBlocklistMatches,
                },

                duplicateMatches: {
                  protectedValue: newPaths.applicantProfileDuplicateMatches,
                  fallbackValue: paths.applicantProfileDuplicateMatches,
                  reactMigrationFlag:
                    Vue2ReactMigrationFlagTypes.ReactApplicantProfileDuplicates,
                },

                potentialMatches: {
                  protectedValue: newPaths.applicantProfilePotentialMatches,
                  fallbackValue: paths.applicantProfilePotentialMatches,
                  reactMigrationFlag:
                    Vue2ReactMigrationFlagTypes.ReactApplicantProfilePotentialMatches,
                },

                transactions: {
                  protectedValue: newPaths.applicantProfileTransactions,
                  fallbackValue: paths.applicantProfileTransactions,
                  reactMigrationFlag:
                    Vue2ReactMigrationFlagTypes.ReactApplicantProfileTransactions,
                },

                riskIndicators: {
                  protectedValue: newPaths.applicantProfileRiskIndicators,
                  fallbackValue: paths.applicantProfileRiskIndicators,
                  reactMigrationFlag:
                    Vue2ReactMigrationFlagTypes.ReactApplicantProfileRiskIndicators,
                },

                personalInfoKyc: {
                  protectedValue: newPaths.applicantProfilePersonalInfo,
                  fallbackValue: paths.applicantProfilePersonalInfo,
                  reactMigrationFlag:
                    Vue2ReactMigrationFlagTypes.ReactApplicantProfilePersonalInfo,
                },

                businessOwnership: {
                  protectedValue: newPaths.applicantProfileOwnersOfficeholders,
                  fallbackValue: paths.applicantProfileOwnersOfficeholders,
                  reactMigrationFlag:
                    Vue2ReactMigrationFlagTypes.ReactApplicantProfileOwnersOfficeholders,
                },

                characteristics: {
                  protectedValue: newPaths.applicantProfileCharacteristics,
                  fallbackValue: paths.applicantProfileCharacteristics,
                  reactMigrationFlag:
                    Vue2ReactMigrationFlagTypes.ReactApplicantProfileCharacteristics,
                },

                biometryOcr: {
                  protectedValue: newPaths.applicantProfileBiometricsOcr,
                  fallbackValue: paths.applicantProfileBiometricsOcr,
                  reactMigrationFlag:
                    Vue2ReactMigrationFlagTypes.ReactApplicantProfileBiometricsOcr,
                },

                fraudCheck: {
                  protectedValue: newPaths.applicantProfileFraudCheck,
                  fallbackValue: paths.applicantProfileFraudCheck,
                  reactMigrationFlag:
                    Vue2ReactMigrationFlagTypes.ReactApplicantProfileFraudCheck,
                },

                blocklistedInfo: {
                  protectedValue: newPaths.applicantProfileBlocklistedInfo,
                  fallbackValue: paths.applicantProfileBlocklistedInfo,
                  reactMigrationFlag:
                    Vue2ReactMigrationFlagTypes.ReactApplicantProfileBlocklistedInfo,
                },

                pepSanctions: {
                  protectedValue: newPaths.applicantProfilePepSanctions,
                  fallbackValue: paths.applicantProfilePepSanctions,
                  reactMigrationFlag:
                    Vue2ReactMigrationFlagTypes.ReactApplicantProfilePepSanctions,
                },

                manualIdv: {
                  protectedValue: newPaths.applicantManualIdv,
                  fallbackValue: paths.applicantManualIdv,
                  reactMigrationFlag:
                    Vue2ReactMigrationFlagTypes.ReactApplicantManualIdv,
                },

                editBusiness: {
                  protectedValue: newPaths.applicantEditBusiness,
                  fallbackValue: paths.applicantEditBusiness,
                  reactMigrationFlag:
                    Vue2ReactMigrationFlagTypes.ReactApplicantEditBusiness,
                },

                relatedOrganisations:
                  newPaths.applicantProfileRelatedOrganisations,

                iKybAmlScreening: newPaths.iKybAmlScreening,

                workflowEvents: newPaths.applicantProfileWorkflowEvent,
                profileInsight: newPaths.individualProfileInsight,
                editEntity: f2Paths.editEntity,
                resolveEntity: f2Paths.resolveEntity,
                amlHistory: f2Paths.individualAmlHistory,
                resolveAmlIssue: f2Paths.resolveAmlIssue,
                amlScreening: f2Paths.amlScreening,
              }}
            />
          }
        >
          {/* vue routes under new layout */}
          <Route path="/entities/profile/:applicantId/*" element={<div />} />

          <Route
            path={newPaths.applicantProfileGeneralInformation}
            element={
              <PrivateRoute loginPath={paths.login}>
                <ApplicantGeneralInformationPage
                  applicantIdParamKey={params.applicantId}
                />
              </PrivateRoute>
            }
          />

          <Route
            path={newPaths.applicantProfileSupportingDocuments}
            element={
              <PrivateRoute loginPath={paths.login}>
                <ApplicantProfileSupportingDocuments
                  applicantIdParamKey={params.applicantId}
                />
              </PrivateRoute>
            }
          />

          <Route
            path={newPaths.applicantProfileBlocklistMatches}
            element={
              <PrivateRoute loginPath={paths.login}>
                <ApplicantBlocklistPage
                  applicantIdParamKey={params.applicantId}
                />
              </PrivateRoute>
            }
          />
          <Route
            path={newPaths.applicantProfileDuplicateMatches}
            element={
              <PrivateRoute loginPath={paths.login}>
                <ApplicantDuplicatePage
                  applicantIdParamKey={params.applicantId}
                />
              </PrivateRoute>
            }
          />
          <Route
            path={newPaths.applicantProfilePotentialMatches}
            element={
              <PrivateRoute loginPath={paths.login}>
                <ApplicantPotentialMatchesPage
                  applicantIdParamKey={params.applicantId}
                />
              </PrivateRoute>
            }
          />
          <Route
            path={newPaths.applicantProfilePersonalInfo}
            element={
              <PrivateRoute loginPath={paths.login}>
                <ApplicantPersonalInfoKycPage
                  applicantIdParamKey={params.applicantId}
                />
              </PrivateRoute>
            }
          />

          <Route
            path={newPaths.applicantProfileAuditReport}
            element={
              <PrivateRoute loginPath={paths.login}>
                <ApplicantAuditReportPage
                  applicantIdParamKey={params.applicantId}
                />
              </PrivateRoute>
            }
          />

          <Route
            path={newPaths.applicantProfileTransactions}
            element={
              <PrivateRoute loginPath={paths.login}>
                <ApplicantTransactionsPage
                  applicantIdParamKey={params.applicantId}
                />
              </PrivateRoute>
            }
          />

          <Route
            path={newPaths.applicantProfileCharacteristics}
            element={
              <PrivateRoute loginPath={paths.login}>
                <ApplicantCustomerCharacteristicsPage
                  applicantIdParamKey={params.applicantId}
                />
              </PrivateRoute>
            }
          />

          <Route
            path={newPaths.applicantProfileRiskIndicators}
            element={
              <PrivateRoute loginPath={paths.login}>
                <ApplicantRiskScorePage
                  applicantIdParamKey={params.applicantId}
                />
              </PrivateRoute>
            }
          />

          <Route
            path={newPaths.applicantProfileOwnersOfficeholders}
            element={
              <PrivateRoute loginPath={paths.login}>
                <ApplicantBusinessOwnershipPage
                  applicantIdParamKey={params.applicantId}
                />
              </PrivateRoute>
            }
          />

          <Route
            path={newPaths.applicantProfileBlocklistedInfo}
            element={
              <PrivateRoute loginPath={paths.login}>
                <ApplicantBlocklistedInfoPage
                  applicantIdParamKey={params.applicantId}
                  applicantGeneralInfoPath={applicantGeneralInfoPath}
                  getApplicantPathParams={getApplicantPathParams}
                />
              </PrivateRoute>
            }
          />

          <Route
            path={newPaths.applicantProfileBiometricsOcr}
            element={
              <PrivateRoute loginPath={paths.login}>
                <ApplicantBiometricsOcrPage
                  applicantIdParamKey={params.applicantId}
                />
              </PrivateRoute>
            }
          />

          <Route
            path={newPaths.applicantProfileFraudCheck}
            element={
              <PrivateRoute loginPath={paths.login}>
                <ApplicantFraudCheckPage
                  applicantIdParamKey={params.applicantId}
                />
              </PrivateRoute>
            }
          />

          <Route
            path={newPaths.applicantProfilePepSanctions}
            element={
              <PrivateRoute loginPath={paths.login}>
                <ApplicantPepSactionsPage
                  applicantIdParamKey={params.applicantId}
                />
              </PrivateRoute>
            }
          />

          <Route
            path={newPaths.applicantProfileRelatedOrganisations}
            element={
              <PrivateRoute loginPath={paths.login}>
                <ApplicantRelatedOrganisationsPage
                  applicantIdParamKey={params.applicantId}
                />
              </PrivateRoute>
            }
          />

          <Route
            path={newPaths.iKybAmlScreening}
            element={
              <PrivateRoute loginPath={paths.login}>
                <IKybAmlScreeningPage
                  applicantIdParamKey={params.applicantId}
                />
              </PrivateRoute>
            }
          />

          <Route
            path={newPaths.applicantManualIdv}
            element={
              <PrivateRoute loginPath={paths.login}>
                <ManualIdvPage
                  applicantGeneralInfoPath={applicantGeneralInfoPath}
                  getApplicantPathParams={getApplicantPathParams}
                  applicantIdParamKey={params.applicantId}
                />
              </PrivateRoute>
            }
          />

          <Route
            path={newPaths.applicantProfileWorkflowEvent}
            element={
              <PrivateRoute loginPath={paths.login}>
                <ApplicantWorkflowEventsPage
                  editEntityPath={f2Paths.resolveEntity}
                  applicantIdParamKey={params.applicantId}
                />
              </PrivateRoute>
            }
          />

          <Route
            path={newPaths.individualProfileInsight}
            element={
              <PrivateRoute loginPath={paths.login}>
                <IndividualProfileInsightPage
                  editEntityPath={f2Paths.resolveEntity}
                  applicantIdParamKey={params.applicantId}
                />
              </PrivateRoute>
            }
          />

          <Route
            path={newPaths.applicantEditBusiness}
            element={
              <PrivateRoute loginPath={paths.login}>
                <EditBusinessPage
                  applicantGeneralInfoPath={applicantGeneralInfoPath}
                  getApplicantPathParams={getApplicantPathParams}
                  applicantIdParamKey={params.applicantId}
                />
              </PrivateRoute>
            }
          />
        </ApplicantProfileLayout>

        <Route
          path={f2Paths.editEntity}
          element={
            <PrivateRoute loginPath={paths.login}>
              <EditEntityPage
                applicantIdParamKey={params.applicantId}
                applicantGeneralInfoPath={
                  newPaths.applicantProfileWorkflowEvent
                }
                getApplicantPathParams={getApplicantPathParams}
              />
            </PrivateRoute>
          }
        />
        <Route
          path={f2Paths.resolveEntity}
          element={
            <PrivateRoute loginPath={paths.login}>
              <ResolveEntityPage
                applicantIdParamKey={params.applicantId}
                applicantGeneralInfoPath={
                  newPaths.applicantProfileWorkflowEvent
                }
                getApplicantPathParams={getApplicantPathParams}
              />
            </PrivateRoute>
          }
        />
        <Route
          path={f2Paths.individualAmlHistory}
          element={
            <PrivateRoute loginPath={paths.login}>
              <IndividualAmlHistoryPage
                applicantIdParamKey={params.applicantId}
              />
            </PrivateRoute>
          }
        />
        <Route
          path={f2Paths.resolveAmlIssue}
          element={
            <PrivateRoute loginPath={paths.login}>
              <IndividualAmlResultPage
                applicantIdParamKey={params.applicantId}
              />
            </PrivateRoute>
          }
        />
        <Route
          path={f2Paths.amlScreening}
          element={
            <PrivateRoute loginPath={paths.login}>
              <IndividualAmlScreeningPage
                applicantIdParamKey={params.applicantId}
              />
            </PrivateRoute>
          }
        />

        <Route
          path={newPaths.blocklist}
          element={
            <PrivateRoute loginPath={paths.login}>
              <BlocklistPage
                addNewBlocklistPath={{
                  fallbackValue: paths.new_blocklist,
                  protectedValue: newPaths.applicantNewBlocklist,
                  reactMigrationFlag:
                    Vue2ReactMigrationFlagTypes.ReactApplicantProfileBlocklistedInfo,
                }}
                applicantGeneralInfoPath={applicantGeneralInfoPath}
                getApplicantPathParams={getApplicantPathParams}
              />
            </PrivateRoute>
          }
        />

        <Route
          path={newPaths.applicantNewBlocklist}
          element={
            <PrivateRoute loginPath={paths.login}>
              <AddToBlocklistPage
                applicantGeneralInfoPath={applicantGeneralInfoPath}
                getApplicantPathParams={getApplicantPathParams}
              />
            </PrivateRoute>
          }
        />

        <Route
          path={paths.needs_review}
          element={
            <PrivateRoute loginPath={paths.login}>
              {/* <PermissionGuard permissions={[PermissionTypes.ApplicantsPage]}> */}
              <NeedsReviewPage
                applicantGeneralInfoPathf2={
                  newPaths.applicantProfileWorkflowEvent
                }
                applicantProfileInsightPath={newPaths.individualProfileInsight}
                applicantGeneralInfoPath={applicantGeneralInfoPath}
                getApplicantPathParams={getApplicantPathParams}
              />
              {/* </PermissionGuard> */}
            </PrivateRoute>
          }
        />

        <Route
          path={paths.search_results}
          element={
            <PrivateRoute loginPath={paths.login}>
              {/* <PermissionGuard permissions={[PermissionTypes.ApplicantsPage]}> */}
              <SearchResultsPage
                applicantWorkflowEventPath={
                  newPaths.applicantProfileWorkflowEvent
                }
                applicantProfileInsightPath={newPaths.individualProfileInsight}
                applicantGeneralInfoPath={applicantGeneralInfoPath}
                getApplicantPathParams={getApplicantPathParams}
              />
              {/* </PermissionGuard> */}
            </PrivateRoute>
          }
        />

        <Route
          path={newPaths.searchResultsNotification}
          element={
            <PrivateRoute loginPath={paths.login}>
              <SearchResultsNotificationPage
                applicantWorkflowEventPath={
                  newPaths.applicantProfileWorkflowEvent
                }
                applicantProfileInsightPath={newPaths.individualProfileInsight}
                applicantGeneralInfoPath={applicantGeneralInfoPath}
                getApplicantPathParams={getApplicantPathParams}
              />
            </PrivateRoute>
          }
        />
        <Route
          path={paths.applicant_profile}
          element={
            <Navigate to="./general-information" replace relative="route" />
          }
        />

        <Route
          path={newPaths.applicantProfile}
          element={
            <Navigate to="./general-information" replace relative="route" />
          }
        />

        <Route path="*" element={<div />} />
      </DefaultLayout>
    </>,
  ),
)
