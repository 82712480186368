import React from 'react'

import { FrankieButton, FrankieLoader } from 'frankify/src'

import { ORGANISATION_SEARCH_KEY } from 'features/organisation-search/organisation-search.key'

import { useApplicantSingleSpaOverviewPath } from 'entities/applicant'
import { useGenerateReportMutation } from 'entities/organisation'

import { useI18n } from 'shared/i18n'
import { notification } from 'shared/notification'
import { Noop } from 'shared/typescript'

import { ABRCompany } from '../../model/abr-search-model'

type Props = {
  onClose: Noop
  abrOrganisation: Partial<ABRCompany>
}

export function SingleReportConfirmationModal({
  abrOrganisation,
  onClose,
}: Props) {
  const t = useI18n([ORGANISATION_SEARCH_KEY])

  const { mutateAsync: generateReport, isLoading: isGeneratingReport } =
    useGenerateReportMutation()

  const { navigateToOverview } = useApplicantSingleSpaOverviewPath()

  const handleClickSingleLevelBusinessOwnership = async () => {
    try {
      const {
        data: { entityId },
      } = await generateReport({
        body: {
          businessProfile: 'organisation',
          ABNNumber: abrOrganisation.abn ?? undefined,
          ACNNumber: abrOrganisation.acn ?? undefined,
          businessType: abrOrganisation.type ?? undefined,
          businessName: abrOrganisation.name,
        },
        reportType: 'SINGLE-LEVEL-AML',
      })

      navigateToOverview(entityId)

      onClose()
    } catch (error) {
      notification.error(t('errorCreatingProfileAUS'))
    }
  }

  return (
    <FrankieLoader loading={isGeneratingReport}>
      <span className="block text-tertiary-grey-800 text-xl font-bold">
        {t('singleReportModal.title')}
      </span>

      <span className="block text-tertiary-grey-700 text-sm font-normal mt-2">
        {t('singleReportModal.description')}
      </span>
      <div className="flex flex-row justify-end mt-6">
        <FrankieButton intent="subtle" onClick={onClose}>
          {t('modalButtons.cancel')}
        </FrankieButton>
        <FrankieButton
          intent="primary"
          className="ml-2"
          onClick={handleClickSingleLevelBusinessOwnership}
        >
          {t('modalButtons.generateReport')}
        </FrankieButton>
      </div>
    </FrankieLoader>
  )
}
