import React from 'react'

import {
  ApplicantsFilterForm,
  IApplicantsGlobalFilter,
  IPartialApplicantsFilter,
} from 'features/applicants-filter'
import { useUserListQuery } from 'features/user-management'

import { ApplicantEntityStatusTypes } from 'entities/applicant'
import { PermissionTypes } from 'entities/role'
import { useHasPermission } from 'entities/session'

type Props = {
  onClose: () => void
  initialValues: IApplicantsGlobalFilter
  onSetFilters: (filter: IPartialApplicantsFilter) => void
  statusesOptionCols: ApplicantEntityStatusTypes[][]
}

export function ApplicantsFilterModal({
  statusesOptionCols,
  onClose,
  onSetFilters,
  initialValues,
}: Props) {
  const { canFetchUserList } = useHasPermission({
    canFetchUserList: PermissionTypes.UserList,
  })
  const { data: userList } = useUserListQuery({ canFetchUserList })

  return (
    <div className="max-h-[calc(100vh-120px)]">
      <ApplicantsFilterForm
        statusesOptionCols={statusesOptionCols}
        users={userList || []}
        onClose={onClose}
        onSubmit={onSetFilters}
        initialValues={initialValues}
      />
    </div>
  )
}
