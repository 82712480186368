import React, { useEffect } from 'react'

import { useNavigate } from 'react-router-dom'

import { FrankieButton, FrankieIcon } from 'frankify/src'

import { useApplicantRoute } from 'entities/routing'
import { useHasFeatureFlag } from 'entities/session'

import { useI18n } from 'shared/i18n'
import { useOverlay } from 'shared/overlay'

import { CUSTOM_VIEWS_KEYS } from '../../custom-view.key'
import { customViewEn } from '../../locale/custom-view.en'
import {
  customViewDeleteFormQa,
  deleteCustomViewButtonQa,
} from '../../qa/custom-view.qa'
import { useCustomViewData } from '../../states/custom-view-state/custom-view.state'
import { useDeleteCustomView } from '../../states/mutation/delete-custom-view/delete-custom-view.mutation'
import { CustomViewLoader } from '../custom-view-loader/custom-view-loader'

type PropType = {
  togglePopover: (value: boolean) => void
}

type Props = {
  closeOverlay: () => void
  handleNavigate: () => void
}

export function CustomViewDeleteForm({ closeOverlay, handleNavigate }: Props) {
  const t = useI18n([CUSTOM_VIEWS_KEYS], { keys: customViewEn })
  const { isLoading, isSuccess, mutate } = useDeleteCustomView()
  const { hasCustomViews } = useHasFeatureFlag({
    hasCustomViews: 'customViews',
  })

  useEffect(() => {
    if (isSuccess) {
      handleNavigate()
      closeOverlay()
    }
  }, [isSuccess])

  if (!hasCustomViews) return null

  return (
    <>
      <CustomViewLoader
        text={t('loading.onDelete')}
        loader={isLoading}
        testId={{
          container: customViewDeleteFormQa.loaderContainer,
          loader: customViewDeleteFormQa.loader,
        }}
      />

      <div
        className="w-[448px] flex flex-col items-center gap-4"
        data-qa={customViewDeleteFormQa.container}
      >
        <div className="flex flex-col gap-[14px] items-center">
          <FrankieIcon
            name="mdiAlertCircleOutline"
            className="text-tertiary-red-700"
            size="lg"
            testId={{ icon: customViewDeleteFormQa.alertIcon }}
          />
          <div
            className="text-md leading-6 text-tertiary-grey-700"
            data-qa={customViewDeleteFormQa.titleContainer}
          >
            {t('deleteModal.title')}
          </div>
        </div>
        <div className="flex gap-4">
          <FrankieButton
            className="px-5 py-2  text-tertiary-grey-700 border-solid border border-tertiary-grey-200  font-semibold text-sm rounded-sm"
            onClick={closeOverlay}
            noStyles
            testId={{ button: customViewDeleteFormQa.cancelBtn }}
          >
            {t('deleteModal.cancel')}
          </FrankieButton>
          <FrankieButton
            className="px-5 py-2 bg-danger cursor-pointer text-mono-white font-semibold text-sm rounded-sm"
            onClick={() => {
              mutate(window.location.pathname.split('/')[2])
            }}
            noStyles
            testId={{ button: customViewDeleteFormQa.deleteBtn }}
          >
            {t('deleteModal.confirm')}
          </FrankieButton>
        </div>
      </div>
    </>
  )
}

export function CustomViewDeleteButton({ togglePopover }: PropType) {
  const t = useI18n([CUSTOM_VIEWS_KEYS], { keys: customViewEn })
  const { currentView } = useCustomViewData()
  const [createOverlay, closeOverlay] = useOverlay()
  const navigate = useNavigate()
  const { generateRoute } = useApplicantRoute()

  const handleNavigate = () => {
    navigate(generateRoute({ routeKey: 'applicants' }))
  }

  const handleDeleteView = () => {
    togglePopover(false)
    createOverlay(
      <CustomViewDeleteForm
        closeOverlay={closeOverlay}
        handleNavigate={handleNavigate}
      />,
      {
        closeButtonClassName: '!top-4 !right-[18px]',
        className: '!pt-[40px] !pb-8',
      },
    )
  }

  if (!currentView) return null
  return (
    <div
      className="bg-mono-white shadow-md"
      data-qa={deleteCustomViewButtonQa.container}
    >
      <FrankieButton
        className="px-5 py-2 hover:bg-tertiary-grey-200 select-none cursor-pointer w-full text-start"
        onClick={handleDeleteView}
        noStyles
        testId={{ button: deleteCustomViewButtonQa.btn }}
      >
        {t('deleteView')}
      </FrankieButton>
    </div>
  )
}
