import React from 'react'

import { Typography } from '@mui/material'
import type {
  GridRenderCellParams,
  GridRowModel,
  GridValidRowModel,
} from '@mui/x-data-grid-pro'

import { FrankieTooltip } from 'frankify/src'

export function ApplicantRelatedOrganisationsNameCell(
  props: GridRenderCellParams<GridValidRowModel, string>,
) {
  const name = props.value ?? ''
  const isTruncated = name.length > 70

  return isTruncated ? (
    <FrankieTooltip body={name}>
      <Typography
        className="truncate font-semibold text-tertiary-grey-700 w-full"
        noWrap
      >
        {name}
      </Typography>
    </FrankieTooltip>
  ) : (
    <Typography className="font-semibold text-tertiary-grey-700" noWrap>
      {name}
    </Typography>
  )
}

export function ApplicantRelatedOrganisationsRelationCell(
  props: GridRenderCellParams<GridRowModel, string[]>,
) {
  const relations = props.value ?? []
  return relations.map(relation => {
    const isTruncated = relation.length > 70
    const key = `${props.id}-${relation.replace(/\s/g, '').toLowerCase()}`

    return isTruncated ? (
      <FrankieTooltip key={key} body={relation}>
        <p className="MuiDataGrid-cellContent truncate w-full">{relation}</p>
      </FrankieTooltip>
    ) : (
      <p className="MuiDataGrid-cellContent" key={key}>
        {relation}
      </p>
    )
  })
}
