import { useQuery } from '@tanstack/react-query'

import { CountryAlpha3CodeTypes, countryPhoneCodeList } from 'entities/country'
import { DocumentIdTypes } from 'entities/entity'

import { isNotEmptyString } from 'shared/typescript'

import { individualApiF2 } from '../api/individual-f2.api'
import { SUPPORTED_COUNTRIES } from '../model/individual-profile-f2-form.model'

export const GetIndividualAddressQueryKey = 'get-individual-address-f2'

const EDITABLE_DOCUMENTS = [
  DocumentIdTypes.DRIVERS_LICENSE,
  DocumentIdTypes.PASSPORT,
  DocumentIdTypes.NATIONAL_ID,
  DocumentIdTypes.NATIONAL_HEALTH_ID,
]

export const useGetIndividualFormData = (entityId?: string) =>
  useQuery({
    queryKey: [GetIndividualAddressQueryKey, entityId],
    queryFn: async () => {
      if (!entityId) return undefined
      const { data } = await individualApiF2.getIndividualFormValue(entityId)

      data.documents = data.documents.filter(
        doc =>
          EDITABLE_DOCUMENTS.includes(doc.type as DocumentIdTypes) &&
          SUPPORTED_COUNTRIES.includes(doc.country as CountryAlpha3CodeTypes),
      )

      if (data.phoneNumbers) {
        data.phoneNumbers = data.phoneNumbers.map(phone => {
          if (!phone.number || !phone.country) return phone

          const countryCode = countryPhoneCodeList.find(
            c => c.code === phone.country,
          )
          if (!countryCode) return phone

          // Remove country code prefix if present
          let { number } = phone
          if (number.startsWith(`+${countryCode.value}`)) {
            number = number.slice(countryCode.value.length + 1)
          }

          return {
            ...phone,
            number,
          }
        })
      }

      return data
    },
    enabled: isNotEmptyString(entityId),
  })
