import React, { useEffect, useMemo, useState } from 'react'

import { AxiosError } from 'axios'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Trans } from 'react-i18next'

import {
  FrankieAlert,
  FrankieButton,
  FrankieLoader,
  FrankieTextField,
} from 'frankify/src'

import { MfaTypes } from 'entities/mfa'

import { ErrorCodeTypes } from 'shared/error'
import { useI18n } from 'shared/i18n'
import { notification } from 'shared/notification'
import { Nullable, Unknownable } from 'shared/typescript'

import { MFA_KEY } from '../../mfa.key'
import {
  getMfaCodeRegisterOptions,
  getResendCodeAttemptsAlert,
  IAlert,
  IMfaCodeInputs,
  MfaCodeInputTypes,
} from '../../model/mfa-code-form.model'
import { IWrongMfaCodeErrorData } from '../../model/mfa.model'
import { useSendTokenMutation } from '../../mutation/send-token.mutation'
import { mfaQa } from '../../qa/mfa.qa'

type Props = {
  email: string
  mfaType: MfaTypes
  onFormSubmit: ({ code }: { code: string }) => Promise<void>
  isMfaResendEmailLocked: boolean
}

// eslint-disable-next-line complexity
export function MfaCodeForm({
  mfaType,
  onFormSubmit,
  email,
  isMfaResendEmailLocked,
}: Props) {
  const t = useI18n([MFA_KEY])
  const [alert, setAlert] = useState<Nullable<IAlert>>(null)
  const [isUsingAppRecovery, setIsUsingAppRecovery] = useState<boolean>(false)
  const [isResendCtaLocked, setIsResendCtaLocked] = useState<boolean>(
    isMfaResendEmailLocked,
  )

  const { mutateAsync: resendToken, isLoading: isResendingCode } =
    useSendTokenMutation()

  useEffect(() => {
    if (isMfaResendEmailLocked) {
      setAlert(getResendCodeAttemptsAlert(0, t))
    }
  }, [isMfaResendEmailLocked, t])

  const {
    register,
    handleSubmit,
    formState: { isDirty, isValid, errors },
  } = useForm<IMfaCodeInputs>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
  })

  const isFormDisabled = !isDirty || !isValid

  const codeRegister = useMemo(
    () => register(MfaCodeInputTypes.Code, getMfaCodeRegisterOptions(t)),
    [register, t],
  )

  const updateApiError = (
    error: AxiosError<Unknownable<IWrongMfaCodeErrorData>>,
  ) => {
    const status = error.response?.status as ErrorCodeTypes | undefined
    if (!status) {
      return
    }

    if (status === ErrorCodeTypes.E429) {
      setAlert({
        type: 'error',
        children: t('form.errors.429_locked'),
      })
      return
    }

    // show generic message, either wrong code or expired
    if (mfaType === MfaTypes.App) {
      setAlert({
        type: 'error',
        children: (
          <div className="flex flex-col gap-2">
            <p>{t('form.errors.general_app.text')}</p>
          </div>
        ),
      })
    } else {
      setAlert({
        type: 'error',
        children: (
          <div className="flex flex-col gap-2">
            <p>{t('form.errors.general_email.text')}</p>
            <p>{t('form.errors.general_email.text2')}</p>
          </div>
        ),
      })
    }
  }

  const handleFormSubmit: SubmitHandler<IMfaCodeInputs> = async ({ code }) => {
    try {
      setAlert(null)
      await onFormSubmit({ code })
    } catch (error) {
      updateApiError(error as AxiosError<Unknownable<IWrongMfaCodeErrorData>>)
    }
  }

  const handleResendCode = async () => {
    try {
      await resendToken({ email, mfaSetup: false })
      notification.success(t('form.notification.resendCodeSuccess'))

      setAlert({
        type: 'info',
        children: (
          <div className="flex flex-col gap-2">
            <p>{t('form.resendCode.success')}</p>
            {mfaType === MfaTypes.Email && (
              <p>{t('form.resendCode.successEmail')}</p>
            )}
          </div>
        ),
      })
    } catch (error) {
      const status = (error as AxiosError).response?.status
      if (
        status &&
        [ErrorCodeTypes.E403, ErrorCodeTypes.E429].includes(status)
      ) {
        setIsResendCtaLocked(true)
        notification.error(t('form.notification.resendCodeError'))
        setAlert({
          type: 'error',
          children: t('form.resendCode.error'),
        })
      }
    }
  }

  const handleToggleAppRecovery = () => {
    setIsUsingAppRecovery(prev => !prev)
  }

  if (mfaType === MfaTypes.App) {
    return (
      <FrankieLoader loading={false}>
        <div
          data-qa={mfaQa.header}
          className="text-primary-700 text-2xl leading-snug font-bold"
        >
          {t('form.heading')}
        </div>
        <div className="text-md text-tertiary-grey-700 mt-2">
          {t(
            isUsingAppRecovery
              ? 'form.subheadingAppRecovery'
              : 'form.subheadingApp',
          )}
        </div>
        <form
          className="min-h-[188px] mt-4 flex flex-initial flex-col justify-between"
          onSubmit={handleSubmit(handleFormSubmit)}
          data-qa={mfaQa.form}
        >
          <div>
            <FrankieTextField
              className="mb-2"
              {...codeRegister}
              label={t(
                isUsingAppRecovery
                  ? 'form.recoveryCodeLabel'
                  : 'form.codeLabel',
              )}
              error={!!errors[MfaCodeInputTypes.Code]}
              errorText={errors[MfaCodeInputTypes.Code]?.message}
              testId={{
                input: mfaQa.codeInput,
              }}
            />
            <FrankieButton
              noStyles
              onClick={handleToggleAppRecovery}
              className="text-primary-800 font-normal text-sm leading-4 disabled:text-tertiary-grey-300"
              testId={{
                button: isUsingAppRecovery
                  ? mfaQa.dontUseAppRecoveryCta
                  : mfaQa.useAppRecoveryCta,
              }}
            >
              {t(
                isUsingAppRecovery
                  ? 'form.cta.dontUseAppRecovery'
                  : 'form.cta.useAppRecovery',
              )}
            </FrankieButton>
            {alert && (
              <FrankieAlert
                className="mt-4 text-tertiary-grey-700"
                type={alert.type}
                testId={{ alert: mfaQa.alert }}
              >
                <Trans className="text-tertiary-grey-700">
                  {alert.children}
                </Trans>
              </FrankieAlert>
            )}
          </div>
          <div className="flex flex-initial justify-end">
            <FrankieButton
              className="mt-4"
              disabled={isFormDisabled}
              type="submit"
              testId={{ button: mfaQa.confirmCta }}
            >
              {t('form.cta.confirm')}
            </FrankieButton>
          </div>
        </form>
      </FrankieLoader>
    )
  }

  // MfaTypes.Email
  return (
    <FrankieLoader loading={false}>
      <div
        data-qa={mfaQa.header}
        className="text-primary-700 text-2xl leading-snug font-bold"
      >
        {t('form.heading')}
      </div>
      <div className="text-md text-tertiary-grey-700 mt-2">
        {t('form.subheadingEmail')}
      </div>
      <form
        className="min-h-[188px] mt-4 flex flex-initial flex-col justify-between"
        onSubmit={handleSubmit(handleFormSubmit)}
        data-qa={mfaQa.form}
      >
        <div>
          <FrankieTextField
            className="mb-2"
            label={t('form.codeLabel')}
            {...codeRegister}
            testId={{
              input: mfaQa.codeInput,
            }}
          />
          <FrankieButton
            noStyles
            onClick={handleResendCode}
            className="text-primary-800 font-normal text-sm leading-4 disabled:text-tertiary-grey-300"
            testId={{ button: mfaQa.resendCta }}
            disabled={isResendingCode || isResendCtaLocked}
          >
            {t('form.cta.resendCode')}
          </FrankieButton>
          {alert && (
            <FrankieAlert
              className="mt-4 text-tertiary-grey-700"
              type={alert.type}
              testId={{ alert: mfaQa.alert }}
            >
              <Trans className="text-tertiary-grey-700">{alert.children}</Trans>
            </FrankieAlert>
          )}
        </div>
        <div className="flex flex-initial justify-end">
          <FrankieButton
            className="mt-4"
            disabled={isFormDisabled}
            type="submit"
            testId={{ button: mfaQa.confirmCta }}
          >
            {t('form.cta.confirm')}
          </FrankieButton>
        </div>
      </form>
    </FrankieLoader>
  )
}
