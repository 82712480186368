import { APPLICANT_TRANSACTIONS_KEY as ATK } from '../applicant-transactions.key'

const AppCustomerTrans = `${ATK}_customer`
export const applicantCustomerTransactionsQa = {
  dataGrid: `${AppCustomerTrans}-data-grid`,
  btn: `${AppCustomerTrans}-btn`,
} satisfies Record<string, `${typeof AppCustomerTrans}-${string}`>

const AppTransactions = `${ATK}_transactions`
export const applicantTransactionsQa = {
  dataGrid: `${AppTransactions}-data-grid`,
  btn: `${AppTransactions}-btn`,
} satisfies Record<string, `${typeof AppTransactions}-${string}`>

const AppTxnDataGrd = `${ATK}_data-grid`
export const applicantTransactionsDataGridQa = {
  searchBtn: `${AppTxnDataGrd}-search-btn`,
  bulkActionBtn: `${AppTxnDataGrd}-bulk-btn`,
  textField: `${AppTxnDataGrd}-text-field`,
} satisfies Record<string, `${typeof AppTxnDataGrd}-${string}`>

const AppTxnDataGrdBlkAction = `${ATK}-grid-action`
export const applicantTransactionsDataGridBulkActionQa = {
  selectField: `${AppTxnDataGrdBlkAction}-select`,
  confirmationField: `${AppTxnDataGrdBlkAction}-confirmation-field`,
} satisfies Record<string, `${typeof AppTxnDataGrdBlkAction}-${string}`>

const AppTxnDataGrdHelper = `${ATK}-grid-helper`
export const applicantTransactionsDataGridHelperQa = {
  readableCell: `${AppTxnDataGrdHelper}-readable-cell`,
  timeStampCell: `${AppTxnDataGrdHelper}-time-stamp-cell`,
  actionCell: `${AppTxnDataGrdHelper}-action-cell`,
} satisfies Record<string, `${typeof AppTxnDataGrdHelper}-${string}`>
