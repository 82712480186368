import React, { Fragment, useMemo } from 'react'

import { ApplicantId, ApplicantIssueActionBadge } from 'entities/applicant'

import { differenceInYears } from 'shared/date-time'
import { useI18n } from 'shared/i18n'

import { APPLICANT_PEP_SACTIONS_KEY } from '../../../../applicant-pep-sactions.key'
import { applicantPepSactionsEn } from '../../../../locale/applicant-pep-sactions.en'
import {
  pepSactionsDateFormat,
  pepSactionsListing,
} from '../../../../model/applicant-pep-sactions.model'
import { applicantPepSanctionKeyDataQa } from '../../../../qa/applicant-pep-sactions.qa'
import { useApplicantPepSactionsQuery } from '../../../../state/applicant-pep-sactions-query/applicant-pep-sactions.query'

type DataSummaryViewProps = {
  dataItems: {
    key: string
    item: string
  }[]
}

export function DataSummaryView({ dataItems }: DataSummaryViewProps) {
  const t = useI18n(APPLICANT_PEP_SACTIONS_KEY, {
    keys: applicantPepSactionsEn,
  })

  // From vue code
  const urlIf = (value?: string) => {
    value?.trim()
    return value?.slice(0, 4) === 'http' || value?.slice(0, 5) === 'https'
  }

  if (!dataItems.length) return <div>{t('empty.data')}</div>

  return (
    <>
      {dataItems.map(({ item, key }) => (
        <div key={key} className="flex">
          <div className="min-w-[200px] text-tertiary-grey-400">{key}</div>
          {urlIf(item) ? (
            <a
              href={item}
              className="text-primary-500 underline"
              target="_blank"
              rel="noreferrer"
            >
              {item}
            </a>
          ) : (
            <div>{item}</div>
          )}
        </div>
      ))}
    </>
  )
}

type Props = {
  applicantId: ApplicantId
  matchIdx: number
  testId?: { container?: string }
}

export function ApplicantPepSactionsKeyData({
  applicantId,
  matchIdx,
  testId,
}: Props) {
  const t = useI18n(APPLICANT_PEP_SACTIONS_KEY, {
    keys: applicantPepSactionsEn,
  })

  const { data } = useApplicantPepSactionsQuery({ applicantId })

  // eslint-disable-next-line complexity
  const { entityFields, entityImage, matchEntityDataSummary } = useMemo(() => {
    const selectedMatch = data?.matches[matchIdx]
    const matchEntityData = data?.matches[matchIdx].details.entityData

    const dob = pepSactionsDateFormat(matchEntityData?.dateOfBirth)

    const age = differenceInYears(new Date(), new Date(dob))

    const addresses = matchEntityData?.addresses
    const countries = matchEntityData?.countries
    const associates = matchEntityData?.associates

    const fields = [
      { label: t('field.fullName'), value: matchEntityData?.name ?? '-' },
      { label: t('field.aka'), value: matchEntityData?.aka.join('; ') ?? '-' },
      {
        label: t('field.dob'),
        value: dob ? `${dob} (${t('field.age')}: ${age})` : '-',
      },
      {
        label: t('field.countries'),
        value: countries?.length ? countries.join(', ') : '-',
      },
      {
        label: t('field.addresses'),
        value: addresses?.length
          ? addresses.map(address => <p>{address}</p>)
          : '-',
      },

      {
        label: t('field.associates'),
        value: associates?.length
          ? associates.map(associate => (
              <p key={associate.name}>
                <span className="capitalize">{associate.relation}:</span>
                <span className="name">{associate.name}</span>
              </p>
            ))
          : '-',
      },
    ]

    return {
      matchEntityDataSummary: selectedMatch?.details,
      entityFields: fields,
      entityImage: matchEntityData?.imageURL,
    }
  }, [data?.matches, matchIdx, t])

  const pepSactionsListingResult = matchEntityDataSummary
    ? pepSactionsListing.filter(
        ({ field }) => !!matchEntityDataSummary[field].length,
      )
    : []

  return (
    <div data-qa={testId?.container}>
      <div className="flex gap-2 py-8 px-2">
        <div className="basis-1/2">
          {entityFields.map(item => (
            <div
              data-qa={applicantPepSanctionKeyDataQa.entity}
              className="flex items-start border-b border-tertiary-grey-200 py-1"
              key={item.label}
            >
              <span className="min-w-[150px] text-md text-tertiary-grey-400">
                {item.label}
              </span>
              <span className="text-secondary-900">{item.value}</span>
            </div>
          ))}
        </div>
        <div className="basis-1/2 p-2 h-[240px]">
          {entityImage && (
            <img className="h-full mx-auto" src={entityImage} alt="entity" />
          )}
        </div>
      </div>
      {matchEntityDataSummary && (
        <div className="px-2">
          {!!pepSactionsListingResult.length && (
            <div className="text-xl text-secondary-900 mb-6">
              {t('listing')}
            </div>
          )}
          {pepSactionsListingResult.map(({ type, field, tKey }) => (
            <div
              key={type}
              data-qa={applicantPepSanctionKeyDataQa.listing}
              className="mt-4 flex gap-2 text-secondary-900"
            >
              <div className="min-w-[200px]">
                <ApplicantIssueActionBadge
                  type={type}
                  prioritiesLongLabel
                  className="!p-1"
                />
              </div>

              <div>
                <div className="text-xl font-bold">
                  {t(`listingFields.${tKey}`)}
                </div>
                <div>{t('currentlyOn')}</div>

                {matchEntityDataSummary[field].length ? (
                  matchEntityDataSummary[field].map(({ name, items }) => (
                    <div key={name} className="mt-2">
                      <div className="text-md">{name}</div>
                      <DataSummaryView
                        dataItems={items.filter(
                          ({ key }) => !key.includes('status.'),
                        )}
                      />
                    </div>
                  ))
                ) : (
                  <div>{t('empty.data')}</div>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
