import { useCallback, useEffect, useRef, useState } from 'react'

import { useQuery } from '@tanstack/react-query'

import {
  ICountryOrSubdivision,
  stripSubdivision,
  useCountriesCoveredQuery,
} from 'entities/country'
import { organisationApi, useOrganisationProfile } from 'entities/organisation'

import { debounce } from 'shared/function'

type Args = {
  searchTerm?: string
  applicantId?: string
}

export const InternationalBusinessSearchQueryKey =
  'international-business-search-query'

export const useInternationalBusinessSearch = ({
  searchTerm,
  applicantId,
}: Args) => {
  const [selectedCountry, setSelectedCountry] =
    useState<ICountryOrSubdivision>()

  const [searchValue, setSearchValue] = useState<Args['searchTerm']>(searchTerm)
  const searchRef = useRef<HTMLInputElement>(null)

  const { data: countriesOptions } = useCountriesCoveredQuery()

  const { data: organisationProfile } = useOrganisationProfile({
    entityId: applicantId,
  })

  useEffect(() => {
    const organisationDetails = organisationProfile?.organization.details

    // Setting selected country based on the organisation's jurisdiction
    if (organisationDetails) {
      const currentInternationalCountryAlpha3Code =
        organisationDetails.jurisdiction?.country

      const selectedCountryOption =
        countriesOptions?.find(
          item => item.alpha3code === currentInternationalCountryAlpha3Code,
        ) ?? countriesOptions?.[0] // REMARK: default to first country if not found

      if (selectedCountryOption)
        setSelectedCountry({
          country: selectedCountryOption,
        })
    } else {
      const firstCountryOption = countriesOptions?.[0]
      if (firstCountryOption)
        setSelectedCountry({ country: firstCountryOption })
    }
  }, [countriesOptions, organisationProfile])

  const query = useQuery({
    enabled: !!searchValue && !!selectedCountry?.country.alpha3code,
    queryKey: [
      InternationalBusinessSearchQueryKey,
      searchValue,
      selectedCountry?.country.alpha3code,
      selectedCountry?.subdivision,
    ],
    queryFn: async () => {
      const { data } = await organisationApi.getOrganizationLookup(
        searchValue!,
        // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
        selectedCountry?.country.alpha3code!,
        selectedCountry?.subdivision
          ? stripSubdivision(selectedCountry.subdivision.subdivisionCode)
          : undefined,
      )
      return data
    },
  })

  const handleSearchValue = useCallback(
    debounce((value: Args['searchTerm']) => {
      setSearchValue(value)
    }, 500),
    [],
  )

  const handleSelectCountry = useCallback((value: ICountryOrSubdivision) => {
    setSelectedCountry(value)
  }, [])

  return {
    ...query,
    countriesOptions,
    selectedCountry,
    handleSearchValue,
    handleSelectCountry,
    hasSearchValue: !!searchValue?.length,
    searchRef,
    searchValue,
  }
}
