import React, { useEffect, useMemo } from 'react'

import { FrankieTextField } from 'frankify/src'

import { INDIVIDUAL_PROFILE_KEY } from 'features/individual-profile/individual-profile.keys'
import { individualProfileEn } from 'features/individual-profile/locale/new-profile.en'
import { IndividualProfileInputTypes } from 'features/individual-profile/model/form.model'

import { countryList } from 'entities/country'
import { KycDocumentCategoryTypes } from 'entities/document'

import { SelectFormField, getError } from 'shared/form'
import { validateDate } from 'shared/form/model/error.model'
import { useI18n } from 'shared/i18n'

import { individualProfileVueMigratedQa } from '../../../../qa/individual-profile.qa'
import { DocumentCategory } from '../document-category/document-category'
import { DocumentsSubFormProps } from '../document-sub-forms.types'

export function CitizenShipCertificateForm({
  form,
  idx,
  wrapperClasses,
}: DocumentsSubFormProps) {
  const {
    register,
    control,
    formState: { errors },
    setValue,
    unregister,
    watch,
  } = form
  const t = useI18n([INDIVIDUAL_PROFILE_KEY], { keys: individualProfileEn })

  const isAustralia = watch(`document.${idx}.country`) === 'AUS'

  const acquisitionDateFieldName = useMemo(
    () =>
      `${IndividualProfileInputTypes.Document}.${idx}.${IndividualProfileInputTypes.CitizenShipCertificate}.${IndividualProfileInputTypes.AcquisitionDate}` as never,
    [idx],
  )

  const countryOptions = useMemo(
    () =>
      countryList.map(country => ({
        label: country.name,
        value: country.alpha3code,
      })),
    [],
  )

  useEffect(() => {
    setValue(
      `${IndividualProfileInputTypes.Document}.${idx}.${IndividualProfileInputTypes.DocumentCategory}`,
      KycDocumentCategoryTypes.PRIMARY_NON_PHOTOGRAPHIC as never,
    )
  }, [idx, setValue])

  const idNumberFieldName = useMemo(
    () =>
      `${IndividualProfileInputTypes.Document}.${idx}.${IndividualProfileInputTypes.IdNumber}` as IndividualProfileInputTypes.Document,
    [idx],
  )

  // unregister id number because used in multiple components
  useEffect(
    () => () => {
      unregister(idNumberFieldName)
    },
    [idx, unregister, idNumberFieldName],
  )

  return (
    <div className={wrapperClasses}>
      {isAustralia && (
        <>
          <FrankieTextField
            className="basis-[32%]"
            label={t('documentForm.stockNumber')}
            placeholder={t('documentForm.stockNumberPlaceholder')}
            error={!!getError(idNumberFieldName, errors)}
            {...register(idNumberFieldName, {
              required: true,
              shouldUnregister: true,
            })}
            testId={{
              input: individualProfileVueMigratedQa.document.idNumberField,
            }}
          />
          <FrankieTextField
            type="date"
            className="basis-[32%]"
            label={t('documentForm.acquisitionDate')}
            placeholder={t('documentForm.datePlaceholder')}
            error={!!getError(acquisitionDateFieldName, errors)}
            {...register(acquisitionDateFieldName, {
              required: true,
              shouldUnregister: true,
              validate: {
                dob: value => validateDate(value),
              },
            })}
            testId={{
              input: individualProfileVueMigratedQa.document.registrationDate,
            }}
          />
          <div className="basis-[32%]" />
        </>
      )}

      <SelectFormField
        className="basis-[32%]"
        label={t(
          isAustralia
            ? 'documentForm.primaryCountry'
            : 'documentForm.countryOfBirth',
        )}
        control={control}
        name={`${IndividualProfileInputTypes.Document}.${idx}.${IndividualProfileInputTypes.CitizenShipCertificate}.${IndividualProfileInputTypes.PrimaryCountry}`}
        options={countryOptions}
        testId={{
          input: individualProfileVueMigratedQa.document.primaryCountry,
        }}
      />

      {isAustralia && (
        <SelectFormField
          className="basis-[32%]"
          label={`${t('documentForm.secondaryCountry')} ${t('optional')}`}
          control={control}
          name={`${IndividualProfileInputTypes.Document}.${idx}.${IndividualProfileInputTypes.CitizenShipCertificate}.${IndividualProfileInputTypes.SecondaryCountry}`}
          options={countryOptions}
          testId={{
            input: individualProfileVueMigratedQa.document.secondaryCountry,
          }}
          shouldUnregister
        />
      )}

      <DocumentCategory
        disabled
        className="basis-[32%]"
        form={form}
        idx={idx}
      />
    </div>
  )
}
