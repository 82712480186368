import { useMemo } from 'react'

import { useMutation } from '@tanstack/react-query'

import {
  useApplicantDataQuery,
  useApplicantRiskCheckQuery,
} from 'entities/applicant'
import { useSessionQuery } from 'entities/session'

import { useI18n } from 'shared/i18n'
import { notification } from 'shared/notification'

import { applicantTransactionsApi } from '../../api/applicant-transactions.api'
import { APPLICANT_TRANSACTIONS_KEY } from '../../applicant-transactions.key'
import { applicantTransactionsEn } from '../../locale/applicant-transactions.en'
import {
  ApplicantTransactionsQueryArgs,
  useApplicantTransactionsQuery,
} from '../applicant-transactions-query/applicant-transactions.query'

type StatusAndCommentData = { newStatus: string; comment: string }

export type ChangeStatusData = {
  transactionResultId: string
  transactionId: string
} & StatusAndCommentData

export type ChangeStatusBulkData = {
  transactionResultIds: string[]
} & StatusAndCommentData

const useInfoStamp = ({ applicantId }: { applicantId: string }) => {
  const { data: applicantData } = useApplicantDataQuery({ applicantId })

  const { data: sessionData } = useSessionQuery()

  const stamp = useMemo(
    () => ({
      createdBy: sessionData?.user.username ?? '',
      assignedTo:
        applicantData?.applicantDetails.assignee ??
        sessionData?.user.email ??
        '',
    }),
    [
      applicantData?.applicantDetails.assignee,
      sessionData?.user.email,
      sessionData?.user.username,
    ],
  )

  return stamp
}

export const useApplicantTransactionsChangeStatus = ({
  applicantId,
  needsAttention,
  transactionType,
}: ApplicantTransactionsQueryArgs) => {
  const t = useI18n([APPLICANT_TRANSACTIONS_KEY], {
    keys: applicantTransactionsEn,
  })

  const { refetch } = useApplicantDataQuery({ applicantId })
  const { refetch: refetchApplicantRisk } = useApplicantRiskCheckQuery({
    entityId: applicantId,
  })

  const stamp = useInfoStamp({ applicantId })

  const { refetch: refetchApplicantTransaction } =
    useApplicantTransactionsQuery({
      transactionType,
      needsAttention,
      applicantId,
    })

  const mutation = useMutation({
    mutationFn: ({ transactionResultId, ...restData }: ChangeStatusData) =>
      applicantTransactionsApi.changeTransactionStatus(
        applicantId,
        transactionResultId,
        { ...restData, ...stamp },
      ),
    onSuccess: () => {
      notification.success(t('success.transactionUpdated'))
      void refetchApplicantTransaction()
      void refetchApplicantRisk()
      void refetch()
    },
    onError: () => {
      notification.error(t('error.updatingTransaction'))
    },
  })

  return mutation
}

export const useApplicantTransactionsChangeStatusBulk = ({
  applicantId,
  needsAttention,
  transactionType,
}: ApplicantTransactionsQueryArgs) => {
  const t = useI18n([APPLICANT_TRANSACTIONS_KEY], {
    keys: applicantTransactionsEn,
  })

  const stamp = useInfoStamp({ applicantId })

  const { refetch: refetchApplicantTransaction } =
    useApplicantTransactionsQuery({
      transactionType,
      needsAttention,
      applicantId,
    })

  const mutation = useMutation({
    mutationFn: async ({
      transactionResultIds,
      ...restData
    }: ChangeStatusBulkData) => {
      const { data } =
        await applicantTransactionsApi.changeTransactionsStatusBulk(
          applicantId,
          {
            update: { ...restData, ...stamp },
            filter: {
              caseIds: transactionResultIds,
              resultTypes: [],
            },
          },
        )

      return data
    },
    onSuccess: () => {
      void refetchApplicantTransaction()
    },
    onError: () => {
      notification.error(t('error.updatingTransaction'))
    },
  })

  return mutation
}
