export const NEW_PASSWORD_LOCALE = 'newPassword'

export const newPasswordEn = {
  validation: {
    email: {
      label: 'Email',
      required: 'Email is required',
      pattern: 'Please enter a valid email address',
    },
    password: {
      label: 'Password',
      required: 'Password is required',
    },
  },
  container: {
    heading: {
      new: 'New password',
    },
    description: {
      new: 'You can now create a new password.',
    },
  },
  form: {
    input: {
      newPassword: {
        label: 'New Password',
        validation: {
          minimum: 'Minimum 12 characters',
          uppercase: 'Must contain at least one capital letter',
          lowercase: 'Must contain at least one lowercase letter',
          numeric: 'Must contain at least one numeric digit',
          special: 'Must contain at least one special character',
        },
      },
      confirmPassword: {
        label: 'Confirm Password',
        validation: {
          same: 'Password do not match',
        },
      },
    },
    button: {
      submit: {
        default: {
          new: 'Save password',
        },
        loading: {
          new: 'Setting password...',
        },
      },
      backToLogin: {
        default: 'Back to login',
      },
    },
    commonError:
      'There was an issue during the request. Please retry the password reset process.',
    validationError: 'Password does not meet minimum requirements',
    passwordMatchError: 'Passwords do not match',
    matchesPreviousPassword:
      'Your new password cannot be the same as your current or previous password.',
    tooManyPasswordAttempts:
      'Too many attempts of password reset. Try again in one hour.',
    passwordResetExpired:
      'Sorry! Looks like this link has expired. Please request another link to reset password.',
    passwordSavedTitle: 'Your new password has been saved',
    passwordSavedSubtitle:
      'Your password has been successfully updated. You may proceed to the FrankieOne Portal.',
    continueButton: 'Continue',
  },
}
