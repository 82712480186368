import { useQuery } from '@tanstack/react-query'

import { ApplicantId } from 'entities/applicant'
import { PermissionTypes } from 'entities/role'
import { useHasFeatureFlag, useHasPermission } from 'entities/session'

import { ErrorResponse } from 'shared/http'

import { applicantBiometricsApi } from '../../api/applicant-biometrics-ocr.api'
import { ApplicantFacialDuplicatesResponse } from '../../model/applicant-biometrics-ocr.model'

type Args = {
  applicantId?: ApplicantId
}

export const ApplicantFacialDuplicatesQueryKey =
  'applicant-facial-duplicates-query'

export const useApplicantFacialDuplicatesQuery = ({ applicantId }: Args) => {
  const { hasFacialDuplicatesFeature } = useHasFeatureFlag({
    hasFacialDuplicatesFeature: ['facialDuplicatesEnabled'],
  })

  const { hasFacialDuplicatesReportFetchPermission } = useHasPermission({
    hasFacialDuplicatesReportFetchPermission:
      PermissionTypes.FacialDuplicatesReportFetchPermission,
  })

  const query = useQuery<ApplicantFacialDuplicatesResponse, ErrorResponse>({
    enabled:
      !!applicantId &&
      hasFacialDuplicatesFeature &&
      hasFacialDuplicatesReportFetchPermission,
    queryKey: [ApplicantFacialDuplicatesQueryKey, applicantId],
    queryFn: async () => {
      const { data } =
        await applicantBiometricsApi.getApplicantFacialDuplicates(applicantId!)

      return data
    },
  })

  return {
    ...query,
    hasFacialDuplicatesReportFetchPermission,
    hasFacialDuplicatesFeature,
  }
}
