export const applicantFraudCheckEn = {
  header: {
    reportedBy: 'Reported By',
    date: 'Date',
    matchStatus: 'Match Status',
    matchList: 'Matched List/s',
    fraudSubject: 'Fraud Subjects',
    fraudType: 'Fraud Type',
    matchType: 'Match Type',
    select: 'Select All',
  },
  matchingResult: 'Matching Result Details',
  fraudCheckResult: 'Fraud Check Results',
  changeMatchStatus: 'Change Match Status',
  results: 'results',
  itemSelected: 'item selected',
  statusChange: {
    form: {
      truePositive: 'True Positive',
      falsePositive: 'False Positive',
      changeMatchStatus: 'Change Match Status',
      save: 'Save',
      cancel: 'Cancel',
      comment: 'Comment',
      typeYourComment: 'Add your comment here',
    },
  },
  entityDetails: {
    business: 'Business Name',
    phone: 'Phone',
    address: 'Address',
    abn: 'ABN',
    search: 'Search',
  },
  success: {
    changeStatus: 'Success update fraud status',
  },
  error: 'There was an issue. Please, try again.',
  records: {
    fetching: 'Fetching frauds',
    empty: 'No Fraud Check matches have been found',
  },
  updating: 'Updating...',
}
