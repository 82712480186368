export const dashboardEn = {
  analytics: {
    passRate: 'Pass Rate',
    riskLevel: 'Risk Level',
    primaryCauses: 'Primary cause of Failure/Referral',
    checks: 'Checks',
  },
  generateReport: {
    btn: 'Generate Report',
    heading: 'Request All Customer Records Report',
    note1:
      'Once the report is generated it will be emailed to the email address associated with your Frankie Portal Account.',
    note2: 'The file will contain sensitive personal information.',
    note3: 'Please enter a password which will be used to decrypt the file.',
    fields: {
      password: 'Enter Password',
    },
    cta: {
      submit: 'Request Report',
      cancel: 'Cancel',
    },
    menu: {
      transactionRecord: 'Transaction Record (CSV)',
      allRecord: 'All Customer Records (CSV)',
    },
  },
  message: {
    reportGenerated:
      'Your request is being processed and you will receive an email when completed.',
    maxDateRange:
      "Date range shouldn't be over {{noOfDays}} days for downloading CSV report",
    singleAccount:
      "Only one account may be selected when generating the 'All Customer Records' report. Ensure one account is selected and try again.",
    inProgress: 'Already in progress, please wait...',
    atleastOneAccount: 'Please select at least one account',
  },
  dateFilter: {
    apply: 'Apply',
    invalidDate: 'Invalid Date',
    dateRange: '{{startDate}} to {{endDate}}',
    option: {
      today: 'Today',
      lastWeek: 'Last Week',
      currentMonth: 'Current Month',
      last30Days: 'Last 30 Days',
      lastYear: 'Last Year',
    },
  },
  passRate: {
    passed: 'Passed',
    manuallyPassed: 'Manually Passed',
    needsAttention: 'Needs Attention',
    failed: 'Failed',
    manuallyFailed: 'Manually Failed',
    pending: 'Pending',
  },
  riskLevel: {
    low: 'Low',
    medium: 'Medium',
    high: 'High',
    unacceptable: 'Unacceptable',
    unknown: 'Unknown',
  },
  issuesType: {
    biometrics: 'Biometrics',
    duplicate: 'Duplicate',
    blocklist: 'Blocklist',
    KYC: 'KYC',
    PEP: 'PEP',
    watchlist: 'Watchlist',
    sanction: 'Sanction',
    adverseMedia: 'Adverse Media',
    serviceFailure: 'Service Failure',
  },
  failureCauses: {
    postCheck: 'Post Check',
    preCheck: 'Pre Check',
  },

  dismiss: 'Dismiss',
}
