import React, { useRef, useState, KeyboardEvent } from 'react'

import { FrankieTextField } from 'frankify/src'

import { useABRSearchQuery } from 'features/abr-search-australia'
import { ORGANISATION_SEARCH_KEY } from 'features/organisation-search'

import {
  CountriesCoveredSelector,
  CountryAlpha3CodeTypes,
  ICountryOrSubdivision,
  stripSubdivision,
  useCountriesCoveredQuery,
} from 'entities/country'
import { useOrganizationsLookupQuery } from 'entities/organisation'
import { useSessionQuery } from 'entities/session'

import { useI18n } from 'shared/i18n'

import './kyb-search-input.css'

type Props = {
  setDataToDisplay: ({
    organizationName,
    countryName,
  }: {
    organizationName: string
    countryName: string
  }) => void
}

export function KybSearchInput({ setDataToDisplay }: Props) {
  const t = useI18n([ORGANISATION_SEARCH_KEY])

  const { data: pageData } = useSessionQuery()

  const { data: coveredCountries } = useCountriesCoveredQuery()

  const availableCountryCodes = pageData?.countries
    ? pageData.countries.map(country => country.alpha3code)
    : [CountryAlpha3CodeTypes.Australia]

  const filteredCountries = coveredCountries
    ? coveredCountries.filter(coveredCountry =>
        availableCountryCodes.includes(coveredCountry.alpha3code),
      )
    : [{ alpha3code: CountryAlpha3CodeTypes.Australia, name: 'Australia' }]

  const [search, setSearch] = useState('')
  const showClearSearchButton = search.length > 0

  const [selectedCountry, setSelectedCountry] = useState<ICountryOrSubdivision>(
    {
      country: {
        alpha3code: CountryAlpha3CodeTypes.Australia,
        name: 'Australia',
      },
    },
  )

  const searchFieldRef = useRef<HTMLInputElement>(null)

  const handleClickClearSearch = () => {
    if (searchFieldRef.current) {
      searchFieldRef.current.focus()
    }

    setSearch('')
  }

  const handleSelectCountry = (value: ICountryOrSubdivision) => {
    setSelectedCountry(value)
  }

  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
  }

  const { refetch: getOrganizations } = useOrganizationsLookupQuery({
    search,
    country: selectedCountry.country.alpha3code,
    subdivision: selectedCountry.subdivision
      ? stripSubdivision(selectedCountry.subdivision.subdivisionCode)
      : undefined,
  })

  const { refetch: getABROrganisations } = useABRSearchQuery({ search })

  const isAustralia =
    selectedCountry.country.alpha3code === CountryAlpha3CodeTypes.Australia

  const handleKeyDown = (e: KeyboardEvent<Element>) => {
    if (e.key === 'Enter' && search.length) {
      if (isAustralia) {
        setDataToDisplay({
          organizationName: search,
          countryName: 'Australia',
        })

        void getABROrganisations()
      } else {
        void getOrganizations()

        const strippedSubdivision = selectedCountry.subdivision
          ? selectedCountry.subdivision.name
          : undefined

        const countryNameWithSubdivision = strippedSubdivision
          ? `${selectedCountry.country.alpha3code} - ${strippedSubdivision}`
          : selectedCountry.country.name

        setDataToDisplay({
          organizationName: search,
          countryName: countryNameWithSubdivision,
        })
      }
    }
  }

  return (
    <>
      <div className="text-tertiary-grey-800 text-xl font-bold">
        {t('title')}
      </div>
      <div className="flex mt-3">
        <div className="basis-[327px] shrink min-w-[250px]">
          <CountriesCoveredSelector
            options={filteredCountries}
            value={selectedCountry}
            onChange={handleSelectCountry}
            disabled={filteredCountries.length === 1}
          />
        </div>
        <div className="relative basis-[584px] shrink min-w-[327px]">
          <FrankieTextField
            ref={searchFieldRef}
            onKeyDown={handleKeyDown}
            size="sm"
            type="search"
            closeButton={
              showClearSearchButton
                ? { onClick: handleClickClearSearch }
                : undefined
            }
            className="before:!mr-10 before:!absolute before:!ml-[-1px] before:!z-20 before:h-[36px] before:w-[1px] search"
            inputClassName="pr-[30px] text-tertiary-grey-700 !rounded-l-none !border-l-0 focus-visible:text-tertiary-grey-700"
            value={search}
            onChange={handleChangeSearch}
            isSearchIcon
            placeholder={t(
              selectedCountry.country.alpha3code ===
                CountryAlpha3CodeTypes.Australia
                ? 'organisationSelector.placeholderAustralia'
                : 'organisationSelector.placeholder',
            )}
          />
        </div>
      </div>
    </>
  )
}
