import { useQuery } from '@tanstack/react-query'

import { applicantSupportingDocumentApi } from '../../api/applicant-supporting-document.api'

export const ApplicantSupportingDocumentCommentQueryKey =
  'applicant-supporting-document-comments-query'

type Args = {
  applicantId?: string
  documentId?: string
  disabled?: boolean
}

/**
 * Applicant Supporting Document comments Query
 */
export const useApplicantSupportingDocumentCommentsQuery = ({
  applicantId,
  documentId,
  disabled = false,
}: Args) =>
  useQuery({
    enabled: !!applicantId && !!documentId && !disabled,
    queryKey: [
      ApplicantSupportingDocumentCommentQueryKey,
      applicantId,
      documentId,
    ],
    queryFn: async () => {
      const { data } =
        await applicantSupportingDocumentApi.getSupportedDocComments(
          applicantId!,
          documentId!,
        )

      return data
    },
  })
