import React from 'react'

import { ApplicantId } from 'entities/applicant'

import { TextAreaFormField } from 'shared/form'
import { useI18n } from 'shared/i18n'
import { Noop } from 'shared/typescript'

import { APPLICANT_GENERAL_INFORMATION_KEY } from '../../../../applicant-general-information.key'
import { applicantGeneralInformationEn } from '../../../../locale/applicant-general-information.en'
import { removeApplicantWatchlist } from '../../../../qa/applicant-general-information.qa'
import {
  AddToBlocklistData,
  useAddApplicantToBlocklistMutation,
} from '../../../../state/applicant-profile-state-change/applicant-profile-state-change'
import { ApplicantActionModalWrapper } from '../../applicant-action-modal-wrapper/applicant-action-modal-wrapper'

type Props = { applicantId: ApplicantId; handleClose: Noop }

export function RemoveApplicantFromWatchList({
  applicantId,
  handleClose,
}: Props) {
  const t = useI18n([APPLICANT_GENERAL_INFORMATION_KEY], {
    keys: applicantGeneralInformationEn,
  })

  const { mutate, isLoading, isSuccess } = useAddApplicantToBlocklistMutation({
    applicantId,
  })

  return (
    <ApplicantActionModalWrapper<AddToBlocklistData>
      heading={t('action.removeFromWatchList')}
      iconName="mdiEyeOutline"
      isProgress={isLoading}
      isSuccess={isSuccess}
      onClose={handleClose}
      onSubmit={mutate}
      submitText={t('action.removeFromWatchList')}
      confirmation={{
        description: t('action.description.areYouSureToRemoveWatchlist'),
        confirmText: t('action.confirm.removeFromWatchlist'),
      }}
      defaultValues={{ flag: 'false_positive', flagType: 'watchlist' }}
      renderForm={({ control }) => (
        <>
          <span
            className="font-bold"
            data-qa={removeApplicantWatchlist.description}
          >
            {t('action.description.removeFromWatchlist')}
          </span>

          <span>{t('action.description.removeAdditionalRiskFactor')}</span>

          <TextAreaFormField
            label={t('action.fields.comment')}
            placeholder={t('action.fields.commentPlaceholder')}
            control={control}
            name="comment"
            rules={{ required: true }}
          />
        </>
      )}
    />
  )
}
