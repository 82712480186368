import React from 'react'

import { FrankieIcon, FrankieIconName, FrankieTooltip } from 'frankify/src'

type Props = {
  title: string
  subtitle: string
  icon?: FrankieIconName
  className?: string
}
export function NameCell({ title, subtitle, icon, className = '' }: Props) {
  return (
    <div
      className={`w-full h-full px-4 py-3 flex items-center whitespace-nowrap ${className}`}
    >
      <div className="flex flex-row items-center">
        {icon && (
          <FrankieIcon
            name={icon}
            className="text-tertiary-grey-400"
            size="sm"
          />
        )}
        <FrankieTooltip
          position="top"
          body={
            <div>
              <div>{title}</div>
              <div>{subtitle}</div>
            </div>
          }
          className="!whitespace-nowrap !max-w-[600px]"
        >
          <div className="flex shrink flex-col min-w-0 justify-center ml-2">
            <span className="data-hj-suppress text-sm font-semibold text-tertiary-grey-700 text-ellipsis overflow-hidden">
              {title}
            </span>

            <span className="text-xs font-normal text-tertiary-grey-500 text-ellipsis overflow-hidden">
              {subtitle}
            </span>
          </div>
        </FrankieTooltip>
      </div>
    </div>
  )
}
