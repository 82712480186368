export const loginEn = {
  heading: 'Login',
  subheading: 'Enter your details below to login to portal',
  button: {
    submit: {
      default: 'Login',
    },
    resetPassword: {
      default: 'Reset your password',
    },
  },
  errors: {
    unspecific: 'Something went wrong',
    '404':
      'The email or password entered does not match our records. Please try again.',
    '404_one':
      'The email or password entered does not match our records. ' +
      'You have <strong>one</strong> more attempt before your account is locked or try resetting your password.',
    '404_other':
      'The email or password entered does not match our records. ' +
      'You have {{count}} more attempts before your account is locked or try resetting your password.',
    '500': 'There was a problem logging in.',
    '503': 'The service is currently unavailable.',
  },
}
