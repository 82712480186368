import { APPLICANT_POTENTIAL_MATCHES_KEYS as ASDK } from '../applicant-potential-matches.keys'

const appSuppKey = `${ASDK}-app-supp-key`

export const appPotentialMatchesQa = {
  wrapper: `${appSuppKey}-wrapper`,
  emptyWrapper: `${appSuppKey}-wrapper`,
  headerWrapper: `${appSuppKey}-header-wrapper`,
  mainWrapper: `${appSuppKey}-main-wrapper`,
} satisfies Record<string, `${typeof appSuppKey}-${string}`>
