import { AxiosProgressEvent } from 'axios'

import { bffClient, IClient } from 'shared/client'

import { Scan } from '../model/document.model'

export type DocumentUploadResponse = {
  id: string
}

export class DocumentApi {
  client: IClient

  constructor(client: IClient) {
    this.client = client
  }

  async uploadDocument(file: File, appendTo = 'the-file') {
    const formData = new FormData()
    formData.append(appendTo, file)

    return this.client.post<DocumentUploadResponse, FormData>(
      '/data/v1/file',
      formData,
      { headers: { 'Content-Type': 'multipart/form-data' } },
    )
  }

  async uploadDocumentWithProgress(
    file: File,
    appendTo = 'the-file',
    onUploadProgress?: (progressEvent: AxiosProgressEvent) => void,
  ) {
    const formData = new FormData()
    formData.append(appendTo, file)

    return this.client.post<DocumentUploadResponse, FormData>(
      '/data/v1/file',
      formData,
      { headers: { 'Content-Type': 'multipart/form-data' }, onUploadProgress },
    )
  }

  async getDocumentScans(documentId: string) {
    return this.client.get<Scan[]>(`/data/v1/documents/${documentId}/scans`)
  }
}

export const documentApi = new DocumentApi(bffClient)
