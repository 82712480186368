import React from 'react'

import { ApplicantId } from 'entities/applicant'

import { Noop } from 'shared/typescript'

import { businessOwnerShipDataGridActionMenuQa } from '../../../../qa/applicant-business-ownership-international.qa'
import { useApplicantAssociateInternationalEntityState } from '../../../../state/applicant-associate-international-entity-query/applicant-associate-international-entity.query'
import { ApplicantAssociateInternationalParties } from '../../../applicant-associate-international-parties/applicant-associate-international-parties'

export type UpdateAssociatedPartyProps = {
  /** Entity Id of the associated party */
  entityId: ApplicantId
  /** Entity Id of the applicant */
  applicantId: ApplicantId
  onClose: Noop
}

export function EditAssociatedPartyModal({
  entityId,
  applicantId,
  onClose,
}: UpdateAssociatedPartyProps) {
  const { associatedEntry } = useApplicantAssociateInternationalEntityState({
    applicantId,
  })

  const associatedParty = associatedEntry.find(
    party => party.entityId === entityId,
  )

  if (!associatedParty) return null

  return (
    <ApplicantAssociateInternationalParties
      testId={{ container: businessOwnerShipDataGridActionMenuQa.container }}
      applicantId={applicantId}
      associationType="updating"
      entityId={entityId}
      defaultValues={{
        beneficiallyOwnership: associatedParty.beneficiallyHeld
          ? `${associatedParty.beneficiallyHeld}`
          : '',
        nonBeneficiallyOwnership: associatedParty.nonBeneficiallyHeld
          ? `${associatedParty.nonBeneficiallyHeld}`
          : '',
        total: associatedParty.totalHeld ? `${associatedParty.totalHeld}` : '',
        entityId: associatedParty.entityId,
        otherRoleTypeDescription: associatedParty.otherRoleTypeDescription,
        roles: associatedParty.roles,
      }}
      onClose={onClose}
    />
  )
}
