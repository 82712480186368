import React, { useMemo, useState } from 'react'

import { SubmitHandler, useForm } from 'react-hook-form'
import { Trans } from 'react-i18next'

import { FrankieAlert, FrankieButton, FrankieTextField } from 'frankify/src'

import { copyToClipboard } from 'shared/clipboard'
import { useI18n } from 'shared/i18n'
import { QrCode } from 'shared/qr-code'

import { MFA_KEY } from '../../../mfa.key'
import {
  getMfaCodeRegisterOptions,
  IMfaCodeInputs,
  MFA_CODE_LENGTH,
  MfaCodeInputTypes,
} from '../../../model/mfa-code-form.model'
import { mfaQa } from '../../../qa/mfa.qa'

type Props = {
  qrCodeValue: string
  mfaSecret: string
  onBack: () => void
  onSubmit: ({ code }: { code: string }) => Promise<void>
}

export function EnableMfaAppCodeForm({
  qrCodeValue,
  onBack,
  onSubmit,
  mfaSecret,
}: Props) {
  const t = useI18n([MFA_KEY])
  const [errorMessage, setErrorMessage] = useState<string>('')

  const {
    register,
    handleSubmit,
    formState: { isDirty, isValid, errors },
  } = useForm<IMfaCodeInputs>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
  })

  const isFormDisabled: boolean = !isDirty || !isValid

  const codeRegister = useMemo(
    () => register(MfaCodeInputTypes.Code, getMfaCodeRegisterOptions(t)),
    [register, t],
  )

  const handleBack = () => {
    onBack()
  }

  const handleCopyKey = async () => {
    await copyToClipboard(mfaSecret)
  }

  const handleFormSubmit: SubmitHandler<IMfaCodeInputs> = async ({ code }) => {
    try {
      setErrorMessage('')
      await onSubmit({ code })
    } catch (error) {
      setErrorMessage(t('form.errors.403'))
    }
  }

  return (
    <form
      onSubmit={handleSubmit(handleFormSubmit)}
      className="min-h-[428px] flex flex-initial flex-col justify-between"
    >
      <div>
        <div
          data-qa={mfaQa.mfaEnableApp_verifyCode_header}
          className="text-2xl leading-8 font-bold mb-4 text-tertiary-grey-800"
        >
          {t('enableMfaApp.heading')}
        </div>
        <div className="text-lg font-semibold mb-3 text-tertiary-grey-700">
          {t('enableMfaApp.h2.scanQr')}
        </div>
        <div className="flex flex-initial justify-between items-center mb-6">
          <div className="basis-1/2">
            <QrCode
              size={140}
              value={qrCodeValue}
              testId={{ code: mfaQa.mfaEnableApp_verifyCode_qr_code }}
            />
          </div>
          <div className="basis-1/2">
            <div
              className="text-lg mb-2 text-tertiary-grey-700"
              data-qa={mfaQa.mfaEnableApp_verifyCode_mfaKey}
            >
              {mfaSecret.match(/.{1,4}/g)?.join(' ')}
            </div>
            <FrankieButton
              noStyles
              onClick={handleCopyKey}
              className="text-primary-800 font-normal text-sm leading-4"
              testId={{ button: mfaQa.mfaEnableApp_verifyCode_ctaCopyMfaKey }}
            >
              {t('enableMfaApp.cta.copyKey')}
            </FrankieButton>
          </div>
        </div>
        <div className="text-lg font-semibold mb-3 text-tertiary-grey-700">
          {t('enableMfaApp.h2.enterCode')}
        </div>
        <FrankieTextField
          {...codeRegister}
          maxLength={MFA_CODE_LENGTH}
          label={t('enableMfaApp.codeLabel')}
          error={!!errors[MfaCodeInputTypes.Code]}
          errorText={errors[MfaCodeInputTypes.Code]?.message}
          testId={{ input: mfaQa.codeInput }}
        />
        {errorMessage ? (
          <FrankieAlert
            className="mt-6 text-tertiary-grey-700"
            type="error"
            testId={{ alert: mfaQa.alert }}
          >
            <Trans className="text-tertiary-grey-700">{errorMessage}</Trans>
          </FrankieAlert>
        ) : null}
      </div>
      <div className="mt-6 flex flex-initial justify-end items-center gap-4">
        <FrankieButton
          intent="subtle"
          size="sm"
          onClick={handleBack}
          testId={{ button: mfaQa.mfaEnableApp_verifyCode_ctaCancel }}
        >
          {t('enableMfaApp.cta.back')}
        </FrankieButton>
        <FrankieButton
          disabled={isFormDisabled}
          size="sm"
          type="submit"
          testId={{ button: mfaQa.mfaEnableApp_verifyCode_ctaSubmit }}
        >
          {t('enableMfaApp.cta.verify')}
        </FrankieButton>
      </div>
    </form>
  )
}
