import React from 'react'

import { useI18n } from 'shared/i18n'

import { INDIVIDUAL_AML_RESULT_KEY } from '../../../individual-aml-result.key'
import { individualAmlResultEn } from '../../../locale/individual-aml-result.en'
import { amlPersonalInfoQa } from '../../../qa/individual-aml-result.qa'
import { useAmlData } from '../../../state/individual-aml-result.state'

type Props = {
  entityId: string
  processResultId?: string
  historyPage?: boolean
}
export function IndividualAmlPersonalInfo({
  entityId,
  processResultId,
  historyPage,
}: Props) {
  const t = useI18n(INDIVIDUAL_AML_RESULT_KEY, {
    keys: individualAmlResultEn,
  })

  const { amlData } = useAmlData({ entityId, processResultId, historyPage })

  const { supplementaryData } = amlData

  return (
    <div
      className="pt-5 shrink flex flex-col gap-4 w-full"
      data-qa={amlPersonalInfoQa.container}
    >
      <div className="flex shrink gap-2.5">
        <div
          data-qa={amlPersonalInfoQa.title}
          className="text-tertiary-grey-700 font-bold text-md leading-6"
        >
          {t('individualAmlMatchData.title')}
        </div>

        {!!supplementaryData?.matchData?.strength && (
          <div
            data-qa={amlPersonalInfoQa.matchStrength}
            className="bg-tertiary-red-100 py-[2px] px-[10px] rounded-full font-semibold text-xs leading-4 text-tertiary-red-600 flex items-center flex-grow-0"
          >
            {t('individualAmlMatchData.matchStrength', {
              matchStrength: supplementaryData.matchData.strength,
            })}
          </div>
        )}
      </div>

      <div className="grid grid-flow-col !auto-rows-auto  gap-3 grid-rows-[repeat(6,_auto)] laptop:grid-rows-[repeat(3,_auto)] laptop:grid-cols-2 w-full ">
        <div className="flex  gap-2.5">
          <div
            data-qa={amlPersonalInfoQa.sourceLabel}
            className="min-w-[170px] font-medium text-sm leading-5 text-tertiary-grey-800 "
          >
            {t('individualAmlResolveSingleSummary.fullName')}
          </div>
          <div
            data-qa={amlPersonalInfoQa.sourceValue}
            className="text-sm leading-5 text-tertiary-grey-800 pr-5"
          >
            {supplementaryData?.matchData?.name ?? '-'}
          </div>
        </div>
        <div className="flex  gap-2.5">
          <div className="font-medium min-w-[170px] text-sm leading-5 text-tertiary-grey-800 ">
            {t('individualAmlResolveSingleSummary.aliases')}
          </div>
          <div className="text-sm leading-5 text-tertiary-grey-800 pr-5">
            {supplementaryData?.aliases?.join(', ') ?? '-'}
          </div>
        </div>
        <div className="flex  gap-2.5">
          <div className="font-medium min-w-[170px] text-sm leading-5 text-tertiary-grey-800 ">
            {t('individualAmlResolveSingleSummary.yearOfBirth')}
          </div>
          <div className="text-sm leading-5 text-tertiary-grey-800 pr-5">
            {supplementaryData?.matchData?.date
              ? new Date(supplementaryData.matchData.date).getFullYear()
              : '-'}
          </div>
        </div>
        <div className="flex gap-2.5">
          <div className="laptop:min-w-[140px] min-w-[170px]  font-medium text-sm leading-5 text-tertiary-grey-800">
            {t('individualAmlResolveSingleSummary.countryOfResidence')}
          </div>
          <div className="text-sm leading-5 text-tertiary-grey-800">
            {supplementaryData?.matchData?.countries?.map(i => i.code) ?? '-'}
          </div>
        </div>

        <div className="flex gap-2.5">
          <div className="laptop:min-w-[140px] min-w-[170px] font-medium text-sm leading-5 text-tertiary-grey-800">
            {t('individualAmlResolveSingleSummary.address')}
          </div>
          <div className="text-sm leading-5 text-tertiary-grey-800 pr-5">
            {supplementaryData?.matchData?.address ?? '-'}
          </div>
        </div>

        <div className="flex gap-2.5">
          <div className="laptop:min-w-[140px] min-w-[170px] font-medium text-sm leading-5 text-tertiary-grey-800">
            {t('individualAmlResolveSingleSummary.associates')}
          </div>
          <div className="text-sm leading-5 text-tertiary-grey-800 pr-5">
            {supplementaryData?.relatedParties?.join(', ') ?? '-'}
          </div>
        </div>
      </div>
    </div>
  )
}
