export const applicantBiometricsOcrEn = {
  heading: 'Biometrics / OCR',
  viewResult: 'View the results of Biometrics / OCR checks performed',
  notCompleted: 'Biometrics / OCR checks not completed for this profile.',
  hasCompleted:
    'The customer has completed the process with a digital licence. Biometrics / OCR must be completed or manual override performed.',
  checksMustReRun:
    'Checks must be re-run to apply manual overrides to the overall profile status',
  idvLinkSent:
    'IDV Process Link sent to {{displayName}} on {{currentDate}}. Awaiting new images.',
  sendLink:
    'Send a link to {{applicantName}}’s mobile device to start the Biometric/OCR document capture process.',
  resendLink:
    'Re-send a link to {{applicantName}}’s mobile device to complete the process again.',
  processCompleted:
    'Once the process has been completed the results will be updated here.',
  copyLinkAndSend:
    'Copy the link and send to the applicant by email or message.',
  secureLink: 'Secure Link',
  enableLivenessDetection: 'Enable liveness detection',
  sendLinkToApplicant: 'Send link to the applicant’s mobile number.',
  country: 'Country',
  mobileNumber: 'Mobile Phone Number',
  comment: 'Comment',
  auditPurposeOnly:
    '(For audit report purposes only, comment will not be sent to the applicant)',
  typeComment: 'Type your comment here',

  biometricManuallyFailed: 'The Biometrics/OCR check has been Manually Failed',
  biometricManuallyPassed: 'The Biometrics/OCR check has been Manually Passed',

  ocrMismatch:
    'OCR information does not match the applicant’s personal information',
  ocrExtractedData: 'OCR Extracted Data',
  customerReview: 'Customer Review',
  overrideOutcome: 'Override outcome to',

  restart: 'Restart',
  copied: 'Copied',
  copy: 'Copy',
  done: 'Done',
  back: 'Back',
  none: 'None',
  clear: 'Clear',
  suspected: 'Suspected',
  caution: 'Caution',
  manualPass: 'Manual Pass',
  manualFail: 'Manual Fail',
  rejected: 'Rejected',
  fail: 'Fail',
  complete: 'Complete',
  unchecked: 'Unchecked',
  pending: 'Pending',
  front: 'Front',

  noCheckCompleted: 'No checks completed',
  checkNotCompleted: 'Check not completed',
  facialRecognitionReport: 'Facial Recognition Report',
  documentReport: 'Document Report',
  facialDuplicateReport: 'Facial Duplicate Report',
  ocrReport: 'OCR Report',
  ocrDocument: 'OCR Document',
  facialDuplicates: 'Facial Duplicates',
  passportDocScan: 'Passport Document Scan',
  checkStatus: 'Check Status',
  lastUpdated: 'Last Updated',
  createdDate: 'Created Date',
  possibleDuplicates: 'Possible Duplicates',
  confidenceScore: 'Confidence score',
  facialRecognition: 'Facial Recognition',
  matchingScore: 'Matching score',
  submitted: 'Submitted',
  duplicateSelected: 'Duplicate (selected)',
  clickOnImage: 'Click the image to compare details',
  noPossibleDup: 'No possible duplicates found',

  process: {
    start: 'Start Biometrics/OCR Process',
    restart: 'Restart Biometrics/OCR Process',
    sendLink: 'Send Link via Text Message',
    copyLink: 'Copy the Link',
  },

  error: {
    copy: 'Error in copying the link',
    noLinkCopy: 'No link to copy',
    linkGeneration: 'Link Generation failed',
    linkNotSent: 'Failed to send Text Message',
    refresh: 'There was an issue refreshing results',
  },

  success: {
    linkSent: 'Link sent successfully',
    statusChanged: 'Status changed successfully',
    reRunCheck: 'Checks re-run successful',
    refresh: 'Results refreshed successfully',
  },

  loading: {
    link: 'Fetching link...',
    scan: 'Fetching scans...',
    sendingLink: 'Sending link...',
    update: 'Updating...',
    default: 'Loading...',
    refreshing: 'Refreshing...',
    process: 'Processing...',
    biometric: 'Loading biometric info...',
  },

  action: {
    sendLinkText: 'Send Link via Text Message',
    copyLink: 'Copy Link',
    cancel: 'Cancel',
    sendLink: 'Send Link',
    reRunCheck: 'Re-run checks',
    manualOverride: 'Manual Override',
    restartBiometrics: 'Restart Biometrics',
    startBiometrics: 'Start Biometrics',
    refreshBiometrics: 'Refresh Results',
    changeStatus: 'Change Status',
    viewProfile: 'View Profile',
  },

  ocrField: {
    firstName: 'First Name',
    middleName: 'Middle Name',
    lastName: 'Last Name',
    dob: 'Date of Birth',
    nativeFirstName: 'Native First Name',
    nativeLastName: 'Native Last Name',
    buddhistDOB: 'Buddhist Date of Birth',
    address: 'Address',
    documentType: 'Document Type',
    licenseNumber: 'License Number',
    passportNumber: 'Passport/Document Number',
    documentNumber: 'Document Number',
    cardNumber: 'Card Number',
    issuingState: 'Issuing State',
    issuingCountry: 'Issuing Country',
    dateOfIssue: 'Date of Issue',
    dateOfExpiry: 'Date of Expiry',
    laserCode: 'Laser Code',
  },

  applicantField: {
    fullName: 'Full Name',
    entityId: 'Entity ID',
    dateOfBirth: 'Date of birth',
    address: 'Address',
    createdOn: 'Created on',
  },

  checkResult: {
    biometry: {
      clear: 'The document has been recognised as a valid identity document',
      caution:
        'The image does not match the image in the document submitted by the applicant',
      suspected: 'Document shows signs of suspected fraud',
      rejected: 'The document is not recognised as a valid identity document',
      manualPass: 'Facial report has been manually passed',
      manualFail: 'Facial report has been manually failed',
    },
    document: {
      clear: 'Document shows no indication of being fraudulent',
      caution:
        'Document can be processed, but image quality is too low to make fraud assessment',
      suspected: 'Document shows signs of suspected fraud',
      rejected: 'An issue has been detected with your document',
      manualPass: 'Document report has been manually passed',
      manualFail: 'Document report has been manually failed',
    },
    ocr: {
      clear:
        'The customer entered data was successfully matched against the OCR extracted data',
      caution:
        'The information read from the document does not match the information entered by the applicant',
      suspected: 'Document shows signs of suspected fraud',
      rejected: 'The document is not recognised as a valid identity document',
      manualPass: 'Document report has been manually passed',
      manualFail: 'Document report has been manually failed',
    },
    facial: {
      multipleDuplicate: '{{count}} possible duplicates has been detected',
      duplicate: '1 possible duplicate has been detected',
    },
  },
}
