import { useCallback, useEffect, useMemo, useState } from 'react'

import { useMutation } from '@tanstack/react-query'

import {
  APPLICANT_STATES,
  ApplicantId,
  useApplicantDataQuery,
  useApplicantState,
} from 'entities/applicant'
import { useHasFeatureFlag } from 'entities/session'

import { ErrorResponse } from 'shared/http'
import { useI18n } from 'shared/i18n'
import { useNotification } from 'shared/notification'

import { applicantPepSactionsApi } from '../../api/applicant-pep-sactions'
import { APPLICANT_PEP_SACTIONS_KEY } from '../../applicant-pep-sactions.key'
import { applicantPepSactionsEn } from '../../locale/applicant-pep-sactions.en'

type Args = {
  applicantId: ApplicantId
}

type MutateArgs = {
  flag: boolean
}

export const useApplicantToggleOngoingAmlState = ({ applicantId }: Args) => {
  const t = useI18n(APPLICANT_PEP_SACTIONS_KEY, {
    keys: applicantPepSactionsEn,
  })

  const { showNotification } = useNotification()

  const { data: applicantData } = useApplicantDataQuery({ applicantId })
  const applicantState = useApplicantState({ applicantId })

  const applicantName = useMemo(
    () =>
      applicantData?.businessInfo
        ? applicantData.businessInfo.businessName
        : applicantState.applicantName,
    [applicantData, applicantState.applicantName],
  )

  const { hasAmlOngoingMonitoring } = useHasFeatureFlag({
    hasAmlOngoingMonitoring: 'amlOngoingMonitoring',
  })

  const [amlStatus, setAmlStatus] = useState(false)

  const { mutate, isLoading } = useMutation({
    mutationFn: (data: MutateArgs) =>
      applicantPepSactionsApi.toggleOngoingAml(applicantId, data),
    onSuccess: () => {
      showNotification({
        message: t('success.ongoingAml', {
          // reverse as state is changed before showing notification
          state: !amlStatus ? t('state.disabled') : t('state.enabled'),
          applicantName,
        }),
        type: 'success',
      })
    },

    onError: (error: ErrorResponse, { flag }) => {
      const isPermissionDenied = error.response?.status === 403

      showNotification({
        message: `${t('error.ongoingAmlError', {
          errorState: amlStatus ? t('state.enabling') : t('state.disabling'),
        })} ${
          isPermissionDenied
            ? t('error.permissionDenied')
            : t('error.pleaseTryAgain')
        }`,
        type: 'error',
      })
      setAmlStatus(!flag) // Resetting it to original if error
    },
  })

  /**
   * Check to show ongoing toggle
   */
  const showOngoingAmlToggle = useMemo(() => {
    const { STATE_PASSED, STATE_MANUAL_PASSED, STATE_PASS } = APPLICANT_STATES

    const isInMonitoring = [
      STATE_PASSED,
      STATE_MANUAL_PASSED,
      STATE_PASS,
    ].includes(applicantData?.checkSummary.status.type ?? '')

    const hasPepCheck = applicantData?.checkSummary.checkResults.some(
      check => check.type === 'pep_media' || check.type === 'pep',
    )

    return isInMonitoring && hasPepCheck && hasAmlOngoingMonitoring
  }, [applicantData, hasAmlOngoingMonitoring])

  /**
   * Ongoing aml value
   */
  useEffect(() => {
    const amlFlag = applicantData?.applicantDetails.flags?.find(
      flag => flag.flag === 'ongoing_aml_disabled',
    )

    if (!amlFlag) {
      setAmlStatus(false)
    } else {
      const flagValue = !amlFlag.value
      setAmlStatus(flagValue)
    }
  }, [applicantData?.applicantDetails.flags])

  /**
   * To toggle ongoing aml status
   */
  const toggleAmlStatus = useCallback(() => {
    mutate({ flag: !amlStatus })
    setAmlStatus(!amlStatus)
  }, [amlStatus, applicantName, mutate, showNotification, t])

  return {
    amlStatus,
    toggleAmlStatus,
    showOngoingAmlToggle,
    isLoading,
  }
}
