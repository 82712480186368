import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'

import { StorageKeyTypes, getLocalStorageState } from 'shared/local-storage'

import { Nullable } from '../../typescript'

export interface IClient {
  setToken(token: string): void

  clearToken(): void

  getConfig(config?: AxiosRequestConfig): AxiosRequestConfig

  get<T>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>>

  post<T, D = void>(
    url: string,
    data?: D,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<T>>

  patch<T, D>(
    url: string,
    data?: D,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<T>>

  put<T, D>(
    url: string,
    data: D,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<T>>

  delete<T>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>>
}

export class Client implements IClient {
  private client: AxiosInstance

  private token: Nullable<string> = null

  constructor(instance: AxiosInstance) {
    this.client = instance
  }

  setToken(token: string) {
    this.token = token
  }

  clearToken() {
    this.token = null
  }

  getToken(): string | null {
    if (this.token) return this.token

    const [token] = getLocalStorageState(StorageKeyTypes.Token)

    if (token) return token as string

    return null
  }

  getConfig(config?: AxiosRequestConfig): AxiosRequestConfig {
    return {
      ...config,
      headers:
        this.getToken() || config?.headers
          ? {
              ...(this.getToken() ? { auth: this.getToken() } : {}),
              ...config?.headers,
            }
          : undefined,
    }
  }

  async get<T>(
    url: string,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<T>> {
    return this.client.get<T>(url, this.getConfig(config))
  }

  async post<T, D>(
    url: string,
    data: D,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<T>> {
    return this.client.post<T>(url, data, this.getConfig(config))
  }

  async patch<T, D>(
    url: string,
    data?: D,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<T>> {
    return this.client.patch<T>(url, data, this.getConfig(config))
  }

  async put<T, D>(
    url: string,
    data: D,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<T>> {
    return this.client.put<T>(url, data, this.getConfig(config))
  }

  async delete<T>(
    url: string,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<T>> {
    return this.client.delete<T>(url, this.getConfig(config))
  }
}
