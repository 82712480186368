import { ROLE_MANAGEMENT_KEY } from '../role-management.key'

export const roleManagementQa = {
  roleTable_roleList: 'role-management-role-table-role-list',

  roleEditForm_inputName: 'role-management-form-edit-input-name',
  roleEditForm_inputDescription: 'role-management-form-edit-input-description',
  roleEditForm_editCta: 'role-management-form-edit-cta-edit',
  roleEditForm_cancelCta: 'role-management-form-edit-cta-cancel',

  roleRemoveConfirm_heading: 'role-management-remove-role-heading',
  roleRemoveConfirm_confirmCta: 'role-management-remove-role-cta-confirm',
  roleRemoveConfirm_cancelCta: 'role-management-remove-role-cta-cancel',
} satisfies Record<string, `${typeof ROLE_MANAGEMENT_KEY}-${string}`>
