import React from 'react'

import { FrankieLoader } from 'frankify/src'

import { useApplicantSingleSpaOverviewPath } from 'entities/applicant'
import { IMatchedOrganization, useRunAmlCheckIKyb } from 'entities/organisation'
import { ProfileGenerate, RunCheckTypes } from 'entities/profile-check'
import { useHasFeatureFlag } from 'entities/session'

import { useI18n } from 'shared/i18n'
import { notification } from 'shared/notification'

import { ORGANISATION_AUDIT_KEY } from '../../locale/organisation-audit.en'

type Props = {
  organisationInfo: IMatchedOrganization
  className?: string
}

export function OrganisationRunAml({ organisationInfo, className }: Props) {
  const t = useI18n([ORGANISATION_AUDIT_KEY])

  const { internationalKybAML } = useHasFeatureFlag({
    internationalKybAML: 'internationalKybAML',
  })

  const { runAmlCheck, isLoading } = useRunAmlCheckIKyb('new')

  const { navigateToOverview } = useApplicantSingleSpaOverviewPath()

  const handleRunAml = async () => {
    try {
      const { entityId } = await runAmlCheck({ organisationInfo })
      navigateToOverview(entityId, 'onboarding')
    } catch (error) {
      notification.error(t('errorAmlCheck'))
    }
  }

  if (!internationalKybAML) {
    return null
  }

  return (
    <>
      <FrankieLoader
        fullscreen
        loading={isLoading}
        label={
          <div className="text-tertiary-grey-800 font-semibold">
            {t('runningAmlCheck')}
          </div>
        }
      />
      <ProfileGenerate
        className={className}
        heading={t('runCheckOrganisation')}
        label={t('runCheck')}
        placeholder={t('selectCheck')}
        options={[
          {
            title: t('amlScreening'),
            value: RunCheckTypes.AML,
            description: t('internationalMmlScreeningSubtitle'),
          },
        ]}
        onSubmit={handleRunAml}
      />
    </>
  )
}
