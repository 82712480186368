import { useMemo, useState } from 'react'

import { useQuery } from '@tanstack/react-query'

import { ApplicantId, ApplicantQueryKeysTypes } from 'entities/applicant'

import { isYearOnly } from 'shared/date-time'

import { applicantPepSactionsApi } from '../../api/applicant-pep-sactions'
import { PepSactionsMatchRecord } from '../../model/applicant-pep-sactions.model'

type Args = {
  applicantId: ApplicantId
}

export const { ApplicantPepSactionsQueryKey } = ApplicantQueryKeysTypes

export const useApplicantPepSactionsQuery = ({ applicantId }: Args) =>
  useQuery({
    enabled: !!applicantId,
    queryKey: [ApplicantPepSactionsQueryKey, applicantId],

    queryFn: async () => {
      const { data } = await applicantPepSactionsApi.getMatches(applicantId)
      return data
    },
  })

export const useApplicantPepSactionsGirdData = ({ applicantId }: Args) => {
  const [selectedMatchIds, setSelectedMatchIds] = useState<string[]>([])

  const { data, isFetching } = useApplicantPepSactionsQuery({
    applicantId,
  })

  const rows = useMemo(
    () =>
      (data?.matches ?? []).map(
        match =>
          ({
            id: match.groupId,
            name: match.name,
            dob: match.dateOfBirth ? isYearOnly(match.dateOfBirth, true) : '',
            countries: match.countries.sort().join(', '),
            issues: match.pawsAlert,
            matchStatus: match.matchStatus.status ?? '',
            matchScore: match.matchStrength ?? 0,
          } as PepSactionsMatchRecord),
      ),
    [data?.matches],
  )

  return {
    rows,
    totalRecords: rows.length,
    selectedMatchIds,
    setSelectedMatchIds,
    loading: isFetching,
  }
}
