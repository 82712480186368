import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'

import { ApplicantDataQueryKey, ApplicantId } from 'entities/applicant'

import { ErrorResponse } from 'shared/http'
import { useI18n } from 'shared/i18n'
import { notification } from 'shared/notification'

import { blocklistApi } from '../api/blocklist.api'
import { APPLICANT_BLOCKLISTED_INFO_KEY } from '../applicant-bllocklisted-info.key'
import { applicantBlocklistedInfoEn } from '../locale/applicant-blocklisted-info.en'
import {
  AddToBlocklistType,
  CheckBlocklistPayloadTypes,
} from '../model/add-to-blocklist.model'

type Args = {
  getApplicantGeneralInfoPath: (applicantId: ApplicantId) => string
  isUpdate: boolean
}

type CheckBlocklistData = {
  entityId: ApplicantId
  payload: CheckBlocklistPayloadTypes
}

type MutateArgs = {
  data: AddToBlocklistType
  checkBlocklistData: CheckBlocklistData
}

export const useSaveBlocklist = ({
  getApplicantGeneralInfoPath,
  isUpdate,
}: Args) => {
  const navigate = useNavigate()

  const t = useI18n([APPLICANT_BLOCKLISTED_INFO_KEY], {
    keys: applicantBlocklistedInfoEn,
  })

  const queryClient = useQueryClient({})

  const { mutate: checkBlocklist, isLoading: checkBlocklistLoading } =
    useMutation({
      mutationFn: async ({ entityId, payload }: CheckBlocklistData) =>
        blocklistApi.checkBlocklist(entityId, payload),
      onError: (err: ErrorResponse) => {
        notification.error(err.response?.data.message as string)
      },
      onSuccess: (_, variables) => {
        const path = getApplicantGeneralInfoPath(variables.entityId)
        notification.success(t(isUpdate ? 'success.update' : 'success.creates'))
        void queryClient.refetchQueries([
          ApplicantDataQueryKey,
          variables.entityId,
        ])
        navigate(path)
      },
    })

  const {
    mutate: addToBlocklist,
    isSuccess: isAddedSuccessfully,
    isLoading: addToBlocklistLoading,
  } = useMutation({
    mutationFn: async ({ data }: MutateArgs) =>
      blocklistApi.addToBlocklist(data),
    onError: (err: ErrorResponse) => {
      notification.error(err.response?.data.message as string)
    },
    onSuccess: (data, variables) => {
      checkBlocklist({
        ...variables.checkBlocklistData,
        entityId: data.data.entity.entityId,
      })
    },
  })

  return {
    addToBlocklist,
    isAddedSuccessfully,
    isLoading: addToBlocklistLoading || checkBlocklistLoading,
  }
}
