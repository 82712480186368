import React, { useMemo } from 'react'

import { Controller, SubmitHandler, useForm } from 'react-hook-form'

import { FrankieButton } from 'frankify/src'

import { IProfileFilter, ProfileIssueCategoryTypes } from 'entities/entity'

import { CheckboxGroup, IOption } from 'shared/filters-common'
import { useI18n } from 'shared/i18n'

import {
  PROFILES_FILTER_KEY,
  profilesFilterEn,
} from '../../locale/profiles-filter.en'
import {
  profileIssueCategory2Columns,
  profileIssuesCategoriesI18n,
} from '../../model/profiles-filter-form.model'
import { profileGlobalFilterDefault } from '../../model/profiles-filter.model'
import { applicantsFilterQa } from '../../qa/applicants-filter.qa'

type IProfileIssuesFilter = Pick<IProfileFilter, 'issueCategories'>

const defaultValues: IProfileIssuesFilter = profileGlobalFilterDefault

type Props = {
  onClose: () => void
  initialValues?: IProfileIssuesFilter
  onSubmit: (filters: IProfileIssuesFilter) => void
}

export function ProfileIssuesForm({ onClose, initialValues, onSubmit }: Props) {
  const t = useI18n([PROFILES_FILTER_KEY], { keys: profilesFilterEn })

  const issueOptions = useMemo<IOption<ProfileIssueCategoryTypes>[][]>(
    () =>
      profileIssueCategory2Columns.map(column =>
        column.map(option => ({
          value: option,
          label: t(profileIssuesCategoriesI18n[option]),
        })),
      ),
    [t],
  )

  const {
    handleSubmit,
    control,
    formState: { isDirty },
  } = useForm<IProfileIssuesFilter>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    values: initialValues || defaultValues,
  })

  const handleClear = () => {
    onSubmit({
      ...initialValues,
      issueCategories: defaultValues.issueCategories,
    })
    onClose()
  }

  const handleFormSubmit: SubmitHandler<IProfileIssuesFilter> = data => {
    onSubmit(data)
    onClose()
  }

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} className="w-full p-4">
      <Controller
        control={control}
        name="issueCategories"
        render={({ field: { onChange, value } }) => (
          <CheckboxGroup
            testId={{ checkbox: applicantsFilterQa.inputs.issueOption }}
            optionGroups={issueOptions}
            onChange={onChange}
            values={value}
          />
        )}
      />
      <div className="flex flex-row items-center gap-3 mt-4">
        <FrankieButton
          testId={{ button: applicantsFilterQa.cta.applySingleFilter }}
          size="xs"
          disabled={!isDirty}
          type="submit"
          className="w-[68px]"
        >
          {t('form.cta.apply')}
        </FrankieButton>
        <FrankieButton
          testId={{ button: applicantsFilterQa.cta.clearSingleFilter }}
          size="xs"
          intent="darkOutline"
          onClick={handleClear}
          className="w-[68px]"
        >
          {t('form.cta.clear')}
        </FrankieButton>
      </div>
    </form>
  )
}
