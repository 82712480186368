export const applicantPepSactionsEn = {
  heading: 'AML profile database search results',
  hasTruePositiveMatch:
    'This applicant has a true positive match. Do you want to approve or reject them?',

  amlResult: 'AML database result details',

  searchResults: 'AML profile database search results',
  ongoingAML: 'Ongoing AML',
  results: 'Results',
  result: 'Result',
  outOfTotal: '{{current}} of {{total}}',
  itemSelected: 'item selected',
  itemsSelected: 'items selected',

  published: 'Published',
  listing: 'Listing',
  currentlyOn: 'Currently on',

  state: {
    enabled: 'enabled',
    disabled: 'disabled',
    enabling: 'enabling',
    disabling: 'disabling',
  },

  tabs: {
    keyData: 'Key Data',
    adverseMedia: 'Adverse Media',
    dataSources: 'Data Sources',
  },

  status: {
    true: 'True Positive',
    false: 'False Positive',
    unknown: 'Unknown',
    possible: 'Possible Match',
    approved: 'True Positive Approved',
    rejected: 'True Positive Rejected',
  },

  loading: {
    default: 'Loading...',
    update: 'Updating...',
    records: 'Fetching records...',
  },

  empty: {
    dataSources: 'There are no supporting documents available for this profile',
    records: 'No records found',
    articles: 'No additional articles found',
    adverseMedia: 'There is no adverse media available for this profile',
    data: 'No data to display',
  },

  success: {
    changeStatus: 'Status changed successfully',
    ongoingAml:
      'Ongoing AML has been successfully {{state}} for {{applicantName}}.',
  },

  error: {
    pleaseTryAgain: 'Please try again.',
    permissionDenied: 'Permission denied',
    changeStatus: 'Error changing status',
    ongoingAmlError: 'There was an issue {{errorState}} Ongoing AML.',
  },

  field: {
    changeMatch: 'Change match status to',
    comment: 'Comment',
    commentPlaceholder: 'Type your comment here',

    name: 'Name',
    yearOfBirth: 'Year of Birth',
    countries: 'Countries',
    searchDate: 'Search Date',
    searchFuzziness: 'Search Fuzziness',
    vendorCaseId: 'Vendor Case ID',
    search: 'Search Details',
    returned: 'Returned Details',
    dob: 'Date of Birth',
    fullName: 'Full Name',
    aka: 'AKA',
    addresses: 'Addresses',
    associates: 'Associates',
    issues: 'Issues',
    matchStrength: 'Match Strength',
    matchStatus: 'Match Status',
    selected: 'Selected',
    age: 'Age',
  },

  listingFields: {
    sanctions: 'Sanctions',
    watchlists: 'Watchlists',
    pepsPep: 'Pep',
    pepsPepClass1: 'Pep Class 1',
    pepsPepClass2: 'Pep Class 2',
    pepsPepClass3: 'Pep Class 3',
    pepsPepClass4: 'Pep Class 4',
  },

  action: {
    cancel: 'Cancel',
    changeStatus: 'Change status',
    changeMatchStatus: 'Change Match status',
    approve: 'Approve',
    reject: 'Reject',
    search: 'Search',
    prev: 'Prev',
    next: 'Next',
  },
}
