import React, { useMemo } from 'react'

import {
  DataGridPro,
  DataGridProProps,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  gridClasses,
  GridColDef,
  GridColumnHeaderParams,
  GridSortModel,
  GridValidRowModel,
} from '@mui/x-data-grid-pro'

import { FrankieTableHeaderCell } from 'frankify/src'

import { WithProps } from 'shared/hoc'

import { GridCheckbox } from '../grid-checkbox/grid-checkbox'

type DataGridHeaderProps<R extends GridValidRowModel> =
  GridColumnHeaderParams<R> & {
    sortModel?: GridSortModel
  }

export function DataGridHeader<R extends GridValidRowModel>({
  colDef,
  sortModel,
}: DataGridHeaderProps<R>) {
  const fieldName = colDef.field

  const currentSortValue = useMemo(
    () => sortModel?.find(item => item.field === fieldName)?.sort || 'off',
    [sortModel, fieldName],
  )

  if (!colDef.sortable) {
    return colDef.headerName
  }

  return (
    <FrankieTableHeaderCell
      className="!px-0 flex-row-reverse text-tertiary-grey-500 gap-1 leading-4 bg-tertiary-grey-50"
      sortValue={currentSortValue}
    >
      {colDef.headerName}
    </FrankieTableHeaderCell>
  )
}

export type DefaultGirdColDef<R extends GridValidRowModel> = GridColDef<R> & {
  field: keyof R
}

type TableProps<R extends GridValidRowModel> = DataGridProProps<R> & {
  tableMinWidth?: number
  tableMinHeight?: number
  defaultColumnWidth?: number
  testId?: {
    container?: string
  }
  columns: DefaultGirdColDef<R>[]
}

export function DefaultDataGrid<R extends GridValidRowModel>({
  columns,
  tableMinWidth = 650,
  tableMinHeight = 100,
  defaultColumnWidth = 150,
  testId,
  rowSelectionModel,
  slots,
  ...tableProps
}: TableProps<R>) {
  const checkboxCol = {
    ...GRID_CHECKBOX_SELECTION_COL_DEF,
    width: 40,
    minWidth: 40,
    maxWidth: 40,
    flex: 0,
  }

  return (
    <DataGridPro
      data-qa={testId?.container}
      slots={{
        baseCheckbox: GridCheckbox,
        ...slots,
      }}
      columns={[
        checkboxCol, // TODO: Add prop to Change position as of now its at start
        ...columns,
      ].map(({ width, renderHeader, ...column }) => ({
        sortable: false,
        resizable: false,
        disableColumnMenu: true,
        disableReorder: true,
        hideSortIcons: true,
        minWidth: width ?? defaultColumnWidth,
        flex: (width ?? defaultColumnWidth) / tableMinWidth,
        renderHeader:
          renderHeader ??
          (WithProps(DataGridHeader, {
            sortModel: tableProps.sortModel,
          }) as unknown as undefined),
        ...column,
      }))}
      sx={{
        [`&.${gridClasses.root}`]: {
          border: 'none',
        },
        '& .MuiDataGrid-virtualScroller': {
          overflowY: 'auto !important',
          overflowX: 'hidden',
        },
        '.MuiDataGrid-virtualScrollerContent': {
          minHeight: `${tableMinHeight}px !important`,
        },
        '.MuiDataGrid-columnSeparator': {
          display: 'none',
        },
        '& .MuiDataGrid-columnHeaderTitleContainerContent': {
          height: '100%',
          textTransform: 'uppercase',
        },

        '& .MuiDataGrid-detailPanelToggleCell': {
          opacity: 0.7,
        },
        "& .MuiDataGrid-detailPanelToggleCell[aria-label='Expand']": {
          transform: 'rotate(-90deg)',
        },
        '& .MuiDataGrid-row.Mui-hovered, .MuiDataGrid-row:hover': {
          backgroundColor: 'transparent',
          cursor: tableProps.onRowClick ? 'pointer' : 'default',
        },
        '& .MuiDataGrid-row.Mui-selected': {
          '&:hover': {
            bgcolor: 'transparent !important',
          },
          bgcolor: 'transparent !important',
        },
        '& .MuiDataGrid-cellContent': {
          whiteSpace: 'normal',
          color: 'inherit',
        },
        '& .MuiButtonBase-root.MuiCheckbox-root': {
          '&.Mui-checked': {
            color: 'inherit',
          },
        },
        '& .MuiDataGrid-columnHeaderCheckbox, .MuiDataGrid-cellCheckbox ': {
          justifyContent: 'right',
        },
        '& .MuiDataGrid-columnHeaderCheckbox': {
          '& .MuiDataGrid-columnHeaderTitleContainer': {
            justifyContent: 'right',
          },
        },
      }}
      className="[&_.MuiDataGrid-virtualScrollerContent]:border-neutral-30 [&_.MuiDataGrid-columnHeadersInner]:bg-tertiary-grey-50 [&_.MuiDataGrid-columnHeaderTitle]:text-xs  [&_.MuiDataGrid-columnHeaderTitle]:font-medium [&_.MuiDataGrid-columnHeadersInner]:text-tertiary-grey-500  [&_.MuiDataGrid-columnHeader]:!outline-none"
      getCellClassName={() =>
        '!outline-none !border-neutral-30 !items-start !py-3  break-words'
      }
      getRowClassName={() => ' hover:!bg-transparent !min-h-[45px]'}
      isCellEditable={() => false}
      getRowHeight={() => 'auto'}
      columnHeaderHeight={40}
      disableRowSelectionOnClick
      disableColumnSelector
      disableColumnFilter
      disableColumnMenu
      disableDensitySelector
      showCellVerticalBorder={false}
      showColumnVerticalBorder={false}
      hideFooterPagination
      disableColumnResize
      disableColumnReorder
      disableVirtualization
      disableColumnPinning
      hideFooter
      hideFooterSelectedRowCount
      autoHeight
      autoPageSize
      rowSelectionModel={rowSelectionModel}
      {...tableProps}
    />
  )
}
