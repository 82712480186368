import React, { useMemo, useState } from 'react'

import { IconButton } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import { GridColumnHeaderParams, GridSortModel } from '@mui/x-data-grid-pro'

import {
  FrankieButton,
  FrankieCheckbox,
  FrankieIcon,
  FrankieIconName,
  FrankiePopover,
} from 'frankify/src'

import { blocklistFilters } from 'features/blocklist-table/model/blocklist-config.model'

import { useI18n } from 'shared/i18n'

import { BLOCKLIST_TABLE_KEY } from '../../blocklist-table.key'
import { blocklistTableEn } from '../../locale/blocklist-table.en'
import {
  BlocklistData,
  BlocklistDataProperties,
} from '../../model/blocklist.model'
import { blocklistTableQa } from '../../qa/blocklist-table.qa'
import { BlocklistQueryFilter } from '../../state/blocklist-query/blocklist.query'

export const sortIcons: Record<'asc' | 'desc' | 'default', FrankieIconName> = {
  asc: 'mdiArrowDown',
  desc: 'mdiArrowUp',
  default: 'mdiSwapVertical',
}

const FILTER_CHUNK_SIZE = 8

export type Props = GridColumnHeaderParams<BlocklistData> & {
  sortModel: GridSortModel
  onFilterChange: (args: Partial<BlocklistQueryFilter>) => void
  filterValues?: string[]
  variant?: 'v1' | 'v2'
}

// eslint-disable-next-line complexity
export function BlocklistTableHeader({
  colDef,
  sortModel,
  filterValues = [],
  onFilterChange,
  variant = 'v1',
}: Props) {
  const t = useI18n([BLOCKLIST_TABLE_KEY], { keys: blocklistTableEn })
  const [selectedFilter, setSelectedFilter] = useState<Set<string>>(
    new Set(filterValues),
  )

  const [appliedFilter, setAppliedFilter] = useState<Set<string>>(
    new Set(filterValues),
  )

  const [isOpen, setIsOpen] = useState(false)
  const [isUpdated, setIsUpdated] = useState(false)

  const fieldName = colDef.field as BlocklistDataProperties

  const filter = blocklistFilters[fieldName]

  const currentSortValue = useMemo(
    () => sortModel.find(item => item.field === fieldName)?.sort || 'default',
    [sortModel, fieldName],
  )

  const handleOpen = (open: boolean) => {
    setIsOpen(open)
    setIsUpdated(false)
  }

  const resetSelectedFilter = () => {
    setSelectedFilter(new Set(appliedFilter))
  }

  // Function to toggle item in the set
  const toggleFilter = (item: string) => {
    setIsUpdated(true)
    setSelectedFilter(currentFilter => {
      const currentFilterSet = new Set(currentFilter)

      if (currentFilterSet.has(item)) {
        currentFilterSet.delete(item)
      } else {
        currentFilterSet.add(item)
      }

      return currentFilterSet
    })
  }

  const handleApplyFilter = () => {
    if (filter) {
      setAppliedFilter(new Set(selectedFilter))
      onFilterChange({ [filter.filterName]: [...selectedFilter] })
      handleOpen(false)
    }
  }

  const handleClearFilter = () => {
    if (filter) {
      setAppliedFilter(new Set())
      setSelectedFilter(new Set())
      onFilterChange({ [filter.filterName]: [] })
      handleOpen(false)
    }
  }

  return (
    <div className="relative flex items-center px-4 py-2">
      <IconButton className="p-[2px]">
        <FrankieIcon
          size="xs"
          className={currentSortValue === 'default' ? '' : 'text-primary-800'}
          name={sortIcons[currentSortValue]}
        />
      </IconButton>

      <span className="px-1">{colDef.headerName}</span>

      {filter && (
        <FrankiePopover
          open={isOpen}
          onOpenChange={open => {
            handleOpen(open)
            resetSelectedFilter()
          }}
          popoverRest={{
            placement: 'bottom',
          }}
          trigger={
            <IconButton
              className={`h-[30px] w-[30px] flex items-center px-2 ${
                isOpen || selectedFilter.size > 0
                  ? 'text-primary-700'
                  : 'text-tertiary-grey-400'
              }`}
              onClick={e => {
                e.stopPropagation()
                setIsOpen(prev => {
                  if (!prev) resetSelectedFilter()
                  return !prev
                })
              }}
              data-qa={blocklistTableQa.filter}
            >
              <FrankieIcon size="xs" name="mdiFilter" />
              <FrankieIcon
                size="xs"
                className="mb-[-5px] ms-[-5px]"
                name={isOpen ? 'mdiChevronUp' : 'mdiChevronDown'}
              />
            </IconButton>
          }
        >
          <div className="relative bg-mono-white shadow-md max-h-[310px] overflow-auto rounded-sm">
            {variant === 'v1' && (
              <div className="flex gap-3 pt-4 px-4 min-w-[150px]">
                {Array.from(
                  {
                    length: Math.ceil(
                      filter.filterOptions.length / FILTER_CHUNK_SIZE,
                    ),
                  },
                  (_v, index) =>
                    filter.filterOptions.slice(
                      index * FILTER_CHUNK_SIZE,
                      index * FILTER_CHUNK_SIZE + FILTER_CHUNK_SIZE,
                    ),
                ).map(filterChunks => (
                  <div key={`chunk-${filterChunks[0].value}`} className="">
                    {filterChunks.map(filterOption => (
                      <label
                        className="flex flex-row cursor-pointer mb-3"
                        key={filterOption.value}
                      >
                        <FrankieCheckbox
                          size="sm"
                          checked={selectedFilter.has(filterOption.value)}
                          onChange={() => toggleFilter(filterOption.value)}
                        />
                        <div className="ml-2 leading-tight text-sm">
                          {t(filterOption.tKey)}
                        </div>
                      </label>
                    ))}
                  </div>
                ))}
              </div>
            )}

            {variant === 'v2' && (
              <div className="px-2 pt-2 min-w-[180px]">
                {filter.filterOptions.map(filterOption => (
                  <MenuItem
                    key={filterOption.value}
                    className={`border-b flex justify-between border-tertiary-grey-200 p-2 cursor-pointer text-xs ${
                      selectedFilter.has(filterOption.value)
                        ? 'bg-primary-container'
                        : ''
                    }`}
                    onClick={() => toggleFilter(filterOption.value)}
                  >
                    <div
                      className={`text-xs ${
                        selectedFilter.has(filterOption.value)
                          ? 'text-primary-800'
                          : ''
                      }`}
                    >
                      {t(filterOption.tKey)}
                    </div>

                    <FrankieIcon
                      name="Check"
                      className={`  ${
                        selectedFilter.has(filterOption.value)
                          ? 'text-primary-800'
                          : 'text-tertiary-grey-400'
                      }`}
                      size="xs"
                      library="mui"
                    />
                  </MenuItem>
                ))}
              </div>
            )}

            <div
              className={`flex flex-row items-center gap-3
            ${variant === 'v1' ? 'px-4 pt-1 pb-4' : 'p-2'}
             sticky bottom-0 bg-mono-white`}
            >
              <FrankieButton
                className="basis-1/2 max-w-[80px]"
                size="xs"
                disabled={!isUpdated}
                type="submit"
                onClick={handleApplyFilter}
                testId={{ button: blocklistTableQa.applyFilterButton }}
              >
                {t('filter.apply')}
              </FrankieButton>
              <FrankieButton
                className="basis-1/2 max-w-[80px]"
                size="xs"
                intent="darkOutline"
                onClick={handleClearFilter}
                testId={{ button: blocklistTableQa.clearFilterButton }}
              >
                {t('filter.clear')}
              </FrankieButton>
            </div>
          </div>
        </FrankiePopover>
      )}
    </div>
  )
}
