import React from 'react'

import { PermissionTypes } from 'entities/role'
import { useHasPermission } from 'entities/session'

export function PermissionGuard({
  children,
  permissions,
}: {
  children: JSX.Element
  permissions: PermissionTypes | PermissionTypes[]
}): JSX.Element {
  const { canSeeThePage } = useHasPermission({ canSeeThePage: permissions })

  if (!canSeeThePage) {
    return <div>No access</div>
  }

  return children
}
