import { cva } from 'cva'

export const FrankieSelectStyle = cva(
  [
    'truncate',
    'w-full',
    'rounded-sm',
    'border',
    'border-solid',
    'box-border',
    'px-4',
    'bg-mono-white',
    'text-mono-90',
    'text-sm',
    'cursor-text',
    'placeholder:text-mono-60',
    'focus-visible:border-primary-800',
    'focus-visible:outline-0',
    'disabled:border-tertiary-grey-300',
    'disabled:bg-tertiary-grey-50',
    'disabled:text-tertiary-grey-400',
  ],
  {
    variants: {
      size: {
        sm: ['h-9', 'py-2'],
        md: ['h-10', 'py-2'],
        lg: ['h-13', 'py-2'],
      },
      variant: {
        primary: ['border-tertiary-grey-300'],
        light: ['border-tertiary-grey-200'],
      },
      inputError: {
        true: [
          '!border-tertiary-red-500',
          '!bg-tertiary-red-50',
          '!text-tertiary-red-600',
          'placeholder:!text-tertiary-red-50',
          '[&_.frankie-select-toggle]:!text-tertiary-red-500',
        ],
      },
      clearable: {
        true: ['pr-10'],
        false: ['pr-8'],
      },
      select: {
        primary: [
          'bg-gray-50',
          'border',
          'border-gray-300',
          'text-gray-900',
          'text-sm',
          'rounded-lg',
          'focus:ring-blue-500',
          'focus:border-blue-500',
          'block',
          'w-full',
          'p-2.5',
        ],
      },
    },
  },
)
