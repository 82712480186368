import { UpdateUsersPatch } from 'features/user-management'

import { IRole, RoleId, RoleName } from 'entities/role'
import { IUser } from 'entities/user'

import { IReassignRoleInputs } from '../reassign-roles-form/reassign-roles-form'

export function transDataToApiBody(
  data: IReassignRoleInputs,
  {
    otherRoles,
    userList,
    removedRoleName,
  }: { otherRoles: IRole[]; userList: IUser[]; removedRoleName: RoleName },
): UpdateUsersPatch[] {
  // build a RoleName - RoleId map
  const roleNameIdMap = new Map<RoleName, RoleId>()
  otherRoles.forEach(role => roleNameIdMap.set(role.role, role.id))

  // for each user
  return data.users.map<UpdateUsersPatch>(
    ({ userId, roleName: newRoleName }) => {
      // get all user roleNames
      const oldRoleNames =
        userList.find(user => user.id === userId)?.roles || []

      // filter removedRoleName && map roleNames to roleIds
      const filteredRoleIds = oldRoleNames.reduce<RoleId[]>((arr, oldRole) => {
        if (oldRole !== removedRoleName) {
          const oldRoleId = roleNameIdMap.get(oldRole)
          if (oldRoleId) {
            arr.push(oldRoleId)
          }
        }
        return arr
      }, [])

      // if there is newRoleName, add newRoleId
      if (newRoleName) {
        const newRoleId = roleNameIdMap.get(newRoleName)
        if (newRoleId) {
          return { id: userId, roleIds: [...filteredRoleIds, newRoleId] }
        }
      }

      // otherwise return filtered array
      return { id: userId, roleIds: filteredRoleIds }
    },
  )
}
