import { bffClient, IClient } from 'shared/client'
import { PartialRecord } from 'shared/typescript'

import { IApplicantSupportingDocuments } from '../model/applicant-document.model'
import {
  ApplicantBusinessInfoResponse,
  ApplicantFraudCheckResponse,
  ApplicantResponse,
  ApplicantRiskChecksResponse,
  KybReportType,
} from '../model/applicant-response.model'
import {
  ApplicantHostedFlowPayload,
  SearchApplicantParams,
  SearchApplicantResponse,
} from '../model/applicant.model'

export class ApplicantAPI {
  client: IClient

  constructor(client: IClient) {
    this.client = client
  }

  async getApplicant(entityId: string) {
    return this.client.get<ApplicantResponse>(`data/v1/applicants/${entityId}`)
  }

  async getApplicantRiskChecks(entityId: string) {
    return this.client.get<ApplicantRiskChecksResponse>(
      `data/v1/applicants/${entityId}/checks/risk`,
    )
  }

  async getApplicantDeviceFraudChecks(entityId: string) {
    return this.client.get<ApplicantFraudCheckResponse>(
      `data/v1/applicants/${entityId}/checks/fraud/device`,
    )
  }

  async changeApplicantState(
    entityId: string,
    payload: { comment: string; state: string },
  ) {
    return this.client.put(`data/v1/applicants/${entityId}/state`, payload)
  }

  async addToBlocklist(
    entityId: string,
    payload: {
      flag: string
      flagType?: string
      comment?: string
      reasonCode?: string
    },
  ) {
    /**
     * In the original vue code delete request is made when flag is false_positive
     * This should not be happing as per the convention the DELETE method does not expect a request body.
     * @todo Remove this condition once the API is improved
     */
    if (payload.flag === 'false_positive') {
      return this.client.delete(
        `data/v1/applicants/${entityId}/checks/blacklists`,
        { data: payload },
      )
    }

    return this.client.put(
      `data/v1/applicants/${entityId}/checks/blacklists`,
      payload,
    )
  }

  async generateIndividualReport(entityId: string) {
    return this.client.get<{
      message: string
      entityId: string
      scanDocId: string
      documentId: string
      fileURL: string
    }>(`data/v1/kyc/${entityId}/generate-pdf`)
  }

  async assignUser(entityId: string, data: { assignee: string }) {
    return this.client.put(`data/v1/applicants/${entityId}/assign`, data)
  }

  async addComments(
    entityId: string,
    data: { comment: string; location: string },
  ) {
    return this.client.post(`data/v1/applicants/${entityId}/comments`, data)
  }

  async getApplicantSupportedDocument(entityId: string) {
    return this.client.get<IApplicantSupportingDocuments[]>(
      `data/v1/applicant/${entityId}/supporting-documents`,
    )
  }

  async getApplicantBusinessReport(
    body: { business: ApplicantBusinessInfoResponse },
    params: { level: KybReportType },
  ) {
    return this.client.post<
      { entityId: string; requestId: string },
      { business: ApplicantBusinessInfoResponse }
    >('/data/v2/business/report', body, { params })
  }

  async getHostedFlow(entityId: string, payload: ApplicantHostedFlowPayload) {
    return this.client.post<{ smsLink: string }, ApplicantHostedFlowPayload>(
      `data/v1/kyc/applicant/${entityId}/hosted-flow`,
      {
        pushToSms: false,
        comment: null,
        smsPhoneNumber: null,
        ...payload,
      },
    )
  }

  async updateApplicantData(
    entityId: string,
    payload:
      | {
          applicant: ApplicantResponse['applicantDetails']
          documents?: ApplicantResponse['documents']
        }
      | {
          business: ApplicantResponse['businessInfo']
          entityType: 'ORGANISATION'
        },
    params?: PartialRecord<
      'saveOnly' | 'noNameDobValidation' | 'noInvalidate',
      boolean
    >,
  ) {
    const finalParams = {
      saveOnly: true,
      noNameDobValidation: true,
      noInvalidate: true,
      ...(params ?? {}),
    }
    return this.client.put(`data/v2/applicants/${entityId}`, payload, {
      params: finalParams,
    })
  }

  getApplicantsList(params: SearchApplicantParams) {
    return this.client.get<SearchApplicantResponse>('data/v1/applicants', {
      params,
    })
  }
}
export const applicantApi = new ApplicantAPI(bffClient)
