export const userEn = {
  tableRowHeader: {
    name: 'NAME',
    email: 'EMAIL',
    isLocked: 'LOCK ACCOUNT',
    role: 'ROLE',
    mfaStatus: 'MFA STATUS',
    lastLoginDate: 'LAST ACCESSED',
    createdDate: 'CREATED',
    actions: 'ACTIONS',
  },
  tableRow: {
    cta: {
      edit: 'Edit user details',
      resetPassword: 'Send password reset link',
      remove: 'Remove user',
    },
    mfa: {
      enabled: 'Enabled',
      disabled: 'Disabled',
    },
  },
}
