import React, { useState } from 'react'

import { DataGridPro, GridColDef, GridSortModel } from '@mui/x-data-grid-pro'

import { blocklistTableQa } from 'features/blocklist-table/qa/blocklist-table.qa'

import { ApplicantId } from 'entities/applicant'

import { WithProps } from 'shared/hoc'
import { useI18n } from 'shared/i18n'

import { BLOCKLIST_TABLE_KEY } from '../../blocklist-table.key'
import { blocklistTableEn } from '../../locale/blocklist-table.en'
import {
  BlocklistData,
  BlocklistDataProperties,
} from '../../model/blocklist.model'
import { useBlocklistQuery } from '../../state/blocklist-query/blocklist.query'
import {
  BlocklistAddedByCell,
  BlocklistAddedCell,
  BlocklistAttributeCell,
  BlocklistDescriptionCell,
  BlocklistReasonCell,
  BlocklistUpdateOnCell,
} from '../blocklist-table-cell/blocklist-table-cell'
import { BlocklistTableHeader } from '../blocklist-table-header/blocklist-table-header'
import {
  BlocklistRow,
  BlocklistLoadingOverlay,
  BlocklistNoRowOverlay,
} from '../blocklist-table-slot/blocklist-table-slot'

const TABLE_MIN_WIDTH = 1200

const headerField = <T extends BlocklistDataProperties>(name: T) => name

export type Props = {
  search?: string
  searchFields?: BlocklistDataProperties[]
  getApplicantGeneralInfoPath: (applicantId: ApplicantId) => string
}

export function BlocklistTable({
  search = '',
  searchFields = ['description'],
  getApplicantGeneralInfoPath,
}: Props) {
  const t = useI18n([BLOCKLIST_TABLE_KEY], { keys: blocklistTableEn })
  const [sortModel, setSortModel] = useState<GridSortModel>([])

  WithProps(BlocklistTableHeader, { sortModel })

  const {
    records: rows,
    setQueryFilter,
    fetchNextData,
    isLoading,
    isFetching,
    loadingType,
    isFilterApplied,
    error,
  } = useBlocklistQuery({})

  const columns: GridColDef<BlocklistData>[] = [
    {
      field: headerField('attribute'),
      headerName: t('header.attribute'),
      minWidth: 200,
      flex: 200 / TABLE_MIN_WIDTH,
      renderCell: WithProps(BlocklistAttributeCell, { t }),
    },
    {
      field: headerField('description'),
      headerName: t('header.description'),
      minWidth: 260,
      flex: 260 / TABLE_MIN_WIDTH,
      renderCell: WithProps(BlocklistDescriptionCell, { t }),
    },
    {
      field: headerField('reason'),
      headerName: t('header.reason'),
      minWidth: 200,
      flex: 200 / TABLE_MIN_WIDTH,
      renderCell: WithProps(BlocklistReasonCell, { t }),
    },
    {
      field: headerField('createdDate'),
      headerName: t('header.createdDate'),
      minWidth: 160,
      flex: 160 / TABLE_MIN_WIDTH,
      renderCell: WithProps(BlocklistAddedCell, { t }),
      sortComparator: (a: string, b: string) => b.localeCompare(a),
    },
    {
      field: headerField('lastUpdated'),
      headerName: t('header.lastUpdated'),
      minWidth: 160,
      flex: 160 / TABLE_MIN_WIDTH,
      renderCell: WithProps(BlocklistUpdateOnCell, { t }),
      sortComparator: (a: string, b: string) => b.localeCompare(a),
    },
    {
      field: headerField('addedBy'),
      headerName: t('header.addedBy'),
      minWidth: 160,
      flex: 160 / TABLE_MIN_WIDTH,
      renderCell: WithProps(BlocklistAddedByCell, { t }),
    },
  ]

  return (
    <DataGridPro
      data-qa={blocklistTableQa.main}
      getRowId={row => row.entityId}
      rows={rows}
      slots={{
        row: WithProps(BlocklistRow, { getApplicantGeneralInfoPath }),
        loadingOverlay: WithProps(BlocklistLoadingOverlay, {
          type: loadingType,
        }),
        noRowsOverlay: WithProps(BlocklistNoRowOverlay, {
          noFilterData: isFilterApplied,
          noSearchData: !isFilterApplied && !!search.length,
          noData: !isFilterApplied && !search.length,
          error: error?.response?.data.message,
        }),
        noResultsOverlay: WithProps(BlocklistNoRowOverlay, {
          noData: true,
        }),
      }}
      onRowsScrollEnd={fetchNextData}
      columns={columns.map(item => ({
        // Adding default properties for every column
        sortable: true,
        hideSortIcons: true,
        resizable: false,
        disableColumnMenu: true,
        disableReorder: true,
        cellClassName: '!outline-none',
        headerClassName:
          '!p-0 text-xs font-medium bg-tertiary-grey-50 text-tertiary-grey-500 !outline-none',
        renderHeader: WithProps(BlocklistTableHeader, {
          sortModel,
          onFilterChange: setQueryFilter,
        }),

        // Adding properties or Overriding default properties
        ...item,
      }))}
      filterModel={{
        items: searchFields.map(field => ({
          field,
          operator: 'contains',
          value: search,
        })),
      }}
      rowHeight={70}
      loading={isLoading || isFetching}
      sortModel={sortModel}
      onSortModelChange={setSortModel}
      isRowSelectable={() => false}
      isCellEditable={() => false}
      disableRowSelectionOnClick
      disableColumnSelector
      disableColumnFilter
      disableColumnMenu
      disableDensitySelector
      showCellVerticalBorder={false}
      showColumnVerticalBorder={false}
      hideFooterPagination
      hideFooter
      hideFooterSelectedRowCount
      sx={{
        '.MuiDataGrid-columnSeparator': {
          display: 'none',
        },
        '&.MuiDataGrid-root': {
          border: 'none',
        },
        '& .MuiDataGrid-columnHeaderTitleContainerContent': {
          height: '100%',
        },
        '& .MuiDataGrid-columnHeaders': {
          minHeight: 'unset !important',
          maxHeight: '40px !important',
          lineHeight: 'unset !important',
          borderRadius: '0 !important',
        },
        '.MuiDataGrid-columnHeaderTitleContainer': {
          display: 'inline-block !important',
          flex: 'none !important',
        },
        '.MuiDataGrid-cell': {
          minHeight: 'unset !important',
          maxHeight: 'unset !important',
          lineHeight: 'unset !important',
          padding: '0 !important',
        },
      }}
    />
  )
}
