import React, { useMemo } from 'react'

import { FrankieIcon } from 'frankify/src'

import { ApplicantId } from 'entities/applicant'

import { useI18n } from 'shared/i18n'

import { APPLICANT_PEP_SACTIONS_KEY } from '../../../../applicant-pep-sactions.key'
import { applicantPepSactionsEn } from '../../../../locale/applicant-pep-sactions.en'
import { applicantPepSanctionDataSourcesQa } from '../../../../qa/applicant-pep-sactions.qa'
import { useApplicantPepSactionsQuery } from '../../../../state/applicant-pep-sactions-query/applicant-pep-sactions.query'

type Props = {
  applicantId: ApplicantId
  matchIdx: number
  testId?: { container?: string }
}

export function ApplicantPepSactionsDataSources({
  applicantId,
  matchIdx,
  testId,
}: Props) {
  const t = useI18n(APPLICANT_PEP_SACTIONS_KEY, {
    keys: applicantPepSactionsEn,
  })

  const { data } = useApplicantPepSactionsQuery({ applicantId })

  const supportingDocs = useMemo(() => {
    const matchDetails = data?.matches[matchIdx]?.details
    const supportingDocuments = matchDetails?.supportingDocuments ?? []
    const supportingDocUrls = matchDetails?.entityData.supportingDocUrls ?? []

    return [...supportingDocuments, ...supportingDocUrls]
  }, [data?.matches, matchIdx])

  if (!supportingDocs.length) {
    return (
      <div data-qa={testId?.container} className="p-3 text-secondary-900">
        {t('empty.dataSources')}
      </div>
    )
  }

  return (
    <div data-qa={testId?.container} className="p-2">
      {supportingDocs.map(supportingDoc => (
        <div
          data-qa={applicantPepSanctionDataSourcesQa.document}
          className="flex items-center gap-2 py-2 border-b border-tertiary-grey-200"
          key={supportingDoc}
        >
          <FrankieIcon
            size="xs"
            name="mdiOpenInNew"
            className="text-secondary-500"
          />
          <a
            className="text-primary-400"
            href={supportingDoc}
            target="_blank"
            rel="noreferrer"
          >
            {supportingDoc} {data?.checkId}
          </a>
        </div>
      ))}
    </div>
  )
}
