import React, { useMemo, useState } from 'react'

import { IconButton } from '@mui/material'
import {
  GridColumnHeaderParams,
  GridSortModel,
  GridRenderCellParams,
} from '@mui/x-data-grid-pro'

import {
  FrankieButton,
  FrankieCheckbox,
  FrankieIcon,
  FrankiePopover,
  FrankieTableHeaderCell,
  FrankieLoader,
} from 'frankify/src'

import {
  APPLICANT_KEY,
  ApplicantEntityTypes,
  ApplicantIssueActionBadge,
  applicantEn,
  applicantProfileStatusOptions,
} from 'entities/applicant'

import { useI18n } from 'shared/i18n'
import { PartialRecord } from 'shared/typescript'

import { APPLICANT_BUSINESS_OWNERSHIP_KEY } from '../../../../applicant-business-ownership.key'
import { applicantBusinessOwnershipEn } from '../../../../locale/applicant-business-ownership.en'
import {
  SearchEntityRecord,
  SearchEntityRecordProperties,
} from '../../../../model/associate-party.model'
import { applicantAssociatePartiesQa } from '../../../../qa/applicant-business-ownership.qa'
import {
  FilterArgs,
  SearchArgs,
} from '../../../../state/existing-applicant-state/existing-applicant.state'

export function LoadingOverlay() {
  const t = useI18n([APPLICANT_BUSINESS_OWNERSHIP_KEY], {
    keys: applicantBusinessOwnershipEn,
  })

  return (
    <FrankieLoader
      loading
      className="text-tertiary-grey-800 text-md font-semibold min-h-[150px]"
      size="sm"
      label={t('loading.searchingEntity')}
      testId={{ loader: applicantAssociatePartiesQa.loader }}
    />
  )
}

export function NoRowsOverlay({ entityIdFilter, nameFilter }: SearchArgs) {
  const t = useI18n([APPLICANT_BUSINESS_OWNERSHIP_KEY], {
    keys: applicantBusinessOwnershipEn,
  })

  const searchedText = useMemo(() => {
    if (entityIdFilter && nameFilter)
      return `"${nameFilter}" & "${entityIdFilter}"`
    if (entityIdFilter) return `"${entityIdFilter}"`
    if (nameFilter) return `"${nameFilter}"`
    return false
  }, [entityIdFilter, nameFilter])

  return (
    <div className="text-center py-6 font-medium text-secondary-900">
      {searchedText
        ? t('searchNoResults', { searchedText })
        : t('searchIndividualOrBusiness')}
    </div>
  )
}

export type CellProps = GridRenderCellParams<SearchEntityRecord>

export function TypeIconCell({ row }: CellProps) {
  return (
    <FrankieIcon
      testId={{ icon: applicantAssociatePartiesQa.icon }}
      className="text-tertiary-grey-400"
      name={
        row.type === ApplicantEntityTypes.Organisation
          ? 'mdiOfficeBuildingOutline'
          : 'mdiAccountOutline'
      }
    />
  )
}

export function EntityNameCell({ row }: CellProps) {
  const t = useI18n([APPLICANT_BUSINESS_OWNERSHIP_KEY], {
    keys: applicantBusinessOwnershipEn,
  })

  const custId = row.customerId

  return (
    <div>
      <div className="text-secondary-900 font-bold">{row.entityName}</div>
      {custId && (
        <div className="text-tertiary-grey-400">
          {t('custId')} {row.customerId}
        </div>
      )}
      <div
        className={`${
          custId ? 'text-secondary-900 mt-[-4px]' : 'text-tertiary-grey-400'
        }`}
      >
        {t('entityId')} {row.entityId}
      </div>
    </div>
  )
}

export function EntityStatusCell({ row }: CellProps) {
  return (
    <ApplicantIssueActionBadge
      className="max-w-[160px] !py-1 text-xs"
      type={row.profileStatus}
    />
  )
}

export function SelectedEntityCell({
  row,
  selectedEntityId,
}: CellProps & { selectedEntityId: string }) {
  return (
    <div className="w-4 h-4 p-[2px] shadow-sm border border-tertiary-grey-500 rounded-full">
      {selectedEntityId === row.entityId && (
        <div className="rounded-full bg-tertiary-red-500 w-full h-full" />
      )}
    </div>
  )
}

export const getWidth = (width: number) => ({
  minWidth: width,
  flex: width / 750,
})

export const headerField = (name: SearchEntityRecordProperties) => ({
  field: name,
})

export type EntityFilter = FilterArgs

export type EntityFilterOption = PartialRecord<
  SearchEntityRecordProperties,
  {
    filterName: keyof EntityFilter
    filterOptions: { label: string; value: string }[]
  }
>

export type Props = GridColumnHeaderParams<SearchEntityRecord> & {
  sortModel: GridSortModel
  onFilterChange: (args: Partial<EntityFilter>) => void
}

export function EntityTableHeader({
  colDef,
  sortModel,
  onFilterChange,
}: Props) {
  const t = useI18n([APPLICANT_BUSINESS_OWNERSHIP_KEY], {
    keys: applicantBusinessOwnershipEn,
  })

  const tApplicant = useI18n([APPLICANT_KEY], {
    keys: applicantEn,
  })

  const [selectedFilter, setSelectedFilter] = useState<Set<string>>(new Set())

  const [appliedFilter, setAppliedFilter] = useState<Set<string>>(new Set())

  const [isOpen, setIsOpen] = useState(false)
  const [isUpdated, setIsUpdated] = useState(false)

  const fieldName = colDef.field as SearchEntityRecordProperties

  const filterOptions: EntityFilterOption = {
    profileStatus: {
      filterName: 'matchStatusFilter',
      filterOptions: applicantProfileStatusOptions.map(({ tKey, value }) => ({
        label: tApplicant(tKey),
        value,
      })),
    },
  }

  const filter = filterOptions[fieldName]

  const currentSortValue = useMemo(
    () =>
      sortModel.find(item => item.field === fieldName)?.sort ||
      (colDef.sortable ? 'off' : undefined),
    [sortModel, colDef.sortable, fieldName],
  )

  const handleOpen = (open: boolean) => {
    setIsOpen(open)
    setIsUpdated(false)
  }

  const resetSelectedFilter = () => {
    setSelectedFilter(new Set(appliedFilter))
  }

  // Function to toggle item in the set
  const toggleFilter = (item: string) => {
    setIsUpdated(true)
    setSelectedFilter(currentFilter => {
      const currentFilterSet = new Set(currentFilter)

      if (currentFilterSet.has(item)) {
        currentFilterSet.delete(item)
      } else {
        currentFilterSet.add(item)
      }

      return currentFilterSet
    })
  }

  const handleApplyFilter = () => {
    if (filter) {
      setAppliedFilter(new Set(selectedFilter))
      onFilterChange({ [filter.filterName]: [...selectedFilter] })
      handleOpen(false)
    }
  }

  const handleClearFilter = () => {
    if (filter) {
      setAppliedFilter(new Set())
      setSelectedFilter(new Set())
      onFilterChange({ [filter.filterName]: [] })
      handleOpen(false)
    }
  }

  return (
    <FrankieTableHeaderCell
      className="!px-0 items-center"
      sortValue={currentSortValue}
    >
      {colDef.headerName}
      {filter && (
        <FrankiePopover
          open={isOpen}
          onOpenChange={open => {
            handleOpen(open)
            resetSelectedFilter()
          }}
          popoverRest={{
            placement: 'bottom-end',
          }}
          trigger={
            <div className="relative h-[30px] w-[30px] ml-2">
              <IconButton
                className="absolute inset-0 z-[2]"
                onClick={e => {
                  e.stopPropagation()
                  setIsOpen(prev => {
                    if (!prev) resetSelectedFilter()
                    return !prev
                  })
                }}
              />

              <div
                className={`absolute inset-0 flex items-center ${
                  isOpen || selectedFilter.size > 0
                    ? 'text-primary-700'
                    : 'text-tertiary-grey-400'
                }`}
              >
                <FrankieIcon size="xs" name="mdiFilter" />
                <FrankieIcon
                  size="xs"
                  className="mb-[-5px] ms-[-5px]"
                  name={isOpen ? 'mdiChevronUp' : 'mdiChevronDown'}
                />
              </div>
            </div>
          }
        >
          <div className="relative bg-mono-white shadow-md max-h-[310px] overflow-auto rounded-sm">
            <div className="flex flex-col pt-4 px-4 min-w-[200px]">
              {filter.filterOptions.map(filterOption => (
                <label
                  className="flex flex-row cursor-pointer mb-3"
                  key={filterOption.value}
                >
                  <FrankieCheckbox
                    size="sm"
                    checked={selectedFilter.has(filterOption.value)}
                    onChange={() => toggleFilter(filterOption.value)}
                  />
                  <div className="ml-2 leading-tight text-sm">
                    {filterOption.label}
                  </div>
                </label>
              ))}
            </div>

            <div className="flex flex-row items-center gap-3 px-4 pt-1 pb-4 sticky bottom-0 bg-mono-white">
              <FrankieButton
                className="basis-1/2 max-w-[80px]"
                size="xs"
                disabled={!isUpdated}
                type="submit"
                onClick={handleApplyFilter}
              >
                {t('apply')}
              </FrankieButton>
              <FrankieButton
                className="basis-1/2 max-w-[80px]"
                size="xs"
                intent="darkOutline"
                onClick={handleClearFilter}
              >
                {t('clear')}
              </FrankieButton>
            </div>
          </div>
        </FrankiePopover>
      )}
    </FrankieTableHeaderCell>
  )
}

export const entityStylesSx = {
  '.MuiDataGrid-columnSeparator': {
    display: 'none',
  },
  '&.MuiDataGrid-root': {
    border: 'none',
  },
  '& .MuiDataGrid-row': {
    cursor: 'pointer',
  },
  '& .MuiDataGrid-columnHeaderTitleContainerContent': {
    height: '100%',
  },

  '& .MuiDataGrid-virtualScroller': {
    minHeight: '160px',
    maxHeight: '160px',
  },
  '& .MuiDataGrid-columnHeaders': {
    minHeight: 'unset !important',
    maxHeight: '40px !important',
    lineHeight: 'unset !important',
    borderRadius: '0 !important',
  },
  '.MuiDataGrid-columnHeaderTitleContainer': {
    display: 'inline-block !important',
    flex: 'none !important',
  },
  '.MuiDataGrid-cell': {
    minHeight: 'unset !important',
    maxHeight: 'unset !important',
    lineHeight: 'unset !important',
    padding: '0 12px !important',
  },
}
