import React from 'react'

import { FrankieIcon } from 'frankify/src'

import { useScrollInView } from 'shared/hooks'
import { useI18n } from 'shared/i18n'

import { MONITORING_OVERVIEW_KEY } from '../../monitoring-overview.key'

type ErrorBannerProps = {
  title: string
  show: boolean
}

function ProgressBanner({ title, show }: ErrorBannerProps) {
  const ref = useScrollInView(show)

  if (!show) return null

  return (
    <div ref={ref} className="pt-6">
      <div className="w-full bg-primary-50 text-tertiary-grey-800 flex flex-row items-center py-4 px-4 rounded-md">
        <FrankieIcon name="mdiInformation" className="text-primary" />
        <span className="ml-2 text-md font-semibold">{title}</span>
      </div>
    </div>
  )
}

type Props = {
  generatingProfileReport: boolean
  generatingOwnershipReport: boolean
}

export function ReportProgressBanner({
  generatingOwnershipReport,
  generatingProfileReport,
}: Props) {
  const t = useI18n([MONITORING_OVERVIEW_KEY])

  return (
    <>
      <ProgressBanner
        show={generatingProfileReport}
        title={t('reportIsGeneratingBadge')}
      />
      <ProgressBanner
        title={t('ownershipReportIsGeneratingBadge')}
        show={generatingOwnershipReport}
      />
    </>
  )
}
