import React, { useMemo } from 'react'

import { ApplicantId } from 'entities/applicant'

import { useI18n } from 'shared/i18n'

import { ApplicantRelatedOrganisationsErrorBoundary } from './applicant-related-organisations-error'
import { APPLICANT_RELATED_ORGANISATIONS_KEY } from '../../applicant-related-organisations.key'
import { applicantRelatedOrganisationsEn } from '../../locale/applicant-related-organisations.en'
import { ApplicantRelatedOrganisationsTable } from '../applicant-related-organisations-table/applicant-related-organisations-table'

type Props = {
  applicantId: ApplicantId
  className?: string
}

export function ApplicantRelatedOrganisations({
  applicantId,
  className = '',
}: Props) {
  const t = useI18n([APPLICANT_RELATED_ORGANISATIONS_KEY], {
    keys: applicantRelatedOrganisationsEn,
  })

  const headers = useMemo(
    () => ({
      name: t('table.header.name'),
      type: t('table.header.type'),
      relationships: t('table.header.relationship'),
    }),
    [t],
  )

  const emptyText = useMemo(
    () => ({
      empty: t('table.row.empty'),
      error: t('table.row.error'),
    }),
    [t],
  )

  return (
    <ApplicantRelatedOrganisationsErrorBoundary>
      <div className={className}>
        <h1 className="mb-1 font-bold text-xl">{t('title')}</h1>
        <h2 className="text-sm text-secondary-900">{t('subtitle')}</h2>
        <ApplicantRelatedOrganisationsTable
          headers={headers}
          emptyText={emptyText}
          applicantId={applicantId}
        />
      </div>
    </ApplicantRelatedOrganisationsErrorBoundary>
  )
}
