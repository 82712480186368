import React from 'react'

import { NavLink } from 'react-router-dom'

import { FrankieIcon, FrankieIconName, FrankieLoader } from 'frankify/src'

import { useI18n } from 'shared/i18n'

import { PROFILE_CHECK_KEY } from '../../locale/profile-check.en'

type StatusTypes = 'passed' | 'failed' | 'unchecked' | 'notRequired'

interface IStatusConfig {
  iconClass: string
  descriptionClass: string
  statusClass: string
  iconName: FrankieIconName
  bgClass: string
  i18n: string
}

const statusesMap: Record<StatusTypes, IStatusConfig> = {
  passed: {
    i18n: 'checkStatus.statuses.passed',
    iconName: 'mdiCheckCircle',
    iconClass: 'text-tertiary-green-600',
    bgClass: 'border border-tertiary-grey-200',
    statusClass: 'text-tertiary-green-600',
    descriptionClass: 'text-tertiary-grey-600',
  },
  failed: {
    i18n: 'checkStatus.statuses.failed',
    iconName: 'mdiCloseCircle',
    iconClass: 'text-tertiary-red-700',
    bgClass: 'bg-tertiary-red-50',
    statusClass: 'text-tertiary-grey-700',
    descriptionClass: 'text-tertiary-grey-700',
  },
  unchecked: {
    i18n: 'checkStatus.statuses.unchecked',
    iconName: 'mdiMinusCircle',
    iconClass: 'text-tertiary-grey-400',
    bgClass: '',
    statusClass: 'text-tertiary-grey-500',
    descriptionClass: 'text-tertiary-grey-700',
  },
  notRequired: {
    i18n: 'checkStatus.statuses.notRequired',
    iconName: 'mdiMinusCircleOff',
    iconClass: 'text-tertiary-grey-400',
    bgClass: 'bg-tertiary-grey-200',
    statusClass: 'text-tertiary-grey-500',
    descriptionClass: 'text-tertiary-grey-700',
  },
}

type Props = {
  name: string
  description: string
  status: StatusTypes
  className?: string
  resolvePage: string
  loading?: boolean
}

export function ProfileCheckStatus({
  name,
  description,
  status,
  className = '',
  resolvePage,
  loading,
}: Props) {
  const t = useI18n([PROFILE_CHECK_KEY])
  const statusConfig: IStatusConfig = statusesMap[status]

  return (
    <div
      className={`p-4 flex gap-2 items-center rounded-md ${statusConfig.bgClass} ${className}`}
    >
      {loading ? (
        <FrankieLoader
          className="!w-[24px] !h-[24px] !min-w-[24px] !min-h-[24px]"
          size="xs"
          loading
        />
      ) : (
        <FrankieIcon
          name={statusConfig.iconName}
          className={statusConfig.iconClass}
        />
      )}
      <div className="basis-full shrink">
        <div className="font-semibold text-md text-tertiary-grey-800 pb-1">
          {name}
        </div>
        <div className="text-tertiary-grey-600">{description}</div>
      </div>

      {status === 'failed' ? (
        <NavLink
          className="text-neutral-100 border border-solid border-neutral-100 font-semibold rounded-sm py-1 px-4 hover:text-primary-700 hover:border-primary-700 flex-grow-0"
          to={resolvePage}
        >
          {t('checkStatus.cta.resolve')}
        </NavLink>
      ) : (
        <div className={`font-semibold ${statusConfig.statusClass}`}>
          {t(statusConfig.i18n as never)}
        </div>
      )}
    </div>
  )
}
