import React, { ReactNode } from 'react'

import { Controller, ControllerProps, FieldValues } from 'react-hook-form'

import { FrankieCheckbox, IFrankieCheckboxProps } from 'frankify/src'

type Props<TFormValues extends FieldValues> = {
  className?: string
  fieldClassName?: string
  label?: ReactNode
  /**
   *Confirmation field is required by default, use nonMandatory to make the field not mandatory
   */
  nonMandatory?: boolean
} & Omit<IFrankieCheckboxProps, 'value' | 'onChange'> &
  Omit<ControllerProps<TFormValues>, 'render'>

export function ConfirmationFormField<TFormValues extends FieldValues>({
  name,
  control,
  defaultValue,
  rules,
  shouldUnregister,
  label,
  className = '',
  fieldClassName = '',
  nonMandatory = false,
  ...checkboxProps
}: Props<TFormValues>) {
  return (
    <Controller
      render={({ field }) => (
        <label
          className={`flex gap-2 items-start text-tertiary-grey-800 text-sm font-medium cursor-pointer ${className}`}
        >
          <FrankieCheckbox
            className={`${fieldClassName} min-w-[20px]`}
            value={field.value}
            onChange={field.onChange}
            {...checkboxProps}
          />
          {label}
        </label>
      )}
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={{
        // Default confirmation validation that confirms the value is should be true
        validate: value => !!value || nonMandatory,
        ...rules,
      }}
      shouldUnregister={shouldUnregister}
    />
  )
}
