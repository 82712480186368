import { FrankieIconName } from 'frankify/src'

import { ApplicantEntityStatusTypes } from 'entities/applicant'

import OrganisationCheckIcon from '../assets/organisation-assign.svg'

export enum NotificationEventTypes {
  USER_ASSIGNED = 'USER_ASSIGNED',
  ENTITY_STATUS_UPDATED = 'ENTITY_STATUS_UPDATED',
  TRUST_ANALYSIS_COMPLETE = 'TRUST_ANALYSIS_COMPLETE',
}

type NotificationIconType = Record<
  NotificationEventTypes,
  FrankieIconName | string
>

export const notificationIndividualIcons: NotificationIconType = {
  [NotificationEventTypes.USER_ASSIGNED]: 'mdiAccountCheckOutline',
  [NotificationEventTypes.ENTITY_STATUS_UPDATED]: 'mdiAccountOutline',
  [NotificationEventTypes.TRUST_ANALYSIS_COMPLETE]: 'mdiFileDocumentOutline',
}

export const notificationBusinessIcons: NotificationIconType = {
  [NotificationEventTypes.USER_ASSIGNED]: OrganisationCheckIcon,
  [NotificationEventTypes.ENTITY_STATUS_UPDATED]: 'mdiOfficeBuildingOutline',
  [NotificationEventTypes.TRUST_ANALYSIS_COMPLETE]: 'mdiFileDocumentOutline',
}

export const notificationBatchIcons: NotificationIconType = {
  [NotificationEventTypes.USER_ASSIGNED]: 'mdiCheckboxMultipleMarkedOutline',
  [NotificationEventTypes.ENTITY_STATUS_UPDATED]:
    'mdiNewspaperVariantMultipleOutline',
  [NotificationEventTypes.TRUST_ANALYSIS_COMPLETE]: 'mdiFileDocumentOutline',
}

export const matchStatusFilterMapping: Record<string, string[]> = {
  Failed: [
    ApplicantEntityStatusTypes.Failed,
    ApplicantEntityStatusTypes.ManuallyFailed,
  ],
  'Manually Failed': [
    ApplicantEntityStatusTypes.Failed,
    ApplicantEntityStatusTypes.ManuallyFailed,
  ],
  'Needs Attention': [ApplicantEntityStatusTypes.NeedsAttention],
}
