import { useQuery } from '@tanstack/react-query'

import { ApplicantId } from 'entities/applicant'

import { ErrorResponse } from 'shared/http'

import { applicantBiometricsApi } from '../../api/applicant-biometrics-ocr.api'
import { ApplicantBiometricResponse } from '../../model/applicant-biometrics-ocr.model'

type Args = {
  applicantId?: ApplicantId
}

export const ApplicantBiometricsOcrQueryKey = 'applicant-biometrics-ocr-query'

export const useApplicantBiometricsOcrQuery = ({ applicantId }: Args) =>
  useQuery<ApplicantBiometricResponse, ErrorResponse>({
    enabled: !!applicantId,
    queryKey: [ApplicantBiometricsOcrQueryKey, applicantId],
    queryFn: async () => {
      const { data } = await applicantBiometricsApi.getApplicantBiometrics(
        applicantId!,
      )

      data.biometry.sort((a, b) => a.checkDate.localeCompare(b.checkDate))

      return data
    },
  })
