import { NOTIFICATIONS_MANAGEMENT_KEY } from '../notification-management.key'

const notificationsConfigTableRow = `${NOTIFICATIONS_MANAGEMENT_KEY}-NotificationsConfigTableRow`

export const notificationsConfigTableRowQa = {
  container: `${notificationsConfigTableRow}-container`,
  name: `${notificationsConfigTableRow}-name`,
  description: `${notificationsConfigTableRow}-description`,
  frequencySelectInput: `${notificationsConfigTableRow}-frequencySelectInput`,
} satisfies Record<string, `${typeof notificationsConfigTableRow}-${string}`>

const notificationsConfigTableRowLayout = `${NOTIFICATIONS_MANAGEMENT_KEY}-NotificationsConfigTableRowLayout`

export const notificationsConfigTableRowLayoutQa = {
  notificationTypeCell: `${notificationsConfigTableRowLayout}-notificationTypeCell`,
  frequencyCell: `${notificationsConfigTableRowLayout}-frequencyCell`,
  channelCell: `${notificationsConfigTableRowLayout}-channelCell`,
} satisfies Record<
  string,
  `${typeof notificationsConfigTableRowLayout}-${string}`
>

const notificationsConfigTableRowHeader = `${NOTIFICATIONS_MANAGEMENT_KEY}-NotificationsConfigTableRowHeader`

export const notificationsConfigTableRowHeaderQa = {
  container: `${notificationsConfigTableRowHeader}-container`,
  header: `${notificationsConfigTableRowHeader}-header`,
  frequency: `${notificationsConfigTableRowHeader}-frequency`,
  rowHeaderChannelCell: `${notificationsConfigTableRowHeader}-channelCell`,
} satisfies Record<
  string,
  `${typeof notificationsConfigTableRowHeader}-${string}`
>

const notificationsConfigTable = `${NOTIFICATIONS_MANAGEMENT_KEY}-NotificationsConfigTable`
export const notificationsConfigTableQa = {
  container: `${notificationsConfigTable}-container`,
  nonPersonalNotificationContainer: `${notificationsConfigTable}-nonPersonalNotificationContainer`,
  notificationRowHeaderContainer: `${notificationsConfigTable}-notificationRowHeaderContainer`,
} satisfies Record<string, `${typeof notificationsConfigTable}-${string}`>

const generalNotificationsConfigTableRowHeader = `${NOTIFICATIONS_MANAGEMENT_KEY}-generalNotificationsConfigTableRowHeader`
export const generalNotificationsConfigTableRowHeaderQa = {
  container: `${generalNotificationsConfigTableRowHeader}-container`,
} satisfies Record<
  string,
  `${typeof generalNotificationsConfigTableRowHeader}-${string}`
>

const generalNotificationsConfigTableRow = `${NOTIFICATIONS_MANAGEMENT_KEY}-GeneralNotificationsConfigTableRow`
export const generalNotificationsConfigTableRowQa = {
  container: `${generalNotificationsConfigTableRow}-container`,
} satisfies Record<
  string,
  `${typeof generalNotificationsConfigTableRow}-${string}`
>
