export const applicantBusinessOwnershipInternationalEn = {
  ubo: {
    heading: 'Ultimate Beneficial Owners (Individuals)',
    subHeading:
      'The following individuals have been identified as Beneficial Owners using information as reported by the registry and our partners.',
    description:
      'Ultimate Beneficial Owners have been defined as having 25% or more ownership. All figures are rounded up to 2 decimal places.',
    emptyText: 'No individuals have been identified',
  },

  blockingEntity: {
    heading: 'Blocking Entities',
    subHeading:
      'The following individuals and organisations have been identified as having significant ownership as reported by the registry and our partners, but an ultimate individual could not ber determined due to non-beneficial ownership, foreign ownership, public ownership, etc.',
    emptyText: 'No blocking entities have been identified.',
  },

  otherOwners: {
    heading: 'Other Owners',
    subHeading:
      'The following individuals and organisations have been identified as having less than 25% ownership of this company.',
    emptyText: 'No beneficial owners have been identified',
  },

  nonIndividualOwners: {
    heading: 'Non-Individual Owners',
    subHeading:
      'The following non-individual entity types own an interest in this entity.',
    emptyText: 'No non-individual entities have been identified',
  },

  linkedCompanies: {
    heading: 'Linked Companies',
    subHeading:
      'The beneficial owners have been linked to an ownership interest in this entity through the following entities.',
    emptyText: 'No linked companies have been identified',
  },

  officeHolders: {
    heading: 'Officials',
    subHeading:
      'The following individuals have been identified as current officials of this company.',
    emptyText: 'No officials have been identified',
  },

  shareCapital: {
    heading: 'Share Capital',
    subHeading:
      'The following section outlines the shareholdings found for this entity as collected from the registry and our partners.',
    emptyText: {
      shareCapital: 'No issued share capital have been identified',
      shareHolder: 'No shareholder entities have been identified',
    },
    shareHolders: 'Shareholders',
    issued: 'Share Capital',
  },

  associatedParties: {
    heading: 'Associated Parties',
    subHeading: 'Manually add associated parties.',
    emptyText: 'There are no manually added Associated Parties',
  },

  addEntity: 'Add Entity',
  associateNewParties: 'Add new associate party',
  associateExistingParties: 'Associate Existing Parties',
  editAssociateParties: 'Edit associate parties',
  associateOrganisation: 'Associate organisation',
  associateIndividual: 'Associate individual',

  personal: 'Personal',
  individual: 'Individual',
  business: 'Organisation',

  personalInfo: 'Personal Info',
  businessInfo: 'Organisation name',

  optional: '(optional)',

  givenName: 'First Name',
  middleName: 'Middle Name',
  familyName: 'Last Name',
  roleType: 'Role type (select all applicable)',
  upto2Decimal: 'Up to 2 decimal places',
  beneficiallyOwnership: 'Beneficially Held Ownership %',
  nonBeneficiallyOwnership: 'Non Beneficially Held Ownership %',
  enterNumber: 'Enter number',
  searchBusiness: 'Search organisation name or registration number',
  total: 'Total %',
  other: 'Other',

  add: 'Add',
  remove: 'Remove',
  cancel: 'Cancel',
  noCancel: 'No, Cancel',
  yesSure: "Yes, I'm sure",
  copy: 'Copy',
  copied: 'Copied',
  back: 'Back',
  done: 'Done',
  yes: 'Yes',
  no: 'No',
  search: 'Search',
  apply: 'Apply',
  clear: 'Clear',

  loading: {
    default: 'Loading...',
    roles: 'Loading roles...',
    recipe: 'Getting recipe...',
    business: 'Searching business...',
    link: 'Fetching Link...',
    updatingRecipe: 'Updating Recipe...',
    sendLink: 'Sending Link...',
    searchingEntity: 'Searching Entity...',
  },

  error: {
    nonBeneficiallyOwnership:
      'Percentage non beneficially must be less than or equal to 100',
    beneficiallyOwnership:
      'Percentage beneficially must be less than or equal to 100',

    copy: 'Error copying link',
    noLinkCopy: 'No link to copy',
    sendTextMessage: 'Failed to send Text Message',
    rolesNotConfigured: 'Roles not configured',
    editAssociatedParty: 'Associated party failed to be added',
    removeAssociatedParty: 'Failed to remove associated party',
    createAssociatedParty: 'Associated party failed to be added',
  },

  success: {
    linkSent: 'Link sent successfully',
    linkCopied: 'Link copied successfully',
    editAssociatedParty: 'Associated party updated',
    removeAssociatedParty: 'Associated party removed',
    createAssociatedParty: 'Associated party added',
  },

  action: {
    sendKycLink: 'Send KYC Link',
    removeAssociatedParty: 'Remove Associated Party',
    editAssociatedParty: 'Edit Associated Party',
    addAssociateParty: 'Add associate party',
  },

  addAssociateDisabledTooltip:
    'Run an Organisation profile or Ownership report first to enable adding an associate party',

  selectRecipe: 'Select a Recipe to run checks',
  sendLink: 'Send Link',
  secureLink: 'Secure Link',
  enterIndividualsPhoneNumber:
    "Enter the individual's phone number or copy and paste this link to the customer.",
  checksWillBeRun:
    'Once the process has been completed the checks will be run and the customer profile updated here automatically.',
  sendLinkMsg: 'Send Link via Text Message',
  pleaseConfirm: 'Please Confirm',
  copyLink: 'Copy Link',
  copyTheLink: 'Copy the Link',
  copyLinkAndSendToApplicant:
    'Copy the link and send to the applicant by email or message.',
  sendLinkToApplicant: 'Send link to the applicants mobile number.',
  automaticSelection: 'Automatic Selection',
  mayOverride: 'This may override previous check results',
  newCheckBeingRun:
    'Please note that the selection of any other recipe than the current recipe may result in a new check being run on the profile. This may override previous check results due to the new check.',
  confirmToContinue: 'Please confirm if you wish to continue.',

  areYouSure:
    'Are you sure you want to remove this associated party from the organisation?',
  actionWillRemoveAssociatedParty: 'You will not be able to undo this action.',
  iConfirmLinkSent:
    'I confirm link has been sent to customer (this action will be recorded within the audit log).',
  country: 'Country',
  mobilePhoneNumber: 'Mobile Phone Number',
  comment: 'Comment',
  auditPurposeOnly:
    '(For audit report purposes only, comment will not be sent to the applicant)',
  typeComment: 'Type your comment here',
  pleaseSpecify: 'Please Specify',

  hideOrganisation: 'Hide organisation entity from the entities page',
  hideIndividual: 'Hide individual entity from the entities page',

  wishToAddIndividualAsAssociatedParty:
    'Do you wish to add this entity as an associated party under the organisation?',

  noResults: 'No results found',
  searchBusinessNotInList: 'Search for a business that is not on this list',
  name: 'Name',
  abn: 'ABN',
  active: 'Active',
  cancelled: 'Cancelled',
  entityStatus: 'Entity Status',
  businessName: 'Business Name',
  abnOrAcn: 'ABN or ACN (optional)',
  backToRegistrySearch: 'Back to Registry Search',
  registrationNumber: 'Registration Number',
  registrationAuthority: 'Registration Authority',

  fullNameOrFamilyName: 'Full name or family name',
  customerId: 'Customer ID, Frankie ID, ACN or ABN',
  entityId: 'Entity. ID',
  searchIndividualOrBusiness: 'Try searching an individual or business above',
  searchNoResults: 'Your search for {{searchedText}} returned no results.',

  iBusinessSummary: {
    heading: 'Organisation Information',

    name: 'Name',
    country: 'Country',
    companyType: 'Company Type',
    registrationNumber: 'Registration Number',
    status: 'Status',
    jurisdiction: 'Jurisdiction',
    registrationAuthority: 'Registration Authority',
    incorporationDate: 'Incorporation date',
  },

  dataGridFields: {
    officialState: 'Current / Previous',
    beneficiallyHeld: 'Beneficially Held',
    blockingReason: 'Blocking Reason',
    beneficiallyHeldOwnership: 'Beneficially Held Ownership',
    nonBeneficiallyHeldOwnership: 'Non-Beneficially Held Ownership',
    classTitle: 'Class Title',
    classCode: 'Class Code',
    currentPrevious: 'Current/Previous',
    entityType: 'Entity Type',
    issues: 'Issues',
    jointHolding: 'Joint Holding',
    name: 'Name',
    profileStatus: 'Profile Status',
    recipe: 'Recipe',
    role: 'Role',
    roleType: 'Role Type',
    sharesHeld: 'Shares Held',
    sharesIssued: 'Shares Issued',
    totalAmountPaid: 'Total Amount Paid',
    totalAmountDueAndPayable: 'Total Amount Due and Payable',
    total: 'Total',
    riskLevel: 'Risk Level',
    type: 'Type',
    updatedName: 'Updated Name',
    registryName: 'Registry Name',
    totalShares: 'Total Shares',
    companyType: 'Entity Type',
  },

  lastUpdated: 'LAST UPDATED',
  frankieId: 'FRANKIE ID',
}
