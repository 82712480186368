import { BULK_ASSIGN_KEY } from '../bulk-assign.key'

const bulkAssign = `${BULK_ASSIGN_KEY}-bulkAssign`
export const bulkAssignQa = {
  button: `${bulkAssign}-button`,
} satisfies Record<string, `${typeof bulkAssign}-${string}`>

const bulkAssignOverlay = `${bulkAssign}-bulkAssignOverlay`
export const bulkAssignOverlayQa = {
  container: `${bulkAssignOverlay}-container`,
  title: `${bulkAssignOverlay}-title`,
  notification: `${bulkAssignOverlay}-notification`,
  searchField: `${bulkAssignOverlay}-searchField`,
  assignToMeBtn: `${bulkAssignOverlay}-assignToMeBtn`,
  options: `${bulkAssignOverlay}-options`,
  textAreaFormField: `${bulkAssignOverlay}-textAreaFormField`,
  note: `${bulkAssignOverlay}-note`,
  cancelBtn: `${bulkAssignOverlay}-cancelBtn`,
  assignBtn: `${bulkAssignOverlay}-assignBtn`,
} satisfies Record<string, `${typeof bulkAssignOverlay}-${string}`>
