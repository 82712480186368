export type OrganisationId = number

export type OrganisationName = string
export type OrganisationBusinessName = string
export type OrganisationLegalName = string
export type OrganisationMainName = string
export type OrganisationTradingName = string
export type OrganisationHistoricalBusinessName = string

export type Abn = string
export type Acn = string

export interface IBusinessName {
  name: OrganisationBusinessName
  effectiveFrom: string // e.g. 2020-11-10
}

export interface IAbnStatus {
  status: string // enum
  effectiveFrom: string // e.g. 2020-11-10
}

export interface IOrganisationInfo {
  name: OrganisationName
  businessNames: IBusinessName[]
  mainNames: OrganisationMainName[]
  legalNames: OrganisationLegalName[]
  historicalBusinessNames: OrganisationHistoricalBusinessName[]
  tradingNames: OrganisationTradingName[]
  score: null
  state: string
  postalCode: string
  type: string
  abn: Abn
  acn: Acn
  isActive: boolean
  abnStatus: IAbnStatus
}

export type IAustralianOrganisationInfo = {
  mainName: string // org name
  location: string // adress postal code + state
  type: string // org type[0] description
  abn: string // reg details find abn
}

export const OTHER_ROLE_KEY = 'OTH'
