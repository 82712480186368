import React, { useMemo } from 'react'

import { UseFormReturn } from 'react-hook-form'

import { FrankieIcon, FrankieTextField } from 'frankify/src'

import { getError } from 'shared/form'
import { useI18n } from 'shared/i18n'

import { APPLICANT_BLOCKLISTED_INFO_KEY } from '../../applicant-bllocklisted-info.key'
import { applicantBlocklistedInfoEn } from '../../locale/applicant-blocklisted-info.en'
import {
  BlacklistedFormInputs,
  BlocklistedInputTypes,
} from '../../model/applicant-blocklisted-form.model'
import { blockListPhoneNumberFormQa } from '../../qa/applicant-blocklisted-info-qa'

type Props = {
  form: UseFormReturn<BlacklistedFormInputs>
}

export function PhoneNumberForm({ form }: Props) {
  const {
    register,
    formState: { errors },
  } = form

  const phoneNumberRegister = useMemo(
    () =>
      register(`${BlocklistedInputTypes.PhoneNumber}`, {
        required: true,
        validate: value => {
          if (!/^[0-9+]*$/.test(value)) {
            return false
          }
          return true
        },
      }),
    [register],
  )

  const t = useI18n([APPLICANT_BLOCKLISTED_INFO_KEY], {
    keys: applicantBlocklistedInfoEn,
  })

  return (
    <div
      data-qa={blockListPhoneNumberFormQa.container}
      className="pb-6 max-w-[486px]"
    >
      <div className="flex justify-start items-center mb-6">
        <FrankieIcon name="mdiCellphone" className="mr-3" />
        <div className="font-bold">{t('form.phoneNumber')}</div>
      </div>
      <FrankieTextField
        testId={{ input: blockListPhoneNumberFormQa.text }}
        className="basis-[32%]"
        label={t('form.phoneNumber')}
        placeholder={t('form.phoneNumber')}
        error={!!getError(phoneNumberRegister.name, errors)}
        {...phoneNumberRegister}
      />
    </div>
  )
}
