import { useCallback } from 'react'

import { useQuery, useQueryClient } from '@tanstack/react-query'
import { isToday, isYesterday } from 'date-fns'

export type NotificationIdAndTime = { n_id: string; createdOn: number }

export type NotificationState = {
  today?: NotificationIdAndTime
  yesterday?: NotificationIdAndTime
  older?: NotificationIdAndTime
}

const NotificationStateQueryKey = ['notification-state']

export const useSuprSendNotificationState = () => {
  const queryClient = useQueryClient()

  return useQuery({
    queryKey: NotificationStateQueryKey,
    initialData: { today: undefined, yesterday: undefined, older: undefined },
    queryFn: () =>
      queryClient.getQueryData<NotificationState>(NotificationStateQueryKey),
  })
}

export const useSuprSendNotificationStateUpdate = () => {
  const queryClient = useQueryClient()

  const update = useCallback(
    (value: NotificationIdAndTime) => {
      const currentValue = queryClient.getQueryData<NotificationState>(
        NotificationStateQueryKey,
      )
      if (isToday(value.createdOn)) {
        if (value.createdOn > (currentValue?.today?.createdOn ?? 0)) {
          queryClient.setQueryData(NotificationStateQueryKey, {
            ...currentValue,
            today: value,
          })
        }
      } else if (isYesterday(value.createdOn)) {
        if (value.createdOn > (currentValue?.yesterday?.createdOn ?? 0)) {
          queryClient.setQueryData(NotificationStateQueryKey, {
            ...currentValue,
            yesterday: value,
          })
        }
      } else if (value.createdOn > (currentValue?.older?.createdOn ?? 0)) {
        queryClient.setQueryData(NotificationStateQueryKey, {
          ...currentValue,
          older: value,
        })
      }
    },
    [queryClient],
  )
  return { update }
}
