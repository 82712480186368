import React, { useEffect, useMemo, useState } from 'react'

import { IconButton, MenuItem, SxProps } from '@mui/material'
import {
  GridColumnHeaderParams,
  GridRenderCellParams,
  GridRow,
  GridRowProps,
  GridSortModel,
  GridValueFormatterParams,
} from '@mui/x-data-grid-pro'
import { NavLink, useLocation } from 'react-router-dom'

import {
  FrankieLoader,
  FrankiePopover,
  FrankieTableHeaderCell,
} from 'frankify/src'

import { ApplicantId } from 'entities/applicant'
import {
  DOCUMENT_KEY,
  DocumentStatusTypes,
  documentEn,
  documentStatusOptions,
} from 'entities/document'

import { DateFormatTypes, formatDate, isValidDate } from 'shared/date-time'
import { useI18n } from 'shared/i18n'
import { Noop } from 'shared/typescript'

import { APPLICANT_SUPPORTING_DOCUMENTS_KEY } from '../../applicant-supporting-documents.key'
import { applicantSupportingDocumentsEn } from '../../locale/applicant-supporting-documents.en'
import {
  ApplicantUploadedDocumentsRecord,
  ApplicantUploadedDocumentsRecordProperties,
  BusinessDocumentCatalogRowType,
} from '../../model/applicant-supporting-documents.model'
import {
  ApplicantUploadedTrustDeedRecord,
  ApplicantUploadedTrustDeedRecordProperties,
} from '../../model/applicant-supporting-trust-deed.model'
import { PurchasedTableTypeData } from '../../model/organisations-purchased-documents.model'
import { appSupportHelperQa } from '../../qa/applicant-support-documents.qa'
import { useApplicantSupportingDocumentCommentsQuery } from '../../state/applicant-supporting-document-comments-query/applicant-supporting-document-comments.query'
import {
  useDocumentStamp,
  useManageApplicantSupportingDocument,
} from '../../state/applicant-supporting-document-manage/applicant-supporting-document-manage'

export const TABLE_MIN_WIDTH = 800

export const headerField = <
  T extends ApplicantUploadedDocumentsRecordProperties,
>(
  name: T,
) => name

export const trustHeaderField = <
  T extends ApplicantUploadedTrustDeedRecordProperties,
>(
  name: T,
) => name

export const documentCellWidth = (width: number) => ({
  minWidth: width,
  flex: width / TABLE_MIN_WIDTH,
})

export const documentDateFormatter = ({
  value,
}: Partial<GridValueFormatterParams<string>>) =>
  value && isValidDate(value)
    ? formatDate(value, DateFormatTypes.FullMonthDate)
    : '-'

export const documentTimeFormatter = ({
  value,
}: Partial<GridValueFormatterParams<string>>) =>
  value && isValidDate(value) ? formatDate(value, DateFormatTypes.Time) : '-'

export function DocumentTableRow({
  paramKey,
  ...rowProps
}: GridRowProps & { paramKey: string }) {
  const location = useLocation()
  if (!rowProps.row?.id) {
    return <GridRow {...rowProps} />
  }

  return (
    <NavLink
      className={
        rowProps.row.status === DocumentStatusTypes.NEEDS_REVIEW
          ? 'bg-tertiary-amber-50'
          : ''
      }
      to={`${location.pathname}?${paramKey}=${rowProps.row.id as string}`}
    >
      <GridRow {...rowProps} />
    </NavLink>
  )
}

type AllDocRecordTypes =
  | ApplicantUploadedTrustDeedRecord
  | ApplicantUploadedDocumentsRecord
  | BusinessDocumentCatalogRowType
  | PurchasedTableTypeData

type DocumentTableHeaderProps<T extends AllDocRecordTypes> =
  GridColumnHeaderParams<T> & {
    sortModel: GridSortModel
  }

export function DocumentTableHeader<T extends AllDocRecordTypes>({
  colDef,
  sortModel,
}: DocumentTableHeaderProps<T>) {
  const fieldName = colDef.field as ApplicantUploadedDocumentsRecordProperties

  const currentSortValue = useMemo(
    () => sortModel.find(item => item.field === fieldName)?.sort || 'off',
    [sortModel, fieldName],
  )

  if (!colDef.sortable) return <span>{colDef.headerName}</span>

  return (
    <FrankieTableHeaderCell
      className="!px-0 flex-row-reverse gap-1 uppercase"
      sortValue={currentSortValue}
    >
      {colDef.headerName}
    </FrankieTableHeaderCell>
  )
}

export function DocumentLoadingOverlay() {
  const t = useI18n([APPLICANT_SUPPORTING_DOCUMENTS_KEY], {
    keys: applicantSupportingDocumentsEn,
  })

  return (
    <div className="flex align-center justify-center flex-col w-full h-full bg-mono-white opacity-80">
      <FrankieLoader
        label={t('refreshingDocList')}
        loading
        className="min-h-[76px]"
        size="sm"
        testId={{ loader: appSupportHelperQa.loader }}
      />
    </div>
  )
}

export function DocumentActionMenu({
  row,
  applicantId,
}: GridRenderCellParams<ApplicantUploadedDocumentsRecord> & {
  applicantId: ApplicantId
}) {
  const [open, setOpen] = useState(false)
  const [newDocumentStatus, setNewDocumentStatus] = useState<
    DocumentStatusTypes | false
  >(false)

  const tDoc = useI18n([DOCUMENT_KEY], {
    keys: documentEn,
  })

  const { data: commentsData, isFetched } =
    useApplicantSupportingDocumentCommentsQuery({
      applicantId,
      documentId: row.data.id ?? undefined,
      disabled: !newDocumentStatus,
    })

  const { mutate, isSuccess } = useManageApplicantSupportingDocument({
    applicantId,
    manageType: 'status-change',
    documentId: row.id,
  })

  useEffect(() => {
    if (isSuccess) setNewDocumentStatus(false)
  }, [isSuccess])

  const getDocumentStamp = useDocumentStamp()

  useEffect(() => {
    if (newDocumentStatus && isFetched && commentsData) {
      const reviewedStamp = getDocumentStamp()

      const comment = commentsData[0]
      mutate({
        comment,
        supportingDocument: {
          ...row.data,
          reviewed: reviewedStamp,
          status: newDocumentStatus,
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newDocumentStatus, isFetched, commentsData])

  /**
   * Menu click handle disable to disable the row click
   */
  const handleClick =
    (cb: Noop) =>
    (e: React.MouseEvent<HTMLButtonElement | HTMLLIElement, MouseEvent>) => {
      e.stopPropagation()
      e.preventDefault()
      cb()
    }

  return (
    <div>
      <FrankiePopover
        open={open}
        onOpenChange={setOpen}
        popoverRest={{ placement: 'bottom-start' }}
        trigger={
          <IconButton
            className={`h-[30px] w-[30px] pb-4 rounded-sm px-2 text-tertiary-grey-400 ${
              open ? 'bg-neutral-30' : ''
            }`}
            onClick={handleClick(() => setOpen(prev => !prev))}
          >
            ...
          </IconButton>
        }
      >
        <div className="bg-mono-white shadow-md rounded-sm py-1">
          {Object.entries(documentStatusOptions).map(([value, { tKey }]) => (
            <MenuItem
              key={value}
              className="py-2 text-sm text-secondary-900 hover:text-primary-600 hover:bg-primary-50"
              onClick={handleClick(() => {
                setNewDocumentStatus(value as DocumentStatusTypes)
                setOpen(false)
              })}
            >
              {tDoc(tKey)}
            </MenuItem>
          ))}
        </div>
      </FrankiePopover>
    </div>
  )
}

export const documentTableMuiStyles: SxProps = {
  '& .MuiDataGrid-columnSeparator': {
    display: 'none',
  },
  '& .MuiDataGrid-columnHeaderTitleContainerContent': {
    height: '100%',
  },
  '& .MuiDataGrid-columnHeaders': {
    minHeight: 'unset !important',
    maxHeight: '40px !important',
    lineHeight: 'unset !important',
    borderRadius: '0 !important',
  },
  '& .MuiDataGrid-columnHeaderTitleContainer': {
    padding: '0 10px !important',
  },
  '& .MuiDataGrid-row': {
    minHeight: 'unset !important',
    maxHeight: 'unset !important',
    lineHeight: 'unset !important',
  },
  '& .MuiDataGrid-cell': {
    maxHeight: 'unset !important',
    px: '8px',
  },
  '& .MuiDataGrid-cellContent': {
    whiteSpace: 'normal',
  },
}
