import { capitalCase, sentenceCase } from 'change-case'
import { unflatten } from 'flat'
import get from 'lodash.get'

import {
  DocumentAttachment,
  DocumentInformation,
  IndividualsEntityResponse,
  ProcessResultObject,
  ServiceProfile,
  WorkflowExecutionStepResult,
  WorkflowExecutionSummary,
} from 'entities/entity/model/entity.model'

import { DateFormatTypes, formatDate } from 'shared/date-time'
import { GalleryImage } from 'shared/document-thumbs/ui/document-thumbs'
import { getDataFileUrl } from 'shared/file'

type IdvValues = {
  id: string
  label: string
  value: string
  success?: boolean
}

export type IdvCheckReport = {
  key: string
  title: string
  success: boolean
  isStatusUnknown: boolean
  description: string
  children?: IdvCheckReport[]
  value?: string
}

export type IdvDocument = {
  title: string
  values: IdvValues[]
  comparisonValues: OcrComparison[]
  report: IdvCheckReport[]
  failingReports: IdvCheckReport[]
  attachments?: GalleryImage[]
  flags: {
    reportSummary: boolean
    ocrSummary: boolean
    ocrComparison: boolean
    overallIdv: boolean
  }
  uploadedOn: {
    date: string
    time: string
    via: string | null
  }
  updatedOn: {
    date: string
    time: string
  }
}

type OcrComparedValues = {
  label: string
  ocrKey: string | string[]
  entityPath: string | ((payload: OcrComparisonPayload) => string)
  formatter?: (value: string) => string
}

const COMMON_OCR_COMPARISON_MAP: OcrComparedValues[] = [
  {
    label: 'Given Name',
    ocrKey: ['OcrScannedGivenName', 'OcrScannedFirstName'],
    entityPath: 'entity.individual.name.givenName',
  },
  {
    label: 'Middle Name',
    ocrKey: 'OcrScannedMiddleName',
    entityPath: 'entity.individual.name.middleName',
  },
  {
    label: 'Last Name',
    ocrKey: ['OcrScannedFamilyName', 'OcrScannedLastName'],
    entityPath: 'entity.individual.name.familyName',
  },
  {
    label: 'Date of Birth',
    ocrKey: 'OcrScannedDateOfBirth',
    entityPath: payload =>
      formatDate(
        payload.entity.individual?.dateOfBirth?.normalized || '-',
        DateFormatTypes.ShortDate,
      ),
    formatter: value => formatDate(value || '-', DateFormatTypes.ShortDate),
  },
  {
    label: 'Country of Issue',
    ocrKey: 'OcrScannedIssuingCountry',
    entityPath: 'doc.country',
  },
  {
    label: 'Date of Expiry',
    ocrKey: ['OcrScannedExpiryDate', 'OcrScannedDateOfExpiry'],
    entityPath: payload =>
      formatDate(
        payload.doc.expiryDate?.normalized || '-',
        DateFormatTypes.ShortDate,
      ),
    formatter: value => formatDate(value || '-', DateFormatTypes.ShortDate),
  },
  {
    label: 'Date of Issue',
    ocrKey: ['OcrScannedIssueDate', 'OcrScannedDateOfIssue'],
    entityPath: payload =>
      formatDate(
        payload.doc.issueDate?.normalized || '-',
        DateFormatTypes.ShortDate,
      ),
    formatter: value => formatDate(value || '-', DateFormatTypes.ShortDate),
  },
  {
    label: 'Residential Address',
    ocrKey: 'OcrScannedAddress',
    entityPath: 'entity.individual.addresses.0.unstructuredLongForm',
  },
  {
    label: 'Gender',
    ocrKey: 'OcrScannedGender',
    entityPath: payload => {
      const gender = payload.entity.individual?.gender?.gender
      if (!gender || gender === 'UNSPECIFIED') return '-'
      return gender
    },
  },
]

type DocumentType =
  | 'DRIVERS_LICENSE'
  | 'PASSPORT'
  | 'NATIONAL_HEALTH_ID'
  | 'NATIONAL_ID'
  | 'BIRTH_CERT'
  | 'TAX_ID'
  | 'OTHER'

const OCR_COMPARISON_MAP: Record<DocumentType, OcrComparedValues[]> = {
  DRIVERS_LICENSE: [
    ...COMMON_OCR_COMPARISON_MAP,
    {
      label: 'Licence Number',
      ocrKey: 'OcrScannedDocumentNumber',
      entityPath: 'doc.primaryIdentifier',
    },
    {
      label: 'Card Number',
      ocrKey: 'OcrScannedIdNumber',
      entityPath: 'doc.secondaryIdentifier',
    },
    {
      label: 'Issuing State',
      ocrKey: 'OcrScannedIssuingState',
      entityPath: 'doc.subdivision',
    },
  ],
  PASSPORT: [
    ...COMMON_OCR_COMPARISON_MAP,
    {
      label: 'Passport Number',
      ocrKey: 'OcrScannedDocumentNumber',
      entityPath: 'doc.primaryIdentifier',
    },
  ],
  NATIONAL_HEALTH_ID: [
    ...COMMON_OCR_COMPARISON_MAP,
    {
      label: 'Card Number',
      ocrKey: 'OcrScannedDocumentNumber',
      entityPath: 'doc.primaryIdentifier',
    },
  ],
  NATIONAL_ID: [
    ...COMMON_OCR_COMPARISON_MAP,
    {
      label: 'ID Number',
      ocrKey: 'OcrScannedDocumentNumber',
      entityPath: 'doc.primaryIdentifier',
    },
  ],
  BIRTH_CERT: [
    ...COMMON_OCR_COMPARISON_MAP,
    {
      label: 'Certificate Number',
      ocrKey: 'OcrScannedDocumentNumber',
      entityPath: 'doc.primaryIdentifier',
    },
  ],
  TAX_ID: [
    ...COMMON_OCR_COMPARISON_MAP,
    {
      label: 'Tax ID Number',
      ocrKey: 'OcrScannedDocumentNumber',
      entityPath: 'doc.primaryIdentifier',
    },
  ],
  OTHER: [
    ...COMMON_OCR_COMPARISON_MAP,
    {
      label: 'Document Number',
      ocrKey: 'OcrScannedDocumentNumber',
      entityPath: 'doc.primaryIdentifier',
    },
  ],
}

type OcrComparison = {
  label: string
  ocrValue: string
  entityValue: string
  match: boolean
}

type OcrComparisonPayload = {
  entity: IndividualsEntityResponse
  doc: DocumentInformation
  comparison: ProcessResultObject<'IDV_OCR_COMPARISON'>
  ocr: ProcessResultObject<'IDV_OCR'>
}

type MakeOcrComparisonValuesParams = {
  doc?: DocumentInformation
  comparisonResult?: ProcessResultObject<'IDV_OCR_COMPARISON'>
  entity?: IndividualsEntityResponse
  ocrResult?: ProcessResultObject<'IDV_OCR'>
}

function makeOcrComparisonValues({
  doc,
  comparisonResult,
  entity,
  ocrResult,
}: MakeOcrComparisonValuesParams): OcrComparison[] {
  if (!doc || !entity || !ocrResult) return []
  const payload: OcrComparisonPayload = {
    entity,
    doc,
    comparison:
      comparisonResult || ({} as ProcessResultObject<'IDV_OCR_COMPARISON'>),
    ocr: ocrResult,
  }

  const remainingOcrResult = {
    ...(ocrResult.supplementaryData?.resultMap || {}),
  }
  const ignoredKeys = [
    'OcrScannedFullName',
    'OcrScannedMismatch',
    'OcrScannedDocumentType',
  ]
  ignoredKeys.forEach(key => delete remainingOcrResult[key])

  const docType = Object.keys(OCR_COMPARISON_MAP).includes(doc.type)
    ? (doc.type as DocumentType)
    : 'OTHER'
  const comparison = OCR_COMPARISON_MAP[docType].map(
    ({ label, entityPath, ocrKey, formatter }) => {
      let ocrValue = ''
      let match = false
      const getOcrValue = (key: string) => {
        const value = get(
          payload,
          `ocr.supplementaryData.resultMap.${key}.resultNormalized`,
          '',
        )
        return typeof formatter === 'function' ? formatter(value) : value
      }
      const isMatch = (key: string) =>
        !get(
          payload,
          `comparison.supplementaryData.mismatchMap.${key}.originalName`,
          '',
        )

      if (Array.isArray(ocrKey)) {
        ;[ocrValue] = ocrKey.map(getOcrValue).filter(Boolean)
        match = ocrKey.map(isMatch).every(Boolean)
        ocrKey.forEach(key => delete remainingOcrResult[key])
      } else {
        ocrValue = getOcrValue(ocrKey)
        match = isMatch(ocrKey)
        delete remainingOcrResult[ocrKey]
      }

      const entityValue =
        typeof entityPath === 'function'
          ? entityPath(payload)
          : get(payload, entityPath, '')

      return {
        label,
        ocrValue,
        entityValue,
        match,
      } as OcrComparison
    },
  )

  // Always remove OcrScannedFullName from remainingOcrResult
  delete remainingOcrResult.OcrScannedFullName

  // Add remaining OCR values
  Object.keys(remainingOcrResult).forEach(key => {
    comparison.push({
      label: capitalCase(key.replace('OcrScanned', '')),
      ocrValue: remainingOcrResult[key].resultNormalized,
      entityValue: '-',
      match: true,
    })
  })

  return comparison
}

type MakeIdvValuesParams = {
  doc?: DocumentInformation
  comparisonResult?: ProcessResultObject<'IDV_OCR_COMPARISON'>
  entity?: IndividualsEntityResponse
}

const IDV_VALUES_ORDER = [
  'OcrScannedFullName',
  'OcrScannedDateOfBirth',
  'OcrScannedDocumentNumber',
  'OcrScannedReferenceNumber',
  'OcrScannedIssuingState',
] as string[]

export function makeIdvValues({
  comparisonResult,
}: MakeIdvValuesParams): IdvValues[] {
  const results = [] as IdvValues[]
  if (comparisonResult?.supplementaryData?.resultMap) {
    const ignoredKeys = [
      'OcrScannedMismatch',
      'OcrScannedDocumentType',
      'OcrScannedFirstName',
      'OcrScannedLastName',
      'OcrScannedGivenName',
      'OcrScannedMiddleName',
      'OcrScannedFamilyName',
    ]
    Object.entries(comparisonResult.supplementaryData.resultMap).forEach(
      ([key, value]) => {
        if (key.startsWith('OcrScanned') && !ignoredKeys.includes(key)) {
          const newKey = key.replace('OcrScanned', '')
          results.push({
            id: key,
            label: capitalCase(newKey),
            value: value.resultNormalized || '-',
            success: !comparisonResult.supplementaryData?.mismatchMap[key],
          })
        }
      },
    )
  }

  return results.sort((a, b) => {
    const aIndex = IDV_VALUES_ORDER.indexOf(a.id)
    const bIndex = IDV_VALUES_ORDER.indexOf(b.id)

    // If both items are not in the predefined order, maintain their original order
    if (aIndex === -1 && bIndex === -1) return 0

    // If only one item is in the predefined order, prioritize it
    if (aIndex === -1) return 1
    if (bIndex === -1) return -1

    // Sort by predefined order
    return aIndex - bIndex
  })
}

type Check = {
  originalName: string
  originalData: string
  resultNormalized: string
}

type TreeNode = {
  id: string
  name: string
  data: Check
  children?: Record<string, TreeNode>
}

const FILTER_KEYS = ['Status']
function buildTree(flatJson: Record<string, string>): TreeNode[] {
  const result = {} as Record<string, string>
  const orderKeys = new Set<string>()
  for (const key in flatJson) {
    if (Object.prototype.hasOwnProperty.call(flatJson, key)) {
      const value = flatJson[key]

      const [, ...parts] = key.split('.')
      // Exclude .Status keys
      if (!FILTER_KEYS.includes(parts[0])) {
        let newKey = ''
        if (parts.length === 1) {
          ;[newKey] = parts
        } else {
          newKey = `${parts.join('.children.')}`
        }

        const id = parts[parts.length - 1]
        result[`${newKey}.id`] = id
        result[`${newKey}.name`] = sentenceCase(id)
        result[`${newKey}.data`] = value

        if (parts[0] !== 'Overall') {
          orderKeys.add(parts[0])
        }
      }
    }
  }

  // Only add Overall to orderKeys if it exists in flatJson
  const hasOverall = Object.keys(flatJson).some(
    key => key.split('.')[1] === 'Overall',
  )
  if (hasOverall) {
    orderKeys.add('Overall') // Add Overall to the end if it exists
  }

  const map: Record<string, TreeNode> = unflatten(result)
  const output = [] as TreeNode[]

  // Update parent status based on children status
  Object.entries(map).forEach(([key, node]) => {
    const childrenStatus = Object.values(node.children || {}).map(
      child => child.data.resultNormalized,
    )

    if (childrenStatus.length && node.name !== 'Scores') {
      const status = childrenStatus.every(
        s => s.toLocaleLowerCase() === 'clear',
      )
        ? 'clear'
        : 'rejected'
      map[key].data.resultNormalized = status
    }
  })

  orderKeys.forEach(key => {
    output.push(map[key])
  })

  return output
}

function isClear(node: TreeNode): boolean {
  let str = ''
  if (node.data.originalName.includes('Scores') && node.children?.Score) {
    str = node.children.Score.data.resultNormalized || ''
  } else {
    str = node.data.resultNormalized
  }
  const lower = str.toLocaleLowerCase()

  switch (lower) {
    case 'clear':
      return true
    case 'suspected':
    case 'rejected':
    case '':
      return false
    default:
      return false
  }
}

function getValueIfScore(node: TreeNode): string | null {
  if (!node.data.originalName.includes('Scores')) {
    return null
  }
  const scoreValue = node.children?.Score.data.originalData || '0.0'
  return `${scoreValue}%`
}

function isStatusUnknown(node: TreeNode): boolean {
  return !['clear', 'rejected', 'suspected'].includes(
    node.data.resultNormalized.toLowerCase(),
  )
}

type MakeIdvReportsParams = {
  idvDocumentResult?: ProcessResultObject<'IDV_DOCUMENT'>
  idvOcrCompResult?: ProcessResultObject<'IDV_OCR_COMPARISON'>
}

type IdvReportsResult = {
  allReports: IdvCheckReport[]
  failingReports: IdvCheckReport[]
}

function makeIdvReports({
  idvDocumentResult,
  idvOcrCompResult,
}: MakeIdvReportsParams): IdvReportsResult {
  const resultMap = idvDocumentResult?.supplementaryData?.resultMap
  if (!resultMap) return { allReports: [], failingReports: [] }

  const reportTree = buildTree(resultMap)

  const reports: IdvCheckReport[] = []

  // Check for DocumentType in mismatch map from IDV_OCR_COMPARISON
  const mismatchMap = idvOcrCompResult?.supplementaryData?.mismatchMap
  if (mismatchMap?.DocumentType !== undefined) {
    // Add unsupported document as first issue
    reports.push({
      key: 'unsupported-document',
      title: 'Unsupported document',
      success: false,
      isStatusUnknown: false,
      description: 'Fail',
    })
  }

  // Add the rest of the report items
  const result = reportTree.map(node => {
    const clear = isClear(node)
    return {
      key: node.id,
      title: node.name,
      success: clear,
      isStatusUnknown: isStatusUnknown(node),
      description: clear ? 'Pass' : 'Fail',
      children: Object.values(node.children || {}).map(child => {
        const clear = isClear(child)
        return {
          key: child.id,
          title: child.name,
          success: clear,
          isStatusUnknown: isStatusUnknown(child),
          description: clear ? 'Pass' : 'Fail',
          value: getValueIfScore(child),
        }
      }),
    } as IdvCheckReport
  })

  const allReports = [...reports, ...result]
  const failingReports = allReports.filter(
    report => report.success === false && report.key !== 'Scores',
  )

  return {
    allReports,
    failingReports,
  }
}

function getDocumentId(
  processResult?:
    | ProcessResultObject<'IDV_OCR'>
    | ProcessResultObject<'IDV_DOCUMENT'>,
) {
  if (!processResult) return null
  if (processResult.objectType === 'DOCUMENT') {
    return processResult.objectId
  }
  return (processResult as ProcessResultObject<'IDV_OCR'>).supplementaryData
    ?.scannedDocumentId
}

function getAttachments(
  document: DocumentInformation,
  processResult?: ProcessResultObject<'IDV_OCR'>,
): GalleryImage[] {
  if (!processResult) return []
  const attachmentIds = [
    processResult.supplementaryData?.scannedAttachmentFrontId,
    processResult.supplementaryData?.scannedAttachmentBackId,
  ]
  const files = attachmentIds
    .map(id => document.attachments?.find(a => a.attachmentId === id))
    .filter(Boolean) as DocumentAttachment[]
  return files.map(attachment => {
    if (!(attachment.data.base64 && attachment.mimeType))
      return {} as GalleryImage

    return {
      url: getDataFileUrl(attachment.mimeType, attachment.data.base64),
      side: attachment.side,
      mimeType: attachment.mimeType,
      id: attachment.attachmentId,
      type: attachment.type,
      createdAt: attachment.createdAt,
    } as GalleryImage
  })
}

type ProcessResultType =
  | 'IDV_DOCUMENT'
  | 'IDV_FACIAL_COMPARISON'
  | 'IDV_FACIAL_LIVENESS'
  | 'IDV_OCR'
  | 'IDV_OCR_COMPARISON'

type ProcessResultMap<T extends ProcessResultType> = Record<
  T,
  ProcessResultObject<T>
>

function getIdvStep(workflowSummary?: WorkflowExecutionSummary) {
  return workflowSummary?.workflowResultData?.workflowStepResults?.find(
    i => i.stepName === 'IDV',
  )
}

function getProcessResultMap(idvStep?: WorkflowExecutionStepResult) {
  return idvStep?.processResults?.reduce<ProcessResultMap<ProcessResultType>>(
    (
      acc: ProcessResultMap<ProcessResultType>,
      item: ProcessResultObject<ProcessResultType>,
    ) => {
      acc[item.supplementaryData?.type as ProcessResultType] = item
      return acc
    },
    {} as ProcessResultMap<ProcessResultType>,
  )
}

function getFlags(
  idvDocumentResult?: ProcessResultObject<'IDV_DOCUMENT'>,
  idvOcrResult?: ProcessResultObject<'IDV_OCR'>,
  idvOcrCompResult?: ProcessResultObject<'IDV_OCR_COMPARISON'>,
): IdvDocument['flags'] {
  return {
    reportSummary: !!idvDocumentResult,
    ocrSummary: !!idvOcrResult,
    ocrComparison: !!idvOcrCompResult,
    overallIdv: !!idvDocumentResult || !!idvOcrCompResult,
  }
}

export function makeIdvDocument(
  entity?: IndividualsEntityResponse,
  workflow?: ServiceProfile,
): IdvDocument | null {
  if (!entity || !workflow) return null
  const idvStep = getIdvStep(workflow.workflowSummaries?.at(0))
  const processResultMap = getProcessResultMap(idvStep)
  if (!processResultMap) return null

  const idvDocumentResult = processResultMap.IDV_DOCUMENT as
    | ProcessResultObject<'IDV_DOCUMENT'>
    | undefined
  const idvOcrResult = processResultMap.IDV_OCR as
    | ProcessResultObject<'IDV_OCR'>
    | undefined
  const idvOcrCompResult = processResultMap.IDV_OCR_COMPARISON as
    | ProcessResultObject<'IDV_OCR_COMPARISON'>
    | undefined

  const documentId = getDocumentId(idvDocumentResult || idvOcrResult)
  const document = entity.individual?.documents?.IDENTITY?.find(
    doc => doc.documentId === documentId,
  )
  const attachments = document ? getAttachments(document, idvOcrResult) : []

  const comparisonValues = makeOcrComparisonValues({
    entity,
    doc: document,
    comparisonResult: idvOcrCompResult,
    ocrResult: idvOcrResult,
  })
  const flags = getFlags(idvDocumentResult, idvOcrResult, idvOcrCompResult)

  const idvDocument: IdvDocument = {
    attachments,
    flags,
    comparisonValues,
    title:
      idvDocumentResult?.supplementaryData?.detectedDocumentType ||
      document?.type ||
      '',
    values: makeIdvValues({
      comparisonResult: idvOcrCompResult,
    }),
    report: makeIdvReports({
      idvDocumentResult,
      idvOcrCompResult,
    }).allReports,
    failingReports: makeIdvReports({
      idvDocumentResult,
      idvOcrCompResult,
    }).failingReports,
    uploadedOn: {
      date: formatDate(
        document?.createdAt || '',
        DateFormatTypes.DateNumbersSlash,
      ),
      time: formatDate(
        document?.createdAt || '',
        DateFormatTypes.Time24HoursWithSeconds,
      ),
      via: null,
    },
    updatedOn: {
      date: formatDate(
        idvOcrCompResult?.updatedAt || '',
        DateFormatTypes.DateNumbersSlash,
      ),
      time: formatDate(
        idvOcrCompResult?.updatedAt || '',
        DateFormatTypes.Time24HoursWithSeconds,
      ),
    },
  }

  return idvDocument
}
