import { WatchlistReasonTypes } from 'entities/applicant/model/applicant-watchlist.model'

import { I18nKeys } from 'shared/i18n'

import { watchListReasonEn } from '../locale/applicant-general-information.en'

export const watchListOptions: Array<{
  tKey: I18nKeys<typeof watchListReasonEn>
  value: WatchlistReasonTypes
}> = [
  {
    tKey: 'watchListReason.previouslyBlocklisted',
    value: WatchlistReasonTypes.WAS_BLACKLISTED,
  },
  {
    tKey: 'watchListReason.suspectedFraud',
    value: WatchlistReasonTypes.SUSPECTED_FRAUD,
  },
  {
    tKey: 'watchListReason.suspectedIdTheft',
    value: WatchlistReasonTypes.SUSPECTED_ID_THEFT,
  },
  {
    tKey: 'watchListReason.idTheftVictim',
    value: WatchlistReasonTypes.ID_THEFT_VICTIM,
  },
  {
    tKey: 'watchListReason.suspiciousData',
    value: WatchlistReasonTypes.SUSPICIOUS_DATA,
  },
  {
    tKey: 'watchListReason.smrRaised',
    value: WatchlistReasonTypes.SMR_RAISED,
  },
  {
    tKey: 'watchListReason._2xSMRReportsLodged',
    value: WatchlistReasonTypes._2X_SMR_REPORTS_LODGED,
  },
]
