import { bffClient, IClient } from 'shared/client'

import { ABRCompany } from '../model/abr-search-model'

export class ABRSearchAPI {
  client: IClient

  constructor(client: IClient) {
    this.client = client
  }

  async searchOrganisation(search: string) {
    return this.client.get<ABRCompany[]>('/data/v2/businesses', {
      params: { search },
    })
  }
}

export const abrSearchAPI = new ABRSearchAPI(bffClient)
