export const applicantBusinessOwnershipEn = {
  ubo: {
    heading: 'Ultimate Beneficial Owners (Individuals)',
    subHeading:
      'The following individuals have been identified as Beneficial Owners using information as reported by ASIC and our partners.',
    description:
      'Ultimate beneficial owners have been defined as having {{uboThreshold}}% or more ownership. All figures are rounded up to 2 decimal places.',
    emptyText: 'No individuals have been identified',
  },

  blockingEntities: {
    heading: 'Blocking entities',
    subHeading:
      'The following Entities have been identified as having {{blockingThreshold}}% or more ownership as reported by ASIC and our partners, but an ultimate individual could not be determined due to non-beneficial ownership, foreign ownership, public ownership, etc.',
    description:
      'The following Entities have been identified as having significant ownership as reported by ASIC and our partners, but an ultimate individual could not be determined due to non-beneficial ownership, foreign ownership, public ownership, etc.',
    emptyText: 'No blocking entities identified',
  },

  otherOwners: {
    heading: 'Other Owners',
    subHeading:
      'The following individuals and organisations have been identified as owners but do not meet the threshold to be classified as UBOs or Blocking Entities.',
    emptyText: 'No beneficial owners have been identified',
  },

  nonIndividualOwners: {
    heading: 'Non-Individual Owners',
    subHeading:
      'The following non-individual entity types own an interest in this entity.',
    emptyText: 'No non-individual entities have been identified',
  },

  linkedCompanies: {
    heading: 'Linked Companies',
    subHeading:
      'The beneficial owners have been linked to an ownership interest in this entity through the following entities.',
    emptyText: 'No linked companies have been identified',
  },

  officeHolders: {
    heading: 'Officeholders & Other Roles',
    subHeading:
      'The following individuals have been identified as current officeholders of this company.',
    emptyText: 'No officeholders have been identified',
  },

  shareCapital: {
    heading: 'Share Capital',
    subHeading:
      'The following section outlines the shareholdings found for this entity as collected from ASIC and our partners.',
    emptyText: {
      shareCapital: 'No issued share capital have been identified',
      shareHolder: 'No shareholder entities have been identified',
    },

    shareHolders: 'Shareholders',
    issued: 'Issued Share Capital',
  },

  associatedParties: {
    heading: 'Associated Parties',
    subHeading: 'Manually add associated parties.',
    emptyText: 'There are no manually added Associated Parties',
  },

  noEntityData: 'No entities have been identified',

  addEntity: 'Add Entity',
  searchExistingParties: 'Search for existing Individual/Organisation',
  createNewParties: 'Create a new Individual/Organisation',
  associateNewParties: 'Add new associate party',
  associateExistingParties: 'Associate Existing Parties',
  editAssociateParties: 'Edit associate parties',
  associateOrganisation: 'Associate Organisation',
  associateIndividual: 'Associate Individual',

  personal: 'Personal',
  individual: 'Individual',
  business: 'Business',

  personalInfo: 'Personal Info',
  businessInfo: 'Business Info',

  optional: '(optional)',

  givenName: 'Given Name',
  middleName: 'Middle Name',
  familyName: 'Family Name',
  roleType: 'Role Type (select all applicable)',
  upto2Decimal: '(up to 2 decimal places)',
  beneficiallyOwnership: 'Beneficially held Ownership %',
  nonBeneficiallyOwnership: 'Non Beneficially held Ownership %',
  enterNumber: 'Enter number',
  searchBusiness: 'Search for a Business Name, ABN or ACN...',
  total: 'Total %',
  other: 'Other',

  add: 'Add',
  remove: 'Remove',
  cancel: 'Cancel',
  copy: 'Copy',
  copied: 'Copied',
  back: 'Back',
  done: 'Done',
  yes: 'Yes',
  search: 'Search',
  apply: 'Apply',
  clear: 'Clear',

  loading: {
    default: 'Loading...',
    roles: 'Loading roles...',
    recipe: 'Getting recipe...',
    business: 'Searching business...',
    link: 'Fetching Link...',
    updatingRecipe: 'Updating Recipe...',
    sendLink: 'Sending Link...',
    searchingEntity: 'Searching Entity...',
  },

  error: {
    copy: 'Error copying link',
    noLinkCopy: 'No link to copy',
    sendTextMessage: 'Failed to send Text Message',
    rolesNotConfigured: 'Roles not configured',
    addAssociatedParty: 'Failed to add associated party',
    editAssociatedParty: 'Failed to edit associated party',
    removeAssociatedParty: 'Failed to remove associated party',
    createAssociatedParty: 'Failed to create associated party',
  },

  success: {
    linkSent: 'Link sent successfully',
    linkCopied: 'Link copied successfully',
    addAssociatedParty: 'Associated party added successfully',
    editAssociatedParty: 'Associated party edited successfully',
    removeAssociatedParty: 'Associated party removed successfully',
    createAssociatedParty: 'Associated party created and added successfully',
  },

  action: {
    sendKycLink: 'Send KYC Link',
    removeAssociatedParty: 'Remove Associated Party',
    editAssociatedParty: 'Edit Associated Party',
  },

  selectRecipe: 'Select a Recipe to run checks',
  sendLink: 'Send Link',
  secureLink: 'Secure Link',
  enterIndividualsPhoneNumber:
    "Enter the individual's phone number or copy and paste this link to the customer.",
  checksWillBeRun:
    'Once the process has been completed the checks will be run and the customer profile updated here automatically.',
  sendLinkMsg: 'Send Link via Text Message',
  pleaseConfirm: 'Please Confirm',
  copyLink: 'Copy Link',
  copyTheLink: 'Copy the Link',
  copyLinkAndSendToApplicant:
    'Copy the link and send to the applicant by email or message.',
  sendLinkToApplicant: 'Send link to the applicants mobile number.',
  automaticSelection: 'Automatic Selection',
  mayOverride: 'This may override previous check results',
  newCheckBeingRun:
    'Please note that the selection of any other recipe than the current recipe may result in a new check being run on the profile. This may override previous check results due to the new check.',
  confirmToContinue: 'Please confirm if you wish to continue.',

  actionWillRemoveAssociatedParty:
    'This action will remove this associated party from the organisation.',
  areYouSure: 'Are you sure you want to proceed?',
  iConfirmLinkSent:
    'I confirm link has been sent to customer (this action will be recorded within the audit log).',
  country: 'Country',
  mobilePhoneNumber: 'Mobile Phone Number',
  comment: 'Comment',
  auditPurposeOnly:
    '(For audit report purposes only, comment will not be sent to the applicant)',
  typeComment: 'Type your comment here',
  pleaseSpecify: 'Please Specify',

  hideMonitoring: 'Hide the associated party from the Entities page',
  wishToAddIndividualAsAssociatedParty:
    'Do you wish to add this Individual as an Associated Party under the Organisation?',

  noResults: 'No results found',
  searchBusinessNotInList: 'Search for a business that is not on this list',
  name: 'Name',
  abn: 'ABN',
  active: 'Active',
  cancelled: 'Cancelled',
  entityStatus: 'Entity Status',
  businessName: 'Business Name',
  abnOrAcn: 'ABN or ACN (optional)',
  backToRegistrySearch: 'Back to Registry Search',

  fullNameOrFamilyName: 'Full name or family name',
  customerId: 'Customer ID, Frankie ID, ACN or ABN',
  entityId: 'Entity. ID',
  custId: 'Cust. ID',
  searchIndividualOrBusiness: 'Try searching an individual or business above',
  searchNoResults: 'Your search for {{searchedText}} returned no results.',

  abrSummary: {
    heading: 'ABR Extract',
    historicalBusinessNames: 'Historical business names',
    currentBusinessNames: 'Current business names',
    status: 'ABN Status',
    abnStatus: '{{status}} from {{from}}',
    businessName: '{{name}} (effective from {{from}})',
    abn: 'ABN',
    acn: 'ACN',
    type: 'Type',
    legalNames: 'Legal names',
    tradingNames: 'Trading names',
    location: 'Location',
    mainNames: 'Main names',
    name: 'Name',
    noRegisteredBusinessName:
      'No current registered business names, please check historical business names',
    noHistoricalBusinessNames: 'No historical business names',
    viewHistoricalBusinessNames: 'View Historical Names',
  },

  dataGridFields: {
    beneficiallyHeld: 'Beneficially Held',
    beneficiallyHeldOwnership: 'Beneficially Held Ownership',
    nonBeneficiallyHeldOwnership: 'Non-Beneficially Held Ownership',
    classTitle: 'Class Title',
    classCode: 'Class Code',
    currentPrevious: 'Current / Previous',
    entityType: 'Entity Type',
    issues: 'Issues',
    jointHolding: 'Joint Holding',
    name: 'Name',
    entityName: 'Entity Name',
    profileStatus: 'Profile Status',
    recipe: 'Recipe',
    role: 'Role',
    roleType: 'Role Type',
    sharesHeld: 'Shares Held',
    sharesIssued: 'Shares Issued',
    totalAmountPaid: 'Total Amount Paid',
    totalAmountDueAndPayable: 'Total Amount Due and Payable',
    total: 'Total',
    riskLevel: 'Risk Level',
    type: 'Type',
    updatedName: 'Updated Name',
    registryName: 'Registry Name',
    totalShares: 'Total Shares',
    blockingReason: 'Blocking Reason',
  },

  lastUpdated: 'LAST UPDATED',
  frankieId: 'FRANKIE ID',
}
