import React from 'react'

import { INDIVIDUAL_PROFILE_KEY } from 'features/individual-profile/individual-profile.keys'
import { individualProfileEn } from 'features/individual-profile/locale/new-profile.en'
import { individualProfileVueMigratedQa } from 'features/individual-profile/qa/individual-profile.qa'

import { TextFormField } from 'shared/form'
import { useI18n } from 'shared/i18n'

import { DocumentCategory } from '../document-category/document-category'
import { DocumentsSubFormProps } from '../document-sub-forms.types'

function BankStatement({ form, idx, wrapperClasses }: DocumentsSubFormProps) {
  const { control } = form
  const t = useI18n([INDIVIDUAL_PROFILE_KEY], { keys: individualProfileEn })

  return (
    <div className={wrapperClasses}>
      <TextFormField
        className="basis-[32%]"
        label={t('documentForm.bankCardNumber')}
        placeholder={t('documentForm.bankCardNumber')}
        control={control}
        name={`document.${idx}.idNumber`}
        rules={{ required: true }}
        testId={{
          input: individualProfileVueMigratedQa.document.idNumberField,
        }}
        shouldUnregister
      />

      <TextFormField
        className="basis-[32%]"
        label={t('documentForm.homeCountryFullName')}
        placeholder={t('documentForm.homeCountryFullName')}
        control={control}
        name={`document.${idx}.homeCountryFullName`}
        rules={{ required: true }}
        testId={{
          input: individualProfileVueMigratedQa.document.homeCountryFullName,
        }}
        shouldUnregister
      />
      <div className="basis-[32%]" />
      <TextFormField
        className="basis-[32%]"
        label={t('documentForm.nationIdNumber')}
        placeholder={t('idType.nationalID')}
        control={control}
        name={`document.${idx}.nationalId`}
        rules={{ required: true }}
        testId={{
          input: individualProfileVueMigratedQa.document.nationalId,
        }}
        shouldUnregister
      />

      <DocumentCategory
        disabled
        className="basis-[32%]"
        form={form}
        idx={idx}
      />
    </div>
  )
}

export { BankStatement }
