import React from 'react'

import { StepperIconStyle, StepperLabelStyle } from './stepper.theme'
import { IFrankieStepperProps } from './stepper.types'
import { FrankieDivider } from '../divider'
import { FrankieIcon } from '../icon'

export function Stepper({ steps, currentStep }: IFrankieStepperProps) {
  return (
    <div className="flex justify-start">
      {steps.map((step, index) => (
        <div className="flex shrink items-center">
          {index <= currentStep && (
            <FrankieIcon
              name="mdiCheckCircle"
              size="sm"
              {...step.icon}
              className={StepperIconStyle({
                selected: index === currentStep,
                className: step.icon?.className,
              })}
            />
          )}
          <span
            className={StepperLabelStyle({
              selected: index === currentStep,
              className: step.className,
            })}
          >
            {step.label}
          </span>
          {index < steps.length - 1 && <FrankieDivider className="w-10 mx-3" />}
        </div>
      ))}
    </div>
  )
}
