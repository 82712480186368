import { AustralianStateCodeTypes, CountryAlpha3Code } from 'entities/country'
import { DocumentIdTypes } from 'entities/document'

import { PartialRecord, aliasKeys } from 'shared/typescript'
import { ALPHA_NUMERIC_PATTERN, NUMERIC_PATTERN } from 'shared/validation'

import { IndividualProfileInputTypes } from './form.model'
import { MCareCard } from '../assets'
import { individualProfileEn } from '../locale/new-profile.en'

export enum CountryCodesTypes {
  AUSTRALIA = 'AUS',
  NEW_ZEALAND = 'NZL',
  UNITED_KINGDOM = 'GBR',
  INDIA = 'IND',
  PHILIPPINES = 'PHL',
  CHINA = 'CHN',
  HONG_KONG = 'HKG',
  INDONESIA = 'IDN',
  MALAYSIA = 'MYS',
  SPAIN = 'ESP',
  SWEDEN = 'SWE',
  ITALY = 'ITA',
  BRAZIL = 'BRA',
  MEXICO = 'MEX',
  RUSSIA = 'RUS',
  TURKEY = 'TUR',
  SINGAPORE = 'SGP',
  SOUTH_AFRICA = 'ZAF',
  ARGENTINA = 'ARG',
  JORDAN = 'JOR',
  KUWAIT = 'KWT',
  OMAN = 'OMN',
  SAUDI_ARABIA = 'SAU',
  EGYPT = 'EGY',
  ROMANIA = 'ROU',
  VIETNAM = 'VNM',
  CAMBODIA = 'KHM',
  THAILAND = 'THA',
  UNITED_STATES_AMERICA = 'USA',
  DEFAULT = 'DEFAULT',
}

export const documentIdTypesOption: ({
  tKey: keyof (typeof individualProfileEn)['idType']
  value: DocumentIdTypes
} & (
  | {
      isDefault: true
    }
  | {
      isDefault: false
      country: {
        code: CountryAlpha3Code
        additionalTextTKey?: keyof (typeof individualProfileEn)['additionalText']
      }[]
    }
))[] = [
  {
    tKey: 'driverLicense',
    value: DocumentIdTypes.DRIVERS_LICENSE,
    isDefault: false,
    country: [
      { code: CountryCodesTypes.AUSTRALIA },
      { code: CountryCodesTypes.NEW_ZEALAND },
      { code: CountryCodesTypes.INDIA },
      { code: CountryCodesTypes.CHINA },
    ],
  },
  {
    tKey: 'passport',
    value: DocumentIdTypes.PASSPORT,
    isDefault: true,
  },
  {
    tKey: 'visa',
    value: DocumentIdTypes.VISA,
    isDefault: false,
    country: [
      { code: CountryCodesTypes.AUSTRALIA },
      { code: CountryCodesTypes.SINGAPORE },
    ],
  },
  {
    tKey: 'immigration',
    value: DocumentIdTypes.IMMIGRATION,
    isDefault: false,
    country: [{ code: CountryCodesTypes.AUSTRALIA }],
  },
  {
    tKey: 'birthCertificate',
    value: DocumentIdTypes.BIRTH_CERT,
    isDefault: false,
    country: [
      { code: CountryCodesTypes.AUSTRALIA },
      { code: CountryCodesTypes.NEW_ZEALAND },
    ],
  },
  {
    tKey: 'medicareCard',
    value: DocumentIdTypes.MEDICARE_CARD,
    isDefault: false,
    country: [{ code: CountryCodesTypes.AUSTRALIA }],
  },
  {
    tKey: 'citizenshipCertificate',
    value: DocumentIdTypes.CITIZENSHIP,
    isDefault: false,
    country: [
      { code: CountryCodesTypes.AUSTRALIA },
      { code: CountryCodesTypes.NEW_ZEALAND },
    ],
  },
  {
    tKey: 'changeOfNameCert',
    value: DocumentIdTypes.NAME_CHANGE,
    isDefault: false,
    country: [{ code: CountryCodesTypes.AUSTRALIA }],
  },
  {
    tKey: 'marriageCertificate',
    value: DocumentIdTypes.MARRIAGE_CERT,
    isDefault: false,
    country: [{ code: CountryCodesTypes.AUSTRALIA }],
  },
  {
    tKey: 'taxId',
    value: DocumentIdTypes.TAX_ID,
    isDefault: true,
  },
  {
    tKey: 'other',
    value: DocumentIdTypes.OTHER,
    isDefault: true,
  },
  {
    tKey: 'motorVehicleRegistration',
    value: DocumentIdTypes.VEHICLE_REGISTRATION,
    isDefault: false,
    country: [{ code: CountryCodesTypes.NEW_ZEALAND }],
  },
  {
    tKey: 'nationalID',
    value: DocumentIdTypes.NATIONAL_ID,
    isDefault: false,
    country: [
      { code: CountryCodesTypes.PHILIPPINES },
      { code: CountryCodesTypes.CHINA },
      { code: CountryCodesTypes.MALAYSIA },
      { code: CountryCodesTypes.SWEDEN },
      { code: CountryCodesTypes.ITALY },
      { code: CountryCodesTypes.RUSSIA },
      { code: CountryCodesTypes.TURKEY },
      { code: CountryCodesTypes.JORDAN },
      { code: CountryCodesTypes.KUWAIT },
      { code: CountryCodesTypes.OMAN },
      { code: CountryCodesTypes.SAUDI_ARABIA },
      { code: CountryCodesTypes.EGYPT },
      { code: CountryCodesTypes.ROMANIA },
      { code: CountryCodesTypes.VIETNAM },
      { code: CountryCodesTypes.THAILAND },
      { code: CountryCodesTypes.INDONESIA },
      { code: CountryCodesTypes.SINGAPORE },
      { code: CountryCodesTypes.SPAIN, additionalTextTKey: 'dni' },
      { code: CountryCodesTypes.ARGENTINA, additionalTextTKey: 'dni' },
      { code: CountryCodesTypes.BRAZIL, additionalTextTKey: 'cpf' },
      { code: CountryCodesTypes.MEXICO, additionalTextTKey: 'curp' },
      { code: CountryCodesTypes.SOUTH_AFRICA, additionalTextTKey: 'smartId' },
    ],
  },
  {
    tKey: 'panCard',
    value: DocumentIdTypes.PAN,
    isDefault: false,
    country: [{ code: CountryCodesTypes.INDIA }],
  },
  {
    tKey: 'ssn',
    value: DocumentIdTypes.NATIONAL_ID,
    isDefault: false,
    country: [{ code: CountryCodesTypes.UNITED_STATES_AMERICA }],
  },
  {
    tKey: 'voterId',
    value: DocumentIdTypes.NATIONAL_ID,
    isDefault: false,
    country: [{ code: CountryCodesTypes.CAMBODIA }],
  },
  {
    tKey: 'hongKongId',
    value: DocumentIdTypes.NATIONAL_ID,
    isDefault: false,
    country: [{ code: CountryCodesTypes.HONG_KONG }],
  },
  {
    tKey: 'voterId',
    value: DocumentIdTypes.VOTER_ID,
    isDefault: false,
    country: [{ code: CountryCodesTypes.INDIA, additionalTextTKey: 'epic' }],
  },
  {
    tKey: 'bankStatement',
    value: DocumentIdTypes.BANK_STATEMENT,
    isDefault: false,
    country: [{ code: CountryCodesTypes.CHINA }],
  },
]

export const defaultDocumentIdTypesOption: ({
  tKey: keyof (typeof individualProfileEn)['idType']
  value: DocumentIdTypes
} & (
  | {
      isDefault: true
    }
  | {
      isDefault: false
      country: { code: CountryAlpha3Code; additionalTextTKey?: string }[]
    }
))[] = [
  {
    tKey: 'passport',
    value: DocumentIdTypes.PASSPORT,
    // country: [],
    isDefault: true,
  },
  {
    tKey: 'taxId',
    value: DocumentIdTypes.TAX_ID,
    // country: [],
    isDefault: true,
  },
  {
    tKey: 'other',
    value: DocumentIdTypes.OTHER,
    // country: [],
    isDefault: true,
  },
]

export const cardValue = {
  green: 'G',
  blue: 'B',
  yellow: 'Y',
}

export const genderValue = {
  male: 'M',
  female: 'F',
}

export const mCareCardOption = [
  {
    icon: MCareCard.green,
    value: cardValue.green,
  },
  {
    icon: MCareCard.blue,
    value: cardValue.blue,
  },
  {
    icon: MCareCard.yellow,
    value: cardValue.yellow,
  },
]

export const birthCertificateFieldConfig: PartialRecord<string, string[]> = {
  ...aliasKeys(
    [
      AustralianStateCodeTypes.ACT,
      AustralianStateCodeTypes.NT,
      AustralianStateCodeTypes.SA,
    ],
    [
      IndividualProfileInputTypes.DateOfPrint,
      IndividualProfileInputTypes.CertificateNumber,
    ],
  ),
  ...aliasKeys(
    [AustralianStateCodeTypes.NSW, AustralianStateCodeTypes.VIC],
    [IndividualProfileInputTypes.RegistrationDate],
  ),
  ...aliasKeys(
    [AustralianStateCodeTypes.QLD, AustralianStateCodeTypes.TAS],
    [`${IndividualProfileInputTypes.RegistrationDate}Full`],
  ),
  [AustralianStateCodeTypes.WA]: [
    IndividualProfileInputTypes.RegistrationDistrict,
    IndividualProfileInputTypes.RegistrationDate,
  ],
}

export function validateLicenceNumberPattern(
  ausState: AustralianStateCodeTypes,
) {
  switch (ausState) {
    case AustralianStateCodeTypes.ACT:
    case AustralianStateCodeTypes.NT:
    case AustralianStateCodeTypes.QLD:
    case AustralianStateCodeTypes.VIC:
    case AustralianStateCodeTypes.WA:
      return NUMERIC_PATTERN

    case AustralianStateCodeTypes.NSW:
    case AustralianStateCodeTypes.SA:
    case AustralianStateCodeTypes.TAS:
      return ALPHA_NUMERIC_PATTERN

    default:
      return undefined
  }
}

export function validateLicenceNumberLength(
  ausState: AustralianStateCodeTypes,
) {
  switch (ausState) {
    case AustralianStateCodeTypes.ACT:
    case AustralianStateCodeTypes.NT:
    case AustralianStateCodeTypes.VIC:
      return { maxLength: 10, minLength: 1 }

    case AustralianStateCodeTypes.QLD:
      return { maxLength: 9, minLength: 8 }

    case AustralianStateCodeTypes.NSW:
    case AustralianStateCodeTypes.TAS:
      return { maxLength: 8, minLength: 6 }

    case AustralianStateCodeTypes.WA:
      return { maxLength: 7, minLength: 7 }

    case AustralianStateCodeTypes.SA:
      return { maxLength: 6, minLength: 6 }

    default:
      return {
        maxLength: undefined,
        minLength: undefined,
      }
  }
}

export function validateCardNumberPattern(ausState: AustralianStateCodeTypes) {
  switch (ausState) {
    case AustralianStateCodeTypes.NT:
    case AustralianStateCodeTypes.NSW:
      return NUMERIC_PATTERN

    case AustralianStateCodeTypes.ACT:
    case AustralianStateCodeTypes.QLD:
    case AustralianStateCodeTypes.VIC:
    case AustralianStateCodeTypes.WA:
    case AustralianStateCodeTypes.SA:
    case AustralianStateCodeTypes.TAS:
      return ALPHA_NUMERIC_PATTERN

    default:
      return undefined
  }
}

export function validateCardNumberLength(ausState: AustralianStateCodeTypes) {
  switch (ausState) {
    case AustralianStateCodeTypes.ACT:
    case AustralianStateCodeTypes.QLD:
    case AustralianStateCodeTypes.NSW:
      return { maxLength: 10, minLength: 10 }

    case AustralianStateCodeTypes.WA:
      return { maxLength: 10, minLength: 8 }

    case AustralianStateCodeTypes.SA:
    case AustralianStateCodeTypes.TAS:
      return { maxLength: 9, minLength: 9 }

    case AustralianStateCodeTypes.VIC:
      return { maxLength: 8, minLength: 8 }

    case AustralianStateCodeTypes.NT:
      return { maxLength: 8, minLength: 6 }

    default:
      return {
        maxLength: undefined,
        minLength: undefined,
      }
  }
}
