import React from 'react'

import { FrankieButton, FrankieIcon } from 'frankify/src'

// eslint-disable-next-line import/no-cycle
import { noRowsPlaceholderQa } from 'features/profiles-table/qa/profiles-table.qa'

import { useI18n } from 'shared/i18n'
import { TrackingEventsTypes, trackingManager } from 'shared/tracking'

import {
  PROFILES_TABLE_KEY,
  profilesTableEn,
} from '../../locale/profiles-table.en'

export function NoRowsPlaceholder() {
  const t = useI18n([PROFILES_TABLE_KEY], { keys: profilesTableEn })

  const handleGiveFeedback = () => {
    trackingManager.track(TrackingEventsTypes.FilteringFeedback)
  }

  return (
    <div
      className="flex align-center flex-col w-full h-full bg-mono-white"
      data-qa={noRowsPlaceholderQa.container}
    >
      <div className="mt-12">
        <FrankieIcon
          testId={{ icon: noRowsPlaceholderQa.icon }}
          size="xl"
          name="mdiMagnify"
          className="text-primary-300 flex justify-center"
        />
        <div
          className="flex flex-col items-center mt-3"
          data-qa={noRowsPlaceholderQa.noMatchTitle}
        >
          <span className="text-lg font-bold inline-block leading-6">
            {t('noMatchTitle')}
          </span>
          <span
            className="text-sm font-normal max-w-[430px] text-center mt-3 leading-6"
            data-qa={noRowsPlaceholderQa.noMatchSubtitle}
          >
            {t('noMatchSubtitle')}
          </span>
          <FrankieButton
            noStyles
            className="text-primary-800 text-sm block mt-3"
            onClick={handleGiveFeedback}
            testId={{ button: noRowsPlaceholderQa.giveFeedbackBtn }}
          >
            {t('giveFeedback')}
          </FrankieButton>
        </div>
      </div>
    </div>
  )
}
