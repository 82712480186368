import { useCallback, useContext } from 'react'

import { IOverlayOptions } from '../../model/overlay.model'
import { OverlayContext } from '../../state/overlay.context'

export function useOverlay(): [
  (children: React.ReactNode, opts?: IOverlayOptions) => void,
  () => void,
] {
  const { setOverlayStack } = useContext(OverlayContext)

  const closeOverlay = useCallback(() => {
    setOverlayStack(prev => prev.slice(0, -1))
  }, [setOverlayStack])

  const createOverlay = useCallback(
    (children: React.ReactNode, opts?: IOverlayOptions) => {
      setOverlayStack(prev => [
        ...prev,
        { children, onClose: closeOverlay, ...opts },
      ])
    },
    [setOverlayStack, closeOverlay],
  )

  return [createOverlay, closeOverlay]
}
