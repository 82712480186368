import { bffClient, IClient } from 'shared/client'

import { IWorkflow } from '../model/workflow.model'

interface IWorkflowResponse {
  workflows: IWorkflow[]
  requestId: string
}

export class WorkflowApi {
  client: IClient

  constructor(client: IClient) {
    this.client = client
  }

  // eslint-disable-next-line complexity, max-params
  async getWorkflows(serviceName?: string) {
    return this.client.get<IWorkflowResponse>('/f2/v1/workflows', {
      params: {
        serviceName,
      },
    })
  }
}

export const workflowApi = new WorkflowApi(bffClient)
