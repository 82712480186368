import { cva } from 'cva'

export const FrankieCardStyle = cva(
  [
    'w-full',
    'h-min',
    'flex',
    'flex-initial',
    'flex-wrap',
    'bg-mono-white',
    'outline',
    'outline-0',
  ],
  {
    variants: {
      size: {
        xs: ['px-6', 'py-2', 'rounded-0'],
        sm: ['px-6', 'py-3', 'rounded-md'],
        md: ['px-8', 'py-6', 'rounded-md'],
        lg: ['px-8', 'py-8', 'rounded-lg'],
      },
      rounded: {
        false: ['!rounded-0'],
      },
      readOnly: {
        true: ['shadow-sm', 'shadow-shadow/10'],
      },
      disabled: {
        true: ['shadow', 'shadow-shadow/10'],
      },
      interactive: {
        true: [
          'cursor-pointer',
          'hover:bg-neutral-10',
          'active:outline-1',
          'focus-visible:outline-2',
          'outline-primary-900',
          'shadow',
          'shadow-shadow/10',
          'active:shadow-md',
          'active:shadow-shadow/10',
        ],
      },
    },
  },
)
