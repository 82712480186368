import { cva } from 'cva'

export const FrankieAvatarStyle = cva(null, {
  variants: {
    container: {
      sm: ['w-object-sm', 'h-object-sm'],
      md: ['w-object-md', 'h-object-md'],
      lg: ['w-object-lg', 'h-object-lg'],
    },
    content: {
      sm: ['text-xs'],
      md: ['text-xs'],
      lg: ['text-lg'],
    },
  },
  compoundVariants: [
    {
      container: ['sm', 'md', 'lg'],
      className: [
        'bg-tertiary-blue-dark-600',
        'text-on-secondary',
        'rounded-full',
        'font-semibold',
        'flex',
        'flex-initial',
        'items-center',
        'justify-center',
      ],
    },
  ],
})
