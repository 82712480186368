export const nameValidation = {
  required: 'Please enter a name',
  minLength: {
    value: 1,
    message: 'Name must be at least 1 characters long',
  },
}

export const numberValidation = {
  required: 'Please enter a number greater than 0',
  min: {
    value: 1,
    message: 'Please enter a number greater than 0',
  },
  valueAsNumber: true,
}
