import { AxiosError } from 'axios'

import { MfaTypes } from 'entities/mfa'
import { PageData } from 'entities/session'

import { isBefore, minutesToMilliseconds } from 'shared/date-time'
import { ErrorCodeTypes } from 'shared/error'
import { isNumber } from 'shared/typescript'

export interface IWrongMfaCodeErrorData {
  attempts_count: number
  attempts_max: number
}

export function isIWrongMfaCodeErrorData(
  val: unknown,
): val is IWrongMfaCodeErrorData {
  return !!(
    val &&
    typeof val === 'object' &&
    'attempts_max' in val &&
    'attempts_count' in val &&
    isNumber(val.attempts_max) &&
    isNumber(val.attempts_count)
  )
}

export function isMfaResendEmailBlockedError(error: AxiosError): boolean {
  const status = error.response?.status as ErrorCodeTypes | undefined
  const data = error.response?.data as { message: string }
  return !!(
    status &&
    status === ErrorCodeTypes.E403 &&
    data.message === 'Resend is blocked'
  )
}

export type LoginResponseSuccessMfa = {
  message: string
  mfaType: MfaTypes
}

export function getIsMfaResendBlocked(session?: PageData): boolean {
  if (!session) return false

  if (!session.mfaBlockTS) return false

  const minToMs = minutesToMilliseconds(30)

  return isBefore(Date.now(), session.mfaBlockTS + minToMs)
}
