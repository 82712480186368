import React from 'react'

type Props = { children: React.ReactNode; header: string }

export function FilterSection({ header, children }: Props) {
  return (
    <div>
      <div className="text-md mb-2 font-semibold text-tertiary-grey-800">
        {header}
      </div>
      {children}
    </div>
  )
}
