const gallery = [
  'https://i.postimg.cc/tRzyqFGd/a.jpg',
  'https://i.postimg.cc/tRzyqFGd/a.jpg',
  'https://videos.pexels.com/video-files/3997798/3997798-uhd_1440_2732_25fps.mp4',
  'https://videos.pexels.com/video-files/3997798/3997798-uhd_1440_2732_25fps.mp4',
  'https://sample-videos.com/video321/mp4/720/big_buck_bunny_720p_1mb.mp4',
  'https://sample-videos.com/video321/mp4/720/big_buck_bunny_720p_1mb.mp4',
  'https://i.postimg.cc/K8Jsrk1F/sddefault.jpg',
  'https://i.postimg.cc/K8Jsrk1F/sddefault.jpg',
  'https://i.postimg.cc/K8Jsrk1F/sddefault.jpg',
]

export { gallery }
