import React from 'react'

import { UseFormReturn } from 'react-hook-form'

import {
  DocumentForm,
  IIndividualProfileInputs,
} from 'features/individual-profile'

import { blockListDocumentFormQa } from '../../qa/applicant-blocklisted-info-qa'

type Props = {
  form: UseFormReturn<IIndividualProfileInputs>
}

export function BlocklistDocumentForm({ form }: Props) {
  return (
    <DocumentForm
      testId={blockListDocumentFormQa}
      form={form}
      singleDocument
      isOptionalFieldRequired={false}
    />
  )
}
