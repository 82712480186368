import { useInfiniteQuery } from '@tanstack/react-query'

import {
  IProfileFilter,
  PROFILES_QUERY,
  IProfileResponse,
} from 'entities/entity'

import { TrackingEventsTypes, trackingManager } from 'shared/tracking'

import { profilesApi } from '../api/profiles.api'

export const useProfilesQueryInfinite = ({
  filters,
  limit,
  sort,
  enabled = true,
}: {
  filters: IProfileFilter
  limit?: string
  enabled?: boolean
  sort: { field: 'createdAt' | 'updatedAt'; sort: 'asc' | 'desc' | null }
}) =>
  useInfiniteQuery<IProfileResponse>({
    enabled,
    queryKey: [PROFILES_QUERY, filters, sort],
    getNextPageParam: lastPage => {
      if (lastPage.meta.count === lastPage.meta.limit)
        return (lastPage.meta.page ?? 0) + 1
      return undefined
    },
    queryFn: async ({ pageParam }: { pageParam?: string }) => {
      const { data } = await profilesApi.getProfiles(
        sort,
        limit,
        pageParam,
        filters,
      )
      return data
    },
    onError: () => {
      trackingManager.track(
        TrackingEventsTypes.ProfileTableViewFilterAdvancedShowEntitiesError,
      )
    },
  })
