import { useCallback, useMemo, useState } from 'react'

import { GridRowId } from '@mui/x-data-grid-pro'
import { useQuery } from '@tanstack/react-query'

import { ApplicantId } from 'entities/applicant'

import { ErrorResponse } from 'shared/http'

import { applicantAuditReportApi } from '../../api/applicant-audit-report.api'
import {
  ApplicantAuditRecord,
  AuditRecordDataProperties,
  AuditRecordsData,
} from '../../model/applicant-audit-report.model'

type Args = {
  applicantId?: ApplicantId
}

export const AuditRecordQueryKey = ['applicant-audit-record']

export const useApplicantAuditRecordState = ({ applicantId }: Args) =>
  useQuery<ApplicantAuditRecord[], ErrorResponse>({
    enabled: !!applicantId,
    queryKey: [AuditRecordQueryKey, applicantId],
    queryFn: async () => {
      const { data } = await applicantAuditReportApi.getAuditRecords(
        applicantId!,
      )
      return data.records
    },
  })

export const useApplicantAuditTableData = ({ applicantId }: Args) => {
  const [expandedRowIds, setExpandedRowIds] = useState<GridRowId[]>([])

  const {
    data: auditRecord,
    isFetching,
    error,
  } = useApplicantAuditRecordState({
    applicantId,
  })

  const handleExpandedRowIds = useCallback((ids: GridRowId[]) => {
    setExpandedRowIds(ids)
  }, [])

  /**
   * Table Rows data
   */
  const rows: AuditRecordsData[] = useMemo(
    () =>
      auditRecord?.map(record => {
        const isChannelPortal = record.channel === 'portal'

        const data: AuditRecordsData = {
          id: record.ts,
          user:
            isChannelPortal && record.username ? record.username : 'Frankie',
          action: record.action,
          resultAndComments: record.description?.length
            ? record.description
            : [''],

          timeStamp: record.ts,
          requestId: record.requestId,
          isChannelPortal,
          showMetaData:
            record.channel === 'smart-ui' || record.channel === 'portal',
          channel: record.channel ?? '',
          comment: record.comment ?? '',
        }

        return data
      }) ?? [],
    [auditRecord],
  )

  /**
   * Sort Fields (add more fields if needed)
   */
  const sortFields: AuditRecordDataProperties[] = useMemo(
    () => ['timeStamp'],
    [],
  )

  return {
    rows,
    sortFields,
    expandedRowIds,
    handleExpandedRowIds,
    loading: isFetching,
    error,
  }
}
