import React from 'react'

import { GridRowParams } from '@mui/x-data-grid-pro'

import { FrankieIcon, FrankieLoader } from 'frankify/src'

import { ApplicantId } from 'entities/applicant'

import { I18nFunction, useI18n } from 'shared/i18n'
import { capitalizeStringBySeparator } from 'shared/string'

import { APPLICANT_FRAUD_CHECK_KEY } from '../../applicant-fraud-check.key'
import { applicantFraudCheckEn } from '../../locale/applicant-fraud-check.en'
import { FraudListData } from '../../model/applicant-fraud-check.model'
import { applicantFraudCheckTableSlotQa } from '../../qa/applicant-fraud-check.qa'
import { useApplicantFraudCheckState } from '../../state/applicant-fraud-check-state/applicant-fraud-check-state'

export type FraudCheckRowProps = GridRowParams<FraudListData> & {
  t: I18nFunction<typeof applicantFraudCheckEn>
}

/**
 * To trigger the expandable row
 */

export function FraudCheckTableDetailPanel({ row, t }: FraudCheckRowProps) {
  return (
    <div
      className="px-3 py-2 font-medium border-b border-tertiary-grey-200"
      data-qa={applicantFraudCheckTableSlotQa.wrapper}
    >
      <div className="px-4 py-1 text-xs bg-neutral-20 text-tertiary-grey-600">
        {t('matchingResult')}
      </div>

      <div>
        {row.extraDetails.map(extraDetail => (
          <div
            key={extraDetail.label}
            className="flex w-100 px-4 py-3 border-b border-tertiary-grey-200"
          >
            <div className="w-[200px] text-xs text-tertiary-grey-600">
              {capitalizeStringBySeparator(extraDetail.label)}
            </div>
            <div>{extraDetail.value}</div>
          </div>
        ))}
      </div>
    </div>
  )
}

/**
 * FraudCheck Report table loading overlay
 */
export function FraudCheckLoadingOverlay() {
  const t = useI18n([APPLICANT_FRAUD_CHECK_KEY], {
    keys: applicantFraudCheckEn,
  })

  return (
    <div className="flex align-center justify-center flex-col w-full h-full bg-mono-white opacity-80">
      <div>
        <div id="loader">
          <FrankieLoader
            loading
            className="min-h-[76px]"
            size="sm"
            testId={{ loader: applicantFraudCheckTableSlotQa.loader }}
          />
        </div>
        <div className="flex flex-col items-center mt-3">
          <span className="inline-block text-md font-semibold leading-6 text-tertiary-grey-800">
            {t('records.fetching')}
          </span>
        </div>
      </div>
    </div>
  )
}

export function FraudCheckNoRowOverlay({
  applicantId,
}: {
  applicantId: ApplicantId
}) {
  const t = useI18n([APPLICANT_FRAUD_CHECK_KEY], {
    keys: applicantFraudCheckEn,
  })

  const { error, isError } = useApplicantFraudCheckState({ applicantId })

  return (
    <div className="flex align-center justify-center flex-col w-full h-full bg-mono-white opacity-80">
      <div>
        <FrankieIcon
          size="lg"
          name="mdiFileSearchOutline"
          className="text-primary-300 flex justify-center"
          testId={{ icon: applicantFraudCheckTableSlotQa.icon }}
        />
        <div className="flex flex-col items-center mt-3">
          <span className="inline-block text-md font-semibold leading-6 text-tertiary-grey-800">
            {isError ? error.response?.data.message : t('records.empty')}
          </span>
        </div>
      </div>
    </div>
  )
}
