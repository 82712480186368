import { IClient, bffClient } from 'shared/client'

import {
  AddressSuggestionResponse,
  DetailAddressResponse,
} from '../model/address.model'

export class AddressAPI {
  client: IClient

  constructor(client: IClient) {
    this.client = client
  }

  async getAddressSuggestion(
    searchTerm: string,
    regions: string[],
    signal?: AbortSignal,
  ) {
    return this.client.get<AddressSuggestionResponse[]>(
      'data/v1/address/search',
      { params: { searchTerm, regions }, signal },
    )
  }

  async getDetailsAddress(googleAddressId: string) {
    return this.client.get<DetailAddressResponse>(
      `data/v1/address/${googleAddressId}`,
    )
  }
}

export const addressApi = new AddressAPI(bffClient)
