import React, { useEffect, useMemo, useRef, useState } from 'react'

import { Controller, useForm } from 'react-hook-form'

import {
  FrankieAvatar,
  FrankieButton,
  FrankiePopover,
  FrankieTextField,
} from 'frankify/src'

import {
  useTableSelectionState,
  useUpdateTableSelectionState,
} from 'features/profiles-table'

import { useAssignEntityMutation } from 'entities/entity'
import { useSessionQuery, useUserListState } from 'entities/session'

import { TextAreaFormField } from 'shared/form'
import { useI18n } from 'shared/i18n'
import { notification } from 'shared/notification'
import { useOverlay } from 'shared/overlay'
import { TrackingEventsTypes, trackingManager } from 'shared/tracking'
import { useValidationRules } from 'shared/validation'

import {
  BULK_ASSIGN_WIDGET_KEY,
  bulkAssignWidgetEn,
} from '../../locale/bulk-assign.en'
import { bulkAssignOverlayQa } from '../../qa/bulk-assign.qa'

type FormState = {
  assignee: string
  comment: string
}

export function BulkAssignOverlay() {
  const [open, setOpen] = useState<boolean>(false)
  const [search, setSearch] = useState<string>('')
  const inputRef = useRef<HTMLInputElement>(null)
  const { xssRule } = useValidationRules()

  const t = useI18n(BULK_ASSIGN_WIDGET_KEY, { keys: bulkAssignWidgetEn })
  const { data } = useTableSelectionState()
  const [, closeOverlay] = useOverlay()

  const { data: session } = useSessionQuery()

  const userList = useUserListState()
  const {
    handleSubmit,
    setValue,
    control,
    formState: { isValid },
  } = useForm<FormState>({ mode: 'onBlur' })

  const focus = () =>
    setTimeout(() => {
      inputRef.current?.focus()
    }, 1)

  const displayOptions = useMemo(
    () =>
      userList
        .slice(1)
        .filter(option =>
          option.label.toLowerCase().includes(search.toLowerCase()),
        ),
    [userList, search],
  )

  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value)
    setValue('assignee', '', { shouldValidate: true })
  }

  const handleOpen = () => {
    setOpen(true)
    focus()
  }

  const handleClearSearch = () => {
    setSearch('')
    setValue('assignee', '', { shouldValidate: true })
  }

  const updateTableSelection = useUpdateTableSelectionState()

  const { mutate } = useAssignEntityMutation('bulk')

  const onSubmit = (formData: FormState) => {
    trackingManager.track(TrackingEventsTypes.BulkActionsAssignConfirm)

    updateTableSelection([])
    let name = userList.find(u => u.value === formData.assignee)?.label
    if (name === session?.user.realname) {
      name = 'you'
    }
    notification.success(
      t('assigningEntities', { count: data?.length || 0, name }),
    )
    mutate({
      ...formData,
      entityIds: data?.map(i => i.toString()) || [],
    })
    closeOverlay()
  }

  const onClick = () => {
    closeOverlay()
  }

  useEffect(() => {
    trackingManager.track(TrackingEventsTypes.BulkActionsAssignShow)
  }, [])

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="gap-4 flex flex-col"
      data-qa={bulkAssignOverlayQa.container}
    >
      <div
        className="text-xl font-bold text-tertiary-grey-800"
        data-qa={bulkAssignOverlayQa.title}
      >
        {t('assignSelectedEntities')}
      </div>
      <div data-qa={bulkAssignOverlayQa.notification}>
        {t('theUserYouAssignTheseEntitiesToWillReceiveANotification')}
      </div>
      <Controller
        name="assignee"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <FrankiePopover
            flip={false}
            open={open}
            popoverRest={{ placement: 'bottom-start' }}
            onOpenChange={(open, event) => {
              // check if close icon is clicked by fetching data=-name of target icon
              const target = event?.target as HTMLElement
              if (
                target.dataset.name === 'mdiClose' ||
                target.parentElement?.dataset.name === 'mdiClose'
              ) {
                handleClearSearch()
              }
              setOpen(open)
            }}
            initialFocus={-1}
            trigger={
              <FrankieTextField
                testId={{ input: bulkAssignOverlayQa.searchField }}
                type="search"
                size="sm"
                isSearchIcon
                label={t('assignTo')}
                value={search}
                ref={inputRef}
                placeholder={t('searchUser')}
                onChange={handleChangeSearch}
                onClick={handleOpen}
                closeButton={
                  search ? { onClick: handleClearSearch } : undefined
                }
              />
            }
          >
            <div className="bg-mono-white shadow-md rounded-sm w-[400px]">
              <div className="flex flex-col py-2.5 max-h-[200px] overflow-y-auto scrollbar-sm mr-1">
                {!search && (
                  <FrankieButton
                    noStyles
                    className="flex flex-row items-center py-4 px-4 cursor-pointer gap-2 leading-tight text-sm text-primary-700 hover:bg-tertiary-grey-100"
                    onClick={() => {
                      field.onChange(session?.user.username)
                      setOpen(false)
                      setSearch(session?.user.realname || '')
                    }}
                    testId={{ button: bulkAssignOverlayQa.assignToMeBtn }}
                  >
                    {t('assignToMe')}
                  </FrankieButton>
                )}
                {displayOptions.map(option => (
                  <FrankieButton
                    noStyles
                    onClick={() => {
                      field.onChange(option.value)
                      setOpen(false)
                      setSearch(option.label)
                    }}
                    className="flex flex-row items-center py-1.5 px-4 cursor-pointer gap-2 leading-tight text-sm text-tertiary-grey-700 hover:bg-tertiary-grey-100"
                    key={option.value}
                    testId={{ button: bulkAssignOverlayQa.options }}
                  >
                    <FrankieAvatar name={option.label} />
                    {option.label}
                  </FrankieButton>
                ))}
                {displayOptions.length === 0 && (
                  <div className="flex flex-row items-center py-1.5 px-4 cursor-pointer gap-2 leading-tight text-sm text-tertiary-grey-700 hover:bg-tertiary-grey-100">
                    {t('noResultsFound')}
                  </div>
                )}
              </div>
            </div>
          </FrankiePopover>
        )}
      />
      <TextAreaFormField
        testId={{ input: bulkAssignOverlayQa.textAreaFormField }}
        control={control}
        maxLength={500}
        rules={{ ...xssRule }}
        showError
        name="comment"
        placeholder=""
        label={t('comment')}
      />
      <div
        data-qa={bulkAssignOverlayQa.note}
        className="text-xs -mt-4 text-tertiary-grey-500"
      >
        {t(
          'noteThatAssignmentsMayTakeACoupleOfMomentsToUpdateIfMoreThan20WereSelected',
        )}
      </div>
      <div className="flex gap-5 justify-end ">
        <FrankieButton
          onClick={onClick}
          intent="subtle"
          testId={{ button: bulkAssignOverlayQa.cancelBtn }}
        >
          {t('cancel')}
        </FrankieButton>
        <FrankieButton
          type="submit"
          disabled={!isValid}
          testId={{ button: bulkAssignOverlayQa.assignBtn }}
        >
          {t('assignEntities')}
        </FrankieButton>
      </div>
    </form>
  )
}
