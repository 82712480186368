import { useMutation } from '@tanstack/react-query'

import { individualProfileApi } from '../api/individual-profile.api'

type Args = {
  entityId: string
}

export const useCreditHeaderAcknowledgementMutation = () => {
  const mutation = useMutation({
    mutationFn: ({ entityId }: Args) =>
      individualProfileApi.creditHeaderAcknowledge(entityId),
  })
  return mutation
}
