import { CountryAlpha3CodeTypes } from 'entities/country'
import { DocumentAttachmentSide, DocumentType } from 'entities/entity'

import { I18nKeys } from 'shared/i18n'

import { documentF2En } from '../locale/document-f2.en'

export const documentIdTypesOption: Array<{
  tKey: I18nKeys<typeof documentF2En>
  value: DocumentType
}> = [
  {
    tKey: 'documentTypeName.driversLicense',
    value: DocumentType.DRIVERS_LICENSE,
  },
  {
    tKey: 'documentTypeName.passport',
    value: DocumentType.PASSPORT,
  },
  {
    tKey: 'documentTypeName.medicare',
    value: DocumentType.NATIONAL_HEALTH_ID,
  },
  {
    tKey: 'documentTypeName.nationalId',
    value: DocumentType.NATIONAL_ID,
  },
  {
    tKey: 'documentTypeName.birthCertificate',
    value: DocumentType.BIRTH_CERT,
  },
  {
    tKey: 'documentTypeName.taxId',
    value: DocumentType.TAX_ID,
  },
  {
    tKey: 'documentTypeName.other',
    value: DocumentType.OTHER,
  },
]

const documentTypeNameByCountry: {
  [key: string]: Record<string, string> | undefined
} = {
  'documentTypeName.nationalId': {
    [CountryAlpha3CodeTypes.UNITED_STATES_AMERICA]: 'SSN',
    // Add more country mappings as needed
    // AUS: 'TFN',
    // GBR: 'NIN',
  },
}

export const getDocumentDisplayName = (
  tKey: string,
  country?: string,
): string | undefined => {
  if (!country) return undefined
  return documentTypeNameByCountry[tKey]?.[country]
}

export const photoPageIdTypes = [
  DocumentType.PASSPORT,
  DocumentType.NATIONAL_HEALTH_ID,
  DocumentType.NATIONAL_ID,
  DocumentType.TAX_ID,
  DocumentType.OTHER,
]

export enum DocumentSideOptionTypes {
  front = 'front',
  back = 'back',
  photoPage = 'photoPage',
}

export const documentSideOptionMap = {
  [DocumentAttachmentSide.FRONT]: DocumentSideOptionTypes.front,
  [DocumentAttachmentSide.BACK]: DocumentSideOptionTypes.back,
}
