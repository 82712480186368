import React from 'react'

import { IFrankieInteractiveCardProps } from './interactive-card.types'
import { FrankieButton } from '../button'
import { FrankieIcon } from '../icon'

export function FrankieInteractiveCard({
  onClick,
  className = '',
  title,
  helperText,
  startIcon,
  disabled = false,
  testId = {
    card: '',
  },
}: IFrankieInteractiveCardProps): JSX.Element {
  const disabledClass = disabled ? 'text-tertiary-grey-400' : ''
  return (
    <FrankieButton
      disabled={disabled}
      className={`w-full rounded-[4px] border border-solid border-tertiary-grey-300 text-tertiary-grey-700
       hover:bg-tertiary-grey-50 focus-visible:bg-primary-50 active:bg-primary-50
       disabled:cursor-auto disabled:text-tertiary-grey-400 ${className}`}
      noStyles
      onClick={onClick}
      testId={{ button: testId.card }}
    >
      <div className="flex flex-initial items-center justify-between p-4">
        <div className="flex flex-initial items-center gap-3">
          {startIcon && <FrankieIcon {...startIcon} />}
          <div className="flex flex-initial flex-col items-start">
            <div className="font-semibold">{title}</div>
            <div className={`text-xs text-tertiary-grey-500 ${disabledClass}`}>
              {helperText}
            </div>
          </div>
        </div>
        <FrankieIcon name="mdiChevronRight" />
      </div>
    </FrankieButton>
  )
  /* eslint-enable react/button-has-type */
}
