import { useMemo } from 'react'

import { useQuery } from '@tanstack/react-query'

import { ApplicantId, mappedBackendState } from 'entities/applicant'
import { OTHER_ROLE_KEY } from 'entities/organisation'

import { ErrorResponse } from 'shared/http'

import { applicantBusinessOwnershipApi } from '../../api/applicant-business-ownership-international.api'
import {
  IAssociateParty,
  InternationalAssociatedPartyResponse,
} from '../../model/applicant-business-ownership-international.model'

type Args = {
  applicantId: ApplicantId
}

export const ApplicantAssociateInternationalEntityQueryKey =
  'applicant-associate-international-entity-query'

export const useApplicantAssociateInternationalEntityQuery = ({
  applicantId,
}: Args) =>
  useQuery<InternationalAssociatedPartyResponse, ErrorResponse>({
    enabled: !!applicantId,
    queryKey: [ApplicantAssociateInternationalEntityQueryKey, applicantId],
    queryFn: async () => {
      const { data } = await applicantBusinessOwnershipApi.getAssociatedParty(
        applicantId,
      )
      return data
    },
  })

export const useApplicantAssociateInternationalEntityState = ({
  applicantId,
}: Args) => {
  const { data, isFetching } = useApplicantAssociateInternationalEntityQuery({
    applicantId,
  })

  const associatedEntry = useMemo(() => {
    if (data) {
      const { associatedParties } = data
      // eslint-disable-next-line complexity
      const result = associatedParties.map(party => {
        const profileStatus =
          mappedBackendState(party.kyc.status.code ?? 'unchecked').type ?? ''
        const isOrganisation = party.entityType === 'ORGANISATION'

        const data: IAssociateParty = {
          entityType: isOrganisation ? 'ORGANISATION' : 'INDIVIDUAL',
          entityId: party.entityId,
          name: party.name,
          roles: party.roles?.map(role => role.type) || [],
          otherRoleTypeDescription:
            party.roles?.find(role => role.type === OTHER_ROLE_KEY)
              ?.typeDescription || '',
          beneficiallyHeld: party.percentageHolding?.beneficially ?? 0,
          totalHeld: party.percentageHolding?.total ?? 0,
          nonBeneficiallyHeld: party.percentageHolding?.nonBeneficially ?? 0,
          profileStatus: {
            key: profileStatus,
            type: profileStatus,
          },
          recipe: party.profileType ?? '',
          riskLevel: party.kyc.riskLevel.label,
          roleType:
            party.roles?.map(role => role.typeDescription).join(', ') || '',
          countryList: [], // REMARKS: Add country list, needed for individual but no way to know
          issues: party.kyc.issuesList.map(issue => {
            const issueType = typeof issue === 'string' ? issue : issue.term
            return {
              term: issueType,
              type: issueType,
            }
          }),
        }

        return data
      })
      return result
    }

    return []
  }, [data])

  return { associatedEntry, isLoading: isFetching }
}
