import {
  ProcessResultManualStatusEnumAML,
  AmlIssuesTypes,
} from 'entities/entity'

import { I18nKeys } from 'shared/i18n'

import { individualProfileInsightEn } from '../locale/individual-profile-insight.en'

export enum ProfileTabTypes {
  personalInfo = 'personalInfo',
  documents = 'documents',
  aml = 'aml',
  selfies = 'selfies',
}

export const amlIssuesToSupplementaryDataMap: Record<
  AmlIssuesTypes,
  'pepData' | 'sanctionData' | 'watchlistData' | 'mediaData'
> = {
  [AmlIssuesTypes.PEP]: 'pepData',
  [AmlIssuesTypes.SANCTION]: 'sanctionData',
  [AmlIssuesTypes.WATCH_LIST]: 'watchlistData',
  [AmlIssuesTypes.ADVERSE_MEDIA]: 'mediaData',
}

export type ProfileAMLTileTypes = Record<
  AmlIssuesTypes,
  Record<
    | ProcessResultManualStatusEnumAML.TRUE_POSITIVE
    | ProcessResultManualStatusEnumAML.UNKNOWN,
    number
  >
>

export const amlIssuesToI18n: Record<
  AmlIssuesTypes,
  I18nKeys<typeof individualProfileInsightEn>
> = {
  [AmlIssuesTypes.PEP]: 'amlSection.pep',
  [AmlIssuesTypes.SANCTION]: 'amlSection.sanctions',
  [AmlIssuesTypes.WATCH_LIST]: 'amlSection.watchlist',
  [AmlIssuesTypes.ADVERSE_MEDIA]: 'amlSection.adverseMedia',
}

export const amlIssuesToTrackingEvent = {
  [AmlIssuesTypes.PEP]: 'pep',
  [AmlIssuesTypes.SANCTION]: 'sanctions',
  [AmlIssuesTypes.WATCH_LIST]: 'watchlist',
  [AmlIssuesTypes.ADVERSE_MEDIA]: 'media',
}
