import { useMutation } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'

import { bffClient } from 'shared/client'

export type SendTokenSuccessResponse = {
  message: string
  attempts_count: number
  attempts_max: number
}

type MfaGenerateTokenRequestBody = {
  userName: string
  mfaSetup: boolean
}

export const useSendTokenMutation = () =>
  useMutation({
    mutationFn: ({
      email,
      mfaSetup = true,
    }: {
      email: string
      mfaSetup?: boolean
    }): Promise<AxiosResponse<SendTokenSuccessResponse>> =>
      bffClient.post<SendTokenSuccessResponse, MfaGenerateTokenRequestBody>(
        '/mfa/:version/token/send',
        {
          userName: email,
          mfaSetup,
        },
      ),
  })
