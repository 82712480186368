import {
  ArchiveProfile,
  ViewAndEditProfile,
  DeactivateProfile,
} from './action-mapping'

export const ActionMapping = {
  ArchiveProfile,
  ViewAndEditProfile,
  DeactivateProfile,
}

export type ActionMappingType = keyof typeof ActionMapping
