export enum ActionGroupTypes {
  EntityViews = 'entity_views',
  Reporting = 'reporting',
  Entity = 'entity',
  Monitoring = 'monitoring',

  // blocklist is the right word (prod)
  // blacklist is presented on some envs (latest, staging3)
  DuplicateBlocklist = 'duplicate_blocklist',
  // TODO: @ggrigorev remove incorrect word from demo envs
  DuplicateBlacklist = 'duplicate_blacklist',

  ManualIdv = 'manual_idv',
  OcrBiometry = 'ocr_biometry',
  UserManagement = 'user_management',
  RoleManagement = 'role_management',
  Onboarding = 'onboarding',
  Audit = 'audit',
  Risk = 'risk',
  NewProfile = 'new_profile',
  IdvDocument = 'idv_document',
  Display = 'display',
  Organisation = 'admin',
  ParentChild = 'parent-child',
  Sso = 'sso',
}
