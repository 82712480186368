import React from 'react'

import { OrganisationInfoRow } from '../../../kyb-search-v2/ui/organisation-info-row/organisation-info-row'

type Props = {
  rows: { title: string; value: string }[]
}

export function OrganisationInfo({ rows }: Props) {
  if (!rows.length) return null

  return (
    <div className="w-full border-t-[1px] border-solid border-tertiary-grey-200">
      {rows.map(row => (
        <OrganisationInfoRow
          field={row.title}
          value={row.value}
          key={row.title}
        />
      ))}
    </div>
  )
}
