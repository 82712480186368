/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import React, { FormEvent, forwardRef, useState } from 'react'

import { FrankieTextFieldStyle } from './text-field.theme'
import { IFrankieTextFieldProps } from './text-field.types'
import { FrankieButton } from '../button'
import { FrankieIcon } from '../icon'

export const FrankieTextField = forwardRef(
  // eslint-disable-next-line complexity, prefer-arrow-callback
  function FrankieTextField(
    {
      className = '',
      inputClassName = '',
      helperTextClassName = '',
      size = 'md',
      variant = 'primary',
      counter,
      disabled = false,
      error = false,
      errorText = '',
      label,
      name,
      placeholder = '',
      readOnly = false,
      supportingText = '',
      tag,
      badge,
      closeButton,
      isSearchIcon,
      searchIconPlacement = 'start',
      testId = {
        container: '',
        input: '',
        label: '',
        tag: '',
        showPassword: '',
        supportingText: '',
        counter: '',
        closeCta: '',
      },
      type = 'text',
      value,
      onBlur,
      onChange,
      onClick,
      onFocus,
      onKeyDown,
      min,
      max,
      minLength,
      maxLength,
      pattern,
      required,
      autoComplete,
    }: IFrankieTextFieldProps,
    ref: React.ForwardedRef<HTMLInputElement>,
  ): JSX.Element {
    const [inputLength, setInputLength] = useState(0)
    const [passwordVisibility, setPasswordVisibility] = useState(false)

    const handleInput = (e: FormEvent<HTMLInputElement>) => {
      setInputLength(e.currentTarget.value.length)
    }

    return (
      <div className={`w-full ${className}`} data-qa={testId.container}>
        {(label || tag) && (
          <div className="flex flex-initial pb-1.5 w-icon-sm">
            <div className="grow">
              <label
                htmlFor={name}
                className="text-tertiary-grey-800 text-sm font-medium"
                data-qa={testId.label}
              >
                {label}
              </label>
            </div>
            <div
              className="flex flex-initial grow justify-end text-mono-70"
              data-qa={testId.tag}
            >
              {tag}
            </div>
          </div>
        )}
        <div className="relative frankie-text-field-container">
          <input
            id={name}
            name={name}
            aria-label={name}
            ref={ref}
            className={FrankieTextFieldStyle({
              inputError: error,
              startIcon: isSearchIcon && searchIconPlacement === 'start',
              className: inputClassName,
              closeButton:
                !!closeButton ||
                (isSearchIcon && searchIconPlacement === 'end'),
              variant,
              size,
            })}
            placeholder={placeholder}
            disabled={disabled}
            readOnly={readOnly}
            type={passwordVisibility ? 'text' : type}
            onInput={handleInput}
            value={value}
            data-qa={testId.input}
            onBlur={e => {
              if (e.relatedTarget?.role !== 'option') {
                if (onBlur) onBlur(e)
              } else {
                e.target.focus()
              }
            }}
            onChange={onChange}
            onClick={onClick}
            onFocus={onFocus}
            min={min}
            max={max}
            minLength={minLength}
            maxLength={maxLength}
            pattern={pattern}
            required={required}
            autoComplete={autoComplete}
            onKeyDown={onKeyDown}
          />
          {type === 'search' && isSearchIcon && (
            <div
              onClick={onClick}
              className={`absolute top-2.5  ${
                searchIconPlacement === 'end' ? 'right-4' : 'left-4'
              } ${
                searchIconPlacement === 'end' && !!value ? 'hidden' : ''
              } text-tertiary-grey-500 cursor-text`}
            >
              <FrankieIcon size="xs" name="mdiMagnify" />
            </div>
          )}
          {!!badge && badge}
          {type === 'password' && (
            <div className="absolute top-0 right-2">
              <FrankieButton
                intent="subtle"
                size="md"
                testId={{ button: testId.showPassword }}
                className="text-tertiary-grey-500"
                onClick={() => setPasswordVisibility(!passwordVisibility)}
                singleIcon={{
                  size: 'xs',
                  name: passwordVisibility
                    ? 'mdiEyeOffOutline'
                    : 'mdiEyeOutline',
                }}
              />
            </div>
          )}
          {(searchIconPlacement === 'end'
            ? value !== '' && value != null
            : true) &&
            closeButton && (
              <div className="absolute top-2.5 right-4">
                <FrankieButton
                  noStyles
                  testId={{ button: testId.closeCta }}
                  className="text-tertiary-grey-500"
                  onClick={closeButton.onClick}
                  singleIcon={{
                    size: 'xs',
                    name: 'mdiClose',
                  }}
                />
              </div>
            )}
        </div>
        <div className="flex">
          {(supportingText || errorText) && (
            <div className=" text-mono-70 pt-2 text-sm">
              <div
                className={`flex flex-initial items-center ${
                  error ? 'text-tertiary-red-600' : ''
                }`}
              >
                <div className="p-0.5 mr-2">
                  <FrankieIcon
                    name={
                      error ? 'mdiAlertCircleOutline' : 'mdiInformationOutline'
                    }
                    size="sm"
                  />
                </div>
                <p
                  className={helperTextClassName || undefined}
                  data-qa={testId.supportingText}
                >
                  {error ? errorText : supportingText}
                </p>
              </div>
            </div>
          )}
          <div
            className="flex flex-initial grow justify-end text-mono-70"
            data-qa={testId.counter}
          >
            {typeof counter === 'number' && counter > 0 ? (
              <div
                className={inputLength > counter ? 'text-tertiary-red-700' : ''}
              >
                {inputLength}/{counter}
              </div>
            ) : (
              counter
            )}
          </div>
        </div>
      </div>
    )
  },
)
