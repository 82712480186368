import { bffClient, IClient } from 'shared/client'

import {
  ApplicantBiometricResponse,
  ApplicantFacialDuplicatesResponse,
  ApplicantIdvLinkPayload,
  BiometricDetailType,
  IdvStatusChangePayload,
  StatusUpdatePayload,
} from '../model/applicant-biometrics-ocr.model'

export class ApplicantBiometricsAPI {
  client: IClient

  constructor(client: IClient) {
    this.client = client
  }

  async getIdvLink(entityId: string, payload: ApplicantIdvLinkPayload) {
    return this.client.post<{ idvLink: string }, ApplicantIdvLinkPayload>(
      `data/v1/external-idv/applicant/${entityId}/retrigger`,
      payload,
    )
  }

  async getApplicantBiometrics(applicantId: string) {
    return this.client.get<ApplicantBiometricResponse>(
      `data/v1/applicants/${applicantId}/checks/biometrics`,
    )
  }

  async getApplicantFacialDuplicates(applicantId: string) {
    return this.client.get<ApplicantFacialDuplicatesResponse>(
      `data/v1/applicants/${applicantId}/checks/biometrics/facialduplicates`,
    )
  }

  async changeBiometricsStatus(
    applicantId: string,
    { detailType, ...payload }: StatusUpdatePayload,
  ) {
    const urlMapping: Record<BiometricDetailType, string> = {
      document: 'ocr/report',
      ocr: 'ocr/document',
      biometry: 'biometry/report',
      facial: 'biometrics/facialduplicates',
    }

    return this.client.put(
      `data/v1/applicants/${applicantId}/checks/${urlMapping[detailType]}`,
      payload,
    )
  }

  async changeIdvStatus(applicantId: string, payload: IdvStatusChangePayload) {
    return this.client.put(
      `data/v1/applicants/${applicantId}/checks/idv`,
      payload,
    )
  }

  async refreshIdvResult(applicantId: string, payload: { checkId: string }) {
    return this.client.post(`data/v2/idvCheck/${applicantId}/restart`, payload)
  }
}

export const applicantBiometricsApi = new ApplicantBiometricsAPI(bffClient)
