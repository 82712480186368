const idvCheckFullReport = [
  {
    title: 'Data consistency',
    success: true,
    description: 'Image marked as suspicious, please review document image.',

    children: [
      { title: 'First Name', success: true },
      { title: 'Last Name', success: true },
      { title: 'Gender', success: true },
      { title: 'Date of Birth', success: true },
      { title: 'Document Type', success: true },
      { title: 'Date of Expiry', success: true },
      { title: 'Nationality', success: true },
      { title: 'Multiple Data Sources Present', success: true },
      { title: 'Issuing Country', success: true },
      { title: 'Document Numbers', success: true },
    ],
  },
  {
    title: 'Data validation',
    success: true,
    description: 'Image marked as suspicious, please review document image.',
    children: [
      { title: 'Document Numbers', success: true },
      { title: 'Date of Birth', success: true },
      { title: 'Gender', success: true },
      { title: 'MRZ', success: true },
      { title: 'Expiry Date', success: true },
      { title: 'Document Expiration', success: true },
    ],
  },
  {
    title: 'Visual authenticity',
    success: false,
    description: 'Image marked as suspicious, please review document image.',
    children: [
      { title: 'Security Features', success: true },
      { title: 'Template', success: true },
      { title: 'Picture Face Integrity', success: false },
      { title: 'Fonts', success: true },
      { title: 'Face Detection', success: false },
      { title: 'Digital Tampering', success: true },
      { title: 'Original Document Present', success: true },
      { title: 'Other', success: true },
      { title: 'Issuing Country', success: true },
      { title: 'Document Numbers', success: true },
    ],
  },
  {
    title: 'Image integrity',
    success: false,
    description: 'Image marked as suspicious, please review document image.',

    children: [
      { title: 'Supported Document', success: true },
      { title: 'Conclusive Document Quality', success: true },
      { title: 'Image Quality', success: false },
      { title: 'Colour Picture', success: true },
    ],
  },
  {
    title: 'Compromised document',
    success: false,
    description: 'Image marked as suspicious, please review document image.',

    children: [
      { title: 'Police Record', success: true },
      { title: 'Repeat attempts', success: false },
    ],
  },
]

type SrcLabelType = {
  src: string
  label: string
  noMatch?: boolean
}

const ocrData: SrcLabelType[] = [
  { src: 'Given Name', label: 'Jane Louise', noMatch: true },
  { src: 'Middle Name', label: 'Louise' },
  { src: 'Family Name', label: 'Citizen' },
  { src: 'Date of Birth', label: '10 july 1990', noMatch: true },
  { src: 'Country of Issue', label: 'Australia' },
  { src: 'Issuing State', label: 'NSW' },
  { src: 'Licence Number', label: '12983 9149' },
  { src: 'Card Number', label: '167423999' },
  { src: 'Date of Expiry', label: '24 Sep 2025' },
  { src: 'Licence Class', label: 'C' },
  {
    src: 'Residential Address',
    label: '121 King St, Greenway ACT 2900, Australia',
  },
]
const entityData: SrcLabelType[] = [
  { src: 'Given Name', label: 'Jane Louise', noMatch: true },
  { src: 'Middle Name', label: 'Louise' },
  { src: 'Family Name', label: 'Citizen' },
  { src: 'Date of Birth', label: '10 july 1990', noMatch: true },
  { src: 'Country of Issue', label: 'Australia' },
  { src: 'Issuing State', label: 'NSW' },
  { src: 'Licence Number', label: '12983 9149' },
  { src: 'Card Number', label: '167423999' },
  { src: 'Date of Expiry', label: '-' },
  { src: 'Licence Class', label: '-' },
  {
    src: 'Residential Address',
    label: '-',
  },
]
const documents = [
  {
    title: 'Driver’s Licence',
    values: [
      { label: 'Full Name', value: 'Jane Citizen', success: true },
      { label: 'Date of Birth', value: '10 Feb 2021', success: false },
      { label: 'Licence Number', value: '12983 9149', success: true },
      { label: 'Card Number', value: '167423999', success: false },
      { label: 'Issuing State', value: 'NSW', success: true },
    ],
    ocrData,
    entityData,

    report: idvCheckFullReport,
  },

  {
    title: 'Passport',
    values: [
      { label: 'Full Name', value: 'Jane Citizen', success: true },
      { label: 'Date of Birth', value: '10 Feb 2021', success: true },
      { label: 'Passport Number', value: 'P234567893', success: true },
      { label: 'Country of Issue', value: 'Australia', success: true },
      { label: 'Date of Issue', value: '01 Mar 2014', success: false },
      { label: 'Date of Expiry', value: '01 Mar 2024', success: null },
      { label: 'Place of Birth', value: 'Canberra', success: null },
      { label: 'Nationality', value: 'Australian', success: false },
    ],
    ocrData,
    entityData,
    report: idvCheckFullReport.map(i => ({
      ...i,
      success: true,
      children: i.children.map(j => ({ ...j, success: true })),
    })),
  },
  {
    title: 'Medicare Card',
    values: [
      { label: 'Full Name', value: 'Jane L Citizen', success: true },
      { label: 'Position on Card', value: '1234567891', success: null },
      { label: 'Card Number', value: '1234567891', success: true },
      { label: 'Country of Issue', value: 'Australia', success: true },
      { label: 'Date of Expiry', value: 'Aug 2024', success: null },
    ],
    ocrData,
    entityData,
    report: idvCheckFullReport.map(i => ({
      ...i,
      success: false,
      children: i.children.map(j => ({ ...j, success: false })),
    })),
  },
]

export { idvCheckFullReport, documents }
