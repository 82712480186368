import { useCallback } from 'react'

import { useMutation } from '@tanstack/react-query'

import {
  ApplicantBusinessInfoResponse,
  KybReportType,
  applicantApi,
  useApplicantDataQuery,
} from 'entities/applicant'

import { ErrorResponse } from 'shared/http'
import { notification } from 'shared/notification'

type Args = {
  applicantId: string
}

export const useApplicantGenerateBusinessReportMutation = ({
  applicantId,
}: Args) => {
  const { data: applicantData, refetch: refetchApplicantData } =
    useApplicantDataQuery({ applicantId })

  const mutation = useMutation({
    mutationFn: ({ reportType }: { reportType: KybReportType }) =>
      applicantApi.getApplicantBusinessReport(
        {
          business:
            applicantData?.businessInfo ??
            ({} as ApplicantBusinessInfoResponse),
        },
        { level: reportType },
      ),
    onSuccess: () => refetchApplicantData(),
    onError: (err: ErrorResponse) => {
      notification.error(err.response?.data.message ?? err.message)
    },
  })

  const generateBusinessReport = useCallback(
    (reportType: KybReportType) => {
      mutation.mutate({ reportType })
    },
    [mutation],
  )

  return { generateBusinessReport, ...mutation }
}
