/**
 * Returns sorted copy of the array
 * @param arr Array to create a copy of
 * @param direction 'asc' or 'desc'
 * @param getFieldToSort Function to get a field of the object to pass to the comparator
 * @param comparator Function to compare object fields, default is alphanumeric sort
 */
// eslint-disable-next-line max-params
export function sortArrOfObjs<T, E = string | number | object>(
  arr: T[],
  direction: 'asc' | 'desc',
  getFieldToSort: (el: T) => E,
  comparator: (a: E, b: E) => 0 | 1 | -1 = (a, b) =>
    // eslint-disable-next-line no-nested-ternary
    a > b ? 1 : a < b ? -1 : 0,
): T[] {
  return [...arr].sort((a: T, b: T) => {
    const asc = comparator(getFieldToSort(a), getFieldToSort(b))
    return direction === 'asc' ? asc : 0 - asc
  })
}

export function replaceAll<T, E = string | number | object>(
  arr: T[],
  newItems: T[],
  getUniqueId: (el: T) => E,
): T[] {
  return arr.map(item => {
    const itemId = getUniqueId(item)
    const itemToReplace = newItems.find(
      newItem => getUniqueId(newItem) === itemId,
    )
    if (itemToReplace) {
      return itemToReplace
    }
    return item
  })
}

export function hasElement<T, E>(
  arr: T[],
  item: T,
  getUniqueId: (el: T) => E | T = el => el,
): boolean {
  return arr.some(el => getUniqueId(el) === getUniqueId(item))
}

/**
 * Returns filtered copy of the array
 * @param arr Array to create a copy of
 * @param getUniqueId Function to get a unique id of the arr element
 */
export function removeDuplicates<T, E>(
  arr: T[],
  getUniqueId: (el: T) => E | T = el => el,
): T[] {
  return arr.filter(
    (el, idx, self) =>
      self.findIndex(el2 => getUniqueId(el) === getUniqueId(el2)) === idx,
  )
}
