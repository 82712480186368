import { useEffect } from 'react'

import { useLocation } from 'react-router-dom'

export function useAppcuesUrlListener() {
  const location = useLocation()

  useEffect(() => {
    if (window.Appcues) {
      window.Appcues.page()
    }
  }, [location])
}
