import React, { ReactElement, useEffect } from 'react'

import { FrankieBadge, FrankieButton } from 'frankify/src'

import { DisableMfaConfirm, EnableMfaDialog } from 'features/multi-factor-auth'

import { useSessionQuery } from 'entities/session'

import { externalLinks } from 'shared/external-links'
import { useI18n } from 'shared/i18n'
import { useOverlay } from 'shared/overlay'
import { TrackingEventsTypes, trackingManager } from 'shared/tracking'

import { settingsPersonalQa } from '../../qa/settings-personal.qa'
import { SETTINGS_PERSONAL_PAGE_KEY } from '../../settings-personal.key'

export function SettingsPersonalPage(): ReactElement {
  const t = useI18n([SETTINGS_PERSONAL_PAGE_KEY])
  const [createOverlay, closeOverlay] = useOverlay()

  const { data: session } = useSessionQuery()
  const isMfaEnabled = Boolean(session?.user.mfaType)

  const handleEnableMfa = () => {
    createOverlay(<EnableMfaDialog onClose={closeOverlay} />, {
      className: 'p-8 w-[600px]',
    })
  }

  const handleDisableMfa = () => {
    createOverlay(<DisableMfaConfirm onCancel={() => closeOverlay()} />, {
      className: 'p-8 w-[600px]',
    })
  }

  useEffect(() => {
    trackingManager.track(TrackingEventsTypes.PersonalSettings)
  }, [])

  return (
    <div className="p-8">
      <div className="w-[468px]">
        <div className="flex flex-initial flex-row items-center">
          <span
            data-qa={settingsPersonalQa.mfaSettingHeader}
            className="text-md font-semibold mr-4 text-tertiary-grey-800"
          >
            {t('settings-personal:header')}
          </span>
          {isMfaEnabled ? (
            <FrankieBadge
              testId={{ badge: settingsPersonalQa.mfaSettingValue }}
              theme="green"
              text={t('settings-personal:enabled')}
            />
          ) : (
            <FrankieBadge
              testId={{ badge: settingsPersonalQa.mfaSettingValue }}
              theme="red"
              text={t('settings-personal:disabled')}
            />
          )}
        </div>
        <span className="text-tertiary-grey-700 inline-block mt-4 text-sm font-normal">
          {t('settings-personal:description')}{' '}
          <a
            data-qa={settingsPersonalQa.learnMoreCta}
            className="text-primary-800"
            href={externalLinks.mfaDocs}
            rel="noreferrer"
            target="_blank"
          >
            {t('learnMoreLink')}
          </a>
        </span>
        {isMfaEnabled && (
          <FrankieButton
            intent="secondary"
            size="sm"
            className="mt-4"
            onClick={handleDisableMfa}
            testId={{ button: settingsPersonalQa.disableMfaCta }}
          >
            Disable MFA
          </FrankieButton>
        )}
        {!isMfaEnabled && (
          <FrankieButton
            type="button"
            intent="secondary"
            size="sm"
            className="mt-4"
            onClick={handleEnableMfa}
            testId={{ button: settingsPersonalQa.enableMfaCta }}
          >
            Enable MFA
          </FrankieButton>
        )}
      </div>
    </div>
  )
}
