export const commentsEn = {
  viewBatch: 'View batch',
  globalIconTitle: 'Show all comments on page',
  noCommentText: 'No comments on this entity',
  resolvedStatus: {
    TRUE_POSITIVE: 'True positive',
    FALSE_POSITIVE: 'False positive',
    UNKNOWN_ACCEPT: 'Unknown, accepted',
    UNKNOWN_REJECT: 'Unknown, rejected',
    TRUE_POSITIVE_ACCEPT: 'True positive',
    TRUE_POSITIVE_REJECT: 'True positive',
    UNKNOWN: 'unknown',

    MANUALLY_PASSED: 'Manually passed',
    PASSED: 'Passed',
    NEEDS_ATTENTION: 'Needs attention',
    FAILED: 'Failed',
    MANUALLY_FAILED: 'Manually failed',
    UNCHECKED: 'Unchecked',
  },
  seeLess: 'See less',
  seeMore: 'See more',
  actionTakenText: {
    resolved: 'Resolved: ',
    batchResolved: 'Batch resolved: ',
    statusOverride: 'Status override: ',
    entityCreated: 'Entity created',
    entityUpdated: 'Entity updated',
    entityArchived: 'Entity archived',
  },
  title: {
    COMMENT_WORKFLOW: 'Workflow status',
    COMMENT_AML: 'AML screening',
    COMMENT_KYC: 'KYC database check',
    COMMENT_PROFILE: 'Entity profile',
    COMMENT_AMLRESULT: 'AML screening',
    COMMENT_AMLHISTORY: 'AML screening',
    COMMENT_AMLSCREENING: 'AML screening',
    COMMENT_PROFILE_PERSONAL_INFO: 'Entity profile',
    AUDIT: 'Audit report',
  },
}
