import { useEffect } from 'react'

import { useQuery } from '@tanstack/react-query'

import {
  ORGANISATION_KEY,
  organisationEn,
} from 'entities/organisation/locale/organisation.en'

import { useI18n } from 'shared/i18n'
import { notification } from 'shared/notification'

import { organisationApi } from '../../api/organisation.api'

export const AuditBusinessAssociationRolesQueryKey = [
  'applicant-business-association-roles-query',
]

export const useApplicantBusinessAssociationRolesQuery = () => {
  const t = useI18n([ORGANISATION_KEY], { keys: organisationEn })

  const query = useQuery({
    queryKey: AuditBusinessAssociationRolesQueryKey,
    queryFn: async () => {
      const { data } = await organisationApi.getAssociationRoles()
      return data
    },
  })

  useEffect(() => {
    if (query.isError) {
      notification.error(t('error.rolesNotConfigured'))
    }
  }, [query.isError, t])

  return query
}
