import React, { useState } from 'react'

import { FrankieButton, FrankieIcon } from 'frankify/src'

import { RoleName } from 'entities/role'

import { useI18n } from 'shared/i18n'

import { roleManagementQa } from '../../qa/role-management.qa'
import { ROLE_MANAGEMENT_KEY } from '../../role-management.key'

type Props = {
  roleName: RoleName
  onCancel: () => void
  onConfirm: () => Promise<void>
}

export function RoleRemoveConfirm({ roleName, onConfirm, onCancel }: Props) {
  const t = useI18n([ROLE_MANAGEMENT_KEY])
  const [disabled, setDisabled] = useState<boolean>(false)

  const handleConfirm = async () => {
    try {
      setDisabled(true)
      await onConfirm()
      setDisabled(false)
    } catch (error) {
      setDisabled(false)
    }
  }

  return (
    <div className="flex flex-col min-w-0 flex-initial items-center">
      <FrankieIcon
        className="text-tertiary-red-700 mb-4"
        size="44px"
        name="mdiAlertCircleOutline"
      />
      <div
        data-qa={roleManagementQa.roleRemoveConfirm_heading}
        className="mb-2 max-w-full break-words text-tertiary-grey-800 text-xl leading-snug font-bold text-center"
      >
        {t('roleRemoveConfirm.heading', { roleName })}
      </div>
      <div className="mb-6 text-tertiary-grey-700 text-md">
        {t('roleRemoveConfirm.description')}
      </div>
      <div className="flex flex-initial items-center gap-4">
        <FrankieButton
          onClick={onCancel}
          size="sm"
          intent="darkOutline"
          disabled={disabled}
          testId={{ button: roleManagementQa.roleRemoveConfirm_cancelCta }}
        >
          {t('roleRemoveConfirm.cta.cancel')}
        </FrankieButton>
        <FrankieButton
          onClick={handleConfirm}
          size="sm"
          intent="danger"
          disabled={disabled}
          testId={{ button: roleManagementQa.roleRemoveConfirm_confirmCta }}
        >
          {t('roleRemoveConfirm.cta.confirm')}
        </FrankieButton>
      </div>
    </div>
  )
}
