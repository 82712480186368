import React, { useCallback } from 'react'

import { useNavigate, useParams } from 'react-router-dom'

import { FrankieButton, FrankieIcon } from 'frankify/src'

import { IndividualProfileF2 } from 'features/individual-profile-f2'

import { ApplicantId } from 'entities/applicant'
import { useEntityDataQuery } from 'entities/entity'
import {
  TPath,
  TPathParams,
  useGetPathValue,
  useHasFeatureFlag,
} from 'entities/session'

import { useI18n } from 'shared/i18n'

import { EDIT_ENTITY_KEY } from '../edit-entity.key'
import { editEntityEn } from '../locale/edit-entity.en'

type Props = {
  applicantIdParamKey: ApplicantId
  applicantGeneralInfoPath: TPath
  getApplicantPathParams: (applicantId: ApplicantId) => TPathParams
}

export function EditEntityPage({
  applicantIdParamKey,
  getApplicantPathParams,
  applicantGeneralInfoPath,
}: Props) {
  const t = useI18n(EDIT_ENTITY_KEY, { keys: editEntityEn })

  const params = useParams()
  const { generatePathValue } = useGetPathValue()
  const paramsEntityId = params[applicantIdParamKey]
  const { data } = useEntityDataQuery(paramsEntityId)

  const { isFrankie2 } = useHasFeatureFlag({ isFrankie2: 'frankie2customer' })

  const getApplicantGeneralInfoPath = useCallback(
    (applicantId: ApplicantId) =>
      generatePathValue(
        applicantGeneralInfoPath,
        getApplicantPathParams(applicantId),
      ),
    [applicantGeneralInfoPath, generatePathValue, getApplicantPathParams],
  )

  const navigate = useNavigate()

  const handleBack = () => {
    navigate(-1)
  }

  if (!paramsEntityId && !isFrankie2) return null

  return (
    <div className=" z-0 relative max-h-[calc(100vh-70px)] overflow-y-auto ">
      <div className=" text-tertiary-grey-700 max-w-[1040px] px-8  mx-auto h-full">
        <div className="w-full  mx-auto sticky bg-mono-white top-0 flex flex-col justify-items-start z-10 item-center pt-6 pb-8">
          <FrankieButton
            noStyles
            startIcon={{
              name: 'mdiArrowLeft',
              size: 'xs',
              className: 'text-tertiary-grey-500',
            }}
            onClick={handleBack}
            className="inline-flex gap-1"
          >
            <span className="text-xs text-tertiary-grey-500">{t('back')}</span>
          </FrankieButton>
          <div className="mt-4 flex gap-2 items-center">
            <div data-hj-suppress className=" text-xs text-tertiary-grey-600">
              {data?.individual?.name?.displayName}
            </div>
            <FrankieIcon
              className="text-tertiary-grey-400"
              name="mdiChevronRight"
              size="xs"
            />
            <div className="text-xs text-tertiary-grey-700">{t('title')} </div>
          </div>
          <div className="pt-2 text-2xl font-bold text-tertiary-grey-800 leading-tight">
            {t('title')}
          </div>
        </div>

        <IndividualProfileF2
          getApplicantPersonalInfoPath={getApplicantGeneralInfoPath}
          getApplicantGeneralInfoPath={getApplicantGeneralInfoPath}
          entityId={paramsEntityId}
        />
      </div>
    </div>
  )
}
