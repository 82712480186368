import { cva } from 'cva'

export const FrankieTextAreaStyle = cva(
  [
    'w-full',
    'rounded-sm',
    'border',
    'border-solid',
    'h-full',
    'box-border',
    'px-4',
    'bg-mono-white',
    'text-mono-90',
    'text-sm',
    'cursor-text',
    'placeholder:text-mono-60',
    'focus-visible:border-primary-800',
    'focus-visible:outline-0',
    'disabled:border-tertiary-grey-300',
    'disabled:bg-tertiary-grey-50',
    'disabled:text-tertiary-grey-400',
  ],
  {
    variants: {
      inputError: {
        true: [
          '!border-tertiary-red-500',
          '!bg-tertiary-red-50',
          '!text-tertiary-red-600',
          'placeholder:!text-tertiary-red-50',
        ],
      },
    },
  },
)
