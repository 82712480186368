import React, { useMemo } from 'react'

import { useForm } from 'react-hook-form'

import { FrankieButton, FrankieImage, FrankieLoader } from 'frankify/src'

import { ApplicantId } from 'entities/applicant'

import { SelectFormField } from 'shared/form'
import { useI18n } from 'shared/i18n'
import { useOverlay } from 'shared/overlay'
import { objectAsValueOptions } from 'shared/typescript'

import { APPLICANT_GENERAL_INFORMATION_KEY } from '../../applicant-general-information.key'
import { businessReportIcon } from '../../assets'
import { applicantGeneralInformationEn } from '../../locale/applicant-general-information.en'
import {
  BusinessReportTypes,
  businessReportData,
} from '../../model/applicant-business.model'
import { applicantBusinessReportRunQa } from '../../qa/applicant-general-information.qa'
import { useApplicantBusinessReport } from '../../state/applicant-business-state/applicant-business-state'
import { ApplicantBusinessReportModal } from '../applicant-business-report-modal/applicant-business-report-modal'

type Props = {
  applicantId: ApplicantId
}

type FormData = {
  businessReportType: BusinessReportTypes
}

export function ApplicantBusinessReportRun({ applicantId }: Props) {
  const t = useI18n([APPLICANT_GENERAL_INFORMATION_KEY], {
    keys: applicantGeneralInformationEn,
  })

  const { control, handleSubmit } = useForm<FormData>()
  const [createOverlay, closeOverlay] = useOverlay()

  const { pendingReport } = useApplicantBusinessReport({ applicantId })
  const reportGenerating = useMemo(
    () =>
      Object.values(pendingReport).reduce((val, curr) => val || curr, false),
    [pendingReport],
  )

  const onSubmit = (data: FormData) => {
    createOverlay(
      <ApplicantBusinessReportModal
        handleClose={closeOverlay}
        applicantId={applicantId}
        {...data}
      />,
      { className: 'p-0' },
    )
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="bg-neutral-20 p-6 rounded-md"
    >
      <div className="flex items-center gap-3">
        <div className="text-lg self-start flex-grow">
          {t('selectReportForBusiness')}
        </div>
        <SelectFormField
          className="relative max-w-[250px]"
          control={control}
          name="businessReportType"
          placeholder={t('action.fields.selectReport')}
          options={objectAsValueOptions(businessReportData).map(
            ({ labelTKey, value }) => ({ label: t(labelTKey), value }),
          )}
          disabled={reportGenerating}
          testId={{ input: applicantBusinessReportRunQa.selectInput }}
          rules={{ required: t('error.selectReport') }}
          showErrorText
          helperTextClassName="absolute bottom-[-25px]"
        />
        <FrankieButton
          disabled={reportGenerating}
          className="whitespace-nowrap"
          type="submit"
          testId={{ button: applicantBusinessReportRunQa.generateReportBtn }}
        >
          {t('action.generateReport')}
        </FrankieButton>

        {reportGenerating && (
          <FrankieLoader className="!h-0 !w-6" loading size="xs" />
        )}
      </div>

      <div className="">
        {objectAsValueOptions(businessReportData).map(
          ({ labelTKey, descriptionTKey, value, icon }) => (
            <div
              className="flex gap-4 items-start p-4 mt-6 min-h-[95px] border border-tertiary-grey-200 rounded-sm bg-mono-white "
              key={value}
            >
              <FrankieImage
                className="max-w-[34px] py-2"
                src={businessReportIcon[icon]}
              />

              <div className="font-semibold leading-tight">
                <div className="text-md">{t(labelTKey)}</div>
                <div className="text-tertiary-grey-400 ">
                  {t(descriptionTKey)}
                </div>
              </div>
            </div>
          ),
        )}
      </div>
    </form>
  )
}
