import React, { useEffect, useMemo } from 'react'

import { useForm } from 'react-hook-form'

import { FrankieButton, FrankieIcon, FrankieTextField } from 'frankify/src'

import { useI18n } from 'shared/i18n'
import { Noop } from 'shared/typescript'

import { DASHBOARD_KEY } from '../../dashboard.key'
import { dashboardEn } from '../../locale/dashboard.en'
import { GenerateReportForm } from '../../model/dashboard-form.model'
import { useGenerateReportMutation } from '../../mutation/dashboard.mutation'
import { requestQa } from '../../qa/dashboard.qa'

export type ModalProps = {
  closeModal: Noop
  onReportGeneration: Noop
}

export function RequestReportModal({
  closeModal,
  onReportGeneration,
}: ModalProps) {
  const t = useI18n([DASHBOARD_KEY], { keys: dashboardEn })

  const { register, formState, getValues } = useForm<GenerateReportForm>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
  })

  const { isSuccess: allReportGenerationSuccess, mutate } =
    useGenerateReportMutation()

  const registerPassword = useMemo(
    () => register('password', { required: true }),
    [register],
  )

  const generateReport = () => {
    mutate({ password: getValues('password') })
  }

  useEffect(() => {
    if (allReportGenerationSuccess) {
      onReportGeneration()
      closeModal()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allReportGenerationSuccess, onReportGeneration, closeModal])

  return (
    <div
      className="relative max-h-full bg-mono-white rounded-2 max-w-[500px]"
      data-qa={requestQa.mainWrapper}
    >
      <div className="flex justify-between py-4 px-8 border-b border-neutral-50">
        <div className="flex gap-2">
          <FrankieIcon
            name="mdiAccountDetailsOutline"
            className="text-tertiary-grey-400"
            size="md"
          />
          <div className="text-md font-bold ">
            {t('generateReport.heading')}
          </div>
        </div>
        <FrankieButton noStyles onClick={closeModal} className="flex-grow-0">
          <FrankieIcon name="mdiClose" />
        </FrankieButton>
      </div>
      <div className="flex flex-col gap-4 overflow-y-auto p-8">
        <div className="font-bold">{t('generateReport.note1')}</div>
        <div>{t('generateReport.note2')}</div>
        <div>{t('generateReport.note3')}</div>
        <FrankieTextField
          label={t('generateReport.fields.password')}
          className="max-w-[260px]"
          type="password"
          {...registerPassword}
          testId={{ input: requestQa.regPassword }}
        />

        <div className="flex justify-between pt-2">
          <FrankieButton
            disabled={!formState.isValid}
            onClick={generateReport}
            testId={{ button: requestQa.submitBtn }}
          >
            {t('generateReport.cta.submit')}
          </FrankieButton>

          <FrankieButton
            intent="secondary"
            onClick={closeModal}
            testId={{ button: requestQa.closeBtn }}
          >
            {t('generateReport.cta.cancel')}
          </FrankieButton>
        </div>
      </div>
    </div>
  )
}
