import {
  AddressCategoryTypes,
  ApplicantResponse,
  Document,
} from 'entities/applicant'

import { I18nFunction, I18nKeys } from 'shared/i18n'

import { applicantPotentialMatchesEn } from '../locale/applicant-potential-matches.en'

export enum ApplicantMatchesTypes {
  POTENTIAL = 'watchlist',
  DUPLICATES = 'duplicates',
  BLOCKLIST = 'blocklist',
}

export enum MatchesValueTypes {
  FalsePositive = 'false_positive',
  TruePositive = 'true_positive',
  Skip = 'skip',
}

export const matchOptions: {
  tKey: I18nKeys<typeof applicantPotentialMatchesEn>
  value: MatchesValueTypes
  matchType: ApplicantMatchesTypes[]
}[] = [
  {
    tKey: 'match.true',
    value: MatchesValueTypes.TruePositive,
    matchType: [
      ApplicantMatchesTypes.POTENTIAL,
      ApplicantMatchesTypes.DUPLICATES,
      ApplicantMatchesTypes.BLOCKLIST,
    ],
  },
  {
    tKey: 'match.false',
    value: MatchesValueTypes.FalsePositive,
    matchType: [
      ApplicantMatchesTypes.DUPLICATES,
      ApplicantMatchesTypes.BLOCKLIST,
    ],
  },
  {
    tKey: 'match.skip',
    value: MatchesValueTypes.Skip,
    matchType: [ApplicantMatchesTypes.POTENTIAL],
  },
]

export type MatchesTypes =
  | ApplicantMatchesTypes.POTENTIAL
  | ApplicantMatchesTypes.DUPLICATES
  | ApplicantMatchesTypes.BLOCKLIST

export enum PossibleMatchTypes {
  Comment = 'comment',
  flag = 'flag',
}

export interface IPossibleMatchInputs {
  [PossibleMatchTypes.flag]: string
  [PossibleMatchTypes.Comment]: string
}

export const possibleMatchDefaultValues = {
  [PossibleMatchTypes.flag]: '',
  [PossibleMatchTypes.Comment]: '',
}

const capitalizeFirstChar = (str: string) =>
  str ? str.charAt(0).toUpperCase() + str.slice(1) : ''

export enum BlocklistAttributeTypes {
  ENTIRE_ENTITY = 'ENTIRE_ENTITY',
  MAILING_ADDRESS = 'MAILING_ADDRESS',
  RESIDENTIAL_ADDRESS = 'RESIDENTIAL_ADDRESS',
  EMAIL_ADDRESS = 'EMAIL_ADDRESS',
  PHONE_NUMBER = 'PHONE_NUMBER',
  ID_DOCUMENT = 'ID_DOCUMENT',
  NAME_AND_DOB = 'NAME_AND_DOB',
}

export const potentialMatchFieldLabel = (
  blocklistedAttribute: string,
  t: I18nFunction<typeof applicantPotentialMatchesEn>,
) => {
  if (!blocklistedAttribute) return ''
  switch (blocklistedAttribute) {
    case BlocklistAttributeTypes.ENTIRE_ENTITY:
      return t('entireProfile')
    case BlocklistAttributeTypes.ID_DOCUMENT:
      return t('idDocument')
    case BlocklistAttributeTypes.NAME_AND_DOB:
      return t('nameDob')
    default: {
      const splitStr = blocklistedAttribute.split('_')
      return splitStr.map(str => capitalizeFirstChar(str)).join(' ')
    }
  }
}

const getAddress = (
  addressType: AddressCategoryTypes,
  applicantData: ApplicantResponse,
) =>
  applicantData.applicantDetails.addresses?.filter(
    address => address.addressType === addressType,
  )[0]?.longForm ?? ''

// eslint-disable-next-line complexity
export const getPotentialFieldValue = (
  blocklistedAttribute: string,
  applicantData: ApplicantResponse,
) => {
  if (!blocklistedAttribute) return ''
  switch (blocklistedAttribute) {
    case BlocklistAttributeTypes.ENTIRE_ENTITY:
      return applicantData.applicantDetails.dateOfBirth

    case BlocklistAttributeTypes.MAILING_ADDRESS:
      return getAddress(AddressCategoryTypes.mailing, applicantData)

    case BlocklistAttributeTypes.RESIDENTIAL_ADDRESS:
      return getAddress(AddressCategoryTypes.previous, applicantData)

    case BlocklistAttributeTypes.PHONE_NUMBER:
      return applicantData.applicantDetails.phoneNumber.idNumber

    case BlocklistAttributeTypes.EMAIL_ADDRESS:
      return applicantData.applicantDetails.email.idNumber

    case BlocklistAttributeTypes.ID_DOCUMENT:
      return `${applicantData.documents[0]?.idType} ${
        applicantData.documents[0]?.idNumber ?? ''
      }`

    case BlocklistAttributeTypes.NAME_AND_DOB:
      return [
        applicantData.applicantDetails.name.givenName,
        applicantData.applicantDetails.name.middleName,
        applicantData.applicantDetails.name.familyName,
      ]
        .filter(Boolean)
        .join(' ')

    default:
      return ''
  }
}

// -- Snippet from vue codebase
const indexPoints = (index: number) => (index === -1 ? Infinity : -1)
export const makeSorterByExistence =
  (otherDocuments: Document[]) => (a: Document, b: Document) => {
    // push up if document exists in both entities AND alphabetically
    const aIndex = otherDocuments.findIndex(doc => doc.idNumber === a.idNumber)
    const bIndex = otherDocuments.findIndex(doc => doc.idNumber === b.idNumber)
    return indexPoints(aIndex) - indexPoints(bIndex)
  }
export const makeSorterAlphabetically = () => (a: Document, b: Document) =>
  // eslint-disable-next-line @typescript-eslint/restrict-plus-operands, prefer-template
  ('' + a.idNumber).localeCompare('' + b.idNumber)
