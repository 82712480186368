import { FrankieIconName } from 'frankify/src'

import {
  DocumentContextTypes,
  IApplicantSupportingDocuments,
  IChangeStamp,
  RegistryDocumentStatusTypes,
} from 'entities/applicant'
import { TrustDeedTypes } from 'entities/applicant/model/applicant-trust-analyser.model'
import { DocumentStatusTypes, DocumentTypes } from 'entities/document'

import { I18nKeys } from 'shared/i18n'
import { Nullable, aliasKeys } from 'shared/typescript'

import { applicantSupportingDocumentsEn } from '../locale/applicant-supporting-documents.en'

export class ApplicantUploadedDocumentsRecord {
  data: IApplicantSupportingDocuments

  id: string

  documentType: DocumentTypes

  documentName: string

  status?: Nullable<DocumentStatusTypes>

  uploadedDate?: string

  uploadedBy?: string

  reviewBy?: string

  lastUpdatedDate?: string

  constructor(input: IApplicantSupportingDocuments) {
    this.data = input
    this.id = input.id ?? ''

    this.documentType = input.type
    this.documentName = input.file?.scanName ?? ''

    this.status = input.status

    if (input.modified) {
      this.uploadedDate = input.modified.timestamp
      this.uploadedBy = input.modified.by
    }
    if (input.reviewed) {
      this.reviewBy = input.reviewed.by
      this.lastUpdatedDate = input.reviewed.timestamp
    }
  }
}

export type ApplicantUploadedDocumentsRecordProperties =
  keyof ApplicantUploadedDocumentsRecord

export interface IApplicantSupportingDocumentsComments {
  comment: string
  associatedResourceId: string // uuid
  created: IChangeStamp
  context: DocumentContextTypes
}

export type SupportingDocumentPayload = {
  supportingDocument: IApplicantSupportingDocuments
  comment: IApplicantSupportingDocumentsComments
}

export interface IComment {
  comment: string
  associatedResourceId: string
  created: IChangeStamp
  context: DocumentContextTypes
}

export interface IBusinessDocumentsCatalogRes {
  reports: DocumentCatalogItem[]
  entityId: string
  requestId: string
}

type DocumentCatalogItem = {
  availableFormats: string
  provider: string
  reportCode: string
  reportTitle: string
  recordStatus?: DocumentStatusType
  priceTierCode: string
}

type DocumentStatusType = {
  requestId: string
  requestedDate: string
  reportCode: string
  reportStatus: string
}

export type BusinessDocumentCatalogRowType = {
  title: string
  id: string
  reportCode: string
  availableFormats: string
  provider: string
  status?: RegistryDocumentStatusTypes
  isSelectable: boolean
}

type StatusPropsValueType = {
  className: string
  translation: I18nKeys<typeof applicantSupportingDocumentsEn>
  iconName?: FrankieIconName
}

export const statusProps: Record<
  RegistryDocumentStatusTypes,
  StatusPropsValueType
> = {
  'Waiting on Provider': {
    className: 'text-tertiary-yellow-700 bg-tertiary-yellow-100',
    translation: 'statuses.processing',
  },
  Completed: {
    iconName: 'mdiCheckCircle',
    className: 'text-tertiary-green-600 bg-tertiary-green-100',
    translation: 'statuses.purchased',
  },
  ...aliasKeys(
    [
      'Failed to Retrieve Report',
      'Unknown Error',
      'Request has timed out',
      'Cancelled Order',
      'Failed',
    ],
    {
      className: 'text-tertiary-red-800 bg-tertiary-red-100',
      translation: 'statuses.failed',
    },
  ),
}
export const registryDocumentErrorStatuses: RegistryDocumentStatusTypes[] = [
  'Failed to Retrieve Report',
  'Request has timed out',
  'Unknown Error',
  'Cancelled Order',
  'Failed',
]

export type PurchaseDocumentPayload = {
  applicantId: string
  selectedReports: {
    availableFormats: string
    provider: string
    reportCode: string
  }[]
}

export type GetTrustAnalysisAssociatedPartiesPayload = {
  applicantId: string
}

export type IPurchaseDocumentResponse =
  | {
      status: 'fulfilled'
      value: IPurchaseResponseValue
    }
  | {
      status: 'rejected'
      value: undefined
    }

interface IPurchaseResponseValue {
  requestId: string
  reportStatus: IPurchaseResponseDocumentStatus
}

interface IPurchaseResponseDocumentStatus {
  requestId: string
  requestedDate: string
  reportCode: string
  reportStatus: string
  documentOrders: IDocumentOrdersStatus[]
}

interface IDocumentOrdersStatus {
  requestId: string
  requestedDate: string
  reportCode: string
  reportStatus: string
}

export enum GenderTypes {
  U = 'U',
  F = 'F',
  M = 'M',
  O = 'O',
}

type TDocumentVerification = {
  electronic?: boolean
  manual?: boolean
}

interface IScan {
  scanId?: string
  scanDocId?: string
  fileUploadUuid?: string
  file?: string
  mimeType?: string
  side?: string
  scanCreated?: string
  scanName?: string
}

type TExtraDataField = {
  'trust.document.raw_pdf.scan_id'?: string
  'trust.document.scan_status'?: TrustDeedTypes
  'trust.document.analysis_id'?: string
  'trust.document.results.scan_id'?: string
}

type TDocumentValidation = {
  manual: {
    isValid?: boolean
  }
  electronic: {
    validationReport?: IScan
    isValid?: boolean
  }
}

export interface ITrustDeedsResponse {
  documentId: string
  idType: DocumentTypes.TRUST_DEED
  docScan: IScan[]
  country: string
  dateOfBirth?: string
  idSubType?: string
  scans?: IScan[]
  verified?: TDocumentVerification
  region?: string
  idNumber?: string
  gender?: GenderTypes
  extraData?: TExtraDataField
  validation?: TDocumentValidation
  idExpiry?: string
  ocrResult?: TExtraDataField
}

export type ConfirmAnalysisResultResponse = {
  requestId: string
}

export type ConfirmAnalysisResultPayload = {
  references?: IReferences
  documentInformation?: IDocumentInformation
}

export type ConfirmAnalysisError = {
  response: {
    data: {
      responseData: {
        details: {
          issue: string
        }[]
      }
    }
  }
}

export type ConfirmAnalysisResultIdPayload = ConfirmAnalysisResultPayload & {
  analysisId: string
}

export interface ITrustDeedAnalysisResult {
  analyses: ITrustAnalyse[]
  entityId: string
  requestId: string
}

export interface IReferences {
  [key: string]: {
    location: {
      page: number
    }
    referenceId: string
  }
}

interface IDocumentInformationDate {
  day: string
  month: string
  normalized: string
  referenceIds?: string[]
  unstructured: string
  year: string
  type?: string
}

export interface INameDetail {
  givenName?: string
  middleName?: string
  familyName?: string
  displayName?: string
  referenceIds?: string[]
}

export interface IDocumentInformationAddresses {
  type?: string
  referenceIds?: string[]
  unstructuredLongForm?: string
  country?: string
  buildingName?: string
  unitNumber?: string
  streetNumber?: string
  streetName?: string
  streetType?: string // Road, Street, etc (Last word in the street name)
  locality?: string // Suburb
  neighborhood?: string // Suburb
  subdivision?: string // State
  postalCode?: string
}

export interface IDocumentInformationEntity {
  entityId: string
  entityType: ApplicantTrustTypes
  referenceIds?: string[]
  unstructuredRole?: string
  holding?: {
    count: number
  }
}

export interface ILinkedIndividuals {
  [entityId: string]: ILinkedIndividual
}

export interface ILinkedIndividual {
  entityId: string
  entityType: ApplicantTrustTypes
  addresses?: IDocumentInformationAddresses[]
  dateOfBirth?: IDocumentInformationDate
  name?: INameDetail
}

export interface ILinkedOrganizations {
  [entityId: string]: ILinkedOrganization
}

export interface ILinkedOrganization {
  entityId: string
  entityType: ApplicantTrustTypes
  addresses?: IDocumentInformationAddresses[]
  details?: {
    name?: IValueReferenceIds
    registrationDetails?: {
      referenceIds?: string[]
      registrationNumber: string
      registrationNumberType?: string
    }[]
  }
}

export enum DocumentAnalysisTypes {
  TRUST_DOCUMENT = 'TRUST_DOCUMENT',
}

export interface IDocumentInformation {
  trust: ITrustDocumentInformation
  type: DocumentAnalysisTypes
}

export interface IDocumentTrustTypes {
  detected: TrustTypes
  provided?: TrustTypes
}

export enum TrustInformationTypes {
  discretionary = 'discretionary',
  selfManagedSuperFund = 'selfManagedSuperFund',
  unit = 'unit',
  unknown = 'unknown',
}

export interface ITrustDocumentInformation {
  certification?: {
    date?: IDocumentInformationDate
  }
  establishment?: {
    date?: IDocumentInformationDate
    country?: IValueReferenceIds
    subdivision?: IValueReferenceIds
  }
  execution?: {
    date?: IDocumentInformationDate
  }
  linkedIndividuals?: ILinkedIndividuals
  linkedOrganizations?: ILinkedOrganizations
  name?: IValueReferenceIds
  settlors?: IDocumentInformationEntity[]
  trustees?: IDocumentInformationEntity[]
  type: IDocumentTrustTypes
  typeInformation: {
    type: TrustTypes
    [TrustInformationTypes.discretionary]?: {
      protectors?: IDocumentInformationEntity[]
      appointors?: IDocumentInformationEntity[]
      generalBeneficiaries?: IValueReferenceIds[]
      specifiedBeneficiaries?: IDocumentInformationEntity[]
    }
    [TrustInformationTypes.selfManagedSuperFund]?: {
      members?: IDocumentInformationEntity[]
    }
    [TrustInformationTypes.unit]?: {
      totalUnits?: IValueNumberReferenceIds
      unitHolders?: IDocumentInformationEntity[]
    }
    [TrustInformationTypes.unknown]?: {
      reasons: {
        issue: string
      }[]
    }
  }
}

export interface IValueReferenceIds {
  referenceIds?: string[]
  value: string
}

export interface IValueNumberReferenceIds {
  referenceIds?: string[]
  value: number
}

interface ITrustError {
  details: {
    issue: string
  }[]
  errorMsg: string
}

export interface ITrustAnalyse {
  analysisId: string
  documentId: string
  documentInformation?: IDocumentInformation
  errors?: ITrustError
  references?: IReferences
  status: TrustDeedTypes
  type: string
}

export type UploadTrustDeedFilePayload = {
  country: string
  docUUID: string
}

export type AnalyseTrustDeedPayload = {
  documentId: string
  type: TrustTypes
}

export interface IUploadTrustDeedFileResponse {
  documentId: string
}

export type AnalysisTrustDocumentResponse = {
  analysis: {
    analysisId: string
    documentId: string
    status: string
    type: string
  }
  entityId: string
  requestId: string
}

export enum ApplicantTrustTypes {
  Organization = 'ORGANIZATION',
  Individual = 'INDIVIDUAL',
}

export enum TrustTypes {
  AUTO_DETECT = '',
  DISCRETIONARY = 'DISCRETIONARY',
  UNIT = 'UNIT',
  SMSF = 'SELF_MANAGED_SUPER_FUND',
  UNKNOWN = 'UNKNOWN',
}

export const trustTypesOption: Array<{
  tKey: I18nKeys<typeof applicantSupportingDocumentsEn>
  value: TrustTypes
}> = [
  {
    tKey: 'trustTypeName.autoDetect',
    value: TrustTypes.AUTO_DETECT,
  },
  {
    tKey: 'trustTypeName.discretionary',
    value: TrustTypes.DISCRETIONARY,
  },
  {
    tKey: 'trustTypeName.unit',
    value: TrustTypes.UNIT,
  },
  {
    tKey: 'trustTypeName.selfManagedSuperFund',
    value: TrustTypes.SMSF,
  },
]

export const applicantEntityOption: Array<{
  tKey: I18nKeys<typeof applicantSupportingDocumentsEn>
  value: ApplicantTrustTypes
}> = [
  {
    tKey: 'applicantEntityName.individual',
    value: ApplicantTrustTypes.Individual,
  },
  {
    tKey: 'applicantEntityName.organisation',
    value: ApplicantTrustTypes.Organization,
  },
]
