export const applicantsFilterQa = {
  inputs: {
    individualType: 'applicants-filter_individual-type-input',
    organisationType: 'applicants-filter_organisation-type-input',
    statusOption: 'applicants-filter_status-option-input',
    issueOption: 'applicants-filter_issue-option-input',
    riskLevelOption: 'applicants-filter_risk-level-option-input',
    createdDate: {
      from: 'applicants-filter_created-date-from-input',
      to: 'applicants-filter_created-date-to-input',
      custom: 'applicants-filter_created-date-custom-input',
      all: 'applicants-filter_created-date-all-input',
      today: 'applicants-filter_created-date-today-input',
      yesterday: 'applicants-filter_created-date-yesterday-input',
      past7Days: 'applicants-filter_created-date-past-7-days-input',
      past30Days: 'applicants-filter_created-date-past-30-days-input',
      pastYear: 'applicants-filter_created-date-past-year-input',
    },
    recipeOption: 'applicants-filter_recipe-option-input',
    assignee: {
      search: 'applicants-filter_assignee-search-input',
      option: 'applicants-filter_assignee-option-input',
    },
  },
  cta: {
    assignee: {
      clearSearch: 'applicants-filter_assignee-clear-search-cta',
      closeBadge: 'applicants-filter_assignee-close-badge-cta',
      badge: 'applicants-filter_assignee-badge',
    },
    viewAllOptions: 'applicants-filter_view-all-options-cta',
    applyFilters: 'applicants-filter_apply-filters-cta',
    clearFilters: 'applicants-filter_clear-filters-cta',
    applySingleFilter: 'applicants-filter_apply-single-filter-cta',
    clearSingleFilter: 'applicants-filter_clear-single-filter-cta',
  },
}
