import React, { useMemo } from 'react'

import { useHasFeatureFlag } from 'entities/session'

import { useI18n } from 'shared/i18n'

import {
  NOTIFICATIONS_MANAGEMENT_KEY,
  notificationsManagementEn,
} from '../../locale/notifications-management.en'
import {
  INotificationChannel,
  INotificationSetting,
  INotificationSettingsDictionary,
  NotificationFrequenciesTypes,
  NotificationTypesType,
  NotificationVolumeTypes,
  personalNotificationMapper,
} from '../../model/notifications-management.model'
import { notificationsConfigTableQa } from '../../qa/notifications-management.qa'
import { GeneralNotificationsConfigTableRow } from '../general-notification-config-table-row/general-notification-config-table-row'
import { GeneralNotificationsConfigTableRowHeader } from '../general-notification-config-table-row-header/general-notification-config-table-row-header'
import {
  NotificationsConfigTableRow,
  NotificationsConfigTableRowHeader,
} from '../notifications-config-table-row'

type DictionaryWithSettings = INotificationSettingsDictionary & {
  settings: INotificationSetting[]
}

type Props = {
  notificationChannels: INotificationChannel[]
  allNotificationChannels: INotificationChannel[]
  notificationSettingsDictionary: INotificationSettingsDictionary[]
  notificationSettings: INotificationSetting[]
  canSeeNonPersonalNotifications: boolean
  onChange: (setting: INotificationSetting, newChecked: boolean) => void
  onMultipleChange?: (settings: INotificationSetting[]) => void
}

export function NotificationsConfigTable({
  notificationChannels,
  allNotificationChannels,
  notificationSettings,
  notificationSettingsDictionary,
  canSeeNonPersonalNotifications,
  onChange,
  onMultipleChange,
}: Props) {
  const t = useI18n([NOTIFICATIONS_MANAGEMENT_KEY], {
    keys: notificationsManagementEn,
  })

  const { trustEnabled } = useHasFeatureFlag({ trustEnabled: 'trustAnalyser' })

  const orderedSettings = useMemo<INotificationSetting[]>(
    () =>
      notificationChannels
        .map<INotificationSetting[]>(chan =>
          notificationSettings.filter(
            setting => setting.channel === chan.value,
          ),
        )
        .flat(),
    [notificationSettings, notificationChannels],
  )

  const dictionaryWithSettingsAndI18n = useMemo<DictionaryWithSettings[]>(
    () =>
      notificationSettingsDictionary.map<DictionaryWithSettings>(dict => {
        let { name, description } = dict
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (notificationsManagementEn.type.types[dict.value]) {
          name = t(`type.types.${dict.value}.name`)
          description = t(`type.types.${dict.value}.description`)
        }

        return {
          ...dict,
          type: dict.value,
          name,
          description,
          settings: orderedSettings.filter(
            setting => setting.type === dict.value,
          ),
        }
      }),
    [notificationSettingsDictionary, orderedSettings, t],
  )

  const filteredDictionaryWithSettingsAndI18n = useMemo<
    DictionaryWithSettings[]
  >(
    () =>
      dictionaryWithSettingsAndI18n.filter(
        dict =>
          dict.isPersonalNotification &&
          !(dict.value === 'assignedEntityRiskUpdatedToHighUnacceptable') &&
          (trustEnabled || dict.value !== 'trustAnalysisComplete'),
      ),
    [dictionaryWithSettingsAndI18n],
  )

  const channelsWithI18n = useMemo<INotificationChannel[]>(
    () =>
      notificationChannels.map<INotificationChannel>(channel => ({
        ...channel,
        name: notificationsManagementEn.channel[channel.value]
          ? t(`channel.${channel.value}`)
          : channel.name,
      })),
    [notificationChannels, t],
  )

  const onFrequencyChange = (
    notification: NotificationTypesType,
    frequency: NotificationFrequenciesTypes,
  ) => {
    const newNotificationSettings: INotificationSetting[] = []
    const changedNotifications = [
      ...(personalNotificationMapper[notification] || []),
      notification,
    ]

    dictionaryWithSettingsAndI18n.forEach(dict => {
      if (changedNotifications.includes(dict.value)) {
        dict.settings.forEach(setting => {
          newNotificationSettings.push({
            ...setting,
            value:
              setting.channel === 'EMAIL' &&
              frequency === NotificationFrequenciesTypes.REALTIME
                ? false
                : setting.value,
            canDisable:
              setting.channel === 'EMAIL'
                ? frequency !== NotificationFrequenciesTypes.REALTIME
                : setting.canDisable,
            frequency,
          })
        })
        allNotificationChannels.forEach(channel => {
          if (
            dict.settings.every(setting => setting.channel !== channel.value)
          ) {
            newNotificationSettings.push({
              channel: channel.value,
              type: dict.value,
              value: true,
              canDisable: true,
              frequency,
            })
          }
        })
      }
    })

    onMultipleChange?.(newNotificationSettings)
  }

  const onVolumeChange = (volume: NotificationVolumeTypes) => {
    const newNotificationSettings: INotificationSetting[] = []
    filteredDictionaryWithSettingsAndI18n.forEach(dict => {
      personalNotificationMapper[dict.value]?.forEach(notification => {
        dictionaryWithSettingsAndI18n
          .find(d => d.value === notification)
          ?.settings.forEach(setting => {
            const newValue = dict.settings.find(
              i => i.channel === setting.channel,
            )?.value
            if (newValue === undefined) return
            if (!setting.canDisable) return

            newNotificationSettings.push({
              ...setting,
              value:
                volume === NotificationVolumeTypes.ALL_ENTITIES
                  ? !!newValue
                  : false,
            })
          })
      })
    })
    onMultipleChange?.(newNotificationSettings)
  }

  const notificationVolumeValue = () =>
    Object.values(personalNotificationMapper).some(value =>
      dictionaryWithSettingsAndI18n
        .find(d => value.includes(d.value))
        ?.settings.some(setting => setting.value),
    )

  return (
    <div data-qa={notificationsConfigTableQa.container}>
      {canSeeNonPersonalNotifications && (
        <div
          data-qa={notificationsConfigTableQa.nonPersonalNotificationContainer}
          className="py-5 px-6 mb-3 rounded-md border border-solid border-tertiary-grey-200"
        >
          <div className="">
            <GeneralNotificationsConfigTableRowHeader
              channels={channelsWithI18n}
            />
          </div>
          <div className="">
            <GeneralNotificationsConfigTableRow
              notificationTypeInfo={{
                name: t('general.types.entityAssignment.name'),
                description: t('general.types.entityAssignment.description'),
                isPersonalNotification: !notificationVolumeValue(),
                value: 'entityAssignment',
              }}
              onMultipleChange={onVolumeChange}
              settings={filteredDictionaryWithSettingsAndI18n.at(0)?.settings}
            />
          </div>
        </div>
      )}
      <div className=" py-5 px-6 rounded-md border border-solid border-tertiary-grey-200">
        <div
          data-qa={notificationsConfigTableQa.notificationRowHeaderContainer}
          className=""
        >
          <NotificationsConfigTableRowHeader channels={channelsWithI18n} />
        </div>
        <div className="">
          {filteredDictionaryWithSettingsAndI18n.map(dict => (
            <NotificationsConfigTableRow
              onChange={onChange}
              notificationTypeInfo={dict}
              settings={dict.settings}
              onMultipleChange={onFrequencyChange}
            />
          ))}
        </div>
      </div>
    </div>
  )
}
