import { useCallback, useEffect } from 'react'

import { useMutation } from '@tanstack/react-query'

import { downloadBlobAsFile } from 'shared/file'

import { dashboardApi } from '../../api/dashboard.api'

export const AnalyticsQueryKey = ['analytics']
export const AnalyticsDownloadQueryKey = ['analytics-download']

export const useDownloadAnalyticsReportQuery = (payload?: [Date, Date]) => {
  const query = useMutation({
    mutationFn: async () => {
      const { data } = await dashboardApi.getAnalyticsCSV(payload)

      return data
    },
  })

  useEffect(() => {
    const { isSuccess, data: csvReport } = query
    if (isSuccess && csvReport) {
      downloadBlobAsFile(csvReport, {
        fileName: 'Dashboard_Report.csv',
        type: 'text/csv',
      })

      query.reset()
    }
  }, [query])

  const downloadReport = useCallback(() => {
    query.mutate()
  }, [])

  return { ...query, downloadReport }
}
