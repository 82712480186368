import React, { useEffect } from 'react'

import { FrankieButton, FrankieImage, FrankieLoader } from 'frankify/src'

import { useI18n } from 'shared/i18n'
import { useOverlay } from 'shared/overlay'

import { CreditHeaderIcon } from '../../assets'
import { INDIVIDUAL_PROFILE_KEY } from '../../individual-profile.keys'
import { individualProfileEn } from '../../locale/new-profile.en'
import { useCreditHeaderAcknowledgementMutation } from '../../mutation/credit-header-acknowledgement.mutation'

type Props = {
  message?: string
  handleSuccess: () => void
  entityId: string
}

export function CreditHeader({ message, entityId, handleSuccess }: Props) {
  const t = useI18n([INDIVIDUAL_PROFILE_KEY], { keys: individualProfileEn })
  const [, closeOverlay] = useOverlay()

  const { mutate, isLoading, isSuccess } =
    useCreditHeaderAcknowledgementMutation()

  const handleClick = () => mutate({ entityId })

  useEffect(() => {
    if (isSuccess) {
      handleSuccess()
      closeOverlay()
    }
  }, [isSuccess])

  return (
    <div className="relative bg-mono-white rounded-2 w-[500px]">
      <div className="flex justify-between py-4 px-6 border-b border-neutral-50">
        <div className="flex gap-2">
          <FrankieImage className="w-[25px]" src={CreditHeaderIcon} />
          <div className="text-md font-bold ">{t('creditHeaderTitle')}</div>
        </div>
        <FrankieButton
          noStyles
          onClick={closeOverlay}
          className="flex-grow-0"
          singleIcon={{
            name: 'mdiClose',
            className: 'text-tertiary-grey-400',
          }}
        />
      </div>
      <FrankieLoader loading={isLoading}>
        <div className="flex flex-col gap-4 overflow-y-auto p-6 text-tertiary-grey-700">
          <div className="flex flex-column justify-between min-h-[180px]">
            <div dangerouslySetInnerHTML={{ __html: message || '' }} />
          </div>

          <div className="flex justify-between pt-2">
            <FrankieButton
              onClick={handleClick}
              className="min-w-[120px]"
              type="submit"
            >
              {t('ok')}
            </FrankieButton>
          </div>
        </div>
      </FrankieLoader>
    </div>
  )
}
