import React from 'react'

import { FrankieLoader } from 'frankify/src'

import { useTableLoadingState } from 'features/profiles-filter'

import { useI18n } from 'shared/i18n'

import {
  PROFILES_TABLE_KEY,
  profilesTableEn,
} from '../../locale/profiles-table.en'
// eslint-disable-next-line import/no-cycle
import { loadingOverlayQa } from '../../qa/profiles-table.qa'

export function LoadingOverlay() {
  const t = useI18n([PROFILES_TABLE_KEY], { keys: profilesTableEn })

  const { data } = useTableLoadingState()

  const isClearingFilter = data === 'clearingFilters'
  const isApplyingFilters = data === 'applyingFilters'
  const isLoadingMoreData = data === 'loadingMoreData'

  return (
    <div
      className="flex align-center justify-center flex-col w-full h-full bg-mono-white"
      data-qa={loadingOverlayQa.container}
    >
      <div>
        <div id="loader" data-qa={loadingOverlayQa.loader}>
          <FrankieLoader loading className="min-h-[76px]" size="sm" />
        </div>
        <div
          className="flex flex-col items-center mt-3"
          data-qa={loadingOverlayQa.filterWrapper}
        >
          <span className="inline-block text-md font-semibold leading-6 text-tertiary-grey-800">
            {isClearingFilter && t('clearingFilters')}
            {isApplyingFilters && t('applyingFilters')}
            {isLoadingMoreData && t('loadingMoreData')}
          </span>
        </div>
      </div>
    </div>
  )
}
