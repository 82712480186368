export type CustomViewData = {
  customViews: {
    id: number
    query: string
    name: string
  }[]
}

export type CustomViewPayload = Omit<
  CustomViewData['customViews'][number],
  'id'
>

export const VIEW_LIMIT = 5
