import React, { FocusEvent, forwardRef, useMemo } from 'react'

import { FrankieIcon, FrankieTextField, useFrankieMenu } from 'frankify/src'

import { CountryAlpha3Code, ICountryOption } from '../../model/country.model'
import { countryQa } from '../../qa/country.qa'

type Props = {
  options: ICountryOption[]
  value: CountryAlpha3Code
  onChange: (event: { target: { value: CountryAlpha3Code } }) => void
  onBlur: (event: FocusEvent) => void
  name: string
  disabled?: boolean
}

export const CountrySelector = forwardRef<HTMLInputElement, Props>(
  (
    { options, value, name, onBlur, onChange, disabled = false }: Props,
    ref,
  ) => {
    const { parentRef, isOpen, handleOpen, handleToggle, handleClose } =
      useFrankieMenu()

    const displayValue = useMemo<string>(
      () => options.find(option => option.alpha3code === value)?.name ?? value,
      [value, options],
    )

    const handleSelectOption = (option: CountryAlpha3Code) => () => {
      onChange({ target: { value: option } })
      handleClose()
    }

    const handleChange = () => {
      handleOpen()
    }

    return (
      <div className="relative" ref={parentRef}>
        <div>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label>
            <FrankieTextField
              onBlur={onBlur}
              className="caret-transparent cursor-pointer"
              inputClassName="pr-[30px] text-tertiary-grey-700 focus-visible:text-tertiary-grey-500"
              value={displayValue}
              onChange={handleChange}
              disabled={disabled}
              name={name}
              ref={ref}
              size="sm"
              onClick={handleToggle}
              testId={{
                input: countryQa.defaultCountrySelector,
              }}
            />
            {!disabled && (
              <FrankieIcon
                className="absolute bottom-2.5 right-4 text-tertiary-grey-700 cursor-pointer"
                name={isOpen ? 'mdiChevronUp' : 'mdiChevronDown'}
                size="xs"
              />
            )}
          </label>
          <div className="relative">
            {isOpen && (
              <ul className="absolute z-10 bg-mono-white shadow-md rounded-sm top-1 w-full max-h-[193px] py-1 overflow-y-auto scrollbar-sm">
                {options.map(option => (
                  // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                  <li
                    key={option.alpha3code}
                    onClick={handleSelectOption(option.alpha3code)}
                    onKeyDown={handleSelectOption(option.alpha3code)}
                    className={`text-sm text-tertiary-grey-800 py-2 px-4 cursor-pointer ${
                      option.alpha3code === value
                        ? 'bg-primary-50'
                        : 'hover:bg-tertiary-grey-100'
                    }`}
                    data-qa={countryQa.defaultCountrySelectorOption}
                  >
                    {option.name}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </div>
    )
  },
)
