import {
  EntityID,
  ProcessResultObject,
  ProcessResultSystemStatusEnum,
  WorkflowStepSummaryAMLSearchParameters,
} from '../../model/entity.model'
import { useGetWorkflowEventsData } from '../entity-workflow/entity-workflow.query'

type Args = {
  entityId: EntityID
  workflowExecutionId?: string
  mock?: boolean
  isNonValid?: boolean
}

export type UpdatedPRO = ProcessResultObject & {
  workflowName?: string
  workflowAttempts?: number
  query?: WorkflowStepSummaryAMLSearchParameters
}

export const useEntityAmlData = ({
  entityId,
  workflowExecutionId,
  isNonValid,
}: Args) => {
  const { data: workflowData, refetch: refetchWorkflow } =
    useGetWorkflowEventsData({ entityId })
  const proIdDictionary: Record<string, boolean> = {}
  const workflowAMLStepsProcessResult: UpdatedPRO[] =
    workflowData?.workflowSummaries?.flatMap(workflow => {
      const step = workflow.workflowResultData?.workflowStepResults?.find(
        step => step.stepName === 'AML',
      )

      let query: WorkflowStepSummaryAMLSearchParameters | undefined
      if (step?.summary?.stepName === 'AML') {
        query = step.summary.providerSummaries?.at(0)?.searchQuery
      }

      return workflowExecutionId &&
        workflow.workflowExecutionId !== workflowExecutionId
        ? []
        : step?.processResults
            ?.filter(pro => {
              if (pro.class !== 'AML') return false
              if (proIdDictionary[pro.processResultId]) {
                return false
              }
              proIdDictionary[pro.processResultId] = true
              return true
            })
            ?.map(i => ({
              ...i,
              query,
              workflowName: workflow.workflowName,
              workflowAttempts: workflow.workflowAttempts,
            })) || []
    }) || []

  const refetch = () => {
    void refetchWorkflow()
  }

  const nonValidData = {
    processResults: workflowAMLStepsProcessResult.filter(
      i => i.systemStatus !== ProcessResultSystemStatusEnum.VALID,
    ),
  }

  const data = {
    processResults: workflowAMLStepsProcessResult.filter(
      i =>
        i.result === 'HIT' &&
        i.systemStatus === ProcessResultSystemStatusEnum.VALID,
    ),
  }

  return {
    data: isNonValid ? nonValidData : data,
    refetch,
  }
}
