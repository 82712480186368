import React, { useEffect } from 'react'

import { FrankieButton, FrankieIcon, FrankieLoader } from 'frankify/src'

import { useApplicantAssociateInternationalEntityMutation } from 'features/applicant-business-ownership-international/state/applicant-associate-international-entity/applicant-associate-international-entity.mutation'

import { useI18n } from 'shared/i18n'

import { APPLICANT_BUSINESS_OWNERSHIP_INTERNATIONAL_KEY } from '../../../../applicant-business-ownership-international.key'
import { applicantBusinessOwnershipInternationalEn } from '../../../../locale/applicant-business-ownership-international.en'
import { businessOwnerShipDataGridActionMenuQa } from '../../../../qa/applicant-business-ownership-international.qa'
import { UpdateAssociatedPartyProps } from '../edit-associated-party-modal/edit-associated-party-modal'

export function RemoveAssociatedPartyModal({
  entityId,
  applicantId,
  onClose,
}: UpdateAssociatedPartyProps) {
  const t = useI18n([APPLICANT_BUSINESS_OWNERSHIP_INTERNATIONAL_KEY], {
    keys: applicantBusinessOwnershipInternationalEn,
  })

  const { mutate, isLoading, isSuccess } =
    useApplicantAssociateInternationalEntityMutation({
      applicantId,
      associationType: 'remove',
    })

  useEffect(() => {
    if (isSuccess) {
      onClose()
    }
  }, [isSuccess, onClose])

  const handleRemove = () => {
    mutate({ entityId })
  }

  return (
    <div className="w-[448px]">
      <FrankieLoader loading={isLoading}>
        <div
          className="px-6 pt-4 pb-8 flex flex-col items-center gap-2 text-center"
          data-qa={businessOwnerShipDataGridActionMenuQa.wrapper}
        >
          <FrankieIcon
            name="mdiAlertCircleOutline"
            className="text-tertiary-red-700 scale-75"
            size="xl"
          />
          <div className="text-md font-semibold text-tertiary-grey-800">
            {t('areYouSure')}
          </div>

          <div className="text-md mb-4">
            {t('actionWillRemoveAssociatedParty')}
          </div>

          <div className="flex justify-center gap-4">
            <FrankieButton
              onClick={onClose}
              intent="darkOutline"
              testId={{
                button: businessOwnerShipDataGridActionMenuQa.removeBtn,
              }}
            >
              {t('noCancel')}
            </FrankieButton>

            <FrankieButton onClick={handleRemove} intent="danger">
              {t('yesSure')}
            </FrankieButton>
          </div>
        </div>
      </FrankieLoader>
    </div>
  )
}
