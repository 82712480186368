export function debounce<T extends (...args: never[]) => void>(
  func: T,
  wait: number,
) {
  let timeout: NodeJS.Timeout
  return (...args: Parameters<T>) => {
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      // @ts-ignore
      func.apply(this, args)
    }, wait)
  }
}
