import React, { useEffect } from 'react'

import { FrankieCheckbox, FrankieRadio } from 'frankify/src'

import { generateRandomKey } from 'shared/string'
import { TrackingEventsTypes, trackingManager } from 'shared/tracking'

import { useRoleManagementTranslation } from '../../locale/role-management-f2.en'
import { IPermissionFacade } from '../../model/role-management-f2.model'
import { rolePermissionDataQa } from '../../qa/role-management-f2.qa'
import { useF2PermissionQuery } from '../../state/f2-permissions-query/f2-permissions.query'
import { useF2RoleQuery } from '../../state/f2-roles-query/f2-roles.query'

type PermissionGroup = {
  title: string
  permissions: IPermissionFacade[]
  selectionType: 'radio' | 'checkbox'
}[][]

type Props = {
  className?: string
  testId?: {
    container?: string
    title?: string
  }
}

export function RolePermissions({ className = '', testId }: Props) {
  const { t } = useRoleManagementTranslation()

  const { selectedRole } = useF2RoleQuery()
  const {
    viewPermission,
    writePermission,
    downloadPermission,
    settingsPermission,
  } = useF2PermissionQuery()

  const availablePermissions = selectedRole?.permissionFacade.map(
    item => item.permission,
  )

  useEffect(() => {
    trackingManager.track(TrackingEventsTypes.RoleManagementSettings)
  }, [])

  const permissionGroup: PermissionGroup = [
    [
      {
        title: t('viewAccess'),
        permissions: viewPermission ?? [],
        selectionType: 'radio',
      },
      {
        title: t('writeAndDownloadAccess'),
        permissions: [
          ...(downloadPermission ?? []),
          ...(writePermission ?? []),
        ],
        selectionType: 'checkbox',
      },
    ],
    [
      {
        title: t('settings'),
        permissions: settingsPermission ?? [],
        selectionType: 'checkbox',
      },
    ],
  ]

  return (
    <div
      data-qa={testId?.container}
      className={`tertiary-grey-700 ${className}`}
    >
      <div
        data-qa={testId?.title}
        className="text-tertiary-grey-800 text-xl font-bold"
      >
        {selectedRole?.role}
      </div>
      <div className="py-2">{selectedRole?.description}</div>

      <div className="py-2 text-tertiary-grey-800 text-lg font-bold">
        {t('permissions')}
      </div>

      {permissionGroup.map((group, idx) => (
        <div
          key={generateRandomKey('permission-group', idx)}
          className="px-5 pt-5 pb-4 mb-5 flex flex-col gap-4 border border-tertiary-grey-200 rounded-md"
        >
          {group.map(({ permissions, title, selectionType }) => (
            <div key={title}>
              <div className="font-semibold mb-3">{title}</div>
              {permissions.map(({ name, permission, description }) => (
                <div key={permission} className="mb-4 flex gap-3 items-start">
                  <div className="min-w-[20px]">
                    {selectionType === 'radio' ? (
                      <FrankieRadio
                        testId={{
                          input: rolePermissionDataQa(permission),
                        }}
                        checked={availablePermissions?.includes(permission)}
                        disabled
                      />
                    ) : (
                      <FrankieCheckbox
                        testId={{
                          input: rolePermissionDataQa(permission),
                        }}
                        checked={availablePermissions?.includes(permission)}
                        disabled
                      />
                    )}
                  </div>
                  <div className="min-w-[170px]"> {name}</div>
                  <div className="max-w-[480px] flex-shrink-1 text-tertiary-grey-500">
                    {description}
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      ))}
    </div>
  )
}
