/* eslint-disable import/no-named-default */
import { default as AND } from './flags/AD.svg'
import { default as ARE } from './flags/AE.svg'
import { default as AFG } from './flags/AF.svg'
import { default as ATG } from './flags/AG.svg'
import { default as AIA } from './flags/AI.svg'
import { default as ALB } from './flags/AL.svg'
import { default as ARM } from './flags/AM.svg'
import { default as AGO } from './flags/AO.svg'
import { default as ARG } from './flags/AR.svg'
import { default as AUT } from './flags/AT.svg'
import { default as AUS } from './flags/AU.svg'
import { default as ABW } from './flags/AW.svg'
import { default as ALA } from './flags/AX.svg'
import { default as AZE } from './flags/AZ.svg'
import { default as BIH } from './flags/BA.svg'
import { default as BRB } from './flags/BB.svg'
import { default as BGD } from './flags/BD.svg'
import { default as BEL } from './flags/BE.svg'
import { default as BFA } from './flags/BF.svg'
import { default as BGR } from './flags/BG.svg'
import { default as BHR } from './flags/BH.svg'
import { default as BDI } from './flags/BI.svg'
import { default as BEN } from './flags/BJ.svg'
import { default as BMU } from './flags/BM.svg'
import { default as BRN } from './flags/BN.svg'
import { default as BOL } from './flags/BO.svg'
import { default as BRA } from './flags/BR.svg'
import { default as BHS } from './flags/BS.svg'
import { default as BTN } from './flags/BT.svg'
import { default as BWA } from './flags/BW.svg'
import { default as BLR } from './flags/BY.svg'
import { default as BLZ } from './flags/BZ.svg'
import { default as CAN } from './flags/CA.svg'
import { default as COD } from './flags/CD.svg'
import { default as CAF } from './flags/CF.svg'
import { default as COG } from './flags/CG.svg'
import { default as CHE } from './flags/CH.svg'
import { default as CIV } from './flags/CI.svg'
import { default as CHL } from './flags/CL.svg'
import { default as CMR } from './flags/CM.svg'
import { default as CHN } from './flags/CN.svg'
import { default as COL } from './flags/CO.svg'
import { default as CRI } from './flags/CR.svg'
import { default as CUB } from './flags/CU.svg'
import { default as CPV } from './flags/CV.svg'
import { default as CYP } from './flags/CY.svg'
import { default as CZE } from './flags/CZ.svg'
import { default as DEU } from './flags/DE.svg'
import { default as DJI } from './flags/DJ.svg'
import { default as DNK } from './flags/DK.svg'
import { default as DMA } from './flags/DM.svg'
import { default as DOM } from './flags/DO.svg'
import { default as DZA } from './flags/DZ.svg'
import { default as ECU } from './flags/EC.svg'
import { default as EST } from './flags/EE.svg'
import { default as EGY } from './flags/EG.svg'
import { default as ERI } from './flags/ER.svg'
import { default as ESP } from './flags/ES.svg'
import { default as ETH } from './flags/ET.svg'
import { default as FIN } from './flags/FI.svg'
import { default as FJI } from './flags/FJ.svg'
import { default as FLK } from './flags/FK.svg'
import { default as FSM } from './flags/FM.svg'
import { default as FRA } from './flags/FR.svg'
import { default as GAB } from './flags/GA.svg'
import { default as GBR } from './flags/GB.svg'
import { default as GRD } from './flags/GD.svg'
import { default as GEO } from './flags/GE.svg'
import { default as GGY } from './flags/GG.svg'
import { default as GHA } from './flags/GH.svg'
import { default as GIB } from './flags/GI.svg'
import { default as GMB } from './flags/GM.svg'
import { default as GIN } from './flags/GN.svg'
import { default as GNQ } from './flags/GQ.svg'
import { default as GRC } from './flags/GR.svg'
import { default as GTM } from './flags/GT.svg'
import { default as GNB } from './flags/GW.svg'
import { default as GUY } from './flags/GY.svg'
import { default as HKG } from './flags/HK.svg'
import { default as HND } from './flags/HN.svg'
import { default as HRV } from './flags/HR.svg'
import { default as HTI } from './flags/HT.svg'
import { default as HUN } from './flags/HU.svg'
import { default as IDN } from './flags/ID.svg'
import { default as IRL } from './flags/IE.svg'
import { default as ISR } from './flags/IL.svg'
import { default as IMN } from './flags/IM.svg'
import { default as IND } from './flags/IN.svg'
import { default as IRQ } from './flags/IQ.svg'
import { default as IRN } from './flags/IR.svg'
import { default as ISL } from './flags/IS.svg'
import { default as ITA } from './flags/IT.svg'
import { default as JEY } from './flags/JE.svg'
import { default as JAM } from './flags/JM.svg'
import { default as JOR } from './flags/JO.svg'
import { default as JPN } from './flags/JP.svg'
import { default as KEN } from './flags/KE.svg'
import { default as KGZ } from './flags/KG.svg'
import { default as KHM } from './flags/KH.svg'
import { default as COM } from './flags/KM.svg'
import { default as KNA } from './flags/KN.svg'
import { default as PRK } from './flags/KP.svg'
import { default as KOR } from './flags/KR.svg'
import { default as KWT } from './flags/KW.svg'
import { default as CYM } from './flags/KY.svg'
import { default as KAZ } from './flags/KZ.svg'
import { default as LAO } from './flags/LA.svg'
import { default as LBN } from './flags/LB.svg'
import { default as LCA } from './flags/LC.svg'
import { default as LIE } from './flags/LI.svg'
import { default as LKA } from './flags/LK.svg'
import { default as LBR } from './flags/LR.svg'
import { default as LSO } from './flags/LS.svg'
import { default as LTU } from './flags/LT.svg'
import { default as LUX } from './flags/LU.svg'
import { default as LVA } from './flags/LV.svg'
import { default as LBY } from './flags/LY.svg'
import { default as MAR } from './flags/MA.svg'
import { default as MCO } from './flags/MC.svg'
import { default as MDA } from './flags/MD.svg'
import { default as MNE } from './flags/ME.svg'
import { default as MDG } from './flags/MG.svg'
import { default as MKD } from './flags/MK.svg'
import { default as MLI } from './flags/ML.svg'
import { default as MMR } from './flags/MM.svg'
import { default as MNG } from './flags/MN.svg'
import { default as MAC } from './flags/MO.svg'
import { default as MRT } from './flags/MR.svg'
import { default as MSR } from './flags/MS.svg'
import { default as MLT } from './flags/MT.svg'
import { default as MUS } from './flags/MU.svg'
import { default as MDV } from './flags/MV.svg'
import { default as MWI } from './flags/MW.svg'
import { default as MEX } from './flags/MX.svg'
import { default as MYS } from './flags/MY.svg'
import { default as MOZ } from './flags/MZ.svg'
import { default as NAM } from './flags/NA.svg'
import { default as NER } from './flags/NE.svg'
import { default as NGA } from './flags/NG.svg'
import { default as NIC } from './flags/NI.svg'
import { default as NLD } from './flags/NL.svg'
import { default as NOR } from './flags/NO.svg'
import { default as NPL } from './flags/NP.svg'
import { default as NZL } from './flags/NZ.svg'
import { default as OMN } from './flags/OM.svg'
import { default as PAN } from './flags/PA.svg'
import { default as PER } from './flags/PE.svg'
import { default as PYF } from './flags/PF.svg'
import { default as PNG } from './flags/PG.svg'
import { default as PHL } from './flags/PH.svg'
import { default as PAK } from './flags/PK.svg'
import { default as POL } from './flags/PL.svg'
import { default as PRI } from './flags/PR.svg'
import { default as PRT } from './flags/PT.svg'
import { default as PLW } from './flags/PW.svg'
import { default as PRY } from './flags/PY.svg'
import { default as QAT } from './flags/QA.svg'
import { default as ROU } from './flags/RO.svg'
import { default as SRB } from './flags/RS.svg'
import { default as RUS } from './flags/RU.svg'
import { default as RWA } from './flags/RW.svg'
import { default as SAU } from './flags/SA.svg'
import { default as SLB } from './flags/SB.svg'
import { default as SYC } from './flags/SC.svg'
import { default as SDN } from './flags/SD.svg'
import { default as SWE } from './flags/SE.svg'
import { default as SGP } from './flags/SG.svg'
import { default as SHN } from './flags/SH.svg'
import { default as SVN } from './flags/SI.svg'
import { default as SVK } from './flags/SK.svg'
import { default as SLE } from './flags/SL.svg'
import { default as SMR } from './flags/SM.svg'
import { default as SEN } from './flags/SN.svg'
import { default as SOM } from './flags/SO.svg'
import { default as SUR } from './flags/SR.svg'
import { default as STP } from './flags/ST.svg'
import { default as SLV } from './flags/SV.svg'
import { default as SYR } from './flags/SY.svg'
import { default as SWZ } from './flags/SZ.svg'
import { default as TCA } from './flags/TC.svg'
import { default as TCD } from './flags/TD.svg'
import { default as TGO } from './flags/TG.svg'
import { default as THA } from './flags/TH.svg'
import { default as TJK } from './flags/TJ.svg'
import { default as TLS } from './flags/TL.svg'
import { default as TKM } from './flags/TM.svg'
import { default as TUN } from './flags/TN.svg'
import { default as TON } from './flags/TO.svg'
import { default as TUR } from './flags/TR.svg'
import { default as TTO } from './flags/TT.svg'
import { default as TWN } from './flags/TW.svg'
import { default as TZA } from './flags/TZ.svg'
import { default as UKR } from './flags/UA.svg'
import { default as UGA } from './flags/UG.svg'
import { default as USA } from './flags/US.svg'
import { default as URY } from './flags/UY.svg'
import { default as UZB } from './flags/UZ.svg'
import { default as VCT } from './flags/VC.svg'
import { default as VEN } from './flags/VE.svg'
import { default as VGB } from './flags/VG.svg'
import { default as VNM } from './flags/VN.svg'
import { default as VUT } from './flags/VU.svg'
import { default as WSM } from './flags/WS.svg'
// import { default as WLF } from './flags/WF.svg'
// import { default as ESH } from './flags/EH.svg'
import { default as YEM } from './flags/YE.svg'
import { default as ZAF } from './flags/ZA.svg'
import { default as ZMB } from './flags/ZM.svg'
import { default as ZWE } from './flags/ZW.svg'
import { CountryAlpha3Code } from '../model/country.model'

export const COUNTRIES_FLAGS = {
  AFG,
  ALA,
  ALB,
  DZA,
  AND,
  AGO,
  AIA,
  ATG,
  ARG,
  ARM,
  ABW,
  AUS,
  AUT,
  AZE,
  BHS,
  BHR,
  BGD,
  BRB,
  BLR,
  BEL,
  BLZ,
  BEN,
  BMU,
  BTN,
  BOL,
  BIH,
  BWA,
  BRA,
  VGB,
  BRN,
  BGR,
  BFA,
  BDI,
  KHM,
  CMR,
  CAN,
  CPV,
  CYM,
  CAF,
  TCD,
  CHL,
  CHN,
  COL,
  COM,
  COG,
  COD,
  CRI,
  HRV,
  CUB,
  CYP,
  CZE,
  DNK,
  DJI,
  DMA,
  DOM,
  ECU,
  EGY,
  SLV,
  GNQ,
  ERI,
  EST,
  ETH,
  FLK,
  FJI,
  FIN,
  FRA,
  PYF,
  GAB,
  GMB,
  GEO,
  DEU,
  GHA,
  GIB,
  GRC,
  GRD,
  GTM,
  GGY,
  GIN,
  GNB,
  GUY,
  HTI,
  HND,
  HKG,
  HUN,
  ISL,
  IND,
  IDN,
  CIV,
  IRN,
  IRQ,
  IRL,
  IMN,
  ISR,
  ITA,
  JAM,
  JPN,
  JEY,
  JOR,
  KAZ,
  KEN,
  KWT,
  KGZ,
  LAO,
  LVA,
  LBN,
  LSO,
  LBR,
  LBY,
  LIE,
  LTU,
  LUX,
  MAC,
  MKD,
  MDG,
  MWI,
  MYS,
  MDV,
  MLI,
  MLT,
  MRT,
  MUS,
  MEX,
  FSM,
  MDA,
  MCO,
  MNG,
  MNE,
  MSR,
  MAR,
  MOZ,
  MMR,
  NAM,
  NPL,
  NLD,
  NZL,
  NIC,
  NER,
  NGA,
  PRK,
  NOR,
  OMN,
  PAK,
  PLW,
  PAN,
  PNG,
  PRY,
  PER,
  PHL,
  POL,
  PRT,
  PRI,
  QAT,
  ROU,
  RUS,
  RWA,
  SHN,
  KNA,
  LCA,
  VCT,
  WSM,
  SMR,
  STP,
  SAU,
  SEN,
  SRB,
  SYC,
  SLE,
  SGP,
  SVK,
  SVN,
  SLB,
  SOM,
  ZAF,
  KOR,
  ESP,
  LKA,
  SDN,
  SUR,
  SWZ,
  SWE,
  CHE,
  SYR,
  TWN,
  TJK,
  TZA,
  THA,
  TLS,
  TGO,
  TON,
  TTO,
  TUN,
  TUR,
  TKM,
  TCA,
  UGA,
  UKR,
  ARE,
  GBR,
  USA,
  URY,
  UZB,
  VUT,
  VEN,
  VNM,
  YEM,
  ZMB,
  ZWE,
} as Record<CountryAlpha3Code, string>
