import { useQuery } from '@tanstack/react-query'

import { isNotEmptyString } from 'shared/typescript'

import { addressF2Api } from '../api/address-f2.api'

export const GetAddressF2QueryKey = ['get-address-f2']

export const useGetDetailAddress = (googleAddressId: string) =>
  useQuery({
    queryKey: [GetAddressF2QueryKey, googleAddressId],
    queryFn: async () => {
      const { data } = await addressF2Api.getDetailsAddress(googleAddressId)
      return data
    },
    enabled: isNotEmptyString(googleAddressId),
  })
