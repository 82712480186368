import { useGetWorkflowEventsData } from 'entities/entity'

import {
  failVariants,
  passVariants,
} from '../../model/individual-idv-check.model'
import { documents, idvCheckFullReport } from '../../model/mock-idv-data.model'

type Args = {
  entityId: string
  idx: number
}

export type DocumentInfo = (typeof documents)[number]
export type DocumentCheckReport = (typeof idvCheckFullReport)[number]

export const useIndividualIdvCheckState = ({ entityId, idx }: Args) => {
  const { data } = useGetWorkflowEventsData({ entityId })

  const workflow = data?.workflowSummaries?.at(0)

  const hadIdvChecks = workflow?.steps?.order?.includes('IDV')

  const step = workflow?.workflowResultData?.workflowStepResults?.filter(
    i => i.stepName === 'IDV',
  )[idx]

  const status = step?.result

  const isManualPass =
    step?.statusOverrideAt && passVariants.includes(step.result)

  const isManualFail =
    step?.statusOverrideAt && failVariants.includes(step.result)

  const requireResolve =
    isManualPass || (status && !passVariants.includes(status))

  return {
    hadIdvChecks,
    status,
    documents,
    isManualPass,
    isManualFail,
    requireResolve,
  }
}
