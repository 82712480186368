import { I18nKeys } from 'shared/i18n'

import { documentTypeNameEn } from '../locale/document.en'

/**
 * @todo [BigOh] Use the package - confirm with Pavel and team
 * [frankieone/shared] {@link https://bitbucket.org/kycutility/frankie-node-shared/src/main/src/model/v2/SupportingDocument.ts}
 */
export enum DocumentTypes {
  DRIVERS_LICENCE = 'DRIVERS_LICENCE',
  PASSPORT = 'PASSPORT',
  VISA = 'VISA',
  IMMIGRATION = 'IMMIGRATION',
  NATIONAL_ID = 'NATIONAL_ID',
  NATIONAL_HEALTH_ID = 'NATIONAL_HEALTH_ID',
  CONCESSION = 'CONCESSION',
  HEALTH_CONCESSION = 'HEALTH_CONCESSION',
  PENSION = 'PENSION',
  MILITARY_ID = 'MILITARY_ID',
  BIRTH_CERT = 'BIRTH_CERT',
  CITIZENSHIP = 'CITIZENSHIP',
  MARRIAGE_CERT = 'MARRIAGE_CERT',
  DEATH_CERT = 'DEATH_CERT',
  NAME_CHANGE = 'NAME_CHANGE',
  UTILITY_BILL = 'UTILITY_BILL',
  BANK_STATEMENT = 'BANK_STATEMENT',
  BANK_ACCOUNT = 'BANK_ACCOUNT',
  INTENT_PROOF = 'INTENT_PROOF',
  SELF_IMAGE = 'SELF_IMAGE',
  EMAIL_ADDRESS = 'EMAIL_ADDRESS',
  MSISDN = 'MSISDN',
  DEVICE = 'DEVICE',
  VEHICLE_REGISTRATION = 'VEHICLE_REGISTRATION',
  PROOF_OF_ADDRESS = 'PROOF_OF_ADDRESS',
  EXTERNAL_ADMIN = 'EXTERNAL_ADMIN',
  CHARGES = 'CHARGES',
  PRE_ASIC = 'PRE_ASIC',
  ANNUAL_RETURN = 'ANNUAL_RETURN',
  REPORT = 'REPORT',
  TRUST_DEED = 'TRUST_DEED',
  PARTNERSHIP_AGREEMENT = 'PARTNERSHIP_AGREEMENT',
  ADMIN_CHANGE = 'ADMIN_CHANGE',
  COMPANY_REPORT = 'COMPANY_REPORT',
  CHECK_RESULTS = 'CHECK_RESULTS',
  OTHER = 'OTHER',
  RENTAL_AGREEMENT = 'RENTAL_AGREEMENT',
  TAX_STATEMENT = 'TAX_STATEMENT',
  HOUSE_REGISTRATION = 'HOUSE_REGISTRATION',
  YELLOW_HOUSE_REGISTRATION = 'YELLOW_HOUSE_REGISTRATION',
  WORK_PERMIT = 'WORK_PERMIT',
  EMPLOYMENT_CERTIFICATE = 'EMPLOYMENT_CERTIFICATE',
  NOTARY_PUBLIC_ID = 'NOTARY_PUBLIC_ID',
}

export type DocumentTypeOption = {
  tKey: I18nKeys<typeof documentTypeNameEn>
  tDefaultKey?: I18nKeys<typeof documentTypeNameEn>
  value: DocumentTypes
}

export const documentTypesOption: Array<DocumentTypeOption> = [
  {
    tKey: 'documentTypeName.driversLicense',
    value: DocumentTypes.DRIVERS_LICENCE,
  },
  {
    tKey: 'documentTypeName.passport',
    value: DocumentTypes.PASSPORT,
  },
  {
    tKey: 'documentTypeName.visa',
    tDefaultKey: 'documentTypeName.visaDefault',
    value: DocumentTypes.VISA,
  },
  {
    tKey: 'documentTypeName.immigration',
    value: DocumentTypes.IMMIGRATION,
  },
  {
    tKey: 'documentTypeName.nationalId',
    value: DocumentTypes.NATIONAL_ID,
  },
  {
    tKey: 'documentTypeName.nationalHealthId',
    value: DocumentTypes.NATIONAL_HEALTH_ID,
  },
  {
    tKey: 'documentTypeName.concession',
    value: DocumentTypes.CONCESSION,
  },
  {
    tKey: 'documentTypeName.healthConcession',
    value: DocumentTypes.HEALTH_CONCESSION,
  },
  {
    tKey: 'documentTypeName.pension',
    value: DocumentTypes.PENSION,
  },
  {
    tKey: 'documentTypeName.militaryId',
    value: DocumentTypes.MILITARY_ID,
  },
  {
    tKey: 'documentTypeName.birthCert',
    value: DocumentTypes.BIRTH_CERT,
  },
  {
    tKey: 'documentTypeName.citizenship',
    value: DocumentTypes.CITIZENSHIP,
  },
  {
    tKey: 'documentTypeName.marriageCert',
    value: DocumentTypes.MARRIAGE_CERT,
  },
  {
    tKey: 'documentTypeName.deathCert',
    value: DocumentTypes.DEATH_CERT,
  },
  {
    tKey: 'documentTypeName.nameChange',
    value: DocumentTypes.NAME_CHANGE,
  },
  {
    tKey: 'documentTypeName.utilityBill',
    value: DocumentTypes.UTILITY_BILL,
  },
  {
    tKey: 'documentTypeName.bankStatement',
    value: DocumentTypes.BANK_STATEMENT,
  },
  {
    tKey: 'documentTypeName.bankAccount',
    value: DocumentTypes.BANK_ACCOUNT,
  },
  {
    tKey: 'documentTypeName.intentProof',
    value: DocumentTypes.INTENT_PROOF,
  },
  {
    tKey: 'documentTypeName.selfImage',
    value: DocumentTypes.SELF_IMAGE,
  },
  {
    tKey: 'documentTypeName.emailAddress',
    value: DocumentTypes.EMAIL_ADDRESS,
  },
  {
    tKey: 'documentTypeName.msisdn',
    value: DocumentTypes.MSISDN,
  },
  {
    tKey: 'documentTypeName.device',
    value: DocumentTypes.DEVICE,
  },
  {
    tKey: 'documentTypeName.vehicleRegistration',
    value: DocumentTypes.VEHICLE_REGISTRATION,
  },
  {
    tKey: 'documentTypeName.proofOfAddress',
    value: DocumentTypes.PROOF_OF_ADDRESS,
  },
  {
    tKey: 'documentTypeName.externalAdmin',
    value: DocumentTypes.EXTERNAL_ADMIN,
  },
  {
    tKey: 'documentTypeName.charges',
    value: DocumentTypes.CHARGES,
  },
  {
    tKey: 'documentTypeName.preAsic',
    value: DocumentTypes.PRE_ASIC,
  },
  {
    tKey: 'documentTypeName.annualReturn',
    value: DocumentTypes.ANNUAL_RETURN,
  },
  {
    tKey: 'documentTypeName.report',
    value: DocumentTypes.REPORT,
  },
  {
    tKey: 'documentTypeName.trustDeed',
    value: DocumentTypes.TRUST_DEED,
  },
  {
    tKey: 'documentTypeName.partnershipAgreement',
    value: DocumentTypes.PARTNERSHIP_AGREEMENT,
  },
  {
    tKey: 'documentTypeName.adminChange',
    value: DocumentTypes.ADMIN_CHANGE,
  },
  {
    tKey: 'documentTypeName.companyReport',
    value: DocumentTypes.COMPANY_REPORT,
  },
  {
    tKey: 'documentTypeName.checkResults',
    value: DocumentTypes.CHECK_RESULTS,
  },
  {
    tKey: 'documentTypeName.rentalAgreement',
    value: DocumentTypes.RENTAL_AGREEMENT,
  },
  {
    tKey: 'documentTypeName.other',
    value: DocumentTypes.OTHER,
  },
  {
    tKey: 'documentTypeName.houseRegistration',
    value: DocumentTypes.HOUSE_REGISTRATION,
  },
  {
    tKey: 'documentTypeName.yellowHouseRegistration',
    value: DocumentTypes.YELLOW_HOUSE_REGISTRATION,
  },
  {
    tKey: 'documentTypeName.workPermit',
    value: DocumentTypes.WORK_PERMIT,
  },
  {
    tKey: 'documentTypeName.employmentCertificate',
    value: DocumentTypes.EMPLOYMENT_CERTIFICATE,
  },
  {
    tKey: 'documentTypeName.notaryPublicId',
    value: DocumentTypes.NOTARY_PUBLIC_ID,
  },
  {
    tKey: 'documentTypeName.taxId',
    value: DocumentTypes.TAX_STATEMENT,
  },
]
