import React, { useEffect, useMemo } from 'react'

import { Controller } from 'react-hook-form'

import { FrankieTextField } from 'frankify/src'

import { INDIVIDUAL_PROFILE_KEY } from 'features/individual-profile/individual-profile.keys'
import { individualProfileEn } from 'features/individual-profile/locale/new-profile.en'
import { birthCertificateFieldConfig } from 'features/individual-profile/model/document.model'
import { IndividualProfileInputTypes } from 'features/individual-profile/model/form.model'

import { stateList } from 'entities/country'
import { KycDocumentCategoryTypes } from 'entities/document'

import { SelectFormField, getError } from 'shared/form'
import { useI18n } from 'shared/i18n'
import { NUMERIC_PATTERN } from 'shared/validation'

import {
  documentSubFormQa,
  individualProfileVueMigratedQa,
} from '../../../../qa/individual-profile.qa'
import { DocumentCategory } from '../document-category/document-category'
import { DocumentsSubFormProps } from '../document-sub-forms.types'

// eslint-disable-next-line complexity
export function BirthCertificateForm({
  form,
  idx,
  wrapperClasses,
}: DocumentsSubFormProps) {
  const {
    register,
    watch,
    control,
    formState: { errors },
    setValue,
    unregister,
  } = form
  const t = useI18n([INDIVIDUAL_PROFILE_KEY], { keys: individualProfileEn })

  const stateWatch: string = watch(
    `${IndividualProfileInputTypes.Document}.${idx}.${IndividualProfileInputTypes.State}`,
  )

  const selectedCountry: string = watch(
    `${IndividualProfileInputTypes.Document}.${idx}.${IndividualProfileInputTypes.Country}`,
  )

  const isNotAustralia = useMemo(
    () => selectedCountry !== 'AUS',
    [selectedCountry],
  )

  const fieldConfig = useMemo(
    () => birthCertificateFieldConfig[stateWatch] || [],
    [stateWatch],
  )

  const registrationDateRegisterFull = useMemo(
    () =>
      fieldConfig.includes(
        `${IndividualProfileInputTypes.RegistrationDate}Full`,
      ) &&
      register(
        `${IndividualProfileInputTypes.Document}.${idx}.${IndividualProfileInputTypes.BirthCertificate}.${IndividualProfileInputTypes.RegistrationDate}`,
        {
          shouldUnregister: true,
        },
      ),
    [idx, register, fieldConfig],
  )

  const registrationDistrictRegister = useMemo(
    () =>
      (fieldConfig.includes(IndividualProfileInputTypes.RegistrationDistrict) ||
        isNotAustralia) &&
      register(
        `${IndividualProfileInputTypes.Document}.${idx}.${IndividualProfileInputTypes.BirthCertificate}.${IndividualProfileInputTypes.RegistrationDistrict}`,
        {
          required: true,
        },
      ),
    [idx, fieldConfig, isNotAustralia, register],
  )

  const certificateNumberRegister = useMemo(
    () =>
      fieldConfig.includes(IndividualProfileInputTypes.CertificateNumber) &&
      register(
        `${IndividualProfileInputTypes.Document}.${idx}.${IndividualProfileInputTypes.BirthCertificate}.${IndividualProfileInputTypes.CertificateNumber}`,
      ),
    [idx, fieldConfig, register],
  )

  const dateOfPrintRegister = useMemo(
    () =>
      fieldConfig.includes(IndividualProfileInputTypes.DateOfPrint) &&
      register(
        `${IndividualProfileInputTypes.Document}.${idx}.${IndividualProfileInputTypes.BirthCertificate}.${IndividualProfileInputTypes.DateOfPrint}`,
        { required: true },
      ),
    [idx, fieldConfig, register],
  )

  const registrationNameRegister = useMemo(
    () =>
      !isNotAustralia &&
      register(
        `${IndividualProfileInputTypes.Document}.${idx}.${IndividualProfileInputTypes.BirthCertificate}.${IndividualProfileInputTypes.RegistrationName}`,
        {
          required: true,
        },
      ),
    [idx, isNotAustralia, register],
  )

  const otherNameRegister = useMemo(
    () =>
      !isNotAustralia &&
      register(
        `${IndividualProfileInputTypes.Document}.${idx}.${IndividualProfileInputTypes.BirthCertificate}.${IndividualProfileInputTypes.OtherName}`,
      ),
    [idx, isNotAustralia, register],
  )

  const familyNameRegister = useMemo(
    () =>
      !isNotAustralia &&
      register(
        `${IndividualProfileInputTypes.Document}.${idx}.${IndividualProfileInputTypes.BirthCertificate}.${IndividualProfileInputTypes.FamilyName}`,
        {
          required: true,
        },
      ),
    [idx, isNotAustralia, register],
  )

  const statesOptions = useMemo(
    () =>
      Object.hasOwn(stateList, selectedCountry)
        ? stateList[selectedCountry]
        : [],
    [selectedCountry],
  )

  useEffect(() => {
    setValue(
      `${IndividualProfileInputTypes.Document}.${idx}.${IndividualProfileInputTypes.DocumentCategory}`,
      KycDocumentCategoryTypes.PRIMARY_NON_PHOTOGRAPHIC as never,
    )
  }, [idx, setValue])

  const idNumberFieldName = useMemo(
    () =>
      `${IndividualProfileInputTypes.Document}.${idx}.${IndividualProfileInputTypes.IdNumber}` as IndividualProfileInputTypes.Document,
    [idx],
  )

  // unregister id number because used in multiple components
  useEffect(
    () => () => {
      unregister(idNumberFieldName)
    },
    [idx, unregister, idNumberFieldName],
  )

  // watch -> act - 1

  if (selectedCountry === 'NZL') {
    return (
      <div className={wrapperClasses}>
        {registrationDistrictRegister && (
          <FrankieTextField
            className="basis-[32%]"
            label={t('documentForm.placeOfBirth')}
            {...registrationDistrictRegister}
            error={!!getError(registrationDistrictRegister.name, errors)}
            placeholder={t('documentForm.placeOfBirth')}
          />
        )}

        <FrankieTextField
          className="basis-[32%]"
          label={t('documentForm.registrationNumber')}
          placeholder={t('documentForm.registrationNumber')}
          testId={{
            input: individualProfileVueMigratedQa.document.idNumberField,
          }}
          error={!!getError(idNumberFieldName, errors)}
          {...register(idNumberFieldName, {
            required: true,
            pattern: NUMERIC_PATTERN,
          })}
        />
      </div>
    )
  }
  return (
    <div className={wrapperClasses}>
      <SelectFormField
        options={statesOptions}
        className="basis-[32%]"
        label={t('documentForm.stateOfRegistration')}
        placeholder={t('documentForm.stateOfRegistration')}
        control={control}
        error={
          !!getError(
            `${IndividualProfileInputTypes.Document}.${idx}.${IndividualProfileInputTypes.State}`,
            errors,
          )
        }
        name={`${IndividualProfileInputTypes.Document}.${idx}.${IndividualProfileInputTypes.State}`}
        rules={{ required: true }}
        testId={{
          input: individualProfileVueMigratedQa.document.region,
        }}
      />

      <FrankieTextField
        className="basis-[32%]"
        label={`${t('documentForm.registrationNumber')} ${t('optional')}`}
        placeholder={t('documentForm.registrationNumber')}
        testId={{
          input: individualProfileVueMigratedQa.document.idNumberField,
        }}
        error={!!getError(idNumberFieldName, errors)}
        {...register(idNumberFieldName)}
      />

      <div className="basis-[32%]" />

      {fieldConfig.includes(IndividualProfileInputTypes.RegistrationDate) && (
        <Controller
          name={`${IndividualProfileInputTypes.Document}.${idx}.${IndividualProfileInputTypes.BirthCertificate}.${IndividualProfileInputTypes.RegistrationDate}`}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <FrankieTextField
              label={`${t('documentForm.registrationDate')} ${t('optional')}`}
              testId={{
                input: individualProfileVueMigratedQa.document.registrationYear,
              }}
              className="basis-[32%]"
              {...field}
              onChange={e => {
                if (e.target.value.match(/^[0-9]{0,4}$/)) {
                  field.onChange(e)
                }
              }}
              error={!!error}
              placeholder={t('documentForm.registrationDatePlaceholder')}
            />
          )}
          rules={{ minLength: 4, maxLength: 4 }}
          shouldUnregister
        />
      )}

      {registrationDateRegisterFull && (
        <FrankieTextField
          type="date"
          className="basis-[32%]"
          label={`${t('documentForm.registrationDate')} ${t('optional')}`}
          testId={{
            input: individualProfileVueMigratedQa.document.registrationYear,
          }}
          {...registrationDateRegisterFull}
          error={!!getError(registrationDateRegisterFull.name, errors)}
          placeholder={t('documentForm.datePlaceholder')}
        />
      )}

      {registrationDistrictRegister && (
        <FrankieTextField
          className="basis-[32%]"
          label={`${t('documentForm.registrationDistrict')}`}
          {...registrationDistrictRegister}
          error={!!getError(registrationDistrictRegister.name, errors)}
          placeholder={t('documentForm.registrationDistrict')}
        />
      )}

      {dateOfPrintRegister && (
        <FrankieTextField
          type="date"
          className="basis-[32%]"
          label={`${t('documentForm.dateOfPrint')}`}
          {...dateOfPrintRegister}
          error={!!getError(dateOfPrintRegister.name, errors)}
          placeholder={t('documentForm.datePlaceholder')}
        />
      )}

      {certificateNumberRegister && (
        <FrankieTextField
          className="basis-[32%]"
          label={`${t('documentForm.certificateNumber')} ${t('optional')}`}
          {...certificateNumberRegister}
          error={!!getError(certificateNumberRegister.name, errors)}
          placeholder={t('documentForm.certificateNumber')}
        />
      )}

      <div className="basis-[66%]" />

      <div className="basis-[100%]">
        <div className="pb-1 font-bold">{t('documentForm.registeredName')}</div>
        <div className={`${wrapperClasses} w-full`}>
          {registrationNameRegister && (
            <FrankieTextField
              className="basis-[32%]"
              label={t('documentForm.firstGiveName')}
              placeholder={t('documentForm.firstGiveName')}
              error={!!getError(registrationNameRegister.name, errors)}
              {...registrationNameRegister}
              testId={{
                input: individualProfileVueMigratedQa.document.fullGivenName,
              }}
            />
          )}
          {otherNameRegister && (
            <FrankieTextField
              className="basis-[32%]"
              label={`${t('documentForm.otherGivenName')} ${t('optional')}`}
              placeholder={t('documentForm.otherGivenName')}
              testId={{
                input: individualProfileVueMigratedQa.document.fullNameOther,
              }}
              {...otherNameRegister}
            />
          )}
          {familyNameRegister && (
            <FrankieTextField
              className="basis-[32%]"
              label={t('documentForm.familyName')}
              placeholder={t('documentForm.familyName')}
              error={!!getError(familyNameRegister.name, errors)}
              testId={{
                input: individualProfileVueMigratedQa.document.fullNameFamily,
              }}
              {...familyNameRegister}
            />
          )}
          <DocumentCategory
            disabled
            className="basis-[32%]"
            form={form}
            idx={idx}
            data-qa={documentSubFormQa.docCategory}
          />
        </div>
      </div>
    </div>
  )
}
