/* eslint-disable import/no-named-default */
import { default as MCareBlueCard } from './mcare-cards/mcare-blue.svg'
import { default as MCareGreenCard } from './mcare-cards/mcare-green.svg'
import { default as MCareYellowCard } from './mcare-cards/mcare-yellow.svg'

export const MCareCard = {
  blue: MCareBlueCard,
  green: MCareGreenCard,
  yellow: MCareYellowCard,
}
