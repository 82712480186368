import React from 'react'

import { FrankieButton, FrankieLoader } from 'frankify/src'

export type BusinessSummaryContentType = string | string[]
export type BusinessSummaryDataType = Record<string, BusinessSummaryContentType>

export type BusinessSummaryField<TData extends BusinessSummaryDataType> = {
  name: string
  value: keyof TData
  isAction?: boolean
  isVisible?: (data: TData, key: keyof TData) => boolean
}

export type BusinessSummaryViewProps<TData extends BusinessSummaryDataType> = {
  className?: string
  heading: string
  fields: BusinessSummaryField<TData>[]
  data: TData
  handleAction?: (arg: keyof TData) => void
  isLoading?: boolean
  hidden?: boolean
  testId?: {
    container?: string
  }
}

export function BusinessSummaryView<TData extends BusinessSummaryDataType>({
  className = '',
  heading,
  data,
  fields,
  handleAction,
  isLoading,
  hidden,
  testId,
}: BusinessSummaryViewProps<TData>) {
  if (hidden) {
    return null
  }

  return (
    <div
      className={`border border-tertiary-grey-200 rounded-sm overflow-hidden ${className}`}
      data-qa={testId?.container}
    >
      <div className="text-md font-bold bg-neutral-20 p-4 text-tertiary-grey-900">
        {heading}
      </div>

      <FrankieLoader loading={!!isLoading}>
        {fields.map(({ name, value, isAction, isVisible }) => {
          const content = data[value]

          if (isVisible && !isVisible(data, value)) {
            return null
          }

          return (
            <div
              key={name}
              className="flex items-start p-4 border-t border-tertiary-grey-200"
            >
              <div className="w-[200px]"> {name} </div>
              {isAction && handleAction && (
                <FrankieButton
                  onClick={() => handleAction(value)}
                  noStyles
                  className="font-bold text-primary-500"
                >
                  {content}
                </FrankieButton>
              )}

              {!isAction &&
                (Array.isArray(content) ? (
                  <div className="font-bold">
                    {content.map(item => (
                      <div> {item} </div>
                    ))}
                  </div>
                ) : (
                  <div className="font-bold"> {data[value]} </div>
                ))}
            </div>
          )
        })}
      </FrankieLoader>
    </div>
  )
}
