import React, { forwardRef } from 'react'

import { CheckboxStyle, ContainerStyle, SliderStyle } from './toggle.theme'
import { IFrankieToggleProps } from './toggle.types'

export const FrankieToggle = forwardRef(
  // eslint-disable-next-line prefer-arrow-callback
  function FrankieToggle(
    {
      className = '',
      size = 'md',
      name,
      disabled = false,
      checked = false,
      onBlur,
      onChange,
      testId = {
        input: '',
      },
    }: IFrankieToggleProps,
    ref: React.ForwardedRef<HTMLInputElement>,
  ): JSX.Element {
    return (
      // eslint-disable-next-line jsx-a11y/label-has-associated-control
      <label className={ContainerStyle({ size })}>
        <input
          id={name}
          name={name}
          aria-label={name}
          ref={ref}
          className={CheckboxStyle({})}
          disabled={disabled}
          type="checkbox"
          checked={checked}
          data-qa={testId.input}
          onBlur={onBlur}
          onChange={onChange}
        />
        <div className={SliderStyle({ size, checked, disabled })} />
      </label>
    )
  },
)
