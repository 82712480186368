export const applicantPersonalInfoEn = {
  inactiveProfileTooltip: 'Inactive profiles cannot be edited',
  trueDuplicate: 'True positive duplicate profiles cannot be edited.',
  blocklistedProfile: 'Blocklisted profiles cannot be edited.',
  businessInfo: {
    title: 'Business Info',
  },

  color: {
    green: 'Green',
    blue: 'Blue',
    yellow: 'Yellow',
  },
  frankieId: 'Frankie ID',
  ocrExpiryDisclaimer: 'OCR Expiry date has not been independently verified',
  ocrExpiryHeader:
    'The following information is associated with this document however has not been independently verified',
  ocrExpiryDateLabel: 'OCR Expiry Date*',
  personalInfo: {
    title: 'Personal Info',
    editCustomerInfo: 'Edit Customer Info',
    name: 'Name',
    nativeName: 'Native Name',
    englishName: 'English Name',
    dob: 'Date of Birth',
    currentResidentialAddress: 'Current Residential Address',
    previousResidentialAddress: 'Previous Residential Address',
    mailingAddress: 'Mailing Address',
    email: 'Email',
    phoneNumber: 'Phone Number',
    yearOfBirth: 'Year of Birth',
    gender: 'Gender',
    maritalStatus: 'Marital Status',
  },
  status: {
    checked: 'Checked',
    unchecked: 'Unchecked',
    verified: 'Verified',
    unVerified: 'Unverified',
  },

  documentInfo: {
    title: 'Document Info',
    rejectedSubclass: 'Rejected Subclass',
    expiredVisa: 'Expired Visa',
    viewId: 'View ID',
    labels: {
      licencePlate: 'Licence Plate',
      bankCardNumber: 'Bank Card Number',
      gender: 'Gender',
      maternalName: 'Maternal Name',
      paternalName: 'Paternal Name',
      laserCode: 'Laser Code',
      nationalIdNumber: 'National ID Number',
      licenseVersion: 'Licence version',
      homeCountryFullName: 'Full Name as Shown on ID',
      registrationDate: 'Registration Date',
      registrationDistrict: 'Registration District',
      placeOfBirth: 'Place of Birth',
      na: 'NA',
      dateOfRegistration: 'Date of Registration',
      countryOfBirth: 'Country of Birth',
      registrationYear: 'Registration Year',
      state: 'State',
      licenceNumber: 'Licence Number',
      docNumber: 'Document/Card number',
      countryIssue: 'Country of Issue',
      passportNumber: 'Passport Number',
      dateOfExp: 'Date Of Expiry',
      number: 'Number',
      cardColor: 'Card Colour',
      cardNumber: 'Card Number',
      positionOnCard: 'Position on Card',
      expiryDate: 'Expiry Date',
      fullNameOnCard: 'Full Name on Card',
      registrationState: 'Registration State',
      registrationNumber: 'Registration Number',
      dateOfPrint: 'Date of Print',
      registrationName: 'Registered Name',
      certificateNumber: 'Certificate Number',
      stockNumber: 'Stock Number',
      dateOfAcquisition: 'Date Of Acquisition',
      primaryCountry: 'Primary Country',
      secondaryCountry: 'Secondary Country',
      marriageDate: 'Marriage Date',
      partnerBride: 'Partner One (Brides) Name',
      partnerGroom: 'Partner Two (Grooms) Name',
      description: 'Description',
      idNumber: 'ID Number',
      country: 'Country',
      idSubtype: 'ID Subtype',
      applicantType: 'Applicant Type',
      grantData: 'Grant date',
      classSubClass: 'Class / Sub Class',
      workingRights: 'Working Rights',
      fileNumber: 'File number',
    },
    error: {
      visaExpired:
        'An expired Visa cannot be used for KYC. Add another ID document to pass KYC.',
      visaClassRejected:
        'Visa type is on your exclusion list. Add another ID Document to pass KYC.',
    },
  },

  rulesets: {
    title: 'Rulesets',
    matches: 'Matches',
    sectionNotSupported:
      'This section is not supported as the checks are based on a legacy customer profile',
    priorityMatched: {
      title: 'Priority Matched',
    },
    table: {
      rulesets: 'Rulesets',
      name: 'Name',
      dob: 'DOB',
      address: 'Address',
      govId: 'GOV ID',
      outcome: 'Outcome',
      noRows: 'No rulesets matched',
    },
    rowDetails: '<0>{{name}}</0> requires',
    requiredData:
      'At least <0>{{countValue}}</0> of match type <0>{{field}}</0>',
  },

  nameComparison: {
    title: 'Name Comparison',
    heading: 'Chinese National ID Name Comparison',
    userDefinedName: 'User entered name from China National ID',
    transliteration: 'Transliteration',
    nameByCustomer: 'Name as input by customer',
    overrideResult: 'Override Result',
    overrideForm: {
      comment: 'Comment',
      typeYourComment: 'Add your comment here',
      changeMatchStatus: 'Change match status',
      manualPass: 'Manual Pass',
      manualFail: 'Manual Fail',
      save: 'Save',
      cancel: 'Cancel',
    },
  },

  individualVerificationReport: {
    title: 'Individual Verification Reports',
    name: 'Name',
    dateGenerated: 'Date Generated',
    actions: 'Actions',
    noRowsOverlayText:
      'No Individual Verification Reports have been generated for this profile.',
    individualEntityReport: 'Individual Entity Report',
    view: 'View',
    download: 'Download',
    generateReport: 'Generate Report',
    report: 'report',
    reports: 'reports',
    noReportGenerated:
      'No Individual Verification Reports have been generated for this profile.',
  },

  matchDataWithSource: 'was matched on the following data sources',
  notMatchedWithSource: 'was not matched on any sources',
  notChecked: 'has not been checked',
  pass: 'Pass',
  noInRegistry: 'Not present in Registry Data',
  diffToRegistry: 'Different to Registry Data',
  uboTitleName: 'Name in Registry Data',
  uboTitleDob: 'Date of Birth in Registry Data',
  uboTitleAddress: 'Address in Registry Data',

  partialMatch: {
    title: 'Partial Match',
    tooltipDesc:
      'A partial match indicates that some of the customer’s information has been verified, however not enough to fulfil the KYC ruleset.',
    desc: 'Some of {{applicantName}}’s information has been verified, but did not pass the KYC ruleset.',
  },
  unchecked: {
    title: 'Unchecked',
    tooltipDesc: '',
    desc: 'The KYC check has not been completed',
  },
  noMatch: {
    title: 'No Match',
    tooltipDesc:
      'None of the customer’s information has been verified.  Double check all of their details have been entered correctly.',
    desc: 'None of {{applicantName}}’s information could be verified. Please Edit Customer Info and try again.',
  },
}
