import { I18nKeys } from 'shared/i18n'

import { notificationsManagementEn } from '../locale/notifications-management.en'

enum NotificationChannelTypes {
  EMAIL = 'EMAIL',
  PORTAL = 'PORTAL',
}

enum NotificationTypes {
  entityAssignment = 'entityAssignment',
  entityStatusUpdatedToNeedsAttentionFailedManuallyFailed = 'entityStatusUpdatedToNeedsAttentionFailedManuallyFailed',
  assignedEntityStatusUpdatedToNeedsAttentionFailedManuallyFailed = 'assignedEntityStatusUpdatedToNeedsAttentionFailedManuallyFailed',
  entityRiskUpdatedToHighUnacceptable = 'entityRiskUpdatedToHighUnacceptable',
  assignedEntityRiskUpdatedToHighUnacceptable = 'assignedEntityRiskUpdatedToHighUnacceptable',
  trustAnalysisComplete = 'trustAnalysisComplete',
}

export const personalNotificationMapper: Partial<
  Record<NotificationTypesType, NotificationTypesType[]>
> = {
  [NotificationTypes.assignedEntityStatusUpdatedToNeedsAttentionFailedManuallyFailed]:
    [NotificationTypes.entityStatusUpdatedToNeedsAttentionFailedManuallyFailed],
}

export const notificationWithFrequencyDisabled: NotificationTypesType[] = [
  NotificationTypes.trustAnalysisComplete,
]

export enum NotificationFrequenciesTypes {
  REALTIME = 'REALTIME',
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
}

export enum NotificationVolumeTypes {
  ALL_ENTITIES = 'ALL_ENTITIES',
  ASSIGNED_ENTITIES = 'ASSIGNED_ENTITIES',
}

type NotificationSelectOptions<TValue> = {
  label: I18nKeys<typeof notificationsManagementEn>
  value: TValue
}

export const notificationFrequenciesOptions: NotificationSelectOptions<NotificationFrequenciesTypes>[] =
  [
    {
      value: NotificationFrequenciesTypes.REALTIME,
      label: 'type.frequencyOptions.realTime',
    },
    {
      value: NotificationFrequenciesTypes.DAILY,
      label: 'type.frequencyOptions.daily',
    },
    {
      value: NotificationFrequenciesTypes.WEEKLY,
      label: 'type.frequencyOptions.weekly',
    },
  ]

export const notificationVolumeOptions: NotificationSelectOptions<NotificationVolumeTypes>[] =
  [
    {
      value: NotificationVolumeTypes.ALL_ENTITIES,
      label: 'general.frequencyOptions.allEntities',
    },
    {
      value: NotificationVolumeTypes.ASSIGNED_ENTITIES,
      label: 'general.frequencyOptions.onlyAssignedEntities',
    },
  ]

type NotificationChannelType = keyof typeof NotificationChannelTypes
export type NotificationTypesType = keyof typeof NotificationTypes

export interface INotificationChannel {
  name: string
  value: NotificationChannelType
}

export interface INotificationSettingsDictionary {
  name: string
  description: string
  value: NotificationTypesType
  isPersonalNotification: boolean
}

export interface INotificationSetting {
  channel: NotificationChannelType
  type: NotificationTypesType
  value: boolean
  canDisable: boolean
  frequency?: NotificationFrequenciesTypes
}

export interface INotificationSettingsConfig {
  notificationChannels: INotificationChannel[]
  allNotificationChannels: INotificationChannel[]
  notificationSettingsDictionary: INotificationSettingsDictionary[]
  notificationSettings: INotificationSetting[]
  canSeeNonPersonalNotifications: boolean
}

export const example: INotificationSettingsConfig = {
  canSeeNonPersonalNotifications: true,
  notificationChannels: [
    {
      name: 'Portal',
      value: 'PORTAL',
    },
    {
      name: 'Email',
      value: 'EMAIL',
    },
  ],
  allNotificationChannels: [
    {
      name: 'Portal',
      value: 'PORTAL',
    },
    {
      name: 'Email',
      value: 'EMAIL',
    },
  ],
  notificationSettingsDictionary: [
    {
      name: 'Entity assignment',
      description: 'Notify me when an entity is assigned to me',
      value: 'entityAssignment',
      isPersonalNotification: true,
    },
    {
      name: 'Workflow status updates on any entities',
      description:
        'Notify me when the workflow status of any entities is updated to Needs attention, Failed, or Manually failed',
      value: 'entityStatusUpdatedToNeedsAttentionFailedManuallyFailed',
      isPersonalNotification: false,
    },
    {
      name: 'Workflow status updates on entities assigned to me',
      description:
        'Notify me when the workflow status of entities assigned to me is updated to Needs attention, Failed, or Manually failed ',
      value: 'assignedEntityStatusUpdatedToNeedsAttentionFailedManuallyFailed',
      isPersonalNotification: true,
    },
    {
      name: 'Risk level updates on any entities',
      description:
        'Notify me when the risk level of any entities is updated to High or Unacceptable',
      value: 'entityRiskUpdatedToHighUnacceptable',
      isPersonalNotification: false,
    },
    {
      name: 'Risk level updates on entities assigned to me',
      description:
        'Notify me when the risk level of entities assigned to me is updated to High or Unacceptable',
      value: 'assignedEntityRiskUpdatedToHighUnacceptable',
      isPersonalNotification: true,
    },
  ],
  notificationSettings: [
    {
      channel: 'EMAIL',
      type: 'entityAssignment',
      value: true,
      canDisable: true,
    },
    {
      channel: 'EMAIL',
      type: 'entityStatusUpdatedToNeedsAttentionFailedManuallyFailed',
      value: true,
      canDisable: true,
    },
    {
      channel: 'EMAIL',
      type: 'assignedEntityStatusUpdatedToNeedsAttentionFailedManuallyFailed',
      value: true,
      canDisable: true,
    },
    {
      channel: 'EMAIL',
      type: 'entityRiskUpdatedToHighUnacceptable',
      value: true,
      canDisable: true,
    },
    {
      channel: 'EMAIL',
      type: 'assignedEntityRiskUpdatedToHighUnacceptable',
      value: true,
      canDisable: true,
    },
    {
      channel: 'PORTAL',
      type: 'entityAssignment',
      value: true,
      canDisable: false,
    },
    {
      channel: 'PORTAL',
      type: 'entityStatusUpdatedToNeedsAttentionFailedManuallyFailed',
      value: true,
      canDisable: true,
    },
    {
      channel: 'PORTAL',
      type: 'assignedEntityStatusUpdatedToNeedsAttentionFailedManuallyFailed',
      value: true,
      canDisable: true,
    },
    {
      channel: 'PORTAL',
      type: 'entityRiskUpdatedToHighUnacceptable',
      value: true,
      canDisable: true,
    },
    {
      channel: 'PORTAL',
      type: 'assignedEntityRiskUpdatedToHighUnacceptable',
      value: true,
      canDisable: true,
    },
  ],
}
