import { useCallback, useMemo } from 'react'

import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { ApplicantId, useApplicantDataQuery } from 'entities/applicant'
import { applicantApi } from 'entities/applicant/api/applicant.api'

import { I18nKeys, useI18n } from 'shared/i18n'
import { notification } from 'shared/notification'

import { APPLICANT_GENERAL_INFORMATION_KEY } from '../../applicant-general-information.key'
import { applicantGeneralInformationEn } from '../../locale/applicant-general-information.en'

type HelperArgs = {
  applicantId: ApplicantId
  successTKey: I18nKeys<typeof applicantGeneralInformationEn>
  errorTKey: I18nKeys<typeof applicantGeneralInformationEn>
}

type OnSuccessArgs = {
  tParams?: Record<string, string>
}

const useHandleStateUpdateResult = ({
  applicantId,
  successTKey,
  errorTKey,
}: HelperArgs) => {
  const t = useI18n([APPLICANT_GENERAL_INFORMATION_KEY], {
    keys: applicantGeneralInformationEn,
  })

  const { refetch } = useApplicantDataQuery({ applicantId })

  const onSuccess = useCallback(
    ({ tParams }: OnSuccessArgs = {}) => {
      void refetch()
      notification.success(t(successTKey, tParams))
    },
    [refetch, t, successTKey],
  )

  const onError = useCallback(
    ({ response }: AxiosError<{ message?: string }>) => {
      notification.error(response?.data.message ?? t(errorTKey))
    },
    [t, errorTKey],
  )

  return { onSuccess, onError }
}

type Args = {
  applicantId: ApplicantId
}

export type ProfileStatusChangeData = {
  comment: string
  state: 'fail' | 'pass' | 'archived' | 'inactive' | 'clear'
}

export const useApplicantProfileStatusChangeMutation = ({
  applicantId,
}: Args) => {
  const t = useI18n([APPLICANT_GENERAL_INFORMATION_KEY], {
    keys: applicantGeneralInformationEn,
  })

  const { onError, onSuccess } = useHandleStateUpdateResult({
    successTKey: 'success.profileStatusChange',
    errorTKey: 'error.profileStatusChange',
    applicantId,
  })

  const statusLabelMap: Record<
    ProfileStatusChangeData['state'],
    I18nKeys<typeof applicantGeneralInformationEn>
  > = useMemo(
    () => ({
      archived: 'action.confirm.archive',
      clear: 'action.confirm.unarchive',
      fail: 'action.fields.manuallyFailed',
      pass: 'action.fields.manuallyPassed',
      inactive: 'action.confirm.inactive',
    }),
    [],
  )

  return useMutation({
    mutationFn: (data: ProfileStatusChangeData) =>
      applicantApi.changeApplicantState(applicantId, data),
    onSuccess: (_, data) =>
      onSuccess({
        tParams: { status: t(statusLabelMap[data.state]) },
      }),
    onError,
  })
}

export type AddToBlocklistData = {
  flag: 'false_positive' | 'true_positive'
  flagType: 'watchlist' | 'blacklist'
  comment: string
  reasonCode: string
}

export const useAddApplicantToBlocklistMutation = ({ applicantId }: Args) => {
  const t = useI18n([APPLICANT_GENERAL_INFORMATION_KEY], {
    keys: applicantGeneralInformationEn,
  })

  const { onError, onSuccess } = useHandleStateUpdateResult({
    successTKey: 'success.blacklistStatusChange',
    errorTKey: 'error.profileStatusChange',
    applicantId,
  })

  const flagMap: Record<AddToBlocklistData['flag'], string> = useMemo(
    () => ({
      false_positive: t('action.status.removed'),
      true_positive: t('action.status.added'),
    }),
    [t],
  )

  const flagTypeMap: Record<AddToBlocklistData['flagType'], string> = useMemo(
    () => ({
      blacklist: t('action.status.internalBlocklist'),
      watchlist: t('action.status.internalWatchlist'),
    }),
    [t],
  )

  return useMutation({
    mutationFn: (data: AddToBlocklistData) =>
      applicantApi.addToBlocklist(applicantId, data),
    onSuccess: (_, data) =>
      onSuccess({
        tParams: {
          flag: flagMap[data.flag],
          flagType: flagTypeMap[data.flagType],
        },
      }),
    onError,
  })
}
