export const blocklistReasonEn = {
  blocklistReason: {
    fabricatedIdentity: 'Fabricated Identity',
    identityTakeover: 'Identity Takeover',
    falsifiedIdDocuments: 'Falsified ID Documents',
    stolenIdDocuments: 'Stolen ID Documents',
    merchantFraud: 'Merchant Fraud',
    neverPayBustOut: 'Never Pay / Bust Out',
    conflictingDataProvided: 'Conflicting Data Provided',
    moneyMule: 'Money Mule',
    falseFraudClaim: 'False Fraud Claim',
    fraudulent3rdParty: 'Fraudulent 3rd Party',
    companyTakeover: 'Company Takeover',
    fictitiousEmployer: 'Fictitious Employer',
    collusiveEmployer: 'Collusive Employer',
    overValuationOfAssets: 'Over-valuation of Assets',
    falsifiedEmploymentDetails: 'Falsified Employment Details',
    manipulatedIdentity: 'Manipulated Identity',
    syndicatedFraud: 'Syndicated Fraud',
    internalFraud: 'Internal Fraud',
    bankFraud: 'Bank Fraud',
    undisclosedData: 'Undisclosed Data',
    falseHardship: 'False Hardship',
    smrReportLodged: 'SMR report lodged',
    _2xSmrReportsLodged: '2x SMR reports lodged',
  },
}

export const blocklistAttributeEn = {
  blocklistAttribute: {
    entireEntity: 'Entire Profile',
    mailingAddress: 'Mailing Address',
    residentialAddress: 'Residential Address',
    emailAddress: 'Email Address',
    phoneNumber: 'Phone Number',
    idDocument: 'ID Document',
    nameAndDob: 'Name and DOB',
    nameAndDateOfBirth: 'Name and Date Of Birth',
  },
}

export const blocklistTableEn = {
  header: {
    attribute: 'Blocklisted Attribute',
    description: 'Description',
    reason: 'Reason for blocklist',
    createdDate: 'Added',
    lastUpdated: 'Last Updated',
    addedBy: 'Added By',
  },
  filter: {
    all: 'All',
    apply: 'Apply',
    clear: 'Clear',
  },
  format: {
    ago: '{{date}} ago',
  },
  tooltip: {
    positiveMatch: 'True positive match',
    matchToResolve: 'Potential matches must be resolved',
  },
  loading: {
    data: 'Loading data...',
    moreData: 'Loading more data...',
    applyingFilter: 'Applying filters...',
    clearingFilter: 'Clearing filters...',
  },
  placeholder: {
    emptyTitle: {
      noData: 'No data found',
      noFilterData: 'No match found with selected filters',
      noSearchData: 'No search result Found',
    },
    emptySubtitle: {
      noData:
        'Their are no entries in the blocklist. Click on add new block to make new entry.',
      noFilterData:
        'Your selected filters did not return any matches. Adjust your filters or clear them, and try again.',
      noSearchData:
        'No entries related to searched data. Please clear the search field, and try again.',
    },
  },
  error: 'Error fetching the blocklist.',

  ...blocklistReasonEn,
  ...blocklistAttributeEn,
}
