/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'

import { useI18n } from 'shared/i18n'
import { Nullable } from 'shared/typescript'

import { ORGANISATION_SEARCH_KEY } from '../../../organisation-search/organisation-search.key'
import { ABRCompany } from '../../model/abr-search-model'

type Props = {
  organizationName: string
  foundABROrganisations: ABRCompany[]
  onSelectAbrOrganisation: (organisationNumbers: {
    abn: Nullable<string>
    acn: Nullable<string>
  }) => void
}

export function AbrSearchResultTableAustralia({
  organizationName,
  foundABROrganisations,
  onSelectAbrOrganisation,
}: Props) {
  const t = useI18n([ORGANISATION_SEARCH_KEY])

  return (
    <>
      <div>
        <span className="text-lg font-bold text-tertiary-grey-800">
          {t('abrOnlySearchResult', {
            organizationName,
          })}
        </span>
        <span className="text-sm font-normal text-tertiary-grey-500 ml-2">
          {t('resultsNumber', {
            resultsNumber: foundABROrganisations.length,
          })}
        </span>
      </div>
      <div className="mt-4">
        <div className="flex bg-tertiary-grey-50 px-4 py-2 text-left border-b border-tertiary-grey-200">
          <div className="w-5/6 ">{t('resultsTable.nameColumnTitle')}</div>
          <div className="w-1/6">{t('resultsTable.abnAbcColumnTitle')}</div>
        </div>
        <div>
          {foundABROrganisations.map(organisation => (
            // eslint-disable-next-line jsx-a11y/no-static-element-interactions
            <div
              key={organisation.abn || organisation.acn}
              className="px-4 py-3 text-left flex flex-row border-b border-tertiary-grey-200 cursor-pointer hover:bg-tertiary-grey-100"
              onClick={() => {
                onSelectAbrOrganisation({
                  acn: organisation.acn ?? null,
                  abn: organisation.abn,
                })
              }}
            >
              <div className="inline-block w-5/6">{organisation.name}</div>
              <div className="inline-block w-1/6">
                {organisation.abn || organisation.acn}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}
