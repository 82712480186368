/* eslint-disable complexity */
import React, { useMemo, useState } from 'react'

import { Controller, useForm } from 'react-hook-form'

import { FrankieButton, FrankieIcon, FrankiePopover } from 'frankify/src'

import { ApplicantResponse } from 'entities/applicant'

import { TextAreaFormField } from 'shared/form'
import { useI18n } from 'shared/i18n'

import { APPLICANT_PERSONAL_INFO_KEYS } from '../../applicant-persona-info.keys'
import { applicantPersonalInfoEn } from '../../locale/applicant-personal-info.en'
import {
  OverrideResultForm,
  OverrideResultTypes,
  getChineseNameComparisonInfo,
} from '../../model/applicant-name-comparison.model'
import { nameComparisonQa } from '../../qa/applicant-personal-info.qa'

type Props = {
  applicantData: ApplicantResponse
}

export function ApplicantNameComparison({ applicantData }: Props) {
  const t = useI18n(APPLICANT_PERSONAL_INFO_KEYS, {
    keys: applicantPersonalInfoEn,
  })
  const [isExpanded, setIsExpended] = useState<boolean>(false)
  const [open, setOpen] = useState<boolean>(false)

  const {
    control,
    formState: { isValid },
    handleSubmit,
    watch,
    reset,
  } = useForm<OverrideResultForm>()

  const formWatch = watch()

  const data = useMemo(
    () => getChineseNameComparisonInfo(applicantData),
    [applicantData],
  )

  const handleFormSubmit = (formData: OverrideResultForm) => {}

  if (!data.comparisonInfo) return null

  return (
    <div className="text-tertiary-grey-700">
      <div className="text-xl font-[700] mt-8 mb-6">
        {t('nameComparison.title')}
      </div>

      <div className="text-tertiary-grey-400 border border-solid border-tertiary-grey-200 py-3">
        <div
          className={`flex justify-start items-center pl-3 ${
            isExpanded
              ? 'pb-4 border-b border-solid border-tertiary-grey-200'
              : ''
          }`}
        >
          <FrankieButton
            singleIcon={{
              name: isExpanded ? 'mdiChevronDown' : 'mdiChevronRight',
              size: 'sm',
            }}
            noStyles
            onClick={() => setIsExpended(prev => !prev)}
          />
          <FrankieIcon
            name="mdiIdeogramCjk"
            size="xs"
            className="mr-2 text-tertiary-grey-300"
          />
          <div className="text-sm"> {t('nameComparison.heading')}</div>
        </div>
        <div
          className={`px-4 text-xs text-neutral-100 font-medium ${
            isExpanded ? 'h-auto opacity-100 mt-6 pb-4' : 'h-0 opacity-0'
          } transition-opacity ease-in-out duration-300`}
        >
          <div className="flex justify-between items-center">
            <div className="basis-[40%] flex-col max-w-[500px] justify-center items-start text-center border border-solid border-tertiary-grey-200 rounded-sm">
              <div
                className={`basis[48%] h-[50px] flex justify-center items-center bg-tertiary-grey-100 flex-wrap ${
                  isExpanded
                    ? 'border-b border-solid border-tertiary-grey-200'
                    : ''
                }`}
              >
                <div className=" w-[40%] text-wrap">
                  {t('nameComparison.userDefinedName')}
                </div>
                <div>
                  <FrankieIcon name="mdiArrowRightThin" className="w-[20%]" />
                </div>
                <div className="w-[40%]">
                  {t('nameComparison.transliteration')}
                </div>
              </div>
              <div className="basis[48%] h-[50px] font-bold flex justify-center items-center rounded-sm">
                <div className="w-[50%] border-r border-solid border-tertiary-grey-200">
                  {data.comparisonInfo.userEnteredNameFromId}
                </div>
                <div className="w-[50%]">
                  {data.comparisonInfo.transliteratedName}
                </div>
              </div>
            </div>

            <div className="basis-[40%]  flex-col max-w-[500px] justify-center items-start text-center border border-solid border-tertiary-grey-200 border-t-0">
              <div className="basis[48%] h-[50px] flex justify-center items-center flex-wrap border-y border-solid border-tertiary-grey-200">
                <div className=" w-[40%] text-wrap">
                  {t('nameComparison.nameByCustomer')}
                </div>
              </div>
              <div className="basis[48%] h-[50px] font-bold flex justify-center items-center ">
                <div className="w-[50%]">{data.customerName}</div>
              </div>
            </div>
          </div>

          <div>
            <FrankiePopover
              onOpenChange={setOpen}
              open={open}
              popoverRest={{ placement: 'top-start' }}
              trigger={
                <FrankieButton
                  testId={{ button: nameComparisonQa.button }}
                  className="text-tertiary-grey-400 border border-solid border-neutral-100 font-semibold rounded-sm p-1 hover:text-primary-700 hover:border-primary-700 flex-grow-0 ml-auto mt-4"
                  intent="secondary"
                  onClick={() => setOpen(prev => !prev)}
                >
                  {t('nameComparison.overrideResult')}
                </FrankieButton>
              }
            >
              <form
                data-qa={nameComparisonQa.form}
                onSubmit={handleSubmit(handleFormSubmit)}
              >
                <div className="min-w-[340px] bg-mono-white p-4 border border-solid border-tertiary-grey-200 rounded-sm relative">
                  <FrankieButton
                    singleIcon={{
                      name: 'mdiClose',
                      size: 'sm',
                    }}
                    noStyles
                    onClick={() => setOpen(false)}
                    className="absolute top-2 right-2 text-tertiary-grey-300"
                  />
                  <div>
                    <div className="font-bold mt-2">
                      {t('nameComparison.overrideForm.changeMatchStatus')}
                    </div>
                    <Controller
                      render={({ field }) => (
                        <div className="flex justify-between items-center mb-3">
                          <FrankieButton
                            className={`basis-[46%]  border border-solid font-extrabold rounded-sm p-1 flex-grow-0 mt-2 ${
                              formWatch.status ===
                              OverrideResultTypes.ManualPass
                                ? 'text-primary-700 border-primary-700 bg-primary-50'
                                : 'text-tertiary-grey-600 border-neutral-100 hover:border-primary-700 hover:bg-mono-white hover:text-primary-700'
                            }`}
                            intent="secondary"
                            onClick={() =>
                              field.onChange(OverrideResultTypes.ManualPass)
                            }
                          >
                            {t('nameComparison.overrideForm.manualPass')}
                          </FrankieButton>
                          <FrankieButton
                            className={`basis-[46%]  border border-solid font-extrabold rounded-sm p-1 flex-grow-0 mt-2 ${
                              formWatch.status ===
                              OverrideResultTypes.ManualFail
                                ? 'text-primary-700 border-primary-700 bg-primary-50'
                                : 'text-tertiary-grey-600 border-neutral-100 hover:border-primary-700 hover:bg-mono-white hover:text-primary-700'
                            }`}
                            intent="secondary"
                            onClick={() =>
                              field.onChange(OverrideResultTypes.ManualFail)
                            }
                          >
                            {t('nameComparison.overrideForm.manualPass')}
                          </FrankieButton>
                        </div>
                      )}
                      name="status"
                      control={control}
                      rules={{ required: true }}
                    />
                  </div>
                  <TextAreaFormField
                    label={t('nameComparison.overrideForm.comment')}
                    testId={{ input: nameComparisonQa.textArea }}
                    name="comment"
                    placeholder={t(
                      'nameComparison.overrideForm.typeYourComment',
                    )}
                    control={control}
                    rules={{ required: true }}
                  />
                  <div className="flex justify-between items-center">
                    <FrankieButton
                      type="submit"
                      disabled={!isValid}
                      className="max-w-[70] font-extrabold rounded-sm p-1 flex-grow-0 mt-2"
                    >
                      {t('nameComparison.overrideForm.save')}
                    </FrankieButton>
                    <FrankieButton
                      className="text-tertiary-grey-600 border-0 font-extrabold rounded-sm p-1 hover:text-primary-700 hover:bg-mono-white flex-grow-0 mt-2"
                      intent="secondary"
                      testId={{ button: nameComparisonQa.closeBtn }}
                      type="reset"
                      onClick={() => {
                        reset()
                        setOpen(false)
                      }}
                    >
                      {t('nameComparison.overrideForm.cancel')}
                    </FrankieButton>
                  </div>
                </div>
              </form>
            </FrankiePopover>
          </div>
        </div>
      </div>
    </div>
  )
}
