import React, { useMemo, useState } from 'react'

import {
  DataGridPro,
  GridColDef,
  GridRowParams,
  GridSortModel,
} from '@mui/x-data-grid-pro'

import { ApplicantId } from 'entities/applicant'
import { useHasFeatureFlag } from 'entities/session'

import { WithProps } from 'shared/hoc'
import { useI18n } from 'shared/i18n'
import { useOverlay } from 'shared/overlay'

import { APPLICANT_PEP_SACTIONS_KEY } from '../../applicant-pep-sactions.key'
import { applicantPepSactionsEn } from '../../locale/applicant-pep-sactions.en'
import {
  PepSactionsMatchRecord,
  PepSactionsMatchRecordProperties,
} from '../../model/applicant-pep-sactions.model'
import { applicantPepSactionsDataGridQa } from '../../qa/applicant-pep-sactions.qa'
import { useApplicantPepSactionsGirdData } from '../../state/applicant-pep-sactions-query/applicant-pep-sactions.query'
import {
  pepSactionsDataGridSx,
  PepSactionsIssuesCell,
  PepSactionsSlot,
  PepSactionsMatchCell,
  PepSactionsTableHeader,
  SelectedEntityCell,
  SelectedEntityCellHeader,
  PepSactionsMatchStrengthCell,
  usePepSactionsMatchStatusLabel,
} from '../applicant-pep-sactions-data-grid-helper/applicant-pep-sactions-data-grid-helper'
import { ApplicantPepSactionsMatchStatusChange } from '../applicant-pep-sactions-match-status-change/applicant-pep-sactions-match-status-change'
import { ApplicantPepSactionsResult } from '../applicant-pep-sactions-result/applicant-pep-sactions-result'

type Props = {
  applicantId: ApplicantId
  className?: string
  testId?: { container: string }
}

const headerField = <T extends PepSactionsMatchRecordProperties>(name: T) =>
  name

const TABLE_MIN_WIDTH = 900
export const cellWidth = (width: number) => ({
  minWidth: width,
  flex: width / TABLE_MIN_WIDTH,
})

export function ApplicantPepSactionsDataGrid({
  applicantId,
  className = '',
  testId = { container: '' },
}: Props) {
  const t = useI18n(APPLICANT_PEP_SACTIONS_KEY, {
    keys: applicantPepSactionsEn,
  })

  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: headerField('matchStatus'), sort: 'desc' },
  ])
  const [createOverlay] = useOverlay()

  const { rows, selectedMatchIds, setSelectedMatchIds, loading } =
    useApplicantPepSactionsGirdData({
      applicantId,
    })

  const handleRowClick = ({ row }: GridRowParams<PepSactionsMatchRecord>) => {
    createOverlay(
      <ApplicantPepSactionsResult
        applicantId={applicantId}
        selectedGroupId={row.id}
      />,
      {
        className: '!p-0 ',
        closeButtonClassName: 'z-10 !top-[15px] !right-[15px]',
      },
    )
  }

  const { showAMLMatchStrength } = useHasFeatureFlag({
    showAMLMatchStrength: 'showAMLMatchStrength',
  })

  const { sortMatchStatusComparator } = usePepSactionsMatchStatusLabel()

  const columns = useMemo(
    () =>
      [
        {
          field: 'action',
          width: 100,
          renderHeader: WithProps(SelectedEntityCellHeader, {
            selectedMatchIds,
            rows,
            setSelectedMatchIds,
          }),
          renderCell: WithProps(SelectedEntityCell, {
            selectedMatchIds,
            setSelectedMatchIds,
          }),
          cellClassName: 'justify-start! ps-[2%]',
          headerAlign: 'left',
        },
        {
          field: headerField('name'),
          headerName: t('field.name'),
          cellClassName: 'font-bold flex-1',
          sortable: true,
          ...cellWidth(150),
        },
        {
          field: headerField('dob'),
          headerName: t('field.dob'),
          ...cellWidth(100),
        },
        {
          field: headerField('countries'),
          headerName: t('field.countries'),
          cellClassName: 'font-medium',
          ...cellWidth(100),
        },
        {
          field: headerField('issues'),
          headerName: t('field.issues'),
          renderCell: PepSactionsIssuesCell,
          ...cellWidth(100),
        },
        {
          field: headerField('matchScore'),
          headerName: t('field.matchStrength'),
          renderCell: PepSactionsMatchStrengthCell,
          ...cellWidth(125),
          sortable: true,
        },
        {
          field: headerField('matchStatus'),
          ...cellWidth(125),
          headerName: t('field.matchStatus'),
          renderCell: PepSactionsMatchCell,
          sortComparator: sortMatchStatusComparator,
          sortable: true,
        },
      ] as GridColDef<PepSactionsMatchRecord>[],
    [rows, selectedMatchIds, setSelectedMatchIds, t],
  )

  return (
    <div className={className} data-qa={testId.container}>
      <div
        className="my-2 flex justify-between w-full"
        data-qa={applicantPepSactionsDataGridQa.applicationMatchStatusContainer}
      >
        <div>{`${rows.length} ${t('results')}`}</div>
        <div className="flex flex-none gap-4 items-center">
          {`${selectedMatchIds.length} ${t(
            selectedMatchIds.length > 1 ? 'itemsSelected' : 'itemSelected',
          )}`}
          <ApplicantPepSactionsMatchStatusChange
            applicantId={applicantId}
            groupIds={selectedMatchIds}
            disabled={!selectedMatchIds.length}
          />
        </div>
      </div>
      <div
        className="w-full overflow-x-auto"
        data-qa={applicantPepSactionsDataGridQa.dataGridProContainer}
      >
        <DataGridPro
          className="[&_.MuiDataGrid-row]:border-tertiary-grey-200"
          columnVisibilityModel={{
            // Hide columns matchScore if showAMLMatchStrength is false
            matchScore: showAMLMatchStrength,
          }}
          rows={rows}
          slots={{
            loadingOverlay: WithProps(PepSactionsSlot, {
              loadingOverlay: true,
            }),
            noRowsOverlay: WithProps(PepSactionsSlot, { noRowsOverlay: true }),
          }}
          columns={columns.map(item => ({
            sortable: false,
            hideSortIcons: true,
            resizable: false,
            disableColumnMenu: true,
            disableReorder: true,
            headerClassName:
              '!p-0 text-xs font-medium text-tertiary-grey-500 !outline-none ',
            renderHeader: WithProps(PepSactionsTableHeader, {
              sortModel,
            }),
            ...item,
          }))}
          onRowClick={handleRowClick}
          getRowHeight={() => 'auto'}
          getCellClassName={() =>
            '!outline-none !border-transparent !items-start !py-3 !px-0 break-words cursor-pointer'
          }
          loading={loading}
          sortModel={sortModel}
          onSortModelChange={setSortModel}
          isRowSelectable={() => false}
          isCellEditable={() => false}
          disableRowSelectionOnClick
          disableColumnSelector
          disableColumnFilter
          disableColumnMenu
          disableDensitySelector
          showCellVerticalBorder={false}
          showColumnVerticalBorder={false}
          hideFooterPagination
          hideFooter
          hideFooterSelectedRowCount
          sx={pepSactionsDataGridSx}
        />
      </div>
    </div>
  )
}
