import React, { useMemo } from 'react'

import { GridRenderCellParams } from '@mui/x-data-grid-pro'

import { FrankieIcon, FrankieTooltip } from 'frankify/src'

import { blocklistTableEn } from 'features/blocklist-table/locale/blocklist-table.en'

import {
  BlocklistAttributeTypes,
  BlocklistReasonTypes,
} from 'entities/applicant'

import {
  DateFormatTypes,
  formatDate,
  formatDistanceToNow,
} from 'shared/date-time'
import { I18nFunction, useI18n } from 'shared/i18n'

import { BLOCKLIST_TABLE_KEY } from '../../blocklist-table.key'
import {
  blocklistAttributeData,
  blocklistReasonData,
} from '../../model/blocklist-config.model'
import { BlocklistData } from '../../model/blocklist.model'
import { blocklistAtrCellQa } from '../../qa/blocklist-table.qa'

const wrapperClasses = 'flex items-center w-full h-full gap-4 px-4 py-3'
const formattedText = (text?: string) =>
  text
    ?.split('_')
    ?.map(str => str.charAt(0).toUpperCase() + str.slice(1))
    ?.join(' ') ?? ''

export type Props = GridRenderCellParams<BlocklistData> & {
  // eslint-disable-next-line react/no-unused-prop-types
  t: I18nFunction<typeof blocklistTableEn>
}

export function BlocklistAttrFormattedText({
  attribute,
  className = '',
}: {
  attribute: BlocklistAttributeTypes
  className?: string
}) {
  const t = useI18n([BLOCKLIST_TABLE_KEY], { keys: blocklistTableEn })

  const data = Object.hasOwn(blocklistAttributeData, attribute)
    ? blocklistAttributeData[attribute]
    : null

  return (
    <span className={className}>
      {data ? t(data.tShortKey ?? data.tKey) : formattedText(attribute)}
    </span>
  )
}

export function BlocklistReasonFormattedText({
  reason,
  className = '',
}: {
  reason: BlocklistReasonTypes
  className?: string
}) {
  const t = useI18n([BLOCKLIST_TABLE_KEY], { keys: blocklistTableEn })

  const reasonText = Object.hasOwn(blocklistReasonData, reason)
    ? t(blocklistReasonData[reason].tKey)
    : formattedText(reason)

  return <span className={className}>{reasonText}</span>
}

export function BlocklistAttributeCell({ row, t }: Props) {
  const data = Object.hasOwn(blocklistAttributeData, row.attribute)
    ? blocklistAttributeData[row.attribute]
    : null

  return (
    <div className={wrapperClasses} data-qa={blocklistAtrCellQa.main}>
      {data?.iconName && (
        <FrankieIcon
          className="text-tertiary-grey-400"
          name={data.iconName}
          size="md"
          testId={{ icon: blocklistAtrCellQa.icon }}
        />
      )}
      <BlocklistAttrFormattedText
        className="font-bold"
        attribute={row.attribute}
        data-qa={blocklistAtrCellQa.content}
      />
    </div>
  )
}

export function BlocklistDescriptionCell({ row }: Props) {
  return (
    <div className={wrapperClasses} data-qa={blocklistAtrCellQa.main}>
      <span className="text-neutral-90 whitespace-normal leading-tight max-h-35 overflow-hidden text-ellipsis line-clamp-2 box-content">
        {row.description}
      </span>
    </div>
  )
}

export function BlocklistReasonCell({ row, t }: Props) {
  return (
    <div className={wrapperClasses} data-qa={blocklistAtrCellQa.reasonCellMain}>
      <BlocklistReasonFormattedText
        className="bg-mono-100 text-mono-white px-1 rounded-sm font-bold"
        reason={row.reason}
      />
    </div>
  )
}

export function BlocklistAddedCell({ row, t }: Props) {
  return (
    <div className={wrapperClasses} data-qa={blocklistAtrCellQa.addCellMain}>
      <span className="text-neutral-90">
        {t('format.ago', {
          date: formatDistanceToNow(new Date(row.createdDate)),
        })}
      </span>
    </div>
  )
}

export function BlocklistUpdateOnCell({ row }: Props) {
  return (
    <div className={wrapperClasses} data-qa={blocklistAtrCellQa.updateCellMain}>
      <span className="text-neutral-90">
        {formatDate(row.lastUpdated, DateFormatTypes.DateNumbers)}
      </span>
    </div>
  )
}

export function BlocklistAddedByCell({ row, t }: Props) {
  const { initials, tooltipText, isResolved, matchToBeResolved } =
    useMemo(() => {
      const { duplicateBlocklistResolved, addedBy } = row

      const splitName = addedBy?.split(' ') || ['']

      const initials = `${splitName[0].charAt(0)}${
        splitName.length > 1 ? splitName[splitName.length - 1].charAt(0) : ''
      }`

      let tooltipText = addedBy || ''
      let isResolved = true

      if (duplicateBlocklistResolved !== null) {
        isResolved = false
        tooltipText = t(
          duplicateBlocklistResolved
            ? 'tooltip.positiveMatch'
            : 'tooltip.matchToResolve',
        )
      }

      const matchToBeResolved = duplicateBlocklistResolved === false

      return {
        initials,
        matchToBeResolved,
        tooltipText,
        isResolved,
      }
    }, [row, t])

  return (
    <div className={wrapperClasses} data-qa={blocklistAtrCellQa.addByCellMain}>
      <FrankieTooltip
        position="top"
        title={tooltipText}
        testId={{ tooltip: blocklistAtrCellQa.addByTooltip }}
      >
        <div>
          {isResolved ? (
            <div className="ms-1 text-center font-bold p-1 rounded-full h-[27px] w-[27px] text-mono-white bg-tertiary-purple-500 uppercase">
              {initials}
            </div>
          ) : (
            <FrankieIcon
              className={
                matchToBeResolved ? 'text-attention' : 'text-tertiary-grey-500'
              }
              size="lg"
              name={
                matchToBeResolved
                  ? 'mdiAlertCircle'
                  : 'mdiCloseBoxMultipleOutline'
              }
            />
          )}
        </div>
      </FrankieTooltip>
    </div>
  )
}
