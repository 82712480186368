export const fraudCheckEn = {
  email: {
    title: 'Email',
    emailAddress: 'Email Address',
    viewRulesCta: 'View rules',
  },
  phone: {
    title: 'Phone',
    phoneNumber: 'Phone Number',
    lineType: 'Line Type',
    carrier: 'Carrier',
    phoneCountry: 'Phone Country',
    viewRulesCta: 'View rules',
  },
  device: {
    title: 'Device',
    deviceModel: 'Device Model',
    trueOS: 'True OS',
    ipAddress: 'IP Address',
    trueIPAddress: 'True IP Address',
    browser: 'Browser',
    viewRulesCta: 'View rules',
  },
  iplocation: {
    title: 'IP Location',
    vpn: 'VPN',
    city: 'City',
    region: 'Region',
    country: 'Country',
    latitude: 'Latitude',
    longitude: 'Longitude',
    viewRulesCta: 'View rules',
    mapPlaceholder: {
      title: 'Location map unavailable',
      description: 'Unable to display map due to the missing location details.',
    },
  },
  result: {
    high: 'High risk',
    low: 'Low risk',
    medium: 'Medium risk',
    veryHigh: 'Very high risk',
    unchecked: 'Unchecked',
    unknown: 'Unchecked',
  },
  checkRules: {
    executed: 'EXECUTED RULES',
    shadow: 'SHADOW RULES',
    email: 'Email check results',
    phone: 'Phone check results',
    device: 'Device check results',
    iplocation: 'IP location check results',
    placeholder: {
      title: 'Risk rules unavailable',
      description:
        'No risk rules were executed as no rules were set up or none match to provide a risk level.',
    },
  },
}
