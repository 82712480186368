import { useLocation } from 'react-router-dom'

import { f2Paths, newPaths, paths } from '../model/router'
import { useApplicantRoute } from '../state/applicant-location/applicant-route'

type RouteKey =
  | keyof typeof f2Paths
  | keyof typeof paths
  | keyof typeof newPaths
type Args = {
  pathKey: RouteKey
  entityId: string
}

export const useRouteMatcher = ({ pathKey, entityId }: Args) => {
  const { generateRoute } = useApplicantRoute()
  const location = useLocation()
  const { pathname } = location
  const path = generateRoute({
    routeKey: pathKey,
    overrideParams: { entityId },
  })

  return pathname === path
}
