import React from 'react'

export function AppInfo() {
  if (!['local', 'dev', 'qa'].includes(process.env.ENV_NAME as string)) {
    return null
  }

  return (
    <span className="self-start pl-[6px] text-xs text-mono-black">
      {process.env.APP_VERSION}
    </span>
  )
}
