import React, { useState } from 'react'

import {
  DataGridPro,
  GridColDef,
  GridExpandMoreIcon,
  GridSortModel,
} from '@mui/x-data-grid-pro'

import { FrankieButton, FrankiePopover } from 'frankify/src'

import { ApplicantId } from 'entities/applicant'

import { DateFormatTypes, dateSorter, formatDate } from 'shared/date-time'
import { WithProps } from 'shared/hoc'
import { useI18n } from 'shared/i18n'

import { APPLICANT_FRAUD_CHECK_KEY } from '../../applicant-fraud-check.key'
import { applicantFraudCheckEn } from '../../locale/applicant-fraud-check.en'
import {
  FraudListData,
  FraudListDataKeys,
  IMatchStatus,
} from '../../model/applicant-fraud-check.model'
import { applicantFraudCheckTableQa } from '../../qa/applicant-fraud-check.qa'
import { useApplicantFraudCheckTableData } from '../../state/applicant-fraud-check-state/applicant-fraud-check-state'
import {
  FraudCheckTableHeader,
  FraudMatchTypeCell,
  MatchStatusTypeCell,
  SelectedEntityCell,
  SelectedEntityCellHeader,
} from '../applicant-fraud-check-cell/applicant-fraud-check-cell'
import { FraudStatusChangeForm } from '../applicant-fraud-check-status-change/applicant-fraud-check-status-change'
import {
  FraudCheckLoadingOverlay,
  FraudCheckNoRowOverlay,
  FraudCheckTableDetailPanel,
} from '../fraud-check-table-slot/fraud-check-table-slot'

type Props = {
  applicantId: ApplicantId
  testId?: { container: string }
}
const headerField = <T extends FraudListDataKeys>(name: T) => name

const TABLE_MIN_WIDTH = 960
export const cellWidth = (width: number) => ({
  minWidth: width,
  flex: width / TABLE_MIN_WIDTH,
})

export function ApplicantFraudCheckTable({
  applicantId,
  testId = { container: '' },
}: Props) {
  const [fraudListIds, setFraudListIds] = useState<string[]>([])
  const [open, setOpen] = useState(false)
  const [sortModel, setSortModel] = useState<GridSortModel>([])

  const t = useI18n([APPLICANT_FRAUD_CHECK_KEY], {
    keys: applicantFraudCheckEn,
  })

  const { rows, isFetching: loading } = useApplicantFraudCheckTableData({
    applicantId,
  })

  const columns: GridColDef<FraudListData>[] = [
    {
      field: headerField('fraudType'),
      headerName: t('header.fraudType'),
      cellClassName: 'font-bold flex-1',
      width: 180,
    },
    {
      field: headerField('matchType'),
      headerName: t('header.matchType'),
      renderCell: FraudMatchTypeCell,
      sortable: false,
      ...cellWidth(40),
    },
    {
      field: headerField('matchList'),
      headerName: t('header.matchList'),
      sortable: false,
      ...cellWidth(56),
    },
    {
      field: headerField('fraudSubject'),
      headerName: t('header.fraudSubject'),
      ...cellWidth(56),
    },
    {
      field: headerField('reportedBy'),
      headerName: t('header.reportedBy'),
      ...cellWidth(80),
    },
    {
      field: headerField('date'),
      headerName: t('header.date'),
      sortComparator: dateSorter,
      valueFormatter: ({ value }) =>
        value ? formatDate(value as string, DateFormatTypes.DateNumbers) : '',
      ...cellWidth(56),
    },
    {
      field: headerField('matchStatus'),
      width: 150,
      headerName: t('header.matchStatus'),
      sortComparator: (v1: IMatchStatus, v2: IMatchStatus) =>
        v1.value.localeCompare(v2.value),
      renderCell: WithProps(MatchStatusTypeCell, { applicantId }),
    },
    {
      field: 'action',
      width: 100,
      renderCell: WithProps(SelectedEntityCell, {
        fraudListIds,
        setFraudListIds,
      }),
      align: 'right',
      headerAlign: 'right',
      sortable: false,
      renderHeader: WithProps(SelectedEntityCellHeader, {
        fraudListIds,
        rows,
        setFraudListIds,
      }),
    },
  ]

  return (
    <>
      <div
        className="pl-16 my-2 pr-[165px] flex justify-between w-full"
        data-qa={testId.container}
      >
        <div>{`${rows.length} ${t('results')}`}</div>
        <div className="flex flex-none gap-4 items-center">
          {`${fraudListIds.length} ${t('itemSelected')}`}
          <FrankiePopover
            open={open}
            onOpenChange={setOpen}
            trigger={
              <FrankieButton
                testId={{ button: applicantFraudCheckTableQa.button }}
                className="!w-[150px] !p-1 !bg-mono-100 disabled:!bg-mono-40 !text-mono-white !outline-mono-50"
                disabled={!fraudListIds.length}
                onClick={() => setOpen(true)}
              >
                {t('changeMatchStatus')}
              </FrankieButton>
            }
          >
            <FraudStatusChangeForm
              applicantId={applicantId}
              fraudListIds={fraudListIds}
              checkId={rows[0]?.checkId}
              handleClose={() => setOpen(false)}
            />
          </FrankiePopover>
        </div>
      </div>
      <div className="px-10 w-full overflow-x-auto">
        <DataGridPro
          rows={rows}
          slots={{
            loadingOverlay: FraudCheckLoadingOverlay,
            noRowsOverlay: WithProps(FraudCheckNoRowOverlay, { applicantId }),
            detailPanelExpandIcon: GridExpandMoreIcon,
            detailPanelCollapseIcon: GridExpandMoreIcon,
          }}
          columns={columns.map(item => ({
            sortable: true,
            hideSortIcons: true,
            resizable: false,
            disableColumnMenu: true,
            disableReorder: true,
            headerClassName:
              '!p-0 text-xs font-medium text-tertiary-grey-500 !outline-none',
            renderHeader: WithProps(FraudCheckTableHeader, {
              sortModel,
            }),
            ...item,
          }))}
          getDetailPanelContent={WithProps(FraudCheckTableDetailPanel, { t })}
          getDetailPanelHeight={() => 'auto'}
          getRowHeight={() => 'auto'}
          className="[&_.MuiDataGrid-virtualScrollerContent]:border-neutral-30"
          getCellClassName={() =>
            '!outline-none !border-neutral-30 !py-3 !px-0 break-words'
          }
          getRowClassName={() => 'hover:!bg-transparent !min-h-[60px]'}
          loading={loading}
          sortModel={sortModel}
          onSortModelChange={setSortModel}
          isRowSelectable={() => false}
          isCellEditable={() => false}
          disableRowSelectionOnClick
          disableColumnSelector
          disableColumnFilter
          disableColumnMenu
          disableDensitySelector
          showCellVerticalBorder={false}
          showColumnVerticalBorder={false}
          hideFooterPagination
          hideFooter
          hideFooterSelectedRowCount
          sx={{
            '.MuiDataGrid-columnSeparator': {
              display: 'none',
            },
            '& .MuiDataGrid-virtualScroller': {
              minHeight: 'calc(100vh - 525px)',
              maxHeight: 'calc(100vh - 325px)',
              overflowX: 'hidden',
            },
            '& .MuiDataGrid-virtualScrollerContent': {
              border: '1px solid',
              borderBottom: 'none',
            },
            '& .MuiDataGrid-cell:first-of-type': {
              justifyContent: 'center',
            },
            '& .MuiDataGrid-columnHeader:first-of-type': {
              justifyContent: 'center',
            },
            '&.MuiDataGrid-root': {
              border: 'none',
              minWidth: 960,
              overflow: 'hidden',
            },
            '& .MuiDataGrid-columnHeaderTitleContainerContent': {
              height: '100%',
            },
            '& .MuiDataGrid-columnHeaders': {
              minHeight: 'unset !important',
              maxHeight: '40px !important',
              lineHeight: 'unset !important',
              borderBottom: '1px solid blue',
            },
            '.MuiDataGrid-columnHeaderTitleContainer': {
              display: 'inline-block !important',
              flex: 'none !important',
            },
            '.MuiDataGrid-cell': {
              minHeight: 'unset !important',
              maxHeight: 'unset !important',
              alignItems: 'center',
              lineHeight: 'unset !important',
            },
            '& .MuiDataGrid-detailPanelToggleCell': {
              opacity: 0.7,
            },
            "& .MuiDataGrid-detailPanelToggleCell[aria-label='Expand']": {
              transform: 'rotate(-90deg)',
            },
            '& .MuiDataGrid-row.Mui-hovered, .MuiDataGrid-row:hover': {
              backgroundColor: 'transparent',
            },
          }}
        />
      </div>
    </>
  )
}
