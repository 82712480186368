export const globalSearchQa = {
  searchInput: 'global-search_search-placeholder',
  searchBadge: 'global-search_search-badge',
  cta: {
    searchClear: 'global-search_search-clear-cta',
    recentSearch: 'global-search_recent-search-cta',
    feedback: 'global-search_give-feedback-cta',
    docs: 'global-search_read-docs-cta',
    searchBy: {
      IndividualName: 'global-search_search-by-cta__individual-name',
      OrganisationName: 'global-search_search-by-cta__organisation-name',
      CustomerId: 'global-search_search-by-cta__customer-id',
      FrankieId: 'global-search_search-by-cta__frankie-id',
      AbnOrAcn: 'global-search_search-by-cta__abn-or-acn',
    },
  },
}
