import { useQuery } from '@tanstack/react-query'

import { isNotEmptyString } from 'shared/typescript'

import { addressApi } from '../api/address.api'

export const GetAddressQueryKey = ['get-address']

export const useGetDetailAddress = (googleAddressId: string) =>
  useQuery({
    queryKey: [GetAddressQueryKey, googleAddressId],
    queryFn: async () => {
      const { data } = await addressApi.getDetailsAddress(googleAddressId)
      return data
    },
    enabled: isNotEmptyString(googleAddressId),
  })
