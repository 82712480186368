import React, { useEffect, useState } from 'react'

import { FrankieIcon, FrankieTableHeaderCellSortValues } from 'frankify/src'

import {
  UserTableRow,
  UserTableRowHeader,
  IUser,
  UserSortByTypes,
  UserId,
} from 'entities/user'

import { sortArrOfObjs } from 'shared/array'
import { useI18n } from 'shared/i18n'
import { notification, notificationQa } from 'shared/notification'
import { useOverlay } from 'shared/overlay'
import { TrackingEventsTypes, trackingManager } from 'shared/tracking'

import { userManagementQa } from '../../qa/user-management.qa'
import { useChangeUserLockMutation } from '../../state/user-list/change-user-lock.mutation'
import { USER_MANAGEMENT_KEY } from '../../user-management.key'
import { DeleteUserModal } from '../delete-user-modal/delete-user-modal'

type Sorts = Record<UserSortByTypes, FrankieTableHeaderCellSortValues>

const defaultSorts: Sorts = {
  [UserSortByTypes.Name]: 'asc',
  [UserSortByTypes.LastLoginDate]: 'off',
  [UserSortByTypes.CreatedDate]: 'off',
}

type Props = {
  users: IUser[]
  onResetUserPassword: (email: string) => Promise<void>
  onEditUser: (user: IUser) => void
  canRemove: boolean
  canEdit: boolean
  canLock: boolean
  canSeeRole: boolean
}

export function UserTable({
  users,
  onResetUserPassword,
  onEditUser,
  canLock,
  canRemove,
  canEdit,
  canSeeRole,
}: Props) {
  const t = useI18n([USER_MANAGEMENT_KEY])
  const [sorts, setSorts] = useState<Sorts>(defaultSorts)
  const [usersSorted, setUsersSorted] = useState<IUser[]>(users)
  const { mutateAsync: changeUserLock } = useChangeUserLockMutation()

  const [createOverlay, closeOverlay] = useOverlay()

  useEffect(() => {
    trackingManager.track(TrackingEventsTypes.UserManagementSettings)
  }, [])

  useEffect(() => {
    if (sorts[UserSortByTypes.Name] !== 'off') {
      setUsersSorted(
        sortArrOfObjs(
          users,
          sorts[UserSortByTypes.Name],
          user => user.realname,
        ),
      )
      return
    }
    if (sorts[UserSortByTypes.LastLoginDate] !== 'off') {
      setUsersSorted(
        sortArrOfObjs(
          users,
          sorts[UserSortByTypes.LastLoginDate],
          user => user.loggedAt?.date || '',
        ),
      )
      return
    }
    if (sorts[UserSortByTypes.CreatedDate] !== 'off') {
      setUsersSorted(
        sortArrOfObjs(
          users,
          sorts[UserSortByTypes.CreatedDate],
          user => user.createdAt?.date || '',
        ),
      )
      return
    }
    setUsersSorted(users)
  }, [sorts, users])

  const handleChangeSort = (sortBy: UserSortByTypes) => () =>
    // 1. turn off all sorts
    // 2. if sorting was disabled -- set asc, otherwise toggle asc/desc
    setSorts({
      ...defaultSorts,
      [sortBy]: sorts[sortBy] === 'asc' ? 'desc' : 'asc',
    })

  const handleChangeUserLocked =
    (id: UserId, locked: boolean, name = '') =>
    async () => {
      if (!canLock) {
        return
      }
      await changeUserLock({ id, locked })

      notification.success(
        t(
          locked
            ? 'usersTable.notification.locked'
            : 'usersTable.notification.unlocked',
          { name },
        ),
      )
    }

  const handleResetPassword = (email: string, name: string) => async () => {
    await onResetUserPassword(email)

    notification.success(t('usersTable.notification.resetPassword', { name }), {
      icon: (
        <FrankieIcon
          testId={{ icon: notificationQa.successIcon }}
          size="sm"
          className="w-8 h-8 flex flex-initial justify-center items-center bg-tertiary-green-600 rounded-full"
          name="mdiLockReset"
        />
      ),
    })
  }

  const handleRemove = (userId: string, name: string) => {
    if (!canRemove) {
      return
    }

    createOverlay(
      <DeleteUserModal
        onCancel={closeOverlay}
        userId={userId}
        userName={name}
      />,
      {
        className: 'p-8 w-[448px]',
      },
    )
  }

  const handleEdit = (userId: string) => {
    if (!canEdit) {
      return
    }

    const userToUpdate = usersSorted.find(user => user.id === userId)

    if (!userToUpdate) return

    onEditUser(userToUpdate)
  }

  return (
    <div>
      <div className="pr-8">
        <UserTableRowHeader
          sorts={sorts}
          onChangeSort={handleChangeSort}
          canSeeRole={canSeeRole}
        />
      </div>
      <div
        data-qa={userManagementQa.userTable_userList}
        className="h-[calc(100vh-364px)] pb-[116px] mr-2 pr-4 overflow-y-auto scrollbar scrollbar-gutter"
      >
        {usersSorted.map(user => (
          <UserTableRow
            id={user.id}
            key={user.id}
            name={user.realname || ''}
            isGlobal={false}
            email={user.email || ''}
            hasMfa={!!user.mfaType}
            roles={user.roles || []}
            isLocked={!!user.locked}
            lastLoginDate={user.loggedAt?.date || null}
            createdDate={user.createdAt?.date || ''}
            onRemove={handleRemove}
            onEdit={handleEdit}
            onResetPassword={handleResetPassword(
              user.email || '',
              user.realname || '',
            )}
            onChangeLocked={handleChangeUserLocked(
              user.id,
              !user.locked,
              user.realname,
            )}
            canEdit={canEdit}
            canRemove={canRemove}
            canChangeLocked={canLock}
            canSeeRole={canSeeRole}
          />
        ))}
      </div>
    </div>
  )
}
