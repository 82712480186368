import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'

import classNames from 'classnames'
import { Document, Page } from 'react-pdf'

import { FrankieButton, FrankieIcon, FrankieLoader } from 'frankify/src'

import { DocumentAttachment } from 'entities/entity'

import { DateFormatTypes, formatDate } from 'shared/date-time'
import { blobToBase64, getDataFileUrl } from 'shared/file'
import { useI18n } from 'shared/i18n'
import { notification } from 'shared/notification'
import { useOverlay } from 'shared/overlay'

import { DOCUMENT_F2_KEY, documentF2En } from '../../locale/document-f2.en'
import {
  defaultSupportedFileTypes,
  SupportedFileTypes,
} from '../../model/document.model'
import { PdfPreview } from '../pdf-preview/pdf-preview'

const FILENAME_MAX_LENGTH = 80

export type UploadedDocumentData = {
  fileUploadUuid: string
  mimeType: string
  scanCreated: string
  scanName: string
  dataUri: string
  size: number
}

type Props = {
  className?: string
  name?: string
  id?: string
  uploadedOn?: string
  supportedFileTypes?: SupportedFileTypes[]
  errorText?: string
  showFile?: boolean
  onChange: (data: UploadedDocumentData) => void
  handleFocus?: () => void
  error?: boolean
  required?: boolean
  value?: DocumentAttachment
}

/**
 * Document Upload
 * @description Supports only pdf and images upload
 */
export const DocumentUploadF2 = forwardRef(
  // eslint-disable-next-line complexity
  (
    {
      className = '',
      uploadedOn,
      supportedFileTypes = defaultSupportedFileTypes,
      errorText,
      onChange,
      handleFocus = () => {},
      name,
      id,
      error = false,
      showFile = true,
      required,
      value,
    }: Props,
    ref: React.ForwardedRef<HTMLInputElement>,
  ) => {
    const innerRef = useRef<HTMLInputElement>(null)

    useImperativeHandle(ref, () => innerRef.current!)
    const t = useI18n([DOCUMENT_F2_KEY], { keys: documentF2En })

    const [fileUrl, setFileUrl] = useState<string | null>()
    const [isFileImage, setIsFileImage] = useState(false)
    const [fileName, setFileName] = useState<string>()
    const [createOverlay] = useOverlay()

    const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
      const { files } = e.target

      if (!files?.length) return

      const file = files[0]
      const fileType = file.type
      const fileName = file.name

      // File Type Check
      if (!supportedFileTypes.some(type => RegExp(type).test(fileType))) {
        notification.error(
          t('message.invalidFileType', {
            fileTypes: supportedFileTypes
              .map(item => item.split('/')[1])
              .join(', '),
          }),
        )

        return
      }

      // Unsafe File name check
      if (
        fileName.length > FILENAME_MAX_LENGTH ||
        /[<>\\/'"`]/.test(fileName)
      ) {
        notification.error(
          t('message.invalidFileName', { maxLength: FILENAME_MAX_LENGTH }),
        )

        return
      }

      const { type, name } = file
      const newDate = new Date()

      setFileName(name)
      const dataUri = await blobToBase64(file)
      onChange({
        fileUploadUuid: '',
        mimeType: type,
        scanName: name,
        scanCreated: newDate.toISOString(),
        dataUri,
        size: file.size,
      })
    }

    const handleView = () => {
      if (!fileUrl) return
      createOverlay(
        <div className="flex flex-col gap-2 -mt-2 tablet:min-w-[610px]">
          <div className="text-tertiary-grey-800 text-xl font-bold">
            {name ?? fileName ?? t('document')}
          </div>

          <div className="flex rounded-md flex-grow-1 items-center justify-center">
            {isFileImage ? (
              <img
                data-hj-suppress
                className="object-contain h-[360px]"
                src={fileUrl}
                alt="uploaded-file"
              />
            ) : (
              <div
                data-hj-suppress
                className="max-h-[600px] overflow-y-auto border border-tertiary-grey-100 rounded-md"
              >
                <PdfPreview
                  url={fileUrl}
                  pageProps={{
                    width: 800,
                  }}
                />
              </div>
            )}
          </div>

          <div className="text-sm mt-2 -mb-2 font-normal text-tertiary-grey-500">
            {t('uploadedOn', {
              date: formatDate(
                uploadedOn ?? new Date().toUTCString(),
                DateFormatTypes.fullDateWithTime,
              ),
            })}
          </div>
        </div>,
        {
          closeButtonClassName: '!top-6 !right-6',
        },
      )
    }

    // use effect to set file url if value is present
    useEffect(() => {
      if (value?.data) {
        if ('base64' in value.data && value.mimeType) {
          const fileString = value.data.base64
          setFileUrl(
            getDataFileUrl(
              value.mimeType,
              fileString.includes('base64,')
                ? fileString.split('base64,')[1]
                : fileString,
            ),
          )
        }
        if (value.mimeType?.split('/').includes('pdf')) {
          setIsFileImage(false)
        } else {
          setIsFileImage(true)
        }
      }
    }, [value])

    return (
      <div className="flex flex-col">
        <div
          className={classNames(
            className,
            'relative w-full min-w-[200px] h-[150px] border rounded-sm',
            fileUrl
              ? 'border-solid border-neutral-40'
              : 'border-dashed border-tertiary-grey-200',
            error
              ? 'border-tertiary-red-500 !border-solid'
              : 'hover:border-primary-500 hover:border-solid',
          )}
        >
          <FrankieLoader
            loading={false}
            label={fileUrl ? t('replacing') : t('uploading')}
          >
            <input
              className="absolute inset-0 opacity-0 z-[2] cursor-pointer"
              type="file"
              ref={innerRef}
              multiple={false}
              name={name}
              id={id}
              onFocus={handleFocus}
              onChange={handleFileUpload}
              accept={supportedFileTypes.join(',')}
              onClick={e => {
                e.currentTarget.value = '' // to reset the file input so it can same file again
              }}
            />

            {fileUrl && showFile ? (
              <div
                className="group/uploaded flex items-center justify-center absolute inset-0 z-[3] bg-neutral-40 overflow-hidden"
                data-hj-suppress
                title="uploaded-file"
              >
                {isFileImage ? (
                  <img
                    className="object-contain h-full"
                    src={fileUrl}
                    alt="uploaded-file"
                  />
                ) : (
                  <Document
                    file={fileUrl}
                    className="h-[148px] overflow-hidden"
                  >
                    <Page
                      pageIndex={0}
                      width={283}
                      renderAnnotationLayer={false}
                      renderTextLayer={false}
                    />
                  </Document>
                )}
                <div
                  className="absolute inset-0 p-2 flex flex-col justify-center gap-4 items-center bg-mono-black bg-opacity-60
            opacity-0 group-hover/uploaded:opacity-100
            transition-opacity duration-300 ease-in-out transform"
                >
                  <FrankieButton
                    intent="primary"
                    className="!w-3/5"
                    size="sm"
                    endIcon={{
                      name: 'mdiArrowExpand',
                      size: 'xs',
                      className: 'ps-2',
                    }}
                    onClick={handleView}
                  >
                    {t('view')}
                  </FrankieButton>
                  <FrankieButton
                    onClick={() => innerRef.current?.click()}
                    intent="primary"
                    size="sm"
                    className="!bg-tertiary-grey-700 !w-3/5"
                  >
                    {t('replace')}
                  </FrankieButton>
                </div>
              </div>
            ) : (
              <div className="absolute text-tertiary-grey-400 inset-0 z-[1] p-4 flex flex-col items-center justify-evenly">
                <FrankieIcon
                  size="lg"
                  className="scale-125"
                  name="mdiCloudUploadOutline"
                />
                <p className="text-center text-sm font-medium">
                  <b>{t('placeholder1')}</b>
                  {t('placeholder2')}
                  {!required && t('optional')}
                </p>
                <p className="text-center text-xs font-medium">
                  {t('placeholder3')}
                </p>
              </div>
            )}
          </FrankieLoader>
        </div>
        {errorText && error && (
          <div className="text-tertiary-red-600 text-start text-mono-70 pt-2 text-sm">
            {errorText}
          </div>
        )}
      </div>
    )
  },
)
