import { useEffect } from 'react'

import { useMutation } from '@tanstack/react-query'

import { ApplicantId } from 'entities/applicant'

import { ErrorResponse } from 'shared/http'
import { useI18n } from 'shared/i18n'
import { notification } from 'shared/notification'

import { applicantBiometricsApi } from '../../api/applicant-biometrics-ocr.api'
import { APPLICANT_BIOMETRICS_OCR_KEY } from '../../applicant-biometrics-ocr.key'
import { applicantBiometricsOcrEn } from '../../locale/applicant-biometrics-ocr.en'
import { ApplicantIdvLinkPayload } from '../../model/applicant-biometrics-ocr.model'

type MutateProps = {
  payload: ApplicantIdvLinkPayload
  successMessage?: string
}

type Args = {
  applicantId: ApplicantId
  enabled?: boolean
}

export const useApplicantIdvLinkMutation = ({
  applicantId,
  enabled = true,
}: Args) => {
  const t = useI18n([APPLICANT_BIOMETRICS_OCR_KEY], {
    keys: applicantBiometricsOcrEn,
  })

  const mutation = useMutation({
    mutationFn: async ({ payload }: MutateProps) => {
      const { data } = await applicantBiometricsApi.getIdvLink(
        applicantId,
        payload,
      )
      return data
    },
    onError: (err: ErrorResponse, { payload: { pushToSms } }) => {
      notification.error(
        err.response?.data.message ??
          t(pushToSms ? 'error.linkNotSent' : 'error.linkGeneration'),
      )
    },
    onSuccess: (_, { successMessage }) => {
      if (successMessage) notification.success(successMessage)
    },
  })

  useEffect(() => {
    if (enabled) {
      mutation.mutate({ payload: { pushToSms: false } })
    }
  }, [enabled])

  return mutation
}
