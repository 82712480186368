import { useCallback, useEffect } from 'react'

import { Noop } from 'shared/typescript'

export const frankieHelpScript = {
  src: 'https://jsd-widget.atlassian.com/assets/embed.js',
  'data-jsd-embedded': true,
  'data-key': '39741c81-e9c3-4b94-91ae-c08e292085e7',
  'data-base-url': 'https://jsd-widget.atlassian.com',
}

type Args = { enable?: boolean }

/**
 * Frankie Help script - pulled from the vue codebase
 * @todo To be enabled only for prod? - not sure
 * @param enable - When to enable the scripts
 */
export const useFrankieHelp = ({ enable = false }: Args) => {
  /**
   * Remove help script
   */
  const removeScript = useCallback(() => {
    const scriptElement = document.querySelector(
      `script[src="${frankieHelpScript.src}"]`,
    )
    if (scriptElement) {
      scriptElement.remove()
    }
  }, [])

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (enable) {
      const addScript = (
        attributes: Record<string, string | boolean | number>,
        callback: Noop,
      ) => {
        const script = document.createElement('script')
        Object.entries(attributes).forEach(([key, value]) => {
          script.setAttribute(key, value as string)
        })

        script.onload = callback
        document.head.appendChild(script)
      }

      const loadScript = () => {
        addScript(frankieHelpScript, () => {
          const domContentLoaderEvent = new Event('DOMContentLoaded')
          window.document.dispatchEvent(domContentLoaderEvent)
        })
      }

      const timeoutId = setTimeout(loadScript, 300)

      return () => {
        removeScript()
        clearTimeout(timeoutId)
      }
    }

    // If not enabled, remove the script
    removeScript()
  }, [enable, removeScript])
}
