import React from 'react'

import { ApplicantId } from 'entities/applicant'

import { applicantFraudCheckQa } from '../../qa/applicant-fraud-check.qa'
import { FraudCheckBusinessDetails } from '../applicant-fraud-check-business-details/applicant-fraud-check-business-details'
import { ApplicantFraudCheckTable } from '../applicant-fraud-check-table/applicant-fraud-check-table'

type Props = {
  applicantId: ApplicantId
}

export function ApplicantFraudCheck({ applicantId }: Props) {
  return (
    <div>
      <FraudCheckBusinessDetails
        applicantId={applicantId}
        testId={{ container: applicantFraudCheckQa.businessDetails }}
      />
      <ApplicantFraudCheckTable
        applicantId={applicantId}
        testId={{ container: applicantFraudCheckQa.fraudCheckTable }}
      />
    </div>
  )
}
