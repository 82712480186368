import { useCallback } from 'react'

import { useQuery, useQueryClient } from '@tanstack/react-query'

import { Nullable } from 'shared/typescript'

const TABLE_LOADING_STATE_QUERY_KEY = ['tableLoadingState']

type TableState = 'clearingFilters' | 'loadingMoreData' | 'applyingFilters'

export const useTableLoadingState = () => {
  const queryClient = useQueryClient()

  return useQuery({
    queryKey: TABLE_LOADING_STATE_QUERY_KEY,
    initialData: 'loadingMoreData',
    queryFn: () =>
      queryClient.getQueryData<TableState>(TABLE_LOADING_STATE_QUERY_KEY),
  })
}

export const useUpdateTableLoadingState = () => {
  const queryClient = useQueryClient()

  const update = useCallback(
    (value: Nullable<TableState>) => {
      if (!value) {
        void queryClient.setQueryData(TABLE_LOADING_STATE_QUERY_KEY, null)
        void queryClient.removeQueries({
          queryKey: TABLE_LOADING_STATE_QUERY_KEY,
        })
        void queryClient.invalidateQueries({
          queryKey: TABLE_LOADING_STATE_QUERY_KEY,
        })
      } else {
        queryClient.setQueryData(TABLE_LOADING_STATE_QUERY_KEY, value)
      }
    },
    [queryClient],
  )

  return update
}
