import { useQuery } from '@tanstack/react-query'

import { abrSearchAPI } from 'features/abr-search-australia/api/abr-search-australia.api'

import { ABRCompany } from '../../model/abr-search-model'

const abrSearchQueryKey = 'abr-search'

type Args = {
  search: string
}

export const useABRSearchQuery = ({ search }: Args) =>
  useQuery<ABRCompany[]>({
    enabled: false,
    queryKey: [abrSearchQueryKey],
    queryFn: async () => {
      const { data } = await abrSearchAPI.searchOrganisation(search)

      return data
    },
  })

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const mock_frankie_data = [
  {
    legalNames: [],
    businessNames: [],
    historicalBusinessNames: [],
    mainNames: ['FRANKIE & CO PTY LTD'],
    tradingNames: [],
    score: '100',
    state: 'VIC',
    postalCode: '3352',
    type: null,
    abn: '53637395138',
    acn: null,
    isActive: true,
    abnStatus: null,
    name: 'FRANKIE & CO PTY LTD',
  },
  {
    legalNames: [],
    businessNames: [
      {
        name: 'FRANKIE CO',
      },
    ],
    historicalBusinessNames: [],
    mainNames: [],
    tradingNames: [],
    state: 'QLD',
    postalCode: '4305',
    type: null,
    abn: '20796204271',
    acn: null,
    isActive: true,
    abnStatus: null,
    name: 'FRANKIE CO',
  },
  {
    legalNames: [],
    businessNames: [],
    historicalBusinessNames: [],
    mainNames: ['FRANKIE PTY LTD'],
    tradingNames: [],
    score: '100',
    state: 'NSW',
    postalCode: '2010',
    type: null,
    abn: '58646451765',
    acn: null,
    isActive: true,
    abnStatus: null,
    name: 'FRANKIE PTY LTD',
  },
  {
    legalNames: [],
    businessNames: [],
    historicalBusinessNames: [],
    mainNames: [],
    tradingNames: ['Frankie'],
    state: 'VIC',
    postalCode: '3934',
    type: null,
    abn: '44580897501',
    acn: null,
    isActive: true,
    abnStatus: null,
    name: 'FRANKIE',
  },
  {
    legalNames: [],
    businessNames: [],
    historicalBusinessNames: [],
    mainNames: [],
    tradingNames: ['Frankie'],
    state: 'NSW',
    postalCode: '2153',
    type: null,
    abn: '80110710589',
    acn: null,
    isActive: true,
    abnStatus: null,
    name: 'FRANKIE',
  },
  {
    legalNames: [],
    businessNames: [],
    historicalBusinessNames: [],
    mainNames: ['MR FRANKIE PTY. LTD.'],
    tradingNames: [],
    score: '100',
    state: 'VIC',
    postalCode: '3930',
    type: null,
    abn: '49628993822',
    acn: null,
    isActive: true,
    abnStatus: null,
    name: 'MR FRANKIE PTY. LTD.',
  },
  {
    legalNames: [],
    businessNames: [
      {
        name: 'Ms Frankie',
      },
    ],
    historicalBusinessNames: [],
    mainNames: [],
    tradingNames: [],
    state: 'VIC',
    postalCode: '3121',
    type: null,
    abn: '91154877040',
    acn: null,
    isActive: true,
    abnStatus: null,
    name: 'MS FRANKIE',
  },
  {
    legalNames: [],
    businessNames: [],
    historicalBusinessNames: [],
    mainNames: [],
    tradingNames: ['frankie'],
    state: 'QLD',
    postalCode: '4122',
    type: null,
    abn: '17373490626',
    acn: null,
    isActive: true,
    abnStatus: null,
    name: 'FRANKIE',
  },
  {
    legalNames: [],
    businessNames: [],
    historicalBusinessNames: [],
    mainNames: [],
    tradingNames: ['frankie'],
    state: 'NSW',
    postalCode: '2022',
    type: null,
    abn: '39706261108',
    acn: null,
    isActive: true,
    abnStatus: null,
    name: 'FRANKIE',
  },
  {
    legalNames: [],
    businessNames: [],
    historicalBusinessNames: [],
    mainNames: [],
    tradingNames: ['frankie'],
    state: 'NSW',
    postalCode: '2205',
    type: null,
    abn: '54957330261',
    acn: null,
    isActive: true,
    abnStatus: null,
    name: 'FRANKIE',
  },
  {
    legalNames: [],
    businessNames: [
      {
        name: 'BAD FRANKIE',
      },
    ],
    historicalBusinessNames: [],
    mainNames: [],
    tradingNames: [],
    state: 'QLD',
    postalCode: '4032',
    type: null,
    abn: '31645704829',
    acn: null,
    isActive: true,
    abnStatus: null,
    name: 'BAD FRANKIE',
  },
  {
    legalNames: [],
    businessNames: [],
    historicalBusinessNames: [],
    mainNames: ['BADGER&FRANKIE PTY LTD'],
    tradingNames: [],
    score: '97',
    state: 'WA',
    postalCode: '6285',
    type: null,
    abn: '65623375253',
    acn: null,
    isActive: true,
    abnStatus: null,
    name: 'BADGER&FRANKIE PTY LTD',
  },
  {
    legalNames: [],
    businessNames: [],
    historicalBusinessNames: [],
    mainNames: ['BELL FRANKIE & CO PTY LTD'],
    tradingNames: [],
    score: '97',
    state: 'VIC',
    postalCode: '3143',
    type: null,
    abn: '30607562292',
    acn: null,
    isActive: true,
    abnStatus: null,
    name: 'BELL FRANKIE & CO PTY LTD',
  },
  {
    legalNames: ['FRANKIE ABRAHAM'],
    businessNames: [],
    historicalBusinessNames: [],
    mainNames: [],
    tradingNames: [],
    state: 'NSW',
    postalCode: '2148',
    type: null,
    abn: '15820389195',
    acn: null,
    isActive: true,
    abnStatus: null,
    name: 'FRANKIE ABRAHAM',
  },
  {
    legalNames: ['FRANKIE AIOLO'],
    businessNames: [],
    historicalBusinessNames: [],
    mainNames: [],
    tradingNames: [],
    state: 'QLD',
    postalCode: '4078',
    type: null,
    abn: '76790950641',
    acn: null,
    isActive: true,
    abnStatus: null,
    name: 'FRANKIE AIOLO',
  },
]
