import { NEW_PASSWORD_KEY } from '../model/new-password.model'

export const newPasswordQA = {
  header: 'new-password-header',
  form: 'new-password-form',
  newPasswordInput: 'new-password-input-new-password',
  newPasswordConfirm: 'new-password-input-confirm-password',
  alert: 'new-password-alert',
  submitButton: 'new-password-submit-button',
  continueButton: 'new-password-continue-button',
  successfulResetHeader: 'new-password-success-header',
  newPasswordInputErrorText: 'new-password-input-error-text',
  confirmPasswordInputErrorText: 'new-password-confirm-input-error-text',
  newPasswordRequirement: 'new-password-requirement',
} satisfies Record<string, `${typeof NEW_PASSWORD_KEY}-${string}`>
