import { FrankieIconName } from 'frankify/src'

import { I18nKeys } from 'shared/i18n'
import { PartialRecord } from 'shared/typescript'

import { issueEn } from '../locale/applicant.en'

export enum ApplicantIssueTypes {
  KYC = 'KYC',
  Biometrics = 'Biometrics',
  Device = 'Device',
  Duplicate = 'Duplicate',
  Risk = 'Risk',
  Document = 'Document',
  Timeout = 'Timeout',
  Blocklist = 'Blocklist',
  DigitalId = 'DigitalId',
  FacialDuplication = 'FacialDuplication',
  InternalWatchlist = 'InternalWatchlist',
  AdverseMedia = 'AdverseMedia',
  PEP = 'pep',
  Sanctions = 'Sanctions',
  Watchlist = 'Watchlist',
  AMLTransaction = 'AMLTransaction',
  FraudTransaction = 'FraudTransaction',
  DeviceCustomer = 'DeviceCustomer',
}

export type IssueTerm =
  | 'pep'
  | 'rsk'
  | 'sanction'
  | 'watchlist'
  | 'media'
  | 404
  | 'partial'
  | 'att-ncmp'
  | 'blacklist'
  | 500
  | 'txn_customer'
  | 'txn_fraud'
  | 'txn_aml'
  | 'fraud'
  | 'att-ocr'
  | 'att-idv'
  | 'att-pht'
  | 'duplicate'
  | 'iwl'
  | 'device'
  | 'support-docs'

export const issuesMapping: Record<IssueTerm, ApplicantIssueTypes> = {
  pep: ApplicantIssueTypes.PEP,
  rsk: ApplicantIssueTypes.Risk,
  sanction: ApplicantIssueTypes.Sanctions,
  watchlist: ApplicantIssueTypes.Watchlist,
  media: ApplicantIssueTypes.AdverseMedia,
  404: ApplicantIssueTypes.KYC,
  partial: ApplicantIssueTypes.KYC,
  blacklist: ApplicantIssueTypes.Blocklist,
  500: ApplicantIssueTypes.Timeout,
  txn_customer: ApplicantIssueTypes.DeviceCustomer,
  txn_fraud: ApplicantIssueTypes.FraudTransaction,
  txn_aml: ApplicantIssueTypes.AMLTransaction,
  duplicate: ApplicantIssueTypes.Duplicate,
  fraud: ApplicantIssueTypes.FraudTransaction,
  'att-ncmp': ApplicantIssueTypes.Biometrics,
  'att-pht': ApplicantIssueTypes.Biometrics,
  'att-ocr': ApplicantIssueTypes.Biometrics,
  'att-idv': ApplicantIssueTypes.Biometrics,
  iwl: ApplicantIssueTypes.InternalWatchlist,
  device: ApplicantIssueTypes.Device,
  'support-docs': ApplicantIssueTypes.Document,
}

type IssueEn<TKey extends keyof (typeof issueEn)['issue']> = I18nKeys<
  (typeof issueEn)['issue'][TKey]
>

export type ApplicantIssueBadgeColorType =
  | 'midLightGrey'
  | 'darkNavy'
  | 'red'
  | 'darkRed'
  | 'lightRed'
  | 'lightYellow'
  | 'extraLightYellow'
  | 'fYellow'
  | 'green'
  | 'extraLightGreen'
  | 'navy'
  | 'blue'
  | 'neutral20'

type TermData = PartialRecord<
  string,
  {
    icon?: FrankieIconName
    shortTKey?: IssueEn<'shortLabel'>
    labelTKey?: IssueEn<'label'>
    tooltipTKey?: IssueEn<'tooltip'>
    colorType?: ApplicantIssueBadgeColorType
  }
>

/**
 * Icon -> short label -> label
 */
export const alertListRecord: TermData = {
  verified: {
    tooltipTKey: 'verified',
  },
  unverified: {
    tooltipTKey: 'delete',
  },
  delete: {
    tooltipTKey: 'delete',
  },
  edit: {
    tooltipTKey: 'edit',
  },
  view: {
    tooltipTKey: 'view',
  },
  'reset-password': {
    tooltipTKey: 'resetPassword',
  },
  passed: {
    tooltipTKey: 'noAMLMatches',
    labelTKey: 'passed',
    colorType: 'extraLightGreen',
  },
  pass_manual: {
    labelTKey: 'passedManual',
    colorType: 'extraLightGreen',
  },
  failed: {
    labelTKey: 'failed',
    colorType: 'lightRed',
  },
  fail_manual: {
    labelTKey: 'failManual',
    colorType: 'lightRed',
  },
  archived: {
    labelTKey: 'archived',
    colorType: 'midLightGrey',
  },
  inactive: {
    labelTKey: 'inactive',
    colorType: 'darkNavy',
  },
  unchecked: {
    labelTKey: 'unchecked',
    colorType: 'neutral20',
  },
  clear: {
    tooltipTKey: 'noAMLMatches',
    icon: 'mdiCheckBold',
    colorType: 'extraLightGreen',
  },
  refer: {
    labelTKey: 'refer',
    colorType: 'extraLightYellow',
  },
  '404': {
    tooltipTKey: 'didNotPassKyc',
    shortTKey: 'kyc',
    labelTKey: 'kyc',
    colorType: 'red',
  },
  partial: {
    tooltipTKey: 'didNotPassKyc',
    shortTKey: 'kyc',
    labelTKey: 'kyc',
    colorType: 'red',
  },
  media: {
    tooltipTKey: 'potentialAdverseMediaFound',
    shortTKey: 'media',
    labelTKey: 'media',
    colorType: 'lightYellow',
  },
  pep: {
    tooltipTKey: 'potentialPEPFound',
    shortTKey: 'pep',
    labelTKey: 'pep',
    colorType: 'lightYellow',
  },
  'pep-class-1': {
    labelTKey: 'pepClass1',
    colorType: 'lightYellow',
  },
  'pep-class-2': {
    labelTKey: 'pepClass2',
    colorType: 'lightYellow',
  },
  'pep-class-3': {
    labelTKey: 'pepClass3',
    colorType: 'lightYellow',
  },
  'pep-class-4': {
    labelTKey: 'pepClass4',
    colorType: 'lightYellow',
  },
  'pep-class-5': {
    labelTKey: 'pepClass5',
    colorType: 'lightYellow',
  },
  watchlist: {
    tooltipTKey: 'potentialWatchlistFound',
    shortTKey: 'watchlist',
    labelTKey: 'watchlist',
    colorType: 'lightYellow',
  },
  sanction: {
    tooltipTKey: 'potentialSanctionFound',
    shortTKey: 'sanction',
    labelTKey: 'sanction',
    colorType: 'red',
  },
  sanctions: {
    tooltipTKey: 'potentialSanctionFound',
    shortTKey: 'sanction',
    labelTKey: 'sanction',
    colorType: 'red',
  },
  iwl: {
    tooltipTKey: 'internalWatchList',
    shortTKey: 'iwl',
    colorType: 'navy',
    icon: 'mdiEyeOutline',
  },
  'att-pht': {
    tooltipTKey: 'facialRecognitionFailed',
    icon: 'mdiTargetAccount',
    colorType: 'red',
  },
  'att-ocr': {
    tooltipTKey: 'ocrFailed',
    icon: 'mdiCreditCardScan',
    colorType: 'red',
  },
  att: {
    tooltipTKey: 'docCheckFailed',
    icon: 'mdiCardAccountDetailsOutline',
    colorType: 'red',
  },
  'att-idv': {
    tooltipTKey: 'docCheckFailed',
    icon: 'mdiCardAccountDetailsOutline',
    colorType: 'red',
  },
  duplicate: {
    shortTKey: 'duplicate',
    labelTKey: 'duplicate',
  },
  blacklist: {
    shortTKey: 'blacklist',
    labelTKey: 'blacklist',
  },
  device: {
    shortTKey: 'device',
    colorType: 'red',
  },
  fraud: {
    shortTKey: 'fraud',
    colorType: 'red',
  },
  txn_fraud: {
    shortTKey: 'txnFraud',
    colorType: 'red',
  },
  txn_aml: {
    shortTKey: 'txnAml',
    colorType: 'red',
  },
  txn_customer: {
    shortTKey: 'txnCustomer',
    colorType: 'red',
  },
  'att-face': {
    shortTKey: 'facialDuplicate',
    colorType: 'red',
  },
  'digital-id': {
    shortTKey: 'digitalId',
    colorType: 'fYellow',
  },
  error: {
    shortTKey: 'timeout',
    labelTKey: 'systemError',
    colorType: 'red',
  },
  aml: {
    shortTKey: 'timeout',
    labelTKey: 'amlError',
    colorType: 'red',
  },
  '500': {
    shortTKey: 'timeout',
    labelTKey: 'systemError',
    colorType: 'red',
  },
  'support-docs': {
    shortTKey: 'document',
    colorType: 'fYellow',
  },
  'SUPPORT-DOCS': {
    shortTKey: 'document',
    colorType: 'fYellow',
  },
  'ATT-NCMP': {
    shortTKey: 'biometrics',
    labelTKey: 'amlError',
    colorType: 'red',
  },
  'att-ncmp': {
    shortTKey: 'biometrics',
    labelTKey: 'amlError',
    colorType: 'red',
  },
  RSK: {
    shortTKey: 'risk',
    colorType: 'red',
  },
  rsk: {
    shortTKey: 'risk',
    colorType: 'red',
  },
  risk_low: {
    labelTKey: 'riskLow',
    colorType: 'green',
  },
  unknown: {
    labelTKey: 'unknown',
    colorType: 'neutral20',
  },
  risk_medium: {
    labelTKey: 'riskMedium',
    colorType: 'fYellow',
  },
  medium: {
    colorType: 'fYellow',
  },
  risk_high: {
    labelTKey: 'riskHigh',
    colorType: 'red',
  },
  very_high: {
    colorType: 'darkRed',
  },
  risk_unacceptable: {
    labelTKey: 'riskUnacceptable',
    colorType: 'red',
  },
  risk_significant: {
    labelTKey: 'riskSignificant',
    colorType: 'red',
  },
  risk_unknown: {
    labelTKey: 'riskUnknown',
    colorType: 'neutral20',
  },
  wait: {
    labelTKey: 'waitOnCustomer',
    colorType: 'blue',
  },
}
