import React, { ComponentProps } from 'react'

import { IKybAmlAdverseMedia } from './ikyb-aml-adverse-media/ikyb-aml-adverse-media'
import { IKybAmlDataSources } from './ikyb-aml-data-sources/ikyb-aml-data-sources'
import { IKybAmlKeyData } from './ikyb-aml-key-data/ikyb-aml-key-data'
import { iKybAmlResultTabQa } from '../../../qa/ikyb-aml-screening.qa'

const ApplicantPepSactionsResultTabsMapping = {
  keyData: IKybAmlKeyData,
  adverseMedia: IKybAmlAdverseMedia,
  dataSource: IKybAmlDataSources,
}

type IKybAmlResultTabsMappingType = typeof ApplicantPepSactionsResultTabsMapping

export type IKybAmlResultTabsMappingTypes = keyof IKybAmlResultTabsMappingType

export type OwnProps<TType extends IKybAmlResultTabsMappingTypes> = {
  type?: TType
}

export function IKybAmlResultTabs<TType extends IKybAmlResultTabsMappingTypes>({
  type,
  ...rest
}: OwnProps<TType> & ComponentProps<IKybAmlResultTabsMappingType[TType]>) {
  const TabType = type
    ? ApplicantPepSactionsResultTabsMapping[type]
    : IKybAmlKeyData

  return (
    <div data-qa={iKybAmlResultTabQa.container} className="">
      <TabType testId={{ container: iKybAmlResultTabQa.tab }} {...rest} />
    </div>
  )
}
