import React from 'react'

import { FrankieButton, FrankieIcon } from 'frankify/src'

import { DateFormatTypes, formatDate } from 'shared/date-time'
import { useDismissibleState, useScrollInView } from 'shared/hooks'
import { useI18n } from 'shared/i18n'
import { capitalize } from 'shared/string'

import { MONITORING_OVERVIEW_KEY } from '../../monitoring-overview.key'

type ErrorState = {
  hasError: boolean
  requestedDate?: string
  updatedDate?: string
  requestId?: string
}

type ReportType = 'profile' | 'ownership'

type ErrorBannerProps = {
  date?: string
  reportType: ReportType
  handleOnClose: () => void
  show: boolean
}

function ErrorBanner({
  handleOnClose,
  reportType,
  date,
  show,
}: ErrorBannerProps) {
  const t = useI18n([MONITORING_OVERVIEW_KEY])
  const ref = useScrollInView(show)

  if (!show) return null

  return (
    <div ref={ref} className="pt-6">
      <div className="relative bg-tertiary-red-50 rounded-sm p-4">
        <div className="flex justify-start items-start gap-2">
          <FrankieIcon
            name="mdiInformation"
            size="sm"
            className="text-tertiary-red-700"
          />
          <div className="">
            <div className="text-wrap tertiary-grey-800 font-semibold text-md">
              {t('reportError.title', {
                reportType: capitalize(reportType),
              })}
            </div>
            {date && (
              <div className="mt-[6px] tertiary-grey-700">
                {t('reportError.description', {
                  requestedDate: formatDate(
                    date,
                    DateFormatTypes.ShortMonthDateYear,
                  ),
                })}
              </div>
            )}
          </div>
        </div>

        <FrankieButton
          singleIcon={{
            name: 'mdiClose',
            size: 'md',
          }}
          noStyles
          onClick={handleOnClose}
          className="cursor-pointer text-tertiary-grey-400 absolute top-4 right-4"
        />
      </div>
    </div>
  )
}

type Props = {
  entityId?: string
} & Record<ReportType, ErrorState>

export function ReportErrorBanner({ entityId, profile, ownership }: Props) {
  const { dismiss: dismissProfileError, flag: hasProfileError } =
    useDismissibleState(
      profile.hasError,
      `${entityId!}-profile-${profile.requestId!}`,
    )

  const { dismiss: dismissOwnershipError, flag: hasOwnershipError } =
    useDismissibleState(
      ownership.hasError,
      `${entityId!}-ownership-${ownership.requestId!}`,
    )

  return (
    <>
      <ErrorBanner
        handleOnClose={dismissProfileError}
        date={profile.updatedDate ?? profile.requestedDate}
        reportType="profile"
        show={hasProfileError}
      />
      <ErrorBanner
        handleOnClose={dismissOwnershipError}
        date={ownership.updatedDate ?? ownership.requestedDate}
        reportType="ownership"
        show={hasOwnershipError}
      />
    </>
  )
}
