import { useMemo } from 'react'

import type { ServiceProfile } from 'entities/entity'

/**
 * Custom hook to filter process IDs by event name in a workflow execution.
 * @param eventName - The name of the event or an array of event names to filter by.
 * @param serviceProfile - The service profile containing workflow summaries.
 * @param executionId - Optional. The specific workflow execution ID to filter on.
 * @returns An array of process IDs matching the specified event name(s).
 */
export const useFilterProcessIdsByEventName = (
  eventName: string | string[],
  serviceProfile: ServiceProfile,
  executiondId?: string,
) =>
  useMemo(() => {
    const eventNames = Array.isArray(eventName) ? eventName : [eventName]
    const workflowSummary = executiondId
      ? serviceProfile.workflowSummaries?.find(
          workflowSummary =>
            workflowSummary.workflowExecutionId === executiondId,
        )
      : serviceProfile.workflowSummaries?.at(0)

    return (
      workflowSummary?.workflowResultData?.workflowStepResults
        ?.find(result => eventNames.includes(result.stepName as string))
        ?.processResults?.map(processResult => processResult.processResultId) ||
      []
    )
  }, [eventName, executiondId, serviceProfile.workflowSummaries])
