import React, { useState } from 'react'

import { MfaTypes } from 'entities/mfa'

import { Nullable } from 'shared/typescript'

import { MfaTypeSelect } from './mfa-type-select/mfa-type-select'
import { EnableMfaApp } from '../enable-mfa-app/enable-mfa-app'
import { EnableMfaEmail } from '../enable-mfa-email/enable-mfa-email'

type Props = {
  onClose: () => void
}

export function EnableMfaDialog({ onClose }: Props) {
  const [mfaType, setMfaType] = useState<Nullable<MfaTypes>>(null)

  const handleSelectMfaType = (type: MfaTypes) => {
    setMfaType(type)
  }

  const handleBackToMfaTypeSelect = () => {
    setMfaType(null)
  }

  if (mfaType === MfaTypes.App) {
    return (
      <EnableMfaApp
        onClose={onClose}
        onBackToMfaTypeSelect={handleBackToMfaTypeSelect}
      />
    )
  }

  if (mfaType === MfaTypes.Email) {
    return (
      <EnableMfaEmail
        onClose={onClose}
        onBackToMfaTypeSelect={handleBackToMfaTypeSelect}
      />
    )
  }

  return <MfaTypeSelect onSelect={handleSelectMfaType} />
}
