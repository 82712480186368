import React, { useMemo } from 'react'

import { FrankieButton, FrankieDivider } from 'frankify/src'

import { ApplicantId, ApplicantResponse } from 'entities/applicant'

import { Noop, Nullable } from 'shared/typescript'

import {
  ApplicantMatchesTypes,
  BlocklistAttributeTypes,
  MatchesTypes,
} from '../../models/applicant-matches.models'
import { BlacklistResponseType } from '../../models/blacklist.model'
import { appPotentialMatchesQa } from '../../qa/applicant-potential-matches.qa'
import { ApplicantDocumentInfo } from '../applicant-document-info/applicant-document-info'
import { ApplicantHeader } from '../applicant-header/applicant-header'
import { ApplicantMatchSubheader } from '../applicant-match-subheader/applicant-match-subheader'
import { ApplicantPersonalInfo } from '../applicant-personal-info/applicant-personal-info'

export type Props = {
  type: MatchesTypes
  applicantId: ApplicantId
  applicantData?: ApplicantResponse
  comparisonData?: ApplicantResponse
  matches?: BlacklistResponseType[]
  isMatchedView?: boolean
  currentProfileIdx: number
  isLoading?: boolean
  getPreviousApplicantData?: Noop
  getNextApplicantData?: Noop
  isConfirmedEntity?: Nullable<boolean>
}

// eslint-disable-next-line complexity
export function ApplicantPotentialMatches({
  type,
  applicantId,
  applicantData,
  matches,
  isMatchedView = false,
  currentProfileIdx,
  isLoading = false,
  getPreviousApplicantData,
  isConfirmedEntity,
  getNextApplicantData = () => null,
  comparisonData,
}: Props) {
  const manualBlocklistContentView = useMemo(() => {
    const blocklistAttribute =
      applicantData?.checkSummary.issues.BLOCKLIST_ATTRIBUTE

    const blocklistReason = applicantData?.checkSummary.issues.MANUAL_BLOCKLIST

    return (
      // type === ApplicantMatchesTypes.POTENTIAL &&
      // !matchedView &&
      blocklistAttribute &&
      blocklistReason &&
      blocklistAttribute !== BlocklistAttributeTypes.ENTIRE_ENTITY
    )
  }, [applicantData?.checkSummary.issues])

  return (
    <div
      className="text-tertiary-grey-700 relative max-w-[660px] border border-solid border-tertiary-grey-200 rounded-lg h-full"
      data-qa={appPotentialMatchesQa.mainWrapper}
    >
      {isMatchedView && matches && matches.length > 1 && (
        <div
          className={`absolute top-[14px] right-[12px] ${
            type === ApplicantMatchesTypes.POTENTIAL
              ? 'text-tertiary-grey-700'
              : 'text-mono-white'
          } ${isLoading ? 'cursor-not-allowed' : ''}`}
        >
          <div className=" flex items-center ">
            <FrankieButton
              singleIcon={{
                name: 'mdiChevronLeft',
                size: 'md',
              }}
              onClick={getPreviousApplicantData}
              noStyles
            />
            <div className="px-2 whitespace-nowrap">
              {currentProfileIdx + 1} of {matches.length}
            </div>
            <FrankieButton
              singleIcon={{
                name: 'mdiChevronRight',
                size: 'md',
              }}
              onClick={getNextApplicantData}
              noStyles
            />
          </div>
        </div>
      )}
      <ApplicantHeader
        matchedView={isMatchedView}
        type={type}
        applicantData={applicantData}
      />
      <FrankieDivider />
      <ApplicantMatchSubheader
        type={type}
        matchedView={isMatchedView}
        applicantId={applicantId}
        matchedApplicantId={matches?.[currentProfileIdx]?.entityId ?? ''}
        showNextProfile={getNextApplicantData}
        isConfirmedEntity={isConfirmedEntity}
        matchesLength={matches ? matches.length : 0}
      />
      <ApplicantPersonalInfo
        manualBlocklistContentView={manualBlocklistContentView}
        applicantData={applicantData}
        matchedView={isMatchedView}
        isLoading={isLoading}
        matches={matches?.[currentProfileIdx]}
        type={type}
      />
      {!manualBlocklistContentView && (
        <ApplicantDocumentInfo
          applicantData={applicantData}
          comparisonData={comparisonData}
          matches={matches?.[currentProfileIdx]}
          isLoading={isLoading}
        />
      )}
    </div>
  )
}
