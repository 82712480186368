import React, { useEffect, useMemo } from 'react'

import { startOfDay } from 'date-fns'
import { Controller } from 'react-hook-form'

import { FrankieButton, FrankieImage } from 'frankify/src'

import { DocumentType } from 'entities/entity'

import { TextFormField, MaskedTextField, ToggleButton } from 'shared/form'
import { useI18n } from 'shared/i18n'
import { useValidationRules } from 'shared/validation'

import { INDIVIDUAL_PROFILE_F2_KEY } from '../../../../individual-profile-f2.key'
import { individualProfileF2En } from '../../../../locale/individual-profile-f2.en'
import {
  cardValue,
  displayMiddleName,
  mCareCardOption,
} from '../../../../model/individual-profile-f2-form.model'
import { medicareCardF2Qa } from '../../../../qa/individual-profile-f2.qa'
import { DocumentsSubFormProps } from '../document-sub-forms.types'

export function MedicareCardForm({
  form,
  idx,
  wrapperClasses,
}: DocumentsSubFormProps) {
  const t = useI18n([INDIVIDUAL_PROFILE_F2_KEY], {
    keys: individualProfileF2En,
  })
  const { control, watch, setValue, getValues, trigger } = form

  const { nonNumericRule, numericRule } = useValidationRules()

  const getRequiredRule = (
    label: Parameters<typeof t>['0'],
    entity = true,
  ) => ({
    required: t(`errors.${entity ? 'requiredEntity' : 'required'}`, {
      label: t(label).toLowerCase(),
    }),
  })

  const getRequiredRuleSelect = (label: Parameters<typeof t>['0']) => ({
    required: t('errors.select', {
      label: t(label).toLowerCase(),
    }),
  })

  const card = watch(`documents.${idx}.subtype`) || cardValue.green
  const middleName = watch('name.middleName')

  useEffect(() => {
    if (middleName) {
      setValue(
        `documents.${idx}.supplementaryData.middleNameOnCard`,
        middleName.at(0) || displayMiddleName.initial,
      )
    } else {
      setValue(`documents.${idx}.supplementaryData.middleNameOnCard`, '')
    }

    const supplementaryData = getValues().documents[idx]?.supplementaryData

    if (!supplementaryData?.displayMiddleName) {
      setValue(
        `documents.${idx}.supplementaryData.displayMiddleName`,
        supplementaryData?.nameOnCardLine2 ? '4' : '1',
      )
      setValue(
        `documents.${idx}.supplementaryData.type`,
        DocumentType.NATIONAL_HEALTH_ID,
      )
    }
  }, [getValues, idx, middleName, setValue])

  const multipleLines = watch(
    `documents.${idx}.supplementaryData.displayMiddleName`,
  )
  const displayNameField = useMemo(() => {
    const displayNameFieldsArr = []

    for (let i = 1; i <= +multipleLines; i += 1) {
      displayNameFieldsArr.push(
        <TextFormField
          trim
          testId={{ container: medicareCardF2Qa.nameOnCard }}
          control={control}
          name={`documents.${idx}.supplementaryData.nameOnCardLine${i}`}
          className="basis-[61.5%]"
          shouldUnregister
          showErrorText
          rules={{
            ...nonNumericRule,
            required:
              i === 1 && getRequiredRule('documentForm.nameAsDisplay').required,
            maxLength: {
              value: 29 - i * 2,
              message: t('errors.maxCharacter', {
                maximum: 29 - i * 2,
              }),
            },
            validate: {
              noSkip: value => {
                const supplementaryData =
                  getValues().documents[idx]?.supplementaryData

                const next = supplementaryData?.[`nameOnCardLine${i + 1}`]

                const prev = supplementaryData?.[`nameOnCardLine${i - 1}`]

                if (!prev) {
                  void trigger(
                    `documents.${idx}.supplementaryData.nameOnCardLine${i - 1}`,
                  )
                }

                if (!value && next) {
                  return t('documentForm.medicare.skipError')
                }

                return undefined
              },
            },
          }}
          label={i === 1 ? t('documentForm.nameOnCard') : undefined}
        />,
      )
    }
    return displayNameFieldsArr
  }, [multipleLines])

  const medicareExpireDateRule = {
    validate: {
      validDate: (value: unknown) => {
        if (new Date(value as string) > startOfDay(new Date())) {
          return undefined
        }
        return t('profileForm.errors.validDate')
      },
    },
  }

  return (
    <div className={wrapperClasses} data-qa={medicareCardF2Qa.container}>
      <Controller
        render={({ field: { onChange, value } }) => (
          <div className="basis-[100%]">
            <div
              className="pb-1 text-tertiary-grey-800 text-sm font-medium"
              data-qa={medicareCardF2Qa.cardColor}
            >
              {t('documentForm.cardColor')}
            </div>

            {mCareCardOption.map(item => (
              <FrankieButton
                key={item.value}
                noStyles
                className={`border-2 border-solid m-1 ${
                  value === item.value
                    ? 'border-primary-700'
                    : 'border-transparent'
                } rounded-sm`}
                onClick={() => {
                  setValue(`documents.${idx}.expiryDate.normalized`, '')
                  onChange(item.value)
                }}
                testId={{ button: medicareCardF2Qa.icon }}
              >
                <FrankieImage className="cursor-pointer" src={item.icon} />
              </FrankieButton>
            ))}
          </div>
        )}
        control={control}
        defaultValue={cardValue.green}
        name={`documents.${idx}.subtype`}
      />
      <TextFormField
        trim
        testId={{ container: medicareCardF2Qa.number }}
        control={control}
        className="basis-[35.5%]"
        label={t('documentForm.number')}
        shouldUnregister
        placeholder={t('documentForm.numberPlaceholder')}
        name={`documents.${idx}.primaryIdentifier`}
        rules={{
          ...getRequiredRule('documentForm.medicareCardNumber'),
          ...numericRule,
          validate: {
            minLength: value => {
              if (Number(value?.toString().length) < 10)
                return t('errors.minLength', {
                  label: t('documentForm.medicareCardNumber'),
                  minimum: 10,
                })
              return undefined
            },
            maxLength: value => {
              if (Number(value?.toString().length) > 10)
                return t('errors.maxLength', {
                  label: t('documentForm.medicareCardNumber'),
                  maximum: 10,
                })
              return undefined
            },
          },
        }}
        showErrorText
      />
      <TextFormField
        trim
        testId={{ container: medicareCardF2Qa.positionCard }}
        control={control}
        name={`documents.${idx}.supplementaryData.reference`}
        className="basis-[23%]"
        label={t('documentForm.positionOnCard')}
        placeholder={t('documentForm.positionOnCardPlaceholder')}
        rules={{
          ...getRequiredRule('documentForm.positionOnCard', false),
          ...numericRule,
        }}
        showErrorText
        maxLength={1}
      />

      {card === cardValue.green ? (
        <MaskedTextField
          testId={{ container: medicareCardF2Qa.dateOfExpiry }}
          className="basis-[35.5%]"
          label={t('documentForm.dateOfExpiry')}
          control={control}
          showErrorText
          valueConverter={value =>
            value && value.split('-').reverse().slice(1).join('-')
          }
          valueReverseConverter={value =>
            value && `${value.split('-').reverse().join('-')}-01`
          }
          rules={{
            pattern: {
              value: /^\d{4}-\d{2}-\d{2}$/,
              message: t('profileForm.errors.validDate'),
            },
            ...getRequiredRuleSelect('documentForm.medicareDateOfExpiry'),
            ...medicareExpireDateRule,
          }}
          name={`documents.${idx}.expiryDate.normalized`}
        />
      ) : (
        <TextFormField
          trim
          testId={{ container: medicareCardF2Qa.normalizedDateOfExpiry }}
          control={control}
          name={`documents.${idx}.expiryDate.normalized`}
          type="date"
          className="basis-[35.5%]"
          rules={{
            ...getRequiredRuleSelect('documentForm.medicareDateOfExpiry'),
            ...medicareExpireDateRule,
          }}
          showErrorText
          label={t('documentForm.dateOfExpiry')}
          placeholder={t('documentForm.dateOfExpiry')}
        />
      )}
      <Controller
        control={control}
        name={`documents.${idx}.supplementaryData.middleNameOnCard`}
        render={({ field: { value, onChange } }) => (
          <ToggleButton
            label={`${t('documentForm.displayMiddleName')}`}
            variant="F2"
            className="basis-[65%] "
            inputClassName="h-[40px] text-sm font-normal"
            value={value}
            options={[
              {
                key: displayMiddleName.fullName,
                label: t('documentForm.fullMiddleName'),
                value:
                  (middleName?.length ?? 0) > 1
                    ? middleName
                    : displayMiddleName.fullName,
                disabled: !middleName || middleName.length === 1,
              },
              {
                key: displayMiddleName.initial,
                label: t('documentForm.middleNameInitial'),
                value: middleName?.at(0) || displayMiddleName.initial,
                disabled: !middleName,
              },
              {
                key: displayMiddleName.noName,
                label: t('documentForm.noMiddleName'),
                value: '',
              },
            ]}
            onChange={onChange}
          />
        )}
      />
      <Controller
        control={control}
        name={`documents.${idx}.supplementaryData.displayMiddleName`}
        render={({ field: { value, onChange } }) => (
          <ToggleButton
            label={`${t('documentForm.multipleLineLabel')}`}
            variant="F2"
            className="basis-[43%] "
            inputClassName="h-[40px] text-sm font-normal"
            value={value}
            options={[
              {
                label: t('documentForm.yes'),
                value: '4',
              },
              {
                label: t('documentForm.no'),
                value: '1',
              },
            ]}
            onChange={onChange}
          />
        )}
      />
      {displayNameField}
    </div>
  )
}
