export const sardineChecksRegex = {
  device: /^device$/i,
  phone: /^phone$/i,
  email: /^email$/i,
  iplocation: /^iplocation$/i,
}
/**
 * @todo [BigOh] hotjar TrackEvent needed to be added for all the cases
 */
export const checksRegex = {
  pep: /^pep$/i,
  pepMedia: /^pep_media$/i,
  chineseId: /^namev$/i,
  blocklist: /^blacklist$/i,
  duplicate: /^duplicate$/i,
  vevo: /^visa$/i,
  fraudCheck: /^fraudcheck$/i,
  fraudList: /^fraudlist$/i,
  attest: /^attest$/i,
  idValidate: /^idvalidate$/i,
  manual: /^manual$/i,
  blocklistSearch: /^blocklist_search$/i,

  kybReport: /^kybReport$/i,
  uboReport: /^uboReport$/i,
  crReport: /^crReport$/i,

  kyc: /^.*_kyc/i,
  onePlusKyc: /^one_plus.*/i,
  twoPlusKyc: /^two_plus.*/i,
  govId: /^id.*/i,
  checkSource: /^check_source*/i,
  supportDocs: /^support_docs*/i,
  deviceRisk: /^DEVICE*/i,
  kycFraud: /^KYCFraud*/i,

  /// from sardine
  ...sardineChecksRegex,
}

export type ChecksTypes = keyof typeof checksRegex
