import { I18nKeys } from 'shared/i18n'
import { DefinedProperties, Nullable } from 'shared/typescript'

import { applicantTransactionsEn } from '../locale/applicant-transactions.en'

export type ChangeTransactionStatusPayload = {
  comment: string
  newStatus: string
  assignedTo: string
  createdBy: string
  transactionId: string
}

export type ChangeTransactionStatusBulkPayload = {
  update: Omit<ChangeTransactionStatusPayload, 'transactionId'>
  filter: { caseIds: string[]; resultTypes: string[] }
}

export type TransactionBulkActionResponse = {
  total: number
  successful: { count: number }
  failed: { count: number }
}

export type TransactionStatusOption = {
  value: ApplicantTransactionsStatus
  tKey: I18nKeys<typeof applicantTransactionsEn>
}

export type ApplicantTransactionsStatus =
  | 'PENDING'
  | 'APPROVED'
  | 'MANUALLY_APPROVED'
  | 'MANUALLY_DECLINED'

export type ApplicantTransactionsIssueType =
  | 'CUSTOMER'
  | 'DEVICE'
  | 'TRANSACTION'
  | 'AML'
  | 'FRAUD'

export type ApplicantTransactionsRiskLevel =
  | 'NONE'
  | 'LOW'
  | 'MEDIUM'
  | 'HIGH'
  | 'VERY_HIGH'
  | 'UNKNOWN'

export type ApplicantTransactionsResponseDetail = {
  transactionId: string
  resultId: string
  status: Nullable<ApplicantTransactionsStatus>
  issueType: Nullable<ApplicantTransactionsIssueType>
  subtype: Nullable<string>
  riskLevel: Nullable<ApplicantTransactionsRiskLevel>
  riskToken: Nullable<{ sessionKey: string; userId: string }>
  activityType: Nullable<
    | 'REGISTRATION'
    | 'LOGIN'
    | 'FIAT_WITHDRAWAL'
    | 'FIAT_DEPOSIT'
    | 'CRYPTO_WITHDRAWAL'
    | 'CRYPTO_DEPOSIT'
  >
  paymentId: Nullable<string>
  paymentMethod: Nullable<'CARD' | 'BANK' | 'WIRE' | 'CRYPTO' | 'OTHER'>
  source: Nullable<string>
  sourceLink: Nullable<string>
  amount: Nullable<{
    value: Nullable<string>
    currencyCode: Nullable<string>
  }>
  transactionTimestamp: Nullable<string>
  deviceId: Nullable<string>
}

export type ApplicantTransactionsResponse = {
  transactions: ApplicantTransactionsResponseDetail[]
  nextPageToken?: Nullable<string>
}

export type ApplicantTransactionsRecord = Omit<
  DefinedProperties<ApplicantTransactionsResponseDetail>,
  'riskToken' | 'amount' | 'resultId'
> &
  Record<'id' | 'sessionKey' | 'amount', string>

export type ApplicantTransactionsProperties = keyof ApplicantTransactionsRecord

export enum TransactionTypes {
  Customer = 'CUSTOMER',
  Aml = 'AML',
  Fraud = 'FRAUD',
}

export const transactionBulkActionOptions: TransactionStatusOption[] = [
  { value: 'MANUALLY_APPROVED', tKey: 'statusOption.manuallyApproved' },
  { value: 'MANUALLY_DECLINED', tKey: 'statusOption.manuallyDeclined' },
]

export const transactionStatusOptions: TransactionStatusOption[] = [
  { value: 'PENDING', tKey: 'statusOption.pending' },
  ...transactionBulkActionOptions,
]

export const transactionRecordMap: Record<
  TransactionTypes,
  { tKey: I18nKeys<typeof applicantTransactionsEn> }
> = {
  [TransactionTypes.Customer]: { tKey: 'activities' },
  [TransactionTypes.Aml]: { tKey: 'transactions' },
  [TransactionTypes.Fraud]: { tKey: 'transactions' },
}
