import React, { useCallback, useMemo, useState } from 'react'

import MenuItem from '@mui/material/MenuItem'

import {
  FrankieButton,
  FrankieIcon,
  FrankieIconName,
  FrankieLoader,
  FrankiePopover,
} from 'frankify/src'

import { ApplicantId } from 'entities/applicant'

import { Show } from 'shared/hoc'
import { useI18n } from 'shared/i18n'
import { useOverlay } from 'shared/overlay'
import { Noop } from 'shared/typescript'

import { APPLICANT_BIOMETRICS_OCR_KEY } from '../../applicant-biometrics-ocr.key'
import { applicantBiometricsOcrEn } from '../../locale/applicant-biometrics-ocr.en'
import { applicantBiometricOcrQa } from '../../qa/applicant-biometric-ocr.qa'
import { useApplicantBiometricReRunCheck } from '../../state/applicant-biometric-re-run-check/applicant-biometric-re-run-check'
import { useApplicantBiometricsOcrChecksState } from '../../state/applicant-biometrics-ocr-check-state/applicant-biometrics-ocr-check.state'
import { useApplicantBiometricsOcrDocumentState } from '../../state/applicant-biometrics-ocr-data-state/applicant-biometrics-ocr-data.state'
import { useApplicantBiometricStatuses } from '../../state/applicant-biometrics-statuses/applicant-biometrics-statuses'
import { useApplicantRefreshIDVResult } from '../../state/applicant-refresh-idv-result/applicant-refresh-idv-result'
import { BannerWrapper } from '../applicant-biometrics-ocr-helper/applicant-biometrics-ocr-helper'
import { ApplicantBiometryReportPanel } from '../applicant-biometry-report-panel/applicant-biometry-report-panel'
import { ApplicantDocumentsReportPanel } from '../applicant-documents-report-panel/applicant-documents-report-panel'
import { ApplicantFacialDuplicateReportPanel } from '../applicant-facial-duplicate-report-panel/applicant-facial-duplicate-report-panel'
import { ApplicantManualOverrideBiometricsStatus } from '../applicant-manual-override-biometrics-status/applicant-manual-override-biometrics-status'
import { ApplicantOcrReportPanel } from '../applicant-ocr-report-panel/applicant-ocr-report-panel'
import { ApplicantSendBiometricLinkModal } from '../applicant-send-biometric-link/applicant-send-biometric-link'

type Props = {
  applicantId: ApplicantId
  className?: string
}

type MenuOption = {
  iconName: FrankieIconName
  label: string
  onClick: Noop
  hidden?: boolean
}

type BiometricDocsProps = {
  docName: string
  applicantId: string
  index: number
  onManualOverride?: (status: Record<string, string>, index: number) => void
}

function BiometricDocs({
  docName,
  applicantId,
  index,
  onManualOverride,
}: BiometricDocsProps) {
  const handleOverrideStatus = (status: Record<string, string>) => {
    onManualOverride?.(status, index)
  }

  return (
    <>
      <h2 className="text-tertiary-grey-900 text-lg mt-10">{docName}</h2>
      <ApplicantBiometryReportPanel
        applicantId={applicantId}
        index={index}
        onManualOverride={handleOverrideStatus}
      />
      <ApplicantDocumentsReportPanel
        applicantId={applicantId}
        index={index}
        onManualOverride={handleOverrideStatus}
      />
      <ApplicantOcrReportPanel
        applicantId={applicantId}
        index={index}
        onManualOverride={handleOverrideStatus}
      />
    </>
  )
}

// eslint-disable-next-line complexity
export function ApplicantBiometricsOcr({ applicantId, className = '' }: Props) {
  const [open, setOpen] = useState(false)
  const [createOverlay, closeOverlay] = useOverlay()
  const getStatuses = useApplicantBiometricStatuses({ applicantId })

  const { mutate: rerunCheck } = useApplicantBiometricReRunCheck({
    applicantId,
  })

  const t = useI18n([APPLICANT_BIOMETRICS_OCR_KEY], {
    keys: applicantBiometricsOcrEn,
  })

  const {
    loading,
    checks: {
      hasBiometrics,
      showRefreshResults,
      showDigitalLicenseBanner,
      updatedBiometricStatus,
      showIDVBanner,
      isIdvOverrideResultPassed,
      hasIdvOverrideResult,
      isFacialDuplicatesEnabled,
      availableBiometryDocs,
    },
  } = useApplicantBiometricsOcrChecksState({ applicantId })

  const {
    data: { documentDetailsData },
  } = useApplicantBiometricsOcrDocumentState({ applicantId, index: 0 })

  const { mutate: refreshIDVResultMutate } = useApplicantRefreshIDVResult({
    applicantId,
  })

  const handleOverrideStatus = (status: Record<string, string>, index = 0) => {
    // Only if the status is manual_pass or manual_fail, rerun the check.
    const finalStatuses = ['manual_pass', 'manual_fail', 'clear']
    const statuses = getStatuses(index)
    const { biometry, document, ocr } = { ...statuses, ...status }

    // Check if all statuses in the array have ocr, document, and biometry properties
    // that are included in the finalStatuses array.
    const shouldRerunCheck = [biometry, document, ocr]
      .map(status => status.toLowerCase())
      .every(status => finalStatuses.includes(status))

    if (shouldRerunCheck) {
      rerunCheck()
    }
  }

  const handleBiometric = useCallback(() => {
    setOpen(false)

    createOverlay(
      <ApplicantSendBiometricLinkModal
        onClose={closeOverlay}
        applicantId={applicantId}
        isRestart={hasBiometrics}
      />,
      {
        className: 'p-0',
        closeButtonClassName: '!top-4 !right-5',
      },
    )
  }, [applicantId, closeOverlay, createOverlay, hasBiometrics])

  const menuOptions = useMemo(
    () =>
      [
        {
          iconName: hasBiometrics ? 'mdiRestart' : 'mdiFaceRecognition',
          label: t(
            hasBiometrics
              ? 'action.restartBiometrics'
              : 'action.startBiometrics',
          ),
          onClick: handleBiometric,
        },
        {
          iconName: 'mdiRestart',
          label: t('action.refreshBiometrics'),
          onClick: () => refreshIDVResultMutate(),
          hidden: !(showRefreshResults && !!documentDetailsData),
        },
      ] satisfies MenuOption[],
    [
      handleBiometric,
      refreshIDVResultMutate,
      documentDetailsData,
      hasBiometrics,
      showRefreshResults,
      t,
    ],
  )

  const fadedBtnClass =
    'text-tertiary-grey-500 border border-solid border-tertiary-grey-400 font-semibold rounded-sm py-2 px-3 hover:text-primary-700 hover:border-primary-700 whitespace-nowrap'

  return (
    <div
      className={`flex flex-col gap-4 text-tertiary-grey-700 ${className}`}
      data-qa={applicantBiometricOcrQa.wrapper}
    >
      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-tertiary-grey-900 text-lg">{t('heading')}</h1>
          <h2 className="text-tertiary-grey-500 font-medium text-sm">
            {t('viewResult')}
          </h2>
        </div>

        <FrankiePopover
          onOpenChange={setOpen}
          open={open}
          popoverRest={{ placement: 'bottom-end' }}
          trigger={
            <FrankieButton
              testId={{ button: applicantBiometricOcrQa.openBtn }}
              noStyles
              className={`border border-tertiary-grey-300 border-solid p-1 rounded-sm ${className} ${
                open ? 'bg-neutral-30 border-transparent' : ''
              } hover:bg-neutral-30 hover:border-transparent`}
              singleIcon={{
                name: 'mdiDotsHorizontal',
                className: 'text-tertiary-grey-300',
              }}
              onClick={() => setOpen(prev => !prev)}
            />
          }
        >
          <div className="bg-mono-white shadow-md rounded-sm py-1">
            {menuOptions
              .filter(({ hidden }) => !hidden)
              .map(({ iconName, label, onClick }) => (
                <MenuItem
                  key={label}
                  onClick={onClick}
                  className="py-2 font-bold text-neutral-90 text-sm hover:text-primary-600 hover:bg-primary-50"
                >
                  <FrankieIcon
                    name={iconName}
                    size="sm"
                    className="text-tertiary-grey-300 me-3"
                  />
                  {label}
                </MenuItem>
              ))}
          </div>
        </FrankiePopover>
      </div>

      <Show showAll>
        <Show.When isTrue={showDigitalLicenseBanner}>
          <BannerWrapper
            iconName="mdiDataMatrixScan"
            message={t('hasCompleted')}
            className="bg-tertiary-amber-50"
            iconClassName="text-tertiary-amber-500"
          >
            <ApplicantManualOverrideBiometricsStatus
              classNameOverride={fadedBtnClass}
              applicantId={applicantId}
              detailType="DL"
              index={1}
              onManualOverride={status => handleOverrideStatus(status, 1)}
            />
            <FrankieButton
              noStyles
              className={fadedBtnClass}
              onClick={handleBiometric}
            >
              {t('action.restartBiometrics')}
            </FrankieButton>
          </BannerWrapper>
        </Show.When>

        <Show.When isTrue={showIDVBanner && !updatedBiometricStatus}>
          <BannerWrapper
            iconName="mdiRestart"
            message={`${hasBiometrics ? `${t('restart')} ` : ''}${t(
              'idvLinkSent',
            )}`}
          />
        </Show.When>

        <Show.When isTrue={hasIdvOverrideResult}>
          <BannerWrapper
            iconName="mdiCheckCircle"
            iconClassName={
              isIdvOverrideResultPassed
                ? 'text-tertiary-green-400'
                : 'text-tertiary-red-500'
            }
            className={
              isIdvOverrideResultPassed
                ? 'bg-tertiary-green-100'
                : 'bg-tertiary-red-50'
            }
            message={
              isIdvOverrideResultPassed
                ? t('biometricManuallyPassed')
                : t('biometricManuallyFailed')
            }
          >
            <ApplicantManualOverrideBiometricsStatus
              applicantId={applicantId}
              detailType="DL"
              index={1}
              onManualOverride={status => handleOverrideStatus(status, 1)}
            />
          </BannerWrapper>
        </Show.When>

        <Show.When isTrue={loading}>
          <FrankieLoader
            testId={{ loader: applicantBiometricOcrQa.loader }}
            loading
            label={
              <div className="text-tertiary-grey-800 font-medium">
                {t('loading.biometric')}
              </div>
            }
            className="min-h-[150px]"
          />
        </Show.When>

        <Show.When isTrue={!hasBiometrics && !loading}>
          <BannerWrapper
            iconName="mdiInformationOutline"
            message={t('notCompleted')}
          />
        </Show.When>

        <Show.When isTrue={isFacialDuplicatesEnabled && !loading}>
          <div className="text-tertiary-grey-900 text-lg mt-10">
            {t('facialDuplicates')}
          </div>
          <ApplicantFacialDuplicateReportPanel applicantId={applicantId} />
        </Show.When>

        <Show.When isTrue={hasBiometrics && !loading}>
          {availableBiometryDocs.map((docName, index) => (
            <BiometricDocs
              index={index}
              key={docName}
              docName={docName}
              applicantId={applicantId}
              onManualOverride={handleOverrideStatus}
            />
          ))}
        </Show.When>
      </Show>
    </div>
  )
}
