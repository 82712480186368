import { useQuery } from '@tanstack/react-query'

import { ApplicantId } from 'entities/applicant'

import { blacklistAPI } from '../../apis/blacklist.api'

export const ApplicantDuplicatesQueryKey = 'applicant-duplicates-query'

type Args = {
  applicantId: ApplicantId
  isEnabled: boolean
}

export const useGetDuplicates = ({ applicantId, isEnabled = true }: Args) =>
  useQuery({
    queryKey: [ApplicantDuplicatesQueryKey, applicantId],
    queryFn: async () => {
      const { data } = await blacklistAPI.getDuplicate(applicantId)
      return data
    },
    enabled: isEnabled,
    cacheTime: 0,
  })
