import { useEffect, useMemo } from 'react'

import { useQuery } from '@tanstack/react-query'

import { useI18n } from 'shared/i18n'
import { notification } from 'shared/notification'

import { applicantApi } from '../../api/applicant.api'
import { APPLICANT_KEY, applicantEn } from '../../locale/applicant.en'
import { ApplicantFraudCheckResponse } from '../../model/applicant-response.model'

export const ApplicantFraudCheckQueryKey = ['applicant-fraud-check']

type Args = {
  entityId?: string
  isDisabled?: boolean
}

export const useApplicantDeviceFraudCheckQuery = ({
  entityId,
  isDisabled = false,
}: Args) => {
  const t = useI18n([APPLICANT_KEY], { keys: applicantEn })

  const query = useQuery<ApplicantFraudCheckResponse>({
    enabled: !!entityId && !isDisabled,
    queryKey: [ApplicantFraudCheckQueryKey, entityId, isDisabled],
    queryFn: async () => {
      const { data } = await applicantApi.getApplicantDeviceFraudChecks(
        entityId!,
      )

      return data
    },
  })

  /**
   * Kyc Device Fraud Review Url
   */
  const kycDeviceFraudReviewUrl = useMemo(() => {
    if (query.isFetched) {
      const fraudCheckData = query.data
      const checkPerformedBySardine = fraudCheckData?.device?.find(
        item => item.checkPerformedBy === 'sardine',
      )
      return checkPerformedBySardine?.resultNotes?.device_sardine_url
    }

    return null
  }, [query.data, query.isFetched])

  /**
   * Error Notification
   */
  useEffect(() => {
    if (query.isError) {
      notification.error(t('error.fraudChecks'))
    }
  }, [query.isError, t])

  return { ...query, kycDeviceFraudReviewUrl }
}
