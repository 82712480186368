import { useMemo } from 'react'

import { useGetWorkflowEventsData } from 'entities/entity'

import { useI18n } from 'shared/i18n'

import { workflowEventsEn } from '../locale/workflow-events.en'
import { RiskFactorRow } from '../model/applicant-workflow-events.model'
import { WORKFLOW_EVENTS_KEY } from '../workflow-events.key'

type Args = {
  entityId: string
}
export const useRiskScore = ({ entityId }: Args) => {
  const { data } = useGetWorkflowEventsData({ entityId })
  const t = useI18n(WORKFLOW_EVENTS_KEY, { keys: workflowEventsEn })
  const summary = data?.workflowSummaries?.at(0)

  const riskScore: RiskFactorRow[] = useMemo(() => {
    const riskScoreArr =
      summary?.riskAssessment?.riskFactors?.map(risk => ({
        riskFactor: risk.description,
        id: risk.riskFactorId || '',
        riskScore: risk.score,
        riskValue: risk.value,
      })) ?? []

    riskScoreArr.push({
      riskFactor: t('riskScore.workflowRiskScore'),
      id: summary?.workflowExecutionId as string,
      riskScore: summary?.riskAssessment?.workflowRiskScore,
      riskValue: summary?.riskAssessment?.workflowRiskLevel,
    })

    return riskScoreArr
  }, [summary])

  return {
    riskScore,
  }
}
