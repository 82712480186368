/* eslint-disable complexity */
import React, { forwardRef, useEffect, useRef, useState } from 'react'

import {
  FrankieIcon,
  FrankieImage,
  FrankieLoader,
  FrankieTextField,
  useFrankieMenu,
} from 'frankify/src'

import { Nullable } from 'shared/typescript'

import { ICountryOrSubdivision } from './model'
import { SelectorOption } from './ui/selector-option'
import { COUNTRIES_FLAGS } from '../../asset'
import { ICountryOption } from '../../model/country.model'
import { countryQa } from '../../qa/country.qa'

type Props = {
  options: ICountryOption[] | null
  value: ICountryOrSubdivision
  onChange: (value: ICountryOrSubdivision) => void
  disabled?: boolean
  className?: string
}

const MAX_DROPDOWN_HEIGHT = 372

export const CountriesCoveredSelector = forwardRef<HTMLInputElement, Props>(
  (
    { options, value, onChange, disabled = false, className = '' }: Props,
    ref,
  ) => {
    const { parentRef, isOpen, handleOpen, handleToggle, handleClose } =
      useFrankieMenu()

    const handleSelectOption = (option: ICountryOrSubdivision) => () => {
      onChange(option)
      handleClose()
    }

    const handleChange = () => {
      handleOpen()
    }

    const singleOption = options ? options.length === 1 : false
    const shouldShowShevronIcon = !disabled && !singleOption

    const scrollableContentRef = useRef<HTMLUListElement>(null)
    const scrollContainerRef = useRef<HTMLDivElement>(null)
    const scrollData = useRef<{ scrollTopValue: number }>({ scrollTopValue: 0 })

    const [showScrollHint, toggleShowShevronHint] = useState(true)

    useEffect(() => {
      let scrollHeight: Nullable<number> = null

      const handleScroll = () => {
        if (scrollableContentRef.current?.scrollTop === scrollHeight) {
          toggleShowShevronHint(false)
        } else {
          toggleShowShevronHint(true)
        }

        if (scrollableContentRef.current) {
          scrollData.current.scrollTopValue =
            scrollableContentRef.current.scrollTop
        }
      }

      if (scrollableContentRef.current && scrollContainerRef.current) {
        scrollHeight =
          scrollContainerRef.current.getBoundingClientRect().height -
          MAX_DROPDOWN_HEIGHT
        scrollableContentRef.current.addEventListener('scroll', handleScroll)
      }

      if (isOpen) {
        if (scrollData.current.scrollTopValue && scrollableContentRef.current) {
          scrollableContentRef.current.scrollTop =
            scrollData.current.scrollTopValue
        }
      }

      return () => {
        if (scrollableContentRef.current) {
          scrollableContentRef.current.removeEventListener(
            'scroll',
            handleScroll,
          )
        }
      }
    }, [isOpen])

    return (
      <div className={`relative ${className}`} ref={parentRef}>
        <div>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label>
            <FrankieTextField
              className="caret-transparent cursor-pointer"
              inputClassName="pl-[48px] pr-[30px] bg-tertiary-grey-50 text-tertiary-grey-700 !rounded-r-none !border-r focus-visible:text-tertiary-grey-500"
              value={
                value.subdivision
                  ? `${value.country.alpha3code} - ${value.subdivision.name}`
                  : value.country.name
              }
              onChange={handleChange}
              disabled={disabled}
              ref={ref}
              size="sm"
              onClick={handleToggle}
              testId={{
                input: countryQa.defaultCountrySelector,
              }}
            />
            <FrankieImage
              className="absolute rounded-xs h-4 bottom-2.5 left-4 cursor-pointer"
              src={COUNTRIES_FLAGS[value.country.alpha3code]}
            />
            {shouldShowShevronIcon && (
              <FrankieIcon
                className="absolute bottom-2.5 right-4 text-tertiary-grey-700 cursor-pointer"
                name={isOpen ? 'mdiChevronUp' : 'mdiChevronDown'}
                size="xs"
              />
            )}
          </label>
          <div className="relative">
            {isOpen && (
              <div className="absolute z-20 py-1 pr-1 z-10 bg-mono-white shadow-md rounded-sm top-1 w-full">
                <ul
                  ref={scrollableContentRef}
                  className="pr-1 w-full !max-h-[372px] overflow-y-auto scrollbar-sm"
                  id="scroll-container"
                >
                  <div ref={scrollContainerRef} id="scrollable-content">
                    {!options && (
                      <FrankieLoader
                        size="sm"
                        loading
                        className="py-2 px-4 min-h-[193px]"
                      />
                    )}
                    {options &&
                      options.map(option =>
                        option.subdivision ? (
                          option.subdivision.map(subdivision => (
                            <SelectorOption
                              key={subdivision.subdivisionCode}
                              name={`${option.alpha3code} - ${subdivision.name}`}
                              icon={COUNTRIES_FLAGS[option.alpha3code]}
                              handleClick={handleSelectOption({
                                country: option,
                                subdivision,
                              })}
                              selected={
                                subdivision.subdivisionCode ===
                                value.subdivision?.subdivisionCode
                              }
                            />
                          ))
                        ) : (
                          <SelectorOption
                            key={option.alpha3code}
                            name={option.name}
                            icon={COUNTRIES_FLAGS[option.alpha3code]}
                            handleClick={handleSelectOption({
                              country: option,
                            })}
                            selected={
                              option.alpha3code === value.country.alpha3code
                            }
                          />
                        ),
                      )}
                  </div>
                </ul>
                {showScrollHint && (
                  <div id="chevron-container" className="flex justify-center">
                    <FrankieIcon name="mdiChevronDown" size="xs" />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    )
  },
)

export type { ICountryOrSubdivision } from './model'
