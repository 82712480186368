import React from 'react'

import { FrankieCheckbox, FrankieRadio } from 'frankify/src'

import { userManagementQa } from 'features/user-management'

import { IRole, RoleId } from 'entities/role'

type Props = {
  options: IRole[]
  values: RoleId[]
  onChange?: (values: RoleId[]) => void
  type?: 'radio' | 'checkbox'
}

export function CheckboxGroup({
  options,
  values,
  onChange,
  type = 'checkbox',
}: Props) {
  const handleChangeCheck = (id: RoleId, newChecked: boolean) => () => {
    if (onChange) {
      if (type === 'radio') {
        onChange([id])
      } else if (newChecked) {
        onChange([...values, id])
      } else onChange(values.filter(value => value !== id))
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const Component = type === 'radio' ? FrankieRadio : FrankieCheckbox
  return (
    <div
      data-qa={userManagementQa.createUserForm_inputRoleGroup}
      className="max-h-[312px] overflow-y-auto scrollbar mt-2"
    >
      {options.map(option => {
        const checked = values.includes(option.id)
        return (
          // eslint-disable-next-line jsx-a11y/label-has-associated-control
          <label className="flex mb-2 items-center" key={option.id}>
            <Component
              size="sm"
              checked={checked}
              onChange={handleChangeCheck(option.id, !checked)}
              testId={{
                input: userManagementQa.createUserForm_inputRoleOption,
              }}
            />
            <div className="inline-block text-md font-normal ml-2 cursor-pointer">
              {option.role}
            </div>
          </label>
        )
      })}
    </div>
  )
}
