import React, { useMemo } from 'react'

import { Trans } from 'react-i18next'

import { FrankieIcon, FrankieLoader } from 'frankify/src'

import {
  ApplicantId,
  ApplicantIssueActionBadgeData,
  ApplicantIssueActionBadge,
  useApplicantPaths,
} from 'entities/applicant'

import { DateFormatTypes, formatDate } from 'shared/date-time'
import { externalLinks } from 'shared/external-links'
import { useI18n } from 'shared/i18n'
import { SuggestionString } from 'shared/typescript'

import { APPLICANT_GENERAL_INFORMATION_KEY } from '../../applicant-general-information.key'
import { applicantGeneralInformationEn } from '../../locale/applicant-general-information.en'
import { normalizeRiskData } from '../../model/applicant-issues.model'
import { applicantCheckSummaryQa } from '../../qa/applicant-general-information.qa'
import { useApplicantCheckSummaryState } from '../../state/applicant-check-summary-state/applicant-check-summary-state'
import { ApplicantActionMenu } from '../applicant-action-menu/applicant-action-menu'
import { ApplicantStatusBanner } from '../applicant-status-banner/applicant-status-banner'

type Props = {
  applicantId: ApplicantId
  className?: string
}

type RedirectIssueTags = SuggestionString<'blacklist' | 'duplicate'>

/**
 * Legacy Applicant Overview
 */
export function ApplicantCheckSummary({ applicantId, className = '' }: Props) {
  const t = useI18n([APPLICANT_GENERAL_INFORMATION_KEY], {
    keys: applicantGeneralInformationEn,
  })

  const { getApplicantPath } = useApplicantPaths()

  const {
    checkSummary,
    isManualKyc,
    isManualBlocklist,
    isLoading: loadingCheckSummary,
  } = useApplicantCheckSummaryState({
    applicantId,
  })

  const redirectionPath: Record<RedirectIssueTags, string> = useMemo(
    () => ({
      blacklist: getApplicantPath('blocklistMatches'),
      duplicate: getApplicantPath('duplicateMatches'),
    }),
    [getApplicantPath],
  )

  const { lastCheckedDate, kycMethod, amlList, riskLevel } = useMemo(() => {
    const lastCheckedDate = checkSummary?.checkDate
    const kycMethod = isManualKyc ? t('manual') : t('electronic')

    const amlList: ApplicantIssueActionBadgeData[] =
      checkSummary?.alertList?.map(item => ({
        type: item.term,
        ...(item.extra
          ? {
              redirectTo: redirectionPath[item.extra.action],
              masterLabel: item.extra.label,
              isDisabled: isManualBlocklist,
            }
          : {}),
      })) ?? []

    /**
     *  Default to clear if no alert list and check summary is passed
     */
    if (
      amlList.length === 0 &&
      checkSummary?.status.type &&
      ['passed', 'pass_manual'].includes(checkSummary.status.type)
    ) {
      amlList.push({ type: 'clear' })
    }

    const riskValue = checkSummary?.risk.class

    const riskLevel = normalizeRiskData(riskValue)

    return {
      lastCheckedDate,
      kycMethod,
      amlList,
      riskLevel,
    }
  }, [checkSummary, isManualBlocklist, isManualKyc, redirectionPath, t])

  const detailContainerClass =
    'flex items-center border-b border-tertiary-grey-200 pb-1 my-2'
  const detailKeyClass = 'text-tertiary-grey-500 min-w-[135px]'

  return (
    <FrankieLoader
      loading={loadingCheckSummary}
      className=""
      testId={{ loader: applicantCheckSummaryQa.loader }}
    >
      <div
        className={`flex flex-col gap-4 rounded-md border border-tertiary-grey-200 text-tertiary-grey-700 ${className}`}
      >
        <div className="flex px-6 pt-6 justify-between">
          <div className="text-xl text-secondary-900 font-bold">
            {t('checkSummary')}
          </div>
          <ApplicantActionMenu applicantId={applicantId} />
        </div>

        <ApplicantStatusBanner applicantId={applicantId} />

        <div className="pb-6 px-6">
          <div className="my-4">
            <div className={detailContainerClass}>
              <div className={detailKeyClass}>{t('riskLevel')}</div>

              <ApplicantIssueActionBadge
                type={riskLevel.type}
                masterLabel={riskLevel.label}
                className="flex-grow-0 min-w-[165px]"
              />
            </div>

            <div className={detailContainerClass}>
              <div className={detailKeyClass}>{t('lastCheckDate')}</div>
              <div>
                {lastCheckedDate
                  ? formatDate(lastCheckedDate, DateFormatTypes.DateNumbers)
                  : ''}
              </div>
            </div>

            <div className={detailContainerClass}>
              <div className={detailKeyClass}>{t('issues')}</div>

              <div className="flex gap-2 flex-wrap">
                {amlList.map(item => (
                  <ApplicantIssueActionBadge
                    className="flex-grow-0"
                    showTooltip
                    {...item}
                  />
                ))}
              </div>
            </div>

            <div className={detailContainerClass}>
              <div className={detailKeyClass}>{t('kycMethod')}</div>
              <div>{kycMethod}</div>
            </div>
          </div>
          <div className="flex p-4 gap-2 bg-tertiary-grey-100 rounded-sm">
            <FrankieIcon
              name="mdiInformation"
              className="text-tertiary-grey-400"
              testId={{ icon: applicantCheckSummaryQa.frankieIcon }}
            />

            <div>
              <div className="font-bold">{t('legacyCustomerView')}</div>

              <div className="leading-tight">
                <Trans
                  i18nKey="applicant_general_information:legacyCustomerViewInfo"
                  components={{
                    link1: (
                      <a
                        href={externalLinks.updateLegacyProfile}
                        target="_blank"
                        rel="noreferrer"
                        className="text-primary-500"
                      >
                        here
                      </a>
                    ),
                    link2: (
                      <a
                        href={externalLinks.whatIsRecipe}
                        target="_blank"
                        rel="noreferrer"
                        className="text-primary-500"
                      >
                        here
                      </a>
                    ),
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </FrankieLoader>
  )
}
