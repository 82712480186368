import { useGetWorkflowEventsData } from 'entities/entity'

type Props = {
  entityId: string
}

export const useAmlUnreviewd = ({ entityId }: Props) => {
  const { data } = useGetWorkflowEventsData({ entityId })

  const isAmlYetToReviewed = data?.workflowSummaries
    ?.at(0)
    ?.workflowResultData?.workflowStepResults?.find(
      step => step.stepName === 'AML',
    )
    ?.processResults?.some(
      process => !process.manualStatus && process.class === 'AML',
    )
  return { isAmlYetToReviewed }
}
