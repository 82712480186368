import { useMemo } from 'react'

import {
  AmlIssuesTypes,
  ProcessResultManualStatusEnumAML,
  useEntityAmlData,
  useGetWorkflowEventsData,
  WorkflowStepSummaryAML,
} from 'entities/entity'
import { useWorkflowListQuery } from 'entities/workflow'

import { I18nKeys, useI18n } from 'shared/i18n'

import { INDIVIDUAL_AML_SCREENING_KEY } from '../../individual-aml-screening.key'
import { individualAmlScreeningEn } from '../../locale/individual-aml-screening.en'
import {
  AmlFilters,
  getAmlIssues,
} from '../../model/individual-aml-screening/individual-aml-screening.model'

type Args = {
  entityId: string
  workflowExecutionId?: string
  filters?: AmlFilters
  isNonValid?: boolean
}

export const useAmlPRORowData = ({
  entityId,
  workflowExecutionId,
  filters,
  isNonValid = false,
}: Args) => {
  const t = useI18n([INDIVIDUAL_AML_SCREENING_KEY], {
    keys: individualAmlScreeningEn,
  })

  const { data: workflow } = useGetWorkflowEventsData({ entityId })
  const { data, refetch } = useEntityAmlData({
    entityId,
    workflowExecutionId,
    isNonValid,
  })

  const rows = useMemo(() => {
    const unFilteredRows = data.processResults.flatMap(process => {
      if (process.supplementaryData?.type === 'AML') {
        const date = process.supplementaryData.matchData?.date
        const year = date ? new Date(date).getFullYear().toString() : null

        return {
          id: process.processResultId,
          name: process.supplementaryData.matchData?.name,
          matchStrength:
            process.providerResult?.sourceNormalized === 'ComplyAdvantage'
              ? ''
              : process.supplementaryData.matchData?.strength,
          countries: process.supplementaryData.matchData?.countries?.map(
            country => country.code,
          ),
          dateOfBirth: year,
          address: process.supplementaryData.matchData?.countries
            ?.map(i => i.code)
            .join(', '),
          resolvedBy: process.updatedBy,
          resolvedAt: process.updatedAt,
          workflow: process.workflowName,
          attempts: process.workflowAttempts,
          issues: getAmlIssues(process.supplementaryData, t),
          matchStatus: process.manualStatus,
          entityId: process.entityId,
          query: process.query,
        }
      }
      return []
    })

    const summary = workflow?.workflowSummaries
      ?.find(i => i.workflowExecutionId === workflowExecutionId)
      ?.workflowResultData?.workflowStepResults?.find(i => i.stepName === 'AML')
      ?.summary as WorkflowStepSummaryAML | undefined

    const searchQuery = summary?.providerSummaries?.at(0)?.searchQuery

    return unFilteredRows.filter(row => {
      const amlMatch = filters?.amlMatch.length
        ? filters.amlMatch.some(match => {
            const rowMatch = row[match as 'dateOfBirth' | 'address']

            if (match === 'address') {
              if (!searchQuery) return true
              if (searchQuery.address?.trim().toUpperCase() === 'ALL')
                return Number(row.countries?.filter(Boolean)?.length) > 0

              if (searchQuery.address === '' || searchQuery.address === '-')
                return !row.countries?.filter(Boolean)?.length

              // Handle multiple countries case (e.g., "THA, PHI")
              const searchCountryList = searchQuery.address
                ?.split(',')
                ?.map(c => c.trim().toUpperCase())

              return row.countries?.some(rowCountry => {
                const upperRowCountry = rowCountry.trim().toUpperCase()

                return searchCountryList?.some(searchCountry => {
                  // Exact match (case-insensitive)
                  if (searchCountry === upperRowCountry) return true

                  // 3-letter to 2-letter comparison
                  if (
                    searchCountry.length === 3 &&
                    upperRowCountry.length === 2
                  ) {
                    return searchCountry.slice(0, 2) === upperRowCountry
                  }

                  // 2-letter to 3-letter comparison
                  if (
                    searchCountry.length === 2 &&
                    upperRowCountry.length === 3
                  ) {
                    return searchCountry === upperRowCountry.slice(0, 2)
                  }

                  return false
                })
              })
            }

            return (
              rowMatch &&
              row.query?.[match] &&
              row.query[match]?.includes(rowMatch)
            )
          })
        : true
      const matchStatus = filters?.matchStatus.length
        ? filters.matchStatus.some(issue =>
            (row.matchStatus ?? 'potentialMatch').includes(issue),
          )
        : true
      const issues = filters?.issues.length
        ? filters.issues.every(issue => row.issues.includes(issue))
        : true
      const workflow = filters?.workflow.length
        ? filters.workflow.includes(row.workflow ?? '')
        : true

      return amlMatch && matchStatus && issues && workflow
    })
  }, [
    workflowExecutionId,
    data.processResults,
    workflow?.workflowSummaries,
    filters?.amlMatch,
    filters?.issues,
    filters?.matchStatus,
    filters?.workflow,
    t,
  ])

  return { rows, refetch }
}

export type AmlPRORow = ReturnType<typeof useAmlPRORowData>['rows'][number]

type AmlOptionsType = {
  label: I18nKeys<typeof individualAmlScreeningEn>
  value: string
}

export const useAmlFiltersOptions = () => {
  const { data } = useWorkflowListQuery()

  const amlMatchOptions: AmlOptionsType[] = [
    {
      label: 'yearOfBirth',
      value: 'dateOfBirth',
    },
    {
      label: 'residence',
      value: 'address',
    },
  ]

  const matchStatusOptions: AmlOptionsType[] = [
    {
      label: 'matchStatus.truePositive',
      value: ProcessResultManualStatusEnumAML.TRUE_POSITIVE,
    },
    {
      label: 'matchStatus.unknown',
      value: ProcessResultManualStatusEnumAML.UNKNOWN,
    },
    {
      label: 'matchStatus.potentialMatch',
      value: 'potentialMatch',
    },
    {
      label: 'matchStatus.falsePositive',
      value: ProcessResultManualStatusEnumAML.FALSE_POSITIVE,
    },
  ]

  const amlIssuesOptions: AmlOptionsType[] = [
    {
      label: 'amlIssues.pep',
      value: AmlIssuesTypes.PEP,
    },
    {
      label: 'amlIssues.sanction',
      value: AmlIssuesTypes.SANCTION,
    },
    {
      label: 'amlIssues.watchlist',
      value: AmlIssuesTypes.WATCH_LIST,
    },
    {
      label: 'amlIssues.adverseMedia',
      value: AmlIssuesTypes.ADVERSE_MEDIA,
    },
  ]

  const workflowOptions =
    data?.map(workflow => ({
      label: workflow.workflowName,
      value: workflow.workflowName,
    })) ?? []

  return {
    amlMatchOptions,
    matchStatusOptions,
    amlIssuesOptions,
    workflowOptions,
  }
}
