import {
  applicantPotentialMatchesEn,
  potentialMatchFieldLabel,
} from 'features/applicant-potential-matches'

import { BlocklistAttributeTypes } from 'entities/applicant'

import { I18nFunction } from 'shared/i18n'

export enum FormTypes {
  ADD = 'add',
  Edit = 'edit',
}

export enum BlocklistedInputTypes {
  BlocklistType = 'blocklistType',
  BlocklistReason = 'blocklistReason',
  Comment = 'comment',
  PhoneNumber = 'phoneNumber',
  Email = 'email',
}

export type BlacklistedFormInputs = {
  [BlocklistedInputTypes.BlocklistType]: string
  [BlocklistedInputTypes.BlocklistReason]: string
  [BlocklistedInputTypes.Comment]: string
  [BlocklistedInputTypes.PhoneNumber]: string
  [BlocklistedInputTypes.Email]: string
}

export const BlocklistedFormDefaultValue: BlacklistedFormInputs = {
  [BlocklistedInputTypes.BlocklistType]: '',
  [BlocklistedInputTypes.BlocklistReason]: '',
  [BlocklistedInputTypes.Comment]: '',
  [BlocklistedInputTypes.PhoneNumber]: '',
  [BlocklistedInputTypes.Email]: '',
}

export const capitalizeFirstChar = (str: string) => {
  const value = str.toLowerCase()
  return value ? value.charAt(0).toUpperCase() + value.slice(1) : ''
}

export const getBlocklistAttributeOptions = (
  t: I18nFunction<typeof applicantPotentialMatchesEn>,
) =>
  Object.values(BlocklistAttributeTypes)
    .filter(attr => attr !== BlocklistAttributeTypes.ENTIRE_ENTITY)
    .map(attr => ({
      label: capitalizeFirstChar(
        potentialMatchFieldLabel(BlocklistAttributeTypes[attr], t),
      ),
      value: attr,
    }))
