import { useState, useEffect } from 'react'

import { useQuery } from '@tanstack/react-query'

import { ApplicantId } from 'entities/applicant'
import { TrustDeedTypes } from 'entities/applicant/model/applicant-trust-analyser.model'

import { useI18n } from 'shared/i18n'
import { notification } from 'shared/notification'

import { applicantSupportingDocumentApi } from '../../api/applicant-supporting-document.api'
import { APPLICANT_SUPPORTING_DOCUMENTS_KEY } from '../../applicant-supporting-documents.key'
import { applicantSupportingDocumentsEn } from '../../locale/applicant-supporting-documents.en'
import { ITrustAnalyse } from '../../model/applicant-supporting-documents.model'
import {
  ApplicantUploadedTrustDeedRecord,
  TrustDeedAssociatedParty,
} from '../../model/applicant-supporting-trust-deed.model'
import {
  applicantTrustDeedDocumentQueryKey,
  trustDeedAnalysisResultQueryKey,
  trustDeedAnalysisStatusQueryKey,
  useQueryUpdater,
} from '../trust-deeds-manage/trust-deeds-manage'

type Args = {
  applicantId: ApplicantId
}

type AnalysisStatusArgs = {
  documentId?: string
  entityId?: string
}

type AnalysisResultArgs = {
  applicantId?: ApplicantId
  documentId?: string
  status?: TrustDeedTypes
}

export const useApplicantTrustDeedDocumentQuery = ({ applicantId }: Args) => {
  const t = useI18n([APPLICANT_SUPPORTING_DOCUMENTS_KEY], {
    keys: applicantSupportingDocumentsEn,
  })

  return useQuery({
    enabled: !!applicantId,
    queryKey: [applicantTrustDeedDocumentQueryKey, applicantId],
    queryFn: async () => {
      try {
        const { data } = await applicantSupportingDocumentApi.getTrustDeeds(
          applicantId,
        )

        return data.map(item => new ApplicantUploadedTrustDeedRecord(item))
      } catch (err) {
        notification.error(t('errorFetchingDocs'))
        return [] as ApplicantUploadedTrustDeedRecord[]
      }
    },
  })
}

export const useTrustDeedAnalysisStatusQuery = ({
  documentId: docId,
  entityId: applicantId,
}: AnalysisStatusArgs) => {
  const { updateTrustListStatusQuery } = useQueryUpdater()

  const t = useI18n([APPLICANT_SUPPORTING_DOCUMENTS_KEY], {
    keys: applicantSupportingDocumentsEn,
  })

  const [ids, setIds] = useState<{ documentId: string; entityId: string }>({
    documentId: docId || '',
    entityId: applicantId || '',
  })

  useEffect(() => {
    if (docId && applicantId) {
      setIds({ documentId: docId, entityId: applicantId })
    }
  }, [applicantId, docId])

  const { documentId, entityId } = ids

  return useQuery({
    enabled: !!documentId,
    queryKey: [trustDeedAnalysisStatusQueryKey, documentId],
    queryFn: async () => {
      try {
        const { data } =
          await applicantSupportingDocumentApi.getTrustDeedAnalysisStatus(
            documentId,
          )

        return data
      } catch (err) {
        notification.error(t('errorFetchingAnalysisStatus'))
        return undefined
      }
    },
    select: data => {
      updateTrustListStatusQuery(data as TrustDeedTypes, entityId, documentId)
      return data
    },
  })
}

export const useTrustDeedAnalysisResultQuery = ({
  applicantId: entityId,
  documentId: docId,
  status,
}: AnalysisResultArgs) => {
  const [resultIds, setResultIds] = useState<{
    applicantId: string
    documentId: string
  }>({
    applicantId: '',
    documentId: '',
  })
  const { applicantId, documentId } = resultIds

  useEffect(() => {
    if (entityId && docId && !applicantId && !documentId) {
      setResultIds({ applicantId: entityId, documentId: docId })
    }
  }, [docId, entityId, applicantId, documentId])

  const t = useI18n([APPLICANT_SUPPORTING_DOCUMENTS_KEY], {
    keys: applicantSupportingDocumentsEn,
  })

  const isCompleteOrConfirmed =
    status === TrustDeedTypes.COMPLETE || status === TrustDeedTypes.CONFIRMED

  return useQuery({
    enabled: !!applicantId && !!documentId && isCompleteOrConfirmed,
    queryKey: [trustDeedAnalysisResultQueryKey, applicantId, documentId],
    queryFn: async () => {
      try {
        const { data } = await applicantSupportingDocumentApi.getAnalysisResult(
          applicantId,
          documentId,
        )

        return data.analyses[0]
      } catch (err) {
        notification.error(t('errorFetchingAnalysisResult'))
        return {} as ITrustAnalyse
      }
    },
  })
}

export const trustDeedAssociatedPartiesQueryKey =
  'trust-deed-analysis-associated-parties'

export const useTrustDeedAssociatedPartiesQuery = ({
  applicantId,
  trustAnalysisId,
  analysisStatus,
}: {
  applicantId?: string
  trustAnalysisId?: string
  analysisStatus?: TrustDeedTypes
}) => {
  const isCompleteOrConfirmed =
    analysisStatus === TrustDeedTypes.COMPLETE ||
    analysisStatus === TrustDeedTypes.CONFIRMED

  return useQuery({
    enabled: !!applicantId && !!trustAnalysisId && isCompleteOrConfirmed,
    queryKey: [trustDeedAssociatedPartiesQueryKey, trustAnalysisId],
    queryFn: async () => {
      try {
        const { data } =
          await applicantSupportingDocumentApi.getTrustAnalysisAssociatedParties(
            {
              applicantId: applicantId ?? '',
            },
          )

        return data
      } catch (err) {
        return [] as TrustDeedAssociatedParty[]
      }
    },
  })
}
