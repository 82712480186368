import React, { useEffect } from 'react'

import { useNavigate, useParams } from 'react-router-dom'

import { FrankieButton, FrankieIcon } from 'frankify/src'

import { IndividualAmlScreening } from 'features/individual-aml-screening'

import { ApplicantId } from 'entities/applicant'
import { useEntityDataQuery, useFrankie2R2Customer } from 'entities/entity'
import { useApplicantRoute } from 'entities/routing'

import { useI18n } from 'shared/i18n'
import { TrackingEventsTypes, trackingManager } from 'shared/tracking'

import { INDIVIDUAL_AML_SCREENING_PAGE_KEY } from '../individual-aml-screening-page.key'
import { individualAmlScreeningPageEn } from '../locale/individual-aml-screening-page.en'

type Props = {
  applicantIdParamKey: ApplicantId
}

export function IndividualAmlScreeningPage({ applicantIdParamKey }: Props) {
  const t = useI18n(INDIVIDUAL_AML_SCREENING_PAGE_KEY, {
    keys: individualAmlScreeningPageEn,
  })

  const params = useParams()
  const paramsEntityId = params[applicantIdParamKey]
  const { data } = useEntityDataQuery(paramsEntityId)

  const isFrankie2 = useFrankie2R2Customer()

  const navigate = useNavigate()

  useEffect(() => {
    trackingManager.trackWithProps(
      TrackingEventsTypes.AmlScreeningPageShowIndividual,
      {
        entityId: paramsEntityId,
      },
    )
  }, [paramsEntityId])

  const handleBack = () => {
    navigate(-1)
  }
  const { generateRoute } = useApplicantRoute()

  if (!paramsEntityId || !isFrankie2) return null

  return (
    <div className=" z-0 relative max-h-[calc(100vh-70px)] overflow-y-auto scrollbar h-full ">
      <div className=" text-tertiary-grey-700 px-8 mb-28 mx-auto h-full">
        <div className="w-full  mx-auto sticky bg-mono-white top-0 flex flex-col justify-items-start z-[100] item-center pt-6 pb-4">
          <FrankieButton
            noStyles
            startIcon={{
              name: 'mdiArrowLeft',
              size: 'xs',
              className: 'text-tertiary-grey-500',
            }}
            onClick={handleBack}
            className="inline-flex gap-1"
          >
            <span className="text-xs text-tertiary-grey-500">{t('back')}</span>
          </FrankieButton>
          <div className="mt-4 flex gap-1 items-center">
            <FrankieButton
              noStyles
              data-hj-suppress
              className=" text-xs text-tertiary-grey-600 cursor-pointer"
              onClick={() => {
                navigate(
                  generateRoute({
                    routeKey: 'individualProfileInsight',
                    overrideParams: { applicantId: paramsEntityId },
                  }),
                )
              }}
              aria-label={data?.individual?.name?.displayName}
            >
              {data?.individual?.name?.displayName}
            </FrankieButton>
            <FrankieIcon
              className="text-tertiary-grey-400"
              name="mdiChevronRight"
              size="xs"
            />

            <div className="text-xs text-tertiary-grey-700">{t('title')} </div>
          </div>
          <div className="pt-2 text-2xl font-bold text-tertiary-grey-800 leading-tight">
            {t('title')}
          </div>
        </div>

        <IndividualAmlScreening entityId={paramsEntityId} defaultOffset={10} />
      </div>
    </div>
  )
}
