import { cva } from 'cva'

export const FrankieTextFieldStyle = cva(
  [
    'truncate',
    'w-full',
    'rounded-sm',
    'border',
    'border-solid',
    'box-border',
    'px-4',
    'bg-mono-white',
    'text-mono-90',
    'text-sm',
    'cursor-text',
    'placeholder:text-mono-60',
    'focus-visible:border-primary-800',
    'focus-visible:outline-0',
    'disabled:border-tertiary-grey-300',
    'disabled:bg-tertiary-grey-50',
    'disabled:text-tertiary-grey-400',
  ],
  {
    variants: {
      size: {
        sm: ['h-9', 'py-2'],
        md: ['h-10', 'py-3'],
        lg: ['h-13', 'py-3'],
      },
      variant: {
        primary: ['border-tertiary-grey-300'],
        light: ['border-tertiary-grey-200'],
      },
      inputError: {
        true: [
          '!border-tertiary-red-500',
          '!bg-tertiary-red-50',
          '!text-tertiary-red-600',
          'placeholder:!text-tertiary-red-50',
        ],
      },
      startIcon: {
        true: ['pl-[42px]'],
      },
      closeButton: {
        true: ['pr-8'],
      },
    },
  },
)
