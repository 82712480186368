// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react'

import {
  RiskLevel,
  WorkflowExecutionResultEnum,
  WorkflowExecutionSummary,
  WorkflowStatusKeysTypes,
  getWorkflowStatusKey,
} from 'entities/entity'

import { I18nFunction } from 'shared/i18n'

import { workflowEventsEn } from '../locale/workflow-events.en'
import { applicantWorkflowEventsQa } from '../qa/applicant-workflow-events.qa'

export const getWorkflowEventResultConfig = <
  IType extends I18nFunction<typeof workflowEventsEn>,
>(
  t: IType,
  type?: WorkflowExecutionSummary,
) => {
  const status = getWorkflowStatusKey(type)
  switch (status) {
    case WorkflowStatusKeysTypes.PASSED:
      return {
        options: [
          {
            label: t('options.needsAttention'),
            value: WorkflowExecutionResultEnum.REVIEW,
            testId: applicantWorkflowEventsQa.needAttentionItem,
          },
          {
            label: t('options.manuallyFailed'),
            value: WorkflowExecutionResultEnum.FAIL,
            testId: applicantWorkflowEventsQa.manuallyFailedItem,
          },
        ],
        styles:
          '!bg-tertiary-green-100 !text-tertiary-green-600 border-2 border-solid border-transparent',
        text: t('statusText.passed'),
        onFocus: '!border-2 !border-solid !border-tertiary-green-200',
      }

    case WorkflowStatusKeysTypes.MANUALLY_PASSED:
      return {
        options: [
          {
            label: t('options.needsAttention'),
            value: WorkflowExecutionResultEnum.REVIEW,
            testId: applicantWorkflowEventsQa.needAttentionItem,
          },
          {
            label: t('options.manuallyFailed'),
            value: WorkflowExecutionResultEnum.FAIL,
            testId: applicantWorkflowEventsQa.manuallyFailedItem,
          },
        ],
        styles:
          '!bg-tertiary-green-100 !text-tertiary-green-600 border-2 border-solid border-transparent',
        text: t('statusText.manuallyPassed'),
        onFocus: '!border-2 !border-solid !border-tertiary-green-200',
      }

    case WorkflowStatusKeysTypes.NEEDS_ATTENTION:
      return {
        options: [
          {
            label: t('options.manuallyPassed'),
            value: WorkflowExecutionResultEnum.PASS,
            testId: applicantWorkflowEventsQa.manuallyPassedItem,
          },
          {
            label: t('options.manuallyFailed'),
            value: WorkflowExecutionResultEnum.FAIL,
            testId: applicantWorkflowEventsQa.manuallyFailedItem,
          },
        ],
        styles:
          '!bg-tertiary-yellow-100 !text-tertiary-yellow-700 border-2 border-solid border-transparent',
        text: t('statusText.needsAttention'),
        onFocus: '!border-2 !border-solid !border-tertiary-yellow-200',
      }

    case WorkflowStatusKeysTypes.UNCHECKED:
      return {
        options: [
          {
            label: t('options.needsAttention'),
            value: WorkflowExecutionResultEnum.REVIEW,
            testId: applicantWorkflowEventsQa.needAttentionItem,
          },
          {
            label: t('options.manuallyPassed'),
            value: WorkflowExecutionResultEnum.PASS,
            testId: applicantWorkflowEventsQa.manuallyPassedItem,
          },
          {
            label: t('options.manuallyFailed'),
            value: WorkflowExecutionResultEnum.FAIL,
            testId: applicantWorkflowEventsQa.manuallyFailedItem,
          },
        ],
        styles:
          '!bg-tertiary-grey-700 !text-mono-white border-2 border-solid border-transparent',
        text: t('statusText.unchecked'),
        onFocus: '!border-2 !border-solid !border-tertiary-grey-200',
      }

    case WorkflowStatusKeysTypes.FAILED:
      return {
        options: [
          {
            label: t('options.needsAttention'),
            value: WorkflowExecutionResultEnum.REVIEW,
            testId: applicantWorkflowEventsQa.needAttentionItem,
          },
          {
            label: t('options.manuallyPassed'),
            value: WorkflowExecutionResultEnum.PASS,
            testId: applicantWorkflowEventsQa.manuallyPassedItem,
          },
        ],
        styles:
          '!bg-tertiary-red-100 !text-tertiary-red-800 border-2 border-solid border-transparent',
        text: t('statusText.failed'),
        onFocus: '!border-2 !border-solid !border-tertiary-red-200',
      }

    case WorkflowStatusKeysTypes.MANUALLY_FAILED:
      return {
        options: [
          {
            label: t('options.needsAttention'),
            value: WorkflowExecutionResultEnum.REVIEW,
            testId: applicantWorkflowEventsQa.needAttentionItem,
          },
          {
            label: t('options.manuallyPassed'),
            value: WorkflowExecutionResultEnum.PASS,
            testId: applicantWorkflowEventsQa.manuallyPassedItem,
          },
        ],
        styles:
          '!bg-tertiary-red-100 !text-tertiary-red-800 border-2 border-solid border-transparent',
        text: t('statusText.manuallyFailed'),
        onFocus: '!border-2 !border-solid !border-tertiary-red-200',
      }

    default:
      return {
        options: [
          {
            label: t('options.needsAttention'),
            value: WorkflowExecutionResultEnum.REVIEW,
            testId: applicantWorkflowEventsQa.needAttentionItem,
          },
          {
            label: t('options.manuallyPassed'),
            value: WorkflowExecutionResultEnum.PASS,
            testId: applicantWorkflowEventsQa.manuallyPassedItem,
          },
        ],
        styles:
          '!bg-tertiary-red-100 !text-tertiary-red-800 border-2 border-solid border-transparent',
        text: t('statusText.failed'),
        onFocus: '!border-2 !border-solid !border-tertiary-red-200',
      }
  }
}

export const getRiskLevelConfig = <
  IType extends I18nFunction<typeof workflowEventsEn>,
>(
  level: RiskLevel,
  t: IType,
) => {
  switch (level) {
    case RiskLevel.LOW:
      return {
        circleStyle: 'bg-tertiary-green-500',
        textStyle: '!text-tertiary-green-600',
        bgStyle: '!bg-tertiary-green-100',
        text: t('header.risk.low'),
      }
    case RiskLevel.MEDIUM:
      return {
        circleStyle: 'bg-tertiary-yellow-300',
        textStyle: '!text-tertiary-yellow-700',
        bgStyle: '!bg-tertiary-yellow-100',
        text: t('header.risk.medium'),
      }
    case RiskLevel.HIGH:
      return {
        circleStyle: 'bg-tertiary-red-500',
        textStyle: 'text-danger',
        bgStyle: '!bg-tertiary-red-100',
        text: t('header.risk.high'),
      }
    case RiskLevel.UNACCEPTABLE:
      return {
        circleStyle: 'bg-tertiary-red-500',
        textStyle: 'text-danger',
        bgStyle: '!bg-tertiary-red-100',
        text: t('header.risk.unacceptable'),
      }

    case RiskLevel.UNKNOWN:
      return {
        circleStyle: 'bg-tertiary-grey-400',
        textStyle: 'text-tertiary-grey-600',
        bgStyle: '!bg-tertiary-grey-100',
        text: t('header.risk.unknown'),
      }
    default:
      return {
        circleStyle: 'bg-tertiary-grey-400',
        textStyle: 'text-tertiary-grey-600',
        bgStyle: '!bg-tertiary-grey-100',
        text: t('header.risk.unknown'),
      }
  }
}
