import React, { useEffect, useMemo, useState } from 'react'

import { useForm } from 'react-hook-form'

import { FrankieButton, FrankieIcon, FrankieLoader } from 'frankify/src'

import { useUpdateAmlStatus } from 'entities/entity/mutation/resolve-aml/resolve-aml'
import { useEntityAmlData } from 'entities/entity/state/entity-aml/entity-aml.state'

import { SelectFormField, TextAreaFormField } from 'shared/form'
import { useI18n } from 'shared/i18n'
import { TrackingEventsTypes, trackingManager } from 'shared/tracking'
import { EmptyString } from 'shared/typescript'
import { useValidationRules } from 'shared/validation'

import { ENTITY_KEY } from '../../entity.key'
import { entityEn } from '../../locale/entity.en'
import {
  AmlStatusUpdateProps,
  getAmlStatusConfig,
  getApprovalStatusOptions,
} from '../../model/aml.model'
import { ProcessResultManualStatusEnumAML } from '../../model/entity.model'
import { entityAssignQa } from '../../qa/entity.qa'

type AmlStatusChangeFormProps = {
  statusValue?: string
  closeOverlay: () => void
  processResultId: string[]
  restProcessResultIds?: string[]
  entityId: string
}

export type UpdateStatusType = {
  amlStatus: ProcessResultManualStatusEnumAML
  comment: string
  approvalStatus: EmptyString<ProcessResultManualStatusEnumAML>
}

function AlertComponent({
  closeOverlay,
}: Pick<AmlStatusChangeFormProps, 'closeOverlay'>) {
  const t = useI18n([ENTITY_KEY], { keys: entityEn })
  return (
    <>
      <div className="flex flex-col gap-4 justify-center items-center pt-5 px-5 pb-4 ">
        <FrankieIcon
          name="mdiAlertCircleOutline"
          size="lg"
          className="text-tertiary-red-700"
        />

        <div className="text-tertiary-grey-700 text-md leading-6 text-center">
          <div>{t('alertAml.title')}</div>
          <div>{t('alertAml.subTitle')}</div>
        </div>
      </div>
      <div className="flex flex-grow-0 justify-end mt-6">
        <FrankieButton
          intent="secondary"
          className="mr-[20px]"
          size="sm"
          type="submit"
          onClick={() => closeOverlay()}
        >
          {t('alertAml.noKeep')}
        </FrankieButton>
        <FrankieButton size="sm" type="submit">
          {t('alertAml.yesUpdate')}
        </FrankieButton>
      </div>
    </>
  )
}

export function AmlStatusChangeForm({
  statusValue,
  closeOverlay,
  processResultId,
  restProcessResultIds,
  entityId,
}: AmlStatusChangeFormProps) {
  const t = useI18n([ENTITY_KEY], { keys: entityEn })
  const { data } = useEntityAmlData({ entityId })
  const { xssRule } = useValidationRules()

  const manualStatus = useMemo(
    () =>
      processResultId.length === 1
        ? (data.processResults.find(
            i => i.processResultId === processResultId[0],
          )?.manualStatus as ProcessResultManualStatusEnumAML)
        : undefined,
    [data, processResultId],
  )

  const { options } = getAmlStatusConfig(t, manualStatus)

  const {
    control,
    formState: { isValid },
    watch,
    handleSubmit,
  } = useForm<UpdateStatusType>({
    defaultValues: {
      amlStatus: statusValue as ProcessResultManualStatusEnumAML,
      approvalStatus: '',
      comment: '',
    },
    mode: 'onTouched',
  })

  const amlStatus = watch('amlStatus')

  const approvalStatus = watch('approvalStatus')

  const isBtnOnly = useMemo(
    () =>
      (restProcessResultIds || []).length > 0 &&
      amlStatus === ProcessResultManualStatusEnumAML.TRUE_POSITIVE &&
      approvalStatus === ProcessResultManualStatusEnumAML.TRUE_POSITIVE_REJECT,
    [restProcessResultIds, amlStatus, approvalStatus],
  )

  const [showAlert, setShowAlert] = useState(false)

  const { mutate, isLoading, reset } = useUpdateAmlStatus({
    entityId,
    onSuccess: () => {
      if (!isBtnOnly) {
        closeOverlay()
      }
      reset()
    },
  })

  const { mutate: mutateFalsePositive, isLoading: isLoadingFalsePositive } =
    useUpdateAmlStatus({
      entityId,
      onSuccess: () => {
        closeOverlay()
      },
    })

  const updateAmlResultStatus = (data: AmlStatusUpdateProps) => {
    mutate(data)
  }

  useEffect(() => {
    trackingManager.trackWithProps(TrackingEventsTypes.AmlModalViewIndividual, {
      entityId: data.processResults.at(0)?.entityId,
      manualStatus: data.processResults.at(0)?.manualStatus,
      processResultId: data.processResults.at(0)?.processResultId,
      systemStatus: data.processResults.at(0)?.systemStatus,
    })
  }, [data])

  const onSubmitHandler = (data: UpdateStatusType) => {
    if (!showAlert) {
      updateAmlResultStatus({
        processResults: processResultId,
        manualStatus: data.approvalStatus || data.amlStatus,
        comment: {
          text: data.comment,
        },
      })
    } else {
      mutateFalsePositive({
        processResults: restProcessResultIds || [],
        manualStatus: ProcessResultManualStatusEnumAML.FALSE_POSITIVE,
        comment: {
          text: data.comment,
        },
      })
    }

    if (isBtnOnly) {
      setShowAlert(true)
    }

    trackingManager.trackWithProps(
      TrackingEventsTypes.AmlConfirmResolveIndividual,
      {
        entityId,
        amlStatus: data.amlStatus,
        approvalStatus: data.approvalStatus,
      },
    )
  }

  const approvalStatusOptions = useMemo(
    () =>
      getApprovalStatusOptions({
        amlStatus,
        t,
      }),
    [amlStatus, t],
  )

  return (
    <div className="w-[440px]">
      <FrankieLoader
        loading={isBtnOnly ? isLoadingFalsePositive : isLoading}
        label={t('alertAml.loading')}
        fullscreen
        className="z-40"
        size="md"
      />
      <div className="flex flex-col">
        <div className="flex flex-col gap-4">
          <div className="flex justify-between flex-grow-0">
            <div className="text-tertiary-grey-800 font-bold text-xl leading-7">
              {t('amlStatusForm.title')}
            </div>
          </div>

          <form onSubmit={handleSubmit(onSubmitHandler)}>
            {showAlert && <AlertComponent closeOverlay={closeOverlay} />}
            <div className={showAlert ? 'invisible !h-0' : ''}>
              <div className="leading-5 mb-4">
                {t('amlStatusForm.subTitle')}
              </div>
              <div className="flex flex-col gap-4 justify-between">
                <div className="flex flex-col gap-4">
                  <SelectFormField
                    name="amlStatus"
                    control={control}
                    options={options}
                    label={t('amlStatusForm.selectLabel')}
                    rules={{ required: true }}
                    testId={{ input: entityAssignQa.amlStatusSelect }}
                  />
                  {approvalStatusOptions.length > 0 && (
                    <SelectFormField
                      name="approvalStatus"
                      control={control}
                      options={approvalStatusOptions}
                      label={t('amlStatusForm.approvalStatusSelectLabel')}
                      placeholder={t('amlStatusForm.approvalStatusPlaceholder')}
                      rules={{ required: true }}
                      testId={{ input: entityAssignQa.approvalStatusSelect }}
                      shouldUnregister
                    />
                  )}

                  <TextAreaFormField
                    name="comment"
                    shouldUnregister
                    control={control}
                    inputClassName="!min-h-[56px]"
                    showError
                    trim
                    counter={500}
                    maxLength={500}
                    label={t('amlStatusForm.commentLabel')}
                    rules={{
                      required: t('amlStatusForm.commentError'),
                      ...xssRule,
                    }}
                    testId={{ input: entityAssignQa.amlCommentTextArea }}
                  />
                </div>
                <div className="flex flex-grow-0 justify-end">
                  <FrankieButton
                    className="mr-8"
                    noStyles
                    onClick={() => closeOverlay()}
                  >
                    {t('amlStatusForm.cancel')}
                  </FrankieButton>
                  <FrankieButton
                    size="sm"
                    disabled={!isValid}
                    type="submit"
                    testId={{ button: entityAssignQa.resolveStatus }}
                  >
                    {t('amlStatusForm.changeStatus')}
                  </FrankieButton>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
