import React, { useMemo } from 'react'

import { FrankieCheckbox } from 'frankify/src'

import {
  groupPermissions,
  PermissionGroup,
} from './model/permission-checkbox-group.model'
import { IPermission, PermissionId } from '../../model/role.model'
import { roleQa } from '../../qa/role.qa'

type Props = {
  options: IPermission[]
  values: IPermission[]
  disabled?: boolean
  onChange?: (values: IPermission[]) => void
}

export function PermissionCheckboxGroup({
  options,
  disabled,
  values,
  onChange,
}: Props) {
  const permissionsGroupedCols = useMemo<PermissionGroup[][]>(
    () => groupPermissions(options, values),
    [options, values],
  )

  const handleChangeCheck = (id: PermissionId, newChecked: boolean) => () => {
    if (onChange) {
      if (newChecked) {
        const option = options.find(option => option.id === id)
        if (option) onChange([...values, option])
      } else onChange(values.filter(value => value.id !== id))
    }
  }

  const handleChangeCheckGroup =
    (group: PermissionGroup, newChecked: boolean) => () => {
      if (onChange) {
        let filteredValues: IPermission[] = values
        if (group.checked) {
          filteredValues = values.filter(
            value => value.actionGroup.id !== group.id,
          )
        }
        if (newChecked) {
          onChange([
            ...filteredValues,
            ...group.checks.map(({ checked, ...permission }) => permission),
          ])
        } else onChange(filteredValues)
      }
    }

  return (
    <div className="max-w-full flex flex-initial gap-6">
      {permissionsGroupedCols.map(col => (
        <div key={col[0].id} className="flex flex-col basis-1/3 gap-8">
          {col.map(group => (
            <div key={group.id} data-qa={roleQa.permissionCheckbox_column}>
              <div className="flex flex-row items-center">
                <FrankieCheckbox
                  disabled={disabled}
                  size="md"
                  partial={group.partial}
                  checked={group.checked}
                  onChange={handleChangeCheckGroup(group, !group.checked)}
                  testId={{
                    input: roleQa.permissionCheckbox_checkGroup,
                  }}
                />
                <div
                  className={`ml-2 leading-tight text-md font-semibold ${
                    disabled && !group.checked
                      ? 'text-tertiary-grey-500'
                      : 'text-tertiary-grey-800'
                  }`}
                >
                  {group.name}
                </div>
              </div>
              {group.checks.map(permission => (
                <div className="mt-3 pl-7 flex flex-row" key={permission.id}>
                  <FrankieCheckbox
                    disabled={disabled}
                    size="sm"
                    checked={permission.checked}
                    onChange={handleChangeCheck(
                      permission.id,
                      !permission.checked,
                    )}
                    testId={{
                      input: roleQa.permissionCheckbox_check,
                    }}
                  />
                  <div
                    className={`ml-2 leading-tight text-sm ${
                      disabled && !permission.checked
                        ? 'text-tertiary-grey-500'
                        : 'text-tertiary-grey-700'
                    }`}
                  >
                    {permission.name}
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      ))}
    </div>
  )
}
