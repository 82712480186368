import React, { useMemo } from 'react'

import { ApplicantId } from 'entities/applicant'
import {
  OrganisationViewModal,
  BusinessSummaryView,
  BusinessSummaryField,
} from 'entities/organisation'

import { useI18n } from 'shared/i18n'
import { useOverlay } from 'shared/overlay'
import { isNonEmptyArray, isNotEmptyString, isNumber } from 'shared/typescript'

import { APPLICANT_GENERAL_INFORMATION_KEY } from '../../applicant-general-information.key'
import { applicantGeneralInformationEn } from '../../locale/applicant-general-information.en'
import {
  ABRBusinessSummary,
  OtherBusinessSummary,
} from '../../model/applicant-business.model'
import { applicantBusinessSummaryQa } from '../../qa/applicant-general-information.qa'
import { useApplicantBusinessSummary } from '../../state/applicant-business-state/applicant-business-state'

type Props = {
  applicantId: ApplicantId
}

type ABRBusinessSummaryFields = BusinessSummaryField<ABRBusinessSummary>[]
type ABRBusinessSummaryProperties = keyof ABRBusinessSummary

type OtherBusinessSummaryFields = BusinessSummaryField<OtherBusinessSummary>[]

export function ApplicantBusinessSummary({ applicantId }: Props) {
  const t = useI18n([APPLICANT_GENERAL_INFORMATION_KEY], {
    keys: applicantGeneralInformationEn,
  })

  const {
    abrBusinessSummary,
    otherBusinessSummary,
    historicalBusinessNames,
    loadingBusinessSummary,
    loadingOtherSummary,
  } = useApplicantBusinessSummary({ applicantId })

  const isNotEmptyValue = (
    data: ABRBusinessSummary,
    key: ABRBusinessSummaryProperties,
  ) => {
    const value = data[key]
    return isNonEmptyArray(value) || isNotEmptyString(value) || isNumber(value)
  }

  const abrSummaryFields = useMemo(
    () =>
      [
        {
          name: t('businessSummary.fields.name'),
          value: 'name',
          isVisible: (data: ABRBusinessSummary) =>
            (
              [
                'mainNames',
                'legalNames',
                // 'currentBusiness',
                'tradingNames',
              ] as ABRBusinessSummaryProperties[]
            ).reduce(
              (value, key) => value && !isNotEmptyValue(data, key),
              true,
            ),
        },
        {
          name: t('businessSummary.fields.mainNames'),
          value: 'mainNames',
          isVisible: isNotEmptyValue,
        },
        {
          name: t('businessSummary.fields.legalNames'),
          value: 'legalNames',
          isVisible: isNotEmptyValue,
        },
        {
          name: t('businessSummary.fields.tradingNames'),
          value: 'tradingNames',
          isVisible: isNotEmptyValue,
        },
        {
          name: t('businessSummary.fields.location'),
          value: 'location',
          isVisible: isNotEmptyValue,
        },
        {
          name: t('businessSummary.fields.type'),
          value: 'type',
          isVisible: isNotEmptyValue,
        },
        {
          name: t('businessSummary.fields.acn'),
          value: 'acn',
          isVisible: isNotEmptyValue,
        },
        {
          name: t('businessSummary.fields.abn'),
          value: 'abn',
          isVisible: isNotEmptyValue,
        },
        {
          name: t('businessSummary.fields.abnStatus'),
          value: 'abnStatus',
          isVisible: isNotEmptyValue,
        },
        {
          name: t('businessSummary.fields.currentBusiness'),
          value: 'currentBusiness',
          isVisible: isNotEmptyValue,
        },
        {
          name: t('businessSummary.fields.historicalBusiness'),
          value: 'historicalBusiness',
          isAction: isNonEmptyArray(historicalBusinessNames),
        },
      ] satisfies ABRBusinessSummaryFields,
    [historicalBusinessNames, t],
  )

  const otherSummaryFields = useMemo(
    () =>
      [
        {
          name: t('businessSummary.fields.merchantCategory'),
          value: 'merchantCategory',
        },
      ] satisfies OtherBusinessSummaryFields,
    [t],
  )

  const [createOverlay, closeOverlay] = useOverlay()

  const handleAction = (value: ABRBusinessSummaryProperties) => {
    if (value === 'historicalBusiness' && historicalBusinessNames) {
      createOverlay(
        <OrganisationViewModal
          heading={t('businessSummary.fields.historicalBusiness')}
          data={historicalBusinessNames}
          onClose={closeOverlay}
        />,
        { className: 'p-0' },
      )
    }
  }

  return (
    <>
      <BusinessSummaryView
        heading={t('businessSummary.abr')}
        data={abrBusinessSummary}
        fields={abrSummaryFields}
        handleAction={handleAction}
        isLoading={loadingBusinessSummary}
        hidden={!(abrBusinessSummary.abn || abrBusinessSummary.acn)}
      />

      <BusinessSummaryView
        heading={t('businessSummary.other')}
        data={otherBusinessSummary}
        fields={otherSummaryFields}
        isLoading={loadingOtherSummary}
        testId={{ container: applicantBusinessSummaryQa.summaryView }}
      />
    </>
  )
}
