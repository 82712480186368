import { GridColDef } from '@mui/x-data-grid-pro'

import { I18nFunction } from 'shared/i18n'

import {
  BusinessDocumentCatalogRowType,
  registryDocumentErrorStatuses,
} from './applicant-supporting-documents.model'
import { applicantSupportingDocumentsEn } from '../locale/applicant-supporting-documents.en'
import { OrganisationDocumentTableStatusCell } from '../ui/organisation-documents-catalog-table-cell/organisation-documents-catalog-table-cell'

const TABLE_MIN_WIDTH = 800

export const documentCellWidth = (width: number) => ({
  minWidth: width,
  flex: width / TABLE_MIN_WIDTH,
})

export const getColumns: (
  t: I18nFunction<typeof applicantSupportingDocumentsEn>,
) => GridColDef<BusinessDocumentCatalogRowType>[] = t => [
  {
    field: 'title',
    sortable: false,
    headerName: t('documentCatalog.tableColumn.document'),
    cellClassName: 'font-semibold',
    ...documentCellWidth(250),
  },
  {
    field: 'pricingTier',
    headerName: t('documentCatalog.tableColumn.pricingTier'),
    sortable: true,
    sortComparator: (a: string, b: string) => b.localeCompare(a),
    ...documentCellWidth(150),
  },
  {
    field: 'status',
    headerName: t('documentCatalog.tableColumn.status'),
    sortable: false,
    renderCell: OrganisationDocumentTableStatusCell,
    ...documentCellWidth(150),
  },
  {
    field: 'issue',
    headerName: t('documentCatalog.tableColumn.issue'),
    sortable: false,
    renderCell: ({ row }) =>
      row.status && registryDocumentErrorStatuses.includes(row.status)
        ? row.status
        : '',
    ...documentCellWidth(150),
  },
]
