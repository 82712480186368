import React, { useMemo } from 'react'

import { Box, IconButton } from '@mui/material'
import {
  GridColumnHeaderParams,
  GridRenderCellParams,
  GridRowId,
  GridSortModel,
} from '@mui/x-data-grid-pro'

import { FrankieIcon, FrankieIconName, FrankieTooltip } from 'frankify/src'

import { DateFormatTypes, formatDate } from 'shared/date-time'
import { sanitizeHTML } from 'shared/function'
import { useI18n } from 'shared/i18n'

import { APPLICANT_AUDIT_REPORT_KEY } from '../../applicant-audit-report.key'
import { applicantAuditReportEn } from '../../locale/applicant-audit-report.en'
import {
  AuditRecordDataProperties,
  AuditRecordsData,
} from '../../model/applicant-audit-report.model'

export type AuditCellProps = GridRenderCellParams<AuditRecordsData>

export function AuditUserCell({ row }: AuditCellProps) {
  /**
   * User Initials calculation
   */
  const userInitial = useMemo(() => {
    const userNameArr = row.user.split(' ')

    if (!userNameArr.length) return ''

    const startInitial = userNameArr[0]?.slice(0, 1)?.toUpperCase()

    if (userNameArr.length === 1) return startInitial

    const endInitial = userNameArr[userNameArr.length - 1]
      ?.slice(0, 1)
      ?.toUpperCase()

    return `${startInitial}${endInitial}`
  }, [row.user])

  return (
    <FrankieTooltip position="top" body={row.user}>
      <div
        className={`w-7 h-7 text-center rounded-full p-1 font-bold uppercase text-mono-white cursor-pointer ${
          row.isChannelPortal ? ' bg-tertiary-purple-500' : 'bg-neutral-100'
        }`}
      >
        {userInitial}
      </div>
    </FrankieTooltip>
  )
}

export function AuditTimeStampCell({ row }: AuditCellProps) {
  return (
    <div className="flex gap-6">
      <div className="text-tertiary-grey-800">
        {formatDate(row.timeStamp, DateFormatTypes.DateNumbers)}
      </div>

      <div className="text-neutral-80">
        {formatDate(row.timeStamp, DateFormatTypes.TimeWithSeconds)}
      </div>
    </div>
  )
}

export function AuditDescriptionCell({
  row,
  expandedRowIds,
}: AuditCellProps & { expandedRowIds: GridRowId[] }) {
  const t = useI18n([APPLICANT_AUDIT_REPORT_KEY], {
    keys: applicantAuditReportEn,
  })

  const isExpanded = expandedRowIds.includes(row.id)

  // @in-vue-code Replaces "blacklist" with "blocklist". Ignores the first "b" so it works with "blacklist" and "Blocklist"
  const [descLine1, ...restDescLine] = row.resultAndComments.map(item =>
    item.replace(/lacklist/gi, 'locklist'),
  )

  const { requestedBy, channel } = useMemo(
    () => ({
      channel: row.channel
        .replace(/-/g, ' ')
        .replace(/\b\w/g, match => match.toUpperCase()),
      requestedBy:
        row.user && row.isChannelPortal && row.user !== 'ANONYMOUS'
          ? row.user
          : null,
    }),
    [row],
  )

  return (
    <div
      className={`flex flex-col whitespace-wrap pr-2 text-neutral-90 w-full h-full ${
        isExpanded ? 'gap-4' : ''
      }`}
    >
      <Box
        className="min-h-[35px]"
        dangerouslySetInnerHTML={{ __html: sanitizeHTML(descLine1) }}
      />

      <div
        className={`transition-all transition-opacity ease-in-out duration-300 overflow-hidden ${
          isExpanded ? 'max-h-full opacity-1' : 'max-h-0 opacity-0'
        }`}
      >
        {row.showMetaData && (
          <div className="flex flex-col gap-2">
            {requestedBy && (
              <div>
                <span className="font-bold">{t('requestedBy')}:</span>
                <span className="ps-1">{requestedBy}</span>
              </div>
            )}
            <div>
              <span className="font-bold">{t('channel')}:</span>
              <span className="ps-1">{channel}</span>
            </div>
            {row.comment && (
              <div className="p-2 bg-neutral-30 rounded-sm">
                <span className="font-bold">{`${row.user} ${t(
                  'commented',
                )}:`}</span>
                <Box
                  dangerouslySetInnerHTML={{
                    __html: sanitizeHTML(row.comment),
                  }}
                />
              </div>
            )}
          </div>
        )}

        {!!restDescLine.length && (
          <Box
            dangerouslySetInnerHTML={{
              __html: sanitizeHTML(restDescLine.join('<br/>')),
            }}
            sx={{
              whiteSpace: 'pre-wrap',
              '& *': { all: 'revert' },
            }}
          />
        )}
      </div>
    </div>
  )
}

export const sortIcons: Record<'asc' | 'desc' | 'default', FrankieIconName> = {
  asc: 'mdiArrowDown',
  desc: 'mdiArrowUp',
  default: 'mdiSwapVertical',
}

type AuditReportTableHeaderProps = GridColumnHeaderParams<AuditRecordsData> & {
  sortModel: GridSortModel
  sortFields: AuditRecordDataProperties[]
}

export function AuditReportTableHeader({
  colDef,
  sortModel,
  sortFields,
}: AuditReportTableHeaderProps) {
  const fieldName = colDef.field as AuditRecordDataProperties

  const currentSortValue = useMemo(
    () => sortModel.find(item => item.field === fieldName)?.sort || 'default',
    [sortModel, fieldName],
  )

  return (
    <div className="relative flex items-center py-2">
      <span>{colDef.headerName}</span>
      {sortFields.includes(fieldName) && (
        <IconButton className="p-[2px]">
          <FrankieIcon
            size="xs"
            className={currentSortValue === 'default' ? '' : 'text-primary-800'}
            name={sortIcons[currentSortValue]}
          />
        </IconButton>
      )}
    </div>
  )
}
