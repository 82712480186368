export const settingsRoleViewEn = {
  cta: {
    editRole: 'Edit role',
    goBack: 'Return to role management',
  },
  header: {
    permissions: 'Permissions',
  },
  alert: {
    childRole:
      'To edit this role, switch to the respective Child account’s Settings page to modify accordingly.',
  },
}
