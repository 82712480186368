import { useState } from 'react'

import { useQuery, useQueryClient } from '@tanstack/react-query'

import {
  ApplicantId,
  ApplicantResponse,
  applicantApi,
} from 'entities/applicant'

import { useCacheCleanup } from 'shared/hooks'
import { ErrorResponse } from 'shared/http'

export const MatchApplicantDataQueryKey = 'matched-applicant-query'

type ArgsT = {
  applicantIds: ApplicantId[]
}

export const useToggleApplicantData = ({ applicantIds = [] }: ArgsT) => {
  const [idx, setIdx] = useState<number>(0)

  const queryClient = useQueryClient()

  const query = useQuery<ApplicantResponse, ErrorResponse>({
    queryKey: [MatchApplicantDataQueryKey, applicantIds[idx]],
    queryFn: async () => {
      const { data } = await applicantApi.getApplicant(applicantIds[idx])
      return data
    },
    enabled: applicantIds.length !== 0,
    refetchOnMount: 'always',
  })

  useCacheCleanup(applicantIds, applicantIdsRef =>
    applicantIdsRef.forEach(applicantId => {
      queryClient.removeQueries({
        queryKey: [MatchApplicantDataQueryKey, applicantId],
      })
    }),
  )

  const getPreviousApplicantData = () => {
    if (idx === 0) {
      setIdx(applicantIds.length - 1)
      return
    }
    setIdx(prev => prev - 1)
  }

  const getNextApplicantData = () => {
    if (idx === applicantIds.length - 1) {
      setIdx(0)
      return
    }
    setIdx(prev => prev + 1)
  }

  return {
    ...query,
    idx,
    getPreviousApplicantData,
    getNextApplicantData,
  }
}
