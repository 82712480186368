import React from 'react'

import { ApplicantId, ApplicantResponse } from 'entities/applicant'

import { useI18n } from 'shared/i18n'

import { APPLICANT_GENERAL_INFORMATION_KEY } from '../../applicant-general-information.key'
import { applicantGeneralInformationEn } from '../../locale/applicant-general-information.en'
import { applicantGeneralInformationQa } from '../../qa/applicant-general-information.qa'
import { useApplicantCheckSummaryState } from '../../state/applicant-check-summary-state/applicant-check-summary-state'
import { ApplicantBusinessOverview } from '../applicant-business-overview/applicant-business-overview'
import { ApplicantCheckSummary } from '../applicant-check-summary/applicant-check-summary'
import { ApplicantOverview } from '../applicant-overview/applicant-overview'

type Props = {
  applicantData: ApplicantResponse
  applicantId: ApplicantId
  className?: string
}

export function ApplicantGeneralInformation({
  applicantData,
  applicantId,
  className = '',
}: Props) {
  const t = useI18n([APPLICANT_GENERAL_INFORMATION_KEY], {
    keys: applicantGeneralInformationEn,
  })

  const { isLegacyProfile, checkSummary, isLoading, error } =
    useApplicantCheckSummaryState({
      applicantId,
    })

  if (!isLoading && !checkSummary) {
    return (
      <div>
        {t('summaryNotFound')} - {error?.response?.data.message}
      </div>
    )
  }

  // If profile has business info Show business overview
  if (applicantData.businessInfo) {
    return <ApplicantBusinessOverview applicantId={applicantId} />
  }

  return (
    <div
      className={`p-8 ${className}`}
      data-qa={applicantGeneralInformationQa.overViewComp}
    >
      {isLegacyProfile ? (
        <ApplicantCheckSummary
          applicantId={applicantId}
          className="max-w-[860px] m-auto"
        />
      ) : (
        <ApplicantOverview applicantId={applicantId} />
      )}
    </div>
  )
}
