import React, { forwardRef, useMemo } from 'react'

import classNames from 'classnames'
import { Controller, ControllerProps, FieldValues } from 'react-hook-form'

import { FrankieButton } from 'frankify/src'

import { Primitive } from 'shared/typescript'

type ToggleSwitchVariant = 'standard' | 'basic'

type ToggleOption<TValue extends Primitive> = {
  activeOption: ToggleSwitchOption<TValue>
  inactiveOption: ToggleSwitchOption<TValue>
}

type ToggleSwitchOption<T extends Primitive> = {
  label: string
  value: T
  color?: string
}

type ToggleSwitchProps<
  TValue extends Primitive,
  TVariant extends ToggleSwitchVariant,
> = {
  value: TValue
  onChange: (value: TValue) => void
  variant?: TVariant
  className?: string
  disabled?: boolean
  name?: string
  testId?: string
} & (TVariant extends 'basic'
  ? Partial<ToggleOption<TValue>>
  : ToggleOption<TValue>)

// eslint-disable-next-line prefer-arrow-callback, complexity
export const ToggleSwitch = forwardRef(function ToggleSwitch<
  TValue extends Primitive,
  TVariant extends ToggleSwitchVariant,
>(
  {
    value,
    onChange,
    variant = 'standard' as TVariant,
    activeOption,
    inactiveOption,
    disabled = false,
    className = '',
    name,
    testId,
  }: ToggleSwitchProps<TValue, TVariant>,
  ref: React.Ref<HTMLButtonElement>,
) {
  const isActive = useMemo(
    () => (variant === 'basic' ? value : activeOption?.value === value),
    [activeOption?.value, value, variant],
  )

  const toggleSwitch = () => {
    if (variant === 'basic') {
      onChange(!value as TValue)
    } else {
      onChange(
        (isActive ? inactiveOption?.value : activeOption?.value) as TValue,
      )
    }
  }

  const bgColor = useMemo(() => {
    if (variant === 'basic') {
      return isActive ? 'bg-primary-800' : 'bg-tertiary-grey-300'
    }

    return isActive
      ? activeOption?.color ?? 'bg-tertiary-green-400'
      : inactiveOption?.color ?? 'bg-tertiary-red-400'
  }, [activeOption?.color, inactiveOption?.color, isActive, variant])

  const togglePositionClass = useMemo(() => {
    if (variant === 'basic') {
      return isActive ? 'left-[calc(100%-25px)]' : 'left-[1px]'
    }

    if (isActive) {
      return 'left-[1px]'
    }
    return 'left-[calc(100%-25px)]'
  }, [isActive, variant])

  return (
    <FrankieButton
      noStyles
      name={name}
      disabled={disabled}
      className={classNames(
        `relative  h-[26px] transition-colors duration-300 ease-in-out ${bgColor} rounded-full cursor-pointer ${className}`,
        disabled && '!cursor-not-allowed',
        variant === 'basic'
          ? 'scale-75 w-[54px] min-w-[54px]'
          : 'w-auto min-w-[80px]',
      )}
      testId={{ button: testId }}
      onClick={toggleSwitch}
      ref={ref}
    >
      <div
        className={`absolute left-0 top-[1px] w-[24px] h-[24px] bg-mono-white rounded-full transition-left duration-300 ease-in-out ${togglePositionClass}`}
      />
      {activeOption && (
        <span
          className={`absolute inset-y-[1px] flex items-center font-bold right-0 pr-2 ${
            isActive ? 'opacity-100' : 'opacity-0'
          } transition-opacity duration-300 ease-in-out text-mono-white`}
        >
          {activeOption.label}
        </span>
      )}
      {inactiveOption && (
        <span
          className={`absolute inset-y-[1px] flex items-center font-bold left-0 pl-2 ${
            isActive ? 'opacity-0' : 'opacity-100'
          } transition-opacity duration-300 ease-in-out text-mono-white`}
        >
          {inactiveOption.label}
        </span>
      )}
    </FrankieButton>
  )
  // Type cast is need to fix the issue with the generic type
}) as <TValue extends Primitive, TVariant extends ToggleSwitchVariant>(
  p: ToggleSwitchProps<TValue, TVariant> & {
    ref?: React.Ref<HTMLButtonElement>
  },
) => JSX.Element

type Props<
  TFormValues extends FieldValues,
  TValue extends Primitive,
  TVariant extends ToggleSwitchVariant,
> = Omit<ToggleSwitchProps<TValue, TVariant>, 'onChange' | 'value'> &
  Omit<ControllerProps<TFormValues>, 'render'>

export function ToggleSwitchFormField<
  TFormValues extends FieldValues,
  TValue extends Primitive,
  TVariant extends ToggleSwitchVariant,
>({
  activeOption,
  inactiveOption,
  disabled,
  variant,
  className,
  testId,
  ...controllerProps
}: Props<TFormValues, TValue, TVariant>) {
  return (
    <Controller
      render={({ field, formState: { errors } }) => (
        <ToggleSwitch
          {...field}
          variant={variant}
          testId={testId}
          disabled={disabled}
          activeOption={activeOption}
          inactiveOption={inactiveOption}
          className={className}
        />
      )}
      {...controllerProps}
    />
  )
}
