import React, { useMemo, useRef, useState } from 'react'

import { createPortal } from 'react-dom'

import { IOverlayProps } from '../../model/overlay.model'
import { OverlayContext } from '../../state/overlay.context'
import { Overlay } from '../overlay/overlay'

type Props = {
  children: React.ReactNode
}
export function OverlayProvider({ children }: Props) {
  const [overlayStack, setOverlayStack] = useState<IOverlayProps[]>([])
  const rootRef = useRef<HTMLDivElement>(null)

  const value = useMemo(
    () => ({ overlayStack, setOverlayStack }),
    [overlayStack],
  )

  return (
    <OverlayContext.Provider value={value}>
      <div className="relative overflow-hidden" ref={rootRef} />
      {overlayStack.length > 0 &&
        rootRef.current &&
        createPortal(
          <Overlay {...overlayStack[overlayStack.length - 1]}>
            {overlayStack[overlayStack.length - 1].children}
          </Overlay>,
          rootRef.current,
        )}
      {children}
    </OverlayContext.Provider>
  )
}
