import { ApplicantResponse } from 'entities/applicant'
import { OrganisationAddress } from 'entities/organisation'

import { Nullable } from 'shared/typescript'

enum EntityTypes {
  ORGANISATION = 'ORGANIZATION',
  INDIVIDUAL = 'INDIVIDUAL',
  UNKNOWN = 'UNKNOWN',
}

enum OfficialStateTypes {
  CURRENT = 'CURRENT',
  PREVIOUS = 'PREVIOUS',
}

export enum BlockingReasonTypes {
  INSUFFICIENT_MAX_CREDIT_COST = 'INSUFFICIENT_MAX_CREDIT_COST',
}

export type TEntityType = keyof typeof EntityTypes
type TOfficialState = keyof typeof OfficialStateTypes

type TIssue = {
  term: string
  type: string
  extra?: {
    label: string
    action: string
  }
}

type TProfileStatus = {
  key: string
  type: string
}

interface IOfficeHolderBaseEntity {
  entityId: string
  entityType: TEntityType
  name?: string
  countryList?: string[]
}

interface IOfficeHolderEntity extends IOfficeHolderBaseEntity {
  beneficiallyHeld?: number
  nonBeneficiallyHeld?: number
  totalHeld?: number
  profileStatus?: TProfileStatus
  issues?: TIssue[]
  recipe?: string
  riskLevel?: string
}

interface ILinkedCompany extends IOfficeHolderBaseEntity {
  companyType?: string
}

interface IOfficialEntity extends IOfficeHolderBaseEntity {
  role?: string
  officialState?: TOfficialState
  profileStatus?: TProfileStatus
  issues?: TIssue[]
  recipe?: string
  riskLevel?: string
}

export interface IShareCapital {
  classTitle?: string
  classCode?: string
  sharesIssued?: number
  totalAmountDueAndPayable?: number
}

export type TShareCapitalGroup = {
  shares?: IShareCapital[]
  totalShares?: number
}

interface IShareholderEntity extends IOfficeHolderBaseEntity {
  jointHolding?: boolean
  isBeneficiallyHeld?: boolean
  profileStatus?: TProfileStatus
  classCode?: string
  sharesHeld?: number
  riskLevel?: string
}

export interface IBlockingReasons {
  description?: string
  type?: string
  code?: string
}

interface IBlockingEntities extends IOfficeHolderBaseEntity {
  blockingReason?: string
  beneficiallyHeld?: number
  nonBeneficiallyHeld?: number
  totalHeld?: number
  reasons?: Array<IBlockingReasons | null>
}

export type InternationalOwnershipResponse = {
  reportCreatedAt: string
  ubos: IOfficeHolderEntity[]
  otherOwners: IOfficeHolderEntity[]
  linkedCompanies: ILinkedCompany[]
  officials: IOfficialEntity[]
  shareCapital: TShareCapitalGroup
  shareholders: IShareholderEntity[]
  blockingEntities: IBlockingEntities[]
}

export interface IAssociateParty extends IOfficeHolderEntity {
  roles: string[]
  otherRoleTypeDescription: string
  roleType?: string
}

export type ApplicantOwnershipHolderTypes =
  | keyof Omit<InternationalOwnershipResponse, 'reportCreatedAt'>
  | 'associatedParties'

export type ApplicantOwnershipHolderRecord = IOfficeHolderEntity &
  ILinkedCompany &
  IOfficialEntity &
  IShareholderEntity &
  IBlockingEntities &
  Partial<IAssociateParty>

export type ApplicantOwnershipHolderRecordProperties =
  | keyof ApplicantOwnershipHolderRecord
  | 'actions'

// -------- Add associate party types ------------
export type AssociateNewInternationalPartyType = 'BUSINESS' | 'INDIVIDUAL'

export type AssociateNewInternationalBusinessData = {
  businessName: string
  businessNumber?: string
  businessDescription?: string
  addresses: OrganisationAddress[]
  organisationToken: string
}

export type AssociateNewInternationalIndividualData =
  ApplicantResponse['applicantDetails']

export type AssociateInternationalPartyFormData = {
  beneficiallyOwnership: string
  nonBeneficiallyOwnership: string
  total: string
  roles: string[]
  otherRoleTypeDescription: string
  hidePartyFromSearch: boolean
  partyType: AssociateNewInternationalPartyType
  individualData: {
    givenName: string
    middleName: string
    familyName: string
  }
  organisationData: AssociateNewInternationalBusinessData
  entityId: string
  searchedOrganisationToken: string
}

export type AssociateInternationalPartiesPayload = {
  addedBy: string
  roles: { type: string; typeDescription: string }[]
  percentage: {
    beneficially: Nullable<number>
    jointly: Nullable<number>
    nonBeneficially: Nullable<number>
  }
}

export type HidePartyFromSearch = { hidePartyFromSearch: boolean }

export type AssociateNewInternationalPartiesPayload<
  TPartyType extends AssociateNewInternationalPartyType,
> = {
  partyType: TPartyType
  party: TPartyType extends 'INDIVIDUAL'
    ? AssociateNewInternationalIndividualData
    : AssociateNewInternationalBusinessData
}

export type InternationalAssociatedPartyResponse = {
  associatedParties: {
    entityId: string
    name: string
    kyc: {
      status: {
        code: unknown
      }
      sources: unknown[]
      issuesList: ({ term: string } | string)[]
      riskLevel: {
        term: string
        label: string
        key: string
      }
    }
    addressLongForm: string
    dateOfBirth: string
    placeOfBirth: string
    type: Nullable<string>
    entityType?: Nullable<'ORGANISATION' | 'INDIVIDUAL'>
    profileType?: Nullable<string>
    customerReference?: Nullable<string>
    originalDetails?: {
      name: Nullable<string>
    }
    percentageHolding?: {
      beneficially: Nullable<number>
      nonBeneficially: Nullable<number>
      jointly: Nullable<number>
      total: Nullable<number>
    }
    roles?: {
      type: string
      typeDescription: string
    }[]
  }[]
}
