import React from 'react'

import { useI18n } from 'shared/i18n'

import { INDIVIDUAL_AML_RESULT_KEY } from '../../individual-aml-result.key'
import { individualAmlResultEn } from '../../locale/individual-aml-result.en'
import { amlEntitySummaryQa } from '../../qa/individual-aml-result.qa'
import { useLabelAndSrcForSummary } from '../../state/individual-aml-helper.state'

type Props = {
  entityId: string
  processResultId?: string
  historyPage?: boolean
}

export function IndividualAmlMatchSummary({
  entityId,
  processResultId,
  historyPage,
}: Props) {
  const t = useI18n(INDIVIDUAL_AML_RESULT_KEY, {
    keys: individualAmlResultEn,
  })

  const labelAndValue = useLabelAndSrcForSummary({
    entityId,
    processResultId,
    historyPage,
  })

  return (
    <div
      data-qa={amlEntitySummaryQa.container}
      className="pt-5 pb-6 px-6 border border-tertiary-grey-200 rounded-[4px] min-w-[250px] max-w-[250px]"
    >
      <h1
        data-qa={amlEntitySummaryQa.title}
        className="text-md text-tertiary-grey-700 font-semibold leading-6 mb-4"
      >
        {t('individualAmlResolveSingleSummary.entitySummary')}
      </h1>
      <div className="flex flex-col gap-3">
        {labelAndValue.map(({ label, value }) => (
          <div className="flex flex-col" key={label}>
            <h1
              data-qa={amlEntitySummaryQa.sourceLabel}
              className="text-sm text-tertiary-grey-800 font-medium leading-5"
            >
              {label}
            </h1>
            <p
              data-qa={amlEntitySummaryQa.sourceValue}
              className="text-sm text-tertiary-grey-700 leading-5"
            >
              {value}
            </p>
          </div>
        ))}
      </div>
    </div>
  )
}
