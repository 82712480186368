import { useMutation } from '@tanstack/react-query'

import { customViewApi } from '../../../api/custom-view.api'

export const useViewCustomView = () =>
  useMutation({
    mutationFn: async (viewId: string) => {
      const res = await customViewApi.view(viewId)
      return res.data
    },
  })
