import { INDIVIDUAL_AUDIT_REPORT_F2_KEY } from '../individual-audit-report-f2.key'

export const individualAuditReportF2Qa = {
  header: `${INDIVIDUAL_AUDIT_REPORT_F2_KEY}-main-header`,
} satisfies Record<string, `${typeof INDIVIDUAL_AUDIT_REPORT_F2_KEY}-${string}`>

export const headerF2Qa = {
  header: `${INDIVIDUAL_AUDIT_REPORT_F2_KEY}-header`,
  title: `${INDIVIDUAL_AUDIT_REPORT_F2_KEY}-title`,
  subTitle: `${INDIVIDUAL_AUDIT_REPORT_F2_KEY}-subTitle`,
  selectField: `${INDIVIDUAL_AUDIT_REPORT_F2_KEY}-select`,
  cta: `${INDIVIDUAL_AUDIT_REPORT_F2_KEY}-cta`,
} satisfies Record<string, `${typeof INDIVIDUAL_AUDIT_REPORT_F2_KEY}-${string}`>

export const individualReportCardF2Qa = {
  header: `${INDIVIDUAL_AUDIT_REPORT_F2_KEY}-header`,
  title: `${INDIVIDUAL_AUDIT_REPORT_F2_KEY}-title`,
  subTitle: `${INDIVIDUAL_AUDIT_REPORT_F2_KEY}-subTitle`,
  tag: `${INDIVIDUAL_AUDIT_REPORT_F2_KEY}-tag`,
  descriptionMapper: `${INDIVIDUAL_AUDIT_REPORT_F2_KEY}-description-mapper`,
  label: `${INDIVIDUAL_AUDIT_REPORT_F2_KEY}-label`,
  value: `${INDIVIDUAL_AUDIT_REPORT_F2_KEY}-value`,
  requestId: `${INDIVIDUAL_AUDIT_REPORT_F2_KEY}-requestId`,
  copyButton: `${INDIVIDUAL_AUDIT_REPORT_F2_KEY}-copyButton`,
} satisfies Record<string, `${typeof INDIVIDUAL_AUDIT_REPORT_F2_KEY}-${string}`>

export const individualReportSectionF2Qa = {
  header: `${INDIVIDUAL_AUDIT_REPORT_F2_KEY}-header`,
  line: `${INDIVIDUAL_AUDIT_REPORT_F2_KEY}-line`,
  date: `${INDIVIDUAL_AUDIT_REPORT_F2_KEY}-date`,
  statusWrapper: `${INDIVIDUAL_AUDIT_REPORT_F2_KEY}-statusWrapper`,
  time: `${INDIVIDUAL_AUDIT_REPORT_F2_KEY}-time`,
} satisfies Record<string, `${typeof INDIVIDUAL_AUDIT_REPORT_F2_KEY}-${string}`>

export const tagMapperQa = {
  archived: `${INDIVIDUAL_AUDIT_REPORT_F2_KEY}-tag-archived`,
  pass: `${INDIVIDUAL_AUDIT_REPORT_F2_KEY}-tag-pass`,
  fail: `${INDIVIDUAL_AUDIT_REPORT_F2_KEY}-tag-fail`,
  review: `${INDIVIDUAL_AUDIT_REPORT_F2_KEY}-tag-review`,
} satisfies Record<string, `${typeof INDIVIDUAL_AUDIT_REPORT_F2_KEY}-${string}`>

export const checkStatusMapperQa = {
  default: `${INDIVIDUAL_AUDIT_REPORT_F2_KEY}-checkStatus-default`,
  pass: `${INDIVIDUAL_AUDIT_REPORT_F2_KEY}-checkStatus-pass`,
  fail: `${INDIVIDUAL_AUDIT_REPORT_F2_KEY}-checkStatus-fail`,
} satisfies Record<string, `${typeof INDIVIDUAL_AUDIT_REPORT_F2_KEY}-${string}`>

export const workflowStatusMapperQa = {
  default: `${INDIVIDUAL_AUDIT_REPORT_F2_KEY}-workflowStatus-default`,
  pass: `${INDIVIDUAL_AUDIT_REPORT_F2_KEY}-workflowStatus-pass`,
  fail: `${INDIVIDUAL_AUDIT_REPORT_F2_KEY}-workflowStatus-fail`,
  review: `${INDIVIDUAL_AUDIT_REPORT_F2_KEY}-workflowStatus-review`,
} satisfies Record<string, `${typeof INDIVIDUAL_AUDIT_REPORT_F2_KEY}-${string}`>

export const emptyViewQa = {
  emptyScreenHeader: `${INDIVIDUAL_AUDIT_REPORT_F2_KEY}-empty-screen-header`,
} satisfies Record<string, `${typeof INDIVIDUAL_AUDIT_REPORT_F2_KEY}-${string}`>
