import React, { useMemo } from 'react'

import { FrankieToggle } from 'frankify/src'

import { ApplicantId, useApplicantState } from 'entities/applicant'

import { useI18n } from 'shared/i18n'

import { APPLICANT_PEP_SACTIONS_KEY } from '../../applicant-pep-sactions.key'
import { applicantPepSactionsEn } from '../../locale/applicant-pep-sactions.en'
import { getPepStatusMapping } from '../../model/applicant-pep-sactions.model'
import { applicantPepSactionsQa } from '../../qa/applicant-pep-sactions.qa'
import { useApplicantPepSactionsQuery } from '../../state/applicant-pep-sactions-query/applicant-pep-sactions.query'
import { useApplicantToggleOngoingAmlState } from '../../state/applicant-toggle-ongoing-aml/applicant-toggle-ongoing-aml.state'
import { ApplicantPepSactionsDataGrid } from '../applicant-pep-sactions-data-grid/applicant-pep-sactions-data-grid'
import { ApplicantPepSactionsInfoHeader } from '../applicant-pep-sactions-info-header/applicant-pep-sactions-info-header'
import { ApplicantPepSactionsMatchStatusChange } from '../applicant-pep-sactions-match-status-change/applicant-pep-sactions-match-status-change'

type Props = {
  applicantId: ApplicantId
}

export function ApplicantPepSactions({ applicantId }: Props) {
  const t = useI18n(APPLICANT_PEP_SACTIONS_KEY, {
    keys: applicantPepSactionsEn,
  })

  const { inactiveProfile } = useApplicantState({ applicantId })

  const { amlStatus, showOngoingAmlToggle, toggleAmlStatus, isLoading } =
    useApplicantToggleOngoingAmlState({ applicantId })

  const { data } = useApplicantPepSactionsQuery({ applicantId })

  const { needsAmlStatusAction, groupIssuesIds } = useMemo(() => {
    const matches = data?.matches ?? []

    const needsAmlStatusAction =
      matches.some(({ matchStatus }) => {
        const statusMap = getPepStatusMapping(matchStatus.status)
        return statusMap.needsAction
      }) &&
      !matches.some(({ matchStatus }) => {
        const statusMap = getPepStatusMapping(matchStatus.status)
        return statusMap.type === 'non-final' && !statusMap.needsAction
      })

    const groupIssuesIds = matches
      .filter(
        ({ matchStatus }) =>
          getPepStatusMapping(matchStatus.status).term === 'true_positive',
      )
      .map(({ groupId }) => groupId)

    return {
      needsAmlStatusAction: needsAmlStatusAction && !inactiveProfile,
      groupIssuesIds,
    }
  }, [data?.matches, inactiveProfile])

  const indentation = 'px-6'

  return (
    <div>
      {needsAmlStatusAction && (
        <div
          className={`flex gap-3 bg-tertiary-yellow-50 py-3 px-6 items-center ${indentation}`}
        >
          <span className="flex-grow text-md font-semibold">
            {t('hasTruePositiveMatch')}
          </span>
          <ApplicantPepSactionsMatchStatusChange
            applicantId={applicantId}
            groupIds={groupIssuesIds}
            approve
          />
          <ApplicantPepSactionsMatchStatusChange
            applicantId={applicantId}
            groupIds={groupIssuesIds}
            reject
          />
        </div>
      )}

      <div
        className={`flex items-center justify-between my-4 ${indentation}`}
        data-qa={applicantPepSactionsQa.toggleSwitchContainer}
      >
        <span className="flex-grow text-lg font-bold">
          {t('searchResults')}
        </span>
        {showOngoingAmlToggle && (
          <div className="flex-grow-0 flex gap-3 items-center">
            <span className="">{t('ongoingAML')}</span>

            <FrankieToggle
              disabled={isLoading}
              onChange={toggleAmlStatus}
              checked={amlStatus}
              size="sm"
            />
          </div>
        )}
      </div>

      <ApplicantPepSactionsInfoHeader
        applicantId={applicantId}
        className={`${indentation} py-1`}
        search
        testId={{
          container: applicantPepSactionsQa.applicantPepSactionsInfoHeader,
        }}
      />

      <ApplicantPepSactionsDataGrid
        className={`${indentation} py-1`}
        applicantId={applicantId}
        testId={{
          container: applicantPepSactionsQa.ApplicantPepSactionsDataGrid,
        }}
      />
    </div>
  )
}
