import { IUser, UserId } from 'entities/user'

import { bffClient, IClient } from 'shared/client'

import type { CreateUserRequestBody } from '../model'
import { UpdateUsersPatch } from '../model'

export class UserManagementApi {
  client: IClient

  constructor(client: IClient) {
    this.client = client
  }

  async getUserList() {
    return this.client.get<IUser[]>('/data/v1/users')
  }

  async changeUserLock(userId: UserId, locked: boolean) {
    return this.client.put<IUser, { locked: boolean }>(
      `/data/v1/users/${userId}/lock`,
      { locked },
    )
  }

  async createUser(body: CreateUserRequestBody) {
    return this.client.post<IUser, CreateUserRequestBody>(
      '/data/v1/users',
      body,
    )
  }

  async deleteUser(userId: string) {
    return this.client.delete<IUser>(`/data/v1/users/${userId}`)
  }

  async updateUser(userId: string, body: CreateUserRequestBody) {
    return this.client.put<IUser, CreateUserRequestBody>(
      `/data/v1/users/${userId}`,
      body,
    )
  }

  async updateUsers(users: UpdateUsersPatch[]) {
    return this.client.put<IUser[], { users: UpdateUsersPatch[] }>(
      '/data/v1/users',
      {
        users,
      },
    )
  }
}

export const userManagementApi = new UserManagementApi(bffClient)
