export const ORGANISATION_AUDIT_KEY = 'organisation-audit'

export const organisationAuditEn = {
  selectReport: 'Select report to generate',
  selectReportTitleAus:
    'Select a check to run or report to generate on this organisation',
  selectReportTitleRow: 'Generate a report on this organisation',
  amlScreeningTitle: 'AML screening',
  amlScreeningSubtitle:
    'Run a sanctions or adverse media check on this organisation',
  internationalMmlScreeningSubtitle:
    'This will run sanctions or adverse media check on this organisation',
  singleLevelBusinessOwnershipTitle: 'Single level business ownership',
  singleLevelBusinessOwnershipSubtitle:
    'This report will identify the directors and shareholders of a business to a single level as reported via ASIC/ABR. AML screening check will be applied to individuals as configured in your FrankieOne account.',
  uboReportTitle: 'UBO report',
  uboReportSubtitle:
    'This report will identify the ultimate owner(s) of this company and help you understand what role (direct or indirect) individuals play within simple or complex corporate structures',
  creditReportTitle: 'Credit report',
  creditReportSubtitle:
    'Includes credit report and ASIC current extract. Add RiskScore and Payment predictor as an optional add on in the next step.',
  runCheck: 'Run check',
  generateReport: 'Generate report',
  generateReportRow: 'Generate a report on this organisation',
  organisationProfileTitle: 'Organisation profile',
  organisationProfileSubtitle:
    'This report will retrieve all information available on this organisation',
  errorCreatingProfileAUS: 'There was an issue. Please, try again',
  errorCreatingProfileROW:
    'There was an issue generating an organisation profile. Please try again',
  errorOwnershipDetailsROW:
    'There was an issue generating an ownership report. Please try again',
  runCheckOrganisation: 'Run a check on this organisation',
  amlScreening: 'AML screening',
  errorAmlCheck: 'There was an issue running an AML check. Please try again',
  runningAmlCheck: 'Running AML check',

  profileReportModal: {
    title: 'Organisation profile',
    subtitle:
      'This report will retrieve all information available on this organisation from the registration authority in the selected jurisdiction.',
    generateReport: 'Generate report',
    cancelButton: 'Cancel',
  },
  organisationOwnershipTitle: 'Ownership report',
  organisationOwnershipSubtitle:
    'This report will identify the ultimate owner(s) of this company and help you understand what role (direct or indirect) individuals play within simple or complex corporate structures',
  createProfile: 'Create profile',
}
