export const DOCUMENT_KEY = 'DOCUMENT'

export const documentTypeNameEn = {
  documentTypeName: {
    driversLicense: 'Drivers Licence',
    passport: 'Passport',
    houseRegistration: 'House Registration',
    yellowHouseRegistration: 'Yellow House Registration Thor Ror 13',
    workPermit: 'Work Permit',
    visa: 'Non Immigrant Visa or Smart Visa',
    visaDefault: 'Visa',
    nationalId: 'Identity Document Issued from Originating Country',
    employmentCertificate: 'Certificate of Employment/Juristic',
    notaryPublicId: 'SWIFT Authenticate or Notary Public Identification',
    medicareCard: 'Medicare Card',
    proofOfAddress: 'Address Proof',
    immigration: 'Immigration',
    birthCert: 'Birth Certificate',
    citizenship: 'Citizenship Certificate',
    nameChange: 'Change of Name Certificate',
    marriageCert: 'Marriage Certificate',
    taxStatement: 'Tax ID',
    nationalHealthId: 'National Health ID',
    concession: 'Concession',
    healthConcession: 'Health Concession',
    pension: 'Pension',
    militaryId: 'Military Id',
    deathCert: 'Death Certificate',
    utilityBill: 'Utility Bill',
    bankStatement: 'Bank Statement',
    bankAccount: 'Bank Account',
    intentProof: 'Intent Proof',
    selfImage: 'Self Image',
    emailAddress: 'Email Address',
    msisdn: 'Mobile Number',
    device: 'Device',
    vehicleRegistration: 'Vehicle Registration',
    externalAdmin: 'External Admin',
    charges: 'Charges',
    preAsic: 'Pre ASIC',
    annualReturn: 'Annual Return',
    report: 'Report',
    trustDeed: 'Trust Deed',
    partnershipAgreement: 'Partnership Agreement',
    adminChange: 'Admin Change',
    companyReport: 'Company Report',
    checkResults: 'Check Results',
    rentalAgreement: 'Rental Agreement',
    other: 'Other',
    taxId: 'Tax ID',
  },
}

export const documentStatusNameEn = {
  documentStatusType: {
    approved: 'Approved',
    needAttention: 'Needs Attention',
    declined: 'Declined',
  },
}

export const documentEn = {
  message: {
    invalidFileType:
      'We don’t support that file type. Please use {{fileTypes}} instead.',
    invalidFileTypeV2:
      'Unsupported file format. Please upload a {{fileTypes}} file.',
    invalidFileSize: 'Your file needs to be under {{fileSize}}MB',
    invalidFileName:
      'File name is too long or contains special characters. Please rename it (max {{maxLength}} characters) and try again.',
  },
  placeholder: 'Drag and drop copy of document or Browse',
  view: 'View',
  replace: 'Replace',
  uploading: 'Uploading...',
  replacing: 'Replacing...',
  document: 'Document',
  uploadedOn: 'Uploaded On {{date}}',
  v2: {
    placeholder: '<span>Click to upload</span> or drag and drop',
    uploading: 'Uploading {{fileName}}',
    maxFileSize: 'Max. File Size: {{fileSize}} MB',
    browseBtnLabel: 'Browse File',
  },

  ...documentTypeNameEn,
  ...documentStatusNameEn,
}
