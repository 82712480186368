import { useMutation } from '@tanstack/react-query'

import { individualProfileApi } from '../api/individual-profile.api'
import { IndividualProfilePayloadTypes } from '../model/individual-profile-payload.model'

type Args = {
  saveOnly?: boolean
  profile: IndividualProfilePayloadTypes
}

export const useSaveIndividualProfile = () =>
  useMutation({
    mutationFn: ({ profile, saveOnly = false }: Args) =>
      individualProfileApi.saveIndividualProfile(profile, saveOnly),
  })
