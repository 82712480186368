import { useMutation, useQueryClient } from '@tanstack/react-query'

import { EntityDataQueryKey, useEntityLabelDataState } from 'entities/entity'

import { notification } from 'shared/notification'

import { individualSendVerificationLinkApi } from '../api/individual-send-verification-link.api'
import {
  SendLinkPayload,
  useSendLinkTranslation,
} from '../model/individual-send-verification-link.model'

type Args = { entityId: string }

export const useSendVerificationLinkMutation = ({ entityId }: Args) => {
  const { personalInfoWithLabelData } = useEntityLabelDataState({ entityId })
  const t = useSendLinkTranslation()
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (params: SendLinkPayload) => {
      await individualSendVerificationLinkApi.sendLink(params)
    },

    onSuccess: () => {
      void queryClient.invalidateQueries([EntityDataQueryKey, entityId])

      notification.success(
        t('success', { displayName: personalInfoWithLabelData.at(0)?.value }),
      )
    },

    onError: () => {
      void queryClient.invalidateQueries([EntityDataQueryKey, entityId])

      notification.error(t('error'))
    },
  })
}
