import { useMutation } from '@tanstack/react-query'

import { SuggestionString } from 'shared/typescript'

import { organisationApi } from '../../api/organisation.api'
import { CreateProfileInternationalBody } from '../../model/applicant.model'
import { getInternationalProfileFromMatchOrganisation } from '../../model/international-organisation.model'
import { IMatchedOrganization } from '../../model/organisation.model'

export const useRunAmlCheckIKyb = (entityId: SuggestionString<'new'>) => {
  const mutateCreate = useMutation({
    mutationFn: (payload: CreateProfileInternationalBody) =>
      organisationApi.runInternationalAmlCheck(payload),
  })

  const mutateUpdate = useMutation({
    mutationFn: () => organisationApi.reRunInternationalAmlCheck(entityId),
  })

  const runAmlCheck = async ({
    organisationInfo,
  }: {
    organisationInfo: IMatchedOrganization
  }) => {
    const response = await mutateCreate.mutateAsync(
      getInternationalProfileFromMatchOrganisation(organisationInfo),
    )
    return response.data
  }

  const reRunAmlCheck = async () => {
    const response = await mutateUpdate.mutateAsync()
    return response.data
  }

  return {
    ...(entityId === 'new' ? mutateCreate : mutateUpdate),
    runAmlCheck,
    reRunAmlCheck,
  }
}
