type Breakpoint = 'tablet' | 'laptop' | 'desktop'

const screenWidths = {
  tablet: 640,
  laptop: 1024,
  desktop: 1280,
}

export const screens = {
  tablet: `${screenWidths.tablet}px`,
  laptop: `${screenWidths.laptop}px`,
  desktop: `${screenWidths.desktop}px`,
}

export function upBreakpoint(breakpoint: Breakpoint): boolean {
  // window.innerWidth - is a right attribute
  // but for devtools we also need window.screen.width
  return (
    window.innerWidth >= screenWidths[breakpoint] &&
    window.screen.width >= screenWidths[breakpoint]
  )
}
