import React from 'react'

type Props = {
  name: string
  value: string | null
  className?: string
}
export function ContentItem({ name, value, className }: Props) {
  return (
    <div className={className}>
      <div className="text-tertiary-grey-800 font-medium">{name}</div>
      <div className="text-tertiary-grey-700">{value || '-'}</div>
    </div>
  )
}
