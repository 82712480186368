import React from 'react'

import { Control, Controller, FieldPath } from 'react-hook-form'

import { CheckboxGroup } from 'shared/filters-common'
import { TrackingEventsTypes, trackingManager } from 'shared/tracking'

import { AmlFilters } from '../../../model/individual-aml-screening/individual-aml-screening.model'

type OptionsType = {
  label: string
  value: string
}[][]

type Args = {
  control: Control<AmlFilters, unknown>
  name: FieldPath<AmlFilters>
  options: OptionsType
  testId?: {
    amlFilter: string
  }
  trackingEvent: TrackingEventsTypes
}

export function IndividualAmlFormComponent({
  control,
  name,
  options,
  testId,
  trackingEvent,
}: Args) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <CheckboxGroup
          optionGroups={options}
          onChange={val => {
            trackingManager.track(trackingEvent)
            onChange(val)
          }}
          values={value as string[]}
          testId={{ checkbox: testId?.amlFilter }}
        />
      )}
    />
  )
}
