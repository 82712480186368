import { DocumentIdTypes, KycDocumentCategoryTypes } from 'entities/document'
import { PermissionTypes } from 'entities/role'

import { I18nKeys } from 'shared/i18n'
import { EmptyString, Nullable } from 'shared/typescript'

import { individualProfileEn } from '../locale/new-profile.en'

export enum IndividualProfileInputTypes {
  PaternalFamilyName = 'paternalFamilyName',
  ScanId = 'ScanId',
  Gender = 'gender',
  MaternalFamilyName = 'maternalFamilyName',
  LaserCode = 'laserCode',
  Recipe = 'recipe',
  PersonalInfo = 'personalInfo',
  CustomerId = 'customerId',
  FirstName = 'firstName',
  MiddleName = 'middleName',
  LastName = 'lastName',
  Dob = 'dob',
  PhoneNumber = 'phoneNumber',
  Email = 'email',
  CurrentResidentialAddress = 'currentResidentialAddress',
  Country = 'country',
  AddressId = 'addressId',
  Search = 'search',
  ManualAddress = 'manualAddress',
  PropertyName = 'propertyName',
  UnitNumber = 'unitNumber',
  StreetNumber = 'streetNumber',
  StreetName = 'streetName',
  StreetType = 'streetType',
  Suburb = 'suburb',
  State = 'state',
  Town = 'town',
  PostalCode = 'postalCode',
  District = 'district',
  City = 'city',
  PreviousResidentialAddress = 'previousResidentialAddress',
  MailingAddress = 'mailingAddress',
  SameAsCurrent = 'sameAsCurrent',
  KycMethod = 'kycMethod',
  Electronic = 'electronic',
  Manual = 'manual',
  Document = 'document',
  Scan = 'scan',
  UploadedUUID = 'fileUploadUuid',
  FileType = 'mimeType',
  IdType = 'IdType',
  DocumentId = 'documentId',
  DocumentNumber = 'documentNumber',
  IdNumber = 'idNumber',
  BirthCertificate = 'birthCertificate',
  RegistrationDate = 'registrationDate',
  RegistrationDateFull = 'registrationDateFull',
  RegistrationName = 'RegistrationName',
  OtherName = 'otherName',
  FamilyName = 'familyName',
  MedicareCard = 'medicareCard',
  Passport = 'passport',
  IdSubType = 'idSubType',
  Reference = 'reference',
  IdExpiry = 'idExpiry',
  MiddleNameDisplay = 'middleNameDisplay',
  DisplayNameLine = 'displayNameLine',
  Name = 'name',
  CitizenShipCertificate = 'citizenShipCertificate',
  AcquisitionDate = 'acquisitionDate',
  PrimaryCountry = 'primaryCountry',
  SecondaryCountry = 'secondaryCountry',
  ChangeNameCertificate = 'changeNameCertificate',
  NationalId = 'nationalId',
  CertificateDateOfRegistration = 'certificateDateOfRegistration',
  MarriageCertificate = 'marriageCertificate',
  MarriageDate = 'marriageDate',
  Bride = 'bride',
  Groom = 'groom',
  TaxId = 'taxId',
  Other = 'other',
  Description = 'description',
  isActive = 'isActive',
  Consent = 'consent',
  SeparateConsent = 'separateConsent',
  Comment = 'comment',
  DocumentCategory = 'documentCategory',
  ForceCheck = 'forceCheck',
  RegistrationDistrict = 'registrationDistrict',
  CertificateNumber = 'certificateNumber',
  RegistrationYear = 'RegistrationYear',
  DateOfPrint = 'dateOfPrint',
  HomeCountryFullName = 'homeCountryFullName',
  LicenceVersion = 'licenceVersion',
}

export type AddressOption = {
  [IndividualProfileInputTypes.PreviousResidentialAddress]: boolean
  [IndividualProfileInputTypes.MailingAddress]: boolean
}

export interface ISeachAddressInputs {
  [IndividualProfileInputTypes.Country]: string
  [IndividualProfileInputTypes.Search]: string
}

export interface IDetailAddressInputs {
  [IndividualProfileInputTypes.PropertyName]: string
  [IndividualProfileInputTypes.UnitNumber]: string
  [IndividualProfileInputTypes.StreetNumber]: string
  [IndividualProfileInputTypes.StreetName]: string
  [IndividualProfileInputTypes.StreetType]: string
  [IndividualProfileInputTypes.Suburb]: string
  [IndividualProfileInputTypes.State]: string
  [IndividualProfileInputTypes.Town]: string
  [IndividualProfileInputTypes.PostalCode]: string
}

export interface IDocumentsInputs {
  [IndividualProfileInputTypes.Country]: string
  [IndividualProfileInputTypes.IdType]: EmptyString<DocumentIdTypes>
  [IndividualProfileInputTypes.IdNumber]: string
  [IndividualProfileInputTypes.DocumentId]?: string
  [IndividualProfileInputTypes.DocumentNumber]: string
  [IndividualProfileInputTypes.State]: string
  [IndividualProfileInputTypes.DocumentCategory]: Nullable<KycDocumentCategoryTypes>
  [IndividualProfileInputTypes.LicenceVersion]?: Nullable<string>
  [IndividualProfileInputTypes.HomeCountryFullName]?: Nullable<string>
  [IndividualProfileInputTypes.Scan]: {
    [IndividualProfileInputTypes.UploadedUUID]: string
    [IndividualProfileInputTypes.ScanId]?: string
    [IndividualProfileInputTypes.FileType]: string
  }
  [IndividualProfileInputTypes.NationalId]: Nullable<string>
  [IndividualProfileInputTypes.PaternalFamilyName]?: Nullable<string>
  [IndividualProfileInputTypes.MaternalFamilyName]?: Nullable<string>
  [IndividualProfileInputTypes.LaserCode]?: Nullable<string>
  [IndividualProfileInputTypes.Gender]?: Nullable<string>

  [IndividualProfileInputTypes.BirthCertificate]: {
    [IndividualProfileInputTypes.RegistrationDate]: string
    [IndividualProfileInputTypes.RegistrationDistrict]: Nullable<string>
    [IndividualProfileInputTypes.CertificateNumber]: Nullable<string>
    [IndividualProfileInputTypes.DateOfPrint]: Nullable<string>

    [IndividualProfileInputTypes.RegistrationName]: string
    [IndividualProfileInputTypes.OtherName]: string
    [IndividualProfileInputTypes.FamilyName]: string
  }
  [IndividualProfileInputTypes.MedicareCard]: {
    [IndividualProfileInputTypes.IdSubType]: string
    [IndividualProfileInputTypes.Reference]: string
    [IndividualProfileInputTypes.IdExpiry]: string
    [IndividualProfileInputTypes.MiddleNameDisplay]: string
    [IndividualProfileInputTypes.DisplayNameLine]: string
    [IndividualProfileInputTypes.Name]: string
  }

  [IndividualProfileInputTypes.Passport]: {
    [IndividualProfileInputTypes.IdExpiry]: string
  }

  [IndividualProfileInputTypes.CitizenShipCertificate]: {
    [IndividualProfileInputTypes.AcquisitionDate]: string
    [IndividualProfileInputTypes.PrimaryCountry]: string
    [IndividualProfileInputTypes.SecondaryCountry]: string
  }
  [IndividualProfileInputTypes.ChangeNameCertificate]: {
    [IndividualProfileInputTypes.RegistrationName]: string
    [IndividualProfileInputTypes.OtherName]: string
    [IndividualProfileInputTypes.FamilyName]: string
    [IndividualProfileInputTypes.DateOfPrint]?: Nullable<string>
    [IndividualProfileInputTypes.CertificateNumber]?: Nullable<string>
    [IndividualProfileInputTypes.CertificateDateOfRegistration]?: Nullable<string>
  }
  [IndividualProfileInputTypes.MarriageCertificate]: {
    [IndividualProfileInputTypes.MarriageDate]: string
    [IndividualProfileInputTypes.DateOfPrint]?: Nullable<string>
    [IndividualProfileInputTypes.CertificateNumber]?: Nullable<string>
    [IndividualProfileInputTypes.RegistrationYear]?: Nullable<string>
    [IndividualProfileInputTypes.CertificateDateOfRegistration]?: Nullable<string>

    [IndividualProfileInputTypes.Bride]: {
      [IndividualProfileInputTypes.FirstName]: string
      [IndividualProfileInputTypes.OtherName]: string
      [IndividualProfileInputTypes.FamilyName]: string
    }
    [IndividualProfileInputTypes.Groom]: {
      [IndividualProfileInputTypes.FirstName]: string
      [IndividualProfileInputTypes.OtherName]: string
      [IndividualProfileInputTypes.FamilyName]: string
    }
  }
  [IndividualProfileInputTypes.TaxId]: {
    [IndividualProfileInputTypes.IdSubType]: string
  }
  [IndividualProfileInputTypes.Other]: {
    [IndividualProfileInputTypes.Description]: string
  }
}

export interface IIndividualProfileInputs {
  [IndividualProfileInputTypes.Recipe]: string
  [IndividualProfileInputTypes.PersonalInfo]: {
    [IndividualProfileInputTypes.CustomerId]: string
    [IndividualProfileInputTypes.FirstName]: string
    [IndividualProfileInputTypes.MiddleName]: string
    [IndividualProfileInputTypes.LastName]: string
    [IndividualProfileInputTypes.Dob]: string
    [IndividualProfileInputTypes.PhoneNumber]: string
    [IndividualProfileInputTypes.Email]: string
  }
  [IndividualProfileInputTypes.CurrentResidentialAddress]: {
    [IndividualProfileInputTypes.Country]: string
    [IndividualProfileInputTypes.Search]: string
    [IndividualProfileInputTypes.AddressId]: Nullable<string>
    [IndividualProfileInputTypes.isActive]: boolean
    [IndividualProfileInputTypes.ManualAddress]: IDetailAddressInputs
  }
  [IndividualProfileInputTypes.PreviousResidentialAddress]: {
    [IndividualProfileInputTypes.Country]: string
    [IndividualProfileInputTypes.Search]: string
    [IndividualProfileInputTypes.AddressId]: Nullable<string>
    [IndividualProfileInputTypes.isActive]: boolean
    [IndividualProfileInputTypes.ManualAddress]: IDetailAddressInputs
  }
  [IndividualProfileInputTypes.MailingAddress]: {
    [IndividualProfileInputTypes.SameAsCurrent]: boolean
    [IndividualProfileInputTypes.Country]: string
    [IndividualProfileInputTypes.Search]: string
    [IndividualProfileInputTypes.AddressId]: Nullable<string>
    [IndividualProfileInputTypes.isActive]: boolean
    [IndividualProfileInputTypes.ManualAddress]: IDetailAddressInputs
  }

  [IndividualProfileInputTypes.KycMethod]: 'electronic' | 'manual'
  [IndividualProfileInputTypes.Document]: IDocumentsInputs[]

  [IndividualProfileInputTypes.Comment]: string
  [IndividualProfileInputTypes.SeparateConsent]: [
    false | ConsentTypes,
    false | ConsentTypes,
    false | ConsentTypes,
  ]
  [IndividualProfileInputTypes.Consent]: boolean
  [IndividualProfileInputTypes.ForceCheck]: boolean
}

export const detailAddressInputs: IDetailAddressInputs = {
  [IndividualProfileInputTypes.PropertyName]: '',
  [IndividualProfileInputTypes.UnitNumber]: '',
  [IndividualProfileInputTypes.StreetNumber]: '',
  [IndividualProfileInputTypes.StreetName]: '',
  [IndividualProfileInputTypes.StreetType]: '',
  [IndividualProfileInputTypes.Suburb]: '',
  [IndividualProfileInputTypes.State]: '',
  [IndividualProfileInputTypes.Town]: '',
  [IndividualProfileInputTypes.PostalCode]: '',
}

export type AddressType =
  | `${IndividualProfileInputTypes.CurrentResidentialAddress}.${IndividualProfileInputTypes.ManualAddress}`
  | `${IndividualProfileInputTypes.PreviousResidentialAddress}.${IndividualProfileInputTypes.ManualAddress}`
  | `${IndividualProfileInputTypes.MailingAddress}.${IndividualProfileInputTypes.ManualAddress}`

export const documentDefaultValue: IDocumentsInputs = {
  [IndividualProfileInputTypes.NationalId]: '',
  [IndividualProfileInputTypes.Country]: 'AUS',
  [IndividualProfileInputTypes.IdType]: '',
  [IndividualProfileInputTypes.IdNumber]: '',
  [IndividualProfileInputTypes.DocumentNumber]: '',
  [IndividualProfileInputTypes.State]: '',
  [IndividualProfileInputTypes.DocumentCategory]: null,

  [IndividualProfileInputTypes.Scan]: {
    [IndividualProfileInputTypes.UploadedUUID]: '',
    [IndividualProfileInputTypes.FileType]: '',
  },

  [IndividualProfileInputTypes.BirthCertificate]: {
    [IndividualProfileInputTypes.RegistrationDate]: '',
    [IndividualProfileInputTypes.RegistrationDistrict]: null,
    [IndividualProfileInputTypes.CertificateNumber]: null,
    [IndividualProfileInputTypes.DateOfPrint]: null,
    [IndividualProfileInputTypes.RegistrationName]: '',
    [IndividualProfileInputTypes.OtherName]: '',
    [IndividualProfileInputTypes.FamilyName]: '',
  },
  [IndividualProfileInputTypes.MedicareCard]: {
    [IndividualProfileInputTypes.IdSubType]: 'G',
    [IndividualProfileInputTypes.Reference]: '',
    [IndividualProfileInputTypes.IdExpiry]: '',
    [IndividualProfileInputTypes.MiddleNameDisplay]: '',
    [IndividualProfileInputTypes.DisplayNameLine]: '',
    [IndividualProfileInputTypes.Name]: '',
  },
  [IndividualProfileInputTypes.Passport]: {
    [IndividualProfileInputTypes.IdExpiry]: '',
  },
  [IndividualProfileInputTypes.CitizenShipCertificate]: {
    [IndividualProfileInputTypes.AcquisitionDate]: '',
    [IndividualProfileInputTypes.PrimaryCountry]: 'AUS',
    [IndividualProfileInputTypes.SecondaryCountry]: '',
  },
  [IndividualProfileInputTypes.ChangeNameCertificate]: {
    [IndividualProfileInputTypes.RegistrationName]: '',
    [IndividualProfileInputTypes.OtherName]: '',
    [IndividualProfileInputTypes.FamilyName]: '',
  },
  [IndividualProfileInputTypes.MarriageCertificate]: {
    [IndividualProfileInputTypes.MarriageDate]: '',
    [IndividualProfileInputTypes.Bride]: {
      [IndividualProfileInputTypes.FirstName]: '',
      [IndividualProfileInputTypes.OtherName]: '',
      [IndividualProfileInputTypes.FamilyName]: '',
    },
    [IndividualProfileInputTypes.Groom]: {
      [IndividualProfileInputTypes.FirstName]: '',
      [IndividualProfileInputTypes.OtherName]: '',
      [IndividualProfileInputTypes.FamilyName]: '',
    },
  },
  [IndividualProfileInputTypes.TaxId]: {
    [IndividualProfileInputTypes.IdSubType]: '',
  },
  [IndividualProfileInputTypes.Other]: {
    [IndividualProfileInputTypes.Description]: '',
  },
}

export const individualProfileDefaultValues: IIndividualProfileInputs = {
  recipe: '',
  personalInfo: {
    customerId: '',
    firstName: '',
    middleName: '',
    lastName: '',
    dob: '',
    phoneNumber: '',
    email: '',
  },
  currentResidentialAddress: {
    country: 'AUS',
    addressId: null,
    search: '',
    isActive: true,
    manualAddress: { ...detailAddressInputs },
  },
  previousResidentialAddress: {
    country: 'AUS',
    addressId: null,
    search: '',
    isActive: false,
    manualAddress: { ...detailAddressInputs },
  },
  mailingAddress: {
    sameAsCurrent: false,
    country: 'AUS',
    addressId: null,
    search: '',
    isActive: false,
    manualAddress: { ...detailAddressInputs },
  },

  [IndividualProfileInputTypes.KycMethod]: 'electronic',

  document: [{ ...documentDefaultValue }],
  comment: '',
  consent: false,
  forceCheck: false,
  separateConsent: [false, false, false],
}

export const manualIdvPermissionObject = {
  personalInfoPermission: [
    PermissionTypes.ManualIdvUpdateAll,
    PermissionTypes.ManualIdvUpdatePersonalInfo,
  ],
  deleteDocumentPermission: PermissionTypes.ManualIdvDeleteDocument,
  editDocumentPermission: [
    PermissionTypes.ManualIdvUpdateAll,
    PermissionTypes.ManualIdvUpdateDocumentInfo,
  ],
  addDocumentPermission: [
    PermissionTypes.ManualIdvUpdateAll,
    PermissionTypes.ManualIdvUpdateDocumentInfo,
    PermissionTypes.ManualIdvManualKyc,
    PermissionTypes.ManualIdvElectronicKyc,
  ],
  uploadScanPermission: [
    PermissionTypes.ManualIdvUploadScan,
    PermissionTypes.ManualIdvManualKyc,
  ],
  switchKycMethodPermission: [
    PermissionTypes.ManualIdvUpdateAll,
    PermissionTypes.ManualIdvManualKyc,
    PermissionTypes.ManualIdvElectronicKyc,
  ],
  savePermission: [
    PermissionTypes.ManualIdvUpdateAll,
    PermissionTypes.ManualIdvUpdatePersonalInfo,
    PermissionTypes.ManualIdvUpdateDocumentInfo,
    PermissionTypes.ManualIdvManualKyc,
    PermissionTypes.ManualIdvElectronicKyc,
  ],
}

type SeparateConsentDetails = {
  [x in
    | IndividualProfileInputTypes.Electronic
    | IndividualProfileInputTypes.Manual]: {
    desc: I18nKeys<typeof individualProfileEn>
    title: I18nKeys<typeof individualProfileEn>
    value: 'general' | 'docs' | 'creditheader'
    name: 'separateConsent.0' | 'separateConsent.1' | 'separateConsent.2'
    required: boolean
  }[]
}

export const separateConsents: SeparateConsentDetails = {
  [IndividualProfileInputTypes.Manual]: [
    {
      desc: 'consentForm.general.eKyc',
      title: 'consentForm.general.title',
      value: 'general',
      name: 'separateConsent.0',
      required: true,
    },
  ],
  [IndividualProfileInputTypes.Electronic]: [
    {
      desc: 'consentForm.general.mKyc',
      title: 'consentForm.general.title',
      value: 'general',
      required: true,
      name: 'separateConsent.0',
    },
    {
      desc: 'consentForm.docs.mKyc',
      title: 'consentForm.docs.title',
      name: 'separateConsent.1',
      value: 'docs',
      required: false,
    },
    {
      desc: 'consentForm.creditHeader.mKyc',
      title: 'consentForm.creditHeader.title',
      name: 'separateConsent.2',
      value: 'creditheader',
      required: false,
    },
  ],
}

export enum ConsentTypes {
  general = 'general',
  docs = 'docs',
  creditHeader = 'creditHeader',
}

export const consentsArray = [
  ConsentTypes.general,
  ConsentTypes.docs,
  ConsentTypes.creditHeader,
]
