import React, { useMemo } from 'react'

import { FrankieButton, FrankieIcon } from 'frankify/src'

import { CustomerChildId } from 'entities/organisation'
import { useSessionQuery } from 'entities/session'

import { SelectedAccounts } from '../../model/dashboard-form.model'

type Account = {
  name: string
  id: CustomerChildId
  isParent: boolean
}

type Props = {
  className?: string
  selectedAccount: SelectedAccounts
  onAccountSelect: (arg: SelectedAccounts) => void
}

export function SelectAccounts({
  className = '',
  selectedAccount,
  onAccountSelect,
}: Readonly<Props>) {
  const { data: session } = useSessionQuery()

  const accounts = useMemo<Account[]>(() => {
    if (!session?.organisation) return []
    const { activeChildCustomerId } = session.user

    const childrenAccount =
      session.organisation.children?.map(child => ({
        name: child.name,
        id: child.customerChildId,
        isParent: false,
      })) ?? []

    if (activeChildCustomerId) {
      return childrenAccount.filter(child => child.id === activeChildCustomerId)
    }

    const parentAccount = [
      {
        name: session.organisation.name,
        id: session.organisation.customerChildId,
        isParent: true,
      },
    ]

    return [...parentAccount, ...childrenAccount]
  }, [session])

  const handleAccountSelection = (account: Account, isSelected: boolean) => {
    onAccountSelect({
      ...selectedAccount,
      ...(account.isParent
        ? { isOwnSelected: !isSelected }
        : {
            customerChildIds: isSelected
              ? selectedAccount.customerChildIds.filter(id => id !== account.id)
              : [...selectedAccount.customerChildIds, account.id],
          }),
    })
  }

  return (
    <div className={`flex items-center gap-6 ${className}`}>
      {
        // No need to account select option if account there are not multiple accounts
        accounts.length > 1 &&
          accounts.map(account => {
            const isSelected = account.isParent
              ? selectedAccount.isOwnSelected
              : selectedAccount.customerChildIds.includes(account.id)
            return (
              <FrankieButton
                key={account.id}
                className="flex items-center gap-4 flex-grow-0"
                noStyles
                onClick={() => handleAccountSelection(account, isSelected)}
              >
                <span
                  className={`cursor-pointer w-[20px] h-[20px] border border-gray-500 rounded-full ${
                    isSelected ? 'bg-mono-black' : ''
                  }`}
                >
                  {isSelected && (
                    <FrankieIcon
                      name="mdiCheck"
                      className="text-mono-white"
                      size="xs"
                    />
                  )}
                </span>
                <span>{account.name}</span>
              </FrankieButton>
            )
          })
      }
    </div>
  )
}
