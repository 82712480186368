import { INDIVIDUAL_SEND_VERIFICATION_LINK_KEY } from '../individual-send-verification-link.key'

const individualSendVerificationLinkForm = `${INDIVIDUAL_SEND_VERIFICATION_LINK_KEY}-IndividualSendVerificationLinkForm`

export const individualSendVerificationLinkFormQa = {
  formContainer: `${individualSendVerificationLinkForm}-form-container`,
  stepOneTitle: `${individualSendVerificationLinkForm}-step-one-title`,
  description: `${individualSendVerificationLinkForm}-description`,
  selectInput: `${individualSendVerificationLinkForm}-select-input`,
  confirmationInput: `${individualSendVerificationLinkForm}-confirmation-input`,
  phoneNumberInput: `${individualSendVerificationLinkForm}-phone-number-input`,
  commentInput: `${individualSendVerificationLinkForm}-comment-input`,
  cancelBtn: `${individualSendVerificationLinkForm}-cancel-btn`,
  nextBtn: `${individualSendVerificationLinkForm}-next-btn`,
  stepTwoTitle: `${individualSendVerificationLinkForm}-step-two-title`,
  supportLinkContainer: `${individualSendVerificationLinkForm}-support-link-container`,
  messageInput: `${individualSendVerificationLinkForm}-message-input`,
  backBtn: `${individualSendVerificationLinkForm}-back-btn`,
  actionCtaContainer: `${individualSendVerificationLinkForm}-action-cta-container`,
  loader: `${individualSendVerificationLinkForm}-loader`,
} satisfies Record<
  string,
  `${typeof individualSendVerificationLinkForm}-${string}`
>

const individualSendVerificationLinkActions = `${INDIVIDUAL_SEND_VERIFICATION_LINK_KEY}-IndividualSendVerificationLinkActions`

export const individualSendVerificationLinkActionsQa = {
  copyBtn: `${individualSendVerificationLinkActions}-copy-btn`,
  sendBtn: `${individualSendVerificationLinkActions}-send-btn`,
} satisfies Record<
  string,
  `${typeof individualSendVerificationLinkActions}-${string}`
>

const individualSendVerificationLink = `${INDIVIDUAL_SEND_VERIFICATION_LINK_KEY}-IndividualSendVerificationLink`

export const individualSendVerificationLinkQa = {
  sendBtn: `${individualSendVerificationLink}-send-btn`,
} satisfies Record<string, `${typeof individualSendVerificationLink}-${string}`>
