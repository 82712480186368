import { useCallback, useEffect, useState } from 'react'

import { useMutation } from '@tanstack/react-query'

import { useI18n } from 'shared/i18n'
import { notification } from 'shared/notification'

import { applicantApi } from '../../api/applicant.api'
import { APPLICANT_KEY, applicantEn } from '../../locale/applicant.en'
import { ApplicantId } from '../../model/applicant.model'
import { useApplicantDataQuery } from '../applicant-data-query/applicant-data.query'

type Args = {
  applicantId: ApplicantId
}

export const useApplicantAssignee = ({ applicantId }: Args) => {
  const t = useI18n([APPLICANT_KEY], { keys: applicantEn })

  const { data: applicantData, remove } = useApplicantDataQuery({ applicantId })
  const assignee = applicantData?.applicantDetails.assignee ?? ''

  const [assigneeValue, setAssigneeValue] = useState<string>(assignee)
  const [isAssigneeSynced, setIsAssigneeSynced] = useState(false)

  useEffect(() => {
    setAssigneeValue(assignee)
  }, [assignee])

  const { mutate, isLoading } = useMutation({
    mutationFn: ({ username }: { username: string }) =>
      applicantApi.assignUser(applicantId, { assignee: username }),
    onError: () => {
      notification.error(t('error.updatingAssignee'))
    },
    onSuccess: (_, { username }) => {
      setAssigneeValue(username)
      setIsAssigneeSynced(true)
      setTimeout(() => setIsAssigneeSynced(false), 2000)
      remove()
    },
  })

  const updateAssignee = useCallback(
    (username: string) => {
      mutate({ username })
    },
    [mutate],
  )

  return {
    assignee: assigneeValue,
    updateAssignee,
    updatingAssignee: isLoading,
    assigneeUpdateSuccessfully: isAssigneeSynced,
  }
}
