import { KeyPrefix, Namespace } from 'i18next'
import {
  useTranslation,
  FallbackNs,
  UseTranslationOptions,
} from 'react-i18next'

// taken from
// https://stackoverflow.com/questions/58434389/typescript-deep-keyof-of-a-nested-object
type Leaves<T> = T extends object
  ? {
      [K in keyof T]: `${Exclude<K, symbol>}${Leaves<T[K]> extends never
        ? ''
        : `.${Leaves<T[K]>}`}`
    }[keyof T]
  : never

export type I18nKeys<T extends object = { [key: string]: string }> = Leaves<T>

export interface I18nFunction<T extends object = { [key: string]: string }> {
  (key: Leaves<T>, params?: unknown): string
}

/**
 * @param options.keys can be used to type the keys of the namespace
 */
export function useI18n<
  N extends Namespace = 'common',
  R extends object = { [key: string]: string },
  TKPrefix extends KeyPrefix<FallbackNs<N>> = undefined,
>(
  ns: N,
  options?: UseTranslationOptions<TKPrefix> & { keys?: R },
): I18nFunction<R> {
  const { t } = useTranslation(ns, options)
  return t as I18nFunction<R>
}
