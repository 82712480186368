import React from 'react'

import { useI18n } from 'shared/i18n'

import { ORGANISATION_SEARCH_KEY } from '../../../organisation-search/organisation-search.key'

export function RowSearchResultGutter() {
  const t = useI18n([ORGANISATION_SEARCH_KEY])

  return (
    <div className="mt-8 flex flex-col pb-16">
      <div className="text-center">
        <span className="text-tertiary-grey-700 text-sm font-normal">
          {t('rowResultGutter.title')}
        </span>{' '}
        <a
          href="https://apidocs.frankiefinancial.com/docs/create-a-new-business-profile-via-the-portal-1"
          target="_blank"
          className="text-primary-800 text-sm"
          rel="noreferrer"
        >
          {t('manuallyAdd.learnMore')}
        </a>{' '}
        <span className="text-tertiary-grey-700 text-sm font-normal">
          {t('manuallyAdd.about')}
        </span>
      </div>
      <div className="text-center mt-3">
        <a
          href="https://apidocs.frankiefinancial.com/docs/contacting-developer-support"
          target="_blank"
          className="text-primary-800 text-sm block"
          rel="noreferrer"
        >
          {t('giveFeedback')}
        </a>
      </div>
    </div>
  )
}
