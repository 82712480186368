import React, { useEffect, useState } from 'react'

import {
  DefaultValues,
  FieldValues,
  UseFormReturn,
  useForm,
} from 'react-hook-form'

import {
  FrankieButton,
  FrankieIcon,
  FrankieIconName,
  FrankieLoader,
} from 'frankify/src'

import { useI18n } from 'shared/i18n'
import { Noop } from 'shared/typescript'

import { APPLICANT_GENERAL_INFORMATION_KEY } from '../../../applicant-general-information.key'
import { applicantGeneralInformationEn } from '../../../locale/applicant-general-information.en'

type Props<TFieldValues extends FieldValues> = {
  heading: string
  iconName: FrankieIconName
  onClose: Noop
  onSubmit: (data: TFieldValues) => void
  onCancel?: Noop
  submitText: string
  cancelText?: string
  isProgress?: boolean
  isSuccess?: boolean
  closeOn?: 'submit' | 'success' | 'never'
  renderForm: (form: UseFormReturn<TFieldValues>) => React.ReactElement
  defaultValues?: DefaultValues<TFieldValues>
  confirmation?: {
    description: string
    confirmText: string
  }
}

export function ApplicantActionModalWrapper<TFieldValues extends FieldValues>({
  heading,
  iconName,
  onSubmit,
  onCancel,
  onClose,
  submitText,
  cancelText,
  confirmation,
  isProgress = false,
  isSuccess = false,
  closeOn = 'success',
  renderForm,
  defaultValues,
}: Props<TFieldValues>) {
  const t = useI18n([APPLICANT_GENERAL_INFORMATION_KEY], {
    keys: applicantGeneralInformationEn,
  })

  const [askConfirmation, setAskConfirmation] = useState(false)

  const form = useForm<TFieldValues>({
    mode: 'onChange',
    defaultValues,
  })

  useEffect(() => {
    if (isSuccess && closeOn === 'success') {
      onClose()
    }
  }, [isSuccess])

  const { formState, handleSubmit } = form

  const handleFormSubmit = (data: TFieldValues) => {
    const isConfirmationRequired = !!confirmation
    if (isConfirmationRequired && !askConfirmation) {
      setAskConfirmation(true)
    } else {
      onSubmit(data)
      if (closeOn === 'submit') {
        onClose()
      }
    }
  }

  return (
    <div className="relative bg-mono-white rounded-2 w-[500px]">
      <div className="flex justify-between py-4 px-6 border-b border-neutral-50">
        <div className="flex gap-2">
          {!askConfirmation && (
            <FrankieIcon
              name={iconName}
              className="text-tertiary-grey-400"
              size="md"
            />
          )}
          <div className="text-md font-bold ">
            {askConfirmation ? t('action.heading.confirm') : heading}
          </div>
        </div>
        <FrankieButton
          noStyles
          onClick={onClose}
          className="flex-grow-0"
          singleIcon={{
            name: 'mdiClose',
          }}
        />
      </div>
      <FrankieLoader loading={isProgress}>
        <form
          onSubmit={handleSubmit(handleFormSubmit)}
          className="flex flex-col gap-4 overflow-y-auto p-6 text-tertiary-grey-700"
        >
          {askConfirmation ? (
            <div className="flex flex-col items-center gap-2 font-bold bg-tertiary-red-50 p-8 text-tertiary-red-500 rounded-sm">
              <FrankieIcon name="mdiAlertOutline" />
              <span className="text-center"> {confirmation?.description}</span>
            </div>
          ) : (
            renderForm(form)
          )}

          <div className="flex justify-between pt-2">
            <FrankieButton disabled={!formState.isValid} type="submit">
              {askConfirmation ? confirmation?.confirmText : submitText}
            </FrankieButton>

            <FrankieButton intent="secondary" onClick={onCancel ?? onClose}>
              {cancelText ?? t('action.cancel')}
            </FrankieButton>
          </div>
        </form>
      </FrankieLoader>
    </div>
  )
}
