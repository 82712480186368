import { DocumentStatusTypes, DocumentTypes } from 'entities/document'

import { Nullable } from 'shared/typescript'

export enum DocumentContextTypes {
  supporting_docs = 'supporting_docs',
}

export enum ChannelTypes {
  PORTAL = 'PORTAL',
  SMART_UI = 'SMART_UI',
  API = 'API',
}

export interface IChangeStamp {
  channel: ChannelTypes
  userId: string
  by: string
  timestamp: string
}

export type SupportingDocumentReference = {
  uuid: string
  type: string
}

export interface IApplicantSupportingDocuments {
  id?: Nullable<string>
  type: DocumentTypes
  label: string
  file: Nullable<{
    scanId: Nullable<string>
    fileUploadUuid: Nullable<string>
    file: Nullable<string>
    mimeType: Nullable<string>
    side: Nullable<string>
    scanCreated: Nullable<string>
    scanName: Nullable<string>
  }>
  status?: Nullable<DocumentStatusTypes>
  modified?: Nullable<IChangeStamp>
  reviewed?: Nullable<IChangeStamp>
  refer?: Nullable<SupportingDocumentReference>
  smartUiDocOrder?: Nullable<number>
}

export const enum DocumentTabTypes {
  UPLOADED = 'UPLOADED',
  TRUSTS = 'TRUSTS',
  PURCHASED = 'PURCHASED',
  CATALOG = 'CATALOG',
}
