import { useQuery } from '@tanstack/react-query'

import { organisationApi } from '../../api/organisation.api'
import { OrganisationProfile } from '../../model/organisation.model'

export const OrganisationQueryKey = ['organisation']

type Args = {
  entityId?: string
}

export const useOrganisationProfile = ({ entityId }: Args) =>
  useQuery<OrganisationProfile>({
    enabled: !!entityId,
    queryKey: [OrganisationQueryKey, entityId],
    queryFn: async () => {
      const { data } = await organisationApi.getProfile(entityId!)

      return data
    },
  })
