import React from 'react'

import { FrankieButton, FrankieIcon, FrankieLoader } from 'frankify/src'

import { useI18n } from 'shared/i18n'
import { Noop } from 'shared/typescript'

import { APPLICANT_KEY, applicantEn } from '../../locale/applicant.en'
import { ApplicantId } from '../../model/applicant.model'
import { applicantQa } from '../../qa/applicant.qa'
import { useApplicantState } from '../../state/applicant-data-query/applicant-data.query'
import { useGenerateIndividualReportQuery } from '../../state/applicant-generate-individual-report/applicant-generate-individual-report'

type Props = { applicantId: ApplicantId; handleClose: Noop }

// eslint-disable-next-line complexity
export function GenerateIndividualVerificationReport({
  applicantId,
  handleClose,
}: Props) {
  const t = useI18n([APPLICANT_KEY], {
    keys: applicantEn,
  })

  const {
    isGenerateReport,
    fetchingReport,
    needRetry,
    generateReport,
    retryGenerateReport,
    reportGenerated,
    reportUrl,
    notifyWhenGenerated,
  } = useGenerateIndividualReportQuery({ applicantId })

  const { applicantName } = useApplicantState({ applicantId })

  const onClose = () => {
    if (fetchingReport) {
      notifyWhenGenerated()
    }
    handleClose()
  }

  return (
    <div className="relative bg-mono-white rounded-2 w-[500px]">
      <div className="flex justify-between py-4 px-6 border-b border-neutral-50">
        <div className="flex gap-2">
          <FrankieIcon
            name="mdiFileDocumentOutline"
            className="text-tertiary-grey-400"
            size="md"
          />

          <div
            className="text-md font-bold"
            data-qa={applicantQa.generateReport}
          >
            {t('generateIndividualReport.generateIndividualVerificationReport')}
          </div>
        </div>
        <FrankieButton
          noStyles
          onClick={onClose}
          className="flex-grow-0"
          singleIcon={{
            name: 'mdiClose',
          }}
          testId={{ button: 'text' }} // test ids
        />
      </div>

      <div className="flex flex-col gap-4 overflow-y-auto p-6">
        {isGenerateReport ? (
          <>
            {fetchingReport && (
              <>
                <FrankieLoader loading className="min-h-[50px]" />
                <div className="text-tertiary-grey-700 font-bold">
                  {t(
                    'generateIndividualReport.description.pleaseWaitForReport',
                  )}
                </div>
                <div className="text-tertiary-grey-700">
                  {t('generateIndividualReport.description.closeAlternatively')}
                </div>
              </>
            )}
            {needRetry && (
              <div className="flex flex-col items-center gap-2 font-bold bg-tertiary-red-50 p-8 text-tertiary-red-500 rounded-sm">
                <FrankieIcon name="mdiAlertOutline" />
                <span className="text-center">
                  {t('generateIndividualReport.error.generateIndividualReport')}
                </span>
              </div>
            )}
            {reportGenerated && (
              <>
                <div className="text-tertiary-grey-700 font-bold">
                  {t('generateIndividualReport.status.reportGeneratedSuccess', {
                    name: applicantName,
                  })}
                </div>
                <div className="text-tertiary-grey-700">
                  {t('generateIndividualReport.description.reportAvailable')}
                </div>
              </>
            )}
          </>
        ) : (
          <>
            <div
              className="text-tertiary-grey-700 font-bold"
              data-qa={applicantQa.containsSummary}
            >
              {t('generateIndividualReport.description.containsSummary')}
            </div>

            <div className="text-tertiary-grey-700">
              {t(
                'generateIndividualReport.description.reflectionUptoThisPoint',
              )}
            </div>
          </>
        )}

        <div className="flex justify-between pt-2">
          {isGenerateReport ? (
            <div>
              {needRetry && !fetchingReport && (
                <FrankieButton onClick={retryGenerateReport}>
                  {t('generateIndividualReport.retry')}
                </FrankieButton>
              )}
              {reportGenerated && (
                <a href={reportUrl} target="_blank" rel="noreferrer">
                  <FrankieButton>
                    {t('generateIndividualReport.openReport')}
                  </FrankieButton>
                </a>
              )}
            </div>
          ) : (
            <FrankieButton onClick={generateReport}>
              {t('generateIndividualReport.generateReport')}
            </FrankieButton>
          )}

          <FrankieButton intent="secondary" onClick={onClose}>
            {fetchingReport
              ? t('generateIndividualReport.close')
              : t('generateIndividualReport.cancel')}
          </FrankieButton>
        </div>
      </div>
    </div>
  )
}
