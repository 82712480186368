import { bffClient, IClient } from 'shared/client'

import {
  ApplicantTransactionsResponse,
  TransactionBulkActionResponse,
  ChangeTransactionStatusBulkPayload,
  ChangeTransactionStatusPayload,
  TransactionTypes,
} from '../model/applicant-transactions.model'

export class ApplicantTransactionsAPI {
  client: IClient

  constructor(client: IClient) {
    this.client = client
  }

  getTransactions(
    applicantId: string,
    params: {
      type: TransactionTypes[]
      needsAttention?: boolean
      limit?: number
      nextPageToken?: string
    },
  ) {
    const { type, ...remainingParams } = {
      needsAttention: false,
      limit: 50,
      ...params,
    }

    return this.client.get<ApplicantTransactionsResponse>(
      `data/v1/applicant/${applicantId}/transactions`,
      {
        params: {
          type: type.join(','),
          ...remainingParams,
        },
      },
    )
  }

  changeTransactionStatus(
    applicantId: string,
    transactionResultId: string,
    payload: ChangeTransactionStatusPayload,
  ) {
    return this.client.patch(
      `data/v1/applicant/${applicantId}/transactions/${transactionResultId}`,
      payload,
    )
  }

  changeTransactionsStatusBulk(
    applicantId: string,
    payload: ChangeTransactionStatusBulkPayload,
  ) {
    return this.client.patch<
      TransactionBulkActionResponse,
      ChangeTransactionStatusBulkPayload
    >(`data/v1/applicant/${applicantId}/transactions`, payload)
  }
}
export const applicantTransactionsApi = new ApplicantTransactionsAPI(bffClient)
