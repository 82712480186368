/* eslint-disable import/no-named-default */
import { default as BusinessPepCheckIcon } from './business-check-icon/pep-check.svg'
import { default as BusinessCreditReportIcon } from './business-report-icon/credit-report.svg'
import { default as BusinessSingleLevelReportIcon } from './business-report-icon/single-level-report.svg'
import { default as BusinessUBOReportIcon } from './business-report-icon/ubo-report.svg'

const businessReportIcon = {
  owner: BusinessSingleLevelReportIcon,
  ubo: BusinessUBOReportIcon,
  credit: BusinessCreditReportIcon,
}

const businessCheckIcon = {
  pepCheck: BusinessPepCheckIcon,
}

export { businessReportIcon, businessCheckIcon }
