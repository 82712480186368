import { useQuery } from '@tanstack/react-query'

import { organisationApi } from '../../api/organisation.api'
import { IMatchedOrganization } from '../../model/organisation.model'

export const OrganizationsLookupQueryKey = 'organizations-lookup'

interface IParams {
  search: string
  country: string
  subdivision?: string
}

export const useOrganizationsLookupQuery = ({
  search,
  country,
  subdivision,
}: IParams) =>
  useQuery<IMatchedOrganization[] | undefined>({
    enabled: false,
    queryKey: [OrganizationsLookupQueryKey],
    queryFn: async () => {
      const { data } = await organisationApi.getOrganizationLookup(
        search,
        country,
        subdivision,
      )

      return data.matchedOrganizations
    },
  })
