import { cva } from 'cva'

export const StepperIconStyle = cva(['mr-1.5', 'text-tertiary-grey-400'], {
  variants: {
    selected: {
      true: ['!text-primary-800'],
    },
  },
})

export const StepperLabelStyle = cva(['text-tertiary-grey-500'], {
  variants: {
    selected: {
      true: ['!text-primary-600'],
    },
  },
})
