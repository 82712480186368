import React, { useEffect } from 'react'

import { FrankieIcon } from 'frankify/src'

import { useI18n } from 'shared/i18n'
import { TrackingEventsTypes, trackingManager } from 'shared/tracking'

import { INDIVIDUAL_AML_SCREENING_KEY } from '../../individual-aml-screening.key'
import { individualAmlScreeningEn } from '../../locale/individual-aml-screening.en'
import { noPlaceHolderQa } from '../../qa/individul-aml-screening.qa'

type Props = {
  trackingEvent?: {
    view: TrackingEventsTypes
  }
}
export function IndividualAmlNoRowPlaceholder({ trackingEvent }: Props) {
  const t = useI18n([INDIVIDUAL_AML_SCREENING_KEY], {
    keys: individualAmlScreeningEn,
  })

  useEffect(() => {
    if (trackingEvent) {
      trackingManager.track(trackingEvent.view)
    }
  }, [trackingEvent])

  return (
    <div
      className="flex align-center flex-col w-full h-full bg-mono-white"
      data-qa={noPlaceHolderQa.container}
    >
      <div className="mt-12">
        <FrankieIcon
          size="xl"
          name="mdiMagnify"
          className="text-primary-300 flex justify-center"
          testId={{ icon: noPlaceHolderQa.icon }}
        />
        <div className="flex flex-col items-center mt-3">
          <span
            className="text-lg font-bold inline-block leading-6"
            data-qa={noPlaceHolderQa.noMatch}
          >
            {t('noMatchTitle')}
          </span>
          <span
            className="text-sm font-normal max-w-[430px] text-center mt-3 leading-6 whitespace-pre-wrap"
            data-qa={noPlaceHolderQa.noMatchSubtitle}
          >
            {t('noMatchSubtitle')}
          </span>
        </div>
      </div>
    </div>
  )
}
