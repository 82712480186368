import React, { useMemo } from 'react'

import { Link } from 'react-router-dom'

import { FrankieIcon, FrankieIconName, FrankieLoader } from 'frankify/src'

import {
  ApplicantIssueActionBadge,
  useApplicantPaths,
} from 'entities/applicant'
import { DocumentView, Scan } from 'entities/document'

import { getDataFileUrl } from 'shared/file'
import { useI18n } from 'shared/i18n'
import { useOverlay } from 'shared/overlay'
import { assertContent } from 'shared/string'
import { Nullable } from 'shared/typescript'

import { APPLICANT_BIOMETRICS_OCR_KEY } from '../../applicant-biometrics-ocr.key'
import { applicantBiometricsOcrEn } from '../../locale/applicant-biometrics-ocr.en'
import {
  ApplicantDetails,
  BiometryItem,
  formatOcrDate,
  scoreStatus,
} from '../../model/applicant-biometrics-ocr.model'
import { applicantBiometricOcrHelperQa } from '../../qa/applicant-biometric-ocr.qa'

type BannerWrapperProps = {
  message: string
  iconName: FrankieIconName
  children?: React.ReactNode
  className?: string
  iconClassName?: string
  isHidden?: boolean
  loading?: boolean
}

export function BannerWrapper({
  children,
  iconName,
  message,
  isHidden = false,
  className = 'bg-neutral-30 text-tertiary-grey-500',
  iconClassName = '',
  loading = false,
}: BannerWrapperProps) {
  const t = useI18n([APPLICANT_BIOMETRICS_OCR_KEY], {
    keys: applicantBiometricsOcrEn,
  })

  if (isHidden) return null

  return (
    <FrankieLoader
      loading={loading}
      size="xs"
      className={`flex !h-[50px] gap-4 items-center px-6 py-4 max-w-max font-bold ${className} rounded-md`}
      label={<div className="text-xs">{t('loading.process')}</div>}
    >
      <FrankieIcon name={iconName} className={iconClassName} />
      <span>{message}</span>
      {children}
    </FrankieLoader>
  )
}

type NewItem = BiometryItem & {
  level?: number
  className?: string
  style?: React.CSSProperties
} & { items?: NewItem[] }

type TickTreeProps = NewItem

export function TickTree({
  title,
  level = 0,
  value = null,
  items,
  className = '',
  style,
}: TickTreeProps) {
  const { colorClass, icon } = useMemo(() => {
    const isFirstTwoLevels = level < 2
    const result: {
      colorClass: string
      icon: FrankieIconName | false
    } = {
      colorClass: 'transparent',
      icon: false,
    }

    if (value === null) return result

    if (value) {
      result.colorClass = isFirstTwoLevels
        ? 'text-tertiary-green-400'
        : 'bg-tertiary-green-400'
      if (isFirstTwoLevels) result.icon = 'mdiCheckCircle'
    } else {
      result.colorClass = isFirstTwoLevels
        ? 'text-tertiary-red-500'
        : 'bg-tertiary-red-500'
      if (isFirstTwoLevels) result.icon = 'mdiCloseCircle'
    }

    return result
  }, [level, value])

  const size = {
    height: `${14 - level * 2}px`,
    width: `${14 - level * 2}px`,
  }

  return (
    <div className={className} style={style}>
      <div className="flex items-center gap-2 max-h-[24px] min-h-[24px]">
        <div
          className={`${colorClass} rounded-full`}
          style={size}
          data-qa={applicantBiometricOcrHelperQa.icon}
        >
          {icon && (
            <FrankieIcon
              name={icon}
              size={level === 0 ? 'sm' : 'xs'}
              className={`${colorClass} h-inherit w-inherit`}
            />
          )}
        </div>

        <span
          className={`whitespace-nowrap ${
            level === 0
              ? 'text-secondary-900 font-semibold'
              : 'text-tertiary-grey-400'
          }`}
          style={level > 1 ? { fontSize: `${16 - level * 2}px` } : {}}
        >
          {title}
        </span>
      </div>

      {items?.map(item => (
        <TickTree
          {...item}
          level={level + 1}
          style={{ marginLeft: `${24 - level * 0.5}px` }}
        />
      ))}
    </div>
  )
}

type ConfidenceScoreProps = {
  confidenceLevel?: string
  className?: string
}

export function ConfidenceScore({
  confidenceLevel,
  className = '',
}: ConfidenceScoreProps) {
  const t = useI18n([APPLICANT_BIOMETRICS_OCR_KEY], {
    keys: applicantBiometricsOcrEn,
  })

  if (!confidenceLevel) {
    return null
  }

  return (
    <div
      className={className}
      data-qa={applicantBiometricOcrHelperQa.confidenceScoreWrapper}
    >
      <div className="text-tertiary-grey-400 font-medium">
        {t('confidenceScore')}
      </div>
      <div className="ml-2 text-secondary-900 font-bold">{`${(
        +confidenceLevel * 100
      ).toFixed(0)}%`}</div>
    </div>
  )
}

type BiometricImageViewProps = {
  imageScan?: Scan
  videoScan?: Scan
  fileName: string
  loading?: boolean
  className?: string
}

export function BiometricDocumentView({
  imageScan,
  videoScan,
  fileName,
  loading = false,
  className = '',
}: BiometricImageViewProps) {
  const t = useI18n([APPLICANT_BIOMETRICS_OCR_KEY], {
    keys: applicantBiometricsOcrEn,
  })

  const [createOverlay] = useOverlay()

  const imageUrl = useMemo(() => {
    if (imageScan) {
      return getDataFileUrl(imageScan.mimeType, imageScan.file)
    }
    return false
  }, [imageScan])

  const handleView = () => {
    const scan = videoScan ?? imageScan
    if (scan) {
      createOverlay(
        <DocumentView
          fileName={fileName}
          fileUrl={scan.file}
          mimeType={scan.mimeType}
          uploadedOn={scan.scanCreated}
        />,
      )
    }
  }

  if (!imageScan && !loading) return null

  return (
    <FrankieLoader
      testId={{ loader: applicantBiometricOcrHelperQa.loader }}
      className={`${className} !h-auto text-xs`}
      loading={loading}
      size="xs"
      label={t('loading.scan')}
    >
      {imageUrl ? (
        <img
          role="presentation"
          onClick={handleView}
          src={imageUrl}
          alt="biometry"
          className="w-full cursor-pointer"
        />
      ) : (
        <div className="w-full min-h-[110px]  bg-neutral-80" />
      )}
    </FrankieLoader>
  )
}

type BiometricEntityViewSliderProps = {
  applicantData?: ApplicantDetails
  applicantImage?: string
  className?: string
  variant?: 'score-card' | 'detail-card'
  score?: Nullable<number>
  viewProfile?: boolean
}

export function BiometricEntityView({
  applicantData,
  applicantImage,
  className = '',
  variant = 'detail-card',
  score,
  viewProfile = false,
}: BiometricEntityViewSliderProps) {
  const t = useI18n([APPLICANT_BIOMETRICS_OCR_KEY], {
    keys: applicantBiometricsOcrEn,
  })

  const { getApplicantPath } = useApplicantPaths()

  const fieldDataMap = useMemo(() => {
    const name = applicantData?.name
    const fieldData: { label: string; value: string }[] = [
      {
        label: t('applicantField.fullName'),
        value: assertContent([
          name?.givenName,
          name?.middleName,
          name?.familyName,
        ]),
      },
      {
        label: t('applicantField.entityId'),
        value: assertContent(applicantData?.entityId),
      },
      {
        label: t('applicantField.dateOfBirth'),
        value: formatOcrDate(applicantData?.dateOfBirth),
      },
      {
        label: t('applicantField.address'),
        value: assertContent(applicantData?.addresses?.[0]?.longForm),
      },
      {
        label: t('applicantField.createdOn'),
        value: formatOcrDate(
          applicantData?.extraData?.biometric_check_last_initiated || '',
        ),
      },
    ]
    return fieldData
  }, [applicantData, t])

  if (variant === 'score-card') {
    return (
      <div
        className={`${className} p-3 max-w-[180px] h-[300px] rounded-md border-2 border-tertiary-grey-300`}
      >
        <div className="h-[200px] flex bg-tertiary-grey-300">
          <img className="m-auto" src={applicantImage} alt="applicant" />
        </div>
        <div className="capitalize whitespace-nowrap py-2 font-medium text-xs overflow-hidden text-ellipsis border-b border-tertiary-grey-300 text-tertiary-grey-700">
          {fieldDataMap[0].value}
        </div>
        <div className="flex items-center justify-between gap-2 py-2 px-1">
          <div className="border-tertiary-grey-300 font-medium text-xs text-tertiary-grey-400 ">
            {t('matchingScore')}
          </div>
          <ApplicantIssueActionBadge
            type={scoreStatus(score)}
            masterLabel={score ? `${score}%` : '0%'}
            className="!py-1"
          />
        </div>
      </div>
    )
  }

  return (
    <div
      className={`${className} min-h-[325px] flex gap-2`}
      data-qa={applicantBiometricOcrHelperQa.entityViewWrapper}
    >
      <div className="flex flex-col gap-2 basis-1/2 py-2 px-3 bg-mono-white rounded-md">
        {fieldDataMap.map(({ label, value }) => (
          <div className="font-semibold" key={value}>
            <div className="capitalize text-tertiary-grey-400">{label}</div>
            <div className="text-tertiary-grey-900">{value}</div>
          </div>
        ))}
        {viewProfile && applicantData?.entityId && (
          <Link
            target="_blank"
            className="flex items-center gap-2 text-primary-700 font-bold"
            to={getApplicantPath('generalInformation', applicantData.entityId)}
          >
            <span>{t('action.viewProfile')}</span>
            <FrankieIcon size="xs" name="mdiOpenInNew" />
          </Link>
        )}
      </div>
      <div className="flex items-center basis-1/2 bg-tertiary-grey-300 border border-dashed border-tertiary-grey-900">
        <img src={applicantImage} alt="applicant" />
      </div>
    </div>
  )
}
