import React, { useEffect, useState } from 'react'

import { useLocation } from 'react-router-dom'

import { FrankieButton } from 'frankify/src'

import { useI18n } from 'shared/i18n'
import { useOverlay } from 'shared/overlay'
import { TrackingEventsTypes, trackingManager } from 'shared/tracking'

import { CUSTOM_VIEWS_KEYS } from '../../custom-view.key'
import { customViewEn } from '../../locale/custom-view.en'
import { customViewCreateQa } from '../../qa/custom-view.qa'
import {
  useCustomView,
  useCustomViewData,
} from '../../states/custom-view-state/custom-view.state'
import {
  CustomViewSaveModal,
  useNavigateToCustomView,
} from '../custom-view-save-modal/custom-view-save-modal'

type Props = {
  noRows: boolean
}

export function CustomViewCreate({ noRows }: Props) {
  const t = useI18n(CUSTOM_VIEWS_KEYS, { keys: customViewEn })
  const { customViewLimitReached, hasCustomViews } = useCustomViewData()
  const { data } = useCustomView()
  const query = window.location.search.slice(1)

  const [createOverlay, closeOverlay] = useOverlay()
  const [customViewCreate, setCustomViewCreate] = useState(false)
  const location = useLocation()
  const hideBtn = location.state?.hideBtn

  useEffect(() => {
    if (hideBtn) {
      setCustomViewCreate(false)
    }
  }, [hideBtn])

  useEffect(() => {
    if (
      location.pathname.includes('entities') &&
      query &&
      !customViewLimitReached
    ) {
      setCustomViewCreate(true)
    }
  }, [query, location.pathname, customViewLimitReached])

  useEffect(() => {
    setCustomViewCreate(customViewCreate)
  }, [customViewCreate])

  const { handleNavigate } = useNavigateToCustomView()

  const handleClick = () => {
    trackingManager.track(
      TrackingEventsTypes.CustomViewCreateFromSaveButtonFromCustomSection,
    )
    createOverlay(
      <CustomViewSaveModal
        onCloseOverlay={closeOverlay}
        customViewStateChange={() => setCustomViewCreate(false)}
        handleNavigate={handleNavigate}
      />,
      {
        closeButtonClassName: '!top-[27px] !right-[24px]',
        className: '!p-6',
      },
    )
  }

  if (
    !customViewCreate ||
    !data?.customViews ||
    customViewLimitReached ||
    !hasCustomViews
  )
    return null

  return (
    <FrankieButton
      noStyles
      className={`rounded-sm min-w-[103px] max-w-[103px] py-2 px-3 text-tertiary-grey-700 font-semibold text-xs border border-solid border-tertiary-grey-200 ${
        !noRows ? 'mr-[14px]' : ''
      }`}
      onClick={handleClick}
      testId={{ button: customViewCreateQa.btn }}
    >
      {t('saveView')}
    </FrankieButton>
  )
}
