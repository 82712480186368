import { useCallback, useEffect, useMemo, useState } from 'react'

import {
  FrankieRawApplicant,
  SearchApplicantParams,
  useSearchApplicantsQuery,
} from 'entities/applicant'

import { SearchEntityRecord } from '../../model/associate-party.model'

export type SearchArgs = Pick<
  SearchApplicantParams,
  'entityIdFilter' | 'nameFilter'
>
export type FilterArgs = Pick<
  SearchApplicantParams,
  'matchStatusFilter' | 'entityTypeFilter'
>

const LIMIT = 5

export const useExistingApplicantSearchState = () => {
  const [filter, setFilter] = useState<FilterArgs & SearchArgs>({})

  // Fetching onboarding applicant data
  const {
    data: onboardingData,
    updateFilter: updateOnboardingFilter,
    isFetched: isOnboardingFetched,
    hasNextPage: hasNextOnboardingPage,
    fetchNextPage: fetchNextOnboardingPage,
    isFetching: fetchingOnboardingData,
  } = useSearchApplicantsQuery({
    limit: LIMIT,
    stage: 'onboarding',
  })

  // Fetching monitoring applicant data
  const {
    data: monitoringData,
    updateFilter: updateMonitoringFilter,
    isFetched: isMonitoringFetched,
    hasNextPage: hasNextMonitoringPage,
    fetchNextPage: fetchNextMonitoringPage,
    isFetching: fetchingMonitoringData,
  } = useSearchApplicantsQuery({
    limit: LIMIT,
    stage: 'monitoring',
  })

  /**
   * Filter handler
   */
  const handleFilter = useCallback(
    <TParams extends FilterArgs | SearchArgs>(params: TParams) => {
      setFilter(prev => ({ ...prev, ...params }))
    },
    [],
  )

  /**
   * Fetching next onboarding or monitoring data depending on the state
   */
  const fetchNextData = useCallback(() => {
    if (hasNextOnboardingPage) {
      void fetchNextOnboardingPage()
    } else if (isMonitoringFetched) {
      if (hasNextMonitoringPage) {
        void fetchNextMonitoringPage()
      }
    } else {
      updateMonitoringFilter(filter)
    }
  }, [
    fetchNextMonitoringPage,
    fetchNextOnboardingPage,
    filter,
    hasNextMonitoringPage,
    hasNextOnboardingPage,
    isMonitoringFetched,
    updateMonitoringFilter,
  ])

  /**
   * Getting initial monitoring data when onboarding data is fetched and the length is less than the limit
   */
  useEffect(() => {
    const onboardingDataLength = onboardingData?.pages[0].applicants.length ?? 0
    if (isOnboardingFetched && onboardingDataLength < LIMIT) {
      updateMonitoringFilter(filter)
    }
  }, [
    filter,
    isOnboardingFetched,
    onboardingData?.pages,
    updateMonitoringFilter,
  ])

  /**
   * When filter is changes get new onboarding data and reset monitoring data
   */
  useEffect(() => {
    updateOnboardingFilter(filter)
    updateMonitoringFilter({ entityIdFilter: '', nameFilter: '' })
  }, [filter, updateMonitoringFilter, updateOnboardingFilter])

  const getSearchedData = useCallback(
    (rawData: FrankieRawApplicant[]) =>
      rawData.map(
        data =>
          ({
            entityId: data.entityId,
            entityName: data.entityName,
            profileStatus: data.applicantStatus.type,
            type: data.entityType,
            customerId: data.customerReference,
          } as SearchEntityRecord),
      ),
    [],
  )

  /**
   * Combining onboarding and monitoring data
   */
  const searchDataList = useMemo(() => {
    const onboardingDataSet = (onboardingData?.pages ?? []).reduce(
      (acc, page) => acc.concat(getSearchedData(page.applicants)),
      [] as SearchEntityRecord[],
    )

    const monitoringDataSet = (monitoringData?.pages ?? []).reduce(
      (acc, page) => acc.concat(getSearchedData(page.applicants)),
      [] as SearchEntityRecord[],
    )

    return [...onboardingDataSet, ...monitoringDataSet]
  }, [getSearchedData, monitoringData?.pages, onboardingData?.pages])

  return {
    searchDataList,
    filter,
    fetchNextData,
    /**
     * Handle filter - MatchStatus and EntityType
     */
    applyFilter: handleFilter<FilterArgs>,
    /**
     * Handle search Field - Name and EntityId
     */
    applySearch: handleFilter<SearchArgs>,
    loading: fetchingMonitoringData || fetchingOnboardingData,
  }
}
