import React from 'react'

import { CountryAlpha3CodeTypes } from 'entities/country'

import { TextFormField } from 'shared/form'
import { useI18n } from 'shared/i18n'
import { useValidationRules } from 'shared/validation'

import { INDIVIDUAL_PROFILE_F2_KEY } from '../../../../individual-profile-f2.key'
import { individualProfileF2En } from '../../../../locale/individual-profile-f2.en'
import { getSubFormQa } from '../../../../qa/individual-profile-f2.qa'
import { DocumentsSubFormProps } from '../document-sub-forms.types'

export function PassportForm({
  form,
  idx,
  wrapperClasses,
}: DocumentsSubFormProps) {
  const t = useI18n([INDIVIDUAL_PROFILE_F2_KEY], {
    keys: individualProfileF2En,
  })

  const { control } = form

  const { alphaNumericRule } = useValidationRules()
  const getRequiredRule = (label: Parameters<typeof t>['0']) => ({
    required: t('errors.requiredEntity', {
      label: t(label).toLowerCase(),
    }),
  })
  const subFormQa = getSubFormQa(idx)
  const country = form.watch(`documents.${idx}.country`)

  switch (country) {
    case CountryAlpha3CodeTypes.NEW_ZEALAND:
      return (
        <div className={wrapperClasses} data-qa={subFormQa.wrapper}>
          <TextFormField
            control={control}
            trim
            name={`documents.${idx}.primaryIdentifier`}
            className="flex-1"
            label={t('documentForm.passportNumber')}
            rules={{
              ...getRequiredRule('documentForm.passportNumber'),
              ...alphaNumericRule,
              maxLength: {
                value: 8,
                message: t('errors.maxLengthMust', {
                  label: t('documentForm.passportNumber'),
                  maximum: 8,
                }),
              },
            }}
            showErrorText
            shouldUnregister
            testId={{ input: subFormQa.passportNumber }}
          />
          <TextFormField
            control={control}
            trim
            name={`documents.${idx}.expiryDate.normalized`}
            className="flex-1"
            label={t('documentForm.passportExpiry')}
            type="date"
            rules={{
              ...getRequiredRule('documentForm.passportExpiryPlaceholder'),
              validate: {
                futureDate: value =>
                  new Date(value as string) > new Date()
                    ? true
                    : t('errors.futureDate'),
              },
            }}
            showErrorText
            shouldUnregister
            testId={{ input: subFormQa.passportExpiry }}
          />
        </div>
      )
    default:
      return (
        <div className={wrapperClasses} data-qa={subFormQa.wrapper}>
          <TextFormField
            control={control}
            trim
            name={`documents.${idx}.primaryIdentifier`}
            className="basis-[47.5%]"
            label={t('documentForm.passportNumber')}
            placeholder={t('documentForm.passportNumberPlaceholder')}
            rules={{
              ...getRequiredRule('documentForm.passportNumber'),
              ...alphaNumericRule,
            }}
            showErrorText
            shouldUnregister
            testId={{ input: subFormQa.passportNumber }}
          />
        </div>
      )
  }
}
