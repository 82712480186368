import React from 'react'

import { FrankieButton, FrankieTooltip } from 'frankify/src'

import { ApplicantId } from 'entities/applicant'

import { useI18n } from 'shared/i18n'
import { useOverlay } from 'shared/overlay'
import { isNonEmptyObject } from 'shared/typescript'

import { APPLICANT_BUSINESS_OWNERSHIP_INTERNATIONAL_KEY } from '../../applicant-business-ownership-international.key'
import { applicantBusinessOwnershipInternationalEn } from '../../locale/applicant-business-ownership-international.en'
import { applicantAddBusinessEntityQa } from '../../qa/applicant-business-ownership-international.qa'
import { useApplicantAssociateInternationalEntityQuery } from '../../state/applicant-associate-international-entity-query/applicant-associate-international-entity.query'
import { ApplicantAssociateInternationalParties } from '../applicant-associate-international-parties/applicant-associate-international-parties'

type Props = {
  applicantId: ApplicantId
  className?: string
}

export function ApplicantAddAssociateInternationalEntity({
  applicantId,
  className = '',
}: Props) {
  const t = useI18n([APPLICANT_BUSINESS_OWNERSHIP_INTERNATIONAL_KEY], {
    keys: applicantBusinessOwnershipInternationalEn,
  })

  const { data: ownershipData, isLoading } =
    useApplicantAssociateInternationalEntityQuery({
      applicantId,
    })

  const [createOverlay, closeOverlay] = useOverlay()

  const associateParties = () => {
    createOverlay(
      <ApplicantAssociateInternationalParties
        applicantId={applicantId}
        associationType="add"
        onClose={closeOverlay}
      />,
      {
        className: 'p-0',
        closeButtonClassName: '!top-6 !right-5',
      },
    )
  }

  return (
    <div className={className} data-qa={applicantAddBusinessEntityQa.wrapper}>
      <FrankieTooltip
        className="min-w-[269px]"
        body={t('addAssociateDisabledTooltip')}
        position="top"
        hidden={isNonEmptyObject(ownershipData) || isLoading}
      >
        <FrankieButton
          size="sm"
          intent="secondary"
          disabled={!isNonEmptyObject(ownershipData)}
          onClick={associateParties}
          testId={{ button: applicantAddBusinessEntityQa.btn }}
        >
          {t('action.addAssociateParty')}
        </FrankieButton>
      </FrankieTooltip>
    </div>
  )
}
