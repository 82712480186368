import {
  IProfileFilter,
  ProfileIssueCategoryTypes,
  ProfileRiskLevelTypes,
  ProfileStateTypes,
} from 'entities/entity'
import { IUser } from 'entities/user'

export enum ProfileSearchParamsTypes {
  EntityName = 'entityName',
  EntityId = 'entityId',
  CustomerId = 'customerId',
  States = 'states',
  IssueCategories = 'issueCategories',
  RiskLevels = 'riskLevels',
  CreatedDate = 'createdDate',
  UpdatedDate = 'updatedDate',
  WorkflowNames = 'workflowNames',
  WorkflowStatuses = 'workflowStatuses',
  Assignees = 'assignees',
}

export const profileGlobalFilterDefault: IProfileFilter = {
  entityName: '',
  entityId: '',
  customerId: '',
  states: [],
  issueCategories: [],
  riskLevels: [],
  createdDate: {
    startDate: null,
    endDate: null,
  },
  updatedDate: {
    startDate: null,
    endDate: null,
  },
  workflowNames: [],
  workflowStatuses: [],
  assignees: [],
}

function splitToArr(str: string | null): string[] {
  return str ? str.split(',') : []
}

function splitToArrValue(
  str: string | null,
  userList: IUser[] | undefined,
): { value: string; label: string }[] {
  const users = userList ?? ([] as IUser[])

  return str
    ? str.split(',').map(value => {
        const label =
          users.find(user => user.email === value)?.realname ?? value
        return {
          value,
          label,
        }
      })
    : []
}

export function getProfileFilterFromSearchParams(
  searchParams: URLSearchParams,
  userList: IUser[] | undefined,
): IProfileFilter {
  const [createStartDateStr, createEndDateStr] = splitToArr(
    searchParams.get(ProfileSearchParamsTypes.CreatedDate),
  )
  const [updateStartDateStr, updateEndDateStr] = splitToArr(
    searchParams.get(ProfileSearchParamsTypes.UpdatedDate),
  )
  return {
    entityName: searchParams.get(ProfileSearchParamsTypes.EntityName) ?? '',
    entityId: searchParams.get(ProfileSearchParamsTypes.EntityId) ?? '',
    customerId: searchParams.get(ProfileSearchParamsTypes.CustomerId) ?? '',
    states: splitToArr(
      searchParams.get(ProfileSearchParamsTypes.States),
    ) as ProfileStateTypes[],
    issueCategories: splitToArr(
      searchParams.get(ProfileSearchParamsTypes.IssueCategories),
    ) as ProfileIssueCategoryTypes[],
    riskLevels: splitToArr(
      searchParams.get(ProfileSearchParamsTypes.RiskLevels),
    ) as ProfileRiskLevelTypes[],
    createdDate: {
      startDate: createStartDateStr ? new Date(createStartDateStr) : null,
      endDate: createEndDateStr ? new Date(createEndDateStr) : null,
    },
    updatedDate: {
      startDate: updateStartDateStr ? new Date(updateStartDateStr) : null,
      endDate: updateEndDateStr ? new Date(updateEndDateStr) : null,
    },
    workflowNames: splitToArr(
      searchParams.get(ProfileSearchParamsTypes.WorkflowNames),
    ),
    workflowStatuses: splitToArr(
      searchParams.get(ProfileSearchParamsTypes.WorkflowStatuses),
    ),
    assignees: splitToArrValue(
      searchParams.get(ProfileSearchParamsTypes.Assignees),
      userList,
    ),
  }
}

/**
 * Updates search params in place with the given filter
 */
// eslint-disable-next-line complexity
export function updateSearchParamsWithProfileFilter(
  searchParams: URLSearchParams,
  filter: IProfileFilter,
) {
  if (filter.entityId)
    searchParams.set(ProfileSearchParamsTypes.EntityId, filter.entityId)
  else searchParams.delete(ProfileSearchParamsTypes.EntityId)

  if (filter.entityName)
    searchParams.set(ProfileSearchParamsTypes.EntityName, filter.entityName)
  else searchParams.delete(ProfileSearchParamsTypes.EntityName)

  if (filter.customerId)
    searchParams.set(ProfileSearchParamsTypes.CustomerId, filter.customerId)
  else searchParams.delete(ProfileSearchParamsTypes.CustomerId)

  if (filter.states.length > 0)
    searchParams.set(ProfileSearchParamsTypes.States, filter.states.join(','))
  else searchParams.delete(ProfileSearchParamsTypes.States)

  if (filter.issueCategories.length > 0)
    searchParams.set(
      ProfileSearchParamsTypes.IssueCategories,
      filter.issueCategories.join(','),
    )
  else searchParams.delete(ProfileSearchParamsTypes.IssueCategories)

  if (filter.riskLevels.length > 0)
    searchParams.set(
      ProfileSearchParamsTypes.RiskLevels,
      filter.riskLevels.join(','),
    )
  else searchParams.delete(ProfileSearchParamsTypes.RiskLevels)

  if (filter.workflowNames.length > 0) {
    searchParams.set(
      ProfileSearchParamsTypes.WorkflowNames,
      filter.workflowNames.join(','),
    )
  } else searchParams.delete(ProfileSearchParamsTypes.WorkflowNames)

  if (filter.workflowStatuses.length > 0) {
    searchParams.set(
      ProfileSearchParamsTypes.WorkflowStatuses,
      filter.workflowStatuses.join(','),
    )
  } else searchParams.delete(ProfileSearchParamsTypes.WorkflowStatuses)

  if (filter.assignees.length > 0)
    searchParams.set(
      ProfileSearchParamsTypes.Assignees,
      filter.assignees.map(value => value.value).join(','),
    )
  else searchParams.delete(ProfileSearchParamsTypes.Assignees)

  if (filter.createdDate.startDate && filter.createdDate.endDate) {
    searchParams.set(
      ProfileSearchParamsTypes.CreatedDate,
      `${filter.createdDate.startDate.toISOString()},${filter.createdDate.endDate.toISOString()}`,
    )
  } else {
    searchParams.delete(ProfileSearchParamsTypes.CreatedDate)
  }

  if (filter.updatedDate.startDate && filter.updatedDate.endDate) {
    searchParams.set(
      ProfileSearchParamsTypes.UpdatedDate,
      `${filter.updatedDate.startDate.toISOString()},${filter.updatedDate.endDate.toISOString()}`,
    )
  } else {
    searchParams.delete(ProfileSearchParamsTypes.UpdatedDate)
  }
}

export function countEnabledFilterCategories(searchParams: URLSearchParams) {
  return Object.values(ProfileSearchParamsTypes)
    .filter(
      value =>
        ![
          ProfileSearchParamsTypes.EntityId,
          ProfileSearchParamsTypes.CustomerId,
          ProfileSearchParamsTypes.EntityName,
        ].includes(value),
    )
    .reduce((acc, curr) => acc + (searchParams.has(curr) ? 1 : 0), 0)
}
