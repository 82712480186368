import { useMutation } from '@tanstack/react-query'

import { ApplicantId } from 'entities/applicant'

import { ErrorResponse } from 'shared/http'
import { notification } from 'shared/notification'

import { blacklistAPI } from '../../apis/blacklist.api'
import { CheckDuplicateType } from '../../models/blacklist.model'

export type Args = {
  ids: { applicantId: ApplicantId; matchedApplicantId: ApplicantId }
  data: CheckDuplicateType
}

export const useCheckDuplicate = () =>
  useMutation({
    mutationFn: async ({ ids, data }: Args) =>
      blacklistAPI.checkDuplicate(ids, data),
    onError: (err: ErrorResponse) => {
      notification.error(err.response?.data.message as string)
    },
  })
