import { useMutation } from '@tanstack/react-query'

import { organisationApi } from '../../api/organisation.api'
import {
  ApplicantBusinessBody,
  TemplateType,
} from '../../model/applicant.model'

type Args = {
  body: ApplicantBusinessBody
  reportType: TemplateType
}

export const useGenerateReportMutation = () =>
  useMutation({
    mutationFn: ({ body, reportType }: Args) =>
      organisationApi.generateReport({ business: body }, { level: reportType }),
    onSuccess: response => response.data,
  })
