import { useQuery } from '@tanstack/react-query'

import { IRole, RoleId } from 'entities/role'

import { roleManagementApi } from '../../api/role-management.api'
import { ROLE_MANAGEMENT_KEY } from '../../role-management.key'

export const getSingleRoleQueryKey = (id: RoleId) => [
  `${ROLE_MANAGEMENT_KEY}_single-role`,
  id,
]

export const useSingleRoleQuery = (
  id: RoleId,
  { canFetchRole }: { canFetchRole: boolean },
) =>
  useQuery<IRole>({
    queryKey: getSingleRoleQueryKey(id),
    queryFn: async () => {
      if (!canFetchRole) {
        throw new Error('No access')
      }
      const { data } = await roleManagementApi.getRole(id)
      return data
    },
  })
