import React, { useEffect, useState } from 'react'

import {
  Controller,
  ControllerRenderProps,
  FormProvider,
  useForm,
} from 'react-hook-form'

import {
  FrankieButton,
  FrankieCheckbox,
  FrankieLoader,
  FrankieTextField,
} from 'frankify/src'

import { ApplicantId, applicantDetailConfig } from 'entities/applicant'
import {
  OTHER_ROLE_KEY,
  useApplicantBusinessAssociationRolesQuery,
} from 'entities/organisation'
import { useSessionQuery } from 'entities/session'

import { ConfirmationFormField, getError } from 'shared/form'
import { useI18n } from 'shared/i18n'
import { notification } from 'shared/notification'
import { Noop } from 'shared/typescript'
import { DECIMAL_2_PLACES_PATTERN } from 'shared/validation'

import { AssociateNewParties } from './associate-new-parties/associate-new-parties'
import { APPLICANT_BUSINESS_OWNERSHIP_INTERNATIONAL_KEY } from '../../applicant-business-ownership-international.key'
import { applicantBusinessOwnershipInternationalEn } from '../../locale/applicant-business-ownership-international.en'
import { AssociateInternationalPartyFormData } from '../../model/applicant-business-ownership-international.model'
import { applicantAssociatePartiesQa } from '../../qa/applicant-business-ownership-international.qa'
import {
  AssociationType,
  useApplicantAssociateInternationalEntityMutation,
} from '../../state/applicant-associate-international-entity/applicant-associate-international-entity.mutation'

type RoleCheckboxProps = {
  field: ControllerRenderProps<AssociateInternationalPartyFormData, 'roles'>
  typeDescription: string
  type: string
  className?: string
  testId?: {
    input?: string
  }
}

function RoleCheckbox({
  field,
  typeDescription,
  type,
  className = '',
  testId,
}: RoleCheckboxProps) {
  const isChecked = field.value.includes(type)

  return (
    <label
      className={`basis-[32%] flex-grow-0 flex gap-2 items-center text-tertiary-grey-700 font-normal cursor-pointer ${className}`}
    >
      <FrankieCheckbox
        value={field.value}
        testId={{ input: testId?.input }}
        checked={isChecked}
        size="sm"
        onChange={() =>
          isChecked
            ? field.onChange(field.value.filter(item => item !== type))
            : field.onChange([...field.value, type])
        }
      />
      {typeDescription}
    </label>
  )
}

type Props = {
  applicantId: ApplicantId
  entityId?: ApplicantId
  associationType: AssociationType
  defaultValues?: Partial<AssociateInternationalPartyFormData>
  onClose: Noop
  testId?: {
    container?: string
    buttonWrapper?: string
    loader?: string
    submitButton?: string
    otherCheckBox?: string
    otherInput?: string
    cancelButton?: string
  }
}

// eslint-disable-next-line complexity
export function ApplicantAssociateInternationalParties({
  applicantId,
  associationType,
  entityId,
  defaultValues = {},
  onClose,
  testId,
}: Props) {
  const t = useI18n([APPLICANT_BUSINESS_OWNERSHIP_INTERNATIONAL_KEY], {
    keys: applicantBusinessOwnershipInternationalEn,
  })

  const isAssociationTypeAdd = associationType === 'add'

  const form = useForm<AssociateInternationalPartyFormData>({
    defaultValues: {
      entityId,
      roles: [],
      partyType: 'INDIVIDUAL',
      individualData: {
        givenName: '',
        middleName: '',
        familyName: '',
      },
      hidePartyFromSearch: false,
      ...defaultValues,
    },
    mode: 'onChange',
  })

  const {
    handleSubmit,
    control,
    register,
    watch,
    setValue,
    formState: { errors, isValid },
    setError,
    setFocus,
  } = form

  const {
    mutate,
    isSuccess,
    isLoading: isAssociatePartyLoading,
  } = useApplicantAssociateInternationalEntityMutation({
    applicantId,
    associationType,
  })

  const [askConfirmation, setAskConfirmation] = useState(false)

  const { data: pageData } = useSessionQuery()

  const { data: roleTypes, isLoading } =
    useApplicantBusinessAssociationRolesQuery()

  useEffect(() => {
    if (isSuccess) {
      onClose()
    }
  }, [isSuccess, onClose])

  /**
   * Final submission of the form
   */
  const handleMutation = (data: AssociateInternationalPartyFormData) => {
    setAskConfirmation(false)

    const isIndividual = data.partyType === 'INDIVIDUAL'

    const roles =
      roleTypes?.filter(role => data.roles.includes(role.type)) ?? []

    if (data.roles.includes(OTHER_ROLE_KEY)) {
      roles.push({
        type: OTHER_ROLE_KEY,
        typeDescription: data.otherRoleTypeDescription,
      })
    }

    mutate({
      entityId: data.entityId,
      mainData: {
        addedBy: pageData?.user.username ?? ' ',
        percentage: {
          beneficially: +data.beneficiallyOwnership,
          nonBeneficially: +data.nonBeneficiallyOwnership,
          jointly: null,
        },
        roles,
      },
      partyData: isIndividual
        ? {
            partyType: 'INDIVIDUAL',
            party: {
              ...applicantDetailConfig,
              name: {
                givenName: data.individualData.givenName,
                middleName: data.individualData.middleName,
                familyName: data.individualData.familyName,
                displayName: null,
              },
            },
          }
        : {
            partyType: 'BUSINESS',
            party: data.organisationData,
          },
      hidePartyFromSearch: data.hidePartyFromSearch,
    })
  }

  const triggerOwnershipValidateError = (
    key: Extract<
      keyof AssociateInternationalPartyFormData,
      'beneficiallyOwnership' | 'nonBeneficiallyOwnership'
    >,
  ) => {
    notification.error(t(`error.${key}`))
    setError(key, { type: 'maxValue' })
    setFocus(key)
  }

  const onSubmit = (data: AssociateInternationalPartyFormData) => {
    const beneficiallyHeld = +data.beneficiallyOwnership
    const nonBeneficiallyHeld = +data.nonBeneficiallyOwnership

    if (beneficiallyHeld > 100) {
      triggerOwnershipValidateError('beneficiallyOwnership')
      return
    }

    if (nonBeneficiallyHeld > 100) {
      triggerOwnershipValidateError('nonBeneficiallyOwnership')
      return
    }

    if (associationType === 'updating' || data.partyType === 'BUSINESS') {
      handleMutation(data)
      return
    }

    setAskConfirmation(true)
  }

  const isIndividual = form.watch('partyType') === 'INDIVIDUAL'

  const watchBeneficiallyOwnershipValue = watch('beneficiallyOwnership')
  const watchNonBeneficiallyOwnershipValue = watch('nonBeneficiallyOwnership')

  useEffect(() => {
    const value1 = +watchBeneficiallyOwnershipValue
    const value2 = +watchNonBeneficiallyOwnershipValue
    setValue('total', (value1 + value2).toFixed(2))
  }, [
    setValue,
    watchBeneficiallyOwnershipValue,
    watchNonBeneficiallyOwnershipValue,
  ])

  return (
    <FormProvider {...form}>
      {askConfirmation ? (
        <div data-qa={testId?.container} className="w-[448px] p-6">
          <div className="text-xl font-bold text-tertiary-grey-900 pb-3">
            {isIndividual
              ? t('associateIndividual')
              : t('associateOrganisation')}
          </div>
          <form
            className="flex flex-col gap-3 items-start"
            onSubmit={handleSubmit(handleMutation)}
          >
            <div className="text-tertiary-grey-700">
              {t('wishToAddIndividualAsAssociatedParty')}
            </div>
            <ConfirmationFormField
              label={isIndividual ? t('hideIndividual') : t('hideOrganisation')}
              control={control}
              name="hidePartyFromSearch"
              className="!font-normal !text-tertiary-grey-700"
              nonMandatory
              testId={{
                input: applicantAssociatePartiesQa.confirmationCheckbox,
              }}
            />
            <div
              className="flex justify-end w-full mt-4 gap-1"
              data-qa={testId?.buttonWrapper}
            >
              <FrankieButton
                intent="subtle"
                testId={{ button: testId?.cancelButton }}
                onClick={() => setAskConfirmation(false)}
              >
                {t('cancel')}
              </FrankieButton>
              <FrankieButton
                type="submit"
                testId={{ button: applicantAssociatePartiesQa.addCTA }}
              >
                {t('action.addAssociateParty')}
              </FrankieButton>
            </div>
          </form>
        </div>
      ) : (
        <div data-qa={testId?.container} className="max-w-[910px] w-[95vw]">
          <div
            className={`pt-6 px-6 text-lg font-bold text-tertiary-grey-800 ${
              isAssociationTypeAdd ? 'pb-4' : 'pb-2'
            }`}
          >
            {isAssociationTypeAdd
              ? t('associateNewParties')
              : t('editAssociateParties')}
          </div>

          <FrankieLoader
            loading={isAssociatePartyLoading}
            size="md"
            testId={{ loader: testId?.loader }}
          >
            <form
              className={`relative overflow-y-auto ${
                isAssociationTypeAdd ? 'pt-0 pb-6' : 'py-4'
              } px-6 max-h-[calc(95vh-60px)]`}
              onSubmit={handleSubmit(onSubmit)}
              noValidate
            >
              {isAssociationTypeAdd && <AssociateNewParties />}

              <div className="flex flex-wrap items-end gap-x-[2%] gap-y-6">
                <Controller
                  render={({ field }) => {
                    const isOther = field.value.includes(OTHER_ROLE_KEY)
                    return (
                      <div className={`basis-full ${isOther ? 'mb-10' : ''}`}>
                        <div className="text-md font-semibold mb-4">
                          {t('roleType')}
                        </div>
                        <FrankieLoader
                          loading={isLoading}
                          className={`flex flex-wrap gap-x-[2%] gap-y-3 text-tertiary-grey-800 font-semibold ${
                            isLoading ? 'min-h-[130px]' : ''
                          }`}
                          label={t('loading.roles')}
                        >
                          {roleTypes?.map(({ type, typeDescription }) => (
                            <RoleCheckbox
                              key={type}
                              field={field}
                              typeDescription={typeDescription}
                              type={type}
                            />
                          ))}

                          <div className="basis-[32%]">
                            <RoleCheckbox
                              className=""
                              testId={{ input: testId?.otherCheckBox }}
                              field={field}
                              typeDescription={t('other')}
                              type={OTHER_ROLE_KEY}
                            />

                            {isOther && (
                              <FrankieTextField
                                testId={{ input: testId?.otherInput }}
                                className="max-w-[320px] !h-0 pt-3"
                                inputClassName="placeholder:font-normal"
                                placeholder={t('pleaseSpecify')}
                                {...register('otherRoleTypeDescription', {
                                  required: true,
                                })}
                              />
                            )}
                          </div>
                        </FrankieLoader>
                      </div>
                    )
                  }}
                  control={control}
                  name="roles"
                  rules={{ required: true }}
                />

                <div className="basis-[32%]">
                  <div className="whitespace-pre-line pb-1.5 text-tertiary-grey-800 font-medium">
                    {`${t('beneficiallyOwnership')} \n ${t('optional')}`}
                  </div>
                  <FrankieTextField
                    testId={{
                      input: applicantAssociatePartiesQa.beneficiaryInput,
                    }}
                    type="number"
                    error={!!getError('beneficiallyOwnership', errors)}
                    {...register('beneficiallyOwnership', {
                      pattern: DECIMAL_2_PLACES_PATTERN,
                    })}
                  />
                  <div className="text-tertiary-grey-500 pt-1.5">
                    {t('upto2Decimal')}
                  </div>
                </div>

                <div className="basis-[32%]">
                  <div className="whitespace-pre-line pb-1.5 text-tertiary-grey-800 font-medium">
                    {`${t('nonBeneficiallyOwnership')} \n ${t('optional')}`}
                  </div>
                  <FrankieTextField
                    type="number"
                    error={!!getError('nonBeneficiallyOwnership', errors)}
                    {...register('nonBeneficiallyOwnership', {
                      pattern: DECIMAL_2_PLACES_PATTERN,
                    })}
                  />
                  <div className="text-tertiary-grey-500 pt-1.5">
                    {t('upto2Decimal')}
                  </div>
                </div>

                <div className="basis-[32%]">
                  <FrankieTextField
                    type="number"
                    disabled
                    label={t('total')}
                    {...register('total')}
                  />
                  <div className="invisible text-tertiary-grey-500 pt-1.5">
                    {t('upto2Decimal')}
                  </div>
                </div>

                <div className="basis-full flex justify-between pt-2">
                  <FrankieButton
                    intent="darkOutline"
                    className="!w-[84px] float-right"
                    testId={{ button: testId?.cancelButton }}
                    onClick={onClose}
                  >
                    {t('cancel')}
                  </FrankieButton>
                  <FrankieButton
                    type="submit"
                    disabled={!!Object.keys(errors).length || !isValid}
                    className="!w-[170px] float-right"
                    testId={{ button: applicantAssociatePartiesQa.addCTA }}
                  >
                    {associationType === 'updating'
                      ? t('done')
                      : t('action.addAssociateParty')}
                  </FrankieButton>
                </div>
              </div>
            </form>
          </FrankieLoader>
        </div>
      )}
    </FormProvider>
  )
}
