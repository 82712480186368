import { ApplicantId } from 'entities/applicant'

import { bffClient, IClient } from 'shared/client'

import {
  AddToBlocklistResponseType,
  AddToBlocklistType,
  CheckBlocklistPayloadTypes,
} from '../model/add-to-blocklist.model'

export class BlocklistAPI {
  client: IClient

  constructor(client: IClient) {
    this.client = client
  }

  async addToBlocklist(data: AddToBlocklistType) {
    return this.client.post<AddToBlocklistResponseType, AddToBlocklistType>(
      'data/v1/blocklists',
      data,
    )
  }

  async checkBlocklist(
    entityId: ApplicantId,
    payload: CheckBlocklistPayloadTypes,
  ) {
    return this.client.put(
      `data/v1/applicants/${entityId}/checks/blacklists`,
      payload,
    )
  }
}

export const blocklistApi = new BlocklistAPI(bffClient)
