import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { FrankieTabs } from 'frankify/src'

import { useI18n } from 'shared/i18n'
import { TrackingEventsTypes, trackingManager } from 'shared/tracking'

import { INDIVIDUAL_AML_RESULT_KEY } from '../../../individual-aml-result.key'
import { individualAmlResultEn } from '../../../locale/individual-aml-result.en'
import { TabItemTypes, tabItems } from '../../../model/individual-aml-result'
import { amlMatchTabsQa } from '../../../qa/individual-aml-result.qa'
import { useAmlData } from '../../../state/individual-aml-result.state'
import {
  IndividualAmlEntityAdverseMediaCard,
  IndividualAmlEntityOtherDataSrcCard,
} from '../../individual-aml-entity-helper/individual-aml-entity-detail-card/individual-aml-entity-detail-card'
import { IndividualAmlPepCard } from '../../individual-aml-entity-helper/individual-aml-pep-card/individual-aml-entity-pep-card'

type Props = {
  entityId: string
  processResultId?: string
  historyPage?: boolean
}

export function IndividualAmlMatchTabs({
  entityId,
  processResultId,
  historyPage,
}: Props) {
  const t = useI18n(INDIVIDUAL_AML_RESULT_KEY, {
    keys: individualAmlResultEn,
  })

  const { amlData } = useAmlData({ entityId, processResultId, historyPage })
  const { supplementaryData } = amlData

  const count = useMemo(
    () => ({
      [TabItemTypes.PEP]: supplementaryData?.pepData?.length || undefined,
      [TabItemTypes.SANCTIONS]:
        supplementaryData?.sanctionData?.length || undefined,
      [TabItemTypes.WATCHLIST]:
        supplementaryData?.watchlistData?.length || undefined,
      [TabItemTypes.OTHER_DATA_SOURCES]:
        supplementaryData?.referenceDocs?.length || undefined,
      [TabItemTypes.ADVERSE_MEDIA]:
        supplementaryData?.mediaData?.length || undefined,
    }),
    [supplementaryData],
  )

  const [currentTab, setCurrentTab] = useState<TabItemTypes>(tabItems[0].value)
  useEffect(() => {
    trackingManager.track(
      TrackingEventsTypes.AmlTabViewIndividual,
      {
        tab: currentTab,
      },
      {
        entityId,
        processResultId,
        count: count[currentTab],
        tab: currentTab,
      },
    )
  }, [count, currentTab, entityId, processResultId])

  const CardsMapper = useCallback(
    (currentTab: TabItemTypes) => {
      switch (currentTab) {
        case TabItemTypes.PEP:
          return (
            <IndividualAmlPepCard
              entityId={entityId}
              key={`${processResultId ?? ''}pepSrcLabel`}
              processResultId={processResultId}
              srcLabelKey="pepSrcLabel"
              historyPage={historyPage}
            />
          )
        case TabItemTypes.SANCTIONS:
          return (
            <IndividualAmlPepCard
              key={`${processResultId ?? ''}sanctionSrcLabel`}
              entityId={entityId}
              processResultId={processResultId}
              srcLabelKey="sanctionSrcLabel"
              historyPage={historyPage}
            />
          )
        case TabItemTypes.WATCHLIST:
          return (
            <IndividualAmlPepCard
              key={`${processResultId ?? ''}watchlistSrcLabel`}
              entityId={entityId}
              processResultId={processResultId}
              srcLabelKey="watchlistSrcLabel"
              historyPage={historyPage}
            />
          )
        case TabItemTypes.OTHER_DATA_SOURCES:
          return (
            <IndividualAmlEntityOtherDataSrcCard
              processResultId={processResultId}
              entityId={entityId}
              historyPage={historyPage}
            />
          )
        default:
          return (
            <IndividualAmlEntityAdverseMediaCard
              processResultId={processResultId}
              entityId={entityId}
              historyPage={historyPage}
            />
          )
      }
    },
    [entityId, processResultId],
  )

  return (
    <div className="flex flex-col gap-6">
      <div data-qa={amlMatchTabsQa.container}>
        <FrankieTabs
          selectedTab={currentTab}
          onTabSelect={setCurrentTab}
          options={tabItems.map(item => ({
            value: item.value,
            label: `${t(item.tKey, { count: count[item.value] })}`,
          }))}
          testId={{ tabsContainer: amlMatchTabsQa.amlTabs }}
          className="!border-tertiary-grey-200"
        />
      </div>

      {CardsMapper(currentTab)}
    </div>
  )
}
