import React, { useEffect, useMemo } from 'react'

import { useForm } from 'react-hook-form'

import { FrankieButton } from 'frankify/src'

import { SelectFormField } from 'shared/form'

type Props<TValue extends string> = {
  className?: string
  heading: string
  label: string
  placeholder: string
  options: {
    title: string
    description: string
    value: TValue
    isHidden?: boolean
  }[]
  value?: TValue
  onSubmit: (selectedValue: TValue) => void
  isLoading?: boolean
}

export function ProfileGenerate<TValue extends string>({
  className = '',
  heading,
  label,
  placeholder,
  options,
  value,
  onSubmit,
  isLoading,
}: Props<TValue>) {
  const resultOptions = useMemo(
    () => options.filter(option => !option.isHidden),
    [options],
  )

  const { control, handleSubmit, reset } = useForm<{
    selectedValue: string
  }>()

  useEffect(() => {
    reset({
      selectedValue:
        value ?? (resultOptions.length === 1 ? resultOptions[0].value : ''),
    })
  }, [options, reset, resultOptions, value])

  return (
    <form
      onSubmit={handleSubmit(data => onSubmit(data.selectedValue as TValue))}
      className={`p-8 pt-7 bg-tertiary-grey-50 rounded-md flex flex-col gap-4 ${className}`}
    >
      <div className="flex items-center gap-3">
        <div className="text-lg font-bold flex-grow text-tertiary-gray-800">
          {heading}
        </div>
        {resultOptions.length > 1 && (
          <SelectFormField
            className="relative max-w-[250px]"
            control={control}
            name="selectedValue"
            placeholder={placeholder}
            options={resultOptions.map(({ title, value }) => ({
              label: title,
              value,
            }))}
            disabled={isLoading}
            rules={{ required: true }}
          />
        )}
        <FrankieButton
          disabled={isLoading}
          className="whitespace-nowrap"
          type="submit"
        >
          {label}
        </FrankieButton>

        {/* {isLoading && <FrankieLoader className="!h-0 !w-6" loading size="xs" />} */}
      </div>

      {resultOptions.map(({ title, description }) => (
        <div className="py-4 px-6 bg-mono-white border border-solid border-tertiary-grey-200 rounded-sm">
          <div className="text-md font-semibold text-tertiary-grey-800">
            {title}
          </div>
          <div className="text-tertiary-grey-700">{description}</div>
        </div>
      ))}
    </form>
  )
}
