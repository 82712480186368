import { useCallback, useEffect, useState } from 'react'

export function useDismissibleState(flag: boolean, key: string) {
  const [resultFlag, setResultFlag] = useState<boolean>(false)

  useEffect(() => {
    const dismiss = localStorage.getItem(key)
    if (!dismiss) {
      setResultFlag(flag)
    }
  }, [flag, key])

  const dismiss = useCallback(() => {
    localStorage.setItem(key, 'true')
    setResultFlag(false)
  }, [key])

  return { dismiss, flag: resultFlag }
}
