import React, { useMemo } from 'react'

import { IMatchedOrganization } from 'entities/organisation'

import { useI18n } from 'shared/i18n'

import { APPLICANT_BUSINESS_OWNERSHIP_INTERNATIONAL_KEY } from '../../../applicant-business-ownership-international.key'
import { applicantBusinessOwnershipInternationalEn } from '../../../locale/applicant-business-ownership-international.en'
import { applicantAssociatePartiesQa } from '../../../qa/applicant-business-ownership-international.qa'

type SummaryFields = {
  tKey: keyof (typeof applicantBusinessOwnershipInternationalEn)['iBusinessSummary']
  value: string
}[]

type Props = { className?: string } & {
  organisationSummary: IMatchedOrganization
}

export function InternationalBusinessSummary({
  className = '',
  organisationSummary,
}: Props) {
  const t = useI18n([APPLICANT_BUSINESS_OWNERSHIP_INTERNATIONAL_KEY], {
    keys: applicantBusinessOwnershipInternationalEn,
  })

  const fieldsValue = useMemo(() => {
    const registrationDetails = organisationSummary.registrationDetails[0]
    const result: SummaryFields = [
      {
        tKey: 'name',
        value: organisationSummary.name?.name ?? '-',
      },
      {
        tKey: 'country',
        value: organisationSummary.country,
      },
      {
        tKey: 'companyType',
        value: organisationSummary.organisationType?.description ?? '-',
      },
      {
        tKey: 'registrationNumber',
        value: registrationDetails.registrationNumber,
      },
      {
        tKey: 'status',
        value: organisationSummary.organizationStatus ?? '-',
      },
      {
        tKey: 'jurisdiction',
        value: organisationSummary.country,
      },
      {
        tKey: 'registrationAuthority',
        value: registrationDetails.registryDescription ?? '-',
      },
      {
        tKey: 'incorporationDate',
        value: registrationDetails.registeredDate ?? '-',
      },
    ]

    return result
  }, [organisationSummary])

  return (
    <div
      data-qa={applicantAssociatePartiesQa.summaryViewWrapper}
      className={className}
    >
      <div className="py-1 px-2 uppercase bg-tertiary-grey-50 text-tertiary-grey-500 border-b border-tertiary-grey-200">
        {t('iBusinessSummary.heading')}
      </div>
      {fieldsValue.map(({ tKey, value }) => (
        <div
          key={tKey}
          className="flex items-center border-b border-tertiary-grey-200"
        >
          <div className="p-3  text-tertiary-grey-700 w-[208px] font-semibold">
            {t(`iBusinessSummary.${tKey}`)}
          </div>
          <div className="p-3  text-tertiary-grey-500">{value}</div>
        </div>
      ))}
    </div>
  )
}
