/* eslint-disable no-nested-ternary */

import { IDateRange } from 'shared/filters-common'
import { Nullable } from 'shared/typescript'

import {
  WorkflowExecutionResultEnum,
  WorkflowExecutionSummary,
} from './entity.model'

export const PROFILES_QUERY = 'profiles-filter'

// ------ HELPER TYPES ------
export enum ProfileRiskLevelTypes {
  UNKNOWN = 'UNKNOWN',
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
  UNACCEPTABLE = 'UNACCEPTABLE',
}

export enum ProfileIssueCategoryTypes {
  VERIFY = 'VERIFY',
  DECEASED = 'DECEASED',
  EXPIRY = 'EXPIRY',
  INFORMATIONAL = 'INFORMATIONAL',
  AML = 'AML',
  FRAUD = 'FRAUD',
  IDV = 'IDV',
  DEVICE = 'DEVICE',
  TRANSACTION = 'TRANSACTION',
  WATCHLIST = 'WATCHLIST',
  DUPLICATE = 'DUPLICATE',
  BLOCKLISTED = 'BLOCKLISTED',
  SYSTEM = 'SYSTEM',
  BAD_DATA = 'BAD_DATA',
  KYC = 'KYC',
}

export enum ProfileIssueTypes {
  NOT_FOUND = 'NOT_FOUND',
  BAD_DATA_NAME = 'BAD_DATA_NAME',
  BAD_DATA_DOB = 'BAD_DATA_DOB',
  BAD_DATA_ADDRESS = 'BAD_DATA_ADDRESS',
  BAD_DATA_ID = 'BAD_DATA_ID',
  BAD_DATA_EMAIL = 'BAD_DATA_EMAIL',
  BAD_DATA_MOBILE = 'BAD_DATA_MOBILE',
  REQUIRES_KYC = 'REQUIRES_KYC',
  REQUIRES_IDV = 'REQUIRES_IDV',
  REQUIRES_AML = 'REQUIRES_AML',
  CREDIT_HEADER = 'CREDIT_HEADER',
  PEP = 'PEP',
  SANCTION = 'SANCTION',
  MEDIA = 'MEDIA',
  FRAUD_LIST = 'FRAUD_LIST',
  FRAUD_CHECK = 'FRAUD_CHECK',
  DEVICE_FRAUD_MATCH = 'DEVICE_FRAUD_MATCH',
  DEVICE_INVALID = 'DEVICE_INVALID',
  AML_ISSUE = 'AML_ISSUE',
  FRAUD_ISSUE = 'FRAUD_ISSUE',
  BLOCKED = 'BLOCKED',
  INTERNAL_MATCH = 'INTERNAL_MATCH',
  DUPLICATE = 'DUPLICATE',
  MATCHED_INTERNAL = 'MATCHED_INTERNAL',
  MATCHED_SHARED = 'MATCHED_SHARED',
  MATCHED_EXTERNAL = 'MATCHED_EXTERNAL',
  SERVICE_TIMEOUT = 'SERVICE_TIMEOUT',
  SERVICE_ERROR = 'SERVICE_ERROR',
}
export interface IProfileFilter {
  entityName: string
  customerId: string
  entityId: string
  states: ProfileStateTypes[]
  issueCategories: ProfileIssueCategoryTypes[]
  riskLevels: ProfileRiskLevelTypes[]
  createdDate: IDateRange
  updatedDate: IDateRange
  workflowNames: string[]
  workflowStatuses: string[]
  assignees: { value: string; label: string }[]
  statusOverrideType?: string
}
export type IPartialProfileFilter = Omit<
  IProfileFilter,
  'entityId' | 'entityName'
>

export type TSortType = 'asc' | 'desc'
export enum ProfileStateTypes {
  INIT = 'INIT',
  AUTO = 'AUTO',
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
  BLOCKLISTED = 'BLOCKLISTED',
  INACTIVE = 'INACTIVE',
  DELETED = 'DELETED',
}

export enum WorkflowStatusKeysTypes {
  UNCHECKED = 'UNCHECKED',
  FAILED = 'FAILED',
  PASSED = 'PASSED',
  NEEDS_ATTENTION = 'NEEDS_ATTENTION',
  MANUALLY_PASSED = 'MANUALLY_PASSED',
  MANUALLY_FAILED = 'MANUALLY_FAILED',
}

export type TEntityType = 'INDIVIDUAL' | 'UNKNOWN'

export const workflowStatusMapping: Record<
  WorkflowExecutionResultEnum,
  WorkflowStatusKeysTypes
> = {
  [WorkflowExecutionResultEnum.UNCHECKED]: WorkflowStatusKeysTypes.UNCHECKED,
  [WorkflowExecutionResultEnum.IN_PROGRESS]: WorkflowStatusKeysTypes.UNCHECKED,
  [WorkflowExecutionResultEnum.COMPLETE]: WorkflowStatusKeysTypes.UNCHECKED,
  [WorkflowExecutionResultEnum.CLEAR]: WorkflowStatusKeysTypes.PASSED,
  [WorkflowExecutionResultEnum.FAIL]: WorkflowStatusKeysTypes.FAILED,
  [WorkflowExecutionResultEnum.INCOMPLETE]: WorkflowStatusKeysTypes.FAILED,
  [WorkflowExecutionResultEnum.REJECTED]: WorkflowStatusKeysTypes.FAILED,
  [WorkflowExecutionResultEnum.PASS]: WorkflowStatusKeysTypes.PASSED,
  [WorkflowExecutionResultEnum.APPROVED]: WorkflowStatusKeysTypes.PASSED,
  [WorkflowExecutionResultEnum.REVIEW]: WorkflowStatusKeysTypes.NEEDS_ATTENTION,
  [WorkflowExecutionResultEnum.NEEDS_APPROVAL]:
    WorkflowStatusKeysTypes.NEEDS_ATTENTION,
  [WorkflowExecutionResultEnum.BLOCKED]:
    WorkflowStatusKeysTypes.NEEDS_ATTENTION,
  [WorkflowExecutionResultEnum.URGENT]: WorkflowStatusKeysTypes.NEEDS_ATTENTION,
  [WorkflowExecutionResultEnum.MONITOR]:
    WorkflowStatusKeysTypes.NEEDS_ATTENTION,
}

export const workflowManualStatusMapping: Partial<
  Record<WorkflowExecutionResultEnum, WorkflowStatusKeysTypes>
> = {
  [WorkflowExecutionResultEnum.FAIL]: WorkflowStatusKeysTypes.MANUALLY_FAILED,
  [WorkflowExecutionResultEnum.PASS]: WorkflowStatusKeysTypes.MANUALLY_PASSED,
  [WorkflowExecutionResultEnum.REVIEW]: WorkflowStatusKeysTypes.NEEDS_ATTENTION,
}

export const getWorkflowStatusKey = (
  workflowSummary?: WorkflowExecutionSummary,
) => {
  const status = workflowSummary?.status
  if (status) {
    const statusKey =
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      workflowStatusMapping[status] || WorkflowStatusKeysTypes.UNCHECKED
    if (workflowSummary.statusOverrideRequestId)
      return workflowManualStatusMapping[status] || statusKey
    return statusKey
  }
  return WorkflowStatusKeysTypes.UNCHECKED
}

export interface IProfile {
  serviceProfileId: Nullable<string>
  customerReference: Nullable<string>
  entityId: string
  entityType: TEntityType
  entityName: string
  serviceId: Nullable<string>
  state: ProfileStateTypes
  createdAt: string
  updatedAt: Nullable<string>
  assignee: Nullable<string>
  serviceName: string
  workflowSummaries: Nullable<WorkflowExecutionSummary[]>
}

interface ISearchFieldMatch {
  description?: string
  confidence?: number
}

interface ISearchMatches {
  serviceProfile: IProfile
  fieldsMatched: ISearchFieldMatch
}

interface IMeta {
  page: Nullable<number>
  total: Nullable<number>
  limit: Nullable<number>
  count: Nullable<number>
  sort: Nullable<TSortType>
  sortField: Nullable<string>
}

export interface IProfileResponse {
  matches: ISearchMatches[]
  meta: Partial<IMeta>
  requestId: string
}
