import { useCallback, useMemo } from 'react'

import {
  ApplicantBusinessInfoResponse,
  ApplicantId,
  KybReportType,
  ReportIdSubType,
  useApplicantDataQuery,
} from 'entities/applicant'
import { formatAddress } from 'entities/applicant/model/applicant-address.model'
import { checksRegex } from 'entities/check'
import { Scan } from 'entities/document'
import {
  useBusinessSearch,
  BusinessSearchResponse,
  useBusinessMCC,
} from 'entities/organisation'

import { differenceInSeconds } from 'shared/date-time'
import { useI18n } from 'shared/i18n'
import { PartialRecord } from 'shared/typescript'

import { APPLICANT_GENERAL_INFORMATION_KEY } from '../../applicant-general-information.key'
import { applicantGeneralInformationEn } from '../../locale/applicant-general-information.en'
import {
  ABRBusinessSummary,
  BusinessCheckTypes,
  OtherBusinessSummary,
  getBusinessFormattedDate,
} from '../../model/applicant-business.model'

type Args = {
  applicantId?: ApplicantId
}

type ReportState = Record<'kyb' | 'ubo' | 'cr', boolean>

/**
 * Kyb Report State
 */
export const useApplicantBusinessReport = ({ applicantId }: Args) => {
  const { data: applicantData, isFetching } = useApplicantDataQuery({
    applicantId,
  })

  const businessInfo = applicantData?.businessInfo
  const documents = applicantData?.documents

  /**
   * To get the sorted scan
   */
  const sortScans = useCallback((scans: Scan[] = []) => {
    // Sort scans by created date
    scans.sort((a, b) =>
      differenceInSeconds(new Date(b.scanCreated), new Date(a.scanCreated)),
    )

    // Sort scans by side: "F" first, "B" second, and default side last
    scans.sort((a, b) => {
      const sideOrder: PartialRecord<string, number> = { F: 1, B: 2 }
      return (sideOrder[a.side] || 3) - (sideOrder[b.side] || 3)
    })

    return scans
  }, [])

  /**
   * To get the last scan error
   */
  const getLastScanError = useCallback(
    (scans: Scan[] = []) =>
      sortScans(scans)[0]?.scanName.toLowerCase().includes('error'),
    [sortScans],
  )

  /**
   * Error report states
   */
  const errorReport: ReportState = useMemo(() => {
    const error = (documents ?? []).reduce(
      (value, doc) => {
        if (doc.idType !== 'REPORT') return value

        const error = getLastScanError(doc.scans)

        switch (doc.idSubType) {
          case 'SINGLE-LEVEL-AML':
            return { ...value, kyb: error }
          case 'UBO':
            return { ...value, ubo: error }
          case 'Custom Credit Report':
            return { ...value, cr: error }
          default:
            return value
        }
      },
      { kyb: false, ubo: false, cr: false },
    )

    return error
  }, [documents, getLastScanError])

  const getReportDocument = useCallback(
    (
      subtype: KybReportType,
      config?: {
        checkForScans?: boolean
        lastScanErrorFree?: boolean
      },
    ) => {
      const { checkForScans = false, lastScanErrorFree = false } = config || {}

      let convertedSubType = subtype as ReportIdSubType

      if (subtype === 'CR' || subtype === 'CR-RISK-PAYMENT') {
        convertedSubType = 'Custom Credit Report'
      }

      const reportDocument = documents?.find(d => {
        const isReport = d.idType === 'REPORT'
        const sameType = d.idSubType === convertedSubType
        const hasScans = checkForScans ? Boolean(d.scans.length) : true
        // if scanName include ERROR, the report generate is error
        const lastScanHasError =
          hasScans && lastScanErrorFree ? getLastScanError(d.scans) : false

        return isReport && sameType && hasScans && !lastScanHasError
      })

      return reportDocument
    },
    [documents, getLastScanError],
  )

  const getIsReportPending = useCallback(
    (type: KybReportType) => {
      const requestedAtRaw =
        businessInfo?.businessReportRequestedAt?.[type] || null

      // Not pending if not requested
      if (!requestedAtRaw) return false

      const reportDocumentSet = getReportDocument(type)
      const scans = sortScans(reportDocumentSet?.scans)
      const latestScanCreatedAt = scans[0]?.scanCreated

      // If the latest report is present then time difference of latestScanCreatedAt greater than requestedAt
      if (
        latestScanCreatedAt &&
        differenceInSeconds(
          new Date(latestScanCreatedAt),
          new Date(requestedAtRaw),
        ) > 0
      ) {
        return false // Report is already generated
      }

      /**
       * Time passed since the report generation
       */
      const timePassedSinceReport = differenceInSeconds(
        new Date(),
        new Date(requestedAtRaw),
      )

      /**
       * Wait time for the report to be generated
       * 10 min wait time in seconds
       */
      const waitTime = 60 * 10

      if (timePassedSinceReport > waitTime) {
        return false // Clearing the pending state
      }

      // If report requestedAt is in wait time limit
      return true
    },
    [businessInfo?.businessReportRequestedAt, getReportDocument, sortScans],
  )

  const pendingReport: ReportState = useMemo(
    () => ({
      cr: getIsReportPending('CR') || getIsReportPending('CR-RISK-PAYMENT'),
      kyb: getIsReportPending('SINGLE-LEVEL-AML'),
      ubo: getIsReportPending('UBO'),
    }),
    [getIsReportPending],
  )

  return {
    sortScans,
    getLastScanError,
    getReportDocument,
    getIsReportPending,
    pendingReport,
    isFetching,
    errorReport,
  }
}

/**
 * Applicant Business State with reusable information (Add more information if needed)
 */
export const useApplicantBusinessState = ({ applicantId }: Args) => {
  const { data: applicantData, isFetching } = useApplicantDataQuery({
    applicantId,
  })

  const { pendingReport } = useApplicantBusinessReport({ applicantId })

  const businessInfo = applicantData?.businessInfo
  const checkSummary = applicantData?.checkSummary

  const { checkResults, checkType, alertList } = useMemo(
    () => ({
      checkResults: checkSummary?.checkResults ?? [],
      checkType: checkSummary?.checkTypes ?? [],
      alertList: checkSummary?.alertList || [],
    }),
    [checkSummary],
  )

  const status = useMemo(() => {
    const pepRan = checkType.some(
      item => item === 'pep' || item === 'pep_media',
    )
    const entityHasAml = alertList.some(alert =>
      ['media', 'watchlist', 'sanction', 'pep'].includes(alert.term),
    )

    return { entityHasAml, pepRan }
  }, [alertList, checkType])

  const businessCheck: Record<BusinessCheckTypes, { isPending: boolean }> =
    useMemo(() => {
      const hasPepCheck =
        checkResults.some(
          cr =>
            (checksRegex.pep.test(cr.type) ||
              checksRegex.pepMedia.test(cr.type)) &&
            cr.result.type !== 'UNCHECKED',
        ) || status.pepRan // entity doesn't have checkResults available, need to manual pick up the PEP check status

      return {
        // UBO and Single Level report run pep but they're not instant
        // When they're running we want to prevent PEP checks
        [BusinessCheckTypes.pep]: {
          isPending: !hasPepCheck && !pendingReport.kyb && !pendingReport.ubo,
        },
      }
    }, [checkResults, status, pendingReport])

  const businessDetail = useMemo(() => {
    const ABNNumber = businessInfo?.ABNNumber
    const ACNNumber = businessInfo?.ACNNumber

    return { applicantBusinessData: businessInfo, ABNNumber, ACNNumber }
  }, [businessInfo])

  return {
    ...businessDetail,
    ...status,
    checkResults,
    businessCheck,
    isFetching,
  }
}

/**
 * Applicant Business Summary
 */
export const useApplicantBusinessSummary = ({ applicantId }: Args) => {
  const t = useI18n([APPLICANT_GENERAL_INFORMATION_KEY], {
    keys: applicantGeneralInformationEn,
  })

  const {
    ABNNumber,
    ACNNumber,
    applicantBusinessData,
    isFetching: isFetchingApplicantData,
  } = useApplicantBusinessState({ applicantId })

  const { data: businessSearchData, isFetching: isFetchingBusinessData } =
    useBusinessSearch({
      searchTerm: ABNNumber ?? ACNNumber,
    })

  const { data: mccData, isFetching: isFetchingMCCData } = useBusinessMCC()

  const getBusinessTypeLabel = useCallback(
    (type?: ApplicantBusinessInfoResponse['businessType']) => {
      if (type?.match(/prv/gi)) return t('ausPvtCompany')
      if (type?.match(/pub/gi)) return t('ausPblCompany')
      return type
    },
    [t],
  )

  const getBusinessNames = useCallback(
    (businessNames?: BusinessSearchResponse['businessNames']) => {
      if (!businessNames?.length) return [t('noRegisteredBusinessName')]

      return businessNames.map(business =>
        t('businessEffectiveFrom', {
          businessName: business.name,
          date: getBusinessFormattedDate(business.effectiveFrom),
        }),
      )
    },
    [t],
  )

  const getAbnStatus = useCallback(
    (abnStatus?: BusinessSearchResponse['abnStatus']) => {
      if (!abnStatus) return ''
      return t('statusEffectiveFrom', {
        status: abnStatus.status,
        date: getBusinessFormattedDate(abnStatus.effectiveFrom),
      })
    },
    [t],
  )

  const abrBusinessSummary: ABRBusinessSummary = useMemo(
    // eslint-disable-next-line complexity
    () => {
      const searchResult = businessSearchData?.[0]
      const businessAddress = applicantBusinessData?.addresses[0]

      return {
        name: searchResult?.name ?? applicantBusinessData?.businessName ?? '',
        mainNames: searchResult?.mainNames ?? [],
        tradingNames: searchResult?.tradingNames ?? [],
        legalNames: searchResult?.legalNames ?? [],
        location: formatAddress(businessAddress),

        type:
          searchResult?.type ??
          getBusinessTypeLabel(applicantBusinessData?.businessType) ??
          '',

        acn: searchResult?.acn ?? applicantBusinessData?.ACNNumber ?? '',
        abn:
          searchResult?.abn ??
          applicantBusinessData?.ABNNumber ??
          applicantBusinessData?.ARBNNumber ??
          '',

        currentBusiness: getBusinessNames(searchResult?.businessNames),

        abnStatus: getAbnStatus(searchResult?.abnStatus),

        historicalBusiness: searchResult?.historicalBusinessNames.length
          ? t('viewHistoricalBusinessNames')
          : t('noHistoricalBusinessNames'),
      }
    },
    [
      applicantBusinessData,
      businessSearchData,
      getAbnStatus,
      getBusinessNames,
      getBusinessTypeLabel,
      t,
    ],
  )

  const otherBusinessSummary: OtherBusinessSummary = useMemo(() => {
    const mccCode = applicantBusinessData?.mccCode
    const mccInfo = mccData?.find(item => item.value === mccCode)
    return {
      merchantCategory:
        mccInfo && mccCode ? `${mccCode} - ${mccInfo.name}` : 'Default',
    }
  }, [applicantBusinessData?.mccCode, mccData])

  return {
    abrBusinessSummary,
    otherBusinessSummary,
    historicalBusinessNames: businessSearchData?.[0]?.historicalBusinessNames,
    loadingBusinessSummary: isFetchingApplicantData || isFetchingBusinessData,
    loadingOtherSummary: isFetchingApplicantData || isFetchingMCCData,
  }
}
