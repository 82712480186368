import React, { useState } from 'react'

import { DataGridPro, GridColDef, GridSortModel } from '@mui/x-data-grid-pro'
import { Controller, useForm, useFormContext } from 'react-hook-form'

import { FrankieButton, FrankieTextField } from 'frankify/src'

import { WithProps } from 'shared/hoc'
import { useI18n } from 'shared/i18n'

import {
  EntityNameCell,
  EntityTableHeader,
  LoadingOverlay,
  SelectedEntityCell,
  EntityStatusCell,
  TypeIconCell,
  getWidth,
  headerField,
  NoRowsOverlay,
  entityStylesSx,
} from './associate-existing-parties-grid-helper/associate-existing-parties-grid-helper'
import { APPLICANT_BUSINESS_OWNERSHIP_KEY } from '../../../applicant-business-ownership.key'
import { applicantBusinessOwnershipEn } from '../../../locale/applicant-business-ownership.en'
import {
  AssociatePartyFormData,
  SearchEntityRecord,
} from '../../../model/associate-party.model'
import { applicantAssociatePartiesQa } from '../../../qa/applicant-business-ownership.qa'
import {
  useExistingApplicantSearchState,
  SearchArgs,
} from '../../../state/existing-applicant-state/existing-applicant.state'

type SearchFormData = SearchArgs

export function AssociateExistingParties() {
  const t = useI18n([APPLICANT_BUSINESS_OWNERSHIP_KEY], {
    keys: applicantBusinessOwnershipEn,
  })

  const [sortModel, setSortModel] = useState<GridSortModel>([])

  const { watch, control } = useFormContext<AssociatePartyFormData>()

  const {
    register,
    getValues,
    watch: watchSearchForm,
    resetField,
  } = useForm<SearchFormData>()

  const {
    searchDataList: rows,
    applySearch,
    filter,
    applyFilter,
    fetchNextData,
    loading,
  } = useExistingApplicantSearchState()

  const handleClearSearch = (keyName: keyof SearchFormData) =>
    watchSearchForm(keyName)
      ? {
          onClick: () => {
            resetField(keyName)
            applySearch({ [keyName]: '' })
          },
        }
      : undefined

  const columns: GridColDef<SearchEntityRecord>[] = [
    {
      ...headerField('type'),
      ...getWidth(100),
      headerName: t('dataGridFields.type'),
      renderCell: TypeIconCell,
    },
    {
      ...headerField('entityName'),
      ...getWidth(350),
      headerName: t('dataGridFields.name'),
      renderCell: EntityNameCell,
      sortable: true,
    },
    {
      ...headerField('profileStatus'),
      ...getWidth(200),
      headerName: t('dataGridFields.profileStatus'),
      renderCell: EntityStatusCell,
    },
    {
      field: 'action',
      headerName: '',
      ...getWidth(100),
      renderCell: WithProps(SelectedEntityCell, {
        selectedEntityId: watch('entityId'),
      }),
    },
  ]

  return (
    <div className="mb-8">
      <div className="flex items-end gap-3 bg-neutral-20 py-1 px-4">
        <FrankieTextField
          placeholder={t('fullNameOrFamilyName')}
          className="max-w-[300px]"
          closeButton={handleClearSearch('nameFilter')}
          {...register('nameFilter')}
          testId={{ input: applicantAssociatePartiesQa.nameFilter }}
        />
        <FrankieTextField
          placeholder={t('customerId')}
          className="max-w-[300px]"
          closeButton={handleClearSearch('entityIdFilter')}
          {...register('entityIdFilter')}
        />
        <FrankieButton
          className="!bg-mono-100 !outline-mono-50"
          size="sm"
          startIcon={{ name: 'mdiMagnify' }}
          onClick={() => applySearch(getValues())}
          testId={{ button: applicantAssociatePartiesQa.applySearchBtn }}
        >
          {t('search')}
        </FrankieButton>
      </div>

      <Controller
        render={({ field }) => (
          <DataGridPro
            getRowId={row => row.entityId}
            rows={rows}
            slots={{
              loadingOverlay: LoadingOverlay,
              noRowsOverlay: WithProps(NoRowsOverlay, {
                nameFilter: filter.nameFilter,
                entityIdFilter: filter.entityIdFilter,
              }),
            }}
            className="[&_.MuiDataGrid-columnHeader--sortable]:border-primary-600 "
            onRowsScrollEnd={fetchNextData}
            columns={columns.map(item => ({
              // Adding default properties for every column
              sortable: false,
              hideSortIcons: true,
              resizable: false,
              disableColumnMenu: true,
              disableReorder: true,
              cellClassName: '!outline-none',
              headerClassName:
                '!px-3 py-0 text-xs font-medium bg-mono-white text-tertiary-grey-700 !outline-none',
              renderHeader: WithProps(EntityTableHeader, {
                sortModel,
                onFilterChange: applyFilter,
              }),

              ...item,
            }))}
            loading={loading}
            sortModel={sortModel}
            onSortModelChange={setSortModel}
            rowSelectionModel={[field.value]}
            onRowSelectionModelChange={newSelection =>
              field.onChange(`${newSelection[0] ?? ''}`)
            }
            rowHeight={70}
            isCellEditable={() => false}
            disableMultipleRowSelection
            disableColumnSelector
            disableColumnFilter
            disableColumnMenu
            disableDensitySelector
            showCellVerticalBorder={false}
            showColumnVerticalBorder={false}
            hideFooterPagination
            hideFooter
            hideFooterSelectedRowCount
            sx={entityStylesSx}
          />
        )}
        name="entityId"
        rules={{ required: true }}
        control={control}
      />
    </div>
  )
}
