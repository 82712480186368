import { useEffect, useMemo } from 'react'

import { useQuery } from '@tanstack/react-query'

import { useHasFeatureFlag } from 'entities/session'

import { ErrorResponse } from 'shared/http'
import { useI18n } from 'shared/i18n'
import { notification } from 'shared/notification'

import { applicantApi } from '../../api/applicant.api'
import { APPLICANT_KEY, applicantEn } from '../../locale/applicant.en'
import {
  AdditionalAddressCategoryTypes,
  AddressCategoryTypes,
} from '../../model/applicant-address.model'
import {
  ApplicantAddressResponse,
  ApplicantResponse,
} from '../../model/applicant-response.model'

export const ApplicantDataQueryKey = 'applicant-data-query'

type Args = {
  applicantId?: string
  enabled?: boolean
  showWarning?: boolean
}

/**
 * Applicant data Query
 */
export const useApplicantDataQuery = ({
  applicantId,
  enabled,
  showWarning,
}: Args) => {
  const t = useI18n([APPLICANT_KEY], { keys: applicantEn })

  const query = useQuery<ApplicantResponse, ErrorResponse>({
    enabled: enabled ?? !!applicantId,
    queryKey: [ApplicantDataQueryKey, applicantId],
    queryFn: async () => {
      const { data } = await applicantApi.getApplicant(applicantId!)
      const { addresses } = data.applicantDetails

      // Handle the deprecation of `RESEDENTIAL` address type by converting it to `RESIDENTIAL1` if it exists in the `addresses`
      if (addresses) {
        // first if no residential1, convert the first residential to residential1
        const isAddressesHaveRESIDENTIAL1 = addresses.some(
          add => add.addressType === AddressCategoryTypes.current,
        )

        const residentialIndex = addresses.findIndex(
          a => a.addressType === AdditionalAddressCategoryTypes.RESIDENTIAL,
        )

        if (!isAddressesHaveRESIDENTIAL1 && residentialIndex >= 0) {
          addresses[residentialIndex].addressType = AddressCategoryTypes.current
        }

        // second if no residential1, take first null and make it residential1
        const residentialTypes = [AddressCategoryTypes.current]
        const isAddressResidential = (a: ApplicantAddressResponse) =>
          residentialTypes.includes(a.addressType as AddressCategoryTypes)

        const addressesWithNullType = addresses.filter(a => !a.addressType)
        const hasResidential = addresses.some(isAddressResidential)
        if (!hasResidential && addressesWithNullType.length > 0) {
          const residential = addressesWithNullType[0]
          residential.addressType = AddressCategoryTypes.current
        }
      }

      return data
    },
  })

  useEffect(() => {
    if (query.isError) {
      if (showWarning) {
        notification.warning(
          t(
            query.error.response?.status === 404
              ? 'error.securityError'
              : 'error.defaultMessage',
          ),
          {
            toastId: 'applicant-data-query-error',
          },
        )
      } else {
        notification.error(
          t(
            query.error.response?.status === 404
              ? 'error.securityError'
              : 'error.defaultMessage',
          ),
          {
            toastId: 'applicant-data-query-error',
          },
        )
      }
    }
  }, [query.isError])

  return query
}

export const useApplicantInternationalKYB = ({ applicantId }: Args) => {
  const { data: applicantData } = useApplicantDataQuery({ applicantId })

  const { internationalKYB, internationalKybAML } = useHasFeatureFlag({
    internationalKYB: 'internationalKYB',
    internationalKybAML: 'internationalKybAML',
  })

  const hasABNorACN =
    !!applicantData?.businessInfo?.ABNNumber ||
    !!applicantData?.businessInfo?.ACNNumber

  const isBusinessProfile = applicantData?.businessInfo

  // const isOtherCountry =
  //   applicantData?.applicantDetails.profile.countryAlpha3 !== 'aus'

  const hasJurisdiction =
    !!applicantData?.applicantDetails.extraData?.requested_jurisdiction_country

  const isInternationalEntity =
    applicantData?.applicantDetails.extraData
      ?.requested_jurisdiction_country !== 'AUS'

  const isInternationalKYB =
    !hasABNorACN &&
    internationalKYB &&
    isBusinessProfile &&
    (hasJurisdiction ? isInternationalEntity : true)

  return {
    isInternationalKYB,
    isInternationalKybAmlEnabled: isInternationalKYB && internationalKybAML,
  }
}

/**
 * To get mostly used data of applicant
 * Add more data modification as per requirement
 */
export const useApplicantState = ({ applicantId }: Args) => {
  const { data: applicantData } = useApplicantDataQuery({ applicantId })

  function getAddress(
    applicantDetails?: ApplicantResponse['applicantDetails'],
  ) {
    const addressTypeOrder = [
      'REGISTERED_OFFICE',
      'PLACE_OF_BUSINESS',
      'BUSINESS',
    ]
    let currentAddress
    for (const addressType of addressTypeOrder) {
      currentAddress = applicantDetails?.addresses?.find(
        address => address.addressType === addressType,
      )
      if (currentAddress) {
        return currentAddress
      }
    }
    return ''
  }

  const result = useMemo(() => {
    const applicantNameData = applicantData?.applicantDetails.name
    const applicantName =
      applicantNameData?.displayName ??
      [
        applicantNameData?.givenName,
        applicantNameData?.middleName,
        applicantNameData?.familyName,
      ]
        .filter(Boolean)
        .join(' ')
    const address = getAddress(applicantData?.applicantDetails)

    const inactiveProfile =
      applicantData?.checkSummary.status.key === 'INACTIVE'

    return { applicantName, address, inactiveProfile }
  }, [applicantData])

  return result
}
