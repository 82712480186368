import { useQuery, useQueryClient } from '@tanstack/react-query'

import { countryPhoneCodeList } from 'entities/country'
import { transformDocumentType } from 'entities/entity/model/transform-document'
import { useHasFeatureFlag } from 'entities/session'

import { entityApi } from '../../api/entity.api'
import {
  IndividualsEntityResponse,
  ServiceProfile,
} from '../../model/entity.model'
import {
  IProfile,
  IProfileResponse,
  PROFILES_QUERY,
} from '../../model/profile.model'

export const EntityDataQueryKey = 'entity-data-query'

export const useEntityDataQuery = (entityId?: string, level?: 'base64') =>
  useQuery({
    queryKey: [EntityDataQueryKey, entityId, level],
    enabled: !!entityId,
    queryFn: async () => {
      const { data } = await entityApi.getEntity(entityId!, level)

      // Temporary fix for the issue with the attachment type PDF
      if (data.individual?.documents?.IDENTITY) {
        data.individual.documents.IDENTITY = transformDocumentType(
          data.individual.documents.IDENTITY,
        )
      }

      if (data.individual?.phoneNumbers) {
        data.individual.phoneNumbers = data.individual.phoneNumbers.map(
          phone => {
            if (!phone.number || !phone.country) return phone

            const countryCode = countryPhoneCodeList.find(
              c => c.code === phone.country,
            )
            if (!countryCode) return phone

            // Remove country code prefix if present
            let { number } = phone
            if (number.startsWith(`+${countryCode.value}`)) {
              number = number.slice(countryCode.value.length + 1)
            }

            return {
              ...phone,
              number,
            }
          },
        )
      }

      return data
    },
  })

export const useFrankie2R2Customer = () => {
  const { isFrankie2, isFrankie2R2 } = useHasFeatureFlag({
    isFrankie2: ['frankie2customer'],
    isFrankie2R2: ['frankie2customerR2'],
  })

  return isFrankie2R2 && isFrankie2
}

export const useUpdateProfileData = (entityId?: string) => {
  const queryClient = useQueryClient()
  const updateProfileData = (serviceProfiles?: ServiceProfile[]) => {
    if (serviceProfiles) {
      if (entityId === 'bulk') {
        void queryClient.removeQueries([EntityDataQueryKey])
      } else {
        queryClient.setQueriesData(
          [EntityDataQueryKey, entityId],
          (oldData?: IndividualsEntityResponse) => {
            if (oldData) {
              return { ...oldData, serviceProfiles }
            }
            return oldData
          },
        )
      }

      queryClient.setQueriesData(
        [PROFILES_QUERY],
        (oldData?: { pages?: IProfileResponse[] }) => {
          if (oldData?.pages) {
            for (const page of oldData.pages) {
              for (const match of page.matches) {
                const serviceProfile = serviceProfiles.find(
                  profile => profile.entityId === match.serviceProfile.entityId,
                )
                if (serviceProfile) {
                  match.serviceProfile = serviceProfile as unknown as IProfile
                }
              }
            }
          }
          return oldData
        },
      )
    }
  }

  return { updateProfileData }
}
