import React from 'react'

import { FrankieImage } from 'frankify/src'

import { countryQa } from '../../../qa/country.qa'

export function SelectorOption({
  handleClick,
  selected,
  icon,
  name,
}: {
  handleClick: () => void
  selected: boolean
  icon: string
  name: string
}) {
  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <li
      onClick={handleClick}
      onKeyDown={handleClick}
      className={`flex items-center text-sm text-tertiary-grey-800 py-2 px-4 cursor-pointer ${
        selected ? 'bg-primary-50' : 'hover:bg-tertiary-grey-100'
      }`}
      data-qa={countryQa.defaultCountrySelectorOption}
    >
      <FrankieImage
        className="rounded-xs h-4 cursor-pointer mr-2.5"
        src={icon}
      />
      <div>{name}</div>
    </li>
  )
}
