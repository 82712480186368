import { useQuery } from '@tanstack/react-query'

import { isNotEmptyString } from 'shared/typescript'

import { addressApi } from '../api/address.api'

export const GetAddressSuggestionQueryKey = ['get-address-suggestion']

const sleep = (ms = 1000) =>
  new Promise(resolve => {
    void setTimeout(resolve, ms)
  })

export const useGetAddressSuggestion = (
  searchTerm: string,
  regions: string[],
) =>
  useQuery({
    queryKey: [GetAddressSuggestionQueryKey, searchTerm, regions],
    queryFn: async ({ signal }) => {
      await sleep(250)
      if (!signal?.aborted) {
        const { data } = await addressApi.getAddressSuggestion(
          searchTerm || '',
          regions,
          signal,
        )
        return data
      }
      return undefined
    },
    enabled: isNotEmptyString(searchTerm || ''),
  })
