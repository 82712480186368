import React, { ComponentProps } from 'react'

import { ApplicantPepSactionsAdverseMedia } from './applicant-pep-sactions-adverse-media/applicant-pep-sactions-adverse-media'
import { ApplicantPepSactionsDataSources } from './applicant-pep-sactions-data-sources/applicant-pep-sactions-data-sources'
import { ApplicantPepSactionsKeyData } from './applicant-pep-sactions-key-data/applicant-pep-sactions-key-data'
import { applicantPepSanctionResultTabQa } from '../../../qa/applicant-pep-sactions.qa'

const ApplicantPepSactionsResultTabsMapping = {
  keyData: ApplicantPepSactionsKeyData,
  adverseMedia: ApplicantPepSactionsAdverseMedia,
  dataSource: ApplicantPepSactionsDataSources,
}

type ApplicantPepSactionsResultTabsMappingType =
  typeof ApplicantPepSactionsResultTabsMapping

export type ApplicantPepSactionsResultTabsMappingTypes =
  keyof ApplicantPepSactionsResultTabsMappingType

export type OwnProps<TType extends ApplicantPepSactionsResultTabsMappingTypes> =
  { type?: TType }

export function ApplicantPepSactionsResultTabs<
  TType extends ApplicantPepSactionsResultTabsMappingTypes,
>({
  type,
  ...rest
}: OwnProps<TType> &
  ComponentProps<ApplicantPepSactionsResultTabsMappingType[TType]>) {
  const TabType = type
    ? ApplicantPepSactionsResultTabsMapping[type]
    : ApplicantPepSactionsKeyData

  return (
    <div data-qa={applicantPepSanctionResultTabQa.container} className="">
      <TabType
        testId={{ container: applicantPepSanctionResultTabQa.tab }}
        {...rest}
      />
    </div>
  )
}
