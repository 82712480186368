import { MutationCache, QueryCache, QueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { ErrorCodeTypes } from 'shared/error'

const TOKEN_INVALID_ERROR_MESSAGE = 'Token is invalid'
function isTokenInvalidError(error: AxiosError<{ message?: string }>): boolean {
  return (
    error.response?.status === ErrorCodeTypes.E401 &&
    error.response.data.message === TOKEN_INVALID_ERROR_MESSAGE
  )
}

export function initQueryClient({
  onInvalidTokenError,
}: {
  onInvalidTokenError: () => void
}): QueryClient {
  return new QueryClient({
    queryCache: new QueryCache({
      onError: error => {
        if (isTokenInvalidError(error as AxiosError<{ message?: string }>)) {
          return onInvalidTokenError()
        }
        // eslint-disable-next-line no-console
        console.warn('global query error ', error)
        return error
      },
    }),
    mutationCache: new MutationCache({
      onError: error => {
        if (isTokenInvalidError(error as AxiosError<{ message?: string }>)) {
          return onInvalidTokenError()
        }
        // eslint-disable-next-line no-console
        console.warn('global mutation err', error)
        return error
      },
    }),
    defaultOptions: {
      queries: {
        retry: false,
        staleTime: Infinity,
        refetchOnWindowFocus: false,
      },
      mutations: {
        retry: false,
      },
    },
  })
}
