import React, { Component, ErrorInfo, ReactNode } from 'react'

import { applicantRelatedOrganisationsEn } from '../../locale/applicant-related-organisations.en'

type Props = {
  children: ReactNode
}

type State = {
  hasError: boolean
}

export class ApplicantRelatedOrganisationsErrorBoundary extends Component<
  Props,
  State
> {
  constructor(props: Props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(): State {
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // eslint-disable-next-line no-console
    console.error('Uncaught error:', error, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      return (
        <div>
          <h1 className="mb-1 font-bold text-xl">
            {applicantRelatedOrganisationsEn.title}
          </h1>
          <h2 className="text-sm text-secondary-900">
            {applicantRelatedOrganisationsEn.subtitle}
          </h2>
        </div>
      )
    }

    return this.props.children
  }
}
