/* eslint-disable complexity */
import React, { useCallback, useEffect, useMemo, useRef } from 'react'

import { useSearchParams } from 'react-router-dom'

import { sardineChecksRegex } from 'entities/check'

import { FraudCheckDevice } from './fraud-check-device/fraud-check-device'
import { FraudCheckEmail } from './fraud-check-email/fraud-check-email'
import { FraudCheckIpLocation } from './fraud-check-ip-location/fraud-check-ip-location'
import { FraudCheckPhone } from './fraud-check-phone/fraud-check-phone'
import {
  IApplicantFraudData,
  SardineCheckType,
} from '../../model/applicant-fraud-check.model'
import { CheckResult } from '../../model/applicant-response.model'

export type SardineCheckResultType = 'fail' | 'unchecked' | 'pass'

const sardinePortalSectionMap = {
  otherRule: 'executedRuleSection',
  deviceRule: 'executedDeviceRuleSection',
}

type Props = {
  fraudData: IApplicantFraudData
  checkResults: CheckResult[]
  email: string | null
  phoneNumber: string | null
  deviceSardineUrl?: string | null
}

export function ApplicantFraudCheckResult({
  fraudData,
  checkResults,
  email,
  phoneNumber,
  deviceSardineUrl,
}: Props) {
  const containerCss =
    'border border-solid border-tertiary-grey-200 rounded-1.5 p-[30px]'

  const sardineChecksMap = useMemo<
    Record<SardineCheckType, SardineCheckResultType | null>
  >(() => {
    const res = {} as Record<SardineCheckType, SardineCheckResultType | null>
    Object.entries(sardineChecksRegex).forEach(([key, regex]) => {
      const findRegMatch: CheckResult | undefined = checkResults.find(
        (checkResult: CheckResult) => {
          const { type } = checkResult
          return regex.test(type)
        },
      )
      // @ts-ignore
      res[key] = findRegMatch?.result.type.toLowerCase()
    })
    return res
  }, [checkResults])

  const [params] = useSearchParams()
  const scrollRef = useRef<HTMLDivElement>(null)
  const sectionFromRoute = params.get('section')

  useEffect(() => {
    const sectionFromRoute = params.get('section')

    if (sectionFromRoute) {
      const target = scrollRef.current

      if (!target) return

      target.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }, [params])

  const getSardineUrlWithSection = useCallback(
    (isDevice = false) => {
      if (deviceSardineUrl?.includes('?'))
        return `${deviceSardineUrl}&scrollTo=${
          isDevice
            ? sardinePortalSectionMap.deviceRule
            : sardinePortalSectionMap.otherRule
        }`
      return deviceSardineUrl ?? null
    },
    [deviceSardineUrl],
  )

  return (
    <div className="flex flex-col gap-5">
      {fraudData.email && sardineChecksMap.email && (
        <div
          className={containerCss}
          ref={sectionFromRoute === 'email' ? scrollRef : undefined}
        >
          <FraudCheckEmail
            checkResult={sardineChecksMap.email}
            rules={fraudData.rules}
            data={fraudData.email}
            email={email}
            deviceSardineUrl={getSardineUrlWithSection()}
          />
        </div>
      )}
      {fraudData.phone && sardineChecksMap.phone && (
        <div
          className={containerCss}
          ref={sectionFromRoute === 'phone' ? scrollRef : undefined}
        >
          <FraudCheckPhone
            checkResult={sardineChecksMap.phone}
            data={fraudData.phone}
            number={phoneNumber}
            rules={fraudData.rules}
            deviceSardineUrl={getSardineUrlWithSection()}
          />
        </div>
      )}
      {fraudData.device && sardineChecksMap.device && (
        <div
          className={containerCss}
          ref={sectionFromRoute === 'device' ? scrollRef : undefined}
        >
          <FraudCheckDevice
            checkResult={sardineChecksMap.device}
            data={fraudData.device}
            rules={fraudData.rules}
            deviceSardineUrl={getSardineUrlWithSection(true)}
          />
        </div>
      )}
      {fraudData.iplocation && sardineChecksMap.iplocation && (
        <div
          className={containerCss}
          ref={sectionFromRoute === 'iplocation' ? scrollRef : undefined}
        >
          <FraudCheckIpLocation
            checkResult={sardineChecksMap.iplocation}
            data={fraudData.iplocation}
            rules={fraudData.rules}
            deviceSardineUrl={getSardineUrlWithSection()}
          />
        </div>
      )}
    </div>
  )
}
