import { useQuery } from '@tanstack/react-query'

import { applicantApi } from '../../api/applicant.api'
import { ApplicantResponse } from '../../model/applicant.model'

export const ApplicantQueryKey = ['applicant']

type Args = {
  entityId?: string
}

export const useApplicantQuery = ({ entityId }: Args) =>
  useQuery<ApplicantResponse>({
    enabled: !!entityId,
    queryKey: [ApplicantQueryKey, entityId],
    queryFn: async () => {
      const { data } = await applicantApi.getApplicant(entityId!)

      return data
    },
  })
