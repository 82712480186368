import {
  IIndividualProfileInputs,
  IndividualProfileInputTypes,
  individualProfileDefaultValues,
  documentDefaultValue,
  cardValue,
} from 'features/individual-profile'

import {
  AddressCategoryTypes,
  ApplicantResponse,
  BlocklistAttributeTypes,
} from 'entities/applicant'
import { DocumentIdTypes, KycDocumentCategoryTypes } from 'entities/document'

import { DateFormatTypes, formatDate } from 'shared/date-time'

import {
  BlacklistedFormInputs,
  BlocklistedFormDefaultValue,
  BlocklistedInputTypes,
} from './applicant-blocklisted-form.model'

// eslint-disable-next-line complexity
export const mapDataToForm = (
  applicantResponseData: ApplicantResponse,
): {
  formData: IIndividualProfileInputs
  blocklistFormData: BlacklistedFormInputs
} => {
  const blocklistAttribute =
    applicantResponseData.checkSummary.issues.BLOCKLIST_ATTRIBUTE
  let formData = individualProfileDefaultValues
  let blocklistFormData = BlocklistedFormDefaultValue

  switch (blocklistAttribute) {
    case BlocklistAttributeTypes.EMAIL_ADDRESS: {
      blocklistFormData = {
        ...BlocklistedFormDefaultValue,
        [BlocklistedInputTypes.Email]:
          applicantResponseData.applicantDetails.email.idNumber ?? '',
      }
      break
    }
    case BlocklistAttributeTypes.ID_DOCUMENT: {
      formData = {
        ...individualProfileDefaultValues,
        [IndividualProfileInputTypes.Document]: applicantResponseData.documents
          .length
          ? // eslint-disable-next-line complexity
            applicantResponseData.documents
              .filter(item => item.idType !== 'REPORT')
              // eslint-disable-next-line complexity
              .map(document => ({
                [IndividualProfileInputTypes.NationalId]:
                  document.extraData?.national_id ?? null,
                [IndividualProfileInputTypes.Passport]: {
                  [IndividualProfileInputTypes.IdExpiry]:
                    document.idExpiry &&
                    document.idType === DocumentIdTypes.PASSPORT
                      ? document.idExpiry
                      : '',
                },
                [IndividualProfileInputTypes.PaternalFamilyName]:
                  document.extraData?.paternal_familyname ?? null,
                [IndividualProfileInputTypes.MaternalFamilyName]:
                  document.extraData?.maternal_familyname ?? null,
                [IndividualProfileInputTypes.Gender]:
                  document.extraData?.gender ?? null,
                [IndividualProfileInputTypes.LaserCode]:
                  document.extraData?.laser_code ?? null,
                [IndividualProfileInputTypes.Country]: document.country ?? '',
                [IndividualProfileInputTypes.IdType]:
                  document.idType as DocumentIdTypes,
                [IndividualProfileInputTypes.DocumentId]: document.documentId,
                [IndividualProfileInputTypes.IdNumber]: document.idNumber ?? '',
                [IndividualProfileInputTypes.DocumentNumber]:
                  document.extraData?.document_number ?? '',
                [IndividualProfileInputTypes.State]: document.region ?? '',
                [IndividualProfileInputTypes.DocumentCategory]: document
                  .extraData?.kyc_document_category as KycDocumentCategoryTypes,

                [IndividualProfileInputTypes.Scan]: {
                  [IndividualProfileInputTypes.UploadedUUID]:
                    null as unknown as string,
                  [IndividualProfileInputTypes.FileType]:
                    document.scans[0]?.mimeType ?? '',
                },

                [IndividualProfileInputTypes.BirthCertificate]: {
                  [IndividualProfileInputTypes.RegistrationDate]:
                    document.extraData?.registration_date ?? '',

                  [IndividualProfileInputTypes.RegistrationName]:
                    document.extraData?.registered_name_first_given ?? '',
                  [IndividualProfileInputTypes.OtherName]:
                    document.extraData?.registered_name_other_given ?? '',
                  [IndividualProfileInputTypes.FamilyName]:
                    document.extraData?.registered_name_family ?? '',
                  [IndividualProfileInputTypes.RegistrationDistrict]:
                    document.extraData?.registration_district ?? null,
                  [IndividualProfileInputTypes.CertificateNumber]:
                    document.extraData?.certificate_number ?? null,
                  [IndividualProfileInputTypes.DateOfPrint]:
                    document.extraData?.certificate_date_of_print ?? null,
                },
                [IndividualProfileInputTypes.MedicareCard]: {
                  [IndividualProfileInputTypes.IdSubType]:
                    document.idSubType ?? '',
                  [IndividualProfileInputTypes.Reference]:
                    document.extraData?.reference ?? '',
                  [IndividualProfileInputTypes.IdExpiry]:
                    document.idExpiry && document.idSubType === cardValue.green
                      ? formatDate(
                          document.idExpiry,
                          DateFormatTypes.MonthYearNumber,
                        )
                      : document.idExpiry ?? '',
                  [IndividualProfileInputTypes.MiddleNameDisplay]:
                    document.extraData?.display_middle_name ?? '',
                  [IndividualProfileInputTypes.DisplayNameLine]:
                    document.extraData?.display_name_line1 ?? '',
                  [IndividualProfileInputTypes.Name]: '',
                },
                [IndividualProfileInputTypes.CitizenShipCertificate]: {
                  [IndividualProfileInputTypes.AcquisitionDate]:
                    document.extraData?.acquisition_date ?? '',
                  [IndividualProfileInputTypes.PrimaryCountry]:
                    document.extraData?.primary_country ?? '',
                  [IndividualProfileInputTypes.SecondaryCountry]:
                    document.extraData?.secondary_country ?? '',
                },
                [IndividualProfileInputTypes.ChangeNameCertificate]: {
                  [IndividualProfileInputTypes.RegistrationName]:
                    document.extraData?.registered_name_first_given ?? '',
                  [IndividualProfileInputTypes.OtherName]:
                    document.extraData?.registered_name_other_given ?? '',
                  [IndividualProfileInputTypes.FamilyName]:
                    document.extraData?.registered_name_family ?? '',
                },
                [IndividualProfileInputTypes.MarriageCertificate]: {
                  [IndividualProfileInputTypes.MarriageDate]:
                    document.extraData?.marriage_date ?? '',
                  [IndividualProfileInputTypes.Bride]: {
                    [IndividualProfileInputTypes.FirstName]:
                      document.extraData?.bride_name_first_given ?? '',
                    [IndividualProfileInputTypes.OtherName]:
                      document.extraData?.bride_name_other_given ?? '',
                    [IndividualProfileInputTypes.FamilyName]:
                      document.extraData?.bride_name_family ?? '',
                  },
                  [IndividualProfileInputTypes.Groom]: {
                    [IndividualProfileInputTypes.FirstName]:
                      document.extraData?.groom_name_first_given ?? '',
                    [IndividualProfileInputTypes.OtherName]:
                      document.extraData?.groom_name_other_given ?? '',
                    [IndividualProfileInputTypes.FamilyName]:
                      document.extraData?.groom_name_family ?? '',
                  },
                },
                [IndividualProfileInputTypes.TaxId]: {
                  [IndividualProfileInputTypes.IdSubType]:
                    document.idSubType ?? '',
                },
                [IndividualProfileInputTypes.Other]: {
                  [IndividualProfileInputTypes.Description]:
                    document.idNumber ?? '',
                },
              }))
          : [{ ...documentDefaultValue }],
      }
      break
    }
    case BlocklistAttributeTypes.NAME_AND_DOB: {
      formData = {
        ...individualProfileDefaultValues,
        [IndividualProfileInputTypes.PersonalInfo]: {
          ...individualProfileDefaultValues[
            IndividualProfileInputTypes.PersonalInfo
          ],
          [IndividualProfileInputTypes.FirstName]:
            applicantResponseData.applicantDetails.name.givenName ?? '',
          [IndividualProfileInputTypes.MiddleName]:
            applicantResponseData.applicantDetails.name.middleName ?? '',
          [IndividualProfileInputTypes.LastName]:
            applicantResponseData.applicantDetails.name.familyName ?? '',
          dob: applicantResponseData.applicantDetails.dateOfBirth ?? '',
        },
      }
      break
    }
    // case BlocklistAttributeTypes.ENTIRE_ENTITY: {
    //   return undefined
    // }
    case BlocklistAttributeTypes.RESIDENTIAL_ADDRESS:
    case BlocklistAttributeTypes.MAILING_ADDRESS: {
      const addressType =
        applicantResponseData.applicantDetails.addresses?.map(
          address => address.addressType,
        ) ?? ''
      if (addressType.includes(AddressCategoryTypes.mailing)) {
        const address = applicantResponseData.applicantDetails.addresses?.find(
          address => address.addressType === AddressCategoryTypes.mailing,
        )
        if (!address) {
          formData = individualProfileDefaultValues
          break
        }

        formData = {
          ...individualProfileDefaultValues,
          [IndividualProfileInputTypes.MailingAddress]: {
            ...individualProfileDefaultValues.mailingAddress,
            [IndividualProfileInputTypes.Country]: address.country ?? '',
            [IndividualProfileInputTypes.Search]: address.longForm ?? '',
            [IndividualProfileInputTypes.ManualAddress]: {
              ...individualProfileDefaultValues.mailingAddress.manualAddress,
              [IndividualProfileInputTypes.PropertyName]:
                address.buildingName ?? '',
              [IndividualProfileInputTypes.UnitNumber]:
                address.unitNumber ?? '',
              [IndividualProfileInputTypes.StreetNumber]:
                address.streetNumber ?? '',
              [IndividualProfileInputTypes.StreetName]:
                address.streetName ?? '',
              [IndividualProfileInputTypes.StreetType]:
                address.streetType ?? '',
              [IndividualProfileInputTypes.Suburb]: address.suburb ?? '',
              [IndividualProfileInputTypes.State]: address.state ?? '',
              [IndividualProfileInputTypes.Town]: address.town ?? '',
              [IndividualProfileInputTypes.PostalCode]:
                address.postalCode ?? '',
            },
          },
        }
        break
      }

      const address = applicantResponseData.applicantDetails.addresses?.find(
        address => address.addressType === AddressCategoryTypes.current,
      )
      if (!address) {
        formData = individualProfileDefaultValues
        break
      }

      formData = {
        ...individualProfileDefaultValues,
        [IndividualProfileInputTypes.CurrentResidentialAddress]: {
          ...individualProfileDefaultValues.currentResidentialAddress,
          [IndividualProfileInputTypes.Country]: address.country ?? '',
          [IndividualProfileInputTypes.Search]: address.longForm ?? '',
          [IndividualProfileInputTypes.ManualAddress]: {
            ...individualProfileDefaultValues.currentResidentialAddress
              .manualAddress,
            [IndividualProfileInputTypes.PropertyName]:
              address.buildingName ?? '',
            [IndividualProfileInputTypes.UnitNumber]: address.unitNumber ?? '',
            [IndividualProfileInputTypes.StreetNumber]:
              address.streetNumber ?? '',
            [IndividualProfileInputTypes.StreetName]: address.streetName ?? '',
            [IndividualProfileInputTypes.StreetType]: address.streetType ?? '',
            [IndividualProfileInputTypes.Suburb]: address.suburb ?? '',
            [IndividualProfileInputTypes.State]: address.state ?? '',
            [IndividualProfileInputTypes.Town]: address.town ?? '',
            [IndividualProfileInputTypes.PostalCode]: address.postalCode ?? '',
          },
        },
      }
      break
    }
    case BlocklistAttributeTypes.PHONE_NUMBER: {
      blocklistFormData = {
        ...BlocklistedFormDefaultValue,
        [BlocklistedInputTypes.PhoneNumber]:
          applicantResponseData.applicantDetails.phoneNumber.idNumber ?? '',
      }
      break
    }
    default:
      break
  }
  blocklistFormData = {
    ...blocklistFormData,
    [BlocklistedInputTypes.BlocklistReason]:
      applicantResponseData.checkSummary.issues.MANUAL_BLOCKLIST ?? '',
    [BlocklistedInputTypes.BlocklistType]:
      applicantResponseData.checkSummary.issues.BLOCKLIST_ATTRIBUTE ?? '',
  }

  return { blocklistFormData, formData }
}
