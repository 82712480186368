export const applicantAuditReportEn = {
  downloadReport: 'Download Audit Report (PDF)',
  auditDownloadSuccess: 'Audit report downloaded',
  auditReport: 'Audit Report',
  allActivityRecord:
    'Record of all activity and status changes on the profile.',
  requestId: 'Request ID',
  requestedBy: 'Requested by',
  channel: 'Channel',
  commented: 'Commented',

  records: {
    fetching: 'Fetching Records...',
    empty: 'No trail yet',
  },

  header: {
    user: 'User',
    action: 'Action',
    resultAndComments: 'Result / Comment',
    date: 'Date',
    time: 'Time',
  },
}
