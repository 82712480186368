import {
  SpecialEntityStateBanner,
  BlocklistBanner,
  BlocklistOrDuplicateMatchBanner,
  WatchlistBanner,
} from './banner-mapping'

export const BannerMapping = {
  SpecialEntityStateBanner,
  BlocklistBanner,
  BlocklistOrDuplicateMatchBanner,
  WatchlistBanner,
}

export type BannerMappingType = keyof typeof BannerMapping
