import React, { useMemo } from 'react'

import { FrankieIcon } from 'frankify/src'

import { ApplicantId } from 'entities/applicant'

import { useI18n } from 'shared/i18n'

import { IKYB_AML_SCREENING_KEY } from '../../../../ikyb-aml-screening.key'
import { iKybAmlScreeningEn } from '../../../../locale/ikyb-aml-screening.en'
import { amlScreeningDateFormat } from '../../../../model/ikyb-aml-screening.model'
import { iKybAmlAdverseMediaQa } from '../../../../qa/ikyb-aml-screening.qa'
import { useIKybAmlScreeningQuery } from '../../../../state/ikyb-aml-screening-query/ikyb-aml-screening.query'

type Props = {
  applicantId: ApplicantId
  testId?: { container?: string }
  matchIdx: number
}

export function IKybAmlAdverseMedia({ applicantId, matchIdx, testId }: Props) {
  const t = useI18n(IKYB_AML_SCREENING_KEY, {
    keys: iKybAmlScreeningEn,
  })

  const { data } = useIKybAmlScreeningQuery({ applicantId })

  const { mediaArticles } = useMemo(
    () => ({
      mediaMatches: data?.matches[matchIdx].details.media.mediaMatches ?? [],
      mediaArticles: data?.matches[matchIdx].details.media.mediaArticles ?? [],
    }),
    [data?.matches, matchIdx],
  )

  if (!mediaArticles.length) {
    return (
      <div data-qa={testId?.container} className="p-3 text-secondary-900">
        {t('empty.adverseMedia')}
      </div>
    )
  }

  return (
    <div data-qa={testId?.container} className="flex flex-col">
      {mediaArticles.map(articles =>
        articles.map(({ date, title, url, snippet }) => (
          <div
            data-qa={iKybAmlAdverseMediaQa.mediaArticle}
            className="flex gap-6 p-5 border-b border-tertiary-grey-200"
            key={title}
          >
            <FrankieIcon
              name="mdiCardAccountDetailsOutline"
              size="sm"
              className="text-tertiary-grey-400"
            />
            <div className="">
              <a
                className="text-primary-800 font-semibold leading-none"
                href={url}
                target="_blank"
                rel="noreferrer"
              >
                {title}
              </a>
              <div className="text-xs text-tertiary-grey-500 leading-none">
                {`${t('published')} ${amlScreeningDateFormat(date)}`}
              </div>
              <div className="pt-4 text-tertiary-grey-700">{snippet}</div>
            </div>
          </div>
        )),
      )}
    </div>
  )
}
