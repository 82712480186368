import React, { useCallback, useEffect } from 'react'

import { DataGridPro, gridClasses } from '@mui/x-data-grid-pro'
import { Controller, UseFormReturn } from 'react-hook-form'

import { FrankieButton, FrankieIcon, FrankieLoader } from 'frankify/src'

import { useI18n } from 'shared/i18n'

import { APPLICANT_SUPPORTING_DOCUMENTS_KEY } from '../../../applicant-supporting-documents.key'
import { applicantSupportingDocumentsEn } from '../../../locale/applicant-supporting-documents.en'
import { BusinessDocumentCatalogRowType } from '../../../model/applicant-supporting-documents.model'
import { getColumns } from '../../../model/organisation-documents-catalog.model'
import { useBusinessDocumentPurchaseMutation } from '../../../state/business-documents-purchase-mutation/business-documents-purchase-mutation'

type Props = {
  rows: BusinessDocumentCatalogRowType[]
  form: UseFormReturn<{ docs: string[]; failedRetryId: string }>
  close: () => void
  applicantId: string
}
export function PurchaseDocuments({ rows, form, close, applicantId }: Props) {
  const { control, handleSubmit, setValue, getValues, reset } = form

  const t = useI18n([APPLICANT_SUPPORTING_DOCUMENTS_KEY], {
    keys: applicantSupportingDocumentsEn,
  })

  const { mutate, isSuccess, isLoading } = useBusinessDocumentPurchaseMutation({
    applicantId,
    t,
  })

  const remove = (rowId: string) => {
    const values = getValues('docs')
    setValue(
      'docs',
      values.filter(i => i !== rowId),
    )
  }

  const columns = useCallback(
    (length: number) => {
      const defaultColumns = getColumns(t)
      defaultColumns.pop()
      defaultColumns.pop()
      defaultColumns.push({
        field: 'action',
        headerName: '',
        minWidth: 80,
        sortable: false,
        renderCell: ({ id }) => (
          <FrankieButton
            intent="subtle"
            disabled={length <= 1}
            className="text-tertiary-red-600 mr-2"
            onClick={() => remove(id as string)}
          >
            {t('remove')}
          </FrankieButton>
        ),
      })
      return defaultColumns
    },
    [t],
  )

  const submit = ({ docs }: { docs: string[] }) => {
    const payloadRows = rows.filter(row => docs.includes(row.id))

    mutate({ applicantId, selectedReports: payloadRows })
  }

  useEffect(() => {
    if (isSuccess) {
      close()
      reset()
    }
  }, [isSuccess, reset, close])

  return (
    <FrankieLoader
      className="rounded-md overflow-hidden p-6"
      loading={isLoading}
      label={t('documentCatalog.purchasing')}
    >
      <form onSubmit={handleSubmit(submit)} className="bg-mono-white">
        <div className="flex justify-between">
          <div className="text-xl font-bold ">
            {t('documentCatalog.purchaseDocumentTitle')}
          </div>
          <FrankieButton
            noStyles
            onClick={close}
            className="flex-grow-0 mt-[-30px] mr-[-15px]"
          >
            <FrankieIcon
              name="mdiClose"
              className="text-tertiary-grey-400 hover:text-tertiary-grey-800"
            />
          </FrankieButton>
        </div>
        <Controller
          control={control}
          name="docs"
          rules={{
            validate: value => value.length >= 1 || 'Need At Least One',
          }}
          render={({ field: { value } }) => (
            <>
              <div className="text-tertiary-grey-700 py-4">
                {t('documentCatalog.selectedForPurchase', {
                  number: value.length,
                })}
              </div>

              <DataGridPro
                rows={rows.filter(row => value.includes(row.id))}
                columns={columns(value.length)}
                sx={{
                  [`&.${gridClasses.root}`]: {
                    border: 'none',
                  },
                  '& .MuiDataGrid-virtualScroller': {
                    maxHeight: '320px',
                    overflowY: 'auto !important',
                    overflowX: 'hidden',
                  },
                  '.MuiDataGrid-columnSeparator': {
                    display: 'none',
                  },
                  '& .MuiDataGrid-columnHeaderTitleContainerContent': {
                    height: '100%',
                  },

                  '& .MuiDataGrid-detailPanelToggleCell': {
                    opacity: 0.7,
                  },
                  "& .MuiDataGrid-detailPanelToggleCell[aria-label='Expand']": {
                    transform: 'rotate(-90deg)',
                  },
                  '& .MuiDataGrid-row.Mui-hovered, .MuiDataGrid-row:hover': {
                    backgroundColor: 'transparent',
                  },
                  '& .MuiDataGrid-row.Mui-selected': {
                    bgcolor: 'transparent',
                  },
                }}
                className="[&_.MuiDataGrid-virtualScrollerContent]:border-neutral-30 [&_.MuiDataGrid-columnHeadersInner]:bg-tertiary-grey-50 [&_.MuiDataGrid-columnHeaderTitle]:text-xs  [&_.MuiDataGrid-columnHeaderTitle]:font-medium [&_.MuiDataGrid-columnHeadersInner]:text-tertiary-grey-500  [&_.MuiDataGrid-columnHeader]:!outline-none"
                getCellClassName={() =>
                  '!outline-none !border-neutral-30 !items-start !py-4  break-words'
                }
                getRowClassName={() => ' hover:!bg-transparent !min-h-[60px]'}
                isCellEditable={() => false}
                getRowHeight={() => 'auto'}
                columnHeaderHeight={40}
                disableRowSelectionOnClick
                disableColumnSelector
                disableColumnFilter
                disableColumnMenu
                disableDensitySelector
                showCellVerticalBorder={false}
                showColumnVerticalBorder={false}
                hideFooterPagination
                disableColumnResize
                disableColumnReorder
                disableVirtualization
                disableColumnPinning
                hideFooter
                hideFooterSelectedRowCount
                autoHeight
                autoPageSize
              />
            </>
          )}
        />
        <div className="flex gap-4 items-center p-2 pt-4  justify-end">
          <FrankieButton onClick={close} intent="secondary">
            {t('cancel')}
          </FrankieButton>
          <FrankieButton type="submit" disabled={isLoading}>
            {t('documentCatalog.purchaseDocument')}
          </FrankieButton>
        </div>
      </form>
    </FrankieLoader>
  )
}
