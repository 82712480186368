// from VUE portal
export function downloadBlobAsFile(
  data: BlobPart,
  config: { type: string; fileName: string },
) {
  try {
    const { type, fileName } = config

    // Create a Blob with the CSV content
    const blob = new Blob([data], { type })

    // Create a download link
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.download = fileName

    // Append the link to the document and trigger the click event
    document.body.appendChild(link)
    link.click()

    // Remove the link from the document
    document.body.removeChild(link)
  } catch (err) {
    throw new Error('Error getting the report')
  }
}
