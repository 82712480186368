import React, { useMemo } from 'react'

import MuiPagination, {
  PaginationProps as MuiPaginationProps,
} from '@mui/material/Pagination'

import { useI18n } from '../../../i18n'
import { DATA_GRID_KEY, dataGridEn } from '../../locale/data-grid.en'

type PaginationProps = MuiPaginationProps & {
  pageSize?: number
  totalCount: number
  showCount?: boolean
}

// eslint-disable-next-line complexity
export function Pagination({
  shape = 'rounded',
  variant = 'outlined',
  pageSize = 0,
  totalCount = 0,
  showCount = true,
  ...rest
}: PaginationProps) {
  const t = useI18n(DATA_GRID_KEY, { keys: dataGridEn })

  const currentRange = useMemo(
    () =>
      rest.page && rest.page > 1 && pageSize
        ? rest.page * pageSize + 1 - pageSize
        : 1,
    [pageSize, rest.page],
  )

  const size = useMemo(() => {
    if (pageSize > totalCount) {
      return totalCount
    }

    if (rest.page && pageSize * rest.page > totalCount) {
      return totalCount
    }

    return pageSize && rest.page ? pageSize * rest.page : 0
  }, [pageSize, rest.page, totalCount])

  if (!rest.count) return null

  return (
    <div className="flex-col justify-center items-center my-3">
      <div>
        <MuiPagination
          variant={variant}
          shape={shape}
          {...rest}
          sx={{
            '& .MuiPagination-ul': {
              flexWrap: 'nowrap',
              justifyContent: 'center',
              borderRadius: '4px',
              border: 'solid 1px #E5E7EB',
              height: '33px',

              '& > li': {
                width: 'auto',
                borderRight: 'solid 1px #E5E7EB',
                height: '100%',
              },
              '& > li:last-child': {
                borderRight: 'none',
              },
              '& .MuiButtonBase-root': {
                margin: '0',
              },
              '& .Mui-disabled': {
                display: 'none',
              },
              '& .MuiPaginationItem-rounded': {
                borderRadius: '0px',
                borderStyle: 'none',
              },
            },
          }}
          className="[&_.MuiPaginationItem-root]:text-tertiary-grey-500 [&_.Mui-selected]:bg-primary-50 [&_.Mui-selected]:!text-primary-800 font-semibold [&_.MuiButtonBase-root]:border-tertiary-grey-200"
        />
      </div>
      {showCount && (
        <div className="text-tertiary-grey-600 mt-3 text-center">
          {t('showing', {
            count: `${currentRange}-${size}`,
            totalCount,
          })}
        </div>
      )}
    </div>
  )
}
