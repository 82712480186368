import { useMutation } from '@tanstack/react-query'

import { CustomerChildId } from 'entities/organisation'
import { useSessionQuery, useSessionMutation } from 'entities/session'

import { accountChangeApi } from '../api/account-change.api'

export const useViewAsChildMutation = () => {
  const { data: session } = useSessionQuery()
  const { mutateAsync: updateSession } = useSessionMutation()

  return useMutation({
    mutationFn: async ({
      customerChildId,
    }: {
      customerChildId: CustomerChildId
    }) => accountChangeApi.viewAsChild(customerChildId),
    onSuccess: async res => {
      const newPageData = res.data

      if (!session) {
        // eslint-disable-next-line no-console
        console.error("Session doesn't exist")
        return
      }

      await updateSession({ sessionData: newPageData })
    },
  })
}
