import { IRole, IPermission, RoleId } from 'entities/role'

import { bffClient, IClient } from 'shared/client'

import { IRoleConfig, IRoleNotSerialized } from '../model/role-management.model'

export type ApiFrankieType = 'f1' | 'f2'

export class RoleManagementApi {
  client: IClient

  constructor(client: IClient) {
    this.client = client
  }

  async getPermissionList() {
    return this.client.get<IPermission[]>('/data/v1/action/list')
  }

  async getRoleList(version: ApiFrankieType) {
    const versionToUrl = {
      f1: '/data/v1',
      f2: '/f2/v1',
    }
    return this.client.get<IRole[]>(`${versionToUrl[version]}/roles`)
  }

  async createRole(config: IRoleConfig) {
    return this.client.post<IRoleNotSerialized, IRoleConfig>(
      '/data/v1/roles',
      config,
    )
  }

  async getRole(id: RoleId) {
    return this.client.get<IRole>(`/data/v1/roles/${id}`)
  }

  async editRole(id: RoleId, config: Partial<IRoleConfig>) {
    return this.client.put<IRole, Partial<IRoleConfig>>(
      `/data/v1/roles/${id}`,
      config,
    )
  }

  async deleteRole(id: RoleId) {
    return this.client.delete(`/data/v1/roles/${id}`)
  }
}

export const roleManagementApi = new RoleManagementApi(bffClient)
