import { useMemo } from 'react'

import {
  useGetWorkflowEventsData,
  ProcessResultSystemStatusEnum,
  getWorkflowStatusKey,
  WorkflowStatusKeysTypes,
} from 'entities/entity'

type Args = {
  entityId?: string
}

export const useInvalidationBanner = ({ entityId }: Args) => {
  const { data, isLoading } = useGetWorkflowEventsData({ entityId: entityId! })

  const isUnchecked =
    getWorkflowStatusKey(data?.workflowSummaries?.at(0)) ===
    WorkflowStatusKeysTypes.UNCHECKED

  const {
    isInvalidationBannerVisible,
    isPersonalInfoInvalid,
    isDocumentInvalid,
  } = useMemo(() => {
    const workflowProcessResults =
      data?.workflowSummaries
        ?.at(0)
        ?.workflowResultData?.workflowStepResults?.filter(
          item => item.stepName === 'KYC' && item.objectType === 'INDIVIDUAL',
        )
        .flatMap(item => item.processResults) || []

    const isInvalidationBannerVisible = workflowProcessResults.some(
      item =>
        item?.systemStatus === ProcessResultSystemStatusEnum.MARKED_INVALID,
    )

    const personalInfoObjectTypes = ['NAME', 'DATE_OF_BIRTH', 'ADDRESS']

    const isPersonalInfoInvalid = workflowProcessResults.some(
      item =>
        item?.systemStatus === ProcessResultSystemStatusEnum.MARKED_INVALID &&
        item.objectType &&
        personalInfoObjectTypes.includes(item.objectType),
    )

    const isDocumentInvalid = workflowProcessResults.some(
      item =>
        item?.systemStatus === ProcessResultSystemStatusEnum.MARKED_INVALID &&
        item.objectType === 'DOCUMENT',
    )
    return {
      isInvalidationBannerVisible,
      isPersonalInfoInvalid,
      isDocumentInvalid,
    }
  }, [data])

  return {
    isInvalidationBannerVisible,
    isPersonalInfoInvalid: isUnchecked || isPersonalInfoInvalid,
    isDocumentInvalid: isUnchecked || isDocumentInvalid,
    isUnchecked,
    isLoading,
  }
}
