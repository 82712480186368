export const settingsRoleEditEn = {
  cta: {
    goBack: 'Return to role management',
  },
  header: {
    editRole: 'Edit role',
  },
  notification: {
    updated: '{{name}} role has been updated',
  },
}
