import { CUSTOM_VIEWS_KEYS } from '../custom-view.key'

const applyFilterSave = `${CUSTOM_VIEWS_KEYS}-apply-filter-save`
export const applyFilterSaveQa = {
  btn: `${applyFilterSave}-btn`,
}

const customViewCreate = `${CUSTOM_VIEWS_KEYS}-custom-view-create`
export const customViewCreateQa = {
  btn: `${customViewCreate}-btn`,
}

const deleteCustomViewButton = `${CUSTOM_VIEWS_KEYS}-custom-view-delete`
export const deleteCustomViewButtonQa = {
  container: `${deleteCustomViewButton}-container`,
  btn: `${deleteCustomViewButton}-btn`,
}

const customViewDeleteForm = `${CUSTOM_VIEWS_KEYS}-custom-view-delete-form`
export const customViewDeleteFormQa = {
  loaderContainer: `${customViewDeleteForm}-container`,
  loader: `${customViewDeleteForm}-loader`,
  container: `${customViewDeleteForm}-container`,
  alertIcon: `${customViewDeleteForm}-alert-icon`,
  titleContainer: `${customViewDeleteForm}-title-container`,
  cancelBtn: `${customViewDeleteForm}-cancel-btn`,
  deleteBtn: `${customViewDeleteForm}-delete-btn`,
}

const customViewEditCta = `${CUSTOM_VIEWS_KEYS}-custom-view-edit-cta`
export const customViewEditCtaQa = {
  saveChanges: `${customViewEditCta}-save-changes`,
  saveNewChange: `${customViewEditCta}-save-new-change`,
  btn: `${customViewEditCta}-btn`,
}

const customViewLoader = `${CUSTOM_VIEWS_KEYS}-custom-view-loader`
export const customViewLoaderQa = {
  container: `${customViewLoader}-container`,
  loader: `${customViewLoader}-loader`,
}

const customNavLink = `${CUSTOM_VIEWS_KEYS}-custom-nav-link`
export const customNavLinkQa = {
  tooltip: `${customNavLink}-tooltip`,
  link: '${customNavLink}-link',
  icon: `${customNavLink}-icon`,
  customIcon: `${customNavLink}-custom-icon`,
  btn: `${customNavLink}-btn`,
}

const customViewModal = `${CUSTOM_VIEWS_KEYS}-custom-view-modal`
export const customViewModalQa = {
  container: `${customViewModal}-container`,
  titleContainer: `${customViewModal}-title-container`,
  subTitleContainer: `${customViewModal}-sub-title-container`,
  leftCharacterContainer: `${customViewModal}-left-character-container`,
  viewInput: `${customViewModal}-view-input`,
  cancelBtn: `${customViewModal}-cancel-btn`,
  saveBtn: `${customViewModal}-save-btn`,
  saveModalLoader: `${customViewModal}-save-modal-loader`,
}

const customViewNav = `${CUSTOM_VIEWS_KEYS}-custom-view-nav`
export const customViewNavQa = {
  customLogo: `${customViewNav}-custom-logo`,
  logo: `${customViewNav}-logo`,
  navLink: `${customViewNav}-nav-link`,
  toolTip: `${customViewNav}-tool-tip`,
}

const customViewControls = `${CUSTOM_VIEWS_KEYS}-custom-view-controls`
export const customViewControlsQa = {
  container: `${customViewControls}-container`,
}
