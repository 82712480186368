import { useMemo } from 'react'

import { PathArray } from 'shared/typescript'

import { getIsFeatureFlagEnabled, PageData } from '../../model/session.model'
import { useSessionQuery } from '../../state/session/session.query'

/**
 * Hook for managing feature flags
 * @description If the feature is nested, provide keys as an array; otherwise, provide a string.
 * @template T - Type representing the mapping of feature keys to either a single key or an array of nested keys.
 * @param actionMap - An object mapping feature keys to either a single key or an array of nested keys.
 * @returns An object with keys from the input object and boolean values indicating whether the corresponding feature is enabled.
 *
 * @example
 * const { showVerticalNavBar } = useHasFeatureFlag({
 *   showVerticalNavBar: 'verticalNavBar',
 *   hasConSardine: ['connectors', 'con_sardine'],
 * })
 */
export function useHasFeatureFlag<
  T extends {
    [action: string]:
      | keyof PageData['features']
      | PathArray<PageData['features']>
  },
>(actionMap: T): { [Property in keyof T]: boolean } {
  const { data: session } = useSessionQuery()

  return useMemo(() => {
    const result = {} as {
      [Property in keyof T]: boolean
    }

    if (!session?.features) return result

    Object.entries(actionMap).forEach(([key, feature]) => {
      result[key as keyof T] = getIsFeatureFlagEnabled(session, [feature])
    })

    return result
  }, [actionMap, session])
}
