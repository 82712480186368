import { format, isValid, compareAsc } from 'date-fns'

export {
  isBefore,
  minutesToMilliseconds,
  startOfYesterday,
  endOfYesterday,
  startOfToday,
  endOfToday,
  subDays,
  endOfMonth,
  subMonths,
  startOfMonth,
  differenceInDays,
  differenceInYears,
  differenceInSeconds,
  endOfDay,
  startOfDay,
  addDays,
  formatDistanceToNow,
} from 'date-fns'

export enum DateFormatTypes {
  YearOnly = 'yyyy', // 2020
  DateNumbers = 'dd-MM-yyyy', // 20-10-2000
  DateNumbersSlash = 'dd/MM/yyyy', // 20/10/2000
  Time = 'hh:mm aaa', // 01:20 am
  TimeWithSeconds = 'hh:mm:ss aaa', // 01:20:25 am
  Time24HoursWithSeconds = 'HH:mm:ss ', // 13:20:25
  fullDateWithTime = 'dd-MM-yyyy, h:mm a', // 02-10-2023, 2:00 PM
  fullDateWithTimeSmall = 'dd-MM-yyyy, h:mm aaa', // 02-10-2023, 2:00 pm
  FullDateWith24HoursTime = 'dd-MM-yyyy, HH:mm', // 02-10-2023, 14:00
  ShortDate = 'dd MMM yyyy', // 20 Oct 2020
  ShortDateNoDay = 'MMM yyyy', // Oct 2020
  ShortDateAndTime = 'dd MMM yyyy, h:mm a', // 20 Oct 2020, 2:00 PM
  shortDateAndShortTime = 'dd MMM yyyy hh:mm a', // 20 Oct 2020 02:00 PM
  FullMonthDate = 'dd MMMM yyyy', // 20 October 2020
  MonthDateAndTime = 'MMMM do yyyy, h:mm a', // February 29th 2024, 11:25 AM
  MonthDateAndTimeShort = 'd MMM yyyy, h:mm a',
  shortDateWithTime = 'dd MMM yyyy  h:mm a',
  MonthYearNumber = 'MM-yyyy', // 10-2020
  ShortMonthDateYear = 'MMM d, yyyy', // Oct 20, 2024
}

export function isValidDate(date: string) {
  return isValid(new Date(date))
}

export function formatDate(
  date: string | number,
  dateFormat: DateFormatTypes,
): string {
  const parsedDate = new Date(date)
  // Return the date as it is if it's not a valid date
  if (!isValid(parsedDate)) {
    return date.toString()
  }
  return format(parsedDate, dateFormat)
}

export function isYearOnly(date: string, convertToDate = false) {
  const matchDate = date.match(/^\d{4}$/)
  const isYear = !!matchDate
  if (convertToDate) {
    if (isYear) {
      return `${date}-01-01`
    }
    return date
  }
  return isYear
}

export function dateSorter(a: string, b: string) {
  return compareAsc(new Date(b), new Date(a))
}
