import React, { useMemo } from 'react'

import { useInView } from 'react-intersection-observer'
import { Link, useNavigate } from 'react-router-dom'

import { IndividualAmlScreening } from 'features/individual-aml-screening'

import { CommentHOC, CommentsTypes } from 'entities/comments'
import {
  ProcessResultManualStatusEnumAML,
  ProcessResultSystemStatusEnum,
  useFrankie2R2Customer,
  useGetWorkflowEventsData,
} from 'entities/entity'
import { useApplicantRoute } from 'entities/routing'
import {
  useFilterWorkflow,
  useFilterProcessIdsByEventName,
} from 'entities/workflow'

import { useI18n } from 'shared/i18n'
import {
  TrackingEventsTypes,
  trackingManager,
  useTrackingShowEvents,
} from 'shared/tracking'

import { workflowEventsEn } from '../../locale/workflow-events.en'
import { amlScreeningEventsQa } from '../../qa/applicant-workflow-events.qa'
import { useOrderWithFailedAndPassed } from '../../state/get-workflow-events.query'
import { WORKFLOW_EVENTS_KEY } from '../../workflow-events.key'
import {
  getIconConfigAml,
  borderStyleConfigAml,
} from '../entity-verification-helper/entity-verification-helper'

type Props = {
  entityId: string
}

export function AmlScreeningEvents({ entityId }: Props) {
  const { data: workflow } = useGetWorkflowEventsData({ entityId })
  const data = useFilterWorkflow(workflow)
  const processResultIds = useFilterProcessIdsByEventName(['AML'], data)
  const t = useI18n([WORKFLOW_EVENTS_KEY], { keys: workflowEventsEn })
  const navigate = useNavigate()

  const { generateRoute } = useApplicantRoute()
  const isFrankie2 = useFrankie2R2Customer()

  const status = data.workflowSummaries
    .at(0)
    ?.workflowResultData?.workflowStepResults?.find(
      step => step.stepName === 'AML',
    )
    ?.processResults?.filter(
      i =>
        i.result === 'HIT' &&
        i.systemStatus === ProcessResultSystemStatusEnum.VALID,
    )
    ?.map(result => result.manualStatus) as
    | (ProcessResultManualStatusEnumAML | undefined)[]
    | undefined

  const { eventStepper } = useOrderWithFailedAndPassed({ entityId })
  const { ref, inView } = useInView()

  const isAml = useMemo(
    () => eventStepper.some(stepper => stepper.eventName === 'AML'),
    [eventStepper],
  )

  useTrackingShowEvents({
    eventsType: TrackingEventsTypes.WorkflowAmlShowIndividual,
    data: isAml && inView,
  })

  if (!isAml) {
    return null
  }

  const handleWorkflowExecuted = () => {
    navigate(generateRoute({ routeKey: 'applicantProfileWorkflowEvent' }))
  }

  if (!isFrankie2) return null

  return (
    <CommentHOC
      entityId={entityId}
      processId={processResultIds}
      commentType={CommentsTypes.AML}
    >
      <div
        id="AML"
        ref={ref}
        data-qa={amlScreeningEventsQa.container}
        className={`border rounded-1.5 flex flex-col gap-4 pt-5 px-6 pb-6 ${borderStyleConfigAml(
          status,
        )}`}
      >
        <div className="flex items-center justify-between">
          <h1
            data-qa={amlScreeningEventsQa.title}
            className="text-xl text-tertiary-grey-800 gap-2 font-bold flex items-center"
          >
            {getIconConfigAml(status)}
            {t('amlScreeningTitle')}
          </h1>
          <Link
            to={generateRoute({
              routeKey: 'individualAmlHistory',
              overrideParams: { entityId },
            })}
            className="text-primary-800 text-sm"
            onClick={() => {
              trackingManager.trackWithProps(
                TrackingEventsTypes.AmlHistoryClickIndividual,
                {
                  entityId,
                  status,
                },
              )
            }}
            data-qa={amlScreeningEventsQa.historyRouteBtn}
          >
            {t('amlViewHistoryBtn')}
          </Link>
        </div>
        {workflow && (
          <IndividualAmlScreening
            workflowName={
              data.workflowSummaries.at(0)?.workflowResultData?.workflowName
            }
            workflowExecutionId={
              data.workflowSummaries.at(0)?.workflowExecutionId
            }
            entityId={entityId}
            onWorkflowExecuted={handleWorkflowExecuted}
          />
        )}
      </div>
    </CommentHOC>
  )
}
