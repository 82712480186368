import { WORKFLOW_EVENTS_KEY as WEK } from '../workflow-events.key'

const applicantWorkflow = 'applicant-workflow'
export const applicantWorkflowEventsQa = {
  container: `${WEK}-${applicantWorkflow}-container`,
  workflowName: `${WEK}-${applicantWorkflow}-workflowName`,
  attempt: `${WEK}-${applicantWorkflow}-attempt`,
  eventId: `${WEK}-${applicantWorkflow}-eventId`,
  createdAt: `${WEK}-${applicantWorkflow}-createdAt`,
  riskWrapper: `${WEK}-${applicantWorkflow}-risk-wrapper`,
  riskScoreCTA: `${WEK}-${applicantWorkflow}-risk-score-cta`,
  verifyEntityCta: `${WEK}-${applicantWorkflow}-verify-entity-cta`,
  statusChangeMenu: `${WEK}-${applicantWorkflow}-status-change-menu`,
  needAttentionItem: `${WEK}-${applicantWorkflow}-need-attention-item`,
  manuallyFailedItem: `${WEK}-${applicantWorkflow}-manually-failed-item`,
  manuallyPassedItem: `${WEK}-${applicantWorkflow}-manually-passed-item`,
} satisfies Record<
  string,
  `${typeof WEK}-${typeof applicantWorkflow}-${string}`
>

const entityVerification = 'entity-verification'
export const entityVerificationEventsQa = {
  container: `${WEK}-${entityVerification}-entity-verification-container`,
  eventStepperWrapper: `${WEK}-${entityVerification}-eventStepperWrapper`,
  verificationHeaderSectionWrapper: `${WEK}-${entityVerification}-verificationHeaderWrapper`,
  navigateToEventName: `${WEK}-${entityVerification}-navigateToEventName`,
} satisfies Record<
  string,
  `${typeof WEK}-${typeof entityVerification}-${string}`
>

const personalInfoSection = 'personal-info-section'
export const personalInfoSectionQa = {
  container: `${WEK}-${personalInfoSection}-container`,
  header: `${WEK}-${personalInfoSection}-header`,
  sourceHeader: `${WEK}-${personalInfoSection}-source-header`,
} satisfies Record<
  string,
  `${typeof WEK}-${typeof personalInfoSection}-${string}`
>

const documentCard = 'document-card'
export const verificationDocumentCardQa = {
  container: `${WEK}-${documentCard}-container`,
  documentLabel: `${WEK}-${documentCard}-label`,
  documentView: `${WEK}-${documentCard}-view`,
} satisfies Record<string, `${typeof WEK}-${typeof documentCard}-${string}`>

const verificationHeader = 'verification-header'
export const verificationHeaderQa = {
  container: `${WEK}-${verificationHeader}-container`,
  verificationEventTextTitle: `${WEK}-${verificationHeader}-title`,
  verificationEventTextBadgeText: `${WEK}-${verificationHeader}-badgeText`,
  verificationEventTextCta: `${WEK}-${verificationHeader}-cta`,
} satisfies Record<
  string,
  `${typeof WEK}-${typeof verificationHeader}-${string}`
>

const iconConfig = 'icon-config'
export const IconConfigQa = {
  failVariant: `${WEK}-${iconConfig}-failVariant`,
  passVariants: `${WEK}-${iconConfig}-passVariants`,
  reviewVariants: `${WEK}-${iconConfig}-reviewVariants`,
  unCheck: `${WEK}-${iconConfig}-unCheck`,
} satisfies Record<string, `${typeof WEK}-${typeof iconConfig}-${string}`>

const sourceMatch = 'source-match'
export const sourceMatchQa = {
  noMatch: `${WEK}-${sourceMatch}-noMatch`,
  container: `${WEK}-${sourceMatch}-container`,
  tooltipTitle: `${WEK}-${sourceMatch}-tooltipTitle`,
  restResultsBadge: `${WEK}-${sourceMatch}-restResultsBadge`,
  matchBadge: `${WEK}-${sourceMatch}-matchBadge`,
} satisfies Record<string, `${typeof WEK}-${typeof sourceMatch}-${string}`>

const labelAndSources = 'label-and-sources'
export const labelAndSourcesQa = {
  container: `${WEK}-${labelAndSources}-container`,
  label: `${WEK}-${labelAndSources}-label`,
  value: `${WEK}-${labelAndSources}-value`,
  sourceWrapper: `${WEK}-${labelAndSources}-source-wrapper`,
} satisfies Record<string, `${typeof WEK}-${typeof labelAndSources}-${string}`>

const riskLevel = 'risk-level'
export const riskLevelQa = {
  container: `${WEK}-${riskLevel}-container`,
  riskLevelContainer: `${WEK}-${riskLevel}-riskLevelContainer`,
  circleIconContainer: `${WEK}-${riskLevel}-circleIconContainer`,
} satisfies Record<string, `${typeof WEK}-${typeof riskLevel}-${string}`>

const entityManualEventForm = 'entity-manual-event-form'
export const entityManualEventFormQa = {
  container: `${WEK}-${entityManualEventForm}-container`,
  title: `${WEK}-${entityManualEventForm}-title`,
  subtitle: `${WEK}-${entityManualEventForm}-subtitle`,
  form: `${WEK}-${entityManualEventForm}-form`,
  select: `${WEK}-${entityManualEventForm}-select`,
  TextAreaFormField: `${WEK}-${entityManualEventForm}-TextAreaFormField`,
  cancel: `${WEK}-${entityManualEventForm}-cancel`,
  changeStatus: `${WEK}-${entityManualEventForm}-changeStatus`,
} satisfies Record<
  string,
  `${typeof WEK}-${typeof entityManualEventForm}-${string}`
>

const entityRiskScore = 'entity-risk-score'
export const entityRiskScoreQa = {
  container: `${WEK}-${entityRiskScore}-container`,
  riskScore: `${WEK}-${entityRiskScore}-riskScore`,
  dataGrid: `${WEK}-${entityRiskScore}-dataGrid`,
} satisfies Record<string, `${typeof WEK}-${typeof entityRiskScore}-${string}`>

const dataGridHelper = 'data-grid-helper'
export const dataGridHelperQa = {
  RiskFactorDataGridCellContainer: `${WEK}-${dataGridHelper}-RiskFactorDataGridCellContainer`,
  RiskValueDataGridCell: `${WEK}-${dataGridHelper}-RiskValueDataGridCell`,
  RiskScoreDataGridCell: `${WEK}-${dataGridHelper}-RiskScoreDataGridCell`,
  RiskDataGridHeader: `${WEK}-${dataGridHelper}-RiskDataGridHeader`,
} satisfies Record<string, `${typeof WEK}-${typeof dataGridHelper}-${string}`>

const amlScreeningEvents = 'aml-screening-events'
export const amlScreeningEventsQa = {
  container: `${WEK}-${amlScreeningEvents}-container`,
  title: `${WEK}-${amlScreeningEvents}-title`,
  historyRouteBtn: `${WEK}-${amlScreeningEvents}-historyRouteBtn`,
} satisfies Record<
  string,
  `${typeof WEK}-${typeof amlScreeningEvents}-${string}`
>
