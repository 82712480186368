import { I18nKeys } from 'shared/i18n'
import { Nullable } from 'shared/typescript'

import { individualOcrCheckEn } from '../locale/individual-ocr-check.en'

type TKey = I18nKeys<typeof individualOcrCheckEn>
export type OcrCheckReport = {
  title: TKey
  success: Nullable<boolean>
  scoreTKey: TKey
  score: number
  children: { title: TKey; success: Nullable<boolean> }[]
}[]

const ocrCheckResults: OcrCheckReport = [
  {
    title: 'checks.documentComparison',
    success: true,
    scoreTKey: 'faceRecognition',
    score: 100,
    children: [
      { title: 'checks.document.passport', success: true },
      { title: 'checks.document.driverLicense', success: true },
    ],
  },
  {
    title: 'checks.livenessDetection',
    score: 50,
    scoreTKey: 'livenessDetection',
    success: true,
    children: [
      { title: 'checks.livenessCheck.livenessCheck', success: true },
      { title: 'checks.livenessCheck.digitalSpoofCheck', success: true },
      { title: 'checks.livenessCheck.faceEvasionCheck', success: true },
      { title: 'checks.livenessCheck.closedEyesCheck', success: true },
      { title: 'checks.livenessCheck.hatCheck', success: true },
    ],
  },
]
const gallery = [
  'https://i.postimg.cc/tRzyqFGd/a.jpg',
  'https://i.postimg.cc/tRzyqFGd/a.jpg',
  'https://videos.pexels.com/video-files/3997798/3997798-uhd_1440_2732_25fps.mp4',
  'https://videos.pexels.com/video-files/3997798/3997798-uhd_1440_2732_25fps.mp4',
  'https://sample-videos.com/video321/mp4/720/big_buck_bunny_720p_1mb.mp4',
  'https://sample-videos.com/video321/mp4/720/big_buck_bunny_720p_1mb.mp4',
  'https://i.postimg.cc/K8Jsrk1F/sddefault.jpg',
  'https://i.postimg.cc/K8Jsrk1F/sddefault.jpg',
  'https://i.postimg.cc/K8Jsrk1F/sddefault.jpg',
]

export { ocrCheckResults, gallery }
