import { useMutation } from '@tanstack/react-query'

import { organisationApi } from '../../api/organisation.api'

type Args = {
  organisationToken: string
}

export const useCreateOwnershipProfileROW = () =>
  useMutation({
    mutationFn: ({ organisationToken }: Args) =>
      organisationApi.createOwnershipProfile(organisationToken),
  })
