import { SxProps } from '@mui/material'

import { capitalizeStringBySeparator } from 'shared/string'
import { PartialRecord, SuggestionString } from 'shared/typescript'

import {
  ApplicantTransactionsStatus,
  ApplicantTransactionsIssueType,
  ApplicantTransactionsRiskLevel,
} from './applicant-transactions.model'

export const ApplicantTransactionIssueTypeMapping: PartialRecord<
  SuggestionString<
    | ApplicantTransactionsStatus
    | ApplicantTransactionsIssueType
    | ApplicantTransactionsRiskLevel
  >,
  string
> = {
  PENDING: 'refer',
  APPROVED: 'passed',
  MANUALLY_APPROVED: 'passed',
  MANUALLY_DECLINED: 'failed',
  CUSTOMER: 'risk_high',
  DEVICE: 'risk_high',
  AML: 'risk_high',
  FRAUD: 'risk_high',
  NONE: 'passed',
  UNKNOWN: 'risk_unknown',
  LOW: 'passed',
  MEDIUM: 'refer',
  HIGH: 'failed',
  VERY_HIGH: 'failed',
}

export const transactionReadableContent = (text: string) => {
  const exceptions: PartialRecord<string, string> = {
    AML: 'AML',
    CUSTOMER: 'Device/Customer',
  }

  return exceptions[text] ?? capitalizeStringBySeparator(text)
}

/**
 * Currency and amount formatter
 */
export const parseTransactionAmount = (
  currency: string,
  amount: string,
): string => {
  const numericValue = Number(amount)

  try {
    if (Number.isNaN(numericValue)) {
      throw new Error('Invalid numeric value')
    }

    const formatter = new Intl.NumberFormat('en', {
      style: 'currency',
      currency,
      maximumFractionDigits: 16,
    })

    return formatter.format(numericValue)
  } catch (e) {
    if (currency && amount) {
      const countDecimalPlaces: number = amount.split('.')[1]?.length || 0
      const formattedValue =
        countDecimalPlaces < 2
          ? `${currency} ${numericValue.toFixed(2)}`
          : `${currency} ${amount}`

      return formattedValue
    }

    return '-'
  }
}

/**
 * Transaction table min width
 */
export const TRANSACTIONS_DATA_GRID_MIN_WIDTH = 800

/**
 * To set the width of the transaction data grid cell
 */
export const transactionDataGridCellWidth = (width: number) => ({
  minWidth: width,
  flex: width / TRANSACTIONS_DATA_GRID_MIN_WIDTH,
})

/**
 * The styles generator for the transaction data grid
 */
export const transactionsDataGridSx = (fullView: boolean) =>
  ({
    '& .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
    '& .MuiDataGrid-columnHeaderTitleContainerContent': {
      height: '100%',
    },
    '& .MuiDataGrid-columnHeaders': {
      lineHeight: 'unset !important',
      borderRadius: '0 !important',
    },
    '& .MuiDataGrid-columnHeaderTitleContainer': {
      padding: '0 14px',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      fontWeight: 'inherit',
    },
    '& .MuiDataGrid-virtualScroller': {
      maxHeight: fullView ? 'calc(100vh - 200px)' : '375px',
      height: fullView ? 'calc(100vh - 200px)' : 'auto',
      overflowY: 'auto !important',
    },
    '& .MuiDataGrid-overlayWrapperInner': {
      maxHeight: fullView
        ? 'calc(100vh - 200px) !important'
        : '375px !important',
      height: fullView ? 'calc(100vh - 200px) !important' : 'auto',
    },
    '& .MuiDataGrid-row': {
      lineHeight: 'unset !important',
    },
    '& .MuiDataGrid-cell': {
      maxHeight: 'unset !important',
      p: '20px 14px',
    },

    '& .MuiDataGrid-row--lastVisible': {
      '& .MuiDataGrid-cell': !fullView ? { border: '0px !important' } : {},
    },

    '& .MuiDataGrid-cellContent': {
      whiteSpace: 'normal',
      color: 'inherit',
    },
    '& .MuiButtonBase-root.MuiCheckbox-root': {
      '&.Mui-checked': {
        color: 'inherit',
      },
    },
    borderRadius: '0 !important',
    border: 'none ',
  } as SxProps)
