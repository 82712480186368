export const monitoringOverviewEn = {
  organisationOverview: 'Organisation overview',
  organisationInformation: 'Organisation information',
  name: 'Name',
  location: 'Location',
  type: 'Type',
  ABN: 'ABN',
  status: 'Status',
  selectReportTitleRow: 'Generate a report on this organisation',
  organisationProfileTitle: 'Organisation profile',
  organisationProfileSubtitle:
    'This report will retrieve all information available on this organisation',
  organisationProfileModalSubtitle:
    'This report will retrieve all information available on this organisation from the registration authority in the selected jurisdiction.',
  errorOnViewReport:
    'There was an issue generating an organisation profile. Please try again',
  errorOnViewOwnershipReport:
    'There was an issue generating an ownership report. Please try again',
  errorAmlCheck: 'There was an issue running an AML check. Please try again',
  viewReportButton: 'View report',
  reportGeneratedOn: 'Report generated on {{date}}',
  completedChecksTitle: 'Completed Checks',
  generatingReport: 'Generating report',
  reportIsGenerating:
    'The report is currently being generated and will be ready shortly. You can leave this tab and return to view the report when ready.',
  reportIsGeneratingBadge:
    'The Organisation Profile report is being generated. Check again shortly to view the report.',
  ownershipReportIsGeneratingBadge:
    'The Ownership report is being generated. Check again shortly to view the report.',
  ownershipReportRefreshIsGeneratingBadge:
    'The Ownership report is being generated. Please check back in a few minutes and refresh the page to view.',
  companyName: 'Name',
  registrationNumber: 'Registration Number',
  companyStatus: 'Status',
  registrationDate: 'Incorporation date',
  companyType: 'Company Type',
  jurisdiction: 'Jurisdiction',
  incorporationDate: 'Incorporation Date',
  country: 'Country',
  registrationAuthority: 'Registration Authority',
  changeStatusModal: {
    title: 'Change profile status',
    subtitle:
      'This will change the status for this profile, all checks should be completed before proceeding.',
    profileStatusSelect: 'Select profile status',
    manuallyPassed: 'Manually passed',
    manuallyFailed: 'Manually Failed',
    comments: 'Comments',
    commentsPlaceholder: 'Type your comment here',
    cancelButton: 'Cancel',
    changeStatusButton: 'Change status',
    error: 'There was an issue. Please, try again',
  },
  archiveModal: {
    archiveProfileTitle: 'Archive profile',
    archiveProfileSubtitle: 'This will change the profile status to "archived"',
    archiveProfileDescription:
      'The profile can still be found in searches, and may be reactivated at any time by updating details in Manual IDV and re-running checks.',
    commentFieldTitle: 'Comment',
    commentPlaceholder: 'Type your comment here',
    archiveProfileButton: 'Archive profile',
    cancelButton: 'Cancel',
  },
  unarchiveModal: {
    unarchiveProfileTitle: 'Unarchive this profile',
    unarchiveProfileSubtitle:
      'This will re-run checks associated with this profile (excluding any business ownership reports)',
    unarchiveProfileDescription:
      'The profile status will be updated according to the new check results.',
    unArchiveProfileButton: 'Unarchive profile',
  },
  unarchiveProfile: 'Unarchive profile',
  archiveProfile: 'Archive profile',
  updateProfile: 'Update profile status',
  lastUpdated: 'Last updated',
  singleLevelBusinessOwnershipTitle: 'Single level business ownership',
  frankieId: 'Frankie ID',
  ownershipProfileTitle: 'Ownership report',
  ownershipProfileSubtitle:
    'This report will identify the ultimate owner(s) of this company and help you understand what role (direct or indirect) individuals play within simple or complex corporate structures',
  retrieveList: 'Retrieve the list of registry documents on this organisation',
  retrieveListButton: 'Retrieve list',
  listAlreadyRetrieved: 'Purchase registry documents on this organisation',
  listAlreadyRetrievedButton: 'View document list',
  retrievingList:
    'The list of registry documents for this organisation is being retrieved. \n Hold tight, in some jurisdictions it can take up to 2 mins to retrieve.',
  runCheckOrganisation: 'Run a check on this organisation',
  runChecks: 'Run check',
  generateReport: 'Generate report',
  amlScreening: 'AML screening',
  amlScreeningSubtitle:
    'This will run sanctions or adverse media check on this organisation',
  selectCheck: 'Select a check to run',
  selectReport: 'Select report to generate',

  banner: {
    heading:
      'Credit limit has been reached. Generate another Ownership report within 24 hours to access further information.',
    description:
      'To continue the ownership search past the point of maximum credit reached, please generate another ownership report within 24 hours of the initial search.',
    descriptionUpdated: 'Your last ownership report was on {{lastUpdated}}.',
    buttonLabel: 'Continue report generation',
  },

  amlResult: {
    title: 'AML Screening',
    failed: 'Potential sanctions or adverse media match',
    passed: 'Sanctions and adverse media match has been verified',
  },

  reportError: {
    title: 'Unable to generate {{reportType}} report. Please try again later.',
    description: 'The last generation attempt failed on {{requestedDate}}.',
  },
}
