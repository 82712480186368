import { bffClient, IClient } from 'shared/client'

import {
  INotificationSetting,
  INotificationSettingsConfig,
} from '../model/notifications-management.model'

export type INotificationSettingChangeable = Omit<
  INotificationSetting,
  'canDisable'
>

export class NotificationsManagementApi {
  client: IClient

  constructor(client: IClient) {
    this.client = client
  }

  async getNotificationSettings() {
    return this.client.get<INotificationSettingsConfig>(
      '/data/v1/notification-settings',
    )
  }

  async changeNotificationSettings(settings: INotificationSetting[]) {
    return this.client.put<
      { message: string },
      { notificationSettings: INotificationSettingChangeable[] }
    >('/data/v1/notification-settings', {
      notificationSettings: settings.map<INotificationSettingChangeable>(
        ({ canDisable, ...other }) => other,
      ),
    })
  }
}

export const notificationsManagementApi = new NotificationsManagementApi(
  bffClient,
)
