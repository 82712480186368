import React, { useMemo } from 'react'

import { FrankieButton, FrankieLoader } from 'frankify/src'

import { GenerateReportTypes, ProfileGenerate } from 'entities/profile-check'

import { useI18n } from 'shared/i18n'
import { useOverlay } from 'shared/overlay'
import { Noop } from 'shared/typescript'

import { MONITORING_OVERVIEW_KEY } from '../../monitoring-overview.key'
import { useHasInternationalUbo } from '../../state/applicant-has-international-ubo/applicant-has-international-ubo'
import {
  ReportGenerationArgs,
  useReportGeneration,
} from '../../state/ikyb-report-generation/ikyb-report-generation'
import { AmlCheckRun } from '../aml-check-run-and-status/aml-check-run-and-status'

type Props = {
  entityId: string
  toggleReportGeneration: ReportGenerationArgs['toggleReportGeneration']
}

type ModalGenerateReportProps = Props & {
  onClose: Noop
  value: GenerateReportTypes
}

function ModalGenerateReport({
  entityId,
  toggleReportGeneration,
  onClose,
  value,
}: ModalGenerateReportProps) {
  const t = useI18n([MONITORING_OVERVIEW_KEY])

  const {
    generateOwnershipReport,
    generateProfileReport,
    isLoadingContinueOwnershipReport,
    isLoadingProfileReport,
  } = useReportGeneration({
    entityId,
    toggleReportGeneration: value => {
      onClose()
      toggleReportGeneration(value)
    },
  })

  const isProfile = value === GenerateReportTypes.PROFILE

  return (
    <FrankieLoader
      loading={isLoadingProfileReport || isLoadingContinueOwnershipReport}
    >
      <div className="w-[480px]">
        <div className="text-xl font-bold text-tertiary-grey-800 pb-3">
          {isProfile
            ? t('organisationProfileTitle')
            : t('ownershipProfileTitle')}
        </div>

        <div className="text-tertiary-grey-700">
          {isProfile
            ? t('organisationProfileModalSubtitle')
            : t('ownershipProfileSubtitle')}
        </div>

        <div className="flex justify-end w-full mt-6 gap-4">
          <FrankieButton intent="subtle" onClick={onClose}>
            {t('changeStatusModal.cancelButton')}
          </FrankieButton>
          <FrankieButton
            onClick={
              isProfile ? generateProfileReport : generateOwnershipReport
            }
          >
            {t('generateReport')}
          </FrankieButton>
        </div>
      </div>
    </FrankieLoader>
  )
}

export function ChecksToRun({
  entityId,
  toggleReportGeneration,
  isGenerating,
}: Props & { isGenerating: boolean }) {
  const t = useI18n([MONITORING_OVERVIEW_KEY])

  const { hasInternationalUBO } = useHasInternationalUbo({
    applicantId: entityId,
  })

  const [createOverlay, closeOverlay] = useOverlay()

  const handleSubmit = (value: GenerateReportTypes) => {
    createOverlay(
      <ModalGenerateReport
        entityId={entityId}
        onClose={closeOverlay}
        toggleReportGeneration={toggleReportGeneration}
        value={value}
      />,
      {
        closeButtonClassName: '!top-7 !right-7 !z-10',
      },
    )
  }

  const options = useMemo(
    () => [
      {
        title: t('organisationProfileTitle'),
        value: GenerateReportTypes.PROFILE,
        description: t('organisationProfileSubtitle'),
      },
      {
        title: t('ownershipProfileTitle'),
        value: GenerateReportTypes.OWNERSHIP,
        description: t('ownershipProfileSubtitle'),
        isHidden: !hasInternationalUBO,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasInternationalUBO],
  )

  return (
    <>
      {/* TODO: Will need to merge report and aml */}
      <AmlCheckRun entityId={entityId} />

      <ProfileGenerate
        className="mb-6"
        heading={t('selectReportTitleRow')}
        label={t('generateReport')}
        placeholder={t('selectReport')}
        options={options}
        onSubmit={handleSubmit}
        isLoading={isGenerating}
      />
    </>
  )
}
