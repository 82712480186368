// This is a temporary solution to store local storage, originally we were
// going to use localForage but because of it's prefix issue where all values
// stored in localStorage must have a key with prefix like "localstorage/"
// This won't work with vue portal so we're making a simple data storage for now

import { Nullable } from 'shared/typescript'

export type Serializable =
  | string
  | number
  | boolean
  | unknown
  | Array<Serializable>

export enum StorageKeyTypes {
  PageData = 'pageData',
  Token = 'token',
  OriginalUrl = 'originalURL',
  IsSidebarOpen = 'isSidebarOpen',
  GlobalSearch = 'globalSearch',
  DismissedAlerts = 'dismissedAlerts',
}

const storageKeyTypesFlat: StorageKeyTypes[] = Object.values(StorageKeyTypes)

const storageKeysPersist: StorageKeyTypes[] = [StorageKeyTypes.GlobalSearch]

const storageKeysSensitive: StorageKeyTypes[] = storageKeyTypesFlat.filter(
  value => !storageKeysPersist.includes(value),
)

export function getAppcuesLocalStorageKeys(): string[] {
  return Object.keys(window.localStorage).filter(key => key.startsWith('apc_'))
}

export function localStorageClearSensitive() {
  ;[...storageKeysSensitive, ...getAppcuesLocalStorageKeys()].forEach(key => {
    window.localStorage.removeItem(key)
  })
}

export const getLocalStorageState = (
  key: string,
): [Nullable<Serializable>, (value: Nullable<Serializable>) => void] => {
  // Attempt to json parse the item, if it's successful we'll return that if it's an
  // item that's unparseable so probably a normal string we should return just the item
  const tryJSONParse = (item: string) => {
    try {
      return JSON.parse(item) as Serializable
    } catch {
      return item
    }
  }

  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const getValue = (): Nullable<Serializable> => {
    if (typeof window === 'undefined') {
      return null
    }

    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key)

      // Parse stored json or if none return null
      return item ? tryJSONParse(item) : null
    } catch (error) {
      // If error also return null
      return null
    }
  }

  // Sets the new value to local storage
  const setValue = (value: Nullable<Serializable>) => {
    try {
      if (typeof window === 'undefined') {
        return
      }
      if (value === null || value === undefined) {
        window.localStorage.removeItem(key)
        return
      }

      // Only stringify objects to align with how vue portal behaves
      const formattedValue =
        value instanceof Object ? JSON.stringify(value) : value.toString()

      window.localStorage.setItem(key, formattedValue)

      // eslint-disable-next-line no-empty
    } catch (error) {}
  }

  const storedValue = getValue()

  return [storedValue, setValue]
}
