import React from 'react'

import * as FrankieImageAssets from './asset'
import { IFrankieImageProps } from './image.types'

export function FrankieImage({
  alt = '',
  className = '',
  src = '',
  testId = {
    image: '',
  },
  title = '',
}: IFrankieImageProps): JSX.Element {
  return (
    <img
      alt={alt}
      title={title}
      className={className}
      src={(FrankieImageAssets as any)[src] || src}
      data-qa={testId.image}
    />
  )
}
