import React from 'react'

import { ApplicantEntityTypes } from 'entities/applicant'

import { CheckboxAsButton } from 'shared/filters-common'
import { useI18n } from 'shared/i18n'

import {
  APPLICANTS_FILTER_KEY,
  applicantsFilterEn,
} from '../../locale/applicants-filter.en'
import { applicantsFilterQa } from '../../qa/applicants-filter.qa'

type Props = {
  value: ApplicantEntityTypes | ''
  onChange: (value: ApplicantEntityTypes | '') => void
}

// eslint-disable-next-line complexity
export function ApplicantTypeSelector({ value, onChange }: Props) {
  const t = useI18n([APPLICANTS_FILTER_KEY], { keys: applicantsFilterEn })

  const handleChange = (option: ApplicantEntityTypes) => () => {
    if (value === option) {
      onChange('')
    } else onChange(option)
  }

  return (
    <div className="flex flex-row items-center gap-6">
      <CheckboxAsButton
        size="md"
        startIcon={{
          name: 'mdiAccountOutline',
          size: 'sm',
          className: 'mr-2',
        }}
        onChange={handleChange(ApplicantEntityTypes.Individual)}
        checked={value === ApplicantEntityTypes.Individual}
        className="basis-1/2"
        testId={{ input: applicantsFilterQa.inputs.individualType }}
      >
        {t('applicantTypes.individual')}
      </CheckboxAsButton>
      <CheckboxAsButton
        size="md"
        startIcon={{
          name: 'mdiOfficeBuildingOutline',
          size: 'sm',
          className: 'mr-2',
        }}
        onChange={handleChange(ApplicantEntityTypes.Organisation)}
        checked={value === ApplicantEntityTypes.Organisation}
        className="basis-1/2"
        testId={{ input: applicantsFilterQa.inputs.organisationType }}
      >
        {t('applicantTypes.organisation')}
      </CheckboxAsButton>
    </div>
  )
}
