import { useCallback } from 'react'

import { useQuery, useQueryClient } from '@tanstack/react-query'

import { COMMENT_KEY } from 'entities/comments/comments.key'
import { commentsEn } from 'entities/comments/locale/comments.en'
import {
  ProcessResultManualStatusEnumAML,
  WorkflowExecutionResultEnum,
  AuditEventType,
} from 'entities/entity'
import {
  workflowManualStatusMapping,
  workflowStatusMapping,
} from 'entities/entity/model/profile.model'
import {
  Audit,
  useAllAuditReportData,
} from 'entities/individual-audit-report-f2'

import { I18nFunction, useI18n } from 'shared/i18n'
import { Nullable } from 'shared/typescript'

import {
  CommentsTypes,
  FunctionNameTypes,
  SupplementaryData,
  successVariantsForAML,
  successVariantsForWorkflow,
  alertVariantsForWorkflow,
  failedVariantsForAML,
  failedVariantsForWorkflow,
  warningVariantsForWorkflow,
  decodeHtmlEntities,
} from '../../model/comments.model'

export const COMMENT_BOX_VISIBILITY_QUERY_KEY = 'commentBoxVisibility'

export type CommentPayloadType = Record<CommentsTypes, boolean>

export const useCommentBoxVisibility = ({ entityId }: { entityId: string }) => {
  const queryClient = useQueryClient()

  return useQuery<CommentPayloadType>({
    queryKey: [COMMENT_BOX_VISIBILITY_QUERY_KEY, entityId],
    queryFn: () => {
      const visibilityData = queryClient.getQueryData<CommentPayloadType>([
        COMMENT_BOX_VISIBILITY_QUERY_KEY,
        entityId,
      ])

      if (visibilityData) {
        return visibilityData
      }
      return {
        COMMENT_WORKFLOW: false,
        COMMENT_AML: false,
        COMMENT_KYC: false,
        COMMENT_PROFILE: false,
        COMMENT_AMLRESULT: false,
        COMMENT_AMLHISTORY: false,
        COMMENT_AMLSCREENING: false,
        COMMENT_PROFILE_PERSONAL_INFO: false,
        AUDIT: false,
        COMMENT_IDV_DOCUMENT: false,
        COMMENT_IDV_BIOMETRICS: false,
      } as CommentPayloadType
    },
  })
}

export const useUpdateCommentBoxVisibility = ({
  entityId,
}: {
  entityId: string
  isProfileTab?: boolean
}) => {
  const queryClient = useQueryClient()

  const update = useCallback(
    ({
      payload,
      overrideState,
    }: {
      payload: CommentsTypes | CommentsTypes[]
      overrideState?: boolean
      expanded?: boolean
    }) => {
      const visibilityData = queryClient.getQueryData<CommentPayloadType>([
        COMMENT_BOX_VISIBILITY_QUERY_KEY,
        entityId,
      ])
      if (visibilityData && !Array.isArray(payload)) {
        queryClient.setQueryData<CommentPayloadType>(
          [COMMENT_BOX_VISIBILITY_QUERY_KEY, entityId],
          {
            ...visibilityData,
            [payload]: !visibilityData[payload],
          },
        )
      }
      if (visibilityData && Array.isArray(payload)) {
        const updatedData = payload.reduce(
          (acc, item) => ({
            ...acc,
            [item]: overrideState ?? !visibilityData[item],
          }),
          {} as CommentPayloadType,
        )
        queryClient.setQueryData<CommentPayloadType>(
          [COMMENT_BOX_VISIBILITY_QUERY_KEY, entityId],
          {
            ...visibilityData,
            ...updatedData,
          },
        )
      }
    },
    [queryClient, entityId],
  )

  return update
}

export const getStatusConfigForAml = (
  t: I18nFunction<typeof commentsEn>,
  status?: ProcessResultManualStatusEnumAML,
) => {
  if (!status)
    return {
      textColor: undefined,
      text: undefined,
    }
  if (successVariantsForAML.includes(status)) {
    return {
      textColor: 'text-tertiary-green-600',
      text: t(`resolvedStatus.${status}`),
    }
  }
  if (failedVariantsForAML.includes(status)) {
    return {
      textColor: 'text-tertiary-red-600',
      text: t(`resolvedStatus.${status}`),
    }
  }

  return {
    textColor: undefined,
    text: undefined,
  }
}

export function getStatusConfigForWorkflow(
  t: I18nFunction<typeof commentsEn>,
  status?: WorkflowExecutionResultEnum,
) {
  if (!status)
    return {
      textColor: undefined,
      text: undefined,
    }
  const mappedStatus =
    workflowManualStatusMapping[status] || workflowStatusMapping[status]

  if (successVariantsForWorkflow.includes(mappedStatus)) {
    return {
      textColor: 'text-tertiary-green-600',
      text: t(`resolvedStatus.${mappedStatus}`),
    }
  }
  if (failedVariantsForWorkflow.includes(mappedStatus)) {
    return {
      textColor: 'text-tertiary-red-600',
      text: t(`resolvedStatus.${mappedStatus}`),
    }
  }

  if (alertVariantsForWorkflow.includes(mappedStatus)) {
    return {
      textColor: 'text-tertiary-grey-400',
      text: t(`resolvedStatus.${mappedStatus}`),
    }
  }
  if (warningVariantsForWorkflow.includes(mappedStatus)) {
    return {
      textColor: 'text-tertiary-yellow-600',
      text: t(`resolvedStatus.${mappedStatus}`),
    }
  }

  return {
    textColor: undefined,
    text: undefined,
  }
}

export type CommentArrayReturnType = {
  data: (Audit & {
    actionTaken: string
    statusComment:
      | {
          textColor: string | undefined
          text: string | undefined
        }
      | undefined
  })[]
}

const filterByProcessId = (
  item: Audit,
  processId?: string | string[],
  objectType = 'RESULT_PROCESS',
) => {
  const supplementaryData =
    item.supplementaryData as Nullable<SupplementaryData>

  const isResultProcess = supplementaryData?.resultsUpdated?.some(
    res => res.objectType === objectType,
  )
  const isSelectedProcess = processId
    ? supplementaryData?.resultsUpdated?.some(res =>
        Array.isArray(processId)
          ? processId.includes(res.objectId)
          : res.objectId === processId,
      )
    : true

  return isResultProcess && isSelectedProcess
}

const processAml = (
  data: Audit[],
  t: I18nFunction<typeof commentsEn>,
  processId?: string | string[],
) =>
  data
    .filter(Boolean)
    .filter(
      item =>
        item.type === AuditEventType.ENTITY_RESULT_UPDATE &&
        item.functionName === FunctionNameTypes.UPDATEAML &&
        item.userComment &&
        filterByProcessId(item, processId),
    )
    .map(item => ({
      ...item,
      actionTaken:
        (
          (item.supplementaryData as Nullable<SupplementaryData>)
            ?.resultsUpdated ?? []
        ).length > 1
          ? t('actionTakenText.batchResolved')
          : t('actionTakenText.resolved'),
      statusComment: getStatusConfigForAml(
        t,
        (item.supplementaryData as Nullable<SupplementaryData>)
          ?.resultsUpdated?.[0]?.newStatus,
      ),
    }))
    .sort(
      (item1, item2) =>
        new Date(item2.timestamp).valueOf() -
        new Date(item1.timestamp).valueOf(),
    )

const processAmlResult = (
  data: Audit[],
  t: I18nFunction<typeof commentsEn>,
  processId?: string | string[],
) =>
  data
    .filter(Boolean)
    .filter(
      item =>
        item.type === AuditEventType.ENTITY_RESULT_UPDATE &&
        item.functionName === FunctionNameTypes.UPDATEAML &&
        item.userComment &&
        filterByProcessId(item, processId),
    )
    .map(item => ({
      ...item,
      actionTaken:
        (
          (item.supplementaryData as Nullable<SupplementaryData>)
            ?.resultsUpdated ?? []
        ).length > 1
          ? t('actionTakenText.batchResolved')
          : t('actionTakenText.resolved'),
      statusComment: getStatusConfigForAml(
        t,
        (item.supplementaryData as Nullable<SupplementaryData>)
          ?.resultsUpdated?.[0]?.newStatus,
      ),
    }))
    .sort(
      (item1, item2) =>
        new Date(item2.timestamp).valueOf() -
        new Date(item1.timestamp).valueOf(),
    )

const processWorkflow = (
  data: Audit[],
  t: I18nFunction<typeof commentsEn>,
  workflowName?: string,
) =>
  data
    .filter(Boolean)
    .filter(
      item =>
        item.type === AuditEventType.WORKFLOW_STATUS_CHANGE &&
        item.functionName ===
          FunctionNameTypes.OVERRIDEWORKFLOWEXECUTIONRESULT &&
        item.userComment,
    )
    .filter(item =>
      item.workflowName && workflowName
        ? item.workflowName === workflowName
        : item.eventStatus !== 'ERROR',
    )
    .map(item => ({
      ...item,
      actionTaken: `${t('actionTakenText.statusOverride')}`,
      statusComment: getStatusConfigForWorkflow(
        t,
        item.status as WorkflowExecutionResultEnum,
      ),
    }))
    .sort(
      (item1, item2) =>
        new Date(item2.timestamp).valueOf() -
        new Date(item1.timestamp).valueOf(),
    )

const processProfile = (data: Audit[], t: I18nFunction<typeof commentsEn>) => {
  const createEntityData = data
    .filter(Boolean)
    .filter(
      item =>
        item.type === AuditEventType.ENTITY_CREATE &&
        item.functionName === FunctionNameTypes.CREATEINDIVIDUAL &&
        item.userComment,
    )
    .map(item => ({
      ...item,
      actionTaken: t('actionTakenText.entityCreated'),
      statusComment: undefined,
    }))
  const updateEntityData = data
    .filter(Boolean)
    .filter(
      item =>
        item.type === AuditEventType.ENTITY_UPDATE &&
        item.functionName === FunctionNameTypes.UPDATEINDIVIDUAL &&
        item.userComment,
    )
    .map(item => ({
      ...item,
      actionTaken: t('actionTakenText.entityUpdated'),
      statusComment: undefined,
    }))
  const archiveEntityData = data
    .filter(Boolean)
    .filter(
      item =>
        item.type === AuditEventType.SERVICE_PROFILE_UPDATE &&
        item.functionName === FunctionNameTypes.UPDATEPROFILE &&
        item.userComment &&
        (
          item.supplementaryData as Nullable<SupplementaryData>
        )?.fieldsUpdated?.some(
          field =>
            field.changeDescription.includes('State is changed') &&
            field.objectType === 'SERVICE_PROFILE',
        ),
    )
    .map(item => ({
      ...item,
      actionTaken: t('actionTakenText.entityArchived'),
      statusComment: undefined,
    }))

  const verifiedEntityData = data
    .filter(Boolean)
    .filter(
      item =>
        item.type === AuditEventType.WORKFLOW_EVENT &&
        item.functionName === FunctionNameTypes.EXECUTEWORKFLOW &&
        item.userComment,
    )
    .map(item => ({
      ...item,
      actionTaken: t('actionTakenText.entityVerified'),
      statusComment: undefined,
    }))

  return [
    ...createEntityData,
    ...updateEntityData,
    ...archiveEntityData,
    ...verifiedEntityData,
  ].sort(
    (item1, item2) =>
      new Date(item2.timestamp).valueOf() - new Date(item1.timestamp).valueOf(),
  )
}

function getIdvStatusText(data: Audit, t: I18nFunction<typeof commentsEn>) {
  const newStatus = (data.supplementaryData as SupplementaryData)
    .resultsUpdated?.[0]
    ?.newStatus as keyof (typeof commentsEn)['resolvedStatus']

  switch (newStatus) {
    case 'CLEAR':
      return {
        textColor: 'text-tertiary-green-600',
        text: t(`resolvedStatus.${newStatus}`),
      }
    case 'REJECTED':
      return {
        textColor: 'text-tertiary-red-600',
        text: t(`resolvedStatus.${newStatus}`),
      }
    default:
      return {
        textColor: undefined,
        text: undefined,
      }
  }
}

const processIdv = (
  data: Audit[],
  t: I18nFunction<typeof commentsEn>,
  processId?: string | string[],
) =>
  data
    .filter(Boolean)
    .filter(
      item =>
        item.type === AuditEventType.ENTITY_RESULT_UPDATE &&
        filterByProcessId(item, processId),
    )
    .map(item => ({
      ...item,
      actionTaken: t('actionTakenText.resolved'),
      statusComment: getIdvStatusText(item, t),
    }))

const getCommentArray = ({
  commentType,
  t,
  data,
  processId,
  workflowName,
}: {
  commentType: CommentsTypes
  t: I18nFunction<typeof commentsEn>
  data?: Audit[]
  processId?: string | string[]
  workflowName?: string
}): CommentArrayReturnType => {
  if (!data) return { data: [] }

  switch (commentType) {
    case CommentsTypes.AMLRESULT:
    case CommentsTypes.AMLHISTORY:
    case CommentsTypes.AMLSCREENING:
      return { data: processAmlResult(data, t, processId) }
    case CommentsTypes.AML:
      return { data: processAml(data, t, processId) }
    case CommentsTypes.WORKFLOW:
      return { data: processWorkflow(data, t, workflowName) }
    case CommentsTypes.PROFILE:
    case CommentsTypes.PROFILE_PERSONAL_INFO:
      return { data: processProfile(data, t) }
    case CommentsTypes.IDV_BIOMETRICS:
    case CommentsTypes.IDV_DOCUMENT:
      return { data: processIdv(data, t, processId) }

    default:
      return { data: [] }
  }
}

const sanitizeComment = (
  comment: Audit & {
    actionTaken: string
    statusComment:
      | {
          textColor: string | undefined
          text: string | undefined
        }
      | undefined
  },
) => ({
  ...comment,
  ...(comment.userComment
    ? {
        userComment: {
          ...comment.userComment,
          ...(comment.userComment.text
            ? { text: decodeHtmlEntities(comment.userComment.text) }
            : {}),
        },
      }
    : {}),
})

export const useCommentData = ({
  entityId,
  commentType,
  processId,
  workflowName,
}: {
  entityId: string
  commentType: CommentsTypes[] | CommentsTypes
  processId?: string | string[]
  workflowName?: string
}) => {
  const { data } = useAllAuditReportData({ entityId })

  const processIds = Array.isArray(processId)
    ? processId.filter(Boolean)
    : processId

  const t = useI18n([COMMENT_KEY], { keys: commentsEn })
  const commentTypesArr = Array.isArray(commentType)
    ? commentType
    : [commentType]

  const comments = commentTypesArr
    .flatMap(
      commentType =>
        getCommentArray({
          commentType,
          t,
          data,
          processId: processIds,
          workflowName,
        }).data,
    )
    .map(sanitizeComment)

  return { data: comments }
}
