import React from 'react'

import { UseFormReturn } from 'react-hook-form'

import {
  IIndividualProfileInputs,
  PersonalInfo,
} from 'features/individual-profile'

import { blockListPersonalFormQa } from '../../qa/applicant-blocklisted-info-qa'

type Props = {
  form: UseFormReturn<IIndividualProfileInputs>
}

export function NameDob({ form }: Props) {
  return (
    <PersonalInfo
      testId={blockListPersonalFormQa}
      form={form}
      isOptionalFieldRequired={false}
    />
  )
}
