export const roleManagementEn = {
  roleEditForm: {
    name: {
      label: 'Role Name',
      required: 'Name is required',
      unique: 'Name must be unique',
    },
    description: {
      label: 'Description (optional)',
    },
    permissions: {
      header: 'Permissions',
    },
    cta: {
      create: 'Create role',
      save: 'Save role',
      cancel: 'Cancel',
    },
    loader: {
      create: 'Creating role...',
      save: 'Saving role...',
    },
  },
  roleRemoveConfirm: {
    heading: 'Are you sure you want to remove {{roleName}} role?',
    description: 'You will not be able to undo this action.',
    cta: {
      cancel: 'No, cancel',
      confirm: "Yes I'm sure",
    },
  },
}
