import React from 'react'

import { useAnalyticsDashboardTranslation } from 'features/analytics-dashboard/locale/analytics-dashboard.en'

import { PermissionTypes } from 'entities/role'
import { useHasFeatureFlag, useHasPermission } from 'entities/session'

import { Menu } from 'shared/components'
import { useOverlay } from 'shared/overlay'

import { CustomerReport } from './customer-report/customer-report'
import { TransactionReport } from './transaction-report/transaction-report'

export function AnalyticsGenerateReport() {
  const { t } = useAnalyticsDashboardTranslation()
  const [createOverlay] = useOverlay()
  const { isFrankie2 } = useHasFeatureFlag({ isFrankie2: 'frankie2customer' })

  const { customerRecord, transactionRecord } = useHasPermission({
    transactionRecord: PermissionTypes.ReportingDownload,
    customerRecord: PermissionTypes.ReportingDownloadCustomerRecords,
  })
  if (isFrankie2) return null

  if (!customerRecord && !transactionRecord) return null

  const handleSelect = (value: 'trans' | 'customer') => {
    if (value === 'trans') {
      createOverlay(<TransactionReport />, { className: 'max-w-[480px] p-5' })
    } else {
      createOverlay(<CustomerReport />, { className: 'max-w-[450px] p-6' })
    }
  }

  return (
    <div className="flex ">
      <Menu
        onSelect={handleSelect}
        options={[
          {
            label: t('report.transactionReport'),
            description: t('report.max31Days'),
            value: 'trans',
            disable: !transactionRecord,
          },
          {
            label: t('report.customerReport'),
            value: 'customer',
            disable: !customerRecord,
          },
        ]}
        icons={{ close: 'mdiChevronDown', open: 'mdiChevronUp' }}
        buttonIntent="secondary"
        buttonClassName="min-w-max !rounded-sm  "
        placement={{ placement: 'bottom-start' }}
      >
        {t('report.generateReport')}
      </Menu>
    </div>
  )
}
