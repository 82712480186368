import { DateFormatTypes, formatDate, isValidDate } from 'shared/date-time'
import { I18nKeys } from 'shared/i18n'
import { Nullable } from 'shared/typescript'

import { applicantPepSactionsEn } from '../locale/applicant-pep-sactions.en'

export type PepSactionsStatus =
  | 'true_positive_reject'
  | 'true_positive_accept'
  | 'true_positive_approve'
  | 'false_positive'
  | 'true_positive'
  | 'unknown'
  | 'possible_match'

export type PepSactionsStatusResult = Nullable<PepSactionsStatus> | ''

export type UpdateMatchPayload = {
  comment: string
  groupIds: string[]
  status: PepSactionsStatus
}

export type PepsStatusMapping = {
  type: 'non-final' | 'final'
  term?: Nullable<string>
  needsAttention: boolean
  needsAction: boolean
}

// From vue code - {mapMultipepMatchStatus}
// eslint-disable-next-line complexity
export function getPepStatusMapping(
  status?: PepSactionsStatusResult,
): PepsStatusMapping {
  const result: PepsStatusMapping = {
    needsAttention: false,
    needsAction: false,
    term: status,
    type: 'non-final',
  }

  // unifying possible_match possible terms
  if ([null, undefined, '', 'possible_match'].includes(status))
    result.term = 'possible_match'

  // flag that it needs attention
  if (status) {
    if (['possible_match', 'unknown'].includes(status))
      result.needsAttention = true
    if (['true_positive'].includes(status)) result.needsAction = true
  }

  switch (status) {
    case 'true_positive_accept':
    case 'true_positive_approve':
      result.term = 'true_positive_accept'
      break
    case 'true_positive_reject':
      result.term = 'true_positive_reject'
      break
    default:
      result.term = status
      break
  }
  // defining as final state
  switch (status) {
    case 'true_positive_reject':
    case 'true_positive_accept':
    case 'false_positive':
      result.type = 'final'
      break
    default:
      result.type = 'non-final'
  }

  return result
}

export type PepsPepClass = {
  name: string
  source: string
  class: string
  items: { key: string; item: string }[]
}

export type AmlMatchSummary = {
  comment: Nullable<string>
  pawsAlert: string[]
  matchStrength?: number | null
  matchStatus: {
    by: Nullable<string>
    status: Nullable<PepSactionsStatus | ''>
  }
  countries: string[]
  dateOfBirth: Nullable<string>
  name: string
  entityType: string
  groupId: string
  details: {
    entityData: {
      name: string
      matchStatus: Nullable<PepSactionsStatus | ''>
      dateOfBirth: Nullable<string>
      aka: string[]
      countries: string[]
      associates: { relation: string; name: string }[]
      addresses: string[]
      imageURL?: Nullable<string>
      supportingDocUrls?: string[]
    }
    matchStatus: Nullable<PepSactionsStatus | ''>
    sanctions: Omit<PepsPepClass, 'class'>[]
    watchlists: Omit<PepsPepClass, 'class'>[]
    media: {
      mediaArticles: {
        date: string
        pdf_url: string
        snippet: string
        title: string
        url: string
      }[][]
      mediaMatches: {
        name: string
        source: string
        items: { key: string; item: string }[]
      }[]
    }
    pepsPep: PepsPepClass[]
    pepsPepClass1: PepsPepClass[]
    pepsPepClass2: PepsPepClass[]
    pepsPepClass3: PepsPepClass[]
    pepsPepClass4: PepsPepClass[]
    supportingDocuments: string[]
  }
}

export type AmlCheckResult = {
  name: Nullable<string>
  dateOfBirth: Nullable<string>
  countries: string[] | null
  searchFuzziness: number | null
  vendorId: Nullable<string>
  checkId: Nullable<string>
  searchDate: Nullable<string>
  matches: AmlMatchSummary[]
}

export type PepSactionsMatchRecord = {
  id: string
  name: string
  dob: string
  countries: string
  issues: string[]
  matchStatus: PepSactionsStatus | ''
  matchScore: number
}

export type PepSactionsMatchRecordProperties = keyof PepSactionsMatchRecord

type PepSactionsListing<TField extends keyof AmlMatchSummary['details']> = {
  type: string
  field: TField
  tKey: I18nKeys<(typeof applicantPepSactionsEn)['listingFields']>
}[]

export const pepSactionsListing: PepSactionsListing<
  | 'watchlists'
  | 'sanctions'
  | 'pepsPep'
  | 'pepsPepClass1'
  | 'pepsPepClass2'
  | 'pepsPepClass3'
  | 'pepsPepClass4'
> = [
  {
    type: 'sanction',
    field: 'sanctions',
    tKey: 'sanctions',
  },
  {
    type: 'watchlist',
    field: 'watchlists',
    tKey: 'watchlists',
  },
  {
    type: 'pep',
    field: 'pepsPep',
    tKey: 'pepsPep',
  },
  {
    type: 'pep-class-1',
    field: 'pepsPepClass1',
    tKey: 'pepsPepClass1',
  },
  {
    type: 'pep-class-2',
    field: 'pepsPepClass2',
    tKey: 'pepsPepClass2',
  },
  {
    type: 'pep-class-3',
    field: 'pepsPepClass3',
    tKey: 'pepsPepClass3',
  },
  {
    type: 'pep-class-4',
    field: 'pepsPepClass4',
    tKey: 'pepsPepClass4',
  },
]

export const pepSactionsDateFormat = (
  date?: Nullable<string>,
  noDateText = '',
  dateFormat: DateFormatTypes = DateFormatTypes.DateNumbers,
) => {
  const value =
    date && isValidDate(date) ? formatDate(date, dateFormat) : noDateText

  return value
}

export function fuzzinessPercentage(num?: Nullable<number>) {
  if (!num) return '-'

  if (num > 0 && num < 1) {
    return `${num * 100}%`
  }

  return `${num}%`
}

/**
 * @todo Remove this dummy data
 */
export const dummyMatchesData: AmlCheckResult = {
  name: 'BELINGBAK CAPITAL PARTNERS PTY LIMITED',
  dateOfBirth: null,
  countries: ['All'],
  searchFuzziness: 0.6,
  vendorId: '1673698710',
  checkId: '8942a76c-016f-bfcd-7563-d433ce051ab8',
  searchDate: '2024-03-20T06:26:28.835Z',
  matches: [
    {
      comment: null,
      pawsAlert: ['media', 'pep-class-1'],
      matchStrength: 0.2,
      matchStatus: {
        by: null,
        status: 'unknown',
      },
      countries: ['AU', 'US'],
      dateOfBirth: null,
      name: 'Capital Partners',
      entityType: 'PERSON',
      groupId: 'd744f5c7-24fb-430d-4ca2-a60444ab2e03',
      details: {
        entityData: {
          name: 'Capital Partners',
          matchStatus: null,
          dateOfBirth: null,
          aka: ['Capital Partners'],
          countries: ['asd', 'qp', 'AU', 'US'],
          associates: [],
          addresses: [],
          imageURL: null,
          supportingDocUrls: [],
        },
        matchStatus: null,
        sanctions: [],
        watchlists: [],
        media: {
          mediaArticles: [
            [
              {
                date: '',
                pdf_url: '',
                snippet:
                  "Tex., Fort Worth Division)] (LR- 17442 ) SEC v. VESTRON FINANCIAL CORP., and others The Commission announced that on January 24, 2002, the Honorable Patricia A. Seitz (Seitz), United States District Judge for the Southern District of Florida, entered an order appointing Russell C. Weigel III, Esquire of Montello & Kenney, P.A., as receiver for Vestron Financial Corporation (Vestron Financial), Vestron Investment Club (Investment Club), Crescent Capital Partners, LP (Capital Partners) and Crescent Capital Offshore Fund (Offshore Fund). The SEC's complaint filed on October 16, 2001, alleged that Defendants Vestron Financial, Salman Shariff, Investment Club, Capital Partners and Offshore Fund (collectively referred to as Defendants) were violating the anti-fraud, registration, investment company and investment advisor provisions of the federal securities law. The SEC alleged that Defendants perpetrated a massive fraud, which duped hundreds of unsuspecting investors nationwide out of over $11 million.",
                title: '(no title)',
                url: 'https://www.sec.gov/news/digest/03-28.txt',
              },
              {
                date: '2021-10-22T00:00:00Z',
                pdf_url: '',
                snippet:
                  '888 Capital Partners became a lender to the flailing retailer in early August, agreeing to advance ABC $5.7 million in cash to keep the lights on. The terms of its bid stipulate that it would forgive the bankruptcy loan and some $8.7 million of pre-bankruptcy debt in exchange for ownership of the business should it have the winning bid, according to court papers. Earlier this month, the retailer\'s bankruptcy counsel, Oscar Pinkas of Greenberg Traurig, said at a court hearing that there had been "considerable" interest in acquiring the Big Apple business and that the investment advisor, B. Riley had been in touch with "100 interested parties."',
                title:
                  'ABC Carpet & Home is being acquired by a Persian rug dealer',
                url: 'https://nypost.com/2021/10/22/abc-carpet-home-is-being-acquired-by-a-persian-rug-dealer/',
              },
              {
                date: '2022-11-02T00:00:00Z',
                pdf_url: '',
                snippet:
                  "It urged shareholders to support its call for change at Argo by voting to elect Mr Bobman and Mr Michelson, and to not vote for Mr Ramji and Mr Bailey. Argo's squabble with Capital Partners comes just a week after news that the Police and Fire Retirement System for the City of Detroit has filed a class-action lawsuit against Argo. The plaintiffs are demanding a jury trial in the case against Argo and five officers and former officers of the company.",
                title:
                  'Boardroom battle for Argo Group - The Royal Gazette | Bermuda News, Business, Sports, Events, & Community |',
                url: 'https://www.royalgazette.com/re-insurance/business/article/20221102/boardroom-battle-for-argo-group/',
              },
              {
                date: '2011-02-07T00:00:00Z',
                pdf_url: '',
                snippet:
                  'Moreover, according to the NB, the brokerage was guilty of excessive trading, or "churning." The central bank charges that Capital Partners traded clients\' assets too frequently in order to earn extra transaction fees, which is prohibited. Capital Partners claims that all trades were carried out on the basis of orders from clients.',
                title:
                  'Capital Partners to file law suit against ČNB - ceskapozice.lidovky.cz',
                url: 'https://ceskapozice.lidovky.cz/tema/capital-partners-to-file-law-suit-against-cnb.A110207_112033_pozice_4967',
              },
              {
                date: '2021-09-20T00:00:00Z',
                pdf_url: '',
                snippet:
                  '"It is clear to me that immediate and meaningful change to the Board\'s composition is necessary to ensure that the Company is operated in a manner consistent with the best interests of all of its stockholders," said Dr. Laster, former CEO/Director of the Company and currently, its largest stockholder. Dr. Laster added, "I am alarmed at allegations of malfeasance, misappropriation of Company assets, and corporate governance failures allegedly committed by Board members affiliated with HCFP/Capital Partners ("HCFP"), which Mr. Ashish Sanghrajka, the Company\'s former President/CFO and currently a member of the Board, has shed light on, as detailed in his complaint, Ashish Sanghrajka v. Scopus BioPharma Inc., HCFP/Capital Partners, et al., number 655159/2021 (N.Y. Sup. Ct.) recently filed in the New York State Supreme Court.',
                title:
                  "Dr. Morris C. Laster Files Preliminary Proxy Statement Nominating Two Highly-Qualified Individuals For Election To Board At Scopus BioPharma Inc.'s 2021 Annual Meeting",
                url: 'https://www.prnewswire.com/news-releases/dr-morris-c-laster-files-preliminary-proxy-statement-nominating-two-highly-qualified-individuals-for-election-to-board-at-scopus-biopharma-incs-2021-annual-meeting-301380435.html',
              },
              {
                date: '2021-12-14T00:00:00Z',
                pdf_url: '',
                snippet:
                  'One contributor was promoter Jack Fisher, who faces a U.S. investigation after two Atlanta accountants in his network pleaded guilty and one was indicted, Bloomberg has reported . Another was Ornstein-Schuler Capital Partners, which faces investor lawsuits related to syndicated deals. EcoVest and Partnership for Conservation hired lobbyists at six firms including Covington & Burling and Holland & Knight , government records show.',
                title:
                  'Fans of Tax Breaks on Green Land Fight U.S. Curbs on Deals (1)',
                url: 'https://news.bloomberglaw.com/daily-tax-report-international/green-tax-break-sellers-try-to-block-congressional-curb-on-deals?context=article-related',
              },
              {
                date: '2020-10-29T00:00:00Z',
                pdf_url: '',
                snippet:
                  '"Plaintiffs cannot demonstrate that joinder is impracticable with only 20 class members." In the lawsuit filed by the two lead plaintiffs, 683 Capital Partners, LP, and Shipco Transport Inc., the defendant was accused of securities fraud. The lawsuit further alleged that Global Brokerage falsely represented its purported agency-trading model and its relation with Effex Capital.',
                title:
                  'Global Brokerage Seeks Denial of Potential Class Members',
                url: 'https://www.financemagnates.com/forex/brokers/global-brokerage-seeks-denial-of-potential-class-members/amp/',
              },
              {
                date: '2023-09-16T00:00:00Z',
                pdf_url: '',
                snippet:
                  "Everton's sale to Miami-based investment firm 777 Capital Partners is at risk of collapsing (co-founder Josh Wander pictured) [...] The latest financial results showed Everton made a £24.5million operating loss in 2022",
                title:
                  'IN THE MONEY: Can Everton really find their way out of a £400m hole? Sale of the Toffees to Miami-based investors 777 Partners could be at risk of collapse | Daily Mail Online',
                url: 'https://www.dailymail.co.uk/sport/football/article-12527047/IN-MONEY-Everton-really-way-400m-hole-Sale-club-Miami-based-investors-risk-collapse.html?ico=topics_pagination_desktop',
              },
              {
                date: '2024-02-20T00:00:00Z',
                pdf_url: '',
                snippet:
                  'But the denial was reversed by the Building Inspection Advisory, Examining and Appeals Board in a 5-1 ruling. To prevent the construction, the church then took the board and Stonelake Capital Partners, a real estate investment firm in control of SL6, the limited partnership entity listed for the project, to court. "Based upon the law and the facts, and to prevent the very real threat of immediate and irreparable harm to the community, the Court made the just and equitable determination that the injunction should be issued," Paul Stafford, the lawyer representing the church, said in a statement to The Dallas Morning News.',
                title:
                  'Judge grants injunction, sides with megachurch to prevent warehouse in Southern Dallas',
                url: 'https://www.dallasnews.com/news/2024/02/20/judge-grants-injunction-sides-with-megachurch-to-prevent-warehouse-in-southern-dallas/?outputType=amp',
              },
              {
                date: '',
                pdf_url: '',
                snippet:
                  'It alleged that the latter said no real estate brokerage commissions were to be paid in relation to the sale. Denying that it conspired with the other parties to cut Mr Damianos out, and therefore save on his commission, CrossHarbor Capital Partners also rejected claims that itself and Mr Rich "induced" Bank of the Bahamas to breach the listing agreement. CrossHarbor Capital Partners and its acquisition vehicle argued that the latter should be struck out as a defendant because Mr Damianos\' claim "fails to disclose a reasonable cause of action", alleging only vaguely that they combined with Mr Rich to influence Bank of The Bahamas to breach the listing agreement.',
                title:
                  "Top realtor's $432k claim survives US buyer's strike out | The Tribune",
                url: 'http://www.tribune242.com/news/2021/mar/29/top-realtors-432k-claim-survives-us-buyers-strike-/?news',
              },
            ],
          ],
          mediaMatches: [
            {
              name: 'company AM',
              source: 'company-am',
              items: [
                {
                  key: 'Name',
                  item: 'company AM',
                },
                {
                  key: 'AML Types',
                  item: 'adverse-media,adverse-media-financial-crime,adverse-media-fraud,adverse-media-general',
                },
                {
                  key: 'requested_check_type',
                  item: 'pep_media',
                },
                {
                  key: 'original_checksource',
                  item: 'company-am',
                },
              ],
            },
          ],
        },
        pepsPep: [],
        pepsPepClass1: [],
        pepsPepClass2: [],
        pepsPepClass3: [],
        pepsPepClass4: [],
        supportingDocuments: [],
      },
    },
    {
      comment: null,
      pawsAlert: ['media'],
      matchStrength: 0.2,
      matchStatus: {
        by: null,
        status: 'false_positive',
      },
      countries: ['US'],
      dateOfBirth: null,
      name: 'Capital Partners',
      entityType: 'PERSON',
      groupId: '330d32f7-6069-d9a2-ad51-c8b3441a0ebf',
      details: {
        entityData: {
          name: 'Capital Partners',
          matchStatus: null,
          dateOfBirth: null,
          aka: ['Capital Partners'],
          countries: ['US', 'PO'],
          associates: [],
          addresses: [],
          imageURL: null,
          supportingDocUrls: [],
        },
        matchStatus: null,
        sanctions: [],
        watchlists: [],
        media: {
          mediaArticles: [
            [
              {
                date: '',
                pdf_url: '',
                snippet:
                  "Defendant, U.S. Manufacturing, LLC (USM), is the successor in interest of U.S. Manufacturing Corporation. On February 9, 2017, plaintiff, plaintiff's parent company Dana Incorporated, U.S. Manufacturing Corporation, and defendant Wynnchurch Capital Partners, III, LP (Wynnchurch), a limited guarantor for USM, executed the UPA. The closing date was March 1, 2017.",
                title:
                  'Dana Light Axle Manufacturing Llc V Us Manufacturing Llc :: 2022 :: Michigan Court of Appeals - Unpublished Opinions Decisions :: Michigan Case Law :: Michigan Law :: US Law :: Justia',
                url: 'https://law.justia.com/cases/michigan/court-of-appeals-unpublished/2022/356985.html',
              },
            ],
          ],
          mediaMatches: [
            {
              name: 'company AM',
              source: 'company-am',
              items: [
                {
                  key: 'Name',
                  item: 'company AM',
                },
                {
                  key: 'Country Codes',
                  item: 'US',
                },
                {
                  key: 'AML Types',
                  item: 'adverse-media,adverse-media-general',
                },
                {
                  key: 'Country',
                  item: 'United States',
                },
                {
                  key: 'Original Country Text',
                  item: 'United States',
                },
                {
                  key: 'requested_check_type',
                  item: 'pep_media',
                },
                {
                  key: 'original_checksource',
                  item: 'company-am',
                },
              ],
            },
          ],
        },
        pepsPep: [],
        pepsPepClass1: [],
        pepsPepClass2: [],
        pepsPepClass3: [],
        pepsPepClass4: [],
        supportingDocuments: [],
      },
    },
    {
      comment: 'w',
      pawsAlert: ['media', 'pep-class-1'],
      matchStrength: 17.75,
      matchStatus: { by: null, status: 'true_positive' },
      countries: [
        'PH',
        'MX',
        'ER',
        'LK',
        'AU',
        'CA',
        'AO',
        'UA',
        'BE',
        'RU',
        'LR',
        'DE',
        'KZ',
        'US',
        'TW',
        'JP',
        'GB',
        'CM',
        'JM',
      ],
      dateOfBirth: '1948',
      name: 'Hillary Rodham Clinton',
      entityType: 'PERSON',
      groupId: '795d05f9-a5d0-6066-f312-a64da56038a8',
      details: {
        entityData: {
          name: 'Hillary Rodham Clinton',
          matchStatus: 'true_positive',
          dateOfBirth: '1948',
          aka: [
            'Khilary Klintan',
            'hylry qlyntvn',
            'हिलेरी रोधम क्लिन्टन',
            'hilerii roddhm kliNttn',
            'હિલેરી ક્લિન્ટન',
            'Khillari Rodema Klinton',
            'ဟီလာရီ ရော်ဒမ် ကလင်တန်',
            'Хилъри Клинтън',
            'hilaari klinttn',
            '힐러리 클린턴',
            'hilreori keulrinteon',
            'H. Rodham-Clinton',
            'ഹിലരി ക്ലിന്റൺ',
            'hylry klyntwn',
            'Hillary Diane Rodham Clinton',
            'ہیلری کلنٹن',
            'hiilaarii reaa[?]dm[?] klng[?]ttn[?]',
            'हिलेरी रोढम क्लिंटन',
            'ਹਿਲੇਰੀ ਕਲਿੰਟਨ',
            'hillaarii khlintan',
            'Хиллари Клинтон',
            'ฮิลลารี คลินตัน',
            'Hilarija Klintone',
            'Ҳиллари Клинтон',
            "Khil'ri Klint'n",
            'Khilari Klinton',
            'hirarikurinton',
            'Hillari Klinton',
            'Hillaru Clinton',
            'Հիլարի Քլինթոն',
            'Hillary Clintonn',
            'Rodham Clinton',
            'হিলাৰি ক্লিণ্টন',
            'Clinton Hillary',
            'Xi La Li *Ke Lin Dun',
            'Hilliary Clinton',
            'Khillari Klinton',
            'হিলারি ক্লিনটন',
            'hilri klinrr[?]',
            'Hillary',
            'hylry khlyntwn',
            'هیلاری کلینتون',
            'Хиллари Дайана Родэма Клинтон',
            'Хілары Клінтан',
            'ヒラリー・クリントン',
            'הילרי קלינטון',
            'Hillary Clintonova',
            'Hillary Clinton',
            'ହିଲାରୀ କ୍ଲିଣ୍ଟନ',
            'Hilary Clinton',
            'Hilari K`lint`on',
            'Χίλαρι Κλίντον',
            'hilerii klinttn',
            'Hillary Clintonová',
            'Hilary Rodham',
            'Hillary Rodman Clinton',
            'hilrii kliNttn',
            'Hillary Rodham',
            'Hiliary Clinton',
            'hilaarii klinnttn',
            'hilari rodehaame kelinetane',
            'Хиллари Родэма Клинтон',
            'Hillar R. Clinton',
            'hilerii rodhm klinttn',
            'Gillari Klinton',
            'Гілларі Клінтон',
            'Һиллари Клинтон',
            'Hillary Rhodam Clinton',
            'H. Clinton',
            'ሂለሪ ሮድሃም ክሊንተን',
            'Khillari Daiana Rodema Klinton',
            'هيلاري كلينتون',
            "hilaar'i klinnttn",
            'Хилари Клинтон',
            '希拉里·克林顿',
            'CLINTON',
            'இலரி கிளின்டன்',
            "Kh'illari Klinton",
            'Hillary R. Clinton',
            'Clinton Hilary',
            'Hillary Clinton Rodham',
            'hylry khlnttn',
            'हिलरी क्लिंटन',
            'ilri killinnnttnnn',
            'hileerii kliNttn',
            'Hilaria Clinton',
            'Hillary Rodh am Clinton',
            'Clin-ton',
            'Hillary Rodham Clinton',
          ],
          countries: [
            'PH',
            'MX',
            'ER',
            'LK',
            'AU',
            'CA',
            'AO',
            'UA',
            'BE',
            'RU',
            'LR',
            'DE',
            'KZ',
            'US',
            'TW',
            'JP',
            'GB',
            'CM',
            'JM',
          ],
          associates: [
            { relation: 'spouse', name: 'Bill Clinton' },
            { relation: 'child', name: 'Chelsea Clinton' },
            { relation: 'relative', name: 'Dorothy Emma Howell Rodham' },
            { relation: 'parent', name: 'Dorothy Howell Rodham' },
            { relation: 'parent', name: 'Hugh Rodham' },
            { relation: 'sibling', name: 'Hugh Rodham' },
            { relation: 'sibling', name: 'Tony Rodham' },
            {
              relation: 'spouse',
              name: 'William Jefferson "Bill" Clinton',
            },
            { relation: 'relative', name: 'Zachary Boxer Rodham' },
          ],
          addresses: [
            '21 Street, Town ACT 1111, Australia',
            '710 Collins Street, Docklands VIC 3008, Australia',
          ],
        },
        matchStatus: 'true_positive',
        sanctions: [],
        watchlists: [],
        media: {
          mediaArticles: [
            [
              {
                date: '2018-11-21T00:00:00Z',
                pdf_url: '',
                snippet:
                  ': //www.generaccion.com/notici a/228148/senadores-exigen-respuesta-firme-donald-trump-sobre-papel-principe-heredero-saudi-asesinato-jamal-khashoggi Donald Trump quiso acusar a Hilary Clinton :',
                title: '(no title)',
                url: 'http://www.generaccion.com/noticia/rss/',
              },
              {
                date: '2019-08-23T00:00:00Z',
                pdf_url: '',
                snippet:
                  "FBI Director James Comey dealt the HRC's campaign a monumental blow from which it failed to recover. Weeks from the election, Comey announced that new emails discovered on a computer owned by HRC's aide invited further investigation to determine, if it had any links to the her private server case, closed earlier. Her lead in the polls started to disappear and matters got worse from there on.",
                title:
                  "An Autopsy of Unity Party: A Democratic Party's Reckoning Moment – FrontPageAfrica",
                url: 'https://frontpageafricaonline.com/opinion/an-autopsy-of-unity-party-a-democratic-party-s-reckoning-moment/',
              },
              {
                date: '2018-11-23T00:00:00Z',
                pdf_url: '',
                snippet:
                  "The UN Charter is a fundamental US treaty obligation, defined by Article VI of the Constitution as the \"supreme law of the land.\" I shouldn't need to remind this writer of the Contra Wars in Nicaragua and El Salvador, the Gulf War followed by Clinton's murderous sanctions on Iraq killing at least a half-million children that his monstrous Sec'y of State and close friend of Hillary, Madeline Albright, found perfectly acceptable, followed by the gratuitous assaults on Afghanistan and Iraq, decapitating and largely destroying both countries without a shred of justification under int ernational or US law. And please don't forget the Ukraine coup orchestrated by Victoria Nuland to move NATO up to Russia's borders.",
                title: 'Article: But What Do We Impeach Him For? | OpEdNews',
                url: 'https://www.opednews.com/articles/But-What-Do-We-Impeach-Him-by-David-Swanson-IMPEACHMENT-OFF-THE-TABLE-PELOSI_Impeach_Impeach-Trump_Impeached-181122-969.html?show=votes',
              },
              {
                date: '2020-01-16T00:00:00Z',
                pdf_url: '',
                snippet:
                  "Right? But Secretary Clinton was guilty until proven innocent and still guilty even though she has been proven innocent. It's a known fact that Congress withdrew $330 million dollars from security in Benghazi.",
                title:
                  'At the DNC All I Saw in HRC Was a Mother, Wife, and Crusader for What is Right in America - The Tennessee Tribune',
                url: 'https://tntribune.com/politics/dnc-saw-hrc-mother-wife-crusader-right-america/',
              },
              {
                date: '2019-10-29T00:00:00Z',
                pdf_url: '',
                snippet:
                  'from foreign interference to campaign finance. During the same election cycle, the Clinton machine orchestrated an $84 million money laundering scheme to funnel excessive six-figure contributions into her campaign. The single largest campaign finance scandal in U.S. history involved six-figure checks from mega-donors like fashion icon Calvin Klein and "Family Guy" creator Seth MacFarlane to the "Hillary Victory Fund."',
                title:
                  "BACKER: Bitter Hillary Clinton Doesn't Like Tulsi Gabbard, Donald Trump — Or Anyone Else | The Daily Caller",
                url: 'https://dailycaller.com/2019/10/29/backer-bitter-hillary-clinton/',
              },
              {
                date: '2020-05-09T00:00:00Z',
                pdf_url: '',
                snippet:
                  "- A Pennsylvania woman who would have been a delegate for Hillary Rodham Clinton at this week's Democratic National Convention will instead be in court Tuesday to face charges of attempted murder in a case that suggests restrictive gun laws supported by Clinton have once again failed, the Citizens Committee for the Right to Keep and Bear Arms said today. The case involves former at-large Pennsylvania delegate Deanna Vicites.",
                title: 'Canada Free Press',
                url: 'https://canadafreepress.com/archive-month/2016/07/P140',
              },
              {
                date: '2020-04-17T00:00:00Z',
                pdf_url: '',
                snippet:
                  'a harbinger of the charges that would result in his federal conviction two years later. Just after Trump\'s primary win in New York, Caputo introduced Stone to a Florida-based Russian named Henry Oknyansky (aka Henry Greenberg), who was fronting for yet another Florida-based Russian who claimed to have evidence that Hillary Clinton had been involved in money laundering. According to the Mueller report, Stone refused because "Trump would not pay for opposition research"',
                title: 'Casey Seiler: Caputo in Trump heaven',
                url: 'https://www.timesunion.com/opinion/article/Casey-Seiler-Caputo-in-Trump-heaven-15209096.php',
              },
              {
                date: '2019-04-28T00:00:00Z',
                pdf_url: '',
                snippet:
                  "Kelli Ballard A former State Department employee, who worked under both Bill and Hillary Clinton, pleaded guilty to a charge of conspiracy to defraud the United States on April 24. According to the Department of Justice's (DOJ) press release, Candace Marie Claiborne, 63, committed the crime by",
                title:
                  'Clinton Official pleads guilty to conspiracy charges - Blitz',
                url: 'https://www.weeklyblitz.net/news/clinton-official-pleads-guilty-to-conspiracy-charges/',
              },
              {
                date: '2019-03-12T00:00:00Z',
                pdf_url: '',
                snippet:
                  '"Based on every analysis that I can find by people who are sympathetic to the goal, the numbers don \'t add up and many people will be worse off than they are right now, " Clinton said. Continuing her assault, Clinton embraced a typical Republican line of attack to demonise Sanders, saying his plans would likely increase the size of the federal government by about 40 per cent. "We have a special obligation to make clear what we stand for, which is why I think we should not make promises we can \'t keep, " Clinton said.',
                title:
                  "Clinton slams unrealistic Sanders 'promises' in debate - World - DAWN.COM",
                url: 'https://www.dawn.com/news/1239039/clinton-slams-unrealistic-sanders-promises-in-debate',
              },
              {
                date: '2020-05-28T00:00:00Z',
                pdf_url: '',
                snippet:
                  'Hope you get the point. But notice Ronald made no mention of the Evil setup by the Caucasian Christian led by the French and Sexual Predator Bill Clinton and his wife Hillary Rodham who stole millions of dollars that was donated to the Africans on Haiti, building only six shacks that they claim cost thirty million dollars. NOTICE HOW CONVENIENTLY RONALD LEFT OUT THE CAUSES OF THE DISASTER.',
                title: 'Comments on: Raise up Haiti from its Knees',
                url: 'https://www.thevoiceslu.com/2016/10/raise-haiti-knees/feed/',
              },
              {
                date: '2020-02-29T00:00:00Z',
                pdf_url: '',
                snippet:
                  'Maldita por querer más y luchar por ello, por ser quien es y defenderlo. La cultura del sueño americano, que aúpa al luchador y perseverante, al mismo tiempo se empeña en condenar a Hillary Rodham Clinton por ser mujer. Dueña de un fascinante compendio de vida, parecería imposible condensarlo en una serie documental de cuatro capítulos.',
                title: 'Documental Hillary Superstar - ELESPECTADOR.COM',
                url: 'https://www.elespectador.com/node/906987',
              },
              {
                date: '2020-05-17T00:00:00Z',
                pdf_url: '',
                snippet:
                  'They include messages from the far-right QAnon conspiracy theory movement, who groundlessly claim that a cabal of child-abusing Democrats and Hollywood stars secretly manipulate world events. In April, he suggested that Hillary Clinton may have had a role in poisoning Jeffrey Epstein, the financier who killed himself in a New York jail cell in August while awaiting trial on child trafficking charges. In June, he was denounced for tweeting.',
                title:
                  "Donald Trump Jr launches baseless 'pedophile' attack on Joe Biden - Business Insider",
                url: 'https://www.businessinsider.com/donald-trump-jr-launches-baseless-pedophile-attack-on-joe-biden-2020-5?IR=T&auto=webp&format=jpeg&r=US&width=600',
              },
              {
                date: '2019-07-18T00:00:00Z',
                pdf_url: '',
                snippet:
                  'Omar and Ocasio-Cortez cannot legally be "sent back" anywhere. Like the chants calling for Hillary Clinton to be jailed, the outburst serves the purpose of vocalising the illegal and irrational fantasies of reactionary voters. But Trump\'s new strategy is far more than rhetoric.',
                title:
                  "Donald Trump's attacks on Ilhan Omar show fascism is coming to the US",
                url: 'https://www.newstatesman.com/world/north-america/2019/07/donald-trumps-attacks-ilhan-omar-show-fascism-coming-us?qt-trending=0',
              },
              {
                date: '2019-12-07T00:00:00Z',
                pdf_url: '',
                snippet:
                  'Serbs The United States has held a meeting with representatives of the Haqqani militant network, the US Secretary of State, Hiliary Clinton revealed on Friday, during current talks in Afghanistan and Pakistan. Speaking to journalists in Pakistan, Clinton said, "We believe that there is now an opportunity for us to begin talking, but there is no guarantee that the talking will resu lt in anything that will move us toward a peaceful resolution."',
                title: 'International Affairs | Page 578 | Tamil Guardian',
                url: 'https://www.tamilguardian.com/news-region/international-affairs?page=577',
              },
              {
                date: '2019-12-31T00:00:00Z',
                pdf_url: '',
                snippet:
                  'Gaddafi was the leader of the most stable and prosperous country in North Africa, and had given up both on terrorism and on nuclear weapons. And Hillary Clintonn cackled about his anal bayonet rape and murder by the islamist terrorists who she had supported. Trying to relativize that is tasteless.',
                title: 'Japan Today',
                url: 'https://japantoday.com/member/willib',
              },
              {
                date: '2018-12-18T00:00:00Z',
                pdf_url: '',
                snippet:
                  "Elle a mis un accent particulier sur les électeurs noirs d'après l'analyse la plus complète à ce jour des milliers de messages et publications diffusés sur les réseaux sociaux par l'IRA, entre 2015 et 2017, menée conjointement par l'université d'Oxford et des spécialistes des nouveaux médias Graphika. Hilary Clinton accusée d'être opportuniste L'autre rapport commandé par le Sénat a lui été élaboré par la compagnie New Knowledge et l'université de Columbia notamment. L'IRA avait ainsi créé de nombreux comptes sous de faux profils américains destinés à la communauté noire.",
                title:
                  "L'ingérence russe visait à inciter les Noirs à s'abstenir à la présidentielle américaine",
                url: 'https://www.france24.com/fr/20181218-etats-unis-ingerence-russe-visait-inciter-noirs-abstention-presidentielle-americaine',
              },
              {
                date: '2020-05-20T00:00:00Z',
                pdf_url: '',
                snippet:
                  'His column clearly indicates his disdain for all of the Arkansans who voted for President Trump. I take it that he would have loved to have the totally corrupt Hillary Rodham Clinton in the White House so that she could have further destroyed this country. It seems the national Democrat Party loves murdering unborn babies, and thank God President Trump supports the right to life.',
                title:
                  'LETTERS TO THE EDITOR: All about Perspective | Long for rebuilding | Disdain for president',
                url: 'https://www.nwaonline.com/news/2020/may/20/letters-20200520/',
              },
              {
                date: '2018-11-28T00:00:00Z',
                pdf_url: '',
                snippet:
                  "entre luttes et poésie. Cindy Wiesner, du Grassroot global justice alliance (USA) accuse Hilary Clinton, en campagne pour la présidentielle, d'avoir trempé dans le coup d'État au Honduras qui a écarté le trop progressiste Manuel Zelaya du pouvoir. Soniamara Maranho, du Movimiento dos atingidos por barragens du Brésil frémit en évoquant l'horreur de Mariana, le déversement de millions de tonnes de déchets toxiques dans une rivière de son pays, « l'une des pires catastrophes fomentée par le libéralisme » .",
                title:
                  'La voix des peuples avant le profit par Patrick Piro | Politis',
                url: 'https://www.politis.fr/blogs/2016/08/la-voix-des-peuples-avant-le-profit-34100/',
              },
              {
                date: '2019-04-08T00:00:00Z',
                pdf_url: '',
                snippet:
                  'Allen, 801 P.2d at 725; see also Harder v. F.C. Clinton, Inc.,   948 P.2d 298 , 303 (Okla. 1997) ("Once the foundation facts for res ipsa loquitur are established, negligence may be inferred from the injurious occurrence without the aid of circumstances pointing to the responsible cause.") (internal footnote omitted) (emphasis altered from original).',
                title:
                  'SCHULZE v. U.S. | Case No. 18-CV-00130-GKF-JFJ... | 20190409e41| Leagle.com',
                url: 'https://www.leagle.com/decision/infdco20190409e41',
              },
              {
                date: '2018-11-29T00:00:00Z',
                pdf_url: '',
                snippet:
                  '"All Jews must die." The shooting came the day after federal authorities arrested a man in Florida for mailing explosive devices to critics of Trump, including former US secretary of state Hillary Rodh am Clinton and former US president Barack Obama. Justice department spokeswoman Kelly Laco, in response to Nadler\'s letter, pointed to a list of hate crime cases the department has brought since last year.',
                title: 'Taipei Times',
                url: 'http://www.taipeitimes.com/News/world/print/2018/11/29/2003705162',
              },
              {
                date: '2020-05-01T00:00:00Z',
                pdf_url: '',
                snippet:
                  'In 1984, singer Michael Jackson suffered serious burns to his scalp when pyrotechnics set his hair on fire during the filming of a Pepsi-Cola TV commercial at the Shrine Auditorium in Los Angeles. In 1998, first lady Hillary Rodham Clinton, on NBC\'s "Today" show, charged the sexual misconduct allegations against her husband, President Bill Clinton, were the work of a "vast right-wing conspiracy." Ten years ago',
                title:
                  'Today in History | News, Sports, Jobs - The Herald Star',
                url: 'https://www.heraldstaronline.com/news/national-news-apwire/2019/01/today-in-history-727/',
              },
              {
                date: '2020-03-14T00:00:00Z',
                pdf_url: '',
                snippet:
                  "Any wonder that her foreign policies and penchant for murderous operations concurs with the regional machinations of these same despots. The ongoing FBI investigation into Clinton's violation of classified government information from using her private, insecure email account is but one illustration of just how dangerous this person is. She considers herself above the law, and given her propensity to sell her policies to the highest bidder that makes for a highly corrupt and unscrupulous leader of the world's biggest nuclear power.",
                title:
                  'Warmongering Clinton Accuses Trump of Madness - Sputnik International',
                url: 'https://sputniknews.com/columnists/201606041040774456-us-elections-clinton-trump/',
              },
              {
                date: '2019-04-26T00:00:00Z',
                pdf_url: '',
                snippet:
                  'Ассанж назвал выдуманными обвинения Хилари Клинтон в сговоре WikiLeaks и правительства России Ассанж назвал выдуманными обвинения Хилари Клинтон в сговоре WikiLeaks и правительства России Пресс-релиз +7 727 250 13 90, akzhan@interfax.kz Корреспондентский пункт г. Нур-Султан',
                title:
                  'Ассанж назвал выдуманными обвинения Хилари Клинтон в сговоре WikiLeaks и правительства России',
                url: 'https://www.interfax.kz/?int_id=21&lang=rus&news_id=66334',
              },
              {
                date: '2020-01-25T00:00:00Z',
                pdf_url: '',
                snippet:
                  'Ранее Клинтон обвинила Коми в уничтожении ее шансов на победу после информирования Конгресса о следствии. Клинтон обвиняли в халатном отношении к служебной переписке во время работы госсекретарем в 2009-2013 годах.',
                title:
                  'Вести.Ru: Коми раскрыл данные по Клинтон, чтобы легитимизировать ее',
                url: 'https://www.vesti.ru/doc.html?id=3007247&tid=110745',
              },
              {
                date: '2020-01-30T00:00:00Z',
                pdf_url: '',
                snippet:
                  'сказал он. Республиканец заявил, что Клинтон должна сидеть в тюрьме.',
                title: 'Вести.Ru: ФБР и Госдеп сговорились против Клинтон',
                url: 'https://www.vesti.ru/doc.html?cid=5&id=2810655',
              },
              {
                date: '2020-01-26T00:00:00Z',
                pdf_url: '',
                snippet:
                  'Он, по ее словам, признает Крым частью России, заявляет об отказе от поддержки союзников НАТО. Напомним, что ранее в предвыборном штабе Хиллари Клинтон обвинили Трампа в финансовых связях с РФ. Бывший глава ЦРУ США Майкл Морелл назвал Дональда Трампа агентом влияния России.',
                title:
                  'Клинтон назвала Путина «крестным отцом национализма» › Новости Санкт-Петербурга › MR-7.ru',
                url: 'https://mr-7.ru/articles/139040/',
              },
              {
                date: '2020-03-02T00:00:00Z',
                pdf_url: '',
                snippet:
                  'И уже в грядущий завтра час "Ч" праймериз, так называемый "супервторник " , с вероятностью 75-80% "левак" Сандерс может обеспечить себе номинацию от демократов на президентских выборах в ноябре 2020 года. Напомню, что в 2016 -м по мнению многих экспертов , не говоря уже о сторонниках самого Сандерса и его лично, у него попросту украли победу над Хиллари клинтон. И многие из его сторонников либо не пришли на выборы чтобы проголосовать за Клинтон, либо вовсе проголосовали за Трампа.',
                title:
                  'Максим Яли: Помешает ли короновирус Трампу победить на выборах?',
                url: 'https://www.obozrevatel.com/abroad/pomeshaet-li-koronovirus-trampu-pobedit-na-vyiborah/amp.htm',
              },
              {
                date: '2020-02-12T00:00:00Z',
                pdf_url: '',
                snippet:
                  'Инициаторы возобновления расследования являются представителями Республиканской партии. Ранее ФБР не стало начинать уголовное преследование Хиллари Клинтон в связи с этим делом. Следите за новостями в Петербурге, России и во всём мире в удобном для вас формате',
                title:
                  'Минюст США просят снова дать ход «делу Хиллари Клинтон» › Новости Санкт-Петербурга › MR-7.ru',
                url: 'https://mr-7.ru/articles/135688/',
              },
              {
                date: '2019-01-23T00:00:00Z',
                pdf_url: '',
                snippet:
                  'Независимая газета Трамп прозрачно намекнул на то, что место Хилари Клинтон в тюрьме /',
                title:
                  'Трамп прозрачно намекнул на то, что место Хилари Клинтон в тюрьме / Новости / Независимая газета',
                url: 'http://www.ng.ru/news/626060.html',
              },
              {
                date: '2020-01-30T00:00:00Z',
                pdf_url: '',
                snippet:
                  'AP Кандидата в президенты США от демократов Хиллари Клинтон обвинили в присвоении мебели в Госдепартаменте, чтобы декорировать свои апартаменты в Вашингтоне, сообщает New York Post. Экс-сотрудник охраны Клинтон рассказал, что политик и ее подчиненные были замечены в краже ламп и предметов мебели.',
                title:
                  'Хиллари Клинтон обвиняют в краже мебели › Новости Санкт-Петербурга › MR-7.ru',
                url: 'https://mr-7.ru/articles/143332/',
              },
            ],
          ],
          mediaMatches: [
            {
              name: 'ComplyAdvantage Adverse Media',
              source: 'complyadvantage-adverse-media',
              items: [
                { key: 'Name', item: 'ComplyAdvantage Adverse Media' },
                { key: 'Source URL', item: 'https://complyadvantage.com' },
                {
                  key: 'Country Codes',
                  item: 'DE,PH,AO,TW,JP,KZ,MX,ER,UA,BE,RU,GB,LK,US,AU,CM',
                },
                {
                  key: 'AML Types',
                  item: 'adverse-media-fraud,adverse-media-sexual-crime,adverse-media-financial-crime,adverse-media-violent-crime,adverse-media,adverse-media-general,adverse-media-terrorism,adverse-media-narcotics',
                },
                { key: 'Country', item: 'Angola' },
                { key: 'Country', item: 'Australia' },
                { key: 'Country', item: 'Belgium' },
                { key: 'Country', item: 'Cameroon' },
                { key: 'Country', item: 'Eritrea' },
                { key: 'Country', item: 'Germany' },
                { key: 'Country', item: 'Japan' },
                { key: 'Country', item: 'Kazakhstan' },
                { key: 'Country', item: 'Mexico' },
                { key: 'Country', item: 'Philippines' },
                { key: 'Country', item: 'Russian Federation' },
                { key: 'Country', item: 'Sri Lanka' },
                { key: 'Country', item: 'Taiwan' },
                { key: 'Country', item: 'Ukraine' },
                { key: 'Country', item: 'United Kingdom' },
                { key: 'Country', item: 'United States' },
                { key: 'Original Country Text', item: 'Angola' },
                {
                  key: 'Original Country Text',
                  item: 'Australia, Philippines, United States',
                },
                {
                  key: 'Original Country Text',
                  item: 'Belgium, Sri Lanka, United Kingdom',
                },
                { key: 'Original Country Text', item: 'Cameroon' },
                {
                  key: 'Original Country Text',
                  item: 'Eritrea, United States',
                },
                { key: 'Original Country Text', item: 'Germany' },
                { key: 'Original Country Text', item: 'Japan' },
                { key: 'Original Country Text', item: 'Kazakhstan' },
                {
                  key: 'Original Country Text',
                  item: 'Mexico, United States',
                },
                { key: 'Original Country Text', item: 'Russia' },
                { key: 'Original Country Text', item: 'Russia, Ukraine' },
                { key: 'Original Country Text', item: 'Taiwan' },
                { key: 'Original Country Text', item: 'United States' },
                { key: 'Date of Birth', item: '1933' },
                { key: 'Date of Birth', item: '1948' },
                {
                  key: 'original_checksource',
                  item: 'complyadvantage-adverse-media',
                },
                {
                  key: 'status.true_positive',
                  item: '01EADR0EABY51C04TH5FG7TD71',
                },
                { key: 'status.current', item: 'true_positive' },
                { key: 'status.comment', item: 'w' },
                {
                  key: 'status.manual_update',
                  item: '01EADR0EABY51C04TH5FG7TD71',
                },
                { key: 'status.set_date', item: '2020-06-09T23:52:51Z' },
              ],
            },
            {
              name: 'company AM',
              source: 'company-am',
              items: [
                { key: 'Name', item: 'company AM' },
                { key: 'Country Codes', item: 'JM,LR,CA,US,AU' },
                {
                  key: 'AML Types',
                  item: 'adverse-media,adverse-media-financial-crime,adverse-media-general',
                },
                { key: 'Country', item: 'Australia' },
                { key: 'Country', item: 'Canada' },
                { key: 'Country', item: 'Jamaica' },
                { key: 'Country', item: 'Liberia' },
                { key: 'Country', item: 'United States' },
                {
                  key: 'Original Country Text',
                  item: 'Australia, Canada, United States',
                },
                {
                  key: 'Original Country Text',
                  item: 'Canada, United States',
                },
                {
                  key: 'Original Country Text',
                  item: 'Jamaica, United States',
                },
                { key: 'Original Country Text', item: 'Liberia' },
                { key: 'Original Country Text', item: 'United States' },
                {
                  key: 'Adverse Media Subtypes',
                  item: 'corruption, other-crime, security',
                },
                {
                  key: 'Adverse Media Subtypes',
                  item: 'litigation, other-crime, security',
                },
                {
                  key: 'Adverse Media Subtypes',
                  item: 'litigation, security',
                },
                {
                  key: 'Adverse Media Subtypes',
                  item: 'other-crime, security',
                },
                { key: 'Adverse Media Subtypes', item: 'security' },
                { key: 'original_checksource', item: 'company-am' },
                {
                  key: 'status.true_positive',
                  item: '01EADR0EABY51C04TH5FG7TD71',
                },
                { key: 'status.current', item: 'true_positive' },
                { key: 'status.comment', item: 'w' },
                {
                  key: 'status.manual_update',
                  item: '01EADR0EABY51C04TH5FG7TD71',
                },
                { key: 'status.set_date', item: '2020-06-09T23:52:51Z' },
              ],
            },
          ],
        },
        pepsPep: [],
        pepsPepClass1: [
          {
            name: 'ComplyAdvantage PEP Data',
            source: 'complyadvantage',
            class: 'pepsPepClass1',
            items: [
              { key: 'Name', item: 'ComplyAdvantage PEP Data' },
              { key: 'AML Types', item: 'pep-class-1' },
              { key: 'Country', item: 'United States' },
              {
                key: 'Original Place of Birth Text',
                item: 'Edgewater Hospital',
              },
              { key: 'Original Country Text', item: 'United States' },
              {
                key: 'Original Country Text',
                item: 'United States of America',
              },
              {
                key: 'Original Country Text',
                item: ', Diplomat, Politician, President of the US',
              },
              { key: 'Nationality', item: 'United States' },
              {
                key: 'Countries',
                item: 'Angola, Australia, Belgium, Cameroon, Canada, Eritrea, Germany, Jamaica, Japan, Kazakhstan, Liberia, Mexico, Philippines, Russian Federation, Sri Lanka, Taiwan, Ukraine, United Kingdom, United States',
              },
              { key: 'Date of Birth', item: '1947-10-26' },
              {
                key: 'Political Position',
                item: 'American politician, senator, and U.S. Secretary of State',
              },
              { key: 'Political Position', item: 'Autobiographer' },
              { key: 'Political Position', item: 'Board Member' },
              { key: 'Political Position', item: 'Chairperson' },
              {
                key: 'Political Position',
                item: 'Child of Dorothy Emma Howell Rodham',
              },
              { key: 'Political Position', item: 'Diplomat' },
              {
                key: 'Political Position',
                item: 'First Lady Of The United States',
              },
              { key: 'Political Position', item: 'Intern' },
              { key: 'Political Position', item: 'Lawyer' },
              { key: 'Political Position', item: 'Partner' },
              { key: 'Political Position', item: 'Politician' },
              { key: 'Political Position', item: 'Research Assistant' },
              {
                key: 'Political Position',
                item: 'Spouse of William Jefferson "Bill" Clinton (Diplomat, Politician, President of the US)',
              },
              {
                key: 'Political Position',
                item: 'United States Secretary Of State',
              },
              { key: 'Political Position', item: 'United States Senator' },
              { key: 'Political Position', item: 'University Teacher' },
              { key: 'Political Position', item: 'Writer' },
              { key: 'Gender', item: 'female' },
              { key: 'Chamber', item: 'Senate' },
              {
                key: 'Legislative Period',
                item: '107th Congress, 108th Congress, 109th Congress, 110th Congress, 111th Congress',
              },
              { key: 'Legislature', item: 'Senate' },
              { key: 'Party', item: 'Democratic Party' },
              { key: 'Party', item: 'Republican Party' },
              { key: 'Political Party', item: 'Democrat' },
              { key: 'Political Region', item: 'New York' },
              { key: 'Related URL', item: 'http://complyadvantage.com' },
              { key: 'Active Start Date', item: '2001-01-06' },
              { key: 'Active End Date', item: '2009-01-21' },
              {
                key: 'reference.doc.0',
                item: 'http://complyadvantage-asset-development.s3.amazonaws.com/1a9c9600-5f36-4b52-84ff-60dd547b4925.jpg',
              },
              { key: 'original_checksource', item: 'complyadvantage' },
              {
                key: 'status.true_positive',
                item: '01EADR0EABY51C04TH5FG7TD71',
              },
              { key: 'status.current', item: 'true_positive' },
              { key: 'status.comment', item: 'w' },
              {
                key: 'status.manual_update',
                item: '01EADR0EABY51C04TH5FG7TD71',
              },
              { key: 'status.set_date', item: '2020-06-09T23:52:51Z' },
            ],
          },
        ],
        pepsPepClass2: [],
        pepsPepClass3: [],
        pepsPepClass4: [],
        supportingDocuments: [
          'http://complyadvantage-asset-development.s3.amazonaws.com/1a9c9600-5f36-4b52-84ff-60dd547b4925.jpg',
        ],
      },
    },
  ],
}
