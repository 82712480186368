export const applicantTransactionsEn = {
  heading: {
    aml: 'AML Transactions',
    fraud: 'Fraud Transactions',
    customer: 'Needs Attention',
    allTxn: 'All Transactions',
    allActivities: 'All Activities',
  },

  statusOption: {
    pending: 'Pending',
    manuallyApproved: 'Manually Approved',
    manuallyDeclined: 'Manually Declined',
  },

  transactions: 'Transactions',
  activities: 'Activities',
  viewAllTransactions: 'View All Transactions',
  viewAllActivities: 'View All Activities',
  transactionId: 'Transaction ID',

  noAccess: 'No access',
  bulkAction: 'Bulk Action',
  search: 'Search',
  record: 'Record',
  cancel: 'Cancel',
  save: 'Save',
  confirm: 'Confirm',
  comment: 'Comment',
  confirmation: 'Confirmation',
  status: 'Status',
  selectStatus: 'Select Status',
  typeYourComment: 'Type your comment here',
  enterYourComment: 'Enter your comment here',
  changeStatus: 'Change status to',
  bulkActionConfirmationNote:
    'You are about to bulk resolve records. This action will resolve every records you have selected with the status chosen above, irrespective of risk level.',
  acknowledge: 'I acknowledge that I am resolving all selected records.',

  updating: 'Updating...',
  loadingTxn: 'Loading {{txnType}}...',
  updatingTxn: 'Updating {{txnType}}...',

  success: {
    transactionUpdated: 'Updated transaction case status successfully',
  },

  error: {
    fetchingResult: 'Error fetching {{txnType}} result.',
    updatingTransaction: 'Failed to update transaction case status".',
  },

  emptyMessage: {
    aml: 'No suspicious transactions found at this time.',
    fraud: 'No fraudulent transactions found at this time.',
    all: 'No transactions found at this time.',
    customer: 'No activities found at this time.',
    noSearchResult: 'No search result. Please clear search field.',
  },

  field: {
    transactionId: 'Transaction ID',
    sessionKey: 'Session Key',
    status: 'Status',
    issueType: 'Issue Type',
    riskLevel: 'Risk Level',
    type: 'Type',
    activityType: 'Activity Type',
    deviceId: 'Device ID',
    paymentMethod: 'Method',
    amount: 'Amount',
    transactionTimestamp: 'Date/Time',
    action: 'Action',
  },

  bulkActionMessage: {
    success: {
      text: 'Successfully resolved',
      subtext: '{{total}} record(s) were successfully resolved.',
    },
    info: {
      text: 'Partially resolved',
      subtext:
        '{{success}} out of {{total}} records were successfully resolved. {{fail}} record(s) failed to resolve. More details in the Audit Report.',
    },
    error: {
      text: 'Unable to resolve',
      subtext:
        '{{total}} record(s) failed to resolve. More details in the Audit Report.',
    },
  },
}
