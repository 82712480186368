import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import {
  DataGridPro,
  GridFilterModel,
  GridRowSelectionModel,
  GridSortModel,
} from '@mui/x-data-grid-pro'

import { FrankieButton, FrankieTextField } from 'frankify/src'

import {
  ApplicantTransactionsProperties,
  TransactionTypes,
  transactionRecordMap,
} from 'features/applicant-transactions/model/applicant-transactions.model'

import { WithProps } from 'shared/hoc'
import { useI18n } from 'shared/i18n'
import { notification } from 'shared/notification'
import { useOverlay } from 'shared/overlay'

import { APPLICANT_TRANSACTIONS_KEY } from '../../applicant-transactions.key'
import { applicantTransactionsEn } from '../../locale/applicant-transactions.en'
import {
  transactionDataGridCellWidth,
  transactionsDataGridSx,
} from '../../model/applicant-transactions-data-grid-helper.model'
import { applicantTransactionsDataGridQa } from '../../qa/applicant-transactions.qa'
import {
  ApplicantTransactionsQueryArgs,
  useApplicantTransactionsQuery,
} from '../../state/applicant-transactions-query/applicant-transactions.query'
import { ApplicantTransactionsDataGridBulkAction } from '../applicant-transactions-data-grid-bulk-action/applicant-transactions-data-grid-bulk-action'
import {
  TransactionsDataGridHeader,
  TransactionsDataGridLoadingOverlay,
  TransactionsDataGridNoResult,
  useTransactionDataGridColumns,
} from '../applicant-transactions-data-grid-helper/applicant-transactions-data-grid-helper'

type Props = {
  className?: string
  heading: string
  searchPlaceholder: string
  hideBulkAction?: boolean
  fullView?: boolean
  refetchState?: boolean
  invalidateData?: boolean
  testId?: { container?: string }
} & ApplicantTransactionsQueryArgs

export function ApplicantTransactionsDataGrid({
  className = '',
  heading,
  applicantId,
  searchPlaceholder,
  transactionType,
  needsAttention = false,
  hideBulkAction = false,
  fullView = false,
  refetchState = false,
  invalidateData = false,
  testId,
}: Props) {
  const t = useI18n([APPLICANT_TRANSACTIONS_KEY], {
    keys: applicantTransactionsEn,
  })

  const [createOverlay, closeOverlay] = useOverlay()

  const searchRef = useRef<HTMLInputElement>(null)

  const [sortModel, setSortModel] = useState<GridSortModel>([])

  const [filterModel, setFilterModel] = useState<GridFilterModel>({ items: [] })

  const handleClearSearch = () => {
    if (searchRef.current) {
      searchRef.current.value = ''
    }
    setFilterModel({ items: [] })
  }

  const handleSearch = (value: string) => {
    if (!value) {
      handleClearSearch()
      return
    }
    const field: ApplicantTransactionsProperties = transactionType.includes(
      TransactionTypes.Customer,
    )
      ? 'sessionKey'
      : 'transactionId'

    setFilterModel({
      items: [{ field, operator: 'contains', value }],
    })
  }

  const {
    transactionRecords,
    isFetching,
    error,
    refetch,
    remove,
    isSuccess,
    isLoading,
  } = useApplicantTransactionsQuery({
    applicantId,
    transactionType,
    needsAttention,
  })

  const columns = useTransactionDataGridColumns({
    transactionType,
    needsAttention,
    applicantId,
    hideBulkAction,
    fullView,
  })

  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([])

  const bulkActionDisabled = useMemo(
    () => rowSelectionModel.length === 0,
    [rowSelectionModel],
  )

  useEffect(() => {
    if (error) {
      notification.error(
        t('error.fetchingResult', {
          txnType: t(transactionRecordMap[transactionType[0]].tKey),
        }),
      )
    }
  }, [error, t, transactionType])

  useEffect(() => {
    if (refetchState) {
      void refetch()
    }
  }, [refetch, refetchState])

  useEffect(
    () => () => {
      if (invalidateData) {
        remove()
      }
    },
    [invalidateData, remove],
  )

  const handleBulkAction = () => {
    createOverlay(
      <ApplicantTransactionsDataGridBulkAction
        applicantId={applicantId}
        transactionType={transactionType}
        selectedTransactionResultIds={rowSelectionModel as string[]}
        onClose={closeOverlay}
        needsAttention={needsAttention}
      />,
      {
        asSideDrawer: true,
        className: '!p-0',
        closeButtonClassName: '!top-4 !right-4',
      },
    )
  }

  useLayoutEffect(() => {
    const scrollTo = () => {
      const { hash } = window.location
      if (hash) {
        const element = document.querySelector(hash)
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' })
        }
      }
    }

    if (!isLoading) setTimeout(scrollTo, 300)
  }, [isSuccess])

  return (
    <div
      className={`text-tertiary-grey-700 ${className}`}
      data-qa={testId?.container}
      id={transactionType[0]}
    >
      <div className="transaction-heading flex justify-between items-center pb-6 border-tertiary-grey-200">
        <div
          className={`text-xl text-tertiary-grey-800 ${
            fullView
              ? 'p-4 border-b border-tertiary-grey-200  w-full'
              : ' font-bold'
          }`}
        >
          {heading}
        </div>
        {!hideBulkAction && (
          <FrankieButton
            size="xs"
            disabled={bulkActionDisabled}
            className={
              bulkActionDisabled
                ? '!border-tertiary-grey-300'
                : '!border-tertiary-grey-700'
            }
            intent="darkOutline"
            onClick={handleBulkAction}
            testId={{ button: applicantTransactionsDataGridQa.bulkActionBtn }}
          >
            {t('bulkAction')}
          </FrankieButton>
        )}
      </div>

      <div
        className={`transaction-grid border border-tertiary-grey-200 rounded-md overflow-hidden ${
          fullView ? 'mx-4' : ''
        }`}
      >
        <div className="flex items-center gap-3 p-3">
          <FrankieTextField
            placeholder={searchPlaceholder}
            className="max-w-[300px]"
            inputClassName="!h-[36px]"
            closeButton={
              filterModel.items.length
                ? { onClick: handleClearSearch }
                : undefined
            }
            ref={searchRef}
            testId={{ input: applicantTransactionsDataGridQa.textField }}
          />
          <FrankieButton
            className="!bg-mono-100 !outline-mono-50"
            size="sm"
            startIcon={{
              name: 'mdiMagnify',
            }}
            onClick={() => handleSearch(searchRef.current?.value || '')}
            testId={{ button: applicantTransactionsDataGridQa.searchBtn }}
          >
            {t('search')}
          </FrankieButton>
          <span className="flex-grow font-bold text-right">{`${
            transactionRecords.length
          } ${t('record')}`}</span>
        </div>
        <DataGridPro
          rows={transactionRecords}
          rowSelectionModel={rowSelectionModel}
          onRowSelectionModelChange={setRowSelectionModel}
          slots={{
            loadingOverlay: WithProps(TransactionsDataGridLoadingOverlay, {
              transactionType,
            }),
            noRowsOverlay: WithProps(TransactionsDataGridNoResult, {
              transactionType,
            }),
            noResultsOverlay: WithProps(TransactionsDataGridNoResult, {
              transactionType,
              isSearch: !!filterModel.items.length,
            }),
          }}
          className="[&_.MuiDataGrid-row.Mui-selected]:bg-primary-50 [&_.MuiButtonBase-root.MuiCheckbox-root]:text-tertiary-grey-400"
          columns={columns.map(
            ({ headerClassName, cellClassName, sortable, ...rest }) => ({
              // Adding default properties for every column
              headerClassName: `!p-0 text-xs !border-tertiary-grey-200 font-semibold bg-tertiary-grey-50 text-tertiary-grey-500 !outline-none ${
                headerClassName ?? ''
              }`,
              cellClassName: `!outline-none !border-tertiary-grey-200 text-sm break-words ${
                cellClassName ?? ''
              }`,
              sortable: sortable ?? true,
              hideSortIcons: true,
              resizable: false,
              disableColumnMenu: true,
              disableReorder: true,
              renderHeader: WithProps(TransactionsDataGridHeader, {
                sortModel,
              }),
              ...transactionDataGridCellWidth(120),
              ...rest,
            }),
          )}
          filterModel={filterModel}
          sortModel={sortModel}
          onSortModelChange={setSortModel}
          loading={isFetching}
          getRowClassName={() => 'bg-mono-white'}
          isCellEditable={() => false}
          getRowHeight={() => 'auto'}
          columnHeaderHeight={40}
          checkboxSelection={!hideBulkAction}
          disableRowSelectionOnClick
          // disableColumnSelector
          disableColumnFilter
          disableColumnMenu
          disableDensitySelector
          showCellVerticalBorder={false}
          showColumnVerticalBorder={false}
          hideFooterPagination
          hideFooter
          hideFooterSelectedRowCount
          autoHeight
          autoPageSize
          sx={transactionsDataGridSx(fullView)}
        />
      </div>
    </div>
  )
}
