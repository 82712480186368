import React, { useEffect, useMemo } from 'react'

import { UseFormReturn, useFieldArray } from 'react-hook-form'
import { Trans } from 'react-i18next'

import { FrankieButton } from 'frankify/src'

import { useOrganisationSettingsQuery } from 'features/organisation-settings'

import { CountryAlpha3CodeTypes, countryList } from 'entities/country'
import { DocumentIdTypes } from 'entities/entity'
import { PermissionTypes } from 'entities/role'
import { useHasPermission } from 'entities/session'

import { SelectFormField, ToggleSwitchFormField } from 'shared/form'
import { useI18n } from 'shared/i18n'
import { useOverlay } from 'shared/overlay'

import { DeletionConfirmation } from './deletion-confirmation/deletion-confirmation'
import { DocumentAttachmentForm } from './document-attachment-form/document-attachment-form'
import { DocumentSubForm } from './document-sub-forms/document-sub-forms'
import { INDIVIDUAL_PROFILE_F2_KEY } from '../../individual-profile-f2.key'
import { individualProfileF2En } from '../../locale/individual-profile-f2.en'
import {
  IIndividualProfileF2FormType,
  documentIdTypesOption,
  getIdTypeOptionsByCountry,
} from '../../model/individual-profile-f2-form.model'
import { getDocumentQa } from '../../qa/individual-profile-f2.qa'

type Props = {
  form: UseFormReturn<IIndividualProfileF2FormType>
  isEdit?: boolean
}

const SUPPORTED_COUNTRY = ['AUS', 'NZL']

export function DocumentForm({ form, isEdit }: Props) {
  const t = useI18n([INDIVIDUAL_PROFILE_F2_KEY], {
    keys: individualProfileF2En,
  })

  const [createOverlay] = useOverlay()

  const { control, watch, getValues, setValue, trigger } = form

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'documents',
  })

  const countryOptions = useMemo(
    () =>
      countryList
        .filter(c => SUPPORTED_COUNTRY.includes(c.alpha3code))
        .map(country => ({
          label: country.name,
          value: country.alpha3code,
        })),
    [],
  )

  const { canFetchOrganisationSettings } = useHasPermission({
    canFetchOrganisationSettings: PermissionTypes.OrganisationSettingsFetch,
  })
  const { data: organisationSettings } = useOrganisationSettingsQuery({
    canFetchOrganisationSettings,
  })

  const defaultCountry = useMemo(
    () =>
      (organisationSettings?.find(setting => setting.name === 'country')
        ?.value || CountryAlpha3CodeTypes.Australia) as CountryAlpha3CodeTypes,
    [organisationSettings],
  )

  const appendDefaultValue = () => {
    append({
      country: defaultCountry,
    })
  }

  const handleRemove = (idx: number) => {
    if (isEdit) {
      createOverlay(<DeletionConfirmation onConfirm={() => remove(idx)} />, {
        className: '!p-5',
      })
    } else {
      remove(idx)
    }
  }

  const eKyc = watch('eKyc')

  // re trigger validation when eKyc is toggled
  useEffect(() => {
    if (eKyc)
      fields.forEach((_, idx) => {
        void trigger(`documents.${idx}.attachments.0`)
      })
  }, [eKyc])

  // find and remove duplicate id Types when changing from mKyc to eKyc
  useEffect(() => {
    watch(({ eKyc, documents }, { name, type }) => {
      if (name === 'eKyc') {
        if (eKyc) {
          const idTypesUsed: (DocumentIdTypes | undefined | '')[] = []
          const indexToRemove: number[] = []
          documents?.forEach((_, idx) => {
            const type = getValues(`documents.${idx}.type`)
            // remove empty fields if there are more than the default options
            const shouldRemoveEmpty =
              !type && documents.length > documentIdTypesOption.length

            if (shouldRemoveEmpty || idTypesUsed.includes(type)) {
              indexToRemove.unshift(idx)
            } else {
              idTypesUsed.push(type)
            }
          })
          remove(indexToRemove)
        } else if (!documents?.length) {
          appendDefaultValue()
        }
      }
      if (name?.includes('documents'))
        documents?.forEach((document, idx) => {
          if (name === `documents.${idx}.type` && type === 'change') {
            setValue(`documents.${idx}`, {
              country: document?.country,
              type: document?.type,
            })
          }
        })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // allow max 10 documents if not eKyc , other wise only number of documents allowed
  const canAddMoreDocuments =
    (eKyc ? documentIdTypesOption.length : 10) > fields.length

  const idTypesUsed = fields.map((_, idx) => watch(`documents.${idx}.type`))

  const disableRemove = fields.length <= 1 && !eKyc

  const documentFormF2Qa = getDocumentQa(0)

  return (
    <div
      className="flex flex-col items-start gap-4"
      data-qa={documentFormF2Qa.wrapper}
    >
      <div className="w-full flex justify-between items-center">
        <div className="text-lg  text-tertiary-grey-800  font-bold">
          {t('documentForm.title')}
        </div>

        <div className="flex justify-end font-bold items-center">
          {t('documentForm.eKyc')}{' '}
          <ToggleSwitchFormField
            control={control}
            testId={documentFormF2Qa.toggle}
            name="eKyc"
            className="scale-75 !w-[54px] !min-w-[54px] "
            activeOption={{
              label: '',
              color: 'bg-tertiary-grey-300',
              value: false,
            }}
            inactiveOption={{
              label: '',
              color: 'bg-primary-900',
              value: true,
            }}
          />
        </div>
      </div>
      {!eKyc && (
        <div className="text-tertiary-grey-700 font-normal text-sm">
          <Trans
            i18nKey={`${INDIVIDUAL_PROFILE_F2_KEY}:documentForm.myKyc`}
            components={{
              strong: <b />,
            }}
          />
        </div>
      )}
      {fields.map((field, idx) => {
        const watchIdType = watch(`documents.${idx}.type`)
        const country = watch(
          `documents.${idx}.country`,
        ) as CountryAlpha3CodeTypes

        const isIdTypeSelected = !!watchIdType

        const idTypesOptions = getIdTypeOptionsByCountry(country)
          .filter(
            idType =>
              // show all documents in mKyc or filter for only unused documents
              !eKyc ||
              watchIdType === idType.value ||
              !idTypesUsed.includes(idType.value),
          )
          .map(item => ({
            label: t(`idType.${item.tKey}`),
            value: item.value,
          }))

        const documentFormQa = getDocumentQa(idx)
        return (
          <div
            key={field.id}
            className={`relative w-full flex gap-4 bg-tertiary-grey-50 py-4 px-5 rounded-sm ${
              isIdTypeSelected ? ' min-h-[210px]' : ''
            } group/document`}
            data-qa={documentFormQa.documentFieldHeader}
          >
            <div className="grid grid-cols-3 gap-x-[2.5%] w-full ">
              <div className="flex flex-wrap self-start gap-x-[4%] gap-y-4 col-span-2">
                <SelectFormField
                  label={t('documentForm.country')}
                  className="basis-[48%]"
                  options={countryOptions}
                  control={control}
                  testId={{
                    input: documentFormQa.country,
                  }}
                  name={`documents.${idx}.country`}
                />
                <SelectFormField
                  label={t('documentForm.idType')}
                  placeholder={t('documentForm.idTypePlaceholder')}
                  className="basis-[48%]"
                  options={idTypesOptions}
                  control={control}
                  testId={{ input: documentFormQa.type }}
                  rules={{ required: !eKyc && t('documentForm.idTypeError') }}
                  name={`documents.${idx}.type`}
                  showErrorText
                  shouldUnregister
                />
                {watchIdType && (
                  <DocumentSubForm
                    wrapperClasses="flex gap-[3%] gap-y-4 flex-wrap"
                    idx={idx}
                    selectedType={watchIdType}
                    form={form}
                  />
                )}
              </div>
              <div className="flex flex-col text-right w-full">
                <FrankieButton
                  noStyles
                  disabled={disableRemove}
                  className={`${
                    disableRemove ? ' !cursor-not-allowed ' : ''
                  } text-primary-800 ml-auto mb-2`}
                  onClick={() => handleRemove(idx)}
                >
                  {t('documentForm.remove')}
                </FrankieButton>
                {isIdTypeSelected && (
                  <DocumentAttachmentForm
                    form={form}
                    idx={idx}
                    eKyc={eKyc}
                    idType={watchIdType}
                    isEdit={isEdit}
                  />
                )}
              </div>
            </div>
          </div>
        )
      })}
      {canAddMoreDocuments && (
        <FrankieButton
          noStyles
          testId={{ button: documentFormF2Qa.addAnother }}
          className="!text-primary-800"
          onClick={appendDefaultValue}
        >
          {t('documentForm.addAnother')}
        </FrankieButton>
      )}
    </div>
  )
}
