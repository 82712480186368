import { useMutation, useQueryClient } from '@tanstack/react-query'

import { IUser } from 'entities/user'

import { UserListQueryKey } from './user-list.query'
import { userManagementApi } from '../../api/user-management.api'
import { UpdateUsersPatch } from '../../model'

export const useUpdateUsersMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ users }: { users: UpdateUsersPatch[] }) =>
      userManagementApi.updateUsers(users),
    onSuccess: ({ data: updatedUsers }) => {
      const users = queryClient.getQueryData<IUser[]>(UserListQueryKey)
      if (users) {
        const newUsers = users.map(user => {
          const updatedUser = updatedUsers.find(item => item.id === user.id)
          return updatedUser ?? user
        })
        queryClient.setQueryData(UserListQueryKey, newUsers)
      }
    },
  })
}
