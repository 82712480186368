import React from 'react'

import { FrankieIcon } from 'frankify/src'

import { GoogleMap } from 'shared/google-map'
import { useI18n } from 'shared/i18n'
import { capitalizeStringBySeparator } from 'shared/string'
import { TrackingEventsTypes, trackingManager } from 'shared/tracking'

import { APPLICANT_KEY, applicantEn } from '../../../locale/applicant.en'
import {
  IFraudCheckpointRule,
  IPLocationCheckpoint,
} from '../../../model/applicant-fraud-check.model'
import { CheckHeader } from '../check-header/check-header'
import { ContentItem } from '../content-item/content-item'

type Props = {
  data: IPLocationCheckpoint
  rules: IFraudCheckpointRule[] | null
  checkResult: 'fail' | 'unchecked' | 'pass'
  deviceSardineUrl: string | null
}
export function FraudCheckIpLocation({
  data,
  rules,
  checkResult,
  deviceSardineUrl,
}: Props) {
  const t = useI18n(APPLICANT_KEY, { keys: applicantEn })
  return (
    <div>
      <CheckHeader
        checkResult={checkResult}
        riskLevel={data.riskLevel}
        rules={rules}
        ruleIds={data.ruleIds}
        title={t('fraud.iplocation.title')}
        buttonLabel={t('fraud.iplocation.viewRulesCta')}
        modalTitle={t('fraud.checkRules.iplocation')}
        onViewRules={() =>
          trackingManager.track(TrackingEventsTypes.FraudViewResultsIpLocation)
        }
        deviceSardineUrl={deviceSardineUrl}
      />
      <div className="flex flex-row justify-between gap-4">
        <div className="flex flex-col gap-[18px] shrink">
          <ContentItem
            name={t('fraud.iplocation.vpn')}
            value={
              data.vpn === null
                ? null
                : capitalizeStringBySeparator(data.vpn.toString())
            }
          />
          <ContentItem name={t('fraud.iplocation.city')} value={data.city} />
          <ContentItem
            name={t('fraud.iplocation.region')}
            value={data.region}
          />
          <ContentItem
            name={t('fraud.iplocation.country')}
            value={data.country}
          />
          <ContentItem
            name={t('fraud.iplocation.latitude')}
            value={data.latitude}
          />
          <ContentItem
            name={t('fraud.iplocation.longitude')}
            value={data.longitude}
          />
        </div>
        <div className="basis-[721px] grow-0 shrink-0">
          {data.longitude && data.latitude ? (
            <GoogleMap
              className="h-[400px] w-full rounded-1.5"
              lng={+data.longitude}
              lat={+data.latitude}
            />
          ) : (
            <div
              className="w-full h-[400px]
           flex flex-col items-center justify-center text-center gap-3
           rounded-1.5 text-tertiary-grey-800 bg-tertiary-grey-100"
            >
              <FrankieIcon
                name="mdiAlertCircleOutline"
                size="75px"
                className="text-primary-300"
              />
              <div className="text-lg font-bold">
                {t('fraud.iplocation.mapPlaceholder.title')}
              </div>
              <div className="text-sm font-normal">
                {t('fraud.iplocation.mapPlaceholder.description')}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
