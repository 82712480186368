import React, { useMemo, useState } from 'react'

import { GridRowParams, GridSortModel } from '@mui/x-data-grid-pro'

import { ApplicantId } from 'entities/applicant'
import { useHasFeatureFlag } from 'entities/session'

import { WithProps } from 'shared/hoc'
import { useI18n } from 'shared/i18n'
import { useOverlay } from 'shared/overlay'
import { DefaultDataGrid, DefaultGirdColDef } from 'shared/tables-common'

import { IKYB_AML_SCREENING_KEY } from '../../ikyb-aml-screening.key'
import { iKybAmlScreeningEn } from '../../locale/ikyb-aml-screening.en'
import {
  AmlScreeningMatchRecord,
  AmlScreeningMatchRecordProperties,
} from '../../model/ikyb-aml-screening.model'
import { iKybAmlDataGridQa } from '../../qa/ikyb-aml-screening.qa'
import { useIKybAmlScreeningGridData } from '../../state/ikyb-aml-screening-query/ikyb-aml-screening.query'
import {
  IssuesCell,
  IKybAmlSlot,
  MatchCell,
  MatchStrengthCell,
  useMatchStatusLabel,
} from '../ikyb-aml-screening-data-grid-helper/ikyb-aml-screening-data-grid-helper'
import { IKybAmlMatchStatusChange } from '../ikyb-aml-screening-match-status-change/ikyb-aml-screening-match-status-change'
import { IKybAmlScreeningResult } from '../ikyb-aml-screening-result/ikyb-aml-screening-result'

type Props = {
  applicantId: ApplicantId
  className?: string
  testId?: { container: string }
}

const headerField = <T extends AmlScreeningMatchRecordProperties>(name: T) =>
  name

const TABLE_MIN_WIDTH = 900
export const cellWidth = (width: number) => ({
  minWidth: width,
  flex: width / TABLE_MIN_WIDTH,
})

export function IKybAmlScreeningDataGrid({
  applicantId,
  className = '',
  testId = { container: '' },
}: Props) {
  const t = useI18n(IKYB_AML_SCREENING_KEY, {
    keys: iKybAmlScreeningEn,
  })

  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: headerField('matchStatus'), sort: 'desc' },
  ])
  const [createOverlay] = useOverlay()

  const { rows, selectedMatchIds, setSelectedMatchIds, loading } =
    useIKybAmlScreeningGridData({
      applicantId,
    })

  const handleRowClick = ({ row }: GridRowParams<AmlScreeningMatchRecord>) => {
    createOverlay(
      <IKybAmlScreeningResult
        applicantId={applicantId}
        selectedGroupId={row.id}
      />,
      {
        className: 'p-8 !pr-4',
      },
    )
  }

  const { showAMLMatchStrength } = useHasFeatureFlag({
    showAMLMatchStrength: 'showAMLMatchStrength',
  })

  const { sortMatchStatusComparator } = useMatchStatusLabel()

  const columns = useMemo(
    () =>
      [
        {
          field: headerField('name'),
          headerName: t('field.name'),
          sortable: false,
        },
        {
          field: headerField('countries'),
          headerName: t('field.countries'),
        },
        {
          field: headerField('matchStatus'),
          headerName: t('field.matchStatus'),
          renderCell: MatchCell,
          sortComparator: sortMatchStatusComparator,
          sortable: true,
        },
        {
          field: headerField('issues'),
          headerName: t('field.issues'),
          renderCell: IssuesCell,
        },
        {
          field: headerField('matchScore'),
          headerName: t('field.matchStrength'),
          renderCell: MatchStrengthCell,
          sortable: true,
        },
      ] as DefaultGirdColDef<AmlScreeningMatchRecord>[],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rows, selectedMatchIds, setSelectedMatchIds, t],
  )

  return (
    <div className={className} data-qa={testId.container}>
      <div
        className="mb-4 flex justify-between items-center w-full text-tertiary-grey-500"
        data-qa={iKybAmlDataGridQa.applicationMatchStatusContainer}
      >
        <div className="lowercase">{`${rows.length} ${t(
          rows.length === 1 ? 'result' : 'results',
        )}`}</div>
        <div className="flex flex-none gap-4 items-center">
          {t('matchSelected', {
            count: selectedMatchIds.length,
          })}
          <IKybAmlMatchStatusChange
            applicantId={applicantId}
            groupIds={selectedMatchIds}
            disabled={!selectedMatchIds.length}
          />
        </div>
      </div>
      <div
        className="w-full overflow-x-auto"
        data-qa={iKybAmlDataGridQa.dataGridProContainer}
      >
        <DefaultDataGrid
          tableMinWidth={835}
          columnVisibilityModel={{
            matchScore: showAMLMatchStrength,
          }}
          rows={rows}
          slots={{
            loadingOverlay: WithProps(IKybAmlSlot, {
              loadingOverlay: true,
            }),
            noRowsOverlay: WithProps(IKybAmlSlot, { noRowsOverlay: true }),
          }}
          columns={columns}
          onRowClick={handleRowClick}
          loading={loading}
          sortModel={sortModel}
          onSortModelChange={setSortModel}
          checkboxSelection
          rowSelectionModel={selectedMatchIds}
          onRowSelectionModelChange={data => {
            setSelectedMatchIds(data as string[])
          }}
        />
      </div>
    </div>
  )
}
