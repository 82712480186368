import { useMemo } from 'react'

import { useApplicantDataQuery } from 'entities/applicant'
import { useHasFeatureFlag } from 'entities/session'

type Props = {
  entityId: string
}

export function useIKybCheckStatus({ entityId }: Props) {
  const { hasInternationalKybAML } = useHasFeatureFlag({
    hasInternationalKybAML: 'internationalKybAML',
  })

  const { data: applicantData, isLoading: isLoadingApplicantData } =
    useApplicantDataQuery({
      applicantId: entityId,
    })

  const { hasAMLRan, alertHasAml } = useMemo(() => {
    const checkSummary = applicantData?.checkSummary

    const checkType = checkSummary?.checkTypes || []
    const alertList = checkSummary?.alertList || []

    const hasAMLRan = !!checkType.find(
      item => item === 'pep' || item === 'pep_media',
    )

    const alertHasAml = alertList.some(alert =>
      ['media', 'watchlist', 'sanction', 'pep', 'pep_media'].includes(
        alert.term,
      ),
    )

    return { hasAMLRan, alertHasAml }
  }, [applicantData])

  return {
    showAmlStatus: hasInternationalKybAML && hasAMLRan,
    alertHasAml,
    isLoadingAmlStatus: isLoadingApplicantData,
  }
}

export function useIKybReportCheckStatus({ entityId }: Props) {
  const { data: applicantData } = useApplicantDataQuery({
    applicantId: entityId,
  })

  const hasCompletedReports = useMemo(() => {
    if (!applicantData?.documents) return false
    const doc = applicantData.documents.find(
      doc => doc.idSubType === 'profile' || doc.idSubType === 'ownership',
    )
    return !!doc
  }, [applicantData?.documents])

  return {
    hasCompletedReports,
  }
}
