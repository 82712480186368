import React from 'react'

import { FrankieButton, FrankieIcon } from 'frankify/src'

type Props = {
  text: {
    description: string
    subDescription?: string
    cancel: string
    confirm: string
  }
  type?: 'danger' | 'warning'
  onClickCancel: () => void
  onClickConfirm: () => void
  testId?: {
    cancelButton?: string
    confirmButton?: string
  }
}

export function ConfirmationOverlay({
  text,
  onClickCancel,
  onClickConfirm,
  testId = {
    cancelButton: '',
    confirmButton: '',
  },
  type = 'warning',
}: Props) {
  return (
    <div className="pr-2 mr-2 max-h-[calc(100vh-145px)] overflow-y-auto scrollbar-gutter scrollbar pb-2">
      <div className="flex flex-col min-w-0 flex-initial items-center">
        <FrankieIcon
          className={`mb-4 ${
            type === 'warning'
              ? 'text-tertiary-yellow-300'
              : 'text-tertiary-red-700'
          }`}
          size="44px"
          name="mdiAlertCircleOutline"
        />
        <div className="mb-6 text-tertiary-grey-700 text-md text-center">
          <p>{text.description}</p>
          {text.subDescription && <p>{text.subDescription}</p>}
        </div>

        <div className="flex flex-initial items-center gap-4">
          <FrankieButton
            onClick={onClickCancel}
            size="sm"
            intent={type === 'warning' ? 'primary' : 'darkOutline'}
            testId={{ button: testId.cancelButton }}
          >
            {text.cancel}
          </FrankieButton>
          <FrankieButton
            onClick={onClickConfirm}
            size="sm"
            intent={type === 'warning' ? 'secondary' : 'danger'}
            testId={{ button: testId.confirmButton }}
          >
            {text.confirm}
          </FrankieButton>
        </div>
      </div>
    </div>
  )
}
