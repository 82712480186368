import React, { useCallback, useEffect, useMemo } from 'react'

import { FrankieTextField } from 'frankify/src'

import { INDIVIDUAL_PROFILE_KEY } from 'features/individual-profile/individual-profile.keys'
import { individualProfileEn } from 'features/individual-profile/locale/new-profile.en'
import { IndividualProfileInputTypes } from 'features/individual-profile/model/form.model'

import { stateList } from 'entities/country'
import { KycDocumentCategoryTypes } from 'entities/document'

import { SelectFormField, getError } from 'shared/form'
import { validateDate } from 'shared/form/model/error.model'
import { useI18n } from 'shared/i18n'

import { individualProfileVueMigratedQa } from '../../../../qa/individual-profile.qa'
import { DocumentCategory } from '../document-category/document-category'
import { DocumentsSubFormProps } from '../document-sub-forms.types'

const group1 = ['ACT'] // idNumber, marriage_date, certificate_date_of_print, certificate_number(optional)
const group2 = ['NT'] // idNumber, marriage_date, certificate_date_of_print, certificate_number
const group3 = ['SA'] // idNumber, marriage_date, certificate_date_of_print(optional), certificate_number(optional)
const group4 = ['NSW', 'TAS', 'VIC', 'WA'] // idNumber, marriage_date, registration_year
const group5 = ['QLD'] // idNumber(optional), marriage_date, certificate_date_of_registration

const stateShowDateOfPrint = [...group1, ...group2, ...group3]

const timeOptions = [...Array(100).keys()].map(i => ({
  value: `${new Date().getFullYear() - i}`,
  label: `${new Date().getFullYear() - i}`,
  key: `${new Date().getFullYear() - i}`,
}))

export function MarriageCertificateForm({
  form,
  idx,
  wrapperClasses,
}: DocumentsSubFormProps) {
  const {
    register,
    watch,
    control,
    formState: { errors },
    setValue,
    unregister,
  } = form
  const t = useI18n([INDIVIDUAL_PROFILE_KEY], { keys: individualProfileEn })

  const marriageDateRegister = useMemo(
    () =>
      register(
        `${IndividualProfileInputTypes.Document}.${idx}.${IndividualProfileInputTypes.MarriageCertificate}.${IndividualProfileInputTypes.MarriageDate}`,
        {
          required: true,
          validate: {
            date: value => validateDate(value, true),
          },
        },
      ),
    [idx, register],
  )

  const getNameRegister = useCallback(
    (
      brideGroom:
        | IndividualProfileInputTypes.Bride
        | IndividualProfileInputTypes.Groom,
      nameType:
        | IndividualProfileInputTypes.FirstName
        | IndividualProfileInputTypes.OtherName
        | IndividualProfileInputTypes.FamilyName,
    ) =>
      register(
        `${IndividualProfileInputTypes.Document}.${idx}.${IndividualProfileInputTypes.MarriageCertificate}.${brideGroom}.${nameType}`,
        { required: nameType !== IndividualProfileInputTypes.OtherName },
      ),

    [register, idx],
  )

  const selectedCountry: string = watch(
    `${IndividualProfileInputTypes.Document}.${idx}.${IndividualProfileInputTypes.Country}`,
  )

  const statesOptions = useMemo(
    () =>
      Object.hasOwn(stateList, selectedCountry)
        ? stateList[selectedCountry]
        : [],
    [selectedCountry],
  )
  const selectedState = watch(
    `${IndividualProfileInputTypes.Document}.${idx}.${IndividualProfileInputTypes.State}`,
  )

  const dateOfPrintRegister = useMemo(
    () =>
      stateShowDateOfPrint.includes(selectedState) &&
      register(
        `${IndividualProfileInputTypes.Document}.${idx}.${IndividualProfileInputTypes.MarriageCertificate}.${IndividualProfileInputTypes.DateOfPrint}`,
        {
          required: [...group1, ...group2].includes(selectedState),
          shouldUnregister: true,
        },
      ),
    [idx, selectedState, register],
  )

  const certificateNumberRegister = useMemo(
    () =>
      stateShowDateOfPrint.includes(selectedState) &&
      register(
        `${IndividualProfileInputTypes.Document}.${idx}.${IndividualProfileInputTypes.MarriageCertificate}.${IndividualProfileInputTypes.CertificateNumber}`,
        { required: group2.includes(selectedState), shouldUnregister: true },
      ),
    [idx, selectedState, register],
  )

  const certificateRegistrationYearRegister = useMemo(
    () =>
      group4.includes(selectedState) &&
      register(
        `${IndividualProfileInputTypes.Document}.${idx}.${IndividualProfileInputTypes.MarriageCertificate}.${IndividualProfileInputTypes.RegistrationYear}`,
        {
          required: true,
          shouldUnregister: true,
        },
      ),
    [idx, selectedState, register],
  )

  const certificateDateOfRegistrationRegister = useMemo(
    () =>
      group5.includes(selectedState) &&
      register(
        `${IndividualProfileInputTypes.Document}.${idx}.${IndividualProfileInputTypes.MarriageCertificate}.${IndividualProfileInputTypes.CertificateDateOfRegistration}`,
        {
          required: true,
          shouldUnregister: true,
        },
      ),
    [idx, selectedState, register],
  )

  const brideFirstNameRegister = useMemo(
    () =>
      getNameRegister(
        IndividualProfileInputTypes.Bride,
        IndividualProfileInputTypes.FirstName,
      ),
    [getNameRegister],
  )

  const brideFamilyNameRegister = useMemo(
    () =>
      getNameRegister(
        IndividualProfileInputTypes.Bride,
        IndividualProfileInputTypes.FamilyName,
      ),
    [getNameRegister],
  )

  const groomFirstNameRegister = useMemo(
    () =>
      getNameRegister(
        IndividualProfileInputTypes.Groom,
        IndividualProfileInputTypes.FirstName,
      ),
    [getNameRegister],
  )

  const groomFamilyNameRegister = useMemo(
    () =>
      getNameRegister(
        IndividualProfileInputTypes.Groom,
        IndividualProfileInputTypes.FamilyName,
      ),
    [getNameRegister],
  )

  useEffect(() => {
    setValue(
      `${IndividualProfileInputTypes.Document}.${idx}.${IndividualProfileInputTypes.DocumentCategory}`,
      KycDocumentCategoryTypes.SECONDARY as never,
    )
  }, [idx, setValue])
  const idNumberFieldName = useMemo(
    () =>
      `${IndividualProfileInputTypes.Document}.${idx}.${IndividualProfileInputTypes.IdNumber}` as IndividualProfileInputTypes.Document,
    [idx],
  )

  // unregister id number because used in multiple components
  useEffect(
    () => () => {
      unregister(idNumberFieldName)
    },
    [idx, unregister, idNumberFieldName],
  )

  return (
    <div className={wrapperClasses}>
      <SelectFormField
        options={statesOptions}
        className="basis-[32%]"
        label={t('documentForm.stateOfRegistration')}
        placeholder={t('documentForm.stateOfRegistration')}
        control={control}
        name={`${IndividualProfileInputTypes.Document}.${idx}.${IndividualProfileInputTypes.State}`}
        error={
          !!getError(
            `${IndividualProfileInputTypes.Document}.${idx}.${IndividualProfileInputTypes.State}`,
            errors,
          )
        }
        rules={{ required: true }}
        testId={{
          input: individualProfileVueMigratedQa.document.region,
        }}
      />

      <FrankieTextField
        className="basis-[32%]"
        label={t('documentForm.registrationNumber')}
        placeholder={t('documentForm.registrationNumber')}
        error={!!getError(idNumberFieldName, errors)}
        {...register(idNumberFieldName, {
          required: !group5.includes(selectedState),
        })}
        testId={{
          input: individualProfileVueMigratedQa.document.idNumberField,
        }}
      />

      <div className="basis-[32%]" />

      <div className="basis-full flex">
        <FrankieTextField
          className="basis-[32%]"
          label={t('documentForm.marriageDate')}
          type="date"
          error={!!getError(marriageDateRegister.name, errors)}
          {...marriageDateRegister}
          testId={{
            input: individualProfileVueMigratedQa.document.marriageDate,
          }}
        />
        {certificateRegistrationYearRegister && (
          <SelectFormField
            className="basis-[32%]"
            options={timeOptions}
            control={control}
            name={certificateRegistrationYearRegister.name}
            label={`${t('documentForm.registrationDate')}`}
            shouldUnregister
            placeholder={t('documentForm.registrationDatePlaceholder')}
          />
        )}
        {certificateDateOfRegistrationRegister && (
          <FrankieTextField
            className="basis-[32%]"
            type="date"
            label={`${t('documentForm.registrationDate')}`}
            {...certificateDateOfRegistrationRegister}
            error={
              !!getError(certificateDateOfRegistrationRegister.name, errors)
            }
            placeholder={t('documentForm.registrationDate')}
          />
        )}
      </div>

      <div className="basis-[100%]">
        <div className="pb-1 font-bold">{t('documentForm.partnerOne')}</div>
        <div className={`${wrapperClasses} w-full`}>
          <FrankieTextField
            className="basis-[32%]"
            label={t('documentForm.firstGiveName')}
            placeholder={t('documentForm.firstGiveName')}
            {...brideFirstNameRegister}
            error={!!getError(brideFirstNameRegister.name, errors)}
            testId={{
              input: individualProfileVueMigratedQa.document.brideNameGiven,
            }}
          />
          <FrankieTextField
            className="basis-[32%]"
            label={`${t('documentForm.otherGivenName')} ${t('optional')}`}
            placeholder={t('documentForm.otherGivenName')}
            {...getNameRegister(
              IndividualProfileInputTypes.Bride,
              IndividualProfileInputTypes.OtherName,
            )}
            testId={{
              input: individualProfileVueMigratedQa.document.brideNameOther,
            }}
          />
          <FrankieTextField
            className="basis-[32%]"
            label={t('documentForm.familyName')}
            placeholder={t('documentForm.familyName')}
            {...brideFamilyNameRegister}
            error={!!getError(brideFamilyNameRegister.name, errors)}
            testId={{
              input: individualProfileVueMigratedQa.document.brideNameFamily,
            }}
          />
        </div>
      </div>
      <div className="basis-[100%]">
        <div className="pb-1 font-bold">{t('documentForm.partnerTwo')}</div>
        <div className={`${wrapperClasses} w-full`}>
          <FrankieTextField
            className="basis-[32%]"
            label={t('documentForm.firstGiveName')}
            placeholder={t('documentForm.firstGiveName')}
            {...groomFirstNameRegister}
            error={!!getError(groomFirstNameRegister.name, errors)}
            testId={{
              input: individualProfileVueMigratedQa.document.groomNameGiven,
            }}
          />
          <FrankieTextField
            className="basis-[32%]"
            label={`${t('documentForm.otherGivenName')} ${t('optional')}`}
            placeholder={t('documentForm.otherGivenName')}
            {...getNameRegister(
              IndividualProfileInputTypes.Groom,
              IndividualProfileInputTypes.OtherName,
            )}
            testId={{
              input: individualProfileVueMigratedQa.document.groomNameOther,
            }}
          />
          <FrankieTextField
            className="basis-[32%]"
            label={t('documentForm.familyName')}
            placeholder={t('documentForm.familyName')}
            {...groomFamilyNameRegister}
            error={!!getError(groomFamilyNameRegister.name, errors)}
            testId={{
              input: individualProfileVueMigratedQa.document.groomNameFamily,
            }}
          />
          <DocumentCategory
            disabled
            className="basis-[32%]"
            form={form}
            idx={idx}
          />
        </div>
      </div>
      {stateShowDateOfPrint.includes(selectedState) && (
        <>
          {certificateNumberRegister && (
            <FrankieTextField
              className="basis-[32%]"
              label={`${t('documentForm.certificateNumber')} ${
                group4.includes(selectedState) ? '' : t('optional')
              }`}
              {...certificateNumberRegister}
              error={!!getError(certificateNumberRegister.name, errors)}
              placeholder={t('documentForm.certificateNumber')}
            />
          )}

          {dateOfPrintRegister && (
            <FrankieTextField
              type="date"
              className="basis-[32%]"
              label={`${t('documentForm.dateOfPrint')} ${
                [...group4, ...group1].includes(selectedState)
                  ? ''
                  : t('optional')
              }`}
              {...dateOfPrintRegister}
              error={!!getError(dateOfPrintRegister.name, errors)}
              placeholder={t('documentForm.datePlaceholder')}
            />
          )}
          <div className="basis-[32%]" />
        </>
      )}
    </div>
  )
}
