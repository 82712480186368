import { useQuery } from '@tanstack/react-query'

import { organisationApi } from '../../api/organisation.api'

export const BusinessMCCQueryKey = 'business-mcc-query'

export const useBusinessMCC = () =>
  useQuery({
    queryKey: [BusinessMCCQueryKey],
    queryFn: async () => {
      const { data } = await organisationApi.getMCC()
      return data
    },
  })
