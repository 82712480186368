import React from 'react'

import { GridRow, GridRowProps } from '@mui/x-data-grid-pro'
import { Link } from 'react-router-dom'

import { FrankieIcon, FrankieLoader } from 'frankify/src'

import { BLOCKLIST_TABLE_KEY } from 'features/blocklist-table/blocklist-table.key'
import { blocklistTableEn } from 'features/blocklist-table/locale/blocklist-table.en'
import { BlocklistData } from 'features/blocklist-table/model/blocklist.model'
import {
  blocklistTableLoadingQa,
  blocklistTableOverlayQa,
  blocklistTableSlotQa,
} from 'features/blocklist-table/qa/blocklist-table.qa'

import { ApplicantId } from 'entities/applicant'

import { useI18n } from 'shared/i18n'

import { BlockLoadingType } from '../../state/blocklist-query/blocklist.query'

export type RowProps = GridRowProps & {
  getApplicantGeneralInfoPath: (applicantId: ApplicantId) => string
}

export function BlocklistRow(props: RowProps) {
  const { entityId } = props.row as BlocklistData
  const { getApplicantGeneralInfoPath } = props

  if (!entityId) {
    return <GridRow {...props} />
  }

  return (
    <Link
      to={getApplicantGeneralInfoPath(entityId)}
      className="!transition-none hover:!transition-none hover:!text-current hover:!text-mono-black"
      data-qa={blocklistTableSlotQa.link}
    >
      <GridRow data-qa={blocklistTableSlotQa.grid} {...props} />
    </Link>
  )
}

type LoadingOverlayProps = {
  type: BlockLoadingType
}

export function BlocklistLoadingOverlay({ type }: LoadingOverlayProps) {
  const t = useI18n([BLOCKLIST_TABLE_KEY], { keys: blocklistTableEn })

  return (
    <div
      className="flex align-center justify-center flex-col w-full h-full bg-mono-white opacity-80"
      data-qa={blocklistTableLoadingQa.main}
    >
      <div>
        <div id="loader">
          <FrankieLoader
            loading
            className="min-h-[76px]"
            size="md"
            testId={{ loader: blocklistTableLoadingQa.loader }}
          />
        </div>
        <div className="flex flex-col items-center mt-3">
          <span
            data-qa={blocklistTableLoadingQa.filter}
            className="inline-block text-md font-semibold leading-6 text-tertiary-grey-800"
          >
            {type === 'applyingFilter' && t('loading.applyingFilter')}
            {type === 'loading' && t('loading.data')}
            {type === 'loadingMore' && t('loading.moreData')}
          </span>
        </div>
      </div>
    </div>
  )
}

type NoRowOverlayProps = Partial<
  Record<'noFilterData' | 'noData' | 'noSearchData', boolean>
> & { error?: string }

export function BlocklistNoRowOverlay({
  noData = false,
  noFilterData = false,
  noSearchData = false,
  error,
}: NoRowOverlayProps) {
  const t = useI18n([BLOCKLIST_TABLE_KEY], { keys: blocklistTableEn })

  return (
    <div
      className="flex align-center justify-center flex-col w-full h-full bg-mono-white opacity-80"
      data-qa={blocklistTableOverlayQa.main}
    >
      <div>
        <FrankieIcon
          size="xl"
          name="mdiMagnify"
          className="text-primary-300 flex justify-center"
          testId={{ icon: blocklistTableOverlayQa.icon }}
        />
        <div
          className="flex flex-col items-center mt-3"
          data-qa={blocklistTableOverlayQa.content}
        >
          {error ? (
            <>
              <span className="text-lg font-bold inline-block leading-6">
                {t('error')}
              </span>
              <span className="text-sm font-normal max-w-[430px] text-center mt-3 leading-6">
                {error}
              </span>
            </>
          ) : (
            <>
              <span className="text-lg font-bold inline-block leading-6">
                {noData && t('placeholder.emptyTitle.noData')}
                {noFilterData && t('placeholder.emptyTitle.noFilterData')}
                {noSearchData && t('placeholder.emptyTitle.noSearchData')}
              </span>
              <span className="text-sm font-normal max-w-[430px] text-center mt-3 leading-6">
                {noData && t('placeholder.emptySubtitle.noData')}
                {noFilterData && t('placeholder.emptySubtitle.noFilterData')}
                {noSearchData && t('placeholder.emptySubtitle.noSearchData')}
              </span>
            </>
          )}
        </div>
      </div>
    </div>
  )
}
