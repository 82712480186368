import React, { Ref, useEffect, useMemo, useState } from 'react'

import {
  DataGridPro,
  GridColDef,
  GridRow,
  GridRowProps,
  GridRowSelectionModel,
  useGridApiRef,
} from '@mui/x-data-grid-pro'
import cx from 'classnames'
import { Link, useLocation } from 'react-router-dom'

import {
  FrankieButton,
  FrankieIcon,
  FrankieIconName,
  FrankieTooltip,
} from 'frankify/src'

import { PROFILES_FILTER_KEY, profilesFilterEn } from 'features/profiles-filter'
import {
  useTableSelectionState,
  useUpdateTableSelectionState,
} from 'features/profiles-table/state/profile-table-selection.state'

import { ApplicantEntityTypes } from 'entities/applicant'
import {
  getWorkflowStatusKey,
  IProfile,
  ProfileStateTypes,
  useFrankie2R2Customer,
  WorkflowStatusKeysTypes,
} from 'entities/entity'
import { useHasFeatureFlag, useSessionQuery } from 'entities/session'
import { getUserList } from 'entities/user'

import { useI18n } from 'shared/i18n'
import {
  DateCell,
  NameCell,
  AvatarCell,
  BadgeCell,
  IndicatorCell,
  BadgeListCell,
  GridCheckbox,
} from 'shared/tables-common'
import { TrackingEventsTypes, trackingManager } from 'shared/tracking'

import {
  PROFILES_TABLE_KEY,
  profilesTableEn,
} from '../../locale/profiles-table.en'
import {
  getEntityIssues,
  getRiskIndicator,
  getWorkFlowStatusBadge,
} from '../../model/profiles-table.model'
import { profilesTableQa } from '../../qa/profiles-table.qa'

function CustomRow(
  props: GridRowProps &
    Pick<
      Props,
      'getApplicantGeneralInfoPath' | 'getApplicantProfileInsightPath'
    >,
) {
  const { entityId, state, workflowSummaries } = props.row as IProfile
  const {
    getApplicantGeneralInfoPath,
    getApplicantProfileInsightPath,
    ...gridRowProps
  } = props
  const isFrankie2R2 = useFrankie2R2Customer()

  if (!entityId) {
    return <GridRow {...gridRowProps} />
  }

  const isArchived = state === ProfileStateTypes.ARCHIVED

  const isUnchecked =
    getWorkflowStatusKey(workflowSummaries?.at(0)) ===
    WorkflowStatusKeysTypes.UNCHECKED

  const workflowExecutionId = workflowSummaries
    ?.sort(
      (a, b) =>
        new Date(String(b.startedAt)).getTime() -
        new Date(String(a.startedAt)).getTime(),
    )
    ?.at(0)?.workflowExecutionId

  return (
    <Link
      to={
        isArchived || (isUnchecked && isFrankie2R2)
          ? getApplicantProfileInsightPath(entityId)
          : getApplicantGeneralInfoPath(entityId, workflowExecutionId)
      }
      className="!transition-none hover:!transition-none hover:!text-current"
    >
      <GridRow {...gridRowProps} />
    </Link>
  )
}

const sortIcons: Record<'asc' | 'desc', FrankieIconName> = {
  desc: 'mdiArrowDown',
  asc: 'mdiArrowUp',
}

const sortingTooltipMapping: Record<string, 'sortAsc' | 'sortDesc'> = {
  asc: 'sortDesc',
  desc: 'sortAsc',
}

const iconMapping: Record<ApplicantEntityTypes, FrankieIconName> = {
  [ApplicantEntityTypes.Individual]: 'mdiAccountOutline',
  [ApplicantEntityTypes.Organisation]: 'mdiOfficeBuildingOutline',
}

const TABLE_MIN_WIDTH = 1136

function EmptyOverlay() {
  return null
}

export type Props = {
  profiles: IProfile[]
  isLoading: boolean
  onScrollEnd: () => void
  className?: string
  getApplicantProfileInsightPath: (applicantId: string) => string
  getApplicantGeneralInfoPath: (
    applicantId: string,
    workflowExecutionId?: string,
  ) => string
  tableRef?: Ref<HTMLDivElement>
  sortModel: {
    field: 'createdAt' | 'updatedAt'
    sort: 'asc' | 'desc' | null
  }[]
  setSortModel: (
    sort: { field: 'createdAt' | 'updatedAt'; sort: 'asc' | 'desc' | null }[],
  ) => void
  trackingEvents: { show: TrackingEventsTypes; scroll: TrackingEventsTypes }
}

export function ProfilesTable({
  profiles,
  isLoading,
  className,
  onScrollEnd,
  getApplicantGeneralInfoPath,
  getApplicantProfileInsightPath,
  tableRef,
  sortModel,
  setSortModel,
  trackingEvents,
}: Props) {
  const t = useI18n([PROFILES_TABLE_KEY], { keys: profilesTableEn })
  const filterT = useI18n([PROFILES_FILTER_KEY], { keys: profilesFilterEn })
  const { data: session } = useSessionQuery()

  const { bulkAssignEnabled } = useHasFeatureFlag({
    bulkAssignEnabled: 'bulkAssignEnabled',
  })
  const apiRef = useGridApiRef()
  const { state } = useLocation()

  const [alreadyScrolledToEntity, setAlreadyScrolledToEntity] = useState(false)
  const { data: rowSelectionModel } = useTableSelectionState()
  const setRowSelectionModel = useUpdateTableSelectionState()

  const headerClassName =
    '!p-0 text-xs font-medium uppercase bg-tertiary-grey-50 text-tertiary-grey-500 !outline-none'
  const cellClassName = '!outline-none'

  const { data: pageData } = useSessionQuery()
  const userList = getUserList(pageData)

  const columns: GridColDef<IProfile>[] = useMemo(
    () => [
      {
        field: 'name',
        headerName: t('headers.name'),
        minWidth: 252,
        flex: 252 / TABLE_MIN_WIDTH,
        disableReorder: true,
        disableColumnMenu: true,
        hideSortIcons: true,
        resizable: false,
        sortable: false,
        headerClassName,
        cellClassName,

        renderHeader: params => (
          <span className="!px-4 !py-2">{params.colDef.headerName}</span>
        ),
        renderCell: params => {
          const { row } = params
          const entityName =
            row.entityName && row.entityName !== '<empty>'
              ? row.entityName
              : row.entityId
          const { customerReference } = row
          return (
            <NameCell
              title={entityName}
              subtitle={customerReference ?? ''}
              icon={
                row.entityType === 'UNKNOWN'
                  ? undefined
                  : iconMapping[row.entityType]
              }
            />
          )
        },
      },
      {
        field: 'workflowStatus',
        headerName: t('headers.lastWorkflowStatus'),
        minWidth: 187,
        flex: 187 / TABLE_MIN_WIDTH,
        disableReorder: true,
        disableColumnMenu: true,
        hideSortIcons: true,
        resizable: false,
        sortable: false,
        headerClassName,
        cellClassName,
        renderHeader: params => (
          <span className="!px-4 !py-2">{params.colDef.headerName}</span>
        ),
        renderCell: params => (
          <BadgeCell
            {...getWorkFlowStatusBadge(params.row)}
            className="min-w-[110px]"
          />
        ),
      },
      {
        field: 'issues',
        headerName: t('headers.issues'),
        minWidth: 163,
        flex: 163 / TABLE_MIN_WIDTH,
        disableReorder: true,
        disableColumnMenu: true,
        hideSortIcons: true,
        resizable: false,
        sortable: false,
        headerClassName,
        cellClassName,
        renderHeader: params => (
          <span className="!px-4 !py-2">{params.colDef.headerName}</span>
        ),
        renderCell: params => (
          <BadgeListCell badges={getEntityIssues(params.row, filterT)} />
        ),
      },
      {
        field: 'riskLevel',
        headerName: t('headers.riskLevel'),
        minWidth: 130,
        flex: 130 / TABLE_MIN_WIDTH,
        disableReorder: true,
        disableColumnMenu: true,
        hideSortIcons: true,
        resizable: false,
        sortable: false,
        headerClassName,
        cellClassName,
        renderHeader: params => (
          <span className="!px-4 !py-2">{params.colDef.headerName}</span>
        ),
        renderCell: params => (
          <IndicatorCell {...getRiskIndicator(params.row, filterT)} />
        ),
      },
      {
        field: 'createdAt',
        headerName: t('headers.createdDate'),
        minWidth: 110,
        flex: 110 / TABLE_MIN_WIDTH,
        disableReorder: true,
        disableColumnMenu: true,
        hideSortIcons: true,
        resizable: false,
        sortable: true,
        headerClassName,
        cellClassName,
        renderHeader: params => {
          const sortItem = sortModel.find(
            sortField => sortField.field === 'createdAt',
          )
          let sortIcon: FrankieIconName = 'mdiSwapVertical'
          let iconClassName = 'ml-1 pointer-events-none'
          let tooltip = t('noSortTooltipCreated')

          if (sortItem?.sort) {
            sortIcon = sortIcons[sortItem.sort]
            iconClassName = 'ml-1 pointer-events-none text-primary-800'
            tooltip = t(sortingTooltipMapping[sortItem.sort])
          }

          return (
            <span className="!px-4 !py-2 flex">
              {params.colDef.headerName}
              <FrankieTooltip position="top" body={<div>{tooltip}</div>}>
                <div>
                  <FrankieIcon
                    name={sortIcon}
                    size="xs"
                    className={iconClassName}
                  />
                </div>
              </FrankieTooltip>
            </span>
          )
        },
        renderCell: params => <DateCell date={params.row.createdAt} />,
      },
      {
        field: 'updatedAt',
        headerName: t('headers.lastUpdatedDate'),
        minWidth: 110,
        flex: 110 / TABLE_MIN_WIDTH,
        disableReorder: true,
        disableColumnMenu: true,
        hideSortIcons: true,
        resizable: false,
        sortable: true,
        headerClassName,
        cellClassName,
        renderHeader: params => {
          const sortItem = sortModel.find(
            sortField => sortField.field === 'updatedAt',
          )

          let sortIcon: FrankieIconName = 'mdiSwapVertical'
          let iconClassName = 'ml-1 pointer-events-none'
          let tooltip = t('noSortTooltipUpdated')

          if (sortItem?.sort) {
            sortIcon = sortIcons[sortItem.sort]
            iconClassName = 'ml-1 text-primary-800 pointer-events-none'
            tooltip = t(sortingTooltipMapping[sortItem.sort])
          }

          return (
            <span className="!px-4 !py-2 flex items-center">
              {params.colDef.headerName}
              <FrankieTooltip position="top" body={<div>{tooltip}</div>}>
                <div>
                  <FrankieIcon
                    name={sortIcon}
                    size="xs"
                    className={iconClassName}
                  />
                </div>
              </FrankieTooltip>
            </span>
          )
        },
        renderCell: params => <DateCell date={params.row.updatedAt ?? ''} />,
      },
      {
        field: 'lastWorkflow',
        headerName: t('headers.lastWorkflow'),
        minWidth: 140,
        flex: 140 / TABLE_MIN_WIDTH,
        disableReorder: true,
        disableColumnMenu: true,
        hideSortIcons: true,
        resizable: false,
        sortable: false,
        headerClassName,
        cellClassName,
        renderHeader: params => (
          <span className="!px-4 !py-2">{params.colDef.headerName}</span>
        ),
        renderCell: params => {
          const lastWorkflow = params.row.workflowSummaries?.at(0)

          if (!lastWorkflow?.workflowName) {
            return null
          }
          return (
            <div className="w-full h-full px-4 py-3 flex items-center whitespace-nowrap">
              <FrankieTooltip
                position="top"
                body={
                  <div>
                    <div>{lastWorkflow.workflowName}</div>
                  </div>
                }
                className="!whitespace-nowrap !max-w-[600px]"
              >
                <span className="data-hj-suppress text-sm font-normal text-tertiary-grey-700 text-ellipsis overflow-hidden">
                  {lastWorkflow.workflowName}
                </span>
              </FrankieTooltip>
            </div>
          )
        },
      },
      {
        field: 'assignee',
        headerName: t('headers.assignee'),
        minWidth: 89,
        flex: 89 / TABLE_MIN_WIDTH,
        disableReorder: true,
        disableColumnMenu: true,
        hideSortIcons: true,
        resizable: false,
        sortable: false,
        headerClassName: cx(
          headerClassName,
          '[&_.MuiDataGrid-columnHeaderDraggableContainer]:justify-center',
        ),
        cellClassName,
        renderHeader: params => (
          <span className="!px-4 !py-2">{params.colDef.headerName}</span>
        ),
        renderCell: params => {
          const userFromList = userList.find(
            user => user.email === params.row.assignee,
          )
          return <AvatarCell name={userFromList?.realname} />
        },
      },
    ],
    [sortModel, t, userList, pageData, filterT],
  )

  const getRowId = (row: IProfile) => row.entityId || ''
  const isRowSelectable = () => bulkAssignEnabled
  const isCellEditable = () => false
  const getRowClassName = () => 'hover:bg-tertiary-grey-200'
  const sortingOrder = ['asc', 'desc'] as const

  const handleSortChange = (
    sort: { field: 'createdAt' | 'updatedAt'; sort: 'asc' | 'desc' }[],
  ) => {
    setSortModel(sort)
  }

  const handleScrollEnd = () => {
    if (!profiles.length) return

    onScrollEnd()
  }

  // track event
  useEffect(() => {
    setRowSelectionModel([])
    trackingManager.track(trackingEvents.show)
  }, [trackingEvents.show])

  useEffect(() => {
    if (state?.entityId && !alreadyScrolledToEntity) {
      requestIdleCallback(() => {
        const index = profiles.findIndex(
          i => i.entityId === (state.entityId as string),
        )

        if (index) {
          setAlreadyScrolledToEntity(true)
          apiRef.current.scrollToIndexes({ rowIndex: index })
        }
      })
    }
  }, [state, apiRef, profiles, alreadyScrolledToEntity])

  const [isScrollingHorizontally, setIsScrollingHorizontally] = useState(false)

  useEffect(() => {
    const container =
      tableRef && 'current' in tableRef ? tableRef.current : null

    const handleScroll = () => {
      if (container && container.scrollLeft > 0) {
        setIsScrollingHorizontally(true)
      } else {
        setIsScrollingHorizontally(false)
      }
    }

    container?.addEventListener('scroll', handleScroll)

    return () => {
      container?.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const selectionLimit = session?.features.bulkAssignLimit ?? 20
  const tableKey = isScrollingHorizontally ? 'tableScroll' : 'table'

  const onRowSelectionModelChange = (i: GridRowSelectionModel) => {
    const isAllSelected = i.length === selectionLimit
    if (isAllSelected)
      trackingManager.track(TrackingEventsTypes.BulkActionsSelectAll)
    setRowSelectionModel(i.slice(0, selectionLimit))
  }

  const onClickSelectAllLoaded = () => {
    trackingManager.track(TrackingEventsTypes.BulkActionsSelectLoaded)
    setRowSelectionModel(profiles.map(p => p.entityId).slice(0, selectionLimit))
  }

  return (
    <>
      {rowSelectionModel?.length && profiles.length ? (
        <div className="bg-primary-container text-sm p-4 mr-8 mb-4 leading-5 rounded-1.5 flex items-center justify-center">
          {rowSelectionModel.length >= selectionLimit ? (
            <span>{t('noSelected', { count: selectionLimit })}</span>
          ) : (
            <span>
              {rowSelectionModel.length === profiles.length
                ? t('allSelected', { count: rowSelectionModel.length })
                : t('rowSelected', { count: rowSelectionModel.length })}
              {rowSelectionModel.length !== profiles.length && (
                <FrankieButton
                  noStyles
                  className="text-primary-800 cursor-pointer"
                  onClick={onClickSelectAllLoaded}
                >
                  {t('selectAll', {
                    count: Math.min(profiles.length, selectionLimit),
                  })}
                </FrankieButton>
              )}
            </span>
          )}
        </div>
      ) : null}
      <DataGridPro
        key={tableKey}
        data-qa={profilesTableQa.container}
        ref={tableRef}
        rows={profiles}
        slots={{
          noRowsOverlay: EmptyOverlay,
          loadingOverlay: EmptyOverlay,
          row: CustomRow,
          baseCheckbox: GridCheckbox,
        }}
        slotProps={{
          row: {
            getApplicantGeneralInfoPath,
            getApplicantProfileInsightPath,
          },
          baseCheckbox: {
            onClick: event => {
              event.stopPropagation()
            },
          },
        }}
        sortModel={sortModel}
        sortingOrder={sortingOrder}
        sortingMode="server"
        onRowsScrollEnd={handleScrollEnd}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onSortModelChange={handleSortChange}
        getRowClassName={getRowClassName}
        loading={isLoading}
        className={cx(className, '[&_.MuiDataGrid-cellCheckbox]:!outline-none')}
        apiRef={apiRef}
        getRowId={getRowId}
        columns={columns}
        rowHeight={68}
        columnHeaderHeight={34}
        columnBuffer={0}
        isRowSelectable={isRowSelectable}
        isCellEditable={isCellEditable}
        disableRowSelectionOnClick
        disableColumnSelector
        disableColumnFilter
        disableColumnMenu
        checkboxSelection={bulkAssignEnabled}
        onRowSelectionModelChange={onRowSelectionModelChange}
        rowSelectionModel={rowSelectionModel}
        disableDensitySelector
        showCellVerticalBorder={false}
        showColumnVerticalBorder={false}
        hideFooterPagination
        hideFooter
        hideFooterSelectedRowCount
        disableVirtualization
        sx={{
          '& .MuiDataGrid-columnHeaderCheckbox': {
            bgcolor: 'rgb(249 250 251 / var(--tw-bg-opacity))',
            outline: 'none !important',
          },
          '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer':
            {
              margin: 'auto',
              bgcolor: 'rgb(249 250 251 / var(--tw-bg-opacity))',
            },
          '.MuiDataGrid-columnSeparator': {
            display: 'none',
          },
          '&.MuiDataGrid-root': {
            border: 'none',
            marginRight: '32px',
            height: `calc(100% - ${rowSelectionModel?.length ? 72 : 0}px)`,
          },
          '.MuiDataGrid-columnHeaders': {
            minHeight: 'unset !important',
            maxHeight: 'unset !important',
            lineHeight: 'unset !important',
            borderRadius: '0 !important',
          },
          '.MuiDataGrid-columnHeaderTitleContainer': {
            display: 'inline-block !important',
            flex: 'none !important',
          },
          '.MuiDataGrid-cell': {
            minHeight: 'unset !important',
            maxHeight: 'unset !important',
            lineHeight: 'unset !important',
            padding: '0 !important',
          },
          '& .MuiDataGrid-row.Mui-selected': {
            bgcolor: 'transparent',
          },
          '& .MuiDataGrid-row.Mui-selected:hover': {
            bgcolor: 'transparent',
          },
        }}
      />
    </>
  )
}
