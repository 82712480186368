import { useQuery } from '@tanstack/react-query'

import { organisationSettingsApi } from '../../api/organisation-settings.api'
import { IOrganisationSetting } from '../../model/organisation-settings.model'

export const OrganisationSettingsQueryKey = ['organisation-settings']

export const useOrganisationSettingsQuery = ({
  canFetchOrganisationSettings,
}: {
  canFetchOrganisationSettings: boolean
}) =>
  useQuery<IOrganisationSetting[]>({
    queryKey: OrganisationSettingsQueryKey,
    queryFn: async () => {
      if (!canFetchOrganisationSettings) {
        throw new Error('No access')
      }
      const { data } = await organisationSettingsApi.getOrganisationSettings()
      return data
    },
  })
