import React, { useMemo } from 'react'

import { SxProps } from '@mui/material'
import {
  GridColDef,
  GridRenderCellParams,
  GridRow,
  GridRowProps,
} from '@mui/x-data-grid-pro'

import { FrankieLoader } from 'frankify/src'

import { ApplicantIssueActionBadge } from 'entities/applicant'

import { useI18n } from 'shared/i18n'

import { APPLICANT_RISK_SCORE_KEY } from '../../applicant-risk-score.key'
import { applicantRiskScoreEn } from '../../locale/applicant-risk-score.en'
import {
  ApplicantRiskScoreRecord,
  ApplicantRiskScoreRecordProperties,
  RiskColorFlag,
  RiskColorType,
} from '../../model/applicant-risk-score.model'
import { applicantRiskScoreDataGridHelperQa } from '../../qa/applicant-risk-score.qa'

export type RiskScoreDataGridColumn = (GridColDef<ApplicantRiskScoreRecord> & {
  field: ApplicantRiskScoreRecordProperties
})[]

export const RISK_SCORE_DATA_GRID_MIN_WIDTH = 600

export const riskScoreDataGridCellWidth = (width: number) => ({
  minWidth: width,
  flex: width / RISK_SCORE_DATA_GRID_MIN_WIDTH,
})

export function RiskScoreDataGridRow({
  ...rowProps
}: GridRowProps & { row: ApplicantRiskScoreRecord }) {
  const { color } = rowProps.row

  const className = useMemo(() => {
    const colorClass: Record<RiskColorType, Record<RiskColorFlag, string>> = {
      bg: {
        high: 'bg-tertiary-red-100',
        medium: 'bg-tertiary-amber-50',
        low: 'bg-tertiary-green-100',
        unknown: 'bg-tertiary-grey-100',
      },
      text: {
        high: 'text-tertiary-red-500',
        medium: 'text-tertiary-yellow-500',
        low: 'text-tertiary-green-400',
        unknown: 'bg-tertiary-grey-100',
      },
    }

    if (color) {
      const { appliedOn, type } = color

      return `${type ? colorClass[appliedOn][type] : ''} ${
        appliedOn === 'bg' ? 'text-secondary-900' : ''
      } font-semibold`
    }

    return 'text-secondary-900'
  }, [color])

  return (
    <div className={className}>
      <GridRow {...rowProps} />
    </div>
  )
}

export function RiskScoreDataGridCell({
  row,
  field,
}: GridRenderCellParams<ApplicantRiskScoreRecord> & {
  field: ApplicantRiskScoreRecordProperties
}) {
  const boldFontField = [
    'value',
    'score',
  ] as ApplicantRiskScoreRecordProperties[]

  if (field === 'level') {
    return row.type === 'badge' ? (
      <ApplicantIssueActionBadge
        // Checking for unknown to be displayed as green - this is as per requirement
        type={row.level.type === 'unknown' ? 'risk_low' : row.level.type}
        masterLabel={row.level.label}
        className="w-full"
      />
    ) : (
      <div className="font-semibold">{row.level}</div>
    )
  }

  return (
    <div
      className={`${boldFontField.includes(field) ? 'font-semibold' : ''}`}
      data-qa={applicantRiskScoreDataGridHelperQa.gridCell}
    >
      {row[field]}
    </div>
  )
}

export function RiskScoreDataGridLoadingOverlay() {
  const t = useI18n([APPLICANT_RISK_SCORE_KEY], {
    keys: applicantRiskScoreEn,
  })

  return (
    <div
      className="flex align-center justify-center flex-col w-full h-full bg-mono-white opacity-80"
      data-qa={applicantRiskScoreDataGridHelperQa.loader}
    >
      <FrankieLoader
        label={t('refreshing')}
        loading
        className="text-tertiary-grey-800 text-md font-semibold min-h-[250px]"
        size="sm"
      />
    </div>
  )
}

export const riskScoreDataGridSx: SxProps = {
  '& .MuiDataGrid-columnSeparator': {
    display: 'none',
  },
  '& .MuiDataGrid-columnHeaderTitleContainerContent': {
    height: '100%',
  },
  '& .MuiDataGrid-columnHeaders': {
    lineHeight: 'unset !important',
    borderRadius: '0 !important',
  },
  '& .MuiDataGrid-row--lastVisible': {
    '& .MuiDataGrid-cell': { border: '0px !important' },
  },
  '& .MuiDataGrid-columnHeaderTitleContainer': {
    padding: '0 12px',
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    fontWeight: 'inherit',
  },
  '& .MuiDataGrid-row': {
    lineHeight: 'unset !important',
  },
  '& .MuiDataGrid-cell': {
    maxHeight: 'unset !important',
    p: '12px',
  },
  '& .MuiDataGrid-cellContent': {
    whiteSpace: 'normal',
    color: 'inherit',
  },
  '& .MuiDataGrid-row.Mui-hovered, .MuiDataGrid-row:hover': {
    backgroundColor: 'transparent',
  },
}
