import { bffClient, IClient } from 'shared/client'

import { IF2Roles, IPermissionFacade } from '../model/role-management-f2.model'

export class RoleManagementF2Api {
  client: IClient

  constructor(client: IClient) {
    this.client = client
  }

  async getPermissionList() {
    return this.client.get<IPermissionFacade[]>('/f2/v1/permissions-facade')
  }

  async getRoleList() {
    return this.client.get<IF2Roles[]>('/f2/v1/roles')
  }
}

export const roleManagementF2Api = new RoleManagementF2Api(bffClient)
