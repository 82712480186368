import { useEffect, useRef, useState } from 'react'

import { trackingManager } from '../service/tracking-manager.service'

type Props<T> = {
  data?: T
  eventsType?: string
  options?: Record<string, string>
}

export function useTrackingShowEvents<T>({
  data,
  eventsType,
  options,
}: Props<T>) {
  const ref = useRef(false)

  const [triggerState, triggerEvent] = useState(false)

  useEffect(() => {
    if ((data || triggerState) && !ref.current && eventsType) {
      trackingManager.track(eventsType, options)
      ref.current = true
    }
  }, [data, eventsType, options, triggerState])

  return { triggerEvent }
}
