import { useCallback, useEffect, useState } from 'react'

/**
 * A hook to manage a state that is triggered for a short period of time.
 * @param triggerDelay The delay in milliseconds for the state to be reset.
 */
export const useTriggerState = (triggerDelay = 1000, autoTrue = false) => {
  const [state, setState] = useState<boolean>(false)

  const trigger = useCallback(() => {
    setState(true)
    setTimeout(() => {
      setState(false)
    }, triggerDelay)
  }, [triggerDelay])

  useEffect(() => {
    if (autoTrue) {
      setTimeout(() => {
        setState(true)
      }, triggerDelay)
    }
  }, [autoTrue, triggerDelay])

  return [state, trigger] as const
}
