import React, { useEffect, useMemo, useState } from 'react'

import { FrankieDivider, FrankieLoader } from 'frankify/src'

import { ApplicantResponse } from 'entities/applicant'

import { DateFormatTypes, formatDate } from 'shared/date-time'
import { useI18n } from 'shared/i18n'
import { Nullable } from 'shared/typescript'

import { APPLICANT_POTENTIAL_MATCHES_KEYS } from '../../applicant-potential-matches.keys'
import { applicantPotentialMatchesEn } from '../../locale/applicant-potential-matches.en'
import {
  ApplicantMatchesTypes,
  MatchesTypes,
  getPotentialFieldValue,
  potentialMatchFieldLabel,
} from '../../models/applicant-matches.models'
import {
  BlacklistResponseType,
  ErrorClassesTypes,
  getMatchedFields,
} from '../../models/blacklist.model'
import { appPotentialMatchesQa } from '../../qa/applicant-potential-matches.qa'

type FieldViewProps = {
  label: string
  value?: string | null
  errorClasses: string[]
  errorClassType?: ErrorClassesTypes
  valueClass?: string
  wrapperClass?: string
}

/**
 * Field View Helper Component
 */
function FieldView({
  label,
  value,
  errorClasses,
  errorClassType = ErrorClassesTypes.DEFAULT,
  valueClass = '',
  wrapperClass = '',
}: FieldViewProps) {
  const errorClassKey = (fieldType: ErrorClassesTypes) =>
    `mr-1 text-tertiary-grey-500 basis-1/4 ${
      errorClasses.includes(fieldType) ? 'text-tertiary-red-500' : ''
    }`

  const errorClassValue = (fieldType: ErrorClassesTypes) =>
    `basis-3/4 ${
      errorClasses.includes(fieldType) ? 'text-tertiary-red-500' : ''
    }`

  return (
    <div
      className={`flex items-center justify-start py-3 ${
        value ? 'opacity-100' : 'opacity-60'
      } ${wrapperClass}`}
    >
      <div className={errorClassKey(errorClassType)}>{label}</div>
      <div className={`${valueClass} ${errorClassValue(errorClassType)}`}>
        {value ?? '-'}
      </div>
    </div>
  )
}

type PotentialMatchFieldViewProps = {
  label: string
  value?: string | null
  hasError?: boolean
  className?: string
}

function PotentialMatchFieldView({
  label,
  value,
  hasError = false,
  className = 'mb-5',
}: PotentialMatchFieldViewProps) {
  return (
    <div className={`flex items-center justify-start ${className}`}>
      <div className="basis-2/6 text-tertiary-grey-500">{label}:</div>
      <div className={`basis-4/6 ${hasError ? 'text-tertiary-red-500' : ''}`}>
        {value ?? ''}
      </div>
    </div>
  )
}

type Props = {
  applicantData: ApplicantResponse | undefined
  matchedView: boolean
  isLoading?: boolean
  matches?: BlacklistResponseType
  type: MatchesTypes
  manualBlocklistContentView?: Nullable<boolean>
}

// eslint-disable-next-line complexity
export function ApplicantPersonalInfo({
  applicantData,
  matchedView,
  isLoading = false,
  matches,
  type,
  manualBlocklistContentView = false,
}: Props) {
  const t = useI18n(APPLICANT_POTENTIAL_MATCHES_KEYS, {
    keys: applicantPotentialMatchesEn,
  })

  const [errorClasses, setErrorClasses] = useState<string[]>([])

  const potentialMatchField = useMemo(() => {
    const defaultData = {
      label: '',
      value: '',
    }
    if (!applicantData?.checkSummary.issues.BLOCKLIST_ATTRIBUTE)
      return defaultData
    const blocklistedAttribute =
      applicantData.checkSummary.issues.BLOCKLIST_ATTRIBUTE

    return {
      label: potentialMatchFieldLabel(blocklistedAttribute, t),
      value: getPotentialFieldValue(blocklistedAttribute, applicantData),
    }
  }, [applicantData, t])

  const getAddress = (addressType: string) => {
    const address = applicantData?.applicantDetails.addresses?.filter(
      address => address.addressType === addressType,
    )[0]?.longForm

    return address
  }

  useEffect(() => {
    if (matches) {
      setErrorClasses(getMatchedFields(matches.matches.personal))
    }
  }, [matches])

  return (
    <FrankieLoader className="!h-auto" loading={isLoading} size="sm">
      {manualBlocklistContentView ? (
        <div className="text-tertiary-grey-700 min-h-[450px]">
          <div className="p-5">
            <div className="text-lg mb-3 font-bold">
              {t(`${ApplicantMatchesTypes.POTENTIAL}.personalInfoTitle`)}
            </div>
            {potentialMatchField.label && (
              <PotentialMatchFieldView
                label={potentialMatchField.label}
                value={potentialMatchField.value}
                className="mb-8 mt-12"
                hasError
              />
            )}

            <PotentialMatchFieldView
              label={t('addedToBlocklist')}
              value={applicantData?.checkSummary.issues.BLOCKLIST_BY}
            />

            <PotentialMatchFieldView
              label={t('dateAddedToBlocklist')}
              value={
                applicantData?.checkSummary.issues.BLOCKLIST_AT
                  ? formatDate(
                      applicantData.checkSummary.issues.BLOCKLIST_AT,
                      DateFormatTypes.DateNumbers,
                    )
                  : ''
              }
            />

            <PotentialMatchFieldView
              label={t('personalInfo.frankieId')}
              value={applicantData?.applicantDetails.entityId}
            />
          </div>
        </div>
      ) : (
        <div
          className="text-tertiary-grey-700 min-h-[450px]"
          data-qa={appPotentialMatchesQa.wrapper}
        >
          {applicantData && (
            <div className="p-5">
              <div className="flex justify-between items-start">
                <div className="text-lg mb-3 font-bold">
                  {matchedView
                    ? t(`${type}.personalInfoTitle`)
                    : t('personalInfo.title')}
                </div>
                {matchedView && (
                  <div className="">
                    <div className="text-md mb-1 h-[20px]">
                      {t('personalInfo.matchStrength')}
                    </div>
                    <div className="flex gap-1 items-center h-[20px]">
                      <div className="text-md mr-1">
                        {matches?.matchStrength ?? 0}%
                      </div>
                      <div className="w-[110px] bg-tertiary-grey-300 h-[6px] rounded-2xl">
                        <div
                          className="bg-primary-500 h-[6px] rounded-2xl"
                          style={{
                            width: `${matches?.matchStrength ?? 0}%`,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <FieldView
                wrapperClass="!py-0"
                label={t('personalInfo.customerId')}
                value={applicantData.applicantDetails.customerReference}
                errorClasses={errorClasses}
                errorClassType={ErrorClassesTypes.EXTERNAL_ID}
              />

              <FieldView
                wrapperClass="text-xs mb-3 !py-0"
                label={t('personalInfo.frankieId')}
                value={applicantData.applicantDetails.entityId}
                errorClasses={errorClasses}
                valueClass="text-tertiary-grey-400"
              />

              <FrankieDivider />

              <FieldView
                label={t('personalInfo.name')}
                value={`${
                  applicantData.applicantDetails.name.givenName ?? ''
                } ${applicantData.applicantDetails.name.middleName ?? ''} ${
                  applicantData.applicantDetails.name.familyName ?? ''
                }`}
                errorClasses={errorClasses}
                errorClassType={ErrorClassesTypes.NAME}
              />

              <FrankieDivider />

              <FieldView
                label={t('personalInfo.dob')}
                value={
                  applicantData.applicantDetails.dateOfBirth
                    ? formatDate(
                        applicantData.applicantDetails.dateOfBirth,
                        DateFormatTypes.DateNumbers,
                      )
                    : ''
                }
                errorClasses={errorClasses}
                errorClassType={ErrorClassesTypes.DOB}
              />

              <FrankieDivider />

              <FieldView
                label={t('personalInfo.currentAddress')}
                value={getAddress('RESIDENTIAL1')}
                errorClasses={errorClasses}
                errorClassType={ErrorClassesTypes.CURRENT_ADDRESS}
              />

              <FrankieDivider />

              <FieldView
                label={t('personalInfo.previousAddress')}
                value={getAddress('RESIDENTIAL2')}
                errorClasses={errorClasses}
                errorClassType={ErrorClassesTypes.PREVIOUS_ADDRESS}
                valueClass="text-tertiary-grey-500"
              />

              <FrankieDivider />

              <FieldView
                label={t('personalInfo.mailingAddress')}
                value={getAddress('POSTAL')}
                errorClasses={errorClasses}
                errorClassType={ErrorClassesTypes.EMAIL_ADDRESS}
                valueClass="text-tertiary-grey-500"
              />

              <FrankieDivider />

              <FieldView
                label={t('personalInfo.phoneNumber')}
                value={applicantData.applicantDetails.phoneNumber.idNumber}
                errorClasses={errorClasses}
                errorClassType={ErrorClassesTypes.PHONE_NUMBER}
              />

              <FrankieDivider />

              <FieldView
                label={t('personalInfo.emailAddress')}
                value={applicantData.applicantDetails.email.idNumber}
                errorClasses={errorClasses}
                errorClassType={ErrorClassesTypes.EMAIL_ADDRESS}
              />

              <FrankieDivider />
            </div>
          )}
        </div>
      )}
    </FrankieLoader>
  )
}
