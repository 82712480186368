import { useMemo } from 'react'

import {
  AddressStatusEnum,
  AddressTypeEnum,
  IDocsToBeDisplayedType,
  useGetWorkflowEventsData,
  ILabelAndSources,
  WorkflowStepResultEnum,
  extractLongForm,
  useEntityDataQuery,
  useEntityLabelDataState,
  WorkflowStatusKeysTypes,
  getWorkflowStatusKey,
  ServiceProfileState,
  ProcessResultManualStatusEnumAML,
  ProcessResultManualStatusEnumKYC,
  ProcessResultSystemStatusEnum,
  WorkflowExecutionSummary,
  ProcessResultObject,
  IndividualsEntityResponse,
} from 'entities/entity'
import { useHasFeatureFlag } from 'entities/session'
import { useFilterWorkflow } from 'entities/workflow'

import { useI18n } from 'shared/i18n'
import { TrackingEventsTypes } from 'shared/tracking'

import { workflowEventsEn } from '../locale/workflow-events.en'
import {
  formatDate,
  getSourceMatch,
} from '../model/applicant-workflow-events.model'
import {
  EventName,
  FILTER_STEP_NAMES,
  getIconConfigAml,
  getIDVStepper,
  IconConfig,
  Stepper,
} from '../ui/entity-verification-helper/entity-verification-helper'
import { WORKFLOW_EVENTS_KEY } from '../workflow-events.key'

export type Args = {
  entityId: string
  docR1?: boolean
}

const resolveValueFromEntity = ({
  objectId,
  objectType,
  entity,
}: {
  objectId: string
  objectType: string
  entity: IndividualsEntityResponse
}): {
  value: string
  type: string
} | null => {
  function fullName() {
    const { displayName, givenName, middleName, familyName } =
      entity.individual?.name ?? {}

    return (
      displayName ??
      `${givenName ?? ''} ${middleName ?? ''} ${familyName ?? ''}`.trim()
    )
  }

  switch (objectType) {
    case 'NAME':
      if (objectId !== entity.individual?.name?.nameId) return null
      return {
        type: objectType,
        value: fullName(),
      }
    case 'DATE_OF_BIRTH':
      if (objectId !== entity.individual?.dateOfBirth?.dateOfBirthId)
        return null
      return {
        type: objectType,
        value: entity.individual.dateOfBirth.normalized ?? '',
      }
    case 'ADDRESS': {
      const address = entity.individual?.addresses?.find(
        address =>
          address.addressId === objectId &&
          address.type === AddressTypeEnum.RESIDENTIAL &&
          address.status === AddressStatusEnum.CURRENT,
      )
      if (!address) return null
      return {
        type: objectType,
        value: extractLongForm(address),
      }
    }
    default:
      return null
  }
}

const getLatestProcessResultsByObjectType = (
  processResults: ProcessResultObject[] = [],
) => {
  const groupedByObjectType = processResults.reduce((acc, curr) => {
    const existingItem = curr.objectType ? acc[curr.objectType] : undefined
    if (
      !existingItem ||
      new Date(curr.createdAt || 0) > new Date(existingItem.createdAt || 0)
    ) {
      acc[curr.objectType as string] = curr
    }
    return acc
  }, {} as Record<string, ProcessResultObject>)

  return Object.values(groupedByObjectType)
}

const getKycDocuments = (processResults: ProcessResultObject[] = []) =>
  processResults.filter(pr => pr.objectType === 'DOCUMENT')

const mapKycDocuments = (
  documentWithLabelData: IDocsToBeDisplayedType[] = [],
  processResults: ProcessResultObject[] = [],
  workflowSummaries: WorkflowExecutionSummary[] = [],
): IDocsToBeDisplayedType[] =>
  processResults
    .map(pr => {
      const doc = documentWithLabelData.find(
        doc => doc.documentId === pr.objectId,
      )
      if (!doc) return null
      return {
        ...doc,
        label: [
          {
            ...doc.label[0],
            sources: getSourceMatch({
              objectType: 'DOCUMENT',
              idType: doc.type,
              workflowSummaries,
            }),
          },
          ...doc.label.slice(1),
        ],
      } as IDocsToBeDisplayedType
    })
    .filter((doc): doc is IDocsToBeDisplayedType => doc !== null)

export const useGetWorkFlowDataWithSources = ({
  entityId,
  docR1 = false,
}: Args) => {
  const { data: workflow, isLoading } = useGetWorkflowEventsData({ entityId })
  const data = useFilterWorkflow(workflow)
  const { data: entity, isFetching } = useEntityDataQuery(entityId, 'base64')
  const {
    documentWithLabelData: documentLabelDataF2R2,
    documentWithLabelDataR1,
  } = useEntityLabelDataState({ entityId })
  const t = useI18n(WORKFLOW_EVENTS_KEY, { keys: workflowEventsEn })

  // some places need docs to have R1 data despite being in F2R2
  const documentWithLabelData = docR1
    ? documentWithLabelDataR1
    : documentLabelDataF2R2

  if (!workflow || !entity) {
    return {
      data: undefined,
      isLoading: isLoading || isFetching,
    }
  }

  const workflowEventResult = data.workflowSummaries.at(0)

  const isArchived =
    entity.serviceProfiles?.at(0)?.state === ServiceProfileState.ARCHIVED

  const kycStep =
    workflowEventResult?.workflowResultData?.workflowStepResults?.find(
      i => i.stepName === 'KYC',
    )

  const isManuallyVerified =
    kycStep?.result === WorkflowStepResultEnum.MATCH &&
    kycStep.processResults?.every(
      i => i.manualStatus === ProcessResultManualStatusEnumKYC.PASS,
    )

  const isNotUnchecked =
    workflowEventResult?.status &&
    getWorkflowStatusKey(workflowEventResult) !==
      WorkflowStatusKeysTypes.UNCHECKED

  const values = getLatestProcessResultsByObjectType(
    kycStep?.processResults,
  ).reduce((acc, pr) => {
    const value = resolveValueFromEntity({
      objectId: pr.objectId ?? '',
      objectType: pr.objectType ?? '',
      entity,
    })
    if (!value) return acc
    acc[value.type] = value.value
    return acc
  }, {} as Record<string, string>)

  const profileInfo: ILabelAndSources[] = [
    ...(values.NAME
      ? [
          {
            label: t('personalInfo.name'),
            value: values.NAME,
            sources: getSourceMatch({
              objectType: 'NAME',
              workflowSummaries: data.workflowSummaries,
            }),
          },
        ]
      : []),
    ...(values.DATE_OF_BIRTH
      ? [
          {
            label: t('personalInfo.dob'),
            value: formatDate({
              dateString: values.DATE_OF_BIRTH,
            }),
            sources: getSourceMatch({
              objectType: 'DATE_OF_BIRTH',
              workflowSummaries: data.workflowSummaries,
            }),
          },
        ]
      : []),
    ...(values.ADDRESS
      ? [
          {
            label: t('personalInfo.address'),
            value: values.ADDRESS,
            sources: getSourceMatch({
              objectType: 'ADDRESS',
              workflowSummaries: data.workflowSummaries,
            }),
          },
        ]
      : []),
  ]

  const kycDocumentProcessResults = getKycDocuments(kycStep?.processResults)
  const docsToBeDisplayed: IDocsToBeDisplayedType[] = mapKycDocuments(
    documentWithLabelData,
    kycDocumentProcessResults,
    data.workflowSummaries,
  )

  return {
    docsToBeDisplayed,
    isLoading,
    profileInfo,
    isNotUnchecked,
    isManuallyVerified,
    isArchived,
  }
}

export const useOrderWithFailedAndPassed = ({ entityId }: Args) => {
  const { data: initWorkflow } = useGetWorkflowEventsData({ entityId })
  const workflow = useFilterWorkflow(initWorkflow)
  const t = useI18n(WORKFLOW_EVENTS_KEY, { keys: workflowEventsEn })

  const { hasIdvCheck } = useHasFeatureFlag({
    hasIdvCheck: ['idvChecks'],
  })

  const lastWorkflow = useMemo(
    () => workflow.workflowSummaries.at(0) ?? ({} as WorkflowExecutionSummary),
    [workflow],
  )
  const order = lastWorkflow.steps?.order
  const failed = lastWorkflow.steps?.failed
  const passed = lastWorkflow.steps?.passed

  const filterStepResults = useMemo(
    () =>
      lastWorkflow.workflowResultData?.workflowStepResults?.filter(
        result => !FILTER_STEP_NAMES.includes(result.stepName as string),
      ),
    [lastWorkflow],
  )

  const filterEvents = useMemo(
    () => filterStepResults?.map(result => result.stepName as EventName),
    [filterStepResults],
  )

  const eventStepper = useMemo(() => {
    if (!filterStepResults) return []

    const stepperList: Stepper[] = []
    filterStepResults.forEach(({ stepName, processResults }) => {
      const eventName = stepName as EventName
      const trackingEventsMap = {
        AML: TrackingEventsTypes.WorkflowEventSummaryAMLClickIndividual,
        KYC: TrackingEventsTypes.WorkflowEventSummaryEntityVerificationClickIndividual,
        IDV: TrackingEventsTypes.WorkflowEventSummaryIDVClickIndividual,
        OCR: TrackingEventsTypes.WorkflowEventSummaryIDVBiometricCheck,
        IDV_DOCUMENT: TrackingEventsTypes.WorkflowEventSummaryIDVDocumentCheck,
        IDV_BIOMETRIC:
          TrackingEventsTypes.WorkflowEventSummaryIDVBiometricCheck,
      }
      const trackingEvent = trackingEventsMap[eventName]
      const workflowOrder = workflow.workflowSummaries[0]?.steps?.order || []

      switch (eventName) {
        case 'AML':
          stepperList.push({
            config: getIconConfigAml(
              lastWorkflow.workflowResultData?.workflowStepResults
                ?.find(step => step.stepName === 'AML')
                ?.processResults?.filter(
                  i =>
                    i.result === 'HIT' &&
                    i.systemStatus === ProcessResultSystemStatusEnum.VALID,
                )
                ?.map(result => result.manualStatus) as
                | (ProcessResultManualStatusEnumAML | undefined)[]
                | undefined,
            ),
            text: t(`entityStatusText.${eventName}`),
            eventName,
            track: trackingEvent,
            order: workflowOrder.indexOf(eventName),
          })
          return
        case 'IDV': {
          if (!hasIdvCheck) return
          const idvSteppers = getIDVStepper(processResults)

          idvSteppers.forEach(item => {
            stepperList.push({
              config: item.icon,
              text: t(`entityStatusText.${item.name}`),
              eventName: item.name as EventName,
              track: trackingEventsMap[item.name],
              order: workflowOrder.indexOf(eventName),
            })
          })
          return
        }
        default:
          if (failed?.find(item => item === eventName)) {
            stepperList.push({
              config: IconConfig({ type: WorkflowStepResultEnum.FAIL }),
              text: t(`entityStatusText.${eventName}`),
              eventName,
              track: trackingEvent,
              order: workflowOrder.indexOf(eventName),
            })
            return
          }
          if (passed?.find(item => item === eventName)) {
            stepperList.push({
              config: IconConfig({ type: WorkflowStepResultEnum.PASS }),
              text: t(`entityStatusText.${eventName}`),
              eventName,
              track: trackingEvent,
              order: workflowOrder.indexOf(eventName),
            })
            return
          }
          stepperList.push({
            config: IconConfig({ type: WorkflowStepResultEnum.UNCHECKED }),
            text: t(`entityStatusText.${eventName}`),
            eventName,
            track: trackingEvent,
            order: workflowOrder.indexOf(eventName),
          })
      }
    })

    return stepperList
  }, [
    filterStepResults,
    lastWorkflow.workflowResultData?.workflowStepResults,
    t,
    failed,
    passed,
    hasIdvCheck,
    workflow.workflowSummaries,
  ])

  return {
    order,
    failed,
    passed,
    filterEvents,
    eventStepper,
  }
}
