import { useQuery } from '@tanstack/react-query'

import { ApplicantId } from 'entities/applicant'

import { blacklistAPI } from '../../apis/blacklist.api'

export const GetBlocklistQueryKey = 'get-blacklist-query-key'

type Args = {
  applicantId: ApplicantId
  isEnabled: boolean
}

export const useGetBlacklist = ({ applicantId, isEnabled = true }: Args) =>
  useQuery({
    queryKey: [GetBlocklistQueryKey, applicantId],
    queryFn: async () => {
      const { data } = await blacklistAPI.getBlacklist(applicantId)
      return data
    },
    enabled: isEnabled,
    cacheTime: 0,
  })
