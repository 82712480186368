import { useGetWorkflowEventsData } from 'entities/entity'

import {
  failVariants,
  passVariants,
} from '../../model/individual-idv-check.model'
import { gallery, ocrCheckResults } from '../../model/mock-ocr-data.model'

type Args = {
  entityId: string
  idx: number
}

export const useIndividualOcrCheckState = ({ entityId, idx }: Args) => {
  const { data } = useGetWorkflowEventsData({ entityId })

  const workflow = data?.workflowSummaries?.at(0)

  const hadOcrChecks = workflow?.steps?.order?.includes('OCR')

  const step = workflow?.workflowResultData?.workflowStepResults?.filter(
    i => i.stepName === 'OCR',
  )[idx]

  const status = step?.result

  const isManualPass =
    step?.statusOverrideAt && passVariants.includes(step.result)

  const isManualFail =
    step?.statusOverrideAt && failVariants.includes(step.result)

  const requireResolve =
    isManualPass || (status && !passVariants.includes(status))

  return {
    hadOcrChecks,
    status,
    gallery,
    ocrCheckResults,
    isManualPass,
    isManualFail,
    requireResolve,
  }
}
