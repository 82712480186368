import React, { useEffect } from 'react'

import {
  DateRangePicker,
  LocalizationProvider,
  SingleInputDateRangeField,
} from '@mui/x-date-pickers-pro'
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns'
import classNames from 'classnames'
import { differenceInCalendarDays, endOfDay, startOfDay } from 'date-fns'
import { Controller, useForm } from 'react-hook-form'

import {
  FrankieAlert,
  FrankieButton,
  FrankieIcon,
  FrankieLoader,
} from 'frankify/src'

import { notification } from 'shared/notification'
import { useOverlay } from 'shared/overlay'

import { useAnalyticsDashboardTranslation } from '../../../locale/analytics-dashboard.en'
import { useDownloadAnalyticsReportQuery } from '../../../state/analytics-query/analytics.query'

export function TransactionReport() {
  const { t } = useAnalyticsDashboardTranslation()
  const { control, watch, handleSubmit, formState } = useForm<{
    date?: [Date, Date]
  }>({ mode: 'onBlur', reValidateMode: 'onBlur' })
  const date = watch('date')
  const [, closeOverlay] = useOverlay()
  const { isValid } = formState

  const { downloadReport, isLoading, isSuccess } =
    useDownloadAnalyticsReportQuery(
      date && [startOfDay(date[0]), endOfDay(date[1])],
    )

  const onSubmit = () => {
    downloadReport()
  }

  useEffect(() => {
    if (isSuccess) {
      notification.success(t('report.success'))
      closeOverlay()
    }
  }, [isSuccess])

  return (
    <FrankieLoader loading={isLoading}>
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
        <div className="text-xl text-tertiary-gray-800 font-bold">
          {t('report.transactionReport')}
        </div>
        <FrankieAlert
          type="warning"
          warningIconClassName="w-20px h-20px text-tertiary-yellow-700"
          className="text-tertiary-grey-700 text-sm leading-5  items-center"
          overrideIconName="mdiAlertCircleOutline"
        >
          {t('report.transactionAlert')}
        </FrankieAlert>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <div className="flex flex-col gap-2 [&_.MuiInputBase-input]:text-sm [&_.MuiInputBase-input]:py-3 ">
            <span className=" text-sm font-medium text-mono-black">
              {t('report.dateLabel')}
            </span>
            <Controller
              control={control}
              name="date"
              render={({ field, fieldState: { error } }) => {
                const diff =
                  field.value?.length === 2 &&
                  !Number.isNaN(differenceInCalendarDays(...field.value)) &&
                  Math.abs(differenceInCalendarDays(...field.value)) + 1

                const dateError = (diff || 0) > 31
                return (
                  <>
                    <DateRangePicker
                      {...field}
                      slotProps={{
                        field: {
                          className: classNames(
                            dateError &&
                              '!border-tertiary-red-500 border !bg-tertiary-red-50 [&_input]:!text-tertiary-red-700 [&_fieldset]:!border-tertiary-red-500',
                          ),
                        },
                      }}
                      slots={{ field: SingleInputDateRangeField }}
                    />
                    {!!diff &&
                      (dateError ? (
                        <div className="leading-tight flex gap-2 text-tertiary-red-600">
                          <FrankieIcon name="mdiInformationOutline" size="xs" />{' '}
                          {t('report.dateError')}
                        </div>
                      ) : (
                        <div className="leading-tight text-tertiary-grey-500">
                          {t('report.daysSelected', {
                            count: diff,
                          })}
                        </div>
                      ))}
                  </>
                )
              }}
              rules={{
                required: true,
                validate: {
                  length: value =>
                    value?.filter(Boolean).length === 2 || 'Required',
                  maxDays: value =>
                    Math.abs(
                      differenceInCalendarDays(
                        value?.[0] || 0,
                        value?.[1] || 0,
                      ),
                    ) <= 31 || 'Max 31 days allowed',
                },
              }}
            />
          </div>
        </LocalizationProvider>
        <div className="flex gap-4 justify-end">
          <FrankieButton onClick={closeOverlay} intent="darkOutline">
            {t('report.cancel')}
          </FrankieButton>
          <FrankieButton type="submit" disabled={!isValid}>
            {t('report.generateReport')}
          </FrankieButton>
        </div>
      </form>
    </FrankieLoader>
  )
}
