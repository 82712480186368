import { useMutation, useQueryClient } from '@tanstack/react-query'

import { MfaTypes } from 'entities/mfa'
import { SessionQueryKey } from 'entities/session'
import type { PageData } from 'entities/session'

import { bffClient } from 'shared/client'
import { getLocalStorageState, StorageKeyTypes } from 'shared/local-storage'

type MfaEnableResponse = MfaEnableSuccessResponse | MfaEnableErrorResponse

export type MfaEnableSuccessResponse = {
  message: string
  backupCodes: string[]
}

type MfaEnableErrorResponse = {
  error: 'Token is not valid' | 'Unknown user'
}

type MfaEnableRequestBody = {
  mfaType: MfaTypes
  token: string
}

export function isMfaEnableSuccessResponse(
  data: MfaEnableSuccessResponse | MfaEnableErrorResponse,
): data is MfaEnableSuccessResponse {
  return !!(data as MfaEnableSuccessResponse).backupCodes
}

export const useEnableMfaMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ mfaType, token }: MfaEnableRequestBody) =>
      bffClient.post<MfaEnableResponse, MfaEnableRequestBody>(
        '/mfa/:version/service/enable',
        {
          mfaType,
          token,
        },
      ),
    onSuccess: successfulResponse => {
      const successMessage = successfulResponse.data as MfaEnableSuccessResponse
      const mfaType = successMessage.message.split(
        ' ',
      )[0] as unknown as MfaTypes

      const [pageData, setPageData] = getLocalStorageState(
        StorageKeyTypes.PageData,
      )

      const pageDataTyped = pageData as PageData

      pageDataTyped.user.mfaType = mfaType

      queryClient.setQueryData(SessionQueryKey, pageDataTyped)

      setPageData(pageData)
    },
  })
}
