import React, { useMemo } from 'react'

import { NavLink, Outlet, useLocation } from 'react-router-dom'

import { FrankieIcon } from 'frankify/src'

import { ROUTING_KEY } from 'app/routing/locale/routing.en'

import {
  TPath,
  Vue2ReactMigrationFlagTypes,
  useGetPathValue,
  useHasFeatureFlag,
} from 'entities/session'

import { useI18n } from 'shared/i18n'
import { modifyRecord } from 'shared/typescript'

import { routingVueMigratedQa } from '../../../qa/routing.qa'

type NewProfilePropsType = {
  paths: {
    individualPath: TPath
    businessProfile: TPath
  }
}

export function LayoutNewProfileF1({
  paths,
}: NewProfilePropsType): JSX.Element {
  const t = useI18n(ROUTING_KEY)
  const { pathname } = useLocation()

  const { getPathValue } = useGetPathValue()

  const { hasVerticalNavBar, hasIndividualInReact } = useHasFeatureFlag({
    hasVerticalNavBar: 'verticalNavBar',
    hasIndividualInReact: [
      'vue2ReactMigration',
      Vue2ReactMigrationFlagTypes.ReactIndividualProfilePage,
    ],
  })

  /**
   * Final new profile paths using getPathValue
   */
  const newProfilePath = useMemo(
    () => modifyRecord(paths, getPathValue),
    [getPathValue, paths],
  )

  // Show in Vue layout if verticalNavBar is enabled and individual profile route is of ReactOne
  const showInReactLayout = hasVerticalNavBar && hasIndividualInReact

  return (
    <div
      className={showInReactLayout ? 'z-10 relative bg-mono-white' : ''}
      data-qa={routingVueMigratedQa.newProfile.profileWrapper}
    >
      {showInReactLayout && (
        <div className="max-w-[1440px] w-full text-xl mx-auto font-bold flex justify-items-start item-center px-11 pl-10 py-3 border-b border-tertiary-grey-300">
          <FrankieIcon
            name="mdiPlus"
            className="self-center text-2xl mr-2 text-tertiary-grey-300"
            size="lg"
          />
          <div className="self-center">{t('newProfile.title')}</div>

          <NavLink
            to={newProfilePath.individualPath}
            className={({ isActive }) =>
              `text-sm rounded-sm border border-solid ml-6 mr-3 ${
                isActive
                  ? 'bg-primary-50 border border-solid border-primary-800 text-primary-400'
                  : 'border-tertiary-grey-300 bg-transparent hover:bg-transparent  hover:border-primary-500 text-tertiary-grey-800'
              }`
            }
          >
            <div className="flex justify-center items-center px-3 mt-[3px] ">
              <FrankieIcon
                name="mdiAccountOutline"
                className={`self-center justify-self-center text-md ${
                  pathname === newProfilePath.individualPath
                    ? 'text-primary-400'
                    : 'text-tertiary-grey-200'
                }  mx-3`}
                size="md"
              />
              <div className="px-2 font-extrabold">
                {t('newProfile.individual')}
              </div>
            </div>
          </NavLink>
          <NavLink
            to={newProfilePath.businessProfile}
            className={({ isActive }) =>
              `text-sm rounded-sm border border-solid ml-2 mr-4 ${
                isActive
                  ? 'bg-primary-50 border border-solid border-primary-800 !text-primary-400'
                  : 'border-tertiary-grey-300 bg-transparent hover:bg-transparent  hover:border-primary-500 text-tertiary-grey-800'
              }`
            }
          >
            <div className="flex justify-center items-center px-3 mt-[3px] ">
              <FrankieIcon
                name="mdiOfficeBuildingOutline"
                className={`self-center justify-self-center text-md ${
                  pathname === newProfilePath.businessProfile
                    ? 'text-primary-400'
                    : 'text-tertiary-grey-200'
                }  mx-3`}
                size="md"
              />
              <div className="px-2 font-extrabold">
                {t('newProfile.business')}
              </div>
            </div>
          </NavLink>
        </div>
      )}
      <div
        className={`overflow-y-auto h-[calc(100vh-125px)] pb-6 pt-1 ${
          showInReactLayout ? '' : 'absolute top-[125px] z-10 w-full'
        }`}
      >
        <Outlet />
      </div>
    </div>
  )
}
