import { useMutation } from '@tanstack/react-query'

import { applicantApi } from '../../api/applicant.api'

type ApiParam = Parameters<typeof applicantApi.updateApplicantData>

type MutateProps = {
  entityId: ApiParam[0]
  payload: ApiParam[1]
  params?: ApiParam[2]
}

export const useApplicantUpdateDetailsMutation = () => {
  const mutation = useMutation({
    mutationFn: async ({ entityId, payload, params }: MutateProps) => {
      const { data } = await applicantApi.updateApplicantData(
        entityId,
        payload,
        params,
      )
      return data
    },
  })

  return mutation
}
