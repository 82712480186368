export const roleEn = {
  tableRowHeader: {
    role: 'ROLE',
    description: 'DESCRIPTION',
    createdBy: 'CREATED BY',
    createdDate: 'CREATED',
    actions: 'ACTIONS',
  },
  tableRow: {
    createdBy: {
      frankie: 'FrankieOne',
    },
    cta: {
      edit: 'Edit role',
      remove: 'Remove role',
    },
  },
}
