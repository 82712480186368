import { GridValueFormatterParams } from '@mui/x-data-grid-pro'

import { DateFormatTypes, formatDate, isValidDate } from 'shared/date-time'
import { I18nKeys } from 'shared/i18n'
import { Nullable } from 'shared/typescript'

import { iKybAmlScreeningEn } from '../locale/ikyb-aml-screening.en'

export type IKybAmlScreeningStatus =
  | 'true_positive_reject'
  | 'true_positive_accept'
  | 'true_positive_approve'
  | 'false_positive'
  | 'true_positive'
  | 'unknown'
  | 'possible_match'

export type IKybAmlScreeningStatusResult = Nullable<IKybAmlScreeningStatus> | ''

export type UpdateMatchPayload = {
  comment: string
  groupIds: string[]
  status: IKybAmlScreeningStatus
}

export type AmlScreeningStatusMapping = {
  type: 'non-final' | 'final'
  term?: Nullable<string>
  needsAttention: boolean
  needsAction: boolean
}

// From vue code - {mapMultipepMatchStatus}
// eslint-disable-next-line complexity
export function getAmlScreeningStatusMapping(
  status?: IKybAmlScreeningStatusResult,
): AmlScreeningStatusMapping {
  const result: AmlScreeningStatusMapping = {
    needsAttention: false,
    needsAction: false,
    term: status,
    type: 'non-final',
  }

  // unifying possible_match possible terms
  if ([null, undefined, '', 'possible_match'].includes(status))
    result.term = 'possible_match'

  // flag that it needs attention
  if (status) {
    if (['possible_match', 'unknown'].includes(status))
      result.needsAttention = true
    if (['true_positive'].includes(status)) result.needsAction = true
  }

  switch (status) {
    case 'true_positive_accept':
    case 'true_positive_approve':
      result.term = 'true_positive_accept'
      break
    case 'true_positive_reject':
      result.term = 'true_positive_reject'
      break
    default:
      result.term = status
      break
  }
  // defining as final state
  switch (status) {
    case 'true_positive_reject':
    case 'true_positive_accept':
    case 'false_positive':
      result.type = 'final'
      break
    default:
      result.type = 'non-final'
  }

  return result
}

export type PepsPepClass = {
  name: string
  source: string
  class: string
  items: { key: string; item: string }[]
}

export type AmlMatchSummary = {
  comment: Nullable<string>
  pawsAlert: string[]
  matchStrength?: number | null
  matchStatus: {
    by: Nullable<string>
    status: Nullable<IKybAmlScreeningStatus | ''>
  }
  countries: string[]
  dateOfBirth: Nullable<string>
  name: string
  entityType: string
  groupId: string
  details: {
    entityData: {
      name: string
      matchStatus: Nullable<IKybAmlScreeningStatus | ''>
      dateOfBirth: Nullable<string>
      aka: string[]
      countries: string[]
      associates: { relation: string; name: string }[]
      addresses: string[]
      imageURL?: Nullable<string>
      supportingDocUrls?: string[]
    }
    matchStatus: Nullable<IKybAmlScreeningStatus | ''>
    sanctions: Omit<PepsPepClass, 'class'>[]
    watchlists: Omit<PepsPepClass, 'class'>[]
    media: {
      mediaArticles: {
        date: string
        pdf_url: string
        snippet: string
        title: string
        url: string
      }[][]
      mediaMatches: {
        name: string
        source: string
        items: { key: string; item: string }[]
      }[]
    }
    pepsPep: PepsPepClass[]
    pepsPepClass1: PepsPepClass[]
    pepsPepClass2: PepsPepClass[]
    pepsPepClass3: PepsPepClass[]
    pepsPepClass4: PepsPepClass[]
    supportingDocuments: string[]
  }
}

export type AmlCheckResult = {
  name: Nullable<string>
  dateOfBirth: Nullable<string>
  countries: string[] | null
  searchFuzziness: number | null
  vendorId: Nullable<string>
  checkId: Nullable<string>
  searchDate: Nullable<string>
  matches: AmlMatchSummary[]
}

export type AmlScreeningMatchRecord = {
  id: string
  name: string
  dob: string
  countries: string
  issues: string[]
  matchStatus: IKybAmlScreeningStatus | ''
  matchScore: number
}

export type AmlScreeningMatchRecordProperties = keyof AmlScreeningMatchRecord

type AmlScreeningListing<TField extends keyof AmlMatchSummary['details']> = {
  type: string
  field: TField
  tKey: I18nKeys<(typeof iKybAmlScreeningEn)['listingFields']>
}[]

export const pepSactionsListing: AmlScreeningListing<
  | 'watchlists'
  | 'sanctions'
  | 'pepsPep'
  | 'pepsPepClass1'
  | 'pepsPepClass2'
  | 'pepsPepClass3'
  | 'pepsPepClass4'
> = [
  {
    type: 'sanction',
    field: 'sanctions',
    tKey: 'sanctions',
  },
  {
    type: 'watchlist',
    field: 'watchlists',
    tKey: 'watchlists',
  },
  {
    type: 'pep',
    field: 'pepsPep',
    tKey: 'pepsPep',
  },
  {
    type: 'pep-class-1',
    field: 'pepsPepClass1',
    tKey: 'pepsPepClass1',
  },
  {
    type: 'pep-class-2',
    field: 'pepsPepClass2',
    tKey: 'pepsPepClass2',
  },
  {
    type: 'pep-class-3',
    field: 'pepsPepClass3',
    tKey: 'pepsPepClass3',
  },
  {
    type: 'pep-class-4',
    field: 'pepsPepClass4',
    tKey: 'pepsPepClass4',
  },
]

export const amlScreeningDateFormat = (
  date?: Nullable<string>,
  noDateText = '',
  dateFormat: DateFormatTypes = DateFormatTypes.DateNumbers,
) => {
  const value =
    date && isValidDate(date) ? formatDate(date, dateFormat) : noDateText

  return value
}

export function fuzzinessPercentage(num?: Nullable<number>) {
  if (!num) return '-'

  if (num > 0 && num < 1) {
    return `${num * 100}%`
  }

  return `${num}%`
}

export function amlSearchResultDashValue({ value }: GridValueFormatterParams) {
  if (Array.isArray(value)) {
    return value.filter(Boolean).join(', ')
  }
  return (value || '-') as string
}
