import { useMutation } from '@tanstack/react-query'

import { notification } from 'shared/notification'

import { editBusinessApi } from '../../api/edit-business.api'
import { useEditBusinessTranslation } from '../../locale/edit-business.en'

type Args = {
  applicantId: string
}

export const useUpdateMCCMutation = ({ applicantId }: Args) => {
  const { t } = useEditBusinessTranslation()

  const mutation = useMutation({
    mutationFn: async (
      args: Parameters<typeof editBusinessApi.updateMCC>[1],
    ) => {
      const { data } = await editBusinessApi.updateMCC(applicantId, args)
      return data
    },
    onSuccess: () => {
      notification.success(t('success.riskScoreUpdated'))
    },
  })

  return mutation
}
