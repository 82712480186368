import { ENTITY_KEY } from '../entity.key'

export const entityAssignQa = {
  assignEntityWrapper: `${ENTITY_KEY}-assign-entity-wrapper`,
  assignPopoverWrapper: `${ENTITY_KEY}-assign-popover-wrapper`,
  assignCTA: `${ENTITY_KEY}-assign-cta`,
  searchField: `${ENTITY_KEY}-search-field`,
  searchItem: `${ENTITY_KEY}-search-item`,
  amlStatusSelect: `${ENTITY_KEY}-aml-status-select`,
  approvalStatusSelect: `${ENTITY_KEY}-approval-status-select`,
  amlCommentTextArea: `${ENTITY_KEY}-aml-comment-text-area`,
  resolveStatus: `${ENTITY_KEY}-resolve-status`,
} satisfies Record<string, `${typeof ENTITY_KEY}-${string}`>
