import { useMemo } from 'react'

import { FrankieSelectOptions } from 'frankify/src'

import { PermissionTypes } from 'entities/role'
import { IUser } from 'entities/user'

import { useSessionQuery } from '../session/session.query'

type UserList = FrankieSelectOptions<string>[]

function appendUser(
  userList: UserList,
  user: IUser,
  hasPermissions?: PermissionTypes[],
) {
  if (
    (hasPermissions || []).every(permission =>
      user.actions?.includes(permission),
    )
  )
    if (user.username && !userList.find(u => u.value === user.username)) {
      userList.push({
        label: user.realname ?? user.username,
        value: user.username,
      })
    }
}

export const useUserListState = (userPermissions?: PermissionTypes[]) => {
  const { data: pageData } = useSessionQuery()

  /**
   * @remark Need to confirm the implementation with the team.
   * The Vue code lacks a clear implementation for this (the applied condition does not seem logical)
   * Check for [getUserListForSelectedOrganisation] in vue code
   */
  const userList = useMemo(() => {
    const userList: UserList = []

    if (pageData) {
      // This is as per code in vue - not sure whats going on
      // organization is used for child user list, rest will use orgainsation
      const { organisation, organization } = pageData
      const { activeChildCustomerId } = pageData.user

      const orgUserList = organisation.users || []
      const orgParentUserList = organisation.parent?.users || []

      const hasParentUserList = orgParentUserList.length > 0
      const selectedUserList = hasParentUserList
        ? orgParentUserList
        : orgUserList

      selectedUserList.forEach(user => {
        if (user.actions?.includes(PermissionTypes.UserChildView)) {
          appendUser(userList, user, userPermissions)
        }
      })

      if (activeChildCustomerId) {
        const childUserList =
          organization.children?.find(
            org => org.customerChildId === activeChildCustomerId,
          )?.users ?? []

        childUserList.forEach(user =>
          appendUser(userList, user, userPermissions),
        )
      } else {
        orgUserList.forEach(user => appendUser(userList, user, userPermissions))
      }
    }

    const sortedUserList = userList.sort((a, b) =>
      a.label.localeCompare(b.label),
    )

    sortedUserList.unshift({ label: 'Unassigned', value: 'unassigned' })

    return sortedUserList
  }, [pageData])

  return userList
}
