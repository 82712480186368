import React, { useMemo } from 'react'

import { NavLink } from 'react-router-dom'
import { navigateToUrl } from 'single-spa'

import {
  FrankieButton,
  FrankieIcon,
  FrankieIconName,
  FrankieTooltip,
} from 'frankify/src'

import { useApplicantDocumentDownload } from 'features/applicant-supporting-documents'

import {
  ApplicantId,
  useApplicantDeviceFraudCheckQuery,
  useApplicantPaths,
} from 'entities/applicant'

import { useI18n } from 'shared/i18n'
import { useOverlay } from 'shared/overlay'

import { APPLICANT_GENERAL_INFORMATION_KEY } from '../../applicant-general-information.key'
import { applicantGeneralInformationEn } from '../../locale/applicant-general-information.en'
import { CheckCardInfo, OpenUrl } from '../../model/checks.model'
import {
  applicantCheckCardQa,
  applicantGeneralInformationVueMigratedQa,
} from '../../qa/applicant-general-information.qa'
import { useApplicantBusinessReport } from '../../state/applicant-business-state/applicant-business-state'
import { ApplicantActionModal } from '../applicant-action-menu/applicant-action-modal/applicant-action-modal'

type Props = CheckCardInfo & { className?: string; applicantId: ApplicantId }

type StateData = {
  icon: FrankieIconName
  text: string
  iconColorClass?: string
  textColorClass: string
  wrapperClass: string
}

type StateMap = Record<CheckCardInfo['state'], StateData>

// eslint-disable-next-line complexity
export function ApplicantCheckCard({
  title,
  description,
  className = '',
  actionName,
  actionType,
  isActionEnabled,
  redirectionType,
  tooltip,
  openUrl,
  openDocument,
  state,
  stateText,
  noteText,
  applicantId,
  sectionId,
}: Props) {
  const t = useI18n([APPLICANT_GENERAL_INFORMATION_KEY], {
    keys: applicantGeneralInformationEn,
  })

  const { getApplicantPath } = useApplicantPaths()

  const [createOverlay, closeOverlay] = useOverlay()

  const { downloadReport } = useApplicantDocumentDownload({})

  const { kycDeviceFraudReviewUrl } = useApplicantDeviceFraudCheckQuery({
    entityId: applicantId,
    isDisabled: openUrl !== 'kycDeviceFraudReviewUrl',
  })

  const { getReportDocument } = useApplicantBusinessReport({ applicantId })

  /**
   * If action is to open some eternal or new url
   */
  const openUrlLink = useMemo(() => {
    const urlMapping: Record<OpenUrl, string> = {
      kycDeviceFraudReviewUrl: kycDeviceFraudReviewUrl ?? '',
      deviceResult: 'personal-info-kyc?section=device',
      phoneResult: 'personal-info-kyc?section=phone',
      iplocationResult: 'personal-info-kyc?section=iplocation',
      emailResult: 'personal-info-kyc?section=email',
    }
    const res = openUrl ? urlMapping[openUrl] : false
    return res
  }, [kycDeviceFraudReviewUrl, openUrl])

  const stateData: StateData = useMemo(() => {
    const data: StateMap = {
      pass: {
        icon: 'mdiCheckCircle',
        text: 'Passed',
        textColorClass: 'text-tertiary-green-400',
        wrapperClass: 'border border-neutral-40',
      },
      manual_passed: {
        icon: 'mdiCheckCircle',
        text: 'Manual Passed',
        textColorClass: 'text-tertiary-green-400',
        wrapperClass: 'border border-neutral-40',
      },
      fail: {
        icon: 'mdiCloseCircle',
        text: 'Failed',
        textColorClass: 'text-tertiary-red-500',
        wrapperClass: 'bg-tertiary-red-50',
      },
      manual_failed: {
        icon: 'mdiCloseCircle',
        text: 'Manually Failed',
        textColorClass: 'text-tertiary-red-500',
        wrapperClass: 'bg-tertiary-red-50',
      },
      disabled: {
        icon: 'mdiMinusCircleOff',
        text: 'Not Required',
        textColorClass: 'text-mono-70',
        iconColorClass: 'text-neutral-60',
        wrapperClass: 'bg-mono-20',
      },
      na: {
        icon: 'mdiMinusCircle',
        text: 'Unchecked',
        textColorClass: 'text-mono-70',
        iconColorClass: 'text-neutral-60',
        wrapperClass: 'border border-neutral-40',
      },
      warn: {
        text: '',
        icon: 'mdiAlertCircle',
        textColorClass: 'text-tertiary-yellow-500',
        wrapperClass: 'bg-tertiary-amber-50',
      },
      pending: {
        text: '',
        icon: 'mdiCircleOutline',
        textColorClass: 'text-tertiary-grey-400',
        wrapperClass: 'border border-neutral-40',
      },
    }

    return data[state]
  }, [state])

  /**
   * Handle action like modal pop up
   */
  const handleAction = () => {
    switch (actionType) {
      case 'removeFromBlocklist':
        createOverlay(
          <ApplicantActionModal
            handleClose={closeOverlay}
            applicantId={applicantId}
            type="RemoveApplicantFromBlocklist"
          />,
          { className: 'p-0' },
        )
        break
      case 'redirectAndScroll':
        navigateToUrl(`personal-info-kyc?section=${title}`)
        break
      default:
        break
    }
  }

  /**
   * Handle document action
   */
  const handleDocument = () => {
    let documentId: string | undefined

    switch (openDocument) {
      case 'kybReport':
        documentId = getReportDocument('SINGLE-LEVEL-AML')?.documentId
        break
      case 'crReport':
        documentId = getReportDocument('CR')?.documentId
        break
      case 'uboReport':
        documentId = getReportDocument('UBO')?.documentId
        break
      default:
        break
    }

    if (documentId) {
      downloadReport(documentId)
    }
  }

  const actionClassName =
    'text-neutral-100 border border-solid border-neutral-100 font-semibold rounded-sm py-1 px-4 hover:text-primary-700 hover:border-primary-700 flex-grow-0'

  const testId = applicantGeneralInformationVueMigratedQa.checkCta(
    t(`checks.title.${title}`),
  )

  return (
    <div
      className={`flex items-center gap-6 p-4 rounded-sm ${className} ${stateData.wrapperClass}`}
      data-qa={applicantCheckCardQa.comp}
    >
      <FrankieIcon
        name={stateData.icon}
        className={stateData.iconColorClass ?? stateData.textColorClass}
        testId={{ icon: applicantCheckCardQa.frankieIcon }}
      />

      <div className="flex-grow">
        <div className="flex gap-2 text-md font-semibold text-mono-90 pb-1">
          <span> {t(`checks.title.${title}`)}</span>
          {tooltip && (
            <FrankieTooltip
              title={t(`checks.tooltip.${tooltip}`)}
              position="top"
            >
              <FrankieButton
                noStyles
                singleIcon={{
                  name: 'mdiHelpCircle',
                  size: 'xs',
                }}
              />
            </FrankieTooltip>
          )}
        </div>

        {description && (
          <div className="text-mono-80 leading-tight">
            {t(`checks.description.${description}`)}
          </div>
        )}
        {noteText && (
          <div className="italic text-xs font-bold text-tertiary-red-500">
            {t(`checks.note.${noteText}`)}
          </div>
        )}
      </div>
      {isActionEnabled ? (
        <>
          {redirectionType && (
            <NavLink
              to={`${getApplicantPath(redirectionType)}${
                sectionId ? `#${sectionId}` : ''
              }`}
              className={actionClassName}
              data-qa={testId}
            >
              {t(`checks.action.${actionName}`)}
            </NavLink>
          )}
          {openUrlLink && (
            <a
              className={`${actionClassName} flex items-center gap-1`}
              href={openUrlLink}
              target="_blank"
              rel="noreferrer"
              data-qa={testId}
            >
              <span>{t(`checks.action.${actionName}`)}</span>
              <FrankieIcon name="mdiOpenInNew" size="xs" />
            </a>
          )}
          {actionType && (
            <FrankieButton
              noStyles
              className={actionClassName}
              onClick={handleAction}
              testId={{ button: testId }}
            >
              {t(`checks.action.${actionName}`)}
            </FrankieButton>
          )}
          {openDocument && (
            <FrankieButton
              noStyles
              className={actionClassName}
              onClick={handleDocument}
            >
              {t(`checks.action.${actionName}`)}
            </FrankieButton>
          )}
        </>
      ) : (
        <div
          className={`${stateData.textColorClass} font-bold whitespace-nowrap`}
        >
          {stateText ? t(`checks.state.${stateText}`) : stateData.text}
        </div>
      )}
    </div>
  )
}
