export const applicantBlocklistedInfoEn = {
  titleAdd: 'Add to Blocklist',
  titleEdit: 'Edit Blocklisted Info',
  editPermissionError:
    'You do not have permission to edit this blocklisted attribute.',
  form: {
    blocklistType: 'Blocklist type',
    blocklistTypePlaceholder: 'Eg. Mailing address, phone number...',
    blocklistReason: 'Reason for blocklist?',
    blocklistReasonPlaceholder: 'Select reason',
    comment: 'Comment',
    commentPlaceholder: 'Add your comment here',
    phoneNumber: 'Phone Number',
    email: 'Email',
    emailAddress: 'Email Address',
    currentResidentialAddress: 'Current Residential Address',
    mailingAddress: 'Mailing Address',
    saveToBlocklist: 'Save to Blocklist',
  },
  modal: {
    save: 'Yes, update blocklisted info',
    cancel: 'Cancel',
    description:
      'Changing a blocklisted attribute will invalidate any current matches against this attribute and will run the blocklisted search against the new information.',
    title: 'Are you sure you want to continue?',
    confirmation: 'Confirmation',
  },
  success: {
    update: 'Blocklisted info has been updated.',
    creates: 'Blocklisted info has been created.',
  },
}
