import React, { useMemo } from 'react'

import { UseFormReturn } from 'react-hook-form'

import {
  APPLICANT_POTENTIAL_MATCHES_KEYS,
  applicantPotentialMatchesEn,
} from 'features/applicant-potential-matches'

import { SelectFormField } from 'shared/form'
import { useI18n } from 'shared/i18n'

import { APPLICANT_BLOCKLISTED_INFO_KEY } from '../../applicant-bllocklisted-info.key'
import { applicantBlocklistedInfoEn } from '../../locale/applicant-blocklisted-info.en'
import {
  BlacklistedFormInputs,
  BlocklistedInputTypes,
  FormTypes,
  getBlocklistAttributeOptions,
} from '../../model/applicant-blocklisted-form.model'
import { blockListTypeFormQa } from '../../qa/applicant-blocklisted-info-qa'

type Props = {
  form: UseFormReturn<BlacklistedFormInputs>
  type: FormTypes
}

export function BlocklistTypeForm({ form, type }: Props) {
  const { control } = form

  const t = useI18n([APPLICANT_BLOCKLISTED_INFO_KEY], {
    keys: applicantBlocklistedInfoEn,
  })

  const tPotentialMatch = useI18n(APPLICANT_POTENTIAL_MATCHES_KEYS, {
    keys: applicantPotentialMatchesEn,
  })

  const blocklistAttributeOptions = useMemo(
    () => getBlocklistAttributeOptions(tPotentialMatch),
    [tPotentialMatch],
  )
  return (
    <div
      data-qa={blockListTypeFormQa.container}
      className={`pt-8 pb-6 max-w-[486px] ${
        type === FormTypes.Edit ? 'cursor-not-allowed' : ''
      }`}
    >
      <SelectFormField
        testId={{ input: blockListTypeFormQa.select }}
        className="mb-2 basis-1/4"
        label={t('form.blocklistType')}
        control={control}
        name={BlocklistedInputTypes.BlocklistType}
        options={blocklistAttributeOptions}
        rules={{ required: true }}
        placeholder={t('form.blocklistTypePlaceholder')}
        disabled={type === FormTypes.Edit}
      />
    </div>
  )
}
