import { useI18n } from 'shared/i18n'

import { ANALYTICS_DASHBOARD_KEY } from '../analytics-dashboard.key'

export const analyticsDashboardEn = {
  title: 'Analytics (Standard dashboard)',
  description:
    'This dashboard provides two key metrics: <0>Pass Rate Straight Through Processing (STP),</0> which shows the percentage of unique active customers (by entity ID), and Pass Rate Including Manual, which includes both automatic and manual passes by operators. Data for both metrics refreshes hourly',
  noAccess: 'No access',
  initializing: 'Initialising...',
  report: {
    generateReport: 'Generate report',
    transactionReport: 'Transaction record (CSV)',
    max31Days: 'Maximum 31 days',
    customerReport: 'Customer records (CSV)',
    transactionRecord: 'Transaction record',
    transactionAlert:
      'The transaction record can only be generated for a maximum of 31 days',
    cancel: 'Cancel',
    customerRecords: 'All customer records',
    customerAlert:
      'Once the report is generated it will be emailed to the email address associated with your FrankieOne Portal account.',
    sensitiveInfo: 'The file will contain sensitive personal information.',
    enterPassword:
      'Please enter your password which will be used to decrypt the file.',
    passwordLabel: 'Enter Password',
    dateError: 'Selected date must not exceed 31 days',
    requestReport: 'Request report',
    dateLabel: 'Select the start and end date for your report',
    daysSelected_one: '{{count}} day selected',
    daysSelected_other: '{{count}} days selected',
    daysSelected: '1 days selected',
    success: 'Report successfully generated',
    issue: 'Issue with the dashboard',
    issueDescription:
      'There seems to be an issue with the dashboard. \nPlease try again in a few minutes.',
  },
}

export const useAnalyticsDashboardTranslation = () => {
  const t = useI18n([ANALYTICS_DASHBOARD_KEY], { keys: analyticsDashboardEn })

  return { t }
}
