import React, { useEffect, useMemo, useState } from 'react'

import { Trans } from 'react-i18next'

import { FrankieButton, FrankieTabs } from 'frankify/src'

import { ApplicantId } from 'entities/applicant'

import { useCircularIterator } from 'shared/hooks'
import { useI18n } from 'shared/i18n'

import {
  IKybAmlResultTabs,
  IKybAmlResultTabsMappingTypes,
} from './ikyb-aml-screening-result-tabs/ikyb-aml-screening-result-tabs'
import { IKYB_AML_SCREENING_KEY } from '../../ikyb-aml-screening.key'
import { iKybAmlScreeningEn } from '../../locale/ikyb-aml-screening.en'
import { iKybAmlResultQa } from '../../qa/ikyb-aml-screening.qa'
import { useIKybAmlScreeningQuery } from '../../state/ikyb-aml-screening-query/ikyb-aml-screening.query'
import { MatchStatusLabel } from '../ikyb-aml-screening-data-grid-helper/ikyb-aml-screening-data-grid-helper'
import { IKybAmlInfoHeader } from '../ikyb-aml-screening-info-listing/ikyb-aml-screening-info-listing'
import { IKybAmlMatchStatusChange } from '../ikyb-aml-screening-match-status-change/ikyb-aml-screening-match-status-change'

type Props = {
  applicantId: ApplicantId
  selectedGroupId?: string
}

export function IKybAmlScreeningResult({
  applicantId,
  selectedGroupId,
}: Props) {
  const t = useI18n(IKYB_AML_SCREENING_KEY, {
    keys: iKybAmlScreeningEn,
  })

  const { data } = useIKybAmlScreeningQuery({ applicantId })
  const noOfMatches = data?.matches.length ?? 0

  const {
    currIdx: matchIdx,
    getNextIdx,
    getPrevIdx,
    setCurrIdx,
  } = useCircularIterator({
    noOfItems: noOfMatches,
  })

  const { matchStatus, groupId } = useMemo(() => {
    const currentMatchData = data?.matches[matchIdx]
    return {
      matchStatus: currentMatchData?.matchStatus.status,
      groupId: currentMatchData?.groupId,
    }
  }, [data?.matches, matchIdx])

  const [selectedTab, setSelectedTab] =
    useState<IKybAmlResultTabsMappingTypes>('keyData')

  const tabs = useMemo(
    () =>
      [
        { label: t('tabs.keyData'), value: 'keyData' },
        { label: t('tabs.adverseMedia'), value: 'adverseMedia' },
        { label: t('tabs.dataSources'), value: 'dataSource' },
      ] as {
        label: string
        value: IKybAmlResultTabsMappingTypes
      }[],
    [t],
  )

  useEffect(() => {
    const currIndex = data?.matches.findIndex(
      match => match.groupId === selectedGroupId,
    )
    setCurrIdx(currIndex && currIndex !== -1 ? currIndex : 0)
  }, [data?.matches, selectedGroupId, setCurrIdx])

  return (
    <div className="flex flex-col bg-mono-white overflow-y-auto rounded-2 w-[90vw] h-[90vh] pr-4">
      <div className="flex-grow-0 py-2 flex items-center">
        <h1 className="font-bold text-2xl text-tertiary-grey-900 flex-grow">
          {t('amlResult')}
        </h1>
        {noOfMatches > 1 && (
          <>
            <div
              data-qa={iKybAmlResultQa.infoHeaderSearchDetails}
              className="flex items-center flex-grow-0 mr-2"
            >
              <span className="text-secondary-900 font-medium">
                {t('result')}
              </span>

              <Trans
                i18nKey={`${IKYB_AML_SCREENING_KEY}:outOfTotal`}
                tOptions={{
                  current: matchIdx + 1,
                  total: noOfMatches,
                }}
                components={[
                  <strong className="font-bold text-tertiary-grey-700 px-1">
                    0
                  </strong>,
                ]}
              />
            </div>

            <div className="flex items-center flex-grow-0">
              <FrankieButton
                startIcon={{
                  name: 'mdiChevronLeft',
                  className: 'mr-2',
                }}
                intent="subtle"
                disabled={matchIdx === 0}
                onClick={getPrevIdx}
                testId={{ button: iKybAmlResultQa.prevBtn }}
              >
                {t('action.prev')}
              </FrankieButton>
              <FrankieButton
                endIcon={{
                  name: 'mdiChevronRight',
                  className: 'ml-2',
                }}
                intent="subtle"
                disabled={matchIdx === noOfMatches - 1}
                onClick={getNextIdx}
                testId={{ button: iKybAmlResultQa.nextBtn }}
              >
                {t('action.next')}
              </FrankieButton>
            </div>
          </>
        )}
      </div>

      <IKybAmlInfoHeader
        testId={{ container: iKybAmlResultQa.infoHeader }}
        matchIdx={matchIdx}
        applicantId={applicantId}
      />

      <div className="flex-grow-0 flex items-center gap-4 my-4 flex">
        <span className="text-tertiary-grey-700">{t('field.matchStatus')}</span>

        <MatchStatusLabel className="flex-grow" matchStatus={matchStatus} />

        <IKybAmlMatchStatusChange
          applicantId={applicantId}
          groupIds={groupId ? [groupId] : []}
        />
      </div>

      <div className="flex-grow">
        <FrankieTabs
          className="sticky top-0 bg-mono-white z-10"
          selectedItemClassName=""
          options={tabs}
          onTabSelect={setSelectedTab}
          selectedTab={selectedTab}
        />

        <IKybAmlResultTabs
          type={selectedTab}
          applicantId={applicantId}
          matchIdx={matchIdx}
        />
      </div>
    </div>
  )
}
