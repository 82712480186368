import { cva } from 'cva'

export const BannerStyle = cva(['rounded-md p-4 mb-3'], {
  variants: {
    theme: {
      danger: ['text-tertiary-grey-800', 'bg-tertiary-red-100'],
      info: ['text-tertiary-grey-800', 'bg-primary-50'],
      warning: ['text-tertiary-grey-800', 'bg-tertiary-yellow-50'],
    },
    btnTheme: {
      danger: ['h-[34px]', '!bg-tertiary-red-800', 'text-mono-white', 'mt-3'],
      info: ['h-[34px]', '!bg-primary-900', 'text-mono-white', 'mt-3'],
      warning: [
        'h-[34px]',
        '!bg-tertiary-yellow-300',
        'text-tertiary-grey-800',
        'mt-3',
      ],
    },
    btnThemeSecondary: {
      danger: [
        'h-[34px]',
        '!border-tertiary-red-800',
        'hover:!bg-tertiary-red-100',
        'text-tertiary-grey-800',
        'mt-3',
      ],
      info: [
        'h-[34px]',
        '!border-primary-900',
        'hover:!bg-tertiary-primary-100',
        'text-tertiary-grey-800',
        'mt-3',
      ],
      warning: [
        'h-[34px]',
        '!border-tertiary-yellow-300',
        'hover:!bg-tertiary-yellow-100',
        'text-tertiary-grey-800',
        'mt-3',
      ],
    },
    iconTheme: {
      danger: ['!text-tertiary-red-800', '!p-0'],
      info: ['!text-primary-900', '!p-0'],
      warning: ['!text-tertiary-yellow-300 rotate-180', '!p-0 !mb-1.5'],
    },
  },
})
