import React, { useEffect, useState } from 'react'

import { FrankieLoader } from 'frankify/src'

import { MfaTypes } from 'entities/mfa'

import { useI18n } from 'shared/i18n'
import { Nullable } from 'shared/typescript'

import { MFA_KEY } from '../../mfa.key'
import {
  isMfaEnableSuccessResponse,
  useEnableMfaMutation,
} from '../../mutation/enable-mfa.mutation'
import { useGenerateTokenMutation } from '../../mutation/generate-token.mutation'
import { mfaQa } from '../../qa/mfa.qa'
import { EnableMfaAppCodeForm } from '../mfa-code-form'
import { MfaIsEnabled } from '../mfa-is-enabled/mfa-is-enabled'

type Props = {
  onClose: () => void
  onBackToMfaTypeSelect: () => void
}

export function EnableMfaApp({ onClose, onBackToMfaTypeSelect }: Props) {
  const t = useI18n([MFA_KEY])
  const [isMfaEnabled, setIsMfaEnabled] = useState<boolean>(false)
  const [qrCodeValue, setQrCodeValue] = useState<Nullable<string>>(null)
  const [mfaSecret, setMfaSecret] = useState<Nullable<string>>(null)
  const [backupCodes, setBackupCodes] = useState<Nullable<string[]>>(null)
  const { mutateAsync: enableMfa } = useEnableMfaMutation()
  const { mutateAsync: generateToken } = useGenerateTokenMutation()

  useEffect(() => {
    void (async function effect() {
      const {
        data: { url, secret },
      } = await generateToken(MfaTypes.App)
      setQrCodeValue(url)
      setMfaSecret(secret)
    })()
  }, [generateToken])

  const handleBackToMfaTypeSelect = () => {
    onBackToMfaTypeSelect()
  }

  const handleVerifyCode = async ({ code }: { code: string }) => {
    const { data } = await enableMfa({ token: code, mfaType: MfaTypes.App })
    if (isMfaEnableSuccessResponse(data)) {
      setBackupCodes(data.backupCodes)
    }
    setIsMfaEnabled(true)
  }

  const handleClose = () => {
    onClose()
  }

  if (isMfaEnabled && backupCodes) {
    return (
      <MfaIsEnabled
        className="min-h-[428px] flex flex-initial flex-col justify-between"
        onClose={handleClose}
      >
        <div className="mt-4 text-tertiary-grey-700">
          <div
            data-qa={mfaQa.mfaEnableApp_verifyCode_saveRecoveryCodes}
            className="text-lg font-semibold mb-2"
          >
            {t('enableMfaApp.h2.saveRecoveryCodes')}
          </div>
          <div className="text-sm mb-3">
            {t('enableMfaApp.description.recoveryCodes')}
          </div>
          <div
            data-qa={mfaQa.mfaEnableApp_verifyCode_recoveryCodes}
            className="flex flex-wrap gap-x-[56px] gap-y-2"
          >
            {backupCodes.map(key => (
              <span key={key} className="min-w-[80px]">
                <span className="mr-1 text-lg font-medium text-tertiary-grey-400">
                  •
                </span>
                <span
                  data-qa={mfaQa.mfaEnableApp_verifyCode_recoveryCode}
                  className="text-lg font-normal"
                >
                  {key}
                </span>
              </span>
            ))}
          </div>
        </div>
      </MfaIsEnabled>
    )
  }

  if (!isMfaEnabled && qrCodeValue && mfaSecret) {
    return (
      <EnableMfaAppCodeForm
        onBack={handleBackToMfaTypeSelect}
        onSubmit={handleVerifyCode}
        qrCodeValue={qrCodeValue}
        mfaSecret={mfaSecret}
      />
    )
  }

  // qrCodeValue or backupCodes are loading
  return (
    <FrankieLoader loading>
      <div className="min-h-[428px]" />
    </FrankieLoader>
  )
}
