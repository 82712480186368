import React from 'react'

import { NavLink } from 'react-router-dom'

import { FrankieButton } from 'frankify/src'

import { ApplicantId, useApplicantPaths } from 'entities/applicant'

import { useI18n } from 'shared/i18n'
import { useOverlay } from 'shared/overlay'

import { APPLICANT_GENERAL_INFORMATION_KEY } from '../../../applicant-general-information.key'
import { applicantGeneralInformationEn } from '../../../locale/applicant-general-information.en'
import { actionMappingQa } from '../../../qa/applicant-general-information.qa'
import { ApplicantActionModal } from '../../applicant-action-menu/applicant-action-modal/applicant-action-modal'

type Props = {
  applicantId: ApplicantId
  className?: string
}

export function ArchiveProfile({ applicantId, className = '' }: Props) {
  const t = useI18n([APPLICANT_GENERAL_INFORMATION_KEY], {
    keys: applicantGeneralInformationEn,
  })

  const [createOverlay, closeOverlay] = useOverlay()

  const handleClick = () => {
    createOverlay(
      <ApplicantActionModal
        handleClose={closeOverlay}
        applicantId={applicantId}
        type="ArchiveApplicant"
      />,
      { className: 'p-0' },
    )
  }

  return (
    <FrankieButton
      onClick={handleClick}
      noStyles
      className={`rounded-sm border border-solid p-2 ${className}`}
      testId={{ button: actionMappingQa.archiveBtn }}
    >
      {t('action.heading.archive')}
    </FrankieButton>
  )
}

export function ViewAndEditProfile({ applicantId, className = '' }: Props) {
  const t = useI18n([APPLICANT_GENERAL_INFORMATION_KEY], {
    keys: applicantGeneralInformationEn,
  })

  const { getApplicantPath } = useApplicantPaths()

  return (
    <NavLink
      className={`rounded-sm border border-solid border-mono-white p-2 text-mono-white hover:!text-mono-white ${className}`}
      to={getApplicantPath('blocklistedInfo')}
    >
      {t('action.viewAndEdit')}
    </NavLink>
  )
}

export function DeactivateProfile({ applicantId, className = '' }: Props) {
  const t = useI18n([APPLICANT_GENERAL_INFORMATION_KEY], {
    keys: applicantGeneralInformationEn,
  })

  const [createOverlay, closeOverlay] = useOverlay()

  const handleClick = () => {
    createOverlay(
      <ApplicantActionModal
        handleClose={closeOverlay}
        applicantId={applicantId}
        type="DeactivateApplicant"
      />,
      { className: 'p-0' },
    )
  }

  return (
    <FrankieButton
      noStyles
      className={`rounded-sm border border-solid border-neutral-100 text-neutral-100 px-2 py-1 ${className}`}
      onClick={handleClick}
      testId={{ button: actionMappingQa.deactivateBtn }}
    >
      {t('action.deactivateProfile')}
    </FrankieButton>
  )
}
