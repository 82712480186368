import React, { useMemo } from 'react'

import { FrankieLoader } from 'frankify/src'

import { useToggleApplicantData } from 'features/applicant-potential-matches/state/applicant-matches-query/applicant-matches.query'

import { ApplicantId, useApplicantDataQuery } from 'entities/applicant'

import { useI18n } from 'shared/i18n'

import { APPLICANT_POTENTIAL_MATCHES_KEYS } from '../../applicant-potential-matches.keys'
import { applicantPotentialMatchesEn } from '../../locale/applicant-potential-matches.en'
import {
  ApplicantMatchesTypes,
  MatchesTypes,
} from '../../models/applicant-matches.models'
import { appPotentialMatchesQa } from '../../qa/applicant-potential-matches.qa'
import { useGetDuplicates } from '../../state/applicant-duplicate-query/applicant-duplicate.query'
import { useGetBlacklist } from '../../state/blocklist-query/blocklist.query'
import { ApplicantPotentialMatches } from '../applicant-potential-matches/applicant-potential-matches'

type Props = {
  type: MatchesTypes
  applicantId: ApplicantId
}

// eslint-disable-next-line complexity
export function ApplicantMatch({ type, applicantId }: Props) {
  const t = useI18n(APPLICANT_POTENTIAL_MATCHES_KEYS, {
    keys: applicantPotentialMatchesEn,
  })

  const { data: applicantData, isFetching: applicantLoading } =
    useApplicantDataQuery({ applicantId })

  const { data: blocklistData, isFetching: blacklistApplicantLoading } =
    useGetBlacklist({
      applicantId,
      isEnabled: type === ApplicantMatchesTypes.BLOCKLIST,
    })

  const { data: duplicateData, isFetching: duplicatesApplicantLoading } =
    useGetDuplicates({
      applicantId,
      isEnabled: type === ApplicantMatchesTypes.DUPLICATES,
    })

  const potentialData = applicantData?.checkSummary.blocklistPotentialMatches

  const matches = blocklistData ?? duplicateData ?? potentialData

  const applicantIds = matches?.map(applicant => applicant.entityId) ?? []

  const isConfirmedEntity = useMemo(
    () => applicantData?.checkSummary.issues.DUPLICATE_RESOLVED,
    [applicantData?.checkSummary.issues.DUPLICATE_RESOLVED],
  )

  const {
    data: matchesData,
    isFetching,
    idx: currentProfileIdx,
    getPreviousApplicantData,
    getNextApplicantData,
  } = useToggleApplicantData({ applicantIds })

  const isLoadingMatches =
    applicantLoading ||
    blacklistApplicantLoading ||
    duplicatesApplicantLoading ||
    (applicantIds.length ? isFetching : false)

  const emptyMessage = useMemo(() => {
    if (ApplicantMatchesTypes.POTENTIAL === type && !potentialData?.length) {
      return t('noPotentialMatches')
    }
    if (ApplicantMatchesTypes.BLOCKLIST === type && !blocklistData?.length) {
      return t('noBlocklistMatches')
    }
    if (ApplicantMatchesTypes.DUPLICATES === type && !duplicateData?.length) {
      return t('noDuplicateMatches')
    }

    return false
  }, [
    blocklistData?.length,
    duplicateData?.length,
    potentialData?.length,
    t,
    type,
  ])

  if (emptyMessage) {
    return (
      <div
        data-qa={appPotentialMatchesQa.emptyWrapper}
        className={`px-4 ${isLoadingMatches ? 'h-[150px] my-8' : ''}`}
      >
        <FrankieLoader loading={isLoadingMatches} size="sm">
          {emptyMessage}
        </FrankieLoader>
      </div>
    )
  }

  return (
    <div
      className="max-w-[1425px] text-tertiary-grey-700 flex justify-center items-stretch items-baseline mx-auto"
      data-qa={appPotentialMatchesQa.wrapper}
    >
      <div className="basis-[45%] mr-4">
        <ApplicantPotentialMatches
          type={type}
          applicantId={applicantId}
          applicantData={applicantData}
          comparisonData={matchesData}
          isLoading={applicantLoading}
          currentProfileIdx={currentProfileIdx}
          matches={matches}
        />
      </div>
      <div className="basis-[45%] ml-4">
        <ApplicantPotentialMatches
          type={type}
          applicantId={applicantId}
          applicantData={matchesData}
          comparisonData={applicantData}
          currentProfileIdx={currentProfileIdx}
          matches={matches}
          isLoading={isFetching}
          isMatchedView
          getPreviousApplicantData={getPreviousApplicantData}
          getNextApplicantData={getNextApplicantData}
          isConfirmedEntity={
            isConfirmedEntity && type !== ApplicantMatchesTypes.POTENTIAL
          }
        />
      </div>
    </div>
  )
}
