export const PASSWORD_RESET_SENT_PAGE_LOCALE = 'passwordResetSentPage'

export const passwordResetSentPageEn = {
  container: {
    headingSent: 'Password link sent',
    subheading: {
      success: 'Please check your email',
    },
    description: {
      success:
        'If the email address you provided is correct and matches our records, you will receive an email with a link to reset your password. This link expires in 1 hour.',
      checkSpam:
        'If you don’t receive an email from us within a few minutes, please check your spam folder.',
    },
  },
}
