import { FrankiePieChartStatsColorTypes } from 'frankify/src'

import { dashboardEn } from '../locale/dashboard.en'
import {
  AnalyticStatsConfig,
  IssuesType,
  PassRate,
  RiskLevel,
} from '../model/dashboard.model'

type DashboardEn = typeof dashboardEn

/**
 * Passed rate Config, Only the mentioned keys value will be used in for analytics calculation
 */
export const passRateConfig: AnalyticStatsConfig<
  PassRate,
  keyof DashboardEn['passRate']
> = [
  {
    tKey: 'passed',
    color: FrankiePieChartStatsColorTypes.Green500,
    key: 'passed',
  },
  {
    tKey: 'manuallyPassed',
    color: FrankiePieChartStatsColorTypes.Green300,
    key: 'manually_passed',
  },
  {
    tKey: 'needsAttention',
    color: FrankiePieChartStatsColorTypes.Yellow500,
    key: 'needs_attention',
  },
  {
    tKey: 'failed',
    color: FrankiePieChartStatsColorTypes.Red500,
    key: 'failed',
  },
  {
    tKey: 'manuallyFailed',
    color: FrankiePieChartStatsColorTypes.Red300,
    key: 'manually_failed',
  },
  {
    tKey: 'pending',
    color: FrankiePieChartStatsColorTypes.Yellow300,
    key: 'pending',
  },
]

/**
 * Risk Level Config, Only the mentioned keys will be used in for Risk Level analytics calculation
 */
export const riskLevelConfig: AnalyticStatsConfig<
  RiskLevel,
  keyof DashboardEn['riskLevel']
> = [
  {
    tKey: 'low',
    color: FrankiePieChartStatsColorTypes.Green500,
    key: 'low',
  },
  {
    tKey: 'medium',
    color: FrankiePieChartStatsColorTypes.Yellow300,
    key: 'medium',
  },
  {
    tKey: 'high',
    color: FrankiePieChartStatsColorTypes.Red500,
    key: 'high',
  },
  {
    tKey: 'unacceptable',
    color: FrankiePieChartStatsColorTypes.Navy700,
    key: 'unacceptable',
  },
  {
    tKey: 'unknown',
    color: FrankiePieChartStatsColorTypes.Green300,
    key: 'unknown',
  },
]

/**
 * Failure Causes Config, Only the mentioned keys will be used in for analytics calculation
 */
export const failureCauses: {
  tKey: keyof DashboardEn['failureCauses']
  data: AnalyticStatsConfig<IssuesType, keyof DashboardEn['issuesType']>
}[] = [
  {
    tKey: 'preCheck',
    data: [
      {
        tKey: 'biometrics',
        key: ['BIO', 'IDV_OCR', 'IDV_IDV', 'IDV_PHT'],
      },
      {
        tKey: 'duplicate',
        key: ['DUP'],
      },
      {
        tKey: 'blocklist',
        key: ['BL'],
      },
    ],
  },
  {
    tKey: 'postCheck',
    data: [
      {
        tKey: 'KYC',
        key: ['KYC', 'NO_MATCH'],
      },
      {
        tKey: 'PEP',
        key: ['AML_PEP'],
      },
      {
        tKey: 'watchlist',
        key: ['AML_WL'],
      },
      {
        tKey: 'sanction',
        key: ['AML_S'],
      },
      {
        tKey: 'adverseMedia',
        key: ['AML_MEDIA'],
      },
      {
        tKey: 'serviceFailure',
        key: ['SRV_FAIL'],
      },
    ],
  },
]
