import {
  ApplicantTabTypes,
  SardineCheckType,
  IApplicantFraudData,
} from 'entities/applicant'
import { ChecksTypes } from 'entities/check'

import { I18nKeys } from 'shared/i18n'
import {
  ExclusiveProperty,
  Nullable,
  SuggestionString,
} from 'shared/typescript'

import { checkEn } from '../locale/applicant-general-information.en'

type CheckEn<TKey extends keyof (typeof checkEn)['checks']> = I18nKeys<
  (typeof checkEn)['checks'][TKey]
>

export type KYBCheckType = 'kybReport' | 'uboReport' | 'crReport'

export type ActionType = 'removeFromBlocklist' | 'redirectAndScroll' // Add more when required
export type OpenUrl =
  | 'kycDeviceFraudReviewUrl'
  | 'deviceResult'
  | 'phoneResult'
  | 'emailResult'
  | 'iplocationResult'
// Add more when required
export type OpenDocument = KYBCheckType // Add more when required

export type CheckCardStates =
  | 'pass'
  | 'fail'
  | 'warn'
  | 'na'
  | 'disabled'
  | 'manual_passed'
  | 'manual_failed'
  | 'pending'

export type CheckCardInfo = {
  state: CheckCardStates
  title: CheckEn<'title'>
  description?: CheckEn<'description'>
  actionName: CheckEn<'action'>
  noteText?: CheckEn<'note'>
  isActionEnabled: boolean
  tooltip?: CheckEn<'tooltip'>
  stateText?: CheckEn<'state'>
  sectionId?: string
} & ExclusiveProperty<{
  openDocument: OpenDocument
  openUrl: OpenUrl
  actionType: ActionType
  redirectionType: ApplicantTabTypes
}>

export const testCheckType = (checkResult: { type: string }, regex: RegExp) =>
  regex.test(checkResult.type)

export type ChecksOptions = Record<
  | 'isDupBlResolved'
  | 'isManualBlacklisted'
  | 'hasPermissionToRemoveBlacklist'
  | 'fraudData'
  | 'hasConSardine',
  boolean | Nullable<IApplicantFraudData>
>

type CheckFunction = (
  checkState: SuggestionString<
    'fail' | 'warn' | 'manual_passed' | 'na' | 'unchecked' | 'pass'
  >,
  config?: ChecksOptions,
) => CheckCardInfo

export const getPEPMediaCheckDetail: CheckFunction = checkState => {
  const result: CheckCardInfo = {
    title: 'aml',
    description: 'noAmlMatched',
    state: 'pass',
    actionName: 'resolve',
    isActionEnabled: false,
    redirectionType: 'pepSanctions',
  }

  switch (checkState) {
    case 'fail':
      result.state = 'fail'
      result.isActionEnabled = true
      result.description = 'amlMatchedFound'
      break
    case 'warn':
      result.state = 'warn'
      result.isActionEnabled = true
      result.description = 'amlMatchedFound'
      break
    case 'manual_passed':
      result.description = 'amlMatchesManuallyResolved'
      result.state = 'manual_passed'
      break
    case 'na':
      result.description = 'notRequired'
      result.state = 'disabled'
      break
    case 'unchecked':
      result.description = 'notCompleted'
      result.state = 'na'
      break
    case 'pass':
    default:
      break
  }
  return result
}

export const getPEPCheckDetail: CheckFunction = checkState => {
  const result: CheckCardInfo = {
    title: 'aml',
    description: 'noAmlMatched',
    state: 'pass',
    actionName: 'resolve',
    isActionEnabled: false,
    redirectionType: 'pepSanctions',
  }

  switch (checkState) {
    case 'fail':
      result.description = 'amlMatchedFound'
      result.state = 'fail'
      result.isActionEnabled = true
      break
    case 'warn':
      result.description = 'amlMatchedFound'
      result.state = 'warn'
      result.isActionEnabled = true
      break
    case 'manual_passed':
      result.description = 'amlMatchesManuallyResolved'
      result.state = 'manual_passed'
      break
    case 'na':
      result.description = 'notRequired'
      result.state = 'disabled'
      break
    case 'unchecked':
      result.description = 'notCompleted'
      result.state = 'na'
      break
    case 'pass':
    default:
      break
  }
  return result
}

export const getChineseNationalIdDetail: CheckFunction = checkState => {
  const result: CheckCardInfo = {
    title: 'chineseNationalId',
    description: 'transliteratedNameMatch',
    state: 'pass',
    isActionEnabled: false,
    actionName: 'resolve',
    redirectionType: 'personalInfoKyc',
  }

  switch (checkState) {
    case 'fail':
      result.description = 'transliteratedNameNotMatch'
      result.state = 'fail'
      result.isActionEnabled = true
      break
    case 'manual_passed':
      result.description = 'identityVerified'
      result.state = 'manual_passed'
      break
    case 'na':
      result.description = 'notRequired'
      result.state = 'disabled'
      break
    case 'unchecked':
      result.description = 'notCompleted'
      result.state = 'na'
      break
    case 'pass':
    default:
      break
  }
  return result
}

// eslint-disable-next-line complexity
export const getBlocklistCheckDetail: CheckFunction = (checkState, options) => {
  const {
    isDupBlResolved,
    isManualBlacklisted,
    hasPermissionToRemoveBlacklist,
  } = { ...options }

  const result: CheckCardInfo = {
    title: 'blocklist',
    description: 'noBlocklistMatches',
    state: 'pass',
    isActionEnabled: false,
    actionName: 'removeFromBlocklist',
    ...(isManualBlacklisted
      ? { actionType: 'removeFromBlocklist' }
      : { redirectionType: 'blocklistMatches' }),
  }

  switch (checkState) {
    case 'fail': {
      if (isManualBlacklisted) {
        result.description = 'manuallyBlocklisted'
      } else {
        result.description = isDupBlResolved
          ? 'truePositiveBlocklistMatch'
          : 'potentialBlocklistMatch'
        result.actionName = isDupBlResolved ? 'view' : 'resolve'
      }

      result.state = 'fail'
      result.isActionEnabled = isManualBlacklisted
        ? !!hasPermissionToRemoveBlacklist
        : true

      break
    }
    case 'manual_passed':
      result.description = 'falsePositiveBlocklistCleared'
      result.state = 'manual_passed'
      break
    case 'manual_failed':
      result.description = 'manuallyConfirmedBlocklist'
      result.state = 'manual_failed'
      break
    case 'na':
      result.description = 'notRequired'
      result.state = 'disabled'
      break
    case 'unchecked':
      result.description = 'notCompleted'
      result.state = 'na'
      break
    case 'pass':
    default:
      break
  }
  return result
}

// eslint-disable-next-line complexity
export const getDuplicatesCheckDetail: CheckFunction = (
  checkState,
  options,
) => {
  const {
    isDupBlResolved,
    isManualBlacklisted,
    hasPermissionToRemoveBlacklist,
  } = { ...options }

  const result: CheckCardInfo = {
    title: 'duplicate',
    description: 'noDuplicateMatches',
    state: 'pass',
    isActionEnabled: false,
    actionName: 'removeFromBlocklist',
    ...(isManualBlacklisted
      ? { actionType: 'removeFromBlocklist' }
      : { redirectionType: 'duplicateMatches' }),
  }

  switch (checkState) {
    case 'fail': {
      if (isManualBlacklisted) {
        result.description = 'manuallyBlocklisted'
      } else {
        result.description = isDupBlResolved
          ? 'truePositiveDuplicateMatch'
          : 'potentialDuplicateMatch'
        result.actionName = isDupBlResolved ? 'view' : 'resolve'
      }

      result.state = 'fail'
      result.isActionEnabled = isManualBlacklisted
        ? !!hasPermissionToRemoveBlacklist
        : true
      break
    }
    case 'manual_passed':
      result.description = 'falsePositiveBlocklistCleared'
      result.state = 'manual_passed'
      break
    case 'manual_failed':
      result.description = 'manuallyConfirmedBlocklist'
      result.state = 'manual_failed'
      break
    case 'na':
      result.description = 'notRequired'
      result.state = 'disabled'
      break
    case 'unchecked':
      result.description = 'notCompleted'
      result.state = 'na'
      break
    case 'pass':
    default:
      break
  }
  return result
}

export const getVEVOCheckDetail: CheckFunction = checkState => {
  const result: CheckCardInfo = {
    title: 'vevo',
    description: 'visaVerified',
    state: 'pass',
    isActionEnabled: false,
    redirectionType: 'personalInfoKyc',
    actionName: 'resolve',
  }

  switch (checkState) {
    case 'fail':
      result.description = 'visaNotVerified'
      result.state = 'fail'
      result.isActionEnabled = true
      break
    case 'manual_passed':
      result.state = 'manual_passed'
      break
    case 'na':
      result.description = 'notRequired'
      result.state = 'disabled'
      break
    case 'unchecked':
      result.description = 'notCompleted'
      result.state = 'na'
      break
    case 'pass':
    default:
      break
  }
  return result
}

export const getFraudCheckDetail: CheckFunction = checkState => {
  const result: CheckCardInfo = {
    title: 'fraudCheck',
    description: 'noFraudFound',
    state: 'pass',
    isActionEnabled: false,
    redirectionType: 'fraudCheck',
    actionName: 'resolve',
  }

  switch (checkState) {
    case 'fail':
      result.description = 'potentialFraud'
      result.state = 'fail'
      result.isActionEnabled = true
      break
    case 'warn':
      result.description = 'potentialFraud'
      result.state = 'warn'
      result.isActionEnabled = true
      break
    case 'manual_passed':
      result.description = 'issuesResolved'
      result.state = 'manual_passed'
      break
    case 'na':
      result.description = 'notRequired'
      result.state = 'disabled'
      break
    case 'unchecked':
      result.description = 'notCompleted'
      result.state = 'na'
      break
    default:
      break
  }
  return result
}

export const getBiometryCheckDetail: CheckFunction = checkState => {
  const result: CheckCardInfo = {
    title: 'biometric',
    description: 'noIssues',
    state: 'pass',
    isActionEnabled: false,
    redirectionType: 'biometryOcr',
    actionName: 'resolve',
  }

  switch (checkState) {
    case 'fail':
      result.description = 'fraudulentDocs'
      result.state = 'fail'
      result.isActionEnabled = true
      break
    case 'warn':
      result.description = 'potentialIssues'
      result.state = 'warn'
      result.isActionEnabled = true
      break
    case 'manual_passed':
      result.description = 'issuesResolved'
      result.state = 'manual_passed'
      break
    case 'na':
      result.description = 'notRequired'
      result.state = 'disabled'
      break
    case 'unchecked':
      result.description = 'notCompleted'
      result.state = 'na'
      break
    case 'pass':
    default:
      break
  }
  return result
}

export const getManualCheckDetail: CheckFunction = checkState => {
  const result: CheckCardInfo = {
    title: 'manualKyc',
    description: 'identityManuallyVerified',
    state: 'pass',
    isActionEnabled: false,
    redirectionType: 'personalInfoKyc',
    actionName: 'resolve',
  }

  switch (checkState) {
    case 'fail':
      result.description = 'minDocReqNotMet'
      result.state = 'fail'
      result.isActionEnabled = true
      break
    case 'unchecked':
      result.description = 'notCompleted'
      result.state = 'na'
      break
    default:
      break
  }
  return result
}

export const getBlocklistSearchCheckDetail: CheckFunction = checkState => {
  const result: CheckCardInfo = {
    title: 'blocklistSearch',
    description: 'noBlocklistMatches',
    state: 'pass',
    isActionEnabled: false,
    redirectionType: 'potentialMatches',
    actionName: 'resolve',
  }

  switch (checkState) {
    case 'fail':
      result.description = 'potentialBlocklistMatch'
      result.state = 'fail'
      result.noteText = 'confirmBlocklist'
      result.isActionEnabled = true
      break
    case 'unchecked':
      result.description = 'notCompleted'
      result.state = 'na'
      break
    default:
      break
  }
  return result
}

export const getKycCheckDetail =
  (kycType: 'kyc' | 'kyc_1' | 'kyc_2'): CheckFunction =>
  checkState => {
    const result: CheckCardInfo = {
      title: kycType,
      description: 'identityVerified',
      state: 'pass',
      isActionEnabled: false,
      redirectionType: 'personalInfoKyc',
      actionName: 'resolve',
    }
    switch (checkState) {
      case 'fail':
        result.description = 'identityNotVerifiedCheckInfo'
        result.state = 'fail'
        result.isActionEnabled = true
        break
      case 'manual_passed':
        result.description = 'identityVerified'
        result.state = 'manual_passed'
        break
      case 'na':
        result.description = 'notRequired'
        result.state = 'disabled'
        break
      case 'unchecked':
        result.description = 'notCompleted'
        result.state = 'na'
        break
      case 'pass':
      default:
        break
    }
    return result
  }

export const getGOVCheckDetail: CheckFunction = checkState => {
  const result: CheckCardInfo = {
    title: 'govId',
    description: 'govIdVerified',
    state: 'pass',
    isActionEnabled: false,
    redirectionType: 'personalInfoKyc',
    actionName: 'resolve',
  }

  switch (checkState) {
    case 'fail':
      result.description = 'govIdNotVerified'
      result.state = 'fail'
      result.isActionEnabled = true
      break
    case 'manual_passed':
      result.state = 'manual_passed'
      break
    case 'na':
      result.description = 'notRequired'
      result.state = 'disabled'
      break
    case 'unchecked':
      result.description = 'notCompleted'
      result.state = 'na'
      break
    default:
      break
  }
  return result
}

export const getCheckSourceValidationCheckDetail: CheckFunction =
  checkState => {
    const result: CheckCardInfo = {
      title: 'checkSource',
      description: 'checkSourcePass',
      state: 'pass',
      isActionEnabled: false,
      redirectionType: 'personalInfoKyc',
      actionName: 'resolve',
    }

    switch (checkState) {
      case 'fail':
        result.description = 'reqCheckSourceNotFound'
        result.state = 'fail'
        break
      case 'manual_passed':
        result.description = 'checkSourceVerified'
        result.state = 'manual_passed'
        break
      case 'na':
        result.description = 'notRequired'
        result.state = 'disabled'
        break
      case 'unchecked':
        result.description = 'checksRemaining'
        result.state = 'na'
        break
      default:
        break
    }
    return result
  }

export const getDocumentUploadCheckDetail: CheckFunction = checkState => {
  const result: CheckCardInfo = {
    title: 'docUpload',
    description: 'noIssues',
    state: 'pass',
    isActionEnabled: false,
    redirectionType: 'supportingDocuments',
    actionName: 'review',
  }

  switch (checkState) {
    case 'fail':
      result.description = 'docApprovalReq'
      result.state = 'fail'
      result.isActionEnabled = true
      break
    case 'warn':
      result.description = 'docApprovalReq'
      result.state = 'warn'
      result.isActionEnabled = true
      break
    case 'manual_passed':
      result.description = 'issuesResolved'
      result.state = 'manual_passed'
      break
    case 'na':
      result.description = 'notRequired'
      result.state = 'disabled'
      break
    case 'unchecked':
      result.description = 'checksRemaining'
      result.state = 'na'
      break
    case 'pass':
    default:
      break
  }
  return result
}

export const getDeviceRiskCheckDetail: CheckFunction = checkState => {
  const result: CheckCardInfo = {
    title: 'device',
    description: 'deviceVerified',
    state: 'pass',
    isActionEnabled: false,
    redirectionType: 'characteristics',
    actionName: 'resolve',
  }

  switch (checkState) {
    case 'fail':
      result.description = 'highRiskDeviceFound'
      result.state = 'fail'
      break
    case 'na':
      result.description = 'notRequired'
      result.state = 'disabled'
      break
    case 'unchecked':
      result.description = 'checksRemaining'
      result.state = 'na'
      break
    case 'pass':
    default:
      break
  }
  return result
}

export const getKYCDeviceFraudDetail: CheckFunction = checkState => {
  const result: CheckCardInfo = {
    title: 'onboardingFraud',
    description: 'notCompleted',
    state: 'na',
    isActionEnabled: false,
    actionName: 'review',
    openUrl: 'kycDeviceFraudReviewUrl',
    tooltip: 'pleaseReviewOnboardingFraud',
  }

  switch (checkState) {
    case 'pass':
      result.description = 'lowRiskDeviceDetected'
      result.state = 'pass'
      result.isActionEnabled = true
      break
    case 'fail':
      result.description = 'highRiskEmailAndPhoneDeviceDetected'
      result.state = 'fail'
      result.isActionEnabled = true
      break
    case 'na':
      result.description = 'notRequired'
      result.state = 'disabled'
      break
    default:
      break
  }

  return result
}

export const getTxnAMLDetail: CheckFunction = checkState => {
  const result: CheckCardInfo = {
    title: 'amlTransactionMonitoring',
    description: 'noMLTransactionFound',
    state: 'pass',
    isActionEnabled: false,
    redirectionType: 'transactions',
    actionName: 'resolve',
  }

  switch (checkState) {
    case 'fail':
      result.description = 'potentialMLTransactionFound'
      result.state = 'fail'
      result.isActionEnabled = true
      break
    case 'warn':
      result.description = 'potentialMLTransactionFound'
      result.state = 'warn'
      result.isActionEnabled = true
      break
    case 'manual_passed':
      result.description = 'issuesResolved'
      result.state = 'manual_passed'
      break
    case 'na':
      result.description = 'notRequired'
      result.state = 'disabled'
      break
    case 'unchecked':
      result.description = 'notCompleted'
      result.state = 'na'
      break
    case 'pass':
    default:
      break
  }
  return result
}

export const getTxnCustomerDetail: CheckFunction = checkState => {
  const result: CheckCardInfo = {
    title: 'deviceCustomerCharacteristics',
    description: 'noHighRiskCharacteristicsFound',
    state: 'pass',
    isActionEnabled: false,
    redirectionType: 'characteristics',
    actionName: 'resolve',
  }

  switch (checkState) {
    case 'fail':
      result.description = 'highRiskCharacteristicsFound'
      result.state = 'fail'
      result.isActionEnabled = true
      break
    case 'warn':
      result.description = 'highRiskCharacteristicsFound'
      result.state = 'warn'
      result.isActionEnabled = true
      break
    case 'manual_passed':
      result.description = 'issuesResolved'
      result.state = 'manual_passed'
      break
    case 'na':
      result.description = 'notRequired'
      result.state = 'disabled'
      break
    case 'unchecked':
      result.description = 'notCompleted'
      result.state = 'na'
      break
    case 'pass':
    default:
      break
  }
  return result
}

export const getTxnFraudDetail: CheckFunction = checkState => {
  const result: CheckCardInfo = {
    title: 'fraudTransactionMonitoring',
    description: 'noFraudTransactionFound',
    state: 'pass',
    isActionEnabled: false,
    redirectionType: 'transactions',
    sectionId: 'FRAUD',
    actionName: 'resolve',
  }

  switch (checkState) {
    case 'fail':
      result.description = 'potentialFraudTransactionFound'
      result.state = 'fail'
      result.isActionEnabled = true
      break
    case 'warn':
      result.description = 'potentialFraudTransactionFound'
      result.state = 'warn'
      result.isActionEnabled = true
      break
    case 'manual_passed':
      result.description = 'issuesResolved'
      result.state = 'manual_passed'
      break
    case 'na':
      result.description = 'notRequired'
      result.state = 'disabled'
      break
    case 'unchecked':
      result.description = 'notCompleted'
      result.state = 'na'
      break
    case 'pass':
    default:
      break
  }
  return result
}

export const getKYBReportCheckDetail =
  (kybType: KYBCheckType): CheckFunction =>
  checkState => {
    const result: CheckCardInfo = {
      title: kybType,
      description: 'viewReportHere',
      state: 'pass',
      isActionEnabled: false,
      actionName: 'viewReport',
      openDocument: kybType,
    }

    if (checkState === 'pass') {
      result.isActionEnabled = true
    }

    if (checkState === 'unchecked') {
      result.description = 'reportGenerating'
      result.state = 'pending'
      result.stateText = 'generatingReport'
    }

    return result
  }

type CheckState = SuggestionString<'fail' | 'unchecked' | 'pass'>

const sardineI18nMapping = {
  title: {
    device: 'device',
    phone: 'phone',
    email: 'email',
    iplocation: 'iplocation',
  },
  description: {
    device: {
      fail: 'deviceFailed',
      pass: 'devicePassed',
      na: 'notCompleted',
    },
    phone: {
      fail: 'phoneFailed',
      pass: 'phonePassed',
      na: 'notCompleted',
    },
    email: {
      fail: 'emailFailed',
      pass: 'emailPassed',
      na: 'notCompleted',
    },
    iplocation: {
      fail: 'ipLocationFailed',
      pass: 'ipLocationPassed',
      na: 'notCompleted',
    },
  },
}

const riskDescriptionMapping: Record<
  SardineCheckType,
  {
    low: string
    medium: string
    high: string
    very_high: string
    unknown: string
  }
> = {
  email: {
    low: 'riskDescriptionMapping.email.low',
    medium: 'riskDescriptionMapping.email.medium',
    high: 'riskDescriptionMapping.email.high',
    very_high: 'riskDescriptionMapping.email.very_high',
    unknown: 'riskDescriptionMapping.email.unknown',
  },
  device: {
    low: 'riskDescriptionMapping.device.low',
    medium: 'riskDescriptionMapping.device.medium',
    high: 'riskDescriptionMapping.device.high',
    very_high: 'riskDescriptionMapping.device.very_high',
    unknown: 'riskDescriptionMapping.device.unknown',
  },
  phone: {
    low: 'riskDescriptionMapping.phone.low',
    medium: 'riskDescriptionMapping.phone.medium',
    high: 'riskDescriptionMapping.phone.high',
    very_high: 'riskDescriptionMapping.phone.very_high',
    unknown: 'riskDescriptionMapping.phone.unknown',
  },
  iplocation: {
    low: 'riskDescriptionMapping.iplocation.low',
    medium: 'riskDescriptionMapping.iplocation.medium',
    high: 'riskDescriptionMapping.iplocation.high',
    very_high: 'riskDescriptionMapping.iplocation.very_high',
    unknown: 'riskDescriptionMapping.iplocation.unknown',
  },
}

function generateSardineCheckFunction(
  type: SardineCheckType,
): (checkState: CheckState, options?: ChecksOptions) => CheckCardInfo {
  return function getSardineResult(
    checkState: CheckState,
    options?: ChecksOptions,
  ) {
    const title = sardineI18nMapping.title[type] as CheckEn<'title'>

    const fraudData = options?.fraudData as IApplicantFraudData | undefined
    const riskLevel = fraudData?.[type]?.riskLevel as
      | 'low'
      | 'medium'
      | 'high'
      | 'very_high'
      | 'unknown'

    const getCheckCardState = (resultType: CheckState) => {
      switch (resultType) {
        case 'fail':
          return 'fail'
        case 'unchecked':
          return 'na'
        case 'pass':
          return 'pass'
        default:
          return 'na'
      }
    }

    const checkCardState = getCheckCardState(checkState)

    const riskDescription =
      riskDescriptionMapping[type][riskLevel] ||
      sardineI18nMapping.description[type][checkCardState]

    const cardInfo: CheckCardInfo = {
      title,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      description: riskDescription,
      state: checkCardState,
      isActionEnabled: false,
      // actionName: 'resolve',
    }

    if (checkState === 'fail') {
      cardInfo.isActionEnabled = true
      cardInfo.actionType = 'redirectAndScroll'
      cardInfo.actionName = 'review'
      // cardInfo.openUrl = `${type}Result`
      // action = $router =>
      // sardineRedirect($router, `personal-info-kyc?section=${type}`)
    }

    return cardInfo
  }
}

export const getDeviceCheckDetail = generateSardineCheckFunction('device')

export const checksDataGenerator: Record<ChecksTypes, CheckFunction> = {
  pep: getPEPCheckDetail,
  pepMedia: getPEPMediaCheckDetail,
  chineseId: getChineseNationalIdDetail,
  blocklist: getBlocklistCheckDetail,
  duplicate: getDuplicatesCheckDetail,
  vevo: getVEVOCheckDetail,
  fraudCheck: getFraudCheckDetail,
  fraudList: getFraudCheckDetail,
  attest: getBiometryCheckDetail,
  idValidate: getBiometryCheckDetail,
  manual: getManualCheckDetail,
  blocklistSearch: getBlocklistSearchCheckDetail,
  kyc: getKycCheckDetail('kyc'),
  onePlusKyc: getKycCheckDetail('kyc_1'),
  twoPlusKyc: getKycCheckDetail('kyc_2'),
  govId: getGOVCheckDetail,
  checkSource: getCheckSourceValidationCheckDetail,
  supportDocs: getDocumentUploadCheckDetail,
  deviceRisk: getDeviceRiskCheckDetail,
  kycFraud: getKYCDeviceFraudDetail,
  kybReport: getKYBReportCheckDetail('kybReport'),
  uboReport: getKYBReportCheckDetail('uboReport'),
  crReport: getKYBReportCheckDetail('crReport'),
  phone: generateSardineCheckFunction('phone'),
  device: generateSardineCheckFunction('device'),
  email: generateSardineCheckFunction('email'),
  iplocation: generateSardineCheckFunction('iplocation'),
}
