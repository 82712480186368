import { useCallback, useState } from 'react'

import { Id, TypeOptions, toast } from 'react-toastify'

export const notification = {
  ...toast,
}

type ShowNotificationParams = {
  message: string
  type: Exclude<TypeOptions, 'default'>
}

/**
 * Custom hook to show notifications. Also dismisses the previous notification.
 * @returns showNotification function
 * @example
 * const { showNotification } = useNotification()
 */
export const useNotification = () => {
  const [toastId, setToastId] = useState<Id>()

  const showNotification = useCallback(
    ({ message, type }: ShowNotificationParams) => {
      notification.dismiss(toastId)
      const notificationType = notification[type]
      setToastId(notificationType(message))
    },
    [toastId],
  )

  return { showNotification }
}
