import React, { useLayoutEffect, useState } from 'react'

import { ApplicantId } from 'entities/applicant'

import { useI18n } from 'shared/i18n'

import { APPLICANT_BIOMETRICS_OCR_KEY } from '../../applicant-biometrics-ocr.key'
import { applicantBiometricsOcrEn } from '../../locale/applicant-biometrics-ocr.en'
import { reportStatus } from '../../model/applicant-biometrics-ocr.model'
import { useApplicantBiometryState } from '../../state/applicant-biometrics-ocr-data-state/applicant-biometrics-ocr-data.state'
import { BiometricAccordionHeader } from '../applicant-biometrics-accordion/applicant-biometrics-accordion'
import {
  BiometricDocumentView,
  ConfidenceScore,
  TickTree,
} from '../applicant-biometrics-ocr-helper/applicant-biometrics-ocr-helper'

type Props = {
  applicantId: ApplicantId
  index: number
  onManualOverride?: (status: { [key: string]: string }) => void
}

export function ApplicantBiometryReportPanel({
  applicantId,
  index,
  onManualOverride,
}: Props) {
  const [rowSpan, setRowSpan] = useState(1)
  const [numColumns, setNumColumns] = useState(1)

  const t = useI18n([APPLICANT_BIOMETRICS_OCR_KEY], {
    keys: applicantBiometricsOcrEn,
  })

  const {
    data: { biometryBase64, biometryReportData },
    loadingScan,
  } = useApplicantBiometryState({ applicantId, index })

  useLayoutEffect(() => {
    // Since we are using implicit rows, we need to calculate the number of rows
    // based on the number of items in the biometry report
    const numRows = Math.ceil((biometryReportData?.items.length ?? 100) / 3)

    setRowSpan(numRows)
  }, [biometryReportData?.items.length])

  useLayoutEffect(() => {
    const itemsLength = biometryReportData?.items.length ?? 0

    // Maximum number of columns allowed
    const maxColumns = 5

    // Add 2 for the image and confidence score
    const columnsNeeded = Math.min(itemsLength + 2, maxColumns)
    setNumColumns(columnsNeeded)
  }, [biometryReportData?.items.length])

  if (!biometryReportData) return null

  return (
    <BiometricAccordionHeader
      checkResult={biometryReportData.result}
      reportStatus={t(reportStatus(biometryReportData.checkStatus))}
      checkDate={biometryReportData.checkDate}
      detailType="biometry"
      applicantId={applicantId}
      index={index}
      className="min-w-[934px]"
      onManualOverride={onManualOverride}
    >
      <div
        className="grid auto-rows-auto gap-x-4 gap-y-6 py-2 overflow-x-auto"
        style={{ gridTemplateColumns: `repeat(${numColumns}, minmax(0, 1fr))` }}
      >
        <div style={{ gridRow: `span ${rowSpan}/ span ${rowSpan}` }}>
          <BiometricDocumentView
            fileName={t('facialRecognition')}
            imageScan={biometryBase64.image}
            videoScan={biometryBase64.video}
            loading={loadingScan}
            className="max-w-[200px]"
          />
        </div>

        {biometryReportData.items.map(item => (
          <TickTree {...item} key={item.key} />
        ))}

        <ConfidenceScore
          className="min-h-[72px]"
          confidenceLevel={biometryReportData.confidence_level}
        />
      </div>
    </BiometricAccordionHeader>
  )
}
