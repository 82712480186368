import React, { useState } from 'react'

import { FrankieButton, FrankieLoader } from 'frankify/src'

import { useApplicantSingleSpaOverviewPath } from 'entities/applicant'
import {
  IMatchedOrganization,
  getRequestStatus,
  useCreateOwnershipProfileROW,
  useGenerateReportROW,
} from 'entities/organisation'

import { useI18n } from 'shared/i18n'
import { notification } from 'shared/notification'
import { Noop } from 'shared/typescript'

import { ORGANISATION_AUDIT_KEY } from '../../locale/organisation-audit.en'

type Props = {
  onClose: Noop
  organisationInfo: IMatchedOrganization
}

const RETRY_TIMEOUT = 4000
const RETRY_COUNT = 30

export function OrganisationOwnershipReportModal({
  organisationInfo,
  onClose,
}: Props) {
  const t = useI18n([ORGANISATION_AUDIT_KEY])
  const [isGettingReportStatus, setIsGettingReportStatus] = useState(false)

  const {
    mutateAsync: createOwnershipProfileROW,
    isLoading: isCreatingProfile,
  } = useCreateOwnershipProfileROW()

  const { mutateAsync: generateReportROW, isLoading: isGeneratingReport } =
    useGenerateReportROW('ownership')

  const { navigateToOverview } = useApplicantSingleSpaOverviewPath()

  const awaitForOwnershipReport = async (
    entityId: string,
    requestId: string,
  ): Promise<unknown> => {
    const retry = async (retries: number): Promise<boolean | undefined> => {
      if (retries >= RETRY_COUNT) {
        return false
      }
      const { request } = await getRequestStatus(entityId, requestId)

      if (request.status === 'COMPLETE') {
        return true
      }

      if (request.status === 'FAILED') {
        return false
      }

      return new Promise(resolve => {
        setTimeout(() => {
          resolve(retry(retries + 1))
        }, RETRY_TIMEOUT)
      })
    }

    const result = await retry(0)

    return result
  }

  const handleClickProfileReport = async () => {
    try {
      setIsGettingReportStatus(true)

      const {
        data: { entityId, requestId },
      } = await createOwnershipProfileROW({
        organisationToken: organisationInfo.organizationToken,
      })

      const status = await awaitForOwnershipReport(entityId, requestId)
      if (!status) throw new Error('Error generating report')

      await generateReportROW({ entityId, reportType: 'ownership' })
      onClose()

      navigateToOverview(entityId, 'onboarding')
    } catch (error) {
      notification.error(t('errorOwnershipDetailsROW'))
      setIsGettingReportStatus(false)
      onClose()
    }
  }

  const isLoading =
    isCreatingProfile || isGeneratingReport || isGettingReportStatus

  return (
    <FrankieLoader loading={isLoading}>
      <span className="block text-tertiary-grey-800 text-xl font-bold">
        {t('organisationOwnershipTitle')}
      </span>
      <span className="block text-tertiary-grey-700 text-sm font-normal mt-2">
        {t('organisationOwnershipSubtitle')}
      </span>
      <div className="flex flex-row justify-end mt-6">
        <FrankieButton intent="subtle" onClick={onClose}>
          {t('profileReportModal.cancelButton')}
        </FrankieButton>
        <FrankieButton
          intent="primary"
          className="ml-2"
          onClick={handleClickProfileReport}
        >
          {t('profileReportModal.generateReport')}
        </FrankieButton>
      </div>
    </FrankieLoader>
  )
}
