import React, { ReactNode } from 'react'

import { FrankieButton, FrankieDivider } from 'frankify/src'

import { trustAnalyserFormQa } from 'features/applicant-supporting-documents/qa/applicant-support-documents.qa'

import { useI18n } from 'shared/i18n'

import { APPLICANT_SUPPORTING_DOCUMENTS_KEY } from '../../../applicant-supporting-documents.key'
import { applicantSupportingDocumentsEn } from '../../../locale/applicant-supporting-documents.en'
import {
  TrustDetailFormTypes,
  TrustFormTypes,
} from '../../../model/applicant-supporting-trust-deed.model'

export type FormHeaderProps = {
  number: number
  onRemove?: () => void
  showRemove: boolean
  text: string
  removeText?: string
  className?: string
  tag?: ReactNode
  trustDetailFormType?: TrustDetailFormTypes
  onClickBack: (
    type: TrustDetailFormTypes,
    formKey: TrustFormTypes,
    index: number,
  ) => void
  formKey: TrustFormTypes
  index: number
  parentRef: React.RefObject<HTMLDivElement>
  testId?: {
    tag?: string
  }
}

export function FormHeader({
  showRemove,
  onRemove,
  number,
  text,
  className,
  removeText,
  tag,
  onClickBack,
  trustDetailFormType = TrustDetailFormTypes.DEFAULT,
  formKey,
  index,
  parentRef: ref,
  testId = {
    tag: '',
  },
}: FormHeaderProps) {
  const t = useI18n([APPLICANT_SUPPORTING_DOCUMENTS_KEY], {
    keys: applicantSupportingDocumentsEn,
  })

  const handleClickOutside = (event: MouseEvent) => {
    if (
      ref.current &&
      !ref.current.contains(event.target as Node) &&
      trustDetailFormType !== TrustDetailFormTypes.DEFAULT
    ) {
      onClickBack(trustDetailFormType, formKey, index)
    }
  }

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trustDetailFormType])

  const titleClassName = 'text-md font-bold mb-4'

  const renderDetailHeader = (formType: TrustDetailFormTypes) => {
    let headerText = ''

    switch (formType) {
      case TrustDetailFormTypes.NAME:
        headerText = `${t('edit')} ${t('fullName')}: ${text}`
        break
      case TrustDetailFormTypes.ADDRESS:
        headerText = `${t('edit')} ${t('address')}: ${text}`
        break
      default:
        return null
    }

    return (
      <div className={`${titleClassName} mt-4`}>
        <h3 className="inline-block text-md">{headerText}</h3>
        <span className="px-1">{number}</span>
        {tag && (
          <p className="inline-block" data-qa={testId.tag}>
            {tag}
          </p>
        )}
      </div>
    )
  }

  return (
    <>
      <div className="flex justify-between">
        {trustDetailFormType !== TrustDetailFormTypes.DEFAULT ? (
          <FrankieButton
            noStyles
            startIcon={{
              name: 'mdiArrowLeft',
              size: 'xs',
              className: 'text-primary-800',
            }}
            onClick={() => onClickBack(trustDetailFormType, formKey, index)}
            className="items-center flex gap-2 text-primary-800"
          >
            Back
          </FrankieButton>
        ) : (
          <div className={className || titleClassName}>
            <p className="inline-block">{text}</p>
            <span className="px-1">{number}</span>
            {tag && (
              <p className="inline-block" data-qa={testId.tag}>
                {tag}
              </p>
            )}
          </div>
        )}

        {showRemove && (
          <div
            className="text-primary-800 h-5"
            onClick={onRemove}
            onKeyDown={onRemove}
            role="button"
            tabIndex={0}
            data-qa={trustAnalyserFormQa.removeEntity}
          >
            {removeText || t('remove')}
          </div>
        )}
      </div>
      {renderDetailHeader(trustDetailFormType)}
    </>
  )
}

export type FormFooterProps = {
  showFooter: boolean
  onAdd: () => void
  text: string
  showDivider?: boolean
}

export function FormFooter({
  showFooter,
  onAdd,
  text,
  showDivider = true,
}: FormFooterProps) {
  const t = useI18n([APPLICANT_SUPPORTING_DOCUMENTS_KEY], {
    keys: applicantSupportingDocumentsEn,
  })

  const addMarginY = !showDivider ? 'my-3' : ''

  if (showFooter) {
    return (
      <>
        <div
          className={`text-primary-800 inline-block ${addMarginY}`}
          onClick={onAdd}
          onKeyDown={onAdd}
          role="button"
          tabIndex={0}
          data-qa={trustAnalyserFormQa.addEntity}
        >
          {t('add')} {text}
        </div>
        {showDivider && <FrankieDivider className="my-4" />}
      </>
    )
  }

  return null
}
