import { useMemo } from 'react'

import { useQuery } from '@tanstack/react-query'
import { useLocation } from 'react-router-dom'

import { useApplicantRoute } from 'entities/routing'
import { useHasFeatureFlag } from 'entities/session'

import { customViewApi } from '../../api/custom-view.api'
import { VIEW_LIMIT } from '../../model/custom-views.model'

export const useCustomView = (hasCustomViews?: boolean) =>
  useQuery({
    enabled: hasCustomViews,
    queryKey: ['customViews'],
    queryFn: async () => {
      const { data } = await customViewApi.getCustomViews()
      return data
    },
  })

export const useCustomViewData = () => {
  const { hasCustomViews } = useHasFeatureFlag({
    hasCustomViews: 'customViews',
  })
  const { data, isFetching } = useCustomView(hasCustomViews)

  const { generateRoute } = useApplicantRoute()
  const location = useLocation()

  const sortedViewData = data?.customViews.sort((a, b) =>
    a.name.localeCompare(b.name),
  )

  const customViewLimitReached = (data?.customViews.length ?? 0) >= VIEW_LIMIT

  const id = useMemo(() => location.pathname.split('/')[2], [location])

  const currentView = useMemo(
    () => data?.customViews.find(view => id && view.id === Number(id)),
    [data, id],
  )

  const routeForView = (name: string) => {
    const matchedView = data?.customViews.find(view => view.name === name)
    if (!matchedView) return ''

    const path = generateRoute({
      routeKey: 'customView',
      overrideParams: { viewId: matchedView.id.toString() },
    })
    const finalPath = `${path}?${matchedView.query}`
    return finalPath
  }

  const dataLength = data?.customViews.length ?? 0

  const query = location.search.slice(1)

  return {
    customViewLimitReached,
    routeForView,
    dataLength,
    query,
    id,
    currentView,
    sortedViewData,
    hasCustomViews,
    isFetchingCustomView: isFetching,
  }
}
