import React, { useEffect, useMemo, useState } from 'react'

import { AxiosError } from 'axios'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Trans } from 'react-i18next'
import { Link } from 'react-router-dom'

import {
  FrankieAlert,
  FrankieButton,
  FrankieLoader,
  FrankieTextField,
} from 'frankify/src'

import { ErrorCodeTypes } from 'shared/error'
import { useI18n } from 'shared/i18n'
import { TrackingEventsTypes, trackingManager } from 'shared/tracking'
import { Unknownable } from 'shared/typescript'
import { EMAIL_PATTERN } from 'shared/validation'

import { LOGIN_KEY } from '../../login.key'
import {
  isIWrongCredentialsErrorData,
  IWrongCredentialsErrorData,
} from '../../model/login.model'
import { loginQa } from '../../qa/login.qa'

export enum LoginInputTypes {
  Email = 'email',
  /**
   * Strange name turns off password autocomplete
   */
  PasswordNoAutocomplete = 'pswrdNoAutocomplete',
}

interface ILoginInputs {
  [LoginInputTypes.Email]: string
  [LoginInputTypes.PasswordNoAutocomplete]: string
}

type Props = {
  passwordResetPath: string
  onFormSubmit: ({
    email,
    password,
  }: {
    email: string
    password: string
  }) => Promise<void>
}

export function LoginForm({ passwordResetPath, onFormSubmit }: Props) {
  const t = useI18n([LOGIN_KEY])
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [isLoader, setIsLoader] = useState<boolean>(false)

  useEffect(() => {
    trackingManager.track(TrackingEventsTypes.LoginFormView)
  }, [])

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid },
    watch,
  } = useForm<ILoginInputs>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
  })

  const formValues = watch()

  const isFormDisabled = !isDirty || !isValid || isLoader

  const emailRegister = useMemo(
    () =>
      register(LoginInputTypes.Email, {
        required: {
          value: true,
          message: t('validation.email.required'),
        },
        pattern: {
          value: EMAIL_PATTERN,
          message: t('validation.email.pattern'),
        },
      }),
    [register, t],
  )

  const passwordRegister = useMemo(
    () =>
      register(LoginInputTypes.PasswordNoAutocomplete, {
        required: {
          value: true,
          message: t('validation.password.required'),
        },
      }),
    [register, t],
  )

  const updateApiError = (
    error: AxiosError<Unknownable<IWrongCredentialsErrorData>>,
  ) => {
    const status = error.response?.status as ErrorCodeTypes | undefined
    const data = error.response?.data
    if (!status) {
      return
    }
    // wrong credentials
    if (status === ErrorCodeTypes.E404 && isIWrongCredentialsErrorData(data)) {
      const attemptsLeft = data.attempts_max - data.attempts_count
      setErrorMessage(
        t('errors.404', {
          count: data.attempts_count > 1 ? attemptsLeft : undefined,
        }),
      )
      return
    }
    setErrorMessage(t(`errors.${status}`, 'errors.unspecific'))
  }

  const handleFormSubmit: SubmitHandler<ILoginInputs> = async ({
    email,
    pswrdNoAutocomplete,
  }) => {
    try {
      setIsLoader(true)
      await onFormSubmit({ email, password: pswrdNoAutocomplete })

      // @ggrigorev This is a workaround to remove the blinking during the redirection from React to Vue
      // setIsLoader(false)
    } catch (error) {
      setIsLoader(false)
      updateApiError(
        error as AxiosError<Unknownable<IWrongCredentialsErrorData>>,
      )
    }
  }

  return (
    <FrankieLoader loading={isLoader}>
      <div
        data-qa={loginQa.header}
        className="text-primary-700 text-3xl leading-tight font-bold"
      >
        {t('heading')}
      </div>
      <div className="text-md text-tertiary-grey-700 mt-2">
        {t('subheading')}
      </div>
      <form onSubmit={handleSubmit(handleFormSubmit)} data-qa={loginQa.form}>
        <FrankieTextField
          data-hj-suppress
          className="mt-4"
          label={t('validation.email.label')}
          {...emailRegister}
          errorText={errors[LoginInputTypes.Email]?.message}
          error={!!errors[LoginInputTypes.Email]}
          testId={{
            input: loginQa.emailInput,
            supportingText: loginQa.emailInputSupportText,
          }}
        />
        <FrankieTextField
          data-hj-suppress
          className="mt-4 mb-2"
          label={t('validation.password.label')}
          {...passwordRegister}
          type="password"
          errorText={errors[LoginInputTypes.PasswordNoAutocomplete]?.message}
          error={!!errors[LoginInputTypes.PasswordNoAutocomplete]}
          testId={{
            input: loginQa.passwordInput,
            showPassword: loginQa.showPasswordCta,
            supportingText: loginQa.passwordInputSupportText,
          }}
          autoComplete="some_strange_value"
        />
        <Link
          className="text-primary-700 text-sm"
          data-qa={loginQa.resetPasswordLink}
          to={passwordResetPath}
          state={{ prefillEmail: formValues[LoginInputTypes.Email] }}
        >
          {t('button.resetPassword.default')}
        </Link>
        {errorMessage ? (
          <FrankieAlert
            className="mt-4 text-tertiary-grey-700"
            type="error"
            testId={{ alert: loginQa.alert }}
          >
            <Trans className="text-tertiary-grey-700">{errorMessage}</Trans>
          </FrankieAlert>
        ) : null}
        <div className="flex flex-initial justify-end">
          <FrankieButton
            className="mt-4"
            disabled={isFormDisabled}
            type="submit"
            testId={{ button: loginQa.submitButton }}
            onClick={() =>
              trackingManager.track(TrackingEventsTypes.LoginFormUserLogin)
            }
          >
            {t('button.submit.default')}
          </FrankieButton>
        </div>
      </form>
    </FrankieLoader>
  )
}
