import React, { useMemo } from 'react'

import { FrankieButton, FrankieIcon, FrankieLoader } from 'frankify/src'

import { MONITORING_OVERVIEW_KEY } from 'features/monitoring-overview/monitoring-overview.key'
import { useScansQuery } from 'features/monitoring-overview/state/scans-query/get-scans.query'

import { useApplicantDataQuery } from 'entities/applicant'
import { scanDateSorter } from 'entities/document'

import { DateFormatTypes, formatDate } from 'shared/date-time'
import { convertDataURItoBlob, makeDataURL } from 'shared/file'
import { useI18n } from 'shared/i18n'
import { notification } from 'shared/notification'

type Props = {
  entityId: string
  title: string
  isGeneratingReport?: boolean
  type: 'profile' | 'ownership'
}

export function CompletedCheck({
  entityId,
  isGeneratingReport,
  type,
  title,
}: Props) {
  const t = useI18n([MONITORING_OVERVIEW_KEY])

  const { data: applicantData } = useApplicantDataQuery({
    applicantId: entityId,
  })

  const { lastReportDate, documentId } = useMemo(() => {
    const documents = applicantData?.documents

    const doc = documents?.find(doc => doc.idSubType === type)
    const lastScan = doc?.scans.sort(scanDateSorter)
    return {
      documentId: doc?.documentId,
      lastReportDate: lastScan?.[0].scanCreated,
    }
  }, [applicantData?.documents, type])

  const { refetch: getScansForCheck, isFetching: preparingReport } =
    useScansQuery({ documentId })

  const handleViewReport = async () => {
    try {
      const scanResult = await getScansForCheck()

      if (!scanResult.data?.length) {
        notification.error(t('errorOnViewReport'))
        return
      }

      const scan = scanResult.data.sort(scanDateSorter)[0]

      const base64 = makeDataURL(scan.mimeType, scan.file)
      const blob = convertDataURItoBlob(base64)
      const temp_url = window.URL.createObjectURL(blob)
      window.open(temp_url, '_blank')
    } catch (error) {
      notification.error(t('errorOnViewReport'))
    }
  }

  if (!documentId) return null

  if (isGeneratingReport) {
    return (
      <div>
        <div className="bg-mono-white border border-tertiary-grey-200 rounded-md flex flex-col gap-4">
          <div className="p-4 flex flex-row justify-between items-center">
            <div id="status-container" className="flex flex-row">
              <div className="inline-flex items-center">
                <FrankieLoader
                  className="!w-[24px] !h-[24px] !min-w-[24px] !min-h-[24px]"
                  size="xs"
                  loading
                />
              </div>

              <div className="ml-2 flex flex-col">
                <span className="text-md font-semibold text-tertiary-grey-800">
                  {title}
                </span>

                <span className="mt-1 text-sm font-normal text-tertiary-grey-600">
                  {t('reportIsGenerating')}
                </span>
              </div>
            </div>
            <FrankieButton
              intent="subtle"
              className="!w-[116px] !min-w-[116px] px-xs py-lg text-sm text-tertiary-grey-700"
              disabled
            >
              {t('generatingReport')}
            </FrankieButton>
          </div>
        </div>
      </div>
    )
  }

  return (
    <FrankieLoader loading={preparingReport}>
      <div className="bg-mono-white border border-tertiary-grey-200 rounded-md flex flex-col gap-4">
        <div className="p-4 flex flex-row justify-between items-center">
          <div id="status-container" className="flex flex-row">
            <FrankieIcon
              className="text-tertiary-green-600 inline-flex flex-grow-0 items-center"
              name="mdiCheckCircle"
              size="md"
            />
            <div className="ml-2 flex flex-col">
              <span className="text-md font-semibold text-tertiary-grey-800">
                {title}
              </span>
              {lastReportDate && (
                <span className="mt-1 text-sm font-normal text-tertiary-grey-600">
                  {t('reportGeneratedOn', {
                    date: formatDate(
                      lastReportDate,
                      DateFormatTypes.DateNumbers,
                    ),
                  })}
                </span>
              )}
            </div>
          </div>
          <FrankieButton
            intent="secondary"
            className="!w-[93px] !min-w-[93px] px-xs py-lg text-xs text-tertiary-grey-700"
            onClick={handleViewReport}
            disabled={preparingReport}
          >
            {t('viewReportButton')}
          </FrankieButton>
        </div>
      </div>
    </FrankieLoader>
  )
}
