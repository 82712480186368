/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access */
/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import React, { forwardRef, useEffect, useMemo, useRef, useState } from 'react'

import { FrankieSelectStyle } from './select.theme'
import { FrankieSelectOptions, IFrankieSelectFieldProps } from './select.types'
import { useFrankieMenu } from '../../hooks'
import { FrankieButton } from '../button'
import { FrankieIcon } from '../icon'
import './select.styles.css'

export const FrankieSelectField = forwardRef(
  // eslint-disable-next-line complexity, prefer-arrow-callback
  function FrankieSelectField<T extends string | number>(
    {
      className = '',
      inputClassName = '',
      helperTextClassName = '',
      optionsClassName = '',
      size = 'md',
      variant = 'primary',
      disabled = false,
      error = false,
      errorText = '',
      label,
      name,
      placeholder = '',
      supportingText = '',
      tag,
      badge,
      options,
      testId = {
        container: '',
        input: '',
        label: '',
        tag: '',
        supportingText: '',
        closeCta: '',
      },
      value,
      onBlur,
      onChange,
      onClick,
      onFocus,
      autocomplete = false,
      clearable = false,
      required,
      noOptionText = 'No record',
    }: IFrankieSelectFieldProps<T>,
    ref: React.LegacyRef<HTMLInputElement>,
  ): JSX.Element {
    // To keep track of the selected index
    const [selectedIndex, setSelectedIndex] = useState<number | null>(null)

    // To keep track of value input - usually in case of autocomplete
    const [filterText, setFilterText] = useState('')

    const inputRef = useRef<HTMLInputElement>(null)

    const { parentRef, isOpen, handleOpen, handleToggle, handleClose } =
      useFrankieMenu()

    const selectedValueLabel = useMemo(
      () => options.find(item => item.value === value)?.label,
      [value, options],
    )

    /**
     * Final option if autocomplete
     * Filtering option when autocomplete is on and filterText should no be equal to already selected value
     */
    const filteredOptions = useMemo(
      () =>
        autocomplete && filterText && filterText !== selectedValueLabel
          ? options.filter(item => {
              const matchText = filterText.toLowerCase().trim()
              const matchString = item.label.toLowerCase().trim()

              return autocomplete === 'contains'
                ? matchString.includes(matchText)
                : matchString.startsWith(matchText)
            })
          : options,
      [options, filterText, autocomplete, selectedValueLabel],
    )

    /**
     * On option click
     */
    const handleSelectOption = (option: FrankieSelectOptions<T>) => () => {
      const { value, label } = option
      onChange({ target: { value } })
      setFilterText(label)
      handleClose()
    }

    /**
     * On Input change
     */
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setFilterText(e.target.value)
      if (!isOpen) handleOpen()
    }

    useEffect(() => {
      const currentIndex = filteredOptions.findIndex(
        option => option.value === value,
      )

      if (isOpen) {
        setSelectedIndex(currentIndex)
      } else {
        setFilterText(
          currentIndex === -1 ? '' : filteredOptions[currentIndex].label,
        )
      }
    }, [isOpen, filteredOptions, value])

    const handleOptionToggle = (
      e: React.MouseEvent<HTMLInputElement, MouseEvent>,
    ) => {
      if (onClick) onClick(e)
      if (disabled) return
      handleToggle()
    }

    /**
     * To handle clearing of data from cross button
     */
    const handleClear = () => {
      onChange({ target: { value: undefined as unknown as T } })
      setFilterText('')
    }

    const handleKeyDown = (
      e: React.KeyboardEvent<HTMLInputElement | HTMLLIElement>,
    ) => {
      switch (e.key) {
        case 'ArrowUp':
        case 'ArrowDown':
          e.preventDefault()
          if (isOpen) {
            setSelectedIndex(prevIndex => {
              let newIdx = 0
              if (prevIndex !== null) {
                newIdx =
                  e.key === 'ArrowDown'
                    ? Math.min(filteredOptions.length - 1, prevIndex + 1)
                    : Math.max(0, prevIndex - 1)
              }

              const selectedOption = document.querySelector(
                `.frankie-select-option-${newIdx}`,
              )

              selectedOption?.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
              })

              return newIdx
            })
          } else handleOpen()

          break
        case 'Enter':
          if (selectedIndex !== null) {
            const { value, label } = filteredOptions[selectedIndex]
            onChange({ target: { value } })
            setFilterText(label)
            handleClose()
            setSelectedIndex(null)
          }
          break
        case 'Escape':
          handleClose()
          break
        default:
          if (!autocomplete) e.preventDefault()
          break
      }
    }

    /**
     * To focus on the input when the when opening from toggle btn
     */
    const handleDropdown = () => {
      if (!isOpen && inputRef.current) {
        inputRef.current.focus()
      }
      handleToggle()
    }

    return (
      <div
        className={`w-full ${className} ${
          disabled ? 'pointer-events-none' : ''
        }`}
        data-qa={testId.container}
        ref={parentRef}
      >
        {(label || tag) && (
          <div className="flex flex-initial pb-1.5 w-icon-sm">
            <div className="grow">
              <label
                htmlFor={name}
                className="text-tertiary-grey-800 text-sm font-medium"
                data-qa={testId.label}
              >
                {label}
              </label>
            </div>
            <div
              className="flex flex-initial grow justify-end text-mono-70"
              data-qa={testId.tag}
            >
              {tag}
            </div>
          </div>
        )}
        <div className="relative frankie-select-field-container">
          <input
            id={name}
            name={name}
            aria-label={name}
            ref={input => {
              ;(inputRef as any).current = input
              if (typeof ref === 'function') {
                ref(input)
              } else if (ref) {
                ;(ref as any).current = input
              }
            }}
            className={FrankieSelectStyle({
              inputError: error,
              className: inputClassName,
              clearable: clearable && !!filterText,
              variant,
              size,
            })}
            placeholder={placeholder}
            disabled={disabled}
            type="text"
            value={filterText}
            data-qa={testId.input}
            onBlur={e => {
              if (e.relatedTarget?.role !== 'option') {
                if (onBlur) onBlur(e)
              } else {
                e.target.focus()
              }
            }}
            onChange={handleChange}
            onClick={handleOptionToggle}
            onFocus={onFocus}
            required={required}
            onKeyDown={handleKeyDown}
            autoComplete="off"
          />
          <div className="relative">
            {isOpen && (
              <ul
                className={`frankie-select-options 
                absolute z-10 bg-mono-white shadow-md rounded-sm top-1 w-full max-h-[193px] py-1 overflow-y-auto scrollbar-sm ${optionsClassName}`}
              >
                {filteredOptions.length > 0 ? (
                  filteredOptions.map((option, index) => (
                    <li
                      tabIndex={-1}
                      role="option"
                      aria-selected={index === selectedIndex}
                      key={option.value}
                      onClick={handleSelectOption(option)}
                      onKeyDown={handleKeyDown}
                      className={`text-sm text-tertiary-grey-800 py-2 px-4 cursor-pointer ${
                        index === selectedIndex
                          ? 'bg-primary-50'
                          : 'hover:bg-tertiary-grey-100'
                      } frankie-select-option-${index}`}
                      data-qa={option.testId}
                    >
                      {option.label}
                    </li>
                  ))
                ) : (
                  <li className="text-sm py-2 px-4">{noOptionText}</li>
                )}
              </ul>
            )}
          </div>

          <FrankieButton
            noStyles
            testId={{ button: testId.closeCta }}
            className="frankie-select-toggle absolute right-3  top-1/2 transform -translate-y-1/2 cursor-pointer"
            onClick={handleDropdown}
            singleIcon={{
              size: 'sm',
              name: isOpen ? 'mdiChevronUp' : 'mdiChevronDown',
              className: error
                ? 'text-tertiary-red-600'
                : 'text-tertiary-gray-700',
            }}
          />

          {!!badge && badge}

          {clearable && !!filterText && (
            <FrankieButton
              noStyles
              testId={{ button: testId.closeCta }}
              className="absolute right-8 top-1/2 transform -translate-y-1/2  text-tertiary-grey-500 hover:text-primary-800"
              onClick={handleClear}
              singleIcon={{
                size: 'xs',
                name: 'mdiClose',
              }}
            />
          )}
        </div>
        <div className={`flex ${helperTextClassName}`}>
          {(supportingText || errorText) && (
            <div className=" text-mono-70 pt-2 text-sm">
              <div
                className={`flex flex-initial items-start ${
                  error ? 'text-tertiary-red-600' : ''
                }`}
              >
                <div className="p-0.5 mr-2">
                  <FrankieIcon
                    name={
                      error ? 'mdiAlertCircleOutline' : 'mdiInformationOutline'
                    }
                    size="sm"
                  />
                </div>
                <p id="supporting-text" data-qa={testId.supportingText}>
                  {error ? errorText : supportingText}
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  },
)
