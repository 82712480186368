export const rolesToUsersEn = {
  roleRemoveFromUsers: {
    notification: {
      removed: '{{roleName}} role has been removed',
    },
  },
  reassignRolesForm: {
    title: 'The following users are currently assigned to this role',
    body: 'Please reassign them to another role before removing this role.',
    cta: {
      cancel: 'Cancel',
      submit: 'Remove role',
    },
    tooltip: 'Roles assigned to {{name}}:',
    roleSelect: {
      placeholder: 'Select new role',
      leaveEmpty: 'Do not reassign a role',
    },
  },
}
