import { capitalizeStringBySeparator } from 'shared/string'
import { Nullable } from 'shared/typescript'

export interface IExtraDetail {
  label: string
  value: Nullable<string>
}

export type FraudCheckResponse = Nullable<{
  matches?: FraudMatchDetail[]
}>

export type FraudMatchDetail = {
  fraudType: Nullable<string>
  matchType: Nullable<string>
  matchList: IMatchField[]
  extraDetails: IExtraDetail[]
  subject: Nullable<string>
  reportedBy: Nullable<string>
  dateOfFraud: Nullable<string>
  status: IMatchStatus
  comment: Nullable<string>
  address: IAddressPayload
  matchListId: string
  checkId: string
}

export type FraudListDataKeys = keyof FraudListData

type EnumAddressType =
  | 'OTHER'
  | 'RESIDENTIAL'
  | 'RESIDENTIAL1'
  | 'RESIDENTIAL2'
  | 'RESIDENTIAL3'
  | 'RESIDENTIAL4'
  | 'BUSINESS'
  | 'POSTAL'
  | 'REGISTERED_OFFICE'
  | 'PLACE_OF_BUSINESS'
  | 'PLACE_OF_BIRTH'
  | 'OFFICIAL_CORRESPONDANCE'

export interface IAddressPayload {
  buildingName: Nullable<string>
  postalCode: Nullable<string>
  state: Nullable<string>
  streetName: Nullable<string>
  streetNumber: Nullable<string>
  streetType: Nullable<string>
  suburb: Nullable<string>
  town: Nullable<string>
  unitNumber: Nullable<string>
  addressType: Nullable<EnumAddressType>
  country: Nullable<string>
  addressId: Nullable<string>
  longForm: Nullable<string>
  data?: Record<string, unknown>
  isAddedByCustomer?: Nullable<boolean>
}

export interface IMatchField {
  type: 'phone_number' | 'id_details' | 'employment' | 'comment'
  matches: IMatchDetails[]
}

export interface IMatchStatus {
  label: string
  value: string
}

interface IMatchDetails {
  key: string
  value: Nullable<string>
}

export type FraudListData = {
  id: string
  checkId: string
  matchId: string

  fraudType: Nullable<string>
  matchType: Nullable<string>
  matchList: string
  fraudSubject: Nullable<string>
  reportedBy: Nullable<string>
  date: Nullable<string>
  matchStatus: IMatchStatus
  extraDetails: IExtraDetail[]
}

export type FraudStatusUpdateFormData = FraudStatusChangePayload

export enum FraudStatusTypes {
  FALSE_POSITIVE = 'false_positive',
  TRUE_POSITIVE = 'true_positive',
}

export type FraudStatusChangePayload = {
  comment: string
  status: string
  checkId: string
  fraudListIds: string[]
}

export function fraudMatchType(fraudDetail: FraudMatchDetail) {
  switch (fraudDetail.reportedBy) {
    case 'mastercard':
      return 'Applicant'
    default:
      return (
        fraudDetail.matchType
          ?.split(',')
          .map(i => capitalizeStringBySeparator(i))
          .join(',') ?? ''
      )
  }
}

export function fraudSubject(fraudDetail: FraudMatchDetail) {
  switch (fraudDetail.reportedBy) {
    case 'mastercard':
      return 'Applicant'
    default:
      return capitalizeStringBySeparator(fraudDetail.subject || '')
  }
}

export function fraudReportedBy(fraudDetail: FraudMatchDetail) {
  switch (fraudDetail.reportedBy) {
    case 'mastercard':
      return 'MasterCard Match'
    default:
      return fraudDetail.reportedBy
  }
}

export function matchList(fraudDetail: FraudMatchDetail) {
  const { matchList } = fraudDetail
  const listTypeOfMatch = matchList.reduce((acc, item) => {
    if (item.matches.length > 0 && item.type !== 'comment')
      return `${acc}, ${capitalizeStringBySeparator(item.type)}`
    return acc
  }, '')
  return listTypeOfMatch
}
