import { useMutation } from '@tanstack/react-query'

import { useSessionQuery } from 'entities/session'

import { dashboardApi } from '../api/dashboard.api'

type ReqData = {
  password: string
}

type Args = {
  onSuccess: () => void
}

export const useGenerateReportMutation = ({ onSuccess }: Args) => {
  const { data: session } = useSessionQuery()

  return useMutation({
    mutationFn: ({ password }: ReqData) =>
      dashboardApi.generateReport({
        password,
        reportName: 'full',
        requester: session?.user.realname ?? '',
      }),
    onSuccess,
  })
}
