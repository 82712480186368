import React from 'react'

import {
  Controller,
  ControllerRenderProps,
  ControllerProps,
  FieldValues,
} from 'react-hook-form'

import { FrankieTextField, IFrankieTextFieldProps } from 'frankify/src'

type Props<TFormValues extends FieldValues> = Omit<
  IFrankieTextFieldProps,
  keyof ControllerRenderProps
> &
  Omit<ControllerProps<TFormValues>, 'render'> & {
    showErrorText?: boolean
    trim?: boolean
  }

export function TextFormField<TFormValues extends FieldValues>({
  name,
  control,
  defaultValue,
  rules,
  shouldUnregister,
  showErrorText = false,
  trim = false,
  ...textFieldProps
}: Props<TFormValues>) {
  return (
    <Controller
      render={({ field, fieldState: { error } }) => (
        <FrankieTextField
          {...textFieldProps}
          {...field}
          onBlur={e => {
            if (trim) {
              field.onChange(e.target.value.trim())
            }
            field.onBlur()
          }}
          error={!!error}
          errorText={showErrorText ? error?.message : undefined}
        />
      )}
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={rules}
      shouldUnregister={shouldUnregister}
    />
  )
}
