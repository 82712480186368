export const workflowEventsEn = {
  amlScreeningTitle: 'AML screening',
  amlViewHistoryBtn: 'View AML history',
  header: {
    attempt: 'attempt{{s}}',
    eventId: 'Event ID',
    executionId: 'Execution ID',
    createdAt: 'Created on',
    navbar: 'Workflows',
    risk: {
      low: 'Low risk',
      medium: 'Medium risk',
      high: 'High risk',
      unacceptable: 'Unacceptable',
      unknown: 'Unknown',
      riskScore: 'Risk score',
    },
    status: {
      completed: 'Completed',
      pending: 'Pending',
      failed: 'Failed',
    },
  },
  options: {
    manuallyPassed: 'Manually passed',
    manuallyFailed: 'Manually failed',
    needsAttention: 'Needs attention',
  },
  statusText: {
    passed: 'Passed',
    needsAttention: 'Needs attention',
    unchecked: 'Unchecked',
    failed: 'Failed',
    manuallyPassed: 'Manually passed',
    manuallyFailed: 'Manually failed',
    manuallyVerified: 'Manually verified',
  },
  verificationEventText: {
    title: 'KYC database check',
    badgeText: 'Unchecked',
    cta: 'Resolve',
    noMatches: 'No matches',
  },
  personalInfo: {
    header: 'Personal info',
    sourceHeader: 'Source match',
    name: 'Full Name',
    dob: 'Date of Birth',
    address: 'Residential Address',
  },
  documentType: {
    licence: 'Driver’s licence',
    passport: 'Passport',
    medicare: 'Medicare card',
  },

  documentLabels: {
    DRIVERS_LICENSE: "Driver's Licence",
    PASSPORT: 'Passport',
    NATIONAL_HEALTH_ID: 'Medicare',
    NATIONAL_ID: 'National ID',
  },

  noMatch: 'No matches',
  tooltipTitle: '{{label}} source match',
  entityStatusText: {
    KYC: 'KYC database check',
    AML: 'AML screening',
    IDV_DOCUMENT: 'Document check',
    IDV_BIOMETRIC: 'Biometrics check',
    OCR: 'Biometrics check',
  },
  manualStatus: {
    title: 'Change workflow status',
    subTitle:
      ' This will manually override the status for this workflow, all checks should be completed before proceeding.',
    cancel: 'Cancel',
    changeStatus: 'Change status',
    selectLabel: 'Change workflow status to',
    commentLabel: 'Comment',
    commentPlaceholder: 'Type your comment here',
    commentError: 'Please add a comment',
  },
  updatingStatus: 'Updating workflow status',
  notification: {
    success: 'Workflow status been successfully updated.',
    error: 'Workflow status has not been updated.',
  },
  riskScore: {
    label: {
      factors: 'FACTORS',
      value: 'VALUE',
      score: 'SCORE',
    },
    workflowRiskScore: 'Workflow risk score',
  },
  amlReviewBannerTitle:
    'There are still unreviewed Potential match AML results',
  banner: {
    noPermission:
      'You do not have the right permissions to verify this profile, please contact your administrator to do so.',
    title: 'No workflow checks to show yet, please verify this profile.',
    description:
      'Please manually complete and verify this entity to see workflow events.',
    button: 'Verify profile',
  },
  sendLinkBanner: {
    title:
      'Send a link to this customer to retry document and biometrics check',
    description:
      'A Document or biometrics check has failed in this profile. Send a link to this customer to request new document images to re-attempt the check.',
    button: 'Send link',
  },
  executeWorkflow: {
    verifyDescription:
      'This will run the following workflow, please check that all data to be verified is accurate before proceeding.',
    selectWorkflow: 'Select Workflow',
    comment: 'Comment',
    commentPlaceholder: 'Type your comment here',
    commentLabel: 'Comment',
    commentError: 'Please add a comment',
    buttonVerify: 'Verify profile',
    loading: 'Verifying profile',
    runWorkflow: 'Run workflow',
    cancel: 'Cancel',
    run: 'Run',
    success: 'Workflow ran successfully.',
    error: 'Failed to run workflow.',
    verifyEntityCta: 'Verify entity',
    runningWorkflow: 'Running workflow',
    edit: 'Edit & verify',
    runWorkflowDesc:
      'This will run another attempt on this workflow, please check that all data to be verified is accurate before proceeding.',
  },
}
