import { useMutation } from '@tanstack/react-query'

import {
  useApplicantDataQuery,
  useApplicantRiskCheckQuery,
} from 'entities/applicant'

import { useI18n } from 'shared/i18n'
import { notification } from 'shared/notification'

import { applicantRiskScoreApi } from '../../api/applicant-risk-score.api'
import { APPLICANT_RISK_SCORE_KEY } from '../../applicant-risk-score.key'
import { applicantRiskScoreEn } from '../../locale/applicant-risk-score.en'
import { RiskLevelData } from '../../model/applicant-risk-score.model'

type Args = {
  applicantId: string
}

export const useApplicantRiskScoreLevel = ({ applicantId }: Args) => {
  const t = useI18n([APPLICANT_RISK_SCORE_KEY], {
    keys: applicantRiskScoreEn,
  })

  const { refetch: refetchApplicantData } = useApplicantDataQuery({
    applicantId,
  })
  const { refetch: refetchApplicantRiskCheck } = useApplicantRiskCheckQuery({
    entityId: applicantId,
  })

  const mutation = useMutation({
    mutationFn: ({ data }: { data: RiskLevelData }) =>
      applicantRiskScoreApi.changeRiskLevel(applicantId, data),
    onSuccess: () => {
      void refetchApplicantData()
      void refetchApplicantRiskCheck()
    },
    onError: () => {
      notification.error(t('errorChangeLevel'))
    },
  })

  return mutation
}
