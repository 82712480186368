/* eslint-disable complexity */
import React, { ReactElement, useState } from 'react'

import { AxiosError } from 'axios'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import { FrankieAlert, FrankieButton, FrankieTextField } from 'frankify/src'

import {
  AccountLocked,
  AccountLockedErrorTypes,
  findAccountLockedError,
} from 'features/account-locked'
import {
  EXPIRED_URL_PARAM,
  resetPasswordApi,
  ResetPasswordInputTypes,
  ResetPasswordQa,
} from 'features/reset-password'

import { useI18n } from 'shared/i18n'
import { TrackingEventsTypes, trackingManager } from 'shared/tracking'
import type { Nullable } from 'shared/typescript'
import { EMAIL_PATTERN } from 'shared/validation'

import { PASSWORD_RESET_PAGE_LOCALE } from '../locale/password-reset-page.en'

type ResetPasswordFormInput = {
  email: string
}

type LocationState = {
  prefillEmail?: string
  disableEmailInput?: boolean
}

type Props = {
  loginPath: string
  afterResetPath: string
}

export function PasswordResetPage({
  loginPath,
  afterResetPath,
}: Props): ReactElement {
  const t = useI18n([PASSWORD_RESET_PAGE_LOCALE])
  const location = useLocation()
  const navigate = useNavigate()
  const locationsState = location.state as Nullable<LocationState>
  const [resetError, setResetError] = useState(false)
  const [accountLockedError, setAccountLockedError] =
    useState<Nullable<AccountLockedErrorTypes>>(null)

  let prefillEmail = ''

  if (locationsState?.prefillEmail) {
    prefillEmail = locationsState.prefillEmail
  }

  const handleNavToLogin = () => navigate(loginPath)

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
  } = useForm<ResetPasswordFormInput>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {
      email: prefillEmail,
    },
  })

  const emailRegister = register(ResetPasswordInputTypes.Email, {
    required: {
      value: true,
      message: t('validation.email.required'),
    },
    pattern: {
      value: EMAIL_PATTERN,
      message: t('validation.email.pattern'),
    },
  })

  const handleFormSubmit: SubmitHandler<ResetPasswordFormInput> = async ({
    email,
  }) => {
    try {
      setResetError(false)
      trackingManager.track(TrackingEventsTypes.LoginFormUserResetPassport)

      await resetPasswordApi.requestResetPasswordLink(email)

      navigate(`${afterResetPath}/${email}`, {
        state: { shoudSupressReset: true },
      })
    } catch (error) {
      setResetError(true)
      setAccountLockedError(findAccountLockedError(error as AxiosError))
    }
  }

  const [searchParams] = useSearchParams()

  const hasResetTokenExpired = searchParams.get(EXPIRED_URL_PARAM)

  const heading = hasResetTokenExpired
    ? t('passwordResetPage:container.headingExpired')
    : t('passwordResetPage:container.heading')
  const subHeading = hasResetTokenExpired ? (
    <>
      <div>{t('passwordResetPage:container.subheadingExpiredFirst')}</div>
      <div className="mt-2">
        {t('passwordResetPage:container.subheadingExpiredSecond')}
      </div>
    </>
  ) : (
    t('passwordResetPage:container.description.request')
  )

  const sendLinkButtonText = hasResetTokenExpired
    ? t('passwordResetPage:form.button.submit.new')
    : t('passwordResetPage:form.button.submit.default')

  if (accountLockedError) {
    return (
      <AccountLocked
        canUnlock={accountLockedError === AccountLockedErrorTypes.CanUnlock}
      />
    )
  }

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} data-qa="login-form">
      <span
        className="text-primary-700 text-3xl font-bold"
        data-qa={ResetPasswordQa.header}
      >
        {heading}
      </span>
      <div className="text-tertiary-grey-700 text-md font-normal leading-6 mt-2">
        {subHeading}
      </div>
      <FrankieTextField
        className="mt-4"
        // label={t('validation.email.label')}
        {...emailRegister}
        errorText={errors[ResetPasswordInputTypes.Email]?.message}
        error={!!errors[ResetPasswordInputTypes.Email]}
        testId={{ input: ResetPasswordQa.emailInput }}
        disabled={locationsState?.disableEmailInput}
      />

      {resetError && (
        <FrankieAlert className="mt-4 text-tertiary-grey-700" type="error">
          {t('passwordResetPage:form.requestError')}
        </FrankieAlert>
      )}
      <div className="flex flex-initial flex-col-reverse gap-4 tablet:flex-row tablet:justify-end mt-6">
        <FrankieButton
          intent="subtle"
          onClick={handleNavToLogin}
          testId={{ button: ResetPasswordQa.backToLoginButton }}
        >
          {t('passwordResetPage:form.button.backToLogin.default')}
        </FrankieButton>

        <FrankieButton
          type="submit"
          disabled={isSubmitting || !isValid}
          testId={{ button: ResetPasswordQa.resetPasswordRequestButton }}
        >
          {isSubmitting
            ? t('passwordResetPage:form.button.submit.loading')
            : sendLinkButtonText}
        </FrankieButton>
      </div>
    </form>
  )
}
