import { DASHBOARD_KEY } from '../dashboard.key'

export const dashboardQa = {
  header: 'dashboard-header',
  startInput: 'dashboard-dateFilter-startDate',
  endInput: 'dashboard-dateFilter-endDate',
  popupWrapper: 'dashboard-dateFilter-popupWrapper',
} satisfies Record<string, `${typeof DASHBOARD_KEY}-${string}`>

export const requestQa = {
  mainWrapper: 'dashboard-rq-main-wrapper',
  submitBtn: 'dashboard-rq-submit-btn',
  closeBtn: 'dashboard-rq-close-btn',
  regPassword: 'dashboard-rq-register-password',
} satisfies Record<string, `${typeof DASHBOARD_KEY}-rq-${string}`>

export const generateRequestQa = {
  mainWrapper: 'dashboard-gn-rq-component',
  menu: 'dashboard-gn-rq-menu',
  openModal: 'dashboard-gn-rq-open-modal',
  generateBtn: 'dashboard-gn-rq-generate-button',
  downloadTCR: 'dashboard-gn-rq-download-transaction-record-report',
  downloadAllTCR: 'dashboard-gn-rq-download-all-customer-records-report',
} satisfies Record<string, `${typeof DASHBOARD_KEY}-gn-rq-${string}`>

/**
 * Migrated from Vue codebase
 */
export const dashboardVueMigratedQa = {
  dateFilterCta: 'Date range input',
  dateFilterOptionList: 'dropdown options list',
}
