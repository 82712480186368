import { useCallback, useEffect, useMemo, useState } from 'react'

import { useQuery, useQueryClient } from '@tanstack/react-query'

import { useApplicantDataQuery } from 'entities/applicant'
import {
  organisationApi,
  BusinessRequestStatus,
  BusinessRequestType,
  InternationalReportType,
  getReportRequestIdQueryKey,
} from 'entities/organisation'

export const useReportRequestId = (
  reportType: InternationalReportType,
  entityId: string,
) => {
  const client = useQueryClient()
  const queryKey = getReportRequestIdQueryKey(reportType, entityId)

  const query = useQuery<string | null>({
    queryKey,
    queryFn: () => {
      const data = client.getQueryData<string>(queryKey)

      if (!data) return null

      return data
    },
  })

  const clearRequestId = useCallback(
    (status?: BusinessRequestStatus) => {
      if (status !== 'PROCESSING') {
        client.setQueryData(queryKey, null)
        void query.refetch()
      }
    },
    [client, query, queryKey],
  )

  const setRequestId = useCallback(
    (requestId?: string) => {
      client.setQueryData(queryKey, requestId)
      void query.refetch()
    },
    [client, query, queryKey],
  )

  return { ...query, clearRequestId, setRequestId }
}

type BusinessRequestState = {
  currentStatus?: BusinessRequestStatus
  previousStatus?: BusinessRequestStatus
  isProcessing: boolean
  hasError: boolean
  requestedDate?: string
  updatedDate?: string
  requestId?: string
}

type AllRequestStatus = Record<BusinessRequestType, BusinessRequestState>

export const BusinessRequestQueryKey = 'business-request-query-key'

/**
 * Sequence of request type matters
 * Ownership report generation gives ownership request first then after completion ownership report request
 */
const requestType: BusinessRequestType[] = [
  'PROFILE_REPORT',
  'OWNERSHIP',
  'OWNERSHIP_REPORT',
]

type Params = {
  entityId: string
}
export const useGetBusinessRequestStatusInterval = ({ entityId }: Params) => {
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout>()

  const { refetch: refetchApplicantData } = useApplicantDataQuery({
    applicantId: entityId,
  })

  const {
    data: profileReportRequestId,
    clearRequestId: removeProfileReportRequestId,
    setRequestId: setProfileReportRequestId,
  } = useReportRequestId('profile', entityId)

  const {
    data: ownershipRequestId,
    clearRequestId: removeOwnershipRequestId,
    setRequestId: setOwnershipRequestId,
  } = useReportRequestId('continue-ownership', entityId)

  const {
    data: ownershipReportRequestId,
    clearRequestId: removeOwnershipReportRequestId,
    setRequestId: setOwnershipReportRequestId,
  } = useReportRequestId('ownership', entityId)

  const {
    data,
    refetch: refetchRequestStatus,
    isFetching,
  } = useQuery({
    queryKey: [BusinessRequestQueryKey, entityId],
    queryFn: async () => {
      // TODO: ADD query param for exact request type
      const { data } = await organisationApi.getBusinessRequestStatus(entityId)

      return data
    },
    retry: 5,
    retryDelay: 5000,
  })

  const [requestStatus, setRequestStatus] = useState<AllRequestStatus>()

  useEffect(() => {
    if (data) {
      // Check if there is a request for ownership and profile
      let processing = false as boolean

      // Check if there is a request for ownership and profile
      const hasRequest = {
        profile: !profileReportRequestId,
        // Both of the ownership request and ownership report is dependent on ownershipRequestId
        ownership: !ownershipRequestId,
        ownershipReport: !ownershipRequestId || !ownershipReportRequestId,
      }

      // Flag to check if refetching of applicant data is required
      const refetchDocumentRequired = !!(
        profileReportRequestId ||
        ownershipRequestId ||
        ownershipReportRequestId
      )

      let newOwnershipRequestDate: string | undefined
      // eslint-disable-next-line complexity
      const allRequestStatus = requestType.reduce((acc, curr) => {
        const sortedStatus = data.requests[curr]?.sort((a, b) =>
          b.requestedAt.localeCompare(a.requestedAt),
        )

        let currentStatus = sortedStatus?.[0]?.status
        let previousStatus = sortedStatus?.[1]?.status
        const requestId = sortedStatus?.[0]?.requestId
        const requestDate = sortedStatus?.[0]?.requestedAt
        const updateDate = sortedStatus?.[0]?.updatedAt

        switch (curr) {
          case 'PROFILE_REPORT':
            if (
              profileReportRequestId &&
              profileReportRequestId === requestId
            ) {
              hasRequest.profile = true
              removeProfileReportRequestId(currentStatus)
            } else if (currentStatus === 'PROCESSING') {
              // setting request id for refetching document
              // edge case when user refreshes the page while request is processing
              // same will be handle for ownership and ownership report
              setProfileReportRequestId(requestId)
            }

            break
          case 'OWNERSHIP':
            newOwnershipRequestDate = requestDate

            if (ownershipRequestId && ownershipRequestId === requestId) {
              hasRequest.ownership = true

              removeOwnershipRequestId(currentStatus)
            } else if (currentStatus === 'PROCESSING') {
              setOwnershipRequestId(requestId)
            }

            break
          case 'OWNERSHIP_REPORT':
            if (
              ownershipReportRequestId &&
              ownershipReportRequestId === requestId
            ) {
              hasRequest.ownershipReport = true
              removeOwnershipReportRequestId(currentStatus)
            } else if (
              newOwnershipRequestDate &&
              requestDate &&
              hasRequest.ownership // If ownership request is completed then only check for ownership report
            ) {
              const lastOwnershipRequestDate = new Date(newOwnershipRequestDate)
              const lastOwnershipReportRequestDate = new Date(requestDate)

              if (lastOwnershipRequestDate > lastOwnershipReportRequestDate) {
                previousStatus = currentStatus
                currentStatus = 'PROCESSING'
              } else {
                hasRequest.ownershipReport = true
                removeOwnershipRequestId(currentStatus)
              }
            }
            if (currentStatus === 'PROCESSING') {
              setOwnershipReportRequestId(requestId)
            }
            break
          default:
            break
        }

        const state: BusinessRequestState = {
          currentStatus,
          previousStatus,
          isProcessing: currentStatus === 'PROCESSING',
          hasError: currentStatus === 'FAILED',
          requestedDate: requestDate,
          updatedDate: updateDate,
          requestId,
        }

        if (state.currentStatus === 'PROCESSING') {
          processing = true
        }

        return {
          ...acc,
          [curr]: state,
        }
      }, {} as AllRequestStatus)

      setRequestStatus(allRequestStatus)

      const doesNotHaveRequest = Object.values(hasRequest).every(
        hasRequest => !hasRequest,
      )

      if (processing || doesNotHaveRequest) {
        // If any request is processing, set interval to refetch request status
        if (!intervalId) {
          setIntervalId(
            setInterval(() => {
              void refetchRequestStatus()
            }, 10000),
          )
        }
      } else {
        // If no request is processing, clear interval
        clearInterval(intervalId)
        setIntervalId(undefined)

        // Refetch applicant data if document is required for OWNERSHIP_REPORT
        if (refetchDocumentRequired) {
          void refetchApplicantData()

          // clear request id to avoid refetching of document
          removeOwnershipReportRequestId()
          removeOwnershipRequestId()
          removeProfileReportRequestId()
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const reportStatus = useMemo(() => {
    const generatingProfileReport =
      !!requestStatus?.PROFILE_REPORT.isProcessing || !!profileReportRequestId

    const generatingOwnershipReport = !!(
      requestStatus?.OWNERSHIP.isProcessing ||
      requestStatus?.OWNERSHIP_REPORT.isProcessing ||
      !!ownershipRequestId
    )

    const profileReportHasError =
      !!requestStatus?.PROFILE_REPORT.hasError && !profileReportRequestId
    const ownershipRequestHasError =
      (!!requestStatus?.OWNERSHIP.hasError ||
        !!requestStatus?.OWNERSHIP_REPORT.hasError) &&
      !ownershipRequestId

    return {
      generatingProfileReport,
      generatingOwnershipReport,
      profileReportHasError,
      ownershipRequestHasError,
    }
  }, [ownershipRequestId, profileReportRequestId, requestStatus])

  return {
    requestStatus,
    refetchRequestStatus,
    isFetchingRequestStatus: isFetching,
    profileReportRequestId,
    ownershipRequestId,
    ...reportStatus,
  }
}
