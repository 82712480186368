import { useMutation } from '@tanstack/react-query'

import { organisationApi } from '../../api/organisation.api'
import { useReportRequestIdQuery } from '../report-request-query/report-request-query'

type Args = {
  entityId: string
}

export const useContinueOwnershipReportGeneration = () => {
  const { setRequestIdData } = useReportRequestIdQuery()

  return useMutation({
    mutationFn: ({ entityId }: Args) =>
      organisationApi.continueOwnershipReportGeneration({
        entityId,
      }),
    onSuccess: (response, { entityId }) => {
      setRequestIdData('continue-ownership', {
        entityId,
        requestId: response.data.requestId,
      })
    },
  })
}
