/* eslint-disable complexity */
import React, { useEffect, useState } from 'react'

import { useQuery, useQueryClient } from '@tanstack/react-query'

import { FrankieButton, FrankieIcon } from 'frankify/src'

import { ORGANISATION_SEARCH_KEY } from 'features/organisation-search'

import { useI18n } from 'shared/i18n'

import { ABRCompany } from '../../model/abr-search-model'
import { AbrSearchResultAustralia } from '../abr-search-australia/abr-search-australia'
import { AbrSearchInput } from '../abr-search-input/abr-search-input'
import { ManualAddAustralianOrganisation } from '../manual-add-aus-organisation/manual-add-aus-organisation'

export function ABRSearch() {
  const t = useI18n([ORGANISATION_SEARCH_KEY])

  const [dataToDisplay, setDataToDisplay] = useState({
    organizationName: '',
    countryName: '',
  })

  const queryClient = useQueryClient()

  const {
    data: foundABROrganisations,
    isFetching: isLoadingAbrOrganisations,
    isError: isErrorLoadingAbrOrganisations,
    isSuccess: isLoadedABROrganizationsSuccesfully,
  } = useQuery({
    queryKey: ['abr-search'],
    queryFn: () => queryClient.getQueryData<ABRCompany[]>(['abr-search']),
    enabled: false,
  })

  useEffect(() => {
    // clear search result on mount
    void queryClient.setQueryData(['abr-search'], null)
    void queryClient.removeQueries({ queryKey: ['abr-search'] })
    void queryClient.invalidateQueries({ queryKey: ['abr-search'] })

    setDataToDisplay({
      countryName: '',
      organizationName: '',
    })
  }, [queryClient])

  const showAbrResults = Boolean(
    !isLoadingAbrOrganisations &&
      isLoadedABROrganizationsSuccesfully &&
      foundABROrganisations &&
      foundABROrganisations.length > 0,
  )

  const showNoABRResults =
    !isLoadingAbrOrganisations &&
    (isErrorLoadingAbrOrganisations || foundABROrganisations?.length === 0)

  const [
    isManualAddAustralianOrganisation,
    toggleManualAddAustralianOrganisation,
  ] = useState(false)

  const handleGoToManualAdd = () => toggleManualAddAustralianOrganisation(true)

  const handleClickBackFromManualAdd = () => {
    setDataToDisplay({
      countryName: '',
      organizationName: '',
    })

    toggleManualAddAustralianOrganisation(false)
  }

  return (
    <div
      id="mid-cont"
      className="max-w-[1440px] w-full flex justify-center bg-mono-white"
    >
      <form className="w-[910px] mt-8">
        {!isManualAddAustralianOrganisation && (
          <>
            <AbrSearchInput setDataToDisplay={setDataToDisplay} />
            <div className="mt-12 pb-2">
              <AbrSearchResultAustralia
                dataToDisplay={dataToDisplay}
                isLoadingAbrOrganisations={isLoadingAbrOrganisations}
                showNoMatchesError={showNoABRResults}
                foundABROrganisations={foundABROrganisations!}
                showAbrResults={showAbrResults}
                onGoToManualAdd={handleGoToManualAdd}
              />
            </div>
          </>
        )}
        {isManualAddAustralianOrganisation && (
          <div>
            <FrankieButton
              noStyles
              className="text-sm font-normal text-tertiary-grey-500"
              onClick={handleClickBackFromManualAdd}
            >
              <div className="flex items-center">
                <FrankieIcon
                  size="xs"
                  name="mdiChevronLeft"
                  className="inline-block"
                />
                <span>{t('backToResults')}</span>
              </div>
            </FrankieButton>
            <div className="mt-2 text-tertiary-grey-800 text-xl font-bold">
              {t('manuallyAdd.header')}
            </div>
            <div className="mt-6">
              <ManualAddAustralianOrganisation />
            </div>
          </div>
        )}
      </form>
    </div>
  )
}
