import React, { ReactNode } from 'react'

import { FrankieButton, FrankieIcon } from 'frankify/src'

import {
  BlocklistAttrFormattedText,
  BlocklistReasonFormattedText,
} from 'features/blocklist-table'

import { ApplicantId } from 'entities/applicant'

import { useI18n } from 'shared/i18n'
import { useOverlay } from 'shared/overlay'

import { APPLICANT_GENERAL_INFORMATION_KEY } from '../../../applicant-general-information.key'
import { applicantGeneralInformationEn } from '../../../locale/applicant-general-information.en'
import { watchListOptions } from '../../../model/applicant-menu.model'
import { bannerMappingQa } from '../../../qa/applicant-general-information.qa'
import { useApplicantCheckSummaryState } from '../../../state/applicant-check-summary-state/applicant-check-summary-state'
import { ApplicantActionModal } from '../../applicant-action-menu/applicant-action-modal/applicant-action-modal'

type WrapperProps = {
  className: string
  renderDetail: ReactNode
  renderAction: ReactNode
}

function StatusBannerWrapper({
  renderDetail,
  renderAction,
  className = '',
}: WrapperProps) {
  return (
    <div
      className={`${className} flex h-[50px] items-center justify-between px-6 font-bold`}
    >
      <div className="flex items-center gap-4">{renderDetail}</div>
      {renderAction}
    </div>
  )
}

type BannerProps = {
  applicantId: ApplicantId
  children?: ReactNode
}

export function SpecialEntityStateBanner({
  applicantId,
  children,
}: BannerProps) {
  const t = useI18n([APPLICANT_GENERAL_INFORMATION_KEY], {
    keys: applicantGeneralInformationEn,
  })

  const { inactiveProfileAccount, isBusinessProfile } =
    useApplicantCheckSummaryState({
      applicantId,
    })

  return (
    <StatusBannerWrapper
      className="bg-tertiary-grey-100 text-tertiary-grey-500"
      renderDetail={
        <>
          {isBusinessProfile && (
            <FrankieIcon
              name={
                inactiveProfileAccount
                  ? 'mdiClose'
                  : 'mdiArchiveArrowDownOutline'
              }
              className="text-tertiary-grey-500"
            />
          )}
          <span>
            {inactiveProfileAccount
              ? t('status.profileDeactivated')
              : t('status.profileArchived')}
          </span>
        </>
      }
      renderAction={children}
    />
  )
}

export function BlocklistBanner({ applicantId, children }: BannerProps) {
  const t = useI18n([APPLICANT_GENERAL_INFORMATION_KEY], {
    keys: applicantGeneralInformationEn,
  })

  const { blocklistReason, blocklistAttribute } = useApplicantCheckSummaryState(
    { applicantId },
  )

  const [createOverlay, closeOverlay] = useOverlay()

  const handleClick = () => {
    createOverlay(
      <ApplicantActionModal
        handleClose={closeOverlay}
        applicantId={applicantId}
        type="AddApplicantToBlockList"
        changeReason={!!blocklistReason}
        reasonCode={blocklistReason || undefined}
      />,
      { className: 'p-0' },
    )
  }

  return (
    <StatusBannerWrapper
      className="bg-neutral-100 text-mono-white"
      renderDetail={
        <>
          <FrankieIcon
            name="mdiCancel"
            className="text-mono-white"
            testId={{ icon: bannerMappingQa.blockListIcon }}
          />
          {blocklistAttribute ? (
            <div>
              <span>{t('blocklisted')}</span>
              <BlocklistAttrFormattedText attribute={blocklistAttribute} />
            </div>
          ) : (
            <span>{t('status.profileBlockListed')}</span>
          )}
          {blocklistReason && (
            <FrankieButton
              noStyles
              className="bg-tertiary-grey-500 px-2 py-1 rounded-sm"
              onClick={handleClick}
            >
              <BlocklistReasonFormattedText reason={blocklistReason} />
            </FrankieButton>
          )}
        </>
      }
      renderAction={children}
    />
  )
}

export function BlocklistOrDuplicateMatchBanner({
  applicantId,
  children,
}: BannerProps) {
  const t = useI18n([APPLICANT_GENERAL_INFORMATION_KEY], {
    keys: applicantGeneralInformationEn,
  })

  const { isResolvedBlacklistEntity } = useApplicantCheckSummaryState({
    applicantId,
  })

  return (
    <StatusBannerWrapper
      className="bg-neutral-100 text-mono-white"
      renderDetail={
        <>
          {isResolvedBlacklistEntity && (
            <FrankieIcon name="mdiCancel" className="text-mono-white" />
          )}
          <span>
            {isResolvedBlacklistEntity
              ? t('status.truePositiveBlocklistMatch')
              : t('status.truePositiveDuplicateMatch')}
          </span>
        </>
      }
      renderAction={children}
    />
  )
}

export function WatchlistBanner({ applicantId, children }: BannerProps) {
  const t = useI18n([APPLICANT_GENERAL_INFORMATION_KEY], {
    keys: applicantGeneralInformationEn,
  })

  const { watchlistReasonCode } = useApplicantCheckSummaryState({
    applicantId,
  })

  const value = watchListOptions.find(
    item => item.value === watchlistReasonCode,
  )?.tKey

  return (
    <StatusBannerWrapper
      className="bg-tertiary-grey-100 text-secondary-900"
      renderDetail={
        <>
          <FrankieIcon
            name="mdiEyeOutline"
            className="text-secondary-900"
            testId={{ icon: bannerMappingQa.watchListIcon }}
          />
          <span>{t('status.internalWatchlist')}</span>
          {value && (
            <div className="bg-tertiary-grey-500  px-2 py-1 rounded-sm text-mono-white">
              {t(value)}
            </div>
          )}
        </>
      }
      renderAction={children}
    />
  )
}
