export const DOCUMENT_F2_KEY = 'DOCUMENT-F2'

export const documentTypeNameEn = {
  documentTypeName: {
    driversLicense: "Driver's Licence",
    passport: 'Passport',
    medicare: 'Medicare Card',
  },
  documentTypes: {
    OTHER: 'OTHER',
    DRIVERS_LICENSE: 'Driver’s Licence',
    PASSPORT: 'Passport',
    VISA: 'VISA',
    IMMIGRATION: 'IMMIGRATION',
    NATIONAL_ID: 'Medicare Card',
    TAX_ID: 'TAX_ID',
    NATIONAL_HEALTH_ID: 'NATIONAL_HEALTH_ID',
    CONCESSION: 'CONCESSION',
    HEALTH_CONCESSION: 'HEALTH_CONCESSION',
    PENSION: 'PENSION',
    MILITARY_ID: 'MILITARY_ID',
    BIRTH_CERT: 'BIRTH_CERT',
    CITIZENSHIP: 'CITIZENSHIP',
    MARRIAGE_CERT: 'MARRIAGE_CERT',
    DEATH_CERT: 'DEATH_CERT',
    NAME_CHANGE: 'NAME_CHANGE',
    UTILITY_BILL: 'UTILITY_BILL',
    BANK_STATEMENT: 'BANK_STATEMENT',
    BANK_ACCOUNT: 'BANK_ACCOUNT',
    INTENT_PROOF: 'INTENT_PROOF',
    ATTESTATION: 'ATTESTATION',
    SELF_IMAGE: 'SELF_IMAGE',
    DEVICE: 'DEVICE',
    VEHICLE_REGISTRATION: 'VEHICLE_REGISTRATION',
    PROOF_OF_ADDRESS: 'PROOF_OF_ADDRESS',
    HOUSE_REGISTRATION: 'HOUSE_REGISTRATION',
    YELLOW_HOUSE_REGISTRATION: 'YELLOW_HOUSE_REGISTRATION',
    WORK_PERMIT: 'WORK_PERMIT',
    EMPLOYMENT_CERTIFICATE: 'EMPLOYMENT_CERTIFICATE',
    NOTARY_PUBLIC_ID: 'NOTARY_PUBLIC_ID',
    EXTERNAL_ADMIN: 'EXTERNAL_ADMIN',
    CHARGES: 'CHARGES',
    PRE_ASIC: 'PRE_ASIC',
    ANNUAL_RETURN: 'ANNUAL_RETURN',
    REPORT: 'REPORT',
    TRUST_DEED: 'TRUST_DEED',
    PARTNERSHIP_AGREEMENT: 'PARTNERSHIP_AGREEMENT',
    ADMIN_CHANGE: 'ADMIN_CHANGE',
    COMPANY_REPORT: 'COMPANY_REPORT',
    CHECK_RESULTS: 'CHECK_RESULTS',
    AVIATION_SECURITY_ID: 'AVIATION_SECURITY_ID',
    MARITIME_SECURITY_ID: 'MARITIME_SECURITY_ID',
  },
  attachmentSide: {
    FRONT: 'Front',
    BACK: 'Back',
    PHOTO_PAGE: 'Photo Page',
  },
}

export const documentF2En = {
  message: {
    invalidFileType:
      'We don’t support that file type. Please use {{fileTypes}} instead.',
    invalidFileSize: 'Your file needs to be under {{fileSize}}MB',
    invalidFileName:
      'File name is too long or contains special characters. Please rename it (max {{maxLength}} characters) and try again.',
  },
  placeholder: 'Drag and drop copy of document or Browse',
  placeholder1: 'Click to upload ',
  placeholder2: 'or drag and drop ',
  optional: '(optional)',
  placeholder3: 'PNG, JPG or PDF (Max. File Size: 20MB)',
  view: 'View',
  replace: 'Replace',
  uploading: 'Uploading...',
  replacing: 'Replacing...',
  document: 'Document',
  uploadedOn: 'Uploaded On {{date}}',
  uploadedAt: 'Uploaded on {{date}} at {{time}}',
  uploadedAtVia: 'Uploaded on {{date}} at {{time}} {{via}}',
  directCapture: 'via direct capture',
  by: 'by {{name}}',
  front: 'Front',
  back: 'Back',
  photoPage: 'Photo Page',

  ...documentTypeNameEn,
}
