import React from 'react'

import { useForm } from 'react-hook-form'

import { FrankieAlert, FrankieButton } from 'frankify/src'

import {
  ServiceProfile,
  useFrankie2R2Customer,
  WorkflowStepResultEnum,
} from 'entities/entity'

import { SelectFormField, TextAreaFormField } from 'shared/form'
import { useI18n } from 'shared/i18n'
import { useValidationRules } from 'shared/validation'

import { workflowEventsEn } from '../../locale/workflow-events.en'
import { MutateManualStatusType } from '../../model/applicant-workflow-events.model'
import { entityManualEventFormQa } from '../../qa/applicant-workflow-events.qa'
import { WORKFLOW_EVENTS_KEY } from '../../workflow-events.key'

type ManualStatusChangeFormProps = {
  options: { label: string; value: string }[]
  statusValue: string
  entityData: ServiceProfile
  closeOverlay: () => void
  updateWorkflowStatus: (payload: MutateManualStatusType) => void
  isAmlYetToReviewed?: boolean
}

export type UpdateStatusType = {
  manualStatus: string
  comment: string
}

export function ManualStatusChangeForm({
  options,
  statusValue,
  entityData,
  closeOverlay,
  updateWorkflowStatus,
  isAmlYetToReviewed = false,
}: ManualStatusChangeFormProps) {
  const t = useI18n([WORKFLOW_EVENTS_KEY], {
    keys: workflowEventsEn,
  })

  const isFrankie2R2 = useFrankie2R2Customer()
  const { xssRule } = useValidationRules()

  const {
    control,
    formState: { isValid },
    handleSubmit,
  } = useForm<UpdateStatusType>({
    defaultValues: {
      manualStatus: statusValue,
      comment: '',
    },
    mode: 'onTouched',
  })

  const onSubmitHandler = (data: UpdateStatusType) => {
    const workFlowSummary = entityData.workflowSummaries?.at(0)

    if (
      entityData.serviceName &&
      entityData.currentWorkflowName &&
      workFlowSummary &&
      workFlowSummary.workflowExecutionId &&
      data.manualStatus
    ) {
      updateWorkflowStatus({
        serviceName: entityData.serviceName,
        workflowName: entityData.currentWorkflowName,
        workflowExecutionId: workFlowSummary.workflowExecutionId,
        workflowStatus: data.manualStatus as WorkflowStepResultEnum,
        comment: data.comment,
      })
    } else closeOverlay()
  }

  return (
    <div className="w-[440px]" data-qa={entityManualEventFormQa.container}>
      <div className="flex flex-col">
        <div className="flex flex-col gap-4">
          <div className="flex justify-between flex-grow-0">
            <div
              className="text-tertiary-grey-800 font-bold text-xl leading-7"
              data-qa={entityManualEventFormQa.title}
            >
              {t('manualStatus.title')}
            </div>
          </div>
          {isAmlYetToReviewed && (
            <FrankieAlert
              type="warning"
              warningIconClassName="w-20px h-20px text-tertiary-yellow-700"
              className="text-tertiary-grey-700 text-sm leading-5  items-center"
              overrideIconName="mdiAlertCircleOutline"
            >
              {t('amlReviewBannerTitle')}
            </FrankieAlert>
          )}
          <div data-qa={entityManualEventFormQa.subtitle} className="leading-5">
            {t('manualStatus.subTitle')}
          </div>

          <form
            className="flex flex-col gap-4 justify-between"
            onSubmit={handleSubmit(onSubmitHandler)}
            data-qa={entityManualEventFormQa.form}
          >
            <div className="flex flex-col gap-4">
              <SelectFormField
                name="manualStatus"
                control={control}
                options={options}
                label={t('manualStatus.selectLabel')}
                rules={{ required: true }}
                testId={{ input: entityManualEventFormQa.select }}
              />

              {isFrankie2R2 ? (
                <TextAreaFormField
                  name="comment"
                  shouldUnregister
                  inputClassName="!min-h-[56px]"
                  control={control}
                  showError
                  trim
                  maxLength={500}
                  counter={500}
                  placeholder={t('manualStatus.commentPlaceholder')}
                  label={t('manualStatus.commentLabel')}
                  rules={{
                    required: t('manualStatus.commentError'),
                    ...xssRule,
                  }}
                  testId={{ input: entityManualEventFormQa.TextAreaFormField }}
                />
              ) : (
                <div />
              )}
            </div>
            <div className="flex flex-grow-0 justify-end">
              <FrankieButton
                className="mr-8"
                noStyles
                onClick={() => closeOverlay()}
                testId={{ button: entityManualEventFormQa.cancel }}
              >
                {t('manualStatus.cancel')}
              </FrankieButton>
              <FrankieButton
                size="sm"
                disabled={!isValid}
                type="submit"
                testId={{ button: entityManualEventFormQa.changeStatus }}
              >
                {t('manualStatus.changeStatus')}
              </FrankieButton>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
