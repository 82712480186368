import { KybReportType } from 'entities/applicant'

import { DateFormatTypes, formatDate } from 'shared/date-time'

import { businessCheckIcon, businessReportIcon } from '../assets'
import { ApplicantGeneralInformationKeys } from '../locale/applicant-general-information.en'

export enum BusinessReportTypes {
  owner = 'SINGLE_LEVEL',
  ubo = 'UBO',
  credit = 'CREDIT',
}

export type BusinessReportData = {
  titleTKey: ApplicantGeneralInformationKeys
  labelTKey: ApplicantGeneralInformationKeys
  descriptionTKey: ApplicantGeneralInformationKeys
  kybReportType: KybReportType
  icon: keyof typeof businessReportIcon
}

export const businessReportData: Record<
  BusinessReportTypes,
  BusinessReportData
> = {
  [BusinessReportTypes.owner]: {
    titleTKey: 'action.generateReport',
    labelTKey: 'businessReport.owner',
    descriptionTKey: 'singleLevelBusinessReport',
    kybReportType: 'SINGLE-LEVEL-AML',
    icon: 'owner',
  },
  [BusinessReportTypes.ubo]: {
    titleTKey: 'action.heading.generateUboReport',
    labelTKey: 'businessReport.uboReport',
    descriptionTKey: 'uboBusinessReport',
    kybReportType: 'UBO',
    icon: 'ubo',
  },
  [BusinessReportTypes.credit]: {
    titleTKey: 'action.generateReport',
    labelTKey: 'businessReport.creditReport',
    descriptionTKey: 'crBusinessReport',
    kybReportType: 'CR',
    icon: 'credit',
  },
}

export enum BusinessCheckTypes {
  pep = 'PEP',
}

export type BusinessCheckData = {
  titleTKey: ApplicantGeneralInformationKeys
  descriptionTKey: ApplicantGeneralInformationKeys
  icon: keyof typeof businessCheckIcon
}

export const businessCheckData: Record<BusinessCheckTypes, BusinessCheckData> =
  {
    [BusinessCheckTypes.pep]: {
      titleTKey: 'businessCheck.pep.title',
      descriptionTKey: 'businessCheck.pep.description',
      icon: 'pepCheck',
    },
  }

export type ABRBusinessSummary = {
  name: string
  mainNames: string[]
  legalNames: string[]
  tradingNames: string[]
  location: string
  type: string
  acn: string
  abn: string
  abnStatus: string
  currentBusiness: string[]
  historicalBusiness: string
}

export type OtherBusinessSummary = {
  merchantCategory: string
}

export function getBusinessFormattedDate(date?: string): string {
  return date ? formatDate(date, DateFormatTypes.ShortDate) : ''
}
