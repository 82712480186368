import React, { useState, useMemo } from 'react'

import { useForm } from 'react-hook-form'

import { FrankieButton } from 'frankify/src'

import { DocumentUploadV2, UploadedDocumentData } from 'entities/document'
import { SupportedFileTypes } from 'entities/document/model/document.model'

import { SelectFormField } from 'shared/form'
import { useI18n } from 'shared/i18n'
import { notification } from 'shared/notification'
import { Noop, Nullable } from 'shared/typescript'

import { APPLICANT_SUPPORTING_DOCUMENTS_KEY } from '../../applicant-supporting-documents.key'
import { applicantSupportingDocumentsEn } from '../../locale/applicant-supporting-documents.en'
import {
  TrustTypes,
  trustTypesOption,
} from '../../model/applicant-supporting-documents.model'
import { trustDeedsDocQa } from '../../qa/applicant-support-documents.qa'
import {
  useTrustDeedAnalyser,
  useTrustDeedUploadFile,
} from '../../state/trust-deeds-mutation/trust-deeds-mutation'

type File = { name: string; id: string }
type UploadedFile = Nullable<File>

type TrustAnalyzerUploadProps = {
  onClose: Noop
  applicantId: string
}

type UploadDocumentProps = {
  onClose: Noop
  isLoading?: boolean
  onChange: (data: UploadedDocumentData) => void
}

type TrustAnalyzerTypeProps = {
  onClose: Noop
  applicantId: string
  reset: () => void
  uploadedFile: UploadedFile
  documentId?: string
}

type TrustForm = {
  type: TrustTypes
}

function UploadDocument({ isLoading, onChange, onClose }: UploadDocumentProps) {
  return (
    <div className="mt-5 grid grid-rows-[1fr_auto] gap-6">
      <div className="bg-neutral-20 p-4 rounded-2">
        <DocumentUploadV2
          className="flex justify-start"
          name="Trust deed upload"
          isLoading={isLoading}
          onChange={onChange}
          fileSize={20}
          supportedFileTypes={[SupportedFileTypes.PDF]}
          testId={{
            loader: trustDeedsDocQa.uploadLoader,
            upload: trustDeedsDocQa.uploadFileInput,
          }}
        />
      </div>
      <FrankieButton
        testId={{ button: trustDeedsDocQa.cancelUpload }}
        className="justify-self-start text-sm font-semibold"
        noStyles
        onClick={onClose}
      >
        Cancel
      </FrankieButton>
    </div>
  )
}

function TrustAnalyzerType({
  applicantId,
  reset,
  uploadedFile,
  onClose,
  documentId,
}: TrustAnalyzerTypeProps) {
  const t = useI18n([APPLICANT_SUPPORTING_DOCUMENTS_KEY], {
    keys: applicantSupportingDocumentsEn,
  })

  const { mutateAsync, isLoading } = useTrustDeedAnalyser()

  const trustOptions = useMemo(
    () =>
      trustTypesOption.map(({ tKey, value }) => ({
        label: t(tKey),
        value,
      })),
    [t],
  )

  const { control, handleSubmit } = useForm<TrustForm>({
    mode: 'onChange',
  })

  const onSubmit = async (data: TrustForm) => {
    if (!documentId) {
      notification.error(t('analysisFailed'))
      return
    }
    await mutateAsync({
      entityId: applicantId,
      payload: {
        documentId,
        type: data.type,
      },
    })

    onClose()
  }

  return (
    <div className="mt-4 flex flex-col gap-4">
      <section>
        <div
          data-qa={trustDeedsDocQa.trustDocumentAdded}
          className="text-tertiary-grey-800 font-semibold text-md"
        >
          {t('trustDocumentAdded')}:
          <span className="ml-2 font-normal text-md">{uploadedFile?.name}</span>
        </div>
        <div
          className="text-primary-800 text-sm font-normal"
          onClick={reset}
          onKeyDown={reset}
          role="button"
          tabIndex={0}
          data-qa={trustDeedsDocQa.uploadDifferentFile}
        >
          {t('uploadDifferentFile')}
        </div>
      </section>
      <p className="text-sm font-normal">{t('trustAnalyzerTypeDescription')}</p>
      <form onSubmit={handleSubmit(onSubmit)} className="h-full flex flex-col">
        <SelectFormField
          label={t('trustType')}
          placeholder={t('select')}
          control={control}
          name="type"
          options={trustOptions}
          defaultValue={TrustTypes.AUTO_DETECT}
          testId={{ input: trustDeedsDocQa.selectTrustType }}
          className="h-full"
        />
        <div className="flex justify-end">
          <div className="flex justify-end">
            <FrankieButton
              intent="secondary"
              onClick={onClose}
              testId={{ button: trustDeedsDocQa.addFileOnlyButton }}
            >
              {t('addFileOnly')}
            </FrankieButton>
            <FrankieButton
              intent="primary"
              className="ml-2"
              testId={{ button: trustDeedsDocQa.startAnalysisButton }}
              type="submit"
              disabled={isLoading}
            >
              {t('startAnalysis')}
            </FrankieButton>
          </div>
        </div>
      </form>
    </div>
  )
}

export function TrustAnalyzerUpload({
  onClose,
  applicantId,
}: TrustAnalyzerUploadProps) {
  const {
    mutate,
    isLoading,
    isSuccess,
    reset,
    data: uploadResponse,
  } = useTrustDeedUploadFile()

  const t = useI18n([APPLICANT_SUPPORTING_DOCUMENTS_KEY], {
    keys: applicantSupportingDocumentsEn,
  })

  const [uploadedFile, setUploadedFile] = useState<UploadedFile>(null)

  const onChange = (data: UploadedDocumentData) => {
    setUploadedFile({ name: data.scanName, id: data.fileUploadUuid })
    mutate({
      entityId: applicantId,
      payload: { country: 'AUS', docUUID: data.fileUploadUuid },
    })
  }

  const uploadFileSuccess = isSuccess

  const title = uploadFileSuccess
    ? t('trustAnalyzer')
    : t('addTrustDeedDocument')

  return (
    <div
      data-qa={trustDeedsDocQa.uploadWrapper}
      className="grid grid-rows-[auto_1fr] h-full"
    >
      <div
        data-qa={trustDeedsDocQa.uploadTitle}
        className="text-tertiary-grey-800 text-2xl leading-tight font-bold"
      >
        {title}
      </div>
      {uploadFileSuccess && !isLoading ? (
        <TrustAnalyzerType
          uploadedFile={uploadedFile}
          reset={reset}
          onClose={onClose}
          applicantId={applicantId}
          documentId={uploadResponse?.documentId}
        />
      ) : (
        <UploadDocument
          isLoading={isLoading}
          onChange={onChange}
          onClose={onClose}
        />
      )}
    </div>
  )
}
