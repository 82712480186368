import React, { useEffect, useState } from 'react'

import { useInView } from 'react-intersection-observer'

import { DocumentViewF2 } from 'entities/document-f2'
import { DocumentIdTypes, IDocsToBeDisplayedType } from 'entities/entity'

import { useI18n } from 'shared/i18n'
import { TrackingEventsTypes, trackingManager } from 'shared/tracking'

import { workflowEventsEn } from '../../locale/workflow-events.en'
import { verificationDocumentCardQa } from '../../qa/applicant-workflow-events.qa'
import { WORKFLOW_EVENTS_KEY } from '../../workflow-events.key'
import { LabelAndSources } from '../entity-verification-helper/entity-verification-helper'

type DocumentProps = {
  data: IDocsToBeDisplayedType
}

export enum DocumentTypes {
  PASSPORT = 'PASSPORT',
  DRIVER_LICENCE = 'DRIVER_LICENCE',
  MEDICARE = 'MEDICARE_CARD',
}

export function VerificationDocumentCard({ data }: DocumentProps) {
  const t = useI18n([WORKFLOW_EVENTS_KEY], {
    keys: workflowEventsEn,
  })

  const { ref, inView } = useInView()
  const [eventEmitted, setEventEmitted] = useState(false)

  useEffect(() => {
    if (inView && !eventEmitted) {
      trackingManager.track(TrackingEventsTypes.WorkflowVerificationIndividual)
      setEventEmitted(true)
    }
  }, [inView])

  return (
    <div
      className="flex flex-col gap-3"
      data-qa={verificationDocumentCardQa.container}
      ref={ref}
    >
      <div
        className="font-semibold text-md lowercase first-letter:capitalize"
        data-qa={verificationDocumentCardQa.documentLabel}
      >
        {t(`documentLabels.${data.type as unknown as DocumentIdTypes}`)}
      </div>

      <div className="flex">
        <div className="flex gap-6 w-3/4 ">
          {data.attachments?.[0] && (
            <div
              className="desktop:w-64 w-36"
              data-qa={verificationDocumentCardQa.documentView}
            >
              <DocumentViewF2
                className="aspect-video"
                document={data}
                extraData={[...data.extraData, ...data.label]}
              />
            </div>
          )}
          <LabelAndSources labelAndSources={data.label} />
        </div>
      </div>
    </div>
  )
}
