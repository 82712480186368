import { useMutation, useQueryClient } from '@tanstack/react-query'

import { IUser } from 'entities/user'

import { userManagementApi } from '../../api/user-management.api'
import { UserListQueryKey } from '../user-list/user-list.query'

export const useDeleteUserMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (userId: string) => userManagementApi.deleteUser(userId),
    onSuccess: ({ data: newUser }) => {
      queryClient.setQueryData<IUser[]>(UserListQueryKey, oldData => {
        if (!oldData) return []

        return oldData.filter(user => user.id !== newUser.id)
      })
    },
  })
}
